/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ViewFulfillmentItemFragment = { __typename?: 'ViewFulfillmentItem', id?: string | null, availability?: number | null, dateAvailable?: string | null, deliveryId?: string | null, fulfillmentCode?: string | null, erpLastChanged?: string | null, pickStatus?: Types.DeliveryCompletionStatus | null, grossWeight?: number | null, weightUOMId?: string | null, weightUOMCode?: string | null, weightUOMLabel?: string | null, item?: string | null, loadStatus?: Types.DeliveryCompletionStatus | null, lotCode?: string | null, lotId?: string | null, netWeight?: number | null, productId?: string | null, productCode?: string | null, productDescription?: string | null, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, quantity?: string | null, salesOrderCode?: string | null, salesOrderItem?: string | null, stockStatus?: string | null, unitOfMeasure?: string | null, unitOfMeasureId?: string | null, volume?: number | null, volumeUOMId?: string | null, volumeUOMCode?: string | null, volumeUOMLabel?: string | null, updatedAt?: string | null, warehouseId: string };

export type ViewFulfillmentItemsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ViewFulfillmentItemFilter>;
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  sorting?: Types.InputMaybe<Array<Types.ViewFulfillmentItemSort> | Types.ViewFulfillmentItemSort>;
}>;


export type ViewFulfillmentItemsQuery = { __typename?: 'Query', viewFulfillmentItems: { __typename?: 'ViewFulfillmentItemOffsetConnection', totalCount: number, fulfillmentItems: Array<{ __typename?: 'ViewFulfillmentItem', id?: string | null, availability?: number | null, dateAvailable?: string | null, deliveryId?: string | null, fulfillmentCode?: string | null, erpLastChanged?: string | null, pickStatus?: Types.DeliveryCompletionStatus | null, grossWeight?: number | null, weightUOMId?: string | null, weightUOMCode?: string | null, weightUOMLabel?: string | null, item?: string | null, loadStatus?: Types.DeliveryCompletionStatus | null, lotCode?: string | null, lotId?: string | null, netWeight?: number | null, productId?: string | null, productCode?: string | null, productDescription?: string | null, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, quantity?: string | null, salesOrderCode?: string | null, salesOrderItem?: string | null, stockStatus?: string | null, unitOfMeasure?: string | null, unitOfMeasureId?: string | null, volume?: number | null, volumeUOMId?: string | null, volumeUOMCode?: string | null, volumeUOMLabel?: string | null, updatedAt?: string | null, warehouseId: string }> } };

export type CreatePickTasksMutationVariables = Types.Exact<{
  fulfillmentId: Types.Scalars['ID']['input'];
}>;


export type CreatePickTasksMutation = { __typename?: 'Mutation', createPickTasks: { __typename?: 'TaskCreatePickToStagingResourceDto', created: Array<{ __typename?: 'FulfillmentItem', id: string, fulfillmentItem: string }>, hasTask: Array<{ __typename?: 'FulfillmentItem', id: string, fulfillmentItem: string }>, noStockFound: Array<{ __typename?: 'FulfillmentItem', id: string, fulfillmentItem: string }> } };

export const ViewFulfillmentItemFragmentDoc = gql`
    fragment ViewFulfillmentItem on ViewFulfillmentItem {
  id
  availability
  dateAvailable
  deliveryId
  fulfillmentCode
  erpLastChanged
  pickStatus
  grossWeight
  weightUOMId
  weightUOMCode
  weightUOMLabel
  item
  loadStatus
  lotCode
  lotId
  netWeight
  productId
  productCode
  productDescription
  licensePlateId
  licensePlateCode
  licensePlateDescription
  quantity
  salesOrderCode
  salesOrderItem
  stockStatus
  unitOfMeasure
  unitOfMeasureId
  volume
  volumeUOMId
  volumeUOMCode
  volumeUOMLabel
  updatedAt
  warehouseId
}
    `;
export const ViewFulfillmentItemsDocument = gql`
    query ViewFulfillmentItems($filter: ViewFulfillmentItemFilter, $paging: OffsetPaging, $sorting: [ViewFulfillmentItemSort!]) {
  viewFulfillmentItems(filter: $filter, paging: $paging, sorting: $sorting) {
    fulfillmentItems: nodes {
      ...ViewFulfillmentItem
    }
    totalCount
  }
}
    ${ViewFulfillmentItemFragmentDoc}`;

/**
 * __useViewFulfillmentItemsQuery__
 *
 * To run a query within a React component, call `useViewFulfillmentItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewFulfillmentItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewFulfillmentItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useViewFulfillmentItemsQuery(baseOptions?: Apollo.QueryHookOptions<ViewFulfillmentItemsQuery, ViewFulfillmentItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewFulfillmentItemsQuery, ViewFulfillmentItemsQueryVariables>(ViewFulfillmentItemsDocument, options);
      }
export function useViewFulfillmentItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewFulfillmentItemsQuery, ViewFulfillmentItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewFulfillmentItemsQuery, ViewFulfillmentItemsQueryVariables>(ViewFulfillmentItemsDocument, options);
        }
export type ViewFulfillmentItemsQueryHookResult = ReturnType<typeof useViewFulfillmentItemsQuery>;
export type ViewFulfillmentItemsLazyQueryHookResult = ReturnType<typeof useViewFulfillmentItemsLazyQuery>;
export type ViewFulfillmentItemsQueryResult = Apollo.QueryResult<ViewFulfillmentItemsQuery, ViewFulfillmentItemsQueryVariables>;
export const CreatePickTasksDocument = gql`
    mutation CreatePickTasks($fulfillmentId: ID!) {
  createPickTasks: createPickTasksForFulfillment(fulfillmentId: $fulfillmentId) {
    created {
      id
      fulfillmentItem
    }
    hasTask {
      id
      fulfillmentItem
    }
    noStockFound {
      id
      fulfillmentItem
    }
  }
}
    `;
export type CreatePickTasksMutationFn = Apollo.MutationFunction<CreatePickTasksMutation, CreatePickTasksMutationVariables>;

/**
 * __useCreatePickTasksMutation__
 *
 * To run a mutation, you first call `useCreatePickTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePickTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPickTasksMutation, { data, loading, error }] = useCreatePickTasksMutation({
 *   variables: {
 *      fulfillmentId: // value for 'fulfillmentId'
 *   },
 * });
 */
export function useCreatePickTasksMutation(baseOptions?: Apollo.MutationHookOptions<CreatePickTasksMutation, CreatePickTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePickTasksMutation, CreatePickTasksMutationVariables>(CreatePickTasksDocument, options);
      }
export type CreatePickTasksMutationHookResult = ReturnType<typeof useCreatePickTasksMutation>;
export type CreatePickTasksMutationResult = Apollo.MutationResult<CreatePickTasksMutation>;
export type CreatePickTasksMutationOptions = Apollo.BaseMutationOptions<CreatePickTasksMutation, CreatePickTasksMutationVariables>;