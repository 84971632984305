import { Grid } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateTeamMutation } from '@/graphql/defs/components/modals/__generated__/team-create-modal.generated';
import { TeamCreateInput } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import FormValues from '@models/FormValues';
import { ITeamCreateModal } from '@models/modal';

const TeamCreateModal = () => {
  const { t } = useTranslation('components');
  const { closeModal, setLoading } = useModalContent<ITeamCreateModal>();
  const { selectedWarehouseId } = useEntityUtils();
  const { showMessage } = useSnackbar();

  const formMethods = useForm<FormValues<TeamCreateInput>>({
    defaultValues: {
      name: '',
      description: '',
    },
  });
  const { control, handleSubmit } = formMethods;
  const { notWhiteSpaceOnly } = useFormValidation();

  const [createTeam] = useCreateTeamMutation({
    onCompleted: ({ createOneTeam: { name } }) => {
      showMessage({
        type: 'success',
        message: t('modal.team.create.success', { name }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const onSubmit = (fields: TeamCreateInput) => {
    void createTeam({
      variables: {
        newTeam: {
          name: fields.name,
          description: fields.description,
          warehouseId: selectedWarehouseId,
        },
      },
    });
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: t('form.validation.requiredField', { field: t('teams.name') }),
                validate: {
                  notWhiteSpaceOnly,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  autoFocus
                  fullWidth
                  required
                  id="team-name"
                  dataTestId="team-name"
                  label={t('teams.name')}
                  {...field}
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  id="team-description"
                  dataTestId="team-description"
                  label={t('teams.descriptionOfTeam')}
                  {...field}
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  minRows={3}
                  maxRows={3}
                  multiline
                />
              )}
            />
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        <ModalButton onClick={() => closeModal()} variant="outlined" color="primary">
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          variant="contained"
          color="primary"
          data-testid="create-team-submit"
          actionType="submit"
        >
          {t('common.submit')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default TeamCreateModal;
