import { Grid } from '@mui/material';
import { useMemo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateEquipmentMutation } from '@/graphql/defs/components/modals/__generated__/add-equipment-modal.generated';
import {
  EquipmentSettingsModelFilterFragment,
  EquipmentSettingsTypeFilterFragment,
  useEquipmentSettingsTableFilterValuesQuery,
} from '@/graphql/defs/hooks/table-props/__generated__/useEquipmentSettingsDataTable.generated';
import { EquipmentCreateType, EquipmentStatus } from '@/graphql/types.generated';
import { ModalActions, ModalButton } from '@components/modal';
import { ModalContent } from '@components/modal/modal-content';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import enumKeys from '@lib/enum-keys';
import { uppercase } from '@lib/form';
import FormValues from '@models/FormValues';
import { IEquipmentAddModal } from '@models/modal';

const AddEquipmentModal = () => {
  const { t } = useTranslation('components');
  const { selectedWarehouseId } = useEntityUtils();
  const { closeModal, isPreparing, setPreparing, setLoading } =
    useModalContent<IEquipmentAddModal>();
  const { showMessage } = useSnackbar();

  const { control: equipmentTypeControl } = useForm<{ equipmentTypeId: string }>({
    defaultValues: { equipmentTypeId: '' },
  });
  const formMethods = useForm<FormValues<EquipmentCreateType>>({
    defaultValues: {
      code: '',
      label: '',
      description: '',
      equipmentModelId: '',
      status: '',
      tagId: '',
    },
  });
  const { control, handleSubmit, resetField } = formMethods;
  const { notWhiteSpaceOnly } = useFormValidation();

  const [createEquipment] = useCreateEquipmentMutation({
    onCompleted: ({ createOneEquipmentItem: { label } }) => {
      showMessage({
        type: 'success',
        message: t('modal.equipment.item.successAdd', { label }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const [equipmentTypes, setEquipmentTypes] = useState<EquipmentSettingsTypeFilterFragment[]>([]);
  const [equipmentModels, setEquipmentModels] = useState<EquipmentSettingsModelFilterFragment[]>(
    [],
  );
  useEquipmentSettingsTableFilterValuesQuery({
    onCompleted: ({
      equipmentTypes: { nodes: _equipmentTypes },
      equipmentModels: { nodes: _equipmentModels },
    }) => {
      setEquipmentTypes(_equipmentTypes);
      setEquipmentModels(_equipmentModels);
      setPreparing(false);
    },
    onError: (error) => {
      setPreparing(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const equipmentTypeId = useWatch({
    control: equipmentTypeControl,
    name: 'equipmentTypeId',
  });
  const filteredEquipmentModels = useMemo(() => {
    if (equipmentTypeId && equipmentTypeId !== '') {
      return equipmentModels.filter((model) => model.equipmentTypeId === equipmentTypeId);
    }
    return equipmentModels;
  }, [equipmentTypeId]);

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = (fields: EquipmentCreateType) => {
    void createEquipment({
      variables: {
        newEquipment: {
          code: fields?.code,
          label: fields?.label,
          description: fields?.description,
          equipmentModelId: fields?.equipmentModelId,
          status: fields?.status,
          tagId: fields?.tagId,
          warehouseId: selectedWarehouseId,
        },
      },
    });
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Controller
                name="code"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('equipment.columns.code'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    ref={null}
                    autoFocus
                    fullWidth
                    required
                    id="equipment-code"
                    label={t('equipment.columns.code')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={(e) => field.onChange(uppercase(e))}
                    dataTestId="equipment-code"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="label"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.label'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    ref={null}
                    fullWidth
                    required
                    id="equipment-label"
                    label={t('common.label')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="equipment-label"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    ref={null}
                    fullWidth
                    id="equipment-desc"
                    label={t('common.description')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="equipment-desc"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="status"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.status'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    ref={null}
                    fullWidth
                    required
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="equipment-status"
                    label={t('common.status')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="equipment-status"
                  >
                    <option disabled />
                    {enumKeys(EquipmentStatus).map((status) => {
                      return (
                        <option key={`equipmentStatus-${status}`} value={EquipmentStatus[status]}>
                          {status}
                        </option>
                      );
                    })}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="equipmentTypeId"
                control={equipmentTypeControl}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.equipmentType'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      ref={null}
                      fullWidth
                      required
                      select
                      SelectProps={{
                        native: true,
                      }}
                      id="equipment-typeId"
                      label={t('common.equipmentType')}
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        resetField('equipmentModelId');
                      }}
                      dataTestId="equipment-typeId"
                    >
                      <option disabled />
                      {equipmentTypes.map((type) => {
                        return (
                          <option key={`equipmentType-${type.code}`} value={type.id}>
                            {type.label}
                          </option>
                        );
                      })}
                    </TextField>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="equipmentModelId"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.model'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      ref={null}
                      fullWidth
                      required
                      select
                      SelectProps={{
                        native: true,
                      }}
                      id="equipment-modelId"
                      label={t('common.model')}
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      disabled={!equipmentTypeId}
                      dataTestId="equipment-modelId"
                    >
                      <option disabled />
                      {filteredEquipmentModels.map((model) => {
                        return (
                          <option key={`equipmentModel-${model.code}`} value={model.id}>
                            {model.label}
                          </option>
                        );
                      })}
                    </TextField>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="tagId"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    ref={null}
                    fullWidth
                    id="equipment-tagId"
                    label={t('common.tagId')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="equipment-tagId"
                  />
                )}
              />
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions>
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton variant="contained" color="primary" actionType="submit">
            {t('common.submit')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default AddEquipmentModal;
