/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateLicensePlateBinToBinTaskMutationVariables = Types.Exact<{
  taskInput: Types.LicensePlateBinToBinTaskMovementCreateDto;
}>;


export type CreateLicensePlateBinToBinTaskMutation = { __typename?: 'Mutation', createLicensePlateBinToBinTask: { __typename?: 'Task', id: string, code: string, status?: Types.TaskStatus | null, sourceLicensePlate?: { __typename?: 'LicensePlate', id: string, code: string } | null, destinationBin?: { __typename?: 'Bin', id: string, code: string } | null } };


export const CreateLicensePlateBinToBinTaskDocument = gql`
    mutation CreateLicensePlateBinToBinTask($taskInput: LicensePlateBinToBinTaskMovementCreateDto!) {
  createLicensePlateBinToBinTask(
    input: {licensePlateBinToBinMovement: $taskInput}
  ) {
    id
    code
    status
    sourceLicensePlate {
      id
      code
    }
    destinationBin {
      id
      code
    }
  }
}
    `;
export type CreateLicensePlateBinToBinTaskMutationFn = Apollo.MutationFunction<CreateLicensePlateBinToBinTaskMutation, CreateLicensePlateBinToBinTaskMutationVariables>;

/**
 * __useCreateLicensePlateBinToBinTaskMutation__
 *
 * To run a mutation, you first call `useCreateLicensePlateBinToBinTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLicensePlateBinToBinTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLicensePlateBinToBinTaskMutation, { data, loading, error }] = useCreateLicensePlateBinToBinTaskMutation({
 *   variables: {
 *      taskInput: // value for 'taskInput'
 *   },
 * });
 */
export function useCreateLicensePlateBinToBinTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateLicensePlateBinToBinTaskMutation, CreateLicensePlateBinToBinTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLicensePlateBinToBinTaskMutation, CreateLicensePlateBinToBinTaskMutationVariables>(CreateLicensePlateBinToBinTaskDocument, options);
      }
export type CreateLicensePlateBinToBinTaskMutationHookResult = ReturnType<typeof useCreateLicensePlateBinToBinTaskMutation>;
export type CreateLicensePlateBinToBinTaskMutationResult = Apollo.MutationResult<CreateLicensePlateBinToBinTaskMutation>;
export type CreateLicensePlateBinToBinTaskMutationOptions = Apollo.BaseMutationOptions<CreateLicensePlateBinToBinTaskMutation, CreateLicensePlateBinToBinTaskMutationVariables>;