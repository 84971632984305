/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEquipmentModelMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  updatedModel: Types.EquipmentModelUpdateInput;
}>;


export type UpdateEquipmentModelMutation = { __typename?: 'Mutation', updateOneEquipmentModel: { __typename?: 'EquipmentModel', code: string, label: string } };

export type DeleteEquipmentModelMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteEquipmentModelMutation = { __typename?: 'Mutation', deleteOneEquipmentModel: { __typename?: 'EquipmentModel', id: string, label: string } };


export const UpdateEquipmentModelDocument = gql`
    mutation UpdateEquipmentModel($id: ID!, $updatedModel: EquipmentModelUpdateInput!) {
  updateOneEquipmentModel(input: {id: $id, update: $updatedModel}) {
    code
    label
  }
}
    `;
export type UpdateEquipmentModelMutationFn = Apollo.MutationFunction<UpdateEquipmentModelMutation, UpdateEquipmentModelMutationVariables>;

/**
 * __useUpdateEquipmentModelMutation__
 *
 * To run a mutation, you first call `useUpdateEquipmentModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEquipmentModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEquipmentModelMutation, { data, loading, error }] = useUpdateEquipmentModelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updatedModel: // value for 'updatedModel'
 *   },
 * });
 */
export function useUpdateEquipmentModelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEquipmentModelMutation, UpdateEquipmentModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEquipmentModelMutation, UpdateEquipmentModelMutationVariables>(UpdateEquipmentModelDocument, options);
      }
export type UpdateEquipmentModelMutationHookResult = ReturnType<typeof useUpdateEquipmentModelMutation>;
export type UpdateEquipmentModelMutationResult = Apollo.MutationResult<UpdateEquipmentModelMutation>;
export type UpdateEquipmentModelMutationOptions = Apollo.BaseMutationOptions<UpdateEquipmentModelMutation, UpdateEquipmentModelMutationVariables>;
export const DeleteEquipmentModelDocument = gql`
    mutation DeleteEquipmentModel($id: ID!) {
  deleteOneEquipmentModel(input: {id: $id}) {
    id
    label
  }
}
    `;
export type DeleteEquipmentModelMutationFn = Apollo.MutationFunction<DeleteEquipmentModelMutation, DeleteEquipmentModelMutationVariables>;

/**
 * __useDeleteEquipmentModelMutation__
 *
 * To run a mutation, you first call `useDeleteEquipmentModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEquipmentModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEquipmentModelMutation, { data, loading, error }] = useDeleteEquipmentModelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEquipmentModelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEquipmentModelMutation, DeleteEquipmentModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEquipmentModelMutation, DeleteEquipmentModelMutationVariables>(DeleteEquipmentModelDocument, options);
      }
export type DeleteEquipmentModelMutationHookResult = ReturnType<typeof useDeleteEquipmentModelMutation>;
export type DeleteEquipmentModelMutationResult = Apollo.MutationResult<DeleteEquipmentModelMutation>;
export type DeleteEquipmentModelMutationOptions = Apollo.BaseMutationOptions<DeleteEquipmentModelMutation, DeleteEquipmentModelMutationVariables>;