import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SlottingAbcAnalysisSortFields } from '@/graphql/types.generated';
import {
  FilterOperators,
  ColumnFilter,
} from '@components/data-table/controls/filter/filter-definitions';
import useCreateSlottingAbcAnalysisColumns, {
  ABC_ANALYSIS_HOOK,
  TABCAnalysisDataType,
  TABCAnalysisFieldNames,
  TABCAnalysisFilterType,
} from '@components/data-table/hooks/shared-columns/useCreateSlottingAbcAnalysisColumns';
import useCreateDataTableColumns from '@components/data-table/hooks/useCreateDataTableColumns';
import useTriggerDataTableRefetch from '@components/data-table/hooks/useTriggerDataTableRefetch';
import { validateDataTableProps } from '@components/data-table/lib/validateDataTableProps';
import { DataTableVariants, IDataTableSort } from '@components/data-table/model/data-table';
import TableIds from '@components/data-table/model/TableIds';

const useSlottingAbcAnalysisDataTable = ({ runId, isDeployedDetails }) => {
  const { t } = useTranslation('components');

  const [refetchTrigger, triggerDataTableRefetch] = useTriggerDataTableRefetch();

  const abcAnalysisColumns = useCreateSlottingAbcAnalysisColumns();
  const columns = useCreateDataTableColumns<TABCAnalysisDataType>(abcAnalysisColumns);

  const baseFilter = useMemo<ColumnFilter<TABCAnalysisFieldNames>[]>(
    () => [
      {
        columnId: SlottingAbcAnalysisSortFields.RunId,
        operator: FilterOperators.eq,
        value: runId,
      },
      {
        columnId: SlottingAbcAnalysisSortFields.Deployed,
        operator: FilterOperators.is,
        value: isDeployedDetails,
      },
    ],
    [runId],
  );

  const defaultSorting = useMemo<IDataTableSort<TABCAnalysisFieldNames>[]>(
    () => [
      {
        id: SlottingAbcAnalysisSortFields.CurrentAbcIndicator,
        desc: false,
      },
      {
        id: SlottingAbcAnalysisSortFields.Material,
        desc: false,
      },
    ],
    [],
  );

  return {
    triggerDataTableRefetch,
    dataTableProps: validateDataTableProps<
      TABCAnalysisDataType,
      TABCAnalysisFilterType,
      TABCAnalysisFieldNames
    >({
      type: 'query',
      variant: DataTableVariants.Collapsible,
      tableId: TableIds.SlottingAbcAnalysis,
      tableHeader: t('slotting.abcDataRecommendations'),
      columns,
      queryHook: ABC_ANALYSIS_HOOK,
      baseFilter,
      defaultSorting,
      refetchTrigger,
    }),
  };
};

export default useSlottingAbcAnalysisDataTable;
