/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateProxyUrlMutationVariables = Types.Exact<{
  proxyUrl: Types.Scalars['String']['input'];
}>;


export type UpdateProxyUrlMutation = { __typename?: 'Mutation', updateProxyUrl: { __typename?: 'ProxyConfig', proxyUrl?: string | null, agents: Array<{ __typename?: 'AgentConfiguration', agentId: string, agentName?: string | null, status?: Types.AgentConfigStatus | null, version?: string | null, hosts: Array<{ __typename?: 'AgentConfigurationHost', accessPolicy: Types.AgentConfigurationAccessPolicy, hostname?: string | null, path: string, port?: string | null }> }> } };


export const UpdateProxyUrlDocument = gql`
    mutation UpdateProxyUrl($proxyUrl: String!) {
  updateProxyUrl(proxyUrl: $proxyUrl) {
    agents {
      agentId
      agentName
      hosts {
        accessPolicy
        hostname
        path
        port
      }
      status
      version
    }
    proxyUrl
  }
}
    `;
export type UpdateProxyUrlMutationFn = Apollo.MutationFunction<UpdateProxyUrlMutation, UpdateProxyUrlMutationVariables>;

/**
 * __useUpdateProxyUrlMutation__
 *
 * To run a mutation, you first call `useUpdateProxyUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProxyUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProxyUrlMutation, { data, loading, error }] = useUpdateProxyUrlMutation({
 *   variables: {
 *      proxyUrl: // value for 'proxyUrl'
 *   },
 * });
 */
export function useUpdateProxyUrlMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProxyUrlMutation, UpdateProxyUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProxyUrlMutation, UpdateProxyUrlMutationVariables>(UpdateProxyUrlDocument, options);
      }
export type UpdateProxyUrlMutationHookResult = ReturnType<typeof useUpdateProxyUrlMutation>;
export type UpdateProxyUrlMutationResult = Apollo.MutationResult<UpdateProxyUrlMutation>;
export type UpdateProxyUrlMutationOptions = Apollo.BaseMutationOptions<UpdateProxyUrlMutation, UpdateProxyUrlMutationVariables>;