import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useMergeLicensePlatesMutation } from '@/graphql/defs/components/modals/__generated__/merge-license-plates.generated';
import { useGetBaseInventoryLazyQuery } from '@/graphql/defs/shared-queries/__generated__/get-base-inventory.generated';
import { useGetDeliveryLpInventoryLazyQuery } from '@/graphql/defs/shared-queries/__generated__/get-delivery-lp-inventory.generated';
import { GetBaseInventory_defaultData } from '@/graphql/defs/shared-queries/get-base-inventory';
import { GetDeliveryLPInventory_defaultData } from '@/graphql/defs/shared-queries/get-delivery-lp-inventory';
import {
  BinType,
  InventoryBaseQueryShape,
  InventoryBaseQueryShapeSortFields,
  SortDirection,
} from '@/graphql/types.generated';
import EditableTable from '@components/editable-table/EditableTable';
import {
  createItemUID,
  useEditableLicensePlateTable,
} from '@components/editable-table/license-plate/hooks';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import usePrevious from '@hooks/usePrevious';
import { ILicensePlateMergeModal } from '@models/modal';

const MergeLicensePlatesModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal, setLoading } = useModalContent<ILicensePlateMergeModal>();

  const [shouldAutocomplete, setShouldAutocomplete] = useState(true);
  const [sourceItems, setSourceItems] = useState<InventoryBaseQueryShape[]>([]);

  const [, setSourceDeliveryLpItems] = useState<InventoryBaseQueryShape[]>([]);

  const [getInventoryItems, { loading: isLoadingInventoryItems, called: calledInventoryItems }] =
    useGetBaseInventoryLazyQuery({
      variables: {
        paging: { offset: 0, limit: 1000000 },
        filter: {
          licensePlateId: { in: modal.licensePlates.map((lp) => lp.id) },
        },
        sorting: [
          {
            field: InventoryBaseQueryShapeSortFields.LicensePlateCode,
            direction: SortDirection.Asc,
          },
          { field: InventoryBaseQueryShapeSortFields.ProductCode, direction: SortDirection.Asc },
        ],
      },
      onCompleted: (data) => {
        const inventory = (data || GetBaseInventory_defaultData())?.inventory?.nodes || [];
        setSourceItems(inventory);
      },
    });

  const [getDeliveryItems, { loading: isLoadingDeliveryItems, called: calledDeliveryItems }] =
    useGetDeliveryLpInventoryLazyQuery({
      variables: {
        paging: { offset: 0, limit: 1000000 },
        filter: {
          licensePlateId: { in: modal.licensePlates.map((lp) => lp.id) },
          binType: { eq: BinType.Planned },
        },
        sorting: [
          {
            field: InventoryBaseQueryShapeSortFields.LicensePlateCode,
            direction: SortDirection.Asc,
          },
          { field: InventoryBaseQueryShapeSortFields.ProductCode, direction: SortDirection.Asc },
        ],
      },
      onCompleted: (data) => {
        const deliveryInventory =
          (data || GetDeliveryLPInventory_defaultData)?.deliveryItems?.nodes || [];
        setSourceDeliveryLpItems(deliveryInventory);
      },
    });

  useEffect(() => {
    modal.source !== 'inventory' ? getDeliveryItems() : getInventoryItems();
  }, []);

  const [destinationLicensePlate, setDestinationLicensePlate] = useState(modal.licensePlates[0]);

  const prevSourceItems: InventoryBaseQueryShape[] = usePrevious(sourceItems);
  const prevDestLicensePlate = usePrevious(destinationLicensePlate.id);
  const hasSourceItemsChanged = () => {
    if (!prevSourceItems) return true;
    // Stringify may unreliably sort objects, so we turn our array of objects into array of uids
    const uid = (x: InventoryBaseQueryShape) =>
      `${x.binId}-${x.licensePlateId}-${x.productId}-${x.stockStatusTypeId}-${x.lotId}-${x.quantity}`;
    const uidPrev = prevSourceItems.map(uid);
    const uidCurr = sourceItems.map(uid);
    return JSON.stringify(uidPrev) !== JSON.stringify(uidCurr);
  };
  const filteredItems = useMemo(() => {
    if (
      sourceItems &&
      (hasSourceItemsChanged() || prevDestLicensePlate !== destinationLicensePlate.id)
    ) {
      return sourceItems.filter((item) => item.licensePlateId !== destinationLicensePlate.id);
    }
  }, [sourceItems, destinationLicensePlate.id]);

  const { createProductInput, licensePlateColumns, productItems, productQuantitiesUoM } =
    useEditableLicensePlateTable(filteredItems || []);

  const formMethods = useForm();
  useEffect(() => {
    formMethods.reset({
      ...productQuantitiesUoM,
    });
  }, [productQuantitiesUoM]);

  const [mergeLicensePlates, { loading: isMergingLicensePlate }] = useMergeLicensePlatesMutation({
    onCompleted: ({ mergeLicensePlates: { code } }) => {
      showMessage({
        type: 'success',
        message: !shouldAutocomplete
          ? t('licensePlateActions.mergeSuccess', { code })
          : t('licensePlateActions.mergeSuccessAutocomplete', { code }),
      });

      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onSubmit = (quantities) => {
    const binId = modal.binId;
    const licensePlateId = destinationLicensePlate.id;
    void mergeLicensePlates({
      variables: {
        binId,
        licensePlateId,
        products: createProductInput(quantities),
        autocomplete: shouldAutocomplete,
      },
    });
  };

  return (
    <ModalForm onSubmit={formMethods.handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <Typography mb={4}>{t('licensePlateActions.addToText')}</Typography>
        <Box sx={{ width: '250px' }}>
          <Autocomplete
            freeSolo={false}
            multiple={false}
            id="destinationLicensePlate"
            data-testid="destinationLicensePlate-dropdown"
            disableClearable
            options={modal.licensePlates}
            getOptionLabel={(option) => option.code}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            defaultValue={modal.licensePlates[0]}
            onChange={(event, item, reason) => {
              if (reason === 'selectOption') {
                setDestinationLicensePlate(item);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('common.destinationLicensePlate')}
                required
                autoFocus
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                dataTestId="destinationLicensePlate-input"
              />
            )}
          />
        </Box>
        {productItems &&
          modal.licensePlates
            .filter((lp) => lp.id !== destinationLicensePlate.id)
            .map((lp) => {
              const lpProducts = productItems.filter((row) => row.item.licensePlateId === lp.id);
              const tableLabel =
                (modal.source !== 'delivery' ? `${modal.binCode} - ` : '') +
                `${t('common.licensePlate')}: ${lp.code}`;
              return (
                <Box
                  data-testid={`${lp.id}-editableTable`}
                  key={`${lp.code}-products`}
                  sx={{ margin: '15px 0 0' }}
                >
                  <EditableTable
                    label={tableLabel}
                    columns={licensePlateColumns}
                    createUID={createItemUID}
                    data={lpProducts}
                  />
                </Box>
              );
            })}
      </ModalContent>
      <ModalActions>
        {modal.forceAutocomplete ? null : (
          <Box sx={{ marginRight: 'auto' }}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  onChange={(e) => {
                    setShouldAutocomplete(e?.target?.checked || false);
                  }}
                />
              }
              label={t('modal.task.markAsComplete')}
            />
          </Box>
        )}
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          data-testid="mergeLicensePlate_submit"
          variant="contained"
          color="primary"
          isDisabled={
            (!calledInventoryItems && !calledDeliveryItems) ||
            isLoadingInventoryItems ||
            isLoadingDeliveryItems ||
            isMergingLicensePlate
          }
          actionType="submit"
        >
          {t('common.merge')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default MergeLicensePlatesModal;
