/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DataTableBusinessPartnerEnumFragment = { __typename?: 'BusinessPartner', id: string, name?: string | null, code: string };

export type DataTableStockStatusTypeEnumFragment = { __typename?: 'ViewStockStatus', id?: string | null, code?: string | null, label?: string | null };

export type DataTableTaskTypeEnumFragment = { __typename?: 'TaskType', id: string, code: string, label: string, description?: string | null };

export type DataTableTeamEnumFragment = { __typename?: 'Team', id: string, name: string };

export type DataTableUomEnumFragment = { __typename?: 'UnitOfMeasureProductConversion', id: string, code: string };

export type FetchDataTableEnumListsQueryVariables = Types.Exact<{
  fetchBusinessPartnerList?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  fetchStockStatusTypeList?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  fetchTaskTypesList?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  fetchTeamsList?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  fetchUoMList?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  warehouseId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type FetchDataTableEnumListsQuery = { __typename?: 'Query', businessPartnerEnumList: { __typename?: 'BusinessPartnerOffsetConnection', nodes: Array<{ __typename?: 'BusinessPartner', id: string, name?: string | null, code: string }> }, stockStatusTypeEnumList: { __typename?: 'ViewStockStatusOffsetConnection', nodes: Array<{ __typename?: 'ViewStockStatus', id?: string | null, code?: string | null, label?: string | null }> }, taskTypeEnumList: { __typename?: 'TaskTypeOffsetConnection', nodes: Array<{ __typename?: 'TaskType', id: string, code: string, label: string, description?: string | null }> }, teamEnumList: { __typename?: 'TeamOffsetConnection', nodes: Array<{ __typename?: 'Team', id: string, name: string }> }, uomEnumList: { __typename?: 'UnitOfMeasureProductConversionOffsetConnection', nodes: Array<{ __typename?: 'UnitOfMeasureProductConversion', id: string, code: string }> } };

export const DataTableBusinessPartnerEnumFragmentDoc = gql`
    fragment DataTableBusinessPartnerEnum on BusinessPartner {
  id
  name
  code
}
    `;
export const DataTableStockStatusTypeEnumFragmentDoc = gql`
    fragment DataTableStockStatusTypeEnum on ViewStockStatus {
  id
  code
  label
}
    `;
export const DataTableTaskTypeEnumFragmentDoc = gql`
    fragment DataTableTaskTypeEnum on TaskType {
  id
  code
  label
  description
}
    `;
export const DataTableTeamEnumFragmentDoc = gql`
    fragment DataTableTeamEnum on Team {
  id
  name
}
    `;
export const DataTableUomEnumFragmentDoc = gql`
    fragment DataTableUomEnum on UnitOfMeasureProductConversion {
  id
  code
}
    `;
export const FetchDataTableEnumListsDocument = gql`
    query FetchDataTableEnumLists($fetchBusinessPartnerList: Boolean = false, $fetchStockStatusTypeList: Boolean = false, $fetchTaskTypesList: Boolean = false, $fetchTeamsList: Boolean = false, $fetchUoMList: Boolean = false, $warehouseId: ID = "") {
  businessPartnerEnumList: businessPartners(
    sorting: [{field: name, direction: ASC}]
  ) @include(if: $fetchBusinessPartnerList) {
    nodes {
      ...DataTableBusinessPartnerEnum
    }
  }
  stockStatusTypeEnumList: viewStockStatuses(
    sorting: [{field: label, direction: ASC}]
  ) @include(if: $fetchStockStatusTypeList) {
    nodes {
      ...DataTableStockStatusTypeEnum
    }
  }
  taskTypeEnumList: taskTypes(
    filter: {code: {notIn: ["TSK-05", "TSK-09"]}}
    sorting: [{field: label, direction: ASC}]
  ) @include(if: $fetchTaskTypesList) {
    nodes {
      ...DataTableTaskTypeEnum
    }
  }
  teamEnumList: teams(
    filter: {warehouseId: {eq: $warehouseId}}
    sorting: [{field: name, direction: ASC}]
  ) @include(if: $fetchTeamsList) {
    nodes {
      ...DataTableTeamEnum
    }
  }
  uomEnumList: unitOfMeasureProductConversions(
    sorting: [{field: code, direction: ASC}]
  ) @include(if: $fetchUoMList) {
    nodes {
      ...DataTableUomEnum
    }
  }
}
    ${DataTableBusinessPartnerEnumFragmentDoc}
${DataTableStockStatusTypeEnumFragmentDoc}
${DataTableTaskTypeEnumFragmentDoc}
${DataTableTeamEnumFragmentDoc}
${DataTableUomEnumFragmentDoc}`;

/**
 * __useFetchDataTableEnumListsQuery__
 *
 * To run a query within a React component, call `useFetchDataTableEnumListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDataTableEnumListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDataTableEnumListsQuery({
 *   variables: {
 *      fetchBusinessPartnerList: // value for 'fetchBusinessPartnerList'
 *      fetchStockStatusTypeList: // value for 'fetchStockStatusTypeList'
 *      fetchTaskTypesList: // value for 'fetchTaskTypesList'
 *      fetchTeamsList: // value for 'fetchTeamsList'
 *      fetchUoMList: // value for 'fetchUoMList'
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useFetchDataTableEnumListsQuery(baseOptions?: Apollo.QueryHookOptions<FetchDataTableEnumListsQuery, FetchDataTableEnumListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchDataTableEnumListsQuery, FetchDataTableEnumListsQueryVariables>(FetchDataTableEnumListsDocument, options);
      }
export function useFetchDataTableEnumListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchDataTableEnumListsQuery, FetchDataTableEnumListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchDataTableEnumListsQuery, FetchDataTableEnumListsQueryVariables>(FetchDataTableEnumListsDocument, options);
        }
export type FetchDataTableEnumListsQueryHookResult = ReturnType<typeof useFetchDataTableEnumListsQuery>;
export type FetchDataTableEnumListsLazyQueryHookResult = ReturnType<typeof useFetchDataTableEnumListsLazyQuery>;
export type FetchDataTableEnumListsQueryResult = Apollo.QueryResult<FetchDataTableEnumListsQuery, FetchDataTableEnumListsQueryVariables>;