/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FulfillmentItemForPickFragment = { __typename?: 'FulfillmentItemDetails', id: string, fulfillmentItem: string, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, productId: string, productCode: string, productDescription?: string | null, quantity: string, lotId?: string | null, lotCode?: string | null, stockStatusId?: string | null, stockStatusCode?: string | null, sourceBinId?: string | null, sourceBinCode?: string | null, destinationBinId?: string | null, destinationBinCode?: string | null };

export type GetFulfillmentItemsForPickQueryVariables = Types.Exact<{
  fulfillmentId: Types.Scalars['ID']['input'];
}>;


export type GetFulfillmentItemsForPickQuery = { __typename?: 'Query', fulfillmentItemsToPick: Array<{ __typename?: 'FulfillmentItemDetails', id: string, fulfillmentItem: string, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, productId: string, productCode: string, productDescription?: string | null, quantity: string, lotId?: string | null, lotCode?: string | null, stockStatusId?: string | null, stockStatusCode?: string | null, sourceBinId?: string | null, sourceBinCode?: string | null, destinationBinId?: string | null, destinationBinCode?: string | null }> };

export type CreatePickToStagingTasksMutationVariables = Types.Exact<{
  fulfillmentId: Types.Scalars['ID']['input'];
}>;


export type CreatePickToStagingTasksMutation = { __typename?: 'Mutation', createPickTasksForFulfillment: { __typename?: 'TaskCreatePickToStagingResourceDto', created: Array<{ __typename?: 'FulfillmentItem', id: string, fulfillmentItem: string }>, hasTask: Array<{ __typename?: 'FulfillmentItem', id: string, fulfillmentItem: string }>, noStockFound: Array<{ __typename?: 'FulfillmentItem', id: string, fulfillmentItem: string }> } };

export const FulfillmentItemForPickFragmentDoc = gql`
    fragment FulfillmentItemForPick on FulfillmentItemDetails {
  id
  fulfillmentItem
  licensePlateId
  licensePlateCode
  licensePlateDescription
  productId
  productCode
  productDescription
  quantity
  lotId
  lotCode
  stockStatusId
  stockStatusCode
  sourceBinId
  sourceBinCode
  destinationBinId
  destinationBinCode
}
    `;
export const GetFulfillmentItemsForPickDocument = gql`
    query GetFulfillmentItemsForPick($fulfillmentId: ID!) {
  fulfillmentItemsToPick(id: $fulfillmentId) {
    ...FulfillmentItemForPick
  }
}
    ${FulfillmentItemForPickFragmentDoc}`;

/**
 * __useGetFulfillmentItemsForPickQuery__
 *
 * To run a query within a React component, call `useGetFulfillmentItemsForPickQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFulfillmentItemsForPickQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFulfillmentItemsForPickQuery({
 *   variables: {
 *      fulfillmentId: // value for 'fulfillmentId'
 *   },
 * });
 */
export function useGetFulfillmentItemsForPickQuery(baseOptions: Apollo.QueryHookOptions<GetFulfillmentItemsForPickQuery, GetFulfillmentItemsForPickQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFulfillmentItemsForPickQuery, GetFulfillmentItemsForPickQueryVariables>(GetFulfillmentItemsForPickDocument, options);
      }
export function useGetFulfillmentItemsForPickLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFulfillmentItemsForPickQuery, GetFulfillmentItemsForPickQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFulfillmentItemsForPickQuery, GetFulfillmentItemsForPickQueryVariables>(GetFulfillmentItemsForPickDocument, options);
        }
export type GetFulfillmentItemsForPickQueryHookResult = ReturnType<typeof useGetFulfillmentItemsForPickQuery>;
export type GetFulfillmentItemsForPickLazyQueryHookResult = ReturnType<typeof useGetFulfillmentItemsForPickLazyQuery>;
export type GetFulfillmentItemsForPickQueryResult = Apollo.QueryResult<GetFulfillmentItemsForPickQuery, GetFulfillmentItemsForPickQueryVariables>;
export const CreatePickToStagingTasksDocument = gql`
    mutation CreatePickToStagingTasks($fulfillmentId: ID!) {
  createPickTasksForFulfillment(fulfillmentId: $fulfillmentId) {
    created {
      id
      fulfillmentItem
    }
    hasTask {
      id
      fulfillmentItem
    }
    noStockFound {
      id
      fulfillmentItem
    }
  }
}
    `;
export type CreatePickToStagingTasksMutationFn = Apollo.MutationFunction<CreatePickToStagingTasksMutation, CreatePickToStagingTasksMutationVariables>;

/**
 * __useCreatePickToStagingTasksMutation__
 *
 * To run a mutation, you first call `useCreatePickToStagingTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePickToStagingTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPickToStagingTasksMutation, { data, loading, error }] = useCreatePickToStagingTasksMutation({
 *   variables: {
 *      fulfillmentId: // value for 'fulfillmentId'
 *   },
 * });
 */
export function useCreatePickToStagingTasksMutation(baseOptions?: Apollo.MutationHookOptions<CreatePickToStagingTasksMutation, CreatePickToStagingTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePickToStagingTasksMutation, CreatePickToStagingTasksMutationVariables>(CreatePickToStagingTasksDocument, options);
      }
export type CreatePickToStagingTasksMutationHookResult = ReturnType<typeof useCreatePickToStagingTasksMutation>;
export type CreatePickToStagingTasksMutationResult = Apollo.MutationResult<CreatePickToStagingTasksMutation>;
export type CreatePickToStagingTasksMutationOptions = Apollo.BaseMutationOptions<CreatePickToStagingTasksMutation, CreatePickToStagingTasksMutationVariables>;