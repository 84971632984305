import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useRulesetsTableLazyQuery } from '@/graphql/defs/hooks/shared-columns/__generated__/useCreateRulesetColumns.generated';
import { ColumnTypes } from '@components/data-table/controls/filter/filter-definitions';
import { TColumnFactory } from '@components/data-table/hooks/useCreateDataTableColumns';
import filterDataTableColumnDefs from '@components/data-table/lib/filterDataTableColumnDefs';
import {
  TExtractLazyHookDataType,
  TExtractLazyHookFetchFilterType,
  TExtractLazyHookFieldNames,
} from '@components/data-table/model/extract-query-hook-types';
import LinkCell from '@components/data-table/table/cells/LinkCell';
import { SLOTTING_RULESET } from '@constants/routes';
import { useDateTime } from '@context/date-time';

export const RULE_SETS_HOOK = useRulesetsTableLazyQuery;
export type TRulesetsDataType = TExtractLazyHookDataType<typeof RULE_SETS_HOOK>;
export type TRulesetsFilterType = TExtractLazyHookFetchFilterType<typeof RULE_SETS_HOOK>;
export type TRulesetsFieldNames = TExtractLazyHookFieldNames<typeof RULE_SETS_HOOK>;

interface ICreatedRulesetColumns {
  removeColumns?: (keyof TRulesetsDataType)[];
}
const useCreateRulesetColumns = ({ removeColumns = [] }: ICreatedRulesetColumns = {}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'rulesets' });
  const { displayDateTime } = useDateTime();

  const createRulesetColumns = useCallback<TColumnFactory<TRulesetsDataType>>(
    (columnHelper) => {
      const columns = [
        columnHelper.accessor('name', {
          header: t('columns.ruleset'),
          cell: ({ row, getValue }) => (
            <LinkCell
              href={`${SLOTTING_RULESET}/${row.original.id}`}
              text={getValue()}
              dataTestId="ruleset-name"
            />
          ),
          meta: {
            columnType: ColumnTypes.string,
            enableExport: false,
          },
        }),
        columnHelper.accessor('lastUpdated', {
          header: t('columns.lastUpdated'),
          cell: ({ getValue }) => displayDateTime({ date: getValue() }),
          meta: {
            columnType: ColumnTypes.dateTime,
            exportFormatter: (value) => displayDateTime({ date: value }),
          },
        }),
        columnHelper.accessor('runCount', {
          header: t('columns.runCount'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.number,
          },
        }),
        columnHelper.accessor('createdBy', {
          header: t('columns.createdBy'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
      ];

      return columns.filter(filterDataTableColumnDefs<TRulesetsDataType>(removeColumns));
    },
    [...removeColumns],
  );

  return createRulesetColumns;
};

export default useCreateRulesetColumns;
