import { Box } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdateUomGlossaryMutation } from '@/graphql/defs/components/modals/__generated__/update-uom-glossary-modal.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import FormValues from '@models/FormValues';
import { IUnitOfMeasureGlossaryModal } from '@models/modal';
import Typography from '@styled/Typography';

const UpdateUomGlossaryModal = () => {
  const { t: tC } = useTranslation('components');
  const { modal, closeModal, setLoading } = useModalContent<IUnitOfMeasureGlossaryModal>();
  const { showMessage } = useSnackbar();
  const { notWhiteSpaceOnly } = useFormValidation();

  const formMethods = useForm<FormValues<{ label: string; description: string }>>({
    defaultValues: {
      label: modal.unitOfMeasure.label || '',
      description: modal.unitOfMeasure.description || '',
    },
  });
  const { control, handleSubmit } = formMethods;

  const [updateUom] = useUpdateUomGlossaryMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: tC('modal.uomGlossary.success', { uomCode: modal.unitOfMeasure.code }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({ type: 'error', message: error.message });
    },
  });

  const onSubmit = (updateInput: { label: string; description: string }) => {
    void updateUom({
      variables: {
        id: modal.unitOfMeasure.id,
        unitOfMeasure: { ...updateInput, companyId: modal.unitOfMeasure.companyId },
      },
    });
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <Typography sx={{ marginBottom: '10px' }}>{tC('modal.uomGlossary.subtitle')}</Typography>
        <Box display="flex" gap="20px" sx={{ marginBottom: '20px' }}>
          <TextField
            fullWidth
            id="uom-code"
            label={tC('common.code')}
            variant="standard"
            value={modal.unitOfMeasure.code}
            disabled
            dataTestId="uom-code"
          />
          <Controller
            name="label"
            control={control}
            rules={{
              required: tC('form.validation.requiredField', { field: tC('common.label') }),
              validate: {
                notWhiteSpaceOnly,
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                autoFocus
                fullWidth
                required
                id="uom-label"
                label={tC('common.label')}
                variant="standard"
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                {...field}
                dataTestId="uom-label"
              />
            )}
          />
        </Box>
        <Controller
          name="description"
          control={control}
          rules={{
            required: tC('form.validation.requiredField', { field: tC('common.description') }),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              required
              id="uom-description"
              label={tC('common.description')}
              variant="standard"
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              dataTestId="uom-description"
            />
          )}
        />
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {tC('common.cancel')}
        </ModalButton>
        <ModalButton
          data-testid="updateUoMGlossary_submit"
          variant="contained"
          color="primary"
          actionType="submit"
        >
          {tC('common.save')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default UpdateUomGlossaryModal;
