/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeliveryItemsToUnloadFragment = { __typename?: 'DeliveryItemDetails', id: string, deliveryItem: string, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, productId: string, productCode: string, productDescription?: string | null, quantity: string, lotId?: string | null, lotCode?: string | null, stockStatusId?: string | null, stockStatusCode?: string | null, sourceBinId?: string | null, sourceBinCode?: string | null, destinationBinId?: string | null, destinationBinCode?: string | null };

export type GetDeliveryItemsForUnloadQueryVariables = Types.Exact<{
  deliveryId: Types.Scalars['ID']['input'];
}>;


export type GetDeliveryItemsForUnloadQuery = { __typename?: 'Query', deliveryItemsToUnload: Array<{ __typename?: 'DeliveryItemDetails', id: string, deliveryItem: string, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, productId: string, productCode: string, productDescription?: string | null, quantity: string, lotId?: string | null, lotCode?: string | null, stockStatusId?: string | null, stockStatusCode?: string | null, sourceBinId?: string | null, sourceBinCode?: string | null, destinationBinId?: string | null, destinationBinCode?: string | null }> };

export type CreateUnloadTasksMutationVariables = Types.Exact<{
  deliveryId: Types.Scalars['ID']['input'];
}>;


export type CreateUnloadTasksMutation = { __typename?: 'Mutation', createUnloadTasksForFFDelivery: Array<{ __typename?: 'Task', id: string, code: string }> };

export const DeliveryItemsToUnloadFragmentDoc = gql`
    fragment DeliveryItemsToUnload on DeliveryItemDetails {
  id
  deliveryItem
  licensePlateId
  licensePlateCode
  licensePlateDescription
  productId
  productCode
  productDescription
  quantity
  lotId
  lotCode
  stockStatusId
  stockStatusCode
  sourceBinId
  sourceBinCode
  destinationBinId
  destinationBinCode
}
    `;
export const GetDeliveryItemsForUnloadDocument = gql`
    query GetDeliveryItemsForUnload($deliveryId: ID!) {
  deliveryItemsToUnload(id: $deliveryId) {
    ...DeliveryItemsToUnload
  }
}
    ${DeliveryItemsToUnloadFragmentDoc}`;

/**
 * __useGetDeliveryItemsForUnloadQuery__
 *
 * To run a query within a React component, call `useGetDeliveryItemsForUnloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryItemsForUnloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryItemsForUnloadQuery({
 *   variables: {
 *      deliveryId: // value for 'deliveryId'
 *   },
 * });
 */
export function useGetDeliveryItemsForUnloadQuery(baseOptions: Apollo.QueryHookOptions<GetDeliveryItemsForUnloadQuery, GetDeliveryItemsForUnloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryItemsForUnloadQuery, GetDeliveryItemsForUnloadQueryVariables>(GetDeliveryItemsForUnloadDocument, options);
      }
export function useGetDeliveryItemsForUnloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryItemsForUnloadQuery, GetDeliveryItemsForUnloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryItemsForUnloadQuery, GetDeliveryItemsForUnloadQueryVariables>(GetDeliveryItemsForUnloadDocument, options);
        }
export type GetDeliveryItemsForUnloadQueryHookResult = ReturnType<typeof useGetDeliveryItemsForUnloadQuery>;
export type GetDeliveryItemsForUnloadLazyQueryHookResult = ReturnType<typeof useGetDeliveryItemsForUnloadLazyQuery>;
export type GetDeliveryItemsForUnloadQueryResult = Apollo.QueryResult<GetDeliveryItemsForUnloadQuery, GetDeliveryItemsForUnloadQueryVariables>;
export const CreateUnloadTasksDocument = gql`
    mutation CreateUnloadTasks($deliveryId: ID!) {
  createUnloadTasksForFFDelivery(deliveryId: $deliveryId) {
    id
    code
  }
}
    `;
export type CreateUnloadTasksMutationFn = Apollo.MutationFunction<CreateUnloadTasksMutation, CreateUnloadTasksMutationVariables>;

/**
 * __useCreateUnloadTasksMutation__
 *
 * To run a mutation, you first call `useCreateUnloadTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUnloadTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUnloadTasksMutation, { data, loading, error }] = useCreateUnloadTasksMutation({
 *   variables: {
 *      deliveryId: // value for 'deliveryId'
 *   },
 * });
 */
export function useCreateUnloadTasksMutation(baseOptions?: Apollo.MutationHookOptions<CreateUnloadTasksMutation, CreateUnloadTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUnloadTasksMutation, CreateUnloadTasksMutationVariables>(CreateUnloadTasksDocument, options);
      }
export type CreateUnloadTasksMutationHookResult = ReturnType<typeof useCreateUnloadTasksMutation>;
export type CreateUnloadTasksMutationResult = Apollo.MutationResult<CreateUnloadTasksMutation>;
export type CreateUnloadTasksMutationOptions = Apollo.BaseMutationOptions<CreateUnloadTasksMutation, CreateUnloadTasksMutationVariables>;