import { Box, Fade } from '@mui/material';
import {
  createContext,
  Dispatch,
  memo,
  MutableRefObject,
  ReactElement,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import LoadingPage from '@pages/loading';

const MemoizedFadeLoadingPage = memo(
  ({ suppressedAppLoading }: { suppressedAppLoading: boolean }) => (
    <Box>
      <Fade in={suppressedAppLoading} appear={false} style={{ transitionDuration: '200ms' }}>
        {/* Box is needed to make Fade happy */}
        <Box>
          <LoadingPage />
        </Box>
      </Fade>
    </Box>
  ),
);

interface IAppLoadingStateContext {
  isAppInitialized: boolean;
  isAppLoading: boolean;
}
export const AppLoadingStateContext = createContext<IAppLoadingStateContext>(null);

export type SetAppLoading = Dispatch<SetStateAction<boolean>>;
export type SetAppInitialized = Dispatch<SetStateAction<boolean>>;
interface IAppLoadingToggleProviderProps {
  setAppLoadingRef: MutableRefObject<SetAppLoading>;
  setAppInitializedRef: MutableRefObject<SetAppInitialized>;
  children: ReactElement | ReactElement[];
}
const AppLoadingStateProvider = ({
  setAppLoadingRef,
  setAppInitializedRef,
  children,
}: IAppLoadingToggleProviderProps) => {
  const [isAppInitialized, setAppInitialized] = useState(false);
  const [isAppLoading, setAppLoading] = useState(true);

  const suppressedTimer = useRef(null);
  const [suppressedAppLoading, setSuppressedAppLoading] = useState(true);
  useEffect(() => {
    clearTimeout(suppressedTimer.current);

    if (isAppLoading) {
      setSuppressedAppLoading(true);
    } else {
      suppressedTimer.current = setTimeout(() => {
        setSuppressedAppLoading(false);
      }, 500);
    }

    return () => clearTimeout(suppressedTimer.current);
  }, [isAppLoading]);

  useEffect(() => {
    setAppLoadingRef.current = setAppLoading;
    setAppInitializedRef.current = setAppInitialized;
  }, [setAppInitialized, setAppLoading]);

  return (
    <>
      <MemoizedFadeLoadingPage suppressedAppLoading={suppressedAppLoading} />
      <AppLoadingStateContext.Provider
        value={{
          isAppInitialized,
          isAppLoading: suppressedAppLoading,
        }}
      >
        {children}
      </AppLoadingStateContext.Provider>
    </>
  );
};

export default AppLoadingStateProvider;

export const useAppLoadingState = () => {
  const ctx = useContext(AppLoadingStateContext);
  if (!ctx) {
    throw new Error('useAppLoadingState must be used within AppLoadingStateProvider');
  }
  return ctx;
};
