/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WarehouseUomFragment = { __typename?: 'WarehousePreferredUnitOfMeasure', id: string, productId?: string | null, unitOfMeasureId: string, warehouseId?: string | null, unitOfMeasure?: { __typename?: 'UnitOfMeasureProductConversion', id: string, code: string } | null };

export type SetWarehouseUomMutationVariables = Types.Exact<{
  productWHUomId: Types.Scalars['ID']['input'];
  uomId: Types.Scalars['ID']['input'];
}>;


export type SetWarehouseUomMutation = { __typename?: 'Mutation', updateOneWarehousePreferredUnitOfMeasure: { __typename?: 'WarehousePreferredUnitOfMeasure', id: string, productId?: string | null, unitOfMeasureId: string, warehouseId?: string | null, unitOfMeasure?: { __typename?: 'UnitOfMeasureProductConversion', id: string, code: string } | null } };

export const WarehouseUomFragmentDoc = gql`
    fragment WarehouseUom on WarehousePreferredUnitOfMeasure {
  id
  productId
  unitOfMeasureId
  unitOfMeasure {
    id
    code
  }
  warehouseId
}
    `;
export const SetWarehouseUomDocument = gql`
    mutation SetWarehouseUom($productWHUomId: ID!, $uomId: ID!) {
  updateOneWarehousePreferredUnitOfMeasure(
    input: {id: $productWHUomId, update: {unitOfMeasureId: $uomId}}
  ) {
    ...WarehouseUom
  }
}
    ${WarehouseUomFragmentDoc}`;
export type SetWarehouseUomMutationFn = Apollo.MutationFunction<SetWarehouseUomMutation, SetWarehouseUomMutationVariables>;

/**
 * __useSetWarehouseUomMutation__
 *
 * To run a mutation, you first call `useSetWarehouseUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWarehouseUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWarehouseUomMutation, { data, loading, error }] = useSetWarehouseUomMutation({
 *   variables: {
 *      productWHUomId: // value for 'productWHUomId'
 *      uomId: // value for 'uomId'
 *   },
 * });
 */
export function useSetWarehouseUomMutation(baseOptions?: Apollo.MutationHookOptions<SetWarehouseUomMutation, SetWarehouseUomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetWarehouseUomMutation, SetWarehouseUomMutationVariables>(SetWarehouseUomDocument, options);
      }
export type SetWarehouseUomMutationHookResult = ReturnType<typeof useSetWarehouseUomMutation>;
export type SetWarehouseUomMutationResult = Apollo.MutationResult<SetWarehouseUomMutation>;
export type SetWarehouseUomMutationOptions = Apollo.BaseMutationOptions<SetWarehouseUomMutation, SetWarehouseUomMutationVariables>;