import { gql } from '@apollo/client';

import { ViewFulfillmentItemsQuery } from '@/graphql/defs/components/modals/__generated__/pick-task-create-modal.generated';

gql`
  fragment ViewFulfillmentItem on ViewFulfillmentItem {
    id
    availability
    dateAvailable
    deliveryId
    fulfillmentCode
    erpLastChanged
    pickStatus
    grossWeight
    weightUOMId
    weightUOMCode
    weightUOMLabel
    item
    loadStatus
    lotCode
    lotId
    netWeight
    productId
    productCode
    productDescription
    licensePlateId
    licensePlateCode
    licensePlateDescription
    quantity
    salesOrderCode
    salesOrderItem
    stockStatus
    unitOfMeasure
    unitOfMeasureId
    volume
    volumeUOMId
    volumeUOMCode
    volumeUOMLabel
    updatedAt
    warehouseId
  }

  query ViewFulfillmentItems(
    $filter: ViewFulfillmentItemFilter
    $paging: OffsetPaging
    $sorting: [ViewFulfillmentItemSort!]
  ) {
    viewFulfillmentItems(filter: $filter, paging: $paging, sorting: $sorting) {
      fulfillmentItems: nodes {
        ...ViewFulfillmentItem
      }
      totalCount
    }
  }

  mutation CreatePickTasks($fulfillmentId: ID!) {
    createPickTasks: createPickTasksForFulfillment(fulfillmentId: $fulfillmentId) {
      created {
        id
        fulfillmentItem
      }
      hasTask {
        id
        fulfillmentItem
      }
      noStockFound {
        id
        fulfillmentItem
      }
    }
  }
`;

export const ViewFulfillmentItems_defaultData: ViewFulfillmentItemsQuery = {
  __typename: 'Query',
  viewFulfillmentItems: {
    fulfillmentItems: [],
    totalCount: 0,
  },
};
