import { Box } from '@mui/material';
import React from 'react';

interface IProgressBar {
  percent: number;
}
const ProgressBar = ({ percent }: IProgressBar) => {
  if (percent === undefined) return null;
  const determineColor = (num: number) => {
    if (num >= 75) {
      return '#6FCF97'; // green
    } else if (num >= 25) {
      return '#F2C94C'; // yellow
    } else {
      return '#eb5757B3'; // red
    }
  };

  return (
    <Box
      position="relative"
      width="100%"
      height="23px"
      borderRadius="2px"
      border="1px solid #68746FB3"
      boxSizing="border-box"
    >
      <Box
        position="absolute"
        width={`${percent}%`}
        height="100%"
        bgcolor={determineColor(percent)}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="absolute"
        top="0"
        bottom="0"
        left="0"
        right="0"
      >
        {`${percent}%`}
      </Box>
    </Box>
  );
};

export default ProgressBar;
