import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FullContactInfoFragment } from '@/graphql/defs/components/modals/__generated__/contact-info-modal.generated';
import { UpdateContactInfoDto } from '@/graphql/types.generated';
import CountriesDropdown from '@components/form-util/field-blocks/countries-dropdown';
import StatesDropdown from '@components/form-util/field-blocks/states-dropdown';
import TextField from '@components/text-field';
import { useFormValidation } from '@hooks/form/validators';
import decodeHtmlEntities from '@lib/decode-html-entities';
import { isUS } from '@lib/utils';

export interface IFieldBlockContactInfo {
  contactInfo?: FullContactInfoFragment;
  control: Control<UpdateContactInfoDto>;
  setValue: any;
  focusOnLoad?: boolean;
  isUpdate?: boolean;
}
export const FieldBlockContactInfo: React.FC<IFieldBlockContactInfo> = ({
  contactInfo,
  control,
  setValue,
  focusOnLoad,
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'field-blocks.contactInformation' });
  const { t: tC } = useTranslation('components');
  const { notWhiteSpaceOnly } = useFormValidation();

  const [statesDropdown, setStatesDropdown] = useState(isUS(contactInfo?.country));

  return (
    <>
      <Grid item xs={6}>
        <Controller
          name="email"
          control={control}
          rules={{
            required: tC('form.validation.requiredField', { field: t('email') }),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              required
              id="company-email"
              dataTestId="company-email"
              label={t('email')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              autoFocus={focusOnLoad}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="phone"
          control={control}
          rules={{
            required: tC('form.validation.requiredField', { field: t('phone') }),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              required
              id="company-phone"
              dataTestId="company-phone"
              label={t('phone')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="street1"
          control={control}
          rules={{
            required: tC('form.validation.requiredField', { field: t('street1') }),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              required
              id="company-street1"
              dataTestId="company-street1"
              label={t('street1')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="street2"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              id="company-street2"
              dataTestId="company-street2"
              label={t('street2')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="country"
          control={control}
          rules={{
            required: tC('form.validation.requiredField', { field: t('country') }),
          }}
          render={({ field, fieldState }) => (
            <CountriesDropdown
              required={true}
              field={field}
              fieldState={fieldState}
              setValue={setValue}
              statesDropdown={statesDropdown}
              setStatesDropdown={setStatesDropdown}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="city"
          control={control}
          rules={{
            required: tC('form.validation.requiredField', { field: t('city') }),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              required
              id="company-city"
              dataTestId="company-city"
              label={t('city')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="state"
          control={control}
          rules={{
            required: decodeHtmlEntities(
              statesDropdown
                ? tC('form.validation.requiredField', { field: t('state') })
                : tC('form.validation.requiredField', { field: t('stateProvinceRegion') }),
            ),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <StatesDropdown
              required={true}
              isDropdown={statesDropdown}
              field={field}
              fieldState={fieldState}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="zip"
          control={control}
          rules={{
            required: tC('form.validation.requiredField', { field: t('zip') }),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              required
              id="company-zip"
              dataTestId="company-zip"
              label={t('zip')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              {...field}
            />
          )}
        />
      </Grid>
    </>
  );
};
