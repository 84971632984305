import { Box } from '@mui/material';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useUnassignDoorFromDeliveryMutation } from '@/graphql/defs/components/modals/__generated__/delivery-door-unassign.generated';
import { DeliveryType } from '@/graphql/types.generated';
import DeliveryTypeCell from '@components/data-table/table/cells/DeliveryTypeCell';
import EditableTable, {
  EditableTableColumn,
  EditableTableRow,
} from '@components/editable-table/EditableTable';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useDateTime } from '@context/date-time';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { getTransComponents } from '@lib/translation';
import { IDeliveryUnassignDoorModal } from '@models/modal';

const DeliveryDoorUnassignModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal } = useModalContent<IDeliveryUnassignDoorModal>();
  const { showMessage } = useSnackbar();
  const { displayDate } = useDateTime();

  const [updateDelivery] = useUnassignDoorFromDeliveryMutation({
    onCompleted: ({ updateOneDelivery: { erpCode: deliveryCode } }) => {
      showMessage({
        type: 'success',
        message: t('modal.delivery.doorUnassign.success', { deliveryCode }),
      });
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const processDoorAssignments = async (index: number) => {
    const delivery = modal.deliveries[index];
    const nextIndex = ++index;

    void updateDelivery({
      variables: {
        deliveryId: delivery?.id,
      },
      onCompleted: () => {
        if (nextIndex >= modal.deliveries.length) {
          closeModal({ bypassLoading: true, success: true });
        }
      },
    });

    if (nextIndex < modal.deliveries.length) {
      await processDoorAssignments(index);
    }
  };

  const onSubmit = () => {
    void processDoorAssignments(0);
  };

  const deliveryColumns: EditableTableColumn[] = [
    {
      label: t('common.code'),
      width: '20%',
    },
    {
      label: t('common.type'),
      width: '20%',
    },
    {
      label: t('common.doorCode'),
      width: '20%',
    },
    {
      label: t('common.dueDate'),
      width: '20%',
    },
    {
      label: t('common.businessPartner'),
      width: '20%',
    },
  ];

  const deliveryRows: EditableTableRow[] = useMemo(
    () =>
      modal.deliveries?.map((delivery): EditableTableRow => {
        return {
          item: delivery,
          dataTestId: `${delivery?.id}`,
          cells: [
            {
              value: delivery?.erpCode,
              dataTestId: `${delivery?.id}-code`,
            },
            {
              value: <DeliveryTypeCell deliveryType={delivery?.type} />,
              dataTestId: `${delivery?.id}-type`,
            },
            {
              value: delivery?.__typename === 'ViewDelivery' && delivery?.doorCode,
              dataTestId: `${delivery?.id}-door`,
            },
            {
              value: displayDate({ date: delivery?.dueDate }),
              dataTestId: `${delivery?.id}-dueDate`,
            },
            {
              value:
                delivery?.type === DeliveryType.Delivery
                  ? delivery?.supplierName
                  : delivery?.soldToName,
              dataTestId: `${delivery?.id}-dueDate`,
            },
          ],
        };
      }) || [],
    [modal.deliveries],
  );

  return (
    <>
      <ModalContent>
        <Box>
          <Trans
            ns="components"
            i18nKey="modal.delivery.doorUnassign.text"
            components={getTransComponents(['bold'])}
            count={modal.deliveries.length}
            values={{
              deliveryType: t(`common.${modal.deliveries[0]?.type}`),
              doorCode:
                modal.deliveries[0]?.__typename === 'Delivery'
                  ? modal.deliveries[0]?.door?.code
                  : modal.deliveries[0]?.doorCode,
            }}
          />
        </Box>
        {modal.deliveries.length > 1 && (
          <EditableTable columns={deliveryColumns} data={deliveryRows} sx={{ marginTop: '15px' }} />
        )}
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton onClick={onSubmit} variant="contained" color="primary" actionType="submit">
          {t('common.unassign')}
        </ModalButton>
      </ModalActions>
    </>
  );
};

export default DeliveryDoorUnassignModal;
