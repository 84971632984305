/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BlockFulfillmentMutationVariables = Types.Exact<{
  input: Types.FulfillmentBlockInput;
}>;


export type BlockFulfillmentMutation = { __typename?: 'Mutation', blockFulfillment: { __typename?: 'Delivery', id: string } };


export const BlockFulfillmentDocument = gql`
    mutation BlockFulfillment($input: FulfillmentBlockInput!) {
  blockFulfillment(input: $input) {
    id
  }
}
    `;
export type BlockFulfillmentMutationFn = Apollo.MutationFunction<BlockFulfillmentMutation, BlockFulfillmentMutationVariables>;

/**
 * __useBlockFulfillmentMutation__
 *
 * To run a mutation, you first call `useBlockFulfillmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockFulfillmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockFulfillmentMutation, { data, loading, error }] = useBlockFulfillmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockFulfillmentMutation(baseOptions?: Apollo.MutationHookOptions<BlockFulfillmentMutation, BlockFulfillmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockFulfillmentMutation, BlockFulfillmentMutationVariables>(BlockFulfillmentDocument, options);
      }
export type BlockFulfillmentMutationHookResult = ReturnType<typeof useBlockFulfillmentMutation>;
export type BlockFulfillmentMutationResult = Apollo.MutationResult<BlockFulfillmentMutation>;
export type BlockFulfillmentMutationOptions = Apollo.BaseMutationOptions<BlockFulfillmentMutation, BlockFulfillmentMutationVariables>;