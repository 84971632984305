import { FC, FormEvent, HTMLProps, useEffect } from 'react';
import { FormProvider, useFormState, UseFormReturn } from 'react-hook-form';

import { useModalContent } from '@context/modal/ModalContentProvider';

interface IModalForm extends HTMLProps<HTMLFormElement> {
  formReturn: UseFormReturn;
}
const ModalForm: FC<IModalForm> = ({ children, formReturn, ...props }) => {
  const { isLoading, loadingType, setLoading, setLoadingType } = useModalContent();
  const { isSubmitting, isSubmitSuccessful } = useFormState({
    control: formReturn.control,
  });

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isSubmitting && !isLoading && props.onSubmit) {
      setLoadingType('submit');
      setLoading(true);
      props.onSubmit(e);
    }
  };

  useEffect(() => {
    if (isLoading && loadingType === 'submit' && !isSubmitting && !isSubmitSuccessful) {
      setLoading(false);
      setLoadingType(null);
    }
  }, [isSubmitting, isSubmitSuccessful, isLoading]);

  return (
    <FormProvider {...formReturn}>
      <form action="#" {...props} onSubmit={handleSubmit} noValidate>
        {children}
      </form>
    </FormProvider>
  );
};

export default ModalForm;
