/* eslint-disable */
import * as Types from '../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VelocityUoMFragment = { __typename?: 'UnitOfMeasureGlossary', id: string, code: string };

export type VelocityUnitOfMeasuresQueryVariables = Types.Exact<{
  warehouseId: Types.Scalars['ID']['input'];
}>;


export type VelocityUnitOfMeasuresQuery = { __typename?: 'Query', unitOfMeasureWarehousePreference: Array<{ __typename?: 'UnitOfMeasureGlossary', id: string, code: string }> };

export const VelocityUoMFragmentDoc = gql`
    fragment VelocityUoM on UnitOfMeasureGlossary {
  id
  code
}
    `;
export const VelocityUnitOfMeasuresDocument = gql`
    query VelocityUnitOfMeasures($warehouseId: ID!) {
  unitOfMeasureWarehousePreference(category: velocity, warehouseId: $warehouseId) {
    ...VelocityUoM
  }
}
    ${VelocityUoMFragmentDoc}`;

/**
 * __useVelocityUnitOfMeasuresQuery__
 *
 * To run a query within a React component, call `useVelocityUnitOfMeasuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useVelocityUnitOfMeasuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVelocityUnitOfMeasuresQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useVelocityUnitOfMeasuresQuery(baseOptions: Apollo.QueryHookOptions<VelocityUnitOfMeasuresQuery, VelocityUnitOfMeasuresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VelocityUnitOfMeasuresQuery, VelocityUnitOfMeasuresQueryVariables>(VelocityUnitOfMeasuresDocument, options);
      }
export function useVelocityUnitOfMeasuresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VelocityUnitOfMeasuresQuery, VelocityUnitOfMeasuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VelocityUnitOfMeasuresQuery, VelocityUnitOfMeasuresQueryVariables>(VelocityUnitOfMeasuresDocument, options);
        }
export type VelocityUnitOfMeasuresQueryHookResult = ReturnType<typeof useVelocityUnitOfMeasuresQuery>;
export type VelocityUnitOfMeasuresLazyQueryHookResult = ReturnType<typeof useVelocityUnitOfMeasuresLazyQuery>;
export type VelocityUnitOfMeasuresQueryResult = Apollo.QueryResult<VelocityUnitOfMeasuresQuery, VelocityUnitOfMeasuresQueryVariables>;