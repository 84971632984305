import { TextField as MUITextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';

type TTextFieldProps = TextFieldProps & {
  dataTestId: string;
  autoComplete?: string;
};

const TextField = forwardRef<HTMLDivElement, TTextFieldProps>(
  ({ dataTestId, autoComplete = 'off', ...textfieldProps }, ref) => {
    return (
      <MUITextField
        {...textfieldProps}
        ref={ref}
        autoComplete={autoComplete}
        inputProps={{
          'data-testid': dataTestId,
          ...(textfieldProps?.inputProps || {}),
        }}
      />
    );
  },
);

export default TextField;
