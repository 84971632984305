import { FileDownloadOutlined } from '@mui/icons-material';
import { Box, LinearProgress } from '@mui/material';
import { DateTime } from 'luxon';
import { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { utils, writeFile } from 'xlsx';

import { useDataBasedDataTable } from '@components/data-table/context/DataBasedProvider';
import ExportingOverlay from '@components/data-table/controls/export/ExportOverlay';
import isDisplayColumn from '@components/data-table/lib/isDisplayColumn';
import Button from '@components/styled/Button';
import { useDateTime } from '@context/date-time';
import { useSnackbar } from '@context/snackbar';

const ExportButton = memo(({ createExportFile }: { createExportFile: () => void }) => {
  const { t } = useTranslation('components');

  return (
    <Button variant="text" onClick={createExportFile} styledVariant="tableControl">
      <FileDownloadOutlined /> {t('common.export').toUpperCase()}
    </Button>
  );
});

const DataTableDataBasedExportControls = () => {
  const { t } = useTranslation('components');
  const { tableId, allColumns, data } = useDataBasedDataTable();
  const { showMessage } = useSnackbar();
  const { displayDate } = useDateTime();

  const exportEnabledColumns = useMemo(
    () =>
      allColumns.filter(
        (c) => !isDisplayColumn(c.id) && c.columnDef.meta.enableExport && c.getIsVisible(),
      ),
    [allColumns],
  );

  const [exporting, setExporting] = useState(false);

  const createExportFile = useCallback(() => {
    setExporting(true);

    try {
      const headers = exportEnabledColumns.map((c) => c.columnDef.header as string);
      const processedData = data.map((row) =>
        exportEnabledColumns.map((column) => {
          const { exportFormatter } = column.columnDef.meta;
          if (exportFormatter && typeof exportFormatter === 'function') {
            return exportFormatter(row[column.id], row);
          } else {
            return row[column.id];
          }
        }),
      );
      const dataWithHeaders = [headers, ...processedData];

      /* convert state to workbook */
      const ws = utils.aoa_to_sheet(dataWithHeaders);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'table 1');

      // /* generate XLSX file and send to client */
      writeFile(
        wb,
        `${tableId}_${displayDate({
          date: DateTime.now().toMillis(),
          timezone: DateTime.now().zoneName,
        })}.xlsx`,
      );

      setExporting(false);
    } catch (error) {
      showMessage({
        type: 'error',
        message: t('dataTable.errorExportBuild', { errorMessage: error.message }),
      });
      setExporting(false);
    }
  }, [exportEnabledColumns, data]);

  return (
    <Box>
      <ExportButton
        createExportFile={createExportFile}
        data-testid={`data-table-${tableId}-export-button`}
      />
      {exporting && (
        <ExportingOverlay data-testid={`data-table-${tableId}-exporting-overlay`}>
          <FileDownloadOutlined
            sx={{ color: (theme) => theme.palette.info.main, fontSize: '50px' }}
          />
          <LinearProgress
            sx={{ position: 'absolute', bottom: 20, left: 25, width: '200px', height: '10px' }}
          />
        </ExportingOverlay>
      )}
    </Box>
  );
};

export default DataTableDataBasedExportControls;
