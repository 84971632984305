import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Grid, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  AddUserListedUserFragment,
  useAddUserListOfUsersQuery,
  useAddUserToTeamMutation,
} from '@/graphql/defs/components/modals/__generated__/add-user-to-team-modal.generated';
import { TeamCardUserFragment } from '@/graphql/defs/hooks/table-props/__generated__/useTeamCardUserListDataTable.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import FormValues from '@models/FormValues';
import { IUserAddToTeamModal } from '@models/modal';

const AddUserToTeamModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, setLoading } = useModalContent<IUserAddToTeamModal>();
  const { showMessage } = useSnackbar();

  const formMethods = useForm<FormValues<{ user: AddUserListedUserFragment }>>({
    defaultValues: { user: '' },
  });
  const { control, handleSubmit, setValue } = formMethods;

  const [userList, setUserList] = useState<AddUserListedUserFragment[]>([]);
  useAddUserListOfUsersQuery({
    onCompleted: ({ getUsers: { users: _users } }) => {
      setUserList(_users);
    },
  });

  const [addUserToTeam] = useAddUserToTeamMutation({
    onCompleted: () => {
      const addedUser = formMethods.getValues().user as AddUserListedUserFragment;
      showMessage({
        type: 'success',
        message: t('modal.team.userAddToTeam.success', {
          userName: `${addedUser?.firstName} ${addedUser?.lastName}`,
          teamName: modal.team?.name,
        }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onSubmit = (fields: { user: TeamCardUserFragment }) => {
    void addUserToTeam({
      variables: {
        teamId: modal.team?.id,
        userId: fields?.user?.id,
      },
    });
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Controller
              name="user"
              control={control}
              rules={{
                required: t('form.validation.requiredField', { field: t('teams.searchUser') }),
              }}
              render={({ field, fieldState }) => (
                <Autocomplete
                  freeSolo={false}
                  multiple={false}
                  id="team-searchUser"
                  data-testid="team-user-dropdown"
                  autoHighlight={true}
                  options={userList}
                  getOptionLabel={(item) =>
                    [item.firstName, item.lastName, `(${item.email})`].join(' ')
                  }
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(event, value, reason) => {
                    if (reason === 'selectOption' || reason === 'clear') {
                      setValue('user', value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...field}
                      autoFocus
                      required
                      label={t('teams.searchUser')}
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      dataTestId="team-user-input"
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          data-testid={'team-add-member-submit'}
          variant="contained"
          color="primary"
          actionType="submit"
        >
          {t('common.submit')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default AddUserToTeamModal;
