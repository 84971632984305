/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateSlottingRunMutationVariables = Types.Exact<{
  input: Types.SlottingRunCreateOneInput;
}>;


export type CreateSlottingRunMutation = { __typename?: 'Mutation', createOneSlottingRun: boolean };


export const CreateSlottingRunDocument = gql`
    mutation CreateSlottingRun($input: SlottingRunCreateOneInput!) {
  createOneSlottingRun(input: $input)
}
    `;
export type CreateSlottingRunMutationFn = Apollo.MutationFunction<CreateSlottingRunMutation, CreateSlottingRunMutationVariables>;

/**
 * __useCreateSlottingRunMutation__
 *
 * To run a mutation, you first call `useCreateSlottingRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSlottingRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSlottingRunMutation, { data, loading, error }] = useCreateSlottingRunMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSlottingRunMutation(baseOptions?: Apollo.MutationHookOptions<CreateSlottingRunMutation, CreateSlottingRunMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSlottingRunMutation, CreateSlottingRunMutationVariables>(CreateSlottingRunDocument, options);
      }
export type CreateSlottingRunMutationHookResult = ReturnType<typeof useCreateSlottingRunMutation>;
export type CreateSlottingRunMutationResult = Apollo.MutationResult<CreateSlottingRunMutation>;
export type CreateSlottingRunMutationOptions = Apollo.BaseMutationOptions<CreateSlottingRunMutation, CreateSlottingRunMutationVariables>;