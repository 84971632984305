import { ApolloError } from '@apollo/client';

import { useCreateCompanyMutation } from '@/graphql/defs/components/modals/__generated__/company-create-modal.generated';
import { useUpdateCompanyDetailsMutation } from '@/graphql/defs/components/modals/__generated__/company-details-modal.generated';
import { CompanyFragment } from '@/graphql/defs/pages/__generated__/companies.generated';
import { CompanyCreateInput, CompanyUpdateInput } from '@/graphql/types.generated';
import { IVStepCBArgs } from '@components/form-util';
import { useEntityUtils } from '@context/entity-utils';

/**
 * Handle the submission of the Company Details field block within a stepper form.
 */
export const useCompanyDetailsStep = (args: IVStepCBArgs) => {
  const { depMap, updateDepMap, setError, setActiveStepState } = args;
  const { company } = depMap as { company?: CompanyFragment };
  const { selectedOrganizationId } = useEntityUtils();

  const handleError = (error: ApolloError) => {
    setError(error.message);
    setActiveStepState('error');
  };

  const [createCompany] = useCreateCompanyMutation({
    onCompleted: ({ createOneCompany: newCompany }) => {
      updateDepMap({ parentId: newCompany.id, company: newCompany });
      setActiveStepState('success');
    },
    onError: handleError,
  });

  const [updateCompany] = useUpdateCompanyDetailsMutation({
    onCompleted: ({ updateOneCompany: updatedCompany }) => {
      updateDepMap({ company: updatedCompany });
      setActiveStepState('success');
    },
    onError: handleError,
  });

  return (formCompany: CompanyFragment) => {
    // Only run the actual update if values changed, or it's a creation, otherwise just return true.
    if (company?.id && company.name === formCompany.name && company.code === formCompany.code) {
      setActiveStepState('success');
      return;
    }

    const companyDTO: Pick<CompanyCreateInput, 'code' | 'name'> &
      Pick<CompanyUpdateInput, 'code' | 'name'> = {
      code: formCompany.code,
      name: formCompany.name,
    };

    if (!company?.id) {
      void createCompany({
        variables: { newCompany: { ...companyDTO, organizationId: selectedOrganizationId } },
      });
    } else {
      // Update Company
      void updateCompany({
        variables: { id: company.id, updatedDetails: companyDTO },
      });
    }
  };
};
