/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FullDisplayPrefFragment = { __typename?: 'DisplayPreference', id: string, currency: string, dateTimeFormat: string, language: string, numberFormat?: Types.NumberFormat | null, measurementSystem: Types.StandardUomSystem, timezone: string };

export type UpdateDisplayPreferenceMutationVariables = Types.Exact<{
  parentId: Types.Scalars['ID']['input'];
  displayPreference: Types.DisplayPreferenceUpdateDto;
  isOrg: Types.Scalars['Boolean']['input'];
  isCompany: Types.Scalars['Boolean']['input'];
  isWarehouse: Types.Scalars['Boolean']['input'];
}>;


export type UpdateDisplayPreferenceMutation = { __typename?: 'Mutation', updateOneDisplayPreferenceByOrganizationId?: { __typename?: 'DisplayPreference', id: string, currency: string, dateTimeFormat: string, language: string, numberFormat?: Types.NumberFormat | null, measurementSystem: Types.StandardUomSystem, timezone: string }, updateOneDisplayPreferenceByCompanyId?: { __typename?: 'DisplayPreference', id: string, currency: string, dateTimeFormat: string, language: string, numberFormat?: Types.NumberFormat | null, measurementSystem: Types.StandardUomSystem, timezone: string }, updateOneDisplayPreferenceByWarehouseId?: { __typename?: 'DisplayPreference', id: string, currency: string, dateTimeFormat: string, language: string, numberFormat?: Types.NumberFormat | null, measurementSystem: Types.StandardUomSystem, timezone: string } };

export const FullDisplayPrefFragmentDoc = gql`
    fragment FullDisplayPref on DisplayPreference {
  id
  currency
  dateTimeFormat
  language
  numberFormat
  measurementSystem
  timezone
}
    `;
export const UpdateDisplayPreferenceDocument = gql`
    mutation UpdateDisplayPreference($parentId: ID!, $displayPreference: DisplayPreferenceUpdateDto!, $isOrg: Boolean!, $isCompany: Boolean!, $isWarehouse: Boolean!) {
  updateOneDisplayPreferenceByOrganizationId(
    input: {id: $parentId, update: $displayPreference}
  ) @include(if: $isOrg) {
    ...FullDisplayPref
  }
  updateOneDisplayPreferenceByCompanyId(
    input: {id: $parentId, update: $displayPreference}
  ) @include(if: $isCompany) {
    ...FullDisplayPref
  }
  updateOneDisplayPreferenceByWarehouseId(
    input: {id: $parentId, update: $displayPreference}
  ) @include(if: $isWarehouse) {
    ...FullDisplayPref
  }
}
    ${FullDisplayPrefFragmentDoc}`;
export type UpdateDisplayPreferenceMutationFn = Apollo.MutationFunction<UpdateDisplayPreferenceMutation, UpdateDisplayPreferenceMutationVariables>;

/**
 * __useUpdateDisplayPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateDisplayPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDisplayPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDisplayPreferenceMutation, { data, loading, error }] = useUpdateDisplayPreferenceMutation({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      displayPreference: // value for 'displayPreference'
 *      isOrg: // value for 'isOrg'
 *      isCompany: // value for 'isCompany'
 *      isWarehouse: // value for 'isWarehouse'
 *   },
 * });
 */
export function useUpdateDisplayPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDisplayPreferenceMutation, UpdateDisplayPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDisplayPreferenceMutation, UpdateDisplayPreferenceMutationVariables>(UpdateDisplayPreferenceDocument, options);
      }
export type UpdateDisplayPreferenceMutationHookResult = ReturnType<typeof useUpdateDisplayPreferenceMutation>;
export type UpdateDisplayPreferenceMutationResult = Apollo.MutationResult<UpdateDisplayPreferenceMutation>;
export type UpdateDisplayPreferenceMutationOptions = Apollo.BaseMutationOptions<UpdateDisplayPreferenceMutation, UpdateDisplayPreferenceMutationVariables>;