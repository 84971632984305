/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EquipmentSettingsFragment = { __typename?: 'ViewEquipment', id?: string | null, code?: string | null, label?: string | null, description?: string | null, equipmentModelId?: string | null, modelLabel?: string | null, equipmentTypeId?: string | null, typeLabel?: string | null, status?: Types.EquipmentStatus | null, tagId?: string | null };

export type EquipmentSettingsTableQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.ViewEquipmentFilter>;
  sorting?: Types.InputMaybe<Array<Types.ViewEquipmentSort> | Types.ViewEquipmentSort>;
  includePageInfo?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeNodes?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeTotalCount?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type EquipmentSettingsTableQuery = { __typename?: 'Query', query: { __typename?: 'ViewEquipmentOffsetConnection', totalCount?: number, pageInfo?: { __typename?: 'OffsetPageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null }, nodes?: Array<{ __typename?: 'ViewEquipment', id?: string | null, code?: string | null, label?: string | null, description?: string | null, equipmentModelId?: string | null, modelLabel?: string | null, equipmentTypeId?: string | null, typeLabel?: string | null, status?: Types.EquipmentStatus | null, tagId?: string | null }> } };

export type EquipmentSettingsTypeFilterFragment = { __typename?: 'EquipmentType', id: string, code: string, label: string };

export type EquipmentSettingsModelFilterFragment = { __typename?: 'EquipmentModel', id: string, code: string, label: string, equipmentTypeId: string };

export type EquipmentSettingsTableFilterValuesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EquipmentSettingsTableFilterValuesQuery = { __typename?: 'Query', equipmentTypes: { __typename?: 'EquipmentTypeOffsetConnection', nodes: Array<{ __typename?: 'EquipmentType', id: string, code: string, label: string }> }, equipmentModels: { __typename?: 'EquipmentModelOffsetConnection', nodes: Array<{ __typename?: 'EquipmentModel', id: string, code: string, label: string, equipmentTypeId: string }> } };

export const EquipmentSettingsFragmentDoc = gql`
    fragment EquipmentSettings on ViewEquipment {
  id
  code
  label
  description
  equipmentModelId
  modelLabel
  equipmentTypeId
  typeLabel
  status
  tagId
}
    `;
export const EquipmentSettingsTypeFilterFragmentDoc = gql`
    fragment EquipmentSettingsTypeFilter on EquipmentType {
  id
  code
  label
}
    `;
export const EquipmentSettingsModelFilterFragmentDoc = gql`
    fragment EquipmentSettingsModelFilter on EquipmentModel {
  id
  code
  label
  equipmentTypeId
}
    `;
export const EquipmentSettingsTableDocument = gql`
    query EquipmentSettingsTable($paging: OffsetPaging, $filter: ViewEquipmentFilter, $sorting: [ViewEquipmentSort!], $includePageInfo: Boolean = true, $includeNodes: Boolean = true, $includeTotalCount: Boolean = false) {
  query: viewEquipments(sorting: $sorting, paging: $paging, filter: $filter) {
    pageInfo @include(if: $includePageInfo) {
      hasNextPage
      hasPreviousPage
    }
    nodes @include(if: $includeNodes) {
      ...EquipmentSettings
    }
    totalCount @include(if: $includeTotalCount)
  }
}
    ${EquipmentSettingsFragmentDoc}`;

/**
 * __useEquipmentSettingsTableQuery__
 *
 * To run a query within a React component, call `useEquipmentSettingsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentSettingsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentSettingsTableQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      includePageInfo: // value for 'includePageInfo'
 *      includeNodes: // value for 'includeNodes'
 *      includeTotalCount: // value for 'includeTotalCount'
 *   },
 * });
 */
export function useEquipmentSettingsTableQuery(baseOptions?: Apollo.QueryHookOptions<EquipmentSettingsTableQuery, EquipmentSettingsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EquipmentSettingsTableQuery, EquipmentSettingsTableQueryVariables>(EquipmentSettingsTableDocument, options);
      }
export function useEquipmentSettingsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EquipmentSettingsTableQuery, EquipmentSettingsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EquipmentSettingsTableQuery, EquipmentSettingsTableQueryVariables>(EquipmentSettingsTableDocument, options);
        }
export type EquipmentSettingsTableQueryHookResult = ReturnType<typeof useEquipmentSettingsTableQuery>;
export type EquipmentSettingsTableLazyQueryHookResult = ReturnType<typeof useEquipmentSettingsTableLazyQuery>;
export type EquipmentSettingsTableQueryResult = Apollo.QueryResult<EquipmentSettingsTableQuery, EquipmentSettingsTableQueryVariables>;
export const EquipmentSettingsTableFilterValuesDocument = gql`
    query EquipmentSettingsTableFilterValues {
  equipmentTypes(sorting: [{field: code, direction: ASC}]) {
    nodes {
      ...EquipmentSettingsTypeFilter
    }
  }
  equipmentModels(sorting: [{field: code, direction: ASC}]) {
    nodes {
      ...EquipmentSettingsModelFilter
    }
  }
}
    ${EquipmentSettingsTypeFilterFragmentDoc}
${EquipmentSettingsModelFilterFragmentDoc}`;

/**
 * __useEquipmentSettingsTableFilterValuesQuery__
 *
 * To run a query within a React component, call `useEquipmentSettingsTableFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentSettingsTableFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentSettingsTableFilterValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEquipmentSettingsTableFilterValuesQuery(baseOptions?: Apollo.QueryHookOptions<EquipmentSettingsTableFilterValuesQuery, EquipmentSettingsTableFilterValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EquipmentSettingsTableFilterValuesQuery, EquipmentSettingsTableFilterValuesQueryVariables>(EquipmentSettingsTableFilterValuesDocument, options);
      }
export function useEquipmentSettingsTableFilterValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EquipmentSettingsTableFilterValuesQuery, EquipmentSettingsTableFilterValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EquipmentSettingsTableFilterValuesQuery, EquipmentSettingsTableFilterValuesQueryVariables>(EquipmentSettingsTableFilterValuesDocument, options);
        }
export type EquipmentSettingsTableFilterValuesQueryHookResult = ReturnType<typeof useEquipmentSettingsTableFilterValuesQuery>;
export type EquipmentSettingsTableFilterValuesLazyQueryHookResult = ReturnType<typeof useEquipmentSettingsTableFilterValuesLazyQuery>;
export type EquipmentSettingsTableFilterValuesQueryResult = Apollo.QueryResult<EquipmentSettingsTableFilterValuesQuery, EquipmentSettingsTableFilterValuesQueryVariables>;