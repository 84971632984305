import { useBarcode } from 'react-barcodes';

export const Barcode = ({ code }) => {
  const { inputRef } = useBarcode({
    value: code,
  });

  return <svg ref={inputRef} />;
};

export default Barcode;
