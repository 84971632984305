// list-sap-variants.ts
import { gql } from '@apollo/client';

import { ListVariantsQuery } from '@/graphql/defs/list/__generated__/list-sap-variants.generated';

gql`
  query ListVariants {
    listVariants: sapDatasetVariants {
      variants
    }
  }
`;

export const ListVariants_defaultData: ListVariantsQuery = {
  __typename: 'Query',
  listVariants: {
    __typename: 'SlottingDatasetVariants',
    variants: [],
  },
};
