import {
  Column,
  ColumnOrderState,
  ColumnSizingState,
  createColumnHelper,
  getCoreRowModel,
  OnChangeFn,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import createRowSelectionColumnDef from '@components/data-table/columnDefs/rowSelection';
import {
  ColumnFilter,
  ColumnFilterEnumOption,
  ColumnFilterDefinition,
  ColumnTypes,
} from '@components/data-table/controls/filter/filter-definitions';
import formatFilter from '@components/data-table/controls/filter/format-filter';
import useDataTableData from '@components/data-table/hooks/useDataTableData';
import useDataTableLayouts from '@components/data-table/hooks/useDataTableLayouts';
import { SelectionType } from '@components/data-table/hooks/useDataTableSelection';
import useDebounceDataTableLoading from '@components/data-table/hooks/useDebounceDataTableLoading';
import useHandleQueryBasedSelection from '@components/data-table/hooks/useHandleQueryBasedSelection';
import formatDataTableSorting from '@components/data-table/lib/formatDataTableSorting';
import removeLinkedFilters from '@components/data-table/lib/removeLinkedFilters';
import {
  DataTableVariants,
  DefaultDataType,
  IDataTableQueryBasedContext,
  IDataTableQueryBasedProviderProps,
  IDataTableSort,
} from '@components/data-table/model/data-table';

const DataTableQueryBasedContext = createContext<IDataTableQueryBasedContext>(null);

const DataTableQueryBasedProvider = ({
  dataTableProps: props,
  children,
}: IDataTableQueryBasedProviderProps) => {
  const tableId = props.tableId;
  const perPageOptions = props.perPageOptions;
  const queryHook = props.queryHook;

  const [suppressDataFetch, setSuppressDataFetch] = useState(
    props.variant === DataTableVariants.Collapsible ? props.defaultCollapsed : false,
  );

  const memoizedColumns = useMemo(
    () =>
      props.rowSelection?.enableRowSelection !== SelectionType.none
        ? [createRowSelectionColumnDef(createColumnHelper()), ...props.columns]
        : props.columns,
    [props.columns, props.rowSelection?.enableRowSelection],
  );

  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>({});

  const [selectedVisibleRows, setSelectedVisibleRows] = useState<RowSelectionState>({});

  const baseFilter = props.baseFilter;
  const linkedFilter = props.linkedFilter;

  const [filter, setFilter] = useState<ColumnFilter[]>([]);
  const [quickFilter, setQuickFilter] = useState<ColumnFilter>(null);
  const memoizedFormattedFilter = useMemo(() => {
    const _allFilters = [...filter, ...baseFilter];
    if (!!quickFilter) _allFilters.splice(0, 0, quickFilter);
    return formatFilter(_allFilters, memoizedColumns);
  }, [memoizedColumns, baseFilter, filter, quickFilter]);

  const currentFilterWithoutLinks = useMemo(() => removeLinkedFilters(filter) || [], [filter]);
  useEffect(() => {
    setFilter([...currentFilterWithoutLinks, ...linkedFilter]);
  }, [linkedFilter]);

  const defaultSorting = props.defaultSorting;
  const [sorting, setSorting] = useState<IDataTableSort[]>(defaultSorting);
  const memoizedFormattedSorting = useMemo(() => formatDataTableSorting(sorting), [sorting]);

  const [hasInitializedLayouts, setHasInitializedLayouts] = useState(false);
  const {
    dataTableData: {
      data,
      isDataTableLoading,
      hasCompletedFirstFetch,
      dataLastFetched,
      refetchData,
      refetchTotalCount,
      getExportData,
    },
    pagination,
  } = useDataTableData({
    tableId,
    queryHook: queryHook,
    suppressDataFetch,
    disablePagination: props.disablePagination,
    perPageOptions: perPageOptions,
    filter: memoizedFormattedFilter,
    sorting: memoizedFormattedSorting,
    onFetchComplete: props.onFetchComplete,
    hasInitializedLayouts,
  });

  const tableInstance = useReactTable({
    data: data,
    columns: memoizedColumns,
    columnResizeMode: 'onChange',
    defaultColumn: {
      size: 150,
      minSize: 20,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    state: {
      columnVisibility,
      columnOrder,
      columnSizing,
      rowSelection: selectedVisibleRows,
      sorting: sorting as SortingState,
    },
    enableRowSelection:
      props.rowSelection?.enableRowSelection !== SelectionType.none &&
      (typeof props.rowSelection?.rowSelectionEnabledFilter === 'function'
        ? props.rowSelection?.rowSelectionEnabledFilter
        : true),
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onRowSelectionChange: setSelectedVisibleRows,
    onSortingChange: setSorting as OnChangeFn<SortingState>,
    onColumnSizingChange: setColumnSizing,
    getCoreRowModel: getCoreRowModel(),
    manualExpanding: true,
    manualFiltering: true,
    manualGrouping: true,
    manualPagination: true,
    manualSorting: true,
    meta: {
      rowSelectionType: props.rowSelection?.enableRowSelection,
    },
  });

  const allTableColumns = tableInstance.getAllLeafColumns();
  const visibleTableColumns = tableInstance.getVisibleLeafColumns();

  const allTableColumnsSorted: Column<DefaultDataType, unknown>[] = useMemo(
    () =>
      [...allTableColumns].sort((a, b) => {
        if (a.columnDef.header < b.columnDef.header) {
          return -1;
        }
        if (a.columnDef.header > b.columnDef.header) {
          return 1;
        }
        return 0;
      }),
    [memoizedColumns],
  );
  const allFilterableColumnsSorted = useMemo(
    () =>
      allTableColumnsSorted.reduce<Column<DefaultDataType>[]>((acc, column) => {
        if (column.columnDef.enableColumnFilter) {
          acc.push(column);
        }
        return acc;
      }, []),
    [allTableColumnsSorted],
  );

  const filterableColumnDefintions: ColumnFilterDefinition[] = useMemo(
    () =>
      allFilterableColumnsSorted.map((c) => ({
        id: c.id,
        label: c.columnDef.header as string,
        columnType: c.columnDef.meta?.columnType,
        options:
          c.columnDef.meta?.columnType === ColumnTypes.enum ? c.columnDef.meta?.options : undefined,
        booleanLabels:
          c.columnDef.meta?.columnType === ColumnTypes.boolean
            ? c.columnDef.meta?.booleanLabels
            : undefined,
      })),
    [allFilterableColumnsSorted],
  );

  const allEnumColumnOptions = useMemo(
    () =>
      allFilterableColumnsSorted
        .filter((c) => c.columnDef.meta.columnType === ColumnTypes.enum)
        .reduce<ColumnFilterEnumOption[]>((optionSet, column) => {
          column.columnDef.meta.options.forEach((o) => {
            if (!optionSet.some((oS) => oS.value === o.value)) {
              optionSet.push(o);
            }
          });
          return optionSet;
        }, [])
        .sort((a, b) => {
          const typedA = Number.isNaN(a.display)
            ? a.display
            : Number(a.display) % 1 === 0
            ? parseInt(a.display)
            : parseFloat(a.display);
          const typedB = Number.isNaN(b.display)
            ? b.display
            : Number(b.display) % 1 === 0
            ? parseInt(b.display)
            : parseFloat(b.display);

          if (typedA < typedB) {
            return -1;
          }
          if (typedA > typedB) {
            return 1;
          }
          return 0;
        }),
    [allFilterableColumnsSorted],
  );

  const dataTableLayoutProps = useDataTableLayouts({
    tableId,
    allTableColumns,
    linkedFilter: linkedFilter,
    defaultSorting: defaultSorting,
    defaultPageSize: perPageOptions[0],
    currentFilterWithoutLinks,
    currentSorting: sorting,
    currentColumnOrder: columnOrder,
    currentColumnSizing: columnSizing,
    currentPageSize: pagination.limit,
    setFilter: setFilter,
    setSorting: setSorting,
    setColumnOrder: setColumnOrder,
    setColumnVisibility: setColumnVisibility,
    setColumnSizing: setColumnSizing,
    setPageSize: pagination.setPageLimit,
    hasInitializedLayouts,
    setHasInitializedLayouts,
  });

  useHandleQueryBasedSelection({
    data,
    selectionType: props.rowSelection.enableRowSelection,
    selectionDataKey: props.rowSelection.selectionDataKey,
    selectionOverride: props.rowSelection.selectionOverride,
    selectedVisibleRows,
    setSelectedVisibleRows,
    setSelectedRowsData: props.rowSelection.setSelectedRowsData,
    hasCompletedFirstFetch,
    clearSelectionTrigger: props.rowSelection.clearSelectionTrigger,
  });

  const _memoizedHeaderColumns = useMemo(
    () => tableInstance.getHeaderGroups()[0].headers,
    [memoizedColumns, columnOrder, columnSizing, columnVisibility],
  );
  const [debouncedDataTableLoading, dataRows] = useDebounceDataTableLoading({
    isDataTableLoading,
    dataRows: tableInstance.getRowModel().rows,
  });

  const [pendingRefetch, setPendingRefetch] = useState(false);
  useEffect(() => {
    if (hasCompletedFirstFetch && !suppressDataFetch && typeof refetchData === 'function') {
      refetchData();
      refetchTotalCount();
    } else if (hasCompletedFirstFetch && suppressDataFetch && typeof refetchData === 'function') {
      setPendingRefetch(true);
    }
  }, [props.refetchTrigger]);

  useEffect(() => {
    if (!suppressDataFetch && pendingRefetch && typeof refetchData === 'function') {
      refetchData();
      refetchTotalCount();
      setPendingRefetch(false);
    }
  }, [suppressDataFetch]);

  return (
    <DataTableQueryBasedContext.Provider
      value={{
        tableId,
        tableSize: tableInstance.getTotalSize(),
        allColumns: allTableColumns,
        visibleTableColumns: visibleTableColumns,
        filterableColumns: filterableColumnDefintions,
        filterableColumnEnumOptions: allEnumColumnOptions,
        setColumnOrder: setColumnOrder,
        headerColumns: _memoizedHeaderColumns,
        hasCompletedFirstFetch,
        dataLastFetched,
        isDataTableLoading: debouncedDataTableLoading,
        dataRows,
        selectedRows: selectedVisibleRows,
        filter: filter,
        setFilter: setFilter,
        quickFilter: quickFilter,
        setQuickFilter: setQuickFilter,
        layoutProps: dataTableLayoutProps,
        refetchData: refetchData,
        refetchTotalCount: refetchTotalCount,
        getExportData: getExportData,
        pagination,
        setSuppressDataFetch,
      }}
    >
      {children}
    </DataTableQueryBasedContext.Provider>
  );
};

export default DataTableQueryBasedProvider;

export const useQueryBasedDataTable = () => {
  const ctx = useContext(DataTableQueryBasedContext);
  if (ctx === null) {
    throw new Error('useQueryBasedDataTable must be used within DataTableQueryBasedProvider');
  }

  return ctx;
};
