/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveFulfillmentBlockMutationVariables = Types.Exact<{
  input: Types.FulfillmentUnblockInput;
}>;


export type RemoveFulfillmentBlockMutation = { __typename?: 'Mutation', removeFulfillmentBlock: { __typename?: 'Delivery', id: string } };


export const RemoveFulfillmentBlockDocument = gql`
    mutation RemoveFulfillmentBlock($input: FulfillmentUnblockInput!) {
  removeFulfillmentBlock(input: $input) {
    id
  }
}
    `;
export type RemoveFulfillmentBlockMutationFn = Apollo.MutationFunction<RemoveFulfillmentBlockMutation, RemoveFulfillmentBlockMutationVariables>;

/**
 * __useRemoveFulfillmentBlockMutation__
 *
 * To run a mutation, you first call `useRemoveFulfillmentBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFulfillmentBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFulfillmentBlockMutation, { data, loading, error }] = useRemoveFulfillmentBlockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFulfillmentBlockMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFulfillmentBlockMutation, RemoveFulfillmentBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFulfillmentBlockMutation, RemoveFulfillmentBlockMutationVariables>(RemoveFulfillmentBlockDocument, options);
      }
export type RemoveFulfillmentBlockMutationHookResult = ReturnType<typeof useRemoveFulfillmentBlockMutation>;
export type RemoveFulfillmentBlockMutationResult = Apollo.MutationResult<RemoveFulfillmentBlockMutation>;
export type RemoveFulfillmentBlockMutationOptions = Apollo.BaseMutationOptions<RemoveFulfillmentBlockMutation, RemoveFulfillmentBlockMutationVariables>;