import { IDateTimePref } from '@lib/date';

// Examples shown using February 1st, 2022
export const DATE_FORMATS = [
  'M/d/yy', // 2/1/22
  'M/d/yyyy', // 2/1/2022
  'MM/dd/yy', // 02/01/22
  'MM/dd/yyyy', // 02/01/2022
  'd/M/yy', // 1/2/22
  'd/M/yyyy', // 1/2/2022
  'dd/MM/yy', // 01/02/22
  'dd/MM/yyyy', // 01/02/2022
  'M-d-yy', // 2/1/22
  'M-d-yyyy', // 2-1-2022
  'MM-dd-yy', // 02-01-22
  'MM-dd-yyyy', // 02-01-2022
  'd-M-yy', // 1-2-22
  'd-M-yyyy', // 1-2-2022
  'dd-MM-yy', // 01-02-22
  'dd-MM-yyyy', // 01-02-2022
  'MMM d, yy', // Feb 1, 22
  'MMM d, yyyy', // Feb 1, 2022
  'MMM dd, yy', // Feb 01, 22
  'MMM dd, yyyy', // Feb 01, 2022
  'MMMM d, yy', // February 1, 22
  'MMMM d, yyyy', // February 1, 2022
  'MMMM dd, yy', // February 01, 22
  'MMMM dd, yyyy', // February 01, 2022
];

export const TIME_FORMATS = [
  'h:mma', // 6:09PM
  'h:mm a', // 6:09 PM
  'H:mm', // 18:09 (6:09PM) || 6:09 (6:09AM),
  'HH:mm', // 18:09 (6:09PM) || 06:09 (6:09AM)
];

export const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';
export const DEFAULT_TIME_FORMAT = 'HH:mm';

// We have the need to concatenated our default date and time since that is how it will be stored in the database
// Once we break up the Display Preference DateTime Format into two separate columns in the DB we look at refactoring.
export const DEFAULT_DATE_TIME_FORMAT = `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT}`;

export const DATE_TIME_FORMATS_CONVERT: Record<string, IDateTimePref> = DATE_FORMATS.reduce(
  (dateTimeFormats, dateFormat) => {
    TIME_FORMATS.forEach((timeFormat) => {
      dateTimeFormats[`${dateFormat} ${timeFormat}`] = {
        dateTime: `${dateFormat} ${timeFormat}`,
        date: dateFormat,
        time: timeFormat,
      };
    });
    return dateTimeFormats;
  },
  {} as Record<string, IDateTimePref>,
);

export const DEFAULT_DATE_TIME_OBJECT = DATE_TIME_FORMATS_CONVERT[DEFAULT_DATE_TIME_FORMAT];
