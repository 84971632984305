import { Grid } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useDeleteDoorMutation,
  useUpdateDoorMutation,
} from '@/graphql/defs/components/modals/__generated__/update-door-modal.generated';
import { useListAreasQuery } from '@/graphql/defs/list/__generated__/list-areas.generated';
import { useListAllNonAssignedBinsInAreaIncludingCurrentBinQuery } from '@/graphql/defs/list/__generated__/list-bins.generated';
import { ListAreas_defaultData } from '@/graphql/defs/list/list-areas';
import { ListAllNonAssignedBinsInArea_defaultData } from '@/graphql/defs/list/list-bins';
import { DoorDirection, DoorUpdateInput } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useModalToggle } from '@context/modal/ModalToggleProvider';
import { useSnackbar } from '@context/snackbar';
import { useWarehouseFeatureFlags } from '@context/warehouse-feature-flags';
import { useFormValidation } from '@hooks/form/validators';
import enumKeys from '@lib/enum-keys';
import { allowOnlyNumeric, formatAsInteger, stripUnchangedFields, uppercase } from '@lib/form';
import FormValues from '@models/FormValues';
import { IDoorUpdateModal, ModalTypes } from '@models/modal';

const UpdateDoorModal = () => {
  const { t } = useTranslation('components');
  const { selectedWarehouseId } = useEntityUtils();
  const { showMessage } = useSnackbar();
  const { warehouseFeatureFlags: featureFlags } = useWarehouseFeatureFlags();

  const { openModal } = useModalToggle();
  const { modal, closeModal, isPreparing, setPreparing, setLoading, depBucket } =
    useModalContent<IDoorUpdateModal>();

  const defaultValues: FormValues<DoorUpdateInput> = {
    code: modal.door?.code || '',
    areaId: modal.door?.areaId || '',
    binId: modal.lookForDepBin && depBucket['binId'] ? depBucket['binId'] : modal.door?.binId || '',
    direction: modal.door?.direction || '',
    x: modal.door?.x || null,
    y: modal.door?.y || null,
  };
  const formMethods = useForm<FormValues<DoorUpdateInput>>({
    defaultValues,
  });
  const { control, handleSubmit, getValues, watch } = formMethods;
  const { notWhiteSpaceOnly, fieldIsInteger } = useFormValidation();

  const [updateDoor] = useUpdateDoorMutation({
    onCompleted: ({ updateOneDoor: { code } }) => {
      showMessage({
        type: 'success',
        message: t('modal.door.update.successUpdate', { code }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const [deleteDoor] = useDeleteDoorMutation({
    onCompleted: ({ deleteOneDoor: { code } }) => {
      showMessage({
        type: 'success',
        message: t('modal.door.update.successDelete', { code }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const { data: listAreasData } = useListAreasQuery({
    skip: !selectedWarehouseId,
    variables: { warehouseId: selectedWarehouseId },
    onError: (error) => {
      setPreparing(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const {
    viewAreas: { areas },
  } = useMemo(() => listAreasData || ListAreas_defaultData, [listAreasData]);

  const watchAreaId = watch('areaId', modal.door?.areaId);
  const { data: listBinsData } = useListAllNonAssignedBinsInAreaIncludingCurrentBinQuery({
    skip: !watchAreaId,
    variables: { areaId: watchAreaId, currentBinId: modal.door?.binId },
    onCompleted: () => {
      setPreparing(false);
    },
    onError: (error) => {
      setPreparing(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const {
    getBins: { binsList },
  } = useMemo(() => listBinsData || ListAllNonAssignedBinsInArea_defaultData, [listBinsData]);

  const NEW_BIN_VALUE = 'newBin';
  const watchBinId = watch('binId', modal.door?.binId);
  useEffect(() => {
    if (watchBinId === NEW_BIN_VALUE) {
      const reopenCreateDoorModal = () => {
        openModal({
          type: ModalTypes.doorUpdate,
          door: {
            ...modal.door,
            code: getValues('code') !== '' ? getValues('code') : modal.door?.code,
            areaId: getValues('areaId') !== '' ? getValues('areaId') : modal.door?.areaId,
            direction:
              getValues('direction') !== ''
                ? (getValues('direction') as DoorDirection)
                : modal.door?.direction,
            x: getValues('x') as number,
            y: getValues('y') as number,
          },
          lookForDepBin: true,
        });
      };

      openModal(
        {
          type: ModalTypes.binCreate,
          forceArea: watchAreaId,
        },
        { beforeClose: reopenCreateDoorModal },
      );
    }
  }, [watchBinId]);

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = (fields: DoorUpdateInput) => {
    void updateDoor({
      variables: {
        id: modal.door?.id,
        updatedDoor: stripUnchangedFields(defaultValues, fields),
      },
    });
  };

  const onDelete = () => {
    void deleteDoor({ variables: { id: modal.door?.id } });
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Controller
                name="code"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.code') }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    autoFocus
                    fullWidth
                    required
                    id="door-code"
                    label={t('common.code')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={(e) => field.onChange(uppercase(e))}
                    dataTestId="door-code"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="areaId"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.area') }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    required
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="door-areaId"
                    label={t('common.area')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="door-areaId"
                  >
                    <option disabled />
                    {areas.map((area) => (
                      <option key={`area-${area.id}`} value={area.id}>
                        {area.name}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="binId"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.bin') }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    required
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="door-binId"
                    label={t('common.bin')}
                    {...field}
                    disabled={!watchAreaId}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="door-binId"
                  >
                    <option disabled />
                    <option value={NEW_BIN_VALUE}>{t('doors.newBin')}</option>
                    {binsList.map((bin) => (
                      <option key={`bin-${bin.id}`} value={bin.id}>
                        {bin.code}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="direction"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('doors.columns.direction'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    required
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="door-direction"
                    label={t('doors.columns.direction')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="door-direction"
                  >
                    <option disabled />
                    {enumKeys(DoorDirection).map((direction) => {
                      return (
                        <option key={`direction-${direction}`} value={DoorDirection[direction]}>
                          {t(`common.${DoorDirection[direction]}`)}
                        </option>
                      );
                    })}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="x"
                control={control}
                rules={{
                  validate: fieldIsInteger,
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    dataTestId="doors-x"
                    label={t('common.x')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    type="number"
                    onChange={(e) => field.onChange(allowOnlyNumeric(e, false))}
                    value={formatAsInteger(field.value)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="y"
                control={control}
                rules={{
                  validate: fieldIsInteger,
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    id="doors-y"
                    dataTestId="doors-y"
                    label={t('common.y')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    type="number"
                    onChange={(e) => field.onChange(allowOnlyNumeric(e, false))}
                    value={formatAsInteger(field.value)}
                  />
                )}
              />
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions>
          {featureFlags.doors.allowDelete && (
            <ModalButton
              onClick={onDelete}
              variant="outlined"
              color="error"
              sx={{ marginRight: 'auto' }}
              actionType="delete"
            >
              {t('common.delete')}
            </ModalButton>
          )}
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton variant="contained" color="primary" actionType="submit">
            {t('common.submit')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default UpdateDoorModal;
