/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
export type BaseWarehouseFragment = { __typename?: 'Warehouse', id: string, code: string, name?: string | null };

export const BaseWarehouseFragmentDoc = gql`
    fragment BaseWarehouse on Warehouse {
  id
  code
  name
}
    `;