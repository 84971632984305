import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdateLicensePlateStockStatusMutation } from '@/graphql/defs/components/modals/__generated__/update-license-plate-stock-status-modal.generated';
import { useListAllStockStatusTypesQuery } from '@/graphql/defs/list/__generated__/list-stock-status-types.generated';
import { ListAllStockStatusTypes_defaultData } from '@/graphql/defs/list/list-stock-status-types';
import { IColumn, JustifiedColumns } from '@components/justified-columns';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import FormValues from '@models/FormValues';
import { IUpdateLicensePlateStockStatusModal } from '@models/modal';
import Typography from '@styled/Typography';

export interface IUpdateLicensePlateStockStatusDetails {
  licensePlateId: string;
  licensePlateCode: string;
  areaCode: string;
  binCode: string;
  quantity: string;
  unitOfMeasure: string;
  stockStatus: string;
}

const UpdateLicensePlateStockStatusModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal, isPreparing, setPreparing, setLoading } =
    useModalContent<IUpdateLicensePlateStockStatusModal>();

  const [updateStockStatus] = useUpdateLicensePlateStockStatusMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t('modal.updateLicensePlateStockStatus.success', {
          lpCode: modal.licensePlateDetails?.licensePlateCode,
        }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({ type: 'error', message: error.message });
    },
  });

  const { data: stockStatusData } = useListAllStockStatusTypesQuery({
    onCompleted: () => {
      setPreparing(false);
    },
    onError: (error) => {
      setPreparing(false);
      showMessage({ type: 'error', message: error.message });
    },
  });
  const {
    viewStockStatuses: { stockStatusTypes },
  } = useMemo(() => stockStatusData || ListAllStockStatusTypes_defaultData, [stockStatusData]);

  interface IUpdateLicensePlateStockStatusFields {
    stockStatusId: string;
  }
  const formMethods = useForm<FormValues<IUpdateLicensePlateStockStatusFields>>({
    defaultValues: {
      stockStatusId: '',
    },
  });

  const licensePlateDetails: IColumn[] = [
    {
      labelWidth: '150px',
      lineItems: [
        {
          label: t('common.licensePlate'),
          value: modal.licensePlateDetails?.licensePlateCode,
          removeBold: true,
          boldLabel: true,
        },
        {
          label: t('common.area'),
          value: modal.licensePlateDetails?.areaCode,
          removeBold: true,
        },
        {
          label: t('common.bin'),
          value: modal.licensePlateDetails?.binCode,
          removeBold: true,
        },
        {
          label: t('common.stockStatus'),
          value: modal.licensePlateDetails?.stockStatus,
          removeBold: true,
        },
        {
          label: t('common.currentCount'),
          value: `${modal.licensePlateDetails?.quantity} ${modal.licensePlateDetails?.unitOfMeasure}`,
        },
      ],
    },
  ];

  const onSubmit = (fields: IUpdateLicensePlateStockStatusFields) => {
    void updateStockStatus({
      variables: {
        licensePlateId: modal.licensePlateDetails?.licensePlateId,
        stockStatusId: fields.stockStatusId,
      },
    });
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={formMethods.handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <JustifiedColumns columns={licensePlateDetails} />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ margin: '20px 0' }}>
                {t('modal.updateLicensePlateStockStatus.text')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="stockStatusId"
                control={formMethods.control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.stockStatus') }),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    autoFocus
                    fullWidth
                    required
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="stockStatusId"
                    label={t('common.stockStatus')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="stockStatusId"
                  >
                    <option disabled />
                    {stockStatusTypes.map((type) => (
                      <option key={`stockStatus-${type.id}`} value={type.id}>
                        {`${type.label}`}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions>
          <ModalButton
            onClick={() => closeModal()}
            variant="outlined"
            color="primary"
            actionType="cancel"
          >
            {t('common.cancel')}
          </ModalButton>
          <ModalButton variant="contained" color="primary" actionType="submit">
            {t('common.submit')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default UpdateLicensePlateStockStatusModal;
