import { Box, keyframes, styled } from '@mui/material';
import { memo } from 'react';

const Shine = keyframes`
	0% {
		left: -100%
	}
	50% {
		left: 0
	}
	100% {
		left: 100%
	}
`;

// The memo is helpful in keeping the animation smooth while other re-renders are happening.
// Since this component never changes on re-render, and will only be unmounted when the load state is turned off,
// we can tell React to always use the memo by just returning true for the equality check.
const LoadingShine = memo(
  styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '-100%',
    zIndex: 4,
    width: '100%',
    background:
      'linear-gradient(117deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 40%, rgba(255,255,255,.25) 45%, rgba(255,255,255,.75) 50%, rgba(255,255,255,.25) 55%, rgba(0,0,0,0) 60%, rgba(0,0,0,0) 100%), transparent',
    animation: `2s linear 0s infinite ${Shine}`,
  })),
  () => true,
);

export default LoadingShine;
