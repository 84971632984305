import {
  Box,
  CircularProgress,
  CircularProgressProps,
  circularProgressClasses,
  BoxProps,
  PaperProps,
  Paper,
} from '@mui/material';
import React, { FC } from 'react';

interface ILoadingIndicatorProps extends BoxProps {
  iconProps?: CircularProgressProps;
  backgroundIconProps?: CircularProgressProps;
  removeBackground?: boolean;
}
const LoadingIndicator: React.FC<ILoadingIndicatorProps> = ({
  iconProps = {},
  backgroundIconProps = {},
  removeBackground = false,
  ...boxProps
}) => {
  return (
    <Box position="relative" {...boxProps}>
      {!removeBackground && (
        <CircularProgress
          variant="determinate"
          size={40}
          thickness={4}
          value={100}
          {...backgroundIconProps}
          sx={{
            color: (theme) => theme.palette.gainsboro.main,
            ...(!!backgroundIconProps?.sx && backgroundIconProps.sx),
          }}
        />
      )}
      <CircularProgress
        variant="indeterminate"
        size={40}
        thickness={4}
        {...iconProps}
        sx={{
          color: (theme) => theme.palette.secondary.main,
          animationDuration: '3000ms',
          position: !removeBackground ? 'absolute' : undefined,
          left: !removeBackground ? 0 : undefined,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
          ...(!!iconProps?.sx && iconProps.sx),
        }}
      />
    </Box>
  );
};

export const CenteredLoadingIndicator: FC<{
  boxProps?: BoxProps;
  indicatorProps?: ILoadingIndicatorProps;
}> = ({ boxProps = {}, indicatorProps = {} }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" {...boxProps}>
      <LoadingIndicator {...indicatorProps} />
    </Box>
  );
};

export const ElevatedLoadingIndicator: FC<{
  paperProps?: PaperProps;
  indicatorProps?: ILoadingIndicatorProps;
}> = ({ paperProps = {}, indicatorProps = {} }) => {
  return (
    <Paper elevation={6} sx={{ padding: 2 }} {...paperProps}>
      <LoadingIndicator {...indicatorProps} />
    </Paper>
  );
};

export const FullPageLoadingIndicator: FC<{
  boxProps?: BoxProps;
  indicatorProps?: ILoadingIndicatorProps;
}> = ({ boxProps = {}, indicatorProps = {} }) => (
  <Box display="flex" alignItems="center" justifyContent="center" height="100%" {...boxProps}>
    <LoadingIndicator {...indicatorProps} />
  </Box>
);

export default LoadingIndicator;
