import { Grid } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useDeleteEquipmentModelMutation,
  useUpdateEquipmentModelMutation,
} from '@/graphql/defs/components/modals/__generated__/update-equipment-model-modal.generated';
import {
  ListEquipmentTypesQuery,
  useListEquipmentTypesQuery,
} from '@/graphql/defs/list/__generated__/list-equipment-types.generated';
import { EquipmentModelUpdateInput } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import { stripUnchangedFields, uppercase } from '@lib/form';
import FormValues from '@models/FormValues';
import { IEquipmentModelUpdateModal } from '@models/modal';

const UpdateEquipmentModelModal = () => {
  const { modal, closeModal, isPreparing, setPreparing, setLoading } =
    useModalContent<IEquipmentModelUpdateModal>();
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();

  const defaultValues: FormValues<EquipmentModelUpdateInput> = {
    code: modal.equipmentModel.code,
    label: modal.equipmentModel.label,
    description: modal.equipmentModel.description,
    equipmentTypeId: modal.equipmentModel.equipmentTypeId,
  };
  const formMethods = useForm<FormValues<EquipmentModelUpdateInput>>({
    defaultValues,
  });
  const { handleSubmit, control } = formMethods;
  const { notWhiteSpaceOnly } = useFormValidation();

  const [equipmentTypes, setEquipmentTypes] = useState<
    ListEquipmentTypesQuery['listEquipmentTypes']['equipmentTypes']
  >([]);

  useListEquipmentTypesQuery({
    onCompleted: ({ listEquipmentTypes: { equipmentTypes } }) => {
      setEquipmentTypes(equipmentTypes);
      setPreparing(false);
    },
    onError: (error) => {
      setPreparing(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const [deleteEquipmentModel] = useDeleteEquipmentModelMutation({
    variables: { id: modal.equipmentModel.id },
    onCompleted: ({ deleteOneEquipmentModel: { label } }) => {
      showMessage({
        type: 'success',
        message: t('modal.equipment.model.successDelete', { label }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const [updateEquipmentModel] = useUpdateEquipmentModelMutation({
    onCompleted: ({ updateOneEquipmentModel: { label } }) => {
      showMessage({
        type: 'success',
        message: t('modal.equipment.model.successUpdate', { label }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onSubmit = (fields: EquipmentModelUpdateInput) => {
    void updateEquipmentModel({
      variables: {
        id: modal.equipmentModel.id,
        updatedModel: stripUnchangedFields(defaultValues, {
          code: fields.code,
          label: fields.label,
          description: fields.description,
          equipmentTypeId: fields.equipmentTypeId,
        }),
      },
    });
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Grid container minWidth="600px" spacing={6}>
            <Grid item xs={6}>
              <Controller
                name="equipmentTypeId"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.equipmentType'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      ref={null}
                      autoFocus
                      fullWidth
                      required
                      select
                      SelectProps={{
                        native: true,
                      }}
                      id="equipment-typeId"
                      label={t('common.equipmentType')}
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      dataTestId="equipment-typeId"
                    >
                      <option disabled />
                      {equipmentTypes.map((model) => {
                        return (
                          <option key={`equipmentType-${model.code}`} value={model.id}>
                            {model.label}
                          </option>
                        );
                      })}
                    </TextField>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Controller
                name="code"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.code'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      ref={null}
                      required
                      id="code"
                      fullWidth
                      label={t('common.code')}
                      variant="outlined"
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      onChange={(e) => field.onChange(uppercase(e))}
                      dataTestId="equipment-model-code"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="label"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.label'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      ref={null}
                      required
                      id="label"
                      fullWidth
                      label={t('common.label')}
                      variant="outlined"
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      dataTestId="equipment-model-label"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      ref={null}
                      id="description"
                      fullWidth
                      label={t('common.description')}
                      variant="outlined"
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      dataTestId="equipment-model-description"
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions>
          <ModalButton
            onClick={() => deleteEquipmentModel()}
            variant="outlined"
            color="error"
            sx={{ marginRight: 'auto' }}
            actionType="delete"
          >
            {t('common.delete')}
          </ModalButton>
          <ModalButton
            onClick={() => closeModal()}
            variant="outlined"
            color="primary"
            actionType="cancel"
          >
            {t('common.cancel')}
          </ModalButton>
          <ModalButton
            data-testid="updateEquipmentModel_submit"
            variant="contained"
            color="primary"
            actionType="submit"
          >
            {t('common.save')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default UpdateEquipmentModelModal;
