/* eslint-disable */
import * as Types from '../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DistanceUoMFragment = { __typename?: 'UnitOfMeasureGlossary', id: string, code: string };

export type DistanceUnitOfMeasuresQueryVariables = Types.Exact<{
  warehouseId: Types.Scalars['ID']['input'];
}>;


export type DistanceUnitOfMeasuresQuery = { __typename?: 'Query', unitOfMeasureWarehousePreference: Array<{ __typename?: 'UnitOfMeasureGlossary', id: string, code: string }> };

export const DistanceUoMFragmentDoc = gql`
    fragment DistanceUoM on UnitOfMeasureGlossary {
  id
  code
}
    `;
export const DistanceUnitOfMeasuresDocument = gql`
    query DistanceUnitOfMeasures($warehouseId: ID!) {
  unitOfMeasureWarehousePreference(category: distance, warehouseId: $warehouseId) {
    ...DistanceUoM
  }
}
    ${DistanceUoMFragmentDoc}`;

/**
 * __useDistanceUnitOfMeasuresQuery__
 *
 * To run a query within a React component, call `useDistanceUnitOfMeasuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistanceUnitOfMeasuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistanceUnitOfMeasuresQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useDistanceUnitOfMeasuresQuery(baseOptions: Apollo.QueryHookOptions<DistanceUnitOfMeasuresQuery, DistanceUnitOfMeasuresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistanceUnitOfMeasuresQuery, DistanceUnitOfMeasuresQueryVariables>(DistanceUnitOfMeasuresDocument, options);
      }
export function useDistanceUnitOfMeasuresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistanceUnitOfMeasuresQuery, DistanceUnitOfMeasuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistanceUnitOfMeasuresQuery, DistanceUnitOfMeasuresQueryVariables>(DistanceUnitOfMeasuresDocument, options);
        }
export type DistanceUnitOfMeasuresQueryHookResult = ReturnType<typeof useDistanceUnitOfMeasuresQuery>;
export type DistanceUnitOfMeasuresLazyQueryHookResult = ReturnType<typeof useDistanceUnitOfMeasuresLazyQuery>;
export type DistanceUnitOfMeasuresQueryResult = Apollo.QueryResult<DistanceUnitOfMeasuresQuery, DistanceUnitOfMeasuresQueryVariables>;