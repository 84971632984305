import { gql } from '@apollo/client';

import { ListAllSapStockStatusTypesQuery } from '@/graphql/defs/list/__generated__/list-sap-stock-status-types.generated';

gql`
  fragment ListedSapStockStatusType on SapStockStatusType {
    id
    code
    label
  }
  query ListAllSapStockStatusTypes {
    listAllSapStockStatusTypes: sapStockStatusTypes(
      paging: { offset: 0, limit: 1000000 }
      sorting: [{ field: label, direction: ASC }]
    ) {
      sapStockStatusTypes: nodes {
        ...ListedSapStockStatusType
      }
    }
  }
`;

export const ListAllSapStockStatusTypes_defaultData: ListAllSapStockStatusTypesQuery = {
  __typename: 'Query',
  listAllSapStockStatusTypes: {
    __typename: 'SapStockStatusTypeOffsetConnection',
    sapStockStatusTypes: [],
  },
};
