/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUnloadTaskDeliveryItemQueryVariables = Types.Exact<{
  deliveryItemId: Types.Scalars['ID']['input'];
}>;


export type GetUnloadTaskDeliveryItemQuery = { __typename?: 'Query', viewDeliveryItems: { __typename?: 'ViewDeliveryItemOffsetConnection', nodes: Array<{ __typename?: 'ViewDeliveryItem', id?: string | null, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null }> } };

export type GetUnloadDoorStagingBinQueryVariables = Types.Exact<{
  doorId: Types.Scalars['ID']['input'];
}>;


export type GetUnloadDoorStagingBinQuery = { __typename?: 'Query', door: { __typename?: 'Door', id: string, binId?: string | null } };

export type GetUnloadDoorBinInfoQueryVariables = Types.Exact<{
  binId: Types.Scalars['ID']['input'];
}>;


export type GetUnloadDoorBinInfoQuery = { __typename?: 'Query', bin: { __typename?: 'Bin', id: string, code: string } };

export type CompleteUnloadTaskMutationVariables = Types.Exact<{
  input: Types.TaskCompleteOneUnloadInput;
}>;


export type CompleteUnloadTaskMutation = { __typename?: 'Mutation', completeOneFFUnloadMovementTask: { __typename?: 'Task', id: string, code: string } };


export const GetUnloadTaskDeliveryItemDocument = gql`
    query GetUnloadTaskDeliveryItem($deliveryItemId: ID!) {
  viewDeliveryItems(filter: {id: {eq: $deliveryItemId}}) {
    nodes {
      id
      licensePlateId
      licensePlateCode
      licensePlateDescription
    }
  }
}
    `;

/**
 * __useGetUnloadTaskDeliveryItemQuery__
 *
 * To run a query within a React component, call `useGetUnloadTaskDeliveryItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnloadTaskDeliveryItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnloadTaskDeliveryItemQuery({
 *   variables: {
 *      deliveryItemId: // value for 'deliveryItemId'
 *   },
 * });
 */
export function useGetUnloadTaskDeliveryItemQuery(baseOptions: Apollo.QueryHookOptions<GetUnloadTaskDeliveryItemQuery, GetUnloadTaskDeliveryItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnloadTaskDeliveryItemQuery, GetUnloadTaskDeliveryItemQueryVariables>(GetUnloadTaskDeliveryItemDocument, options);
      }
export function useGetUnloadTaskDeliveryItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnloadTaskDeliveryItemQuery, GetUnloadTaskDeliveryItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnloadTaskDeliveryItemQuery, GetUnloadTaskDeliveryItemQueryVariables>(GetUnloadTaskDeliveryItemDocument, options);
        }
export type GetUnloadTaskDeliveryItemQueryHookResult = ReturnType<typeof useGetUnloadTaskDeliveryItemQuery>;
export type GetUnloadTaskDeliveryItemLazyQueryHookResult = ReturnType<typeof useGetUnloadTaskDeliveryItemLazyQuery>;
export type GetUnloadTaskDeliveryItemQueryResult = Apollo.QueryResult<GetUnloadTaskDeliveryItemQuery, GetUnloadTaskDeliveryItemQueryVariables>;
export const GetUnloadDoorStagingBinDocument = gql`
    query GetUnloadDoorStagingBin($doorId: ID!) {
  door(id: $doorId) {
    id
    binId
  }
}
    `;

/**
 * __useGetUnloadDoorStagingBinQuery__
 *
 * To run a query within a React component, call `useGetUnloadDoorStagingBinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnloadDoorStagingBinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnloadDoorStagingBinQuery({
 *   variables: {
 *      doorId: // value for 'doorId'
 *   },
 * });
 */
export function useGetUnloadDoorStagingBinQuery(baseOptions: Apollo.QueryHookOptions<GetUnloadDoorStagingBinQuery, GetUnloadDoorStagingBinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnloadDoorStagingBinQuery, GetUnloadDoorStagingBinQueryVariables>(GetUnloadDoorStagingBinDocument, options);
      }
export function useGetUnloadDoorStagingBinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnloadDoorStagingBinQuery, GetUnloadDoorStagingBinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnloadDoorStagingBinQuery, GetUnloadDoorStagingBinQueryVariables>(GetUnloadDoorStagingBinDocument, options);
        }
export type GetUnloadDoorStagingBinQueryHookResult = ReturnType<typeof useGetUnloadDoorStagingBinQuery>;
export type GetUnloadDoorStagingBinLazyQueryHookResult = ReturnType<typeof useGetUnloadDoorStagingBinLazyQuery>;
export type GetUnloadDoorStagingBinQueryResult = Apollo.QueryResult<GetUnloadDoorStagingBinQuery, GetUnloadDoorStagingBinQueryVariables>;
export const GetUnloadDoorBinInfoDocument = gql`
    query GetUnloadDoorBinInfo($binId: ID!) {
  bin(id: $binId) {
    id
    code
  }
}
    `;

/**
 * __useGetUnloadDoorBinInfoQuery__
 *
 * To run a query within a React component, call `useGetUnloadDoorBinInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnloadDoorBinInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnloadDoorBinInfoQuery({
 *   variables: {
 *      binId: // value for 'binId'
 *   },
 * });
 */
export function useGetUnloadDoorBinInfoQuery(baseOptions: Apollo.QueryHookOptions<GetUnloadDoorBinInfoQuery, GetUnloadDoorBinInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnloadDoorBinInfoQuery, GetUnloadDoorBinInfoQueryVariables>(GetUnloadDoorBinInfoDocument, options);
      }
export function useGetUnloadDoorBinInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnloadDoorBinInfoQuery, GetUnloadDoorBinInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnloadDoorBinInfoQuery, GetUnloadDoorBinInfoQueryVariables>(GetUnloadDoorBinInfoDocument, options);
        }
export type GetUnloadDoorBinInfoQueryHookResult = ReturnType<typeof useGetUnloadDoorBinInfoQuery>;
export type GetUnloadDoorBinInfoLazyQueryHookResult = ReturnType<typeof useGetUnloadDoorBinInfoLazyQuery>;
export type GetUnloadDoorBinInfoQueryResult = Apollo.QueryResult<GetUnloadDoorBinInfoQuery, GetUnloadDoorBinInfoQueryVariables>;
export const CompleteUnloadTaskDocument = gql`
    mutation CompleteUnloadTask($input: TaskCompleteOneUnloadInput!) {
  completeOneFFUnloadMovementTask(completeUnloadTask: $input) {
    id
    code
  }
}
    `;
export type CompleteUnloadTaskMutationFn = Apollo.MutationFunction<CompleteUnloadTaskMutation, CompleteUnloadTaskMutationVariables>;

/**
 * __useCompleteUnloadTaskMutation__
 *
 * To run a mutation, you first call `useCompleteUnloadTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteUnloadTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeUnloadTaskMutation, { data, loading, error }] = useCompleteUnloadTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteUnloadTaskMutation(baseOptions?: Apollo.MutationHookOptions<CompleteUnloadTaskMutation, CompleteUnloadTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteUnloadTaskMutation, CompleteUnloadTaskMutationVariables>(CompleteUnloadTaskDocument, options);
      }
export type CompleteUnloadTaskMutationHookResult = ReturnType<typeof useCompleteUnloadTaskMutation>;
export type CompleteUnloadTaskMutationResult = Apollo.MutationResult<CompleteUnloadTaskMutation>;
export type CompleteUnloadTaskMutationOptions = Apollo.BaseMutationOptions<CompleteUnloadTaskMutation, CompleteUnloadTaskMutationVariables>;