import { DateTime, DurationLikeObject, DurationUnits } from 'luxon';

import { FullDisplayPrefFragment } from '@/graphql/defs/components/modals/__generated__/display-preferences-modal.generated';
import { DATE_TIME_FORMATS_CONVERT, DEFAULT_DATE_TIME_OBJECT } from '@constants/date-time-formats';

export interface IDateTimePref {
  date: string;
  time: string;
  dateTime: string;
}
export interface IDisplayParams {
  date: string | number;
  format?: string;
  defaultFormat: string;
  timezone?: string;
  showTimezone?: boolean;
}
export type IDisplayOpts = Omit<IDisplayParams, 'defaultFormat'>;
export type IDisplayFunc = (opts: IDisplayOpts) => string;

export const display = ({
  date,
  format,
  defaultFormat,
  timezone,
  showTimezone,
}: IDisplayParams): string => {
  if (!date) return '';

  const dateTime =
    typeof date === 'number'
      ? DateTime.fromMillis(date).setLocale('en').setZone(timezone)
      : DateTime.fromISO(date).setLocale('en').setZone(timezone);

  // Manually appending ZZZZ if showTimezone is true, as the mobile app doesn't support this token as of yet so we can't save it to the DB.
  const dtFormat = format || defaultFormat;
  return dateTime.isValid ? dateTime.toFormat(showTimezone ? `${dtFormat} ZZZZ` : dtFormat) : '';
};

export const createDateTimeFormatObject = (displayPreference: FullDisplayPrefFragment) => {
  return displayPreference?.dateTimeFormat &&
    DATE_TIME_FORMATS_CONVERT[displayPreference?.dateTimeFormat]
    ? DATE_TIME_FORMATS_CONVERT[displayPreference?.dateTimeFormat]
    : DEFAULT_DATE_TIME_OBJECT;
};

export const getDateRangeDuration = (
  startDate: string | number,
  endDate: string | number,
  durationUnits: DurationUnits,
) => {
  const startDateTime =
    typeof startDate === 'number' ? DateTime.fromMillis(startDate) : DateTime.fromISO(startDate);
  const endDateTime =
    typeof endDate === 'number' ? DateTime.fromMillis(endDate) : DateTime.fromISO(endDate);

  if (!startDateTime.isValid || !endDateTime.isValid) {
    const _default: Record<keyof DurationLikeObject, 0> = {
      years: 0,
      year: 0,
      quarters: 0,
      quarter: 0,
      months: 0,
      month: 0,
      weeks: 0,
      week: 0,
      days: 0,
      day: 0,
      hours: 0,
      hour: 0,
      minutes: 0,
      minute: 0,
      seconds: 0,
      second: 0,
      milliseconds: 0,
      millisecond: 0,
    };
    return _default;
  }

  const diff = endDateTime.diff(startDateTime, durationUnits);

  return diff.toObject();
};
