import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DeliveryCompletionStatus,
  InventoryBaseQueryShapeSortFields,
} from '@/graphql/types.generated';
import {
  ColumnFilter,
  FilterOperators,
} from '@components/data-table/controls/filter/filter-definitions';
import useCreateBaseInventoryColumns, {
  BASE_INV_ALL_HOOK,
  TBaseInvDataType,
  TBaseInvFieldNames,
  TBaseInvFilterType,
} from '@components/data-table/hooks/shared-columns/useCreateBaseInventoryColumns';
import useCreateDataTableColumns from '@components/data-table/hooks/useCreateDataTableColumns';
import useDataTableSelection, {
  SelectionType,
} from '@components/data-table/hooks/useDataTableSelection';
import useTriggerDataTableRefetch from '@components/data-table/hooks/useTriggerDataTableRefetch';
import { validateDataTableProps } from '@components/data-table/lib/validateDataTableProps';
import { IDataTableSort } from '@components/data-table/model/data-table';
import TableIds from '@components/data-table/model/TableIds';
import { IDropdownMenuItem } from '@components/dropdown-menu';
import { useWarehouseFeatureFlags } from '@context/warehouse-feature-flags';
import { InboundDeliveryItemsView } from '@pages/deliveries/inbound-deliveries/tables/InboundDeliveryItemsInvDataTables';

const useInboundDeliveryInventoryDataTable = (
  deliveryId: string,
  pickOrPutawayStatus: DeliveryCompletionStatus,
  deliveryItemViews: IDropdownMenuItem<InboundDeliveryItemsView>[],
) => {
  const { t } = useTranslation('pages');

  // TODO: We need a way to give this table a unique ID from the query response
  const { warehouseFeatureFlags: featureFlags } = useWarehouseFeatureFlags();
  const { selection, clearSelection, rowSelection } = useDataTableSelection<TBaseInvDataType>(
    pickOrPutawayStatus !== DeliveryCompletionStatus.Complete &&
      featureFlags.inboundDeliveries.manageInboundLPs
      ? SelectionType.multi
      : SelectionType.none,
    pickOrPutawayStatus !== DeliveryCompletionStatus.Complete &&
      featureFlags.inboundDeliveries.manageInboundLPs
      ? 'deliveryItemId'
      : undefined,
  );
  const [refetchTrigger, triggerDataTableRefetch] = useTriggerDataTableRefetch({
    triggerCallback: clearSelection,
  });

  const createInventoryColumns = useCreateBaseInventoryColumns({
    dataTestId: 'inbound-delivery-inventory',
    removeColumns: ['binCode', 'areaCode'],
  });
  const columns = useCreateDataTableColumns<TBaseInvDataType>(createInventoryColumns);

  const baseFilter = useMemo<ColumnFilter<TBaseInvFieldNames>[]>(
    () => [
      {
        columnId: InventoryBaseQueryShapeSortFields.StockDeliveryId,
        operator: FilterOperators.eq,
        value: deliveryId,
      },
      {
        columnId: InventoryBaseQueryShapeSortFields.BinType,
        operator: FilterOperators.eq,
        value: 'planned',
      },
    ],
    [deliveryId],
  );

  const defaultSorting: IDataTableSort<TBaseInvFieldNames>[] = useMemo(
    () => [
      {
        id: InventoryBaseQueryShapeSortFields.ProductCode,
        desc: false,
      },
    ],
    [],
  );

  return {
    selectedDeliveryInventory: selection,
    triggerIbdDeliveryInventoryDataTableRefetch: triggerDataTableRefetch,
    ibdDeliveryInventoryDataTableProps: validateDataTableProps<
      TBaseInvDataType,
      TBaseInvFilterType,
      TBaseInvFieldNames
    >({
      type: 'query',
      tableId: TableIds.InboundDeliveryInventory,
      tableHeader: t(`deliveries.deliveryItems.${InboundDeliveryItemsView.Inventory}`),
      layoutDropdownAddtOptions: deliveryItemViews,
      layoutDropdownAddtOptionLabel: t('deliveries.deliveryItemViews'),
      queryHook: BASE_INV_ALL_HOOK,
      columns,
      baseFilter,
      defaultSorting,
      refetchTrigger,
      rowSelection,
    }),
  };
};

export default useInboundDeliveryInventoryDataTable;
