import { Autocomplete, FormControl, FormHelperText } from '@mui/material';
import React, { useState } from 'react';
import { ControllerFieldState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextField from '@components/text-field';
import { WORLD_COUNTRIES } from '@constants/world-countries';
import { isUS } from '@lib/utils';

const CountriesDropdown: React.FC<{
  field: any;
  fieldState: ControllerFieldState;
  required?: boolean;
  setValue: any;
  statesDropdown: boolean;
  setStatesDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ field, fieldState, required = false, setValue, statesDropdown, setStatesDropdown }) => {
  const { t } = useTranslation('components', { keyPrefix: 'field-blocks.contactInformation' });

  const [countryValue, setCountryValue] = useState(field.value || '');

  return (
    <FormControl fullWidth error={!!fieldState?.error}>
      <Autocomplete
        multiple={false}
        id="company-country"
        data-testid="company-country-dropdown"
        autoHighlight={true}
        options={Object.values(WORLD_COUNTRIES).map((country) => country.name)}
        value={countryValue}
        onChange={(event, item, reason) => {
          if (reason === 'selectOption' || reason === 'clear') {
            setCountryValue(item);
            field.onChange(item);

            const shouldUseDropdown = isUS(item);
            if (shouldUseDropdown !== statesDropdown) {
              setValue('state', '');
              setStatesDropdown(shouldUseDropdown);
            }
          }
        }}
        isOptionEqualToValue={(option, value) => {
          // Accept empty string
          if (value === '' || value === option) {
            return true;
          }
        }}
        renderInput={(params) => (
          <TextField
            {...field}
            {...params}
            required={required}
            label={t('country')}
            dataTestId="country-dropdown-input"
          />
        )}
      />
      {fieldState.error && <FormHelperText>{fieldState?.error?.message}</FormHelperText>}
    </FormControl>
  );
};

export default CountriesDropdown;
