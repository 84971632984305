/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SlottingMovementRowFragment = { __typename?: 'SlottingMovement', id?: string | null, run_id?: string | null, source_bin?: string | null, material: string, material_description?: string | null, destination_bin?: string | null, current_max_quantity?: number | null, proposed_max_quantity?: number | null, current_min_quantity?: number | null, proposed_min_quantity?: number | null, distance_saved?: number | null, money_saved?: number | null, importance?: number | null, process_dt: string };

export type SlottingMovementsDataTableQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.SlottingMovementFilter>;
  sorting?: Types.InputMaybe<Array<Types.SlottingMovementSort> | Types.SlottingMovementSort>;
  includePageInfo?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeNodes?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeTotalCount?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type SlottingMovementsDataTableQuery = { __typename?: 'Query', query: { __typename?: 'SlottingMovementOffsetConnection', totalCount?: number, pageInfo?: { __typename?: 'OffsetPageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null }, nodes?: Array<{ __typename?: 'SlottingMovement', id?: string | null, run_id?: string | null, source_bin?: string | null, material: string, material_description?: string | null, destination_bin?: string | null, current_max_quantity?: number | null, proposed_max_quantity?: number | null, current_min_quantity?: number | null, proposed_min_quantity?: number | null, distance_saved?: number | null, money_saved?: number | null, importance?: number | null, process_dt: string }> } };

export const SlottingMovementRowFragmentDoc = gql`
    fragment SlottingMovementRow on SlottingMovement {
  id
  run_id
  source_bin
  material
  material_description
  destination_bin
  current_max_quantity
  proposed_max_quantity
  current_min_quantity
  proposed_min_quantity
  distance_saved
  money_saved
  importance
  process_dt
}
    `;
export const SlottingMovementsDataTableDocument = gql`
    query SlottingMovementsDataTable($paging: OffsetPaging, $filter: SlottingMovementFilter, $sorting: [SlottingMovementSort!], $includePageInfo: Boolean = true, $includeNodes: Boolean = true, $includeTotalCount: Boolean = false) {
  query: slottingMovements(paging: $paging, filter: $filter, sorting: $sorting) {
    pageInfo @include(if: $includePageInfo) {
      hasNextPage
      hasPreviousPage
    }
    nodes @include(if: $includeNodes) {
      ...SlottingMovementRow
    }
    totalCount @include(if: $includeTotalCount)
  }
}
    ${SlottingMovementRowFragmentDoc}`;

/**
 * __useSlottingMovementsDataTableQuery__
 *
 * To run a query within a React component, call `useSlottingMovementsDataTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlottingMovementsDataTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlottingMovementsDataTableQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      includePageInfo: // value for 'includePageInfo'
 *      includeNodes: // value for 'includeNodes'
 *      includeTotalCount: // value for 'includeTotalCount'
 *   },
 * });
 */
export function useSlottingMovementsDataTableQuery(baseOptions?: Apollo.QueryHookOptions<SlottingMovementsDataTableQuery, SlottingMovementsDataTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SlottingMovementsDataTableQuery, SlottingMovementsDataTableQueryVariables>(SlottingMovementsDataTableDocument, options);
      }
export function useSlottingMovementsDataTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SlottingMovementsDataTableQuery, SlottingMovementsDataTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SlottingMovementsDataTableQuery, SlottingMovementsDataTableQueryVariables>(SlottingMovementsDataTableDocument, options);
        }
export type SlottingMovementsDataTableQueryHookResult = ReturnType<typeof useSlottingMovementsDataTableQuery>;
export type SlottingMovementsDataTableLazyQueryHookResult = ReturnType<typeof useSlottingMovementsDataTableLazyQuery>;
export type SlottingMovementsDataTableQueryResult = Apollo.QueryResult<SlottingMovementsDataTableQuery, SlottingMovementsDataTableQueryVariables>;