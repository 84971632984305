// Created after using the below as a starting point, and then expanded upon to allow for dynamic itemSize and height setting.
// https://stackoverflow.com/questions/59256947/how-can-i-use-material-ui-autocomplete-together-with-react-virtualized

import { Box } from '@mui/material';
import * as React from 'react';
import { VariableSizeList } from 'react-window';

export const VirtualListboxContext = React.createContext({
  getItemSize: (index): number => 48,
  setItemSize: (index, size): void => {},
  virtualListboxRef: React.createRef(),
});

const Row = ({ data, index, style }) => {
  const elem = data[index];
  return (
    <div style={style}>
      <React.Fragment>{elem}</React.Fragment>
    </div>
  );
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);

  // @ts-ignore
  const extendedStyleProps = { ...props, style: { ...props.style, padding: 0, margin: '8px 0' } };

  return <Box ref={ref} {...extendedStyleProps} {...outerProps} />;
});

const VirtualizedList = React.forwardRef<
  HTMLDivElement,
  {
    maxListHeight: number;
    children: React.ReactNode[];
  }
>((props, ref) => {
  const { getItemSize, virtualListboxRef } = React.useContext(VirtualListboxContext);
  const items = props.children as React.ReactNode[];
  const itemCount = items.length;
  const outerProps = { ...props };
  // Remove these props so they are not added as part of OuterElementType
  delete outerProps.children;
  delete outerProps.maxListHeight;

  let dynamicHeight = 0;
  for (let i = 0; i < items.length; i++) {
    const childSize = getItemSize(i);
    dynamicHeight += childSize;
    if (dynamicHeight > props.maxListHeight) {
      dynamicHeight = props.maxListHeight;
      break;
    }
  }

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={outerProps}>
        <VariableSizeList
          ref={virtualListboxRef}
          outerElementType={OuterElementType}
          className="List"
          height={dynamicHeight}
          itemCount={itemCount}
          itemSize={getItemSize}
          overscanCount={5}
          itemData={{ ...items }}
        >
          {Row}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const GetVirtualListbox = (maxListHeight = 250) =>
  React.forwardRef((defaultprops: any, ref) => {
    return <VirtualizedList ref={ref} {...defaultprops} maxListHeight={maxListHeight} />;
  });

export default GetVirtualListbox;
