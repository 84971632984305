const UnpackLpIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.5423 8.78301L18.5988 7.65826C18.4155 7.43063 18.1372 7.29004 17.8182 7.29004H6.18181C5.86278 7.29004 5.58448 7.43063 5.39442 7.65826L4.45769 8.78301C4.26084 9.01064 4.14545 9.31191 4.14545 9.63327V20.2974C4.14545 21.0338 4.75636 21.6363 5.50302 21.6363H18.497C19.2436 21.6363 19.8545 21.0338 19.8545 20.2974V9.63327C19.8545 9.31191 19.7391 9.01064 19.5423 8.78301ZM6.34472 8.62903H17.6553L18.497 9.87237H5.50302L6.34472 8.62903ZM5.50302 20.2974V11.2114H18.497V20.2974H5.50302Z"
        fill="currentColor"
      />
      <path
        d="M19.6346 11.6297L18.3492 10.8488L21.2747 5.24893L19.7004 4.88248L17.5784 8.51449L16.3152 8.0731L18.7017 3.32375L23.4878 4.43786L22.8912 5.6252L19.6346 11.6297Z"
        fill="currentColor"
      />
      <path
        d="M4.10849 11.6297L5.39391 10.8488L2.4684 5.24893L4.04268 4.88248L6.16472 8.51449L7.42792 8.0731L5.04143 3.32375L0.25528 4.43786L0.851906 5.6252L4.10849 11.6297Z"
        fill="currentColor"
      />
      <path
        d="M10.498 15.9572H8L11.9184 12.0021L15.8367 15.9572H13.3388V18.9235H10.498V15.9572Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UnpackLpIcon;
