/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListBusinessPartnersQueryVariables = Types.Exact<{
  sorting?: Types.InputMaybe<Array<Types.BusinessPartnerSort> | Types.BusinessPartnerSort>;
  filter?: Types.InputMaybe<Types.BusinessPartnerFilter>;
  paging?: Types.InputMaybe<Types.OffsetPaging>;
}>;


export type ListBusinessPartnersQuery = { __typename?: 'Query', listBusinessPartners: { __typename?: 'BusinessPartnerOffsetConnection', businessPartners: Array<{ __typename?: 'BusinessPartner', id: string, createdAt: string, updatedAt?: string | null, updatedByEmail?: string | null, updatedById?: string | null, deletedAt?: string | null, code: string, name?: string | null, type?: string | null, searchTerm1?: string | null, searchTerm2?: string | null, customerCode?: string | null, supplierCode?: string | null, validityStart?: string | null, validityEnd?: string | null, addressTimezone?: string | null, addressUUID?: string | null, cityName?: string | null, district?: string | null, region?: string | null, streetAddress?: string | null, postalCode?: string | null, country?: string | null, language?: string | null, defaultPhoneNumber?: string | null, phoneNumber?: string | null, email?: string | null }> } };


export const ListBusinessPartnersDocument = gql`
    query ListBusinessPartners($sorting: [BusinessPartnerSort!], $filter: BusinessPartnerFilter, $paging: OffsetPaging) {
  listBusinessPartners: businessPartners(
    sorting: $sorting
    filter: $filter
    paging: $paging
  ) {
    businessPartners: nodes {
      id
      createdAt
      updatedAt
      updatedByEmail
      updatedById
      deletedAt
      code
      name
      type
      searchTerm1
      searchTerm2
      customerCode
      supplierCode
      validityStart
      validityEnd
      addressTimezone
      addressUUID
      cityName
      district
      region
      streetAddress
      postalCode
      country
      language
      defaultPhoneNumber
      phoneNumber
      email
    }
  }
}
    `;

/**
 * __useListBusinessPartnersQuery__
 *
 * To run a query within a React component, call `useListBusinessPartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBusinessPartnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBusinessPartnersQuery({
 *   variables: {
 *      sorting: // value for 'sorting'
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useListBusinessPartnersQuery(baseOptions?: Apollo.QueryHookOptions<ListBusinessPartnersQuery, ListBusinessPartnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBusinessPartnersQuery, ListBusinessPartnersQueryVariables>(ListBusinessPartnersDocument, options);
      }
export function useListBusinessPartnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBusinessPartnersQuery, ListBusinessPartnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBusinessPartnersQuery, ListBusinessPartnersQueryVariables>(ListBusinessPartnersDocument, options);
        }
export type ListBusinessPartnersQueryHookResult = ReturnType<typeof useListBusinessPartnersQuery>;
export type ListBusinessPartnersLazyQueryHookResult = ReturnType<typeof useListBusinessPartnersLazyQuery>;
export type ListBusinessPartnersQueryResult = Apollo.QueryResult<ListBusinessPartnersQuery, ListBusinessPartnersQueryVariables>;