import { createContext, useContext, useMemo } from 'react';

import { useEntityUtils } from '@context/entity-utils';
import {
  DEFAULT_WAREHOUSE_FEATURE_FLAG_TOGGLES,
  IWarehouseFeatureFlagToggles,
  useFeatureFlags,
} from '@context/feature-flags';

interface IWarehouseFeatureFlagsContent {
  warehouseFeatureFlags: IWarehouseFeatureFlagToggles;
}
const WarehouseFeatureFlagsContext = createContext<IWarehouseFeatureFlagsContent>(null);

const WarehouseFeatureFlagsProvider = ({ children }) => {
  const { selectedWarehouse } = useEntityUtils();
  const { warehouseTypeFeatureFlags } = useFeatureFlags();

  const warehouseFeatureFlags = useMemo<IWarehouseFeatureFlagToggles>(() => {
    if (warehouseTypeFeatureFlags && selectedWarehouse?.id) {
      const warehouseModelType = selectedWarehouse?.type;
      const warehouseFeatureFlags = warehouseTypeFeatureFlags[warehouseModelType];
      return Object.assign({}, DEFAULT_WAREHOUSE_FEATURE_FLAG_TOGGLES, warehouseFeatureFlags);
    } else {
      return Object.assign({}, DEFAULT_WAREHOUSE_FEATURE_FLAG_TOGGLES);
    }
  }, [warehouseTypeFeatureFlags, selectedWarehouse]);

  // TODO: See how we can fix the layout so this extra loading page isn't needed / is only needed when necessary.
  // We put a loading page whenever the selected warehouse is loading because the warehouse type flags may have changed.
  // Best example of where this helps rendering is on dynamic table columns.
  // Columns that are shown/hidden based off Warehouse Type flags do not show correctly in the Column Controls.
  return (
    <WarehouseFeatureFlagsContext.Provider value={{ warehouseFeatureFlags }}>
      {children}
    </WarehouseFeatureFlagsContext.Provider>
  );

  // return <LoadingPage />;
};

export default WarehouseFeatureFlagsProvider;

export const useWarehouseFeatureFlags = () => {
  const ctx = useContext(WarehouseFeatureFlagsContext);
  if (ctx === null) {
    throw new Error('useWarehouseFeatureFlags must be used within WarehouseFeatureFlagsProvider');
  }
  return ctx;
};
