/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDeliveryInfoForIssueStockModalQueryVariables = Types.Exact<{
  deliveryId: Types.Scalars['ID']['input'];
}>;


export type GetDeliveryInfoForIssueStockModalQuery = { __typename?: 'Query', deliveryInfo: { __typename?: 'Delivery', id: string, shipToBusinessPartner?: { __typename?: 'BusinessPartner', id: string, name?: string | null } | null, soldToBusinessPartner?: { __typename?: 'BusinessPartner', id: string, name?: string | null } | null, supplierBusinessPartner?: { __typename?: 'BusinessPartner', id: string, name?: string | null } | null } };

export type CreateIssueStockTaskMutationVariables = Types.Exact<{
  taskInput: Types.CreateOneIssueStockTaskDto;
}>;


export type CreateIssueStockTaskMutation = { __typename?: 'Mutation', createOneIssueStockTask: { __typename?: 'Task', id: string, code: string, status?: Types.TaskStatus | null } };


export const GetDeliveryInfoForIssueStockModalDocument = gql`
    query GetDeliveryInfoForIssueStockModal($deliveryId: ID!) {
  deliveryInfo: delivery(id: $deliveryId) {
    id
    shipToBusinessPartner {
      id
      name
    }
    soldToBusinessPartner {
      id
      name
    }
    supplierBusinessPartner {
      id
      name
    }
  }
}
    `;

/**
 * __useGetDeliveryInfoForIssueStockModalQuery__
 *
 * To run a query within a React component, call `useGetDeliveryInfoForIssueStockModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryInfoForIssueStockModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryInfoForIssueStockModalQuery({
 *   variables: {
 *      deliveryId: // value for 'deliveryId'
 *   },
 * });
 */
export function useGetDeliveryInfoForIssueStockModalQuery(baseOptions: Apollo.QueryHookOptions<GetDeliveryInfoForIssueStockModalQuery, GetDeliveryInfoForIssueStockModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryInfoForIssueStockModalQuery, GetDeliveryInfoForIssueStockModalQueryVariables>(GetDeliveryInfoForIssueStockModalDocument, options);
      }
export function useGetDeliveryInfoForIssueStockModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryInfoForIssueStockModalQuery, GetDeliveryInfoForIssueStockModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryInfoForIssueStockModalQuery, GetDeliveryInfoForIssueStockModalQueryVariables>(GetDeliveryInfoForIssueStockModalDocument, options);
        }
export type GetDeliveryInfoForIssueStockModalQueryHookResult = ReturnType<typeof useGetDeliveryInfoForIssueStockModalQuery>;
export type GetDeliveryInfoForIssueStockModalLazyQueryHookResult = ReturnType<typeof useGetDeliveryInfoForIssueStockModalLazyQuery>;
export type GetDeliveryInfoForIssueStockModalQueryResult = Apollo.QueryResult<GetDeliveryInfoForIssueStockModalQuery, GetDeliveryInfoForIssueStockModalQueryVariables>;
export const CreateIssueStockTaskDocument = gql`
    mutation CreateIssueStockTask($taskInput: CreateOneIssueStockTaskDto!) {
  createOneIssueStockTask(input: {createOneIssueStockTaskDto: $taskInput}) {
    id
    code
    status
  }
}
    `;
export type CreateIssueStockTaskMutationFn = Apollo.MutationFunction<CreateIssueStockTaskMutation, CreateIssueStockTaskMutationVariables>;

/**
 * __useCreateIssueStockTaskMutation__
 *
 * To run a mutation, you first call `useCreateIssueStockTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIssueStockTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIssueStockTaskMutation, { data, loading, error }] = useCreateIssueStockTaskMutation({
 *   variables: {
 *      taskInput: // value for 'taskInput'
 *   },
 * });
 */
export function useCreateIssueStockTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateIssueStockTaskMutation, CreateIssueStockTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIssueStockTaskMutation, CreateIssueStockTaskMutationVariables>(CreateIssueStockTaskDocument, options);
      }
export type CreateIssueStockTaskMutationHookResult = ReturnType<typeof useCreateIssueStockTaskMutation>;
export type CreateIssueStockTaskMutationResult = Apollo.MutationResult<CreateIssueStockTaskMutation>;
export type CreateIssueStockTaskMutationOptions = Apollo.BaseMutationOptions<CreateIssueStockTaskMutation, CreateIssueStockTaskMutationVariables>;