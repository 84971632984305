/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddToLicensePlateMutationVariables = Types.Exact<{
  binId: Types.Scalars['ID']['input'];
  licensePlateId: Types.Scalars['ID']['input'];
  products: Array<Types.LicensePlateMovementProductInput> | Types.LicensePlateMovementProductInput;
  autocomplete: Types.Scalars['Boolean']['input'];
}>;


export type AddToLicensePlateMutation = { __typename?: 'Mutation', addToLicensePlate: { __typename?: 'LicensePlate', id: string, code: string } };


export const AddToLicensePlateDocument = gql`
    mutation AddToLicensePlate($binId: ID!, $licensePlateId: ID!, $products: [LicensePlateMovementProductInput!]!, $autocomplete: Boolean!) {
  addToLicensePlate: moveStockToLicensePlate(
    input: {licensePlateMovement: {autocomplete: $autocomplete, destinationBinId: $binId, licensePlateId: $licensePlateId, products: $products}}
  ) {
    id
    code
  }
}
    `;
export type AddToLicensePlateMutationFn = Apollo.MutationFunction<AddToLicensePlateMutation, AddToLicensePlateMutationVariables>;

/**
 * __useAddToLicensePlateMutation__
 *
 * To run a mutation, you first call `useAddToLicensePlateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToLicensePlateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToLicensePlateMutation, { data, loading, error }] = useAddToLicensePlateMutation({
 *   variables: {
 *      binId: // value for 'binId'
 *      licensePlateId: // value for 'licensePlateId'
 *      products: // value for 'products'
 *      autocomplete: // value for 'autocomplete'
 *   },
 * });
 */
export function useAddToLicensePlateMutation(baseOptions?: Apollo.MutationHookOptions<AddToLicensePlateMutation, AddToLicensePlateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToLicensePlateMutation, AddToLicensePlateMutationVariables>(AddToLicensePlateDocument, options);
      }
export type AddToLicensePlateMutationHookResult = ReturnType<typeof useAddToLicensePlateMutation>;
export type AddToLicensePlateMutationResult = Apollo.MutationResult<AddToLicensePlateMutation>;
export type AddToLicensePlateMutationOptions = Apollo.BaseMutationOptions<AddToLicensePlateMutation, AddToLicensePlateMutationVariables>;