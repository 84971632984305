import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ViewHistoryFieldDiffSortFields } from '@/graphql/types.generated';
import {
  ColumnFilter,
  FilterOperators,
} from '@components/data-table/controls/filter/filter-definitions';
import useCreateEntityHistoryColumns, {
  TEntityHistoryDataType,
  TEntityHistoryFilterType,
  TEntityHistoryFieldNames,
  ENTITY_HISTORY_HOOK,
} from '@components/data-table/hooks/shared-columns/useCreateEntityHistoryColumns';
import useCreateDataTableColumns from '@components/data-table/hooks/useCreateDataTableColumns';
import useTriggerDataTableRefetch from '@components/data-table/hooks/useTriggerDataTableRefetch';
import { validateDataTableProps } from '@components/data-table/lib/validateDataTableProps';
import { IDataTableSort } from '@components/data-table/model/data-table';
import TableIds from '@components/data-table/model/TableIds';

const useBinStorageTypeChangeHistoryDataTable = (binStorageTypeId: string) => {
  const { t: tP } = useTranslation('pages', { keyPrefix: 'bin-storage-type-details' });

  const [refetchTrigger, triggerDataTableRefetch] = useTriggerDataTableRefetch();

  const createEntityHistoryColumns = useCreateEntityHistoryColumns({
    removeColumns: ['entity', 'code'],
  });
  const columns = useCreateDataTableColumns<TEntityHistoryDataType>(createEntityHistoryColumns);

  const baseFilter = useMemo<ColumnFilter<TEntityHistoryFieldNames>[]>(
    () => [
      {
        columnId: ViewHistoryFieldDiffSortFields.EntityId,
        operator: FilterOperators.eq,
        value: binStorageTypeId,
      },
      {
        columnId: ViewHistoryFieldDiffSortFields.Field,
        operator: FilterOperators.notIn,
        value: ['id', 'createdAt', 'deletedAt'],
      },
    ],
    [binStorageTypeId],
  );

  const defaultSorting: IDataTableSort<TEntityHistoryFieldNames>[] = useMemo(
    () => [
      {
        id: ViewHistoryFieldDiffSortFields.Date,
        desc: true,
      },
      {
        id: ViewHistoryFieldDiffSortFields.FieldType,
        desc: false,
      },
    ],
    [],
  );

  return {
    triggerBinHistoryDataTableRefetch: triggerDataTableRefetch,
    dataTableProps: validateDataTableProps<
      TEntityHistoryDataType,
      TEntityHistoryFilterType,
      TEntityHistoryFieldNames
    >({
      type: 'query',
      tableId: TableIds.BinStorageTypeChangeHistory,
      tableHeader: tP('changeHistory'),
      queryHook: ENTITY_HISTORY_HOOK,
      columns,
      baseFilter,
      defaultSorting,
      refetchTrigger,
    }),
  };
};

export default useBinStorageTypeChangeHistoryDataTable;
