import DataTableControlsWrapper from '@components/data-table/controls/ControlsWrapper';
import DataTableDataBasedExportControls from '@components/data-table/controls/export/DataBasedExport';

const DataTableDataBasedControls = ({ disableExport = false }) => {
  return (
    <DataTableControlsWrapper>
      {disableExport ? null : <DataTableDataBasedExportControls />}
    </DataTableControlsWrapper>
  );
};

export default DataTableDataBasedControls;
