/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RulesetsFragment = { __typename?: 'ViewSlottingRuleset', id?: string | null, name?: string | null, lastUpdated?: string | null, runCount?: number | null, createdBy?: string | null, createdByUserId?: string | null, warehouseId?: string | null };

export type RulesetsTableQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.ViewSlottingRulesetFilter>;
  sorting?: Types.InputMaybe<Array<Types.ViewSlottingRulesetSort> | Types.ViewSlottingRulesetSort>;
  includePageInfo?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeNodes?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeTotalCount?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type RulesetsTableQuery = { __typename?: 'Query', query: { __typename?: 'ViewSlottingRulesetOffsetConnection', totalCount?: number, pageInfo?: { __typename?: 'OffsetPageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null }, nodes?: Array<{ __typename?: 'ViewSlottingRuleset', id?: string | null, name?: string | null, lastUpdated?: string | null, runCount?: number | null, createdBy?: string | null, createdByUserId?: string | null, warehouseId?: string | null }> } };

export const RulesetsFragmentDoc = gql`
    fragment Rulesets on ViewSlottingRuleset {
  id
  name
  lastUpdated
  runCount
  createdBy
  createdByUserId
  warehouseId
}
    `;
export const RulesetsTableDocument = gql`
    query RulesetsTable($paging: OffsetPaging, $filter: ViewSlottingRulesetFilter, $sorting: [ViewSlottingRulesetSort!], $includePageInfo: Boolean = true, $includeNodes: Boolean = true, $includeTotalCount: Boolean = false) {
  query: viewSlottingRulesets(sorting: $sorting, paging: $paging, filter: $filter) {
    pageInfo @include(if: $includePageInfo) {
      hasNextPage
      hasPreviousPage
    }
    nodes @include(if: $includeNodes) {
      ...Rulesets
    }
    totalCount @include(if: $includeTotalCount)
  }
}
    ${RulesetsFragmentDoc}`;

/**
 * __useRulesetsTableQuery__
 *
 * To run a query within a React component, call `useRulesetsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useRulesetsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRulesetsTableQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      includePageInfo: // value for 'includePageInfo'
 *      includeNodes: // value for 'includeNodes'
 *      includeTotalCount: // value for 'includeTotalCount'
 *   },
 * });
 */
export function useRulesetsTableQuery(baseOptions?: Apollo.QueryHookOptions<RulesetsTableQuery, RulesetsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RulesetsTableQuery, RulesetsTableQueryVariables>(RulesetsTableDocument, options);
      }
export function useRulesetsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RulesetsTableQuery, RulesetsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RulesetsTableQuery, RulesetsTableQueryVariables>(RulesetsTableDocument, options);
        }
export type RulesetsTableQueryHookResult = ReturnType<typeof useRulesetsTableQuery>;
export type RulesetsTableLazyQueryHookResult = ReturnType<typeof useRulesetsTableLazyQuery>;
export type RulesetsTableQueryResult = Apollo.QueryResult<RulesetsTableQuery, RulesetsTableQueryVariables>;