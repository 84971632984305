/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateZoneMutationVariables = Types.Exact<{
  input: Types.ZoneUpdateOneInput;
}>;


export type UpdateZoneMutation = { __typename?: 'Mutation', updateOneZone: { __typename?: 'Zone', id: string, createdAt: string, updatedAt?: string | null, updatedByEmail?: string | null, updatedById?: string | null, deletedAt?: string | null, code: string, name?: string | null, description?: string | null, warehouseId: string } };

export type DeleteZoneMutationVariables = Types.Exact<{
  input: Types.DeleteOneEntityIdInput;
}>;


export type DeleteZoneMutation = { __typename?: 'Mutation', deleteOneZone: { __typename?: 'Zone', id: string, createdAt: string, updatedAt?: string | null, updatedByEmail?: string | null, updatedById?: string | null, deletedAt?: string | null, code: string, name?: string | null, description?: string | null, warehouseId: string } };


export const UpdateZoneDocument = gql`
    mutation UpdateZone($input: ZoneUpdateOneInput!) {
  updateOneZone(input: $input) {
    id
    createdAt
    updatedAt
    updatedByEmail
    updatedById
    deletedAt
    code
    name
    description
    warehouseId
  }
}
    `;
export type UpdateZoneMutationFn = Apollo.MutationFunction<UpdateZoneMutation, UpdateZoneMutationVariables>;

/**
 * __useUpdateZoneMutation__
 *
 * To run a mutation, you first call `useUpdateZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoneMutation, { data, loading, error }] = useUpdateZoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateZoneMutation(baseOptions?: Apollo.MutationHookOptions<UpdateZoneMutation, UpdateZoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateZoneMutation, UpdateZoneMutationVariables>(UpdateZoneDocument, options);
      }
export type UpdateZoneMutationHookResult = ReturnType<typeof useUpdateZoneMutation>;
export type UpdateZoneMutationResult = Apollo.MutationResult<UpdateZoneMutation>;
export type UpdateZoneMutationOptions = Apollo.BaseMutationOptions<UpdateZoneMutation, UpdateZoneMutationVariables>;
export const DeleteZoneDocument = gql`
    mutation DeleteZone($input: DeleteOneEntityIDInput!) {
  deleteOneZone(input: $input) {
    id
    createdAt
    updatedAt
    updatedByEmail
    updatedById
    deletedAt
    code
    name
    description
    warehouseId
  }
}
    `;
export type DeleteZoneMutationFn = Apollo.MutationFunction<DeleteZoneMutation, DeleteZoneMutationVariables>;

/**
 * __useDeleteZoneMutation__
 *
 * To run a mutation, you first call `useDeleteZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteZoneMutation, { data, loading, error }] = useDeleteZoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteZoneMutation(baseOptions?: Apollo.MutationHookOptions<DeleteZoneMutation, DeleteZoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteZoneMutation, DeleteZoneMutationVariables>(DeleteZoneDocument, options);
      }
export type DeleteZoneMutationHookResult = ReturnType<typeof useDeleteZoneMutation>;
export type DeleteZoneMutationResult = Apollo.MutationResult<DeleteZoneMutation>;
export type DeleteZoneMutationOptions = Apollo.BaseMutationOptions<DeleteZoneMutation, DeleteZoneMutationVariables>;