/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListCompaniesFragment = { __typename?: 'Company', id: string, name: string, code: string };

export type ListCompaniesByOrgQueryVariables = Types.Exact<{
  orgId: Types.Scalars['ID']['input'];
}>;


export type ListCompaniesByOrgQuery = { __typename?: 'Query', listCompaniesByOrg: { __typename?: 'CompanyOffsetConnection', companies: Array<{ __typename?: 'Company', id: string, name: string, code: string }> } };

export const ListCompaniesFragmentDoc = gql`
    fragment ListCompanies on Company {
  id
  name
  code
}
    `;
export const ListCompaniesByOrgDocument = gql`
    query ListCompaniesByOrg($orgId: ID!) {
  listCompaniesByOrg: companies(
    paging: {offset: 0, limit: 1000000}
    filter: {organizationId: {eq: $orgId}}
    sorting: [{field: name, direction: ASC}]
  ) {
    companies: nodes {
      ...ListCompanies
    }
  }
}
    ${ListCompaniesFragmentDoc}`;

/**
 * __useListCompaniesByOrgQuery__
 *
 * To run a query within a React component, call `useListCompaniesByOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompaniesByOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompaniesByOrgQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useListCompaniesByOrgQuery(baseOptions: Apollo.QueryHookOptions<ListCompaniesByOrgQuery, ListCompaniesByOrgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCompaniesByOrgQuery, ListCompaniesByOrgQueryVariables>(ListCompaniesByOrgDocument, options);
      }
export function useListCompaniesByOrgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCompaniesByOrgQuery, ListCompaniesByOrgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCompaniesByOrgQuery, ListCompaniesByOrgQueryVariables>(ListCompaniesByOrgDocument, options);
        }
export type ListCompaniesByOrgQueryHookResult = ReturnType<typeof useListCompaniesByOrgQuery>;
export type ListCompaniesByOrgLazyQueryHookResult = ReturnType<typeof useListCompaniesByOrgLazyQuery>;
export type ListCompaniesByOrgQueryResult = Apollo.QueryResult<ListCompaniesByOrgQuery, ListCompaniesByOrgQueryVariables>;