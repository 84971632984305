import { Typography } from '@mui/material';
import { memo } from 'react';

import { useDataBasedDataTable } from '@components/data-table/context/DataBasedProvider';
import { DefaultDataType } from '@components/data-table/model/data-table';
import TableIds from '@components/data-table/model/TableIds';
import FooterWrapper from '@components/data-table/pagination/FooterWrapper';

const FooterWrapperMemoized = memo(
  ({ tableId, data }: { tableId: TableIds; data: DefaultDataType[] }) => {
    return (
      <FooterWrapper data-testid={`data-table-${tableId}-footer`}>
        <Typography
          variant="body1"
          color="primary"
          fontWeight={600}
          data-testid={`data-table-${tableId}-noPagination`}
        >
          {`${data.length > 0 ? '1 - ' : ''}${data.length} of ${data.length}`}
        </Typography>
      </FooterWrapper>
    );
  },
);

const DataTableDataBasedPagination = () => {
  const { tableId, data } = useDataBasedDataTable();

  return <FooterWrapperMemoized tableId={tableId} data={data} />;
};

export default DataTableDataBasedPagination;
