import { Autocomplete, FormControl, FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ControllerFieldState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import TextField from '@components/text-field';
import { US_STATES } from '@constants/us-states';
import usePrevious from '@hooks/usePrevious';

const StatesDropdown: React.FC<{
  isDropdown: boolean;
  required?: boolean;
  field: any;
  fieldState: ControllerFieldState;
}> = ({ isDropdown, required = false, field, fieldState }) => {
  const { t } = useTranslation('components', { keyPrefix: 'field-blocks.contactInformation' });

  const [stateValue, setStateValue] = useState((field.value as string) || '');

  const prevDropdown = usePrevious(isDropdown);
  const [showDropdown, setShowDropdown] = useState(isDropdown);

  useEffect(() => {
    // We have to use setStateValue so the Autocomplete component erases its state in a "controlled" way.
    // We have to use setShowDropdown so we can reset the stateValue BEFORE we render the Autocomplete with a potentially invalid value.
    if (typeof prevDropdown !== 'undefined' && prevDropdown !== isDropdown) {
      setStateValue('');
      setShowDropdown(isDropdown);
    }
  }, [isDropdown]);

  if (showDropdown) {
    return (
      <FormControl fullWidth error={!!fieldState?.error}>
        <Autocomplete
          data-testid="state-dropdown"
          multiple={false}
          id="company-state"
          autoHighlight={true}
          options={Object.values(US_STATES).map((state) => state.name)}
          value={stateValue}
          onChange={(event, item, reason) => {
            if (reason === 'selectOption' || reason === 'clear') {
              setStateValue(item);
              field.onChange(item);
            }
          }}
          isOptionEqualToValue={(option, value) => {
            // Accept empty string
            if (value === '' || value === option) {
              return true;
            }
          }}
          renderInput={(params) => (
            <TextField
              {...field}
              {...params}
              required={required}
              label={t('state')}
              dataTestId="state-dropdown-input"
            />
          )}
        />
        {fieldState.error && <FormHelperText>{fieldState?.error?.message}</FormHelperText>}
      </FormControl>
    );
  }

  return (
    <TextField
      fullWidth
      id="state"
      data-testid="state"
      label={t('stateProvinceRegion')}
      error={!!fieldState?.error}
      helperText={fieldState?.error?.message}
      {...field}
    />
  );
};

export default StatesDropdown;
