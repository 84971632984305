import { Box, FormControl, FormHelperText } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdateProxyUrlMutation } from '@/graphql/defs/components/modals/__generated__/agent-proxy-modal.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { IAgentProxyModal } from '@models/modal';

const AgentProxy = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.agentConfig' });
  const { t: tC } = useTranslation('components', { keyPrefix: 'common' });
  const { t: tH } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal } = useModalContent<IAgentProxyModal>();

  const [proxy, setProxy] = useState(modal.proxyUrl || '');
  const [updateProxy] = useUpdateProxyUrlMutation({
    onError: (error) => {
      showMessage({ type: 'error', message: error.message });
    },
  });

  const formMethods = useForm();
  const { control, handleSubmit } = formMethods;

  const onSubmit = async () => {
    await updateProxy({
      variables: {
        proxyUrl: proxy,
      },
    }).catch(() => {
      return;
    });
    closeModal({ bypassLoading: true, success: true });
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent sx={{ width: '800px' }}>
        <Box sx={{ margin: '5px 0 15px' }}>
          <Controller
            name={t('proxyUrl')}
            control={control}
            rules={{
              required: tH('form.validation.requiredField', { field: t('proxyUrl') }),
              minLength: 1,
            }}
            defaultValue=""
            render={({ field, fieldState }) => (
              <FormControl error={!!fieldState?.error} sx={{ width: 'calc(50% - 12px)' }}>
                <TextField
                  sx={{ width: '400px' }}
                  {...field}
                  autoFocus
                  required
                  autoComplete="off"
                  error={!!fieldState.error}
                  variant="standard"
                  label={t('proxyUrl')}
                  value={proxy}
                  onChange={({ target: { value } }) => {
                    field.onChange(value);
                    setProxy(value);
                  }}
                  dataTestId="agentProxy-url"
                />
                <FormHelperText sx={{ height: 19 }}>{fieldState?.error?.message}</FormHelperText>
              </FormControl>
            )}
          />
          {/* <TextField
            variant="standard"
            label={t('proxyUrl')}
            value={proxy}
            sx={{ width: '400px' }}
            onChange={({ target: { value } }) => {
              setProxy(value);
            }}
          /> */}
        </Box>
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {tC('cancel')}
        </ModalButton>
        <ModalButton
          data-testid="agentProxy_submit"
          variant="contained"
          color="primary"
          actionType="submit"
        >
          {tC('save')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default AgentProxy;
