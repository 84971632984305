import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDatasetsTableLazyQuery } from '@/graphql/defs/hooks/shared-columns/__generated__/useCreateDatasetColumns.generated';
import { SlottingAnalysisStatus, SlottingLoadStatus } from '@/graphql/types.generated';
import { ColumnTypes } from '@components/data-table/controls/filter/filter-definitions';
import { TColumnFactory } from '@components/data-table/hooks/useCreateDataTableColumns';
import useDataTableEnumList from '@components/data-table/hooks/useDataTableEnumLists';
import filterDataTableColumnDefs from '@components/data-table/lib/filterDataTableColumnDefs';
import {
  TExtractLazyHookDataType,
  TExtractLazyHookFetchFilterType,
  TExtractLazyHookFieldNames,
} from '@components/data-table/model/extract-query-hook-types';
import LinkCell from '@components/data-table/table/cells/LinkCell';
import StatusTypography from '@components/status-typography';
import { SLOTTING_DATASET_RUN_SUMMARIES } from '@constants/routes';
import { useDateTime } from '@context/date-time';
import { useWarehouseFeatureFlags } from '@context/warehouse-feature-flags';
import enumKeys from '@lib/enum-keys';
import StatusValues from '@models/StatusValues';

export const DATASETS_HOOK = useDatasetsTableLazyQuery;
export type TDatasetDataType = TExtractLazyHookDataType<typeof DATASETS_HOOK>;
export type TDatasetFilterType = TExtractLazyHookFetchFilterType<typeof DATASETS_HOOK>;
export type TDatasetFieldNames = TExtractLazyHookFieldNames<typeof DATASETS_HOOK>;

interface ICreatedDatasetColumns {
  removeColumns?: (keyof TDatasetDataType)[];
}
const useCreateDatasetColumns = ({ removeColumns = [] }: ICreatedDatasetColumns = {}) => {
  const { t } = useTranslation('pages', { keyPrefix: 'datasets' });
  const { t: tC } = useTranslation('components');
  const { displayDateTime } = useDateTime();

  const {
    warehouseFeatureFlags: { inventory: inventoryFlags },
  } = useWarehouseFeatureFlags();

  const { stockStatusTypeEnumList, unitOfMeasureEnumList } = useDataTableEnumList({
    fetchStockStatusTypeList: true,
    fetchUoMList: true,
  });

  const createDatasetColumns = useCallback<TColumnFactory<TDatasetDataType>>(
    (columnHelper) => {
      const columns = [
        columnHelper.accessor('code', {
          header: t('columns.dataset'),
          cell: ({ row, getValue }) => (
            <LinkCell
              href={SLOTTING_DATASET_RUN_SUMMARIES.replace(':id', row.original.id)}
              text={getValue()}
              dataTestId="dataset-code"
            />
          ),
          meta: {
            columnType: ColumnTypes.string,
            enableExport: false,
          },
        }),
        columnHelper.accessor('tag', {
          header: t('columns.tag'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
            enableExport: false,
          },
        }),
        columnHelper.accessor('variantName', {
          header: t('columns.variantName'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('createdByUserName', {
          header: t('columns.pulledBy'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('processType', {
          header: t('columns.processType'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('loadStatus', {
          header: t('columns.loadStatus'),
          cell: ({ getValue }) => (
            <StatusTypography
              status={getValue()}
              display={tC(`common.${getValue()}`)}
              bold={true}
              uppercase={true}
            />
          ),
          meta: {
            columnType: ColumnTypes.enum,
            options: enumKeys(SlottingLoadStatus).map((status) => ({
              value: SlottingLoadStatus[status],
              display: tC(`common.${status as SlottingLoadStatus}`),
            })),
            exportFormatter: (value) => tC(`common.${value as SlottingLoadStatus}`),
          },
        }),
        columnHelper.accessor('analysisStatus', {
          header: t('columns.analysisStatus'),
          cell: ({ getValue }) => (
            <StatusTypography
              status={getValue()}
              display={tC(`common.${getValue()}`)}
              bold={true}
              uppercase={true}
            />
          ),
          meta: {
            columnType: ColumnTypes.enum,
            options: enumKeys(SlottingAnalysisStatus).map((status) => ({
              value: SlottingAnalysisStatus[status],
              display: tC(`common.${status as SlottingAnalysisStatus}`),
            })),
            exportFormatter: (value) => tC(`common.${value as SlottingAnalysisStatus}`),
          },
        }),
        columnHelper.accessor('locked', {
          header: t('columns.lockStatus'),
          cell: ({ getValue }) => (
            <StatusTypography
              status={getValue() ? StatusValues.Locked : StatusValues.Unlocked}
              display={getValue() ? tC(`common.locked`) : tC(`common.unlocked`)}
              bold={true}
              uppercase={true}
            />
          ),
          meta: {
            columnType: ColumnTypes.boolean,
            exportFormatter: (value) => (value ? tC('common.locked') : tC('common.unlocked')),
            booleanLabels: [tC('common.unlocked'), tC('common.locked')],
          },
        }),
        columnHelper.accessor('pullDate', {
          header: t('columns.pullDate'),
          cell: ({ getValue }) => displayDateTime({ date: getValue() }),
          meta: {
            columnType: ColumnTypes.dateTime,
            exportFormatter: (value) => displayDateTime({ date: value }),
          },
        }),
        columnHelper.accessor('dateRangeStart', {
          header: t('columns.startDate'),
          cell: ({ getValue }) => displayDateTime({ date: getValue() }),
          meta: {
            columnType: ColumnTypes.dateTime,
            exportFormatter: (value) => displayDateTime({ date: value }),
          },
        }),
        columnHelper.accessor('dateRangeEnd', {
          header: t('columns.endDate'),
          cell: ({ getValue }) => displayDateTime({ date: getValue() }),
          meta: {
            columnType: ColumnTypes.dateTime,
            exportFormatter: (value) => displayDateTime({ date: value }),
          },
        }),
        columnHelper.accessor('updatedAt', {
          header: t('columns.lastChangedDate'),
          cell: ({ getValue }) => displayDateTime({ date: getValue() }),
          meta: {
            columnType: ColumnTypes.dateTime,
            exportFormatter: (value) => displayDateTime({ date: value }),
          },
        }),
        columnHelper.accessor('updatedByEmail', {
          header: t('columns.changedBy'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
      ];

      return columns.filter(filterDataTableColumnDefs<TDatasetDataType>(removeColumns));
    },
    [stockStatusTypeEnumList, unitOfMeasureEnumList, inventoryFlags, ...removeColumns],
  );

  return createDatasetColumns;
};

export default useCreateDatasetColumns;
