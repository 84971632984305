import { DeleteOutline } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DataTableDisplayColumns } from '@components/data-table/columnDefs/displayColumns';
import useCreateSlottingMovementsColumns, {
  TSlottingMovementsDataType,
} from '@components/data-table/hooks/shared-columns/useCreateSlottingMovementsColumns';
import useCreateDataTableColumns, {
  TColumnFactory,
} from '@components/data-table/hooks/useCreateDataTableColumns';
import { validateDataTableProps } from '@components/data-table/lib/validateDataTableProps';
import TableIds from '@components/data-table/model/TableIds';
import IconButton from '@components/styled/IconButton';

interface IUseRunDeployMovementsApprovedMovementsDataTableProps {
  handleRemoveApproved?: (id: string) => void;
  approvedMovements: TSlottingMovementsDataType[];
}

const useRunDeployMovementsApprovedMovementsDataTable = ({
  handleRemoveApproved,
  approvedMovements,
}: IUseRunDeployMovementsApprovedMovementsDataTableProps) => {
  const { t } = useTranslation('components');

  const addRemoveApprovedColumnAction: TColumnFactory<TSlottingMovementsDataType> = (
    columnHelper,
  ) => {
    const removeApprovedColumnAction = columnHelper.display({
      id: DataTableDisplayColumns.Delete,
      header: '',
      cell: ({ row }) => (
        <Box position="relative" width="100%">
          <IconButton
            onClick={() => {
              handleRemoveApproved(row.original.id);
            }}
            styledVariant="dataGrid"
            size="small"
          >
            <DeleteOutline data-testid="remove-approved-movement" />
          </IconButton>
        </Box>
      ),
      size: 40,
      enableColumnFilter: false,
      enableHiding: false,
      enableResizing: false,
      enableSorting: false,
      enableMultiSort: false,
    });

    return [removeApprovedColumnAction];
  };

  const slottingMovementsColumns = useCreateSlottingMovementsColumns({
    addColumnsToStart: handleRemoveApproved ? addRemoveApprovedColumnAction : undefined,
  });
  const columns = useCreateDataTableColumns<TSlottingMovementsDataType>(slottingMovementsColumns);

  return {
    dataTableProps: validateDataTableProps<TSlottingMovementsDataType>({
      type: 'data',
      tableId: TableIds.RunDeployMovementsApprovedMovements,
      tableHeader: t('slotting.fixedBinAcceptedRecommendations'),
      columns,
      data: approvedMovements,
      isDataLoading: false,
      disableExport: true,
    }),
  };
};

export default useRunDeployMovementsApprovedMovementsDataTable;
