import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CompanyDetailsFragment,
  useUpdateCompanyDetailsMutation,
} from '@/graphql/defs/components/modals/__generated__/company-details-modal.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import { stripUnchangedFields, uppercase } from '@lib/form';
import FormValues from '@models/FormValues';
import { ICompanyDetailsModal } from '@models/modal';

const CompanyDetailsModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal, setLoading } = useModalContent<ICompanyDetailsModal>();

  const defaultValues: FormValues<CompanyDetailsFragment> = {
    id: modal.details?.id,
    code: modal.details?.code,
    name: modal.details?.name,
  };
  const formMethods = useForm<FormValues<CompanyDetailsFragment>>({
    defaultValues,
  });
  const { control, handleSubmit } = formMethods;
  const { notWhiteSpaceOnly } = useFormValidation();

  const [updateCompanyDetails] = useUpdateCompanyDetailsMutation({
    onCompleted: ({ updateOneCompany: { name } }) => {
      showMessage({
        type: 'success',
        message: t('modal.company.updateDetails.success', { name }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onSubmit = (fields: CompanyDetailsFragment) => {
    void updateCompanyDetails({
      variables: {
        id: fields.id,
        updatedDetails: stripUnchangedFields(defaultValues, fields),
      },
    });
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Controller
              name="code"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('field-blocks.companyDetails.code'),
                }),
                validate: {
                  notWhiteSpaceOnly,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  autoFocus
                  fullWidth
                  required
                  id="company-code"
                  label={t('field-blocks.companyDetails.code')}
                  {...field}
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  onChange={(e) => field.onChange(uppercase(e))}
                  dataTestId="company-code"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('field-blocks.companyDetails.name'),
                }),
                validate: {
                  notWhiteSpaceOnly,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  required
                  id="company-name"
                  label={t('field-blocks.companyDetails.name')}
                  {...field}
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  dataTestId="company-name"
                />
              )}
            />
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" actionType="submit">
          {t('common.submit')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default CompanyDetailsModal;
