/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShortShipFulfillmentMutationVariables = Types.Exact<{
  fulfillmentId: Types.Scalars['ID']['input'];
}>;


export type ShortShipFulfillmentMutation = { __typename?: 'Mutation', shortShipFulfillment: { __typename?: 'Delivery', id: string, erpCode: string } };


export const ShortShipFulfillmentDocument = gql`
    mutation ShortShipFulfillment($fulfillmentId: ID!) {
  shortShipFulfillment(fulfillmentId: $fulfillmentId) {
    id
    erpCode
  }
}
    `;
export type ShortShipFulfillmentMutationFn = Apollo.MutationFunction<ShortShipFulfillmentMutation, ShortShipFulfillmentMutationVariables>;

/**
 * __useShortShipFulfillmentMutation__
 *
 * To run a mutation, you first call `useShortShipFulfillmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShortShipFulfillmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shortShipFulfillmentMutation, { data, loading, error }] = useShortShipFulfillmentMutation({
 *   variables: {
 *      fulfillmentId: // value for 'fulfillmentId'
 *   },
 * });
 */
export function useShortShipFulfillmentMutation(baseOptions?: Apollo.MutationHookOptions<ShortShipFulfillmentMutation, ShortShipFulfillmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShortShipFulfillmentMutation, ShortShipFulfillmentMutationVariables>(ShortShipFulfillmentDocument, options);
      }
export type ShortShipFulfillmentMutationHookResult = ReturnType<typeof useShortShipFulfillmentMutation>;
export type ShortShipFulfillmentMutationResult = Apollo.MutationResult<ShortShipFulfillmentMutation>;
export type ShortShipFulfillmentMutationOptions = Apollo.BaseMutationOptions<ShortShipFulfillmentMutation, ShortShipFulfillmentMutationVariables>;