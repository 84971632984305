import { Typography } from '@mui/material';
import { isValidElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { IConfirmationModal } from '@models/modal';

const ConfirmationModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal } = useModalContent<IConfirmationModal>();

  return (
    <>
      <ModalContent>
        {isValidElement(modal.message) ? modal.message : <Typography>{modal.message}</Typography>}
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => modal.onCancel(closeModal)}
          variant="outlined"
          color="primary"
          data-testid="confirmation-modal-cancel-button"
          actionType="cancel"
        >
          {modal?.cancelButtonText ? modal.cancelButtonText : t('common.cancel')}
        </ModalButton>
        <ModalButton
          onClick={() => modal.onConfirm(closeModal)}
          variant="contained"
          color={modal?.confirmButtonColor ? modal.confirmButtonColor : 'primary'}
          data-testid="confirmation-modal-confirm-button"
          actionType="submit"
        >
          {modal?.confirmButtonText ? modal.confirmButtonText : t('common.confirm')}
        </ModalButton>
      </ModalActions>
    </>
  );
};

export default ConfirmationModal;
