import Close from '@mui/icons-material/Close';
import { Dialog, Box, DialogTitle } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimerContext } from 'react-idle-timer';

import LoadingIndicator from '@components/loading-indicator';
import { ModalButton } from '@components/modal';
import { TCloseModal } from '@context/modal/ModalContentProvider';
import { ModalMaxWidth, ModalTypes } from '@models/modal';

interface IModalContainerProps {
  open: boolean;
  title: string | ReactNode;
  modalType: ModalTypes;
  closeModal: TCloseModal;
  isLoading: boolean;
  disableManualClose?: boolean;
  guardedWarning?: boolean;
  setGuardedWarning?: Dispatch<SetStateAction<boolean>>;
  maxWidth?: ModalMaxWidth;
  suppressTimeoutReset?: boolean;
  children: ReactNode;
  guarded?: boolean;
}

const ModalContainer = ({
  open,
  title,
  modalType,
  closeModal,
  isLoading,
  disableManualClose = false,
  suppressTimeoutReset = false,
  children,
  maxWidth = 'md',
  guardedWarning = false,
  setGuardedWarning = () => undefined,
  guarded = false,
}: IModalContainerProps) => {
  const idleTimer = useIdleTimerContext();

  useEffect(() => {
    if (open && !suppressTimeoutReset) idleTimer.reset();
  }, [open]);

  return (
    <Dialog
      open={open}
      sx={{
        position: 'fixed',
        top: '0',
      }}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          if (guarded) return setGuardedWarning(true);
          if (disableManualClose) return;
        }
        if (!suppressTimeoutReset) idleTimer.reset();
        closeModal();
      }}
      maxWidth={maxWidth}
      disableEscapeKeyDown={disableManualClose}
      data-testid={`modal-${modalType}-container`}
    >
      <Box
        sx={{
          position: 'relative',
          padding: '10px 10px',
        }}
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <DialogTitle data-testid={`modal-${modalType}-title`}>
            {title || ''}
            {!disableManualClose && (
              <Close
                sx={{
                  cursor: 'pointer',
                  float: 'right',
                  width: '35px',
                  height: '35px',
                  transform: 'translate(20px, -10px)',
                }}
                onClick={() => closeModal()}
                data-testid={`modal-${modalType}-close-x`}
              />
            )}
          </DialogTitle>
        )}
        {children}
        {guardedWarning ? (
          <GuardedWarning setGuardedWarning={setGuardedWarning} closeModal={closeModal} />
        ) : null}
      </Box>
    </Dialog>
  );
};

export default ModalContainer;

const GuardedWarning = ({ setGuardedWarning, closeModal }) => {
  const { t } = useTranslation('components');
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: '#00000088',
        zIndex: 500000000000000000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#ffffff',
          borderRadius: '4px',
          width: '500px',
          height: '400px',
          padding: '40px',
          fontSize: '24px',
          fontWeight: 600,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {t('modal.closeModalWarning')}
        <Box sx={{ display: 'flex' }}>
          <ModalButton
            variant="outlined"
            sx={{ margin: '0px 5px 0px auto' }}
            onClick={() => setGuardedWarning(false)}
          >
            {t('common.cancel')}
          </ModalButton>
          <ModalButton
            variant="contained"
            onClick={() => {
              setGuardedWarning(false);
              closeModal({ bypassGuarded: true });
            }}
          >
            {t('common.close')}
          </ModalButton>
        </Box>
      </Box>
    </Box>
  );
};
