/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import { BaseDeliveryFragmentDoc } from '../../../shared-fragments/__generated__/delivery.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompleteDeliveryMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type CompleteDeliveryMutation = { __typename?: 'Mutation', updateOneDelivery: { __typename?: 'Delivery', id: string, type: Types.DeliveryType, billOfLading?: string | null, erpCreated?: string | null, erpBlockingReason?: string | null, dueDate?: string | null, erpCode: string, totalGrossWeight?: number | null, totalNetWeight?: number | null, totalWeightUOMId?: string | null, erpLastChanged?: string | null, erpSalesOrder?: string | null, goodsReceiptOrIssueStatus?: Types.DeliveryCompletionStatus | null, hasTasks: boolean, pickOrPutawayStatus?: Types.DeliveryCompletionStatus | null, deliveryStatus?: Types.DeliveryCompletionStatus | null, supplier?: string | null, supplierBusinessPartnerId?: string | null, blockStatus?: string | null, supplierName?: string | null, promiseDate?: string | null, pointOfContact?: string | null, shipReadyDate?: string | null, totalVolume?: number | null, totalVolumeUOMId?: string | null, export?: string | null, shipTo?: string | null, shipToBusinessPartnerId?: string | null, shipToName?: string | null, soldTo?: string | null, soldToBusinessPartnerId?: string | null, soldToName?: string | null, progress?: number | null, progressString?: Types.DeliveryCompletionStatus | null, createdAt: string, updatedAt?: string | null, deletedAt?: string | null, warehouse?: { __typename?: 'Warehouse', id: string, code: string, name?: string | null } | null, fulfillmentBlock?: { __typename?: 'FulfillmentBlock', label: string, description?: string | null, id: string } | null } };


export const CompleteDeliveryDocument = gql`
    mutation CompleteDelivery($id: ID!) {
  updateOneDelivery(input: {id: $id, update: {deliveryStatus: complete}}) {
    ...BaseDelivery
  }
}
    ${BaseDeliveryFragmentDoc}`;
export type CompleteDeliveryMutationFn = Apollo.MutationFunction<CompleteDeliveryMutation, CompleteDeliveryMutationVariables>;

/**
 * __useCompleteDeliveryMutation__
 *
 * To run a mutation, you first call `useCompleteDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeDeliveryMutation, { data, loading, error }] = useCompleteDeliveryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<CompleteDeliveryMutation, CompleteDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteDeliveryMutation, CompleteDeliveryMutationVariables>(CompleteDeliveryDocument, options);
      }
export type CompleteDeliveryMutationHookResult = ReturnType<typeof useCompleteDeliveryMutation>;
export type CompleteDeliveryMutationResult = Apollo.MutationResult<CompleteDeliveryMutation>;
export type CompleteDeliveryMutationOptions = Apollo.BaseMutationOptions<CompleteDeliveryMutation, CompleteDeliveryMutationVariables>;