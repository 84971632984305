/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateOrganizationDetailsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  updatedOrg: Types.OrganizationUpdateInput;
}>;


export type UpdateOrganizationDetailsMutation = { __typename?: 'Mutation', updateOneOrganization: { __typename?: 'Organization', id: string, code?: string | null, name: string } };


export const UpdateOrganizationDetailsDocument = gql`
    mutation UpdateOrganizationDetails($id: ID!, $updatedOrg: OrganizationUpdateInput!) {
  updateOneOrganization(input: {id: $id, update: $updatedOrg}) {
    id
    code
    name
  }
}
    `;
export type UpdateOrganizationDetailsMutationFn = Apollo.MutationFunction<UpdateOrganizationDetailsMutation, UpdateOrganizationDetailsMutationVariables>;

/**
 * __useUpdateOrganizationDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationDetailsMutation, { data, loading, error }] = useUpdateOrganizationDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updatedOrg: // value for 'updatedOrg'
 *   },
 * });
 */
export function useUpdateOrganizationDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationDetailsMutation, UpdateOrganizationDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationDetailsMutation, UpdateOrganizationDetailsMutationVariables>(UpdateOrganizationDetailsDocument, options);
      }
export type UpdateOrganizationDetailsMutationHookResult = ReturnType<typeof useUpdateOrganizationDetailsMutation>;
export type UpdateOrganizationDetailsMutationResult = Apollo.MutationResult<UpdateOrganizationDetailsMutation>;
export type UpdateOrganizationDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationDetailsMutation, UpdateOrganizationDetailsMutationVariables>;