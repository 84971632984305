/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserGroupFragment = { __typename?: 'ViewUserGroup', userGroupId?: string | null, userGroupName?: string | null, roleDefault?: string | null, roleId?: string | null, roleName?: string | null, warehouseCode?: string | null, warehouseId?: string | null, warehouseName?: string | null };

export type ViewUserGroupsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ViewUserGroupFilter>;
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  sorting?: Types.InputMaybe<Array<Types.ViewUserGroupSort> | Types.ViewUserGroupSort>;
}>;


export type ViewUserGroupsQuery = { __typename?: 'Query', viewUserGroups: { __typename?: 'ViewUserGroupOffsetConnection', nodes: Array<{ __typename?: 'ViewUserGroup', userGroupId?: string | null, userGroupName?: string | null, roleDefault?: string | null, roleId?: string | null, roleName?: string | null, warehouseCode?: string | null, warehouseId?: string | null, warehouseName?: string | null }> } };

export const UserGroupFragmentDoc = gql`
    fragment UserGroup on ViewUserGroup {
  userGroupId
  userGroupName
  roleDefault
  roleId
  roleName
  warehouseCode
  warehouseId
  warehouseName
}
    `;
export const ViewUserGroupsDocument = gql`
    query ViewUserGroups($filter: ViewUserGroupFilter, $paging: OffsetPaging, $sorting: [ViewUserGroupSort!]) {
  viewUserGroups(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      ...UserGroup
    }
  }
}
    ${UserGroupFragmentDoc}`;

/**
 * __useViewUserGroupsQuery__
 *
 * To run a query within a React component, call `useViewUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewUserGroupsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useViewUserGroupsQuery(baseOptions?: Apollo.QueryHookOptions<ViewUserGroupsQuery, ViewUserGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewUserGroupsQuery, ViewUserGroupsQueryVariables>(ViewUserGroupsDocument, options);
      }
export function useViewUserGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewUserGroupsQuery, ViewUserGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewUserGroupsQuery, ViewUserGroupsQueryVariables>(ViewUserGroupsDocument, options);
        }
export type ViewUserGroupsQueryHookResult = ReturnType<typeof useViewUserGroupsQuery>;
export type ViewUserGroupsLazyQueryHookResult = ReturnType<typeof useViewUserGroupsLazyQuery>;
export type ViewUserGroupsQueryResult = Apollo.QueryResult<ViewUserGroupsQuery, ViewUserGroupsQueryVariables>;