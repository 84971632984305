/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WarehouseDropdownFragment = { __typename?: 'Warehouse', id: string, name?: string | null };

export type GetWarehouseDropdownQueryVariables = Types.Exact<{
  orgId: Types.Scalars['ID']['input'];
}>;


export type GetWarehouseDropdownQuery = { __typename?: 'Query', warehouses: { __typename?: 'WarehouseOffsetConnection', nodes: Array<{ __typename?: 'Warehouse', id: string, name?: string | null }> } };

export const WarehouseDropdownFragmentDoc = gql`
    fragment WarehouseDropdown on Warehouse {
  id
  name
}
    `;
export const GetWarehouseDropdownDocument = gql`
    query GetWarehouseDropdown($orgId: ID!) {
  warehouses(
    filter: {company: {organizationId: {eq: $orgId}}}
    sorting: [{field: name, direction: ASC}]
  ) {
    nodes {
      ...WarehouseDropdown
    }
  }
}
    ${WarehouseDropdownFragmentDoc}`;

/**
 * __useGetWarehouseDropdownQuery__
 *
 * To run a query within a React component, call `useGetWarehouseDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseDropdownQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useGetWarehouseDropdownQuery(baseOptions: Apollo.QueryHookOptions<GetWarehouseDropdownQuery, GetWarehouseDropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWarehouseDropdownQuery, GetWarehouseDropdownQueryVariables>(GetWarehouseDropdownDocument, options);
      }
export function useGetWarehouseDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWarehouseDropdownQuery, GetWarehouseDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWarehouseDropdownQuery, GetWarehouseDropdownQueryVariables>(GetWarehouseDropdownDocument, options);
        }
export type GetWarehouseDropdownQueryHookResult = ReturnType<typeof useGetWarehouseDropdownQuery>;
export type GetWarehouseDropdownLazyQueryHookResult = ReturnType<typeof useGetWarehouseDropdownLazyQuery>;
export type GetWarehouseDropdownQueryResult = Apollo.QueryResult<GetWarehouseDropdownQuery, GetWarehouseDropdownQueryVariables>;