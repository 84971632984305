import { FormControl, InputLabel, Select } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const MapSelector = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('pages');
  const mapType = useMemo(() => {
    if (location.pathname.includes('aisle')) {
      return '/map/aisle';
    }
    if (location.pathname.includes('area')) {
      return '/map/area';
    }
    if (location.pathname.includes('capacity')) {
      return '/map/capacity';
    }
    if (location.pathname.includes('slotting')) {
      return '/map/slotting';
    }
  }, [location.pathname]);
  const handleChange = ({ target: { value } }) => {
    navigate(value);
  };

  return (
    <FormControl sx={{ marginLeft: '15px' }}>
      <InputLabel id="demo-simple-select-label">{t('maps.mapView')}</InputLabel>
      <Select
        size="medium"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={mapType}
        label={t('maps.mapView')}
        onChange={handleChange}
        sx={{ width: 300 }}
        native
      >
        {[
          { id: '/map/area', label: t('maps.area') },
          { id: '/map/aisle', label: t('maps.aisle'), disabled: true },
          { id: '/map/capacity', label: t('maps.capacity') },
        ].map((status, key) => (
          <option key={key} value={status.id} disabled={status.disabled}>
            {status.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export default MapSelector;
