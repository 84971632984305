/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTaskTypeStockStatusModalMappingsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ViewTaskTypeStockStatusMappingFilter>;
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  sorting?: Types.InputMaybe<Array<Types.ViewTaskTypeStockStatusMappingSort> | Types.ViewTaskTypeStockStatusMappingSort>;
}>;


export type GetTaskTypeStockStatusModalMappingsQuery = { __typename?: 'Query', view: { __typename?: 'ViewTaskTypeStockStatusMappingOffsetConnection', nodes: Array<{ __typename?: 'ViewTaskTypeStockStatusMapping', taskTypeCode?: string | null, taskTypeId?: string | null, taskTypeLabel?: string | null, stockStatusMappings?: Array<{ __typename?: 'StockStatusMapping', editable?: boolean | null, mapped?: boolean | null, stockStatusCode?: string | null, stockStatusId?: string | null, stockStatusLabel?: string | null }> | null }> } };

export type TaskTypeStockStatusModalMappingUpdateMutationVariables = Types.Exact<{
  input: Types.TaskTypeStockStatusMappingReplaceInputDto;
}>;


export type TaskTypeStockStatusModalMappingUpdateMutation = { __typename?: 'Mutation', update: { __typename?: 'ViewTaskTypeStockStatusMapping', taskTypeCode?: string | null, taskTypeId?: string | null, taskTypeLabel?: string | null, stockStatusMappings?: Array<{ __typename?: 'StockStatusMapping', editable?: boolean | null, mapped?: boolean | null, stockStatusCode?: string | null, stockStatusId?: string | null, stockStatusLabel?: string | null }> | null } };


export const GetTaskTypeStockStatusModalMappingsDocument = gql`
    query GetTaskTypeStockStatusModalMappings($filter: ViewTaskTypeStockStatusMappingFilter, $paging: OffsetPaging, $sorting: [ViewTaskTypeStockStatusMappingSort!]) {
  view: viewTaskTypeStockStatusMappings(
    filter: $filter
    paging: $paging
    sorting: $sorting
  ) {
    nodes {
      stockStatusMappings {
        editable
        mapped
        stockStatusCode
        stockStatusId
        stockStatusLabel
      }
      taskTypeCode
      taskTypeId
      taskTypeLabel
    }
  }
}
    `;

/**
 * __useGetTaskTypeStockStatusModalMappingsQuery__
 *
 * To run a query within a React component, call `useGetTaskTypeStockStatusModalMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskTypeStockStatusModalMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskTypeStockStatusModalMappingsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetTaskTypeStockStatusModalMappingsQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskTypeStockStatusModalMappingsQuery, GetTaskTypeStockStatusModalMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskTypeStockStatusModalMappingsQuery, GetTaskTypeStockStatusModalMappingsQueryVariables>(GetTaskTypeStockStatusModalMappingsDocument, options);
      }
export function useGetTaskTypeStockStatusModalMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskTypeStockStatusModalMappingsQuery, GetTaskTypeStockStatusModalMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskTypeStockStatusModalMappingsQuery, GetTaskTypeStockStatusModalMappingsQueryVariables>(GetTaskTypeStockStatusModalMappingsDocument, options);
        }
export type GetTaskTypeStockStatusModalMappingsQueryHookResult = ReturnType<typeof useGetTaskTypeStockStatusModalMappingsQuery>;
export type GetTaskTypeStockStatusModalMappingsLazyQueryHookResult = ReturnType<typeof useGetTaskTypeStockStatusModalMappingsLazyQuery>;
export type GetTaskTypeStockStatusModalMappingsQueryResult = Apollo.QueryResult<GetTaskTypeStockStatusModalMappingsQuery, GetTaskTypeStockStatusModalMappingsQueryVariables>;
export const TaskTypeStockStatusModalMappingUpdateDocument = gql`
    mutation TaskTypeStockStatusModalMappingUpdate($input: TaskTypeStockStatusMappingReplaceInputDto!) {
  update: updateOneTaskTypeStockStatusMapping(input: $input) {
    stockStatusMappings {
      editable
      mapped
      stockStatusCode
      stockStatusId
      stockStatusLabel
    }
    taskTypeCode
    taskTypeId
    taskTypeLabel
  }
}
    `;
export type TaskTypeStockStatusModalMappingUpdateMutationFn = Apollo.MutationFunction<TaskTypeStockStatusModalMappingUpdateMutation, TaskTypeStockStatusModalMappingUpdateMutationVariables>;

/**
 * __useTaskTypeStockStatusModalMappingUpdateMutation__
 *
 * To run a mutation, you first call `useTaskTypeStockStatusModalMappingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskTypeStockStatusModalMappingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskTypeStockStatusModalMappingUpdateMutation, { data, loading, error }] = useTaskTypeStockStatusModalMappingUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskTypeStockStatusModalMappingUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TaskTypeStockStatusModalMappingUpdateMutation, TaskTypeStockStatusModalMappingUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskTypeStockStatusModalMappingUpdateMutation, TaskTypeStockStatusModalMappingUpdateMutationVariables>(TaskTypeStockStatusModalMappingUpdateDocument, options);
      }
export type TaskTypeStockStatusModalMappingUpdateMutationHookResult = ReturnType<typeof useTaskTypeStockStatusModalMappingUpdateMutation>;
export type TaskTypeStockStatusModalMappingUpdateMutationResult = Apollo.MutationResult<TaskTypeStockStatusModalMappingUpdateMutation>;
export type TaskTypeStockStatusModalMappingUpdateMutationOptions = Apollo.BaseMutationOptions<TaskTypeStockStatusModalMappingUpdateMutation, TaskTypeStockStatusModalMappingUpdateMutationVariables>;