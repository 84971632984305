import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { useDateTime } from '@context/date-time';

export const useFormValidation = () => {
  const { t } = useTranslation('components');
  const { displayDate, displayDateTime } = useDateTime();

  /**
   * Returns a validate method that enforces a field's value to be an integer.
   *
   * @returns true if field value is an integer, otherwise returns a string with the error message.
   */
  const fieldIsInteger = (value: string | number): true | string => {
    const valueAsNumber = +value;
    return (
      (!isNaN(valueAsNumber) && Number.isInteger(valueAsNumber)) || t('form.validation.isInteger')
    );
  };

  /**
   * Returns a validate method that enforces a field's value to be greater than or equal to a certain minValue.
   *
   * @param minValue - minimum value allowed.
   * @returns true if field value is greater than or equal to the minValue, otherwise returns a string with the error message.
   */
  const fieldMinValue =
    (minValue: number) =>
    (value: string | number): true | string => {
      const valueAsNumber = +value;
      return (
        (!isNaN(valueAsNumber) && valueAsNumber >= minValue) ||
        `${t('form.validation.minValue')} ${minValue}`
      );
    };

  /**
   * Returns a validate method that enforces a field's value to be greater than zero.
   *
   * @returns true if field value is greater than or equal to the minValue, otherwise returns a string with the error message.
   */
  const fieldGreaterThanZero = (value: number): true | string =>
    value > 0 || `${t('form.validation.greaterThanZero')}`;

  /**
   * Returns a validate method that enforces a field's value to be greater than zero.
   *
   * @returns true if field value is greater than or equal to the minValue, otherwise returns a string with the error message.
   */
  const fieldGreaterThanOrEqualZero = (value: number): true | string =>
    value >= 0 || `${t('form.validation.greaterThanZero')}`;

  /**
   * Returns a validate method that enforces a field's value to be less than or equal to a certain maxValue.
   *
   * @param maxValue - maximum value allowed.
   * @returns true if field value is less than or equal to the minValue, otherwise returns a string with the error message.
   */
  const fieldMaxValue =
    (maxValue: number) =>
    (value: string | number): true | string => {
      const valueAsNumber = +value;
      return (
        (!isNaN(valueAsNumber) && valueAsNumber <= maxValue) ||
        `${t('form.validation.maxValue')} ${maxValue}`
      );
    };

  /**
   * Returns a validate method that enforces a field's value isn't solely whitespace.
   *
   * @returns true if field value is not solely whitespace, otherwise returns a string with the error message.
   */
  const notWhiteSpaceOnly = (value: string): true | string =>
    (typeof value !== 'undefined' && typeof value !== 'string') ||
    value.length === 0 ||
    value.trim().length > 0 ||
    t('form.validation.notWhiteSpaceOnly');

  /**
   * Returns a validate method that enforces a field's value is a valid Date.
   *
   * @returns true if field value is a valid Date/DateTime, otherwise returns a string with the error message.
   * Returns true if value is undefined or an empty string to by-pass validity check if field is not required.
   * If field is required, the react-hook-form library should catch the undefined state before this is ran.
   *
   * value is an empty string as a default value from `useForm` - if no DateTime default is applicable.
   * value is undefined when a user types/adds a DateTime but then deletes it.
   */
  const validDate = (value: DateTime | null | ''): true | string => {
    const exampleDate = displayDate({
      date: DateTime.now().toMillis(),
      timezone: DateTime.now().zoneName,
    });
    return (
      typeof value === 'undefined' ||
      value === null ||
      value === '' ||
      (value !== null && value.isValid) ||
      t('form.validation.validDate', { exampleDate })
    );
  };

  /**
   * Returns a validate method that enforces a field's value is a valid Date and/or DateTime.
   *
   * @returns true if field value is a valid Date/DateTime, otherwise returns a string with the error message.
   * Returns true if value is undefined or an empty string to by-pass validity check if field is not required.
   * If field is required, the react-hook-form library should catch the undefined state before this is ran.
   *
   * value is an empty string as a default value from `useForm` - if no DateTime default is applicable.
   * value is undefined when a user types/adds a DateTime but then deletes it.
   */
  const validDateTime = (value: DateTime | null | ''): true | string => {
    const exampleDateTime = displayDateTime({
      date: DateTime.now().toMillis(),
      timezone: DateTime.now().zoneName,
      showTimezone: false,
    });
    return (
      typeof value === 'undefined' ||
      value === null ||
      value === '' ||
      (value !== null && value.isValid) ||
      t('form.validation.validDateTime', { exampleDateTime })
    );
  };

  /**
   * Returns a validate method that enforces a field's value is a valid e-mail address.
   *
   * @returns true if field value is greater than or equal to the minValue, otherwise returns a string with the error message.
   */
  const validEmail = (value: string): true | string => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(value) || t('form.validation.validEmail');
  };

  return {
    fieldIsInteger,
    fieldMinValue,
    fieldGreaterThanZero,
    fieldGreaterThanOrEqualZero,
    fieldMaxValue,
    notWhiteSpaceOnly,
    validDate,
    validDateTime,
    validEmail,
  };
};
