import { Box } from '@mui/material';

import DataTableQueryBasedProvider from '@components/data-table/context/QueryBasedProvider';
import DataTableQuickFilter from '@components/data-table/controls/filter/quick-filter';
import DataTableLayoutDropdown from '@components/data-table/controls/layout/layout-dropdown';
import DataTableQueryBasedControls from '@components/data-table/controls/QueryBasedControls';
import DataTableTableContainer from '@components/data-table/DataTableTableContainer';
import {
  DataTableVariants,
  IDataTableQueryBasedProps,
} from '@components/data-table/model/data-table';
import DataTableQueryBasedPagination from '@components/data-table/pagination/QueryBasedPagination';
import DataTableQueryBasedTable from '@components/data-table/table/QueryBasedTable';
import DataTableQueryBasedCollapse from '@components/data-table/variants/QueryBasedCollapse';

const DataTableQueryBased = ({
  queryBasedProps,
}: {
  queryBasedProps: IDataTableQueryBasedProps;
}) => {
  return (
    <DataTableQueryBasedProvider dataTableProps={queryBasedProps}>
      {queryBasedProps.variant === DataTableVariants.Basic && (
        <DataTableTableContainer>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ padding: (theme) => theme.spacing(3, 3, 1) }}
            data-testid={`data-table-${queryBasedProps.tableId}-header`}
          >
            <DataTableLayoutDropdown
              tableHeader={queryBasedProps.tableHeader}
              additionalOptions={queryBasedProps.layoutDropdownAddtOptions}
              additionalOptionsSectionLabel={
                !!queryBasedProps.layoutDropdownAddtOptionLabel
                  ? queryBasedProps.layoutDropdownAddtOptionLabel
                  : undefined
              }
            />
            {!!queryBasedProps.tableActions && queryBasedProps.tableActions}
          </Box>
          <DataTableQueryBasedControls />
          <DataTableQuickFilter />
          <DataTableQueryBasedTable />
          <DataTableQueryBasedPagination />
        </DataTableTableContainer>
      )}
      {queryBasedProps.variant === DataTableVariants.Collapsible && (
        <DataTableQueryBasedCollapse
          title={
            <DataTableLayoutDropdown
              tableHeader={queryBasedProps.tableHeader}
              additionalOptions={queryBasedProps.layoutDropdownAddtOptions}
              additionalOptionsSectionLabel={
                !!queryBasedProps.layoutDropdownAddtOptionLabel
                  ? queryBasedProps.layoutDropdownAddtOptionLabel
                  : undefined
              }
            />
          }
          action={!!queryBasedProps.tableActions ? queryBasedProps.tableActions : undefined}
          bodySx={{ padding: 0 }}
          defaultCollapsed={queryBasedProps.defaultCollapsed}
        >
          <DataTableQueryBasedControls />
          <DataTableQuickFilter />
          <DataTableQueryBasedTable />
          <DataTableQueryBasedPagination />
        </DataTableQueryBasedCollapse>
      )}
    </DataTableQueryBasedProvider>
  );
};

export default DataTableQueryBased;
