import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Control, Controller, UseFormSetValue, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateSlottingRunMutation } from '@/graphql/defs/components/modals/__generated__/create-run-modal.generated';
import DataTable from '@components/data-table';
import useCreateRunFromDatasetRulesetsDataTable from '@components/data-table/hooks/table-props/useCreateRunFromDatasetRulesetsDataTable';
import CollapseLoading from '@components/loading-indicator/CollapseLoading';
import { ModalActions } from '@components/modal/modal-actions';
import { ModalButton } from '@components/modal/modal-button';
import { ModalContent } from '@components/modal/modal-content';
import { Separator } from '@components/modal/ruleset/steps';
import Step1 from '@components/modal/ruleset/steps/step-1';
import Step2 from '@components/modal/ruleset/steps/step-2';
import Step3 from '@components/modal/ruleset/steps/step-3';
import Step4 from '@components/modal/ruleset/steps/step-4';
import Step5 from '@components/modal/ruleset/steps/step-5';
import Step6 from '@components/modal/ruleset/steps/step-6';
import Step7 from '@components/modal/ruleset/steps/step-7';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useModalToggle } from '@context/modal/ModalToggleProvider';
import { useSnackbar } from '@context/snackbar';
import useRulesetForm from '@hooks/form/ruleset/useRulesetForm';
import { useFormValidation } from '@hooks/form/validators';
import FormValues from '@models/FormValues';
import { ICreateRunFromDatasetModal, ModalTypes } from '@models/modal';

type TModalActiveStep = 'setRulesetTypeAndName' | 'selectExistingRuleset' | 'previewRuleset';

export type TRulesetType = 'existing' | 'new';
interface ICreateRunFromDatasetForm {
  runName: string;
  datasetId: string;
  rulesetId: string;
  rulesetType: TRulesetType;
}

const CreateRunFromDataset = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { openModal } = useModalToggle();
  const { modal, closeModal, setLoading } = useModalContent<ICreateRunFromDatasetModal>();

  const activeModalStep = useMemo<TModalActiveStep>(() => {
    if (!modal.runName || (modal.runName && !modal.rulesetType)) {
      return 'setRulesetTypeAndName';
    } else if (modal.runName && !modal.rulesetId && modal.rulesetType === 'existing') {
      return 'selectExistingRuleset';
    } else if (modal.runName && modal.rulesetId) {
      return 'previewRuleset';
    }

    return 'setRulesetTypeAndName';
  }, [modal]);

  const openSetRulesetTypeAndNameStep = (runName: string) => {
    openModal({
      type: ModalTypes.createRunFromDataset,
      dataset: modal.dataset,
      runName,
    });
  };

  const openSelectExistingRulesetStep = (runName: string) => {
    openModal({
      type: ModalTypes.createRunFromDataset,
      dataset: modal.dataset,
      runName,
      rulesetType: 'existing',
    });
  };

  const openPreviewRulesetStep = (
    runName: string,
    rulesetId: string,
    rulesetType: TRulesetType,
  ) => {
    openModal({
      type: ModalTypes.createRunFromDataset,
      dataset: modal.dataset,
      runName,
      rulesetId,
      rulesetType,
    });
  };

  const { handleSubmit, control, watch, setValue, getValues } = useForm<
    FormValues<ICreateRunFromDatasetForm>
  >({
    defaultValues: {
      datasetId: modal.dataset.id,
      runName: modal.runName || '',
      rulesetId: modal.rulesetId || '',
      rulesetType: modal.runName && !modal.rulesetId ? 'existing' : '',
    },
  });

  const [createRun] = useCreateSlottingRunMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t('modal.run.createFromDataset.success', { name: getValues('runName') }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const handleInvalidSubmit = () => {
    setLoading(false);
  };

  const rulesetType = watch('rulesetType');
  const runName = watch('runName');
  const rulesetId = watch('rulesetId');

  const isSubmitButtonDisabled = useMemo(() => {
    switch (activeModalStep) {
      case 'previewRuleset':
        return false;
      case 'selectExistingRuleset':
        return !rulesetId || rulesetId === '';
      case 'setRulesetTypeAndName':
      default:
        return runName === '' || rulesetType === '';
    }
  }, [activeModalStep, runName, rulesetType, rulesetId]);

  const submitButtonText = useMemo(() => {
    switch (activeModalStep) {
      case 'previewRuleset':
        return t('modal.run.createFromDataset.createRun');
      case 'selectExistingRuleset':
        return t('modal.run.createFromDataset.selectRuleSet');
      case 'setRulesetTypeAndName':
      default:
        return t('common.continue');
    }
  }, [activeModalStep]);

  const handleRulesetTypeSubmit = (fields: ICreateRunFromDatasetForm) => {
    if (fields.rulesetType === 'existing') {
      openSelectExistingRulesetStep(fields.runName);
    } else if (fields.rulesetType === 'new') {
      openModal(
        {
          type: ModalTypes.rulesetCreateOrCopy,
          rulesetId: null,
        },
        {
          beforeClose: (success, opts) => {
            if (success && opts.rulesetId) {
              openPreviewRulesetStep(fields.runName, opts.rulesetId, 'new');
            } else if (success && !opts.rulesetId) {
              showMessage({
                type: 'error',
                message: t('modal.run.createFromDataset.error_newRulesetIdMissing'),
              });
              openSetRulesetTypeAndNameStep(fields.runName);
            }
          },
        },
      );
    }
  };

  const handleSelectExistingRulesetSubmit = (fields: ICreateRunFromDatasetForm) => {
    openPreviewRulesetStep(modal.runName, fields.rulesetId, 'existing');
  };

  const handleCreateRunSubmit = () => {
    createRun({
      variables: {
        input: {
          slottingRun: {
            name: modal.runName,
            datasetId: modal.dataset.id,
            rulesetId: modal.rulesetId,
          },
        },
      },
    });
  };

  const handleSubmitButton = () => {
    switch (activeModalStep) {
      case 'previewRuleset':
        handleCreateRunSubmit();
        break;
      case 'selectExistingRuleset':
        handleSubmit(handleSelectExistingRulesetSubmit, handleInvalidSubmit)();
        break;
      case 'setRulesetTypeAndName':
      default:
        handleSubmit(handleRulesetTypeSubmit, handleInvalidSubmit)();
        break;
    }
  };

  const handleBackButton = () => {
    switch (activeModalStep) {
      case 'previewRuleset':
        if (modal.rulesetType === 'existing') {
          openSelectExistingRulesetStep(modal.runName);
        } else if (modal.rulesetType === 'new') {
          openSetRulesetTypeAndNameStep(modal.runName);
        }
        break;
      case 'selectExistingRuleset':
        openSetRulesetTypeAndNameStep(modal.runName);
        break;
      case 'setRulesetTypeAndName':
      default:
        closeModal();
        break;
    }
  };

  return (
    <>
      <ModalContent sx={{ width: '98vw' }}>
        {activeModalStep === 'setRulesetTypeAndName' && <RulesetTypeStep control={control} />}
        {activeModalStep === 'selectExistingRuleset' && (
          <SelectExistingRulesetStep setValue={setValue} runName={modal.runName} />
        )}
        {activeModalStep === 'previewRuleset' && (
          <PreviewRulesetStep runName={modal.runName} rulesetId={modal.rulesetId} />
        )}
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => handleBackButton()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.back')}
        </ModalButton>
        <ModalButton
          onClick={handleSubmitButton}
          isDisabled={isSubmitButtonDisabled}
          variant="contained"
          color="primary"
          actionType="submit"
        >
          {submitButtonText}
        </ModalButton>
      </ModalActions>
    </>
  );
};

export default CreateRunFromDataset;

const RulesetTypeStep = ({
  control,
}: {
  control: Control<FormValues<ICreateRunFromDatasetForm>, any>;
}) => {
  const { t } = useTranslation('components');
  const { notWhiteSpaceOnly } = useFormValidation();

  return (
    <Grid container spacing={6} pt={2}>
      <Grid item xs={6}>
        <Controller
          name="runName"
          control={control}
          rules={{
            required: t('form.validation.requiredField', { field: t('common.name') }),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              required
              fullWidth
              id="run-name"
              label={t('common.name')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              dataTestId="create-run-from-dataset-name"
            />
          )}
        />
      </Grid>
      <Grid item xs={6} />
      <Grid item xs={12}>
        <Controller
          name="rulesetType"
          control={control}
          rules={{
            required: t('form.validation.requiredField', { field: t('common.name') }),
          }}
          render={({ field, fieldState }) => (
            <FormControl error={!!fieldState?.error?.message}>
              <FormLabel id="ruleset-type">
                <Typography variant="h4" mb={4}>
                  {t('modal.run.createFromDataset.rulesetType')}
                </Typography>
              </FormLabel>
              <Box ml={4}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={field.value}
                  onChange={(e, value) => {
                    field.onChange(value);
                  }}
                >
                  <FormControlLabel
                    value="existing"
                    control={<Radio />}
                    label={
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body1">
                          {t('modal.run.createFromDataset.existingRuleset_label')}
                        </Typography>
                        <Typography variant="body4">
                          {t('modal.run.createFromDataset.existingRuleset_subtext')}
                        </Typography>
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value="new"
                    control={<Radio />}
                    label={
                      <Box display="flex" flexDirection="column">
                        <Typography variant="body1">
                          {t('modal.run.createFromDataset.createNewRuleset_label')}
                        </Typography>
                        <Typography variant="body4">
                          {t('modal.run.createFromDataset.createNewRuleset_subtext')}
                        </Typography>
                      </Box>
                    }
                  />
                </RadioGroup>
                {!!fieldState?.error?.message && (
                  <FormHelperText>{fieldState.error.message}</FormHelperText>
                )}
              </Box>
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  );
};

const SelectExistingRulesetStep = ({
  setValue,
  runName,
}: {
  setValue: UseFormSetValue<FormValues<ICreateRunFromDatasetForm>>;
  runName: string;
}) => {
  const { t } = useTranslation('components');
  const { selectedRuleset, dataTableProps } = useCreateRunFromDatasetRulesetsDataTable();

  useEffect(() => {
    if (selectedRuleset?.id) {
      setValue('rulesetId', selectedRuleset.id);
    } else {
      setValue('rulesetId', '');
    }
  }, [selectedRuleset?.id]);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box display="flex" gap={2}>
        <Typography variant="h4">{t('modal.run.createFromDataset.runName')}:</Typography>
        <Typography variant="h4" fontWeight={400}>
          {runName}
        </Typography>
      </Box>
      <Box sx={{ maxWidth: '100%', border: '1px solid #D8E0E5', borderRadius: '4px' }}>
        <DataTable {...dataTableProps} />
      </Box>
    </Box>
  );
};

const PreviewRulesetStep = ({ runName, rulesetId }: { runName: string; rulesetId: string }) => {
  const { t } = useTranslation('components');
  const [preparing, setPreparing] = useState(true);

  const { control, clearErrors, getValues, setValue } = useRulesetForm({
    readonly: true,
    rulesetId: rulesetId,
    setPreparing,
  });

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Box display="flex" gap={2}>
        <Typography variant="h4">{t('modal.run.createFromDataset.runName')}:</Typography>
        <Typography variant="h4" fontWeight={400}>
          {runName}
        </Typography>
      </Box>
      {preparing ? (
        <CollapseLoading />
      ) : (
        <Box>
          <Step1 control={control} review={true} active={false} />
          <Separator />
          <Step2 control={control} review={true} active={false} />
          <Separator />
          <Step3 control={control} review={true} active={false} />
          <Separator />
          <Step4
            control={control}
            review={true}
            active={false}
            clearErrors={clearErrors}
            getValues={getValues}
            setValue={setValue}
          />
          <Separator />
          <Step5 control={control} review={true} active={false} />
          <Separator />
          <Step6 control={control} review={true} active={false} />
          <Separator />
          <Step7 control={control} review={true} active={false} />
        </Box>
      )}
    </Box>
  );
};
