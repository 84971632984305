/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListedTeamFragment = { __typename?: 'Team', id: string, name: string };

export type ListAllTeamsQueryVariables = Types.Exact<{
  warehouseId: Types.Scalars['ID']['input'];
}>;


export type ListAllTeamsQuery = { __typename?: 'Query', getTeams: { __typename?: 'TeamOffsetConnection', teams: Array<{ __typename?: 'Team', id: string, name: string }> } };

export const ListedTeamFragmentDoc = gql`
    fragment ListedTeam on Team {
  id
  name
}
    `;
export const ListAllTeamsDocument = gql`
    query ListAllTeams($warehouseId: ID!) {
  getTeams: teams(
    filter: {warehouseId: {eq: $warehouseId}}
    sorting: {field: name, direction: ASC}
  ) {
    teams: nodes {
      ...ListedTeam
    }
  }
}
    ${ListedTeamFragmentDoc}`;

/**
 * __useListAllTeamsQuery__
 *
 * To run a query within a React component, call `useListAllTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllTeamsQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useListAllTeamsQuery(baseOptions: Apollo.QueryHookOptions<ListAllTeamsQuery, ListAllTeamsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllTeamsQuery, ListAllTeamsQueryVariables>(ListAllTeamsDocument, options);
      }
export function useListAllTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllTeamsQuery, ListAllTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllTeamsQuery, ListAllTeamsQueryVariables>(ListAllTeamsDocument, options);
        }
export type ListAllTeamsQueryHookResult = ReturnType<typeof useListAllTeamsQuery>;
export type ListAllTeamsLazyQueryHookResult = ReturnType<typeof useListAllTeamsLazyQuery>;
export type ListAllTeamsQueryResult = Apollo.QueryResult<ListAllTeamsQuery, ListAllTeamsQueryVariables>;