import { IFeatureFlagToggles } from '@context/feature-flags';

// Note: This file is mainly used as a Developer aid for subbing the feature flags locally, when REACT_APP_STUB_FEATURE_FLAGS is true.
// As well as, this file helps developers make sure the JSON they put into GCP includes all necessary flags.
// While there are flags set here, it is not guaranteed these values match those in the GCP secret.

// IMPORTANT: This secret is expecting JSON, so no trailing commas and all property names / strings need to be in double quotes.
const _stubbedFeatureFlags: IFeatureFlagToggles = {
  "companies": {
    "allowCreate": true,
    "allowEditDetails": true,
    "allowEditContactInfo": true,
    "allowEditDisplayPref": true
  },
  "dataTables": {
    "totalCountRefreshMs": 60000,
    "savedPaginationTTL": 300000
  },
  "guide": {
    "enabled": true
  },
  "maintenanceMode": {
    "enabled": false,
    "title": "",
    "message": ""
  },
  "organization": {
    "allowEditDetails": true,
    "allowEditContactInfo": true,
    "allowEditDisplayPref": true
  },
  "pagesEnabled": {
    "map": true,
    "settings": {
      "apiLogs": false,
      "agentConfig": true,
      "erpSystemAuth": false,
      "hardware": false,
      "licensePlates": false,
      "numberRanges": false,
      "organization": true,
      "processes": false,
      "queueLogs": false,
      "serialNumbers": false,
      "stockStatus": true,
      "sapSettings": {
        "company": false,
        "costCenters": false,
        "documentTypes": false,
        "movementTypes": false,
        "syncSettings": false,
        "warehouseMapping": true
      }
    },
    "support": false,
    "developerTools": true
  },
  "stockStatuses": {
    "allowCreate": true,
    "allowEdit": true,
    "allowDelete": true
  },
  "warehouses": {
    "allowCreate": true,
    "allowEditDetails": true,
    "allowEditContactInfo": true,
    "allowEditDisplayPref": true
  },
  "warehouseTypes": {
    "default": {
      "barcodes": {
        "showNonCompliantTable": false
      },
      "deliveries": {
        "assignDoor": true,
        "unassignDoor": true,
        "showAsnOcInfo": false,
        "showCategory": false,
        "showERPDates": true,
        "showERPCode": true,
        "showGoodsIssueReceipt": true,
        "showLoadUnload": true,
        "showERPBlock": true,
        "showAvailability": false
      },
      "doors": {
        "allowCreate": true,
        "allowEdit": true,
        "allowDelete": true
      },
      "inboundDeliveries": {
        "showPGR": true,
        "manageInboundLPs": true
      },
      "inventory": {
        "deliveryAssociated": false,
        "levelsEnabled": {
          "bin": true,
          "counting": false,
          "lot": true,
          "lostAndFound": false,
          "lp": true,
          "product": true
        },
        "levels": {
          "bin": {
            "showAdjustInventory": true
          }
        },
        "binLevelActions": {
          "adjustInventory": true,
          "updateStockStatus": false,
          "createCount": true,
          "createBinToBin": true,
          "createIssueStock": false
        },
        "licensePlateActions": {
          "add": true,
          "block": true,
          "create": true,
          "merge": true,
          "move": true,
          "unpack": true
        },
        "lostAndFoundActions": {
          "issueStock": false,
          "editStock": false,
          "binToBin": false
        }
      },
      "licensePlates": {
        "details": {
          "showNestedTable": false
        }
      },
      "outboundDeliveries": {
        "allowShortShip": false,
        "showPGI": true,
        "showPromiseDate": false,
        "loadCreate": {
          "columnType": "product"
        }
      },
      "tasks": {
        "actions": {
          "editUoM": true,
          "cancelTasks": true
        }
      }
    },
    "aoe": {
      "barcodes": {
        "showNonCompliantTable": true
      },
      "deliveries": {
        "assignDoor": true,
        "unassignDoor": true,
        "showAsnOcInfo": false,
        "showCategory": false,
        "showERPDates": true,
        "showERPCode": true,
        "showGoodsIssueReceipt": true,
        "showLoadUnload": true,
        "showERPBlock": true,
        "showAvailability": false
      },
      "doors": {
        "allowCreate": true,
        "allowEdit": true,
        "allowDelete": true
      },
      "inboundDeliveries": {
        "showPGR": true,
        "manageInboundLPs": true
      },
      "inventory": {
        "deliveryAssociated": false,
        "levelsEnabled": {
          "bin": true,
          "counting": false,
          "lot": true,
          "lostAndFound": false,
          "lp": true,
          "product": true
        },
        "levels": {
          "bin": {
            "showAdjustInventory": true
          }
        },
        "binLevelActions": {
          "adjustInventory": true,
          "updateStockStatus": false,
          "createCount": true,
          "createBinToBin": true,
          "createIssueStock": false
        },
        "licensePlateActions": {
          "add": true,
          "block": true,
          "create": true,
          "merge": true,
          "move": true,
          "unpack": true
        },
        "lostAndFoundActions": {
          "issueStock": false,
          "editStock": false,
          "binToBin": false
        }
      },
      "licensePlates": {
        "details": {
          "showNestedTable": false
        }
      },
      "outboundDeliveries": {
        "allowShortShip": false,
        "showPGI": true,
        "showPromiseDate": false,
        "loadCreate": {
          "columnType": "product"
        }
      },
      "tasks": {
        "actions": {
          "editUoM": true,
          "cancelTasks": true
        }
      }
    },
    "pto": {
      "barcodes": {
        "showNonCompliantTable": false
      },
      "deliveries": {
        "assignDoor": false,
        "unassignDoor": false,
        "showAsnOcInfo": true,
        "showCategory": true,
        "showERPDates": false,
        "showERPCode": false,
        "showGoodsIssueReceipt": false,
        "showLoadUnload": true,
        "showERPBlock": false,
        "showAvailability": true
      },
      "doors": {
        "allowCreate": false,
        "allowEdit": false,
        "allowDelete": false
      },
      "inboundDeliveries": {
        "showPGR": false,
        "manageInboundLPs": false
      },
      "inventory": {
        "deliveryAssociated": true,
        "levelsEnabled": {
          "bin": true,
          "counting": true,
          "lot": false,
          "lostAndFound": true,
          "lp": true,
          "product": true
        },
        "levels": {
          "bin": {
            "showAdjustInventory": false
          }
        },
        "binLevelActions": {
          "adjustInventory": false,
          "updateStockStatus": true,
          "createCount": true,
          "createBinToBin": true,
          "createIssueStock": true
        },
        "licensePlateActions": {
          "add": false,
          "block": true,
          "create": false,
          "merge": false,
          "move": true,
          "unpack": false
        },
        "lostAndFoundActions": {
          "issueStock": true,
          "editStock": true,
          "binToBin": true
        }
      },
      "licensePlates": {
        "details": {
          "showNestedTable": false
        }
      },
      "outboundDeliveries": {
        "allowShortShip": true,
        "showPGI": false,
        "showPromiseDate": true,
        "loadCreate": {
          "columnType": "lp"
        }
      },
      "tasks": {
        "actions": {
          "editUoM": false,
          "cancelTasks": true
        }
      }
    }
  }
};

export default _stubbedFeatureFlags;
