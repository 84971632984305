const FulfilldLogo = ({ fillColor }: { fillColor: string }) => {
  return (
    <svg width="65" height="61" viewBox="0 0 65 61" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_27216_95056"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="65"
        height="61"
      >
        <g clipPath="url(#clip0_27216_95056)">
          <path
            d="M13.1849 14.3703C13.1849 16.0147 11.8796 17.3421 10.2624 17.3421C8.64526 17.3421 7.33984 16.0147 7.33984 14.3703C7.33984 12.7259 8.64526 11.3984 10.2624 11.3984C11.8796 11.3984 13.1849 12.7259 13.1849 14.3703Z"
            fill="#3CACFE"
          />
          <path
            d="M24.5805 29.2697C26.6788 29.2697 28.3798 27.54 28.3798 25.4064C28.3798 23.2727 26.6788 21.543 24.5805 21.543C22.4823 21.543 20.7812 23.2727 20.7812 25.4064C20.7812 27.54 22.4823 29.2697 24.5805 29.2697Z"
            fill="#3CACFE"
          />
          <path
            d="M12.7552 48.3507C12.7552 49.9951 11.4498 51.3226 9.83269 51.3226C8.21551 51.3226 6.91016 49.9951 6.91016 48.3507C6.91016 46.7063 8.21551 45.3789 9.83269 45.3789C11.4498 45.3789 12.7552 46.7063 12.7552 48.3507Z"
            fill="#3CACFE"
          />
          <path
            d="M55.542 51.4798C56.6502 51.4798 57.5488 50.5661 57.5488 49.4391C57.5488 48.3121 56.6502 47.3984 55.542 47.3984C54.4336 47.3984 53.5352 48.3121 53.5352 49.4391C53.5352 50.5661 54.4336 51.4798 55.542 51.4798Z"
            fill="#3CACFE"
          />
          <path
            d="M58.4623 14.925C58.4623 16.5694 57.1568 17.8968 55.5397 17.8968C53.9226 17.8968 52.6172 16.5694 52.6172 14.925C52.6172 13.2805 53.9226 11.9531 55.5397 11.9531C57.1568 11.9531 58.4623 13.2805 58.4623 14.925Z"
            fill="#3CACFE"
          />
          <path
            d="M4.39695 35.2151C6.49524 35.2151 8.19623 33.4854 8.19623 31.3517C8.19623 29.218 6.49524 27.4883 4.39695 27.4883C2.29866 27.4883 0.597656 29.218 0.597656 31.3517C0.597656 33.4854 2.29866 35.2151 4.39695 35.2151Z"
            fill="#3CACFE"
          />
          <path
            d="M26.334 59.1458C26.334 60.2553 25.4573 61.1667 24.3467 61.1667C23.2556 61.1667 22.3594 60.2751 22.3594 59.1458C22.3594 58.0363 23.2361 57.125 24.3467 57.125C25.4573 57.125 26.334 58.0363 26.334 59.1458Z"
            fill="#3CACFE"
          />
          <path
            d="M43.325 58.7703C43.325 60.0185 42.3314 61.0289 41.1039 61.0289C39.8765 61.0289 38.8828 60.0185 38.8828 58.7703C38.8828 57.5222 39.8765 56.5117 41.1039 56.5117C42.3314 56.5117 43.325 57.5222 43.325 58.7703Z"
            fill="#3CACFE"
          />
          <path
            d="M33.6036 54.5125C35.7879 54.5125 37.5588 52.7118 37.5588 50.4906C37.5588 48.2694 35.7879 46.4688 33.6036 46.4688C31.4192 46.4688 29.6484 48.2694 29.6484 50.4906C29.6484 52.7118 31.4192 54.5125 33.6036 54.5125Z"
            fill="#3CACFE"
          />
          <path
            d="M64.7384 32.5804C64.7384 34.3436 63.3355 35.7702 61.6017 35.7702C59.8677 35.7702 58.4648 34.3436 58.4648 32.5804C58.4648 30.8171 59.8677 29.3906 61.6017 29.3906C63.3355 29.3906 64.7384 30.8171 64.7384 32.5804Z"
            fill="#3CACFE"
          />
          <path
            d="M21.1514 41.6938C21.1514 42.7043 20.3525 43.5166 19.3589 43.5166C18.3652 43.5166 17.5664 42.7043 17.5664 41.6938C17.5664 40.6834 18.3652 39.8711 19.3589 39.8711C20.3525 39.8711 21.1514 40.6834 21.1514 41.6938Z"
            fill="#3CACFE"
          />
          <path
            d="M50.8843 42.7838C50.8843 44.4084 49.5984 45.716 48.0007 45.716C46.4031 45.716 45.1172 44.4084 45.1172 42.7838C45.1172 41.1592 46.4031 39.8516 48.0007 39.8516C49.5984 39.8714 50.8843 41.179 50.8843 42.7838Z"
            fill="#3CACFE"
          />
          <path
            d="M42.0386 6.64545C43.0286 6.64545 43.8311 5.82938 43.8311 4.82272C43.8311 3.81606 43.0286 3 42.0386 3C41.0486 3 40.2461 3.81606 40.2461 4.82272C40.2461 5.82938 41.0486 6.64545 42.0386 6.64545Z"
            fill="#3CACFE"
          />
          <path
            d="M46.5988 25.4056C46.5988 27.9614 44.553 30.0417 42.0396 30.0417C39.5262 30.0417 37.4805 27.9614 37.4805 25.4056C37.4805 22.8499 39.5262 20.7695 42.0396 20.7695C44.553 20.7695 46.5988 22.8499 46.5988 25.4056Z"
            fill="#3CACFE"
          />
          <path
            d="M29.1418 5.21808C29.1418 7.77386 27.096 9.85413 24.5826 9.85413C22.0692 9.85413 20.0234 7.77386 20.0234 5.21808C20.0234 2.66231 22.0692 0.582031 24.5826 0.582031C27.096 0.582031 29.1418 2.66231 29.1418 5.21808Z"
            fill="#3CACFE"
          />
          <path d="M41.8242 4.82031V25.4052H42.2529V4.82031" fill="#3CACFE" />
          <path
            d="M41.9219 5.00115L55.4045 15.1054L55.6578 14.7487L42.1752 4.64453"
            fill="#3CACFE"
          />
          <path
            d="M42.1751 25.5834L55.6577 15.1027L55.4044 14.7461L41.9023 25.2267"
            fill="#3CACFE"
          />
          <path
            d="M61.6782 32.3793L42.1168 25.1875L41.9609 25.6035L61.5224 32.7953"
            fill="#3CACFE"
          />
          <path
            d="M55.3281 15.0061L61.3875 32.6588L61.7967 32.5201L55.7373 14.8477"
            fill="#3CACFE"
          />
          <path
            d="M47.8633 42.9618L55.7541 49.6187L56.0463 49.2819L48.1555 42.625"
            fill="#3CACFE"
          />
          <path
            d="M41.8438 25.4668L47.8057 42.8619L48.2148 42.7232L42.2529 25.3281"
            fill="#3CACFE"
          />
          <path
            d="M33.8168 50.4302L24.7764 25.3281L24.3672 25.4866L33.4076 50.5688"
            fill="#3CACFE"
          />
          <path
            d="M42.1544 25.0898L33.4062 50.4296L33.8154 50.5683L42.5635 25.2285"
            fill="#3CACFE"
          />
          <path
            d="M24.7375 25.5458L42.2142 4.96096L41.883 4.68359L24.4062 25.2486"
            fill="#3CACFE"
          />
          <path d="M24.582 5.43758L42.0393 5.04134V4.60547L24.582 5.00172" fill="#3CACFE" />
          <path
            d="M10.0482 14.293L4.20312 31.2918L4.61228 31.4305L10.4573 14.4515"
            fill="#3CACFE"
          />
          <path d="M24.4649 4.625L10.1445 13.8377L10.3783 14.1943L24.6987 5.00143" fill="#3CACFE" />
          <path
            d="M4.20312 31.4278L9.61956 48.4068L10.0287 48.2681L4.61228 31.2891"
            fill="#3CACFE"
          />
          <path d="M24.561 59.0873L19.5732 41.6328L19.1641 41.7517L24.1519 59.226" fill="#3CACFE" />
          <path d="M9.69531 48.5285L24.23 59.3261L24.4833 58.9696L9.94855 48.1719" fill="#3CACFE" />
          <path
            d="M41.084 58.5508L24.3477 58.9272L24.3671 59.3829L41.1035 58.9867"
            fill="#3CACFE"
          />
          <path
            d="M47.9022 42.582L33.5039 50.3088L33.7182 50.6852L48.1166 42.9782"
            fill="#3CACFE"
          />
          <path
            d="M41.2026 58.9499L56.0101 49.6382L55.7958 49.2617L40.9883 58.5735"
            fill="#3CACFE"
          />
          <path
            d="M61.3845 32.5195L55.6953 49.3797L56.1044 49.5183L61.7937 32.6582"
            fill="#3CACFE"
          />
          <path d="M4.45675 31.567L24.6417 25.6035L24.5248 25.1875L4.33984 31.151" fill="#3CACFE" />
          <path d="M10.125 14.548L24.4455 25.5834L24.7182 25.2268L10.3978 14.1914" fill="#3CACFE" />
          <path d="M24.3672 4.82031V25.4052H24.7959V4.82031" fill="#3CACFE" />
          <path
            d="M4.28125 31.5483L19.9071 41.8704L20.1409 41.494L4.51506 31.1719"
            fill="#3CACFE"
          />
          <path
            d="M24.1535 25.168L19.8086 41.6319L20.2373 41.7508L24.5626 25.2868"
            fill="#3CACFE"
          />
          <path d="M33.72 50.3086L20.14 41.4922L19.9062 41.8686L33.4863 50.685" fill="#3CACFE" />
        </g>
      </mask>
      <g mask="url(#mask0_27216_95056)">
        <rect
          x="-1.25781"
          y="-3.97656"
          width="68.5988"
          height="67.3917"
          rx="33.6958"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_27216_95056">
          <rect
            width="64.1452"
            height="60.4034"
            fill={fillColor}
            transform="translate(0.597656 0.582031)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FulfilldLogo;
