import { ApolloError } from '@apollo/client';

import {
  FullContactInfoFragment,
  useUpdateContactInfoMutation,
} from '@/graphql/defs/components/modals/__generated__/contact-info-modal.generated';
import {
  FullDisplayPrefFragment,
  useUpdateDisplayPreferenceMutation,
} from '@/graphql/defs/components/modals/__generated__/display-preferences-modal.generated';
import { DisplayPreferenceUpdateDto, UpdateContactInfoDto } from '@/graphql/types.generated';
import { IVStepCBArgs } from '@components/form-util';
import { stripUnchangedFields } from '@lib/form';
import FormValues from '@models/FormValues';

export const contactInfoDefault: FormValues<UpdateContactInfoDto> = {
  email: '',
  phone: '',
  street1: '',
  street2: '',
  country: '',
  city: '',
  state: '',
  zip: '',
};

/**
 * Handle the submission of the Contact Info field block within a stepper form.
 */
export const useContactInformationStep = (args: IVStepCBArgs) => {
  const { depMap, updateDepMap, setError, setActiveStepState } = args;

  const handleError = (error: ApolloError) => {
    setError(error.message);
    setActiveStepState('error');
  };

  const [updateContactInformation] = useUpdateContactInfoMutation({
    onCompleted: ({
      updateOneContactInfoByOrganizationId,
      updateOneContactInfoByCompanyId,
      updateOneContactInfoByWarehouseId,
    }) => {
      let updatedContactInfo;
      switch (depMap.parentType) {
        case 'organization':
          updatedContactInfo = updateOneContactInfoByOrganizationId;
          break;
        case 'company':
          updatedContactInfo = updateOneContactInfoByCompanyId;
          break;
        case 'warehouse':
          updatedContactInfo = updateOneContactInfoByWarehouseId;
          break;
      }

      updateDepMap({ contactInfo: updatedContactInfo });
      setActiveStepState('success');
    },
    onError: handleError,
  });

  return (formContactInfo: UpdateContactInfoDto) => {
    const { contactInfo, parentType, parentId } = depMap as {
      contactInfo: FullContactInfoFragment;
      parentType: string;
      parentId: string;
    };

    // Only run the actual update if values changed, otherwise just return true.
    if (
      contactInfo &&
      contactInfo.email === formContactInfo.email &&
      contactInfo.phone === formContactInfo.phone &&
      contactInfo.street1 === formContactInfo.street1 &&
      contactInfo.street2 === formContactInfo.street2 &&
      contactInfo.country === formContactInfo.country &&
      contactInfo.city === formContactInfo.city &&
      contactInfo.state === formContactInfo.state &&
      contactInfo.zip === formContactInfo.zip
    ) {
      setActiveStepState('success');
      return;
    }

    void updateContactInformation({
      variables: {
        parentId: parentId,
        contactInfo: stripUnchangedFields(contactInfoDefault, formContactInfo),
        isOrg: parentType === 'organization',
        isCompany: parentType === 'company',
        isWarehouse: parentType === 'warehouse',
      },
    });
  };
};

/**
 * Handle the submission of the Display Preferences field block within a stepper form.
 */
export const useDisplayPreferencesStep = (args: IVStepCBArgs) => {
  const { depMap, updateDepMap, setError, setActiveStepState } = args;

  const [updateDisplayPreferences] = useUpdateDisplayPreferenceMutation({
    onCompleted: ({
      updateOneDisplayPreferenceByOrganizationId,
      updateOneDisplayPreferenceByCompanyId,
      updateOneDisplayPreferenceByWarehouseId,
    }) => {
      let updatedDisplayPreferences;
      switch (depMap.parentType) {
        case 'organization':
          updatedDisplayPreferences = updateOneDisplayPreferenceByOrganizationId;
          break;
        case 'company':
          updatedDisplayPreferences = updateOneDisplayPreferenceByCompanyId;
          break;
        case 'warehouse':
          updatedDisplayPreferences = updateOneDisplayPreferenceByWarehouseId;
          break;
      }

      updateDepMap({ displayPref: updatedDisplayPreferences });
      setActiveStepState('success');
    },
    onError: (error: ApolloError) => {
      setError(error.message);
      setActiveStepState('error');
    },
  });

  return (formDisplayPref: FullDisplayPrefFragment) => {
    const { parentType, parentId } = depMap as {
      parentType: string;
      parentId: string;
    };

    const displayPreferenceDTO: DisplayPreferenceUpdateDto = {
      timezone: formDisplayPref.timezone,
      numberFormat: formDisplayPref?.numberFormat,
      language: formDisplayPref.language,
      currency: formDisplayPref.currency,
      dateTimeFormat: formDisplayPref.dateTimeFormat,
    };
    void updateDisplayPreferences({
      variables: {
        parentId: parentId,
        displayPreference: displayPreferenceDTO,
        isOrg: parentType === 'organization',
        isCompany: parentType === 'company',
        isWarehouse: parentType === 'warehouse',
      },
    });
  };
};
