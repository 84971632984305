/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BinStorageTypeItemFragment = { __typename?: 'ViewBinStorageType', id?: string | null, code?: string | null, label?: string | null, description?: string | null, weightCapacity?: number | null, weightCapacityUomCode?: string | null, depth?: number | null, width?: number | null, height?: number | null, distanceUomCode?: string | null };

export type ListBinStorageTypesQueryVariables = Types.Exact<{
  warehouseId: Types.Scalars['ID']['input'];
}>;


export type ListBinStorageTypesQuery = { __typename?: 'Query', viewBinStorageTypes: { __typename?: 'ViewBinStorageTypeOffsetConnection', nodes: Array<{ __typename?: 'ViewBinStorageType', id?: string | null, code?: string | null, label?: string | null, description?: string | null, weightCapacity?: number | null, weightCapacityUomCode?: string | null, depth?: number | null, width?: number | null, height?: number | null, distanceUomCode?: string | null }> } };

export const BinStorageTypeItemFragmentDoc = gql`
    fragment BinStorageTypeItem on ViewBinStorageType {
  id
  code
  label
  description
  weightCapacity
  weightCapacityUomCode
  depth
  width
  height
  distanceUomCode
}
    `;
export const ListBinStorageTypesDocument = gql`
    query ListBinStorageTypes($warehouseId: ID!) {
  viewBinStorageTypes(
    filter: {warehouseId: {eq: $warehouseId}}
    sorting: [{field: code, direction: ASC}]
  ) {
    nodes {
      ...BinStorageTypeItem
    }
  }
}
    ${BinStorageTypeItemFragmentDoc}`;

/**
 * __useListBinStorageTypesQuery__
 *
 * To run a query within a React component, call `useListBinStorageTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListBinStorageTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListBinStorageTypesQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useListBinStorageTypesQuery(baseOptions: Apollo.QueryHookOptions<ListBinStorageTypesQuery, ListBinStorageTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListBinStorageTypesQuery, ListBinStorageTypesQueryVariables>(ListBinStorageTypesDocument, options);
      }
export function useListBinStorageTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListBinStorageTypesQuery, ListBinStorageTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListBinStorageTypesQuery, ListBinStorageTypesQueryVariables>(ListBinStorageTypesDocument, options);
        }
export type ListBinStorageTypesQueryHookResult = ReturnType<typeof useListBinStorageTypesQuery>;
export type ListBinStorageTypesLazyQueryHookResult = ReturnType<typeof useListBinStorageTypesLazyQuery>;
export type ListBinStorageTypesQueryResult = Apollo.QueryResult<ListBinStorageTypesQuery, ListBinStorageTypesQueryVariables>;