import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  AreaItemFragment,
  useListAreasQuery,
} from '@/graphql/defs/list/__generated__/list-areas.generated';
import {
  BinStorageTypeItemFragment,
  useListBinStorageTypesQuery,
} from '@/graphql/defs/list/__generated__/list-bin-storage-types.generated';
import DateTimePicker from '@components/date-time-picker';
import TextField from '@components/text-field';
import VirtualAutocomplete from '@components/virtual-autocomplete';
import { useEntityUtils } from '@context/entity-utils';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import { allowOnlyNumeric, formatAsInteger, uppercase } from '@lib/form';

const BinFields = ({ forceArea }: { forceArea: boolean }) => {
  const { t } = useTranslation('components');
  const { selectedWarehouseId } = useEntityUtils();
  const { showMessage } = useSnackbar();

  const { fieldIsInteger, fieldMaxValue, fieldMinValue, notWhiteSpaceOnly, validDateTime } =
    useFormValidation();

  const [binStorageTypes, setBinStorageTypes] = useState<
    Record<string, BinStorageTypeItemFragment>
  >({});
  useListBinStorageTypesQuery({
    skip: !selectedWarehouseId,
    variables: {
      warehouseId: selectedWarehouseId,
    },
    onCompleted: ({ viewBinStorageTypes: { nodes: _binStorageTypes } }) => {
      const _binStorageTypesMap = _binStorageTypes.reduce<
        Record<string, BinStorageTypeItemFragment>
      >((acc, binStorageType) => {
        acc[binStorageType.id] = binStorageType;
        return acc;
      }, {});
      setBinStorageTypes(_binStorageTypesMap);
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const [areas, setAreas] = useState<AreaItemFragment[]>([]);
  useListAreasQuery({
    skip: !selectedWarehouseId,
    variables: { warehouseId: selectedWarehouseId },
    onCompleted: ({ viewAreas: { areas } }) => {
      setAreas(areas);
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const { control, watch } = useFormContext();
  const binStorageTypeId = watch('binStorageTypeId');

  return (
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Controller
          name="binStorageTypeId"
          control={control}
          rules={{
            required: t('form.validation.requiredField', { field: t('bins.columns.code') }),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <VirtualAutocomplete
              freeSolo={false}
              multiple={false}
              disableClearable={true}
              autoHighlight={true}
              options={Object.keys(binStorageTypes)}
              getOptionLabel={(option) => binStorageTypes[option]?.code || ''}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(event, item, reason) => {
                field.onChange(item);
              }}
              value={field.value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  autoFocus
                  fullWidth
                  label={t('common.codeSuffix', { prefix: t('common.binStorageType') })}
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  dataTestId="bin-storage-type-dropdown-input"
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          disabled={true}
          label={t('common.binStorageTypeLabel')}
          value={binStorageTypes[binStorageTypeId]?.label || ''}
          dataTestId="bin-storage-type-label-input"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          disabled={true}
          label={t('common.binStorageTypeDescription')}
          value={binStorageTypes[binStorageTypeId]?.description || ''}
          dataTestId="bin-storage-type-description-input"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          disabled={true}
          label={t('common.weightCapacity')}
          value={
            binStorageTypes[binStorageTypeId]?.weightCapacity &&
            binStorageTypes[binStorageTypeId]?.weightCapacityUomCode
              ? `${binStorageTypes[binStorageTypeId]?.weightCapacity}${binStorageTypes[binStorageTypeId]?.weightCapacityUomCode}`
              : ''
          }
          dataTestId="bin-storage-type-weight-capacity-input"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          disabled={true}
          label={t('common.depth')}
          value={
            binStorageTypes[binStorageTypeId]?.depth &&
            binStorageTypes[binStorageTypeId]?.distanceUomCode
              ? `${binStorageTypes[binStorageTypeId]?.depth}${binStorageTypes[binStorageTypeId]?.distanceUomCode}`
              : ''
          }
          dataTestId="bin-storage-type-depth-input"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          disabled={true}
          label={t('common.width')}
          value={
            binStorageTypes[binStorageTypeId]?.width &&
            binStorageTypes[binStorageTypeId]?.distanceUomCode
              ? `${binStorageTypes[binStorageTypeId]?.width}${binStorageTypes[binStorageTypeId]?.distanceUomCode}`
              : ''
          }
          dataTestId="bin-storage-type-width-input"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          disabled={true}
          label={t('common.height')}
          value={
            binStorageTypes[binStorageTypeId]?.height &&
            binStorageTypes[binStorageTypeId]?.distanceUomCode
              ? `${binStorageTypes[binStorageTypeId]?.height}${binStorageTypes[binStorageTypeId]?.distanceUomCode}`
              : ''
          }
          dataTestId="bin-storage-type-height-input"
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ width: '100%', height: '1px', border: '1px solid gainsboro' }} />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="code"
          control={control}
          rules={{
            required: t('form.validation.requiredField', { field: t('bins.columns.code') }),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              autoFocus
              fullWidth
              required
              id="bin-code"
              dataTestId="bin-code"
              label={t('bins.columns.code')}
              {...field}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              onChange={(e) => field.onChange(uppercase(e))}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="areaId"
          control={control}
          rules={{
            required: t('form.validation.requiredField', { field: t('bins.columns.area') }),
          }}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              required
              select
              SelectProps={{
                native: true,
              }}
              id="bin-area"
              label={t('common.area')}
              {...field}
              disabled={forceArea}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              dataTestId="bin-area"
            >
              <option disabled />
              {areas.map((area) => (
                <option key={`area-${area.id}`} value={area.id}>
                  {`${area.code}: ${area.name}`}
                </option>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="aisleId"
          control={control}
          rules={{
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              id="bins-aisle"
              dataTestId="bins-aisle"
              label={t('bins.columns.aisle')}
              {...field}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="aisleColumnId"
          control={control}
          rules={{
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              id="bins-column"
              dataTestId="bins-column"
              label={t('bins.columns.column')}
              {...field}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="level"
          control={control}
          rules={{
            validate: {
              fieldIsInteger,
              minValue: fieldMinValue(0),
              maxValue: fieldMaxValue(100),
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              required
              id="bins-level"
              dataTestId="bins-level"
              label={t('bins.columns.level')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              type="number"
              onChange={(e) =>
                field.onChange(allowOnlyNumeric(e, false, { allowNegatives: false }))
              }
              value={formatAsInteger(field.value)}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="x"
          control={control}
          rules={{
            validate: fieldIsInteger,
          }}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              dataTestId="bins-x"
              label={t('bins.columns.x')}
              {...field}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              type="number"
              onChange={(e) => field.onChange(allowOnlyNumeric(e, false))}
              value={formatAsInteger(field.value)}
            />
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="y"
          control={control}
          rules={{
            validate: fieldIsInteger,
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              id="bins-y"
              dataTestId="bins-y"
              label={t('bins.columns.y')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              type="number"
              onChange={(e) => field.onChange(allowOnlyNumeric(e, false))}
              value={formatAsInteger(field.value)}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="lastCount"
          control={control}
          rules={{
            validate: {
              validDateTime,
            },
          }}
          render={({ field, fieldState }) => (
            <DateTimePicker
              label={t('common.lastCountDate')}
              field={field}
              fieldState={fieldState}
              setDate={field.onChange}
            />
          )}
        />
      </Grid>
      <Grid item xs={6} />
    </Grid>
  );
};

export default BinFields;
