import { gql } from '@apollo/client';

import { RetrieveBinDetailsForCountBinModalQuery } from '@/graphql/defs/components/modals/__generated__/create-count-bin-modal.generated';

gql`
  fragment CountBinModal on ViewBin {
    id
    code
    lastCount
    lastMovement
    hasOpenTasks
    containsProducts
  }
  query RetrieveBinDetailsForCountBinModal($binIds: [ID!]!) {
    viewBins(filter: { id: { in: $binIds } }, sorting: [{ field: code, direction: ASC }]) {
      bins: nodes {
        ...CountBinModal
      }
    }
  }

  mutation CreateCountBinTask($binId: ID!, $dueDate: DateTime) {
    createCountBinTask: createOnePhysicalInventoryTaskByBin(
      input: { physicalInventoryByBin: { binId: $binId, dueDate: $dueDate } }
    ) {
      id
      code
    }
  }
`;

export const RetrieveBinDetailsForCountBinModal_defaultData: RetrieveBinDetailsForCountBinModalQuery =
  {
    __typename: 'Query',
    viewBins: {
      __typename: 'ViewBinOffsetConnection',
      bins: [],
    },
  };
