import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdateFulfillmentHeaderMutation } from '@/graphql/defs/components/modals/__generated__/fulfillment-header-update.generated';
import { useListFulfillmentBlocksQuery } from '@/graphql/defs/list/__generated__/fulfillment-blocks.generated';
import { useListBusinessPartnersQuery } from '@/graphql/defs/list/__generated__/list-business-partners.generated';
import { ListFulfillmentBlocks_defaultData } from '@/graphql/defs/list/fulfillment-blocks';
import { ListBusinessPartners_defaultData } from '@/graphql/defs/list/list-business-partners';
import {
  BusinessPartnerSortFields,
  FulfillmentBlockSortFields,
  SortDirection,
} from '@/graphql/types.generated';
import { DatePicker } from '@components/date-picker';
import DateTimePicker from '@components/date-time-picker';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useModalToggle } from '@context/modal/ModalToggleProvider';
import { useSnackbar } from '@context/snackbar';
import { useWarehouseFeatureFlags } from '@context/warehouse-feature-flags';
import { useFormValidation } from '@hooks/form/validators';
import { stripUnchangedFields } from '@lib/form';
import FormValues from '@models/FormValues';
import { IUpdateFulfillmentHeaderModal, ModalTypes } from '@models/modal';

export interface IHeaderUpdateInput {
  fulfillmentBlockId: string;
  shipToBusinessPartnerId: string;
  soldToBusinessPartnerId: string;
  billOfLading: string;
  pointOfContact: string;
  dueDate: DateTime;
  promiseDate: DateTime;
}

const UpdateFulfillmentHeaderModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { openModal } = useModalToggle();
  const { modal, closeModal, isPreparing, setPreparing, setLoading, depBucket, updateDepBucket } =
    useModalContent<IUpdateFulfillmentHeaderModal>();
  const {
    warehouseFeatureFlags: { outboundDeliveries: outboundFlags },
  } = useWarehouseFeatureFlags();

  const [updateFulfillmentHeader] = useUpdateFulfillmentHeaderMutation({
    onCompleted: async () => {
      showMessage({
        type: 'success',
        message: t('modal.updateFulfillmentHeader.success'),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      showMessage({ type: 'error', message: error.message });
      setLoading(false);
    },
  });

  const { data: listFulfillmentBlocksData, loading: loadingFulfillmentBlocks } =
    useListFulfillmentBlocksQuery({
      variables: {
        sorting: [{ field: FulfillmentBlockSortFields.Label, direction: SortDirection.Asc }],
      },
    });

  const { fulfillmentBlocks } = useMemo(
    () => ({
      fulfillmentBlocks: (listFulfillmentBlocksData || ListFulfillmentBlocks_defaultData)
        ?.listFulfillmentBlocks?.fulfillmentBlocks,
      totalCount: 0,
    }),
    [listFulfillmentBlocksData],
  );

  const { data: listBusinessPartnersData, loading: loadingBusinessPartners } =
    useListBusinessPartnersQuery({
      variables: {
        sorting: [{ field: BusinessPartnerSortFields.Code, direction: SortDirection.Asc }],
      },
    });

  const { businessPartners } = useMemo(
    () => ({
      businessPartners: (listBusinessPartnersData || ListBusinessPartners_defaultData)
        ?.listBusinessPartners?.businessPartners,
      totalCount: 0,
    }),
    [listBusinessPartnersData],
  );

  useEffect(() => {
    if (!loadingBusinessPartners && !loadingFulfillmentBlocks) {
      setPreparing(false);
    }
  }, [fulfillmentBlocks, businessPartners]);

  // TODO: Create a helper method to help us more cleanly merge perviousFormState and defaultValues for any modal.
  const defaultValues: IHeaderUpdateInput = {
    fulfillmentBlockId: !!modal?.previousFormState?.fulfillmentBlockId
      ? modal.previousFormState.fulfillmentBlockId
      : modal.fulfillmentDetails?.fulfillmentBlock?.id || '',
    shipToBusinessPartnerId: !!modal?.previousFormState?.shipToBusinessPartnerId
      ? modal.previousFormState.shipToBusinessPartnerId
      : modal.fulfillmentDetails.shipToBusinessPartnerId || '',
    soldToBusinessPartnerId: !!modal?.previousFormState?.soldToBusinessPartnerId
      ? modal.previousFormState.soldToBusinessPartnerId
      : modal.fulfillmentDetails.soldToBusinessPartnerId || '',
    billOfLading: !!modal?.previousFormState?.billOfLading
      ? modal.previousFormState.billOfLading
      : modal.fulfillmentDetails.billOfLading || '',
    pointOfContact: !!modal?.previousFormState?.pointOfContact
      ? modal.previousFormState.pointOfContact
      : modal.fulfillmentDetails.pointOfContact || '',
    dueDate: !!modal?.previousFormState?.dueDate
      ? modal.previousFormState.dueDate
      : DateTime.fromISO(modal.fulfillmentDetails.dueDate) || null,
    promiseDate: !!modal?.previousFormState?.promiseDate
      ? modal.previousFormState.promiseDate
      : DateTime.fromISO(modal.fulfillmentDetails.promiseDate) || null,
  };
  Object.assign(defaultValues, {
    fulfillmentBlockId:
      !!modal?.previousFormState && depBucket['fulfillmentBlockId']
        ? depBucket['fulfillmentBlockId']
        : defaultValues.fulfillmentBlockId,
    shipToBusinessPartnerId:
      !!modal?.previousFormState && depBucket['shipToBusinessPartnerId']
        ? depBucket['shipToBusinessPartnerId']
        : defaultValues.shipToBusinessPartnerId,
    soldToBusinessPartnerId:
      !!modal?.previousFormState && depBucket['soldToBusinessPartnerId']
        ? depBucket['soldToBusinessPartnerId']
        : defaultValues.soldToBusinessPartnerId,
  });

  const formMethods = useForm<FormValues<IHeaderUpdateInput>>({
    defaultValues,
  });
  const { control, getValues, handleSubmit, watch } = formMethods;

  const { validDate, validDateTime } = useFormValidation();

  const NEW_BP_VALUE = 'newBusinessPartner';
  const NEW_BLOCK_VALUE = 'newFulfillmentBlock';
  const watchShipTo = watch('shipToBusinessPartnerId', null);
  const watchSoldTo = watch('soldToBusinessPartnerId', null);
  const watchBlock = watch('fulfillmentBlockId', null);
  useEffect(() => {
    const reopenCurrentModal = () => {
      openModal({
        type: ModalTypes.updateFulfillmentHeader,
        fulfillmentDetails: modal.fulfillmentDetails,
        previousFormState: getValues(),
      });
    };
    if (watchShipTo === NEW_BP_VALUE || watchSoldTo === NEW_BP_VALUE) {
      if (watchShipTo === NEW_BP_VALUE) {
        updateDepBucket('pendingDepBucketKey', 'shipToBusinessPartnerId');
      } else {
        updateDepBucket('pendingDepBucketKey', 'soldToBusinessPartnerId');
      }
      openModal(
        {
          type: ModalTypes.businessPartnerCreate,
        },
        { beforeClose: reopenCurrentModal },
      );
    }
    if (watchBlock === NEW_BLOCK_VALUE) {
      updateDepBucket('pendingDepBucketKey', 'fulfillmentBlockId');
      openModal(
        {
          type: ModalTypes.fulfillmentBlockCreate,
        },
        { beforeClose: reopenCurrentModal },
      );
    }
  }, [watchShipTo, watchSoldTo, watchBlock]);

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = (fields: IHeaderUpdateInput) => {
    const defaultWithoutDepBuckets = {
      ...defaultValues,
      fulfillmentBlockId: modal.fulfillmentDetails?.fulfillmentBlock?.id || '',
      shipToBusinessPartnerId: modal.fulfillmentDetails.shipToBusinessPartnerId || '',
      soldToBusinessPartnerId: modal.fulfillmentDetails.soldToBusinessPartnerId || '',
    };

    void updateFulfillmentHeader({
      variables: {
        input: {
          id: modal.fulfillmentDetails.id,
          update: stripUnchangedFields(defaultWithoutDepBuckets, fields),
        },
      },
    });
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Controller
                name="shipToBusinessPartnerId"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    autoFocus
                    fullWidth
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="ship-to"
                    label={t('common.shipTo')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="fulfillment-ship-to"
                  >
                    <option value="" disabled></option>
                    <option key={`ship-to-null`} value={null}>
                      {t('common.none')}
                    </option>
                    <option value={NEW_BP_VALUE}>{t('common.newBusinessPartner')}</option>
                    {businessPartners.map((partner) => (
                      <option key={`ship-to-${partner.id}`} value={partner.id}>
                        {partner.code} {partner.name && '-'} {partner.name}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="soldToBusinessPartnerId"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="sold-to"
                    label={t('common.soldTo')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="fulfillment-sold-to"
                  >
                    <option value="" disabled></option>
                    <option key={`sold-to-null`} value={null}>
                      {t('common.none')}
                    </option>
                    <option value={NEW_BP_VALUE}>{t('common.newBusinessPartner')}</option>
                    {businessPartners.map((partner) => (
                      <option key={`sold-to-${partner.id}`} value={partner.id}>
                        {partner.code} {partner.name && '-'} {partner.name}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="fulfillmentBlockId"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="block-reason"
                    label={t('common.block')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="fulfillment-block"
                  >
                    <option value="" disabled></option>
                    <option key={`block-null`} value={null}>
                      {t('common.none')}
                    </option>
                    <option value={NEW_BLOCK_VALUE}>{t('common.newFulfillmentBlock')}</option>
                    {fulfillmentBlocks.map((block) => (
                      <option key={`block-${block.id}`} value={block.id}>
                        {block.label}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="billOfLading"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    autoComplete="off"
                    id="bill-of-lading"
                    label={t('common.billOfLading')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={(e) => field.onChange(e)}
                    dataTestId="fulfillment-bill-of-lading"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="dueDate"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.dueDate') }),
                  validate: {
                    validDate,
                  },
                }}
                render={({ field, fieldState }) => (
                  <DatePicker
                    required
                    label={t('common.dueDate')}
                    field={field}
                    fieldState={fieldState}
                    setDate={field.onChange}
                  />
                )}
              />
            </Grid>
            {outboundFlags.showPromiseDate && (
              <Grid item xs={6}>
                <Controller
                  name="promiseDate"
                  control={control}
                  rules={{
                    required: t('form.validation.requiredField', {
                      field: t('common.promiseDate'),
                    }),
                    validate: {
                      validDateTime,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <DateTimePicker
                      required
                      label={t('common.promiseDate')}
                      field={field}
                      fieldState={fieldState}
                      setDate={field.onChange}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <Controller
                name="pointOfContact"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    autoComplete="off"
                    id="point-of-contact"
                    data-testid="pointOfContact"
                    label={t('common.pointOfContact')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={(e) => field.onChange(e)}
                    dataTestId="fulfillment-point-of-contact"
                  />
                )}
              />
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions>
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton
            variant="contained"
            color="primary"
            data-testid="create-bin-submit"
            actionType="submit"
          >
            {t('common.submit')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default UpdateFulfillmentHeaderModal;
