import { Section, StepWrapper } from '@components/modal/ruleset/steps';
import RestrictionsInput from '@components/modal/ruleset/steps/restrictions-input';

export const sliderMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 100,
    label: '100',
  },
];
const Step3 = ({ active, control, review }) => {
  return (
    <StepWrapper active={active} review={review}>
      <Section section="warehouseRestrictions">
        <RestrictionsInput control={control} prefix={`restrictions`} />
      </Section>
    </StepWrapper>
  );
};

export default Step3;
