import { ReactNode, SetStateAction, Dispatch } from 'react';

import { CompanyDetailsFragment } from '@/graphql/defs/components/modals/__generated__/company-details-modal.generated';
import { FullContactInfoFragment } from '@/graphql/defs/components/modals/__generated__/contact-info-modal.generated';
import { FullDisplayPrefFragment } from '@/graphql/defs/components/modals/__generated__/display-preferences-modal.generated';
import { PiApprovalTaskFragment } from '@/graphql/defs/components/modals/__generated__/inventory-adjustment-approval-modal.generated';
import { PiCreateTaskFragment } from '@/graphql/defs/components/modals/__generated__/inventory-adjustment-modal.generated';
import { WarehouseDetailsFragment } from '@/graphql/defs/components/modals/__generated__/warehouse-details-modal.generated';
import { LostAndFoundInventoryFragment } from '@/graphql/defs/hooks/shared-columns/__generated__/useCreateLostAndFoundInventoryColumns.generated';
import { TaskTableFragment } from '@/graphql/defs/hooks/shared-columns/__generated__/useCreateTaskColumns.generated';
import { AreaSettingsTableFragment } from '@/graphql/defs/hooks/table-props/__generated__/useAreaSettingsDataTable.generated';
import { BinStorageTypeRowFragment } from '@/graphql/defs/hooks/table-props/__generated__/useBinStorageTypesDataTable.generated';
import { DeliveryFulfillmentOverviewFragment } from '@/graphql/defs/hooks/table-props/__generated__/useDeliveriesFulfillmentsOverviewDataTable.generated';
import { EquipmentModelSettingsFragment } from '@/graphql/defs/hooks/table-props/__generated__/useEquipmentModelSettingsDataTable.generated';
import { EquipmentTypeSettingsFragment } from '@/graphql/defs/hooks/table-props/__generated__/useEquipmentTypeSettingsDataTable.generated';
import { UomGlossaryFragment } from '@/graphql/defs/hooks/table-props/__generated__/useUnitOfMeasureGlossaryDataTable.generated';
import { UserAdminSettingsTableRowFragment } from '@/graphql/defs/hooks/table-props/__generated__/useUserAdminSettingsDataTable.generated';
import { WarehouseRoleTypeSettingsFragment } from '@/graphql/defs/hooks/table-props/__generated__/useWarehouseRoleTypeSettingsDataTable.generated';
import { ZoneSettingsTableFragment } from '@/graphql/defs/hooks/table-props/__generated__/useZoneSettingsDataTable.generated';
import { CompanyFragment } from '@/graphql/defs/pages/__generated__/companies.generated';
import { InboundDeliveryDetailsFragment } from '@/graphql/defs/pages/__generated__/inbound-deliveries.generated';
import { OrganizationDetailsFragment } from '@/graphql/defs/pages/__generated__/organization.generated';
import { OutboundFulfillmentDetailsFragment } from '@/graphql/defs/pages/__generated__/outbound-deliveries.generated';
import { ProductWarehouseUoMFragment } from '@/graphql/defs/pages/__generated__/product.generated';
import { TeamDetailsFragment } from '@/graphql/defs/pages/__generated__/teams.generated';
import { WarehouseFragment } from '@/graphql/defs/pages/__generated__/warehouses.generated';
import { BaseDeliveryFragment } from '@/graphql/defs/shared-fragments/__generated__/delivery.generated';
import { BaseInventoryFragment } from '@/graphql/defs/shared-fragments/__generated__/inventory.generated';
import {
  BusinessPartner,
  DeliveryType,
  DoorCreateInput,
  DoorDirection,
  FulfillmentBlock,
  FulfillmentBlockCreateInput,
  LayoutVariant,
  LicensePlateDetail,
  SlottingDataset,
  ViewBin,
  ViewDoor,
  ViewEquipment,
  ViewStockStatus,
} from '@/graphql/types.generated';
import { TDeliveryItemsDataType } from '@components/data-table/hooks/shared-columns/useCreateInboundDeliveryItemColumns';
import { TRulesetsDataType } from '@components/data-table/hooks/shared-columns/useCreateRulesetColumns';
import { DeliveryFulfillmentDataType } from '@components/data-table/hooks/table-props/useDeliveriesFulfillmentsOverviewDataTable';
import { ZoneEntityTableVersion } from '@components/data-table/hooks/table-props/useZoneBinsSettingsDataTable';
import {
  TAddLayoutMethod,
  TDeleteLayoutMethod,
  TUpdateLayoutMethod,
} from '@components/data-table/hooks/useDataTableLayouts';
import TableIds from '@components/data-table/model/TableIds';
import { IAgentConfig } from '@components/modal/agent-config/AgentConfig';
import { IHeaderUpdateInput } from '@components/modal/fulfillment/update-fulfillment-header-modal';
import { IUpdateLostAndFoundLPFields } from '@components/modal/lost-and-found/update-lost-and-found-lp-modal';
import { TRulesetType } from '@components/modal/run/create-run-from-dataset';
import { TPutawayLooseProduct } from '@components/modal/task';
import { ICreateCountBinDetails } from '@components/modal/task/create-count-bin-modal';
import { IUpdateLicensePlateStockStatusDetails } from '@components/modal/task/update-license-plate-stock-status-modal';
import { ZoneEntities } from '@components/zones/zone-entity-mapping-action-menu';
import { TCloseModal } from '@context/modal/ModalContentProvider';
import FormValues from '@models/FormValues';
import { InventoryItem } from '@models/inventory';
import { LicensePlateSelection } from '@models/license-plate-actions';

export enum ModalTypes {
  generic,
  adjustQuantity,
  adjustQuantityResult,
  agentConfig,
  agentProxy,
  approveQuantityAdjustment,
  areaCreate,
  areaUpdate,
  authTimeout,
  barcodeCompliant,
  binBulkEdit,
  binCreate,
  binToBinMovement,
  binUpdate,
  binStorageTypeCreate,
  binStorageTypeUpdate,
  businessPartnerCreate,
  businessPartnerUpdate,
  blockFulfillment,
  unblockFulfillment,
  cancelTasks,
  closeDelivery,
  cancelDelivery,
  cancelDeliveryItems,
  companyCreate,
  companyDetails,
  confirmation,
  contactInformation,
  createCountBin,
  createIssueStock,
  createProduct,
  createRunFromDataset,
  createRunFromRuleset,
  datasetCreate,
  datasetDelete,
  datasetUpdate,
  deliveriesAnalytics,
  deliveryAssignDoor,
  deliveryItems,
  deliveryUnassignDoor,
  displayPreferences,
  dispositionApprove,
  dispositionDeny,
  dispositionImage,
  doorCreate,
  doorUpdate,
  editTaskUnitOfMeasure,
  equipmentAdd,
  equipmentModelAdd,
  equipmentModelUpdate,
  equipmentType,
  equipmentUpdate,
  fulfillmentBlockCreate,
  fulfillmentBlockUpdate,
  fulfillmentItems,
  licensePlateAddTo,
  licensePlateBlock,
  licensePlateCreate,
  licensePlateMerge,
  licensePlateMove,
  licensePlateUnpack,
  loadTaskComplete,
  loadTaskCreate,
  organizationDetails,
  pickTaskComplete,
  pickTaskCreate,
  pickToStagingComplete,
  pickToStagingCreate,
  postGoodsReceiptOrIssue,
  putawayTaskComplete,
  putawayTaskCreate,
  roleCreate,
  roleDelete,
  rolePermissionsSave,
  rulesetCreateOrCopy,
  runDeployMovements,
  salesOrderList,
  shortShip,
  stockStatusCopy,
  stockStatusCreate,
  stockStatusEdit,
  tableLayoutCreate,
  tableLayoutDelete,
  tableLayoutOverwrite,
  taskEditDetails,
  taskTypeBinStatusMapping,
  taskTypeStockStatusMapping,
  teamCreate,
  teamUpdate,
  unitOfMeasureGlossary,
  unloadTaskComplete,
  unloadTaskCreate,
  updateFulfillmentHeader,
  updateLicensePlateStockStatus,
  updateLostAndFoundLP,
  userAddToTeam,
  userCreate,
  userDelete,
  userGroupCreate,
  userGroupCopy,
  userGroupEdit,
  userGroupUsers,
  userGroupUsersEdit,
  userUpdate,
  warehouseCreate,
  warehouseDetails,
  warehouseRoleType,
  warehouseUnitOfMeasure,
  zoneCreate,
  zoneUpdate,
  updateZoneEntityMapping,
}

export type TRefetchDataTable = () => void;

export type ModalMaxWidth = 'sm' | 'md' | 'lg' | false;

// As of 03/14/2022 this is only used for basic tests surrounding modal.
// However, this could be retrofitted to be use for basic / non-actionable modals if the need arises.
export interface IGenericModal {
  type: ModalTypes.generic;
  title?: string | ReactNode;
  content?: string | ReactNode;
}

export interface IUpdateZoneEntityMappingModal {
  type: ModalTypes.updateZoneEntityMapping;
  title?: string | ReactNode;
  zoneId?: string;
  entity: ZoneEntities;
  version: ZoneEntityTableVersion;
}

export interface IAgentConfigModal {
  type: ModalTypes.agentConfig;
  title?: string | ReactNode;
  agentConfig?: IAgentConfig;
}

export interface IAgentProxyModal {
  type: ModalTypes.agentProxy;
  title?: string | ReactNode;
  proxyUrl?: string;
}

export interface IAreaCreateModal {
  type: ModalTypes.areaCreate;
}

export interface IAreaUpdateModal {
  type: ModalTypes.areaUpdate;
  area: AreaSettingsTableFragment;
}

export interface IBarcodeMarkAsCompliantModal {
  type: ModalTypes.barcodeCompliant;
  nonCompliantBarcodesIds: string[];
}

export interface IBinCreateModal {
  type: ModalTypes.binCreate;
  forceArea?: string;
}
export interface IBusinessPartnerCreateModal {
  type: ModalTypes.businessPartnerCreate;
}
export interface IBusinessPartnerUpdateModal {
  type: ModalTypes.businessPartnerUpdate;
  businessPartner?: BusinessPartner;
}
export interface IBlockFulfillmentModal {
  type: ModalTypes.blockFulfillment;
  fulfillmentId?: string;
}
export interface IUnblockFulfillmentModal {
  type: ModalTypes.unblockFulfillment;
  title: string;
  fulfillmentBlockLabel: string;
  fulfillmentId: string;
}

export interface IBinStorageTypeCreateModal {
  type: ModalTypes.binStorageTypeCreate;
}

export interface IBinStorageTypeUpdateModal {
  type: ModalTypes.binStorageTypeUpdate;
  binStorageType: BinStorageTypeRowFragment;
}

export interface IBinToBinMovementModal {
  type: ModalTypes.binToBinMovement;
  inventoryItem: InventoryItem;
}

export interface IBinUpdateModal {
  type: ModalTypes.binUpdate;
  bin: ViewBin;
}

export interface IBulkEditBinsModal {
  type: ModalTypes.binBulkEdit;
  title?: string | ReactNode;
  bins: ViewBin[];
  onCloseModal?: () => void;
}

export interface ICancelTasksModal {
  type: ModalTypes.cancelTasks;
  tasks: TaskTableFragment[];
}

export interface ICloseDeliveryModal {
  type: ModalTypes.closeDelivery;
  title?: string | ReactNode;
  deliveryType: DeliveryType;
  id: string;
  code: string;
}

export interface ICancelDeliveryModal {
  type: ModalTypes.cancelDelivery;
  title?: string | ReactNode;
  deliveryType: DeliveryType;
  id: string;
  code: string;
}

export interface ICancelDeliveryItemsModal {
  type: ModalTypes.cancelDeliveryItems;
  deliveryItems: TDeliveryItemsDataType[];
}

export interface ICompanyCreateModal {
  type: ModalTypes.companyCreate;
  setSelectedCompany: Dispatch<SetStateAction<CompanyFragment>>;
}

export interface ICompanyDetailsModal {
  type: ModalTypes.companyDetails;
  details: CompanyDetailsFragment;
}

export interface IConfirmationModal {
  type: ModalTypes.confirmation;
  title: string | ReactNode;
  message: string | ReactNode;
  onConfirm: (closeModal: TCloseModal) => void;
  onCancel: (closeModal: TCloseModal) => void;
  cancelButtonText?: string;
  confirmButtonText?: string;
  confirmButtonColor?: 'primary' | 'error';
}

export interface IContactInfoModal {
  type: ModalTypes.contactInformation;
  parentType: 'organization' | 'company' | 'warehouse';
  parentId: string;
  contactInfo: FullContactInfoFragment;
}

export interface ICreateCountBinModal {
  type: ModalTypes.createCountBin;
  bins: ICreateCountBinDetails[];
}

export interface ICreateIssueStockModal {
  type: ModalTypes.createIssueStock;
  inventoryItem: LostAndFoundInventoryFragment | BaseInventoryFragment;
}

export interface ICreateProductModal {
  type: ModalTypes.createProduct;
}

export interface ICreateRunFromDatasetModal {
  type: ModalTypes.createRunFromDataset;
  dataset: {
    id: string;
    code: string;
  };
  runName?: string;
  rulesetId?: string;
  rulesetType?: TRulesetType;
}

export interface ICreateRunFromRulesetModal {
  type: ModalTypes.createRunFromRuleset;
  ruleset: TRulesetsDataType;
}

export interface IDatasetCreateModal {
  type: ModalTypes.datasetCreate;
}

export interface IDatasetDeleteModal {
  type: ModalTypes.datasetDelete;
  datasets: SlottingDataset[];
}

export interface IDatasetUpdateModal {
  type: ModalTypes.datasetUpdate;
  lock: boolean;
  datasets: SlottingDataset[];
}
export interface IDeliveryAssignDoorModal {
  type: ModalTypes.deliveryAssignDoor;
  deliveries:
    | DeliveryFulfillmentOverviewFragment[]
    | InboundDeliveryDetailsFragment[]
    | OutboundFulfillmentDetailsFragment[];
  doorDirection: DoorDirection;
}

export interface IDeliveryItemsModal {
  type: ModalTypes.deliveryItems;
  delivery: DeliveryFulfillmentDataType;
}

export interface IDeliveryUnassignDoorModal {
  type: ModalTypes.deliveryUnassignDoor;
  deliveries:
    | DeliveryFulfillmentOverviewFragment[]
    | InboundDeliveryDetailsFragment[]
    | OutboundFulfillmentDetailsFragment[];
}

export interface IDisplayPreferencesModal {
  type: ModalTypes.displayPreferences;
  parentType: 'organization' | 'company' | 'warehouse';
  parentId: string;
  displayPreferences: FullDisplayPrefFragment;
}
export interface IDispositionApproveModal {
  type: ModalTypes.dispositionApprove;
  licensePlate: LicensePlateDetail;
}
export interface IDispositionDenyModal {
  type: ModalTypes.dispositionDeny;
  licensePlate: LicensePlateDetail;
}
export interface IDispositionImageModal {
  type: ModalTypes.dispositionImage;
  src: string;
}

export interface IDoorCreateModal {
  type: ModalTypes.doorCreate;
  previousFormState?: FormValues<DoorCreateInput>;
}

export interface IDoorUpdateModal {
  type: ModalTypes.doorUpdate;
  door: ViewDoor;
  lookForDepBin?: boolean;
}

export interface IFulfillmentBlockCreateModal {
  type: ModalTypes.fulfillmentBlockCreate;
  previousFormState?: FormValues<FulfillmentBlockCreateInput>;
}
export interface IFulfillmentBlockUpdateModal {
  type: ModalTypes.fulfillmentBlockUpdate;
  fulfillmentBlock: FulfillmentBlock;
}

export interface IEditTaskUnitOfMeasureModal {
  type: ModalTypes.editTaskUnitOfMeasure;
  taskId: string;
  quantity: string;
  productId: string;
  unitOfMeasureId: string;
}

export interface IEquipmentAddModal {
  type: ModalTypes.equipmentAdd;
}

export interface IEquipmentUpdateModal {
  type: ModalTypes.equipmentUpdate;
  equipment: ViewEquipment;
}

export interface IEquipmentTypeModal {
  type: ModalTypes.equipmentType;
  equipmentType?: EquipmentTypeSettingsFragment;
}

export interface IEquipmentModelAddModal {
  type: ModalTypes.equipmentModelAdd;
}

export interface IEquipmentModelUpdateModal {
  type: ModalTypes.equipmentModelUpdate;
  equipmentModel: EquipmentModelSettingsFragment;
}

export interface IFulfillmentItemsModal {
  type: ModalTypes.fulfillmentItems;
  fulfillment: DeliveryFulfillmentDataType;
}

export interface IInventoryAdjustmentModal {
  type: ModalTypes.adjustQuantity;
  title?: string | ReactNode;
  inventoryItem: InventoryItem;
  onCloseModal?: () => void;
}

export interface IInventoryApprovalModal {
  type: ModalTypes.approveQuantityAdjustment;
  title?: string | ReactNode;
  approvalTask: PiCreateTaskFragment;
  onCloseModal?: () => void;
}

export interface IInventoryAdjustmentResultModal {
  type: ModalTypes.adjustQuantityResult;
  title?: string | ReactNode;
  approvalTask: PiApprovalTaskFragment;
}

export interface ILicensePlateAddToModal {
  type: ModalTypes.licensePlateAddTo;
  title?: string | ReactNode;
  licensePlateId: string;
  licensePlateCode: string;
  inventoryItems: BaseInventoryFragment[];
  forceAutocomplete?: boolean;
}

export interface ILicensePlateCreateModal {
  type: ModalTypes.licensePlateCreate;
  title?: string | ReactNode;
  inventoryItems: BaseInventoryFragment[];
  forceAutocomplete?: boolean;
}

export interface ILicensePlateMoveModal {
  type: ModalTypes.licensePlateMove;
  licensePlates: LicensePlateSelection[];
  forceAutocomplete?: boolean;
}

export interface ILicensePlateMergeModal {
  type: ModalTypes.licensePlateMerge;
  title?: string | ReactNode;
  source: 'inventory' | 'delivery';
  binId: string;
  binCode: string;
  licensePlates: LicensePlateSelection[];
  forceAutocomplete?: boolean;
}

export interface ILicensePlateUnpackModal {
  type: ModalTypes.licensePlateUnpack;
  title?: string | ReactNode;
  source: 'inventory' | 'delivery';
  licensePlates: LicensePlateSelection[];
  binCode: string;
  forceAutocomplete?: boolean;
}

export interface ILicensePlateBlockModal {
  type: ModalTypes.licensePlateBlock;
  title?: string | ReactNode;
  licensePlateIds: string[];
  block: boolean;
}

export interface ILoadTaskCompleteModal {
  type: ModalTypes.loadTaskComplete;
  task: TaskTableFragment;
  fulfillmentId: string;
  fulfillmentCode: string;
  customerName: string;
}

export interface ILoadTaskCreateModal {
  type: ModalTypes.loadTaskCreate;
  fulfillmentId: string;
  fulfillmentCode: string;
}

export interface IOrganizationDetailsModal {
  type: ModalTypes.organizationDetails;
  details: OrganizationDetailsFragment;
}

export interface IPutawayTaskCreateModal {
  type: ModalTypes.putawayTaskCreate;
  deliveryId: string;
  erpCode: string;
  looseProduct: TPutawayLooseProduct[];
}

export interface IPutawayTaskCompleteModal {
  type: ModalTypes.putawayTaskComplete;
  title?: string | ReactNode;
  deliveryDetails: BaseDeliveryFragment;
  task: TaskTableFragment;
}

export interface IPickTaskCreateModal {
  type: ModalTypes.pickTaskCreate;
  fulfillmentId: string;
  erpCode: string;
}

export interface IPickTaskCompleteModal {
  type: ModalTypes.pickTaskComplete;
  title?: string | ReactNode;
  task: TaskTableFragment;
  deliveryDetails: OutboundFulfillmentDetailsFragment;
  onCloseModal?: () => void;
}

export interface IPickToStagingCompleteModal {
  type: ModalTypes.pickToStagingComplete;
  task: TaskTableFragment;
  fulfillmentId: string;
  fulfillmentCode: string;
  customerName: string;
}

export interface IPickToStagingCreateModal {
  type: ModalTypes.pickToStagingCreate;
  fulfillmentId: string;
  erpCode: string;
}

export interface IPostGoodsReceiptOrIssueModal {
  type: ModalTypes.postGoodsReceiptOrIssue;
  title?: string | ReactNode;
  deliveryType: DeliveryType;
  id: string;
  code: string;
}

export interface IRulesetCreateOrCopyModal {
  type: ModalTypes.rulesetCreateOrCopy;
  rulesetId: string | null;
}

export interface IRunDeployMovementsModal {
  type: ModalTypes.runDeployMovements;
  runName: string;
  runId: string;
  datasetId: string;
  rulesetId: string;
  skipAbc?: boolean;
}

export interface ISalesOrderListModal {
  type: ModalTypes.salesOrderList;
  salesOrder: string;
}

export interface IShortShipModal {
  type: ModalTypes.shortShip;
  title?: string | ReactNode;
  id: string;
  code: string;
}

export interface ITableLayoutCreateModal {
  type: ModalTypes.tableLayoutCreate;
  tableId: TableIds;
  handleCreate: TAddLayoutMethod;
}

export interface ITableLayoutDeleteModal {
  type: ModalTypes.tableLayoutDelete;
  tableId: TableIds;
  layout: { id: string; variant: LayoutVariant; label: string };
  handleDelete: TDeleteLayoutMethod;
}

export interface ITableLayoutOverwriteModal {
  type: ModalTypes.tableLayoutOverwrite;
  tableId: TableIds;
  layout: { id: string; label: string };
  handleCreate: TAddLayoutMethod;
  handleOverwrite: TUpdateLayoutMethod;
}

export interface ITaskEditDetailsModal {
  type: ModalTypes.taskEditDetails;
  title?: string | ReactNode;
  task: TaskTableFragment;
}

export interface ITaskTypeStockStatusMappingModal {
  type: ModalTypes.taskTypeStockStatusMapping;
  title?: string | ReactNode;
  mapping: {
    stockStatuses: {
      mapped: boolean;
      stockStatusCode: string;
      stockStatusId: string;
      stockStatusName: string;
      __typename: string;
    }[];
    taskTypeCode: string;
    taskTypeId: string;
    taskTypeLabel: string;
  };
  onCloseModal?: () => void;
}

export interface ITaskTypeBinStatusMappingModal {
  type: ModalTypes.taskTypeBinStatusMapping;
  title?: string | ReactNode;
  mapping: {
    binStatuses: {
      mapped?: boolean;
      binStatusCode?: string;
      binStatusId?: string;
      binStatusLabel?: string;
      __typename?: string;
    }[];
    taskTypeCode: string;
    taskTypeId: string;
    taskTypeLabel: string;
  };
  onCloseModal?: () => void;
}

export interface ICreateStockStatusModal {
  type: ModalTypes.stockStatusCreate;
  title: string;
}

export interface ICopyStockStatusModal {
  type: ModalTypes.stockStatusCopy;
  title: string;
  stockStatusType: ViewStockStatus;
}

export interface IEditStockStatusModal {
  type: ModalTypes.stockStatusEdit;
  title: string;
  stockStatusType: ViewStockStatus;
}

export interface ITeamCreateModal {
  type: ModalTypes.teamCreate;
}

export interface ITeamUpdateModal {
  type: ModalTypes.teamUpdate;
  team: TeamDetailsFragment;
}

export interface IUnitOfMeasureGlossaryModal {
  type: ModalTypes.unitOfMeasureGlossary;
  unitOfMeasure: UomGlossaryFragment;
}
export interface IUpdateFulfillmentHeaderModal {
  type: ModalTypes.updateFulfillmentHeader;
  fulfillmentDetails: BaseDeliveryFragment;
  previousFormState?: FormValues<IHeaderUpdateInput>;
}

export interface IUnloadTaskCompleteModal {
  type: ModalTypes.unloadTaskComplete;
  deliveryDetails: InboundDeliveryDetailsFragment;
  task: TaskTableFragment;
}

export interface IUnloadTaskCreateModal {
  type: ModalTypes.unloadTaskCreate;
  deliveryDetails: InboundDeliveryDetailsFragment;
}

export interface IUserAddToTeamModal {
  type: ModalTypes.userAddToTeam;
  team: TeamDetailsFragment;
}
export interface IRoleCreateModal {
  type: ModalTypes.roleCreate;
}
export interface IRoleDeleteModal {
  type: ModalTypes.roleDelete;
  role: { id: string; name: string };
}
export interface IRolePermissionsSaveModal {
  type: ModalTypes.rolePermissionsSave;
  role: { id: string; name: string };
  permissionIds: string[];
}

export interface IUpdateLicensePlateStockStatusModal {
  type: ModalTypes.updateLicensePlateStockStatus;
  licensePlateDetails: IUpdateLicensePlateStockStatusDetails;
}

export interface IUpdateLostAndFoundLPModal {
  type: ModalTypes.updateLostAndFoundLP;
  lostAndFoundItem: LostAndFoundInventoryFragment;
  previousFormState?: FormValues<IUpdateLostAndFoundLPFields>;
}

export interface IUserCreateModal {
  type: ModalTypes.userCreate;
  title: string;
}

export interface IUserUpdateModal {
  type: ModalTypes.userUpdate;
  title: string;
  user: UserAdminSettingsTableRowFragment;
}

export interface IUserGroupCreateModal {
  type: ModalTypes.userGroupCreate;
}
export interface IUserGroupCopyModal {
  type: ModalTypes.userGroupCopy;
  userGroupId: string;
  userGroupName: string;
}

export interface IUserGroupEditModal {
  type: ModalTypes.userGroupEdit;
  userGroupId: string;
  userGroupName: string;
}
export interface IUserGroupUsersEditModal {
  type: ModalTypes.userGroupUsersEdit;
  userGroupId: string;
  userGroupName: string;
}

export interface IUserGroupUsersModal {
  type: ModalTypes.userGroupUsers;
  userGroupId: string;
  userGroupName: string;
}

export interface IWarehouseCreateModal {
  type: ModalTypes.warehouseCreate;
  setSelectedWarehouse: Dispatch<SetStateAction<WarehouseFragment>>;
}

export interface IWarehouseDetailsModal {
  type: ModalTypes.warehouseDetails;
  details: WarehouseDetailsFragment;
}
export interface IWarehouseRoleTypeModal {
  type: ModalTypes.warehouseRoleType;
  warehouseRoleType?: WarehouseRoleTypeSettingsFragment;
}

export interface IWarehouseUnitOfMeasureModal {
  type: ModalTypes.warehouseUnitOfMeasure;
  productId: string;
  productCode: string;
  whUom: ProductWarehouseUoMFragment;
}

export interface IZoneCreateModal {
  type: ModalTypes.zoneCreate;
}

export interface IZoneUpdateModal {
  type: ModalTypes.zoneUpdate;
  zone: ZoneSettingsTableFragment;
}

export type IModal =
  | IGenericModal
  | IUpdateZoneEntityMappingModal
  | IAgentConfigModal
  | IAgentProxyModal
  | IAreaCreateModal
  | IAreaUpdateModal
  | IBarcodeMarkAsCompliantModal
  | IBlockFulfillmentModal
  | IUnblockFulfillmentModal
  | IBinCreateModal
  | IBulkEditBinsModal
  | IBinToBinMovementModal
  | IBinUpdateModal
  | IBinStorageTypeCreateModal
  | IBinStorageTypeUpdateModal
  | IBusinessPartnerCreateModal
  | IBusinessPartnerUpdateModal
  | ICancelDeliveryModal
  | ICancelDeliveryItemsModal
  | ICancelTasksModal
  | ICloseDeliveryModal
  | ICompanyCreateModal
  | ICompanyDetailsModal
  | IConfirmationModal
  | IContactInfoModal
  | ICopyStockStatusModal
  | ICreateCountBinModal
  | ICreateIssueStockModal
  | ICreateProductModal
  | ICreateStockStatusModal
  | ICreateRunFromDatasetModal
  | ICreateRunFromRulesetModal
  | IDatasetCreateModal
  | IDatasetDeleteModal
  | IDatasetUpdateModal
  | IDeliveryAssignDoorModal
  | IDeliveryItemsModal
  | IDeliveryUnassignDoorModal
  | IDisplayPreferencesModal
  | IDispositionApproveModal
  | IDispositionDenyModal
  | IDispositionImageModal
  | IDoorCreateModal
  | IDoorUpdateModal
  | IEditStockStatusModal
  | IEditTaskUnitOfMeasureModal
  | IEquipmentAddModal
  | IEquipmentModelAddModal
  | IEquipmentModelUpdateModal
  | IEquipmentTypeModal
  | IEquipmentUpdateModal
  | IFulfillmentBlockCreateModal
  | IFulfillmentBlockUpdateModal
  | IFulfillmentItemsModal
  | IInventoryAdjustmentModal
  | IInventoryAdjustmentResultModal
  | IInventoryApprovalModal
  | ILicensePlateAddToModal
  | ILicensePlateBlockModal
  | ILicensePlateCreateModal
  | ILicensePlateMergeModal
  | ILicensePlateMoveModal
  | ILicensePlateUnpackModal
  | ILoadTaskCompleteModal
  | ILoadTaskCreateModal
  | IOrganizationDetailsModal
  | IPickTaskCompleteModal
  | IPickTaskCreateModal
  | IPickToStagingCompleteModal
  | IPickToStagingCreateModal
  | IPostGoodsReceiptOrIssueModal
  | IPutawayTaskCompleteModal
  | IPutawayTaskCreateModal
  | IRoleCreateModal
  | IRoleDeleteModal
  | IRolePermissionsSaveModal
  | IRulesetCreateOrCopyModal
  | IRunDeployMovementsModal
  | ISalesOrderListModal
  | IShortShipModal
  | ITableLayoutDeleteModal
  | ITableLayoutOverwriteModal
  | ITableLayoutCreateModal
  | ITaskEditDetailsModal
  | ITaskTypeBinStatusMappingModal
  | ITaskTypeStockStatusMappingModal
  | ITeamCreateModal
  | ITeamUpdateModal
  | IUnitOfMeasureGlossaryModal
  | IUnloadTaskCompleteModal
  | IUnloadTaskCreateModal
  | IUpdateFulfillmentHeaderModal
  | IUpdateLicensePlateStockStatusModal
  | IUpdateLostAndFoundLPModal
  | IUserAddToTeamModal
  | IUserCreateModal
  | IUserGroupCreateModal
  | IUserGroupCopyModal
  | IUserGroupEditModal
  | IUserGroupUsersEditModal
  | IUserGroupUsersModal
  | IUserUpdateModal
  | IWarehouseCreateModal
  | IWarehouseDetailsModal
  | IWarehouseRoleTypeModal
  | IWarehouseUnitOfMeasureModal
  | IZoneCreateModal
  | IZoneUpdateModal;
