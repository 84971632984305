import { DialogActions, DialogActionsProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC } from 'react';

const StyledModalActions = styled(DialogActions)({
  justifyContent: 'flex-end',
  padding: '24px 24px 24px 24px',
});

export const ModalActions: FC<DialogActionsProps> = ({ children, ...props }) => {
  return <StyledModalActions {...props}>{children}</StyledModalActions>;
};
