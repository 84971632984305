import TextField from '@components/text-field';
import { CURRENCIES } from '@constants/currencies';

const CurrencyInput = ({
  field,
  fieldState,
  label,
  id,
  dataTestId,
  required,
  disabled = false,
}) => (
  <TextField
    {...field}
    ref={null}
    disabled={disabled}
    fullWidth
    required={required}
    id={id}
    label={label}
    error={!!fieldState?.error}
    helperText={fieldState?.error?.message}
    select
    SelectProps={{ native: true }}
    dataTestId={dataTestId}
  >
    <option disabled />
    {Object.values(CURRENCIES).map((currency) => (
      <option key={currency.cc} value={currency.cc}>
        {currency.name}
      </option>
    ))}
  </TextField>
);

export default CurrencyInput;
