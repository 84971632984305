import { Box, Checkbox, styled } from '@mui/material';
import { ColumnHelper } from '@tanstack/react-table';
import { HTMLProps, useRef, useEffect } from 'react';

import { DataTableDisplayColumns } from '@components/data-table/columnDefs/displayColumns';
import { SelectionType } from '@components/data-table/hooks/useDataTableSelection';
import { DefaultDataType } from '@components/data-table/model/data-table';

export function DataTableSelectionCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate]);

  return <input type="checkbox" ref={ref} {...rest} />;
}

function createRowSelectionColumnDef<DataType = DefaultDataType>(
  columnHelper: ColumnHelper<DataType>,
) {
  return columnHelper.display({
    id: DataTableDisplayColumns.Selection,
    header: ({ table }) =>
      table.options.meta.rowSelectionType === SelectionType.multi && (
        <Box position="relative" width="100%">
          <SelectionBox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
            size="small"
          />
        </Box>
      ),
    cell: ({ table, row }) => (
      <Box position="relative" width="100%">
        <SelectionBox
          checked={row.getIsSelected()}
          onChange={() => {
            if (
              table.options.meta.rowSelectionType === SelectionType.single &&
              !row.getIsSelected()
            ) {
              table.toggleAllRowsSelected(false);
            }
            row.toggleSelected();
          }}
          disabled={!row.getCanSelect()}
          size="small"
        />
      </Box>
    ),
    size: 50,
    maxSize: 50,
    enableColumnFilter: false,
    enableHiding: false,
    enableResizing: false,
    enableSorting: false,
    enableMultiSort: false,
  });
}

export default createRowSelectionColumnDef;

const SelectionBox = styled(Checkbox)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 3,
}));
