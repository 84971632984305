import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUnpackLicensePlateMutation } from '@/graphql/defs/components/modals/__generated__/unpack-license-plate.generated';
import { useGetBaseInventoryLazyQuery } from '@/graphql/defs/shared-queries/__generated__/get-base-inventory.generated';
import { useGetDeliveryLpInventoryLazyQuery } from '@/graphql/defs/shared-queries/__generated__/get-delivery-lp-inventory.generated';
import { GetBaseInventory_defaultData } from '@/graphql/defs/shared-queries/get-base-inventory';
import { GetDeliveryLPInventory_defaultData } from '@/graphql/defs/shared-queries/get-delivery-lp-inventory';
import {
  BinType,
  InventoryBaseQueryShape,
  InventoryBaseQueryShapeSortFields,
  SortDirection,
} from '@/graphql/types.generated';
import EditableTable from '@components/editable-table/EditableTable';
import {
  createItemUID,
  useEditableLicensePlateTable,
} from '@components/editable-table/license-plate/hooks';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { ILicensePlateUnpackModal } from '@models/modal';

const UnpackLicensePlateModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal } = useModalContent<ILicensePlateUnpackModal>();
  const [shouldAutocomplete, setShouldAutocomplete] = useState(true);

  const [sourceItems, setSourceItems] = useState<InventoryBaseQueryShape[]>([]);

  const [getInventoryItems, { loading: isLoadingInventoryItems, called: calledInventoryItems }] =
    useGetBaseInventoryLazyQuery({
      variables: {
        paging: { offset: 0, limit: 1000000 },
        filter: {
          licensePlateId: { in: modal.licensePlates.map((lp) => lp.id) },
        },
        sorting: [
          {
            field: InventoryBaseQueryShapeSortFields.LicensePlateCode,
            direction: SortDirection.Asc,
          },
          { field: InventoryBaseQueryShapeSortFields.ProductCode, direction: SortDirection.Asc },
        ],
      },
      onCompleted: (data) => {
        const inventory = (data || GetBaseInventory_defaultData())?.inventory?.nodes || [];
        setSourceItems(inventory);
      },
    });

  const [getDeliveryItems, { loading: isLoadingDeliveryItems, called: calledDeliveryItems }] =
    useGetDeliveryLpInventoryLazyQuery({
      variables: {
        paging: { offset: 0, limit: 1000000 },
        filter: {
          licensePlateId: { in: modal.licensePlates.map((lp) => lp.id) },
          binType: { eq: BinType.Planned },
        },
        sorting: [
          {
            field: InventoryBaseQueryShapeSortFields.LicensePlateCode,
            direction: SortDirection.Asc,
          },
          { field: InventoryBaseQueryShapeSortFields.ProductCode, direction: SortDirection.Asc },
        ],
      },
      onCompleted: (data) => {
        const deliveryInventory =
          (data || GetDeliveryLPInventory_defaultData)?.deliveryItems?.nodes || [];
        setSourceItems(deliveryInventory);
      },
    });

  const [createUnpackTask] = useUnpackLicensePlateMutation({
    onCompleted: ({
      createOneUnpackLicensePlateTask: { code, status, sourceLicensePlate, destinationBin },
    }) => {
      showMessage({
        type: 'success',
        message: !shouldAutocomplete
          ? t('licensePlateActions.unpackSuccess', {
              taskCode: code,
            })
          : t('licensePlateActions.unpackSuccessAutocomplete', {
              licensePlateCode: sourceLicensePlate?.code,
              binCode: destinationBin?.code,
            }),
      });
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  useEffect(() => {
    modal.source !== 'inventory' ? getDeliveryItems() : getInventoryItems();
  }, []);

  const { licensePlateColumns, productItems } = useEditableLicensePlateTable(
    sourceItems || [],
    false,
  );

  const onSubmit = () => {
    void processLicensePlates(0);
  };

  const processLicensePlates = async (index: number) => {
    const licensePlate = modal.licensePlates[index];
    await createUnpackTask({
      variables: {
        licensePlateId: licensePlate.id,
        autocomplete: shouldAutocomplete,
      },
    });

    if (++index < modal.licensePlates.length) {
      await processLicensePlates(index);
    } else {
      closeModal({ bypassLoading: true, success: true });
    }
  };

  return (
    <>
      <ModalContent>
        <Typography mb={4}>
          {t('licensePlateActions.unpackText', { count: modal.licensePlates.length })}
        </Typography>
        {productItems &&
          modal.licensePlates.map((lp) => {
            const lpProducts = productItems.filter((row) => row.item.licensePlateId === lp.id);
            const tableLabel =
              (modal.source !== 'delivery' ? `${productItems[0].item.binCode} - ` : '') +
              `${t('common.licensePlate')}: ${lp.code}`;
            return (
              <Box
                data-testid={`${lp.id}-editableTable`}
                key={`${lp.code}-products`}
                sx={{ margin: '15px 0 0' }}
              >
                <EditableTable
                  label={tableLabel}
                  columns={licensePlateColumns}
                  createUID={createItemUID}
                  data={lpProducts}
                />
              </Box>
            );
          })}
      </ModalContent>
      <ModalActions>
        {modal.forceAutocomplete ? null : (
          <Box sx={{ marginRight: 'auto' }}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  onChange={(e) => {
                    setShouldAutocomplete(e?.target?.checked || false);
                  }}
                />
              }
              label={t('modal.task.markAsComplete')}
            />
          </Box>
        )}
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          data-testid="unpackLicensePlate_submit"
          onClick={onSubmit}
          variant="contained"
          color="primary"
          isDisabled={
            (!calledInventoryItems && !calledDeliveryItems) ||
            isLoadingInventoryItems ||
            isLoadingDeliveryItems
          }
          actionType="submit"
        >
          {t('common.confirm')}
        </ModalButton>
      </ModalActions>
    </>
  );
};

export default UnpackLicensePlateModal;
