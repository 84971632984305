/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelDeliveryMutationVariables = Types.Exact<{
  deliveryId: Types.Scalars['String']['input'];
}>;


export type CancelDeliveryMutation = { __typename?: 'Mutation', cancelDelivery: { __typename?: 'Delivery', erpCode: string } };


export const CancelDeliveryDocument = gql`
    mutation CancelDelivery($deliveryId: String!) {
  cancelDelivery(deliveryId: $deliveryId) {
    erpCode
  }
}
    `;
export type CancelDeliveryMutationFn = Apollo.MutationFunction<CancelDeliveryMutation, CancelDeliveryMutationVariables>;

/**
 * __useCancelDeliveryMutation__
 *
 * To run a mutation, you first call `useCancelDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDeliveryMutation, { data, loading, error }] = useCancelDeliveryMutation({
 *   variables: {
 *      deliveryId: // value for 'deliveryId'
 *   },
 * });
 */
export function useCancelDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<CancelDeliveryMutation, CancelDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelDeliveryMutation, CancelDeliveryMutationVariables>(CancelDeliveryDocument, options);
      }
export type CancelDeliveryMutationHookResult = ReturnType<typeof useCancelDeliveryMutation>;
export type CancelDeliveryMutationResult = Apollo.MutationResult<CancelDeliveryMutation>;
export type CancelDeliveryMutationOptions = Apollo.BaseMutationOptions<CancelDeliveryMutation, CancelDeliveryMutationVariables>;