import { Box } from '@mui/material';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { useCreatePutawayTasksMutation } from '@/graphql/defs/components/modals/__generated__/putaway-task-create-modal.generated';
import { TaskLooseProductsInput } from '@/graphql/types.generated';
import { IInboundLooseProduct } from '@components/deliveries/delivery-items-action-menu';
import EditableTable, {
  EditableTableCell,
  EditableTableColumn,
  EditableTableRow,
} from '@components/editable-table/EditableTable';
import EditableUoM from '@components/editable-table/EditableUoM';
import NoneditableQty from '@components/editable-table/NoneditableQty';
import { ModalActions, ModalContent } from '@components/modal';
import { ModalButton } from '@components/modal/modal-button';
import ModalForm from '@components/modal/modal-form';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { getTransComponents } from '@lib/translation';
import { IPutawayTaskCreateModal } from '@models/modal';

export type TPutawayLooseProduct = IInboundLooseProduct & { deliveryItem: string };

export const PutawayTaskCreateModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, setLoading } = useModalContent<IPutawayTaskCreateModal>();

  const [createDeliveryTasks] = useCreatePutawayTasksMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: `${t('modal.task.createPutawayTaskSuccessMessage')}`,
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: () => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: `${t('modal.task.createPutawayTaskErrorMessage')}`,
      });
    },
  });

  const { showMessage } = useSnackbar();
  const formMethods = useForm();

  const createItemUID = (item: TPutawayLooseProduct) =>
    `${item.deliveryItem}-${item.productId}-${item.lotId}-${item.unitOfMeasureId}`;

  const onSubmit = (qtyUoMs) => {
    void createDeliveryTasks({
      variables: {
        input: {
          delivery: {
            id: modal.deliveryId,
            looseProducts: modal.looseProduct.map((product): TaskLooseProductsInput => {
              const uid = createItemUID(product);
              return {
                binId: product.binId,
                lotId: product.lotId,
                productId: product.productId,
                stockStatusTypeId: product.stockStatusTypeId,
                quantity: qtyUoMs[`${uid}-qty`],
                createdInUnitOfMeasureId: qtyUoMs[`${uid}-uom`],
              };
            }),
          },
        },
      },
    });
  };

  const onCancel = () => {
    closeModal();
  };

  const deliveryItemColumns: EditableTableColumn[] = [
    {
      label: t('common.item'),
      width: '16.66%',
    },
    {
      label: t('common.productCodeAbbr'),
      width: '16.66%',
    },
    {
      label: t('common.description'),
      width: '16.66%',
    },
    {
      label: t('common.lot'),
      width: '16.66%',
    },
    {
      label: t('common.qty'),
      width: '16.66%',
    },
    {
      label: t('common.qtyUoM'),
      width: '16.66%',
    },
  ];

  const looseProductRows: EditableTableRow[] = useMemo(() => {
    const rows = [...modal.looseProduct];
    return rows.map((product, index): EditableTableRow => {
      const cells: EditableTableCell[] = [
        {
          dataTestId: `looseProduct-${index}-deliveryItem`,
          value: product.deliveryItem,
        },
        {
          dataTestId: `looseProduct-${index}-productCode`,
          value: product.productCode,
        },
        {
          dataTestId: `looseProduct-${index}-productDesc`,
          value: product.productDescription,
        },
        {
          dataTestId: `looseProduct-${index}-lotCode`,
          value: product.lotCode,
        },
        {
          dataTestId: `looseProduct-${index}-quantity`,
          value: NoneditableQty,
        },
        {
          dataTestId: `looseProduct-${index}-quantityUoM`,
          value: EditableUoM,
        },
      ];
      return {
        dataTestId: `looseProduct-${index}`,
        item: product,
        cells: cells,
      };
    });
  }, [modal.looseProduct]);

  return (
    <ModalForm onSubmit={formMethods.handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        {modal.looseProduct.length > 0 ? (
          <>
            <Trans
              ns="components"
              i18nKey="modal.task.createPutawayTask.looseProduct"
              components={getTransComponents(['bold'])}
            />
            <Box sx={{ marginTop: '25px' }}>
              <EditableTable
                columns={deliveryItemColumns}
                createUID={createItemUID}
                data={looseProductRows}
              />
            </Box>
          </>
        ) : (
          <Trans
            ns="components"
            i18nKey="modal.task.createPutawayTask.allLicensePlates"
            components={getTransComponents(['bold'])}
          />
        )}
      </ModalContent>
      <ModalActions>
        <ModalButton variant="outlined" color="primary" onClick={onCancel} actionType="cancel">
          {modal.looseProduct.length > 0 ? t('common.dontCreate') : t('common.cancel')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" actionType="submit">
          {t('common.createTask', { count: 2 })}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};
