/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnpackLicensePlateMutationVariables = Types.Exact<{
  autocomplete: Types.Scalars['Boolean']['input'];
  licensePlateId: Types.Scalars['ID']['input'];
}>;


export type UnpackLicensePlateMutation = { __typename?: 'Mutation', createOneUnpackLicensePlateTask: { __typename?: 'Task', id: string, code: string, status?: Types.TaskStatus | null, sourceLicensePlate?: { __typename?: 'LicensePlate', id: string, code: string } | null, destinationBin?: { __typename?: 'Bin', id: string, code: string } | null } };


export const UnpackLicensePlateDocument = gql`
    mutation UnpackLicensePlate($autocomplete: Boolean!, $licensePlateId: ID!) {
  createOneUnpackLicensePlateTask(
    input: {licensePlateUnpack: {autocomplete: $autocomplete, licensePlateId: $licensePlateId}}
  ) {
    id
    code
    status
    sourceLicensePlate {
      id
      code
    }
    destinationBin {
      id
      code
    }
  }
}
    `;
export type UnpackLicensePlateMutationFn = Apollo.MutationFunction<UnpackLicensePlateMutation, UnpackLicensePlateMutationVariables>;

/**
 * __useUnpackLicensePlateMutation__
 *
 * To run a mutation, you first call `useUnpackLicensePlateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpackLicensePlateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpackLicensePlateMutation, { data, loading, error }] = useUnpackLicensePlateMutation({
 *   variables: {
 *      autocomplete: // value for 'autocomplete'
 *      licensePlateId: // value for 'licensePlateId'
 *   },
 * });
 */
export function useUnpackLicensePlateMutation(baseOptions?: Apollo.MutationHookOptions<UnpackLicensePlateMutation, UnpackLicensePlateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnpackLicensePlateMutation, UnpackLicensePlateMutationVariables>(UnpackLicensePlateDocument, options);
      }
export type UnpackLicensePlateMutationHookResult = ReturnType<typeof useUnpackLicensePlateMutation>;
export type UnpackLicensePlateMutationResult = Apollo.MutationResult<UnpackLicensePlateMutation>;
export type UnpackLicensePlateMutationOptions = Apollo.BaseMutationOptions<UnpackLicensePlateMutation, UnpackLicensePlateMutationVariables>;