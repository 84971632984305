import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Control,
  FieldValues,
  UseFormReturn,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import { StepForm } from '@components/form-util';
import { useModalContent } from '@context/modal/ModalContentProvider';

/**
 * StepContent component props
 */
export interface IVStepContentProps<FieldValuesType> {
  control?: Control<FieldValuesType>;
  register?: UseFormRegister<FieldValues>;
  setValue?: UseFormSetValue<FieldValues>;
  focusOnLoad?: boolean;
  isUpdate?: boolean;
}

/**
 * Individual step callback functions arguments.
 *
 * @typeParam Entity - Object returned on form submission.
 */
export interface IVStepCBArgs {
  depMap?: { [key: string]: any };
  updateDepMap: Dispatch<SetStateAction<Record<string, unknown>>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  setActiveStepState: Dispatch<SetStateAction<string>>;
  setIsProcessing: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string | null>>;
}

/**
 * Individual step for VerticalStepper component to render.
 */
export interface IVStep<FieldValuesType = any> {
  title: string;
  onSubmit: (args: IVStepCBArgs) => (entity) => void;
  onBack?: boolean | ((args: IVStepCBArgs) => Promise<void>);
  stepContent: React.FC<IVStepContentProps<FieldValuesType>>;
  formHooks: UseFormReturn<FieldValuesType>;
  focusOnLoad?: boolean;
}

/**
 * VerticalStepper component props
 */
export interface IVStepper {
  steps: IVStep[];
  onDepMapUpdate: (args?: IVStepCBArgs) => void;
  beforeStart?: (args?: IVStepCBArgs) => void;
  onComplete: (args?: IVStepCBArgs) => void;
}

export const VerticalStepper: React.FC<IVStepper> = ({
  steps,
  onDepMapUpdate,
  beforeStart,
  onComplete,
}) => {
  const { setLoading } = useModalContent();
  const [depMap, setDepMap] = useState<{ [key: string]: any }>({});
  const [activeStep, setActiveStep] = useState<number>(0);
  const [activeStepState, setActiveStepState] = useState('inProgress');
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const updateDepMap = (updateObject: { [key: string]: any }) => {
    const updatedDepMap = Object.assign({}, depMap, updateObject);
    setDepMap(updatedDepMap);
    if (typeof onDepMapUpdate === 'function') {
      onDepMapUpdate({
        depMap: updatedDepMap,
        updateDepMap,
        setActiveStep,
        setActiveStepState,
        setIsProcessing,
        setError,
      });
    }
  };

  const handleComplete = () => {
    onComplete({
      depMap,
      updateDepMap,
      setActiveStep,
      setActiveStepState,
      setIsProcessing,
      setError,
    });
  };

  const nextStep = (isLastStep: boolean) => {
    if (!isLastStep) {
      setLoading(false);
      setActiveStep(activeStep + 1);
    } else {
      handleComplete();
    }
  };

  const cbArgs = {
    depMap,
    updateDepMap,
    setActiveStep,
    setActiveStepState,
    setIsProcessing,
    setError,
  };

  useEffect(() => {
    setDepMap({});
    setActiveStep(0);
    setIsProcessing(false);
    setError(null);

    if (typeof beforeStart === 'function') beforeStart(cbArgs);
  }, []); // Empty deps array is needed to have effect run only once on mount.

  useEffect(() => {
    setError(null);
  }, [activeStep]);

  useEffect(() => {
    switch (activeStepState) {
      case 'success':
        const wasStepAlreadyComplete = completedSteps.includes(activeStep);
        if (!wasStepAlreadyComplete) setCompletedSteps([...completedSteps, activeStep]);
        setIsProcessing(false);
        setActiveStepState('inProgress');
        nextStep(steps.length === activeStep + 1);
        break;
      case 'error':
      case 'inProgress':
        setLoading(false);
        setIsProcessing(false);
        break;
    }
  }, [activeStepState]);

  // Pull out step hooks, run them, and assign their callback submit function to an index array where index = step index
  const stepSubmitCbs = steps.map((step, index) => step.onSubmit(cbArgs));

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => {
        const formMethods = step.formHooks;
        const { control, register, setValue, handleSubmit } = formMethods;

        const isLast = steps.length === index + 1;
        const onStepSubmit = (entity) => {
          setError(null);
          setIsProcessing(true);
          stepSubmitCbs[index](entity);
        };

        const onStepBack =
          step.onBack &&
          (async () => {
            if (typeof step.onBack === 'function') {
              await step.onBack(cbArgs);
            }
            setActiveStep(index - 1);
          });

        return (
          <Step key={step.title}>
            <StepLabel>{step.title}</StepLabel>
            <StepContent>
              <StepForm
                onBack={onStepBack}
                onSubmit={handleSubmit(onStepSubmit)}
                formReturn={formMethods}
                processing={isProcessing}
                error={error}
                isLast={isLast}
              >
                {step.stepContent({
                  control,
                  register,
                  setValue,
                  focusOnLoad: step.focusOnLoad,
                  isUpdate: completedSteps.includes(activeStep),
                })}
              </StepForm>
            </StepContent>
          </Step>
        );
      })}
    </Stepper>
  );
};
