import { useTranslation, Trans } from 'react-i18next';

import { useBlockUnblockLicensePlatesMutation } from '@/graphql/defs/components/modals/__generated__/block-license-plate.generated';
import { LicensePlateStatusCode } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { getTransComponents } from '@lib/translation';
import { ILicensePlateBlockModal } from '@models/modal';

const BlockLicensePlateModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal, setLoading } = useModalContent<ILicensePlateBlockModal>();
  const [blockUnblockLicensePlates] = useBlockUnblockLicensePlatesMutation({
    onCompleted: () => {
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (e) => {
      setLoading(false);
      showMessage({ type: 'error', message: e.message });
    },
  });

  const onSubmit = () => {
    void blockUnblockLicensePlates({
      variables: {
        input: {
          licensePlateStatusMappings: {
            licensePlateIds: modal.licensePlateIds,
            licensePlateStatus: [
              {
                licensePlateStatusCode: LicensePlateStatusCode.LpStatusInactive,
                on: modal.block,
              },
            ],
          },
        },
      },
    });
  };

  return (
    <>
      <ModalContent sx={{ fontSize: '18px', width: '700px' }}>
        <Trans
          ns="components"
          i18nKey={
            modal.block
              ? 'licensePlateActions.lpBlockDialog'
              : 'licensePlateActions.lpUnblockDialog'
          }
          components={getTransComponents(['bold'])}
          count={modal.licensePlateIds.length}
        />
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          data-testid="blockLicensePlate_submit"
          onClick={onSubmit}
          variant="contained"
          color="primary"
          actionType="submit"
        >
          {t('common.confirm')}
        </ModalButton>
      </ModalActions>
    </>
  );
};

export default BlockLicensePlateModal;
