import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BaseMap, { backgroundColor, insertPolygon } from '../../components/map/base-map';

import { useSlottingMovementsQuery } from '@/graphql/defs/list/__generated__/list-slotting-movements.generated';
import { useGetMapsLazyQuery } from '@/graphql/defs/shared-queries/__generated__/get-maps.generated';
import { GetMaps_defaultData } from '@/graphql/defs/shared-queries/get-maps';
import { useEntityUtils } from '@context/entity-utils';
const MOVED = '#CB3E27';
const UNMOVED = '#7BB88C';

export default function SlottingRunMap({ runId }) {
  const [mapView, setMapView] = useState('fixedBinChanges');
  const [binView, setBinView] = useState('source');
  const [loading, setLoading] = useState(true);
  const [movementColumns, setMovementColumns] = useState({
    source: {},
    destination: {},
  });

  const [getMaps, { data: { maps: { nodes: maps } } = GetMaps_defaultData }] = useGetMapsLazyQuery({
    onCompleted: (data) => {
      const rawMap = data.maps.nodes[0].mapJson.warehouses[0];
      const preparedMap = prepareAreaMap(rawMap, 'warehouse');
      setMap(preparedMap);
      console.log({ preparedMap });
      setLoading(false);
    },
  });

  useEffect(() => {
    if (maps?.length) {
      const rawMap = maps?.[0]?.mapJson?.warehouses[0];
      setLoading(true);
      const preparedMap = prepareAreaMap(rawMap, 'warehouse');
      console.log({ preparedMap });
      setMap(preparedMap);
      setLoading(false);
    }
  }, [mapView, binView, maps]);

  useSlottingMovementsQuery({
    variables: {
      filter: {
        run_id: {
          eq: runId,
        },
      },
    },
    onCompleted: ({ listSlottingMovements: { slottingMovements } }) => {
      setMovementColumns(
        slottingMovements.reduce(
          (acc, movement) => {
            acc.source[movement.source_column] = true;
            acc.destination[movement.destination_column] = true;
            return acc;
          },
          { source: {}, destination: {} },
        ),
      );
      getMaps({
        variables: {
          filter: {
            warehouseId: {
              eq: selectedWarehouseId,
            },
          },
        },
      });
    },
  });
  const [map, setMap] = useState(null);
  const { selectedWarehouseId } = useEntityUtils();

  const prepareAreaMap = (_object, type) => {
    const obj = { ..._object };
    if ((obj.x1 || obj.polygon) && !['paths', 'path', 'polygon'].includes(type)) {
      if (!obj.polygon) obj.polygon = insertPolygon(obj);

      switch (type) {
        case 'columns':
          obj.lineWidth = 0.5;
          obj.strokeStyle = backgroundColor;
          obj.fillStyle = movementColumns[binView][_object.code] ? MOVED : UNMOVED;
          obj.selectionStrokeStyle = 'green'; // maybe make object with selection type as key
          obj.selectionFillStyle = 'green'; // maybe make object with selection type as key
          obj.rendered = true;
          obj.clickable = true;
          obj.selectable = true;
          break;
        case 'walls':
          obj.rendred = true;
          obj.strokeStyle = 'black';
          break;
        case 'areas':
          obj.rendered = true;
          obj.strokeStyle = 'black';
          obj.clickable = 'true';
          // const center = polylabel([obj.polygon]);
          // obj.text = [
          //   {
          //     text: obj.code,
          //     font: '12px Barlow',
          //     x: center[0],
          //     y: center[1],
          //     direction: 'vertical',
          //     textAlign: 'center',
          //   },
          // ];
          break;
        case 'aisles':
          obj.clickable = true;
          break;
      }
    }

    // go through all children in arrays preparing each for the map
    Object.entries(obj).forEach(([key, value]) => {
      if (Array.isArray(value) && typeof value[0] === 'object') {
        obj[key] = value.map((child, index) => {
          return prepareAreaMap(child, key);
        });
      }
    });

    return obj;
  };

  return (
    <Box sx={{ display: 'flex', padding: '15px', backgroundColor: '#FFF', borderRadius: '4px' }}>
      <Box sx={{ width: 'calc(100% - 250px)' }}>
        <BaseMap map={map} hideMapSelector={true} hideTitle={true} loading={loading} />
      </Box>
      <Key mapView={mapView} setMapView={setMapView} binView={binView} setBinView={setBinView} />
    </Box>
  );
}

const Key = ({ mapView, setMapView, binView, setBinView }) => {
  const { t } = useTranslation('components');
  return (
    <Box sx={{ backgroundColor: '#FFF', borderRadius: '4px', width: '250px', padding: '20px' }}>
      <Box sx={{ marginBottom: '25px', fontWeight: 600, fontSize: '18px' }}>Filters</Box>
      <FormControl fullWidth>
        <InputLabel id="zone-label">{t('common.mapView')}</InputLabel>
        <Select
          labelId="zone-label"
          id="demo-simple-select"
          value={mapView}
          label={t('common.mapView')}
          onChange={({ target: { value } }) => setMapView(value)}
        >
          <MenuItem value={'fixedBinChanges'}>{t('slotting.fixedBinChanges')}</MenuItem>
        </Select>
      </FormControl>
      <Box sx={{ marginTop: '25px', fontWeight: 600, fontSize: '16px' }}>{t('common.binView')}</Box>
      <RadioGroup
        name={'binView'}
        value={binView}
        onChange={(e, value) => {
          setBinView(value);
        }}
      >
        <FormControlLabel value={'source'} control={<Radio />} label={t('common.sourceBin')} />
        <FormControlLabel
          value={'destination'}
          control={<Radio />}
          label={t('common.destinationBin')}
        />
      </RadioGroup>
      <hr />
      <Box sx={{ margin: '15px 0px 15px 0px', fontWeight: 600, fontSize: '18px' }}>
        {t('common.key')}
      </Box>
      <Box sx={{ display: 'flex', margin: '10px 10px 15px 0px', alignItems: 'center' }}>
        <Box sx={{ height: '32px', marginRight: '5px', width: '14px', backgroundColor: UNMOVED }} />
        {t('slotting.noMovements')}
      </Box>
      <Box sx={{ display: 'flex', margin: '10px 10px 15px 0px', alignItems: 'center' }}>
        <Box sx={{ height: '32px', marginRight: '5px', width: '14px', backgroundColor: MOVED }} />
        {t('slotting.movements')}
      </Box>
    </Box>
  );
};
