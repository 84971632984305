import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Menu, Fade, Typography, MenuItem } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { useState, MouseEvent, ReactNode, useMemo, memo } from 'react';
import isEqual from 'react-fast-compare';

import LoadingIndicator from '@components/loading-indicator';

export interface IDropdownMenuItem<T = unknown> {
  value: T;
  component: string | ReactNode;
  selected?: boolean;
  onSelect?: () => void;
  optionSx?: SxProps<Theme>;
}

export interface IDropdownMenuSection<T = unknown> {
  label?: string;
  emptyMessage?: string;
  options: IDropdownMenuItem<T>[];
}

export interface IDropdownMenu<T = unknown> {
  loading?: boolean;
  optionSections: IDropdownMenuSection<T>[];
  handleSelect?: (option: IDropdownMenuItem<T>) => void;
  anchorLeft?: boolean;
  maxMenuHeight?: number;
  expandIconSx?: SxProps<Theme>;
  dataTestId: string;
}

const DropdownMenu = memo(
  ({
    loading = false,
    optionSections = [],
    handleSelect = () => {},
    anchorLeft = false,
    maxMenuHeight = 250,
    expandIconSx = {},
    dataTestId,
  }: IDropdownMenu) => {
    const [menu, setMenu] = useState<null | HTMLElement>(null);
    const openMenu = (event: MouseEvent<HTMLDivElement>) => {
      setMenu(event.currentTarget);
    };
    const closeMenu = () => {
      setMenu(null);
    };

    const selected = useMemo(() => {
      let selectedOption = null;
      for (let i = 0; i < optionSections.length; i++) {
        const selectedFound = optionSections[i].options.find((o) => o.selected);
        if (!!selectedFound) {
          selectedOption = selectedFound;
          break;
        }
      }
      return selectedOption;
    }, [optionSections]);

    if (loading) {
      return <LoadingIndicator />;
    }
    return (
      <>
        <StyledBox onClick={openMenu} data-testid={dataTestId}>
          {selected && selected.component}
          {menu ? (
            <ExpandLessIcon
              sx={{
                fontSize: '36px',
                fill: (theme) => theme.palette.primary.main,
                ...expandIconSx,
              }}
            />
          ) : (
            <ExpandMoreIcon
              sx={{
                fontSize: '36px',
                fill: (theme) => theme.palette.primary.main,
                ...expandIconSx,
              }}
            />
          )}
        </StyledBox>
        <Menu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: !anchorLeft ? 'center' : 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: !anchorLeft ? 'center' : 'left',
          }}
          PaperProps={{
            style: {
              maxHeight: maxMenuHeight,
            },
          }}
          id="dropdown-menu"
          anchorEl={menu}
          keepMounted
          disableAutoFocusItem
          open={Boolean(menu)}
          onClose={closeMenu}
          TransitionComponent={Fade}
          data-testid={`${dataTestId}-menu`}
        >
          {optionSections.map((optionSection, sectionIndex) => (
            <Box key={`dropdownMenu-optionSection-${sectionIndex}`}>
              {sectionIndex > 0 && <StyledLine />}
              {!!optionSection.label && (
                <Typography
                  variant="body4"
                  sx={{ padding: (theme) => theme.spacing(0, 2, 2) }}
                  data-testid={`${dataTestId}-menu-section-label`}
                >
                  {optionSection.label}
                </Typography>
              )}
              {optionSection.options.length > 0 ? (
                optionSection.options.map((option, optionIndex) => (
                  <StyledMenuItem
                    onClick={() => {
                      closeMenu();
                      handleSelect(option);
                    }}
                    key={`dropdown-menu-item-${optionIndex}`}
                    data-testid={`${dataTestId}-menu-item`}
                    sx={option?.optionSx || {}}
                  >
                    {option.component}
                  </StyledMenuItem>
                ))
              ) : (
                <Typography
                  textAlign="center"
                  sx={{ padding: '6px 16px;' }}
                  data-testid={`${dataTestId}-menu-emptyMessage`}
                >
                  {optionSection.emptyMessage}
                </Typography>
              )}
            </Box>
          ))}
        </Menu>
      </>
    );
  },
  isEqual,
);

export default DropdownMenu;

const StyledMenuItem = styled(MenuItem)(
  () => `
    font-size: 18px;
    font-weight: 600;
    color: #4A4A4A;
`,
);

const StyledBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    color: ${theme.palette.primary.main};
    cursor: pointer;
    user-select: none;
    padding: 7px 0 5px 5px;
    border-radius: 5px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
`,
);

const StyledLine = styled('div')(
  ({ theme }) => `
    height: 1px;
    width: 100%;
    margin: ${theme.spacing(2, 0)};
    background-color: #D8E0E5
  `,
);
