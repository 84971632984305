/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ZoneAislesSettingsTableFragment = { __typename?: 'ViewZoneAisle', zoneId?: string | null, zoneCode?: string | null, zoneName?: string | null, zoneDescription?: string | null, warehouseId?: string | null, aisleId?: string | null, aisleCode?: string | null, aisleName?: string | null, aisleDescription?: string | null, mapped?: boolean | null };

export type ZoneAislesSettingsTableQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.ViewZoneAisleFilter>;
  sorting?: Types.InputMaybe<Array<Types.ViewZoneAisleSort> | Types.ViewZoneAisleSort>;
  includePageInfo?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeNodes?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeTotalCount?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ZoneAislesSettingsTableQuery = { __typename?: 'Query', query: { __typename?: 'ViewZoneAisleOffsetConnection', totalCount?: number, pageInfo?: { __typename?: 'OffsetPageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null }, nodes?: Array<{ __typename?: 'ViewZoneAisle', zoneId?: string | null, zoneCode?: string | null, zoneName?: string | null, zoneDescription?: string | null, warehouseId?: string | null, aisleId?: string | null, aisleCode?: string | null, aisleName?: string | null, aisleDescription?: string | null, mapped?: boolean | null }> } };

export const ZoneAislesSettingsTableFragmentDoc = gql`
    fragment ZoneAislesSettingsTable on ViewZoneAisle {
  zoneId
  zoneCode
  zoneName
  zoneDescription
  warehouseId
  aisleId
  aisleCode
  aisleName
  aisleDescription
  mapped
}
    `;
export const ZoneAislesSettingsTableDocument = gql`
    query ZoneAislesSettingsTable($paging: OffsetPaging, $filter: ViewZoneAisleFilter, $sorting: [ViewZoneAisleSort!], $includePageInfo: Boolean = true, $includeNodes: Boolean = true, $includeTotalCount: Boolean = false) {
  query: viewZoneAisles(sorting: $sorting, paging: $paging, filter: $filter) {
    pageInfo @include(if: $includePageInfo) {
      hasNextPage
      hasPreviousPage
    }
    nodes @include(if: $includeNodes) {
      ...ZoneAislesSettingsTable
    }
    totalCount @include(if: $includeTotalCount)
  }
}
    ${ZoneAislesSettingsTableFragmentDoc}`;

/**
 * __useZoneAislesSettingsTableQuery__
 *
 * To run a query within a React component, call `useZoneAislesSettingsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoneAislesSettingsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoneAislesSettingsTableQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      includePageInfo: // value for 'includePageInfo'
 *      includeNodes: // value for 'includeNodes'
 *      includeTotalCount: // value for 'includeTotalCount'
 *   },
 * });
 */
export function useZoneAislesSettingsTableQuery(baseOptions?: Apollo.QueryHookOptions<ZoneAislesSettingsTableQuery, ZoneAislesSettingsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ZoneAislesSettingsTableQuery, ZoneAislesSettingsTableQueryVariables>(ZoneAislesSettingsTableDocument, options);
      }
export function useZoneAislesSettingsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZoneAislesSettingsTableQuery, ZoneAislesSettingsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ZoneAislesSettingsTableQuery, ZoneAislesSettingsTableQueryVariables>(ZoneAislesSettingsTableDocument, options);
        }
export type ZoneAislesSettingsTableQueryHookResult = ReturnType<typeof useZoneAislesSettingsTableQuery>;
export type ZoneAislesSettingsTableLazyQueryHookResult = ReturnType<typeof useZoneAislesSettingsTableLazyQuery>;
export type ZoneAislesSettingsTableQueryResult = Apollo.QueryResult<ZoneAislesSettingsTableQuery, ZoneAislesSettingsTableQueryVariables>;