import { Box, Paper, Typography } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { useEffect, useState } from 'react';

type TCreateUID = (item: Record<string, any>) => string;
export interface EditableCell {
  item: Record<string, any>;
  createUID: TCreateUID;
}

export interface EditableTableColumn {
  label: string;
  width: string;
}
export interface EditableTableCell {
  dataTestId: string;
  value: string | number | React.FC<EditableCell> | JSX.Element;
}
export interface EditableTableRow {
  dataTestId: string;
  item: Record<string, any>;
  cells: EditableTableCell[];
}
interface IEditableTable {
  label?: string | JSX.Element;
  columns: EditableTableColumn[];
  createUID?: TCreateUID;
  data: EditableTableRow[];
  darkHeader?: boolean;
  removeElevate?: boolean;
  sx?: SxProps<Theme>;
}
const EditableTable: React.FC<IEditableTable> = ({
  label,
  columns,
  createUID,
  data,
  darkHeader,
  removeElevate,
  sx,
}) => {
  const [tableContent, setTableContent] = useState(data || []);
  useEffect(() => {
    setTableContent(data ? [...data] : []);
  }, [data]);

  const TableHeaders = () => (
    <TableHeaderRow>
      {columns.map((column, i) => (
        <TableHeader
          data-testid={`editableTable_header_${i}`}
          key={`${label}-tableHeader-${i}`}
          sx={{ width: `${column.width}` }}
          darkHeader={darkHeader}
        >
          {column.label}
        </TableHeader>
      ))}
    </TableHeaderRow>
  );

  const TableContent = () => (
    <>
      {tableContent.map((row, rowIndex) => (
        <TableRow
          role="editableTable_contentRow"
          key={`editableTable-row-${rowIndex}`}
          data-testid={row.dataTestId}
        >
          {row.cells.map((cell, cellIndex) => {
            return (
              <TableCell
                key={`editableTable-cell-${rowIndex}-${cellIndex}`}
                sx={{ width: `${columns[cellIndex]?.width || '250px'}` }}
                data-testid={cell.dataTestId}
              >
                {typeof cell.value === 'function'
                  ? cell.value({ item: row.item, createUID })
                  : cell.value}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </>
  );

  return (
    // TODO: At some point this should be changed to a react-table variant so the headers and data can be aligned properly - and have sorting if we really want it.
    <Paper elevation={!removeElevate ? 5 : 0} sx={{ overflow: 'hidden', minWidth: '830px', ...sx }}>
      {label && (
        <Typography
          data-testid="editableTable_header"
          variant="h3"
          sx={{ padding: '15px 15px 10px' }}
        >
          {label}
        </Typography>
      )}

      <TableContainer data-testid="createLicensePlate_form">
        {/* Table Headers */}
        <TableHeaders />
        {/* Table Content */}
        <TableContent />
      </TableContainer>
    </Paper>
  );
};
export default EditableTable;

const TableContainer = styled(Box)({
  '&': {
    maxHeight: '485px',
    overflowY: 'auto',
  },
});

const TableHeaderRow = styled(Box)(({ theme }) => ({
  '&': {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '5px 15px',
    background: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.gainsboro.main}`,
  },
}));

const TableHeader = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'darkHeader',
})<{ darkHeader?: boolean }>(({ theme, darkHeader }) => ({
  '&': {
    fontWeight: 600,
    color: !darkHeader ? '#68746F' : 'rgba(0,0,0,0.87)',
    padding: '5px',
    borderRight: '1px solid #D7D7D7',
    textTransform: 'capitalize',
  },
  '&:last-of-type': {
    borderRight: 'none',
  },
}));

const TableRow = styled(Box)(({ theme }) => ({
  '&': {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '5px 15px',
  },
  '&:nth-of-type(odd)': {
    background: theme.palette.offwhite.main,
  },
}));

const TableCell = styled(Box)(({ theme }) => ({
  '&': {
    padding: '5px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.palette.primary.main,
  },
  '&:last-of-type': {
    borderRight: 'none',
  },
}));
