import { Grid } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { BinStorageTypeCreateInput, BinStorageTypeUpdateInput } from '@/graphql/types.generated';
import DistanceUomDropdownDb from '@components/form-util/field-blocks/distance-uom-dropdown-db';
import WeightUomDropdownDb from '@components/form-util/field-blocks/weight-uom-dropdown-db';
import TextField from '@components/text-field';
import { useEntityUtils } from '@context/entity-utils';
import { useFormValidation } from '@hooks/form/validators';
import { allowOnlyNumeric, formatAsInteger, uppercase } from '@lib/form';
import FormValues from '@models/FormValues';

const BinStorageTypeFields = ({
  control,
}: {
  control:
    | Control<FormValues<BinStorageTypeCreateInput>, any>
    | Control<FormValues<BinStorageTypeUpdateInput>, any>;
}) => {
  const { t } = useTranslation('components');
  const { selectedWarehouseId } = useEntityUtils();
  const { fieldIsInteger, fieldMinValue, notWhiteSpaceOnly } = useFormValidation();

  return (
    <Grid container spacing={6}>
      <Grid item xs={6}>
        <Controller
          name="code"
          control={control}
          rules={{
            required: t('form.validation.requiredField', { field: t('common.code') }),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              autoFocus
              fullWidth
              required
              dataTestId="bin-storage-type-code"
              label={t('common.code')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              onChange={(e) => field.onChange(uppercase(e))}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="label"
          control={control}
          rules={{
            required: t('form.validation.requiredField', { field: t('common.label') }),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              required
              dataTestId="bin-storage-type-label"
              label={t('common.label')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="description"
          control={control}
          rules={{
            required: t('form.validation.requiredField', { field: t('common.description') }),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              required
              dataTestId="bin-storage-type-description"
              label={t('common.description')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="weightCapacityUomId"
          control={control}
          rules={{
            required: t('form.validation.requiredField', { field: t('common.weightUOM') }),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <WeightUomDropdownDb
              field={field}
              fieldState={fieldState}
              warehouseId={selectedWarehouseId}
              label={t('common.weightUOM')}
              dataTestId="bin-storage-type"
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="weightCapacity"
          control={control}
          rules={{
            required: t('form.validation.requiredField', { field: t('common.weightCapacity') }),
            validate: {
              fieldIsInteger,
              minValue: fieldMinValue(0),
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              required
              dataTestId="bin-storage-type-weightCapacity"
              label={t('common.weightCapacity')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              type="number"
              onChange={(e) =>
                field.onChange(allowOnlyNumeric(e, false, { allowNegatives: false }))
              }
              value={formatAsInteger(field.value)}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="distanceUomId"
          control={control}
          rules={{
            required: t('form.validation.requiredField', { field: t('common.dimensionUOM') }),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <DistanceUomDropdownDb
              field={field}
              fieldState={fieldState}
              warehouseId={selectedWarehouseId}
              label={t('common.dimensionUOM')}
              dataTestId="bin-storage-type"
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="depth"
          control={control}
          rules={{
            required: t('form.validation.requiredField', { field: t('common.depth') }),
            validate: {
              fieldIsInteger,
              minValue: fieldMinValue(0),
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              required
              dataTestId="bin-storage-type-depth"
              label={t('common.depth')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              type="number"
              onChange={(e) =>
                field.onChange(allowOnlyNumeric(e, false, { allowNegatives: false }))
              }
              value={formatAsInteger(field.value)}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="width"
          control={control}
          rules={{
            required: t('form.validation.requiredField', { field: t('common.width') }),
            validate: {
              fieldIsInteger,
              minValue: fieldMinValue(0),
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              required
              dataTestId="bin-storage-type-width"
              label={t('common.width')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              type="number"
              onChange={(e) =>
                field.onChange(allowOnlyNumeric(e, false, { allowNegatives: false }))
              }
              value={formatAsInteger(field.value)}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="height"
          control={control}
          rules={{
            required: t('form.validation.requiredField', { field: t('common.height') }),
            validate: {
              fieldIsInteger,
              minValue: fieldMinValue(0),
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              required
              dataTestId="bin-storage-type-height"
              label={t('common.height')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              type="number"
              onChange={(e) =>
                field.onChange(allowOnlyNumeric(e, false, { allowNegatives: false }))
              }
              value={formatAsInteger(field.value)}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default BinStorageTypeFields;
