/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBusinessPartnerMutationVariables = Types.Exact<{
  input: Types.BusinessPartnerUpdateOneInput;
}>;


export type UpdateBusinessPartnerMutation = { __typename?: 'Mutation', updateOneBusinessPartner: { __typename?: 'BusinessPartner', id: string, code: string, name?: string | null } };

export type DeleteBusinessPartnerMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteBusinessPartnerMutation = { __typename?: 'Mutation', deleteOneBusinessPartner: { __typename?: 'BusinessPartner', id: string, code: string, name?: string | null } };


export const UpdateBusinessPartnerDocument = gql`
    mutation UpdateBusinessPartner($input: BusinessPartnerUpdateOneInput!) {
  updateOneBusinessPartner(input: $input) {
    id
    code
    name
  }
}
    `;
export type UpdateBusinessPartnerMutationFn = Apollo.MutationFunction<UpdateBusinessPartnerMutation, UpdateBusinessPartnerMutationVariables>;

/**
 * __useUpdateBusinessPartnerMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessPartnerMutation, { data, loading, error }] = useUpdateBusinessPartnerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBusinessPartnerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessPartnerMutation, UpdateBusinessPartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessPartnerMutation, UpdateBusinessPartnerMutationVariables>(UpdateBusinessPartnerDocument, options);
      }
export type UpdateBusinessPartnerMutationHookResult = ReturnType<typeof useUpdateBusinessPartnerMutation>;
export type UpdateBusinessPartnerMutationResult = Apollo.MutationResult<UpdateBusinessPartnerMutation>;
export type UpdateBusinessPartnerMutationOptions = Apollo.BaseMutationOptions<UpdateBusinessPartnerMutation, UpdateBusinessPartnerMutationVariables>;
export const DeleteBusinessPartnerDocument = gql`
    mutation DeleteBusinessPartner($id: ID!) {
  deleteOneBusinessPartner(input: {id: $id}) {
    id
    code
    name
  }
}
    `;
export type DeleteBusinessPartnerMutationFn = Apollo.MutationFunction<DeleteBusinessPartnerMutation, DeleteBusinessPartnerMutationVariables>;

/**
 * __useDeleteBusinessPartnerMutation__
 *
 * To run a mutation, you first call `useDeleteBusinessPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusinessPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusinessPartnerMutation, { data, loading, error }] = useDeleteBusinessPartnerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBusinessPartnerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBusinessPartnerMutation, DeleteBusinessPartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBusinessPartnerMutation, DeleteBusinessPartnerMutationVariables>(DeleteBusinessPartnerDocument, options);
      }
export type DeleteBusinessPartnerMutationHookResult = ReturnType<typeof useDeleteBusinessPartnerMutation>;
export type DeleteBusinessPartnerMutationResult = Apollo.MutationResult<DeleteBusinessPartnerMutation>;
export type DeleteBusinessPartnerMutationOptions = Apollo.BaseMutationOptions<DeleteBusinessPartnerMutation, DeleteBusinessPartnerMutationVariables>;