/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateDoorMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  updatedDoor: Types.DoorUpdateInput;
}>;


export type UpdateDoorMutation = { __typename?: 'Mutation', updateOneDoor: { __typename?: 'Door', id: string, code?: string | null } };

export type DeleteDoorMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteDoorMutation = { __typename?: 'Mutation', deleteOneDoor: { __typename?: 'Door', id: string, code?: string | null, deletedAt?: string | null } };


export const UpdateDoorDocument = gql`
    mutation UpdateDoor($id: ID!, $updatedDoor: DoorUpdateInput!) {
  updateOneDoor(input: {id: $id, update: $updatedDoor}) {
    id
    code
  }
}
    `;
export type UpdateDoorMutationFn = Apollo.MutationFunction<UpdateDoorMutation, UpdateDoorMutationVariables>;

/**
 * __useUpdateDoorMutation__
 *
 * To run a mutation, you first call `useUpdateDoorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDoorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDoorMutation, { data, loading, error }] = useUpdateDoorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updatedDoor: // value for 'updatedDoor'
 *   },
 * });
 */
export function useUpdateDoorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDoorMutation, UpdateDoorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDoorMutation, UpdateDoorMutationVariables>(UpdateDoorDocument, options);
      }
export type UpdateDoorMutationHookResult = ReturnType<typeof useUpdateDoorMutation>;
export type UpdateDoorMutationResult = Apollo.MutationResult<UpdateDoorMutation>;
export type UpdateDoorMutationOptions = Apollo.BaseMutationOptions<UpdateDoorMutation, UpdateDoorMutationVariables>;
export const DeleteDoorDocument = gql`
    mutation DeleteDoor($id: ID!) {
  deleteOneDoor(input: {id: $id}) {
    id
    code
    deletedAt
  }
}
    `;
export type DeleteDoorMutationFn = Apollo.MutationFunction<DeleteDoorMutation, DeleteDoorMutationVariables>;

/**
 * __useDeleteDoorMutation__
 *
 * To run a mutation, you first call `useDeleteDoorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDoorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDoorMutation, { data, loading, error }] = useDeleteDoorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDoorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDoorMutation, DeleteDoorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDoorMutation, DeleteDoorMutationVariables>(DeleteDoorDocument, options);
      }
export type DeleteDoorMutationHookResult = ReturnType<typeof useDeleteDoorMutation>;
export type DeleteDoorMutationResult = Apollo.MutationResult<DeleteDoorMutation>;
export type DeleteDoorMutationOptions = Apollo.BaseMutationOptions<DeleteDoorMutation, DeleteDoorMutationVariables>;