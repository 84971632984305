import { gql } from '@apollo/client';

import { ListDoorsForDeliveryQuery } from '@/graphql/defs/components/modals/__generated__/delivery-door-assign.generated';

gql`
  mutation AssignDoorToDelivery($doorId: ID!, $deliveryId: ID!) {
    updateOneDelivery(input: { id: $deliveryId, update: { doorId: $doorId } }) {
      id
      erpCode
      door {
        id
        code
      }
    }
  }

  query ListDoorsForDelivery($direction: doorDirection!) {
    listDoors: doors(
      filter: { direction: { in: [$direction, both] } }
      sorting: [{ field: code, direction: ASC }]
    ) {
      doors: nodes {
        id
        code
      }
    }
  }
`;

export const ListDoorsForDelivery_defaultData: ListDoorsForDeliveryQuery = {
  __typename: 'Query',
  listDoors: {
    __typename: 'DoorOffsetConnection',
    doors: [],
  },
};
