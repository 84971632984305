import { DateTime } from 'luxon';

interface IAllowOnlyNumericOptions {
  /**
   * Determines if negative values are allowed.
   *
   * @defaultValue true
   */
  allowNegatives?: boolean;
  /**
   * Determines if `0` is an allowed value.
   *
   * @defaultValue true
   */
  allowZero?: boolean;
  /**
   * Determines if we parse the value as an int or float.
   *
   * @defaultValue false
   */
  allowFloat?: boolean;
}

const defaultAllowOnlyNumericOpts: IAllowOnlyNumericOptions = {
  allowNegatives: true,
  allowZero: true,
  allowFloat: false,
};

/**
 * onChange handler for fields to enforce only integer/numeric values can be inputted.
 *
 * @param e -
 * @param castToString - Should
 * @param optsOverride - Optional options object.
 * @returns Empty string if value is false-y, an empty string, or fails our integer checks. Otherwise, the number will be returned.
 */
export function allowOnlyNumeric(
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  castToString: true,
  optsOverride?: IAllowOnlyNumericOptions,
): string;
export function allowOnlyNumeric(
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  castToString: false,
  optsOverride?: IAllowOnlyNumericOptions,
): number | '';
export function allowOnlyNumeric(
  e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  castToString: boolean,
  optsOverride: IAllowOnlyNumericOptions = {},
): number | string {
  const options: IAllowOnlyNumericOptions = { ...defaultAllowOnlyNumericOpts, ...optsOverride };
  if (e.target.value && e.target.value !== '') {
    const output = optsOverride.allowFloat
      ? parseFloat(e.target.value)
      : parseInt(e.target.value, 10);
    if (
      !isNaN(output) &&
      (options.allowNegatives || output >= 0) &&
      (options.allowZero || output !== 0)
    ) {
      return castToString ? output.toString() : output;
    }
  }
  return '';
}

/**
 * Formats a given number to a string, if value is NaN an empty string is returned.
 *
 * @param value -
 * @returns string -
 */
export const formatAsInteger = (value: string | number | '') => {
  const valueAsNumber = +value;
  if (typeof valueAsNumber !== 'undefined' && value !== null && !isNaN(valueAsNumber)) {
    return value.toString(10);
  }
  return '';
};

/**
 * onChange handler for fields to enforce values are always uppercase.
 *
 * @param e -
 */
export const uppercase = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  if (typeof e.target.value === 'string' && e.target.value !== '') {
    return e.target.value.toUpperCase();
  }
  return '';
};

/**
 * Returns a modify version of updatedValues with keys removed that are unchanged from currentValues.
 *
 * @param currentValues - Object
 * @param updatedValues - Object
 *
 * @return Object
 */
export function stripUnchangedFields<T = Record<string, any>>(
  currentValues: T,
  updatedValues: Partial<Record<keyof T, any>>,
) {
  const stripedFields = updatedValues;
  Object.keys(updatedValues).forEach((key) => {
    if (typeof updatedValues[key] === 'object' && updatedValues[key] instanceof DateTime) {
      // We have to set the timezone specifically to UTC-0 here, without keeping the local time, so that the ISO format and offset matches how the database saves/returns DateTime ISO strings.
      // Etc/UTC appends +00:00 while UTC-0 appends only Z. Here, we want UTC-0 as the DB saves with Z instead of +00:00.
      const updatedDateTime = updatedValues[key] as DateTime;
      if (updatedDateTime.setZone('UTC-0').toISO() === currentValues[key])
        delete stripedFields[key];
    } else if (updatedValues[key] === currentValues[key]) {
      delete stripedFields[key];
    }
  });
  return stripedFields;
}

// export function convertNullValuesToEmptyString<T>(keys: T[], obj: Record<T, any>) {
// export function convertNullValuesToEmptyString<T extends string>(
// T = 'code' | 'id'
export function convertNullValuesToEmptyString<T extends string>(keys: T[], obj: Record<any, any>) {
  return keys.reduce((acc, key) => {
    acc[key] = !obj || obj[key] === null ? '' : obj[key];
    return acc;
  }, {} as unknown as Record<any, any>);
}
