import React, { useState, useEffect, useContext, createContext } from 'react';

import { useAuth } from './auth';

import {
  SelectedWarehouseFragment,
  useGetSelectedWarehouseQuery,
} from '@/graphql/defs/context/__generated__/entity-utils.generated';
import { GetSelectedWarehouse_defaultData } from '@/graphql/defs/context/entity-utils';
import {
  PermittedWarehousesQuery,
  usePermittedWarehousesQuery,
} from '@/graphql/defs/list/__generated__/list-permitted-warehouses.generated';
import { PermittedWarehouses_defaultData } from '@/graphql/defs/list/list-permitted-warehouses';
import { useSnackbar } from '@context/snackbar';
import usePrevious from '@hooks/usePrevious';

export const SELECTED_WAREHOUSE_ID_KEY = 'selectedWarehouseId';
export const DEFAULT_SELECTED_WAREHOUSE_ID = 'core';

export interface IEntityUtils {
  selectedOrganizationId: string;
  selectedWarehouseId?: string;
  selectedWarehouse?: SelectedWarehouseFragment;
  selectWarehouse: (warehouseId: string) => void;
  isLoadingSelectedWarehouse: boolean;
  permittedWarehouses: PermittedWarehousesQuery['permittedWarehouses'];
}
const EntityUtilsContext = createContext<IEntityUtils>(null);

const EntityUtilsProvider = ({ children }) => {
  const { showMessage } = useSnackbar();
  const { user } = useAuth();

  // There is not multi-org functionality currently, but when we support it we can update selectedOrganizationId to help propagate across the app.
  const organizationId = 'default';

  const selectedWarehouseId = (function () {
    const _sessionWarehouseId = sessionStorage.getItem(SELECTED_WAREHOUSE_ID_KEY);
    if (!_sessionWarehouseId) {
      sessionStorage.setItem(SELECTED_WAREHOUSE_ID_KEY, DEFAULT_SELECTED_WAREHOUSE_ID);
      return DEFAULT_SELECTED_WAREHOUSE_ID;
    } else {
      return _sessionWarehouseId;
    }
  })();

  const [selectedWarehouse, setSelectedWarehouse] = useState<SelectedWarehouseFragment>(
    GetSelectedWarehouse_defaultData.warehouse,
  );

  const selectWarehouse = (warehouseId: string) => {
    sessionStorage.setItem(SELECTED_WAREHOUSE_ID_KEY, warehouseId);

    // If the user is on the aisle map page, we need to redirect them to the area map page.
    // Otherwise, we just reload the page. We need to reload the page so the Apollo Client x-warehouse-id header is updated.
    const { pathname } = window.location;
    if (/map\/area\/.*\/aisle/.test(pathname)) {
      window.location.href = window.location.origin + '/map/area';
    } else {
      window.location.reload();
    }
  };

  const { loading: isLoadingSelectedWarehouse } = useGetSelectedWarehouseQuery({
    skip: !user?.id || !selectedWarehouseId,
    fetchPolicy: 'cache-first',
    variables: { id: selectedWarehouseId },
    onCompleted: ({ warehouse }) => {
      setSelectedWarehouse(warehouse);
    },
    onError: async (error) => {
      showMessage({ type: 'error', message: error.message });
    },
  });
  const { data: { permittedWarehouses } = PermittedWarehouses_defaultData } =
    usePermittedWarehousesQuery();

  return (
    <EntityUtilsContext.Provider
      value={{
        selectedOrganizationId: organizationId,
        selectedWarehouseId,
        selectedWarehouse,
        selectWarehouse,
        isLoadingSelectedWarehouse,
        permittedWarehouses,
      }}
    >
      {children}
    </EntityUtilsContext.Provider>
  );
};

export default EntityUtilsProvider;

interface IUseEntityUtils {
  onWarehouseChange?: () => void;
}

export const useEntityUtils = ({ onWarehouseChange }: IUseEntityUtils = {}) => {
  const ctx = useContext(EntityUtilsContext);
  if (ctx === null) {
    throw new Error('useEntityUtils must be used within WarehouseProvider');
  }

  const { selectedWarehouseId } = ctx;
  const prevSelectedWarehouseId = usePrevious(selectedWarehouseId);

  useEffect(() => {
    if (
      prevSelectedWarehouseId &&
      prevSelectedWarehouseId !== selectedWarehouseId &&
      onWarehouseChange
    ) {
      onWarehouseChange();
    }
  }, [selectedWarehouseId]);

  return ctx;
};
