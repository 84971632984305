import { DateTime } from 'luxon';
import { useRef, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { OffsetPageInfo, OffsetPaging } from '@/graphql/types.generated';
import {
  DefaultDataType,
  DefaultFilterType,
  IAPISort,
  IDataTableData,
  IDataTablePagination,
  IFetchResponseData,
  TGoToPageActions,
  TLazyQueryHook,
} from '@components/data-table/model/data-table';
import TableIds from '@components/data-table/model/TableIds';
import { useEntityUtils } from '@context/entity-utils';
import { useFeatureFlags } from '@context/feature-flags';
import { useSnackbar } from '@context/snackbar';
import useDebouncedEffect from '@hooks/useDebouncedEffect';

const DEFAULT_QUERY_RES: IFetchResponseData<DefaultDataType> = {
  __typename: 'Query',
  query: {
    __typename: 'defaultResponse',
    pageInfo: {
      hasNextPage: false,
      hasPreviousPage: false,
    },
    nodes: [],
    totalCount: 0,
  },
};

const SAVED_PAGINATION_PREFIX = 'savedDataTablePagination';
interface ISavedPagination {
  dataTablePagination: OffsetPaging;
  lastUpdated: number;
}
const checkForSavedPagination = (
  tableId: string,
  warehouseId: string,
  savedPaginationTTL: number,
) => {
  const _savedPaginationStringified = sessionStorage.getItem(
    `${SAVED_PAGINATION_PREFIX}-${tableId}-${warehouseId}`,
  );
  if (!!_savedPaginationStringified) {
    const timeMs = DateTime.now().toMillis();
    const _savedPagination = JSON.parse(_savedPaginationStringified) as ISavedPagination;
    if (timeMs - _savedPagination.lastUpdated <= savedPaginationTTL) {
      sessionStorage.setItem(
        `${SAVED_PAGINATION_PREFIX}-${tableId}-${warehouseId}`,
        JSON.stringify({
          dataTablePagination: _savedPagination.dataTablePagination,
          lastUpdated: DateTime.now().toMillis(),
        }),
      );
      return _savedPagination.dataTablePagination;
    } else {
      sessionStorage.removeItem(`${SAVED_PAGINATION_PREFIX}-${tableId}-${warehouseId}`);
    }
  }
  return null;
};

export const DEFAULT_DATA_TABLE_PAGINATION: IDataTablePagination = {
  disablePagination: false,
  offset: 0,
  limit: 0,
  startIndex: 0,
  endIndex: 0,
  pageCount: 0,
  totalCount: 0,
  isLoadingTotalCount: false,
  perPageOptions: [10],
  goToPage: (page) => {},
  setPageLimit: (limit) => {},
  canPreviousPage: false,
  canNextPage: false,
  goToFirstPage: () => {},
  goToPrevPage: () => {},
  goToNextPage: () => {},
  goToLastPage: () => {},
};

interface IDataTableDataProps {
  tableId: TableIds;
  queryHook: TLazyQueryHook;
  suppressDataFetch: boolean;
  disablePagination: boolean;
  perPageOptions: number[];
  filter: DefaultFilterType;
  sorting: IAPISort[];
  onFetchComplete?: (data: DefaultDataType) => void;
  hasInitializedLayouts: boolean;
}

const useDataTableData = ({
  tableId,
  queryHook,
  suppressDataFetch,
  disablePagination,
  perPageOptions,
  filter,
  sorting,
  onFetchComplete,
  hasInitializedLayouts,
}: IDataTableDataProps): {
  dataTableData: IDataTableData;
  pagination: IDataTablePagination;
} => {
  const { t } = useTranslation('components', { keyPrefix: 'dataTable' });
  const { selectedWarehouseId } = useEntityUtils();
  const { applicationFeatureFlags } = useFeatureFlags();
  const { showMessage } = useSnackbar();
  const {
    applicationFeatureFlags: { dataTables: dataTableFlags },
  } = useFeatureFlags();

  const hasCompletedFirstFetch = useRef(false);
  const [isFetchPendingDebounce, setFetchPendingDebounce] = useState(false);

  perPageOptions = perPageOptions.length > 0 ? perPageOptions : [10];
  const [dataTablePagination, setDataTablePagination] = useState<OffsetPaging>({
    offset: 0,
    limit: perPageOptions[0],
  });

  const recordPaginationState = (updateLastUpdatedOnly: boolean = false) => {
    const _stringifiedPagination = sessionStorage.getItem(
      `${SAVED_PAGINATION_PREFIX}-${tableId}-${selectedWarehouseId}`,
    );

    if (updateLastUpdatedOnly && !!_stringifiedPagination) {
      const _pagination = JSON.parse(_stringifiedPagination) as ISavedPagination;
      sessionStorage.setItem(
        `${SAVED_PAGINATION_PREFIX}-${tableId}-${selectedWarehouseId}`,
        JSON.stringify({
          dataTablePagination: _pagination.dataTablePagination,
          lastUpdated: DateTime.now().toMillis(),
        }),
      );
    } else if (!updateLastUpdatedOnly) {
      sessionStorage.setItem(
        `${SAVED_PAGINATION_PREFIX}-${tableId}-${selectedWarehouseId}`,
        JSON.stringify({
          dataTablePagination,
          lastUpdated: DateTime.now().toMillis(),
        }),
      );
    }
  };

  const [persistedData, setPersistedData] = useState<DefaultDataType[]>([]);
  const [{ hasNextPage, hasPreviousPage }, setPersistedPageInfo] = useState<OffsetPageInfo>(
    DEFAULT_QUERY_RES?.query?.pageInfo,
  );
  const [dataLastFetched, setDataLastFetched] = useState<number>(null);
  const [fetchData, { loading: isLoadingNodes, refetch: refetchData }] = queryHook({
    onCompleted: ({ query: { nodes: _nodes, pageInfo: _pageInfo } }) => {
      setPersistedData(_nodes || DEFAULT_QUERY_RES?.query?.nodes);
      setPersistedPageInfo(_pageInfo || DEFAULT_QUERY_RES?.query?.pageInfo);
      setDataLastFetched(new Date().getTime());

      if (!hasCompletedFirstFetch.current) hasCompletedFirstFetch.current = true;

      if (
        !fetchTotalCountCalled ||
        (totalCountLastFetched &&
          new Date().getTime() - totalCountLastFetched >=
            applicationFeatureFlags.dataTables.totalCountRefreshMs)
      ) {
        fetchTotalCount({
          variables: {
            paging: {
              offset: dataTablePagination.offset,
              limit: dataTablePagination.limit,
            },
            filter: filter,
            sorting: sorting,
            includePageInfo: false,
            includeNodes: false,
            includeTotalCount: true,
          },
        });
      }

      if (onFetchComplete) onFetchComplete(_nodes);
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: t('errorFetchData', { errorMessage: error.message }),
      });
    },
  });

  const [persistedTotalCount, setPersistedTotalCount] = useState(0);
  const [totalCountLastFetched, setTotalCountLastFetched] = useState<number>(null);
  const [
    fetchTotalCount,
    { loading: isLoadingTotalCount, called: fetchTotalCountCalled, refetch: refetchTotalCount },
  ] = queryHook({
    onCompleted: ({ query: { totalCount: _totalCount } }) => {
      setPersistedTotalCount(_totalCount || DEFAULT_QUERY_RES?.query?.totalCount);
      setTotalCountLastFetched(new Date().getTime());
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: t('errorFetchTotalCount', { errorMessage: error.message }),
      });
    },
    fetchPolicy: 'no-cache',
  });

  const [fetchExportData] = queryHook({
    onError: (error) => {
      showMessage({
        type: 'error',
        message: t('errorFetchExport', { errorMessage: error.message }),
      });
    },
    fetchPolicy: 'no-cache',
  });
  const getExportData = useCallback(async () => {
    const { data } = await fetchExportData({
      variables: {
        filter: filter,
        sorting: sorting,
        includePageInfo: false,
        includeNodes: true,
        includeTotalCount: false,
      },
    });

    return data?.query?.nodes || [];
  }, []);

  const pageCount = Math.ceil(persistedTotalCount / dataTablePagination.limit);
  const goToPage = useCallback(
    (action: TGoToPageActions) => {
      setDataTablePagination((old) => {
        const currPage = Math.ceil(old.offset / old.limit);
        const lastPage = pageCount - 1;
        let newPage;
        switch (action) {
          case 'first':
            newPage = 0;
            break;
          case 'prev':
            newPage = currPage - 1;
            break;
          case 'next':
            newPage = currPage + 1;
            break;
          case 'last':
            newPage = lastPage;
            break;
        }

        if (newPage < 0) newPage = 0;
        if (newPage > lastPage) newPage = lastPage;

        return {
          ...old,
          offset: newPage * old.limit,
        };
      });
    },
    [pageCount],
  );

  const setPageLimit = useCallback((limit: number) => {
    setDataTablePagination({
      offset: 0,
      limit,
    });
  }, []);

  const startIndex = dataTablePagination.offset + 1;
  let endIndex = dataTablePagination.offset + dataTablePagination.limit;
  if (endIndex > persistedTotalCount) endIndex = persistedTotalCount;

  const goToFirstPage = useCallback(() => {
    if (!hasPreviousPage) return;
    goToPage('first');
  }, [goToPage, hasPreviousPage]);

  const goToPrevPage = useCallback(() => {
    if (!hasPreviousPage) return;
    goToPage('prev');
  }, [goToPage, hasPreviousPage]);

  const goToNextPage = useCallback(() => {
    if (!hasNextPage) return;
    goToPage('next');
  }, [goToPage, hasNextPage]);

  const goToLastPage = useCallback(() => {
    if (!hasNextPage || !pageCount) return;
    goToPage('last');
  }, [goToPage, hasNextPage, pageCount]);

  useDebouncedEffect(
    () => {
      fetchData({
        variables: {
          paging: {
            offset: dataTablePagination.offset,
            limit: dataTablePagination.limit,
          },
          filter: filter,
          sorting: sorting,
        },
      });
    },
    500,
    [sorting, filter, dataTablePagination],
    {
      shouldRun: !suppressDataFetch && hasCompletedFirstFetch.current,
      setDebouncePending: setFetchPendingDebounce,
    },
  );

  useDebouncedEffect(
    () => {
      fetchTotalCount({
        variables: {
          paging: {
            offset: dataTablePagination.offset,
            limit: dataTablePagination.limit,
          },
          filter: filter,
          sorting: sorting,
          includePageInfo: false,
          includeNodes: false,
          includeTotalCount: true,
        },
      });
    },
    500,
    [filter],
    {
      shouldRun: !suppressDataFetch && hasCompletedFirstFetch.current,
    },
  );

  useEffect(() => {
    if (hasCompletedFirstFetch.current) recordPaginationState();
  }, [dataTablePagination]);

  useEffect(() => {
    if (hasCompletedFirstFetch.current) {
      goToFirstPage();
    }
  }, [filter, sorting]);

  useEffect(() => {
    if (hasInitializedLayouts && !suppressDataFetch && !hasCompletedFirstFetch.current) {
      fetchData({
        variables: {
          paging: {
            offset: dataTablePagination.offset,
            limit: dataTablePagination.limit,
          },
          filter: filter,
          sorting: sorting,
        },
      });
    }
  }, [hasInitializedLayouts, suppressDataFetch]);

  useEffect(() => {
    const _savedPagination = checkForSavedPagination(
      tableId,
      selectedWarehouseId,
      dataTableFlags.savedPaginationTTL,
    );
    if (!!_savedPagination) setDataTablePagination(_savedPagination);

    window.onbeforeunload = () => {
      sessionStorage.removeItem(`${SAVED_PAGINATION_PREFIX}-${tableId}-${selectedWarehouseId}`);
    };

    return () => {
      window.onbeforeunload = null;
      recordPaginationState(true);
    };
  }, []);

  return {
    dataTableData: {
      data: persistedData,
      isDataTableLoading:
        !hasInitializedLayouts ||
        !hasCompletedFirstFetch.current ||
        isFetchPendingDebounce ||
        isLoadingNodes,
      hasCompletedFirstFetch: hasCompletedFirstFetch.current,
      dataLastFetched,
      refetchData,
      refetchTotalCount,
      getExportData,
    },
    pagination: {
      disablePagination,
      offset: dataTablePagination.offset,
      limit: dataTablePagination.limit,
      startIndex,
      endIndex,
      pageCount: pageCount,
      totalCount: persistedTotalCount,
      isLoadingTotalCount: isLoadingTotalCount || !fetchTotalCountCalled,
      perPageOptions,
      goToPage,
      setPageLimit,
      canPreviousPage: hasPreviousPage,
      canNextPage: hasNextPage,
      goToFirstPage,
      goToPrevPage,
      goToNextPage,
      goToLastPage,
    },
  };
};

export default useDataTableData;
