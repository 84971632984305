import SearchIcon from '@mui/icons-material/Search';
import { Grid, InputAdornment } from '@mui/material';
import { useMemo } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  usePopulateLostAndFoundLpModalQuery,
  useUpdateLostAndFoundLpStockMutation,
} from '@/graphql/defs/components/modals/__generated__/update-lost-and-found-lp-modal.generated';
import { PopulateLostAndFoundLPModal_defaultData } from '@/graphql/defs/components/modals/update-lost-and-found-lp-modal';
import { LicensePlateStatusState } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import VirtualAutocomplete from '@components/virtual-autocomplete';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useModalToggle } from '@context/modal/ModalToggleProvider';
import { useSnackbar } from '@context/snackbar';
import enumKeys from '@lib/enum-keys';
import FormValues from '@models/FormValues';
import { IUpdateLostAndFoundLPModal, ModalTypes } from '@models/modal';

export interface IUpdateLostAndFoundLPFields {
  productCode: string | null;
  productStockStatusId: string;
  blockStatus: LicensePlateStatusState;
  fulfillmentCode: string | null;
  fulfillmentLineItem: string;
}

const UpdateLostAndFoundLpModal = () => {
  const { t } = useTranslation('components');
  // const { selectedWarehouseId } = useEntityUtils();
  const { showMessage } = useSnackbar();
  const { openModal } = useModalToggle();
  const { modal, closeModal, setLoading, isPreparing, setPreparing, depBucket, updateDepBucket } =
    useModalContent<IUpdateLostAndFoundLPModal>();

  const [updateLPStock] = useUpdateLostAndFoundLpStockMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t('modal.lostAndFound.updateLP.success', {
          lpCode: modal.lostAndFoundItem?.licensePlateCode,
        }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({ type: 'error', message: error.message });
    },
  });

  const defaultValues: FormValues<IUpdateLostAndFoundLPFields> = {
    productCode: !!modal?.previousFormState?.productCode
      ? modal?.previousFormState?.productCode
      : modal.lostAndFoundItem?.productCode || '',
    productStockStatusId: !!modal?.previousFormState?.productStockStatusId
      ? modal?.previousFormState?.productStockStatusId
      : modal.lostAndFoundItem?.stockStatusId || '',
    blockStatus: !!modal?.previousFormState?.blockStatus
      ? modal?.previousFormState?.blockStatus
      : modal.lostAndFoundItem?.licensePlateStatus || LicensePlateStatusState.Active,
    fulfillmentCode: !!modal?.previousFormState?.fulfillmentCode
      ? modal?.previousFormState?.fulfillmentCode
      : modal.lostAndFoundItem?.stockFulfillmentCode || '',
    fulfillmentLineItem: '',
  };

  const formMethods = useForm<FormValues<IUpdateLostAndFoundLPFields>>({
    defaultValues,
  });
  const { control, handleSubmit, getValues, setValue } = formMethods;

  const { data: populateLostAndFoundData } = usePopulateLostAndFoundLpModalQuery({
    onCompleted: ({ getProductList: { products } }) => {
      if (!!depBucket.productId) {
        const newProduct = products.find((p) => p.id === depBucket.productId);
        if (newProduct?.code) setValue('productCode', newProduct.code);
      }
      setPreparing(false);
    },
    onError: (error) => {
      showMessage({ type: 'error', message: error.message });
      setPreparing(false);
    },
  });
  const {
    getProductList: { products },
    getStockStatusList: { stockStatuses },
    getFulfillments: { fulfillments },
  } = useMemo(
    () => populateLostAndFoundData || PopulateLostAndFoundLPModal_defaultData,
    [populateLostAndFoundData],
  );

  const selectedProductCode = useWatch({
    control,
    name: 'productCode',
  });
  const [productDescription, productUoM] = useMemo(() => {
    let description = '';
    let uom = '';
    if (selectedProductCode && products.length > 0) {
      const selectedProduct = products.find((p) => p.code === selectedProductCode);
      description = selectedProduct?.description || '';
      uom = selectedProduct?.unitOfMeasures?.nodes
        ?.filter((uom) => uom.isBaseUom)
        ?.reduce((acc, uom) => uom.code, '');
    }
    return [description, uom];
  }, [products, selectedProductCode]);

  const onSubmit = (fields: IUpdateLostAndFoundLPFields) => {
    void updateLPStock({
      variables: {
        lpStock: {
          fulfillmentCode: fields?.fulfillmentCode,
          licensePlateId: modal?.lostAndFoundItem?.licensePlateId,
          licensePlateStatus: fields?.blockStatus,
          productCode: fields?.productCode,
          lotId: modal?.lostAndFoundItem?.lotId,
          quantity: '1',
          stockStatusId: fields?.productStockStatusId,
          // warehouseId: selectedWarehouseId,
        },
      },
    });
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Controller
                name="productCode"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.productCode'),
                  }),
                }}
                render={({ field, fieldState }) => (
                  <VirtualAutocomplete
                    freeSolo={false}
                    multiple={false}
                    autoHighlight={true}
                    value={field.value}
                    options={['New Product', ...products.map(({ code }) => code)]}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(event, item, reason) => {
                      if (reason === 'selectOption' || reason === 'clear') {
                        if (item !== 'New Product') {
                          field.onChange(item);
                        } else {
                          updateDepBucket('pendingDepBucketKey', 'productId');
                          openModal(
                            {
                              type: ModalTypes.createProduct,
                            },
                            {
                              beforeClose: () => {
                                openModal({
                                  type: ModalTypes.updateLostAndFoundLP,
                                  lostAndFoundItem: modal.lostAndFoundItem,
                                  previousFormState: getValues(),
                                });
                              },
                            },
                          );
                        }
                      }
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          {...field}
                          dataTestId="productCode"
                          autoFocus
                          required
                          name={field.name}
                          label={t('common.productCode')}
                          error={!!fieldState?.error}
                          helperText={fieldState?.error?.message}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                disabled
                id="productDescription"
                label={t('common.description')}
                value={productDescription}
                dataTestId="productDescription"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                disabled
                id="quantity"
                label={t('common.qty')}
                value={1}
                dataTestId="quantity"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                required
                disabled
                id="uom"
                label={t('common.uom')}
                value={productUoM}
                dataTestId="uom"
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="productStockStatusId"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.stockStatus'),
                  }),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    id="productStockStatus"
                    label={t('common.stockStatus')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    select
                    SelectProps={{ native: true }}
                    dataTestId="productStockStatus"
                  >
                    <option disabled value="" />
                    {stockStatuses.map((stockStatus) => (
                      <option key={`stockStatus-${stockStatus.id}`} value={stockStatus.id}>
                        {stockStatus.label}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="blockStatus"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.blockStatus'),
                  }),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    id="blockStatus"
                    label={t('common.blockStatus')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    select
                    SelectProps={{ native: true }}
                    dataTestId="blockStatus"
                  >
                    <option disabled value="" />
                    {enumKeys(LicensePlateStatusState).map((blockStatus) => (
                      <option
                        key={`blockStatus-${LicensePlateStatusState[blockStatus]}`}
                        value={LicensePlateStatusState[blockStatus]}
                      >
                        {LicensePlateStatusState[blockStatus] === LicensePlateStatusState.Active
                          ? t(`common.active`)
                          : t('common.blocked')}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="fulfillmentCode"
                control={control}
                render={({ field, fieldState }) => (
                  <VirtualAutocomplete
                    freeSolo={false}
                    multiple={false}
                    autoHighlight={true}
                    value={field.value}
                    getOptionLabel={(option) => option}
                    options={fulfillments.map(({ erpCode }) => erpCode)}
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(event, item, reason) => {
                      if (reason === 'selectOption' || reason === 'clear') {
                        field.onChange(item);
                      }
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          {...field}
                          dataTestId="fulfillmentNumber"
                          label={t('common.fulfillmentNumber')}
                          error={!!fieldState?.error}
                          helperText={fieldState?.error?.message}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} />
          </Grid>
        </ModalContent>
        <ModalActions>
          <ModalButton
            onClick={() => closeModal()}
            variant="outlined"
            color="primary"
            actionType="cancel"
          >
            {t('common.cancel')}
          </ModalButton>
          <ModalButton variant="contained" color="primary" actionType="submit">
            {t('common.save')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default UpdateLostAndFoundLpModal;
