/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateManyBinStatusMappingsMutationVariables = Types.Exact<{
  input: Types.BinStatusMappingInputDto;
}>;


export type UpdateManyBinStatusMappingsMutation = { __typename?: 'Mutation', updateManyBinStatusMappings: string };

export type BulkEditBinMutationVariables = Types.Exact<{
  binId: Types.Scalars['ID']['input'];
  lastCountDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  binStorageTypeId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  updateBoth: Types.Scalars['Boolean']['input'];
  updateLastCountDate: Types.Scalars['Boolean']['input'];
  updateBinStorageType: Types.Scalars['Boolean']['input'];
}>;


export type BulkEditBinMutation = { __typename?: 'Mutation', updateLastCountAndBinStorageType: { __typename?: 'Bin', id: string, lastCount?: string | null }, updateLastCount: { __typename?: 'Bin', id: string, lastCount?: string | null }, updateBinStorageType: { __typename?: 'Bin', id: string, lastCount?: string | null } };


export const UpdateManyBinStatusMappingsDocument = gql`
    mutation UpdateManyBinStatusMappings($input: BinStatusMappingInputDto!) {
  updateManyBinStatusMappings(input: $input)
}
    `;
export type UpdateManyBinStatusMappingsMutationFn = Apollo.MutationFunction<UpdateManyBinStatusMappingsMutation, UpdateManyBinStatusMappingsMutationVariables>;

/**
 * __useUpdateManyBinStatusMappingsMutation__
 *
 * To run a mutation, you first call `useUpdateManyBinStatusMappingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManyBinStatusMappingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateManyBinStatusMappingsMutation, { data, loading, error }] = useUpdateManyBinStatusMappingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateManyBinStatusMappingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateManyBinStatusMappingsMutation, UpdateManyBinStatusMappingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateManyBinStatusMappingsMutation, UpdateManyBinStatusMappingsMutationVariables>(UpdateManyBinStatusMappingsDocument, options);
      }
export type UpdateManyBinStatusMappingsMutationHookResult = ReturnType<typeof useUpdateManyBinStatusMappingsMutation>;
export type UpdateManyBinStatusMappingsMutationResult = Apollo.MutationResult<UpdateManyBinStatusMappingsMutation>;
export type UpdateManyBinStatusMappingsMutationOptions = Apollo.BaseMutationOptions<UpdateManyBinStatusMappingsMutation, UpdateManyBinStatusMappingsMutationVariables>;
export const BulkEditBinDocument = gql`
    mutation BulkEditBin($binId: ID!, $lastCountDate: DateTime, $binStorageTypeId: ID, $updateBoth: Boolean!, $updateLastCountDate: Boolean!, $updateBinStorageType: Boolean!) {
  updateLastCountAndBinStorageType: updateOneBin(
    input: {id: $binId, update: {lastCount: $lastCountDate, binStorageTypeId: $binStorageTypeId}}
  ) @include(if: $updateBoth) {
    id
    lastCount
  }
  updateLastCount: updateOneBin(
    input: {id: $binId, update: {lastCount: $lastCountDate}}
  ) @include(if: $updateLastCountDate) {
    id
    lastCount
  }
  updateBinStorageType: updateOneBin(
    input: {id: $binId, update: {binStorageTypeId: $binStorageTypeId}}
  ) @include(if: $updateBinStorageType) {
    id
    lastCount
  }
}
    `;
export type BulkEditBinMutationFn = Apollo.MutationFunction<BulkEditBinMutation, BulkEditBinMutationVariables>;

/**
 * __useBulkEditBinMutation__
 *
 * To run a mutation, you first call `useBulkEditBinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBulkEditBinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bulkEditBinMutation, { data, loading, error }] = useBulkEditBinMutation({
 *   variables: {
 *      binId: // value for 'binId'
 *      lastCountDate: // value for 'lastCountDate'
 *      binStorageTypeId: // value for 'binStorageTypeId'
 *      updateBoth: // value for 'updateBoth'
 *      updateLastCountDate: // value for 'updateLastCountDate'
 *      updateBinStorageType: // value for 'updateBinStorageType'
 *   },
 * });
 */
export function useBulkEditBinMutation(baseOptions?: Apollo.MutationHookOptions<BulkEditBinMutation, BulkEditBinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BulkEditBinMutation, BulkEditBinMutationVariables>(BulkEditBinDocument, options);
      }
export type BulkEditBinMutationHookResult = ReturnType<typeof useBulkEditBinMutation>;
export type BulkEditBinMutationResult = Apollo.MutationResult<BulkEditBinMutation>;
export type BulkEditBinMutationOptions = Apollo.BaseMutationOptions<BulkEditBinMutation, BulkEditBinMutationVariables>;