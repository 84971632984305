import { gql } from '@apollo/client';

import { GetDeliveryLpInventoryQuery } from '@/graphql/defs/shared-queries/__generated__/get-delivery-lp-inventory.generated';

// Get a Delivery's Inventory, with both loose product and product(s) associated to License Plate(s)
gql`
  fragment DeliveryLPInventory on InventoryBaseQueryShape {
    areaCode
    areaId
    binCode
    binId
    stockStatus
    stockStatusTypeId
    unitOfMeasure
    unitOfMeasureId
    warehouseCode
    warehouseId
    quantity
    availableQuantity
    productId
    productCode
    productDescription
    lotId
    lotCode
    licensePlateId
    licensePlateCode
    licensePlateStatus
    licensePlateDescription
    openTaskQuantity
  }
  query GetDeliveryLPInventory(
    $paging: OffsetPaging
    $filter: InventoryBaseQueryShapeFilter
    $sorting: [InventoryBaseQueryShapeSort!]
  ) {
    deliveryItems: baseInventoryAll(paging: $paging, filter: $filter, sorting: $sorting) {
      nodes {
        ...DeliveryLPInventory
      }
      totalCount
    }
  }
`;

export const GetDeliveryLPInventory_defaultData: GetDeliveryLpInventoryQuery = {
  __typename: 'Query',
  deliveryItems: {
    __typename: 'InventoryBaseQueryShapeOffsetConnection',
    nodes: [],
    totalCount: 0,
  },
};
