import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ModalForm from '../modal-form';

import { IUserGroup } from './create-user-group';

import { useUserGroupMappingsQuery } from '@/graphql/defs/components/modals/__generated__/user-group-users-modal.generated';
import { useListUsersLazyQuery } from '@/graphql/defs/list/__generated__/list-users.generated';
import EditableTable, { EditableTableColumn } from '@components/editable-table/EditableTable';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useModalToggle } from '@context/modal/ModalToggleProvider';
import FormValues from '@models/FormValues';
import { IUserGroupUsersModal, ModalTypes } from '@models/modal';

const UserGroupUsersModal = () => {
  const modalContext = useModalContent<IUserGroupUsersModal>();
  const { modal } = modalContext;

  const formReturn = useForm<FormValues<IUserGroup>>({
    defaultValues: {
      name: modal.userGroupName,
      warehouses: [],
      roles: [],
    },
  });

  const { setPreparing, isPreparing } = modalContext;
  const { columns, data } = useEditableUserGroupUsersTable(modal.userGroupId, setPreparing);

  return isPreparing ? (
    <></>
  ) : (
    <ModalForm formReturn={formReturn}>
      <EditableTable
        label={<Label modal={modal} />}
        sx={{ minWidth: '700px', margin: '10px 40px 45px 40px' }}
        columns={columns}
        data={data}
      />
    </ModalForm>
  );
};

const Label = ({ modal }) => {
  const { t } = useTranslation('components');
  const { openModal } = useModalToggle();

  return (
    <Box sx={{ flex: 1, display: 'flex' }}>
      {t('common.users')}
      <Button
        onClick={() => {
          openModal(
            {
              type: ModalTypes.userGroupUsersEdit,
              userGroupId: modal.userGroupId,
              userGroupName: modal.userGroupName,
            },
            {
              beforeClose: () => {
                openModal({
                  type: modal.type,
                  userGroupId: modal.userGroupId,
                  userGroupName: modal.userGroupName,
                });
              },
            },
          );
        }}
        variant="contained"
        sx={{ marginLeft: 'auto' }}
      >
        {t('common.edit')}
      </Button>
    </Box>
  );
};

const useEditableUserGroupUsersTable = (userGroupId, setPreparing) => {
  const { t } = useTranslation('components');
  const [users, setUsers] = useState([]);
  const [getUsers] = useListUsersLazyQuery();
  useUserGroupMappingsQuery({
    variables: {
      filter: {
        userGroupId: {
          eq: userGroupId,
        },
      },
    },
    onCompleted: async ({ userGroupMappings: { nodes } }) => {
      if (nodes.length) {
        const {
          data: {
            listUsers: { users },
          },
        } = await getUsers({
          variables: {
            filter: {
              id: {
                in: nodes.map(({ userId }) => userId),
              },
            },
          },
        });
        setUsers(users);
      }
      setPreparing(false);
    },
  });

  // Columns for the EditableTable component
  const columns: EditableTableColumn[] = users.length
    ? [
        {
          label: t('common.user'),
          width: '30%',
        },
        {
          label: t('common.status'),
          width: '70%',
        },
      ]
    : [{ label: t('common.user'), width: '100%' }];

  const data = users.length
    ? users.map((user) => ({
        dataTestId: `user-${user.firstName}-row`,
        item: user,
        cells: [
          {
            dataTestId: `user-${user.firstName}-cell-name`,
            value: `${user.firstName} ${user.lastName}`,
          },
          {
            dataTestId: `user-${user.firstName}-cell-status`,
            value: user.status,
          },
        ],
      }))
    : [
        {
          dataTestId: `no-user-table`,
          item: { name: 'no', id: 'user' },
          cells: [
            {
              dataTestId: `user-cell-name`,
              value: t(`userGroupUserActions.noUsersInGroup`),
            },
          ],
        },
      ];

  return {
    columns,
    data,
  };
};

export default UserGroupUsersModal;
