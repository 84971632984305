/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetSalesOrderDeliveriesQueryVariables = Types.Exact<{
  salesOrder: Types.Scalars['String']['input'];
}>;


export type GetSalesOrderDeliveriesQuery = { __typename?: 'Query', getDeliveries: { __typename?: 'DeliveryOffsetConnection', deliveries: Array<{ __typename?: 'Delivery', id: string, erpCode: string, erpSalesOrder?: string | null, type: Types.DeliveryType, dueDate?: string | null, deliveryStatus?: Types.DeliveryCompletionStatus | null }> } };


export const GetSalesOrderDeliveriesDocument = gql`
    query GetSalesOrderDeliveries($salesOrder: String!) {
  getDeliveries: deliveries(
    filter: {erpSalesOrder: {eq: $salesOrder}}
    sorting: [{field: erpCode, direction: ASC}]
  ) {
    deliveries: nodes {
      id
      erpCode
      erpSalesOrder
      type
      dueDate
      deliveryStatus
    }
  }
}
    `;

/**
 * __useGetSalesOrderDeliveriesQuery__
 *
 * To run a query within a React component, call `useGetSalesOrderDeliveriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSalesOrderDeliveriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSalesOrderDeliveriesQuery({
 *   variables: {
 *      salesOrder: // value for 'salesOrder'
 *   },
 * });
 */
export function useGetSalesOrderDeliveriesQuery(baseOptions: Apollo.QueryHookOptions<GetSalesOrderDeliveriesQuery, GetSalesOrderDeliveriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesOrderDeliveriesQuery, GetSalesOrderDeliveriesQueryVariables>(GetSalesOrderDeliveriesDocument, options);
      }
export function useGetSalesOrderDeliveriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesOrderDeliveriesQuery, GetSalesOrderDeliveriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesOrderDeliveriesQuery, GetSalesOrderDeliveriesQueryVariables>(GetSalesOrderDeliveriesDocument, options);
        }
export type GetSalesOrderDeliveriesQueryHookResult = ReturnType<typeof useGetSalesOrderDeliveriesQuery>;
export type GetSalesOrderDeliveriesLazyQueryHookResult = ReturnType<typeof useGetSalesOrderDeliveriesLazyQuery>;
export type GetSalesOrderDeliveriesQueryResult = Apollo.QueryResult<GetSalesOrderDeliveriesQuery, GetSalesOrderDeliveriesQueryVariables>;