import { ColumnDef } from '@tanstack/react-table';

import { DefaultDataType } from '@components/data-table/model/data-table';

function filterDataTableColumnDefs<DataType extends DefaultDataType>(removeColumns?: string[]) {
  return (column: ColumnDef<DataType>) => {
    if (!column) return false;
    if (!removeColumns || removeColumns.length === 0) return true;

    // @ts-ignore - Need to figure out how to type columns correctly so accessorKey is a valid property to TS.
    const columnIdentifier = !!column.id ? column.id : column.accessorKey;
    return !removeColumns.includes(columnIdentifier);
  };
}

export default filterDataTableColumnDefs;
