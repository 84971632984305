import { IconButton as MUIIconButton, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

type TVariants = 'dataGrid' | 'dataGridFooter' | 'formEdit';
interface IStyledIconButton {
  styledVariant?: TVariants;
  disabled?: boolean;
}

const styledVariants = (theme: Theme, disabled: boolean): Record<TVariants, any> => ({
  dataGrid: {
    '&': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 35,
      height: 35,
      '& svg': {
        color: theme.palette.secondary.main,
        fontSize: 24,
      },
      zIndex: 3,
    },
  },
  dataGridFooter: {
    '&': {
      width: 35,
      height: 35,
      cursor: !disabled ? 'pointer' : 'default',
      '& svg': {
        color: !disabled ? theme.palette.primary.main : theme.palette.slateGrey.light,
        fontSize: 24,
      },
    },
  },
  formEdit: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(4),
    backgroundColor: 'transparent',
    '& svg': {
      color: theme.palette.secondary.main,
      fontSize: 28,
    },
  },
});

const IconButton = styled(MUIIconButton, {
  shouldForwardProp: (prop) => prop !== 'styledVariant',
})<IStyledIconButton>(
  ({ theme, disabled, styledVariant }) => styledVariants(theme, disabled)[styledVariant] || {},
);

export default IconButton;
