/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UseRulesetFormFragment = { __typename?: 'SlottingRuleset', id: string, createdAt: string, updatedAt?: string | null, updatedByEmail?: string | null, updatedById?: string | null, deletedAt?: string | null, name: string, privateRuleset: boolean, warehouseId: string, maxMovements?: number | null, forecasting: Types.Forecasting, runCount: number, createdByUserId: string, lastRun?: string | null, workers: Array<{ __typename?: 'SlottingWorker', id: string, quantity: number }>, equipment: Array<{ __typename?: 'SlottingEquipment', id: string, quantity: number }>, abcAnalysis?: { __typename?: 'AbcAnalysis', criteria: string, indicatorPercentages: Array<number> } | null, weights: { __typename?: 'SlottingWeights', pickDensity: number, pickEfficiency: number, putawayDensity: number, putawayEfficiency: number, avoidCongestion: number, favorGroundLevel: number }, restrictions: { __typename?: 'SlottingRestrictions', fifoPicking: boolean, fefoFifoPicking: boolean, heaviestToLightestPicks: boolean, preventMixedProductsInBins: boolean, preventMixedLotsInBins: boolean, preventMixedExpirationDatesInBins: boolean, enforceSingleOrderPicks: boolean, preventSimilarProductsInAdjacentBins: boolean }, zones: Array<{ __typename?: 'SlottingZone', zoneId: string, fixedBins: boolean, useWarehouseDefault: boolean, maxFixedBinsPerProduct: number, restrictions: { __typename?: 'SlottingRestrictions', fifoPicking: boolean, fefoFifoPicking: boolean, heaviestToLightestPicks: boolean, preventMixedProductsInBins: boolean, preventMixedLotsInBins: boolean, preventMixedExpirationDatesInBins: boolean, enforceSingleOrderPicks: boolean, preventSimilarProductsInAdjacentBins: boolean }, weights: { __typename?: 'SlottingWeights', pickDensity: number, pickEfficiency: number, putawayDensity: number, putawayEfficiency: number, avoidCongestion: number, favorGroundLevel: number } }> };

export type GetUseRulesetFormDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetUseRulesetFormDetailsQuery = { __typename?: 'Query', slottingRuleset: { __typename?: 'SlottingRuleset', id: string, createdAt: string, updatedAt?: string | null, updatedByEmail?: string | null, updatedById?: string | null, deletedAt?: string | null, name: string, privateRuleset: boolean, warehouseId: string, maxMovements?: number | null, forecasting: Types.Forecasting, runCount: number, createdByUserId: string, lastRun?: string | null, workers: Array<{ __typename?: 'SlottingWorker', id: string, quantity: number }>, equipment: Array<{ __typename?: 'SlottingEquipment', id: string, quantity: number }>, abcAnalysis?: { __typename?: 'AbcAnalysis', criteria: string, indicatorPercentages: Array<number> } | null, weights: { __typename?: 'SlottingWeights', pickDensity: number, pickEfficiency: number, putawayDensity: number, putawayEfficiency: number, avoidCongestion: number, favorGroundLevel: number }, restrictions: { __typename?: 'SlottingRestrictions', fifoPicking: boolean, fefoFifoPicking: boolean, heaviestToLightestPicks: boolean, preventMixedProductsInBins: boolean, preventMixedLotsInBins: boolean, preventMixedExpirationDatesInBins: boolean, enforceSingleOrderPicks: boolean, preventSimilarProductsInAdjacentBins: boolean }, zones: Array<{ __typename?: 'SlottingZone', zoneId: string, fixedBins: boolean, useWarehouseDefault: boolean, maxFixedBinsPerProduct: number, restrictions: { __typename?: 'SlottingRestrictions', fifoPicking: boolean, fefoFifoPicking: boolean, heaviestToLightestPicks: boolean, preventMixedProductsInBins: boolean, preventMixedLotsInBins: boolean, preventMixedExpirationDatesInBins: boolean, enforceSingleOrderPicks: boolean, preventSimilarProductsInAdjacentBins: boolean }, weights: { __typename?: 'SlottingWeights', pickDensity: number, pickEfficiency: number, putawayDensity: number, putawayEfficiency: number, avoidCongestion: number, favorGroundLevel: number } }> } };

export type CreateRulesetMutationVariables = Types.Exact<{
  input: Types.SlottingRulesetCreateOneInput;
}>;


export type CreateRulesetMutation = { __typename?: 'Mutation', ruleset: { __typename?: 'SlottingRuleset', id: string, name: string } };

export const UseRulesetFormFragmentDoc = gql`
    fragment UseRulesetForm on SlottingRuleset {
  id
  createdAt
  updatedAt
  updatedByEmail
  updatedById
  deletedAt
  name
  privateRuleset
  warehouseId
  maxMovements
  workers {
    id
    quantity
  }
  equipment {
    id
    quantity
  }
  abcAnalysis {
    criteria
    indicatorPercentages
  }
  forecasting
  weights {
    pickDensity
    pickEfficiency
    putawayDensity
    putawayEfficiency
    avoidCongestion
    favorGroundLevel
  }
  restrictions {
    fifoPicking
    fefoFifoPicking
    heaviestToLightestPicks
    preventMixedProductsInBins
    preventMixedLotsInBins
    preventMixedExpirationDatesInBins
    enforceSingleOrderPicks
    preventSimilarProductsInAdjacentBins
  }
  runCount
  zones {
    zoneId
    fixedBins
    useWarehouseDefault
    maxFixedBinsPerProduct
    restrictions {
      fifoPicking
      fefoFifoPicking
      heaviestToLightestPicks
      preventMixedProductsInBins
      preventMixedLotsInBins
      preventMixedExpirationDatesInBins
      enforceSingleOrderPicks
      preventSimilarProductsInAdjacentBins
    }
    weights {
      pickDensity
      pickEfficiency
      putawayDensity
      putawayEfficiency
      avoidCongestion
      favorGroundLevel
    }
  }
  createdByUserId
  lastRun
}
    `;
export const GetUseRulesetFormDetailsDocument = gql`
    query GetUseRulesetFormDetails($id: ID!) {
  slottingRuleset(id: $id) {
    ...UseRulesetForm
  }
}
    ${UseRulesetFormFragmentDoc}`;

/**
 * __useGetUseRulesetFormDetailsQuery__
 *
 * To run a query within a React component, call `useGetUseRulesetFormDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUseRulesetFormDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUseRulesetFormDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUseRulesetFormDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetUseRulesetFormDetailsQuery, GetUseRulesetFormDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUseRulesetFormDetailsQuery, GetUseRulesetFormDetailsQueryVariables>(GetUseRulesetFormDetailsDocument, options);
      }
export function useGetUseRulesetFormDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUseRulesetFormDetailsQuery, GetUseRulesetFormDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUseRulesetFormDetailsQuery, GetUseRulesetFormDetailsQueryVariables>(GetUseRulesetFormDetailsDocument, options);
        }
export type GetUseRulesetFormDetailsQueryHookResult = ReturnType<typeof useGetUseRulesetFormDetailsQuery>;
export type GetUseRulesetFormDetailsLazyQueryHookResult = ReturnType<typeof useGetUseRulesetFormDetailsLazyQuery>;
export type GetUseRulesetFormDetailsQueryResult = Apollo.QueryResult<GetUseRulesetFormDetailsQuery, GetUseRulesetFormDetailsQueryVariables>;
export const CreateRulesetDocument = gql`
    mutation CreateRuleset($input: SlottingRulesetCreateOneInput!) {
  ruleset: createOneSlottingRuleset(input: $input) {
    id
    name
  }
}
    `;
export type CreateRulesetMutationFn = Apollo.MutationFunction<CreateRulesetMutation, CreateRulesetMutationVariables>;

/**
 * __useCreateRulesetMutation__
 *
 * To run a mutation, you first call `useCreateRulesetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRulesetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRulesetMutation, { data, loading, error }] = useCreateRulesetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRulesetMutation(baseOptions?: Apollo.MutationHookOptions<CreateRulesetMutation, CreateRulesetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRulesetMutation, CreateRulesetMutationVariables>(CreateRulesetDocument, options);
      }
export type CreateRulesetMutationHookResult = ReturnType<typeof useCreateRulesetMutation>;
export type CreateRulesetMutationResult = Apollo.MutationResult<CreateRulesetMutation>;
export type CreateRulesetMutationOptions = Apollo.BaseMutationOptions<CreateRulesetMutation, CreateRulesetMutationVariables>;