import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useUpdateZoneMutation } from '@/graphql/defs/components/modals/__generated__/update-zone-modal.generated';
import { ZoneUpdateOneInput } from '@/graphql/types.generated';
import DataTable from '@components/data-table';
import useZoneAisleColumnsSettingsDataTable from '@components/data-table/hooks/table-props/useZoneAisleColumnsSettingsDataTable';
import useZoneAislesSettingsDataTable from '@components/data-table/hooks/table-props/useZoneAislesSettingsDataTable';
import useZoneAreasSettingsDataTable from '@components/data-table/hooks/table-props/useZoneAreasSettingsDataTable';
import useZoneBinsAddDataTable from '@components/data-table/hooks/table-props/useZoneBinsAddDataTable';
import useZoneBinsSettingsDataTable, {
  ZoneEntityTableVersion,
} from '@components/data-table/hooks/table-props/useZoneBinsSettingsDataTable';
import { ModalContent } from '@components/modal/modal-content';
import { ZoneEntities } from '@components/zones/zone-entity-mapping-action-menu';
import { TCloseModal, useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { IUpdateZoneEntityMappingModal } from '@models/modal';

const UpdateZoneEntityMappingModal = () => {
  const { modal, closeModal, setLoading } = useModalContent<IUpdateZoneEntityMappingModal>();

  const { zoneBinsSettingsDataTableProps, selectedBins } = useZoneBinsSettingsDataTable({
    zoneId: modal.zoneId,
    version: modal.version,
  });
  const {
    zoneBinsSettingsDataTableProps: zoneBinsAddDataTableProps,
    selectedBins: selectedBinsAdd,
  } = useZoneBinsAddDataTable();
  const { zoneAislesSettingsDataTableProps, selectedAisles } = useZoneAislesSettingsDataTable({
    zoneId: modal.zoneId,
    version: modal.version,
  });
  const { zoneAreasSettingsDataTableProps, selectedAreas } = useZoneAreasSettingsDataTable({
    zoneId: modal.zoneId,
    version: modal.version,
  });
  const { zoneAisleColumnsSettingsDataTableProps, selectedAisleColumns } =
    useZoneAisleColumnsSettingsDataTable({
      zoneId: modal.zoneId,
      version: modal.version,
    });

  const renderTable = () => {
    const { zoneId, version, entity } = modal;
    const sharedArgs = { zoneId, entity, version, closeModal, setLoading };
    switch (entity) {
      case 'Aisle':
        return (
          <DataTable
            {...zoneAislesSettingsDataTableProps}
            tableActions={
              <AddRemoveMapping
                {...sharedArgs}
                ids={selectedAisles.map(({ aisleId }) => aisleId)}
              />
            }
          />
        );
      case 'Area':
        return (
          <DataTable
            {...zoneAreasSettingsDataTableProps}
            tableActions={
              <AddRemoveMapping {...sharedArgs} ids={selectedAreas.map(({ areaId }) => areaId)} />
            }
          />
        );
      case 'Bin':
        if (modal.version === ZoneEntityTableVersion.Add) {
          return (
            <DataTable
              {...zoneBinsAddDataTableProps}
              tableActions={
                <AddRemoveMapping {...sharedArgs} ids={selectedBinsAdd.map(({ binId }) => binId)} />
              }
            />
          );
        } else {
          return (
            <DataTable
              {...zoneBinsSettingsDataTableProps}
              tableActions={
                <AddRemoveMapping {...sharedArgs} ids={selectedBins.map(({ binId }) => binId)} />
              }
            />
          );
        }
      case 'AisleColumn':
        return (
          <DataTable
            {...zoneAisleColumnsSettingsDataTableProps}
            tableActions={
              <AddRemoveMapping
                {...sharedArgs}
                ids={selectedAisleColumns.map(({ aisleColumnId }) => aisleColumnId)}
              />
            }
          />
        );
    }
  };

  return (
    <>
      <ModalContent sx={{ width: '90vw' }}>{renderTable()}</ModalContent>
    </>
  );
};

const buildUpdateObj = ({ entity, version, ids }) => {
  const temp = {} as { add?: string[]; remove?: string[] };
  if (version === ZoneEntityTableVersion.Add) {
    temp.add = ids;
    temp.remove = [];
  } else {
    temp.remove = ids;
    temp.add = [];
  }
  switch (entity) {
    case 'Area':
      return { areaIds: temp };
    case 'Bin':
      return { binIds: temp };
    case 'Aisle':
      return { aisleIds: temp };
    case 'AisleColumn':
      return { aisleColumnIds: temp };
  }
};

const AddRemoveMapping = ({
  zoneId,
  version,
  entity,
  ids,
  closeModal,
  setLoading,
}: {
  zoneId: string;
  version: ZoneEntityTableVersion;
  entity: ZoneEntities;
  ids: string[];
  closeModal: TCloseModal;
  setLoading: (isLoading: boolean) => void;
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'zones.mappings' });
  const { t: tT } = useTranslation('components', { keyPrefix: 'dataTable' });
  const { showMessage } = useSnackbar();
  const [updateZone] = useUpdateZoneMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t(`${entity}.${version === ZoneEntityTableVersion.Add ? 'add' : 'remove'}Success`),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: tT('errorFetchData', { errorMessage: error.message }),
      });
      setLoading(false);
    },
  });

  const onSubmit = () => {
    const input = {
      id: zoneId,
      update: buildUpdateObj({
        version,
        entity,
        ids,
      }),
    } as ZoneUpdateOneInput;
    setLoading(true);
    updateZone({
      variables: {
        input,
      },
    });
  };

  return (
    <Button disabled={ids.length === 0} variant="contained" onClick={onSubmit}>
      {version === ZoneEntityTableVersion.Add ? t(`${entity}.add`) : t(`${entity}.remove`)}
    </Button>
  );
};

export default UpdateZoneEntityMappingModal;
