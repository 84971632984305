/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompletePickTaskMutationVariables = Types.Exact<{
  input: Types.CompletePickTaskInputDto;
}>;


export type CompletePickTaskMutation = { __typename?: 'Mutation', completePickTask: { __typename?: 'Task', code: string } };

export type PopulateCompletePickTaskModalQueryVariables = Types.Exact<{
  productId: Types.Scalars['ID']['input'];
  taskId: Types.Scalars['ID']['input'];
}>;


export type PopulateCompletePickTaskModalQuery = { __typename?: 'Query', product: { __typename?: 'Product', id: string, description?: string | null }, binSources: Array<{ __typename?: 'BinForPickTask', binCode?: string | null, binId?: string | null, binLevel?: string | null, lotCode?: string | null, lotExpiration?: string | null, lotId?: string | null, lotProductionDate?: string | null, quantity?: number | null }>, licensePlateSources: Array<{ __typename?: 'LicensePlateForPickTask', binCode?: string | null, binId?: string | null, licensePlateCode?: string | null, licensePlateId?: string | null, lotCode?: string | null, lotExpirationDate?: string | null, lotId?: string | null, lotProductionDate?: string | null, quantity?: number | null }> };


export const CompletePickTaskDocument = gql`
    mutation CompletePickTask($input: CompletePickTaskInputDto!) {
  completePickTask: completeOnePickTask(input: $input) {
    code
  }
}
    `;
export type CompletePickTaskMutationFn = Apollo.MutationFunction<CompletePickTaskMutation, CompletePickTaskMutationVariables>;

/**
 * __useCompletePickTaskMutation__
 *
 * To run a mutation, you first call `useCompletePickTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePickTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePickTaskMutation, { data, loading, error }] = useCompletePickTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompletePickTaskMutation(baseOptions?: Apollo.MutationHookOptions<CompletePickTaskMutation, CompletePickTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompletePickTaskMutation, CompletePickTaskMutationVariables>(CompletePickTaskDocument, options);
      }
export type CompletePickTaskMutationHookResult = ReturnType<typeof useCompletePickTaskMutation>;
export type CompletePickTaskMutationResult = Apollo.MutationResult<CompletePickTaskMutation>;
export type CompletePickTaskMutationOptions = Apollo.BaseMutationOptions<CompletePickTaskMutation, CompletePickTaskMutationVariables>;
export const PopulateCompletePickTaskModalDocument = gql`
    query PopulateCompletePickTaskModal($productId: ID!, $taskId: ID!) {
  product(id: $productId) {
    id
    description
  }
  binSources: binsForPickTask(taskId: $taskId) {
    binCode
    binId
    binLevel
    lotCode
    lotExpiration
    lotId
    lotProductionDate
    quantity
  }
  licensePlateSources: licensePlatesForPickTask(taskId: $taskId) {
    binCode
    binId
    licensePlateCode
    licensePlateId
    lotCode
    lotExpirationDate
    lotId
    lotProductionDate
    quantity
  }
}
    `;

/**
 * __usePopulateCompletePickTaskModalQuery__
 *
 * To run a query within a React component, call `usePopulateCompletePickTaskModalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopulateCompletePickTaskModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopulateCompletePickTaskModalQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function usePopulateCompletePickTaskModalQuery(baseOptions: Apollo.QueryHookOptions<PopulateCompletePickTaskModalQuery, PopulateCompletePickTaskModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PopulateCompletePickTaskModalQuery, PopulateCompletePickTaskModalQueryVariables>(PopulateCompletePickTaskModalDocument, options);
      }
export function usePopulateCompletePickTaskModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PopulateCompletePickTaskModalQuery, PopulateCompletePickTaskModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PopulateCompletePickTaskModalQuery, PopulateCompletePickTaskModalQueryVariables>(PopulateCompletePickTaskModalDocument, options);
        }
export type PopulateCompletePickTaskModalQueryHookResult = ReturnType<typeof usePopulateCompletePickTaskModalQuery>;
export type PopulateCompletePickTaskModalLazyQueryHookResult = ReturnType<typeof usePopulateCompletePickTaskModalLazyQuery>;
export type PopulateCompletePickTaskModalQueryResult = Apollo.QueryResult<PopulateCompletePickTaskModalQuery, PopulateCompletePickTaskModalQueryVariables>;