import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ViewBinSortFields } from '@/graphql/types.generated';
import {
  ColumnFilter,
  FilterOperators,
} from '@components/data-table/controls/filter/filter-definitions';
import useCreateBinColumns, {
  BINS_LAZY_HOOK,
  TBinsDataType,
  TBinsFieldNames,
  TBinsFilterType,
} from '@components/data-table/hooks/shared-columns/useCreateBinColumns';
import useCreateDataTableColumns from '@components/data-table/hooks/useCreateDataTableColumns';
import { validateDataTableProps } from '@components/data-table/lib/validateDataTableProps';
import { IDataTableSort } from '@components/data-table/model/data-table';
import TableIds from '@components/data-table/model/TableIds';

const useBinStorageTypeBinsDataTable = (binStorageTypeId: string) => {
  const { t: tP } = useTranslation('pages', { keyPrefix: 'bin-storage-type-details' });

  const createBinColumns = useCreateBinColumns({
    dataTestId: 'bin-storage-type-bins',
    removeColumns: [
      'binStorageTypeCode',
      'binStorageTypeDepth',
      'binStorageTypeWidth',
      'binStorageTypeHeight',
      'binStorageTypeWeightCapacity',
    ],
  });
  const columns = useCreateDataTableColumns(createBinColumns);

  const baseFilter = useMemo<ColumnFilter<TBinsFieldNames>[]>(
    () => [
      {
        columnId: ViewBinSortFields.BinStorageTypeId,
        operator: FilterOperators.eq,
        value: binStorageTypeId,
      },
    ],
    [binStorageTypeId],
  );

  const defaultSorting = useMemo<IDataTableSort<TBinsFieldNames>[]>(
    () => [
      {
        id: ViewBinSortFields.Code,
        desc: false,
      },
    ],
    [],
  );

  return {
    dataTableProps: validateDataTableProps<TBinsDataType, TBinsFilterType, TBinsFieldNames>({
      type: 'query',
      tableId: TableIds.BinStorageTypeBins,
      tableHeader: tP('bins'),
      columns,
      queryHook: BINS_LAZY_HOOK,
      baseFilter,
      defaultSorting,
    }),
  };
};

export default useBinStorageTypeBinsDataTable;
