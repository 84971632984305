import { TextField, styled } from '@mui/material';
import { Controller } from 'react-hook-form';

export const QuantityInput = ({ control, name, quantity = 0 }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Input
          variant="standard"
          color="secondary"
          InputProps={{ inputProps: { min: 0, max: 10000 } }}
          type="number"
          id="ruleset-quantity"
          {...field}
          value={quantity}
          onInput={(e) => {
            const {
              nativeEvent: { data },
              target: { value },
            } = e as unknown as { nativeEvent: { data: string }; target: { value: string } };
            if (['+', '-', '.'].includes(data)) {
              e.stopPropagation();
              e.preventDefault();
            }
            (e.target as unknown as { value: string }).value = value.replace(/^0+/, '');
          }}
          onChange={({ target: { value } }) => {
            if (/^\d+$/.test(value) || !value) {
              field.onChange(Math.max(Number(value), 0));
            }
          }}
          label={null}
          ref={null}
          error={!!fieldState?.error}
          helperText={fieldState?.error?.message}
        />
      )}
    />
  );
};

const Input = styled(TextField)(
  ({
    theme: {
      palette: { secondary },
    },
  }) => ({
    width: 60,
    borderBottom: `1px solid ${secondary.main}`,
    '& input': {
      textAlign: 'center',
      fontWeight: 500,
      color: secondary.main,
      // borderBottom: `1px solid ${secondary.main}`,
      border: 'none',
    },
    '& ::hover, & ::focus': {
      borderBottom: `1px solid ${secondary.main}`,
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
  }),
);
