import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeliveryCompletionStatus } from '@/graphql/types.generated';
import DataTable from '@components/data-table';
import useOutboundFulfillmentInventoryDataTable from '@components/data-table/hooks/table-props/useOutboundFulfillmentInventoryDataTable';
import useOutboundFulfillmentItemsDataTable from '@components/data-table/hooks/table-props/useOutboundFulfillmentItemsDataTable';
import { DataTableVariants } from '@components/data-table/model/data-table';
import FulfillmentItemsActionMenu from '@components/deliveries/fulfillment-items-action-menu';
import { IDropdownMenuItem } from '@components/dropdown-menu';
import CollapseLoading from '@components/loading-indicator/CollapseLoading';
import useMounted from '@hooks/useMounted';
import { TRefetchDataTable } from '@models/modal';
import { OutboundDetailsWithProgress } from '@pages/deliveries/outbound-fulfillments/FulfillmentDetailsActions';

export enum OutboundDeliveryItemsView {
  FulfillmentItem = 'fulfillmentItemView',
  Inventory = 'inventoryView',
}

interface IOutboundFulfillmentItemsInvDataTables {
  fulfillmentId: string;
  fulfillmentBinCode: string;
  fulfillmentDetails: OutboundDetailsWithProgress;
  refetchDataTrigger: boolean;
  triggerRefetchAll: TRefetchDataTable;
  hasLoadedInitialDetails: boolean;
}
const OutboundFulfillmentItemsInvDataTables = ({
  fulfillmentId,
  fulfillmentBinCode,
  fulfillmentDetails,
  refetchDataTrigger,
  triggerRefetchAll,
  hasLoadedInitialDetails,
}: IOutboundFulfillmentItemsInvDataTables) => {
  const { t } = useTranslation('pages');
  const mounted = useMounted();

  const [selectedView, setSelectedView] = useState<OutboundDeliveryItemsView>(
    OutboundDeliveryItemsView.FulfillmentItem,
  );
  const fulfillmentItemViews: IDropdownMenuItem<OutboundDeliveryItemsView>[] = [
    {
      value: OutboundDeliveryItemsView.FulfillmentItem,
      component: t(`deliveries.fulfillmentItems.${OutboundDeliveryItemsView.FulfillmentItem}`),
      onSelect: () => {
        setSelectedView(OutboundDeliveryItemsView.FulfillmentItem);
      },
      selected: false,
    },
    {
      value: OutboundDeliveryItemsView.Inventory,
      component: t(`deliveries.fulfillmentItems.${OutboundDeliveryItemsView.Inventory}`),
      onSelect: () => {
        setSelectedView(OutboundDeliveryItemsView.Inventory);
      },
      selected: false,
    },
  ];

  const { triggerItemsDataTableRefetch, fulfillmentItemsDataTableProps } =
    useOutboundFulfillmentItemsDataTable(fulfillmentId, fulfillmentItemViews);
  const { triggerInventoryDataTableRefetch, fulfillmentInventoryDataTableProps } =
    useOutboundFulfillmentInventoryDataTable(fulfillmentBinCode, fulfillmentItemViews);

  useEffect(() => {
    if (mounted) {
      triggerItemsDataTableRefetch();
      triggerInventoryDataTableRefetch();
    }
  }, [refetchDataTrigger]);

  return !hasLoadedInitialDetails ? (
    <CollapseLoading />
  ) : (
    <>
      {selectedView === OutboundDeliveryItemsView.FulfillmentItem ? (
        <DataTable
          {...fulfillmentItemsDataTableProps}
          variant={DataTableVariants.Collapsible}
          tableActions={
            fulfillmentDetails.loadOrUnloadStatus !== DeliveryCompletionStatus.Complete && (
              <FulfillmentItemsActionMenu
                deliveryDetails={fulfillmentDetails}
                hasDoorAssigned={!!fulfillmentDetails.door?.id}
                refetchData={triggerRefetchAll}
              />
            )
          }
        />
      ) : null}

      {selectedView === OutboundDeliveryItemsView.Inventory ? (
        <DataTable
          {...fulfillmentInventoryDataTableProps}
          variant={DataTableVariants.Collapsible}
        />
      ) : null}
    </>
  );
};

export default OutboundFulfillmentItemsInvDataTables;
