import { DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ModalContent = styled(DialogContent)({
  padding: '15px 24px 15px 24px',
  minWidth: '600px',
  maxWidth: '100%',
  width: '100%',
  overflowY: 'hidden',
});
