import { useMemo } from 'react';

import DataTableDataBased from '@components/data-table/DataBasedDataTable';
import { SelectionType } from '@components/data-table/hooks/useDataTableSelection';
import {
  DataTableVariants,
  DefaultDataType,
  DefaultFilterType,
  DefaultFieldNames,
  IDataTableProps,
  IOptionalDataTableQueryBasedProps,
  IOptionalDataTableDataBasedProps,
  IDataTableQueryBasedProps,
  IDataTableDataBasedProps,
} from '@components/data-table/model/data-table';
import DataTableQueryBased from '@components/data-table/QueryBasedDataTable';

function DataTable<
  DataType extends DefaultDataType = DefaultDataType,
  FilterType extends DefaultFilterType = DefaultFilterType,
  FieldNames extends DefaultFieldNames = DefaultFieldNames,
>(props: IDataTableProps<DataType, FilterType, FieldNames>) {
  // We de-generize props because having generics after the initial Component Definition doesn't provide any benefit.
  // Plus, it makes managing types further down the line a lot more difficult.
  const propsUntyped = props as IDataTableProps;
  const memoizedEmptyArray = useMemo(() => [], []);
  if (propsUntyped.type === 'query') {
    // Query Based Data Table
    const DEFAULT_OPTIONAL_QUERY_BASED_PROPS: IOptionalDataTableQueryBasedProps = {
      variant: DataTableVariants.Basic,
      tableHeader: null,
      tableActions: null,
      layoutDropdownAddtOptions: memoizedEmptyArray,
      layoutDropdownAddtOptionLabel: null,
      onFetchComplete: null,
      baseFilter: memoizedEmptyArray,
      linkedFilter: memoizedEmptyArray,
      defaultSorting: memoizedEmptyArray,
      disablePagination: false,
      perPageOptions: [10, 20, 50, 100],
      rowSelection: {
        enableRowSelection: SelectionType.none,
      },
      refetchTrigger: false,
      defaultCollapsed: false,
    };
    const propsWithDefaults: IDataTableQueryBasedProps = Object.assign(
      { ...DEFAULT_OPTIONAL_QUERY_BASED_PROPS },
      propsUntyped,
    );
    return <DataTableQueryBased queryBasedProps={propsWithDefaults} />;
  } else {
    // Data Based Data Table
    const DEFAULT_OPTIONAL_DATA_BASED_PROPS: IOptionalDataTableDataBasedProps = {
      variant: DataTableVariants.Basic,
      tableHeader: null,
      tableActions: null,
      rowSelection: {
        enableRowSelection: SelectionType.none,
      },
      defaultCollapsed: false,
      disableExport: false,
    };
    const propsWithDefaults: IDataTableDataBasedProps = Object.assign(
      { ...DEFAULT_OPTIONAL_DATA_BASED_PROPS },
      propsUntyped,
    );
    return <DataTableDataBased dataBasedProps={propsWithDefaults} />;
  }
}

export default DataTable;
