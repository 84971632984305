import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useCompleteOnePhysicalInventoryApprovalTaskMutation,
  useGetPiApprovalProductQuery,
} from '@/graphql/defs/components/modals/__generated__/inventory-adjustment-approval-modal.generated';
import { FullBinFragment } from '@/graphql/defs/shared-fragments/__generated__/bin.generated';
import { FullProductFragment } from '@/graphql/defs/shared-fragments/__generated__/product.generated';
import { useGetBinQuery } from '@/graphql/defs/shared-queries/__generated__/get-bin.generated';
import { ModalActions, ModalContent } from '@components/modal';
import { ModalButton } from '@components/modal/modal-button';
import { CurrenciesCC } from '@constants/currencies';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useModalToggle } from '@context/modal/ModalToggleProvider';
import { ModalTypes, IInventoryApprovalModal } from '@models/modal';

export const InventoryAdjustmentApprovalModal = () => {
  const { t } = useTranslation('components');
  const [completeApproval] = useCompleteOnePhysicalInventoryApprovalTaskMutation();

  const { openModal } = useModalToggle();
  const { modal, closeModal, isPreparing, setPreparing, setLoading } =
    useModalContent<IInventoryApprovalModal>();

  const {
    called: calledProduct,
    loading: isLoadingProduct,
    data: { product } = { product: {} as FullProductFragment },
  } = useGetPiApprovalProductQuery({ variables: { id: modal.approvalTask.productId } });

  const {
    called: calledBin,
    loading: isLoadingBin,
    data: { bin } = { bin: {} as FullBinFragment },
  } = useGetBinQuery({ variables: { id: modal.approvalTask.destinationBinId } });

  const baseUom = useMemo(() => {
    if (product) {
      return product.unitOfMeasures?.nodes?.find((uom) => uom.isBaseUom);
    }
    return;
  }, [product]);

  const onApprove = async () => {
    try {
      const {
        data: { completeOnePhysicalInventoryApprovalTask: approvalTask },
      } = await completeApproval({
        variables: {
          input: {
            physicalInventoryApproval: {
              taskId: modal.approvalTask.id,
            },
          },
        },
      });

      setLoading(false);
      openModal({
        type: ModalTypes.adjustQuantityResult,
        approvalTask,
      });
    } catch (error) {
      // TODO: deal with error
    }
    setLoading(false);
  };

  // TODO: deal with implications of pi adjustment being denied / cancelled
  const onDeny = () => {
    // do something
  };

  const onCancel = () => {
    closeModal();
  };

  useEffect(() => {
    setPreparing(!calledBin || !calledProduct || isLoadingProduct || isLoadingBin);
  }, [calledBin, calledProduct, isLoadingProduct, isLoadingBin]);

  return (
    !isPreparing && (
      <>
        <ModalContent>
          <Box sx={{ height: 150, width: 600 }}>
            <Grid container>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('common.product')}:
                </Typography>
              </Grid>
              <Grid xs={9} item>
                <Typography variant="body1" align="left">
                  <strong>{product?.code}</strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('common.description')}:
                </Typography>
              </Grid>
              <Grid xs={9} item>
                <Typography variant="body1" align="left">
                  {product?.description}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('common.area')}:
                </Typography>
              </Grid>
              <Grid xs={9} item>
                <Typography variant="body1" align="left">
                  {bin?.area?.code}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('common.bin')}:
                </Typography>
              </Grid>
              <Grid xs={9} item>
                <Typography variant="body1" align="left">
                  {bin?.code}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <Grid container>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('modal.task.currentCount')}:
                </Typography>
              </Grid>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  <strong>
                    {modal.approvalTask.metaData.startingBinInventory} {baseUom?.code}
                  </strong>
                </Typography>
              </Grid>
              {/* ======================================================================== */}
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('modal.task.newCount')}:
                </Typography>
              </Grid>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  <strong>
                    {Number(modal.approvalTask.metaData.startingBinInventory) +
                      Number(modal.approvalTask.metaData.binQuantityChange)}{' '}
                    {baseUom?.code}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
            {/* ======================================================================== */}
            <Box sx={{ height: 15 }} />
            <Grid container>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('modal.task.qtyDifference')}:
                </Typography>
              </Grid>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  <strong>
                    {modal.approvalTask.metaData.binQuantityChange} {baseUom?.code}
                  </strong>
                </Typography>
              </Grid>
              {/* ======================================================================== */}
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('modal.task.valueDifference')}:
                </Typography>
              </Grid>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  <strong>
                    {t(`currency.${modal.approvalTask.metaData.currency as CurrenciesCC}.symbol`)}
                    {modal.approvalTask.metaData.valueDifference}
                  </strong>
                </Typography>
              </Grid>
              {/* ======================================================================== */}
            </Grid>
          </Box>
        </ModalContent>
        <ModalActions>
          <ModalButton
            variant="outlined"
            color="error"
            onClick={onDeny}
            sx={{ marginRight: 'auto' }}
            actionType="delete"
          >
            {t('common.deny')}
          </ModalButton>
          <ModalButton variant="outlined" color="primary" onClick={onCancel} actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton variant="contained" color="primary" actionType="submit" onClick={onApprove}>
            {t('common.approve')}
          </ModalButton>
        </ModalActions>
      </>
    )
  );
};
