/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePutawayTasksMutationVariables = Types.Exact<{
  input: Types.CreatePutawayTaskInputDto;
}>;


export type CreatePutawayTasksMutation = { __typename?: 'Mutation', createPutawayTasks: Array<{ __typename?: 'Task', id: string, code: string }> };


export const CreatePutawayTasksDocument = gql`
    mutation CreatePutawayTasks($input: CreatePutawayTaskInputDto!) {
  createPutawayTasks: createTasksForDelivery(input: $input) {
    id
    code
  }
}
    `;
export type CreatePutawayTasksMutationFn = Apollo.MutationFunction<CreatePutawayTasksMutation, CreatePutawayTasksMutationVariables>;

/**
 * __useCreatePutawayTasksMutation__
 *
 * To run a mutation, you first call `useCreatePutawayTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePutawayTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPutawayTasksMutation, { data, loading, error }] = useCreatePutawayTasksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePutawayTasksMutation(baseOptions?: Apollo.MutationHookOptions<CreatePutawayTasksMutation, CreatePutawayTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePutawayTasksMutation, CreatePutawayTasksMutationVariables>(CreatePutawayTasksDocument, options);
      }
export type CreatePutawayTasksMutationHookResult = ReturnType<typeof useCreatePutawayTasksMutation>;
export type CreatePutawayTasksMutationResult = Apollo.MutationResult<CreatePutawayTasksMutation>;
export type CreatePutawayTasksMutationOptions = Apollo.BaseMutationOptions<CreatePutawayTasksMutation, CreatePutawayTasksMutationVariables>;