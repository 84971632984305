/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DataTableLayoutFragment = { __typename?: 'Layout', id: string, tableId: string, warehouseId: string, variant: Types.LayoutVariant, label: string, columns?: any | null, columnSizes?: any | null, filter?: any | null, sorting?: any | null, pageSize: number, userDefault: boolean, sharedDefault: boolean, userId: string };

export type GetDataTableLayoutsQueryVariables = Types.Exact<{
  tableId: Types.Scalars['ID']['input'];
  userId: Types.Scalars['ID']['input'];
  warehouseId: Types.Scalars['ID']['input'];
}>;


export type GetDataTableLayoutsQuery = { __typename?: 'Query', GetDataTableLayouts: { __typename?: 'LayoutOffsetConnection', layouts: Array<{ __typename?: 'Layout', id: string, tableId: string, warehouseId: string, variant: Types.LayoutVariant, label: string, columns?: any | null, columnSizes?: any | null, filter?: any | null, sorting?: any | null, pageSize: number, userDefault: boolean, sharedDefault: boolean, userId: string }> } };

export type CreateDataTableSharedLayoutMutationVariables = Types.Exact<{
  newLayout: Types.LayoutSharedCreateDto;
}>;


export type CreateDataTableSharedLayoutMutation = { __typename?: 'Mutation', createOneSharedLayout: { __typename?: 'Layout', id: string, tableId: string, warehouseId: string, variant: Types.LayoutVariant, label: string, columns?: any | null, columnSizes?: any | null, filter?: any | null, sorting?: any | null, pageSize: number, userDefault: boolean, sharedDefault: boolean, userId: string } };

export type CreateDataTableUserLayoutMutationVariables = Types.Exact<{
  newLayout: Types.LayoutUserCreateDto;
}>;


export type CreateDataTableUserLayoutMutation = { __typename?: 'Mutation', createOneUserLayout: { __typename?: 'Layout', id: string, tableId: string, warehouseId: string, variant: Types.LayoutVariant, label: string, columns?: any | null, columnSizes?: any | null, filter?: any | null, sorting?: any | null, pageSize: number, userDefault: boolean, sharedDefault: boolean, userId: string } };

export type DeleteDataTableSharedLayoutMutationVariables = Types.Exact<{
  layoutId: Types.Scalars['ID']['input'];
}>;


export type DeleteDataTableSharedLayoutMutation = { __typename?: 'Mutation', deleteOneSharedLayout: string };

export type DeleteDataTableUserLayoutMutationVariables = Types.Exact<{
  layoutId: Types.Scalars['ID']['input'];
}>;


export type DeleteDataTableUserLayoutMutation = { __typename?: 'Mutation', deleteOneUserLayout: string };

export type UpdateDataTableSharedLayoutMutationVariables = Types.Exact<{
  layoutId: Types.Scalars['ID']['input'];
  updatedLayout: Types.LayoutSharedUpdateDto;
}>;


export type UpdateDataTableSharedLayoutMutation = { __typename?: 'Mutation', updateOneSharedLayout: { __typename?: 'Layout', id: string, tableId: string, warehouseId: string, variant: Types.LayoutVariant, label: string, columns?: any | null, columnSizes?: any | null, filter?: any | null, sorting?: any | null, pageSize: number, userDefault: boolean, sharedDefault: boolean, userId: string } };

export type UpdateDataTableUserLayoutMutationVariables = Types.Exact<{
  layoutId: Types.Scalars['ID']['input'];
  updatedLayout: Types.LayoutUserUpdateDto;
}>;


export type UpdateDataTableUserLayoutMutation = { __typename?: 'Mutation', updateOneUserLayout: { __typename?: 'Layout', id: string, tableId: string, warehouseId: string, variant: Types.LayoutVariant, label: string, columns?: any | null, columnSizes?: any | null, filter?: any | null, sorting?: any | null, pageSize: number, userDefault: boolean, sharedDefault: boolean, userId: string } };

export type MarkLayoutAsUserDefaultMutationVariables = Types.Exact<{
  layoutId: Types.Scalars['ID']['input'];
  markAsDefault: Types.Scalars['Boolean']['input'];
}>;


export type MarkLayoutAsUserDefaultMutation = { __typename?: 'Mutation', setUserDefaultLayout: { __typename?: 'Layout', id: string, tableId: string, warehouseId: string, variant: Types.LayoutVariant, label: string, columns?: any | null, columnSizes?: any | null, filter?: any | null, sorting?: any | null, pageSize: number, userDefault: boolean, sharedDefault: boolean, userId: string } };

export const DataTableLayoutFragmentDoc = gql`
    fragment DataTableLayout on Layout {
  id
  tableId
  warehouseId
  variant
  label
  columns
  columnSizes
  filter
  sorting
  pageSize
  userDefault
  sharedDefault
  userId
}
    `;
export const GetDataTableLayoutsDocument = gql`
    query GetDataTableLayouts($tableId: ID!, $userId: ID!, $warehouseId: ID!) {
  GetDataTableLayouts: layouts(
    paging: {offset: 0, limit: 1000000}
    filter: {tableId: {eq: $tableId}, warehouseId: {eq: $warehouseId}, or: [{variant: {eq: User}, userId: {eq: $userId}}, {variant: {eq: Shared}}]}
  ) {
    layouts: nodes {
      ...DataTableLayout
    }
  }
}
    ${DataTableLayoutFragmentDoc}`;

/**
 * __useGetDataTableLayoutsQuery__
 *
 * To run a query within a React component, call `useGetDataTableLayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataTableLayoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataTableLayoutsQuery({
 *   variables: {
 *      tableId: // value for 'tableId'
 *      userId: // value for 'userId'
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useGetDataTableLayoutsQuery(baseOptions: Apollo.QueryHookOptions<GetDataTableLayoutsQuery, GetDataTableLayoutsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataTableLayoutsQuery, GetDataTableLayoutsQueryVariables>(GetDataTableLayoutsDocument, options);
      }
export function useGetDataTableLayoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataTableLayoutsQuery, GetDataTableLayoutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataTableLayoutsQuery, GetDataTableLayoutsQueryVariables>(GetDataTableLayoutsDocument, options);
        }
export type GetDataTableLayoutsQueryHookResult = ReturnType<typeof useGetDataTableLayoutsQuery>;
export type GetDataTableLayoutsLazyQueryHookResult = ReturnType<typeof useGetDataTableLayoutsLazyQuery>;
export type GetDataTableLayoutsQueryResult = Apollo.QueryResult<GetDataTableLayoutsQuery, GetDataTableLayoutsQueryVariables>;
export const CreateDataTableSharedLayoutDocument = gql`
    mutation CreateDataTableSharedLayout($newLayout: LayoutSharedCreateDto!) {
  createOneSharedLayout(input: {input: $newLayout}) {
    ...DataTableLayout
  }
}
    ${DataTableLayoutFragmentDoc}`;
export type CreateDataTableSharedLayoutMutationFn = Apollo.MutationFunction<CreateDataTableSharedLayoutMutation, CreateDataTableSharedLayoutMutationVariables>;

/**
 * __useCreateDataTableSharedLayoutMutation__
 *
 * To run a mutation, you first call `useCreateDataTableSharedLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataTableSharedLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataTableSharedLayoutMutation, { data, loading, error }] = useCreateDataTableSharedLayoutMutation({
 *   variables: {
 *      newLayout: // value for 'newLayout'
 *   },
 * });
 */
export function useCreateDataTableSharedLayoutMutation(baseOptions?: Apollo.MutationHookOptions<CreateDataTableSharedLayoutMutation, CreateDataTableSharedLayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDataTableSharedLayoutMutation, CreateDataTableSharedLayoutMutationVariables>(CreateDataTableSharedLayoutDocument, options);
      }
export type CreateDataTableSharedLayoutMutationHookResult = ReturnType<typeof useCreateDataTableSharedLayoutMutation>;
export type CreateDataTableSharedLayoutMutationResult = Apollo.MutationResult<CreateDataTableSharedLayoutMutation>;
export type CreateDataTableSharedLayoutMutationOptions = Apollo.BaseMutationOptions<CreateDataTableSharedLayoutMutation, CreateDataTableSharedLayoutMutationVariables>;
export const CreateDataTableUserLayoutDocument = gql`
    mutation CreateDataTableUserLayout($newLayout: LayoutUserCreateDto!) {
  createOneUserLayout(input: {input: $newLayout}) {
    ...DataTableLayout
  }
}
    ${DataTableLayoutFragmentDoc}`;
export type CreateDataTableUserLayoutMutationFn = Apollo.MutationFunction<CreateDataTableUserLayoutMutation, CreateDataTableUserLayoutMutationVariables>;

/**
 * __useCreateDataTableUserLayoutMutation__
 *
 * To run a mutation, you first call `useCreateDataTableUserLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDataTableUserLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDataTableUserLayoutMutation, { data, loading, error }] = useCreateDataTableUserLayoutMutation({
 *   variables: {
 *      newLayout: // value for 'newLayout'
 *   },
 * });
 */
export function useCreateDataTableUserLayoutMutation(baseOptions?: Apollo.MutationHookOptions<CreateDataTableUserLayoutMutation, CreateDataTableUserLayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDataTableUserLayoutMutation, CreateDataTableUserLayoutMutationVariables>(CreateDataTableUserLayoutDocument, options);
      }
export type CreateDataTableUserLayoutMutationHookResult = ReturnType<typeof useCreateDataTableUserLayoutMutation>;
export type CreateDataTableUserLayoutMutationResult = Apollo.MutationResult<CreateDataTableUserLayoutMutation>;
export type CreateDataTableUserLayoutMutationOptions = Apollo.BaseMutationOptions<CreateDataTableUserLayoutMutation, CreateDataTableUserLayoutMutationVariables>;
export const DeleteDataTableSharedLayoutDocument = gql`
    mutation DeleteDataTableSharedLayout($layoutId: ID!) {
  deleteOneSharedLayout(input: {id: $layoutId})
}
    `;
export type DeleteDataTableSharedLayoutMutationFn = Apollo.MutationFunction<DeleteDataTableSharedLayoutMutation, DeleteDataTableSharedLayoutMutationVariables>;

/**
 * __useDeleteDataTableSharedLayoutMutation__
 *
 * To run a mutation, you first call `useDeleteDataTableSharedLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataTableSharedLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataTableSharedLayoutMutation, { data, loading, error }] = useDeleteDataTableSharedLayoutMutation({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *   },
 * });
 */
export function useDeleteDataTableSharedLayoutMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDataTableSharedLayoutMutation, DeleteDataTableSharedLayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDataTableSharedLayoutMutation, DeleteDataTableSharedLayoutMutationVariables>(DeleteDataTableSharedLayoutDocument, options);
      }
export type DeleteDataTableSharedLayoutMutationHookResult = ReturnType<typeof useDeleteDataTableSharedLayoutMutation>;
export type DeleteDataTableSharedLayoutMutationResult = Apollo.MutationResult<DeleteDataTableSharedLayoutMutation>;
export type DeleteDataTableSharedLayoutMutationOptions = Apollo.BaseMutationOptions<DeleteDataTableSharedLayoutMutation, DeleteDataTableSharedLayoutMutationVariables>;
export const DeleteDataTableUserLayoutDocument = gql`
    mutation DeleteDataTableUserLayout($layoutId: ID!) {
  deleteOneUserLayout(input: {id: $layoutId})
}
    `;
export type DeleteDataTableUserLayoutMutationFn = Apollo.MutationFunction<DeleteDataTableUserLayoutMutation, DeleteDataTableUserLayoutMutationVariables>;

/**
 * __useDeleteDataTableUserLayoutMutation__
 *
 * To run a mutation, you first call `useDeleteDataTableUserLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDataTableUserLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDataTableUserLayoutMutation, { data, loading, error }] = useDeleteDataTableUserLayoutMutation({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *   },
 * });
 */
export function useDeleteDataTableUserLayoutMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDataTableUserLayoutMutation, DeleteDataTableUserLayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDataTableUserLayoutMutation, DeleteDataTableUserLayoutMutationVariables>(DeleteDataTableUserLayoutDocument, options);
      }
export type DeleteDataTableUserLayoutMutationHookResult = ReturnType<typeof useDeleteDataTableUserLayoutMutation>;
export type DeleteDataTableUserLayoutMutationResult = Apollo.MutationResult<DeleteDataTableUserLayoutMutation>;
export type DeleteDataTableUserLayoutMutationOptions = Apollo.BaseMutationOptions<DeleteDataTableUserLayoutMutation, DeleteDataTableUserLayoutMutationVariables>;
export const UpdateDataTableSharedLayoutDocument = gql`
    mutation UpdateDataTableSharedLayout($layoutId: ID!, $updatedLayout: LayoutSharedUpdateDto!) {
  updateOneSharedLayout(input: {id: $layoutId, update: $updatedLayout}) {
    ...DataTableLayout
  }
}
    ${DataTableLayoutFragmentDoc}`;
export type UpdateDataTableSharedLayoutMutationFn = Apollo.MutationFunction<UpdateDataTableSharedLayoutMutation, UpdateDataTableSharedLayoutMutationVariables>;

/**
 * __useUpdateDataTableSharedLayoutMutation__
 *
 * To run a mutation, you first call `useUpdateDataTableSharedLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataTableSharedLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataTableSharedLayoutMutation, { data, loading, error }] = useUpdateDataTableSharedLayoutMutation({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      updatedLayout: // value for 'updatedLayout'
 *   },
 * });
 */
export function useUpdateDataTableSharedLayoutMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDataTableSharedLayoutMutation, UpdateDataTableSharedLayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDataTableSharedLayoutMutation, UpdateDataTableSharedLayoutMutationVariables>(UpdateDataTableSharedLayoutDocument, options);
      }
export type UpdateDataTableSharedLayoutMutationHookResult = ReturnType<typeof useUpdateDataTableSharedLayoutMutation>;
export type UpdateDataTableSharedLayoutMutationResult = Apollo.MutationResult<UpdateDataTableSharedLayoutMutation>;
export type UpdateDataTableSharedLayoutMutationOptions = Apollo.BaseMutationOptions<UpdateDataTableSharedLayoutMutation, UpdateDataTableSharedLayoutMutationVariables>;
export const UpdateDataTableUserLayoutDocument = gql`
    mutation UpdateDataTableUserLayout($layoutId: ID!, $updatedLayout: LayoutUserUpdateDto!) {
  updateOneUserLayout(input: {id: $layoutId, update: $updatedLayout}) {
    ...DataTableLayout
  }
}
    ${DataTableLayoutFragmentDoc}`;
export type UpdateDataTableUserLayoutMutationFn = Apollo.MutationFunction<UpdateDataTableUserLayoutMutation, UpdateDataTableUserLayoutMutationVariables>;

/**
 * __useUpdateDataTableUserLayoutMutation__
 *
 * To run a mutation, you first call `useUpdateDataTableUserLayoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDataTableUserLayoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDataTableUserLayoutMutation, { data, loading, error }] = useUpdateDataTableUserLayoutMutation({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      updatedLayout: // value for 'updatedLayout'
 *   },
 * });
 */
export function useUpdateDataTableUserLayoutMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDataTableUserLayoutMutation, UpdateDataTableUserLayoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDataTableUserLayoutMutation, UpdateDataTableUserLayoutMutationVariables>(UpdateDataTableUserLayoutDocument, options);
      }
export type UpdateDataTableUserLayoutMutationHookResult = ReturnType<typeof useUpdateDataTableUserLayoutMutation>;
export type UpdateDataTableUserLayoutMutationResult = Apollo.MutationResult<UpdateDataTableUserLayoutMutation>;
export type UpdateDataTableUserLayoutMutationOptions = Apollo.BaseMutationOptions<UpdateDataTableUserLayoutMutation, UpdateDataTableUserLayoutMutationVariables>;
export const MarkLayoutAsUserDefaultDocument = gql`
    mutation MarkLayoutAsUserDefault($layoutId: ID!, $markAsDefault: Boolean!) {
  setUserDefaultLayout(
    input: {id: $layoutId, update: {userDefault: $markAsDefault}}
  ) {
    ...DataTableLayout
  }
}
    ${DataTableLayoutFragmentDoc}`;
export type MarkLayoutAsUserDefaultMutationFn = Apollo.MutationFunction<MarkLayoutAsUserDefaultMutation, MarkLayoutAsUserDefaultMutationVariables>;

/**
 * __useMarkLayoutAsUserDefaultMutation__
 *
 * To run a mutation, you first call `useMarkLayoutAsUserDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkLayoutAsUserDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markLayoutAsUserDefaultMutation, { data, loading, error }] = useMarkLayoutAsUserDefaultMutation({
 *   variables: {
 *      layoutId: // value for 'layoutId'
 *      markAsDefault: // value for 'markAsDefault'
 *   },
 * });
 */
export function useMarkLayoutAsUserDefaultMutation(baseOptions?: Apollo.MutationHookOptions<MarkLayoutAsUserDefaultMutation, MarkLayoutAsUserDefaultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkLayoutAsUserDefaultMutation, MarkLayoutAsUserDefaultMutationVariables>(MarkLayoutAsUserDefaultDocument, options);
      }
export type MarkLayoutAsUserDefaultMutationHookResult = ReturnType<typeof useMarkLayoutAsUserDefaultMutation>;
export type MarkLayoutAsUserDefaultMutationResult = Apollo.MutationResult<MarkLayoutAsUserDefaultMutation>;
export type MarkLayoutAsUserDefaultMutationOptions = Apollo.BaseMutationOptions<MarkLayoutAsUserDefaultMutation, MarkLayoutAsUserDefaultMutationVariables>;