import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetWarehouseDropdownQuery } from '@/graphql/defs/components/__generated__/warehouse-dropdown.generated';
import { GetWarehouseDropdown_defaultData } from '@/graphql/defs/components/warehouse-dropdown';
import { useListUserRolesQuery } from '@/graphql/defs/list/__generated__/list-user-roles.generated';
import { ListUserRoles_defaultData } from '@/graphql/defs/list/list-user-roles';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useEntityUtils } from '@context/entity-utils';

const UserGroupModalBase = ({
  onSubmit,
  onCancel,
  formReturn,
  modalContext,
  preload = undefined,
  onDelete = undefined,
}) => {
  const { t } = useTranslation('components');
  const { setPreparing, isPreparing } = modalContext;
  const { selectedOrganizationId } = useEntityUtils();
  const [preloading, setPreloading] = useState(!!preload);
  const [defaultValue, setDefaultValue] = useState({ roles: [], warehouses: [] });

  const {
    data: { warehouses: { nodes: warehouses } } = GetWarehouseDropdown_defaultData,
    loading: loadingWarehouses,
  } = useGetWarehouseDropdownQuery({
    variables: {
      orgId: selectedOrganizationId,
    },
  });
  const { data: { listRoles: { roles } } = ListUserRoles_defaultData, loading: loadingRoles } =
    useListUserRolesQuery();

  useEffect(() => {
    if (preload)
      preload().then((defaultSelection) => {
        setDefaultValue(defaultSelection);
        setPreloading(false);
      });
  }, []);

  useEffect(() => {
    if (!loadingWarehouses && !loadingRoles && !preloading) {
      setPreparing(false);
    }
  }, [loadingWarehouses, preloading, loadingRoles]);

  const { control, handleSubmit } = formReturn;

  return isPreparing ? null : (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formReturn}>
      <ModalContent sx={{ width: '800px' }}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: t('form.validation.requiredField', { field: t('common.name') }),
              }}
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  fullWidth
                  autoFocus
                  required
                  id="name"
                  label={t('common.name')}
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  onChange={(e) => field.onChange(e)}
                  autoComplete="off"
                  dataTestId="user-group-name"
                />
              )}
            />
            <ControlledAutocomplete
              control={control}
              objOptions={warehouses}
              defaultValue={defaultValue?.warehouses}
              name={t('common.warehouses')}
              rules={{
                required: t('form.validation.requiredField', { field: t('common.warehouses') }),
                minLength: 1,
              }}
            />
            <ControlledAutocomplete
              control={control}
              objOptions={roles}
              defaultValue={defaultValue?.roles}
              name={t('common.roles')}
              rules={{
                required: t('form.validation.requiredField', { field: t('common.roles') }),
                minLength: 1,
              }}
            />
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        {onDelete ? (
          <ModalButton
            sx={{ marginRight: 'auto' }}
            onClick={onDelete}
            variant="outlined"
            color="error"
            actionType="cancel"
          >
            {t('common.delete')}
          </ModalButton>
        ) : null}
        <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
          {t('common.cancel')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" actionType="submit">
          {t('common.save')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const ControlledAutocomplete = ({
  control,
  objOptions,
  defaultValue,
  name,
  label = '',
  rules = null,
  sx = {},
}) => {
  return (
    <Controller
      control={control}
      name={name.toLocaleLowerCase()}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field: { ref, onChange, ...field }, fieldState }) => (
        <Autocomplete
          data-testid={`${name}-dropdown`}
          disableCloseOnSelect={true}
          sx={{ width: '100%', marginTop: '25px', ...sx }}
          multiple
          options={objOptions}
          defaultValue={defaultValue}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.id || option.name}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          onChange={(_, data) => onChange(data)}
          limitTags={3}
          isOptionEqualToValue={(a, b) => {
            return a.id === b.id;
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...field}
                {...params}
                autoFocus
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                required={!!rules?.required}
                multiline={false}
                fullWidth
                inputRef={ref}
                variant="outlined"
                maxRows={1}
                rows={1}
                label={label || name}
                dataTestId={`${name}-input`}
              />
            );
          }}
        />
      )}
    />
  );
};

export default UserGroupModalBase;
