import { createContext, useContext, Dispatch, SetStateAction, useRef } from 'react';

import AppLoadingStateProvider from '@context/app-loading/AppLoadingStateProvider';

interface IAppLoadingToggleContext {
  setAppInitialized: (isAppInitialized: boolean) => void;
  setAppLoading: (isAppLoading: boolean) => void;
}
const AppLoadingToggleContext = createContext<IAppLoadingToggleContext>(null);

const AppLoadingToggleProvider = ({ children }) => {
  const setAppLoadingRef = useRef<Dispatch<SetStateAction<boolean>>>(() => {});
  const setAppInitializedRef = useRef<Dispatch<SetStateAction<boolean>>>(() => {});

  const setAppLoading = (isAppLoading: boolean) => {
    setAppLoadingRef.current(isAppLoading);
  };

  const setAppInitialized = (isAppInitialized: boolean) => {
    setAppInitializedRef.current(isAppInitialized);
  };

  return (
    <>
      <AppLoadingToggleContext.Provider
        value={{
          setAppLoading,
          setAppInitialized,
        }}
      >
        <AppLoadingStateProvider
          setAppInitializedRef={setAppInitializedRef}
          setAppLoadingRef={setAppLoadingRef}
        >
          {children}
        </AppLoadingStateProvider>
      </AppLoadingToggleContext.Provider>
    </>
  );
};

export default AppLoadingToggleProvider;

export const useAppLoadingToggle = () => {
  const ctx = useContext(AppLoadingToggleContext);
  if (!ctx) {
    throw new Error('useAppLoadingToggle must be used within AppLoadingToggleProvider');
  }
  return ctx;
};
