import { gql } from '@apollo/client';

import { ListAreasQuery } from '@/graphql/defs/list/__generated__/list-areas.generated';

gql`
  fragment AreaItem on ViewArea {
    id
    code
    name
  }
  query ListAreas($warehouseId: ID!) {
    viewAreas(
      paging: { offset: 0, limit: 1000000 }
      filter: { warehouseId: { eq: $warehouseId } }
      sorting: [{ field: code, direction: ASC }]
    ) {
      areas: nodes {
        ...AreaItem
      }
    }
  }
`;

export const ListAreas_defaultData: ListAreasQuery = {
  __typename: 'Query',
  viewAreas: {
    __typename: 'ViewAreaOffsetConnection',
    areas: [],
  },
};
