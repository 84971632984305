/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EditTaskUoMUpdateMutationVariables = Types.Exact<{
  input: Types.UpdateOneTaskInput;
}>;


export type EditTaskUoMUpdateMutation = { __typename?: 'Mutation', task: { __typename?: 'Task', id: string, code: string } };


export const EditTaskUoMUpdateDocument = gql`
    mutation EditTaskUoMUpdate($input: UpdateOneTaskInput!) {
  task: updateOneTask(input: $input) {
    id
    code
  }
}
    `;
export type EditTaskUoMUpdateMutationFn = Apollo.MutationFunction<EditTaskUoMUpdateMutation, EditTaskUoMUpdateMutationVariables>;

/**
 * __useEditTaskUoMUpdateMutation__
 *
 * To run a mutation, you first call `useEditTaskUoMUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditTaskUoMUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editTaskUoMUpdateMutation, { data, loading, error }] = useEditTaskUoMUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditTaskUoMUpdateMutation(baseOptions?: Apollo.MutationHookOptions<EditTaskUoMUpdateMutation, EditTaskUoMUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditTaskUoMUpdateMutation, EditTaskUoMUpdateMutationVariables>(EditTaskUoMUpdateDocument, options);
      }
export type EditTaskUoMUpdateMutationHookResult = ReturnType<typeof useEditTaskUoMUpdateMutation>;
export type EditTaskUoMUpdateMutationResult = Apollo.MutationResult<EditTaskUoMUpdateMutation>;
export type EditTaskUoMUpdateMutationOptions = Apollo.BaseMutationOptions<EditTaskUoMUpdateMutation, EditTaskUoMUpdateMutationVariables>;