import { Autocomplete, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LayoutVariant } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { usePermissions } from '@context/permissions';
import { useFormValidation } from '@hooks/form/validators';
import enumKeys from '@lib/enum-keys';
import FormValues from '@models/FormValues';
import { ITableLayoutCreateModal } from '@models/modal';

const CreateTableLayoutModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal } = useModalContent<ITableLayoutCreateModal>();
  const { permissions } = usePermissions();

  interface ISaveTableLayoutFields {
    layoutVariant: LayoutVariant;
    layoutName: string;
    userDefaultLayout: boolean;
    sharedDefaultLayout: boolean;
  }
  const formMethods = useForm<FormValues<ISaveTableLayoutFields>>({
    defaultValues: {
      layoutVariant: LayoutVariant.User,
      layoutName: '',
      userDefaultLayout: false,
      sharedDefaultLayout: false,
    },
  });
  const { control, handleSubmit, watch } = formMethods;
  const { notWhiteSpaceOnly } = useFormValidation();

  const watchedLayoutVariant = watch('layoutVariant', LayoutVariant.User);

  const onSubmit = (fields: ISaveTableLayoutFields) => {
    modal.handleCreate(
      {
        variant: fields.layoutVariant,
        layoutLabel: fields.layoutName,
        userDefault: fields.userDefaultLayout,
        sharedDefault:
          fields.layoutVariant === LayoutVariant.Shared ? fields.sharedDefaultLayout : false,
      },
      () => {
        closeModal({ bypassLoading: true, success: true });
      },
    );
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            {t('modal.tableLayout.create.text')}
          </Grid>
          {permissions['shared-layout.create'] && (
            <>
              <Grid item xs={6}>
                <Controller
                  name="layoutVariant"
                  control={control}
                  rules={{
                    required: t('form.validation.requiredField', {
                      field: t('tableLayout.layoutType'),
                    }),
                    validate: {
                      notWhiteSpaceOnly,
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      data-testid="newLayoutVariant-dropdown"
                      freeSolo={false}
                      multiple={false}
                      autoHighlight={true}
                      disableClearable={true}
                      getOptionLabel={(option) =>
                        option !== '' ? t(`tableLayout.layoutTypes.${LayoutVariant[option]}`) : ''
                      }
                      options={enumKeys(LayoutVariant)}
                      onChange={(event, item, reason) => {
                        if (reason === 'selectOption') {
                          field.onChange(LayoutVariant[item]);
                        }
                      }}
                      value={field.value}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            {...field}
                            dataTestId="newLayoutVariant-input"
                            label={t('tableLayout.layoutType')}
                            error={!!fieldState?.error}
                            helperText={fieldState?.error?.message}
                          />
                        );
                      }}
                      sx={{
                        width: '250px',
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} />
            </>
          )}
          <Grid item xs={6}>
            <Controller
              name="layoutName"
              control={control}
              rules={{
                required: t('form.validation.requiredField', { field: t('common.layoutName') }),
                validate: {
                  notWhiteSpaceOnly,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  autoFocus
                  fullWidth
                  required
                  id="layoutName"
                  label={t('common.layoutName')}
                  {...field}
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  dataTestId="layoutName"
                />
              )}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Controller
              name="userDefaultLayout"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} />}
                  label={t('modal.tableLayout.create.setAsUserDefault')}
                />
              )}
            />
          </Grid>
          {permissions['shared-layout.create'] && watchedLayoutVariant === LayoutVariant.Shared ? (
            <Grid item xs={6}>
              <Controller
                name="sharedDefaultLayout"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} />}
                    label={t('modal.tableLayout.create.setAsSharedDefault')}
                  />
                )}
              />
            </Grid>
          ) : (
            <Grid item xs={6} />
          )}
        </Grid>
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" actionType="submit">
          {t('common.save')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default CreateTableLayoutModal;
