/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import { BaseDeliveryFragmentDoc } from '../../../shared-fragments/__generated__/delivery.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PostGoodsIssueMutationVariables = Types.Exact<{
  fulfillmentId: Types.Scalars['ID']['input'];
}>;


export type PostGoodsIssueMutation = { __typename?: 'Mutation', postGoodsIssue: { __typename?: 'Delivery', id: string, type: Types.DeliveryType, billOfLading?: string | null, erpCreated?: string | null, erpBlockingReason?: string | null, dueDate?: string | null, erpCode: string, totalGrossWeight?: number | null, totalNetWeight?: number | null, totalWeightUOMId?: string | null, erpLastChanged?: string | null, erpSalesOrder?: string | null, goodsReceiptOrIssueStatus?: Types.DeliveryCompletionStatus | null, hasTasks: boolean, pickOrPutawayStatus?: Types.DeliveryCompletionStatus | null, deliveryStatus?: Types.DeliveryCompletionStatus | null, supplier?: string | null, supplierBusinessPartnerId?: string | null, blockStatus?: string | null, supplierName?: string | null, promiseDate?: string | null, pointOfContact?: string | null, shipReadyDate?: string | null, totalVolume?: number | null, totalVolumeUOMId?: string | null, export?: string | null, shipTo?: string | null, shipToBusinessPartnerId?: string | null, shipToName?: string | null, soldTo?: string | null, soldToBusinessPartnerId?: string | null, soldToName?: string | null, progress?: number | null, progressString?: Types.DeliveryCompletionStatus | null, createdAt: string, updatedAt?: string | null, deletedAt?: string | null, warehouse?: { __typename?: 'Warehouse', id: string, code: string, name?: string | null } | null, fulfillmentBlock?: { __typename?: 'FulfillmentBlock', label: string, description?: string | null, id: string } | null } };

export type PostGoodsReceiptMutationVariables = Types.Exact<{
  deliveryId: Types.Scalars['ID']['input'];
}>;


export type PostGoodsReceiptMutation = { __typename?: 'Mutation', postGoodsReceipt: { __typename?: 'Delivery', id: string, type: Types.DeliveryType, billOfLading?: string | null, erpCreated?: string | null, erpBlockingReason?: string | null, dueDate?: string | null, erpCode: string, totalGrossWeight?: number | null, totalNetWeight?: number | null, totalWeightUOMId?: string | null, erpLastChanged?: string | null, erpSalesOrder?: string | null, goodsReceiptOrIssueStatus?: Types.DeliveryCompletionStatus | null, hasTasks: boolean, pickOrPutawayStatus?: Types.DeliveryCompletionStatus | null, deliveryStatus?: Types.DeliveryCompletionStatus | null, supplier?: string | null, supplierBusinessPartnerId?: string | null, blockStatus?: string | null, supplierName?: string | null, promiseDate?: string | null, pointOfContact?: string | null, shipReadyDate?: string | null, totalVolume?: number | null, totalVolumeUOMId?: string | null, export?: string | null, shipTo?: string | null, shipToBusinessPartnerId?: string | null, shipToName?: string | null, soldTo?: string | null, soldToBusinessPartnerId?: string | null, soldToName?: string | null, progress?: number | null, progressString?: Types.DeliveryCompletionStatus | null, createdAt: string, updatedAt?: string | null, deletedAt?: string | null, warehouse?: { __typename?: 'Warehouse', id: string, code: string, name?: string | null } | null, fulfillmentBlock?: { __typename?: 'FulfillmentBlock', label: string, description?: string | null, id: string } | null } };


export const PostGoodsIssueDocument = gql`
    mutation PostGoodsIssue($fulfillmentId: ID!) {
  postGoodsIssue(fulfillmentId: $fulfillmentId) {
    ...BaseDelivery
  }
}
    ${BaseDeliveryFragmentDoc}`;
export type PostGoodsIssueMutationFn = Apollo.MutationFunction<PostGoodsIssueMutation, PostGoodsIssueMutationVariables>;

/**
 * __usePostGoodsIssueMutation__
 *
 * To run a mutation, you first call `usePostGoodsIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostGoodsIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postGoodsIssueMutation, { data, loading, error }] = usePostGoodsIssueMutation({
 *   variables: {
 *      fulfillmentId: // value for 'fulfillmentId'
 *   },
 * });
 */
export function usePostGoodsIssueMutation(baseOptions?: Apollo.MutationHookOptions<PostGoodsIssueMutation, PostGoodsIssueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostGoodsIssueMutation, PostGoodsIssueMutationVariables>(PostGoodsIssueDocument, options);
      }
export type PostGoodsIssueMutationHookResult = ReturnType<typeof usePostGoodsIssueMutation>;
export type PostGoodsIssueMutationResult = Apollo.MutationResult<PostGoodsIssueMutation>;
export type PostGoodsIssueMutationOptions = Apollo.BaseMutationOptions<PostGoodsIssueMutation, PostGoodsIssueMutationVariables>;
export const PostGoodsReceiptDocument = gql`
    mutation PostGoodsReceipt($deliveryId: ID!) {
  postGoodsReceipt(deliveryId: $deliveryId) {
    ...BaseDelivery
  }
}
    ${BaseDeliveryFragmentDoc}`;
export type PostGoodsReceiptMutationFn = Apollo.MutationFunction<PostGoodsReceiptMutation, PostGoodsReceiptMutationVariables>;

/**
 * __usePostGoodsReceiptMutation__
 *
 * To run a mutation, you first call `usePostGoodsReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostGoodsReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postGoodsReceiptMutation, { data, loading, error }] = usePostGoodsReceiptMutation({
 *   variables: {
 *      deliveryId: // value for 'deliveryId'
 *   },
 * });
 */
export function usePostGoodsReceiptMutation(baseOptions?: Apollo.MutationHookOptions<PostGoodsReceiptMutation, PostGoodsReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PostGoodsReceiptMutation, PostGoodsReceiptMutationVariables>(PostGoodsReceiptDocument, options);
      }
export type PostGoodsReceiptMutationHookResult = ReturnType<typeof usePostGoodsReceiptMutation>;
export type PostGoodsReceiptMutationResult = Apollo.MutationResult<PostGoodsReceiptMutation>;
export type PostGoodsReceiptMutationOptions = Apollo.BaseMutationOptions<PostGoodsReceiptMutation, PostGoodsReceiptMutationVariables>;