import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateBinStorageTypeMutation } from '@/graphql/defs/components/modals/__generated__/bin-storage-type-create-modal.generated';
import { BinStorageTypeCreateInput } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import BinStorageTypeFields from '@components/modal/bin-storage-type/bin-storage-type-fields';
import ModalForm from '@components/modal/modal-form';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import FormValues from '@models/FormValues';
import { IBinStorageTypeCreateModal } from '@models/modal';

const BinStorageTypeCreateModal = () => {
  const { t } = useTranslation('components');
  const { closeModal, setLoading } = useModalContent<IBinStorageTypeCreateModal>();
  const { showMessage } = useSnackbar();
  const { selectedWarehouseId } = useEntityUtils();

  const formReturn = useForm<FormValues<BinStorageTypeCreateInput>>();
  const { control, handleSubmit } = formReturn;

  const [createBinStorageType] = useCreateBinStorageTypeMutation({
    onCompleted: ({ createOneBinStorageType: { label } }) => {
      showMessage({
        type: 'success',
        message: t('modal.binStorageTypes.create.success', { binStorageTypeLabel: label }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: t('modal.binStorageTypes.create.error', { errorMessage: error.message }),
      });
      setLoading(false);
    },
  });
  const onSubmit = (fields: BinStorageTypeCreateInput) => {
    createBinStorageType({
      variables: {
        binStorageType: {
          code: fields.code,
          label: fields.label,
          description: fields.description,
          weightCapacityUomId: fields.weightCapacityUomId,
          weightCapacity: fields.weightCapacity,
          distanceUomId: fields.distanceUomId,
          depth: fields.depth,
          width: fields.width,
          height: fields.height,
          warehouseId: selectedWarehouseId,
        },
      },
    });
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formReturn}>
      <ModalContent>
        <BinStorageTypeFields control={control} />
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          variant="contained"
          color="primary"
          data-testid="create-bin-storage-type-submit"
          actionType="submit"
        >
          {t('common.submit')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default BinStorageTypeCreateModal;
