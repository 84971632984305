/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTaskEditBinsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.BinFilter>;
}>;


export type GetTaskEditBinsQuery = { __typename?: 'Query', bins: { __typename?: 'BinOffsetConnection', nodes: Array<{ __typename?: 'Bin', id: string, code: string }> } };

export type GetTaskEditLotsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.LotFilter>;
}>;


export type GetTaskEditLotsQuery = { __typename?: 'Query', lots: { __typename?: 'LotOffsetConnection', nodes: Array<{ __typename?: 'Lot', id: string, code: string }> } };

export type TaskEditUpdateMutationVariables = Types.Exact<{
  input: Types.UpdateOneTaskInput;
}>;


export type TaskEditUpdateMutation = { __typename?: 'Mutation', task: { __typename?: 'Task', id: string, code: string } };


export const GetTaskEditBinsDocument = gql`
    query GetTaskEditBins($filter: BinFilter) {
  bins(filter: $filter, sorting: {field: code, direction: ASC}) {
    nodes {
      id
      code
    }
  }
}
    `;

/**
 * __useGetTaskEditBinsQuery__
 *
 * To run a query within a React component, call `useGetTaskEditBinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskEditBinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskEditBinsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTaskEditBinsQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskEditBinsQuery, GetTaskEditBinsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskEditBinsQuery, GetTaskEditBinsQueryVariables>(GetTaskEditBinsDocument, options);
      }
export function useGetTaskEditBinsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskEditBinsQuery, GetTaskEditBinsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskEditBinsQuery, GetTaskEditBinsQueryVariables>(GetTaskEditBinsDocument, options);
        }
export type GetTaskEditBinsQueryHookResult = ReturnType<typeof useGetTaskEditBinsQuery>;
export type GetTaskEditBinsLazyQueryHookResult = ReturnType<typeof useGetTaskEditBinsLazyQuery>;
export type GetTaskEditBinsQueryResult = Apollo.QueryResult<GetTaskEditBinsQuery, GetTaskEditBinsQueryVariables>;
export const GetTaskEditLotsDocument = gql`
    query GetTaskEditLots($filter: LotFilter) {
  lots(filter: $filter, sorting: {field: code, direction: ASC}) {
    nodes {
      id
      code
    }
  }
}
    `;

/**
 * __useGetTaskEditLotsQuery__
 *
 * To run a query within a React component, call `useGetTaskEditLotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskEditLotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskEditLotsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetTaskEditLotsQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskEditLotsQuery, GetTaskEditLotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskEditLotsQuery, GetTaskEditLotsQueryVariables>(GetTaskEditLotsDocument, options);
      }
export function useGetTaskEditLotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskEditLotsQuery, GetTaskEditLotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskEditLotsQuery, GetTaskEditLotsQueryVariables>(GetTaskEditLotsDocument, options);
        }
export type GetTaskEditLotsQueryHookResult = ReturnType<typeof useGetTaskEditLotsQuery>;
export type GetTaskEditLotsLazyQueryHookResult = ReturnType<typeof useGetTaskEditLotsLazyQuery>;
export type GetTaskEditLotsQueryResult = Apollo.QueryResult<GetTaskEditLotsQuery, GetTaskEditLotsQueryVariables>;
export const TaskEditUpdateDocument = gql`
    mutation TaskEditUpdate($input: UpdateOneTaskInput!) {
  task: updateOneTask(input: $input) {
    id
    code
  }
}
    `;
export type TaskEditUpdateMutationFn = Apollo.MutationFunction<TaskEditUpdateMutation, TaskEditUpdateMutationVariables>;

/**
 * __useTaskEditUpdateMutation__
 *
 * To run a mutation, you first call `useTaskEditUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskEditUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskEditUpdateMutation, { data, loading, error }] = useTaskEditUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskEditUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TaskEditUpdateMutation, TaskEditUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskEditUpdateMutation, TaskEditUpdateMutationVariables>(TaskEditUpdateDocument, options);
      }
export type TaskEditUpdateMutationHookResult = ReturnType<typeof useTaskEditUpdateMutation>;
export type TaskEditUpdateMutationResult = Apollo.MutationResult<TaskEditUpdateMutation>;
export type TaskEditUpdateMutationOptions = Apollo.BaseMutationOptions<TaskEditUpdateMutation, TaskEditUpdateMutationVariables>;