import { Box, keyframes, styled } from '@mui/material';
import { memo } from 'react';

const slowBlink = keyframes`
0% { opacity: 1 }
100% { opacity: .85 }
`;

// The memo is helpful in keeping the animation smooth while other re-renders are happening.
// Since this component never changes on re-render, and will only be unmounted when the load state is turned off,
// we can tell React to always use the memo by just returning true for the equality check.
const LoadingBlob = memo(
  styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.offwhite.dark,
    animation: `1.25s linear 0s infinite alternate ${slowBlink}`,
  })),
  () => true,
);

export default LoadingBlob;
