export const getInitials = (value: string): string | null => {
  if (!value) {
    return null;
  }

  return value
    .split(' ')
    .map((n) => n[0].toUpperCase())
    .join('')
    .substring(0, 2);
};

// TODO: Need to remove this function and its usages as this will not support I18n
export const camelCaseToSentence = (value = ''): string => {
  const result = value.replace(/([A-Z])/g, ' $1');
  return (result.charAt(0).toUpperCase() + result.slice(1)).trim();
};

// Mainly used for taking user provided string and making it camelCase to match against enum values.
export function toCamelCase(str: string) {
  return (
    typeof str === 'string' &&
    str
      .split(' ')
      .map((word, index) => {
        return (
          (index === 0 ? word[0].toLowerCase() : word[0].toUpperCase()) +
          word.slice(1).toLowerCase()
        );
      })
      .join('')
  );
}

export function toKebabCase(str: string) {
  return str
    .split(' ')
    .map((word) => word.toLowerCase())
    .join('-');
}
