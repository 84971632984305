import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { Typography, Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { ReactNode, isValidElement, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { DASHBOARD } from '@constants/routes';

export interface IPageInfo {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  backButton?: boolean;
  backButtonRoute?: string;
  backButtonContainerWidth?: string;
  backButtonGap?: number;
  titlePaddingLeft?: string;
}

const PageInfo: React.FC<IPageInfo> = ({
  title,
  subtitle,
  backButton = false,
  titlePaddingLeft = '0px',
  backButtonRoute,
  backButtonContainerWidth,
  backButtonGap,
  children,
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'pageInfo' });
  const [searchParams] = useSearchParams();

  const [fallbackRoute, setFallbackRoute] = useState('');

  useEffect(() => {
    if (searchParams.get('referrer')) {
      const referrer = decodeURIComponent(searchParams.get('referrer'));
      setFallbackRoute(referrer);
    } else {
      setFallbackRoute(backButtonRoute ? backButtonRoute : DASHBOARD);
    }
  }, [searchParams]);

  return (
    <Box
      display="flex"
      alignItems="center"
      flexWrap="nowrap"
      gap={backButtonGap || 0}
      data-testid="page-info-container"
    >
      {(backButton || backButtonContainerWidth) && (
        <Box flexShrink={0} width={backButtonContainerWidth || 'auto'}>
          {backButton && (
            <Box mr={5}>
              <BackButton href={fallbackRoute} data-testid="page-info-back-button">
                <ArrowBackIosOutlinedIcon />
                {t('back')}
              </BackButton>
            </Box>
          )}
        </Box>
      )}
      <Box display="flex" flexGrow={1} alignItems="center" flexWrap="nowrap" overflow="hidden">
        <Box flexGrow="1">
          <Box
            sx={{ paddingRight: children ? '15px' : '0', paddingLeft: titlePaddingLeft }}
            data-testid="page-info-title"
          >
            {isValidElement(title) ? (
              title
            ) : (
              <Typography variant="h2" align="left">
                {title}
              </Typography>
            )}
            {subtitle &&
              (isValidElement(subtitle) ? (
                subtitle
              ) : (
                <Typography
                  variant="body2"
                  align="left"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  pt={1}
                >
                  {subtitle}
                </Typography>
              ))}
          </Box>
        </Box>
        <Box
          display="flex"
          flexShrink={0}
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          data-testid="page-info-additional-content"
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default PageInfo;

const BackButton = styled(Link)(({ theme }) => ({
  ...theme.typography.body2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  border: 'none',
  backgroundColor: 'transparent',
  textDecoration: 'none',
  textTransform: 'uppercase',
  fontWeight: 600,
  lineHeight: 0,
  color: theme.palette.slateGrey.main,
  '& svg': {
    fontSize: '24px',
  },
  '&:hover': {
    border: 'none',
    backgroundColor: 'transparent',
    color: theme.palette.slateGrey.dark,
  },
}));
