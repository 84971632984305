/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListAllUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListAllUsersQuery = { __typename?: 'Query', getUsers: { __typename?: 'UserOffsetConnection', users: Array<{ __typename?: 'User', id: string, firstName: string, lastName: string, status: Types.UserStatus }> } };

export type ListUsersQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.UserFilter>;
}>;


export type ListUsersQuery = { __typename?: 'Query', listUsers: { __typename?: 'UserOffsetConnection', users: Array<{ __typename?: 'User', id: string, firstName: string, lastName: string, status: Types.UserStatus }> } };


export const ListAllUsersDocument = gql`
    query ListAllUsers {
  getUsers: users(
    sorting: [{field: lastName, direction: ASC}, {field: firstName, direction: ASC}]
  ) {
    users: nodes {
      id
      firstName
      lastName
      status
    }
  }
}
    `;

/**
 * __useListAllUsersQuery__
 *
 * To run a query within a React component, call `useListAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListAllUsersQuery, ListAllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllUsersQuery, ListAllUsersQueryVariables>(ListAllUsersDocument, options);
      }
export function useListAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllUsersQuery, ListAllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllUsersQuery, ListAllUsersQueryVariables>(ListAllUsersDocument, options);
        }
export type ListAllUsersQueryHookResult = ReturnType<typeof useListAllUsersQuery>;
export type ListAllUsersLazyQueryHookResult = ReturnType<typeof useListAllUsersLazyQuery>;
export type ListAllUsersQueryResult = Apollo.QueryResult<ListAllUsersQuery, ListAllUsersQueryVariables>;
export const ListUsersDocument = gql`
    query ListUsers($filter: UserFilter) {
  listUsers: users(filter: $filter) {
    users: nodes {
      id
      firstName
      lastName
      status
    }
  }
}
    `;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
      }
export function useListUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
        }
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;