import { gql } from '@apollo/client';

import {
  GetUnloadDoorBinInfoQuery,
  GetUnloadDoorStagingBinQuery,
  GetUnloadTaskDeliveryItemQuery,
} from '@/graphql/defs/components/modals/__generated__/unload-task-complete-modal.generated';

gql`
  query GetUnloadTaskDeliveryItem($deliveryItemId: ID!) {
    viewDeliveryItems(filter: { id: { eq: $deliveryItemId } }) {
      nodes {
        id
        licensePlateId
        licensePlateCode
        licensePlateDescription
      }
    }
  }

  query GetUnloadDoorStagingBin($doorId: ID!) {
    door(id: $doorId) {
      id
      binId
    }
  }

  query GetUnloadDoorBinInfo($binId: ID!) {
    bin(id: $binId) {
      id
      code
    }
  }

  mutation CompleteUnloadTask($input: TaskCompleteOneUnloadInput!) {
    completeOneFFUnloadMovementTask(completeUnloadTask: $input) {
      id
      code
    }
  }
`;

export const GetUnloadTaskDeliveryItem_defaultData: GetUnloadTaskDeliveryItemQuery = {
  __typename: 'Query',
  viewDeliveryItems: {
    __typename: 'ViewDeliveryItemOffsetConnection',
    nodes: [],
  },
};

export const GetUnloadDoorStagingBin_defaultData: GetUnloadDoorStagingBinQuery = {
  __typename: 'Query',
  door: null,
};

export const GetUnloadDoorBinInfo_defaultData: GetUnloadDoorBinInfoQuery = {
  __typename: 'Query',
  bin: null,
};
