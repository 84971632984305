import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import UserGroupModalBase from './user-group-modal-base';

import {
  useCreateUserGroupMutation,
  useUpdateRolesForUserGroupMutation,
} from '@/graphql/defs/components/modals/__generated__/user-group-modal.generated';
import { Role } from '@/graphql/types.generated';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import FormValues from '@models/FormValues';
import { IUserGroupCreateModal } from '@models/modal';

export interface IUserGroup {
  name: string;
  warehouses: { name: string; id: string }[];
  roles: Role[];
}

const CreateUserGroupModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const modalContext = useModalContent<IUserGroupCreateModal>();
  const { closeModal, setLoading } = modalContext;

  const formReturn = useForm<FormValues<IUserGroup>>({
    defaultValues: {
      name: '',
      warehouses: [],
      roles: [],
    },
  });

  const [createUserGroup] = useCreateUserGroupMutation({
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const [updateRoles] = useUpdateRolesForUserGroupMutation({
    onCompleted: async () => {
      showMessage({
        type: 'success',
        message: t('userGroupActions.createSuccess', { name: formReturn.getValues().name }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
  });

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = async (fields: IUserGroup) => {
    const {
      data: {
        createOneUserGroup: { id: userGroupId },
      },
    } = await createUserGroup({
      variables: {
        input: {
          userGroup: {
            name: fields.name,
          },
        },
      },
    });
    await updateRoles({
      variables: {
        input: {
          userGroupId,
          roleIds: fields.roles.map(({ id }) => id),
          warehouseIds: fields.warehouses.map(({ id }) => id),
        },
      },
    });
  };

  return (
    <UserGroupModalBase
      onSubmit={onSubmit}
      onCancel={onCancel}
      formReturn={formReturn}
      modalContext={modalContext}
    />
  );
};

export default CreateUserGroupModal;
