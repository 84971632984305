/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkBarcodesAsCompliantMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type MarkBarcodesAsCompliantMutation = { __typename?: 'Mutation', resolveNonCompliantBarcodes: string };


export const MarkBarcodesAsCompliantDocument = gql`
    mutation MarkBarcodesAsCompliant($ids: [ID!]!) {
  resolveNonCompliantBarcodes(input: {resolveNonCompliantBarcodes: {ids: $ids}})
}
    `;
export type MarkBarcodesAsCompliantMutationFn = Apollo.MutationFunction<MarkBarcodesAsCompliantMutation, MarkBarcodesAsCompliantMutationVariables>;

/**
 * __useMarkBarcodesAsCompliantMutation__
 *
 * To run a mutation, you first call `useMarkBarcodesAsCompliantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkBarcodesAsCompliantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markBarcodesAsCompliantMutation, { data, loading, error }] = useMarkBarcodesAsCompliantMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkBarcodesAsCompliantMutation(baseOptions?: Apollo.MutationHookOptions<MarkBarcodesAsCompliantMutation, MarkBarcodesAsCompliantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkBarcodesAsCompliantMutation, MarkBarcodesAsCompliantMutationVariables>(MarkBarcodesAsCompliantDocument, options);
      }
export type MarkBarcodesAsCompliantMutationHookResult = ReturnType<typeof useMarkBarcodesAsCompliantMutation>;
export type MarkBarcodesAsCompliantMutationResult = Apollo.MutationResult<MarkBarcodesAsCompliantMutation>;
export type MarkBarcodesAsCompliantMutationOptions = Apollo.BaseMutationOptions<MarkBarcodesAsCompliantMutation, MarkBarcodesAsCompliantMutationVariables>;