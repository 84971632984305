import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DateTimePickerBase from '@components/date-time-range-picker/date-time-range-base';

interface DateTimeI extends DateTime {
  ts?: number;
}

const DateTimeRangePicker = ({
  dateTimeRange,
  setDateTimeRange,
  direction = 'row',
  onClear = undefined,
  startLabel = undefined,
  endLabel = undefined,
  isDisabled = false,
  dataTestId = 'date-time-range-picker',
}) => {
  const [internalDateRange, setInternalDateRange] = useState<[DateTimeI, DateTimeI]>(dateTimeRange);
  const [error, setError] = useState<FieldError[]>([null, null]);
  const { t } = useTranslation('components');
  useEffect(() => {
    if (dateTimeRange[0] === undefined && dateTimeRange[1] === undefined) {
      const now = DateTime.now();
      return setDateTimeRange([now, now.plus({ days: 1 })]);
    }
    if (dateTimeRange[0] !== internalDateRange[0] || dateTimeRange[1] !== internalDateRange[1]) {
      setInternalDateRange([
        typeof dateTimeRange[0] === 'string'
          ? DateTime.fromISO(dateTimeRange[0])
          : dateTimeRange[0],
        typeof dateTimeRange[1] === 'string'
          ? DateTime.fromISO(dateTimeRange[1])
          : dateTimeRange[1],
      ]);
    }
  }, [dateTimeRange[0], dateTimeRange[1]]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: (theme) => theme.spacing(3) }}>
      <Box sx={{ width: '220px' }}>
        <DateTimePickerBase
          fullWidth={false}
          value={internalDateRange[0]}
          setDate={(newDateTime) => {
            if ((newDateTime as unknown as { ts: number })?.ts > internalDateRange[1]?.ts) {
              setError([{ message: 'Invalid Date Range', type: 'input' }, null]);
              setInternalDateRange([newDateTime, internalDateRange[1]]);
            } else {
              setError([null, null]);
              setDateTimeRange([newDateTime, internalDateRange[1]]);
            }
          }}
          fieldState={{ error: error[0], isTouched: true, isDirty: true, invalid: !!error[0] }}
          label={startLabel}
          isDisabled={isDisabled}
          dataTestId={`${dataTestId}-start`}
        />
      </Box>
      <Box>{t('filter.and').toUpperCase()}</Box>
      <Box sx={{ width: '220px' }}>
        <DateTimePickerBase
          fullWidth={false}
          value={internalDateRange[1]}
          setDate={(newDateTime: DateTime) => {
            if ((newDateTime as unknown as { ts: number })?.ts < internalDateRange[0]?.ts) {
              setError([null, { message: 'Invalid Date Range', type: 'input' }]);
              setInternalDateRange([internalDateRange[0], newDateTime]);
            } else {
              setError([null, null]);
              setDateTimeRange([internalDateRange[0], newDateTime]);
            }
          }}
          fieldState={{ error: error[1], isTouched: true, isDirty: true, invalid: !!error[1] }}
          label={endLabel}
          isDisabled={isDisabled}
          dataTestId={`${dataTestId}-end`}
        />
      </Box>
    </Box>
  );
};

export default DateTimeRangePicker;
