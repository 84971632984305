/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BinStatusesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type BinStatusesQuery = { __typename?: 'Query', binStatuses: { __typename?: 'BinStatusOffsetConnection', nodes: Array<{ __typename?: 'BinStatus', code: Types.BinStatusCode, createdAt: string, deletedAt?: string | null, id: string, label: string, updatedAt?: string | null }> } };


export const BinStatusesDocument = gql`
    query BinStatuses {
  binStatuses {
    nodes {
      code
      createdAt
      deletedAt
      id
      label
      updatedAt
    }
  }
}
    `;

/**
 * __useBinStatusesQuery__
 *
 * To run a query within a React component, call `useBinStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBinStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBinStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useBinStatusesQuery(baseOptions?: Apollo.QueryHookOptions<BinStatusesQuery, BinStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BinStatusesQuery, BinStatusesQueryVariables>(BinStatusesDocument, options);
      }
export function useBinStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BinStatusesQuery, BinStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BinStatusesQuery, BinStatusesQueryVariables>(BinStatusesDocument, options);
        }
export type BinStatusesQueryHookResult = ReturnType<typeof useBinStatusesQuery>;
export type BinStatusesLazyQueryHookResult = ReturnType<typeof useBinStatusesLazyQuery>;
export type BinStatusesQueryResult = Apollo.QueryResult<BinStatusesQuery, BinStatusesQueryVariables>;