import { Box } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useSaveRolePermissionsMutation } from '@/graphql/defs/components/modals/__generated__/save-role-permissions.generated';
import { RoleDeleteInputType } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import FormValues from '@models/FormValues';
import { IRolePermissionsSaveModal } from '@models/modal';

const SaveRolePermissionsModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal, setLoading } = useModalContent<IRolePermissionsSaveModal>();

  const [updatePermissions] = useSaveRolePermissionsMutation({
    onCompleted: async (x) => {
      showMessage({
        type: 'success',
        message: t('roleActions.permissionsUpdated'),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = (fields: RoleDeleteInputType) => {
    void updatePermissions({
      variables: {
        updateRolePermissionsInput: {
          roleId: modal.role.id,
          permissionIds: modal.permissionIds,
        },
      },
    });
  };

  const formReturn = useForm<FormValues<RoleDeleteInputType>>({
    defaultValues: {
      id: modal.role?.id,
    },
  });
  const { handleSubmit } = formReturn;

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formReturn}>
      <ModalContent>
        <Box
          sx={{ color: (theme) => theme.palette.text.primary, fontSize: '16px', fontWeight: 400 }}
        >
          {t('roleActions.updatePermissionsPrompt', { name: modal.role.name })}
        </Box>
      </ModalContent>
      <ModalActions>
        <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
          {t('common.cancel')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" actionType="submit">
          {t('common.submit')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default SaveRolePermissionsModal;
