/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelDeliveryItemsMutationVariables = Types.Exact<{
  deliveryItemIds: Array<Types.CancelManyDeliveryItemsDto> | Types.CancelManyDeliveryItemsDto;
}>;


export type CancelDeliveryItemsMutation = { __typename?: 'Mutation', cancelManyDeliveryItems: Array<{ __typename?: 'DeliveryItem', id: string, deliveryId: string, item: string }> };


export const CancelDeliveryItemsDocument = gql`
    mutation CancelDeliveryItems($deliveryItemIds: [CancelManyDeliveryItemsDto!]!) {
  cancelManyDeliveryItems(cancelManyDeliveryItems: $deliveryItemIds) {
    id
    deliveryId
    item: deliveryItem
  }
}
    `;
export type CancelDeliveryItemsMutationFn = Apollo.MutationFunction<CancelDeliveryItemsMutation, CancelDeliveryItemsMutationVariables>;

/**
 * __useCancelDeliveryItemsMutation__
 *
 * To run a mutation, you first call `useCancelDeliveryItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelDeliveryItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelDeliveryItemsMutation, { data, loading, error }] = useCancelDeliveryItemsMutation({
 *   variables: {
 *      deliveryItemIds: // value for 'deliveryItemIds'
 *   },
 * });
 */
export function useCancelDeliveryItemsMutation(baseOptions?: Apollo.MutationHookOptions<CancelDeliveryItemsMutation, CancelDeliveryItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelDeliveryItemsMutation, CancelDeliveryItemsMutationVariables>(CancelDeliveryItemsDocument, options);
      }
export type CancelDeliveryItemsMutationHookResult = ReturnType<typeof useCancelDeliveryItemsMutation>;
export type CancelDeliveryItemsMutationResult = Apollo.MutationResult<CancelDeliveryItemsMutation>;
export type CancelDeliveryItemsMutationOptions = Apollo.BaseMutationOptions<CancelDeliveryItemsMutation, CancelDeliveryItemsMutationVariables>;