/* eslint-disable */
import * as Types from '../../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type VolumeUoMFragment = { __typename?: 'UnitOfMeasureGlossary', id: string, code: string };

export type VolumeUnitOfMeasuresQueryVariables = Types.Exact<{
  warehouseId: Types.Scalars['ID']['input'];
}>;


export type VolumeUnitOfMeasuresQuery = { __typename?: 'Query', unitOfMeasureWarehousePreference: Array<{ __typename?: 'UnitOfMeasureGlossary', id: string, code: string }> };

export const VolumeUoMFragmentDoc = gql`
    fragment VolumeUoM on UnitOfMeasureGlossary {
  id
  code
}
    `;
export const VolumeUnitOfMeasuresDocument = gql`
    query VolumeUnitOfMeasures($warehouseId: ID!) {
  unitOfMeasureWarehousePreference(category: volume, warehouseId: $warehouseId) {
    ...VolumeUoM
  }
}
    ${VolumeUoMFragmentDoc}`;

/**
 * __useVolumeUnitOfMeasuresQuery__
 *
 * To run a query within a React component, call `useVolumeUnitOfMeasuresQuery` and pass it any options that fit your needs.
 * When your component renders, `useVolumeUnitOfMeasuresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVolumeUnitOfMeasuresQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useVolumeUnitOfMeasuresQuery(baseOptions: Apollo.QueryHookOptions<VolumeUnitOfMeasuresQuery, VolumeUnitOfMeasuresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VolumeUnitOfMeasuresQuery, VolumeUnitOfMeasuresQueryVariables>(VolumeUnitOfMeasuresDocument, options);
      }
export function useVolumeUnitOfMeasuresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VolumeUnitOfMeasuresQuery, VolumeUnitOfMeasuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VolumeUnitOfMeasuresQuery, VolumeUnitOfMeasuresQueryVariables>(VolumeUnitOfMeasuresDocument, options);
        }
export type VolumeUnitOfMeasuresQueryHookResult = ReturnType<typeof useVolumeUnitOfMeasuresQuery>;
export type VolumeUnitOfMeasuresLazyQueryHookResult = ReturnType<typeof useVolumeUnitOfMeasuresLazyQuery>;
export type VolumeUnitOfMeasuresQueryResult = Apollo.QueryResult<VolumeUnitOfMeasuresQuery, VolumeUnitOfMeasuresQueryVariables>;