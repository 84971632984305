import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Grid,
  InputAdornment,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  createFilterOptions,
  Autocomplete,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  useCompletePickTaskMutation,
  usePopulateCompletePickTaskModalQuery,
} from '@/graphql/defs/components/modals/__generated__/pick-task-complete-modal.generated';
import { PopulateCompletePickTaskModal_defaultData } from '@/graphql/defs/components/modals/pick-task-complete-modal';
import {
  BinForPickTask,
  LicensePlateForPickTask,
  TaskCompletePickInput,
} from '@/graphql/types.generated';
import UoMSelection from '@components/form-util/uom-selection';
import UoMTiedQuantity from '@components/form-util/uom-tied-quantity';
import { IColumn, JustifiedColumns } from '@components/justified-columns';
import LoadingIndicator from '@components/loading-indicator';
import { ModalActions, ModalContent, ModalButton } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import StatusTypography from '@components/status-typography';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { getTransComponents } from '@lib/translation';
import { IPickTaskCompleteModal } from '@models/modal';
import StatusValues from '@models/StatusValues';

type IPickSource = BinForPickTask | LicensePlateForPickTask;

export const PickTaskCompleteModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, setLoading } = useModalContent<IPickTaskCompleteModal>();
  const { showMessage } = useSnackbar();

  const formMethods = useForm();
  const { control, reset, handleSubmit } = formMethods;
  const quantityFieldName = 'quantity';
  const completedUoMFieldName = 'completedUoMId';

  type SourceType = 'looseProduct' | 'licensePlate';
  const [sourceType, setSourceType] = useState<SourceType>('looseProduct');
  const [lot, setLot] = useState<{ lotId: string; lotCode: string }>(null);

  useEffect(() => {
    reset({ source: null });
  }, [sourceType]);

  const [completePickTask] = useCompletePickTaskMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: `${t('modal.task.completePickTaskSuccessMessage')}`,
      });

      closeModal({ bypassLoading: true, success: true });
    },
    onError: () => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: `${t('modal.task.completePickTaskErrorMessage')}`,
      });
    },
  });

  const { loading: loadingResources, data: populateCompletePickTaskModalData } =
    usePopulateCompletePickTaskModalQuery({
      variables: { productId: modal.task.productId, taskId: modal.task.id },
    });
  const {
    product: pickProduct,
    binSources,
    licensePlateSources,
  } = useMemo(
    () => populateCompletePickTaskModalData || PopulateCompletePickTaskModal_defaultData,
    [populateCompletePickTaskModalData],
  );

  const onSubmit = (data: { source: IPickSource; quantity?: string; completedUoMId?: string }) => {
    let pickTaskUpdate: TaskCompletePickInput;
    if (sourceType === 'looseProduct') {
      pickTaskUpdate = {
        sourceBinId: data.source?.binId,
        quantity: data[quantityFieldName],
        completedInUnitOfMeasureId: data[completedUoMFieldName],
        sourceLotId: lot && lot.lotId,
      };
    } else if (sourceType === 'licensePlate') {
      pickTaskUpdate = {
        sourceBinId: data.source?.binId,
        quantity: modal.task.quantity,
        completedInUnitOfMeasureId: modal.task.unitOfMeasureId,
        licensePlateId: (data.source as LicensePlateForPickTask).licensePlateId,
      };
    }

    void completePickTask({
      variables: {
        input: {
          id: modal.task.id,
          update: pickTaskUpdate,
        },
      },
    });
  };

  const onCancel = () => {
    closeModal();
  };

  const detailsColumns = [
    {
      labelWidth: '125px',
      lineItems: [
        {
          label: t('common.product'),
          value: (
            <Typography sx={{ color: (theme) => theme.palette.info.main, fontWeight: 'bold' }}>
              {modal.task?.productCode}
            </Typography>
          ),
        },
        {
          label: t('common.description'),
          value: pickProduct?.description,
          removeBold: true,
        },
        {
          label: t('common.warehouse'),
          value: modal.deliveryDetails?.warehouse.code,
          removeBold: true,
        },
        {
          label: t('common.fulfillment', { count: 1 }),
          value: modal.deliveryDetails?.erpCode,
          removeBold: true,
        },
        {
          label: t('common.item'),
          value: modal.task?.fulfillmentItem,
          removeBold: true,
        },
      ],
    },
    {
      labelWidth: '125px',
      lineItems: [
        {
          emptyLine: true,
        },
        {
          label: t('common.stockStatus'),
          value: <StatusTypography status={modal.task.sourceStatus as StatusValues} />,
          removeBold: true,
        },
        {
          label: t('common.quantity'),
          value: modal.task?.quantity,
          removeBold: true,
        },
        {
          label: t('common.uom'),
          value: modal.task?.unitOfMeasure,
          removeBold: true,
        },
      ],
    },
  ] as IColumn[];

  const handleSourceType = (
    event: React.MouseEvent<HTMLElement>,
    newSourceType: SourceType | null,
  ) => {
    if (newSourceType) {
      setSourceType(newSourceType);
    }
  };

  const pickSources = useMemo(() => {
    return sourceType === 'looseProduct' ? binSources : licensePlateSources;
  }, [binSources, licensePlateSources, sourceType]);

  const filterOptions = useMemo(
    () =>
      createFilterOptions<IPickSource>({
        matchFrom: 'any',
        stringify: (option) =>
          sourceType === 'looseProduct'
            ? `${option.binCode}${option.lotCode ? option.lotCode : ''}`
            : `${option.binCode} ${(option as LicensePlateForPickTask).licensePlateCode}`,
      }),
    [sourceType],
  );

  const LooseProductAutocomplete = ({ field, fieldState }) => {
    return (
      <Autocomplete
        freeSolo={false}
        multiple={false}
        data-testid="looseProduct-pickSource-dropdown"
        autoHighlight={true}
        filterOptions={filterOptions}
        getOptionLabel={(option) => {
          return option?.binCode;
        }}
        options={pickSources}
        onChange={(event, item, reason) => {
          if (reason === 'selectOption' || reason === 'clear') {
            field.onChange(item);
            setLot(item.lotId ? { lotId: item.lotId, lotCode: item.lotCode } : null);
          }
        }}
        renderOption={(props, option) => (
          <Box
            component="li"
            display="flex"
            flexDirection="column"
            {...props}
            sx={{ alignItems: 'flex-start!important' }}
          >
            <Grid container spacing={2} justifyContent="space-between">
              <Grid
                item
                component="p"
                sx={{ color: (theme) => theme.palette.slateGrey.main, fontSize: '12px' }}
              >
                {t('common.qty')}: {option.quantity}
              </Grid>
              {!option.lotId ? null : (
                <Grid
                  item
                  component="p"
                  sx={{ color: (theme) => theme.palette.slateGrey.main, fontSize: '12px' }}
                >
                  {t('common.lot')}: {option.lotCode}
                </Grid>
              )}
            </Grid>
            <Typography>{option.binCode}</Typography>
          </Box>
        )}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              {...field}
              autoFocus
              required
              label={t('common.sourceBin')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              dataTestId="looseProduct-pickSource-input"
            />
          );
        }}
      />
    );
  };
  const LicensePlateAutocomplete = ({ field, fieldState }) => {
    return (
      <Autocomplete
        freeSolo={false}
        multiple={false}
        data-testid="lp-pickSource-dropdown"
        autoHighlight={true}
        filterOptions={filterOptions}
        getOptionLabel={(option: LicensePlateForPickTask) => {
          return option.licensePlateCode;
        }}
        options={pickSources}
        onChange={(event, item, reason) => {
          if (reason === 'selectOption' || reason === 'clear') {
            field.onChange(item);
          }
        }}
        renderOption={(props, option: LicensePlateForPickTask) => (
          <Box
            component="li"
            display="flex"
            flexDirection="column"
            {...props}
            sx={{ alignItems: 'flex-start!important' }}
          >
            <Grid container spacing={2} justifyContent="space-between">
              <Grid
                item
                component="p"
                sx={{ color: (theme) => theme.palette.slateGrey.main, fontSize: '12px' }}
              >
                {t('common.qty')}: {option.quantity}
              </Grid>
              <Grid
                item
                component="p"
                sx={{ color: (theme) => theme.palette.slateGrey.main, fontSize: '12px' }}
              >
                {t('common.bin')}: {option.binCode}
              </Grid>
            </Grid>
            <Typography>{option.licensePlateCode}</Typography>
          </Box>
        )}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              {...field}
              autoFocus
              required
              label={t('common.sourceLicensePlate')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              dataTestId="lp-pickSource-dropdown"
            />
          );
        }}
      />
    );
  };
  const PickSourceAutocomplete = ({ field, fieldState }) => {
    return sourceType === 'looseProduct' ? (
      <LooseProductAutocomplete field={field} fieldState={fieldState} />
    ) : (
      <LicensePlateAutocomplete field={field} fieldState={fieldState} />
    );
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <JustifiedColumns columns={detailsColumns} mb="5px" />
        <Box mb={10} />
        <ToggleButtonGroup
          exclusive
          color="primary"
          value={sourceType}
          onChange={handleSourceType}
          sx={{ marginBottom: '25px' }}
        >
          <ToggleButton value="looseProduct">{t('common.looseProduct')}</ToggleButton>
          <ToggleButton value="licensePlate">{t('common.licensePlate')}</ToggleButton>
        </ToggleButtonGroup>
        <Box>
          <Grid container spacing={6}>
            {pickSources.length > 0 ? (
              <>
                <Grid item xs={6}>
                  <Controller
                    name="source"
                    control={control}
                    rules={{
                      required:
                        sourceType === 'looseProduct'
                          ? t('form.validation.requiredField', { field: t('common.sourceBin') })
                          : t('form.validation.requiredField', {
                              field: t('common.sourceLicensePlate'),
                            }),
                    }}
                    render={(props) => {
                      const { field, fieldState } = props;
                      if (loadingResources) return <LoadingIndicator />;
                      return <PickSourceAutocomplete field={field} fieldState={fieldState} />;
                    }}
                  />
                </Grid>
                {!lot ? (
                  <Grid xs={6} />
                ) : (
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      fullWidth
                      id="srcLot"
                      label={t('common.lot')}
                      value={lot?.lotCode}
                      dataTestId="srcLot"
                    />
                  </Grid>
                )}
                {sourceType === 'looseProduct' && (
                  <FormProvider {...formMethods}>
                    <Grid item xs={6}>
                      <UoMTiedQuantity
                        fieldName={quantityFieldName}
                        fieldLabel={t('common.quantity')}
                        uomFieldName={completedUoMFieldName}
                        defaultQuantity={modal.task?.quantity}
                        availableQuantity={modal.task?.quantity}
                        defaultUomId={modal.task?.unitOfMeasureId}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <UoMSelection
                        fieldName={completedUoMFieldName}
                        fieldLabel={t('common.completedUoM')}
                        defaultUoMId={modal.task?.unitOfMeasureId}
                        productId={modal.task?.productId}
                      />
                    </Grid>
                  </FormProvider>
                )}
              </>
            ) : (
              <Grid item xs={12}>
                {sourceType === 'looseProduct' ? (
                  <Typography sx={{ fontWeight: 'bold' }}>{t('modal.task.pickNoBins')}</Typography>
                ) : (
                  <Typography sx={{ fontWeight: 'bold' }}>
                    <Trans
                      ns="components"
                      i18nKey="modal.task.pickNoLicensePlates"
                      components={getTransComponents(['break'])}
                    />
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
        </Box>
      </ModalContent>
      <ModalActions>
        <ModalButton variant="outlined" color="primary" onClick={onCancel} actionType="cancel">
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          variant="contained"
          color="primary"
          isDisabled={pickSources.length <= 0}
          actionType="submit"
        >
          {t('modal.task.completeTask')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};
