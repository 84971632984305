import i18n from '@/i18n';
import { getDateRangeDuration } from '@lib/date';

const getMinutesText = (minutes: number) =>
  i18n.t('dates.minute', { ns: 'components', count: minutes });
const getHoursText = (hours: number) => i18n.t('dates.hour', { ns: 'components', count: hours });
const getDaysText = (days: number) => i18n.t('dates.day', { ns: 'components', count: days });
const getWeeksText = (weeks: number) => i18n.t('dates.week', { ns: 'components', count: weeks });
const getMonthsText = (months: number) =>
  i18n.t('dates.month', { ns: 'components', count: months });
const getYearsText = (years: number) => i18n.t('dates.year', { ns: 'components', count: years });

const displayDeployedDuration = (startDate: string | number, endDate: string | number) => {
  if (!startDate || !endDate) return '';

  const { years, months, weeks, days, hours, minutes } = getDateRangeDuration(startDate, endDate, [
    'years',
    'months',
    'weeks',
    'days',
    'hours',
    'minutes',
  ]);

  if (years > 0) {
    const _diff = `${Math.round(years)} ${getYearsText(years)}`;
    return months > 0 ? `${_diff} ${Math.round(months)} ${getMonthsText(months)}` : _diff;
  } else if (months > 0) {
    const _diff = `${Math.round(months)} ${getMonthsText(months)}`;
    return weeks > 0 ? `${_diff} ${Math.round(weeks)} ${getWeeksText(weeks)}` : _diff;
  } else if (weeks > 0) {
    const _diff = `${Math.round(weeks)} ${getWeeksText(weeks)}`;
    return days > 0 ? `${_diff} ${Math.round(days)} ${getDaysText(days)}` : _diff;
  } else if (days > 0) {
    const _diff = `${Math.round(days)} ${getDaysText(days)}`;
    return hours > 0 ? `${_diff} ${Math.round(hours)} ${getHoursText(hours)}` : _diff;
  } else if (hours > 0) {
    const _diff = `${Math.round(hours)} ${getHoursText(hours)}`;
    return minutes > 0 ? `${_diff} ${Math.round(minutes)} ${getMinutesText(minutes)}` : _diff;
  } else {
    return `${Math.round(minutes)} ${getMinutesText(minutes)}`;
  }
};

export default displayDeployedDuration;
