/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SlottingRunDetailsFragment = { __typename?: 'SlottingRun', id?: string | null, name: string, dataset_id: string, ruleset_id: string, overall?: number | null, distance_saved?: number | null, money_saved?: number | null, created_at?: string | null, deployed_at?: string | null, deployment_ended_at?: string | null, duration?: number | null };

export type GetSlottingRunDetailsPageQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  isDeployedDetails: Types.Scalars['Boolean']['input'];
}>;


export type GetSlottingRunDetailsPageQuery = { __typename?: 'Query', slottingRun?: { __typename?: 'SlottingRun', id?: string | null, name: string, dataset_id: string, ruleset_id: string, overall?: number | null, distance_saved?: number | null, money_saved?: number | null, created_at?: string | null, deployed_at?: string | null, deployment_ended_at?: string | null, duration?: number | null }, deployedRun?: { __typename?: 'SlottingRun', id?: string | null, name: string, dataset_id: string, ruleset_id: string, overall?: number | null, distance_saved?: number | null, money_saved?: number | null, created_at?: string | null, deployed_at?: string | null, deployment_ended_at?: string | null, duration?: number | null } | null };

export type SlottingRunDatasetDetailsFragment = { __typename?: 'SlottingDataset', id: string, code: string, tag?: string | null, createdByUserName: string, pullDate: string, updatedAt?: string | null };

export type SlottingRunRulesetDetailsFragment = { __typename?: 'ViewSlottingRuleset', id?: string | null, name?: string | null, createdBy?: string | null, lastUpdated?: string | null, skipAbc?: boolean | null };

export type GetSlottingRunDatasetAndRulesetQueryVariables = Types.Exact<{
  datasetId: Types.Scalars['ID']['input'];
  rulesetId: Types.Scalars['ID']['input'];
}>;


export type GetSlottingRunDatasetAndRulesetQuery = { __typename?: 'Query', runDataset: { __typename?: 'SlottingDataset', id: string, code: string, tag?: string | null, createdByUserName: string, pullDate: string, updatedAt?: string | null }, runRuleset: { __typename?: 'ViewSlottingRulesetOffsetConnection', nodes: Array<{ __typename?: 'ViewSlottingRuleset', id?: string | null, name?: string | null, createdBy?: string | null, lastUpdated?: string | null, skipAbc?: boolean | null }> } };

export const SlottingRunDetailsFragmentDoc = gql`
    fragment SlottingRunDetails on SlottingRun {
  id
  name
  dataset_id
  ruleset_id
  overall
  distance_saved
  money_saved
  created_at
  deployed_at
  deployment_ended_at
  duration
}
    `;
export const SlottingRunDatasetDetailsFragmentDoc = gql`
    fragment SlottingRunDatasetDetails on SlottingDataset {
  id
  code
  tag
  createdByUserName
  pullDate
  updatedAt
}
    `;
export const SlottingRunRulesetDetailsFragmentDoc = gql`
    fragment SlottingRunRulesetDetails on ViewSlottingRuleset {
  id
  name
  createdBy
  lastUpdated
  skipAbc
}
    `;
export const GetSlottingRunDetailsPageDocument = gql`
    query GetSlottingRunDetailsPage($id: String!, $isDeployedDetails: Boolean!) {
  slottingRun(runId: $id) @skip(if: $isDeployedDetails) {
    ...SlottingRunDetails
  }
  deployedRun @include(if: $isDeployedDetails) {
    ...SlottingRunDetails
  }
}
    ${SlottingRunDetailsFragmentDoc}`;

/**
 * __useGetSlottingRunDetailsPageQuery__
 *
 * To run a query within a React component, call `useGetSlottingRunDetailsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSlottingRunDetailsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSlottingRunDetailsPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isDeployedDetails: // value for 'isDeployedDetails'
 *   },
 * });
 */
export function useGetSlottingRunDetailsPageQuery(baseOptions: Apollo.QueryHookOptions<GetSlottingRunDetailsPageQuery, GetSlottingRunDetailsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSlottingRunDetailsPageQuery, GetSlottingRunDetailsPageQueryVariables>(GetSlottingRunDetailsPageDocument, options);
      }
export function useGetSlottingRunDetailsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSlottingRunDetailsPageQuery, GetSlottingRunDetailsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSlottingRunDetailsPageQuery, GetSlottingRunDetailsPageQueryVariables>(GetSlottingRunDetailsPageDocument, options);
        }
export type GetSlottingRunDetailsPageQueryHookResult = ReturnType<typeof useGetSlottingRunDetailsPageQuery>;
export type GetSlottingRunDetailsPageLazyQueryHookResult = ReturnType<typeof useGetSlottingRunDetailsPageLazyQuery>;
export type GetSlottingRunDetailsPageQueryResult = Apollo.QueryResult<GetSlottingRunDetailsPageQuery, GetSlottingRunDetailsPageQueryVariables>;
export const GetSlottingRunDatasetAndRulesetDocument = gql`
    query GetSlottingRunDatasetAndRuleset($datasetId: ID!, $rulesetId: ID!) {
  runDataset: slottingDataset(id: $datasetId) {
    ...SlottingRunDatasetDetails
  }
  runRuleset: viewSlottingRulesets(
    paging: {offset: 0, limit: 1}
    filter: {id: {eq: $rulesetId}}
  ) {
    nodes {
      ...SlottingRunRulesetDetails
    }
  }
}
    ${SlottingRunDatasetDetailsFragmentDoc}
${SlottingRunRulesetDetailsFragmentDoc}`;

/**
 * __useGetSlottingRunDatasetAndRulesetQuery__
 *
 * To run a query within a React component, call `useGetSlottingRunDatasetAndRulesetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSlottingRunDatasetAndRulesetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSlottingRunDatasetAndRulesetQuery({
 *   variables: {
 *      datasetId: // value for 'datasetId'
 *      rulesetId: // value for 'rulesetId'
 *   },
 * });
 */
export function useGetSlottingRunDatasetAndRulesetQuery(baseOptions: Apollo.QueryHookOptions<GetSlottingRunDatasetAndRulesetQuery, GetSlottingRunDatasetAndRulesetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSlottingRunDatasetAndRulesetQuery, GetSlottingRunDatasetAndRulesetQueryVariables>(GetSlottingRunDatasetAndRulesetDocument, options);
      }
export function useGetSlottingRunDatasetAndRulesetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSlottingRunDatasetAndRulesetQuery, GetSlottingRunDatasetAndRulesetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSlottingRunDatasetAndRulesetQuery, GetSlottingRunDatasetAndRulesetQueryVariables>(GetSlottingRunDatasetAndRulesetDocument, options);
        }
export type GetSlottingRunDatasetAndRulesetQueryHookResult = ReturnType<typeof useGetSlottingRunDatasetAndRulesetQuery>;
export type GetSlottingRunDatasetAndRulesetLazyQueryHookResult = ReturnType<typeof useGetSlottingRunDatasetAndRulesetLazyQuery>;
export type GetSlottingRunDatasetAndRulesetQueryResult = Apollo.QueryResult<GetSlottingRunDatasetAndRulesetQuery, GetSlottingRunDatasetAndRulesetQueryVariables>;