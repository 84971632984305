import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateProductMutation } from '@/graphql/defs/components/modals/__generated__/create-product-modal.generated';
import { ModalButton } from '@components/modal';
import { ModalActions } from '@components/modal/modal-actions';
import { ModalContent } from '@components/modal/modal-content';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import { uppercase } from '@lib/form';
import FormValues from '@models/FormValues';

interface ICreateProductFields {
  code: string;
  description: string;
}

const CreateProductModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { closeModal, setLoading, depBucket, updateDepBucket } = useModalContent();
  const { selectedWarehouse } = useEntityUtils();

  const formMethods = useForm<FormValues<ICreateProductFields>>({
    defaultValues: {
      code: '',
      description: '',
    },
  });
  const { control, handleSubmit } = formMethods;
  const { notWhiteSpaceOnly } = useFormValidation();

  const [createProduct] = useCreateProductMutation({
    onCompleted: ({ createOneProduct: { id, code } }) => {
      showMessage({
        type: 'success',
        message: t('modal.product.create.success', { productCode: code }),
      });

      if (depBucket['pendingDepBucketKey']) {
        updateDepBucket(depBucket['pendingDepBucketKey'], id);
      }

      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: `${t('modal.product.create.errorCreatingProduct')} ${error.message}`,
      });
      setLoading(false);
    },
  });

  const onSubmit = (fields: ICreateProductFields) => {
    void createProduct({
      variables: {
        newProduct: {
          code: fields.code,
          description: fields.description,
          companyId: selectedWarehouse?.companyId,
          warehouseId: selectedWarehouse?.id,
        },
      },
    });
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Controller
              name="code"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.code'),
                }),
                validate: {
                  notWhiteSpaceOnly,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  required
                  id="equipment-code"
                  label={t('common.code')}
                  {...field}
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  onChange={(e) => field.onChange(uppercase(e))}
                  dataTestId="equipment-code"
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="description"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.description'),
                }),
                validate: {
                  notWhiteSpaceOnly,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  required
                  id="product-description"
                  label={t('common.description')}
                  {...field}
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  dataTestId="product-description"
                />
              )}
            />
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" actionType="submit">
          {t('common.submit')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default CreateProductModal;
