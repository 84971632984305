import { DeleteOutlined, Star, StarBorder } from '@mui/icons-material';
import { Box, styled } from '@mui/material';

import { DataTableLayoutFragment } from '@/graphql/defs/hooks/__generated__/useDataTableLayouts.generated';
import { useQueryBasedDataTable } from '@components/data-table/context/QueryBasedProvider';
import DataTableLayoutDropdownLabel from '@components/data-table/controls/layout/layout-dropdown-label';
import { TDeleteLayoutMethod } from '@components/data-table/hooks/useDataTableLayouts';
import TableIds from '@components/data-table/model/TableIds';
import { useModalToggle } from '@context/modal/ModalToggleProvider';
import { ModalTypes } from '@models/modal';

interface DeleteLayoutProps {
  tableId: TableIds;
  layout: DataTableLayoutFragment;
  handleDelete: TDeleteLayoutMethod;
}

interface LayoutVariantProps {
  layout: DataTableLayoutFragment;
}

const DeleteLayout = ({ tableId, layout, handleDelete }: DeleteLayoutProps) => {
  const { openModal } = useModalToggle();

  const openDeleteModal = () => {
    openModal({
      type: ModalTypes.tableLayoutDelete,
      tableId,
      layout: { id: layout.id, variant: layout.variant, label: layout.label },
      handleDelete,
    });
  };

  return (
    <Box
      display="inline-flex"
      onClick={(e) => {
        e.stopPropagation();
        openDeleteModal();
      }}
      sx={{ padding: (theme) => theme.spacing(0, 3), marginLeft: 'auto' }}
    >
      <DeleteOutlined color="error" />
    </Box>
  );
};

const UserLayoutVariant = ({ layout }: LayoutVariantProps) => {
  const { tableId, layoutProps } = useQueryBasedDataTable();

  return (
    <StyledLayoutOption className={layout.userDefault ? 'defaultLayout' : 'nonDefaultLayout'}>
      <Box
        className="defaultToggle"
        onClick={(e) => {
          e.stopPropagation();
          layoutProps.markLayoutAsUserDefault(layout.id, !layout.userDefault);
        }}
      >
        <Star color="primary" />
        <StarBorder color="primary" />
      </Box>
      <DataTableLayoutDropdownLabel title={layout.label}>
        {layout.label}
      </DataTableLayoutDropdownLabel>
      <DeleteLayout tableId={tableId} layout={layout} handleDelete={layoutProps.deleteLayout} />
    </StyledLayoutOption>
  );
};

export default UserLayoutVariant;

const StyledLayoutOption = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  '.defaultToggle': {
    position: 'relative',
    display: 'inline-flex',
    padding: theme.spacing(0, 3),
  },
  '&.defaultLayout': {
    '.defaultToggle': {
      '[data-testid="StarBorderIcon"]': {
        position: 'absolute',
        visibility: 'hidden',
        top: 0,
        left: theme.spacing(3),
      },
      '[data-testid="StarIcon"]': {
        visibility: 'visible',
      },
      '&:hover': {
        '[data-testid="StarBorderIcon"]': {
          visibility: 'visible',
        },
        '[data-testid="StarIcon"]': {
          visibility: 'hidden',
        },
      },
    },
  },
  '&.nonDefaultLayout': {
    '& .defaultToggle': {
      '[data-testid="StarIcon"]': {
        position: 'absolute',
        visibility: 'hidden',
        top: 0,
        left: theme.spacing(3),
      },
      '[data-testid="StarBorderIcon"]': {
        visibility: 'hidden',
      },
      '&:hover': {
        '[data-testid="StarBorderIcon"]': {
          visibility: 'hidden',
        },
        '[data-testid="StarIcon"]': {
          visibility: 'visible',
        },
      },
    },
    '&:hover': {
      '[data-testid="StarBorderIcon"]': {
        visibility: 'visible',
      },
    },
  },
}));
