import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdateBinStorageTypeMutation } from '@/graphql/defs/components/modals/__generated__/bin-storage-type-update.generated';
import { BinStorageTypeUpdateInput } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import BinStorageTypeFields from '@components/modal/bin-storage-type/bin-storage-type-fields';
import ModalForm from '@components/modal/modal-form';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { stripUnchangedFields } from '@lib/form';
import FormValues from '@models/FormValues';
import { IBinStorageTypeUpdateModal } from '@models/modal';

const BinStorageTypeUpdateModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, setLoading } = useModalContent<IBinStorageTypeUpdateModal>();
  const { showMessage } = useSnackbar();

  const defaultValues: FormValues<BinStorageTypeUpdateInput> = {
    code: modal.binStorageType.code,
    label: modal.binStorageType.label,
    description: modal.binStorageType.description,
    weightCapacityUomId: modal.binStorageType.weightCapacityUomId,
    weightCapacity: modal.binStorageType.weightCapacity,
    distanceUomId: modal.binStorageType.distanceUomId,
    depth: modal.binStorageType.depth,
    width: modal.binStorageType.width,
    height: modal.binStorageType.height,
  };
  const formReturn = useForm<FormValues<BinStorageTypeUpdateInput>>({
    defaultValues,
  });
  const { control, handleSubmit } = formReturn;

  const [updateBinStorageType] = useUpdateBinStorageTypeMutation({
    onCompleted: ({ updateOneBinStorageType: { label } }) => {
      showMessage({
        type: 'success',
        message: t('modal.binStorageTypes.update.success', { binStorageTypeLabel: label }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: t('modal.binStorageTypes.update.error', { errorMessage: error.message }),
      });
      setLoading(false);
    },
  });
  const onSubmit = (fields: BinStorageTypeUpdateInput) => {
    updateBinStorageType({
      variables: {
        id: modal.binStorageType.id,
        binStorageType: stripUnchangedFields(defaultValues, fields),
      },
    });
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formReturn}>
      <ModalContent>
        <BinStorageTypeFields control={control} />
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          variant="contained"
          color="primary"
          data-testid="update-bin-storage-type-submit"
          actionType="submit"
        >
          {t('common.submit')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default BinStorageTypeUpdateModal;
