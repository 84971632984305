import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ViewFulfillmentItemSortFields } from '@/graphql/types.generated';
import {
  ColumnFilter,
  FilterOperators,
} from '@components/data-table/controls/filter/filter-definitions';
import useCreateOutboundFulfillmentItemColumns, {
  FULFILLMENT_ITEMS_HOOK,
  TFulfillmentItemsDataType,
  TFulfillmentItemsFieldNames,
  TFulfillmentItemsFilterType,
} from '@components/data-table/hooks/shared-columns/useCreateOutboundFulfillmentItemColumns';
import useCreateDataTableColumns from '@components/data-table/hooks/useCreateDataTableColumns';
import useTriggerDataTableRefetch from '@components/data-table/hooks/useTriggerDataTableRefetch';
import { validateDataTableProps } from '@components/data-table/lib/validateDataTableProps';
import { IDataTableSort } from '@components/data-table/model/data-table';
import TableIds from '@components/data-table/model/TableIds';
import { IDropdownMenuItem } from '@components/dropdown-menu';
import { OutboundDeliveryItemsView } from '@pages/deliveries/outbound-fulfillments/tables/OutboundFulfillmentItemsInvDataTables';

const useOutboundFulfillmentItemsDataTable = (
  fulfillmentId: string,
  fulfillmentItemViews: IDropdownMenuItem<OutboundDeliveryItemsView>[] | undefined,
) => {
  const { t } = useTranslation('pages');

  const [refetchTrigger, triggerDataTableRefetch] = useTriggerDataTableRefetch();

  const createFulfillmentItemColumns = useCreateOutboundFulfillmentItemColumns({
    dataTestId: 'fulfillment-items',
    removeColumns: ['fulfillmentCode', 'fulfillmentDueDate'],
  });
  const columns = useCreateDataTableColumns<TFulfillmentItemsDataType>(
    createFulfillmentItemColumns,
  );

  const baseFilter = useMemo<ColumnFilter<TFulfillmentItemsFieldNames>[]>(
    () => [
      {
        columnId: ViewFulfillmentItemSortFields.DeliveryId,
        operator: FilterOperators.eq,
        value: fulfillmentId,
      },
    ],
    [fulfillmentId],
  );

  const defaultSorting = useMemo<IDataTableSort<TFulfillmentItemsFieldNames>[]>(
    () => [
      {
        id: ViewFulfillmentItemSortFields.Item,
        desc: false,
      },
    ],
    [],
  );

  return {
    triggerItemsDataTableRefetch: triggerDataTableRefetch,
    fulfillmentItemsDataTableProps: validateDataTableProps<
      TFulfillmentItemsDataType,
      TFulfillmentItemsFilterType,
      TFulfillmentItemsFieldNames
    >({
      type: 'query',
      tableId: TableIds.OutboundFulfillmentItems,
      tableHeader: t(`deliveries.fulfillmentItems.${OutboundDeliveryItemsView.FulfillmentItem}`),
      layoutDropdownAddtOptions: fulfillmentItemViews || undefined,
      layoutDropdownAddtOptionLabel: !!fulfillmentItemViews
        ? t('deliveries.fulfillmentItemViews')
        : undefined,
      columns,
      queryHook: FULFILLMENT_ITEMS_HOOK,
      baseFilter,
      defaultSorting,
      refetchTrigger,
    }),
  };
};

export default useOutboundFulfillmentItemsDataTable;
