import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useDeleteEquipmentMutation,
  useUpdateEquipmentMutation,
} from '@/graphql/defs/components/modals/__generated__/update-equipment-modal.generated';
import {
  EquipmentSettingsModelFilterFragment,
  useEquipmentSettingsTableFilterValuesQuery,
} from '@/graphql/defs/hooks/table-props/__generated__/useEquipmentSettingsDataTable.generated';
import { EquipmentStatus, EquipmentUpdateInput } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import enumKeys from '@lib/enum-keys';
import { stripUnchangedFields, uppercase } from '@lib/form';
import FormValues from '@models/FormValues';
import { IEquipmentUpdateModal } from '@models/modal';

interface IEquipmentUpdate extends EquipmentUpdateInput {
  equipmentTypeId: string;
}

const UpdateEquipmentModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, isPreparing, setPreparing, setLoading } =
    useModalContent<IEquipmentUpdateModal>();
  const { showMessage } = useSnackbar();

  const defaultValues: FormValues<IEquipmentUpdate> = {
    code: modal.equipment?.code || '',
    label: modal.equipment?.label || '',
    description: modal.equipment?.description || '',
    equipmentModelId: modal.equipment?.equipmentModelId || '',
    status: modal.equipment?.status || '',
    tagId: modal.equipment?.tagId || '',
    equipmentTypeId: modal.equipment?.equipmentTypeId || '',
  };
  const formMethods = useForm<FormValues<IEquipmentUpdate>>({
    defaultValues,
  });
  const { control, handleSubmit, getValues, setValue } = formMethods;
  const { notWhiteSpaceOnly } = useFormValidation();

  const [updateEquipment] = useUpdateEquipmentMutation({
    onCompleted: ({ updateOneEquipmentItem: { label } }) => {
      showMessage({
        type: 'success',
        message: t('modal.equipment.item.successUpdate', { label }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const [deleteEquipment] = useDeleteEquipmentMutation({
    onCompleted: ({ deleteOneEquipmentItem: { label } }) => {
      showMessage({
        type: 'success',
        message: t('modal.equipment.item.successDelete', { label }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const [filteredEquipmentModels, setFilteredEquipmentModels] = useState<
    EquipmentSettingsModelFilterFragment[]
  >([]);

  const {
    data: {
      equipmentTypes: { nodes: equipmentTypes },
      equipmentModels: { nodes: equipmentModels },
    },
  } = useEquipmentSettingsTableFilterValuesQuery({
    onCompleted: ({ equipmentModels: { nodes } }) => {
      const { equipmentTypeId } = getValues();
      setFilteredEquipmentModels(
        nodes.filter((model) => model.equipmentTypeId === equipmentTypeId),
      );
      setPreparing(false);
    },
    onError: (error) => {
      setPreparing(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = (fields: IEquipmentUpdate) => {
    void updateEquipment({
      variables: {
        id: modal.equipment?.id,
        updatedEquipment: stripUnchangedFields(defaultValues, {
          ...fields,
          equipmentTypeId: undefined,
        }),
      },
    });
  };

  const onDelete = () => {
    void deleteEquipment({ variables: { id: modal.equipment?.id } });
  };

  useEffect(() => {
    if (isPreparing && !modal.equipment?.equipmentTypeId) setPreparing(false);
  }, []);

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Controller
                name="code"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('equipment.columns.code'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    ref={null}
                    autoFocus
                    fullWidth
                    required
                    id="equipment-code"
                    label={t('equipment.columns.code')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={(e) => field.onChange(uppercase(e))}
                    dataTestId="equipment-code"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="label"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.label'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    ref={null}
                    fullWidth
                    required
                    id="equipment-label"
                    label={t('common.label')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="equipment-label"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    ref={null}
                    fullWidth
                    id="equipment-desc"
                    label={t('common.description')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="equipment-desc"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="status"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.status'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    ref={null}
                    fullWidth
                    required
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="equipment-status"
                    label={t('common.status')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="equipment-status"
                  >
                    <option disabled />
                    {enumKeys(EquipmentStatus).map((status) => {
                      return (
                        <option key={`equipmentStatus-${status}`} value={EquipmentStatus[status]}>
                          {status}
                        </option>
                      );
                    })}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="equipmentTypeId"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.equipmentType'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      ref={null}
                      fullWidth
                      required
                      select
                      SelectProps={{
                        native: true,
                      }}
                      id="equipment-typeId"
                      label={t('common.equipmentType')}
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setFilteredEquipmentModels(
                          equipmentModels.filter(
                            (model) => model.equipmentTypeId === e.target.value,
                          ),
                        );
                        setValue('equipmentModelId', '');
                      }}
                      dataTestId="equipment-type"
                    >
                      <option disabled />
                      {equipmentTypes.map((model) => {
                        return (
                          <option key={`equipmentType-${model.code}`} value={model.id}>
                            {model.label}
                          </option>
                        );
                      })}
                    </TextField>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="equipmentModelId"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.model'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      ref={null}
                      fullWidth
                      required
                      select
                      SelectProps={{
                        native: true,
                      }}
                      id="equipment-modelId"
                      label={t('common.model')}
                      error={!!fieldState?.error}
                      helperText={fieldState?.error?.message}
                      disabled={!filteredEquipmentModels.length}
                      dataTestId="equipment-model"
                    >
                      <option disabled />
                      {filteredEquipmentModels.map((model) => {
                        return (
                          <option key={`equipmentModel-${model.code}`} value={model.id}>
                            {model.label}
                          </option>
                        );
                      })}
                    </TextField>
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="tagId"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    ref={null}
                    fullWidth
                    id="equipment-tagId"
                    label={t('common.tagId')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="equipment-tagId"
                  />
                )}
              />
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions>
          <ModalButton
            onClick={onDelete}
            variant="outlined"
            color="error"
            actionType="delete"
            sx={{ marginRight: 'auto' }}
          >
            {t('common.delete')}
          </ModalButton>
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton variant="contained" color="primary" actionType="submit">
            {t('common.submit')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default UpdateEquipmentModal;
