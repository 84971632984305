/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import { BaseProductFragmentDoc } from '../../../shared-fragments/__generated__/product.generated';
import { UoMFragmentDoc } from '../../../shared-fragments/__generated__/uom.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PiResultProductFragment = { __typename?: 'Product', lotManaged?: boolean | null, netWeight?: number | null, grossWeight?: number | null, totalWeightUOMId?: string | null, volumeUOMId?: string | null, volume?: number | null, erpUpdatedAt?: string | null, erpCreatedAt?: string | null, createdAt: string, updatedAt?: string | null, id: string, companyId: string, code: string, description?: string | null, type?: string | null, status?: Types.ProductAvailability | null, unitOfMeasures?: { __typename?: 'UnitOfMeasureProductConversionNodes', nodes: Array<{ __typename?: 'UnitOfMeasureProductConversion', id: string, code: string, isBaseUom: boolean }> } | null };

export type GetPiResultProductQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetPiResultProductQuery = { __typename?: 'Query', product: { __typename?: 'Product', lotManaged?: boolean | null, netWeight?: number | null, grossWeight?: number | null, totalWeightUOMId?: string | null, volumeUOMId?: string | null, volume?: number | null, erpUpdatedAt?: string | null, erpCreatedAt?: string | null, createdAt: string, updatedAt?: string | null, id: string, companyId: string, code: string, description?: string | null, type?: string | null, status?: Types.ProductAvailability | null, unitOfMeasures?: { __typename?: 'UnitOfMeasureProductConversionNodes', nodes: Array<{ __typename?: 'UnitOfMeasureProductConversion', id: string, code: string, isBaseUom: boolean }> } | null } };

export const PiResultProductFragmentDoc = gql`
    fragment PIResultProduct on Product {
  ...BaseProduct
  lotManaged
  unitOfMeasures {
    nodes {
      ...UoM
    }
  }
  netWeight
  grossWeight
  totalWeightUOMId
  volumeUOMId
  volume
  erpUpdatedAt
  erpCreatedAt
  createdAt
  updatedAt
}
    ${BaseProductFragmentDoc}
${UoMFragmentDoc}`;
export const GetPiResultProductDocument = gql`
    query GetPIResultProduct($id: ID!) {
  product(id: $id) {
    ...PIResultProduct
  }
}
    ${PiResultProductFragmentDoc}`;

/**
 * __useGetPiResultProductQuery__
 *
 * To run a query within a React component, call `useGetPiResultProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPiResultProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPiResultProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPiResultProductQuery(baseOptions: Apollo.QueryHookOptions<GetPiResultProductQuery, GetPiResultProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPiResultProductQuery, GetPiResultProductQueryVariables>(GetPiResultProductDocument, options);
      }
export function useGetPiResultProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPiResultProductQuery, GetPiResultProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPiResultProductQuery, GetPiResultProductQueryVariables>(GetPiResultProductDocument, options);
        }
export type GetPiResultProductQueryHookResult = ReturnType<typeof useGetPiResultProductQuery>;
export type GetPiResultProductLazyQueryHookResult = ReturnType<typeof useGetPiResultProductLazyQuery>;
export type GetPiResultProductQueryResult = Apollo.QueryResult<GetPiResultProductQuery, GetPiResultProductQueryVariables>;