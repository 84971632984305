import { Grid } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdateDisplayPreferenceMutation } from '@/graphql/defs/components/modals/__generated__/display-preferences-modal.generated';
import { DisplayPreferenceUpdateDto } from '@/graphql/types.generated';
import { FieldBlockDisplayPreferences } from '@components/form-util';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import {
  DATE_TIME_FORMATS_CONVERT,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
} from '@constants/date-time-formats';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { stripUnchangedFields } from '@lib/form';
import FormValues from '@models/FormValues';
import { IDisplayPreferencesModal } from '@models/modal';

export interface IDisplayPreferenceFields extends DisplayPreferenceUpdateDto {
  dateFormat: string;
  timeFormat: string;
  dateTimeFormat?: never;
}

const DisplayPreferencesModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, setLoading } = useModalContent<IDisplayPreferencesModal>();
  const { showMessage } = useSnackbar();

  const defaultValues: IDisplayPreferenceFields = {
    dateFormat:
      DATE_TIME_FORMATS_CONVERT[modal?.displayPreferences?.dateTimeFormat]?.date ||
      DEFAULT_DATE_FORMAT,
    timeFormat:
      DATE_TIME_FORMATS_CONVERT[modal?.displayPreferences?.dateTimeFormat]?.time ||
      DEFAULT_TIME_FORMAT,
    currency: modal?.displayPreferences?.currency || '',
    language: modal?.displayPreferences?.language || '',
    timezone: modal?.displayPreferences?.timezone || '',
  };
  const formMethods = useForm<FormValues<IDisplayPreferenceFields>>({
    defaultValues,
  });
  const { control, handleSubmit } = formMethods;

  const [updateDisplayPreferences] = useUpdateDisplayPreferenceMutation({
    onCompleted: () => {
      showMessage({ type: 'success', message: t('modal.displayPreferences.success') });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const onSubmit = (fields: IDisplayPreferenceFields) => {
    void updateDisplayPreferences({
      variables: {
        parentId: modal.parentId,
        isOrg: modal.parentType === 'organization',
        isCompany: modal.parentType === 'company',
        isWarehouse: modal.parentType === 'warehouse',
        displayPreference: stripUnchangedFields(
          {
            ...defaultValues,
            dateTimeFormat: `${defaultValues.dateFormat} ${defaultValues.timeFormat}`,
            dateFormat: undefined,
            timeFormat: undefined,
          },
          {
            ...fields,
            dateTimeFormat: `${fields.dateFormat} ${fields.timeFormat}`,
            dateFormat: undefined,
            timeFormat: undefined,
          },
        ),
      },
    });
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <Grid container spacing={6}>
          <FieldBlockDisplayPreferences control={control} focusOnLoad />
        </Grid>
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" actionType="submit">
          {t('common.submit')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default DisplayPreferencesModal;
