import { WORLD_COUNTRIES } from '@constants/world-countries';

export const isUS = (country: string) => {
  return country === WORLD_COUNTRIES.find((c) => c.code === 'US').name;
};

export const wait = (milisec) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve('');
    }, milisec);
  });
};
