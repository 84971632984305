/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DatasetsFragment = { __typename?: 'SlottingDataset', id: string, createdAt: string, updatedAt?: string | null, updatedByEmail?: string | null, updatedById?: string | null, deletedAt?: string | null, code: string, tag?: string | null, variantName: string, variantDescription: string, processType: Types.SlottingDatasetProcessType, loadStatus: Types.SlottingLoadStatus, analysisStatus: Types.SlottingAnalysisStatus, pullDate: string, dateRangeStart: string, dateRangeEnd: string, locked: boolean, createdByUserId: string, createdByUserName: string, warehouseId: string };

export type DatasetsTableQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.SlottingDatasetFilter>;
  sorting?: Types.InputMaybe<Array<Types.SlottingDatasetSort> | Types.SlottingDatasetSort>;
  includePageInfo?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeNodes?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeTotalCount?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type DatasetsTableQuery = { __typename?: 'Query', query: { __typename?: 'SlottingDatasetOffsetConnection', totalCount?: number, pageInfo?: { __typename?: 'OffsetPageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null }, nodes?: Array<{ __typename?: 'SlottingDataset', id: string, createdAt: string, updatedAt?: string | null, updatedByEmail?: string | null, updatedById?: string | null, deletedAt?: string | null, code: string, tag?: string | null, variantName: string, variantDescription: string, processType: Types.SlottingDatasetProcessType, loadStatus: Types.SlottingLoadStatus, analysisStatus: Types.SlottingAnalysisStatus, pullDate: string, dateRangeStart: string, dateRangeEnd: string, locked: boolean, createdByUserId: string, createdByUserName: string, warehouseId: string }> } };

export const DatasetsFragmentDoc = gql`
    fragment Datasets on SlottingDataset {
  id
  createdAt
  updatedAt
  updatedByEmail
  updatedById
  deletedAt
  code
  tag
  variantName
  variantDescription
  processType
  loadStatus
  analysisStatus
  pullDate
  dateRangeStart
  dateRangeEnd
  locked
  createdByUserId
  createdByUserName
  warehouseId
}
    `;
export const DatasetsTableDocument = gql`
    query DatasetsTable($paging: OffsetPaging, $filter: SlottingDatasetFilter, $sorting: [SlottingDatasetSort!], $includePageInfo: Boolean = true, $includeNodes: Boolean = true, $includeTotalCount: Boolean = false) {
  query: slottingDatasets(sorting: $sorting, paging: $paging, filter: $filter) {
    pageInfo @include(if: $includePageInfo) {
      hasNextPage
      hasPreviousPage
    }
    nodes @include(if: $includeNodes) {
      ...Datasets
    }
    totalCount @include(if: $includeTotalCount)
  }
}
    ${DatasetsFragmentDoc}`;

/**
 * __useDatasetsTableQuery__
 *
 * To run a query within a React component, call `useDatasetsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useDatasetsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDatasetsTableQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      includePageInfo: // value for 'includePageInfo'
 *      includeNodes: // value for 'includeNodes'
 *      includeTotalCount: // value for 'includeTotalCount'
 *   },
 * });
 */
export function useDatasetsTableQuery(baseOptions?: Apollo.QueryHookOptions<DatasetsTableQuery, DatasetsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DatasetsTableQuery, DatasetsTableQueryVariables>(DatasetsTableDocument, options);
      }
export function useDatasetsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DatasetsTableQuery, DatasetsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DatasetsTableQuery, DatasetsTableQueryVariables>(DatasetsTableDocument, options);
        }
export type DatasetsTableQueryHookResult = ReturnType<typeof useDatasetsTableQuery>;
export type DatasetsTableLazyQueryHookResult = ReturnType<typeof useDatasetsTableLazyQuery>;
export type DatasetsTableQueryResult = Apollo.QueryResult<DatasetsTableQuery, DatasetsTableQueryVariables>;