import { Box, InputAdornment } from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid-pro';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useGetTaskEditBinsLazyQuery,
  useGetTaskEditLotsLazyQuery,
  useTaskEditUpdateMutation,
} from '@/graphql/defs/components/modals/__generated__/task-edit-details.generated';
import { useListAllTeamsLazyQuery } from '@/graphql/defs/list/__generated__/list-teams.generated';
import { ListAllTeams_defaultData } from '@/graphql/defs/list/list-teams';
import { BinType } from '@/graphql/types.generated';
import { DatePicker } from '@components/date-picker';
import { ModalContent } from '@components/modal';
import { ModalActions } from '@components/modal/modal-actions';
import { ModalButton } from '@components/modal/modal-button';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import VirtualAutocomplete from '@components/virtual-autocomplete';
import { TaskTypeCodes } from '@constants/task-type-config';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useFormValidation } from '@hooks/form/validators';
import { ITaskEditDetailsModal } from '@models/modal';

export const TaskEditDetailsModal = () => {
  const { t } = useTranslation('components');
  const { selectedWarehouseId } = useEntityUtils();
  const [updateTask] = useTaskEditUpdateMutation();
  const { modal, closeModal, isPreparing, setPreparing } = useModalContent<ITaskEditDetailsModal>();

  const { validDate } = useFormValidation();

  const [binList, setBinList] = useState<{ id: string; code: string }[]>([]);
  const [lotList, setLotList] = useState<{ id: string; code: string }[]>([]);

  const [getBins] = useGetTaskEditBinsLazyQuery({
    onCompleted: ({ bins: { nodes: _bins } }) => {
      setBinList(_bins);
    },
  });
  const [getLots] = useGetTaskEditLotsLazyQuery({
    onCompleted: ({ lots: { nodes: _lots } }) => {
      setLotList(_lots);
    },
  });

  const [getTeams, { data: { getTeams: { teams } } = ListAllTeams_defaultData }] =
    useListAllTeamsLazyQuery();

  const formMethods = useForm();
  const { control, reset, handleSubmit } = formMethods;

  const setInitialValues = (task) => {
    const {
      dueDate,
      assignedTeamId,
      destinationBinId,
      destinationBinCode,
      destinationLotCode,
      destinationLotId,
      sourceLotCode,
      sourceLotId,
      sourceBinCode,
      sourceBinId,
    } = task;
    const initialValues = {
      dueDate: dueDate ? DateTime.fromISO(dueDate) : null,
      team: assignedTeamId || '',
      sourceBin: sourceBinId ? { id: sourceBinId, label: sourceBinCode } : null,
      sourceLot: sourceLotId ? { id: sourceLotId, label: sourceLotCode } : null,
      destinationBin: destinationBinId ? { id: destinationBinId, label: destinationBinCode } : null,
      destinationLot: destinationLotId ? { id: destinationLotId, label: destinationLotCode } : null,
    };
    setTeam(initialValues.team);
    setDestinationBin(initialValues.destinationBin);
    setSourceBin(initialValues.sourceBin);
    setDestinationLot(initialValues.destinationLot);
    setSourceLot(initialValues.sourceLot);
    reset(initialValues);
  };

  useEffect(() => {
    Promise.all([
      getBins({
        variables: {
          filter: {
            or: [
              { type: { eq: BinType.Default }, warehouseId: { eq: selectedWarehouseId } },
              { id: { in: [modal.task.sourceBinId, modal.task.destinationBinId] } },
            ],
          },
        },
      }),
      getTeams({
        variables: {
          warehouseId: selectedWarehouseId,
        },
      }),
      getLots(),
    ]).then(() => {
      setInitialValues(modal.task);
      setPreparing(false);
    });
  }, []);

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = async ({
    sourceLot,
    sourceBin,
    team,
    dueDate,
    destinationBin,
    destinationLot,
  }) => {
    await updateTask({
      variables: {
        input: {
          id: modal.task.id,
          update: {
            sourceLotId: sourceLot?.id,
            sourceBinId: sourceBin?.id,
            dueDate: dueDate,
            destinationBinId: destinationBin?.id,
            destinationLotId: destinationLot?.id,
            assignedTeamId: team,
            createdInUnitOfMeasureId: modal.task?.unitOfMeasureId,
            quantity: modal.task?.quantity,
          },
        },
      },
    });
    closeModal({ bypassLoading: true, success: true });
  };

  const [destinationBin, setDestinationBin] = useState(null);
  const [destinationLot, setDestinationLot] = useState(null);
  const [sourceBin, setSourceBin] = useState(null);
  const [sourceLot, setSourceLot] = useState(null);
  const [team, setTeam] = useState(null);
  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent sx={{ display: 'flex', gap: (theme) => theme.spacing(8), height: '370px' }}>
          <Box
            sx={{
              display: 'flex',
              width: '300px',
              flexDirection: 'column',
              gap: (theme) => theme.spacing(8),
            }}
          >
            <Controller
              name="team"
              control={control}
              defaultValue={team}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  autoFocus
                  fullWidth
                  value={team}
                  id="task-team"
                  label={t('common.team')}
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  select
                  SelectProps={{ native: true }}
                  onChange={({ target: { value } }) => {
                    field.onChange(value || null);
                    setTeam(value || null);
                  }}
                  dataTestId="task-team"
                >
                  <option value="" />
                  {teams.map((team) => (
                    <option key={`team-${team.id}`} value={team.id}>
                      {team.name}
                    </option>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="sourceLot"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <VirtualAutocomplete
                    multiple={false}
                    autoHighlight={true}
                    isOptionEqualToValue={(a, b) => a.id === b.id}
                    options={lotList.map(({ id, code }) => ({ id, label: code }))}
                    getOptionLabel={(option) => option.label}
                    value={sourceLot}
                    onChange={(event, item, reason) => {
                      if (reason === 'selectOption' || reason === 'clear') {
                        field.onChange(item);
                        setSourceLot(item);
                      }
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          {...field}
                          variant="standard"
                          label={t('modal.task.sourceLot')}
                          error={!!fieldState?.error}
                          helperText={fieldState?.error?.message}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <GridSearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          dataTestId="sourceLot"
                        />
                      );
                    }}
                  />
                );
              }}
            />
            <Controller
              name="sourceBin"
              control={control}
              rules={{
                required: [
                  TaskTypeCodes.deliveryPutawayTask,
                  TaskTypeCodes.fulfillmentPickTask,
                  TaskTypeCodes.initialImport,
                ].includes(modal.task.taskTypeCode as TaskTypeCodes)
                  ? false
                  : t('form.validation.requiredField', { field: t('common.sourceBin') }),
              }}
              render={({ field, fieldState }) => {
                return (
                  <VirtualAutocomplete
                    multiple={false}
                    autoHighlight={true}
                    options={binList.map(({ id, code }) => ({ id, label: code }))}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(a, b) => a.id === b.id}
                    onChange={(event, item, reason) => {
                      if (reason === 'selectOption' || reason === 'clear') {
                        field.onChange(item);
                        setSourceBin(item);
                      }
                    }}
                    value={sourceBin}
                    renderInput={(params) => {
                      return (
                        <TextField
                          variant="standard"
                          {...params}
                          {...field}
                          required
                          label={t('common.sourceBin')}
                          error={!!fieldState?.error}
                          helperText={fieldState?.error?.message}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <GridSearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          dataTestId="sourceBin"
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </Box>
          <Box
            sx={{
              width: '300px',
              display: 'flex',
              flexDirection: 'column',
              gap: (theme) => theme.spacing(8),
            }}
          >
            <Controller
              name="dueDate"
              control={control}
              defaultValue=""
              rules={{
                required: t('form.validation.requiredField', { field: t('dates.dueDate') }),
                validate: {
                  validDate,
                },
              }}
              render={({ field, fieldState }) => (
                <DatePicker
                  required
                  label={t('dates.dueDate')}
                  field={field}
                  fieldState={fieldState}
                  setDate={field.onChange}
                />
              )}
            />
            <Controller
              name="destinationLot"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <VirtualAutocomplete
                    multiple={false}
                    autoHighlight={true}
                    options={lotList.map(({ id, code }) => ({ id, label: code }))}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={destinationLot}
                    onChange={(event, item, reason) => {
                      if (reason === 'selectOption' || reason === 'clear') {
                        field.onChange(item);
                        setDestinationLot(item);
                      }
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          {...field}
                          variant="standard"
                          label={t('modal.task.destinationLot')}
                          error={!!fieldState?.error}
                          helperText={fieldState?.error?.message}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <GridSearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          dataTestId="destinationLot"
                        />
                      );
                    }}
                  />
                );
              }}
            />
            <Controller
              name="destinationBin"
              control={control}
              rules={{
                required: [
                  TaskTypeCodes.deliveryPutawayTask,
                  TaskTypeCodes.fulfillmentPickTask,
                  TaskTypeCodes.initialImport,
                ].includes(modal.task.taskTypeCode as TaskTypeCodes)
                  ? false
                  : t('form.validation.requiredField', { field: t('common.destinationBin') }),
              }}
              render={({ field, fieldState }) => {
                return (
                  <VirtualAutocomplete
                    multiple={false}
                    autoHighlight={true}
                    options={binList.map(({ id, code }) => ({ id, label: code }))}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(a, b) => a.id === b.id}
                    value={destinationBin}
                    onChange={(event, item, reason) => {
                      if (reason === 'selectOption' || reason === 'clear') {
                        field.onChange(item);
                        setDestinationBin(item);
                      }
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          variant="standard"
                          {...params}
                          {...field}
                          required
                          label={t('common.destinationBin')}
                          error={!!fieldState?.error}
                          helperText={fieldState?.error?.message}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <InputAdornment position="start">
                                <GridSearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          dataTestId="destinationBin"
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </Box>
        </ModalContent>
        <ModalActions>
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton
            data-testid="taskEditDetails_submit"
            variant="contained"
            color="primary"
            actionType="submit"
          >
            {t('common.edit')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};
