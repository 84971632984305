import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useBlockFulfillmentMutation } from '@/graphql/defs/components/modals/__generated__/block-fulfillment-modal.generated';
import { useListFulfillmentBlocksQuery } from '@/graphql/defs/list/__generated__/fulfillment-blocks.generated';
import { ListFulfillmentBlocks_defaultData } from '@/graphql/defs/list/fulfillment-blocks';
import { FulfillmentBlockInput } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useModalToggle } from '@context/modal/ModalToggleProvider';
import { useSnackbar } from '@context/snackbar';
import FormValues from '@models/FormValues';
import { IBlockFulfillmentModal, ModalTypes } from '@models/modal';

const BlockFulfillmentModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { openModal } = useModalToggle();
  const { modal, closeModal, isPreparing, setPreparing, depBucket, updateDepBucket } =
    useModalContent<IBlockFulfillmentModal>();

  const formMethods = useForm<FormValues<FulfillmentBlockInput>>({
    defaultValues: {
      fulfillmentBlockId: depBucket.fulfillmentBlockId || '',
    },
  });

  const { control, handleSubmit } = formMethods;

  const [blockFulfillment] = useBlockFulfillmentMutation({
    onCompleted: async () => {
      showMessage({
        type: 'success',
        message: t('modal.fulfillmentBlock.blockFulfillment.success'),
      });
      closeModal({ bypassLoading: true, success: true });
    },
  });

  const { data: listFulfillmentBlocksData } = useListFulfillmentBlocksQuery({
    onCompleted: () => {
      setPreparing(false);
    },
  });

  const { fulfillmentBlocks } = useMemo(
    () => ({
      fulfillmentBlocks: (listFulfillmentBlocksData || ListFulfillmentBlocks_defaultData)
        ?.listFulfillmentBlocks?.fulfillmentBlocks,
      totalCount: 0,
    }),
    [listFulfillmentBlocksData],
  );

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = (fields: { fulfillmentBlockId: string }) => {
    void blockFulfillment({
      variables: {
        input: {
          fulfillmentBlockId: fields.fulfillmentBlockId,
          id: modal.fulfillmentId,
        },
      },
    });
  };

  const onCreateNewBlock = () => {
    updateDepBucket('pendingDepBucketKey', 'fulfillmentBlockId');
    openModal(
      {
        type: ModalTypes.fulfillmentBlockCreate,
      },
      {
        beforeClose: () =>
          openModal({
            type: modal.type,
            fulfillmentId: modal.fulfillmentId,
          }),
      },
    );
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          {fulfillmentBlocks.length ? (
            <>
              {t('modal.fulfillmentBlock.blockFulfillment.subtitle')}
              <Grid container spacing={6} sx={{ marginTop: '15px' }}>
                <Grid item xs={12}>
                  <Controller
                    name="fulfillmentBlockId"
                    control={control}
                    rules={{
                      required: t('form.validation.requiredField', { field: t('common.area') }),
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        autoFocus
                        fullWidth
                        required
                        select
                        SelectProps={{
                          native: true,
                        }}
                        id="fulfillment-block-id"
                        label={t('common.fulfillmentBlock')}
                        {...field}
                        error={!!fieldState?.error}
                        helperText={fieldState?.error?.message}
                        dataTestId="fulfillment-block-id"
                      >
                        <option key={`ship-to-null`} value={null}>
                          {t('common.none')}
                        </option>
                        {fulfillmentBlocks.map((fulfillmentBlock) => (
                          <option
                            key={`fulfillmentBlock-${fulfillmentBlock.id}`}
                            value={fulfillmentBlock.id}
                          >
                            {fulfillmentBlock.label}
                          </option>
                        ))}
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            t('modal.fulfillmentBlock.blockFulfillment.createBlock')
          )}
        </ModalContent>
        <ModalActions>
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          {fulfillmentBlocks.length > 0 ? (
            <ModalButton
              isDisabled={!fulfillmentBlocks.length}
              variant="contained"
              color="primary"
              actionType="submit"
            >
              {t('common.submit')}
            </ModalButton>
          ) : (
            <ModalButton onClick={onCreateNewBlock} variant="contained" color="primary">
              {t('modal.fulfillmentBlock.blockFulfillment.createNewBlock')}
            </ModalButton>
          )}
        </ModalActions>
      </ModalForm>
    )
  );
};

export default BlockFulfillmentModal;
