import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LicensePlateMovementProductInput } from '@/graphql/types.generated';
import EditableQty from '@components/editable-table/EditableQty';
import {
  EditableTableCell,
  EditableTableColumn,
  EditableTableRow,
} from '@components/editable-table/EditableTable';
import EditableUoM from '@components/editable-table/EditableUoM';
import { InventoryItem } from '@models/inventory';

export const createItemUID = (item: InventoryItem) =>
  `${item.binId}_${item.licensePlateId}_${item.productId}_${item.stockStatusTypeId}_${item.lotId}`;

export const useEditableLicensePlateTable = (itemList: InventoryItem[], editableQtyUom = true) => {
  const { t } = useTranslation('components');

  const keyedItemList = useMemo(() => {
    return itemList.map((item) => ({
      ...item,
      formKey: createItemUID(item),
    }));
  }, [itemList]);

  // Columns for the EditableTable component
  const licensePlateColumns: EditableTableColumn[] = [
    {
      label: t('common.productCodeAbbr'),
      width: '16.66%',
    },
    {
      label: t('common.description'),
      width: '25.66%',
    },
    {
      label: t('common.lot'),
      width: '13.66%',
    },
    {
      label: t('common.qty').toLowerCase(),
      width: '13.66%',
    },
    {
      label: t('common.uom'),
      width: '13.66%',
    },
    {
      label: t('common.stockStatus'),
      width: '16.66%',
    },
  ];

  // Used for react-hook-form `reset()` to seed the item quantity inputs
  const productQuantitiesUoM = useMemo(() => {
    const fieldValues = {};
    keyedItemList.forEach((item, index) => {
      const qtyKey = `${createItemUID(item)}-qty`;
      const uomKey = `${createItemUID(item)}-uom`;
      fieldValues[qtyKey] = item.availableQuantity;
      fieldValues[uomKey] = item.unitOfMeasureId;
    });
    return Object.keys(fieldValues).length ? fieldValues : null;
  }, [keyedItemList]);

  // Used in the EditableTable component to show the product item rows
  const productItems = useMemo(() => {
    const formattedItems: EditableTableRow[] = keyedItemList.map((keyedItem, i) => {
      const item = Object.assign({}, keyedItem);
      delete item.formKey;

      const itemUid = createItemUID(item);
      const cells: EditableTableCell[] = [
        {
          dataTestId: `${itemUid}-productCode`,
          value: item.productCode,
        },
        {
          dataTestId: `${itemUid}-description`,
          value: item.description,
        },
        {
          dataTestId: `${itemUid}-lotCode`,
          value: item.lotCode,
        },
        {
          dataTestId: `${itemUid}-qty`,
          value: editableQtyUom ? EditableQty : item.availableQuantity,
        },
        {
          dataTestId: `${itemUid}-uom`,
          value: editableQtyUom ? EditableUoM : item.unitOfMeasure,
        },
        {
          dataTestId: `${itemUid}-stockStatus`,
          value: item.stockStatus,
        },
      ];

      return {
        dataTestId: `item-${itemUid}`,
        item: item,
        cells: cells,
      };
    });

    return formattedItems.length ? formattedItems : null;
  }, [keyedItemList]);

  // Take react-hook-form response of edited quantities and turn it into LicensePlateMovementProductInput[]
  const createProductInput = (
    quantitiesUoMs: Record<any, any>,
  ): LicensePlateMovementProductInput[] => {
    const itemUIDs = [];
    const itemQtys: Record<string, number> = {};
    const itemUoMs: Record<string, string> = {};

    Object.keys(quantitiesUoMs).forEach((record) => {
      const itemUID = record.replace('-qty', '').replace('-uom', '');
      if (!itemUIDs.includes(itemUID)) itemUIDs.push(itemUID);
      if (record.includes('-qty')) {
        itemQtys[itemUID] = quantitiesUoMs[record];
      } else if (record.includes('-uom')) {
        itemUoMs[itemUID] = quantitiesUoMs[record];
      }
    });

    return itemUIDs
      .map((key) => {
        const item = keyedItemList.find((item) => item.formKey === key);
        const licensePlateProduct: LicensePlateMovementProductInput = {
          binId: item.binId,
          productId: item.productId,
          licensePlateId: item.licensePlateId,
          deliveryItemId: item.deliveryItemId,
          lotId: item.lotId,
          quantity: itemQtys[key].toString(),
          createdInUnitOfMeasureId: itemUoMs[key],
          stockStatusId: item.stockStatusTypeId,
        };
        return licensePlateProduct;
      })
      .filter((item) => parseFloat(item.quantity) > 0);
  };

  return {
    createProductInput,
    licensePlateColumns,
    productItems,
    productQuantitiesUoM,
  };
};
