/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddEquipmentModelMutationVariables = Types.Exact<{
  newModel: Types.EquipmentModelCreateType;
}>;


export type AddEquipmentModelMutation = { __typename?: 'Mutation', equipmentModel: { __typename?: 'EquipmentModel', id: string, code: string, label: string, description?: string | null } };


export const AddEquipmentModelDocument = gql`
    mutation AddEquipmentModel($newModel: EquipmentModelCreateType!) {
  equipmentModel: createOneEquipmentModel(input: {equipmentModel: $newModel}) {
    id
    code
    label
    description
  }
}
    `;
export type AddEquipmentModelMutationFn = Apollo.MutationFunction<AddEquipmentModelMutation, AddEquipmentModelMutationVariables>;

/**
 * __useAddEquipmentModelMutation__
 *
 * To run a mutation, you first call `useAddEquipmentModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEquipmentModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEquipmentModelMutation, { data, loading, error }] = useAddEquipmentModelMutation({
 *   variables: {
 *      newModel: // value for 'newModel'
 *   },
 * });
 */
export function useAddEquipmentModelMutation(baseOptions?: Apollo.MutationHookOptions<AddEquipmentModelMutation, AddEquipmentModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEquipmentModelMutation, AddEquipmentModelMutationVariables>(AddEquipmentModelDocument, options);
      }
export type AddEquipmentModelMutationHookResult = ReturnType<typeof useAddEquipmentModelMutation>;
export type AddEquipmentModelMutationResult = Apollo.MutationResult<AddEquipmentModelMutation>;
export type AddEquipmentModelMutationOptions = Apollo.BaseMutationOptions<AddEquipmentModelMutation, AddEquipmentModelMutationVariables>;