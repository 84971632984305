import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useDeleteBinMutation,
  useUpdateBinMutation,
} from '@/graphql/defs/components/modals/__generated__/update-bin-modal.generated';
import { BinUpdateInput } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import BinFields from '@components/modal/bin/bin-fields';
import ModalForm from '@components/modal/modal-form';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { stripUnchangedFields } from '@lib/form';
import FormValues from '@models/FormValues';
import { IBinUpdateModal } from '@models/modal';

const UpdateBinModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal, setLoading } = useModalContent<IBinUpdateModal>();

  const defaultValues: FormValues<BinUpdateInput> = {
    code: modal.bin?.code || '',
    areaId: modal.bin?.areaId || '',
    aisleId: modal.bin?.aisleId || '',
    aisleColumnId: modal.bin?.aisleColumnId || '',
    level: modal.bin?.level || '',
    x: modal.bin?.x || null,
    y: modal.bin?.y || null,
    lastCount: modal.bin?.lastCount || null,
    binStorageTypeId: modal.bin?.binStorageTypeId || null,
  };
  const formMethods = useForm<FormValues<BinUpdateInput>>({
    defaultValues,
  });
  const { handleSubmit } = formMethods;

  const [updateBin] = useUpdateBinMutation({
    onCompleted: ({ updateOneBin: { code } }) => {
      showMessage({
        type: 'success',
        message: t('modal.bin.updateBin.successUpdate', { code }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const [deleteBin] = useDeleteBinMutation({
    onCompleted: ({ deleteOneBin: { code } }) => {
      showMessage({
        type: 'success',
        message: t('modal.bin.updateBin.successDelete', { code }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = (fields: BinUpdateInput) => {
    void updateBin({
      variables: {
        id: modal.bin?.id,
        updatedBin: stripUnchangedFields(defaultValues, fields),
      },
    });
  };

  const onDelete = () => {
    void deleteBin({ variables: { id: modal.bin?.id } });
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <BinFields forceArea={!!modal.bin?.isAssignedToDoor} />
      </ModalContent>
      <ModalActions>
        <ModalButton
          data-testid="delete-bin-button"
          onClick={onDelete}
          variant="outlined"
          color="error"
          sx={{ marginRight: 'auto' }}
          actionType="delete"
        >
          {t('common.delete')}
        </ModalButton>
        <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          variant="contained"
          color="primary"
          data-testid="create-bin-submit"
          actionType="submit"
        >
          {t('common.submit')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default UpdateBinModal;
