import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useModalContent } from '@context/modal/ModalContentProvider';
import { IDispositionImageModal } from '@models/modal';

const DispositionImageModal = () => {
  const { modal, closeModal } = useModalContent<IDispositionImageModal>();
  return (
    <Box sx={{ position: 'relative' }}>
      <Image src={modal.src} />
      <Button
        sx={{ position: 'absolute', right: 30, bottom: 30 }}
        onClick={() => closeModal()}
        variant="contained"
      >
        <FullscreenExitIcon />
      </Button>
    </Box>
  );
};

const Image = styled('img')(({ theme }) => ({
  width: '800px',
  // height: '185px',
  objectFit: 'contain',
  borderRadius: 3,
  backgroundColor: theme.palette.offwhite.main,
  border: '1px solid #D8E0E5',
  padding: '10px',
  marginRight: '15px',
}));

export default DispositionImageModal;
