import { Grid, Typography } from '@mui/material';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';

export interface IStepForm {
  onBack?: boolean | (() => Promise<void>);
  onSubmit: (entity: any) => void;
  formReturn: UseFormReturn;
  processing: boolean;
  error: string | null;
  isLast?: boolean;
}
export const StepForm: React.FC<IStepForm> = ({
  children,
  onBack,
  onSubmit,
  formReturn,
  processing,
  error,
  isLast,
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'common' });

  return (
    <ModalForm onSubmit={onSubmit} formReturn={formReturn}>
      <ModalContent>
        <Grid container spacing={6}>
          {error && (
            <Grid item xs={12}>
              <Typography
                component="p"
                sx={{ color: (theme) => theme.palette.error.main, margin: 0 }}
              >
                {error}
              </Typography>
            </Grid>
          )}
          {children}
        </Grid>
      </ModalContent>
      <ModalActions>
        {onBack && typeof onBack === 'function' && (
          <ModalButton
            id="stepper-back"
            onClick={onBack}
            variant="outlined"
            color="primary"
            sx={{ marginRight: 5 }}
            isDisabled={processing}
            actionType="back"
          >
            {t('back')}
          </ModalButton>
        )}
        <ModalButton
          id="stepper-next"
          data-testid="stepper-next"
          variant="contained"
          color="primary"
          isDisabled={processing}
          actionType="submit"
        >
          {isLast ? t('submit') : t('next')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};
