import { gql } from '@apollo/client';

import { GetFulfillmentItemsForPickQuery } from '@/graphql/defs/components/modals/__generated__/pick-to-staging-create-modal.generated';


gql`
  fragment FulfillmentItemForPick on FulfillmentItemDetails {
    id
    fulfillmentItem
    licensePlateId
    licensePlateCode
    licensePlateDescription
    productId
    productCode
    productDescription
    quantity
    lotId
    lotCode
    stockStatusId
    stockStatusCode
    sourceBinId
    sourceBinCode
    destinationBinId
    destinationBinCode
  }

  query GetFulfillmentItemsForPick($fulfillmentId: ID!) {
    fulfillmentItemsToPick(id: $fulfillmentId) {
      ...FulfillmentItemForPick
    }
  }

  mutation CreatePickToStagingTasks($fulfillmentId: ID!) {
    createPickTasksForFulfillment(fulfillmentId: $fulfillmentId) {
      created {
        id
        fulfillmentItem
      }
      hasTask {
        id
        fulfillmentItem
      }
      noStockFound {
        id
        fulfillmentItem
      }
    }
  }
`;

export const GetFulfillmentItemsForPick_defaultData: GetFulfillmentItemsForPickQuery = {
  __typename: 'Query',
  fulfillmentItemsToPick: [],
};
