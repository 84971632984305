/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WarehouseDetailsFragment = { __typename?: 'Warehouse', id: string, code: string, name?: string | null, type?: Types.ModelWarehouseType | null, companyId: string };

export type UpdateWarehouseDetailsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  updatedWarehouse: Types.WarehouseUpdateInput;
}>;


export type UpdateWarehouseDetailsMutation = { __typename?: 'Mutation', updateOneWarehouse: { __typename?: 'Warehouse', id: string, code: string, name?: string | null, type?: Types.ModelWarehouseType | null, companyId: string } };

export const WarehouseDetailsFragmentDoc = gql`
    fragment WarehouseDetails on Warehouse {
  id
  code
  name
  type
  companyId
}
    `;
export const UpdateWarehouseDetailsDocument = gql`
    mutation UpdateWarehouseDetails($id: ID!, $updatedWarehouse: WarehouseUpdateInput!) {
  updateOneWarehouse(input: {id: $id, update: $updatedWarehouse}) {
    ...WarehouseDetails
  }
}
    ${WarehouseDetailsFragmentDoc}`;
export type UpdateWarehouseDetailsMutationFn = Apollo.MutationFunction<UpdateWarehouseDetailsMutation, UpdateWarehouseDetailsMutationVariables>;

/**
 * __useUpdateWarehouseDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateWarehouseDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWarehouseDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWarehouseDetailsMutation, { data, loading, error }] = useUpdateWarehouseDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updatedWarehouse: // value for 'updatedWarehouse'
 *   },
 * });
 */
export function useUpdateWarehouseDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWarehouseDetailsMutation, UpdateWarehouseDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWarehouseDetailsMutation, UpdateWarehouseDetailsMutationVariables>(UpdateWarehouseDetailsDocument, options);
      }
export type UpdateWarehouseDetailsMutationHookResult = ReturnType<typeof useUpdateWarehouseDetailsMutation>;
export type UpdateWarehouseDetailsMutationResult = Apollo.MutationResult<UpdateWarehouseDetailsMutation>;
export type UpdateWarehouseDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateWarehouseDetailsMutation, UpdateWarehouseDetailsMutationVariables>;