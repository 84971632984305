import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers';
import { DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker';
import { DateTime } from 'luxon';
import { FC, useMemo, useRef, useState } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';

import TextField from '@components/text-field';
import { useDateTime } from '@context/date-time';

interface IDateTimePicker
  extends Omit<DateTimePickerProps<DateTime, DateTime>, 'onChange' | 'renderInput' | 'value'> {
  dataTestId?: string;
  onChange?: never;
  renderInput?: never;
  filterStyle?: boolean;
  required?: boolean;
  isDisabled?: boolean;
  fullWidth?: boolean;
  setDate: (dateTime: DateTime | null) => void;
}
interface IDateTimePickerControllerBased extends IDateTimePicker {
  value?: never;
  field?: ControllerRenderProps<any, any>;
  fieldState?: ControllerFieldState;
}
interface IDateTimePickerNonControllerBased extends IDateTimePicker {
  value: DateTime | null;
  field?: never;
  fieldState?: ControllerFieldState;
}

const DateTimePickerBase: FC<
  IDateTimePickerControllerBased | IDateTimePickerNonControllerBased
> = ({
  setDate,
  value,
  field = {
    onChange: () => {
      return;
    },
    value: null,
  },
  fieldState,
  filterStyle = false,
  required = false,
  isDisabled = false,
  fullWidth = true,
  dataTestId = 'date-time-picker',
  ...props
}) => {
  const { dateTimeToWHTimezone } = useDateTime();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const formattedValue = useMemo(() => {
    if (typeof value !== 'undefined') return value;
    if (typeof field.value === 'string') {
      const formatted = DateTime.fromISO(field.value);
      field.onChange(formatted);
      return formatted;
    }
    return field.value;
  }, [value, field.value]);

  return (
    <MUIDateTimePicker
      {...props}
      {...field}
      disabled={isDisabled}
      value={formattedValue}
      onChange={(dateTime) => {
        setDate(dateTimeToWHTimezone(dateTime));
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => {
        setOpen(false);
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            ref={ref}
            required={required}
            label={props.label}
            fullWidth={fullWidth}
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
            focused={open}
            onClick={(e) => {
              setOpen(true);
              ref.current.focus();
              e.preventDefault();
            }}
            dataTestId={dataTestId}
          />
        );
      }}
    />
  );
};

export default DateTimePickerBase;
