import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useCreateOneBinToBinMovementTaskMutation,
  useDestinationBinsForBinToBinQuery,
} from '@/graphql/defs/components/modals/__generated__/bin-to-bin-movement-modal.generated';
import { DestinationBinsForBinToBin_defaultData } from '@/graphql/defs/components/modals/bin-to-bin-movement-modal';
import { useListAllTeamsQuery } from '@/graphql/defs/list/__generated__/list-teams.generated';
import { ListAllTeams_defaultData } from '@/graphql/defs/list/list-teams';
import { TaskCreateBinToBinInput, TaskStatus } from '@/graphql/types.generated';
import UoMSelection from '@components/form-util/uom-selection';
import UoMTiedQuantity from '@components/form-util/uom-tied-quantity';
import { IColumn, JustifiedColumns } from '@components/justified-columns';
import { ModalActions, ModalContent } from '@components/modal';
import { ModalButton } from '@components/modal/modal-button';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import VirtualAutocomplete from '@components/virtual-autocomplete';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import FormValues from '@models/FormValues';
import { IBinToBinMovementModal } from '@models/modal';

// TODO: Update Modal to use TaskCreateBinToBinInput
const defaultValues: FormValues<Record<string, any>> = {
  quantity: 0,
  team: null,
  taskType: null,
  destinationBin: null,
  complete: true,
};

export const BinToBinMovementModal = () => {
  const { t } = useTranslation('components');

  const { modal, closeModal, setLoading, isPreparing, setPreparing } =
    useModalContent<IBinToBinMovementModal>();

  const { inventoryItem: ii } = modal;

  const { showMessage } = useSnackbar();
  const { selectedWarehouseId } = useEntityUtils();

  const formMethods = useForm({
    defaultValues,
  });
  const { control, handleSubmit, watch } = formMethods;
  const quantityFieldName = 'quantity';
  const createdInUomFieldName = 'createdInUomId';

  const [destinationAreaId, setDestinationAreaId] = useState('');
  const [createBinToBinMovement] = useCreateOneBinToBinMovementTaskMutation({
    onCompleted: ({ createOneBinToBinMovementTask: { code, status, product, destinationBin } }) => {
      showMessage({
        type: 'success',
        message:
          status !== TaskStatus.Complete
            ? t('modal.bin.binToBin.success', { taskCode: code })
            : t('modal.bin.binToBin.successAutocomplete', {
                productCode: product?.code,
                binCode: destinationBin?.code,
              }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (err) => {
      showMessage({
        type: 'error',
        message: err.message,
      });
      setLoading(false);
    },
  });

  const {
    called: calledBins,
    loading: isLoadingBins,
    data: destinationBinsData,
  } = useDestinationBinsForBinToBinQuery({
    variables: {
      warehouseId: selectedWarehouseId,
    },
  });
  const {
    destinationBinsForTaskType: { destinationBins: binList },
  } = useMemo(
    () => destinationBinsData || DestinationBinsForBinToBin_defaultData,
    [destinationBinsData],
  );

  const {
    called: calledTeams,
    loading: isLoadingTeams,
    data: listAllTeamsData,
  } = useListAllTeamsQuery({
    variables: {
      warehouseId: selectedWarehouseId,
    },
  });
  const {
    getTeams: { teams },
  } = useMemo(() => listAllTeamsData || ListAllTeams_defaultData, [listAllTeamsData]);

  useEffect(() => {
    setPreparing(!calledBins || !calledTeams || isLoadingBins || isLoadingTeams);
  }, [calledBins, calledTeams, isLoadingBins, isLoadingTeams]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'destinationBin') {
        setDestinationAreaId(value?.destinationBin?.areaCode || '');
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = (data: any) => {
    const formData: TaskCreateBinToBinInput = {
      warehouseId: ii.warehouseId,
      productId: ii.productId,
      quantity: data[quantityFieldName],
      createdInUnitOfMeasureId: data[createdInUomFieldName],
      sourceBinId: ii.binId,
      destinationBinId: data.destinationBin?.id,
      sourceLotId: ii.lotId,
      destinationLotId: ii.lotId,
      sourceStockStatusId: ii.stockStatusTypeId,
      destinationStockStatusId: ii.stockStatusTypeId,
      autocomplete: data.complete,
      // teamId: data.team?.id, TODO: api currently doesn't allow passing team id
    };

    void createBinToBinMovement({ variables: { input: { binToBinMovement: formData } } });
  };

  const Product = () => {
    return (
      <Typography
        variant="body1"
        sx={{
          fontWeight: 700,
          color: (theme) => theme.palette.info.main,
          textTransform: 'uppercase',
        }}
      >
        {ii.productCode}
      </Typography>
    );
  };

  const Status = () => {
    const val = ii.stockStatus?.toLocaleLowerCase() === 'available';

    return (
      <Typography
        variant="body1"
        sx={{
          color: (theme) => (val ? theme.palette.success.main : theme.palette.error.main),
          textTransform: 'uppercase',
        }}
      >
        {ii.stockStatus}
      </Typography>
    );
  };

  const detailsColumns = [
    {
      labelWidth: '125px',
      lineItems: [
        {
          label: t('common.product'),
          value: <Product />,
        },
        {
          label: t('common.description'),
          value: ii.description,
        },
        {
          label: t('common.warehouse'),
          value: ii.warehouseCode,
        },
        {
          label: t('common.sourceArea'),
          value: ii.areaCode,
        },
        {
          label: t('common.sourceBin'),
          value: ii.binCode,
        },
      ],
    },
    {
      labelWidth: '125px',
      lineItems: [
        {
          emptyLine: true,
        },
        {
          label: t('common.stockStatus'),
          value: <Status />,
        },
        {
          label: t('common.lot'),
          value: ii.lotCode,
        },
        {
          label: t('common.availableQty'),
          value: ii.availableQuantity,
        },
        {
          label: t('common.uom'),
          value: ii.unitOfMeasure,
        },
      ],
    },
  ] as IColumn[];

  return isPreparing ? (
    <></>
  ) : (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent data-testid="bin-to-bin-modal">
        <Box
          sx={{ width: 600 }}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <JustifiedColumns columns={detailsColumns} mb="5px" />
          <Box mb={10} />
          <Grid container spacing={6}>
            <FormProvider {...formMethods}>
              <Grid item xs={6}>
                <UoMTiedQuantity
                  autoFocus={true}
                  fieldName={quantityFieldName}
                  fieldLabel={t('common.quantity')}
                  uomFieldName={createdInUomFieldName}
                  defaultQuantity={'0'}
                  availableQuantity={ii.availableQuantity}
                  defaultUomId={ii.unitOfMeasureId}
                />
              </Grid>
              <Grid item xs={6}>
                <UoMSelection
                  fieldName={createdInUomFieldName}
                  fieldLabel={t('common.unitOfMeasureAbbr')}
                  defaultUoMId={ii.unitOfMeasureId}
                  productId={ii.productId}
                />
              </Grid>
            </FormProvider>
            <Grid item xs={6}>
              <Controller
                name="destinationBin"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.destinationBin'),
                  }),
                }}
                render={({ field, fieldState }) => {
                  return (
                    <VirtualAutocomplete
                      freeSolo={false}
                      multiple={false}
                      getOptionLabel={(option) => option.code}
                      autoHighlight={true}
                      options={binList.filter((bin) => bin.id !== ii.binId)}
                      onChange={(event, item, reason) => {
                        if (reason === 'selectOption' || reason === 'clear') {
                          field.onChange(item);
                        }
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            {...field}
                            required
                            dataTestId="dest-bin-input"
                            label={t('common.destinationBin')}
                            error={!!fieldState?.error}
                            helperText={fieldState?.error?.message}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                dataTestId="text-dest-area"
                fullWidth
                id="destinationArea"
                label={t('common.destinationArea')}
                value={destinationAreaId}
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="complete"
                control={control}
                defaultValue={true}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox id="complete" {...field} color="primary" checked={field.value} />
                    }
                    label={t('modal.task.markAsComplete') as string}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="team"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.team') }),
                }}
                render={({ field, fieldState }) => {
                  return (
                    <Autocomplete
                      data-testid="team-dropdown"
                      freeSolo={false}
                      multiple={false}
                      getOptionLabel={(option) => option.name}
                      autoHighlight={true}
                      options={teams}
                      onChange={(event, item, reason) => {
                        if (reason === 'selectOption' || reason === 'clear') {
                          field.onChange(item);
                        }
                      }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            {...field}
                            required
                            dataTestId="team-input"
                            label={t('common.team')}
                            error={!!fieldState?.error}
                            helperText={fieldState?.error?.message}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon />
                                </InputAdornment>
                              ),
                            }}
                          />
                        );
                      }}
                    />
                  );
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </ModalContent>
      <ModalActions>
        <ModalButton variant="outlined" color="primary" onClick={onCancel} actionType="cancel">
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          data-testid="createBinToBin_submit"
          variant="contained"
          color="primary"
          actionType="submit"
        >
          {t('common.create')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};
