import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { WarehouseFragment } from '@/graphql/defs/pages/__generated__/warehouses.generated';
import { ModelWarehouseType, WarehouseCreateInput } from '@/graphql/types.generated';
import {
  contactInfoDefault,
  FieldBlockContactInfo,
  FieldBlockDisplayPreferences,
  IVStep,
  IVStepCBArgs,
  useContactInformationStep,
  useDisplayPreferencesStep,
  VerticalStepper,
} from '@components/form-util';
import { ModalContent } from '@components/modal';
import { IDisplayPreferenceFields } from '@components/modal/display-preferences/display-preferences-modal';
import {
  FieldBlockWarehouseDetails,
  useWarehouseDetailsStep,
} from '@components/modal/warehouse/form';
import { useModalContent } from '@context/modal/ModalContentProvider';
import FormValues from '@models/FormValues';
import { IWarehouseCreateModal } from '@models/modal';

const WarehouseCreateModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, setAfterClose } = useModalContent<IWarehouseCreateModal>();

  const [warehouse, setWarehouse] = useState<WarehouseFragment>();

  useEffect(() => {
    if (warehouse) {
      setAfterClose(() => {
        modal.setSelectedWarehouse(warehouse);
      });
    } else {
      setAfterClose(null);
    }
  }, [warehouse]);

  const onDepMapUpdate = (args: IVStepCBArgs) => {
    const { warehouse: depWarehouse } = args.depMap;
    if (depWarehouse?.id) setWarehouse(depWarehouse);
  };

  const onComplete = () => {
    closeModal({ bypassLoading: true, success: true });
  };

  const formSteps: IVStep[] = [
    {
      title: t('field-blocks.warehouseDetails.title'),
      onSubmit: useWarehouseDetailsStep,
      stepContent: FieldBlockWarehouseDetails,
      formHooks: useForm<FormValues<WarehouseCreateInput>>({
        defaultValues: {
          code: '',
          name: '',
          companyId: '',
          type: ModelWarehouseType.Default,
        },
      }),
      focusOnLoad: true,
    },
    {
      title: t('warehouse.contactInformation.title'),
      onBack: true,
      onSubmit: useContactInformationStep,
      stepContent: FieldBlockContactInfo,
      formHooks: useForm({ defaultValues: contactInfoDefault }),
      focusOnLoad: true,
    },
    {
      title: t('warehouse.displayPreferences.title'),
      onBack: true,
      onSubmit: useDisplayPreferencesStep,
      stepContent: FieldBlockDisplayPreferences,
      formHooks: useForm<FormValues<IDisplayPreferenceFields>>({
        defaultValues: {
          dateFormat: '',
          timeFormat: '',
          language: '',
          currency: '',
          timezone: '',
        },
      }),
      focusOnLoad: true,
    },
  ];

  const setParentType = (args: IVStepCBArgs) => {
    const { updateDepMap } = args;
    updateDepMap({ parentType: 'warehouse' });
  };

  return (
    <ModalContent>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <VerticalStepper
            steps={formSteps}
            onDepMapUpdate={onDepMapUpdate}
            onComplete={onComplete}
            beforeStart={setParentType}
          />
        </Grid>
      </Grid>
    </ModalContent>
  );
};

export default WarehouseCreateModal;
