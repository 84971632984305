/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListWarehouseRoleTypesQueryVariables = Types.Exact<{
  sorting?: Types.InputMaybe<Array<Types.WarehouseRoleTypeSort> | Types.WarehouseRoleTypeSort>;
  filter?: Types.InputMaybe<Types.WarehouseRoleTypeFilter>;
  paging?: Types.InputMaybe<Types.OffsetPaging>;
}>;


export type ListWarehouseRoleTypesQuery = { __typename?: 'Query', listWarehouseRoleTypes: { __typename?: 'WarehouseRoleTypeOffsetConnection', warehouseRoleTypes: Array<{ __typename?: 'WarehouseRoleType', id: string, createdAt: string, updatedAt?: string | null, updatedByEmail?: string | null, updatedById?: string | null, deletedAt?: string | null, code: string, label: string, description?: string | null, velocityUOMId?: string | null, velocity?: number | null, weightUOMId: string, weightMax?: number | null, currency: string, cost?: number | null }> } };


export const ListWarehouseRoleTypesDocument = gql`
    query ListWarehouseRoleTypes($sorting: [WarehouseRoleTypeSort!], $filter: WarehouseRoleTypeFilter, $paging: OffsetPaging) {
  listWarehouseRoleTypes: warehouseRoleTypes(
    sorting: $sorting
    filter: $filter
    paging: $paging
  ) {
    warehouseRoleTypes: nodes {
      id
      createdAt
      updatedAt
      updatedByEmail
      updatedById
      deletedAt
      code
      label
      description
      velocityUOMId
      velocity
      weightUOMId
      weightMax
      currency
      cost
    }
  }
}
    `;

/**
 * __useListWarehouseRoleTypesQuery__
 *
 * To run a query within a React component, call `useListWarehouseRoleTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWarehouseRoleTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWarehouseRoleTypesQuery({
 *   variables: {
 *      sorting: // value for 'sorting'
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useListWarehouseRoleTypesQuery(baseOptions?: Apollo.QueryHookOptions<ListWarehouseRoleTypesQuery, ListWarehouseRoleTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListWarehouseRoleTypesQuery, ListWarehouseRoleTypesQueryVariables>(ListWarehouseRoleTypesDocument, options);
      }
export function useListWarehouseRoleTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListWarehouseRoleTypesQuery, ListWarehouseRoleTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListWarehouseRoleTypesQuery, ListWarehouseRoleTypesQueryVariables>(ListWarehouseRoleTypesDocument, options);
        }
export type ListWarehouseRoleTypesQueryHookResult = ReturnType<typeof useListWarehouseRoleTypesQuery>;
export type ListWarehouseRoleTypesLazyQueryHookResult = ReturnType<typeof useListWarehouseRoleTypesLazyQuery>;
export type ListWarehouseRoleTypesQueryResult = Apollo.QueryResult<ListWarehouseRoleTypesQuery, ListWarehouseRoleTypesQueryVariables>;