import { useTranslation } from 'react-i18next';

import { useRemoveFulfillmentBlockMutation } from '@/graphql/defs/components/modals/__generated__/remove-fulfillment-block-modal.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { IUnblockFulfillmentModal } from '@models/modal';

const UnblockFulfillmentModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal } = useModalContent<IUnblockFulfillmentModal>();

  const [removeFulfillmentBlock] = useRemoveFulfillmentBlockMutation({
    onCompleted: async () => {
      showMessage({
        type: 'success',
        message: t('modal.fulfillmentBlock.removeFulfillmentBlock.success'),
      });
      closeModal({ bypassLoading: true, success: true });
    },
  });

  const onSubmit = () => {
    void removeFulfillmentBlock({
      variables: {
        input: {
          id: modal.fulfillmentId,
        },
      },
    });
  };

  return (
    <>
      <ModalContent>
        {t('modal.fulfillmentBlock.unblockFulfillment.subtitle', {
          label: modal.fulfillmentBlockLabel,
        })}
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          onClick={() => onSubmit()}
          variant="contained"
          color="primary"
          actionType="submit"
        >
          {t('common.submit')}
        </ModalButton>
      </ModalActions>
    </>
  );
};

export default UnblockFulfillmentModal;
