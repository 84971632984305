// Get Product UoMs
import { gql } from '@apollo/client';

import { ViewUoMsQuery } from '@/graphql/defs/shared-queries/__generated__/view-uoms.generated';

gql`
  fragment ViewUoM on ViewUnitOfMeasureProductConversion {
    id
    productId
    code
    label
    description
    conversionFactor
    height
    length
    width
    dimensionUOMId
    dimensionUOMCode
    dimensionUOMLabel
    grossWeight
    weightUOMId
    weightUOMCode
    weightUOMLabel
    volume
    volumeUOMId
    volumeUOMCode
    volumeUOMLabel
    isBaseUom
  }
`;
gql`
  query ViewUoMs(
    $filter: ViewUnitOfMeasureProductConversionFilter
    $paging: OffsetPaging
    $sorting: [ViewUnitOfMeasureProductConversionSort!]
  ) {
    unitOfMeasures: viewUnitOfMeasureProductConversions(
      filter: $filter
      paging: $paging
      sorting: $sorting
    ) {
      nodes {
        ...ViewUoM
      }
    }
  }
`;

export const ViewUoMs_defaultData: ViewUoMsQuery = {
  __typename: 'Query',
  unitOfMeasures: {
    __typename: 'ViewUnitOfMeasureProductConversionOffsetConnection',
    nodes: [],
  },
};
