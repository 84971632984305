import { gql } from '@apollo/client';

import { ListBusinessPartnersQuery } from '@/graphql/defs/list/__generated__/list-business-partners.generated';

gql`
  query ListBusinessPartners(
    $sorting: [BusinessPartnerSort!]
    $filter: BusinessPartnerFilter
    $paging: OffsetPaging
  ) {
    listBusinessPartners: businessPartners(sorting: $sorting, filter: $filter, paging: $paging) {
      businessPartners: nodes {
        id
        createdAt
        updatedAt
        updatedByEmail
        updatedById
        deletedAt
        code
        name
        type
        searchTerm1
        searchTerm2
        customerCode
        supplierCode
        validityStart
        validityEnd
        addressTimezone
        addressUUID
        cityName
        district
        region
        streetAddress
        postalCode
        country
        language
        defaultPhoneNumber
        phoneNumber
        email
      }
    }
  }
`;

export const ListBusinessPartners_defaultData: ListBusinessPartnersQuery = {
  __typename: 'Query',
  listBusinessPartners: {
    __typename: 'BusinessPartnerOffsetConnection',
    businessPartners: [],
  },
};
