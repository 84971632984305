import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SlottingDatasetSortFields } from '@/graphql/types.generated';
import {
  ColumnFilter,
  FilterOperators,
} from '@components/data-table/controls/filter/filter-definitions';
import useCreateDatasetColumns, {
  DATASETS_HOOK,
  TDatasetDataType,
  TDatasetFieldNames,
  TDatasetFilterType,
} from '@components/data-table/hooks/shared-columns/useCreateDatasetColumns';
import useCreateDataTableColumns from '@components/data-table/hooks/useCreateDataTableColumns';
import useDataTableSelection, {
  SelectionType,
} from '@components/data-table/hooks/useDataTableSelection';
import { validateDataTableProps } from '@components/data-table/lib/validateDataTableProps';
import { IDataTableSort } from '@components/data-table/model/data-table';
import TableIds from '@components/data-table/model/TableIds';
import { useEntityUtils } from '@context/entity-utils';

const useCreateRunFromRulesetDatasetsDataTable = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'datasets' });
  const { selectedWarehouseId } = useEntityUtils();

  const { selection, rowSelection } = useDataTableSelection<TDatasetDataType>(
    SelectionType.single,
    'id',
  );

  const createDatasetColumns = useCreateDatasetColumns();
  const columns = useCreateDataTableColumns(createDatasetColumns);

  const baseFilter = useMemo<ColumnFilter<TDatasetFieldNames>[]>(
    () => [
      {
        columnId: SlottingDatasetSortFields.WarehouseId,
        operator: FilterOperators.eq,
        value: selectedWarehouseId,
      },
    ],
    [selectedWarehouseId],
  );

  const defaultSorting = useMemo<IDataTableSort<TDatasetFieldNames>[]>(
    () => [
      {
        id: SlottingDatasetSortFields.PullDate,
        desc: true,
      },
    ],
    [],
  );

  return {
    selectedDataset: selection,
    dataTableProps: validateDataTableProps<
      TDatasetDataType,
      TDatasetFilterType,
      TDatasetFieldNames
    >({
      type: 'query',
      tableId: TableIds.CreateRunFromRulesetDatasets,
      tableHeader: t('title'),
      columns,
      queryHook: DATASETS_HOOK,
      baseFilter,
      defaultSorting,
      rowSelection,
    }),
  };
};

export default useCreateRunFromRulesetDatasetsDataTable;
