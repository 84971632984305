/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InboundDeliveryItemFragment = { __typename?: 'ViewDeliveryItem', id?: string | null, deliveryId: string, deliveryCode?: string | null, deliveryDueDate?: string | null, erpLastChanged?: string | null, grossWeight?: number | null, weightUOMId?: string | null, weightUOMCode?: string | null, weightUOMLabel?: string | null, item?: string | null, lotCode?: string | null, lotId?: string | null, netWeight?: number | null, productDescription?: string | null, productCode?: string | null, productId?: string | null, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, erpPurchaseOrder?: string | null, erpPurchaseOrderItem?: string | null, putawayStatus?: Types.DeliveryCompletionStatus | null, quantity?: string | null, receiptStatus?: Types.DeliveryCompletionStatus | null, recievedQuantity?: string | null, supplierLotCode?: string | null, unitOfMeasure?: string | null, unitOfMeasureId?: string | null, unloadStatus?: Types.DeliveryCompletionStatus | null, volume?: number | null, volumeUOMId?: string | null, volumeUOMCode?: string | null, volumeUOMLabel?: string | null, advancedShipmentNotificationFileId?: string | null, advancedShipmentNotificationFileCode?: string | null, updatedAt?: string | null, warehouseId: string };

export type InboundDeliveryItemsTableQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ViewDeliveryItemFilter>;
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  sorting?: Types.InputMaybe<Array<Types.ViewDeliveryItemSort> | Types.ViewDeliveryItemSort>;
  includePageInfo?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeNodes?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeTotalCount?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type InboundDeliveryItemsTableQuery = { __typename?: 'Query', query: { __typename?: 'ViewDeliveryItemOffsetConnection', totalCount?: number, pageInfo?: { __typename?: 'OffsetPageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null }, nodes?: Array<{ __typename?: 'ViewDeliveryItem', id?: string | null, deliveryId: string, deliveryCode?: string | null, deliveryDueDate?: string | null, erpLastChanged?: string | null, grossWeight?: number | null, weightUOMId?: string | null, weightUOMCode?: string | null, weightUOMLabel?: string | null, item?: string | null, lotCode?: string | null, lotId?: string | null, netWeight?: number | null, productDescription?: string | null, productCode?: string | null, productId?: string | null, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, erpPurchaseOrder?: string | null, erpPurchaseOrderItem?: string | null, putawayStatus?: Types.DeliveryCompletionStatus | null, quantity?: string | null, receiptStatus?: Types.DeliveryCompletionStatus | null, recievedQuantity?: string | null, supplierLotCode?: string | null, unitOfMeasure?: string | null, unitOfMeasureId?: string | null, unloadStatus?: Types.DeliveryCompletionStatus | null, volume?: number | null, volumeUOMId?: string | null, volumeUOMCode?: string | null, volumeUOMLabel?: string | null, advancedShipmentNotificationFileId?: string | null, advancedShipmentNotificationFileCode?: string | null, updatedAt?: string | null, warehouseId: string }> } };

export const InboundDeliveryItemFragmentDoc = gql`
    fragment InboundDeliveryItem on ViewDeliveryItem {
  id
  deliveryId
  deliveryCode
  deliveryDueDate
  erpLastChanged
  grossWeight
  weightUOMId
  weightUOMCode
  weightUOMLabel
  item
  lotCode
  lotId
  netWeight
  productDescription
  productCode
  productId
  licensePlateId
  licensePlateCode
  licensePlateDescription
  erpPurchaseOrder
  erpPurchaseOrderItem
  putawayStatus
  quantity
  receiptStatus
  recievedQuantity
  supplierLotCode
  unitOfMeasure
  unitOfMeasureId
  unloadStatus
  volume
  volumeUOMId
  volumeUOMCode
  volumeUOMLabel
  advancedShipmentNotificationFileId
  advancedShipmentNotificationFileCode
  updatedAt
  warehouseId
}
    `;
export const InboundDeliveryItemsTableDocument = gql`
    query InboundDeliveryItemsTable($filter: ViewDeliveryItemFilter, $paging: OffsetPaging, $sorting: [ViewDeliveryItemSort!], $includePageInfo: Boolean = true, $includeNodes: Boolean = true, $includeTotalCount: Boolean = false) {
  query: viewDeliveryItems(sorting: $sorting, paging: $paging, filter: $filter) {
    pageInfo @include(if: $includePageInfo) {
      hasNextPage
      hasPreviousPage
    }
    nodes @include(if: $includeNodes) {
      ...InboundDeliveryItem
    }
    totalCount @include(if: $includeTotalCount)
  }
}
    ${InboundDeliveryItemFragmentDoc}`;

/**
 * __useInboundDeliveryItemsTableQuery__
 *
 * To run a query within a React component, call `useInboundDeliveryItemsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useInboundDeliveryItemsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInboundDeliveryItemsTableQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      includePageInfo: // value for 'includePageInfo'
 *      includeNodes: // value for 'includeNodes'
 *      includeTotalCount: // value for 'includeTotalCount'
 *   },
 * });
 */
export function useInboundDeliveryItemsTableQuery(baseOptions?: Apollo.QueryHookOptions<InboundDeliveryItemsTableQuery, InboundDeliveryItemsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InboundDeliveryItemsTableQuery, InboundDeliveryItemsTableQueryVariables>(InboundDeliveryItemsTableDocument, options);
      }
export function useInboundDeliveryItemsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InboundDeliveryItemsTableQuery, InboundDeliveryItemsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InboundDeliveryItemsTableQuery, InboundDeliveryItemsTableQueryVariables>(InboundDeliveryItemsTableDocument, options);
        }
export type InboundDeliveryItemsTableQueryHookResult = ReturnType<typeof useInboundDeliveryItemsTableQuery>;
export type InboundDeliveryItemsTableLazyQueryHookResult = ReturnType<typeof useInboundDeliveryItemsTableLazyQuery>;
export type InboundDeliveryItemsTableQueryResult = Apollo.QueryResult<InboundDeliveryItemsTableQuery, InboundDeliveryItemsTableQueryVariables>;