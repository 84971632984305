import { Box, Grid, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { utils, writeFile } from 'xlsx';

import {
  RunDeployMovementsRunDetailsFragment,
  RunDeploymentMovementsDatasetFragment,
  RunDeploymentMovementsRulesetFragment,
  useDeployRunMovementsMutation,
  useGetRunDeployMovementsRunDetailsQuery,
  useGetRunDeploymentMovementsDatasetsAndRulesetsLazyQuery,
} from '@/graphql/defs/components/modals/__generated__/deploy-run-movements.generated';
import DataTable from '@components/data-table';
import { TABCAnalysisDataType } from '@components/data-table/hooks/shared-columns/useCreateSlottingAbcAnalysisColumns';
import { TSlottingMovementsDataType } from '@components/data-table/hooks/shared-columns/useCreateSlottingMovementsColumns';
import useRunDeployMovementsAbcAnalysisDataTable from '@components/data-table/hooks/table-props/useRunDeployMovementsAbcAnalysisDataTable';
import useRunDeployMovementsApprovedAbcAnalysisDataTable from '@components/data-table/hooks/table-props/useRunDeployMovementsApprovedAbcAnalysisDataTable';
import useRunDeployMovementsApprovedMovementsDataTable from '@components/data-table/hooks/table-props/useRunDeployMovementsApprovedMovementsDataTable';
import useRunDeployMovementsProposedMovementsDataTable from '@components/data-table/hooks/table-props/useRunDeployMovementsProposedMovementsDataTable';
import { ModalActions } from '@components/modal/modal-actions';
import { ModalButton } from '@components/modal/modal-button';
import { ModalContent } from '@components/modal/modal-content';
import { useAuth } from '@context/auth';
import { useDateTime } from '@context/date-time';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import displayDeployedDuration from '@lib/slotting/displayDeployedDuration';
import { IRunDeployMovementsModal } from '@models/modal';

export const RUN_DEPLOY_STEPS = [
  'runChanges',
  'abcMovements',
  'fixedBinMovements',
  'review',
] as const;

export interface ExportRef {
  exportData: () => Promise<void>;
}

const RunDeployMovementsModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, isPreparing, setPreparing, setLoading } =
    useModalContent<IRunDeployMovementsModal>();
  const { showMessage } = useSnackbar();

  const [activeStep, setActiveStep] = useState(0);
  const [deployedRunDetails, setDeployedRunDetails] =
    useState<RunDeployMovementsRunDetailsFragment>(null);
  const [proposedRunDetails, setProposedRunDetails] =
    useState<RunDeployMovementsRunDetailsFragment>(null);
  const [datasetDetails, setDatasetDetails] = useState<
    Record<string, RunDeploymentMovementsDatasetFragment>
  >({});
  const [rulesetDetails, setRulesetDetails] = useState<
    Record<string, RunDeploymentMovementsRulesetFragment>
  >({});
  const [approvedAbcAnalysis, setApprovedAbcAnalysis] = useState<TABCAnalysisDataType[]>([]);
  const [approvedFixedBinMovements, setApprovedFixedBinMovements] = useState<
    TSlottingMovementsDataType[]
  >([]);

  const [getDatasetAndRulesetDetails] = useGetRunDeploymentMovementsDatasetsAndRulesetsLazyQuery({
    onCompleted: ({
      slottingDatasets: { nodes: _slottingDatasets },
      slottingRulesets: { nodes: _slottingRulesets },
    }) => {
      const _slottingDatasetMap = _slottingDatasets.reduce((acc, dataset) => {
        acc[dataset.id] = dataset;
        return acc;
      }, {});
      const _slottingRulesetMap = _slottingRulesets.reduce((acc, ruleset) => {
        acc[ruleset.id] = ruleset;
        return acc;
      }, {});
      setDatasetDetails(_slottingDatasetMap);
      setRulesetDetails(_slottingRulesetMap);
      setPreparing(false);
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: t('modal.run.deployMovements.errorLoadingRunDetails', {
          errorMessage: error.message,
        }),
      });
      closeModal({ bypassLoading: true, bypassGuarded: true });
    },
  });

  useGetRunDeployMovementsRunDetailsQuery({
    variables: {
      runId: modal.runId,
    },
    onCompleted: ({ deployedRunDetails: { nodes }, proposedRunDetails }) => {
      setDeployedRunDetails(nodes[0]);
      setProposedRunDetails(proposedRunDetails);

      let _datasetIds = [proposedRunDetails.dataset_id];
      let _rulesetIds = [proposedRunDetails.ruleset_id];

      if (nodes[0]?.dataset_id) _datasetIds.push(nodes[0].dataset_id);
      if (nodes[0]?.ruleset_id) _rulesetIds.push(nodes[0].ruleset_id);

      getDatasetAndRulesetDetails({
        variables: {
          datasetIds: _datasetIds,
          rulesetIds: _rulesetIds,
        },
      });
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: t('modal.run.deployMovements.errorLoadingRunDetails', {
          errorMessage: error.message,
        }),
      });
      closeModal({ bypassLoading: true, bypassGuarded: true });
    },
  });

  const submitButtonText = useMemo(() => {
    switch (activeStep) {
      case RUN_DEPLOY_STEPS.length - 1:
        return t('common.submit');
      default:
        return t('common.next');
    }
  }, [activeStep]);

  const onBack = () => {
    if (activeStep === 0) {
      closeModal({ bypassGuarded: true });
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const [deployMovements] = useDeployRunMovementsMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t('modal.run.deployMovements.success', {
          runName: proposedRunDetails?.name,
          interpolation: { escapeValue: false },
        }),
      });
      closeModal({ success: true, bypassGuarded: true, bypassLoading: true });
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: t('modal.run.deployMovements.error', {
          runName: proposedRunDetails?.name,
          errorMessage: error.message,
        }),
      });
      setLoading(false);
    },
  });
  const { displayDate } = useDateTime();

  const createAbcExport = (data) => {
    if (data.length) {
      createExportFile(
        data,
        t('slotting.fulfilldAbcAnalysis') +
          `- ${modal.runName} - ${displayDate({
            date: DateTime.now().toMillis(),
            timezone: DateTime.now().zoneName,
          })}`,
        [
          { key: 'material', label: t('slotting.material') },
          { key: 'material_description', label: t('slotting.materialDescription') },
          { key: 'current_abc_indicator', label: t('slotting.currentAbcIndicator') },
          { key: 'proposed_abc_indicator', label: t('slotting.proposedAbcIndicator') },
        ],
      );
    }
  };

  const createFixedBinExport = (data) => {
    if (data.length) {
      createExportFile(
        data,
        t('slotting.fulfilldFixedBins') +
          `- ${modal.runName} - ${displayDate({
            date: DateTime.now().toMillis(),
            timezone: DateTime.now().zoneName,
          })}`,
        [
          { key: 'material', label: t('slotting.material') },
          { key: 'material_description', label: t('slotting.materialDescription') },
          { key: 'source_bin', label: t('slotting.currentFixedBin') },
          { key: 'destination_bin', label: t('slotting.proposedFixedBin') },
          { key: 'current_max_quantity', label: t('slotting.currentMaxQuantity') },
          { key: 'proposed_max_quantity', label: t('slotting.proposedMaxQuantity') },
          { key: 'current_min_quantity', label: t('slotting.currentMinQuantity') },
          { key: 'proposed_min_quantity', label: t('slotting.proposedMinQuantity') },
        ],
      );
    }
  };

  const onSubmit = () => {
    if (activeStep === RUN_DEPLOY_STEPS.length - 1) {
      createAbcExport(approvedAbcAnalysis);
      createFixedBinExport(approvedFixedBinMovements);
      deployMovements({
        variables: {
          runId: modal.runId,
          abcAnalysisIds: approvedAbcAnalysis.map(({ id }) => id),
          movementsIds: approvedFixedBinMovements.map(({ id }) => id),
        },
      });
    } else {
      setActiveStep(activeStep + 1);
      setLoading(false);
    }
  };

  return (
    !isPreparing && (
      <>
        <ModalContent>
          <Box display="flex" flexDirection="column" gap={6}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {RUN_DEPLOY_STEPS.map((label, key) => (
                <Step key={key}>
                  <StepLabel>{t(`modal.run.deployMovements.steps.${label}.label`)}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box>
              <Typography variant="h3">{t(`modal.run.deployMovements.title`)}</Typography>
              <Typography variant="body2">
                {t(`modal.run.deployMovements.steps.${RUN_DEPLOY_STEPS[activeStep]}.subtitle`)}
              </Typography>
            </Box>
            <Box>
              {/* Step 1 */}
              {activeStep === 0 && (
                <DeployMovementsStep1
                  deployedRunDetails={deployedRunDetails}
                  proposedRunDetails={proposedRunDetails}
                  datasetDetails={datasetDetails}
                  rulesetDetails={rulesetDetails}
                />
              )}
              {/* Step 2 */}
              {activeStep === 1 && (
                <DeployMovementsStep2
                  runId={modal.runId}
                  currentlyApprovedAbcAnalysis={approvedAbcAnalysis}
                  setApprovedAbcAnalysis={setApprovedAbcAnalysis}
                />
              )}
              {/* Step 3 */}
              {activeStep === 2 && (
                <DeployMovementsStep3
                  runId={modal.runId}
                  currentlyApprovedFixedBinMovements={approvedFixedBinMovements}
                  setApprovedFixedBinMovements={setApprovedFixedBinMovements}
                />
              )}
              {/* Step 4 */}
              {activeStep === 3 && (
                <DeployMovementsStep4
                  approvedAbcAnalysis={approvedAbcAnalysis}
                  approvedFixedBinMovements={approvedFixedBinMovements}
                />
              )}
            </Box>
          </Box>
        </ModalContent>
        <ModalActions>
          <ModalButton onClick={onBack} variant="outlined" color="primary" actionType="cancel">
            {t('common.back')}
          </ModalButton>
          <ModalButton
            onClick={onSubmit}
            id="submit"
            variant="contained"
            color="primary"
            data-testid="run-deploy-movements-modal-submit"
            actionType="submit"
          >
            {submitButtonText}
          </ModalButton>
        </ModalActions>
      </>
    )
  );
};

export default RunDeployMovementsModal;

const Step1RunDetails = ({
  runDetails,
  datasetDetails,
  rulesetDetails,
  runType,
}: {
  runDetails: RunDeployMovementsRunDetailsFragment;
  datasetDetails: RunDeploymentMovementsDatasetFragment;
  rulesetDetails: RunDeploymentMovementsRulesetFragment;
  runType: 'deployed' | 'proposed';
}) => {
  const { t } = useTranslation('components');
  const { displayDateTime } = useDateTime();
  const { user } = useAuth();
  const deployed = runType === 'deployed';

  const deployedBy = deployed
    ? runDetails?.deployed_by_user_name
    : `${user?.firstName} ${user?.lastName}`;
  const deployedAtDate = deployed
    ? displayDateTime({ date: runDetails?.deployed_at })
    : t('slotting.pendingDeployment');
  const deployDuration = deployed
    ? displayDeployedDuration(runDetails?.deployed_at, Date.now())
    : t('slotting.pendingDeployment');

  return (
    <Box display="grid" gridTemplateColumns="auto 1fr" columnGap={10} rowGap={3}>
      <Typography variant="h4">{t(`slotting.run`)}:</Typography>
      <Typography variant="body2">{runDetails?.name}</Typography>
      <Typography variant="body2">{t(`slotting.deployedBy`)}:</Typography>
      <Typography variant="body2">{deployedBy}</Typography>
      <Typography variant="body2">{t(`dates.createdDate`)}:</Typography>
      <Typography variant="body2">{displayDateTime({ date: runDetails?.created_at })}</Typography>

      <Typography sx={{ visibility: deployed ? 'visible' : 'hidden' }} variant="body2">
        {t(`slotting.deployed`)}:
      </Typography>
      <Typography sx={{ visibility: deployed ? 'visible' : 'hidden' }} variant="body2">
        {deployedAtDate}
      </Typography>
      <Typography sx={{ visibility: deployed ? 'visible' : 'hidden' }} variant="body2">
        {t(`dates.duration`)}:
      </Typography>
      <Typography sx={{ visibility: deployed ? 'visible' : 'hidden' }} variant="body2">
        {deployDuration}
      </Typography>

      <Typography variant="h4" mt={3}>
        {t(`common.dataSet`, { count: 1 })}:
      </Typography>
      <Typography variant="body2" mt={3}>
        {datasetDetails?.code}
      </Typography>
      <Typography variant="body2">{t(`common.tag`)}:</Typography>
      <Typography variant="body2">{datasetDetails?.tag}:</Typography>
      <Typography variant="body2">{t(`slotting.deployedDate`)}:</Typography>
      <Typography variant="body2">{displayDateTime({ date: datasetDetails?.pullDate })}</Typography>
      <Typography variant="body2">{t(`slotting.whoDeployed`)}:</Typography>
      <Typography variant="body2">{datasetDetails?.createdByUserName}</Typography>
      {/* <Typography variant="body2">{t(`slotting.running`)}:</Typography>
      <Typography variant="body2">Blah</Typography> */}
      <Typography variant="body2">{t(`slotting.dataRange`)}:</Typography>
      <Typography variant="body2">
        {displayDateTime({ date: datasetDetails?.dateRangeStart })}
        {' - '}
        {displayDateTime({ date: datasetDetails?.dateRangeEnd })}
      </Typography>
      <Typography variant="h4" mt={3}>
        {t(`common.ruleSet`, { count: 1 })}:
      </Typography>
      <Typography variant="body2" mt={3}>
        {rulesetDetails?.name}
      </Typography>
    </Box>
  );
};

const DeployMovementsStep1 = ({
  deployedRunDetails,
  proposedRunDetails,
  datasetDetails,
  rulesetDetails,
}: {
  deployedRunDetails: RunDeployMovementsRunDetailsFragment;
  proposedRunDetails: RunDeployMovementsRunDetailsFragment;
  datasetDetails: Record<string, RunDeploymentMovementsDatasetFragment>;
  rulesetDetails: Record<string, RunDeploymentMovementsRulesetFragment>;
}) => {
  const { t } = useTranslation('components');

  return (
    <Box px={(theme) => theme.spacing(8)}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box display="flex" flexDirection="column" gap={6}>
            <Typography variant="h4">
              {t(`modal.run.deployMovements.steps.${RUN_DEPLOY_STEPS[0]}.currentRun`)}
            </Typography>
            {deployedRunDetails?.id ? (
              <Step1RunDetails
                runType="deployed"
                runDetails={deployedRunDetails}
                datasetDetails={datasetDetails[deployedRunDetails?.dataset_id]}
                rulesetDetails={rulesetDetails[deployedRunDetails?.ruleset_id]}
              />
            ) : (
              <Typography variant="body2">
                {t(`modal.run.deployMovements.steps.${RUN_DEPLOY_STEPS[0]}.noRunCurrentlyDeployed`)}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h4" mb={6}>
            {t(`modal.run.deployMovements.steps.${RUN_DEPLOY_STEPS[0]}.proposedRun`)}
          </Typography>
          <Step1RunDetails
            runType="proposed"
            runDetails={proposedRunDetails}
            datasetDetails={datasetDetails[proposedRunDetails?.dataset_id]}
            rulesetDetails={rulesetDetails[proposedRunDetails?.ruleset_id]}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const DeployMovementsStep2 = ({
  runId,
  setApprovedAbcAnalysis,
  currentlyApprovedAbcAnalysis,
}: {
  runId: string;
  setApprovedAbcAnalysis: Dispatch<SetStateAction<TABCAnalysisDataType[]>>;
  currentlyApprovedAbcAnalysis: TABCAnalysisDataType[];
}) => {
  const {
    approvedAbcAnalysis,
    setCurrentlyApprovedAbcAnalysis,
    handleRemoveApproved,
    dataTableProps: abcAnalysisDataTableProps,
  } = useRunDeployMovementsAbcAnalysisDataTable({ runId });

  const { dataTableProps: approvedAbcAnalysisDataTableProps } =
    useRunDeployMovementsApprovedAbcAnalysisDataTable({
      handleRemoveApproved,
      approvedAbcAnalysisData: approvedAbcAnalysis,
    });

  useEffect(() => {
    setCurrentlyApprovedAbcAnalysis(currentlyApprovedAbcAnalysis);
  }, []);

  useEffect(() => {
    setApprovedAbcAnalysis(approvedAbcAnalysis);
  }, [approvedAbcAnalysis]);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box sx={{ maxWidth: '100%', border: '1px solid #D8E0E5', borderRadius: '4px' }}>
        <DataTable {...abcAnalysisDataTableProps} />
      </Box>
      <Box sx={{ maxWidth: '100%', border: '1px solid #D8E0E5', borderRadius: '4px' }}>
        <DataTable {...approvedAbcAnalysisDataTableProps} />
      </Box>
    </Box>
  );
};

const DeployMovementsStep3 = ({
  runId,
  setApprovedFixedBinMovements,
  currentlyApprovedFixedBinMovements,
}: {
  runId: string;
  setApprovedFixedBinMovements: Dispatch<SetStateAction<TSlottingMovementsDataType[]>>;
  currentlyApprovedFixedBinMovements: TSlottingMovementsDataType[];
}) => {
  const {
    approvedMovements,
    setCurrentlyApprovedMovements,
    handleRemoveApproved,
    dataTableProps: movementsDataTableProps,
  } = useRunDeployMovementsProposedMovementsDataTable({ runId });
  const { dataTableProps: approvedMovementsDataTableProps } =
    useRunDeployMovementsApprovedMovementsDataTable({ handleRemoveApproved, approvedMovements });

  useEffect(() => {
    setCurrentlyApprovedMovements(currentlyApprovedFixedBinMovements);
  }, []);

  useEffect(() => {
    setApprovedFixedBinMovements(approvedMovements);
  }, [approvedMovements]);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box sx={{ maxWidth: '100%', border: '1px solid #D8E0E5', borderRadius: '4px' }}>
        <DataTable {...movementsDataTableProps} />
      </Box>
      <Box sx={{ maxWidth: '100%', border: '1px solid #D8E0E5', borderRadius: '4px' }}>
        <DataTable {...approvedMovementsDataTableProps} />
      </Box>
    </Box>
  );
};

const DeployMovementsStep4 = ({
  approvedAbcAnalysis,
  approvedFixedBinMovements,
}: {
  approvedAbcAnalysis: TABCAnalysisDataType[];
  approvedFixedBinMovements: TSlottingMovementsDataType[];
}) => {
  const { dataTableProps: approvedAbcAnalysisDataTableProps } =
    useRunDeployMovementsApprovedAbcAnalysisDataTable({
      approvedAbcAnalysisData: approvedAbcAnalysis,
    });

  const { dataTableProps: approvedMovementsDataTableProps } =
    useRunDeployMovementsApprovedMovementsDataTable({
      approvedMovements: approvedFixedBinMovements,
    });

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box sx={{ maxWidth: '100%', border: '1px solid #D8E0E5', borderRadius: '4px' }}>
        <DataTable {...approvedAbcAnalysisDataTableProps} />
      </Box>
      <Box sx={{ maxWidth: '100%', border: '1px solid #D8E0E5', borderRadius: '4px' }}>
        <DataTable {...approvedMovementsDataTableProps} />
      </Box>
    </Box>
  );
};

export const createExportFile = (
  data: any[],
  name: string,
  columns: { label: string; key: string }[],
) => {
  const headers = columns.map(({ label }) => label);
  const processedData = data.map((row) =>
    columns.map((column) => {
      return row[column.key];
    }),
  );
  const dataWithHeaders = [headers, ...processedData];

  /* convert state to workbook */
  const ws = utils.aoa_to_sheet(dataWithHeaders);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, 'table 1');

  // /* generate XLSX file and send to client */
  writeFile(wb, `${name}.xlsx`);
};
