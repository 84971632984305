import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers';
import { DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker/DateTimePicker';
import { DateTime } from 'luxon';
import { FC, useMemo } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';

import { ColumnFilterBetweenValue } from '@components/data-table/controls/filter/filter-definitions';
import TextField from '@components/text-field';
import { useDateTime } from '@context/date-time';

interface IDateTimePicker
  extends Omit<DateTimePickerProps<DateTime, DateTime>, 'onChange' | 'renderInput' | 'value'> {
  dataTestId?: string;
  onChange?: never;
  renderInput?: never;
  filterStyle?: boolean;
  required?: boolean;
  isDisabled?: boolean;
  fullWidth?: boolean;
  setDate: (dateTime: DateTime | null) => void;
}
interface IDateTimePickerControllerBased extends IDateTimePicker {
  value?: never;
  field?: ControllerRenderProps<any, any>;
  fieldState?: ControllerFieldState;
}
interface IDateTimePickerNonControllerBased extends IDateTimePicker {
  value: DateTime | ColumnFilterBetweenValue<DateTime> | null;
  field?: never;
  fieldState?: never;
}

const DateTimePicker: FC<IDateTimePickerControllerBased | IDateTimePickerNonControllerBased> = ({
  setDate,
  value,
  field,
  fieldState,
  filterStyle = false,
  required = false,
  isDisabled = false,
  fullWidth = true,
  label = undefined,
  dataTestId = 'date-time-picker',
  ...props
}) => {
  const { dateTimeToWHTimezone } = useDateTime();

  const formattedValue = useMemo(() => {
    if (typeof value !== 'undefined' && value !== null) {
      if (value instanceof DateTime) return value;
      if ('lower' in value) {
        return value.lower;
      }
    }
    if (typeof field?.value === 'string') {
      const formatted = DateTime.fromISO(field.value);
      if (typeof field?.onChange === 'function') field.onChange(formatted);
      return formatted;
    }
    return field?.value || null;
  }, [value, field?.value]);

  return (
    <MUIDateTimePicker
      {...props}
      {...field}
      value={formattedValue}
      disabled={isDisabled}
      onChange={(dateTime) => {
        setDate(dateTimeToWHTimezone(dateTime));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label}
          fullWidth={fullWidth}
          error={!!fieldState?.error}
          helperText={fieldState?.error?.message}
          dataTestId={dataTestId}
        />
      )}
    />
  );
};

export default DateTimePicker;
