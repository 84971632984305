import { useQueryBasedDataTable } from '@components/data-table/context/QueryBasedProvider';
import DataTableTableStructure from '@components/data-table/table/TableStructure';

const DataTableQueryBasedTable = () => {
  const {
    tableId,
    tableSize,
    headerColumns,
    dataRows,
    selectedRows,
    hasCompletedFirstFetch,
    isDataTableLoading,
  } = useQueryBasedDataTable();

  return (
    <DataTableTableStructure
      tableId={tableId}
      tableSize={tableSize}
      headerColumns={headerColumns}
      dataRows={dataRows}
      selectedRows={selectedRows}
      hasCompletedFirstFetch={hasCompletedFirstFetch}
      isDataTableLoading={isDataTableLoading}
    />
  );
};

export default DataTableQueryBasedTable;
