import { gql } from '@apollo/client';

import {
  ListAllBinsByAreaQuery,
  ListAllNonAssignedBinsInAreaIncludingCurrentBinQuery,
  ListAllNonAssignedBinsInAreaQuery,
} from '@/graphql/defs/list/__generated__/list-bins.generated';

gql`
  fragment ListAllBinsByArea on ViewBin {
    id
    areaCode
    code
  }
  query ListAllBinsByArea($warehouseId: ID!) {
    viewBins(
      paging: { offset: 0, limit: 1000000 }
      filter: { warehouseId: { eq: $warehouseId } }
      sorting: [{ field: areaCode, direction: ASC }, { field: code, direction: ASC }]
    ) {
      binsList: nodes {
        ...ListAllBinsByArea
      }
    }
  }
`;

export const ListAllBinsByArea_defaultData: ListAllBinsByAreaQuery = {
  __typename: 'Query',
  viewBins: {
    __typename: 'ViewBinOffsetConnection',
    binsList: [],
  },
};

gql`
  query ListAllNonAssignedBinsInArea($areaId: ID!) {
    getBins: viewBins(
      filter: { areaId: { eq: $areaId }, isAssignedToDoor: { is: false } }
      sorting: [{ field: code, direction: ASC }]
    ) {
      binsList: nodes {
        id
        code
      }
    }
  }
`;

export const ListAllNonAssignedBinsInArea_defaultData: ListAllNonAssignedBinsInAreaQuery = {
  __typename: 'Query',
  getBins: {
    __typename: 'ViewBinOffsetConnection',
    binsList: [],
  },
};

gql`
  query ListAllNonAssignedBinsInAreaIncludingCurrentBin($areaId: ID!, $currentBinId: ID!) {
    getBins: viewBins(
      filter: {
        or: [
          { areaId: { eq: $areaId }, isAssignedToDoor: { is: false } }
          { id: { eq: $currentBinId } }
        ]
      }
      sorting: [{ field: code, direction: ASC }]
    ) {
      binsList: nodes {
        id
        code
      }
    }
  }
`;

export const ListAllNonAssignedBinsInAreaIncludingCurrentBin_defaultData: ListAllNonAssignedBinsInAreaIncludingCurrentBinQuery =
  {
    __typename: 'Query',
    getBins: {
      __typename: 'ViewBinOffsetConnection',
      binsList: [],
    },
  };
