/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PopulateLostAndFoundLpModalQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PopulateLostAndFoundLpModalQuery = { __typename?: 'Query', getProductList: { __typename?: 'ProductOffsetConnection', products: Array<{ __typename?: 'Product', id: string, code: string, description?: string | null, unitOfMeasures?: { __typename?: 'UnitOfMeasureProductConversionNodes', nodes: Array<{ __typename?: 'UnitOfMeasureProductConversion', id: string, code: string, isBaseUom: boolean }> } | null }> }, getStockStatusList: { __typename?: 'StockStatusTypeOffsetConnection', stockStatuses: Array<{ __typename?: 'StockStatusType', id: string, label: string }> }, getFulfillments: { __typename?: 'DeliveryOffsetConnection', fulfillments: Array<{ __typename?: 'Delivery', id: string, erpCode: string }> } };

export type UpdateLostAndFoundLpStockMutationVariables = Types.Exact<{
  lpStock: Types.CreateAndCompleteOneFoundStockTaskDto;
}>;


export type UpdateLostAndFoundLpStockMutation = { __typename?: 'Mutation', updateLostAndFoundLPStock: { __typename?: 'Task', id: string, code: string } };


export const PopulateLostAndFoundLpModalDocument = gql`
    query PopulateLostAndFoundLPModal {
  getProductList: products(sorting: [{field: code, direction: ASC}]) {
    products: nodes {
      id
      code
      description
      unitOfMeasures {
        nodes {
          id
          code
          isBaseUom
        }
      }
    }
  }
  getStockStatusList: stockStatusTypes(sorting: [{field: label, direction: ASC}]) {
    stockStatuses: nodes {
      id
      label
    }
  }
  getFulfillments: deliveries(
    filter: {type: {eq: fulfillment}, deliveryStatus: {neq: complete}}
  ) {
    fulfillments: nodes {
      id
      erpCode
    }
  }
}
    `;

/**
 * __usePopulateLostAndFoundLpModalQuery__
 *
 * To run a query within a React component, call `usePopulateLostAndFoundLpModalQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopulateLostAndFoundLpModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopulateLostAndFoundLpModalQuery({
 *   variables: {
 *   },
 * });
 */
export function usePopulateLostAndFoundLpModalQuery(baseOptions?: Apollo.QueryHookOptions<PopulateLostAndFoundLpModalQuery, PopulateLostAndFoundLpModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PopulateLostAndFoundLpModalQuery, PopulateLostAndFoundLpModalQueryVariables>(PopulateLostAndFoundLpModalDocument, options);
      }
export function usePopulateLostAndFoundLpModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PopulateLostAndFoundLpModalQuery, PopulateLostAndFoundLpModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PopulateLostAndFoundLpModalQuery, PopulateLostAndFoundLpModalQueryVariables>(PopulateLostAndFoundLpModalDocument, options);
        }
export type PopulateLostAndFoundLpModalQueryHookResult = ReturnType<typeof usePopulateLostAndFoundLpModalQuery>;
export type PopulateLostAndFoundLpModalLazyQueryHookResult = ReturnType<typeof usePopulateLostAndFoundLpModalLazyQuery>;
export type PopulateLostAndFoundLpModalQueryResult = Apollo.QueryResult<PopulateLostAndFoundLpModalQuery, PopulateLostAndFoundLpModalQueryVariables>;
export const UpdateLostAndFoundLpStockDocument = gql`
    mutation UpdateLostAndFoundLPStock($lpStock: CreateAndCompleteOneFoundStockTaskDto!) {
  updateLostAndFoundLPStock: createAndCompleteOneFoundStockTask(
    input: {createAndCompleteOneFoundStockTaskDto: $lpStock}
  ) {
    id
    code
  }
}
    `;
export type UpdateLostAndFoundLpStockMutationFn = Apollo.MutationFunction<UpdateLostAndFoundLpStockMutation, UpdateLostAndFoundLpStockMutationVariables>;

/**
 * __useUpdateLostAndFoundLpStockMutation__
 *
 * To run a mutation, you first call `useUpdateLostAndFoundLpStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLostAndFoundLpStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLostAndFoundLpStockMutation, { data, loading, error }] = useUpdateLostAndFoundLpStockMutation({
 *   variables: {
 *      lpStock: // value for 'lpStock'
 *   },
 * });
 */
export function useUpdateLostAndFoundLpStockMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLostAndFoundLpStockMutation, UpdateLostAndFoundLpStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLostAndFoundLpStockMutation, UpdateLostAndFoundLpStockMutationVariables>(UpdateLostAndFoundLpStockDocument, options);
      }
export type UpdateLostAndFoundLpStockMutationHookResult = ReturnType<typeof useUpdateLostAndFoundLpStockMutation>;
export type UpdateLostAndFoundLpStockMutationResult = Apollo.MutationResult<UpdateLostAndFoundLpStockMutation>;
export type UpdateLostAndFoundLpStockMutationOptions = Apollo.BaseMutationOptions<UpdateLostAndFoundLpStockMutation, UpdateLostAndFoundLpStockMutationVariables>;