/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BinsTableRowFragment = { __typename?: 'ViewBin', id?: string | null, areaCode?: string | null, areaId?: string | null, areaName?: string | null, code?: string | null, binStorageTypeId?: string | null, binStorageTypeCode?: string | null, binStorageTypeDepth?: number | null, binStorageTypeWidth?: number | null, binStorageTypeHeight?: number | null, binStorageTypeWeightCapacity?: number | null, distanceUomCode?: string | null, weightCapacityUomCode?: string | null, containsProducts?: boolean | null, destinationBinBlock?: Types.BinBlockState | null, hasOpenTasks?: boolean | null, inactive?: Types.BinActiveState | null, lastCount?: string | null, lastMovement?: string | null, level?: number | null, sourceBinBlock?: Types.BinBlockState | null, verificationCode?: string | null, warehouseId?: string | null, warehouseCode?: string | null, x?: number | null, y?: number | null, isAssignedToDoor?: boolean | null };

export type BinsTableQueryVariables = Types.Exact<{
  sorting?: Types.InputMaybe<Array<Types.ViewBinSort> | Types.ViewBinSort>;
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.ViewBinFilter>;
  includePageInfo?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeNodes?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeTotalCount?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type BinsTableQuery = { __typename?: 'Query', query: { __typename?: 'ViewBinOffsetConnection', totalCount?: number, pageInfo?: { __typename?: 'OffsetPageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null }, nodes?: Array<{ __typename?: 'ViewBin', id?: string | null, areaCode?: string | null, areaId?: string | null, areaName?: string | null, code?: string | null, binStorageTypeId?: string | null, binStorageTypeCode?: string | null, binStorageTypeDepth?: number | null, binStorageTypeWidth?: number | null, binStorageTypeHeight?: number | null, binStorageTypeWeightCapacity?: number | null, distanceUomCode?: string | null, weightCapacityUomCode?: string | null, containsProducts?: boolean | null, destinationBinBlock?: Types.BinBlockState | null, hasOpenTasks?: boolean | null, inactive?: Types.BinActiveState | null, lastCount?: string | null, lastMovement?: string | null, level?: number | null, sourceBinBlock?: Types.BinBlockState | null, verificationCode?: string | null, warehouseId?: string | null, warehouseCode?: string | null, x?: number | null, y?: number | null, isAssignedToDoor?: boolean | null }> } };

export const BinsTableRowFragmentDoc = gql`
    fragment BinsTableRow on ViewBin {
  id
  areaCode
  areaId
  areaName
  code
  binStorageTypeId
  binStorageTypeCode
  binStorageTypeDepth
  binStorageTypeWidth
  binStorageTypeHeight
  binStorageTypeWeightCapacity
  distanceUomCode
  weightCapacityUomCode
  containsProducts
  destinationBinBlock
  hasOpenTasks
  inactive
  lastCount
  lastMovement
  level
  sourceBinBlock
  verificationCode
  warehouseId
  warehouseCode
  x
  y
  isAssignedToDoor
}
    `;
export const BinsTableDocument = gql`
    query BinsTable($sorting: [ViewBinSort!], $paging: OffsetPaging, $filter: ViewBinFilter, $includePageInfo: Boolean = true, $includeNodes: Boolean = true, $includeTotalCount: Boolean = false) {
  query: viewBins(sorting: $sorting, paging: $paging, filter: $filter) {
    pageInfo @include(if: $includePageInfo) {
      hasNextPage
      hasPreviousPage
    }
    nodes @include(if: $includeNodes) {
      ...BinsTableRow
    }
    totalCount @include(if: $includeTotalCount)
  }
}
    ${BinsTableRowFragmentDoc}`;

/**
 * __useBinsTableQuery__
 *
 * To run a query within a React component, call `useBinsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useBinsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBinsTableQuery({
 *   variables: {
 *      sorting: // value for 'sorting'
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      includePageInfo: // value for 'includePageInfo'
 *      includeNodes: // value for 'includeNodes'
 *      includeTotalCount: // value for 'includeTotalCount'
 *   },
 * });
 */
export function useBinsTableQuery(baseOptions?: Apollo.QueryHookOptions<BinsTableQuery, BinsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BinsTableQuery, BinsTableQueryVariables>(BinsTableDocument, options);
      }
export function useBinsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BinsTableQuery, BinsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BinsTableQuery, BinsTableQueryVariables>(BinsTableDocument, options);
        }
export type BinsTableQueryHookResult = ReturnType<typeof useBinsTableQuery>;
export type BinsTableLazyQueryHookResult = ReturnType<typeof useBinsTableLazyQuery>;
export type BinsTableQueryResult = Apollo.QueryResult<BinsTableQuery, BinsTableQueryVariables>;