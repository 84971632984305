import SearchIcon from '@mui/icons-material/Search';
import { Box, Checkbox, FormControlLabel, InputAdornment, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateLicensePlateBinToBinTaskMutation } from '@/graphql/defs/components/modals/__generated__/move-license-plate.generated';
import { useListAllBinsByAreaQuery } from '@/graphql/defs/list/__generated__/list-bins.generated';
import { ListAllBinsByArea_defaultData } from '@/graphql/defs/list/list-bins';
import { useGetBaseInventoryAllQuery } from '@/graphql/defs/shared-queries/__generated__/get-base-inventory-all.generated';
import { GetBaseInventory_defaultData } from '@/graphql/defs/shared-queries/get-base-inventory';
import {
  InventoryBaseQueryShapeSortFields,
  LicensePlateBinToBinTaskMovementCreateDto,
  SortDirection,
  ViewBin,
} from '@/graphql/types.generated';
import EditableTable from '@components/editable-table/EditableTable';
import {
  createItemUID,
  useEditableLicensePlateTable,
} from '@components/editable-table/license-plate/hooks';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import VirtualAutocomplete from '@components/virtual-autocomplete';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { ILicensePlateMoveModal } from '@models/modal';

const MoveLicensePlateModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal } = useModalContent<ILicensePlateMoveModal>();

  const { selectedWarehouseId } = useEntityUtils();
  const [shouldAutocomplete, setShouldAutocomplete] = useState(true);

  const formMethods = useForm();
  const { control, handleSubmit } = formMethods;

  const { loading: isLoadingBins, data: listAllBinsByAreaData } = useListAllBinsByAreaQuery({
    variables: {
      warehouseId: selectedWarehouseId,
    },
  });
  const {
    viewBins: { binsList },
  } = useMemo(
    () => listAllBinsByAreaData || ListAllBinsByArea_defaultData,
    [listAllBinsByAreaData],
  );

  const { loading: isLoadingInventory, data: binLevelInventoryData } = useGetBaseInventoryAllQuery({
    variables: {
      paging: { offset: 0, limit: 1000000 },
      filter: {
        licensePlateId: { in: modal.licensePlates.map((lp) => lp.id) },
      },
      sorting: [
        {
          field: InventoryBaseQueryShapeSortFields.LicensePlateCode,
          direction: SortDirection.Asc,
        },
        { field: InventoryBaseQueryShapeSortFields.ProductCode, direction: SortDirection.Asc },
      ],
    },
  });
  const {
    inventory: { nodes: binLevelInventory },
  } = useMemo(
    () => binLevelInventoryData || GetBaseInventory_defaultData(),
    [binLevelInventoryData],
  );

  const { licensePlateColumns, productItems } = useEditableLicensePlateTable(
    binLevelInventory || [],
    false,
  );

  const filteredBinList = useMemo(() => {
    return binsList && binLevelInventory
      ? binsList.filter((bin) => bin.id !== binLevelInventory[0]?.binId)
      : [];
  }, [binsList, binLevelInventory]);

  const onSubmit = (form) => {
    void processLicensePlates(0, form.destinationBin);
  };

  const processLicensePlates = async (index: number, bin?: ViewBin) => {
    const licensePlate = modal.licensePlates[index];
    const taskInput: LicensePlateBinToBinTaskMovementCreateDto = {
      destinationBinId: bin.id,
      licensePlateId: licensePlate.id,
      autocomplete: shouldAutocomplete,
      deliveryItemId: licensePlate.deliveryItemId || undefined,
      fulfillmentItemId: licensePlate.fulfillmentItemId || undefined,
    };

    await createBinToBinTasks({
      variables: {
        taskInput,
      },
    });

    if (++index < modal.licensePlates.length) {
      await processLicensePlates(index, bin);
    } else {
      closeModal({ bypassLoading: true, success: true });
    }
  };

  // TODO: If moving multiple license plates, these messages will NOT stack - this is as designed from MUI, if we want stacking snackbar messages we can use
  // TODO: https://github.com/iamhosseindhv/notistack or look for additional alternatives.
  const [createBinToBinTasks] = useCreateLicensePlateBinToBinTaskMutation({
    onCompleted: ({
      createLicensePlateBinToBinTask: { code, status, sourceLicensePlate, destinationBin },
    }) => {
      showMessage({
        type: 'success',
        message: !shouldAutocomplete
          ? t('licensePlateActions.moveSuccess', { taskCode: code })
          : t('licensePlateActions.moveSuccessAutocomplete', {
              licensePlateCode: sourceLicensePlate.code,
              binCode: destinationBin.code,
            }),
      });
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <Typography mb={4}>
          {t('licensePlateActions.moveText', { count: modal.licensePlates.length })}
        </Typography>
        <Box sx={{ width: '250px' }}>
          <Controller
            name="destinationBin"
            control={control}
            rules={{
              required: t('form.validation.requiredField', { field: t('common.destinationBin') }),
            }}
            defaultValue=""
            render={({ field, fieldState }) => (
              <VirtualAutocomplete
                freeSolo={false}
                multiple={false}
                id="destinationBin"
                data-testid="destinationBin-dropdown"
                disableClearable
                autoHighlight={true}
                options={filteredBinList}
                getOptionLabel={(option) => option.code}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => {
                  return (
                    <Box
                      {...props}
                      component="li"
                      display="flex"
                      flexDirection="column"
                      sx={{ alignItems: 'flex-start!important' }}
                    >
                      <Box
                        sx={{ color: (theme) => theme.palette.slateGrey.main, fontSize: '12px' }}
                      >{`${t('common.area')}: ${option.areaCode}`}</Box>
                      <Typography>{option.code}</Typography>
                    </Box>
                  );
                }}
                onChange={(event, item, reason) => {
                  if (reason === 'selectOption') {
                    field.onChange(item);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...field}
                    required
                    autoFocus
                    label={t('common.destinationBin')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    dataTestId="destinationBin-input"
                  />
                )}
              />
            )}
          />
        </Box>
        {productItems &&
          modal.licensePlates.map((lp) => {
            const lpProducts = productItems.filter((row) => row.item.licensePlateId === lp.id);
            return (
              <Box
                data-testid={`${lp.id}-editableTable`}
                key={`${lp.id}-products`}
                sx={{ margin: '15px 0 0' }}
              >
                <EditableTable
                  label={`${productItems[0].item.binCode} - ${t('common.licensePlate')}: ${
                    lp.code
                  }`}
                  columns={licensePlateColumns}
                  createUID={createItemUID}
                  data={lpProducts}
                />
              </Box>
            );
          })}
      </ModalContent>
      <ModalActions>
        {modal.forceAutocomplete ? null : (
          <Box sx={{ marginRight: 'auto' }}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  onChange={(e) => {
                    setShouldAutocomplete(e?.target?.checked || false);
                  }}
                />
              }
              label={t('modal.task.markAsComplete')}
            />
          </Box>
        )}
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          data-testid="moveLicensePlate_submit"
          variant="contained"
          color="primary"
          isDisabled={isLoadingBins || isLoadingInventory}
          actionType="submit"
        >
          {t('common.confirm')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default MoveLicensePlateModal;
