import { Grid } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateDoorMutation } from '@/graphql/defs/components/modals/__generated__/create-door-modal.generated';
import { useListAreasQuery } from '@/graphql/defs/list/__generated__/list-areas.generated';
import { useListAllNonAssignedBinsInAreaQuery } from '@/graphql/defs/list/__generated__/list-bins.generated';
import { ListAreas_defaultData } from '@/graphql/defs/list/list-areas';
import { ListAllNonAssignedBinsInArea_defaultData } from '@/graphql/defs/list/list-bins';
import { DoorCreateInput, DoorDirection } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useModalToggle } from '@context/modal/ModalToggleProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import enumKeys from '@lib/enum-keys';
import { allowOnlyNumeric, formatAsInteger, uppercase } from '@lib/form';
import FormValues from '@models/FormValues';
import { IDoorCreateModal, ModalTypes } from '@models/modal';

const CreateDoorModal = () => {
  const { t } = useTranslation('components');
  const { selectedWarehouseId } = useEntityUtils();
  const { showMessage } = useSnackbar();
  const { openModal } = useModalToggle();
  const { modal, closeModal, isPreparing, setPreparing, setLoading, depBucket, updateDepBucket } =
    useModalContent<IDoorCreateModal>();

  const formMethods = useForm<FormValues<DoorCreateInput>>({
    defaultValues: {
      code: !!modal?.previousFormState?.code ? modal.previousFormState.code : '',
      areaId: !!modal?.previousFormState?.areaId ? modal.previousFormState.areaId : '',
      binId: !!modal?.previousFormState && depBucket['binId'] ? depBucket['binId'] : '',
      direction: !!modal?.previousFormState?.direction ? modal.previousFormState.direction : '',
      x: !!modal?.previousFormState?.x ? modal.previousFormState.x : null,
      y: !!modal?.previousFormState?.y ? modal.previousFormState.y : null,
    },
  });
  const { control, handleSubmit, getValues, watch } = formMethods;
  const { notWhiteSpaceOnly, fieldIsInteger } = useFormValidation();

  const [createDoor] = useCreateDoorMutation({
    onCompleted: ({ createOneDoor: { code } }) => {
      showMessage({
        type: 'success',
        message: t('modal.door.create.success', { code }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const { data: listAreasData } = useListAreasQuery({
    skip: !selectedWarehouseId,
    variables: { warehouseId: selectedWarehouseId },
    onCompleted: () => {
      if (!modal?.previousFormState) setPreparing(false);
    },
    onError: (error) => {
      setPreparing(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const {
    viewAreas: { areas },
  } = useMemo(() => listAreasData || ListAreas_defaultData, [listAreasData]);

  const watchAreaId = watch(
    'areaId',
    !!modal?.previousFormState?.areaId ? modal.previousFormState.areaId : null,
  );
  const { data: listBinsData } = useListAllNonAssignedBinsInAreaQuery({
    skip: !watchAreaId,
    variables: { areaId: watchAreaId },
    onCompleted: () => {
      if (!!modal?.previousFormState) setPreparing(false);
    },
    onError: (error) => {
      setPreparing(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const {
    getBins: { binsList },
  } = useMemo(() => listBinsData || ListAllNonAssignedBinsInArea_defaultData, [listBinsData]);

  const NEW_BIN_VALUE = 'newBin';
  const watchBinId = watch('binId', null);
  useEffect(() => {
    if (watchBinId === NEW_BIN_VALUE) {
      const reopenCreateDoorModal = () => {
        openModal({
          type: ModalTypes.doorCreate,
          previousFormState: getValues(),
        });
      };

      updateDepBucket('pendingDepBucketKey', 'binId');
      openModal(
        {
          type: ModalTypes.binCreate,
          forceArea: watchAreaId,
        },
        {
          beforeClose: reopenCreateDoorModal,
        },
      );
    }
  }, [watchBinId]);

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = (fields: DoorCreateInput) => {
    void createDoor({
      variables: {
        newDoor: {
          code: fields.code,
          areaId: fields.areaId,
          binId: fields.binId,
          direction: fields.direction,
          x: fields.x,
          y: fields.y,
          warehouseId: selectedWarehouseId,
        },
      },
    });
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Controller
                name="code"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.code') }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    autoFocus
                    required
                    fullWidth
                    id="door-code"
                    label={t('common.code')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={(e) => field.onChange(uppercase(e))}
                    dataTestId="door-code"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="areaId"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.area') }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    required
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="door-areaId"
                    label={t('common.area')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="door-areaId"
                  >
                    <option disabled />
                    {areas.map((area) => (
                      <option key={`area-${area.id}`} value={area.id}>
                        {area.name}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="binId"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.bin') }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    required
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="door-binId"
                    label={t('common.bin')}
                    {...field}
                    disabled={!watchAreaId}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="door-binId"
                  >
                    <option disabled />
                    <option value={NEW_BIN_VALUE}>{t('doors.newBin')}</option>
                    {binsList.map((bin) => (
                      <option key={`bin-${bin.id}`} value={bin.id}>
                        {bin.code}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="direction"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('doors.columns.direction'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    required
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="door-direction"
                    label={t('doors.columns.direction')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="door-direction"
                  >
                    <option disabled />
                    {enumKeys(DoorDirection).map((direction) => {
                      return (
                        <option key={`direction-${direction}`} value={DoorDirection[direction]}>
                          {t(`common.${DoorDirection[direction]}`)}
                        </option>
                      );
                    })}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="x"
                control={control}
                rules={{
                  validate: fieldIsInteger,
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    dataTestId="doors-x"
                    label={t('common.x')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    type="number"
                    onChange={(e) => field.onChange(allowOnlyNumeric(e, false))}
                    value={formatAsInteger(field.value)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="y"
                control={control}
                rules={{
                  validate: fieldIsInteger,
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    id="doors-y"
                    dataTestId="doors-y"
                    label={t('common.y')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    type="number"
                    onChange={(e) => field.onChange(allowOnlyNumeric(e, false))}
                    value={formatAsInteger(field.value)}
                  />
                )}
              />
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions>
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton variant="contained" color="primary" actionType="submit">
            {t('common.submit')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default CreateDoorModal;
