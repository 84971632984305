import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useBinsTableLazyQuery } from '@/graphql/defs/hooks/shared-columns/__generated__/useCreateBinColumns.generated';
import { BinActiveState, BinBlockState } from '@/graphql/types.generated';
import createEditRowColumnDef from '@components/data-table/columnDefs/editRow';
import { ColumnTypes } from '@components/data-table/controls/filter/filter-definitions';
import { TColumnFactory } from '@components/data-table/hooks/useCreateDataTableColumns';
import filterDataTableColumnDefs from '@components/data-table/lib/filterDataTableColumnDefs';
import {
  TExtractLazyHookDataType,
  TExtractLazyHookFetchFilterType,
  TExtractLazyHookFieldNames,
} from '@components/data-table/model/extract-query-hook-types';
import LinkCell from '@components/data-table/table/cells/LinkCell';
import { BIN, BIN_STORAGE_TYPE } from '@constants/routes';
import { useDateTime } from '@context/date-time';
import { useModalToggle } from '@context/modal/ModalToggleProvider';
import enumKeys from '@lib/enum-keys';
import { ModalTypes } from '@models/modal';

export const BINS_LAZY_HOOK = useBinsTableLazyQuery;
export type TBinsDataType = TExtractLazyHookDataType<typeof BINS_LAZY_HOOK>;
export type TBinsFilterType = TExtractLazyHookFetchFilterType<typeof BINS_LAZY_HOOK>;
export type TBinsFieldNames = TExtractLazyHookFieldNames<typeof BINS_LAZY_HOOK>;

interface IUseCreateBinColumns {
  dataTestId: string;
  triggerDataTableRefetch?: () => void;
  allowEdit?: boolean;
  removeColumns?: (keyof TBinsDataType)[];
}
const useCreateBinColumns = ({
  dataTestId,
  triggerDataTableRefetch = () => {},
  allowEdit = false,
  removeColumns = [],
}: IUseCreateBinColumns) => {
  const { t } = useTranslation('components');
  const { openModal } = useModalToggle();
  const { displayDateTime } = useDateTime();

  const createBinColumns = useCallback<TColumnFactory<TBinsDataType>>(
    (columnHelper) => {
      const columns = [
        allowEdit
          ? createEditRowColumnDef(columnHelper, (bin) => {
              openModal(
                {
                  type: ModalTypes.binUpdate,
                  bin,
                },
                {
                  afterClose: (success) => {
                    if (success) triggerDataTableRefetch();
                  },
                },
              );
            })
          : null,
        columnHelper.accessor('code', {
          header: t('common.code'),
          cell: ({ row, getValue }) => (
            <LinkCell
              href={`${BIN}/${row.original.id}`}
              text={getValue()}
              dataTestId={`${dataTestId}-bin-details-link`}
            />
          ),
          meta: {
            columnType: ColumnTypes.string,
            exportFormatter: (value) => value,
          },
        }),
        columnHelper.accessor('inactive', {
          header: t('common.status'),
          cell: ({ getValue }) => t(`common.${getValue()}`),
          meta: {
            columnType: ColumnTypes.enum,
            options: enumKeys(BinActiveState).map((state) => ({
              value: BinActiveState[state],
              display: t(`common.${BinActiveState[state]}`),
            })),
            exportFormatter: (value) => t(`common.${value}`),
          },
        }),
        columnHelper.accessor('sourceBinBlock', {
          header: t('common.removal'),
          cell: ({ getValue }) => t(`common.${getValue()}`),
          meta: {
            columnType: ColumnTypes.enum,
            options: enumKeys(BinBlockState).map((state) => ({
              value: BinBlockState[state],
              display: t(`common.${BinBlockState[state]}`),
            })),
            exportFormatter: (value) => t(`common.${value}`),
          },
        }),
        columnHelper.accessor('destinationBinBlock', {
          header: t('common.placement'),
          cell: ({ getValue }) => t(`common.${getValue()}`),
          meta: {
            columnType: ColumnTypes.enum,
            options: enumKeys(BinBlockState).map((state) => ({
              value: BinBlockState[state],
              display: t(`common.${BinBlockState[state]}`),
            })),
            exportFormatter: (value) => t(`common.${value}`),
          },
        }),
        columnHelper.accessor('areaCode', {
          header: t('bins.columns.areaCode'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('level', {
          header: t('common.level'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.number,
          },
        }),
        columnHelper.accessor('verificationCode', {
          header: t('bins.columns.verification'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('x', {
          header: t('common.x'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.number,
          },
        }),
        columnHelper.accessor('y', {
          header: t('common.y'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.number,
          },
        }),
        columnHelper.accessor('lastMovement', {
          header: t('common.lastMovement'),
          cell: ({ getValue }) => displayDateTime({ date: getValue() }),
          meta: {
            columnType: ColumnTypes.dateTime,
            exportFormatter: (value) => displayDateTime({ date: value }),
          },
        }),
        columnHelper.accessor('lastCount', {
          header: t('common.lastCounted'),
          cell: ({ getValue }) => displayDateTime({ date: getValue() }),
          meta: {
            columnType: ColumnTypes.dateTime,
            exportFormatter: (value) => displayDateTime({ date: value }),
          },
        }),
        columnHelper.accessor('hasOpenTasks', {
          header: t('common.hasOpenTasks'),
          cell: ({ getValue }) => (getValue() ? t('common.yes') : t('common.no')),
          meta: {
            columnType: ColumnTypes.boolean,
            exportFormatter: (value) => (value ? t('common.yes') : t('common.no')),
          },
        }),
        columnHelper.accessor('containsProducts', {
          header: t('bins.columns.containsProducts'),
          cell: ({ getValue }) => (getValue() ? t('common.yes') : t('common.no')),
          meta: {
            columnType: ColumnTypes.boolean,
            exportFormatter: (value) => (value ? t('common.yes') : t('common.no')),
          },
        }),
        columnHelper.accessor('binStorageTypeCode', {
          header: t('bins.columns.binStorageType'),
          cell: ({ row, getValue }) => (
            <LinkCell
              href={`${BIN_STORAGE_TYPE}/${row.original.binStorageTypeId}`}
              text={getValue()}
              dataTestId={`${dataTestId}-bin-storage-type-details-link`}
            />
          ),
          meta: {
            columnType: ColumnTypes.string,
            exportFormatter: (value) => value,
          },
        }),
        columnHelper.accessor('binStorageTypeWeightCapacity', {
          header: t('common.weightCapacity'),
          cell: ({ row, getValue }) => `${getValue()}${row.original.weightCapacityUomCode}`,
          meta: {
            columnType: ColumnTypes.number,
            exportFormatter: (value, row) => `${value}${row.weightCapacityUomCode}`,
          },
        }),
        columnHelper.accessor('binStorageTypeDepth', {
          header: t('common.depth'),
          cell: ({ row, getValue }) => `${getValue()}${row.original.distanceUomCode}`,
          meta: {
            columnType: ColumnTypes.number,
            exportFormatter: (value, row) => `${value}${row.distanceUomCode}`,
          },
        }),
        columnHelper.accessor('binStorageTypeWidth', {
          header: t('common.width'),
          cell: ({ row, getValue }) => `${getValue()}${row.original.distanceUomCode}`,
          meta: {
            columnType: ColumnTypes.number,
            exportFormatter: (value, row) => `${value}${row.distanceUomCode}`,
          },
        }),
        columnHelper.accessor('binStorageTypeHeight', {
          header: t('common.height'),
          cell: ({ row, getValue }) => `${getValue()}${row.original.distanceUomCode}`,
          meta: {
            columnType: ColumnTypes.number,
            exportFormatter: (value, row) => `${value}${row.distanceUomCode}`,
          },
        }),
      ];

      return columns.filter(filterDataTableColumnDefs<TBinsDataType>(removeColumns));
    },
    [t, displayDateTime, triggerDataTableRefetch, ...removeColumns],
  );

  return createBinColumns;
};

export default useCreateBinColumns;
