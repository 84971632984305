import { gql } from '@apollo/client';

import { GetPickToStagingFulfillmentItemQuery } from '@/graphql/defs/components/modals/__generated__/pick-to-staging-complete-modal.generated';

gql`
  fragment PickToStagingFulfillmentItem on ViewFulfillmentItem {
    id
    item
    licensePlateId
    licensePlateCode
    licensePlateDescription
    productId
    productCode
    productDescription
    unitOfMeasure
  }

  query GetPickToStagingFulfillmentItem($fulfillmentItemId: ID!) {
    viewFulfillmentItems(filter: { id: { eq: $fulfillmentItemId } }) {
      nodes {
        ...PickToStagingFulfillmentItem
      }
    }
  }

  mutation CompletePickToStagingTask(
    $warehouseId: String!
    $completePickToStagingInput: [CompletePickToStagingTaskInput!]!
  ) {
    completeManyPickToStagingTasks(
      warehouseId: $warehouseId
      completePickToStagingTasks: $completePickToStagingInput
    ) {
      id
      code
    }
  }
`;

export const GetPickToStagingFulfillmentItem_defaultData: GetPickToStagingFulfillmentItemQuery = {
  __typename: 'Query',
  viewFulfillmentItems: {
    __typename: 'ViewFulfillmentItemOffsetConnection',
    nodes: [],
  },
};
