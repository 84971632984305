/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListAllBinsByAreaFragment = { __typename?: 'ViewBin', id?: string | null, areaCode?: string | null, code?: string | null };

export type ListAllBinsByAreaQueryVariables = Types.Exact<{
  warehouseId: Types.Scalars['ID']['input'];
}>;


export type ListAllBinsByAreaQuery = { __typename?: 'Query', viewBins: { __typename?: 'ViewBinOffsetConnection', binsList: Array<{ __typename?: 'ViewBin', id?: string | null, areaCode?: string | null, code?: string | null }> } };

export type ListAllNonAssignedBinsInAreaQueryVariables = Types.Exact<{
  areaId: Types.Scalars['ID']['input'];
}>;


export type ListAllNonAssignedBinsInAreaQuery = { __typename?: 'Query', getBins: { __typename?: 'ViewBinOffsetConnection', binsList: Array<{ __typename?: 'ViewBin', id?: string | null, code?: string | null }> } };

export type ListAllNonAssignedBinsInAreaIncludingCurrentBinQueryVariables = Types.Exact<{
  areaId: Types.Scalars['ID']['input'];
  currentBinId: Types.Scalars['ID']['input'];
}>;


export type ListAllNonAssignedBinsInAreaIncludingCurrentBinQuery = { __typename?: 'Query', getBins: { __typename?: 'ViewBinOffsetConnection', binsList: Array<{ __typename?: 'ViewBin', id?: string | null, code?: string | null }> } };

export const ListAllBinsByAreaFragmentDoc = gql`
    fragment ListAllBinsByArea on ViewBin {
  id
  areaCode
  code
}
    `;
export const ListAllBinsByAreaDocument = gql`
    query ListAllBinsByArea($warehouseId: ID!) {
  viewBins(
    paging: {offset: 0, limit: 1000000}
    filter: {warehouseId: {eq: $warehouseId}}
    sorting: [{field: areaCode, direction: ASC}, {field: code, direction: ASC}]
  ) {
    binsList: nodes {
      ...ListAllBinsByArea
    }
  }
}
    ${ListAllBinsByAreaFragmentDoc}`;

/**
 * __useListAllBinsByAreaQuery__
 *
 * To run a query within a React component, call `useListAllBinsByAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllBinsByAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllBinsByAreaQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useListAllBinsByAreaQuery(baseOptions: Apollo.QueryHookOptions<ListAllBinsByAreaQuery, ListAllBinsByAreaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllBinsByAreaQuery, ListAllBinsByAreaQueryVariables>(ListAllBinsByAreaDocument, options);
      }
export function useListAllBinsByAreaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllBinsByAreaQuery, ListAllBinsByAreaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllBinsByAreaQuery, ListAllBinsByAreaQueryVariables>(ListAllBinsByAreaDocument, options);
        }
export type ListAllBinsByAreaQueryHookResult = ReturnType<typeof useListAllBinsByAreaQuery>;
export type ListAllBinsByAreaLazyQueryHookResult = ReturnType<typeof useListAllBinsByAreaLazyQuery>;
export type ListAllBinsByAreaQueryResult = Apollo.QueryResult<ListAllBinsByAreaQuery, ListAllBinsByAreaQueryVariables>;
export const ListAllNonAssignedBinsInAreaDocument = gql`
    query ListAllNonAssignedBinsInArea($areaId: ID!) {
  getBins: viewBins(
    filter: {areaId: {eq: $areaId}, isAssignedToDoor: {is: false}}
    sorting: [{field: code, direction: ASC}]
  ) {
    binsList: nodes {
      id
      code
    }
  }
}
    `;

/**
 * __useListAllNonAssignedBinsInAreaQuery__
 *
 * To run a query within a React component, call `useListAllNonAssignedBinsInAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllNonAssignedBinsInAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllNonAssignedBinsInAreaQuery({
 *   variables: {
 *      areaId: // value for 'areaId'
 *   },
 * });
 */
export function useListAllNonAssignedBinsInAreaQuery(baseOptions: Apollo.QueryHookOptions<ListAllNonAssignedBinsInAreaQuery, ListAllNonAssignedBinsInAreaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllNonAssignedBinsInAreaQuery, ListAllNonAssignedBinsInAreaQueryVariables>(ListAllNonAssignedBinsInAreaDocument, options);
      }
export function useListAllNonAssignedBinsInAreaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllNonAssignedBinsInAreaQuery, ListAllNonAssignedBinsInAreaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllNonAssignedBinsInAreaQuery, ListAllNonAssignedBinsInAreaQueryVariables>(ListAllNonAssignedBinsInAreaDocument, options);
        }
export type ListAllNonAssignedBinsInAreaQueryHookResult = ReturnType<typeof useListAllNonAssignedBinsInAreaQuery>;
export type ListAllNonAssignedBinsInAreaLazyQueryHookResult = ReturnType<typeof useListAllNonAssignedBinsInAreaLazyQuery>;
export type ListAllNonAssignedBinsInAreaQueryResult = Apollo.QueryResult<ListAllNonAssignedBinsInAreaQuery, ListAllNonAssignedBinsInAreaQueryVariables>;
export const ListAllNonAssignedBinsInAreaIncludingCurrentBinDocument = gql`
    query ListAllNonAssignedBinsInAreaIncludingCurrentBin($areaId: ID!, $currentBinId: ID!) {
  getBins: viewBins(
    filter: {or: [{areaId: {eq: $areaId}, isAssignedToDoor: {is: false}}, {id: {eq: $currentBinId}}]}
    sorting: [{field: code, direction: ASC}]
  ) {
    binsList: nodes {
      id
      code
    }
  }
}
    `;

/**
 * __useListAllNonAssignedBinsInAreaIncludingCurrentBinQuery__
 *
 * To run a query within a React component, call `useListAllNonAssignedBinsInAreaIncludingCurrentBinQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllNonAssignedBinsInAreaIncludingCurrentBinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllNonAssignedBinsInAreaIncludingCurrentBinQuery({
 *   variables: {
 *      areaId: // value for 'areaId'
 *      currentBinId: // value for 'currentBinId'
 *   },
 * });
 */
export function useListAllNonAssignedBinsInAreaIncludingCurrentBinQuery(baseOptions: Apollo.QueryHookOptions<ListAllNonAssignedBinsInAreaIncludingCurrentBinQuery, ListAllNonAssignedBinsInAreaIncludingCurrentBinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllNonAssignedBinsInAreaIncludingCurrentBinQuery, ListAllNonAssignedBinsInAreaIncludingCurrentBinQueryVariables>(ListAllNonAssignedBinsInAreaIncludingCurrentBinDocument, options);
      }
export function useListAllNonAssignedBinsInAreaIncludingCurrentBinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllNonAssignedBinsInAreaIncludingCurrentBinQuery, ListAllNonAssignedBinsInAreaIncludingCurrentBinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllNonAssignedBinsInAreaIncludingCurrentBinQuery, ListAllNonAssignedBinsInAreaIncludingCurrentBinQueryVariables>(ListAllNonAssignedBinsInAreaIncludingCurrentBinDocument, options);
        }
export type ListAllNonAssignedBinsInAreaIncludingCurrentBinQueryHookResult = ReturnType<typeof useListAllNonAssignedBinsInAreaIncludingCurrentBinQuery>;
export type ListAllNonAssignedBinsInAreaIncludingCurrentBinLazyQueryHookResult = ReturnType<typeof useListAllNonAssignedBinsInAreaIncludingCurrentBinLazyQuery>;
export type ListAllNonAssignedBinsInAreaIncludingCurrentBinQueryResult = Apollo.QueryResult<ListAllNonAssignedBinsInAreaIncludingCurrentBinQuery, ListAllNonAssignedBinsInAreaIncludingCurrentBinQueryVariables>;