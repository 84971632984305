import { gql } from '@apollo/client';

import { ListSapStorageLocationsQuery } from '@/graphql/defs/list/__generated__/list-sap-storage-location.generated';

gql`
  query ListSapStorageLocations(
    $paging: OffsetPaging
    $filter: SapStorageLocationPlantFilter
    $sorting: [SapStorageLocationPlantSort!]
  ) {
    sapStorageLocationPlants(paging: $paging, filter: $filter, sorting: $sorting) {
      storageLocations: nodes {
        id
        plant
        storageLocation
      }
    }
  }
`;

export const ListSapStorageLocations_defaultData: ListSapStorageLocationsQuery = {
  __typename: 'Query',
  sapStorageLocationPlants: {
    __typename: 'SapStorageLocationPlantOffsetConnection',
    storageLocations: [],
  },
};
