import { Grid } from '@mui/material';
import React from 'react';
import { Control, Controller, FieldValues, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CompanyFragment } from '@/graphql/defs/pages/__generated__/companies.generated';
import TextField from '@components/text-field';
import { useFormValidation } from '@hooks/form/validators';
import { uppercase } from '@lib/form';

export interface IFieldBlockCompanyDetails {
  company?: CompanyFragment;
  control: Control;
  register: UseFormRegister<FieldValues>;
  focusOnLoad?: boolean;
}
export const FieldBlockCompanyDetails: React.FC<IFieldBlockCompanyDetails> = ({
  company,
  control,
  register,
  focusOnLoad,
}) => {
  const { t } = useTranslation('components');
  const { notWhiteSpaceOnly } = useFormValidation();

  return (
    <>
      {company && company.id && <input {...register('id')} type="hidden" value={company.id} />}
      <Grid item xs={6}>
        <Controller
          name="code"
          control={control}
          rules={{
            required: t('form.validation.requiredField', {
              field: t('field-blocks.companyDetails.code'),
            }),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          defaultValue={company?.code || ''}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              required
              id="company-code"
              label={t('field-blocks.companyDetails.code')}
              {...field}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              onChange={(e) => field.onChange(uppercase(e))}
              autoFocus={focusOnLoad}
              dataTestId="company-code"
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="name"
          control={control}
          rules={{
            required: t('form.validation.requiredField', {
              field: t('field-blocks.companyDetails.name'),
            }),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          defaultValue={company?.name || ''}
          render={({ field, fieldState }) => (
            <TextField
              fullWidth
              required
              id="company-name"
              label={t('field-blocks.companyDetails.name')}
              {...field}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              dataTestId="company-name"
            />
          )}
        />
      </Grid>
    </>
  );
};
