import { LicenseInfo } from '@mui/x-license-pro';
import { useEffect, useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';

import './i18n';

import AppProviders from '@/context';
import { ErrorBoundary } from '@components/error-boundary';
import { Router } from '@components/router';
import { useAppLoadingState } from '@context/app-loading/AppLoadingStateProvider';
import { useAppLoadingToggle } from '@context/app-loading/AppLoadingToggleProvider';
import { useEntityUtils } from '@context/entity-utils';
import { usePermissions } from '@context/permissions';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE);

const AppInitializer = ({ children }) => {
  const { setAppInitialized, setAppLoading } = useAppLoadingToggle();
  const { isAppInitialized, isAppLoading } = useAppLoadingState();
  const { isLoadingSelectedWarehouse } = useEntityUtils();
  const { loading: isLoadingPermissions } = usePermissions();

  useEffect(() => {
    if (isAppInitialized) {
      setAppLoading(isLoadingSelectedWarehouse || isLoadingPermissions);
    }
  }, [isAppInitialized, isLoadingSelectedWarehouse, isLoadingPermissions]);

  useEffect(() => {
    setAppInitialized(true);
  }, []);

  const showChildren = useMemo(
    () => isAppInitialized && !isAppLoading,
    [isAppInitialized, isAppLoading],
  );

  return showChildren && children;
};

function App() {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <AppProviders>
          <AppInitializer>
            <Router />
          </AppInitializer>
        </AppProviders>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
