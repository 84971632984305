import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateOneFulfillmentBlockMutation } from '@/graphql/defs/components/modals/__generated__/create-fulfillment-block-modal.generated';
import { useListFulfillmentBlocksQuery } from '@/graphql/defs/list/__generated__/fulfillment-blocks.generated';
import { FulfillmentBlockCreateInput } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import FormValues from '@models/FormValues';
import { IFulfillmentBlockCreateModal } from '@models/modal';

const CreateFulfillmentBlockModal = () => {
  const { t } = useTranslation('components');
  const { selectedWarehouseId } = useEntityUtils();
  const { showMessage } = useSnackbar();
  const { modal, closeModal, isPreparing, setPreparing, setLoading, depBucket, updateDepBucket } =
    useModalContent<IFulfillmentBlockCreateModal>();

  const formMethods = useForm<FormValues<FulfillmentBlockCreateInput>>({
    defaultValues: {
      label: !!modal?.previousFormState?.label ? modal.previousFormState.label : '',
      description: !!modal?.previousFormState?.description
        ? modal.previousFormState.description
        : '',
    },
  });
  const { control, handleSubmit } = formMethods;
  const { notWhiteSpaceOnly } = useFormValidation();

  const [createFulfillmentBlock] = useCreateOneFulfillmentBlockMutation({
    onCompleted: async ({ createOneFulfillmentBlock: { label, id } }) => {
      showMessage({
        type: 'success',
        message: t('modal.fulfillmentBlock.create.success', { label }),
      });

      if (depBucket['pendingDepBucketKey']) {
        updateDepBucket(depBucket['pendingDepBucketKey'], id);
      }
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const { data: listFulfillmentBlocksData } = useListFulfillmentBlocksQuery({
    skip: !selectedWarehouseId,
    onCompleted: () => {
      if (!modal?.previousFormState) setPreparing(false);
    },
    onError: (error) => {
      setPreparing(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = async (fields: FulfillmentBlockCreateInput) => {
    createFulfillmentBlock({
      variables: {
        input: {
          fulfillmentBlock: {
            label: fields.label,
            description: fields.description,
          },
        },
      },
    });
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Controller
                name="label"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.code') }),
                  validate: {
                    notWhiteSpaceOnly,
                    noDuplicats: (value) => {
                      return (
                        !listFulfillmentBlocksData.listFulfillmentBlocks.fulfillmentBlocks.some(
                          ({ label }) => label === value,
                        ) || t('fulfillmentBlocks.noDuplicateLabels')
                      );
                    },
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    autoFocus
                    fullWidth
                    required
                    id="fulfillmentBlock-label"
                    label={t('common.label')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={(e) => field.onChange(e)}
                    dataTestId="fulfillmentBlock-label"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    id="fulfillmentBlock-description"
                    label={t('common.description')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={(e) => field.onChange(e)}
                    dataTestId="fulfillmentBlock-description"
                  />
                )}
              />
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions>
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton variant="contained" color="primary" actionType="submit">
            {t('common.submit')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default CreateFulfillmentBlockModal;
