/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUserGroupMutationVariables = Types.Exact<{
  input: Types.UserGroupCreateInputDto;
}>;


export type CreateUserGroupMutation = { __typename?: 'Mutation', createOneUserGroup: { __typename?: 'UserGroup', createdAt: string, deletedAt?: string | null, id: string, name: string, updatedAt?: string | null } };

export type UpdateRolesForUserGroupMutationVariables = Types.Exact<{
  input: Types.UpdateRolesForUserGroupDto;
}>;


export type UpdateRolesForUserGroupMutation = { __typename?: 'Mutation', updateRolesForUserGroup: string };

export type DeleteOneUserGroupMutationVariables = Types.Exact<{
  input: Types.UserGroupDeleteOneDto;
}>;


export type DeleteOneUserGroupMutation = { __typename?: 'Mutation', deleteOneUserGroup: { __typename?: 'UserGroup', createdAt: string, deletedAt?: string | null, id: string, name: string, updatedAt?: string | null } };

export type UpdateOneUserGroupMutationVariables = Types.Exact<{
  input: Types.UserGroupUpdateInput;
}>;


export type UpdateOneUserGroupMutation = { __typename?: 'Mutation', updateOneUserGroup: { __typename?: 'UserGroup', createdAt: string, deletedAt?: string | null, id: string, name: string, updatedAt?: string | null } };


export const CreateUserGroupDocument = gql`
    mutation CreateUserGroup($input: UserGroupCreateInputDto!) {
  createOneUserGroup(input: $input) {
    createdAt
    deletedAt
    id
    name
    updatedAt
  }
}
    `;
export type CreateUserGroupMutationFn = Apollo.MutationFunction<CreateUserGroupMutation, CreateUserGroupMutationVariables>;

/**
 * __useCreateUserGroupMutation__
 *
 * To run a mutation, you first call `useCreateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserGroupMutation, { data, loading, error }] = useCreateUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserGroupMutation, CreateUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserGroupMutation, CreateUserGroupMutationVariables>(CreateUserGroupDocument, options);
      }
export type CreateUserGroupMutationHookResult = ReturnType<typeof useCreateUserGroupMutation>;
export type CreateUserGroupMutationResult = Apollo.MutationResult<CreateUserGroupMutation>;
export type CreateUserGroupMutationOptions = Apollo.BaseMutationOptions<CreateUserGroupMutation, CreateUserGroupMutationVariables>;
export const UpdateRolesForUserGroupDocument = gql`
    mutation UpdateRolesForUserGroup($input: UpdateRolesForUserGroupDto!) {
  updateRolesForUserGroup(input: $input)
}
    `;
export type UpdateRolesForUserGroupMutationFn = Apollo.MutationFunction<UpdateRolesForUserGroupMutation, UpdateRolesForUserGroupMutationVariables>;

/**
 * __useUpdateRolesForUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateRolesForUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRolesForUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRolesForUserGroupMutation, { data, loading, error }] = useUpdateRolesForUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRolesForUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRolesForUserGroupMutation, UpdateRolesForUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRolesForUserGroupMutation, UpdateRolesForUserGroupMutationVariables>(UpdateRolesForUserGroupDocument, options);
      }
export type UpdateRolesForUserGroupMutationHookResult = ReturnType<typeof useUpdateRolesForUserGroupMutation>;
export type UpdateRolesForUserGroupMutationResult = Apollo.MutationResult<UpdateRolesForUserGroupMutation>;
export type UpdateRolesForUserGroupMutationOptions = Apollo.BaseMutationOptions<UpdateRolesForUserGroupMutation, UpdateRolesForUserGroupMutationVariables>;
export const DeleteOneUserGroupDocument = gql`
    mutation DeleteOneUserGroup($input: UserGroupDeleteOneDto!) {
  deleteOneUserGroup(input: $input) {
    createdAt
    deletedAt
    id
    name
    updatedAt
  }
}
    `;
export type DeleteOneUserGroupMutationFn = Apollo.MutationFunction<DeleteOneUserGroupMutation, DeleteOneUserGroupMutationVariables>;

/**
 * __useDeleteOneUserGroupMutation__
 *
 * To run a mutation, you first call `useDeleteOneUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneUserGroupMutation, { data, loading, error }] = useDeleteOneUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneUserGroupMutation, DeleteOneUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneUserGroupMutation, DeleteOneUserGroupMutationVariables>(DeleteOneUserGroupDocument, options);
      }
export type DeleteOneUserGroupMutationHookResult = ReturnType<typeof useDeleteOneUserGroupMutation>;
export type DeleteOneUserGroupMutationResult = Apollo.MutationResult<DeleteOneUserGroupMutation>;
export type DeleteOneUserGroupMutationOptions = Apollo.BaseMutationOptions<DeleteOneUserGroupMutation, DeleteOneUserGroupMutationVariables>;
export const UpdateOneUserGroupDocument = gql`
    mutation UpdateOneUserGroup($input: UserGroupUpdateInput!) {
  updateOneUserGroup(input: $input) {
    createdAt
    deletedAt
    id
    name
    updatedAt
  }
}
    `;
export type UpdateOneUserGroupMutationFn = Apollo.MutationFunction<UpdateOneUserGroupMutation, UpdateOneUserGroupMutationVariables>;

/**
 * __useUpdateOneUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateOneUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneUserGroupMutation, { data, loading, error }] = useUpdateOneUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneUserGroupMutation, UpdateOneUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneUserGroupMutation, UpdateOneUserGroupMutationVariables>(UpdateOneUserGroupDocument, options);
      }
export type UpdateOneUserGroupMutationHookResult = ReturnType<typeof useUpdateOneUserGroupMutation>;
export type UpdateOneUserGroupMutationResult = Apollo.MutationResult<UpdateOneUserGroupMutation>;
export type UpdateOneUserGroupMutationOptions = Apollo.BaseMutationOptions<UpdateOneUserGroupMutation, UpdateOneUserGroupMutationVariables>;