/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { BaseInventoryFragmentDoc } from '../../shared-fragments/__generated__/inventory.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBaseInventoryAllQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.InventoryBaseQueryShapeFilter>;
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  sorting?: Types.InputMaybe<Array<Types.InventoryBaseQueryShapeSort> | Types.InventoryBaseQueryShapeSort>;
}>;


export type GetBaseInventoryAllQuery = { __typename?: 'Query', inventory: { __typename?: 'InventoryBaseQueryShapeOffsetConnection', totalCount: number, nodes: Array<{ __typename?: 'InventoryBaseQueryShape', areaCode?: string | null, areaId?: string | null, availableQuantity?: string | null, binCode?: string | null, binId?: string | null, erpSalesOrder?: string | null, deliveryItemId?: string | null, stockDeliveryId?: string | null, stockDeliveryCode?: string | null, fulfillmentItemId?: string | null, stockFulfillmentId?: string | null, stockFulfillmentCode?: string | null, soldToBusinessPartnerId?: string | null, soldToBusinessPartnerName?: string | null, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, licensePlateStatus?: Types.LicensePlateStatusState | null, lotCode?: string | null, lotId?: string | null, lotManaged?: boolean | null, openTaskCount?: number | null, productCode?: string | null, productDescription?: string | null, productId?: string | null, quantity: string, stockStatus?: string | null, stockStatusTypeId?: string | null, unitOfMeasure?: string | null, unitOfMeasureId?: string | null, warehouseCode?: string | null, warehouseId?: string | null, deliveryItem?: string | null, fulfillmentItem?: string | null }> } };


export const GetBaseInventoryAllDocument = gql`
    query GetBaseInventoryAll($filter: InventoryBaseQueryShapeFilter, $paging: OffsetPaging, $sorting: [InventoryBaseQueryShapeSort!]) {
  inventory: baseInventoryAll(filter: $filter, paging: $paging, sorting: $sorting) {
    nodes {
      ...BaseInventory
    }
    totalCount
  }
}
    ${BaseInventoryFragmentDoc}`;

/**
 * __useGetBaseInventoryAllQuery__
 *
 * To run a query within a React component, call `useGetBaseInventoryAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseInventoryAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseInventoryAllQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetBaseInventoryAllQuery(baseOptions?: Apollo.QueryHookOptions<GetBaseInventoryAllQuery, GetBaseInventoryAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBaseInventoryAllQuery, GetBaseInventoryAllQueryVariables>(GetBaseInventoryAllDocument, options);
      }
export function useGetBaseInventoryAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBaseInventoryAllQuery, GetBaseInventoryAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBaseInventoryAllQuery, GetBaseInventoryAllQueryVariables>(GetBaseInventoryAllDocument, options);
        }
export type GetBaseInventoryAllQueryHookResult = ReturnType<typeof useGetBaseInventoryAllQuery>;
export type GetBaseInventoryAllLazyQueryHookResult = ReturnType<typeof useGetBaseInventoryAllLazyQuery>;
export type GetBaseInventoryAllQueryResult = Apollo.QueryResult<GetBaseInventoryAllQuery, GetBaseInventoryAllQueryVariables>;