import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import {
  ViewFulfillmentItemFragment,
  useCreatePickTasksMutation,
  useViewFulfillmentItemsQuery,
} from '@/graphql/defs/components/modals/__generated__/pick-task-create-modal.generated';
import { ViewFulfillmentItems_defaultData } from '@/graphql/defs/components/modals/pick-task-create-modal';
import { SortDirection, ViewFulfillmentItemSortFields } from '@/graphql/types.generated';
import EditableTable, {
  EditableTableCell,
  EditableTableColumn,
  EditableTableRow,
} from '@components/editable-table/EditableTable';
import EditableUoM from '@components/editable-table/EditableUoM';
import NoneditableQty from '@components/editable-table/NoneditableQty';
import { ModalActions, ModalContent } from '@components/modal';
import { ModalButton } from '@components/modal/modal-button';
import ModalForm from '@components/modal/modal-form';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { getTransComponents } from '@lib/translation';
import { IPickTaskCreateModal } from '@models/modal';

export const PickTaskCreateModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, setLoading, setPreparing, isPreparing } =
    useModalContent<IPickTaskCreateModal>();
  const [createFulfillmentTasks] = useCreatePickTasksMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: `${t('modal.task.createPickTaskSuccessMessage')}`,
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: () => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: `${t('modal.task.createPickTaskErrorMessage')}`,
      });
    },
  });

  const { showMessage } = useSnackbar();
  const formMethods = useForm();

  const { data: viewFulfillmentItemsData } = useViewFulfillmentItemsQuery({
    variables: {
      filter: { deliveryId: { eq: modal.fulfillmentId } },
      sorting: [{ field: ViewFulfillmentItemSortFields.Item, direction: SortDirection.Asc }],
    },
    onCompleted: () => {
      setPreparing(false);
    },
    onError: (error) => {
      setPreparing(false);
      showMessage({ type: 'error', message: error.message });
    },
  });
  const {
    viewFulfillmentItems: { fulfillmentItems },
  } = useMemo(
    () => viewFulfillmentItemsData || ViewFulfillmentItems_defaultData,
    [viewFulfillmentItemsData],
  );

  const createItemUID = (item: ViewFulfillmentItemFragment) =>
    `${item.item}-${item.productId}-${item.lotId}-${item.stockStatus}-${item.unitOfMeasure}`;

  const onSubmit = (qtyUoMs) => {
    void createFulfillmentTasks({
      variables: {
        fulfillmentId: modal.fulfillmentId,
      },
    });
  };

  const fulfillmentItemColumns: EditableTableColumn[] = [
    {
      label: t('common.item'),
      width: '16.66%',
    },
    {
      label: t('common.productCodeAbbr'),
      width: '16.66%',
    },
    {
      label: t('common.description'),
      width: '16.66%',
    },
    {
      label: t('common.lot'),
      width: '16.66%',
    },
    {
      label: t('common.qty'),
      width: '16.66%',
    },
    {
      label: t('common.qtyUoM'),
      width: '16.66%',
    },
  ];

  const fulfillmentItemRows: EditableTableRow[] = useMemo(() => {
    const rows = [...fulfillmentItems];
    return rows.map((fulfillmentItem, index): EditableTableRow => {
      const cells: EditableTableCell[] = [
        {
          dataTestId: `looseProduct-${index}-deliveryItem`,
          value: fulfillmentItem.item,
        },
        {
          dataTestId: `looseProduct-${index}-productCode`,
          value: fulfillmentItem.productCode,
        },
        {
          dataTestId: `looseProduct-${index}-productDesc`,
          value: fulfillmentItem.productDescription,
        },
        {
          dataTestId: `looseProduct-${index}-lotCode`,
          value: fulfillmentItem.lotCode,
        },
        {
          dataTestId: `looseProduct-${index}-quantity`,
          value: NoneditableQty,
        },
        {
          dataTestId: `looseProduct-${index}-quantityUoM`,
          value: EditableUoM,
        },
      ];
      return {
        dataTestId: `looseProduct-${index}`,
        item: fulfillmentItem,
        cells: cells,
      };
    });
  }, [fulfillmentItems]);

  return (
    !isPreparing && (
      <ModalForm onSubmit={formMethods.handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Typography>
            <Trans
              ns="components"
              i18nKey="modal.task.createPickTaskInfo"
              components={getTransComponents(['break', 'bold'])}
            />
          </Typography>
          <Box sx={{ marginTop: '25px' }}>
            <EditableTable
              columns={fulfillmentItemColumns}
              createUID={createItemUID}
              data={fulfillmentItemRows}
            />
          </Box>
        </ModalContent>
        <ModalActions>
          <ModalButton
            variant="outlined"
            color="primary"
            onClick={() => closeModal()}
            actionType="cancel"
          >
            {t('common.cancel')}
          </ModalButton>
          <ModalButton variant="contained" color="primary" actionType="submit">
            {t('modal.task.createTask')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};
