/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FullContactInfoFragment = { __typename?: 'ContactInfo', id: string, email?: string | null, phone?: string | null, fax?: string | null, street1?: string | null, street2?: string | null, city?: string | null, state?: string | null, zip?: string | null, country?: string | null };

export type UpdateContactInfoMutationVariables = Types.Exact<{
  parentId: Types.Scalars['ID']['input'];
  contactInfo: Types.UpdateContactInfoDto;
  isOrg: Types.Scalars['Boolean']['input'];
  isCompany: Types.Scalars['Boolean']['input'];
  isWarehouse: Types.Scalars['Boolean']['input'];
}>;


export type UpdateContactInfoMutation = { __typename?: 'Mutation', updateOneContactInfoByOrganizationId?: { __typename?: 'ContactInfo', id: string, email?: string | null, phone?: string | null, fax?: string | null, street1?: string | null, street2?: string | null, city?: string | null, state?: string | null, zip?: string | null, country?: string | null }, updateOneContactInfoByCompanyId?: { __typename?: 'ContactInfo', id: string, email?: string | null, phone?: string | null, fax?: string | null, street1?: string | null, street2?: string | null, city?: string | null, state?: string | null, zip?: string | null, country?: string | null }, updateOneContactInfoByWarehouseId?: { __typename?: 'ContactInfo', id: string, email?: string | null, phone?: string | null, fax?: string | null, street1?: string | null, street2?: string | null, city?: string | null, state?: string | null, zip?: string | null, country?: string | null } };

export const FullContactInfoFragmentDoc = gql`
    fragment FullContactInfo on ContactInfo {
  id
  email
  phone
  fax
  street1
  street2
  city
  state
  zip
  country
}
    `;
export const UpdateContactInfoDocument = gql`
    mutation UpdateContactInfo($parentId: ID!, $contactInfo: UpdateContactInfoDto!, $isOrg: Boolean!, $isCompany: Boolean!, $isWarehouse: Boolean!) {
  updateOneContactInfoByOrganizationId(
    input: {id: $parentId, update: $contactInfo}
  ) @include(if: $isOrg) {
    ...FullContactInfo
  }
  updateOneContactInfoByCompanyId(input: {id: $parentId, update: $contactInfo}) @include(if: $isCompany) {
    ...FullContactInfo
  }
  updateOneContactInfoByWarehouseId(input: {id: $parentId, update: $contactInfo}) @include(if: $isWarehouse) {
    ...FullContactInfo
  }
}
    ${FullContactInfoFragmentDoc}`;
export type UpdateContactInfoMutationFn = Apollo.MutationFunction<UpdateContactInfoMutation, UpdateContactInfoMutationVariables>;

/**
 * __useUpdateContactInfoMutation__
 *
 * To run a mutation, you first call `useUpdateContactInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactInfoMutation, { data, loading, error }] = useUpdateContactInfoMutation({
 *   variables: {
 *      parentId: // value for 'parentId'
 *      contactInfo: // value for 'contactInfo'
 *      isOrg: // value for 'isOrg'
 *      isCompany: // value for 'isCompany'
 *      isWarehouse: // value for 'isWarehouse'
 *   },
 * });
 */
export function useUpdateContactInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactInfoMutation, UpdateContactInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactInfoMutation, UpdateContactInfoMutationVariables>(UpdateContactInfoDocument, options);
      }
export type UpdateContactInfoMutationHookResult = ReturnType<typeof useUpdateContactInfoMutation>;
export type UpdateContactInfoMutationResult = Apollo.MutationResult<UpdateContactInfoMutation>;
export type UpdateContactInfoMutationOptions = Apollo.BaseMutationOptions<UpdateContactInfoMutation, UpdateContactInfoMutationVariables>;