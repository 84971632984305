/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import { BaseInventoryFragmentDoc } from '../../../shared-fragments/__generated__/inventory.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BaseInventoryBinLevelTableQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.InventoryBaseQueryShapeFilter>;
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  sorting?: Types.InputMaybe<Array<Types.InventoryBaseQueryShapeSort> | Types.InventoryBaseQueryShapeSort>;
  includePageInfo?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeNodes?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeTotalCount?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type BaseInventoryBinLevelTableQuery = { __typename?: 'Query', query: { __typename?: 'InventoryBaseQueryShapeOffsetConnection', totalCount?: number, pageInfo?: { __typename?: 'OffsetPageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null }, nodes?: Array<{ __typename?: 'InventoryBaseQueryShape', areaCode?: string | null, areaId?: string | null, availableQuantity?: string | null, binCode?: string | null, binId?: string | null, erpSalesOrder?: string | null, deliveryItemId?: string | null, stockDeliveryId?: string | null, stockDeliveryCode?: string | null, fulfillmentItemId?: string | null, stockFulfillmentId?: string | null, stockFulfillmentCode?: string | null, soldToBusinessPartnerId?: string | null, soldToBusinessPartnerName?: string | null, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, licensePlateStatus?: Types.LicensePlateStatusState | null, lotCode?: string | null, lotId?: string | null, lotManaged?: boolean | null, openTaskCount?: number | null, productCode?: string | null, productDescription?: string | null, productId?: string | null, quantity: string, stockStatus?: string | null, stockStatusTypeId?: string | null, unitOfMeasure?: string | null, unitOfMeasureId?: string | null, warehouseCode?: string | null, warehouseId?: string | null, deliveryItem?: string | null, fulfillmentItem?: string | null }> } };

export type BaseInventoryTableQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.InventoryBaseQueryShapeFilter>;
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  sorting?: Types.InputMaybe<Array<Types.InventoryBaseQueryShapeSort> | Types.InventoryBaseQueryShapeSort>;
  includePageInfo?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeNodes?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeTotalCount?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type BaseInventoryTableQuery = { __typename?: 'Query', query: { __typename?: 'InventoryBaseQueryShapeOffsetConnection', totalCount?: number, pageInfo?: { __typename?: 'OffsetPageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null }, nodes?: Array<{ __typename?: 'InventoryBaseQueryShape', areaCode?: string | null, areaId?: string | null, availableQuantity?: string | null, binCode?: string | null, binId?: string | null, erpSalesOrder?: string | null, deliveryItemId?: string | null, stockDeliveryId?: string | null, stockDeliveryCode?: string | null, fulfillmentItemId?: string | null, stockFulfillmentId?: string | null, stockFulfillmentCode?: string | null, soldToBusinessPartnerId?: string | null, soldToBusinessPartnerName?: string | null, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, licensePlateStatus?: Types.LicensePlateStatusState | null, lotCode?: string | null, lotId?: string | null, lotManaged?: boolean | null, openTaskCount?: number | null, productCode?: string | null, productDescription?: string | null, productId?: string | null, quantity: string, stockStatus?: string | null, stockStatusTypeId?: string | null, unitOfMeasure?: string | null, unitOfMeasureId?: string | null, warehouseCode?: string | null, warehouseId?: string | null, deliveryItem?: string | null, fulfillmentItem?: string | null }> } };


export const BaseInventoryBinLevelTableDocument = gql`
    query BaseInventoryBinLevelTable($filter: InventoryBaseQueryShapeFilter, $paging: OffsetPaging, $sorting: [InventoryBaseQueryShapeSort!], $includePageInfo: Boolean = true, $includeNodes: Boolean = true, $includeTotalCount: Boolean = false) {
  query: baseInventory(sorting: $sorting, paging: $paging, filter: $filter) {
    pageInfo @include(if: $includePageInfo) {
      hasNextPage
      hasPreviousPage
    }
    nodes @include(if: $includeNodes) {
      ...BaseInventory
    }
    totalCount @include(if: $includeTotalCount)
  }
}
    ${BaseInventoryFragmentDoc}`;

/**
 * __useBaseInventoryBinLevelTableQuery__
 *
 * To run a query within a React component, call `useBaseInventoryBinLevelTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseInventoryBinLevelTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseInventoryBinLevelTableQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      includePageInfo: // value for 'includePageInfo'
 *      includeNodes: // value for 'includeNodes'
 *      includeTotalCount: // value for 'includeTotalCount'
 *   },
 * });
 */
export function useBaseInventoryBinLevelTableQuery(baseOptions?: Apollo.QueryHookOptions<BaseInventoryBinLevelTableQuery, BaseInventoryBinLevelTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BaseInventoryBinLevelTableQuery, BaseInventoryBinLevelTableQueryVariables>(BaseInventoryBinLevelTableDocument, options);
      }
export function useBaseInventoryBinLevelTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BaseInventoryBinLevelTableQuery, BaseInventoryBinLevelTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BaseInventoryBinLevelTableQuery, BaseInventoryBinLevelTableQueryVariables>(BaseInventoryBinLevelTableDocument, options);
        }
export type BaseInventoryBinLevelTableQueryHookResult = ReturnType<typeof useBaseInventoryBinLevelTableQuery>;
export type BaseInventoryBinLevelTableLazyQueryHookResult = ReturnType<typeof useBaseInventoryBinLevelTableLazyQuery>;
export type BaseInventoryBinLevelTableQueryResult = Apollo.QueryResult<BaseInventoryBinLevelTableQuery, BaseInventoryBinLevelTableQueryVariables>;
export const BaseInventoryTableDocument = gql`
    query BaseInventoryTable($filter: InventoryBaseQueryShapeFilter, $paging: OffsetPaging, $sorting: [InventoryBaseQueryShapeSort!], $includePageInfo: Boolean = true, $includeNodes: Boolean = true, $includeTotalCount: Boolean = false) {
  query: baseInventoryAll(sorting: $sorting, paging: $paging, filter: $filter) {
    pageInfo @include(if: $includePageInfo) {
      hasNextPage
      hasPreviousPage
    }
    nodes @include(if: $includeNodes) {
      ...BaseInventory
    }
    totalCount @include(if: $includeTotalCount)
  }
}
    ${BaseInventoryFragmentDoc}`;

/**
 * __useBaseInventoryTableQuery__
 *
 * To run a query within a React component, call `useBaseInventoryTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseInventoryTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseInventoryTableQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      includePageInfo: // value for 'includePageInfo'
 *      includeNodes: // value for 'includeNodes'
 *      includeTotalCount: // value for 'includeTotalCount'
 *   },
 * });
 */
export function useBaseInventoryTableQuery(baseOptions?: Apollo.QueryHookOptions<BaseInventoryTableQuery, BaseInventoryTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BaseInventoryTableQuery, BaseInventoryTableQueryVariables>(BaseInventoryTableDocument, options);
      }
export function useBaseInventoryTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BaseInventoryTableQuery, BaseInventoryTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BaseInventoryTableQuery, BaseInventoryTableQueryVariables>(BaseInventoryTableDocument, options);
        }
export type BaseInventoryTableQueryHookResult = ReturnType<typeof useBaseInventoryTableQuery>;
export type BaseInventoryTableLazyQueryHookResult = ReturnType<typeof useBaseInventoryTableLazyQuery>;
export type BaseInventoryTableQueryResult = Apollo.QueryResult<BaseInventoryTableQuery, BaseInventoryTableQueryVariables>;