import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IUserGroup } from './create-user-group';
import UserGroupModalBase from './user-group-modal-base';

import {
  useCreateUserGroupMutation,
  useUpdateRolesForUserGroupMutation,
} from '@/graphql/defs/components/modals/__generated__/user-group-modal.generated';
import { useViewUserGroupsLazyQuery } from '@/graphql/defs/shared-queries/__generated__/view-user-groups.generated';
import { Role } from '@/graphql/types.generated';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import FormValues from '@models/FormValues';
import { IUserGroupCopyModal } from '@models/modal';

const CopyUserGroupModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const modalContext = useModalContent<IUserGroupCopyModal>();
  const { modal, closeModal, setLoading } = modalContext;

  const formReturn = useForm<FormValues<IUserGroup>>({
    defaultValues: {
      name: `${modal.userGroupName} - ${t('common.copy')}`,
      warehouses: [],
      roles: [],
    },
  });

  const [viewUserGroups] = useViewUserGroupsLazyQuery();

  const preload = async () => {
    const {
      data: {
        viewUserGroups: { nodes: userGroups },
      },
    } = await viewUserGroups({
      variables: {
        filter: {
          userGroupId: {
            eq: modal.userGroupId,
          },
        },
      },
    });
    const valueObjects = userGroups.reduce(
      (acc, group) => {
        acc.roles[group.roleId] = { id: group.roleId, name: group.roleName };
        acc.warehouses[group.warehouseId] = { id: group.warehouseId, name: group.warehouseName };
        return acc;
      },
      { roles: {}, warehouses: {} },
    );
    const roles = Object.values(valueObjects.roles) as Role[];
    const warehouses = Object.values(valueObjects.warehouses) as { name: string; id: string }[];
    formReturn.setValue('roles', roles);
    formReturn.setValue('warehouses', warehouses);

    return { roles, warehouses };
  };

  const [createUserGroup] = useCreateUserGroupMutation({
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const [updateRoles] = useUpdateRolesForUserGroupMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t('userGroupActions.createSuccess', { name: formReturn.getValues().name }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: error.message,
      });
      closeModal({ bypassLoading: true, success: true });
    },
  });

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = async (fields: IUserGroup) => {
    const {
      data: {
        createOneUserGroup: { id: userGroupId },
      },
    } = await createUserGroup({
      variables: {
        input: {
          userGroup: {
            name: fields.name,
          },
        },
      },
    });
    await updateRoles({
      variables: {
        input: {
          userGroupId,
          roleIds: fields.roles.map(({ id }) => id),
          warehouseIds: fields.warehouses.map(({ id }) => id),
        },
      },
    });
  };

  return (
    <UserGroupModalBase
      onSubmit={onSubmit}
      onCancel={onCancel}
      formReturn={formReturn}
      modalContext={modalContext}
      preload={preload}
    />
  );
};

export default CopyUserGroupModal;
