import {
  AddBoxOutlined,
  CallMergeOutlined,
  ForwardOutlined,
  KeyboardArrowDown,
  QueueOutlined,
  UploadOutlined,
} from '@mui/icons-material';
import BlockIcon from '@mui/icons-material/Block';
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  PopoverOrigin,
  Theme,
  Tooltip,
} from '@mui/material';
import { SxProps } from '@mui/system';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { LicensePlateInventoryFragment } from '@/graphql/defs/hooks/shared-columns/__generated__/useCreateLicensePlateLevelInventoryColumns.generated';
import { BaseInventoryFragment } from '@/graphql/defs/shared-fragments/__generated__/inventory.generated';
import {
  ValidateTaskQueryVariables,
  useValidateTaskLazyQuery,
} from '@/graphql/defs/validate/__generated__/validate-task.generated';
import { LicensePlateStatusState } from '@/graphql/types.generated';
import { ErrorActionButton } from '@components/errors/error-action-button';
import UnpackLpIcon from '@components/icons/unpack-lp';
import { TaskTypeCodes } from '@constants/task-type-config';
import { useEntityUtils } from '@context/entity-utils';
import { useModalToggle } from '@context/modal/ModalToggleProvider';
import { useSnackbar } from '@context/snackbar';
import { useWarehouseFeatureFlags } from '@context/warehouse-feature-flags';
import { LicensePlateSelection } from '@models/license-plate-actions';
import { ModalTypes, TRefetchDataTable } from '@models/modal';
import MenuItem from '@styled/MenuItem';

interface ILicensePlateActions {
  inventoryType?: 'inventoryItems' | 'licensePlateDetails';
  sourceType?: 'inventory' | 'delivery';
  selectedInventory: LicensePlateInventoryFragment[] | BaseInventoryFragment[];
  handleActionComplete: TRefetchDataTable;
  forceAutocomplete?: boolean;
  removeCreate?: boolean;
  removeAdd?: boolean;
  removeMerge?: boolean;
  removeMove?: boolean;
  removeUnpack?: boolean;
  removeBlock?: boolean;
  setValidating?: Dispatch<SetStateAction<boolean>>;
  externalMenuAnchor?: MutableRefObject<any>;
  externalIsOpen?: boolean;
  handleExternalAnchorClose?: (closeParentMenu: boolean) => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  sx?: SxProps<Theme>;
}

const LicensePlateActions: React.FC<ILicensePlateActions> = ({
  inventoryType = 'inventoryItems',
  sourceType = 'inventory',
  selectedInventory,
  handleActionComplete,
  forceAutocomplete = false,
  removeCreate = false,
  removeAdd = false,
  removeMerge = false,
  removeMove = false,
  removeUnpack = false,
  removeBlock = false,
  setValidating,
  externalMenuAnchor,
  externalIsOpen,
  handleExternalAnchorClose,
  anchorOrigin,
  transformOrigin,
  sx,
}) => {
  const { t } = useTranslation('components');
  const { openModal } = useModalToggle();
  const { showMessage } = useSnackbar();
  const { selectedWarehouseId } = useEntityUtils();
  const [validateTask] = useValidateTaskLazyQuery();
  const {
    warehouseFeatureFlags: {
      inventory: { licensePlateActions: lpActionFlags },
    },
  } = useWarehouseFeatureFlags();

  const internalMenuAnchor = useRef(null);
  const [internalIsOpen, internalSetIsOpen] = useState(false);

  const [menuAnchor, isOpen] = useMemo(() => {
    return !!handleExternalAnchorClose
      ? [externalMenuAnchor, externalIsOpen]
      : [internalMenuAnchor, internalIsOpen];
  }, [
    internalMenuAnchor,
    internalIsOpen,
    externalMenuAnchor,
    externalIsOpen,
    handleExternalAnchorClose,
  ]);
  const closeMenu = useCallback(
    (closeParentMenu = false) => {
      if (!!handleExternalAnchorClose) {
        handleExternalAnchorClose(closeParentMenu);
      } else {
        internalSetIsOpen(false);
      }
    },
    [handleExternalAnchorClose],
  );

  const onValidateTask = async ({ toValidate, openModal }) => {
    if (setValidating) setValidating(true);
    try {
      const validations = await Promise.all(
        toValidate.map((variables) => {
          return validateTask({ variables });
        }),
      );

      // check if any of the mappings were invalid
      if (validations.some(({ data: { validateTask } }) => !validateTask.valid)) {
        const errors = validations.reduce((acc, { data: { validateTask } }) => {
          return acc.concat(validateTask.errors);
        }, []);
        const loggedCodes = {};

        errors.forEach((error) => {
          if (!loggedCodes[error.code]) {
            showMessage({
              title: 'Error',
              message: error.message,
              duration: error.duration,
              type: 'error', // TODO: use i18n for 'viewStatus'
              action:
                error.code === 'TASK_TYPE_STOCK_STATUS_RESTRICTION' ? (
                  <ErrorActionButton href="/settings/task-type-stock-status" text={'viewStatus'} />
                ) : (
                  <ErrorActionButton href="/settings/bins" text={'viewStatus'} />
                ),
            });
            loggedCodes[error.code] = true;
          }
        });
      } else {
        openModal();
      }
    } catch (err) {
      showMessage({
        title: 'Error',
        message: err.message,
        duration: 5000,
        type: 'error',
      });
    }
    if (setValidating) setValidating(false);
  };

  interface IUserSelection {
    licensePlateId?: string;
    licensePlateCode?: string;
    licensePlateStatus?: LicensePlateStatusState;
    binId: string;
    binCode: string;
    item: BaseInventoryFragment | LicensePlateInventoryFragment;
    stockStatusId?: string;
    hasOpenTasks?: boolean;
    deliveryItemId?: string;
    fulfillmentItemId?: string;
  }
  const userSelection = useMemo<IUserSelection[]>(() => {
    if (inventoryType === 'inventoryItems') {
      const typedSelection = selectedInventory as BaseInventoryFragment[];
      return typedSelection.map<IUserSelection>((item) => ({
        licensePlateId: item.licensePlateId,
        licensePlateCode: item.licensePlateCode,
        licensePlateStatus: item.licensePlateStatus,
        binId: item.binId,
        binCode: item.binCode,
        stockStatusId: item.stockStatusTypeId,
        item,
        hasOpenTasks: item.openTaskCount > 0,
        deliveryItemId: item.deliveryItemId,
        fulfillmentItemId: item.fulfillmentItemId,
      }));
    } else {
      const typedSelection = selectedInventory as LicensePlateInventoryFragment[];
      return typedSelection.map<IUserSelection>((item) => ({
        licensePlateId: item.id,
        licensePlateCode: item.code,
        licensePlateStatus: item.status,
        binId: item.binId,
        binCode: item.binCode,
        item,
        hasOpenTasks: item.hasOpenTasks,
        deliveryItemId: item.deliveryItemId,
        fulfillmentItemId: item.fulfillmentItemId,
      }));
    }
  }, [selectedInventory]);

  const getSelectedLicensePlates = () => {
    const licensePlates: LicensePlateSelection[] = [];
    const licensePlateIds = [];
    userSelection.forEach((item) => {
      if (!licensePlateIds.includes(item.licensePlateId)) {
        licensePlateIds.push(item.licensePlateId);
        licensePlates.push({
          id: item.licensePlateId,
          code: item.licensePlateCode,
          deliveryItemId: item.deliveryItemId,
          fulfillmentItemId: item.fulfillmentItemId,
        });
      }
    });
    return licensePlates;
  };

  const isMultipleBins = useMemo(() => {
    const firstItem = userSelection[0];
    const otherBins = userSelection.filter((item) => item.binId !== firstItem.binId);
    // Disable if otherBins length is greater than 0, as we don't support multiple bin selection currently
    return otherBins.length > 0;
  }, [userSelection]);

  const createTooltipError = useMemo(() => {
    // Disable if there is no userSelection
    if (userSelection.length === 0) {
      return t('licensePlateActions.selectionRequiredTooltip');
    }

    // Disable if any inventory items are already associated to a License Plate
    const licensePlateItems = userSelection.some((item) => !!item.licensePlateId);
    if (licensePlateItems) {
      return t('licensePlateActions.existingLPNotAllowedTooltip');
    }

    // Prevent license plate creation from multiple bins
    if (isMultipleBins) {
      return t('licensePlateActions.sameBinSelectionTooltip');
    }
  }, [userSelection]);

  const addTooltipError = useMemo(() => {
    // Disable if there is not at least 2 items selected
    if (userSelection.length < 2) {
      return t('licensePlateActions.multiSelectionRequiredTooltip');
    }

    // Check that all licensPlates don't have open tasks against them
    if (userSelection.some((item) => item.hasOpenTasks)) {
      return t('licensePlateActions.openTaskTooltip');
    }

    // Disabled if license plate is blocked
    if (
      userSelection.some(
        ({ licensePlateStatus }) => licensePlateStatus === LicensePlateStatusState.Inactive,
      )
    ) {
      return t('licensePlateActions.blockedLPTooltip');
    }

    // Disabled selection of more than one license plate.
    let licensePlateId = null;
    for (const item of userSelection) {
      if (item.licensePlateId) {
        // First item with an associated license plate code, save its reference and continue in the loop.
        if (!licensePlateId) {
          licensePlateId = item.licensePlateId;
          continue;
        }
        // item has an associated license plate, and we already have a saved reference from a previous item.
        // check that licensePlateId matches item.licensePlateId - return true to disable addTo if licensePlatesCode do not match.
        if (item.licensePlateId !== licensePlateId) {
          return t('licensePlateActions.multiLPSelectionInvalidTooltip');
        }
      }
    }

    const noLicensePlateItems = userSelection.some((item) => !item.licensePlateId);
    // If licensePlateId has a saved reference, then at least one item has an associated license plate and all others either have the same or are not yet associated with any.
    if (licensePlateId === null) {
      return t('licensePlateActions.lpSelectionRequiredTooltip');
    }
    // If noLicensePlateItems.length === false, then all the selected items have an associated license plate
    if (!noLicensePlateItems) {
      return t('licensePlateActions.nonLPSelectionRequiredTooltip');
    }
    // disalbed selection of multiple bins
    if (isMultipleBins) {
      return t('licensePlateActions.sameBinSelectionTooltip');
    }
  }, [userSelection]);

  const moveTooltipError = useMemo(() => {
    // Disable if there is no userSelection
    if (userSelection.length === 0) {
      return t('licensePlateActions.selectionRequiredTooltip');
    }

    // Disabled if license plate is blocked
    if (
      userSelection.some(
        ({ licensePlateStatus }) => licensePlateStatus === LicensePlateStatusState.Inactive,
      )
    ) {
      return t('licensePlateActions.blockedLPTooltip');
    }

    // Check that all licensPlates don't have open tasks against them
    if (userSelection.some((item) => item.hasOpenTasks)) {
      return t('licensePlateActions.openTaskTooltip');
    }

    const nonLPItems = userSelection.filter((item) => !item.licensePlateId);

    // Disable if any selected item doesn't have an associated license plate
    if (nonLPItems.length > 0) {
      return t('licensePlateActions.allSelectionsMustBeInALPTooltip');
    }

    // Prevent license plate movement from multiple bins
    if (isMultipleBins) {
      return t('licensePlateActions.sameBinSelectionTooltip');
    }
  }, [userSelection]);

  const mergeTooltipError = useMemo(() => {
    // Disable if there is not at least 2 items selected
    if (userSelection.length < 2) {
      return t('licensePlateActions.multiSelectionRequiredTooltip');
    }

    // Disabled if license plate is blocked
    if (
      userSelection.some(
        ({ licensePlateStatus }) => licensePlateStatus === LicensePlateStatusState.Inactive,
      )
    ) {
      return t('licensePlateActions.blockedLPTooltip');
    }

    // Check that all licensPlates don't have open tasks against them
    if (userSelection.some((item) => item.hasOpenTasks)) {
      return t('licensePlateActions.openTaskTooltip');
    }

    // Check that all items have an associated licensePlateCode
    for (const item of userSelection) {
      if (!item.licensePlateId) return t('licensePlateActions.allSelectionsMustBeInALPTooltip');
    }

    // Check that there are at least two different license plates
    const licensePlates = getSelectedLicensePlates();
    if (licensePlates.length <= 1) {
      return t('licensePlateActions.multiLPSelectionRequiredTooltip');
    }

    // disalbed selection of multiple bins
    if (isMultipleBins) {
      return t('licensePlateActions.sameBinSelectionTooltip');
    }
  }, [userSelection]);

  const unpackTooltipError = useMemo(() => {
    // Disable if there is no userSelection
    if (userSelection.length === 0) {
      return t('licensePlateActions.selectionRequiredTooltip');
    }

    // Check that all licensPlates don't have open tasks against them
    if (userSelection.some((item) => item.hasOpenTasks)) {
      return t('licensePlateActions.openTaskTooltip');
    }

    // Disabled if license plate is blocked
    if (
      userSelection.some(
        ({ licensePlateStatus }) => licensePlateStatus === LicensePlateStatusState.Inactive,
      )
    ) {
      return t('licensePlateActions.blockedLPTooltip');
    }

    // Check that all items have an associated licensePlateCode
    for (const item of userSelection) {
      if (!item.licensePlateId) return t('licensePlateActions.allSelectionsMustBeInALPTooltip');
    }

    // disalbed selection of multiple bins
    if (isMultipleBins) {
      return t('licensePlateActions.sameBinSelectionTooltip');
    }
  }, [userSelection]);

  const blockLP = useMemo(() => {
    // Disable if there is not at least 1 item selected
    return userSelection.some(
      ({ licensePlateStatus }) => licensePlateStatus === LicensePlateStatusState.Active,
    );
  }, [userSelection]);

  const blockTooltipError = useMemo(() => {
    // Disable if there is no userSelection
    if (userSelection.length === 0) {
      return t('licensePlateActions.selectionRequiredTooltip');
    }

    // Check that all licensPlates don't have open tasks against them
    if (userSelection.some((item) => item.hasOpenTasks)) {
      return t('licensePlateActions.openTaskTooltip');
    }

    // Check that all items have an associated licensePlateCode
    for (const item of userSelection) {
      if (!item.licensePlateId) return t('licensePlateActions.allSelectionsMustBeInALPTooltip');
    }
  }, [userSelection]);

  const getLicensePlateInfo = () => {
    for (const item of userSelection) {
      if (item.licensePlateId) {
        return { licensePlateId: item.licensePlateId, licensePlateCode: item.licensePlateCode };
      }
    }
    return { licensePlateId: null, licensePlateCode: null };
  };

  const openCreateModal = () => {
    if (createTooltipError) return;
    closeMenu(false);
    openModal(
      {
        type: ModalTypes.licensePlateCreate,
        title: t('licensePlateActions.createNew'),
        inventoryItems: selectedInventory as BaseInventoryFragment[],
        forceAutocomplete,
      },
      {
        afterClose: (success) => {
          if (success) {
            handleActionComplete();
          }
        },
      },
    );
  };

  const openAddToModal = () => {
    if (addTooltipError) return;
    const { licensePlateId, licensePlateCode } = getLicensePlateInfo();
    if (licensePlateId) {
      closeMenu(true);
      openModal(
        {
          type: ModalTypes.licensePlateAddTo,
          title: t('licensePlateActions.addTo'),
          licensePlateId,
          licensePlateCode,
          inventoryItems: selectedInventory as BaseInventoryFragment[],
          forceAutocomplete,
        },
        {
          afterClose: (success) => {
            if (success) {
              handleActionComplete();
            }
          },
        },
      );
    }
  };

  const openMoveModal = () => {
    if (moveTooltipError) return;
    const licensePlates = getSelectedLicensePlates();
    if (licensePlates.length >= 1) {
      closeMenu(true);
      openModal(
        {
          type: ModalTypes.licensePlateMove,
          licensePlates,
          forceAutocomplete,
        },
        {
          afterClose: (success) => {
            if (success) {
              handleActionComplete();
            }
          },
        },
      );
    }
  };

  const openMergeModal = () => {
    if (mergeTooltipError) return;
    const licensePlates = getSelectedLicensePlates();
    if (licensePlates.length > 1) {
      closeMenu(true);
      openModal(
        {
          type: ModalTypes.licensePlateMerge,
          title: t('licensePlateActions.mergeHeader'),
          source: sourceType,
          licensePlates,
          binId: userSelection[0].binId,
          binCode: userSelection[0].binCode,
          forceAutocomplete,
        },
        {
          afterClose: (success) => {
            if (success) {
              handleActionComplete();
            }
          },
        },
      );
    }
  };

  const openUnpackModal = () => {
    if (unpackTooltipError) return;
    const licensePlates = getSelectedLicensePlates();
    if (licensePlates.length > 0) {
      closeMenu(true);
      openModal(
        {
          type: ModalTypes.licensePlateUnpack,
          title: t('licensePlateActions.unpackHeader', { count: licensePlates.length }),
          source: sourceType,
          licensePlates,
          binCode: userSelection[0].binCode,
          forceAutocomplete,
        },
        {
          afterClose: (success) => {
            if (success) {
              handleActionComplete();
            }
          },
        },
      );
    }
  };

  const openChangeLPStatusModal = () => {
    if (!!blockTooltipError) return;
    closeMenu(true);
    openModal(
      {
        type: ModalTypes.licensePlateBlock,
        title: t(blockLP ? 'licensePlateActions.blockHeader' : 'licensePlateActions.unblockHeader'),
        licensePlateIds: userSelection.map(({ licensePlateId }) => licensePlateId),
        block: blockLP,
      },
      {
        afterClose: (success) => {
          if (success) {
            handleActionComplete();
          }
        },
      },
    );
  };

  const createCreateLPVariables = (): ValidateTaskQueryVariables[] => {
    return userSelection.map((item) => ({
      warehouseId: selectedWarehouseId,
      taskTypeCode: TaskTypeCodes.licensePlateMovement,
      taskTypeBinStatus: {
        sourceBinId: item.binId,
      },
      taskTypeStockStatus: {
        stockStatusId: item.stockStatusId,
      },
    }));
  };

  const createAddToLPVariables = (): ValidateTaskQueryVariables[] =>
    userSelection.map((item) => ({
      warehouseId: selectedWarehouseId,
      taskTypeCode: TaskTypeCodes.licensePlateMovement,
      taskTypeBinStatus: {
        sourceBinId: item.binId,
      },
      taskTypeStockStatus: {
        stockStatusId: item.stockStatusId,
      },
    }));

  const createMoveLPVariables = (): ValidateTaskQueryVariables[] =>
    userSelection.map((item) => ({
      warehouseId: selectedWarehouseId,
      taskTypeCode: TaskTypeCodes.binToBinMovement,
      taskTypeBinStatus: {
        sourceBinId: item.binId,
      },
      taskTypeStockStatusForLicensePlate: {
        licensePlateId: item.licensePlateId,
      },
    }));

  const createMergeLPVariables = (): ValidateTaskQueryVariables[] =>
    userSelection.map((item) => ({
      warehouseId: selectedWarehouseId,
      taskTypeCode: TaskTypeCodes.licensePlateMerge,
      taskTypeBinStatus: {
        sourceBinId: item.binId,
      },
    }));

  const createUnpackLPVariables = (): ValidateTaskQueryVariables[] =>
    userSelection.map((item) => ({
      warehouseId: selectedWarehouseId,
      taskTypeCode: TaskTypeCodes.licensePlateUnpackTask,
      taskTypeBinStatus: {
        sourceBinId: item.binId,
      },
      taskTypeStockStatusForLicensePlate: {
        licensePlateId: item.licensePlateId,
      },
    }));

  const validateCreateLP = () => {
    onValidateTask({
      toValidate: createCreateLPVariables(),
      openModal: openCreateModal,
    });
  };

  const validateAddToLP = () =>
    onValidateTask({
      toValidate: createAddToLPVariables(), // [TaskTypeCodes.licensePlateMovement],
      openModal: openAddToModal,
    });

  const validateMoveLP = () =>
    onValidateTask({
      toValidate: createMoveLPVariables(),
      openModal: openMoveModal,
    });

  const validateMergeLP = () =>
    onValidateTask({
      toValidate: createMergeLPVariables(),
      openModal: openMergeModal,
    });

  const validateUnpackLP = () =>
    onValidateTask({
      toValidate: createUnpackLPVariables(),
      openModal: openUnpackModal,
    });

  return (
    <>
      {!externalMenuAnchor && (
        <Button
          data-testid="licensePlateActions_button"
          ref={internalMenuAnchor}
          variant="contained"
          color="primary"
          endIcon={<KeyboardArrowDown fontSize="inherit" />}
          onClick={() => internalSetIsOpen(true)}
          sx={{
            textTransform: 'capitalize',
            fontWeight: 500,
            padding: '5px 25px',
            '& svg:nth-of-type(1)': { fontSize: 28 },
            ...sx,
          }}
        >
          {t('common.licensePlate')}
        </Button>
      )}
      <Menu
        id="license-plate-actions-menu"
        data-testid="licensePlateActions_menu"
        anchorEl={menuAnchor.current}
        anchorOrigin={
          anchorOrigin
            ? anchorOrigin
            : {
                vertical: 'bottom',
                horizontal: 'right',
              }
        }
        transformOrigin={
          transformOrigin
            ? transformOrigin
            : {
                vertical: 'top',
                horizontal: 'right',
              }
        }
        open={isOpen}
        onClose={() => closeMenu(false)}
      >
        {!removeCreate && lpActionFlags.create && (
          /* Create New License Plate (Never disabled if !isAllActionsDisabled) */
          <Tooltip
            title={createTooltipError || ''}
            placement="left"
            arrow
            disableHoverListener={!createTooltipError}
            disableFocusListener
            disableTouchListener
            disableInteractive
          >
            <MenuItem
              styledVariant="actionMenu"
              data-testid="actionItem_createLicensePlate"
              isDisabled={!!createTooltipError}
              aria-disabled={!!createTooltipError}
              onClick={validateCreateLP}
            >
              <ListItemIcon>
                <AddBoxOutlined />
              </ListItemIcon>
              <ListItemText>{t('licensePlateActions.createNew')}</ListItemText>
            </MenuItem>
          </Tooltip>
        )}

        {!removeAdd && lpActionFlags.add && (
          /* Add to License Plate */
          <Tooltip
            title={addTooltipError || ''}
            placement="left"
            arrow
            disableHoverListener={!addTooltipError}
            disableFocusListener
            disableTouchListener
            disableInteractive
          >
            <MenuItem
              styledVariant="actionMenu"
              data-testid="actionItem_addToLicensePlate"
              isDisabled={!!addTooltipError}
              aria-disabled={!!addTooltipError}
              onClick={validateAddToLP}
            >
              <ListItemIcon>
                <QueueOutlined />
              </ListItemIcon>
              <ListItemText>{t('licensePlateActions.addTo')}</ListItemText>
            </MenuItem>
          </Tooltip>
        )}

        {!removeMove && lpActionFlags.move && (
          /* Move License Plate */
          <Tooltip
            title={moveTooltipError || ''}
            placement="left"
            arrow
            disableHoverListener={!moveTooltipError}
            disableFocusListener
            disableTouchListener
            disableInteractive
          >
            <MenuItem
              styledVariant="actionMenu"
              data-testid="actionItem_moveLicensePlate"
              isDisabled={!!moveTooltipError}
              aria-disabled={!!moveTooltipError}
              onClick={validateMoveLP}
            >
              <ListItemIcon>
                <ForwardOutlined />
              </ListItemIcon>
              <ListItemText>{t('licensePlateActions.move')}</ListItemText>
            </MenuItem>
          </Tooltip>
        )}

        {!removeMerge && lpActionFlags.merge && (
          /* Merge License Plates */
          <Tooltip
            title={mergeTooltipError || ''}
            placement="left"
            arrow
            disableHoverListener={!mergeTooltipError}
            disableFocusListener
            disableTouchListener
            disableInteractive
          >
            <MenuItem
              styledVariant="actionMenu"
              data-testid="actionItem_mergeLicensePlates"
              isDisabled={!!mergeTooltipError}
              aria-disabled={!!mergeTooltipError}
              onClick={validateMergeLP}
            >
              <ListItemIcon>
                <CallMergeOutlined />
              </ListItemIcon>
              <ListItemText>{t('licensePlateActions.merge')}</ListItemText>
            </MenuItem>
          </Tooltip>
        )}

        {!removeUnpack && lpActionFlags.unpack && (
          /* Merge License Plates */
          <Tooltip
            title={unpackTooltipError || ''}
            placement="left"
            arrow
            disableHoverListener={!unpackTooltipError}
            disableFocusListener
            disableTouchListener
            disableInteractive
          >
            <MenuItem
              styledVariant="actionMenu"
              data-testid="actionItem_unpackLicensePlates"
              isDisabled={!!unpackTooltipError}
              aria-disabled={!!unpackTooltipError}
              onClick={validateUnpackLP}
            >
              <ListItemIcon>
                <UnpackLpIcon />
              </ListItemIcon>
              <ListItemText>{t('licensePlateActions.unpack')}</ListItemText>
            </MenuItem>
          </Tooltip>
        )}

        {!removeBlock && lpActionFlags.block && (
          /* Block License Plates */
          <Tooltip
            title={blockTooltipError || ''}
            placement="left"
            arrow
            disableHoverListener={!blockTooltipError}
            disableFocusListener
            disableTouchListener
            disableInteractive
          >
            <MenuItem
              styledVariant="actionMenu"
              data-testid="actionItem_unpackLicensePlates"
              isDisabled={!!blockTooltipError}
              aria-disabled={!!blockTooltipError}
              onClick={openChangeLPStatusModal}
            >
              <ListItemIcon>{blockLP ? <BlockIcon /> : <UploadOutlined />}</ListItemIcon>
              <ListItemText>
                {t(blockLP ? 'licensePlateActions.block' : 'licensePlateActions.unblock')}
              </ListItemText>
            </MenuItem>
          </Tooltip>
        )}
      </Menu>
    </>
  );
};

export default LicensePlateActions;
