import { ApolloError } from '@apollo/client';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Tooltip,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useCopyStockStatusTypeMutation,
  useCreateStockStatusTypeMutation,
  useDeleteStockStatusTypeMutation,
  useEditStockStatusTypeMutation,
} from '@/graphql/defs/components/modals/__generated__/stock-status-crud-modal.generated';
import { useListAllSapStockStatusTypesQuery } from '@/graphql/defs/list/__generated__/list-sap-stock-status-types.generated';
import { ListAllSapStockStatusTypes_defaultData } from '@/graphql/defs/list/list-sap-stock-status-types';
import { StockStatusTypeStatus } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useFeatureFlags } from '@context/feature-flags';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import { uppercase } from '@lib/form';
import FormValues from '@models/FormValues';
import {
  ICopyStockStatusModal,
  ICreateStockStatusModal,
  IEditStockStatusModal,
  ModalTypes,
} from '@models/modal';

const StockStatusCrudModal = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.stock-status-settings' });
  const { t: tC } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { applicationFeatureFlags } = useFeatureFlags();
  const { notWhiteSpaceOnly } = useFormValidation();
  const { modal, closeModal, setLoading } = useModalContent<
    ICreateStockStatusModal | ICopyStockStatusModal | IEditStockStatusModal
  >();

  const onCrudError = (error: ApolloError) => {
    setLoading(false);
    showMessage({ type: 'error', message: error.message });
  };

  const { data: sapStockStatusTypesData } = useListAllSapStockStatusTypesQuery();
  const {
    listAllSapStockStatusTypes: { sapStockStatusTypes },
  } = useMemo(
    () => sapStockStatusTypesData || ListAllSapStockStatusTypes_defaultData,
    [sapStockStatusTypesData],
  );

  const [createStockStatusType] = useCreateStockStatusTypeMutation({
    onCompleted: async ({ createOneStockStatusType: { label } }) => {
      showMessage({ type: 'success', message: t('createdStockStatusType', { label }) });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: onCrudError,
  });

  const [copyStockStatusType] = useCopyStockStatusTypeMutation({
    onCompleted: async ({ copyOneStockStatusType: { label } }) => {
      // Have to use conditional on modal.type so modal.stockStatusType.label is valid.
      if (modal.type === ModalTypes.stockStatusCopy)
        showMessage({
          type: 'success',
          message: t('copiedStockStatusType', {
            label,
            copiedLabel: modal.stockStatusType.label,
          }),
        });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: onCrudError,
  });

  const [editStockStatusType] = useEditStockStatusTypeMutation({
    onCompleted: async ({ updateOneStockStatusType: { label } }) => {
      showMessage({
        type: 'success',
        message: t('editedStockStatusType', { label }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: onCrudError,
  });

  const [deleteStockStatusType] = useDeleteStockStatusTypeMutation({
    onCompleted: async () => {
      // Have to use conditional on modal.type so modal.stockStatusType.label is valid.
      if (modal.type === ModalTypes.stockStatusEdit)
        showMessage({
          type: 'success',
          message: t('deletedStockStatusType', { label: modal.stockStatusType.label }),
        });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: onCrudError,
  });

  interface StockStatusTypeFields {
    code: string;
    label: string;
    description: string;
    erpMapping: string;
    stockStatusTypeStatus: StockStatusTypeStatus;
    defaultStockStatusType: boolean;
  }

  const formMethods = useForm<FormValues<StockStatusTypeFields>>({
    defaultValues: {
      code: modal.type !== ModalTypes.stockStatusEdit ? '' : modal.stockStatusType.code,
      label: modal.type === ModalTypes.stockStatusCreate ? '' : modal.stockStatusType.label,
      description:
        modal.type === ModalTypes.stockStatusCreate ? '' : modal.stockStatusType.description,
      erpMapping:
        modal.type === ModalTypes.stockStatusCreate ? '' : modal.stockStatusType.sapStockStatusId,
      stockStatusTypeStatus:
        modal.type === ModalTypes.stockStatusCreate
          ? StockStatusTypeStatus.Active
          : modal.stockStatusType.stockStatusTypeStatus,
      defaultStockStatusType:
        modal.type !== ModalTypes.stockStatusEdit ? false : modal.stockStatusType.default,
    },
  });
  const { control, formState, handleSubmit, setValue, watch } = formMethods;

  // When the user updates defaultStockStatusType to true, we need to force set ERP Mapping to the defaultValue - so we can then disable the field.
  const watchDefault = watch('defaultStockStatusType');
  useEffect(() => {
    if (modal.type === ModalTypes.stockStatusEdit && formState.isDirty && watchDefault) {
      setValue('erpMapping', modal.stockStatusType.sapStockStatusId);
    }
  }, [watchDefault]);

  const onSubmit = (formStockStatusType: StockStatusTypeFields) => {
    const stockStatusType = {
      code: formStockStatusType.code,
      label: formStockStatusType.label,
      description: formStockStatusType.description,
      sapStockStatusTypeId: formStockStatusType.erpMapping,
      stockStatusTypeStatus: formStockStatusType.stockStatusTypeStatus,
    };

    switch (modal.type) {
      case ModalTypes.stockStatusCreate:
        void createStockStatusType({
          variables: {
            stockStatusType: stockStatusType,
          },
        });
        break;
      case ModalTypes.stockStatusCopy:
        void copyStockStatusType({
          variables: {
            stockStatusType: {
              id: modal.stockStatusType.id,
              ...stockStatusType,
            },
          },
        });
        break;
      case ModalTypes.stockStatusEdit:
        void editStockStatusType({
          variables: {
            id: modal.stockStatusType.id,
            stockStatusTypeUpdate: {
              ...stockStatusType,
              default: formStockStatusType.defaultStockStatusType,
            },
          },
        });
        break;
    }
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <Box
          sx={{
            display: 'grid',
            columnGap: 4,
            rowGap: 3,
            gridTemplateColumns: 'repeat(2, 1fr)',
            minWidth: '600px',
          }}
        >
          <Controller
            name="code"
            control={control}
            rules={{
              required: tC('form.validation.requiredField', { field: tC('common.code') }),
              validate: {
                notWhiteSpaceOnly,
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                autoFocus
                fullWidth
                required
                id="stock-status-code"
                label={tC('common.code')}
                variant="standard"
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                {...field}
                onChange={(e) => field.onChange(uppercase(e))}
                dataTestId="add-status-code"
              />
            )}
          />
          <Controller
            name="label"
            control={control}
            rules={{
              required: tC('form.validation.requiredField', { field: tC('common.label') }),
              validate: {
                notWhiteSpaceOnly,
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                required
                id="stock-status-label"
                label={tC('common.label')}
                variant="standard"
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                {...field}
                dataTestId="add-status-label"
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                id="stock-status-description"
                label={tC('common.description')}
                variant="standard"
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                {...field}
                sx={{ gridColumn: 'span 2' }}
                dataTestId="add-status-description"
              />
            )}
          />
          <Controller
            name="erpMapping"
            control={control}
            rules={{
              required: tC('form.validation.requiredField', { field: tC('common.erpMapping') }),
              validate: {
                notWhiteSpaceOnly,
              },
            }}
            render={({ field, fieldState }) => (
              <FormControl fullWidth error={!!fieldState?.error}>
                <Tooltip
                  title={
                    (modal.type === ModalTypes.stockStatusEdit && modal.stockStatusType.default) ||
                    watchDefault
                      ? t('erpDisableMessage')
                      : ''
                  }
                  arrow
                  followCursor
                >
                  {(modal.type === ModalTypes.stockStatusEdit && modal.stockStatusType.default) ||
                  watchDefault ? (
                    <div>
                      <InputLabel
                        required
                        variant="standard"
                        id="stock-status-erp-mapping-label"
                        disabled
                        shrink
                      >
                        {tC('common.erpMapping')}
                      </InputLabel>
                      <InputLabel
                        variant="standard"
                        id="stock-status-erp-mapping-label"
                        htmlFor="stock-status-erp-mapping"
                        disabled
                        shrink={false}
                        data-testid="add-status-erpmapping-disabled"
                      >
                        {
                          sapStockStatusTypes.find((status) => {
                            return status.id === field.value;
                          })?.label
                        }
                      </InputLabel>
                    </div>
                  ) : (
                    <div>
                      <Autocomplete
                        data-testid="stockStatus-erpMapping-dropdown"
                        freeSolo={false}
                        multiple={false}
                        autoHighlight={true}
                        disableClearable={true}
                        options={sapStockStatusTypes.map((status) => status.id)}
                        getOptionLabel={(option) =>
                          sapStockStatusTypes.find((status) => status.id === option)?.label || ''
                        }
                        defaultValue={field?.value || null}
                        onChange={(event, item, reason) => {
                          if (reason === 'selectOption') {
                            field.onChange(item);
                          }
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              dataTestId="stockStatus-erpMapping-input"
                              label={tC('common.erpMapping')}
                            />
                          );
                        }}
                        sx={{
                          width: '250px',
                        }}
                      />
                    </div>
                  )}
                </Tooltip>
                {fieldState.error && <FormHelperText>{fieldState?.error?.message}</FormHelperText>}
              </FormControl>
            )}
          />
          <Controller
            name="stockStatusTypeStatus"
            control={control}
            rules={{
              required: tC('form.validation.requiredField', { field: tC('common.status') }),
              validate: {
                notWhiteSpaceOnly,
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                required
                id="stock-status-status"
                label={tC('common.status')}
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                select
                SelectProps={{ native: true }}
                dataTestId="add-status-status"
              >
                {Object.values(StockStatusTypeStatus).map((status) => (
                  <option key={`stock-status-status-${status}`} value={status}>
                    {tC(`common.${status}`)}
                  </option>
                ))}
              </TextField>
            )}
          />
        </Box>
        {modal.type === ModalTypes.stockStatusEdit && (
          <Box>
            <Controller
              name="defaultStockStatusType"
              control={control}
              render={({ field }) => (
                <Tooltip
                  title={
                    modal.type === ModalTypes.stockStatusEdit && modal.stockStatusType.default
                      ? t('defaultDisableMessage')
                      : ''
                  }
                  arrow
                  followCursor
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={!!field.value}
                        disabled={
                          modal.type === ModalTypes.stockStatusEdit && modal.stockStatusType.default
                        }
                      />
                    }
                    label={tC('common.default')}
                  />
                </Tooltip>
              )}
            />
          </Box>
        )}
      </ModalContent>
      <ModalActions>
        {modal.type === ModalTypes.stockStatusEdit &&
          applicationFeatureFlags.stockStatuses.allowDelete && (
            <ModalButton
              onClick={() => {
                if (modal.type === ModalTypes.stockStatusEdit)
                  void deleteStockStatusType({ variables: { id: modal.stockStatusType.id } });
              }}
              variant="outlined"
              color="error"
              sx={{ marginRight: 'auto' }}
              data-testid="status-delete-button"
              actionType="delete"
            >
              {tC('common.delete')}
            </ModalButton>
          )}
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {tC('common.cancel')}
        </ModalButton>
        <ModalButton
          data-testid="stockStatusCrud_submit"
          variant="contained"
          color="primary"
          actionType="submit"
        >
          {tC('common.save')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default StockStatusCrudModal;
