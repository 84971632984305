import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SlottingAbcAnalysisSortFields } from '@/graphql/types.generated';
import {
  FilterOperators,
  ColumnFilter,
} from '@components/data-table/controls/filter/filter-definitions';
import useCreateSlottingAbcAnalysisColumns, {
  ABC_ANALYSIS_HOOK,
  TABCAnalysisDataType,
  TABCAnalysisFieldNames,
  TABCAnalysisFilterType,
} from '@components/data-table/hooks/shared-columns/useCreateSlottingAbcAnalysisColumns';
import useCreateDataTableColumns from '@components/data-table/hooks/useCreateDataTableColumns';
import useDataTableSelection, {
  SelectionType,
} from '@components/data-table/hooks/useDataTableSelection';
import { validateDataTableProps } from '@components/data-table/lib/validateDataTableProps';
import { IDataTableSort } from '@components/data-table/model/data-table';
import TableIds from '@components/data-table/model/TableIds';

interface IUseRunDeployMovementsAbcAnalysisDataTableProps {
  runId: string;
}

const useRunDeployMovementsAbcAnalysisDataTable = ({
  runId,
}: IUseRunDeployMovementsAbcAnalysisDataTableProps) => {
  const { t } = useTranslation('components');

  const abcAnalysisColumns = useCreateSlottingAbcAnalysisColumns();
  const columns = useCreateDataTableColumns<TABCAnalysisDataType>(abcAnalysisColumns);

  const { selection, rowSelection } = useDataTableSelection<TABCAnalysisDataType>(
    SelectionType.multi,
    'id',
  );

  const [selectionOverride, setSelectionOverride] = useState<TABCAnalysisDataType[]>([]);
  const handleRemoveApproved = (id: string) => {
    const updatedApprovedAbcAnalysis = selection.filter((abcAnalysis) => abcAnalysis.id !== id);
    setSelectionOverride(updatedApprovedAbcAnalysis);
  };

  const baseFilter = useMemo<ColumnFilter<TABCAnalysisFieldNames>[]>(
    () => [
      {
        columnId: SlottingAbcAnalysisSortFields.RunId,
        operator: FilterOperators.eq,
        value: runId,
      },
    ],
    [runId],
  );

  const defaultSorting = useMemo<IDataTableSort<TABCAnalysisFieldNames>[]>(
    () => [
      {
        id: SlottingAbcAnalysisSortFields.CurrentAbcIndicator,
        desc: false,
      },
      {
        id: SlottingAbcAnalysisSortFields.Material,
        desc: false,
      },
    ],
    [],
  );

  return {
    approvedAbcAnalysis: selection,
    setCurrentlyApprovedAbcAnalysis: setSelectionOverride,
    handleRemoveApproved,
    dataTableProps: validateDataTableProps<
      TABCAnalysisDataType,
      TABCAnalysisFilterType,
      TABCAnalysisFieldNames
    >({
      type: 'query',
      tableId: TableIds.RunDeployMovementsProposedAbcAnalysis,
      tableHeader: t('slotting.abcDataRecommendations'),
      columns,
      queryHook: ABC_ANALYSIS_HOOK,
      baseFilter,
      defaultSorting,
      rowSelection: {
        ...rowSelection,
        selectionOverride,
      },
    }),
  };
};

export default useRunDeployMovementsAbcAnalysisDataTable;
