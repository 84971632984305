import AuthBackground from '@components/auth-background';
import { ElevatedLoadingIndicator } from '@components/loading-indicator';

const LoadingPage = () => {
  return (
    <AuthBackground page="loading">
      <ElevatedLoadingIndicator />
    </AuthBackground>
  );
};

export default LoadingPage;
