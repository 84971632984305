import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InboundDeliveryDetailsFragment } from '@/graphql/defs/pages/__generated__/inbound-deliveries.generated';
import { DeliveryCompletionStatus } from '@/graphql/types.generated';
import DataTable from '@components/data-table';
import useInboundDeliveryInventoryDataTable from '@components/data-table/hooks/table-props/useInboundDeliveryInventoryDataTable';
import useInboundDeliveryItemsDataTable from '@components/data-table/hooks/table-props/useInboundDeliveryItemsDataTable';
import { DataTableVariants } from '@components/data-table/model/data-table';
import DeliveryItemsActionMenu, {
  IInboundLooseProduct,
} from '@components/deliveries/delivery-items-action-menu';
import { IDropdownMenuItem } from '@components/dropdown-menu';
import LicensePlateActions from '@components/inventory/LicensePlateActions';
import CollapseLoading from '@components/loading-indicator/CollapseLoading';
import { useWarehouseFeatureFlags } from '@context/warehouse-feature-flags';
import useMounted from '@hooks/useMounted';
import { TRefetchDataTable } from '@models/modal';

export enum InboundDeliveryItemsView {
  DeliveryItem = 'deliveryItemView',
  Inventory = 'inventoryView',
}

interface IInboundDeliveryItemsInvDataTables {
  deliveryId: string;
  deliveryDetails: InboundDeliveryDetailsFragment;
  looseProduct: IInboundLooseProduct[];
  refetchDataTrigger: boolean;
  triggerRefetchAll: TRefetchDataTable;
  hasLoadedInitialDetails: boolean;
}
const InboundDeliveryItemsInvDataTables = ({
  deliveryId,
  deliveryDetails,
  looseProduct,
  refetchDataTrigger,
  triggerRefetchAll,
  hasLoadedInitialDetails,
}: IInboundDeliveryItemsInvDataTables) => {
  const { t } = useTranslation('pages');
  const mounted = useMounted();
  const { warehouseFeatureFlags: featureFlags } = useWarehouseFeatureFlags();

  const [selectedView, setSelectedView] = useState<InboundDeliveryItemsView>(
    InboundDeliveryItemsView.DeliveryItem,
  );
  const deliveryItemViews: IDropdownMenuItem<InboundDeliveryItemsView>[] = useMemo(
    () => [
      {
        value: InboundDeliveryItemsView.DeliveryItem,
        component: t(`deliveries.deliveryItems.${InboundDeliveryItemsView.DeliveryItem}`),
        onSelect: () => setSelectedView(InboundDeliveryItemsView.DeliveryItem),
        selected: false,
      },
      {
        value: InboundDeliveryItemsView.Inventory,
        component: t(`deliveries.deliveryItems.${InboundDeliveryItemsView.Inventory}`),
        onSelect: () => setSelectedView(InboundDeliveryItemsView.Inventory),
        selected: false,
      },
    ],
    [],
  );

  const {
    selectedDeliveryItems,
    triggerIbdDeliveryItemDataTableRefetch,
    ibdDeliveryItemDataTableProps,
  } = useInboundDeliveryItemsDataTable(deliveryId, deliveryItemViews);
  const {
    selectedDeliveryInventory,
    triggerIbdDeliveryInventoryDataTableRefetch,
    ibdDeliveryInventoryDataTableProps,
  } = useInboundDeliveryInventoryDataTable(
    deliveryId,
    deliveryDetails?.pickOrPutawayStatus,
    deliveryItemViews,
  );

  useEffect(() => {
    if (mounted) {
      triggerIbdDeliveryItemDataTableRefetch();
      triggerIbdDeliveryInventoryDataTableRefetch();
    }
  }, [refetchDataTrigger]);

  return !hasLoadedInitialDetails ? (
    <CollapseLoading />
  ) : (
    <>
      {/* Delivery Items - Delivery Item View */}
      {selectedView === InboundDeliveryItemsView.DeliveryItem && (
        <DataTable
          {...ibdDeliveryItemDataTableProps}
          variant={DataTableVariants.Collapsible}
          tableActions={
            deliveryDetails.pickOrPutawayStatus !== DeliveryCompletionStatus.Complete && (
              <DeliveryItemsActionMenu
                deliveryDetails={deliveryDetails}
                looseProduct={looseProduct}
                selectedDeliveryItems={selectedDeliveryItems}
                refetchData={triggerRefetchAll}
              />
            )
          }
        />
      )}

      {/* Delivery Items - Inventory View */}
      {selectedView === InboundDeliveryItemsView.Inventory && (
        <DataTable
          {...ibdDeliveryInventoryDataTableProps}
          variant={DataTableVariants.Collapsible}
          tableActions={
            deliveryDetails.pickOrPutawayStatus !== DeliveryCompletionStatus.Complete && (
              <Box display="inline-flex" alignItems="center" gap={2}>
                {featureFlags.inboundDeliveries.manageInboundLPs &&
                  selectedView === InboundDeliveryItemsView.Inventory && (
                    <LicensePlateActions
                      sourceType="delivery"
                      selectedInventory={selectedDeliveryInventory}
                      handleActionComplete={triggerIbdDeliveryInventoryDataTableRefetch}
                      removeMove
                      forceAutocomplete
                    />
                  )}
                <DeliveryItemsActionMenu
                  deliveryDetails={deliveryDetails}
                  looseProduct={looseProduct}
                  selectedDeliveryItems={selectedDeliveryItems}
                  refetchData={triggerRefetchAll}
                />
              </Box>
            )
          }
        />
      )}
    </>
  );
};

export default InboundDeliveryItemsInvDataTables;
