import { Button as MUIButton } from '@mui/material';
import { styled, Theme } from '@mui/material/styles';

type TVariants = 'actionMenu' | 'pageInfoCta' | 'tableControl' | 'tableAction';

interface IStyledButton {
  styledVariant?: TVariants;
}

const styledVariants = (theme: Theme): Record<TVariants, Record<string, any>> => ({
  actionMenu: {
    '&': {
      textTransform: 'none',
      fontWeight: 500,
      borderWidth: '3px',
      padding: '5px 25px',
      '&:hover': {
        borderWidth: '3px',
        padding: '5px 25px',
      },
    },
  },
  pageInfoCta: {
    '&': {
      textTransform: 'none',
      fontWeight: 500,
      borderWidth: '3px',
      padding: '5px 25px',
      '&:hover': {
        borderWidth: '3px',
        padding: '5px 25px',
      },
    },
  },
  tableControl: {
    ...theme.typography.body1,
    display: 'flex',
    width: 'max-content',
    color: theme.palette.text.primary,
    fontWeight: 600,
    '& svg': {
      color: theme.palette.secondary.main,
      fontSize: '32px',
    },
  },
  tableAction: {
    '&': {
      textTransform: 'none',
      fontWeight: 500,
      borderWidth: '3px',
      padding: '5px 25px',
      '&:hover': {
        borderWidth: '3px',
        padding: '5px 25px',
      },
    },
  },
});

const Button = styled(MUIButton, {
  shouldForwardProp: (prop) => prop !== 'styledVariant',
})<IStyledButton>(({ styledVariant, theme }) => styledVariants(theme)[styledVariant] || {});

export default Button;
