import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetSalesOrderDeliveriesQuery } from '@/graphql/defs/components/modals/__generated__/sales-order-list-modal.generated';
import { GetSalesOrderDeliveries_defaultData } from '@/graphql/defs/components/modals/sales-order-list-modal';
import { DeliveryType } from '@/graphql/types.generated';
import DeliveryTypeCell from '@components/data-table/table/cells/DeliveryTypeCell';
import LinkCell from '@components/data-table/table/cells/LinkCell';
import EditableTable, {
  EditableTableCell,
  EditableTableColumn,
  EditableTableRow,
} from '@components/editable-table/EditableTable';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import StatusTypography from '@components/status-typography';
import { INBOUND_DELIVERY, OUTBOUND_DELIVERY } from '@constants/routes';
import { useDateTime } from '@context/date-time';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { ISalesOrderListModal } from '@models/modal';

const SalesOrderListModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal, isPreparing, setPreparing } = useModalContent<ISalesOrderListModal>();
  const { displayDate } = useDateTime();

  const { data: salesOrderListData } = useGetSalesOrderDeliveriesQuery({
    variables: {
      salesOrder: modal.salesOrder,
    },
    onCompleted: () => {
      setPreparing(false);
    },
    onError: (error) => {
      setPreparing(false);
      showMessage({ type: 'error', message: error.message });
    },
  });
  const {
    getDeliveries: { deliveries },
  } = useMemo(
    () => salesOrderListData || GetSalesOrderDeliveries_defaultData,
    [salesOrderListData],
  );

  const deliveryColumns: EditableTableColumn[] = [
    {
      label: t('common.type'),
      width: '20%',
    },
    {
      label: t('common.delivery', { count: 1 }),
      width: '20%',
    },
    {
      label: t('common.salesOrder'),
      width: '20%',
    },
    {
      label: t('common.dueDate'),
      width: '20%',
    },
    {
      label: t('common.deliveryStatus'),
      width: '20%',
    },
  ];

  const salesOrderDeliveries = useMemo<EditableTableRow[]>(() => {
    return deliveries.map((delivery, index) => {
      const cells: EditableTableCell[] = [
        {
          dataTestId: `delivery-${index}-type`,
          value: <DeliveryTypeCell deliveryType={delivery.type} />,
        },
        {
          dataTestId: `delivery-${index}-code`,
          value: (
            <LinkCell
              href={`${
                delivery.type === DeliveryType.Delivery ? INBOUND_DELIVERY : OUTBOUND_DELIVERY
              }/${delivery.id}`}
              text={delivery.erpCode}
              dataTestId="sales-order-list-delivery-code"
            />
          ),
        },
        {
          dataTestId: `delivery-${index}-salesOrder`,
          value: delivery.erpSalesOrder,
        },
        {
          dataTestId: `delivery-${index}-dueDate`,
          value: displayDate({ date: delivery.dueDate }),
        },
        {
          dataTestId: `delivery-${index}-deliveryStatus`,
          value: (
            <StatusTypography
              status={delivery?.deliveryStatus}
              display={t(`common.${delivery?.deliveryStatus}`)}
            />
          ),
        },
      ];
      return {
        dataTestId: `delivery-${index}`,
        item: delivery,
        cells: cells,
      };
    });
  }, [deliveries]);

  return (
    !isPreparing && (
      <>
        <ModalContent>
          <EditableTable columns={deliveryColumns} data={salesOrderDeliveries} />
        </ModalContent>
        <ModalActions>
          <ModalButton
            onClick={() => closeModal()}
            variant="outlined"
            color="primary"
            actionType="cancel"
          >
            {t('common.close')}
          </ModalButton>
        </ModalActions>
      </>
    )
  );
};

export default SalesOrderListModal;
