import { Grid } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useCreateCountBinTaskMutation,
  useRetrieveBinDetailsForCountBinModalQuery,
} from '@/graphql/defs/components/modals/__generated__/create-count-bin-modal.generated';
import { RetrieveBinDetailsForCountBinModal_defaultData } from '@/graphql/defs/components/modals/create-count-bin-modal';
import { DatePicker } from '@components/date-picker';
import EditableTable, {
  EditableTableCell,
  EditableTableColumn,
  EditableTableRow,
} from '@components/editable-table/EditableTable';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import { useDateTime } from '@context/date-time';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import FormValues from '@models/FormValues';
import { ICreateCountBinModal } from '@models/modal';
import Typography from '@styled/Typography';

export interface ICreateCountBinDetails {
  binId: string;
}

const CreateCountBinModal = () => {
  const { t } = useTranslation('components');
  const { displayDateTime } = useDateTime();
  const { showMessage } = useSnackbar();
  const { modal, closeModal, isPreparing, setPreparing } = useModalContent<ICreateCountBinModal>();

  const { validDate } = useFormValidation();

  const { data: binData } = useRetrieveBinDetailsForCountBinModalQuery({
    variables: {
      binIds: modal.bins.map((bin) => bin.binId),
    },
    onCompleted: () => {
      setPreparing(false);
    },
    onError: (error) => {
      setPreparing(false);
      showMessage({ type: 'error', message: error.message });
    },
  });
  const {
    viewBins: { bins },
  } = useMemo(() => binData || RetrieveBinDetailsForCountBinModal_defaultData, [binData]);

  const [binIndex, setBinIndex] = useState(0);
  const [dueDate, setDueDate] = useState<DateTime | null>(null);

  const runNextOrExit = () => {
    if (binIndex < modal.bins.length - 1) {
      const newIndex = binIndex + 1;
      setBinIndex(newIndex);
      void createCountTask({ variables: { binId: modal.bins[newIndex]?.binId, dueDate } });
    } else {
      closeModal({ bypassLoading: true, success: true });
    }
  };

  const [createCountTask] = useCreateCountBinTaskMutation({
    onCompleted: ({ createCountBinTask: { code } }) => {
      showMessage({
        type: 'success',
        message: t('modal.countBin.create.success', { taskCode: code }),
      });
      runNextOrExit();
    },
    onError: (error) => {
      showMessage({ type: 'error', message: error.message });
      runNextOrExit();
    },
  });

  interface ICreateCountBinFields {
    dueDate: DateTime;
  }
  const formMethods = useForm<FormValues<ICreateCountBinFields>>({
    defaultValues: { dueDate },
  });

  const binColumns: EditableTableColumn[] = [
    {
      label: t('common.codeSuffix', { prefix: t('common.bin') }),
      width: '20%',
    },
    {
      label: t('common.lastCounted'),
      width: '20%',
    },
    {
      label: t('common.lastMovement'),
      width: '20%',
    },
    {
      label: t('common.hasOpenTasks'),
      width: '20%',
    },
    {
      label: t('common.empty'),
      width: '20%',
    },
  ];

  const binRows = useMemo<EditableTableRow[]>(
    () =>
      bins.map((bin, index) => {
        const cells: EditableTableCell[] = [
          {
            dataTestId: `bin-${index}-code`,
            value: bin.code,
          },
          {
            dataTestId: `bin-${index}-lastCounted`,
            value: displayDateTime({ date: bin.lastCount }),
          },
          {
            dataTestId: `bin-${index}-lastMovement`,
            value: displayDateTime({ date: bin.lastMovement }),
          },
          {
            dataTestId: `bin-${index}-hasOpenTasks`,
            value: bin.hasOpenTasks ? t('common.yes') : t('common.no'),
          },
          {
            dataTestId: `bin-${index}-empty`,
            value: !bin.containsProducts ? t('common.yes') : t('common.no'),
          },
        ];
        return {
          dataTestId: `bin-${index}`,
          item: bin,
          cells: cells,
        };
      }),
    [bins],
  );

  const onSubmit = (fields: ICreateCountBinFields) => {
    setDueDate(fields.dueDate);
    void createCountTask({ variables: { binId: modal.bins[0]?.binId, dueDate: fields.dueDate } });
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={formMethods.handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Typography>
                {t('modal.countBin.create.text', { count: modal.bins.length })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <EditableTable columns={binColumns} data={binRows} />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="dueDate"
                control={formMethods.control}
                rules={{
                  validate: {
                    validDate,
                  },
                }}
                render={({ field, fieldState }) => (
                  <DatePicker
                    autoFocus={true}
                    label={t('common.dueDate')}
                    field={field}
                    fieldState={fieldState}
                    setDate={field.onChange}
                  />
                )}
              />
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions>
          <ModalButton
            onClick={() => closeModal()}
            variant="outlined"
            color="primary"
            actionType="cancel"
          >
            {t('common.cancel')}
          </ModalButton>
          <ModalButton variant="contained" color="primary" actionType="submit">
            {t('common.submit')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default CreateCountBinModal;
