/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { FullDisplayPrefFragmentDoc } from '../../components/modals/__generated__/display-preferences-modal.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetDateTimeDisplayPrefQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type GetDateTimeDisplayPrefQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, displayPreference?: { __typename?: 'DisplayPreference', id: string, currency: string, dateTimeFormat: string, language: string, numberFormat?: Types.NumberFormat | null, measurementSystem: Types.StandardUomSystem, timezone: string } | null } };


export const GetDateTimeDisplayPrefDocument = gql`
    query GetDateTimeDisplayPref($userId: ID!) {
  user(id: $userId) {
    id
    displayPreference {
      ...FullDisplayPref
    }
  }
}
    ${FullDisplayPrefFragmentDoc}`;

/**
 * __useGetDateTimeDisplayPrefQuery__
 *
 * To run a query within a React component, call `useGetDateTimeDisplayPrefQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDateTimeDisplayPrefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDateTimeDisplayPrefQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetDateTimeDisplayPrefQuery(baseOptions: Apollo.QueryHookOptions<GetDateTimeDisplayPrefQuery, GetDateTimeDisplayPrefQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDateTimeDisplayPrefQuery, GetDateTimeDisplayPrefQueryVariables>(GetDateTimeDisplayPrefDocument, options);
      }
export function useGetDateTimeDisplayPrefLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDateTimeDisplayPrefQuery, GetDateTimeDisplayPrefQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDateTimeDisplayPrefQuery, GetDateTimeDisplayPrefQueryVariables>(GetDateTimeDisplayPrefDocument, options);
        }
export type GetDateTimeDisplayPrefQueryHookResult = ReturnType<typeof useGetDateTimeDisplayPrefQuery>;
export type GetDateTimeDisplayPrefLazyQueryHookResult = ReturnType<typeof useGetDateTimeDisplayPrefLazyQuery>;
export type GetDateTimeDisplayPrefQueryResult = Apollo.QueryResult<GetDateTimeDisplayPrefQuery, GetDateTimeDisplayPrefQueryVariables>;