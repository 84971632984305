/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBinMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  updatedBin: Types.BinUpdateInput;
}>;


export type UpdateBinMutation = { __typename?: 'Mutation', updateOneBin: { __typename?: 'Bin', id: string, code: string } };

export type DeleteBinMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteBinMutation = { __typename?: 'Mutation', deleteOneBin: { __typename?: 'Bin', id: string, code: string, deletedAt?: string | null } };


export const UpdateBinDocument = gql`
    mutation UpdateBin($id: ID!, $updatedBin: BinUpdateInput!) {
  updateOneBin(input: {id: $id, update: $updatedBin}) {
    id
    code
  }
}
    `;
export type UpdateBinMutationFn = Apollo.MutationFunction<UpdateBinMutation, UpdateBinMutationVariables>;

/**
 * __useUpdateBinMutation__
 *
 * To run a mutation, you first call `useUpdateBinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBinMutation, { data, loading, error }] = useUpdateBinMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updatedBin: // value for 'updatedBin'
 *   },
 * });
 */
export function useUpdateBinMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBinMutation, UpdateBinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBinMutation, UpdateBinMutationVariables>(UpdateBinDocument, options);
      }
export type UpdateBinMutationHookResult = ReturnType<typeof useUpdateBinMutation>;
export type UpdateBinMutationResult = Apollo.MutationResult<UpdateBinMutation>;
export type UpdateBinMutationOptions = Apollo.BaseMutationOptions<UpdateBinMutation, UpdateBinMutationVariables>;
export const DeleteBinDocument = gql`
    mutation DeleteBin($id: ID!) {
  deleteOneBin(input: {id: $id}) {
    id
    code
    deletedAt
  }
}
    `;
export type DeleteBinMutationFn = Apollo.MutationFunction<DeleteBinMutation, DeleteBinMutationVariables>;

/**
 * __useDeleteBinMutation__
 *
 * To run a mutation, you first call `useDeleteBinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBinMutation, { data, loading, error }] = useDeleteBinMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBinMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBinMutation, DeleteBinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBinMutation, DeleteBinMutationVariables>(DeleteBinDocument, options);
      }
export type DeleteBinMutationHookResult = ReturnType<typeof useDeleteBinMutation>;
export type DeleteBinMutationResult = Apollo.MutationResult<DeleteBinMutation>;
export type DeleteBinMutationOptions = Apollo.BaseMutationOptions<DeleteBinMutation, DeleteBinMutationVariables>;