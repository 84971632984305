import { gql } from '@apollo/client';

import { GetSalesOrderDeliveriesQuery } from '@/graphql/defs/components/modals/__generated__/sales-order-list-modal.generated';

gql`
  query GetSalesOrderDeliveries($salesOrder: String!) {
    getDeliveries: deliveries(
      filter: { erpSalesOrder: { eq: $salesOrder } }
      sorting: [{ field: erpCode, direction: ASC }]
    ) {
      deliveries: nodes {
        id
        erpCode
        erpSalesOrder
        type
        dueDate
        deliveryStatus
      }
    }
  }
`;

export const GetSalesOrderDeliveries_defaultData: GetSalesOrderDeliveriesQuery = {
  __typename: 'Query',
  getDeliveries: {
    __typename: 'DeliveryOffsetConnection',
    deliveries: [],
  },
};
