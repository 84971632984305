import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  useCreateLoadTasksMutation,
  useGetFulfillmentItemsForLoadQuery,
} from '@/graphql/defs/components/modals/__generated__/load-task-create-modal.generated';
import { GetFulfillmentItemsForLoad_defaultData } from '@/graphql/defs/components/modals/load-task-create-modal';
import EditableTable, {
  EditableTableCell,
  EditableTableColumn,
  EditableTableRow,
} from '@components/editable-table/EditableTable';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useWarehouseFeatureFlags } from '@context/warehouse-feature-flags';
import { getTransComponents } from '@lib/translation';
import { ILoadTaskCreateModal } from '@models/modal';

const LoadTaskCreateModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const {
    warehouseFeatureFlags: {
      outboundDeliveries: { loadCreate: loadCreateFlags },
    },
  } = useWarehouseFeatureFlags();
  const { modal, closeModal, setLoading, isPreparing, setPreparing } =
    useModalContent<ILoadTaskCreateModal>();

  const [createTasks] = useCreateLoadTasksMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t('modal.fulfillment.loadTaskCreate.success', {
          fulfillmentCode: modal.fulfillmentCode,
        }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({ type: 'error', message: error.message });
    },
  });

  const { data: fulfillmentItemsData } = useGetFulfillmentItemsForLoadQuery({
    variables: { fulfillmentId: modal.fulfillmentId },
    onCompleted: () => {
      setPreparing(false);
    },
    onError: (error) => {
      setPreparing(false);
      showMessage({ type: 'error', message: error.message });
    },
  });
  const { fulfillmentItemsToLoad: fulfillmentItems } = useMemo(
    () => fulfillmentItemsData || GetFulfillmentItemsForLoad_defaultData,
    [fulfillmentItemsData],
  );

  const lpFulfillmentItemColumns: EditableTableColumn[] = [
    {
      label: t('common.item'),
      width: '15%',
    },
    {
      label: t('common.licensePlate'),
      width: '25%',
    },
    {
      label: t('common.licensePlateDescription'),
      width: '60%',
    },
  ];

  const productFulfillmentItemColumns: EditableTableColumn[] = [
    {
      label: t('common.item'),
      width: '15%',
    },
    {
      label: t('common.product'),
      width: '25%',
    },
    {
      label: t('common.productDescription'),
      width: '60%',
    },
  ];

  const fulfillmentItemRows: EditableTableRow[] = useMemo(() => {
    const rows = [...fulfillmentItems];
    return loadCreateFlags.columnType === 'lp'
      ? rows.map(createLpFulfillmentItemRow)
      : rows.map(createProductFulfillmentItemRow);
  }, [fulfillmentItems, loadCreateFlags]);

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = () => {
    void createTasks({ variables: { fulfillmentId: modal.fulfillmentId } });
  };

  return (
    !isPreparing && (
      <>
        <ModalContent>
          <Box marginBottom="34px">
            <Trans
              ns="components"
              i18nKey="modal.fulfillment.loadTaskCreate.text"
              components={getTransComponents(['bold'])}
            />
          </Box>
          <EditableTable
            columns={
              loadCreateFlags.columnType === 'lp'
                ? lpFulfillmentItemColumns
                : productFulfillmentItemColumns
            }
            data={fulfillmentItemRows}
          />
        </ModalContent>
        <ModalActions>
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton onClick={onSubmit} variant="contained" color="primary" actionType="submit">
            {t('common.create')}
          </ModalButton>
        </ModalActions>
      </>
    )
  );
};

export default LoadTaskCreateModal;

const createLpFulfillmentItemRow = (fulfillmentItem, index): EditableTableRow => {
  const cells: EditableTableCell[] = [
    {
      dataTestId: `fulfillmentItem-${index}-deliveryItem`,
      value: fulfillmentItem.fulfillmentItem,
    },
    {
      dataTestId: `fulfillmentItem-${index}-lpCode`,
      value: fulfillmentItem.licensePlateCode,
    },
    {
      dataTestId: `fulfillmentItem-${index}-lpDesc`,
      value: fulfillmentItem.licensePlateDescription,
    },
  ];
  return {
    dataTestId: `fulfillmentItem-${index}`,
    item: fulfillmentItem,
    cells: cells,
  };
};

const createProductFulfillmentItemRow = (fulfillmentItem, index): EditableTableRow => {
  const cells: EditableTableCell[] = [
    {
      dataTestId: `fulfillmentItem-${index}-deliveryItem`,
      value: fulfillmentItem.item,
    },
    {
      dataTestId: `fulfillmentItem-${index}-productCode`,
      value: fulfillmentItem.productCode,
    },
    {
      dataTestId: `fulfillmentItem-${index}-productDesc`,
      value: fulfillmentItem.productDescription,
    },
  ];
  return {
    dataTestId: `fulfillmentItem-${index}`,
    item: fulfillmentItem,
    cells: cells,
  };
};
