/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateProductMutationVariables = Types.Exact<{
  newProduct: Types.ProductCreateInput;
}>;


export type CreateProductMutation = { __typename?: 'Mutation', createOneProduct: { __typename?: 'Product', id: string, code: string, description?: string | null, unitOfMeasures?: { __typename?: 'UnitOfMeasureProductConversionNodes', nodes: Array<{ __typename?: 'UnitOfMeasureProductConversion', id: string, code: string, isBaseUom: boolean }> } | null } };

export type AddUoMToProductMutationVariables = Types.Exact<{
  productId: Types.Scalars['ID']['input'];
  unitOfMeasureId: Types.Scalars['ID']['input'];
  warehouseId: Types.Scalars['ID']['input'];
}>;


export type AddUoMToProductMutation = { __typename?: 'Mutation', createOneWarehousePreferredUnitOfMeasure: { __typename?: 'WarehousePreferredUnitOfMeasure', id: string, product?: { __typename?: 'Product', id: string, code: string } | null } };


export const CreateProductDocument = gql`
    mutation CreateProduct($newProduct: ProductCreateInput!) {
  createOneProduct(input: {product: $newProduct}) {
    id
    code
    description
    unitOfMeasures {
      nodes {
        id
        code
        isBaseUom
      }
    }
  }
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      newProduct: // value for 'newProduct'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const AddUoMToProductDocument = gql`
    mutation AddUoMToProduct($productId: ID!, $unitOfMeasureId: ID!, $warehouseId: ID!) {
  createOneWarehousePreferredUnitOfMeasure(
    input: {warehousePreferredUnitOfMeasure: {productId: $productId, unitOfMeasureId: $unitOfMeasureId, warehouseId: $warehouseId}}
  ) {
    id
    product {
      id
      code
    }
  }
}
    `;
export type AddUoMToProductMutationFn = Apollo.MutationFunction<AddUoMToProductMutation, AddUoMToProductMutationVariables>;

/**
 * __useAddUoMToProductMutation__
 *
 * To run a mutation, you first call `useAddUoMToProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUoMToProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUoMToProductMutation, { data, loading, error }] = useAddUoMToProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      unitOfMeasureId: // value for 'unitOfMeasureId'
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useAddUoMToProductMutation(baseOptions?: Apollo.MutationHookOptions<AddUoMToProductMutation, AddUoMToProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUoMToProductMutation, AddUoMToProductMutationVariables>(AddUoMToProductDocument, options);
      }
export type AddUoMToProductMutationHookResult = ReturnType<typeof useAddUoMToProductMutation>;
export type AddUoMToProductMutationResult = Apollo.MutationResult<AddUoMToProductMutation>;
export type AddUoMToProductMutationOptions = Apollo.BaseMutationOptions<AddUoMToProductMutation, AddUoMToProductMutationVariables>;