import DataTable from '@components/data-table';
import useOutboundFulfillmentItemsDataTable from '@components/data-table/hooks/table-props/useOutboundFulfillmentItemsDataTable';
import { ModalContent } from '@components/modal/modal-content';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { IFulfillmentItemsModal } from '@models/modal';

const FulfillmentItemsModal = () => {
  const { modal } = useModalContent<IFulfillmentItemsModal>();

  const { fulfillmentItemsDataTableProps } = useOutboundFulfillmentItemsDataTable(
    modal.fulfillment.id,
    undefined,
  );

  return (
    <>
      <ModalContent>
        <DataTable {...fulfillmentItemsDataTableProps} />
      </ModalContent>
    </>
  );
};

export default FulfillmentItemsModal;
