/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PutawayBinFragment = { __typename?: 'ViewBin', areaCode?: string | null, areaId?: string | null, code?: string | null, id?: string | null };

export type DestinationBinsForPutawayQueryVariables = Types.Exact<{
  taskTypeId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  taskTypeCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sorting?: Types.InputMaybe<Array<Types.TaskTypeBinStatusBinsQueryDtoSort> | Types.TaskTypeBinStatusBinsQueryDtoSort>;
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.TaskTypeBinStatusBinsQueryDtoFilter>;
}>;


export type DestinationBinsForPutawayQuery = { __typename?: 'Query', destinationBinsForTaskType: { __typename?: 'TaskTypeBinStatusDestinationBins', destinationBins: Array<{ __typename?: 'ViewBin', areaCode?: string | null, areaId?: string | null, code?: string | null, id?: string | null }> } };

export type CompletePutawayTaskMutationVariables = Types.Exact<{
  input: Types.CompletePutawayTaskInputDto;
}>;


export type CompletePutawayTaskMutation = { __typename?: 'Mutation', completePutawayTask: { __typename?: 'Task', id: string, code: string } };

export const PutawayBinFragmentDoc = gql`
    fragment PutawayBin on ViewBin {
  areaCode
  areaId
  code
  id
}
    `;
export const DestinationBinsForPutawayDocument = gql`
    query DestinationBinsForPutaway($taskTypeId: ID, $taskTypeCode: String, $sorting: [TaskTypeBinStatusBinsQueryDtoSort!], $paging: OffsetPaging, $filter: TaskTypeBinStatusBinsQueryDtoFilter) {
  destinationBinsForTaskType(
    taskTypeId: $taskTypeId
    taskTypeCode: $taskTypeCode
    sorting: $sorting
    paging: $paging
    filter: $filter
  ) {
    destinationBins {
      ...PutawayBin
    }
  }
}
    ${PutawayBinFragmentDoc}`;

/**
 * __useDestinationBinsForPutawayQuery__
 *
 * To run a query within a React component, call `useDestinationBinsForPutawayQuery` and pass it any options that fit your needs.
 * When your component renders, `useDestinationBinsForPutawayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDestinationBinsForPutawayQuery({
 *   variables: {
 *      taskTypeId: // value for 'taskTypeId'
 *      taskTypeCode: // value for 'taskTypeCode'
 *      sorting: // value for 'sorting'
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDestinationBinsForPutawayQuery(baseOptions?: Apollo.QueryHookOptions<DestinationBinsForPutawayQuery, DestinationBinsForPutawayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DestinationBinsForPutawayQuery, DestinationBinsForPutawayQueryVariables>(DestinationBinsForPutawayDocument, options);
      }
export function useDestinationBinsForPutawayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DestinationBinsForPutawayQuery, DestinationBinsForPutawayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DestinationBinsForPutawayQuery, DestinationBinsForPutawayQueryVariables>(DestinationBinsForPutawayDocument, options);
        }
export type DestinationBinsForPutawayQueryHookResult = ReturnType<typeof useDestinationBinsForPutawayQuery>;
export type DestinationBinsForPutawayLazyQueryHookResult = ReturnType<typeof useDestinationBinsForPutawayLazyQuery>;
export type DestinationBinsForPutawayQueryResult = Apollo.QueryResult<DestinationBinsForPutawayQuery, DestinationBinsForPutawayQueryVariables>;
export const CompletePutawayTaskDocument = gql`
    mutation CompletePutawayTask($input: CompletePutawayTaskInputDto!) {
  completePutawayTask: completeOneFFPutawayTask(input: $input) {
    id
    code
  }
}
    `;
export type CompletePutawayTaskMutationFn = Apollo.MutationFunction<CompletePutawayTaskMutation, CompletePutawayTaskMutationVariables>;

/**
 * __useCompletePutawayTaskMutation__
 *
 * To run a mutation, you first call `useCompletePutawayTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePutawayTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePutawayTaskMutation, { data, loading, error }] = useCompletePutawayTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompletePutawayTaskMutation(baseOptions?: Apollo.MutationHookOptions<CompletePutawayTaskMutation, CompletePutawayTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompletePutawayTaskMutation, CompletePutawayTaskMutationVariables>(CompletePutawayTaskDocument, options);
      }
export type CompletePutawayTaskMutationHookResult = ReturnType<typeof useCompletePutawayTaskMutation>;
export type CompletePutawayTaskMutationResult = Apollo.MutationResult<CompletePutawayTaskMutation>;
export type CompletePutawayTaskMutationOptions = Apollo.BaseMutationOptions<CompletePutawayTaskMutation, CompletePutawayTaskMutationVariables>;