import { useCallback, useMemo, useState } from 'react';

import {
  DefaultDataType,
  IDataTableRowSelectionMultiple,
  IDataTableRowSelectionNone,
  IDataTableRowSelectionSingle,
} from '@components/data-table/model/data-table';

export enum SelectionType {
  none = 'none',
  single = 'single',
  multi = 'multi',
  multiNoAll = 'multiNoAll',
}
interface IUseDataTableSelectionMulti<DataType extends DefaultDataType = DefaultDataType> {
  selection: DataType[];
  clearSelection: () => void;
  rowSelection: IDataTableRowSelectionMultiple<DataType>;
}
interface IUseDataTableSelectionSingle<DataType extends DefaultDataType = DefaultDataType> {
  selection: DataType | null;
  clearSelection: () => void;
  rowSelection: IDataTableRowSelectionSingle<DataType>;
}
interface IUseDataTableSelectionNone {
  selection: null;
  clearSelection: () => void;
  rowSelection: IDataTableRowSelectionNone;
}
type IUseDataTableSelection<DataType extends DefaultDataType = DefaultDataType> =
  | IUseDataTableSelectionMulti<DataType>
  | IUseDataTableSelectionSingle<DataType>
  | IUseDataTableSelectionNone;

// Multi Selection
function useDataTableSelection<DataType extends DefaultDataType = DefaultDataType>(
  selectionType: SelectionType.multi | SelectionType.multiNoAll,
  selectionDataKey: keyof DataType,
): IUseDataTableSelectionMulti<DataType>;

// Single Selection
function useDataTableSelection<DataType extends DefaultDataType = DefaultDataType>(
  selectionType: SelectionType.single,
  selectionDataKey: keyof DataType,
): IUseDataTableSelectionSingle<DataType>;

// None Selection
function useDataTableSelection(
  selectionType: SelectionType.none,
  selectionDataKey?: never,
): IUseDataTableSelectionNone;

// Dynamic Multi Selection Catch-all (Used when a table has conditions that may change selectionType)
function useDataTableSelection<DataType extends DefaultDataType = DefaultDataType>(
  selectionType: SelectionType.multi | SelectionType.multiNoAll | SelectionType.none,
  selectionDataKey: keyof DataType,
): IUseDataTableSelectionMulti<DataType> | IUseDataTableSelectionNone;

// Dynamic Single Selection Catch-all (Used when a table has conditions that may change selectionType)
function useDataTableSelection<DataType extends DefaultDataType = DefaultDataType>(
  selectionType: SelectionType.single | SelectionType.none,
  selectionDataKey: keyof DataType,
): IUseDataTableSelectionSingle<DataType> | IUseDataTableSelectionNone;

function useDataTableSelection<DataType extends DefaultDataType = DefaultDataType>(
  selectionType: SelectionType,
  selectionDataKey: keyof DataType,
): IUseDataTableSelection<DataType> {
  const [selection, setSelection] = useState<DataType | DataType[]>(null);
  const memoizedSelection = useMemo(() => selection, [selection]);

  const [clearSelectionTrigger, setClearSelectionTrigger] = useState(false);
  const triggerClearSelection = useCallback(() => {
    setClearSelectionTrigger((trigger) => !trigger);
  }, []);

  if (selectionType === SelectionType.multi || selectionType === SelectionType.multiNoAll) {
    return {
      selection: (memoizedSelection as DataType[]) || [],
      clearSelection: triggerClearSelection,
      rowSelection: {
        enableRowSelection: selectionType,
        setSelectedRowsData: setSelection,
        selectionDataKey,
        clearSelectionTrigger,
      },
    };
  }
  if (selectionType === SelectionType.single) {
    return {
      selection: memoizedSelection as DataType,
      clearSelection: triggerClearSelection,
      rowSelection: {
        enableRowSelection: selectionType,
        setSelectedRowsData: setSelection,
        selectionDataKey,
        clearSelectionTrigger,
      },
    };
  }
  if (selectionType === SelectionType.none) {
    return {
      selection: null,
      clearSelection: () => {},
      rowSelection: { enableRowSelection: selectionType },
    };
  }
}

export default useDataTableSelection;
