/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateOneAgentConfigurationMutationVariables = Types.Exact<{
  agentName: Types.Scalars['String']['input'];
  hosts: Array<Types.AgentConfigurationHostInput> | Types.AgentConfigurationHostInput;
}>;


export type CreateOneAgentConfigurationMutation = { __typename?: 'Mutation', createOneAgentConfiguration: { __typename?: 'ProxyConfig', proxyUrl?: string | null, agents: Array<{ __typename?: 'AgentConfiguration', agentId: string, agentName?: string | null, status?: Types.AgentConfigStatus | null, version?: string | null, hosts: Array<{ __typename?: 'AgentConfigurationHost', accessPolicy: Types.AgentConfigurationAccessPolicy, hostname?: string | null, path: string, port?: string | null }> }> } };

export type UpdateOneAgentConfigurationMutationVariables = Types.Exact<{
  agentId: Types.Scalars['ID']['input'];
  update: Types.AgentConfigurationUpdate;
}>;


export type UpdateOneAgentConfigurationMutation = { __typename?: 'Mutation', updateOneAgentConfiguration: { __typename?: 'ProxyConfig', proxyUrl?: string | null, agents: Array<{ __typename?: 'AgentConfiguration', agentId: string, agentName?: string | null, status?: Types.AgentConfigStatus | null, version?: string | null, hosts: Array<{ __typename?: 'AgentConfigurationHost', accessPolicy: Types.AgentConfigurationAccessPolicy, hostname?: string | null, path: string, port?: string | null }> }> } };


export const CreateOneAgentConfigurationDocument = gql`
    mutation CreateOneAgentConfiguration($agentName: String!, $hosts: [AgentConfigurationHostInput!]!) {
  createOneAgentConfiguration(agentName: $agentName, hosts: $hosts) {
    proxyUrl
    agents {
      agentId
      agentName
      status
      version
      hosts {
        accessPolicy
        hostname
        path
        port
      }
    }
  }
}
    `;
export type CreateOneAgentConfigurationMutationFn = Apollo.MutationFunction<CreateOneAgentConfigurationMutation, CreateOneAgentConfigurationMutationVariables>;

/**
 * __useCreateOneAgentConfigurationMutation__
 *
 * To run a mutation, you first call `useCreateOneAgentConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneAgentConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneAgentConfigurationMutation, { data, loading, error }] = useCreateOneAgentConfigurationMutation({
 *   variables: {
 *      agentName: // value for 'agentName'
 *      hosts: // value for 'hosts'
 *   },
 * });
 */
export function useCreateOneAgentConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneAgentConfigurationMutation, CreateOneAgentConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneAgentConfigurationMutation, CreateOneAgentConfigurationMutationVariables>(CreateOneAgentConfigurationDocument, options);
      }
export type CreateOneAgentConfigurationMutationHookResult = ReturnType<typeof useCreateOneAgentConfigurationMutation>;
export type CreateOneAgentConfigurationMutationResult = Apollo.MutationResult<CreateOneAgentConfigurationMutation>;
export type CreateOneAgentConfigurationMutationOptions = Apollo.BaseMutationOptions<CreateOneAgentConfigurationMutation, CreateOneAgentConfigurationMutationVariables>;
export const UpdateOneAgentConfigurationDocument = gql`
    mutation UpdateOneAgentConfiguration($agentId: ID!, $update: AgentConfigurationUpdate!) {
  updateOneAgentConfiguration(agentId: $agentId, update: $update) {
    agents {
      agentId
      agentName
      hosts {
        accessPolicy
        hostname
        path
        port
      }
      status
      version
    }
    proxyUrl
  }
}
    `;
export type UpdateOneAgentConfigurationMutationFn = Apollo.MutationFunction<UpdateOneAgentConfigurationMutation, UpdateOneAgentConfigurationMutationVariables>;

/**
 * __useUpdateOneAgentConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateOneAgentConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneAgentConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneAgentConfigurationMutation, { data, loading, error }] = useUpdateOneAgentConfigurationMutation({
 *   variables: {
 *      agentId: // value for 'agentId'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdateOneAgentConfigurationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneAgentConfigurationMutation, UpdateOneAgentConfigurationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneAgentConfigurationMutation, UpdateOneAgentConfigurationMutationVariables>(UpdateOneAgentConfigurationDocument, options);
      }
export type UpdateOneAgentConfigurationMutationHookResult = ReturnType<typeof useUpdateOneAgentConfigurationMutation>;
export type UpdateOneAgentConfigurationMutationResult = Apollo.MutationResult<UpdateOneAgentConfigurationMutation>;
export type UpdateOneAgentConfigurationMutationOptions = Apollo.BaseMutationOptions<UpdateOneAgentConfigurationMutation, UpdateOneAgentConfigurationMutationVariables>;