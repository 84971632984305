/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CancelTasksMutationVariables = Types.Exact<{
  tasksToCancel: Array<Types.TaskCancelManyDto> | Types.TaskCancelManyDto;
}>;


export type CancelTasksMutation = { __typename?: 'Mutation', cancelManyTasks: Array<{ __typename?: 'Task', id: string, code: string }> };


export const CancelTasksDocument = gql`
    mutation CancelTasks($tasksToCancel: [TaskCancelManyDto!]!) {
  cancelManyTasks(input: $tasksToCancel) {
    id
    code
  }
}
    `;
export type CancelTasksMutationFn = Apollo.MutationFunction<CancelTasksMutation, CancelTasksMutationVariables>;

/**
 * __useCancelTasksMutation__
 *
 * To run a mutation, you first call `useCancelTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelTasksMutation, { data, loading, error }] = useCancelTasksMutation({
 *   variables: {
 *      tasksToCancel: // value for 'tasksToCancel'
 *   },
 * });
 */
export function useCancelTasksMutation(baseOptions?: Apollo.MutationHookOptions<CancelTasksMutation, CancelTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelTasksMutation, CancelTasksMutationVariables>(CancelTasksDocument, options);
      }
export type CancelTasksMutationHookResult = ReturnType<typeof useCancelTasksMutation>;
export type CancelTasksMutationResult = Apollo.MutationResult<CancelTasksMutation>;
export type CancelTasksMutationOptions = Apollo.BaseMutationOptions<CancelTasksMutation, CancelTasksMutationVariables>;