import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useListCompaniesByOrgQuery } from '@/graphql/defs/list/__generated__/list-companies.generated';
import { ListCompaniesByOrg_defaultData } from '@/graphql/defs/list/list-companies';
import { ModelWarehouseType } from '@/graphql/types.generated';
import TextField from '@components/text-field';
import { useEntityUtils } from '@context/entity-utils';
import { useFormValidation } from '@hooks/form/validators';
import enumKeys from '@lib/enum-keys';
import { uppercase } from '@lib/form';

export interface IFieldBlockWarehouseDetails {
  control: Control;
  focusOnLoad?: boolean;
  isUpdate?: boolean;
}
export const FieldBlockWarehouseDetails: React.FC<IFieldBlockWarehouseDetails> = ({
  control,
  focusOnLoad,
  isUpdate = false,
}) => {
  const { t } = useTranslation('components');
  const { notWhiteSpaceOnly } = useFormValidation();

  const { selectedOrganizationId } = useEntityUtils();

  const { data: listCompaniesData } = useListCompaniesByOrgQuery({
    variables: { orgId: selectedOrganizationId },
  });
  const {
    listCompaniesByOrg: { companies },
  } = useMemo(() => listCompaniesData || ListCompaniesByOrg_defaultData, [listCompaniesData]);

  return (
    <>
      <Grid item xs={6}>
        <Controller
          name="name"
          control={control}
          rules={{
            required: t('field-blocks.warehouseDetails.name'),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              required
              id="warehouse-name"
              dataTestId="warehouse-name"
              label={t('field-blocks.warehouseDetails.name')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              autoFocus={focusOnLoad}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="code"
          control={control}
          rules={{
            required: t('form.validation.requiredField', {
              field: t('field-blocks.warehouseDetails.code'),
            }),
            validate: {
              notWhiteSpaceOnly,
            },
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              required
              id="warehouse-code"
              dataTestId="warehouse-code"
              label={t('field-blocks.warehouseDetails.code')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              onChange={(e) => field.onChange(uppercase(e))}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="companyId"
          control={control}
          rules={{
            required: t('form.validation.requiredField', {
              field: t('field-blocks.warehouseDetails.company'),
            }),
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              required
              id="warehouse-company"
              label={t('field-blocks.warehouseDetails.company')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              select
              SelectProps={{ native: true }}
              dataTestId="warehouse-company"
            >
              <option disabled />
              {companies.map((c) => (
                <option key={c.id} value={c.id}>
                  {c.name}
                </option>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="type"
          control={control}
          rules={{
            required: t('form.validation.requiredField', {
              field: t('field-blocks.warehouseDetails.model'),
            }),
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              required
              select
              SelectProps={{
                native: true,
              }}
              id="modelType"
              dataTestId="warehouse-model-type"
              label={t('field-blocks.warehouseDetails.model')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              disabled={isUpdate}
            >
              <option disabled />
              {enumKeys(ModelWarehouseType).map((modelType) => (
                <option
                  key={`modelType-${ModelWarehouseType[modelType]}`}
                  value={ModelWarehouseType[modelType]}
                >
                  {t(`warehouse.modelTypes.${ModelWarehouseType[modelType]}`)}
                </option>
              ))}
            </TextField>
          )}
        />
      </Grid>
    </>
  );
};
