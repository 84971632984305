import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateZoneMutation } from '@/graphql/defs/components/modals/__generated__/create-zone-modal.generated';
import { ZoneCreateInput } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import { uppercase } from '@lib/form';
import FormValues from '@models/FormValues';
import { IZoneCreateModal } from '@models/modal';

const CreateZoneModal = () => {
  const { t } = useTranslation('components');
  const { closeModal, isPreparing, setLoading } = useModalContent<IZoneCreateModal>();
  const { selectedWarehouseId } = useEntityUtils();
  const { showMessage } = useSnackbar();
  const formMethods = useForm<FormValues<ZoneCreateInput>>({
    defaultValues: {
      name: '',
      code: '',
      description: '',
    },
  });
  const { control, handleSubmit } = formMethods;
  const { notWhiteSpaceOnly } = useFormValidation();

  const [createZone] = useCreateZoneMutation({
    onCompleted: ({ createOneZone: { name } }) => {
      showMessage({
        type: 'success',
        message: t('modal.zone.createSuccess', { name }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onSubmit = (zoneFields: ZoneCreateInput) => {
    void createZone({
      variables: {
        input: {
          zone: { ...zoneFields, warehouseId: selectedWarehouseId },
        },
      },
    });
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Controller
                name="code"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('zones.columns.code') }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    autoFocus
                    required
                    fullWidth
                    dataTestId="zone-code"
                    label={t('zones.columns.code')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={(e) => field.onChange(uppercase(e))}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('zones.columns.name') }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    dataTestId="zone-name"
                    label={t('zones.columns.name')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    dataTestId="zone-description"
                    label={t('zones.columns.description')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    maxRows={3}
                    multiline
                  />
                )}
              />
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions>
          <ModalButton
            onClick={() => closeModal()}
            variant="outlined"
            color="primary"
            actionType="cancel"
          >
            {t('common.cancel')}
          </ModalButton>
          <ModalButton
            data-testid="createZoneModal_submit"
            variant="contained"
            color="primary"
            actionType="submit"
          >
            {t('common.submit')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default CreateZoneModal;
