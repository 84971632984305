/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTaskTypeBinStatusModalMappingsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ViewTaskTypeBinStatusMappingFilter>;
  sorting?: Types.InputMaybe<Array<Types.ViewTaskTypeBinStatusMappingSort> | Types.ViewTaskTypeBinStatusMappingSort>;
  paging?: Types.InputMaybe<Types.OffsetPaging>;
}>;


export type GetTaskTypeBinStatusModalMappingsQuery = { __typename?: 'Query', view: { __typename?: 'ViewTaskTypeBinStatusMappingOffsetConnection', nodes: Array<{ __typename?: 'ViewTaskTypeBinStatusMapping', taskTypeCode?: string | null, taskTypeId?: string | null, taskTypeLabel?: string | null, binStatusMappings?: Array<{ __typename?: 'BinStatusMappingJson', binStatusId?: string | null, binStatusLabel?: string | null, binStatusCode?: string | null, editable?: boolean | null, mapped?: boolean | null }> | null }> } };

export type TaskTypeBinStatusModalMappingUpdateMutationVariables = Types.Exact<{
  input: Types.TaskTypeBinStatusReplaceInputDto;
}>;


export type TaskTypeBinStatusModalMappingUpdateMutation = { __typename?: 'Mutation', update: { __typename?: 'ViewTaskTypeBinStatusMapping', taskTypeCode?: string | null, taskTypeId?: string | null, taskTypeLabel?: string | null, binStatusMappings?: Array<{ __typename?: 'BinStatusMappingJson', binStatusId?: string | null, binStatusLabel?: string | null, binStatusCode?: string | null, editable?: boolean | null, mapped?: boolean | null }> | null } };


export const GetTaskTypeBinStatusModalMappingsDocument = gql`
    query GetTaskTypeBinStatusModalMappings($filter: ViewTaskTypeBinStatusMappingFilter, $sorting: [ViewTaskTypeBinStatusMappingSort!], $paging: OffsetPaging) {
  view: viewTaskTypeBinStatusMappings(
    filter: $filter
    sorting: $sorting
    paging: $paging
  ) {
    nodes {
      binStatusMappings {
        binStatusId
        binStatusLabel
        binStatusCode
        editable
        mapped
      }
      taskTypeCode
      taskTypeId
      taskTypeLabel
    }
  }
}
    `;

/**
 * __useGetTaskTypeBinStatusModalMappingsQuery__
 *
 * To run a query within a React component, call `useGetTaskTypeBinStatusModalMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskTypeBinStatusModalMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskTypeBinStatusModalMappingsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useGetTaskTypeBinStatusModalMappingsQuery(baseOptions?: Apollo.QueryHookOptions<GetTaskTypeBinStatusModalMappingsQuery, GetTaskTypeBinStatusModalMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTaskTypeBinStatusModalMappingsQuery, GetTaskTypeBinStatusModalMappingsQueryVariables>(GetTaskTypeBinStatusModalMappingsDocument, options);
      }
export function useGetTaskTypeBinStatusModalMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTaskTypeBinStatusModalMappingsQuery, GetTaskTypeBinStatusModalMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTaskTypeBinStatusModalMappingsQuery, GetTaskTypeBinStatusModalMappingsQueryVariables>(GetTaskTypeBinStatusModalMappingsDocument, options);
        }
export type GetTaskTypeBinStatusModalMappingsQueryHookResult = ReturnType<typeof useGetTaskTypeBinStatusModalMappingsQuery>;
export type GetTaskTypeBinStatusModalMappingsLazyQueryHookResult = ReturnType<typeof useGetTaskTypeBinStatusModalMappingsLazyQuery>;
export type GetTaskTypeBinStatusModalMappingsQueryResult = Apollo.QueryResult<GetTaskTypeBinStatusModalMappingsQuery, GetTaskTypeBinStatusModalMappingsQueryVariables>;
export const TaskTypeBinStatusModalMappingUpdateDocument = gql`
    mutation TaskTypeBinStatusModalMappingUpdate($input: TaskTypeBinStatusReplaceInputDto!) {
  update: updateOneTaskTypeBinStatusMapping(input: $input) {
    binStatusMappings {
      binStatusId
      binStatusLabel
      binStatusCode
      editable
      mapped
    }
    taskTypeCode
    taskTypeId
    taskTypeLabel
  }
}
    `;
export type TaskTypeBinStatusModalMappingUpdateMutationFn = Apollo.MutationFunction<TaskTypeBinStatusModalMappingUpdateMutation, TaskTypeBinStatusModalMappingUpdateMutationVariables>;

/**
 * __useTaskTypeBinStatusModalMappingUpdateMutation__
 *
 * To run a mutation, you first call `useTaskTypeBinStatusModalMappingUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTaskTypeBinStatusModalMappingUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [taskTypeBinStatusModalMappingUpdateMutation, { data, loading, error }] = useTaskTypeBinStatusModalMappingUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskTypeBinStatusModalMappingUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TaskTypeBinStatusModalMappingUpdateMutation, TaskTypeBinStatusModalMappingUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TaskTypeBinStatusModalMappingUpdateMutation, TaskTypeBinStatusModalMappingUpdateMutationVariables>(TaskTypeBinStatusModalMappingUpdateDocument, options);
      }
export type TaskTypeBinStatusModalMappingUpdateMutationHookResult = ReturnType<typeof useTaskTypeBinStatusModalMappingUpdateMutation>;
export type TaskTypeBinStatusModalMappingUpdateMutationResult = Apollo.MutationResult<TaskTypeBinStatusModalMappingUpdateMutation>;
export type TaskTypeBinStatusModalMappingUpdateMutationOptions = Apollo.BaseMutationOptions<TaskTypeBinStatusModalMappingUpdateMutation, TaskTypeBinStatusModalMappingUpdateMutationVariables>;