import React, { useEffect, useMemo, useState } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  UoMConversionsFragment,
  useGetProductUoMConversionsLazyQuery,
  useGetProductUoMConversionsQuery,
} from '@/graphql/defs/shared-queries/__generated__/get-product-uom-conversions.generated';
import TextField from '@components/text-field';
import { useFormValidation } from '@hooks/form/validators';
import decodeHtmlEntities from '@lib/decode-html-entities';
import { allowOnlyNumeric } from '@lib/form';

interface IUoMTiedQuantity {
  fieldName: string;
  fieldLabel: string;
  uomFieldName: string;
  defaultQuantity: string;
  availableQuantity: string;
  defaultUomId: string;
  required?: boolean;
  autoFocus?: boolean;
}
const UoMTiedQuantity: React.FC<IUoMTiedQuantity> = ({
  fieldName,
  fieldLabel,
  uomFieldName,
  defaultQuantity,
  availableQuantity,
  defaultUomId,
  required = true,
  autoFocus = false,
}) => {
  const { t: tC } = useTranslation('components');
  const { control, setValue, clearErrors } = useFormContext();
  const { fieldGreaterThanZero, fieldMaxValue } = useFormValidation();

  const {
    data: {
      convertQuantityToAllUoM: { unitOfMeasureConversions: maxQtyUnitOfMeasureConversions },
    } = {
      convertQuantityToAllUoM: { unitOfMeasureConversions: [] as UoMConversionsFragment[] },
    },
  } = useGetProductUoMConversionsQuery({
    variables: { quantity: availableQuantity, unitOfMeasure: defaultUomId },
  });

  const [
    getConversions,
    {
      data: { convertQuantityToAllUoM: { unitOfMeasureConversions } } = {
        convertQuantityToAllUoM: { unitOfMeasureConversions: [] as UoMConversionsFragment[] },
      },
    },
  ] = useGetProductUoMConversionsLazyQuery();

  const uomId = useWatch({
    control,
    name: uomFieldName,
  });

  const [userUpdatedUoM, setUserUpdatedUoM] = useState(false);
  useEffect(() => {
    const uomConversion = unitOfMeasureConversions.find((uom) => uom.unitOfMeasure.id === uomId);
    if (uomConversion?.quantity) {
      setUserUpdatedUoM(true);
      setValue(fieldName, uomConversion.quantity);
    }
  }, [uomId]);

  const maxQuantity = useMemo(() => {
    const currUomConversion = maxQtyUnitOfMeasureConversions.find((conversion) => {
      return uomId
        ? conversion?.unitOfMeasure?.id === uomId
        : conversion?.unitOfMeasure?.id === defaultUomId;
    });
    return currUomConversion?.quantity || '0';
  }, [uomId, maxQtyUnitOfMeasureConversions]);

  const { field, fieldState } = useController({
    control,
    name: fieldName,
    defaultValue: defaultQuantity || '0',
    rules: {
      required: decodeHtmlEntities(
        tC('form.validation.requiredField', {
          field: fieldLabel,
        }),
      ),
      validate: {
        minValue: fieldGreaterThanZero,
        maxValue: fieldMaxValue(parseFloat(maxQuantity)),
      },
    },
  });

  useEffect(() => {
    clearErrors();
    if (!userUpdatedUoM && field.value) {
      void getConversions({
        variables: { quantity: String(field.value), unitOfMeasure: uomId ? uomId : defaultUomId },
      });
    } else {
      setUserUpdatedUoM(false);
    }
  }, [field.value]);

  return (
    <TextField
      {...field}
      autoFocus={autoFocus}
      fullWidth
      required={required}
      id={`${fieldName}-uomTiedQuantity`}
      label={fieldLabel}
      error={!!fieldState?.error}
      helperText={fieldState?.error?.message}
      type="number"
      onChange={(e) =>
        field.onChange(
          allowOnlyNumeric(e, true, {
            allowNegatives: false,
            allowZero: false,
            allowFloat: true,
          }),
        )
      }
      dataTestId={`${fieldName}-uomTiedQuantity`}
    />
  );
};

export default UoMTiedQuantity;
