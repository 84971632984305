import { gql } from '@apollo/client';

import { GetDateTimeDisplayPrefQuery } from '@/graphql/defs/context/__generated__/date-time.generated';
import { NumberFormat, StandardUomSystem } from '@/graphql/types.generated';
import { DEFAULT_DATE_TIME_FORMAT } from '@constants/date-time-formats';

gql`
  query GetDateTimeDisplayPref($userId: ID!) {
    user(id: $userId) {
      id
      displayPreference {
        ...FullDisplayPref
      }
    }
  }
`;

export const GetDateTimeDisplayPref_defaultData: GetDateTimeDisplayPrefQuery = {
  __typename: 'Query',
  user: {
    __typename: 'User',
    id: 'nullResponse',
    displayPreference: {
      __typename: 'DisplayPreference',
      id: 'nullResponse',
      dateTimeFormat: DEFAULT_DATE_TIME_FORMAT,
      currency: '',
      language: '',
      numberFormat: NumberFormat.WholeCommasFractionalPeriod,
      measurementSystem: StandardUomSystem.Imperial,
      timezone: '',
    },
  },
};
