import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useCallback, useLayoutEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import MapSelector from './map-selector';
import PanZoom from './pan-zoom';

import PageInfo from '@components/page-info';

export const insertPolygon = (obj) => {
  return [
    [obj.x1, obj.y1],
    [obj.x1, obj.y2],
    [obj.x2, obj.y2],
    [obj.x2, obj.y1],
    [obj.x1, obj.y1],
  ];
};

type CanvasProps = {
  map: Record<any, any>;
  handleClick?: (clicked: any[]) => void;
  handleSelection?: (selected: any[]) => void;
  selection?: any[];
  mapKey?: React.ReactElement;
  additionalSelectors?: React.ReactElement;
  loading?: boolean;
  hideMapSelector?: boolean;
  hideTitle?: boolean;
};

type Point = {
  x: number;
  y: number;
};

const ORIGIN = Object.freeze({ x: 0, y: 0 });

// adjust to device to avoid blur
const { devicePixelRatio: ratio = 1 } = window;

function diffPoints(p1: Point, p2: Point) {
  return { x: p1.x - p2.x, y: p1.y - p2.y };
}

function addPoints(p1: Point, p2: Point) {
  return { x: p1.x + p2.x, y: p1.y + p2.y };
}

function scalePoint(p1: Point, scale: number) {
  return { x: p1.x / scale, y: p1.y / scale };
}

const renderedText = [];

const lineWidth = 0.5;
export const backgroundColor = '#D9D9D9';

const ZOOM_SENSITIVITY = 500; // bigger for lower zoom per scroll
let moving = false;

const MAX_SCALE = 10;
const MIN_SCALE = 0.01;
const STARTING_SCALE = 1;
const USE_TEST_MAP = false;

export default function BaseMap(props: CanvasProps) {
  const map = USE_TEST_MAP ? TEST_WAREHOUSE : props?.map;
  const [mouseEnabled, setMouseEnabled] = useState(false);
  const { t } = useTranslation('pages', { keyPrefix: 'maps' });
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
  const [scale, setScale] = useState<number>(STARTING_SCALE);
  const [scaleFactor, setScaleFactor] = useState<number>(1);
  const [offset, setOffset] = useState<Point>(ORIGIN);
  const [mousePos, setMousePos] = useState<Point>(ORIGIN);
  const [viewportTopLeft, setViewportTopLeft] = useState<Point>(ORIGIN);
  const isResetRef = useRef<boolean>(false);
  const lastMousePosRef = useRef<Point>(ORIGIN);
  const lastOffsetRef = useRef<Point>(ORIGIN);

  const [width, setWidth] = useState(0);
  const height = 600;
  const sizeRef = useRef(null);
  const handleResize = () => {
    setWidth(sizeRef.current.offsetWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    setWidth(sizeRef.current.offsetWidth);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setInitialScaleFactorAndOffset();
  }, [map]);

  const setInitialScaleFactorAndOffset = () => {
    if (map) {
      console.log({ map: props.map });
      const bounds = getBounds(props.map.polygon);
      const yDiff = bounds.y.max - bounds.y.min;
      const yScaleFactor = yDiff / height;
      const xDiff = bounds.x.max - bounds.x.min;
      const xScaleFactor = xDiff / width;
      const scaleFactor = Math.max(yScaleFactor, xScaleFactor) * 1.05; // 1.05 is used to add some padding
      const xOffset = width / 2 - (bounds.x.max + bounds.x.min) / scaleFactor / 2;
      const yOffset = height / 2 - (bounds.y.max + bounds.y.min) / scaleFactor / 2;
      setScaleFactor(scaleFactor);
      setOffset({ x: xOffset, y: yOffset });
    }
  };

  const getBounds = (polygon) => {
    console.log({ polygon });
    return polygon.reduce(
      (acc, point) => {
        acc.x.max = Math.max(acc.x.max, point[0]);
        acc.x.min = Math.min(acc.x.min, point[0]);
        acc.y.max = Math.max(acc.y.max, point[1]);
        acc.y.min = Math.min(acc.y.min, point[1]);
        return acc;
      },
      {
        x: { max: polygon[0][0], min: polygon[0][0] },
        y: { max: polygon[0][1], min: polygon[0][1] },
      },
    );
  };

  // update last offset
  useEffect(() => {
    lastOffsetRef.current = offset;
  }, [offset]);

  // reset
  const reset = useCallback(
    (context: CanvasRenderingContext2D) => {
      if (context && !isResetRef.current) {
        // adjust for device pixel density
        context.canvas.width = width * ratio;
        context.canvas.height = height * ratio;
        context.scale(ratio, ratio);
        setScale(STARTING_SCALE);

        // reset state and refs
        setContext(context);
        setOffset(ORIGIN);
        setMousePos(ORIGIN);
        setViewportTopLeft(ORIGIN);
        lastOffsetRef.current = ORIGIN;
        lastMousePosRef.current = ORIGIN;

        // this thing is so multiple resets in a row don't clear canvas
        isResetRef.current = true;
      }
    },
    [width, height, map],
  );

  // functions for panning
  const mouseMove = useCallback(
    (event: MouseEvent) => {
      moving = true;
      if (context) {
        const lastMousePos = lastMousePosRef.current;
        const currentMousePos = { x: event.pageX, y: event.pageY }; // use document so can pan off element
        lastMousePosRef.current = currentMousePos;

        const mouseDiff = diffPoints(currentMousePos, lastMousePos);
        setOffset((prevOffset) => {
          const newOffset = addPoints(prevOffset, mouseDiff);
          return newOffset;
        });
      }
    },
    [context, offset],
  );

  const mouseUp = useCallback(
    (event) => {
      document.removeEventListener('mousemove', mouseMove);
      document.removeEventListener('mouseup', mouseUp);
      if (moving === false) {
        mouseClickEvent(event);
      }
      moving = false;
    },
    [mouseMove, offset, map, scale],
  );

  const getOffset = () => {
    const canvas = context.canvas;
    const html = document.body.parentNode as HTMLElement;
    return {
      stylePaddingLeft:
        parseInt(document.defaultView.getComputedStyle(canvas, null)['paddingLeft'], 10) || 0,
      stylePaddingTop:
        parseInt(document.defaultView.getComputedStyle(canvas, null)['paddingTop'], 10) || 0,
      styleBorderLeft:
        parseInt(document.defaultView.getComputedStyle(canvas, null)['borderLeftWidth'], 10) || 0,
      styleBorderTop:
        parseInt(document.defaultView.getComputedStyle(canvas, null)['borderTopWidth'], 10) || 0,
      htmlTop: html.offsetTop,
      htmlLeft: html.offsetLeft,
    };
  };

  const getMouseLocation = (e) => {
    let element = context.canvas as HTMLCanvasElement;
    let offsetX = 0,
      offsetY = 0,
      mx,
      my;

    // Compute the total offset
    if (element.offsetParent !== undefined) {
      do {
        offsetX += element.offsetLeft;
        offsetY += element.offsetTop;
      } while ((element = element.offsetParent as HTMLCanvasElement));
    }

    const offset = getOffset();
    // Add padding and border style widths to offset
    // Also add the <html> offsets in case there's a position:fixed bar
    offsetX += offset.stylePaddingLeft + offset.styleBorderLeft + offset.htmlLeft;
    offsetY += offset.stylePaddingTop + offset.styleBorderTop + offset.htmlTop;

    // eslint-disable-next-line prefer-const
    mx = e.pageX - offsetX;
    // eslint-disable-next-line prefer-const
    my = e.pageY - offsetY;

    // We return a simple javascript object (a hash) with x and y defined
    return { x: mx, y: my };
  };

  const clickedInside = useCallback(
    ({ object: _object, type, x, y }) => {
      if (!_object.clickable) {
        // if object is not clickable then it and its children should not get added to list of selected objects
        return { clicked: [], selected: [] };
      }

      const object = { ..._object };
      if (object.polygon) {
        object.x1 = object.polygon[0][0];
        object.y1 = object.polygon[0][1];
        object.x2 = object.polygon[2][0];
        object.y2 = object.polygon[2][1];
      }
      // if click in object add to route
      // const insideX = x >= Math.min(object.x1, object.x2) && x <= Math.max(object.x1, object.x2);
      // const insideY = y >= Math.min(object.y1, object.y2) && y <= Math.max(object.y1, object.y2);
      const insideX =
        x >= Math.min(object.x1 / scaleFactor, object.x2 / scaleFactor) &&
        x <= Math.max(object.x1 / scaleFactor, object.x2 / scaleFactor);
      const insideY =
        y >= Math.min(object.y1 / scaleFactor, object.y2 / scaleFactor) &&
        y <= Math.max(object.y1 / scaleFactor, object.y2 / scaleFactor);
      // if (_object.code === 'FE51003') {
      //   console.log({ _object, type, x, y, insideX, insideY });
      // }
      // if object is not clicked inside of then it and its children should not get added to list of selected objects
      if (!insideX || !insideY) {
        return { clicked: [], selected: [] };
      }

      // find an array of child objects that were also clicked on
      const children = Object.entries(object);
      const { selected: selectedChildren, clicked: clickedChildren } = children.reduce(
        (acc, [key, value]) => {
          if (Array.isArray(value) && typeof value[0] === 'object' && value[0]?.clickable) {
            const xero = value.reduce(
              (acc2, childObject) => {
                const childClickResult = clickedInside({ object: childObject, type: key, x, y });
                acc2.selected = acc2.selected.concat(childClickResult.selected);
                acc2.clicked = acc2.clicked.concat(childClickResult.clicked);
                return acc2;
              },
              { selected: [], clicked: [] },
            );
            acc.selected = acc.selected.concat(xero.selected);
            acc.clicked = acc.clicked.concat(xero.clicked);
          }

          return acc as { selected: any[]; clicked: any[] };
        },
        { selected: [], clicked: [] },
      );

      // add object to array if selectable

      const clickedObjects = [];
      clickedObjects.push({
        type,
        facing: object.facing,
        id: object.id,
        code: object.code,
        x1: object.x1,
        x2: object.x2,
        y1: object.y1,
        y2: object.y2,
        polygon: object.polygon,
      });
      const selectedObjects = [];
      if (_object.selectable) {
        selectedObjects.push({
          type,
          facing: object.facing,
          id: object.id,
          code: object.code,
          x1: object.x1,
          x2: object.x2,
          y1: object.y1,
          y2: object.y2,
          polygon: object.polygon,
        });
      }
      const selected = selectedObjects.concat(selectedChildren);
      const clicked = clickedObjects.concat(clickedChildren);
      return {
        selected,
        clicked,
      };
    },
    [offset],
  );

  const mouseClickEvent = (e) => {
    if (!props.handleClick && !props.handleSelection) {
      return;
    }
    const mouseLocation = getMouseLocation(e);
    const x = mouseLocation.x;
    const y = mouseLocation.y;

    // calculate scale and offset adjust x and y

    const scaledWidth = width * scale;
    const scaledHeight = height * scale;
    // const calculatedX = x * scaleFactor;
    // const calculatedY = y * scaleFactor;
    const calculatedX = ((scaledWidth - width) / 2 + x - offset.x) / scale;
    const calculatedY = ((scaledHeight - height) / 2 + y - offset.y) / scale;
    console.log({ x, y, calculatedX, calculatedY, offset, scale, scaleFactor });
    // x- 3000 y - 6200
    // calculatedX: 15.185551218315448
    // calculatedY: 33.297194230259336
    // height: 600
    // offset: {x: -2, y: -1}
    // scale: 0.9936153436225535
    // scaleFactor: 198.1
    // width: 912
    // x: 16     3000/16=187.5
    // y: 34     6200/34=182.32
    const clickResult = clickedInside({
      object: map,
      type: 'map',
      x: calculatedX,
      y: calculatedY,
    });
    if (props.handleClick) {
      props.handleClick(clickResult.clicked);
    }
    if (props.handleSelection) {
      props.handleSelection(clickResult.selected);
    }
  };

  const startPan = useCallback(
    (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
      document.addEventListener('mousemove', mouseMove, { passive: false });
      document.addEventListener('mouseup', mouseUp, { passive: false });
      lastMousePosRef.current = { x: event.pageX, y: event.pageY };
    },
    [mouseMove, mouseUp, offset],
  );

  const onMouseDown = (event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
    startPan(event);
  };

  // setup canvas and set context
  useLayoutEffect(() => {
    if (canvasRef.current) {
      // get new drawing context
      const renderCtx = canvasRef.current.getContext('2d');

      if (renderCtx) {
        reset(renderCtx);
      }
    }
  }, [reset, height, width]);

  // pan when offset or scale changes
  useLayoutEffect(() => {
    if (context && lastOffsetRef.current) {
      const offsetDiff = scalePoint(diffPoints(offset, lastOffsetRef.current), scale);
      context.translate(offsetDiff.x, offsetDiff.y);
      setViewportTopLeft((prevVal) => diffPoints(prevVal, offsetDiff));
      isResetRef.current = false;
    }
  }, [context, offset, scale]);

  const renderObject = (obj, type, ctx) => {
    if (obj.rendered) {
      if (!obj.polygon) {
        obj.polygon = [
          [obj.x1, obj.y1],
          [obj.x1, obj.y2],
          [obj.x2, obj.y2],
          [obj.x2, obj.y1],
          [obj.x1, obj.y1],
        ];
      }
      const isSelected = obj.selectable && props.selection?.includes(({ id }) => id === obj.id);
      if (obj.strokeStyle) ctx.strokeStyle = isSelected ? obj.selectedStrokeStyle : obj.strokeStyle;
      ctx.beginPath();
      ctx.moveTo(Number(obj.polygon[0][0]) / scaleFactor, Number(obj.polygon[0][1]) / scaleFactor);
      for (const coord of obj.polygon.slice(0, -1)) {
        ctx.lineTo(Number(Number(coord[0]) / scaleFactor), Number(Number(coord[1]) / scaleFactor));
      }
      ctx.closePath();
      if (obj.fillStyle) {
        ctx.fillStyle = isSelected ? obj.selectedFillStyle : obj.fillStyle;
        ctx.fill();
      }
      if (obj.lineWidth && obj.lineWidth !== ctx.lineWidth) {
        const original = ctx.lineWidth;
        ctx.lineWidth = obj.lineWidth;
        ctx.stroke();
        ctx.lineWidth = original;
      } else {
        ctx.stroke();
      }
    }
    Object.entries(obj).forEach(([key, value]) => {
      if (key === 'text') {
        (value as Record<any, any>[]).forEach((copy) => {
          renderedText.push({
            ...copy,
            strokeStyle: '#000000',
            fillStyle: '#000000',
            x: copy.x / scaleFactor,
            y: copy.y / scaleFactor,
          });
        });
        return;
      }
      if (Array.isArray(value) && typeof value[0] === 'object') {
        value.forEach((child) => renderObject(child, key, ctx));
      }
    });
  };

  const draw = (_obj, type) => {
    renderedText.splice(0, renderedText.length);
    const obj = JSON.parse(JSON.stringify(_obj));
    context.lineWidth = lineWidth;
    renderObject(obj, type, context);

    renderedText.forEach(({ font, text, x, y, fillStyle, strokeStyle, direction, textAlign }) => {
      context.save();
      context.translate(x, y);
      if (direction === 'vertical') {
        context.rotate(-Math.PI / 2);
      }
      context.font = font;
      context.textAlign = textAlign || 'start';
      context.fillStyle = fillStyle;
      context.fillText(text, 0, 0);
      context.restore();
    });
  };

  // draw
  useLayoutEffect(() => {
    if (context && map) {
      // clear canvas but maintain transform
      const storedTransform = context.getTransform();
      context.canvas.width = Number(context?.canvas?.width);
      context.setTransform(storedTransform);

      draw(map, 'warehouse');
    }
  }, [width, height, context, scale, offset, viewportTopLeft, map, props.selection]);

  // add event listener on canvas for mouse position
  useEffect(() => {
    const canvasElem = canvasRef.current;
    if (canvasElem === null) {
      return;
    }

    function handleUpdateMouse(event: MouseEvent) {
      event.preventDefault();
      if (canvasRef.current) {
        const viewportMousePos = { x: event.clientX, y: event.clientY };
        const topLeftCanvasPos = {
          x: canvasRef.current.offsetLeft,
          y: canvasRef.current.offsetTop,
        };
        setMousePos(diffPoints(viewportMousePos, topLeftCanvasPos));
      }
    }

    canvasElem.addEventListener('mousemove', handleUpdateMouse, { passive: false });
    canvasElem.addEventListener('wheel', handleUpdateMouse, { passive: false });
    return () => {
      canvasElem.removeEventListener('mousemove', handleUpdateMouse);
      canvasElem.removeEventListener('wheel', handleUpdateMouse);
    };
  }, []);

  const zoomMap = (deltaY, center = false) => {
    const zoom = 1 - deltaY / ZOOM_SENSITIVITY;
    if ((zoom > 1 && scale >= MAX_SCALE) || (zoom < 1 && scale <= MIN_SCALE)) {
      return;
    }
    const viewportTopLeftDelta = {
      x: ((center ? width / 2 : mousePos.x) / scale) * (1 - 1 / zoom),
      y: ((center ? height / 2 : mousePos.y) / scale) * (1 - 1 / zoom),
    };
    const newViewportTopLeft = addPoints(viewportTopLeft, viewportTopLeftDelta);

    context.translate(viewportTopLeft.x, viewportTopLeft.y);
    context.scale(zoom, zoom);
    context.translate(-newViewportTopLeft.x, -newViewportTopLeft.y);

    setViewportTopLeft(newViewportTopLeft);
    setScale(scale * zoom);
    isResetRef.current = false;
  };

  // add event listener on canvas for zoom
  useEffect(() => {
    const canvasElem = canvasRef.current;
    if (canvasElem === null) {
      return;
    }

    // this is tricky. Update the viewport's "origin" such that
    // the mouse doesn't move during scale - the 'zoom point' of the mouse
    // before and after zoom is relatively the same position on the viewport
    function handleWheel(event: WheelEvent) {
      event.preventDefault();
      if (context) {
        zoomMap(event.deltaY * 3);
      }
    }

    canvasElem.addEventListener('wheel', handleWheel, { passive: false });
    return () => canvasElem.removeEventListener('wheel', handleWheel);
  }, [context, mousePos.x, mousePos.y, viewportTopLeft, scale]);

  return (
    <>
      {props.hideTitle ? null : (
        <PageInfo title={t('warehouseMap')}>
          <Box sx={{ padding: '10px 0px', display: 'flex' }}>
            {props.additionalSelectors || null}
            {props.hideMapSelector ? null : <MapSelector />}
          </Box>
        </PageInfo>
      )}
      <Box
        ref={sizeRef}
        sx={{
          backgroundColor,
          position: 'relative',
          lineHeight: 0,
          // borderTop: '2px solid #d8e0e5',
        }}
      >
        <canvas
          onMouseDown={onMouseDown}
          id="map-canvas"
          ref={canvasRef}
          width={width * ratio}
          height={height * ratio}
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
        ></canvas>
        {mouseEnabled ? (
          <Box
            onClick={() => setMouseEnabled(false)}
            sx={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '35px',
              width: '80px',
              bottom: 10,
              left: 10,
              lineHeight: '25px',
              backgroundColor: '#fffffe',
              borderRadius: '4px',
              color: '#4A4A4A',
              cursor: 'pointer',
              boxShadow:
                '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
            }}
          >
            Disable
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: '#4A4A4AAA',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#ffffff',
              fontSize: '18px',
              fontWeight: 600,
              textShadow: '-1px 0 #4A4A4A99, 0 1px #4A4A4A99, 1px 0 #4A4A4A99, 0 -1px #4A4A4A99',
            }}
            onClick={() => setMouseEnabled(true)}
          >
            Click on map to enable pan/zoom
          </Box>
        )}
        <PanZoom setOffset={setOffset} zoomMap={zoomMap} />
        {props?.mapKey}
        {props.loading ? (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: (theme) => theme.palette.offwhite.main,
            }}
          >
            <CircularProgress />
          </Box>
        ) : null}
      </Box>
    </>
  );
}

const TEST_WAREHOUSE = {
  id: 'std_pallets',
  aisles: [
    {
      id: 'A0',
      group: 1,
      facing: 'W',
      columns: [
        {
          id: '01',
          bins: [
            {
              id: 'A00101',
              level: 1,
              'bin-type': 'None',
            },
          ],
          polygon: [
            [400, 350],
            [500, 350],
            [500, 450],
            [400, 450],
            [400, 350],
          ],
        },
      ],
      polygon: [
        [400, 350],
        [500, 350],
        [500, 450],
        [400, 450],
        [400, 350],
      ],
    },
  ],
  polygon: [
    [400, 350],
    [500, 350],
    [500, 450],
    [400, 450],
    [400, 350],
  ],
};
