import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useViewUoMsQuery } from '@/graphql/defs/shared-queries/__generated__/view-uoms.generated';
import { ViewUoMs_defaultData } from '@/graphql/defs/shared-queries/view-uoms';
import {
  SortDirection,
  ViewUnitOfMeasureProductConversionSortFields,
} from '@/graphql/types.generated';
import LoadingIndicator from '@components/loading-indicator';
import TextField from '@components/text-field';
import decodeHtmlEntities from '@lib/decode-html-entities';

interface IUoMSelection {
  fieldName: string;
  fieldLabel: string;
  defaultUoMId: string;
  productId: string;
  fullWidth?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  sx?: SxProps<Theme>;
}
const UoMSelection: React.FC<IUoMSelection> = ({
  fieldName,
  fieldLabel,
  defaultUoMId,
  productId,
  fullWidth = true,
  required = true,
  autoFocus = false,
  sx,
}) => {
  const { t: tC } = useTranslation('components');
  const { control } = useFormContext();

  const { field, fieldState } = useController({
    control,
    name: fieldName,
    defaultValue: defaultUoMId,
    rules: {
      required: decodeHtmlEntities(
        tC('form.validation.requiredField', {
          field: fieldLabel,
        }),
      ),
    },
  });

  const { data: uomData } = useViewUoMsQuery({
    variables: {
      filter: { productId: { eq: productId } },
      sorting: [
        { field: ViewUnitOfMeasureProductConversionSortFields.Code, direction: SortDirection.Asc },
      ],
    },
  });
  const {
    unitOfMeasures: { nodes: productUoMs },
  } = useMemo(() => uomData || ViewUoMs_defaultData, [uomData]);

  // Needed to fix edge-case where loading productUoMs loads in a hair too late and the Select label doesn't update to the selected state.
  if (!productUoMs.length)
    return (
      <Box>
        <LoadingIndicator />
      </Box>
    );

  return (
    <TextField
      {...field}
      autoFocus={autoFocus}
      fullWidth={fullWidth}
      required={required}
      id={`${fieldName}-uomTiedQuantity`}
      label={fieldLabel}
      error={!!fieldState?.error}
      helperText={fieldState?.error?.message}
      select
      SelectProps={{ native: true }}
      sx={sx}
      dataTestId={`${fieldName}-uomTiedQuantity`}
    >
      <option disabled />
      {productUoMs.map((uom) => (
        <option key={`area-${uom.id}`} value={uom.id}>
          {uom.code}
        </option>
      ))}
    </TextField>
  );
};

export default UoMSelection;
