import { Box, Typography } from '@mui/material';
import { ReactElement, useMemo, isValidElement } from 'react';

import {
  UoMConversionsFragment,
  useGetProductUoMConversionsQuery,
} from '@/graphql/defs/shared-queries/__generated__/get-product-uom-conversions.generated';
import LoadingIndicator from '@components/loading-indicator';
import Tooltip from '@styled/Tooltip';

interface IQuantityConversions {
  quantity: string;
  unitOfMeasureId: string;
}

const QuantityConversions: React.FC<IQuantityConversions> = ({ quantity, unitOfMeasureId }) => {
  const {
    loading: isLoadingConversions,
    data: { convertQuantityToAllUoM: { unitOfMeasureConversions } } = {
      convertQuantityToAllUoM: {
        unitOfMeasureConversions: [] as UoMConversionsFragment[],
      },
    },
  } = useGetProductUoMConversionsQuery({
    variables: { quantity, unitOfMeasure: unitOfMeasureId },
  });

  const sortedConversions = useMemo(() => {
    if (!isLoadingConversions) {
      // We have to copy the array since .sort mutates the source, and we can't mutate the value coming from Apollo.
      const uomCopy = [...unitOfMeasureConversions];
      return uomCopy.sort((a, b) => {
        if (a.quantity < b.quantity) return 1;
        if (a.quantity > b.quantity) return -1;
        return 0;
      });
    }
    return [];
  }, [unitOfMeasureConversions, isLoadingConversions]);

  return isLoadingConversions || sortedConversions.length === 0 ? (
    <LoadingIndicator iconProps={{ sx: { color: (theme) => theme.palette.common.white } }} />
  ) : (
    <>
      {sortedConversions.map((conversion) => (
        <Box
          key={`${conversion.unitOfMeasure.code}-conversions`}
          sx={{
            padding: '5px 10px',
            borderBottom: '1px solid #FFF',
            '&:last-of-type': { borderBottom: 'none' },
          }}
        >
          <Typography
            key={`${conversion.unitOfMeasure.code}-quantity`}
            sx={{
              color: (theme) => theme.palette.common.white,
            }}
          >
            {conversion.quantity} {conversion.unitOfMeasure.code}
          </Typography>
        </Box>
      ))}
    </>
  );
};

const QuantityConversionsTooltip: React.FC<
  IQuantityConversions & {
    anchor: ReactElement | string | number;
    placement?:
      | 'bottom'
      | 'left'
      | 'right'
      | 'top'
      | 'bottom-end'
      | 'bottom-start'
      | 'left-end'
      | 'left-start'
      | 'right-end'
      | 'right-start'
      | 'top-end'
      | 'top-start';
  }
> = ({ quantity, unitOfMeasureId, anchor, placement = 'right-start' }) => {
  const anchorEleCheck = isValidElement(anchor) ? (
    anchor
  ) : (
    <Typography display="inline-block" sx={{ cursor: 'pointer' }}>
      {anchor}
    </Typography>
  );

  return unitOfMeasureId && parseFloat(quantity) > 0 ? (
    <Tooltip
      styledVariant="primaryBg"
      title={<QuantityConversions quantity={quantity} unitOfMeasureId={unitOfMeasureId} />}
      placement={placement}
      arrow
    >
      {anchorEleCheck}
    </Tooltip>
  ) : (
    anchorEleCheck
  );
};

export default QuantityConversionsTooltip;
