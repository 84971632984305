/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateLicensePlateMutationVariables = Types.Exact<{
  binId: Types.Scalars['ID']['input'];
  products: Array<Types.LicensePlateMovementProductInput> | Types.LicensePlateMovementProductInput;
  autocomplete: Types.Scalars['Boolean']['input'];
}>;


export type CreateLicensePlateMutation = { __typename?: 'Mutation', createLicensePlate: { __typename?: 'LicensePlate', id: string, code: string } };


export const CreateLicensePlateDocument = gql`
    mutation CreateLicensePlate($binId: ID!, $products: [LicensePlateMovementProductInput!]!, $autocomplete: Boolean!) {
  createLicensePlate: moveStockToLicensePlate(
    input: {licensePlateMovement: {autocomplete: $autocomplete, destinationBinId: $binId, products: $products}}
  ) {
    id
    code
  }
}
    `;
export type CreateLicensePlateMutationFn = Apollo.MutationFunction<CreateLicensePlateMutation, CreateLicensePlateMutationVariables>;

/**
 * __useCreateLicensePlateMutation__
 *
 * To run a mutation, you first call `useCreateLicensePlateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLicensePlateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLicensePlateMutation, { data, loading, error }] = useCreateLicensePlateMutation({
 *   variables: {
 *      binId: // value for 'binId'
 *      products: // value for 'products'
 *      autocomplete: // value for 'autocomplete'
 *   },
 * });
 */
export function useCreateLicensePlateMutation(baseOptions?: Apollo.MutationHookOptions<CreateLicensePlateMutation, CreateLicensePlateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLicensePlateMutation, CreateLicensePlateMutationVariables>(CreateLicensePlateDocument, options);
      }
export type CreateLicensePlateMutationHookResult = ReturnType<typeof useCreateLicensePlateMutation>;
export type CreateLicensePlateMutationResult = Apollo.MutationResult<CreateLicensePlateMutation>;
export type CreateLicensePlateMutationOptions = Apollo.BaseMutationOptions<CreateLicensePlateMutation, CreateLicensePlateMutationVariables>;