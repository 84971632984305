/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { UoMFragmentDoc } from './uom.generated';
export type BaseProductFragment = { __typename?: 'Product', id: string, companyId: string, code: string, description?: string | null, type?: string | null, status?: Types.ProductAvailability | null };

export type FullProductFragment = { __typename?: 'Product', lotManaged?: boolean | null, netWeight?: number | null, grossWeight?: number | null, totalWeightUOMId?: string | null, volumeUOMId?: string | null, volume?: number | null, erpUpdatedAt?: string | null, erpCreatedAt?: string | null, createdAt: string, updatedAt?: string | null, id: string, companyId: string, code: string, description?: string | null, type?: string | null, status?: Types.ProductAvailability | null, unitOfMeasures?: { __typename?: 'UnitOfMeasureProductConversionNodes', nodes: Array<{ __typename?: 'UnitOfMeasureProductConversion', id: string, code: string, isBaseUom: boolean }> } | null };

export const BaseProductFragmentDoc = gql`
    fragment BaseProduct on Product {
  id
  companyId
  code
  description
  type
  status
}
    `;
export const FullProductFragmentDoc = gql`
    fragment FullProduct on Product {
  ...BaseProduct
  lotManaged
  unitOfMeasures {
    nodes {
      ...UoM
    }
  }
  netWeight
  grossWeight
  totalWeightUOMId
  volumeUOMId
  volume
  erpUpdatedAt
  erpCreatedAt
  createdAt
  updatedAt
}
    ${BaseProductFragmentDoc}
${UoMFragmentDoc}`;