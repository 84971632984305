import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAddEquipmentTypeMutation } from '@/graphql/defs/components/modals/__generated__/add-equipment-type-modal.generated';
import {
  useDeleteEquipmentTypeMutation,
  useUpdateEquipmentTypeMutation,
} from '@/graphql/defs/components/modals/__generated__/update-equipment-type-modal.generated';
import { EquipmentMobility, EquipmentTypeCreateType } from '@/graphql/types.generated';
import CurrencyInput from '@components/form-util/field-blocks/currency-dropdown';
import DistanceUomDropdownDb from '@components/form-util/field-blocks/distance-uom-dropdown-db';
import VelocityUomDropdownDb from '@components/form-util/field-blocks/velocity-uom-dropdown-db';
import VolumeUomDropdownDb from '@components/form-util/field-blocks/volume-uom-dropdown-db';
import WeightUomDropdownDb from '@components/form-util/field-blocks/weight-uom-dropdown-db';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import enumKeys from '@lib/enum-keys';
import { convertNullValuesToEmptyString, stripUnchangedFields, uppercase } from '@lib/form';
import FormValues from '@models/FormValues';
import { IEquipmentTypeModal } from '@models/modal';

interface IEquipmentType {
  code: string;
  label: string;
  description: string;
  weightMax: number | string;
  weightUOMId: string;
  heightMax: number | string;
  heightMin: number | string;
  heightUOMId: string;
  volumeMax: number | string;
  volumeUOMId: string;
  cost: number | string;
  currency: string;
  velocity: number | string;
  verticalVelocity: number | string;
  velocityUOMId: string;
  mobility: EquipmentMobility;
}
type TEquipmentTypeFields = keyof IEquipmentType;
const equipmentFields: TEquipmentTypeFields[] = [
  'code',
  'label',
  'description',
  'weightMax',
  'weightUOMId',
  'heightMax',
  'heightMin',
  'heightUOMId',
  'volumeMax',
  'volumeUOMId',
  'cost',
  'currency',
  'velocity',
  'verticalVelocity',
  'velocityUOMId',
  'mobility',
];
const EquipmentTypeModal = () => {
  const { closeModal, setLoading, modal } = useModalContent<IEquipmentTypeModal>();
  const { selectedWarehouseId } = useEntityUtils();
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const defaultValues = useMemo(() => {
    return convertNullValuesToEmptyString<TEquipmentTypeFields>(
      equipmentFields,
      modal?.equipmentType,
    );
  }, []);

  const formMethods = useForm<FormValues<IEquipmentType>>({
    defaultValues: {
      ...defaultValues,
      currency: 'USD',
    },
  });
  const { handleSubmit, control } = formMethods;
  const { notWhiteSpaceOnly, fieldGreaterThanOrEqualZero } = useFormValidation();

  const [addEquipmentType] = useAddEquipmentTypeMutation({
    onCompleted: ({ equipmentType: { label } }) => {
      showMessage({
        type: 'success',
        message: t('modal.equipment.type.add.success', { label }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const [deleteEquipmentType] = useDeleteEquipmentTypeMutation({
    onCompleted: ({ deleteOneEquipmentType: { label } }) => {
      showMessage({
        type: 'success',
        message: t('modal.equipment.type.delete.success', { label }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const [updateEquipmentType] = useUpdateEquipmentTypeMutation({
    onCompleted: ({ updateOneEquipmentType: { label } }) => {
      showMessage({
        type: 'success',
        message: t('modal.equipment.type.update.success', { label }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onSubmit = (fields: EquipmentTypeCreateType) => {
    if (modal?.equipmentType) {
      const updated = stripUnchangedFields(defaultValues, fields);
      void updateEquipmentType({
        variables: {
          id: modal.equipmentType.id,
          updatedType: {
            ...updated,
            velocity: updated?.velocity ? Number(updated.velocity) : undefined,
            verticalVelocity: updated?.verticalVelocity
              ? Number(updated.verticalVelocity)
              : undefined,
            weightMax: updated?.weightMax ? Number(updated.weightMax) : undefined,
            cost: updated?.cost ? Number(updated.cost) : undefined,
            volumeMax: updated?.volumeMax ? Number(updated.volumeMax) : undefined,
            heightMax: updated?.heightMax ? Number(updated.heightMax) : undefined,
            heightMin: updated?.heightMin ? Number(updated.heightMin) : undefined,
          },
        },
      });
    } else {
      void addEquipmentType({
        variables: {
          newType: {
            ...fields,
            cost: Number(fields.cost),
            velocity: Number(fields.velocity),
            verticalVelocity: Number(fields.verticalVelocity),
            weightMax: Number(fields.weightMax),
            volumeMax: Number(fields.volumeMax),
            heightMax: Number(fields.heightMax),
            heightMin: Number(fields.heightMin),
          },
        },
      });
    }
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <Grid container minWidth="600px" spacing={6}>
          <Grid item xs={6}>
            <Controller
              name="code"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.code'),
                }),
                validate: {
                  notWhiteSpaceOnly,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    ref={null}
                    autoFocus
                    required
                    id="code"
                    fullWidth
                    label={t('common.code')}
                    variant="outlined"
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={(e) => field.onChange(uppercase(e))}
                    dataTestId="code"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="label"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.label'),
                }),
                validate: {
                  notWhiteSpaceOnly,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    ref={null}
                    required
                    id="label"
                    fullWidth
                    label={t('common.label')}
                    variant="outlined"
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="label"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    ref={null}
                    id="description"
                    fullWidth
                    label={t('common.description')}
                    variant="outlined"
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="description"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="mobility"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.mobility'),
                }),
                validate: {
                  notWhiteSpaceOnly,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    fullWidth
                    required
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="equipment-mobility"
                    label={t('common.mobility')}
                    {...field}
                    ref={null}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="equipment-mobility"
                  >
                    <option disabled />
                    {enumKeys(EquipmentMobility).map((mobility) => {
                      return (
                        <option
                          key={`equipmentMobility-${mobility}`}
                          value={EquipmentMobility[mobility]}
                        >
                          {t(`common.${EquipmentMobility[mobility]}`)}
                        </option>
                      );
                    })}
                  </TextField>
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="cost"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.cost'),
                }),
                validate: {
                  fieldGreaterThanOrEqualZero,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    ref={null}
                    required
                    id="description"
                    type="number"
                    fullWidth
                    label={t('common.cost')}
                    variant="outlined"
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="equipment-cost"
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="currency"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.unitOfMeasureAbbrSuffix', {
                    prefix: t('common.currency'),
                  }),
                }),
                validate: {
                  notWhiteSpaceOnly,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <CurrencyInput
                    disabled={true}
                    field={field}
                    fieldState={fieldState}
                    id="equipment-currency"
                    dataTestId="equipment-currency"
                    label={t('common.currency')}
                    required
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="weightMax"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.weightMax'),
                }),
                validate: {
                  fieldGreaterThanOrEqualZero,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    ref={null}
                    required
                    id="description"
                    type="number"
                    fullWidth
                    label={t('common.weightMax')}
                    variant="outlined"
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="equipment-weightMax"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="weightUOMId"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.unitOfMeasureAbbrSuffix', {
                    prefix: t('common.weight'),
                  }),
                }),
                validate: {
                  notWhiteSpaceOnly,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <WeightUomDropdownDb
                    field={field}
                    fieldState={fieldState}
                    warehouseId={selectedWarehouseId}
                    dataTestId="equipment-weight-uom"
                    label={t('common.unitOfMeasureAbbrSuffix', {
                      prefix: t('common.weight'),
                    })}
                    required
                  />
                );
              }}
            />
            {/* <Controller
              name="heightUOMId"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.unitOfMeasureAbbrSuffix', {
                    prefix: t('common.height'),
                  }),
                }),
                validate: {
                  notWhiteSpaceOnly,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <DistanceUomDropdownDb
                    field={field}
                    fieldState={fieldState}
                    warehouseId={selectedWarehouseId}
                    dataTestId="equipment-height-uom"
                    label={t('common.unitOfMeasureAbbrSuffix', {
                      prefix: t('common.height'),
                    })}
                    required
                  />
                );
              }}
            /> */}
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="heightMax"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.height'),
                }),
                validate: {
                  fieldGreaterThanOrEqualZero,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    ref={null}
                    required
                    id="heightMax"
                    type="number"
                    fullWidth
                    label={t('common.heightMax')}
                    variant="outlined"
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="equipment-heightMax"
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="heightUOMId"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.unitOfMeasureAbbrSuffix', {
                    prefix: t('common.height'),
                  }),
                }),
                validate: {
                  notWhiteSpaceOnly,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <DistanceUomDropdownDb
                    field={field}
                    fieldState={fieldState}
                    warehouseId={selectedWarehouseId}
                    dataTestId="equipment-height-uom"
                    label={t('common.unitOfMeasureAbbrSuffix', {
                      prefix: t('common.height'),
                    })}
                    required
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name="heightMin"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.height'),
                }),
                validate: {
                  fieldGreaterThanOrEqualZero,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    ref={null}
                    required
                    id="heightMin"
                    type="number"
                    fullWidth
                    label={t('common.heightMin')}
                    variant="outlined"
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="equipment-heightMin"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <Controller
              name="volumeMax"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.volume'),
                }),
                validate: {
                  fieldGreaterThanOrEqualZero,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    ref={null}
                    required
                    id="volumeMax"
                    type="number"
                    fullWidth
                    label={t('common.volumeMax')}
                    variant="outlined"
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="equipment-volumeMax"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="volumeUOMId"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.unitOfMeasureAbbrSuffix', {
                    prefix: t('common.volume'),
                  }),
                }),
                validate: {
                  notWhiteSpaceOnly,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <VolumeUomDropdownDb
                    field={field}
                    fieldState={fieldState}
                    warehouseId={selectedWarehouseId}
                    dataTestId="equipment-volume-uom"
                    label={t('common.unitOfMeasureAbbrSuffix', {
                      prefix: t('common.volume'),
                    })}
                    required
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="velocity"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.volume'),
                }),
                validate: {
                  fieldGreaterThanOrEqualZero,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    ref={null}
                    required
                    id="velocity"
                    type="number"
                    fullWidth
                    label={t('common.velocity')}
                    variant="outlined"
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="equipment-velocity"
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="velocityUOMId"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.unitOfMeasureAbbrSuffix', {
                    prefix: t('common.velocity'),
                  }),
                }),
                validate: {
                  notWhiteSpaceOnly,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <VelocityUomDropdownDb
                    field={field}
                    fieldState={fieldState}
                    dataTestId="equipment-velocity-uom"
                    warehouseId={selectedWarehouseId}
                    label={t('common.unitOfMeasureAbbrSuffix', {
                      prefix: t('common.velocity'),
                    })}
                    required
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="verticalVelocity"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.volume'),
                }),
                validate: {
                  fieldGreaterThanOrEqualZero,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    ref={null}
                    required
                    id="verticalSpeed"
                    type="number"
                    fullWidth
                    label={t('common.verticalVelocity')}
                    variant="outlined"
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="equipment-verticleSpeed"
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => deleteEquipmentType({ variables: { id: modal?.equipmentType?.id } })}
          variant="outlined"
          color="error"
          sx={{ marginRight: 'auto', visibility: modal.equipmentType ? 'visible' : 'hidden' }}
          actionType="delete"
        >
          {t('common.delete')}
        </ModalButton>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          data-testid="addEquipmentType_submit"
          variant="contained"
          color="primary"
          actionType="submit"
        >
          {t('common.save')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default EquipmentTypeModal;
