/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CrudStockStatusFragment = { __typename?: 'StockStatusType', id: string, code: string, label: string };

export type CreateStockStatusTypeMutationVariables = Types.Exact<{
  stockStatusType: Types.StockStatusTypeCreate;
}>;


export type CreateStockStatusTypeMutation = { __typename?: 'Mutation', createOneStockStatusType: { __typename?: 'StockStatusType', id: string, code: string, label: string } };

export type CopyStockStatusTypeMutationVariables = Types.Exact<{
  stockStatusType: Types.StockStatusTypeCopy;
}>;


export type CopyStockStatusTypeMutation = { __typename?: 'Mutation', copyOneStockStatusType: { __typename?: 'StockStatusType', id: string, code: string, label: string } };

export type EditStockStatusTypeMutationVariables = Types.Exact<{
  stockStatusTypeUpdate: Types.StockStatusTypeUpdate;
  id: Types.Scalars['ID']['input'];
}>;


export type EditStockStatusTypeMutation = { __typename?: 'Mutation', updateOneStockStatusType: { __typename?: 'StockStatusType', id: string, code: string, label: string } };

export type DeleteStockStatusTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteStockStatusTypeMutation = { __typename?: 'Mutation', deleteOneStockStatusType: { __typename?: 'StockStatusType', id: string, deletedAt?: string | null } };

export const CrudStockStatusFragmentDoc = gql`
    fragment CrudStockStatus on StockStatusType {
  id
  code
  label
}
    `;
export const CreateStockStatusTypeDocument = gql`
    mutation CreateStockStatusType($stockStatusType: StockStatusTypeCreate!) {
  createOneStockStatusType(input: {stockStatusType: $stockStatusType}) {
    ...CrudStockStatus
  }
}
    ${CrudStockStatusFragmentDoc}`;
export type CreateStockStatusTypeMutationFn = Apollo.MutationFunction<CreateStockStatusTypeMutation, CreateStockStatusTypeMutationVariables>;

/**
 * __useCreateStockStatusTypeMutation__
 *
 * To run a mutation, you first call `useCreateStockStatusTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockStatusTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockStatusTypeMutation, { data, loading, error }] = useCreateStockStatusTypeMutation({
 *   variables: {
 *      stockStatusType: // value for 'stockStatusType'
 *   },
 * });
 */
export function useCreateStockStatusTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateStockStatusTypeMutation, CreateStockStatusTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStockStatusTypeMutation, CreateStockStatusTypeMutationVariables>(CreateStockStatusTypeDocument, options);
      }
export type CreateStockStatusTypeMutationHookResult = ReturnType<typeof useCreateStockStatusTypeMutation>;
export type CreateStockStatusTypeMutationResult = Apollo.MutationResult<CreateStockStatusTypeMutation>;
export type CreateStockStatusTypeMutationOptions = Apollo.BaseMutationOptions<CreateStockStatusTypeMutation, CreateStockStatusTypeMutationVariables>;
export const CopyStockStatusTypeDocument = gql`
    mutation CopyStockStatusType($stockStatusType: StockStatusTypeCopy!) {
  copyOneStockStatusType(input: {copyStockStatus: $stockStatusType}) {
    ...CrudStockStatus
  }
}
    ${CrudStockStatusFragmentDoc}`;
export type CopyStockStatusTypeMutationFn = Apollo.MutationFunction<CopyStockStatusTypeMutation, CopyStockStatusTypeMutationVariables>;

/**
 * __useCopyStockStatusTypeMutation__
 *
 * To run a mutation, you first call `useCopyStockStatusTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyStockStatusTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyStockStatusTypeMutation, { data, loading, error }] = useCopyStockStatusTypeMutation({
 *   variables: {
 *      stockStatusType: // value for 'stockStatusType'
 *   },
 * });
 */
export function useCopyStockStatusTypeMutation(baseOptions?: Apollo.MutationHookOptions<CopyStockStatusTypeMutation, CopyStockStatusTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyStockStatusTypeMutation, CopyStockStatusTypeMutationVariables>(CopyStockStatusTypeDocument, options);
      }
export type CopyStockStatusTypeMutationHookResult = ReturnType<typeof useCopyStockStatusTypeMutation>;
export type CopyStockStatusTypeMutationResult = Apollo.MutationResult<CopyStockStatusTypeMutation>;
export type CopyStockStatusTypeMutationOptions = Apollo.BaseMutationOptions<CopyStockStatusTypeMutation, CopyStockStatusTypeMutationVariables>;
export const EditStockStatusTypeDocument = gql`
    mutation EditStockStatusType($stockStatusTypeUpdate: StockStatusTypeUpdate!, $id: ID!) {
  updateOneStockStatusType(input: {id: $id, update: $stockStatusTypeUpdate}) {
    ...CrudStockStatus
  }
}
    ${CrudStockStatusFragmentDoc}`;
export type EditStockStatusTypeMutationFn = Apollo.MutationFunction<EditStockStatusTypeMutation, EditStockStatusTypeMutationVariables>;

/**
 * __useEditStockStatusTypeMutation__
 *
 * To run a mutation, you first call `useEditStockStatusTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditStockStatusTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editStockStatusTypeMutation, { data, loading, error }] = useEditStockStatusTypeMutation({
 *   variables: {
 *      stockStatusTypeUpdate: // value for 'stockStatusTypeUpdate'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditStockStatusTypeMutation(baseOptions?: Apollo.MutationHookOptions<EditStockStatusTypeMutation, EditStockStatusTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditStockStatusTypeMutation, EditStockStatusTypeMutationVariables>(EditStockStatusTypeDocument, options);
      }
export type EditStockStatusTypeMutationHookResult = ReturnType<typeof useEditStockStatusTypeMutation>;
export type EditStockStatusTypeMutationResult = Apollo.MutationResult<EditStockStatusTypeMutation>;
export type EditStockStatusTypeMutationOptions = Apollo.BaseMutationOptions<EditStockStatusTypeMutation, EditStockStatusTypeMutationVariables>;
export const DeleteStockStatusTypeDocument = gql`
    mutation DeleteStockStatusType($id: ID!) {
  deleteOneStockStatusType(input: {id: $id}) {
    id
    deletedAt
  }
}
    `;
export type DeleteStockStatusTypeMutationFn = Apollo.MutationFunction<DeleteStockStatusTypeMutation, DeleteStockStatusTypeMutationVariables>;

/**
 * __useDeleteStockStatusTypeMutation__
 *
 * To run a mutation, you first call `useDeleteStockStatusTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStockStatusTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStockStatusTypeMutation, { data, loading, error }] = useDeleteStockStatusTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteStockStatusTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStockStatusTypeMutation, DeleteStockStatusTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStockStatusTypeMutation, DeleteStockStatusTypeMutationVariables>(DeleteStockStatusTypeDocument, options);
      }
export type DeleteStockStatusTypeMutationHookResult = ReturnType<typeof useDeleteStockStatusTypeMutation>;
export type DeleteStockStatusTypeMutationResult = Apollo.MutationResult<DeleteStockStatusTypeMutation>;
export type DeleteStockStatusTypeMutationOptions = Apollo.BaseMutationOptions<DeleteStockStatusTypeMutation, DeleteStockStatusTypeMutationVariables>;