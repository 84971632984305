import { useQueryBasedDataTable } from '@components/data-table/context/QueryBasedProvider';
import PaginationControls from '@components/data-table/pagination/controls';
import PaginationDisabledState from '@components/data-table/pagination/disabled-pagination';
import FooterWrapper from '@components/data-table/pagination/FooterWrapper';
import PaginationLastFetched from '@components/data-table/pagination/last-fetched';

const DataTableQueryBasedPagination = () => {
  const {
    tableId,
    pagination: {
      disablePagination,
      limit,
      startIndex,
      endIndex,
      totalCount,
      isLoadingTotalCount,
      perPageOptions,
      setPageLimit,
      canPreviousPage,
      canNextPage,
      goToFirstPage,
      goToPrevPage,
      goToNextPage,
      goToLastPage,
    },
    hasCompletedFirstFetch,
    isDataTableLoading,
    dataLastFetched,
    refetchData,
    refetchTotalCount,
  } = useQueryBasedDataTable();

  return (
    <FooterWrapper data-testid={`data-table-${tableId}-pagination-container`}>
      <PaginationLastFetched
        dataLastFetched={dataLastFetched}
        isDataTableLoading={isDataTableLoading}
        refetchData={refetchData}
        refetchTotalCount={refetchTotalCount}
        dataTestId={`data-table-${tableId}`}
      />
      {!hasCompletedFirstFetch || totalCount === 0 ? null : disablePagination ? (
        <PaginationDisabledState
          tableId={tableId}
          isLoadingTotalCount={isLoadingTotalCount}
          totalCount={totalCount}
        />
      ) : (
        <PaginationControls
          tableId={tableId}
          hasCompletedFirstFetch={hasCompletedFirstFetch}
          perPageOptions={perPageOptions}
          limit={limit}
          setPageLimit={setPageLimit}
          startIndex={startIndex}
          endIndex={endIndex}
          isLoadingTotalCount={isLoadingTotalCount}
          totalCount={totalCount}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          goToFirstPage={goToFirstPage}
          goToLastPage={goToLastPage}
          goToNextPage={goToNextPage}
          goToPrevPage={goToPrevPage}
        />
      )}
    </FooterWrapper>
  );
};

export default DataTableQueryBasedPagination;
