/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UnassignDoorFromDeliveryMutationVariables = Types.Exact<{
  deliveryId: Types.Scalars['ID']['input'];
}>;


export type UnassignDoorFromDeliveryMutation = { __typename?: 'Mutation', updateOneDelivery: { __typename?: 'Delivery', id: string, erpCode: string, door?: { __typename?: 'Door', id: string, code?: string | null } | null } };


export const UnassignDoorFromDeliveryDocument = gql`
    mutation UnassignDoorFromDelivery($deliveryId: ID!) {
  updateOneDelivery(input: {id: $deliveryId, update: {doorId: null}}) {
    id
    erpCode
    door {
      id
      code
    }
  }
}
    `;
export type UnassignDoorFromDeliveryMutationFn = Apollo.MutationFunction<UnassignDoorFromDeliveryMutation, UnassignDoorFromDeliveryMutationVariables>;

/**
 * __useUnassignDoorFromDeliveryMutation__
 *
 * To run a mutation, you first call `useUnassignDoorFromDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignDoorFromDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignDoorFromDeliveryMutation, { data, loading, error }] = useUnassignDoorFromDeliveryMutation({
 *   variables: {
 *      deliveryId: // value for 'deliveryId'
 *   },
 * });
 */
export function useUnassignDoorFromDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<UnassignDoorFromDeliveryMutation, UnassignDoorFromDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignDoorFromDeliveryMutation, UnassignDoorFromDeliveryMutationVariables>(UnassignDoorFromDeliveryDocument, options);
      }
export type UnassignDoorFromDeliveryMutationHookResult = ReturnType<typeof useUnassignDoorFromDeliveryMutation>;
export type UnassignDoorFromDeliveryMutationResult = Apollo.MutationResult<UnassignDoorFromDeliveryMutation>;
export type UnassignDoorFromDeliveryMutationOptions = Apollo.BaseMutationOptions<UnassignDoorFromDeliveryMutation, UnassignDoorFromDeliveryMutationVariables>;