import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useApproveDenyDispositionMutation } from '@/graphql/defs/pages/__generated__/license-plate.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { IDispositionDenyModal } from '@models/modal';

const DispositionDenyModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, setLoading } = useModalContent<IDispositionDenyModal>();
  const { showMessage } = useSnackbar();

  const [approveDisposition] = useApproveDenyDispositionMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t('modal.disposition.deny.success'),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onSubmit = async () => {
    setLoading(true);
    await approveDisposition({
      variables: {
        input: {
          licensePlateId: modal.licensePlate.id,
          dispositionId: modal.licensePlate.dispositionId,
          approval: false,
        },
      },
    });
  };

  return (
    <Box>
      <ModalContent>
        {t('modal.disposition.deny.subtitle', { code: modal.licensePlate.code })}
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          data-testid="createAreaModal_submit"
          variant="contained"
          color="primary"
          actionType="submit"
          onClick={onSubmit}
        >
          {t('common.deny')}
        </ModalButton>
      </ModalActions>
    </Box>
  );
};

export default DispositionDenyModal;
