/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBinStorageTypeMutationVariables = Types.Exact<{
  binStorageType: Types.BinStorageTypeCreateInput;
}>;


export type CreateBinStorageTypeMutation = { __typename?: 'Mutation', createOneBinStorageType: { __typename?: 'BinStorageType', id: string, code: string, label: string } };


export const CreateBinStorageTypeDocument = gql`
    mutation CreateBinStorageType($binStorageType: BinStorageTypeCreateInput!) {
  createOneBinStorageType(input: {binStorageType: $binStorageType}) {
    id
    code
    label
  }
}
    `;
export type CreateBinStorageTypeMutationFn = Apollo.MutationFunction<CreateBinStorageTypeMutation, CreateBinStorageTypeMutationVariables>;

/**
 * __useCreateBinStorageTypeMutation__
 *
 * To run a mutation, you first call `useCreateBinStorageTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBinStorageTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBinStorageTypeMutation, { data, loading, error }] = useCreateBinStorageTypeMutation({
 *   variables: {
 *      binStorageType: // value for 'binStorageType'
 *   },
 * });
 */
export function useCreateBinStorageTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateBinStorageTypeMutation, CreateBinStorageTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBinStorageTypeMutation, CreateBinStorageTypeMutationVariables>(CreateBinStorageTypeDocument, options);
      }
export type CreateBinStorageTypeMutationHookResult = ReturnType<typeof useCreateBinStorageTypeMutation>;
export type CreateBinStorageTypeMutationResult = Apollo.MutationResult<CreateBinStorageTypeMutation>;
export type CreateBinStorageTypeMutationOptions = Apollo.BaseMutationOptions<CreateBinStorageTypeMutation, CreateBinStorageTypeMutationVariables>;