import { Paper, styled } from '@mui/material';

const DataTableTableContainer = styled(Paper)(({ theme }) => ({
  position: 'relative',
  maxWidth: '100%',
  overflow: 'hidden',
  backgroundColor: theme.palette.common.white,
}));

export default DataTableTableContainer;
