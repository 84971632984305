import { MenuItem as MUIMenuItem, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

type TVariants = 'actionMenu';
type TIconColor = ((theme: Theme) => string) | string;

interface IStyledMenuItem {
  styledVariant?: TVariants;
  isDisabled?: boolean;
  iconColor?: TIconColor;
}

const styledVariants = (
  theme: Theme,
  isDisabled: boolean,
  iconColor: TIconColor = '#3E789C',
): Record<TVariants, Record<string, any>> => ({
  actionMenu: {
    '&': {
      borderBottom: '1px solid #D7D7D7',
    },
    '&:last-of-type': {
      borderBottom: 'none',
    },
    '& svg': {
      color: isDisabled
        ? 'rgba(0, 0, 0, 0.26)'
        : typeof iconColor === 'string'
        ? iconColor
        : iconColor(theme),
    },
    '& span': {
      fontWeight: 700,
      color: isDisabled ? 'rgba(0, 0, 0, 0.26)' : '#4A4A4A',
    },
    '&:hover': {
      background: isDisabled ? 'none' : 'rgba(0, 0, 0, 0.04)',
    },
  },
});

const MenuItem = styled(MUIMenuItem, {
  shouldForwardProp: (prop) =>
    !['styledVariant', 'isDisabled', 'iconColor'].includes(prop as string),
})<IStyledMenuItem>(
  ({ theme, styledVariant, isDisabled, iconColor }) =>
    styledVariants(theme, isDisabled, iconColor)[styledVariant] || {},
);

export default MenuItem;
