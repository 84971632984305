import { Grid } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { GetWarehouseDropdownDocument } from '@/graphql/defs/components/__generated__/warehouse-dropdown.generated';
import { useUpdateWarehouseDetailsMutation } from '@/graphql/defs/components/modals/__generated__/warehouse-details-modal.generated';
import { ModelWarehouseType, WarehouseUpdateInput } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import { FieldBlockWarehouseDetails } from '@components/modal/warehouse/form';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { stripUnchangedFields } from '@lib/form';
import FormValues from '@models/FormValues';
import { IWarehouseDetailsModal } from '@models/modal';

const WarehouseDetailsModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, setLoading } = useModalContent<IWarehouseDetailsModal>();
  const { showMessage } = useSnackbar();
  const { selectedOrganizationId } = useEntityUtils();

  const defaultValues: FormValues<WarehouseUpdateInput> = {
    code: modal.details?.code || '',
    name: modal.details?.name || '',
    companyId: modal.details?.companyId || '',
    type: modal.details?.type || ModelWarehouseType.Default,
  };
  const formMethods = useForm<FormValues<WarehouseUpdateInput>>({
    defaultValues,
  });
  const { control, handleSubmit } = formMethods;

  const [updateWarehouse] = useUpdateWarehouseDetailsMutation({
    onCompleted: ({ updateOneWarehouse: { name } }) => {
      showMessage({
        type: 'success',
        message: t('modal.warehouse.details.success', { name }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const onUpdate = (fields: WarehouseUpdateInput) => {
    // We don't allow updating the modelType at this point in time, but we want to show the user in this form the value.
    // Remove it from both of the objects so it isn't included in the request.
    const defaultValuesNoModelType = { ...defaultValues };
    const fieldsNoModelType = { ...fields };
    delete defaultValuesNoModelType.type;
    delete fieldsNoModelType.type;

    void updateWarehouse({
      variables: {
        id: modal.details?.id,
        updatedWarehouse: stripUnchangedFields(defaultValuesNoModelType, fieldsNoModelType),
      },
      refetchQueries: [
        { query: GetWarehouseDropdownDocument, variables: { orgId: selectedOrganizationId } },
      ],
    });
  };

  return (
    <ModalForm onSubmit={handleSubmit(onUpdate)} formReturn={formMethods}>
      <ModalContent>
        <Grid container spacing={6}>
          <FieldBlockWarehouseDetails control={control} isUpdate={true} focusOnLoad />
        </Grid>
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" actionType="submit">
          {t('common.submit')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default WarehouseDetailsModal;
