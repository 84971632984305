import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useOutboundFulfillmentItemsTableLazyQuery } from '@/graphql/defs/hooks/shared-columns/__generated__/useCreateOutboundFulfillmentItemColumns.generated';
import { DeliveryCompletionStatus } from '@/graphql/types.generated';
import { ColumnTypes } from '@components/data-table/controls/filter/filter-definitions';
import { TColumnFactory } from '@components/data-table/hooks/useCreateDataTableColumns';
import useDataTableEnumList from '@components/data-table/hooks/useDataTableEnumLists';
import filterDataTableColumnDefs from '@components/data-table/lib/filterDataTableColumnDefs';
import {
  TExtractLazyHookDataType,
  TExtractLazyHookFetchFilterType,
  TExtractLazyHookFieldNames,
} from '@components/data-table/model/extract-query-hook-types';
import LinkCell from '@components/data-table/table/cells/LinkCell';
import ProgressBar from '@components/progress-bar';
import QuantityConversionsTooltip from '@components/tooltips/quantity-conversions';
import { LICENSE_PLATE, LOT, OUTBOUND_DELIVERY, PRODUCT } from '@constants/routes';
import { useDateTime } from '@context/date-time';
import { useWarehouseFeatureFlags } from '@context/warehouse-feature-flags';
import enumKeys from '@lib/enum-keys';

export const FULFILLMENT_ITEMS_HOOK = useOutboundFulfillmentItemsTableLazyQuery;
export type TFulfillmentItemsDataType = TExtractLazyHookDataType<typeof FULFILLMENT_ITEMS_HOOK>;
export type TFulfillmentItemsFilterType = TExtractLazyHookFetchFilterType<
  typeof FULFILLMENT_ITEMS_HOOK
>;
export type TFulfillmentItemsFieldNames = TExtractLazyHookFieldNames<typeof FULFILLMENT_ITEMS_HOOK>;

interface IUseCreateOutboundFulfillmentItemColumns {
  dataTestId: string;
  removeColumns?: (keyof TFulfillmentItemsDataType)[];
}
const useCreateOutboundFulfillmentItemColumns = ({
  dataTestId,
  removeColumns = [],
}: IUseCreateOutboundFulfillmentItemColumns) => {
  const { t } = useTranslation('pages');
  const { t: tC } = useTranslation('components');
  const { displayDate, displayDateTime } = useDateTime();
  const { warehouseFeatureFlags: featureFlags } = useWarehouseFeatureFlags();
  const { deliveries: deliveryFlags } = featureFlags;

  const { unitOfMeasureEnumList } = useDataTableEnumList({
    fetchUoMList: true,
  });

  const createInboundDeliveryItemColumns = useCallback<TColumnFactory<TFulfillmentItemsDataType>>(
    (columnHelper) => {
      const columns = [
        columnHelper.accessor('fulfillmentCode', {
          header: t('deliveries.fulfillmentCodeAbbr'),
          cell: ({ row, getValue }) => (
            <LinkCell
              href={`${OUTBOUND_DELIVERY}/${row.original.deliveryId}`}
              text={getValue()}
              dataTestId={`${dataTestId}-fulfillment-link`}
            />
          ),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('fulfillmentDueDate', {
          header: t('deliveries.columns.dueDate'),
          cell: ({ getValue }) => displayDate({ date: getValue() }),
          meta: {
            columnType: ColumnTypes.date,
            exportFormatter: (value) => displayDate({ date: value }),
          },
        }),
        columnHelper.accessor('item', {
          header: t('deliveries.item'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('licensePlateCode', {
          header: tC('common.licensePlate'),
          cell: ({ row, getValue }) => (
            <LinkCell
              href={`${LICENSE_PLATE}/${row.original.licensePlateId}`}
              text={getValue()}
              dataTestId={`${dataTestId}-lp-link`}
            />
          ),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('licensePlateDescription', {
          header: tC('common.licensePlateDescription'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('productCode', {
          header: t('deliveries.columns.productCode'),
          cell: ({ row, getValue }) => (
            <LinkCell
              href={`${PRODUCT}/${row.original.productId}`}
              text={getValue()}
              dataTestId={`${dataTestId}-product-link`}
            />
          ),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('productDescription', {
          header: tC('common.productDescription'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('lotCode', {
          header: t('deliveries.columns.lotCode'),
          cell: ({ row, getValue }) => (
            <LinkCell
              href={`${LOT}/${row.original.lotId}`}
              text={getValue()}
              dataTestId={`${dataTestId}-lot-link`}
            />
          ),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('dateAvailable', {
          header: t('deliveries.dateAvailableAbbr'),
          cell: ({ getValue }) => displayDate({ date: getValue() }),
          meta: {
            columnType: ColumnTypes.date,
            exportFormatter: (value) => displayDate({ date: value }),
          },
        }),
        featureFlags.deliveries.showAvailability
          ? columnHelper.accessor('availability', {
              header: t('deliveries.columns.availability'),
              cell: ({ getValue }) => <ProgressBar percent={getValue()} />,
              meta: {
                columnType: ColumnTypes.number,
                exportFormatter: (value) => `${value}%`,
              },
            })
          : null,
        columnHelper.accessor('quantity', {
          header: t('deliveries.quantityAbbr'),
          cell: ({ row, getValue }) => (
            <QuantityConversionsTooltip
              quantity={getValue()}
              unitOfMeasureId={row.original.unitOfMeasureId}
              anchor={getValue()}
            />
          ),
          meta: {
            columnType: ColumnTypes.number,
          },
        }),
        columnHelper.accessor('unitOfMeasure', {
          header: tC('common.unitOfMeasureAbbr'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.enum,
            options: unitOfMeasureEnumList,
          },
        }),
        deliveryFlags.showLoadUnload
          ? columnHelper.accessor('loadStatus', {
              header: t('deliveries.loadStatus'),
              cell: ({ getValue }) => (getValue() ? tC(`tasks.status.${getValue()}`) : ''),
              meta: {
                columnType: ColumnTypes.enum,
                options: enumKeys(DeliveryCompletionStatus).map((status) => ({
                  value: DeliveryCompletionStatus[status],
                  display: tC(`tasks.status.${DeliveryCompletionStatus[status]}`),
                })),
                exportFormatter: (value) => (value ? tC(`tasks.status.${value}`) : ''),
              },
            })
          : null,
        columnHelper.accessor('pickStatus', {
          header: t('deliveries.pickStatus'),
          cell: ({ getValue }) => (getValue() ? tC(`tasks.status.${getValue()}`) : ''),
          meta: {
            columnType: ColumnTypes.enum,
            options: enumKeys(DeliveryCompletionStatus).map((status) => ({
              value: DeliveryCompletionStatus[status],
              display: tC(`tasks.status.${DeliveryCompletionStatus[status]}`),
            })),
            exportFormatter: (value) => (value ? tC(`tasks.status.${value}`) : ''),
          },
        }),
        columnHelper.accessor('erpLastChanged', {
          header: t('deliveries.updatedERP'),
          cell: ({ getValue }) => displayDateTime({ date: getValue() }),
          meta: {
            columnType: ColumnTypes.dateTime,
            exportFormatter: (value) => displayDateTime({ date: value }),
          },
        }),
        columnHelper.accessor('updatedAt', {
          header: t('deliveries.updatedFF'),
          cell: ({ getValue }) => displayDateTime({ date: getValue() }),
          meta: {
            columnType: ColumnTypes.dateTime,
            exportFormatter: (value) => displayDateTime({ date: value }),
          },
        }),
        columnHelper.accessor('salesOrderCode', {
          header: t('deliveries.shippingOrderCodeAbbr'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('salesOrderItem', {
          header: t('deliveries.shippingOrderItemAbbr'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('volume', {
          header: tC('common.volume'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.number,
          },
        }),
        columnHelper.accessor('volumeUOMCode', {
          header: tC('common.unitOfMeasureAbbrSuffix', { prefix: tC('common.volume') }),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.enum,
            options: unitOfMeasureEnumList,
          },
        }),
        columnHelper.accessor('grossWeight', {
          header: t('deliveries.grossWeightAbbr'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.number,
          },
        }),
        columnHelper.accessor('weightUOMCode', {
          header: tC('common.unitOfMeasureAbbrSuffix', { prefix: t('deliveries.grossWeightAbbr') }),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.enum,
            options: unitOfMeasureEnumList,
          },
        }),
        columnHelper.accessor('netWeight', {
          header: t('deliveries.netWeightAbbr'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.number,
          },
        }),
        columnHelper.accessor('weightUOMCode', {
          header: tC('common.unitOfMeasureAbbrSuffix', { prefix: t('deliveries.netWeightAbbr') }),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.enum,
            options: unitOfMeasureEnumList,
          },
        }),
      ];

      return columns.filter(filterDataTableColumnDefs<TFulfillmentItemsDataType>(removeColumns));
    },
    [displayDate, displayDateTime, deliveryFlags, ...removeColumns],
  );

  return createInboundDeliveryItemColumns;
};

export default useCreateOutboundFulfillmentItemColumns;
