import {
  Autocomplete,
  FilledInputProps,
  FormControl,
  InputLabel,
  InputProps,
  MenuItem,
  OutlinedInputProps,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ColumnFilter,
  ColumnFilterBetweenValue,
  ColumnFilterEnumOption,
  ColumnFilterValues,
  ColumnFilterDefinition,
  ColumnTypes,
  FilterOperators,
  getDefaultValue,
} from './filter-definitions';

import i18n from '@/i18n';
import useDateFilterAdjustment from '@components/data-table/hooks/useDateFilterAdjustment';
import { DatePicker } from '@components/date-picker';
import DateRangePicker from '@components/date-range-picker';
import DateTimePicker from '@components/date-time-picker';
import DateTimeRangePicker from '@components/date-time-range-picker';
import TextField from '@components/text-field';
import { allowOnlyNumeric, formatAsInteger } from '@lib/form/transformers';

type CustomLabelKeys =
  | 'AnyInput'
  | 'TextInput'
  | 'BooleanInput'
  | 'NumberInput'
  | 'EnumInput'
  | 'DateInput'
  | 'DateTimeInput';

interface CustomLabelProperties {
  single: string;
  start?: string;
  end?: string;
}

const DEFAULT_LABELS: Record<CustomLabelKeys, CustomLabelProperties> = {
  AnyInput: {
    single: i18n.t('filter.quickFilter.anySingleLabel', { ns: 'components' }),
  },
  BooleanInput: {
    single: i18n.t('filter.quickFilter.booleanSingleLabel', { ns: 'components' }),
  },
  DateInput: {
    single: i18n.t('filter.quickFilter.dateSingleLabel', { ns: 'components' }),
    start: i18n.t('filter.quickFilter.dateStartLabel', { ns: 'components' }),
    end: i18n.t('filter.quickFilter.dateEndLabel', { ns: 'components' }),
  },
  DateTimeInput: {
    single: i18n.t('filter.quickFilter.dateTimeSingleLabel', { ns: 'components' }),
    start: i18n.t('filter.quickFilter.dateTimeStartLabel', { ns: 'components' }),
    end: i18n.t('filter.quickFilter.dateTimeEndLabel', { ns: 'components' }),
  },
  EnumInput: {
    single: i18n.t('filter.quickFilter.enumSingleLabel', { ns: 'components' }),
  },
  NumberInput: {
    single: i18n.t('filter.quickFilter.numberSingleLabel', { ns: 'components' }),
  },
  TextInput: {
    single: i18n.t('filter.quickFilter.textSingleLabel', { ns: 'components' }),
  },
};

interface InputFieldProps {
  inputValue: ColumnFilterValues;
  setValue: (value: ColumnFilterValues) => void;
  dataTestId: string;
  operator?: FilterOperators;
  columnId?: string;
  columnType?: ColumnTypes;
  options?: ColumnFilterEnumOption[];
  customLabels?: Record<CustomLabelKeys, CustomLabelProperties>;
  inputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>;
  booleanLabels?: string[];
}

export const FilterInput = ({
  input: { operator, value },
  columnDef,
  setValue,
  customLabels = DEFAULT_LABELS,
  inputProps,
  dataTestId,
}: {
  input: ColumnFilter;
  columnDef: ColumnFilterDefinition;
  setValue: (value: ColumnFilterValues) => void;
  customLabels?: Record<CustomLabelKeys, CustomLabelProperties>;
  inputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>;
  dataTestId: string;
}) => {
  const columnType = columnDef?.columnType || ColumnTypes.any;
  const options = columnDef?.options || [];

  switch (columnType) {
    case ColumnTypes.any:
      return (
        <AnyInput
          inputValue={value}
          setValue={setValue}
          options={options}
          customLabels={customLabels}
          inputProps={inputProps}
          dataTestId={`${dataTestId}-any-input`}
        />
      );
    case ColumnTypes.string:
    case ColumnTypes.stringRange:
      return (
        <TextInput
          columnType={columnType}
          inputValue={value}
          setValue={setValue}
          operator={operator}
          customLabels={customLabels}
          inputProps={inputProps}
          dataTestId={`${dataTestId}-text-input`}
        />
      );
    case ColumnTypes.boolean:
      return (
        <BooleanInput
          booleanLabels={columnDef?.booleanLabels}
          inputValue={value}
          setValue={setValue}
          operator={operator}
          customLabels={customLabels}
          inputProps={inputProps}
          dataTestId={`${dataTestId}-boolean-input`}
        />
      );
    case ColumnTypes.number:
      return (
        <NumberInput
          inputValue={value}
          setValue={setValue}
          operator={operator}
          customLabels={customLabels}
          inputProps={inputProps}
          dataTestId={`${dataTestId}-number-input`}
        />
      );
    case ColumnTypes.enum:
      return (
        <EnumInput
          inputValue={value}
          setValue={setValue}
          options={options}
          operator={operator}
          customLabels={customLabels}
          inputProps={inputProps}
          dataTestId={`${dataTestId}-enum-input`}
        />
      );
    case ColumnTypes.date:
      return (
        <DateInput
          inputValue={value}
          setValue={setValue}
          operator={operator}
          customLabels={customLabels}
          inputProps={inputProps}
          dataTestId={`${dataTestId}-date-input`}
        />
      );
    case ColumnTypes.dateTime:
      return (
        <DateTimeInput
          inputValue={value}
          setValue={setValue}
          operator={operator}
          customLabels={customLabels}
          inputProps={inputProps}
          dataTestId={`${dataTestId}-date-time-input`}
        />
      );
    default:
      return null;
  }
};

const AnyInput = ({
  inputValue,
  setValue,
  options,
  customLabels,
  inputProps,
  dataTestId,
}: InputFieldProps) => {
  const { t } = useTranslation('components', { keyPrefix: 'filter' });

  const getOptionLabelWithFreeSolo = (option: string | ColumnFilterEnumOption) => {
    if (typeof option !== 'string') return option.display;
    const optionMatch = options.find((o) => o.value === option);
    return !!optionMatch ? optionMatch.display : option;
  };

  const isOptionEqualToValueWithFreeSolo = (
    option: string | ColumnFilterEnumOption,
    value: string | ColumnFilterEnumOption,
  ) => {
    if (typeof option === 'string' && typeof value === 'string') return option === value;
    if (typeof option !== 'string' && typeof value === 'string') return option.value === value;
    if (typeof option === 'string' && typeof value !== 'string') return option === value.value;
    if (typeof option !== 'string' && typeof value !== 'string')
      return option.value === value.value;
  };

  return (
    <Autocomplete
      data-testid={`${dataTestId}-dropdown`}
      disabled={inputProps?.disabled || false}
      freeSolo={true}
      autoSelect={true}
      multiple={false}
      autoHighlight={false}
      getOptionLabel={getOptionLabelWithFreeSolo}
      options={options || []}
      isOptionEqualToValue={isOptionEqualToValueWithFreeSolo}
      onChange={(event, item, reason) => {
        if (['createOption', 'selectOption'].includes(reason)) {
          if (typeof item === 'string') {
            const enumMatch = options.find((opt) => opt.display === item);
            setValue(enumMatch ? enumMatch.value : item);
          } else {
            setValue(item.value);
          }
        } else if (reason === 'clear') {
          setValue('');
        }
      }}
      value={inputValue as string}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            dataTestId={`${dataTestId}-input`}
            label={customLabels.AnyInput.single || t('quickFilter.anySingleLabel')}
          />
        );
      }}
      sx={{
        width: '250px',
      }}
    />
  );
};

const TextInput = ({
  columnType,
  inputValue,
  setValue,
  operator,
  customLabels,
  inputProps,
  dataTestId,
}: InputFieldProps) => {
  const { t } = useTranslation('components', { keyPrefix: 'filter' });

  useEffect(() => {
    const DefaultValue = getDefaultValue(columnType, operator);
    if (
      [FilterOperators.between, FilterOperators.notBetween].includes(operator) &&
      !(inputValue instanceof Object)
    ) {
      setValue({
        lower: inputValue || (DefaultValue as { lower: any })?.lower,
        upper: inputValue || (DefaultValue as { upper: any })?.upper,
      });
    } else if (
      ![FilterOperators.between, FilterOperators.notBetween].includes(operator) &&
      inputValue instanceof Object
    ) {
      const typedInputValue = inputValue as ColumnFilterBetweenValue;
      setValue(typedInputValue?.lower || DefaultValue);
    }
  }, [columnType, operator]);

  if ([FilterOperators.between, FilterOperators.notBetween].includes(operator)) {
    const typedInputValue = inputValue as ColumnFilterBetweenValue;
    return (
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <TextField
          disabled={inputProps?.disabled || false}
          value={typedInputValue?.lower}
          label={customLabels?.TextInput?.start || t('value')}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            if (!Number.isNaN(value)) {
              setValue({ ...typedInputValue, lower: value });
            }
          }}
          dataTestId={`${dataTestId}-lower`}
        />
        <Box sx={{ padding: '10px' }}>{t('and')}</Box>
        <TextField
          disabled={inputProps?.disabled || false}
          value={typedInputValue?.upper}
          label={customLabels?.TextInput?.end || t('value')}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            if (!Number.isNaN(value)) {
              setValue({ ...typedInputValue, upper: value });
            }
          }}
          dataTestId={`${dataTestId}-upper`}
        />
      </Box>
    );
  } else if ([FilterOperators.isEmpty, FilterOperators.isNotEmpty].includes(operator)) {
    return null;
  }

  return (
    <TextField
      disabled={inputProps?.disabled || false}
      id="outlined-name"
      label={customLabels?.TextInput?.single || t('value')}
      value={inputValue}
      onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setValue(value)}
      dataTestId={dataTestId}
    />
  );
};

const BooleanInput = ({
  inputValue,
  operator,
  setValue,
  customLabels,
  inputProps,
  dataTestId,
  booleanLabels,
}: InputFieldProps) => {
  const { t } = useTranslation('components', { keyPrefix: 'filter' });

  if ([FilterOperators.isEmpty, FilterOperators.isNotEmpty].includes(operator)) {
    return null;
  }
  return (
    <FormControl>
      <InputLabel id="boolean-select-label">
        {customLabels?.BooleanInput?.single || t('value')}
      </InputLabel>
      <Select
        disabled={inputProps?.disabled || false}
        labelId="boolean-select-label"
        id="boolean-select"
        value={inputValue === null ? 'null' : inputValue.toString()}
        label={customLabels?.BooleanInput?.single || t('value')}
        onChange={({ target: { value } }: SelectChangeEvent) => {
          switch (value) {
            case 'true':
              setValue(true);
              break;
            case 'false':
              setValue(false);
              break;
          }
        }}
        data-testid={dataTestId}
        sx={{ width: '150px' }}
      >
        <MenuItem key="true" value={'true'}>
          {booleanLabels?.[1] || t('true')}
        </MenuItem>
        <MenuItem key="false" value={'false'}>
          {booleanLabels?.[0] || t('false')}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

const EnumInput = ({
  inputValue,
  setValue,
  options,
  operator,
  customLabels,
  inputProps,
  dataTestId,
}: InputFieldProps) => {
  const { t } = useTranslation('components', { keyPrefix: 'filter' });

  useEffect(() => {
    const DefaultValue = getDefaultValue(ColumnTypes.enum, operator);
    if (
      [FilterOperators.in, FilterOperators.notIn].includes(operator) &&
      !Array.isArray(inputValue)
    ) {
      const cleanedInValue = (!!inputValue ? [inputValue] : DefaultValue) as string[];
      setValue(cleanedInValue);
    } else if (
      ![FilterOperators.in, FilterOperators.notIn].includes(operator) &&
      Array.isArray(inputValue)
    ) {
      setValue(!!inputValue[0] ? inputValue[0] : DefaultValue);
    }
  }, [operator]);

  return (
    <FormControl>
      <InputLabel id="enum-select-label">
        {customLabels?.EnumInput?.single || t('value')}
      </InputLabel>
      <Select
        disabled={inputProps?.disabled || false}
        labelId="enum-select-label"
        id="enum-select"
        multiple={[FilterOperators.in, FilterOperators.notIn].includes(operator)}
        value={
          [FilterOperators.in, FilterOperators.notIn].includes(operator)
            ? Array.isArray(inputValue)
              ? inputValue
              : []
            : !Array.isArray(inputValue)
            ? inputValue || ''
            : ''
        }
        label={customLabels?.EnumInput?.single || t('value')}
        onChange={({ target: { value } }: SelectChangeEvent) => setValue(value)}
        data-testid={dataTestId}
        sx={{ width: '250px' }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.display}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const DateInput = ({
  inputValue,
  setValue,
  operator,
  customLabels,
  inputProps,
  dataTestId,
}: InputFieldProps) => {
  const setAdjustedValue = useDateFilterAdjustment(inputValue, operator, setValue);

  if ([FilterOperators.between, FilterOperators.notBetween].includes(operator)) {
    const typedInputValue = inputValue as ColumnFilterBetweenValue;
    return (
      <DateRangePicker
        isDisabled={inputProps?.disabled || false}
        dateRange={[typedInputValue?.lower, typedInputValue?.upper]}
        setDateRange={(dateRange) => {
          setValue({
            lower: dateRange[0],
            upper: dateRange[1],
          });
        }}
        startLabel={customLabels?.DateInput?.start}
        endLabel={customLabels?.DateInput?.end}
        dataTestId={`${dataTestId}-range`}
      />
    );
  }

  if ([FilterOperators.eq, FilterOperators.neq].includes(operator)) {
    const typedInputValue = inputValue as ColumnFilterBetweenValue;
    return (
      <Box>
        <DatePicker
          isDisabled={inputProps?.disabled || false}
          value={typedInputValue?.lower}
          setDate={setAdjustedValue}
          label={customLabels?.DateInput?.single}
          dataTestId={dataTestId}
        />
      </Box>
    );
  }

  return (
    <Box>
      <DatePicker
        isDisabled={inputProps?.disabled || false}
        value={inputValue as any}
        setDate={setAdjustedValue}
        label={customLabels?.DateInput?.single}
        dataTestId={dataTestId}
      />
    </Box>
  );
};

const DateTimeInput = ({
  inputValue,
  setValue,
  operator,
  customLabels,
  inputProps,
  dataTestId,
}: InputFieldProps) => {
  const setAdjustedValue = useDateFilterAdjustment(inputValue, operator, setValue, true);

  if ([FilterOperators.between, FilterOperators.notBetween].includes(operator)) {
    const typedInputValue = inputValue as ColumnFilterBetweenValue;
    return (
      <DateTimeRangePicker
        isDisabled={inputProps?.disabled || false}
        dateTimeRange={[typedInputValue?.lower, typedInputValue?.upper]}
        setDateTimeRange={(dateRange) => {
          setValue({
            lower: dateRange[0],
            upper: dateRange[1],
          });
        }}
        startLabel={customLabels?.DateTimeInput?.start}
        endLabel={customLabels?.DateTimeInput?.end}
        dataTestId={dataTestId}
      />
    );
  }

  return (
    <Box>
      <DateTimePicker
        isDisabled={inputProps?.disabled || false}
        value={inputValue as DateTime}
        setDate={(date) => setAdjustedValue(date)}
        label={customLabels?.DateTimeInput?.single}
        dataTestId={dataTestId}
      />
    </Box>
  );
};

const NumberInput = ({
  inputValue,
  setValue,
  operator,
  customLabels,
  inputProps,
  dataTestId,
}: InputFieldProps) => {
  const { t } = useTranslation('components', { keyPrefix: 'filter' });

  useEffect(() => {
    const DefaultValue = getDefaultValue(ColumnTypes.number, operator);
    if (
      [FilterOperators.between, FilterOperators.notBetween].includes(operator) &&
      !(inputValue instanceof Object)
    ) {
      setValue({
        lower: inputValue || (DefaultValue as { lower: any })?.lower,
        upper: inputValue || (DefaultValue as { upper: any })?.upper,
      });
    } else if (
      ![FilterOperators.between, FilterOperators.notBetween].includes(operator) &&
      inputValue instanceof Object
    ) {
      const typedInputValue = inputValue as ColumnFilterBetweenValue;
      setValue(typedInputValue?.lower || DefaultValue);
    }
  }, [operator]);

  if ([FilterOperators.between, FilterOperators.notBetween].includes(operator)) {
    const typedInputValue = inputValue as ColumnFilterBetweenValue;
    return (
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <TextField
          disabled={inputProps?.disabled || false}
          value={typedInputValue?.lower}
          label={customLabels?.NumberInput?.start || t('value')}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            if (!Number.isNaN(value)) {
              setValue({ ...typedInputValue, lower: value });
            }
          }}
          dataTestId={`${dataTestId}-lower`}
        />
        <Box sx={{ padding: '10px' }}>{t('and')}</Box>
        <TextField
          disabled={inputProps?.disabled || false}
          value={typedInputValue?.upper}
          label={customLabels?.NumberInput?.end || t('value')}
          onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
            if (!Number.isNaN(value)) {
              setValue({ ...typedInputValue, upper: value });
            }
          }}
          dataTestId={`${dataTestId}-upper`}
        />
      </Box>
    );
  } else if ([FilterOperators.isEmpty, FilterOperators.isNotEmpty].includes(operator)) {
    return null;
  }

  return (
    <TextField
      disabled={inputProps?.disabled || false}
      type="number"
      value={formatAsInteger(inputValue as number)}
      label={customLabels?.NumberInput?.single || t('value')}
      onChange={(e) => {
        setValue(
          allowOnlyNumeric(e, false, { allowFloat: true, allowNegatives: true, allowZero: true }),
        );
      }}
      dataTestId={dataTestId}
    />
  );
};
