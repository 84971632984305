import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Autocomplete, Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdateUsersForUserGroupMutation } from '@/graphql/defs/components/modals/__generated__/user-group-users-modal.generated';
import {
  useCreateUserMutation,
  useUpdateUserMutation,
  useUserGroupMappingsByUserIdLazyQuery,
} from '@/graphql/defs/components/modals/__generated__/user-manage-modal.generated';
import {
  UserGroupListItemFragment,
  useListUserGroupsLazyQuery,
} from '@/graphql/defs/list/__generated__/list-user-groups.generated';
import { UserCreateInput, UserGroupMappingStatus, UserStatus } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import FormValues from '@models/FormValues';
import { IUserCreateModal, IUserUpdateModal, ModalTypes } from '@models/modal';

const UserManageModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, setLoading, isPreparing, setPreparing } = useModalContent<
    IUserCreateModal | IUserUpdateModal
  >();

  const [startingGroups, setStartingGroups] = useState<UserGroupListItemFragment[]>([]);
  const defaultValues: FormValues<TUserManageFields> = {
    firstName: (modal.type === ModalTypes.userUpdate && modal.user?.firstName) || '',
    lastName: (modal.type === ModalTypes.userUpdate && modal.user?.lastName) || '',
    email: (modal.type === ModalTypes.userUpdate && modal.user?.email) || '',
    phone: (modal.type === ModalTypes.userUpdate && modal.user?.phone) || '',
    usergroups: startingGroups,
    active: !(modal.type === ModalTypes.userUpdate && modal.user?.status === UserStatus.Inactive),
  };

  const formMethods = useForm<FormValues<TUserManageFields>>({
    defaultValues,
  });
  const { control, handleSubmit } = formMethods;
  const { notWhiteSpaceOnly } = useFormValidation();

  const { showMessage } = useSnackbar();
  const [userGroups, setUserGroups] = useState<UserGroupListItemFragment[]>([]);

  type TUserManageFields = UserCreateInput & {
    role: string;
    usergroups: UserGroupListItemFragment[];
    active: boolean;
  };

  const [getUserGroupsForUser] = useUserGroupMappingsByUserIdLazyQuery();
  const [listUserGroups] = useListUserGroupsLazyQuery();

  const prepare = async () => {
    console.log(modal);

    const [
      {
        data: {
          userGroupMappings: { nodes: usersCurrentGroupMappings },
        },
      },
      {
        data: {
          listUserGroups: { userGroups },
        },
      },
    ] = await Promise.all([
      getUserGroupsForUser({
        variables: {
          userId: modal.type === ModalTypes.userUpdate ? modal?.user?.id : '',
        },
      }),
      listUserGroups(),
    ]);
    setUserGroups(userGroups);

    const processedGroupIds = [];
    const userUserGroups = usersCurrentGroupMappings.reduce((acc, { userGroupId }) => {
      if (!processedGroupIds.includes(userGroupId)) {
        userGroups.forEach((_group) => {
          if (_group.id === userGroupId) acc.push(_group);
        });
      }
      processedGroupIds.push(userGroupId);
      return acc;
    }, [] as UserGroupListItemFragment[]);

    setStartingGroups(userUserGroups);
    formMethods.setValue('usergroups', userUserGroups);
    setPreparing(false);
  };

  useEffect(() => {
    prepare();
  }, []);

  const [createUser] = useCreateUserMutation({
    onCompleted: ({ createOneUser: { firstName, lastName } }) => {
      showMessage({
        type: 'success',
        message: t('modal.users.create.success', { name: `${firstName} ${lastName}` }),
      });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const [updateUser] = useUpdateUserMutation({
    onCompleted: ({ updateOneUser: { firstName, lastName } }) => {
      showMessage({
        type: 'success',
        message: t('modal.users.update.success', { name: `${firstName} ${lastName}` }),
      });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const [updateUserGroupUsers] = useUpdateUsersForUserGroupMutation();

  const updateUserGroupMappings = (
    userId,
    selectedGroups: UserGroupListItemFragment[],
    startingGroups: UserGroupListItemFragment[],
  ) => {
    // collect all mappings that need to be turned on
    const turnOn = selectedGroups.reduce((acc, { id }) => {
      if (!startingGroups.find((group) => group.id === id)) {
        acc.push({
          status: UserGroupMappingStatus.On,
          userGroupId: id,
          userId: userId,
        });
      }
      return acc;
    }, []);

    // add mappings that need to be turned off
    const userGroupMappings = startingGroups.reduce((acc, { id }) => {
      if (!selectedGroups.find((group) => group.id === id)) {
        acc.push({
          status: UserGroupMappingStatus.Off,
          userGroupId: id,
          userId: userId,
        });
      }
      return acc;
    }, turnOn);
    return updateUserGroupUsers({
      variables: {
        input: {
          userGroupMappings,
        },
      },
    });
  };

  const onSubmit = async (fields: TUserManageFields) => {
    if (modal.type === ModalTypes.userCreate) {
      const {
        data: {
          createOneUser: { id: userId },
        },
      } = await createUser({
        variables: {
          newUser: {
            firstName: fields?.firstName,
            lastName: fields?.lastName,
            email: fields?.email,
            phone: fields?.phone,
            status: fields?.active ? UserStatus.Active : UserStatus.Inactive,
          },
        },
      });
      await updateUserGroupMappings(userId, fields.usergroups, startingGroups);
    } else {
      // Due to the structure of `role` vs `roles` and that weirdness, we can't use stripUnchangedFields easily... so im going to wait until this field is blown away with the new permissions changes
      // const updatedFields = Object.entries(fields).reduce((acc, [key,value])=>{
      const updatedFields = {} as Partial<UserCreateInput>;
      if (modal.user.firstName !== fields.firstName) {
        updatedFields.firstName = fields.firstName;
      }
      if (modal.user.lastName !== fields.lastName) {
        updatedFields.lastName = fields.lastName;
      }
      if (modal.user.phone !== fields.phone && fields.phone) {
        updatedFields.phone = fields.phone;
      }
      if (
        fields.active !==
        { [UserStatus.Active]: true, [UserStatus.Inactive]: false }[modal.user.status]
      ) {
        updatedFields.status = fields.active ? UserStatus.Active : UserStatus.Inactive;
      }
      await Promise.all([
        updateUser({
          variables: {
            id: modal?.user?.id,
            updatedUser: updatedFields,
          },
        }),
        updateUserGroupMappings(modal?.user?.id, fields.usergroups, startingGroups),
      ]);
    }

    closeModal({ bypassLoading: true, success: true });
  };
  return isPreparing ? null : (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent sx={{ width: '700px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '10px',
              fontWeight: 600,
              fontSize: '16px',
              marginBottom: '5px',
              color: 'rgba(74, 74, 74, 1)',
            }}
          >
            <InfoOutlinedIcon sx={{ marginRight: '5px', color: 'rgba(74, 74, 74, 1)' }} />{' '}
            {t('modal.users.userInfo')}
          </Box>
          <Box
            sx={{
              border: '1px solid rgba(216, 224, 229, 1)',
              borderRadius: '4px',
              padding: '30px 0px',
              marginBottom: '30px',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-evenly',
            }}
          >
            <Box sx={{ marginBottom: '20px', width: '45%' }}>
              <Controller
                name="firstName"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('user-admin.columns.firstName'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <TextField
                    autoFocus
                    fullWidth
                    required
                    id="user-admin-firstName"
                    label={t('user-admin.columns.firstName')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="user-admin-firstName"
                  />
                )}
              />
            </Box>
            <Box sx={{ marginBottom: '20px', width: '45%' }}>
              <Controller
                name="lastName"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('user-admin.columns.lastName'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    id="user-admin-lastName"
                    label={t('user-admin.columns.lastName')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="user-admin-lastName"
                  />
                )}
              />
            </Box>
            <Box sx={{ marginBottom: '20px', width: '45%' }}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('user-admin.columns.email'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    disabled={modal.type === ModalTypes.userUpdate}
                    fullWidth
                    required
                    id="user-admin-email"
                    label={t('user-admin.columns.email')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="user-admin-email"
                  />
                )}
              />
            </Box>
            <Box sx={{ marginBottom: '20px', width: '45%' }}>
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    id="user-admin-phone"
                    label={t('user-admin.columns.phone')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="user-admin-phone"
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '10px',
              fontWeight: 600,
              fontSize: '16px',
              marginBottom: '5px',
              color: 'rgba(74, 74, 74, 1)',
            }}
          >
            <GroupsOutlinedIcon sx={{ marginRight: '5px', color: 'rgba(74, 74, 74, 1)' }} />{' '}
            {t('modal.users.create.userAssignmentGroups')}
          </Box>
          <Box sx={{ marginBottom: '30px' }}>
            <Controller
              control={control}
              name="usergroups"
              defaultValue={startingGroups}
              render={({ field: { ref, onChange, ...field }, fieldState }) => (
                <Autocomplete
                  data-testid="user-admin-usergroups-dropdown"
                  disableCloseOnSelect={true}
                  sx={{ width: '100%' }}
                  multiple
                  options={userGroups}
                  defaultValue={startingGroups}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={`${option.id}.${option.name}`}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                        checkedIcon={<CheckBox fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  onChange={(_, data) => onChange(data)}
                  limitTags={3}
                  isOptionEqualToValue={(a, b) => {
                    return a.id === b.id;
                  }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...field}
                        {...params}
                        autoFocus
                        error={!!fieldState?.error}
                        helperText={fieldState?.error?.message}
                        multiline={false}
                        fullWidth
                        inputRef={ref}
                        variant="outlined"
                        maxRows={1}
                        rows={1}
                        label={t('modal.users.create.userAssignmentGroups')}
                        dataTestId="user-admin-usergroups-input"
                      />
                    );
                  }}
                />
              )}
            />
          </Box>
          <Grid item xs={6} style={{ margin: '0px auto' }}>
            <Controller
              name="active"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={!!field.value} />}
                  label={t('modal.users.create.userActive')}
                />
              )}
            />
          </Grid>
        </Box>
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" actionType="submit">
          {t('common.submit')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default UserManageModal;
