import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useZoneBinsAddTableLazyQuery } from '@/graphql/defs/hooks/table-props/__generated__/useZoneBinsAddDataTable.generated';
import { ViewZoneBinUnmappedSortFields } from '@/graphql/types.generated';
import {
  ColumnFilter,
  ColumnTypes,
  FilterOperators,
} from '@components/data-table/controls/filter/filter-definitions';
import useCreateDataTableColumns from '@components/data-table/hooks/useCreateDataTableColumns';
import useDataTableSelection, {
  SelectionType,
} from '@components/data-table/hooks/useDataTableSelection';
import useTriggerDataTableRefetch from '@components/data-table/hooks/useTriggerDataTableRefetch';
import { validateDataTableProps } from '@components/data-table/lib/validateDataTableProps';
import { DataTableVariants, IDataTableSort } from '@components/data-table/model/data-table';
import {
  TExtractLazyHookDataType,
  TExtractLazyHookFetchFilterType,
  TExtractLazyHookFieldNames,
} from '@components/data-table/model/extract-query-hook-types';
import TableIds from '@components/data-table/model/TableIds';
import LinkCell from '@components/data-table/table/cells/LinkCell';
import { BIN } from '@constants/routes';
import { useEntityUtils } from '@context/entity-utils';

const LAZY_QUERY_HOOK = useZoneBinsAddTableLazyQuery;
type _TAddDataType = TExtractLazyHookDataType<typeof LAZY_QUERY_HOOK>;
type _TAddFilterType = TExtractLazyHookFetchFilterType<typeof LAZY_QUERY_HOOK>;
type _TAddFieldNames = TExtractLazyHookFieldNames<typeof LAZY_QUERY_HOOK>;

const useZoneBinsAddDataTable = () => {
  const { t } = useTranslation('components');
  const { selectedWarehouseId } = useEntityUtils();
  const { selection, rowSelection } = useDataTableSelection<_TAddDataType>(
    SelectionType.multi,
    'binId',
  );

  const [refetchTrigger, triggerDataTableRefetch] = useTriggerDataTableRefetch();
  const columns = useCreateDataTableColumns<_TAddDataType>(
    (columnHelper) => [
      columnHelper.accessor('binCode', {
        header: t('common.code'),
        cell: ({ row, getValue }) => (
          <LinkCell
            href={`${BIN}/${row.original.binId}`}
            text={getValue()}
            dataTestId="zone-bins-settings-bin-link"
          />
        ),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
      columnHelper.accessor('binType', {
        header: t('common.type'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
      columnHelper.accessor('inactive', {
        header: t('common.inactive'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
      columnHelper.accessor('sourceBinBlock', {
        header: t('common.sourceBinBlock'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
      columnHelper.accessor('destinationBinBlock', {
        header: t('common.destinationBinBlock'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
      columnHelper.accessor('level', {
        header: t('common.level'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
      columnHelper.accessor('x', {
        header: t('common.x'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
      columnHelper.accessor('y', {
        header: t('common.y'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
      columnHelper.accessor('z', {
        header: t('common.z'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
    ],
    [],
  );

  const baseFilter = useMemo<ColumnFilter<_TAddFieldNames>[]>(
    () => [
      {
        columnId: ViewZoneBinUnmappedSortFields.BinType,
        operator: FilterOperators.notIn,
        value: ['disposition', 'lostAndFound', 'planned'],
      },
      {
        columnId: ViewZoneBinUnmappedSortFields.WarehouseId,
        operator: FilterOperators.eq,
        value: selectedWarehouseId,
      },
    ],
    [selectedWarehouseId],
  );

  const defaultSorting: IDataTableSort<_TAddFieldNames>[] = useMemo(
    () => [
      {
        id: ViewZoneBinUnmappedSortFields.BinCode,
        desc: false,
      },
    ],
    [],
  );

  return {
    triggerDataTableRefetch,
    selectedBins: selection,
    zoneBinsSettingsDataTableProps: validateDataTableProps<
      _TAddDataType,
      _TAddFilterType,
      _TAddFieldNames
    >({
      variant: DataTableVariants.Basic,
      type: 'query',
      tableId: TableIds.ZoneBinsSettingsAdd,
      tableHeader: t('zone.zoneBins.title'),
      columns,
      queryHook: LAZY_QUERY_HOOK,
      baseFilter,
      defaultSorting,
      refetchTrigger,
      defaultCollapsed: false,
      rowSelection,
    }),
  };
};

export default useZoneBinsAddDataTable;
