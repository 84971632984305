import { Paper, Box } from '@mui/material';

import LoadingBlob from '@components/loading-indicator/LoadingBlob';
import LoadingShine from '@components/loading-indicator/LoadingShine';

const CollapseLoading = () => {
  return (
    <Paper
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: (theme) => theme.spacing(4),
        width: '100%',
        padding: (theme) => theme.spacing(4),
        overflow: 'hidden',
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <LoadingBlob
          sx={{
            width: '33%',
            height: (theme) => theme.spacing(10),
            borderRadius: (theme) => theme.spacing(2),
          }}
        />
        <LoadingBlob
          sx={{
            width: (theme) => theme.spacing(10),
            height: (theme) => theme.spacing(10),
            borderRadius: (theme) => theme.spacing(2),
          }}
        />
      </Box>
      <Box
        sx={{
          height: '1px',
          width: '100%',
          backgroundColor: (theme) => theme.palette.gainsboro.main,
        }}
      ></Box>
      <LoadingBlob
        sx={{
          width: '75%',
          height: (theme) => theme.spacing(5),
          borderRadius: (theme) => theme.spacing(1),
        }}
      />
      <LoadingBlob
        sx={{
          width: '50%',
          height: (theme) => theme.spacing(5),
          borderRadius: (theme) => theme.spacing(1),
        }}
      />
      <LoadingBlob
        sx={{
          width: '25%',
          height: (theme) => theme.spacing(5),
          borderRadius: (theme) => theme.spacing(1),
        }}
      />
      <LoadingShine />
    </Paper>
  );
};

export default CollapseLoading;
