import { Box, Checkbox, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useGetTaskTypeStockStatusModalMappingsQuery,
  useTaskTypeStockStatusModalMappingUpdateMutation,
} from '@/graphql/defs/components/modals/__generated__/task-type-stock-status-modal.generated';
import { ModalContent } from '@components/modal';
import { ModalActions } from '@components/modal/modal-actions';
import { ModalButton } from '@components/modal/modal-button';
import ModalForm from '@components/modal/modal-form';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { ITaskTypeStockStatusMappingModal } from '@models/modal';

export const TaskTypeStockStatusModal = () => {
  const { t } = useTranslation('components');
  const [updateMapping] = useTaskTypeStockStatusModalMappingUpdateMutation();
  const { showMessage } = useSnackbar();
  const { selectedWarehouseId } = useEntityUtils();
  const { modal, closeModal, isPreparing, setPreparing } =
    useModalContent<ITaskTypeStockStatusMappingModal>();
  const { startPolling, stopPolling } = useGetTaskTypeStockStatusModalMappingsQuery({
    variables: {
      filter: {
        warehouseId: {
          eq: selectedWarehouseId,
        },
        taskTypeId: {
          eq: modal.mapping.taskTypeId,
        },
      },
    },
    onCompleted: (
      {
        view: {
          nodes: [{ stockStatusMappings }],
        },
      } = { view: { nodes: [{ stockStatusMappings: [] }] } },
    ) => {
      setPreparing(false);
      const updatedMapping = stockStatusMappings.map((m, i) => {
        if (!m.editable || !mapping[i]) return m;
        else return mapping[i];
      });
      setMapping(updatedMapping);
    },
    onError: (error) => {
      setPreparing(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  useEffect(() => {
    startPolling(5000);
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const formMethods = useForm();
  const { control, handleSubmit } = formMethods;

  const onCancel = () => {
    closeModal();
  };

  const [mapping, setMapping] = useState([]);
  const onSubmit = async (_, __) => {
    await updateMapping({
      variables: {
        input: {
          id: modal.mapping.taskTypeId,
          update: {
            warehouseId: selectedWarehouseId,
            stockStatusMappings: mapping
              .filter(({ mapped }) => mapped)
              .map(({ stockStatusId }) => stockStatusId),
          },
        },
      },
    });
    closeModal({ bypassLoading: true, success: true });
  };
  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent sx={{ height: '370px', width: '850px' }}>
          <Box sx={{ fontSize: '18px', display: 'flex', marginBottom: '20px' }}>
            <Box sx={{ fontWeight: 600, paddingRight: '10px' }}>{modal.mapping.taskTypeCode}</Box>
            {modal.mapping.taskTypeLabel}
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {mapping.map((stockStatus, i) => {
              return (
                <Box key={i} sx={{ width: '33%', marginBottom: '10px' }}>
                  <Controller
                    name={stockStatus.stockStatusLabel}
                    control={control}
                    defaultValue={stockStatus.mapped}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            checked={stockStatus.mapped}
                            color="primary"
                            disabled={!stockStatus.editable}
                            onChangeCapture={() => {
                              const updatedMapping = [...mapping];
                              updatedMapping[i] = {
                                ...updatedMapping[i],
                                mapped: !updatedMapping[i].mapped,
                              };
                              setMapping(updatedMapping);
                            }}
                          />
                        }
                        label={stockStatus.stockStatusLabel}
                      />
                    )}
                  />
                </Box>
              );
            })}
          </Box>
        </ModalContent>
        <ModalActions>
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton
            data-testid="taskTypeStockStatusMapping_submit"
            variant="contained"
            color="primary"
            actionType="submit"
          >
            {t('common.save')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};
