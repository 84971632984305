import {
  ApolloQueryResult,
  LazyQueryHookOptions,
  LazyQueryResult,
  QueryLazyOptions,
} from '@apollo/client';
import {
  Column,
  ColumnDef,
  ColumnOrderState,
  Header,
  Row,
  RowSelectionState,
  Updater,
} from '@tanstack/react-table';
import { Dispatch, ReactElement, ReactNode, SetStateAction } from 'react';

import TableIds from './TableIds';

import {
  Exact,
  InputMaybe,
  OffsetPageInfo,
  OffsetPaging,
  SortNulls,
  SortDirection,
} from '@/graphql/types.generated';
import {
  ColumnFilter,
  ColumnFilterEnumOption,
  ColumnFilterDefinition,
  LinkedColumnFilter,
} from '@components/data-table/controls/filter/filter-definitions';
import { IDataTableLayouts } from '@components/data-table/hooks/useDataTableLayouts';
import { SelectionType } from '@components/data-table/hooks/useDataTableSelection';
import { IDropdownMenuItem } from '@components/dropdown-menu';

export enum DataTableVariants {
  Basic = 'basic',
  Collapsible = 'collapsible',
}

/*
Data Table Component Props (Variant Agnostic)
*/
export type DefaultDataType<T = any> = Record<string, T>;
export type DefaultFilterType = Record<string, any>;
export type DefaultFieldNames = string;

export interface IDataTableSort<FieldNames = DefaultFieldNames> {
  id: FieldNames;
  desc: boolean;
}

export interface IAPISort<FieldNames = DefaultFieldNames> {
  field: FieldNames;
  direction: SortDirection;
  nulls?: InputMaybe<SortNulls>;
}

export type TOnFetchComplete<DataType = DefaultDataType> = (data: DataType[]) => void;

export interface IFetchResponseData<DataType = DefaultDataType> {
  __typename?: 'Query';
  query: {
    __typename?: string;
    pageInfo?: OffsetPageInfo;
    nodes?: DataType[];
    totalCount?: number;
  };
}

export type TFetchDataVariables<
  FilterType = DefaultFilterType,
  FieldNames = DefaultFieldNames,
> = Exact<{
  filter?: FilterType;
  paging?: OffsetPaging;
  sorting?: IAPISort<FieldNames> | IAPISort<FieldNames>[];
  includePageInfo?: boolean;
  includeNodes?: boolean;
  includeTotalCount?: boolean;
}>;

export type TFetchDataFunction<
  DataType = DefaultDataType,
  FilterType = DefaultFilterType,
  FieldNames = DefaultFieldNames,
> = (
  opts?: QueryLazyOptions<TFetchDataVariables<FilterType, FieldNames>>,
) => Promise<
  LazyQueryResult<IFetchResponseData<DataType>, TFetchDataVariables<FilterType, FieldNames>>
>;

export type TLazyQueryHook<
  DataType = DefaultDataType,
  FilterType = DefaultFilterType,
  FieldNames = DefaultFieldNames,
> = (
  baseOptions?: LazyQueryHookOptions<
    IFetchResponseData<DataType>,
    TFetchDataVariables<FilterType, FieldNames>
  >,
) => [
  TFetchDataFunction<DataType, FilterType, FieldNames>,
  LazyQueryResult<IFetchResponseData<DataType>, TFetchDataVariables<FilterType, FieldNames>>,
];

export type RowSelectionEnabledFilter<DataType = DefaultDataType> = (row: Row<DataType>) => boolean;

export interface IDataTableRowSelectionMultiple<DataType = DefaultDataType> {
  enableRowSelection: SelectionType.multi | SelectionType.multiNoAll;
  setSelectedRowsData: Dispatch<SetStateAction<DataType[]>>;
  selectionDataKey: keyof DataType;
  rowSelectionEnabledFilter?: RowSelectionEnabledFilter<DataType>;
  selectionOverride?: DataType[];
  clearSelectionTrigger: boolean;
}

export interface IDataTableRowSelectionSingle<DataType = DefaultDataType> {
  enableRowSelection: SelectionType.single;
  setSelectedRowsData: Dispatch<SetStateAction<DataType>>;
  selectionDataKey: keyof DataType;
  rowSelectionEnabledFilter?: RowSelectionEnabledFilter<DataType>;
  selectionOverride?: DataType[];
  clearSelectionTrigger: boolean;
}

export interface IDataTableRowSelectionNone {
  enableRowSelection: SelectionType.none;
  setSelectedRowsData?: never;
  selectionDataKey?: never;
  rowSelectionEnabledFilter?: never;
  selectionOverride?: never;
  clearSelectionTrigger?: never;
}

export type IDataTableRowSelection<DataType = DefaultDataType> =
  | IDataTableRowSelectionMultiple<DataType>
  | IDataTableRowSelectionSingle<DataType>
  | IDataTableRowSelectionNone;

export interface IOptionalDataTableQueryBasedProps<
  DataType = DefaultDataType,
  FieldNames = DefaultFieldNames,
> {
  variant: DataTableVariants;
  tableHeader: string;
  tableActions: ReactElement;
  layoutDropdownAddtOptions: IDropdownMenuItem[];
  layoutDropdownAddtOptionLabel: string;
  onFetchComplete: TOnFetchComplete<DataType>;
  baseFilter: ColumnFilter<FieldNames>[];
  linkedFilter: LinkedColumnFilter<FieldNames>[];
  defaultSorting: IDataTableSort<FieldNames>[];
  disablePagination: boolean;
  perPageOptions: number[];
  refetchTrigger: boolean;
  rowSelection: IDataTableRowSelection<DataType>;
  defaultCollapsed: boolean;
}

export interface IOptionalDataTableDataBasedProps<DataType = DefaultDataType> {
  variant: DataTableVariants;
  tableHeader: string;
  tableActions: ReactElement;
  rowSelection: IDataTableRowSelection<DataType>;
  defaultCollapsed: boolean;
  disableExport: boolean;
}

export interface IDataTableQueryBasedProps<
  DataType extends DefaultDataType = DefaultDataType,
  FilterType extends DefaultFilterType = DefaultFilterType,
  FieldNames extends DefaultFieldNames = DefaultFieldNames,
> extends Partial<IOptionalDataTableQueryBasedProps<DataType, FieldNames>> {
  type: 'query';
  tableId: TableIds;
  columns: ColumnDef<DataType, any>[];
  queryHook: TLazyQueryHook<DataType, FilterType, FieldNames>;
  data?: never;
}

export interface IDataTableDataBasedProps<DataType extends DefaultDataType = DefaultDataType>
  extends Partial<IOptionalDataTableDataBasedProps<DataType>> {
  type: 'data';
  tableId: TableIds;
  columns: ColumnDef<DataType, any>[];
  data: DataType[];
  isDataLoading: boolean;
  queryHook?: never;
  disableExport?: boolean;
}

export type IDataTableProps<
  DataType extends DefaultDataType = DefaultDataType,
  FilterType extends DefaultFilterType = DefaultFilterType,
  FieldNames extends DefaultFieldNames = DefaultFieldNames,
> =
  | IDataTableQueryBasedProps<DataType, FilterType, FieldNames>
  | IDataTableDataBasedProps<DataType>;

/*
Data Table Fetch Data / Pagination (useDataTableData)
*/
export type TRefetchDataTableData<
  DataType extends DefaultDataType,
  FilterType extends DefaultFilterType,
  FieldNames extends DefaultFieldNames,
> = (
  variables?: TFetchDataVariables<FilterType, FieldNames>,
) => Promise<ApolloQueryResult<IFetchResponseData<DataType>>>;
export type TRefetchDataTableTotalCount<
  DataType extends DefaultDataType,
  FilterType extends DefaultFilterType,
  FieldNames extends DefaultFieldNames,
> = (
  variables?: TFetchDataVariables<FilterType, FieldNames>,
) => Promise<ApolloQueryResult<IFetchResponseData<DataType>>>;
export interface IDataTableData<
  DataType extends DefaultDataType = DefaultDataType,
  FilterType extends DefaultFilterType = DefaultFilterType,
  FieldNames extends DefaultFieldNames = DefaultFieldNames,
> {
  data: DataType[];
  isDataTableLoading: boolean;
  hasCompletedFirstFetch: boolean;
  dataLastFetched: number;
  refetchData: TRefetchDataTableData<DataType, FilterType, FieldNames>;
  refetchTotalCount: TRefetchDataTableTotalCount<DataType, FilterType, FieldNames>;
  getExportData: () => Promise<DataType[]>;
}

export type TGoToPageActions = 'first' | 'prev' | 'next' | 'last';
export interface IDataTablePagination {
  disablePagination: boolean;
  offset: number;
  limit: number;
  startIndex: number;
  endIndex: number;
  pageCount: number;
  totalCount: number;
  isLoadingTotalCount: boolean;
  perPageOptions: number[];
  goToPage: (action: TGoToPageActions) => void;
  setPageLimit: (limit: number) => void;
  canPreviousPage: boolean;
  canNextPage: boolean;
  goToFirstPage: () => void;
  goToPrevPage: () => void;
  goToNextPage: () => void;
  goToLastPage: () => void;
}

/*
Data Table Context Provider
*/
export type TFilterSetter = (filter: ColumnFilter<string>[]) => void;

export type TQuickFilterSetter = (filter: ColumnFilter<string>) => void;

export type TDataTableRefetch = (
  variables?: TFetchDataVariables<DefaultFilterType, string>,
) => Promise<ApolloQueryResult<IFetchResponseData<DefaultDataType>>>;

export interface IDataTableQueryBasedContext {
  tableId: TableIds;
  tableSize: number;
  allColumns: Column<DefaultDataType, unknown>[];
  visibleTableColumns: Column<DefaultDataType, unknown>[];
  filterableColumns: ColumnFilterDefinition[];
  filterableColumnEnumOptions: ColumnFilterEnumOption[];
  setColumnOrder: (updater: Updater<ColumnOrderState>) => void;
  headerColumns: Header<DefaultDataType, unknown>[];
  dataRows: Row<DefaultDataType>[];
  selectedRows: RowSelectionState;
  filter: ColumnFilter<string>[];
  setFilter: TFilterSetter;
  quickFilter: ColumnFilter<string>;
  setQuickFilter: TQuickFilterSetter;
  refetchData: TDataTableRefetch;
  refetchTotalCount: TDataTableRefetch;
  getExportData: () => Promise<DefaultDataType[]>;
  pagination: IDataTablePagination;
  dataLastFetched: number;
  isDataTableLoading: boolean;
  hasCompletedFirstFetch: boolean;
  layoutProps: IDataTableLayouts;
  setSuppressDataFetch: Dispatch<SetStateAction<boolean>>;
}

export interface IDataTableQueryBasedProviderProps<
  DataType extends DefaultDataType = DefaultDataType,
  FilterType extends DefaultFilterType = DefaultFilterType,
  FieldNames extends DefaultFieldNames = DefaultFieldNames,
> {
  dataTableProps: IDataTableQueryBasedProps<DataType, FilterType, FieldNames>;
  children: ReactNode | ReactNode[];
}

export interface IDataTableDataBasedContext {
  tableId: TableIds;
  tableSize: number;
  allColumns: Column<DefaultDataType, unknown>[];
  headerColumns: Header<DefaultDataType, unknown>[];
  data: DefaultDataType[];
  dataRows: Row<DefaultDataType>[];
  selectedRows: RowSelectionState;
  isLoadingData: boolean;
}

export interface IDataTableDataBasedProviderProps<DataType = DefaultDataType> {
  dataTableProps: IDataTableDataBasedProps<DataType>;
  children: ReactNode | ReactNode[];
}
