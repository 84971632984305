/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompanyDetailsFragment = { __typename?: 'Company', id: string, code: string, name: string };

export type UpdateCompanyDetailsMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  updatedDetails: Types.CompanyUpdateInput;
}>;


export type UpdateCompanyDetailsMutation = { __typename?: 'Mutation', updateOneCompany: { __typename?: 'Company', id: string, code: string, name: string } };

export const CompanyDetailsFragmentDoc = gql`
    fragment CompanyDetails on Company {
  id
  code
  name
}
    `;
export const UpdateCompanyDetailsDocument = gql`
    mutation UpdateCompanyDetails($id: ID!, $updatedDetails: CompanyUpdateInput!) {
  updateOneCompany(input: {id: $id, update: $updatedDetails}) {
    ...CompanyDetails
  }
}
    ${CompanyDetailsFragmentDoc}`;
export type UpdateCompanyDetailsMutationFn = Apollo.MutationFunction<UpdateCompanyDetailsMutation, UpdateCompanyDetailsMutationVariables>;

/**
 * __useUpdateCompanyDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyDetailsMutation, { data, loading, error }] = useUpdateCompanyDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updatedDetails: // value for 'updatedDetails'
 *   },
 * });
 */
export function useUpdateCompanyDetailsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyDetailsMutation, UpdateCompanyDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyDetailsMutation, UpdateCompanyDetailsMutationVariables>(UpdateCompanyDetailsDocument, options);
      }
export type UpdateCompanyDetailsMutationHookResult = ReturnType<typeof useUpdateCompanyDetailsMutation>;
export type UpdateCompanyDetailsMutationResult = Apollo.MutationResult<UpdateCompanyDetailsMutation>;
export type UpdateCompanyDetailsMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyDetailsMutation, UpdateCompanyDetailsMutationVariables>;