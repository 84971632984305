import {
  UserCredential,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { Suspense, createContext, lazy, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useSnackbar } from './snackbar';

import { authHandshake, mapSapToken } from '@/apis/axios';
import { User } from '@/graphql/types.generated';
import { FullPageLoadingIndicator } from '@components/loading-indicator';
import { LOGIN, MAP_SAP_TOKEN, SUPPORT_LOGIN } from '@constants/routes';
import { firebaseOAuthProvider } from '@lib/firebase';
const NestedProvider = lazy(() => import('@context/nested-provider'));
const AuthRouter = lazy(() => import('@components/auth-router'));

const POST_AUTH_REDIRECT = 'post-auth-redirect';

export enum LoginMethods {
  POPUP = 'popup',
  EMAIL_PASS = 'emailPass',
}
export interface ICredentials {
  email: string;
  password: string;
}
export interface IAuthContext {
  user: User;
  logout: () => Promise<void>;
  login: () => Promise<void | UserCredential>;
  loginWithEmail: (opts: ICredentials) => Promise<void>;
}

const AuthContext = createContext<IAuthContext>({
  user: null,
  logout: () => Promise.resolve(),
  login: () => Promise.resolve(),
  loginWithEmail: () => Promise.resolve(),
});

const AuthProvider = ({ children }) => {
  const { showMessage } = useSnackbar();
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const logout = async (): Promise<void> => {
    setLoading(true);
    setUser(null);
    await getAuth().signOut();
  };

  const authError = (error) => showMessage({ type: 'error', message: error.message });

  const loginWithEmail = ({ email, password }: ICredentials): Promise<any> =>
    signInWithEmailAndPassword(getAuth(), email, password).catch(authError);

  const login = (): Promise<void | UserCredential> =>
    signInWithPopup(getAuth(), firebaseOAuthProvider).catch(authError);

  // maps user to sap token already stored in the database
  const handleSapMapping = async () => {
    return mapSapToken(searchParams.get('auth_mapping_id'));
  };

  const getToken = () => getAuth()?.currentUser?.getIdToken();

  // store url in local storage before user gets redirected to login pages
  const setInitialUrl = () => {
    // must to use window.location instead of react-router-dom location
    const url = window.location.pathname + window.location.search;
    localStorage.setItem(POST_AUTH_REDIRECT, url);
  };

  // retrieve the initial url and clear it from local storage so that it isn't reused.
  const getAndClearInitialUrl = () => {
    const url = localStorage.getItem(POST_AUTH_REDIRECT) || '/';
    localStorage.removeItem(POST_AUTH_REDIRECT);
    return url;
  };

  const handleAuthorizedUser = async () => {
    try {
      // perform handshake with api to see if further action
      // is required
      const { user, authRedirect } = await authHandshake();

      if (authRedirect) return window.location.assign(authRedirect);

      setUser(user);
      const initialUrl = getAndClearInitialUrl();
      navigate(initialUrl);
      setLoading(false);
    } catch (error) {
      const message = error?.response?.data?.message || error.message;
      showMessage({
        type: 'error',
        message: message,
      });
      navigate(`/auth/error?error=access_denied&message=${message}`);
    }
  };

  // must to use window.location instead of react-router-dom location
  const isAuthSupportPage = () => window.location.pathname === SUPPORT_LOGIN;
  const isAuthPage = () => window.location.pathname.slice(0, 5) === '/auth';
  const isTokenMappingPage = () => window.location.pathname === MAP_SAP_TOKEN;
  const isErrorPage = () => window.location.pathname.includes('/error');

  // take user to login page
  const handleUnauthorizedUser = async () => {
    if (!isAuthSupportPage()) navigate(LOGIN);
    setLoading(false);
  };

  // This function is run everytime the auth context mounts
  // this occurs anytime the app is initially loaded, a redirect is
  // followed, or the page is refreshed.
  const onInitialLoad = async () => {
    if (isErrorPage()) return setLoading(false);
    if (!isAuthPage()) setInitialUrl();
    if (isTokenMappingPage()) await handleSapMapping();

    const token = await getToken();
    token ? handleAuthorizedUser() : handleUnauthorizedUser();
  };

  useEffect(() => {
    // onAuthStateChanged runs the callback as soon as firebase is initialized
    // and whenever firebase auth status is updated.
    getAuth().onAuthStateChanged(onInitialLoad);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login,
        loginWithEmail,
        logout,
        user,
      }}
    >
      {loading ? (
        <FullPageLoadingIndicator />
      ) : user ? (
        <Suspense fallback={<SuspenseElement />}>
          <NestedProvider>{children}</NestedProvider>
        </Suspense>
      ) : (
        <Suspense fallback={<SuspenseElement />}>
          <AuthRouter />
        </Suspense>
      )}
    </AuthContext.Provider>
  );
};

const SuspenseElement = () => {
  return <div></div>;
};

export default AuthProvider;

export const useAuth = () => {
  const ctx = useContext(AuthContext);
  if (ctx === null) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return ctx;
};
