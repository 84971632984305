/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AssignDoorToDeliveryMutationVariables = Types.Exact<{
  doorId: Types.Scalars['ID']['input'];
  deliveryId: Types.Scalars['ID']['input'];
}>;


export type AssignDoorToDeliveryMutation = { __typename?: 'Mutation', updateOneDelivery: { __typename?: 'Delivery', id: string, erpCode: string, door?: { __typename?: 'Door', id: string, code?: string | null } | null } };

export type ListDoorsForDeliveryQueryVariables = Types.Exact<{
  direction: Types.DoorDirection;
}>;


export type ListDoorsForDeliveryQuery = { __typename?: 'Query', listDoors: { __typename?: 'DoorOffsetConnection', doors: Array<{ __typename?: 'Door', id: string, code?: string | null }> } };


export const AssignDoorToDeliveryDocument = gql`
    mutation AssignDoorToDelivery($doorId: ID!, $deliveryId: ID!) {
  updateOneDelivery(input: {id: $deliveryId, update: {doorId: $doorId}}) {
    id
    erpCode
    door {
      id
      code
    }
  }
}
    `;
export type AssignDoorToDeliveryMutationFn = Apollo.MutationFunction<AssignDoorToDeliveryMutation, AssignDoorToDeliveryMutationVariables>;

/**
 * __useAssignDoorToDeliveryMutation__
 *
 * To run a mutation, you first call `useAssignDoorToDeliveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignDoorToDeliveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignDoorToDeliveryMutation, { data, loading, error }] = useAssignDoorToDeliveryMutation({
 *   variables: {
 *      doorId: // value for 'doorId'
 *      deliveryId: // value for 'deliveryId'
 *   },
 * });
 */
export function useAssignDoorToDeliveryMutation(baseOptions?: Apollo.MutationHookOptions<AssignDoorToDeliveryMutation, AssignDoorToDeliveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignDoorToDeliveryMutation, AssignDoorToDeliveryMutationVariables>(AssignDoorToDeliveryDocument, options);
      }
export type AssignDoorToDeliveryMutationHookResult = ReturnType<typeof useAssignDoorToDeliveryMutation>;
export type AssignDoorToDeliveryMutationResult = Apollo.MutationResult<AssignDoorToDeliveryMutation>;
export type AssignDoorToDeliveryMutationOptions = Apollo.BaseMutationOptions<AssignDoorToDeliveryMutation, AssignDoorToDeliveryMutationVariables>;
export const ListDoorsForDeliveryDocument = gql`
    query ListDoorsForDelivery($direction: doorDirection!) {
  listDoors: doors(
    filter: {direction: {in: [$direction, both]}}
    sorting: [{field: code, direction: ASC}]
  ) {
    doors: nodes {
      id
      code
    }
  }
}
    `;

/**
 * __useListDoorsForDeliveryQuery__
 *
 * To run a query within a React component, call `useListDoorsForDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDoorsForDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDoorsForDeliveryQuery({
 *   variables: {
 *      direction: // value for 'direction'
 *   },
 * });
 */
export function useListDoorsForDeliveryQuery(baseOptions: Apollo.QueryHookOptions<ListDoorsForDeliveryQuery, ListDoorsForDeliveryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDoorsForDeliveryQuery, ListDoorsForDeliveryQueryVariables>(ListDoorsForDeliveryDocument, options);
      }
export function useListDoorsForDeliveryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDoorsForDeliveryQuery, ListDoorsForDeliveryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDoorsForDeliveryQuery, ListDoorsForDeliveryQueryVariables>(ListDoorsForDeliveryDocument, options);
        }
export type ListDoorsForDeliveryQueryHookResult = ReturnType<typeof useListDoorsForDeliveryQuery>;
export type ListDoorsForDeliveryLazyQueryHookResult = ReturnType<typeof useListDoorsForDeliveryLazyQuery>;
export type ListDoorsForDeliveryQueryResult = Apollo.QueryResult<ListDoorsForDeliveryQuery, ListDoorsForDeliveryQueryVariables>;