import { gql } from '@apollo/client';

import { GetFulfillmentItemsForLoadQuery } from '@/graphql/defs/components/modals/__generated__/load-task-create-modal.generated';

gql`
  fragment FulfillmentItemToLoad on FulfillmentItemDetails {
    id
    fulfillmentItem
    licensePlateId
    licensePlateCode
    licensePlateDescription
    productId
    productCode
    productDescription
  }

  query GetFulfillmentItemsForLoad($fulfillmentId: ID!) {
    fulfillmentItemsToLoad(id: $fulfillmentId) {
      ...FulfillmentItemToLoad
    }
  }

  mutation CreateLoadTasks($fulfillmentId: ID!) {
    createLoadTasksForFulfillment(fulfillmentId: $fulfillmentId) {
      id
      code
    }
  }
`;

export const GetFulfillmentItemsForLoad_defaultData: GetFulfillmentItemsForLoadQuery = {
  __typename: 'Query',
  fulfillmentItemsToLoad: [],
};
