/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListVariantsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListVariantsQuery = { __typename?: 'Query', listVariants: { __typename?: 'SlottingDatasetVariants', variants: Array<string> } };


export const ListVariantsDocument = gql`
    query ListVariants {
  listVariants: sapDatasetVariants {
    variants
  }
}
    `;

/**
 * __useListVariantsQuery__
 *
 * To run a query within a React component, call `useListVariantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListVariantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListVariantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListVariantsQuery(baseOptions?: Apollo.QueryHookOptions<ListVariantsQuery, ListVariantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListVariantsQuery, ListVariantsQueryVariables>(ListVariantsDocument, options);
      }
export function useListVariantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListVariantsQuery, ListVariantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListVariantsQuery, ListVariantsQueryVariables>(ListVariantsDocument, options);
        }
export type ListVariantsQueryHookResult = ReturnType<typeof useListVariantsQuery>;
export type ListVariantsLazyQueryHookResult = ReturnType<typeof useListVariantsLazyQuery>;
export type ListVariantsQueryResult = Apollo.QueryResult<ListVariantsQuery, ListVariantsQueryVariables>;