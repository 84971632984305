import { gql } from '@apollo/client';

import { ListCompaniesByOrgQuery } from '@/graphql/defs/list/__generated__/list-companies.generated';

gql`
  fragment ListCompanies on Company {
    id
    name
    code
  }

  query ListCompaniesByOrg($orgId: ID!) {
    listCompaniesByOrg: companies(
      paging: { offset: 0, limit: 1000000 }
      filter: { organizationId: { eq: $orgId } }
      sorting: [{ field: name, direction: ASC }]
    ) {
      companies: nodes {
        ...ListCompanies
      }
    }
  }
`;

export const ListCompaniesByOrg_defaultData: ListCompaniesByOrgQuery = {
  __typename: 'Query',
  listCompaniesByOrg: {
    __typename: 'CompanyOffsetConnection',
    companies: [],
  },
};
