import { Grid, TextField } from '@mui/material';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useCreateWarehouseRoleTypeMutation,
  useDeleteWarehouseRoleTypeMutation,
  useUpdateWarehouseRoleTypeMutation,
} from '@/graphql/defs/components/modals/__generated__/warehouse-role-type-modal.generated';
import { WarehouseRoleTypeCreateOneInput } from '@/graphql/types.generated';
import CurrencyInput from '@components/form-util/field-blocks/currency-dropdown';
import VelocityUomDropdownDb from '@components/form-util/field-blocks/velocity-uom-dropdown-db';
import WeightUomDropdownDb from '@components/form-util/field-blocks/weight-uom-dropdown-db';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import { convertNullValuesToEmptyString, stripUnchangedFields } from '@lib/form';
import FormValues from '@models/FormValues';
import { IWarehouseRoleTypeModal } from '@models/modal';

interface IwarehouseRoleType {
  code: string;
  label: string;
  description?: string;
  velocity: number;
  velocityUOMId: string;
  cost: number;
  currency: string;
  weightUOMId: string;
  weightMax: number;
}
type TWarehouseRoleTypeFields = keyof IwarehouseRoleType;
const warehouseRoleTypeFields: TWarehouseRoleTypeFields[] = [
  'code',
  'label',
  'description',
  'velocity',
  'velocityUOMId',
  'cost',
  'currency',
  'weightUOMId',
  'weightMax',
];

const WarehouseRoleTypeModal = () => {
  const { t } = useTranslation('components');
  const { selectedWarehouseId } = useEntityUtils();
  const { showMessage } = useSnackbar();
  const { closeModal, isPreparing, setLoading, modal } = useModalContent<IWarehouseRoleTypeModal>();
  const { fieldGreaterThanOrEqualZero } = useFormValidation();
  const defaultValues = useMemo(() => {
    return convertNullValuesToEmptyString<TWarehouseRoleTypeFields>(
      warehouseRoleTypeFields,
      modal?.warehouseRoleType,
    );
  }, []);
  const formMethods = useForm<FormValues<IwarehouseRoleType>>({
    defaultValues: {
      ...defaultValues,
      currency: 'USD',
    },
  });
  const { control, handleSubmit } = formMethods;

  const [deleteWarehouseRoleType] = useDeleteWarehouseRoleTypeMutation({
    onCompleted: ({ warehouseRoleType: { code } }) => {
      showMessage({
        type: 'success',
        message: t('modal.warehouseRoleType.update.deleted'),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const [createWarehouseRoleType] = useCreateWarehouseRoleTypeMutation({
    onCompleted: ({ warehouseRoleType: { code } }) => {
      showMessage({
        type: 'success',
        message: t('modal.warehouseRoleType.create.success', { code }),
      });

      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const [updateWarehouseRoleType] = useUpdateWarehouseRoleTypeMutation({
    onCompleted: ({ warehouseRoleType: { code } }) => {
      showMessage({
        type: 'success',
        message: t('modal.warehouseRoleType.update.success', { code }),
      });

      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = (fields: WarehouseRoleTypeCreateOneInput['warehouseRoleType']) => {
    if (modal.warehouseRoleType) {
      const updated = stripUnchangedFields(defaultValues, fields);
      void updateWarehouseRoleType({
        variables: {
          input: {
            id: modal.warehouseRoleType.id,
            update: {
              ...updated,
              velocity: updated?.velocity ? Number(updated.velocity) : undefined,
              weightMax: updated?.weightMax ? Number(updated.weightMax) : undefined,
              cost: updated?.cost ? Number(updated.cost) : undefined,
            },
          },
        },
      });
    } else {
      void createWarehouseRoleType({
        variables: {
          input: {
            warehouseRoleType: {
              ...fields,
              velocity: Number(fields.velocity),
              weightMax: Number(fields.weightMax),
              cost: Number(fields.cost),
            },
          },
        },
      });
    }
  };

  const onDelete = () => {
    void deleteWarehouseRoleType({
      variables: {
        input: {
          id: modal.warehouseRoleType.id,
        },
      },
    });
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Controller
                name="code"
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.code') }),
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    required
                    fullWidth
                    id="warehouse-role-type-code"
                    data-testid="warehouse-role-type-code"
                    label={t('common.code')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="label"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.label') }),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    required
                    fullWidth
                    id="warehouse-role-type-label"
                    data-testid="warehouse-role-type-label"
                    label={t('common.label')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    id="warehouse-role-type-description"
                    data-testid="warehouse-role-type-description"
                    label={t('common.description')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="velocity"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.velocity') }),
                  validate: {
                    fieldGreaterThanOrEqualZero,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    required
                    id="warehouse-role-type-speed"
                    data-testid="warehouse-role-type-speed"
                    label={t('common.velocity')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    type="number"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="velocityUOMId"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.velocityUOM') }),
                }}
                render={({ field, fieldState }) => (
                  <VelocityUomDropdownDb
                    field={field}
                    fieldState={fieldState}
                    dataTestId="equipment-speed-uom"
                    warehouseId={selectedWarehouseId}
                    label={t('common.unitOfMeasureAbbrSuffix', {
                      prefix: t('common.velocity'),
                    })}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="cost"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.cost') }),
                  validate: {
                    fieldGreaterThanOrEqualZero,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    id="warehouse-role-type-cost"
                    required
                    data-testid="warehouse-role-type-cost"
                    label={t('common.cost')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    type="number"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="currency"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.currency') }),
                }}
                render={({ field, fieldState }) => {
                  return (
                    <CurrencyInput
                      disabled={true}
                      required
                      id="warehouse-role-type-currency"
                      label={t('common.currency')}
                      field={field}
                      fieldState={fieldState}
                      dataTestId="warehouse-role-type-currency"
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="weightMax"
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.weightMax') }),
                  validate: {
                    fieldGreaterThanOrEqualZero,
                  },
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    id="warehouse-role-type-weight-limit"
                    data-testid="warehouse-role-type-weight-limit"
                    required
                    label={t('common.weightMax')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    type="number"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="weightUOMId"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.weightUOM') }),
                }}
                render={({ field, fieldState }) => (
                  <WeightUomDropdownDb
                    {...field}
                    required
                    warehouseId={selectedWarehouseId}
                    label={t('common.weightUOM')}
                    field={field}
                    fieldState={fieldState}
                    dataTestId="warehouse-role-type-weight-uom"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} />
          </Grid>
        </ModalContent>
        <ModalActions>
          <ModalButton
            onClick={() => onDelete()}
            variant="outlined"
            color="error"
            actionType="delete"
            sx={{
              marginRight: 'auto',
              visibility: modal?.warehouseRoleType?.id ? 'visible' : 'hidden',
            }}
          >
            {t('common.delete')}
          </ModalButton>
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton
            variant="contained"
            color="primary"
            data-testid="create-dataset-submit"
            actionType="submit"
          >
            {modal?.warehouseRoleType?.id ? t('common.edit') : t('common.save')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default WarehouseRoleTypeModal;
