import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdateContactInfoMutation } from '@/graphql/defs/components/modals/__generated__/contact-info-modal.generated';
import { UpdateContactInfoDto } from '@/graphql/types.generated';
import { FieldBlockContactInfo } from '@components/form-util';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { stripUnchangedFields } from '@lib/form';
import FormValues from '@models/FormValues';
import { IContactInfoModal } from '@models/modal';

const ContactInfoModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, setLoading } = useModalContent<IContactInfoModal>();
  const { showMessage } = useSnackbar();

  const defaultValues: FormValues<UpdateContactInfoDto> = {
    email: modal.contactInfo?.email || '',
    phone: modal.contactInfo?.phone || '',
    street1: modal.contactInfo?.street1 || '',
    street2: modal.contactInfo?.street2 || '',
    country: modal.contactInfo?.country || '',
    city: modal.contactInfo?.city || '',
    state: modal.contactInfo?.state || '',
    zip: modal.contactInfo?.zip || '',
  };
  const formMethods = useForm<FormValues<UpdateContactInfoDto>>({
    defaultValues,
  });
  const { control, handleSubmit, setValue } = formMethods;

  const [updateContactInfo] = useUpdateContactInfoMutation({
    onCompleted: () => {
      showMessage({ type: 'success', message: t('modal.contactInfo.success') });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const onSubmit = (fields: UpdateContactInfoDto) => {
    void updateContactInfo({
      variables: {
        parentId: modal.parentId,
        isOrg: modal.parentType === 'organization',
        isCompany: modal.parentType === 'company',
        isWarehouse: modal.parentType === 'warehouse',
        contactInfo: stripUnchangedFields(defaultValues, fields),
      },
    });
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <Grid container spacing={6}>
          <FieldBlockContactInfo
            contactInfo={modal.contactInfo}
            control={control}
            setValue={setValue}
            focusOnLoad
          />
        </Grid>
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" actionType="submit">
          {t('common.submit')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default ContactInfoModal;
