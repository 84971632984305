import { Grid, TextField, Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateDatasetMutation } from '@/graphql/defs/components/modals/__generated__/create-dataset-modal.generated';
import { useListVariantsQuery } from '@/graphql/defs/list/__generated__/list-sap-variants.generated';
import { ListVariants_defaultData } from '@/graphql/defs/list/list-sap-variants';
import { SlottingDatasetCreateInput } from '@/graphql/types.generated';
import { DatePicker } from '@components/date-picker';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import FormValues from '@models/FormValues';
import { IDatasetCreateModal } from '@models/modal';

const CreateDatasetModal = () => {
  const { t } = useTranslation('components');
  const { permittedWarehouses } = useEntityUtils();
  const { showMessage } = useSnackbar();
  const { closeModal, isPreparing, setPreparing, setLoading, depBucket, updateDepBucket } =
    useModalContent<IDatasetCreateModal>();

  const formMethods = useForm<FormValues<SlottingDatasetCreateInput>>({
    defaultValues: {
      dateRangeEnd: null,
      dateRangeStart: null,
      locked: false,
      variantName: '',
      warehouseId: '',
      tag: '',
    },
  });
  const { control, handleSubmit } = formMethods;
  const { validDate } = useFormValidation();

  const [createDataset] = useCreateDatasetMutation({
    onCompleted: ({ dataset: { id, code } }) => {
      showMessage({
        type: 'success',
        message: t('modal.dataset.createDataset.success', { code }),
      });

      if (depBucket['pendingDepBucketKey']) {
        updateDepBucket(depBucket['pendingDepBucketKey'], id);
      }

      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onCancel = () => {
    closeModal();
  };

  const { data: { listVariants: { variants } } = ListVariants_defaultData } = useListVariantsQuery({
    onCompleted: () => setPreparing(false),
  });

  const onSubmit = (fields: SlottingDatasetCreateInput) => {
    void createDataset({
      variables: {
        input: {
          SlottingDataset: {
            warehouseId: fields.warehouseId,
            dateRangeEnd: fields.dateRangeEnd,
            dateRangeStart: fields.dateRangeStart,
            locked: false,
            variantDescription: fields.variantName,
            variantName: fields.variantName,
            tag: fields.tag,
          },
        },
      },
    });
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Box marginBottom="25px">{t('modal.dataset.create.subtitle')}</Box>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Controller
                name="warehouseId"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('bins.columns.area') }),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    required
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="dataset-warehouse"
                    label={t('common.warehouse')}
                    {...field}
                    ref={null}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                  >
                    <option disabled />
                    {permittedWarehouses.map((warehouse) => (
                      <option key={`warehouse-${warehouse.id}`} value={warehouse.id}>
                        {`${warehouse.code}: ${warehouse.name}`}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="variantName"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('bins.columns.area') }),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    required
                    select
                    multiline
                    SelectProps={{
                      native: true,
                    }}
                    id="dataset-variant"
                    label={t('modal.dataset.variant')}
                    {...field}
                    ref={null}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                  >
                    <option disabled />
                    {variants.map((variant) => (
                      <option key={`variant-${variant}`} value={variant}>
                        {variant}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="dateRangeStart"
                control={formMethods.control}
                rules={{
                  validate: {
                    validDate,
                  },
                }}
                render={({ field, fieldState }) => (
                  <DatePicker
                    autoFocus={true}
                    label={t('common.startDate')}
                    field={field}
                    fieldState={fieldState}
                    setDate={field.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="dateRangeEnd"
                control={formMethods.control}
                rules={{
                  validate: {
                    validDate,
                  },
                }}
                render={({ field, fieldState }) => (
                  <DatePicker
                    autoFocus={true}
                    label={t('common.endDate')}
                    field={field}
                    fieldState={fieldState}
                    setDate={field.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="tag"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    id="dataset-tag"
                    data-testid="dataset-tag"
                    label={t('common.tag')}
                    {...field}
                    ref={null}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} />
          </Grid>
        </ModalContent>
        <ModalActions>
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton
            variant="contained"
            color="primary"
            data-testid="create-dataset-submit"
            actionType="submit"
          >
            {t('common.submit')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default CreateDatasetModal;
