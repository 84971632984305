import { Box, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  useCreatePickToStagingTasksMutation,
  useGetFulfillmentItemsForPickQuery,
} from '@/graphql/defs/components/modals/__generated__/pick-to-staging-create-modal.generated';
import { GetFulfillmentItemsForPick_defaultData } from '@/graphql/defs/components/modals/pick-to-staging-create-modal';
import EditableTable, {
  EditableTableCell,
  EditableTableColumn,
  EditableTableRow,
} from '@components/editable-table/EditableTable';
import { ModalActions, ModalContent } from '@components/modal';
import { ModalButton } from '@components/modal/modal-button';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { getTransComponents } from '@lib/translation';
import { IPickToStagingCreateModal } from '@models/modal';

const PickToStagingCreateModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();

  const { modal, closeModal, setLoading, setPreparing, isPreparing } =
    useModalContent<IPickToStagingCreateModal>();

  const [createTasks] = useCreatePickToStagingTasksMutation({
    onCompleted: ({ createPickTasksForFulfillment: { created, hasTask, noStockFound } }) => {
      if (created.length) {
        showMessage({
          type: 'success',
          message: t('modal.fulfillment.pickToStagingCreate.created.success', {
            fulfillmentCode: modal.erpCode,
          }),
        });
      }

      if (hasTask.length && !created.length && !noStockFound.length) {
        showMessage({
          type: 'warning',
          title: t('modal.fulfillment.pickToStagingCreate.hasTask.title'),
          message: t('modal.fulfillment.pickToStagingCreate.hasTask.warning'),
        });
      }

      if (noStockFound.length) {
        showMessage({
          type: 'error',
          title: t('modal.fulfillment.pickToStagingCreate.noStockFound.title'),
          message: t('modal.fulfillment.pickToStagingCreate.noStockFound.error', {
            fulfillmentItems: noStockFound.map((item) => `${item.fulfillmentItem}`).join(', '),
          }),
        });
      }
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({ type: 'error', message: error.message });
    },
  });

  const { data: fulfillmentItemsData } = useGetFulfillmentItemsForPickQuery({
    variables: {
      fulfillmentId: modal.fulfillmentId,
    },
    onCompleted: () => {
      setPreparing(false);
    },
    onError: (error) => {
      setPreparing(false);
      showMessage({ type: 'error', message: error.message });
    },
  });
  const { fulfillmentItemsToPick: fulfillmentItems } = useMemo(
    () => fulfillmentItemsData || GetFulfillmentItemsForPick_defaultData,
    [fulfillmentItemsData],
  );

  const lpFulfillmentItemColumns: EditableTableColumn[] = [
    {
      label: t('common.item'),
      width: '20%',
    },
    {
      label: t('common.licensePlateNumberSymbol'),
      width: '20%',
    },
    {
      label: t('common.licensePlateDescription'),
      width: '20%',
    },
    {
      label: t('common.sourceBin'),
      width: '20%',
    },
    {
      label: t('common.destinationBin'),
      width: '20%',
    },
  ];

  const productFulfillmentItemColumns: EditableTableColumn[] = [
    {
      label: t('common.item'),
      width: '16.66%',
    },
    {
      label: t('common.productCodeAbbr'),
      width: '16.66%',
    },
    {
      label: t('common.description'),
      width: '16.66%',
    },
    {
      label: t('common.lot'),
      width: '16.66%',
    },
    {
      label: t('common.qty'),
      width: '16.66%',
    },
  ];

  const [lpTasks, looseProductTasks] = useMemo<[EditableTableRow[], EditableTableRow[]]>(() => {
    const rows = [...fulfillmentItems];
    const looseProductRows = rows.filter((row) => !row.licensePlateId);
    const lpRows = rows.filter((row) => row.licensePlateId);

    return [
      lpRows.map(createLpFulfillmentItemRow),
      looseProductRows.map(createProductFulfillmentItemRow),
    ];
  }, [fulfillmentItems]);

  const onSubmit = useCallback(() => {
    void createTasks({
      variables: {
        fulfillmentId: modal?.fulfillmentId,
      },
    });
  }, [fulfillmentItems]);

  return (
    !isPreparing && (
      <>
        <ModalContent>
          <Box mb={8}>
            <Typography>
              <Trans
                ns="components"
                i18nKey="modal.task.createPickLoadTaskInfo"
                components={getTransComponents(['break', 'bold'])}
              />
            </Typography>
          </Box>
          <Box mb={4}>
            {lpTasks && lpTasks.length > 0 && (
              <EditableTable columns={lpFulfillmentItemColumns} data={lpTasks} />
            )}
          </Box>
          <Box>
            {looseProductTasks && looseProductTasks.length > 0 && (
              <EditableTable columns={productFulfillmentItemColumns} data={looseProductTasks} />
            )}
          </Box>
        </ModalContent>
        <ModalActions>
          <ModalButton
            variant="outlined"
            color="primary"
            onClick={() => closeModal()}
            actionType="cancel"
          >
            {t('common.cancel')}
          </ModalButton>
          <ModalButton onClick={onSubmit} variant="contained" color="primary" actionType="submit">
            {t('modal.task.createTask')}
          </ModalButton>
        </ModalActions>
      </>
    )
  );
};

export default PickToStagingCreateModal;

const createLpFulfillmentItemRow = (fulfillmentItem, index): EditableTableRow => {
  const cells: EditableTableCell[] = [
    {
      dataTestId: `fulfillmentItem-${index}-deliveryItem`,
      value: fulfillmentItem.fulfillmentItem,
    },
    {
      dataTestId: `fulfillmentItem-${index}-lpCode`,
      value: fulfillmentItem.licensePlateCode,
    },
    {
      dataTestId: `fulfillmentItem-${index}-lpDesc`,
      value: fulfillmentItem.licensePlateDescription,
    },
    {
      dataTestId: `fulfillmentItem-${index}-sourceBin`,
      value: fulfillmentItem.sourceBinCode,
    },
    {
      dataTestId: `fulfillmentItem-${index}-destBin`,
      value: fulfillmentItem.destinationBinCode,
    },
  ];
  return {
    dataTestId: `fulfillmentItem-${index}`,
    item: fulfillmentItem,
    cells: cells,
  };
};

const createProductFulfillmentItemRow = (fulfillmentItem, index): EditableTableRow => {
  const cells: EditableTableCell[] = [
    {
      dataTestId: `fulfillmentItem-${index}-deliveryItem`,
      value: fulfillmentItem.item,
    },
    {
      dataTestId: `fulfillmentItem-${index}-productCode`,
      value: fulfillmentItem.productCode,
    },
    {
      dataTestId: `fulfillmentItem-${index}-productDesc`,
      value: fulfillmentItem.productDescription,
    },
    {
      dataTestId: `fulfillmentItem-${index}-lotCode`,
      value: fulfillmentItem.lotCode,
    },
    {
      dataTestId: `fulfillmentItem-${index}-quantity`,
      value: fulfillmentItem.quantity,
    },
  ];
  return {
    dataTestId: `fulfillmentItem-${index}`,
    item: fulfillmentItem,
    cells: cells,
  };
};
