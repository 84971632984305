import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  Box,
  MenuItem,
  Typography,
  styled,
  Button,
  IconButton,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  CreateOneAgentConfigurationMutationVariables,
  useCreateOneAgentConfigurationMutation,
  useUpdateOneAgentConfigurationMutation,
} from '@/graphql/defs/components/modals/__generated__/agent-config-modal.generated';
import {
  AgentConfiguration,
  AgentConfigurationAccessPolicy,
  AgentConfigurationUpdate,
} from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import FormValues from '@models/FormValues';
import { IAgentConfigModal } from '@models/modal';

interface IAgentConfigHost {
  accessPolicy: AgentConfigurationAccessPolicy;
  hostname?: string;
  path?: string;
  port?: string;
}
export type IAgentConfig = {
  agentId?: string;
  agentName?: string;
  version?: string;
  hosts: IAgentConfigHost[];
};

const AgentConfig = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'settings.agentConfig' });
  const { t: tC } = useTranslation('components', { keyPrefix: 'common' });
  const { t: tH } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal, setLoading } = useModalContent<IAgentConfigModal>();
  const cleanAgent = ({ agentName = null, hosts }) => ({
    agentName,
    hosts: hosts.map(({ accessPolicy, hostname, path, port }) => ({
      accessPolicy,
      hostname,
      path,
      port,
    })),
  });
  const agentId = modal?.agentConfig?.agentId;
  const [agentConfig, setAgentConfig] = useState<IAgentConfig>(
    modal.agentConfig ? cleanAgent(modal.agentConfig) : ({} as AgentConfiguration),
  );
  const createDefaultValues = (): FormValues<{ name: string; hosts: IAgentConfigHost[] }> => ({
    name: agentConfig.agentName,
    hosts: agentConfig.hosts,
  });

  const formMethods = useForm({
    defaultValues: createDefaultValues(),
  });
  const { control, reset, handleSubmit } = formMethods;
  useEffect(() => {
    reset(createDefaultValues());
  }, []);

  const [createAgent] = useCreateOneAgentConfigurationMutation({
    onError: (error) => {
      setLoading(false);
      showMessage({ type: 'error', message: error.message });
    },
  });
  const [updateAgent] = useUpdateOneAgentConfigurationMutation({
    onError: (error) => {
      setLoading(false);
      showMessage({ type: 'error', message: error.message });
    },
  });

  const onSubmit = async () => {
    if (agentId) {
      await updateAgent({
        variables: {
          agentId,
          update: agentConfig as AgentConfigurationUpdate,
        },
      }).catch(() => {
        return;
      });
    } else {
      await createAgent({
        variables: agentConfig as CreateOneAgentConfigurationMutationVariables,
      }).catch(() => {
        return;
      });
    }
    closeModal({ bypassLoading: true, success: true });
  };

  const updateHostField = (index, key, value) => {
    const hosts = [...agentConfig?.hosts];
    hosts[index] = { ...hosts[index], [key]: value };

    setAgentConfig({
      ...agentConfig,
      hosts,
    });
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent sx={{ width: '800px' }}>
        <Box sx={{ margin: '5px 0 15px' }}>
          <Controller
            name={'name'}
            control={control}
            rules={{
              required: tH('form.validation.requiredField', { field: t('columns.agentName') }),
              minLength: 1,
            }}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                autoFocus
                required
                autoComplete="off"
                variant="standard"
                label={t('columns.agentName')}
                value={agentConfig?.agentName || ''}
                sx={{ width: '250px' }}
                onChange={({ target: { value } }) => {
                  field.onChange(value);
                  setAgentConfig({
                    ...agentConfig,
                    agentName: value,
                  });
                }}
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                dataTestId="agentConfig-name"
              />
            )}
          />
        </Box>
        <Typography sx={{ fontSize: '18px', fontWeight: 500, margin: '25px 0px 10px' }}>
          {t('endpoints')}
        </Typography>
        {agentConfig?.hosts?.map((host, index) => {
          return (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
              <Hosts>
                <HostTextInput
                  label={t('columns.erpHost')}
                  name={`hosts[${index}].hostname`}
                  value={host.hostname || ''}
                  rules={{
                    required: tH('form.validation.requiredField', {
                      field: t('columns.erpHost'),
                    }),
                    minLength: 1,
                  }}
                  control={control}
                  onChange={(value) => updateHostField(index, 'hostname', value)}
                />
                <HostTextInput
                  label={t('columns.erpPort')}
                  name={`hosts[${index}].port`}
                  value={host.port || ''}
                  rules={{
                    required: tH('form.validation.requiredField', {
                      field: t('columns.erpPort'),
                    }),
                    minLength: 1,
                  }}
                  control={control}
                  onChange={(value) => updateHostField(index, 'port', value)}
                />
                <HostTextInput
                  label={t('columns.uriPath')}
                  name={`hosts[${index}].path`}
                  value={host.path || ''}
                  control={control}
                  onChange={(value) => updateHostField(index, 'path', value)}
                />
                <TextField
                  variant="standard"
                  sx={{ width: 'calc(50% - 12px)' }}
                  select
                  label={t('columns.access')}
                  value={host.accessPolicy}
                  onChange={({ target: { value } }) =>
                    updateHostField(index, 'accessPolicy', value)
                  }
                  dataTestId="agentConfig-access"
                >
                  <MenuItem
                    key={AgentConfigurationAccessPolicy.PathOnly}
                    value={AgentConfigurationAccessPolicy.PathOnly}
                  >
                    {t('PATH_ONLY')}
                  </MenuItem>
                  <MenuItem
                    key={AgentConfigurationAccessPolicy.AllPaths}
                    value={AgentConfigurationAccessPolicy.AllPaths}
                  >
                    {t('ALL_PATHS')}
                  </MenuItem>
                </TextField>
              </Hosts>
              <IconButton
                sx={{
                  height: '30px',
                  width: '30px',
                  marginLeft: '15px',
                  visibility: agentConfig.hosts.length > 1 ? 'visible' : 'hidden',
                }}
                onClick={() => {
                  setAgentConfig({
                    ...agentConfig,
                    hosts: agentConfig.hosts.filter((_, i) => index !== i),
                  });
                }}
              >
                <RemoveCircleOutlineIcon color="primary" />
              </IconButton>
            </Box>
          );
        })}
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            sx={{ textTransform: 'none' }}
            variant="outlined"
            size="large"
            color="primary"
            onClick={() => {
              const newConfig = {
                ...(agentConfig || {}),
                hosts: [
                  ...(agentConfig?.hosts || []),
                  {
                    accessPolicy: AgentConfigurationAccessPolicy.PathOnly,
                  },
                ],
              };
              setAgentConfig(newConfig);
            }}
          >
            {t('addEnpoint')}
          </Button>
        </Box>
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {tC('cancel')}
        </ModalButton>
        <ModalButton
          data-testid="agentConfig_submit"
          variant="contained"
          color="primary"
          actionType="submit"
        >
          {tC('save')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default AgentConfig;

const HostTextInput = ({ name, label, onChange, control, value, rules = null }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <FormControl error={!!fieldState?.error} sx={{ width: 'calc(50% - 12px)' }}>
          <TextField
            sx={{ width: '340px' }}
            {...field}
            required={!!rules?.required}
            autoComplete="off"
            error={!!fieldState.error}
            variant="standard"
            label={label}
            value={value}
            onChange={({ target: { value } }) => {
              field.onChange(value);
              onChange(value);
            }}
            dataTestId={`agentConfig-${name}`}
          />
          <FormHelperText sx={{ height: 19 }}>{fieldState?.error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

const Hosts = styled(Box)({
  flexWrap: 'wrap',
  display: 'flex',
  justifyContent: 'space-between',
  // borderBottom: '1px solid #D1D1D1',
  marginBottom: '25px',
});
