import { KeyboardArrowDown } from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import React, { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseDeliveryFragment } from '@/graphql/defs/shared-fragments/__generated__/delivery.generated';
import LoadIcon from '@components/icons/load';
import Button from '@components/styled/Button';
import { useModalToggle } from '@context/modal/ModalToggleProvider';
import { useWarehouseFeatureFlags } from '@context/warehouse-feature-flags';
import { ModalTypes } from '@models/modal';
import MenuItem from '@styled/MenuItem';

interface IFulfillmentItemsActionMenu {
  deliveryDetails: BaseDeliveryFragment;
  hasDoorAssigned: boolean;
  refetchData: () => void;
  sx?: SxProps<Theme>;
}
const FulfillmentItemsActionMenu: FC<IFulfillmentItemsActionMenu> = ({
  deliveryDetails,
  refetchData,
  sx,
}) => {
  const { t } = useTranslation('components');
  const { openModal } = useModalToggle();
  const {
    warehouseFeatureFlags: { deliveries: deliveryFlags },
  } = useWarehouseFeatureFlags();

  const menuAnchor = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const openCreatePickTasksModal = () => {
    if (deliveryFlags.showLoadUnload) {
      openModal(
        {
          type: ModalTypes.pickToStagingCreate,
          fulfillmentId: deliveryDetails?.id,
          erpCode: deliveryDetails?.erpCode,
        },
        {
          afterClose: (success) => {
            if (success) {
              refetchData();
            }
          },
        },
      );
    } else {
      openModal(
        {
          type: ModalTypes.pickTaskCreate,
          fulfillmentId: deliveryDetails?.id,
          erpCode: deliveryDetails?.erpCode,
        },
        {
          afterClose: (success) => {
            if (success) {
              refetchData();
            }
          },
        },
      );
    }
  };

  // TODO: Can not create load tasks independently of pick tasks
  // const openCreateLoadTasksModal = () => {
  //   openModal(
  //     {
  //       type: ModalTypes.loadTaskCreate,
  //       fulfillmentId: deliveryDetails?.id,
  //       fulfillmentCode: deliveryDetails?.erpCode,
  //     },
  //     {
  //       afterClose: (success) => {
  //         if (success) {
  //           refetchData();
  //         }
  //       },
  //     },
  //   );
  // };

  return (
    <>
      <Button
        data-testid="fulfillmentItemsActions_button"
        ref={menuAnchor}
        variant="contained"
        styledVariant="actionMenu"
        color="primary"
        endIcon={<KeyboardArrowDown fontSize="inherit" />}
        onClick={() => setIsOpen(true)}
        sx={{
          fontWeight: 500,
          padding: '5px 25px',
          '& svg:nth-of-type(1)': { fontSize: 28 },
          ...sx,
        }}
      >
        {t('common.actions')}
      </Button>
      <Menu
        id="fulfillment-items-action-menu"
        data-testid="fulfillmentItemsAction_menu"
        anchorEl={menuAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onClick={() => setIsOpen(false)}
      >
        <MenuItem
          styledVariant="actionMenu"
          data-testid="actionItem_createPickTasks"
          onClick={openCreatePickTasksModal}
        >
          <ListItemIcon>
            <LoadIcon />
          </ListItemIcon>
          <ListItemText>
            {deliveryFlags.showLoadUnload
              ? t('deliveries.itemActionMenus.createPickLoadTasks')
              : t('deliveries.itemActionMenus.createPickTasks')}
          </ListItemText>
        </MenuItem>
        {/* 
          // TODO: Can not create load tasks independently of pick tasks          
          {deliveryFlags.showLoadUnload && (
          <MenuItem
            styledVariant="actionMenu"
            data-testid="actionItem_createLoadTasks"
            onClick={openCreateLoadTasksModal}
          >
            <ListItemIcon>
              <LoadIcon />
            </ListItemIcon>
            <ListItemText>{t('deliveries.itemActionMenus.createLoadTasks')}</ListItemText>
          </MenuItem>
        )} */}
      </Menu>
    </>
  );
};

export default FulfillmentItemsActionMenu;
