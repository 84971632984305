import { gql } from '@apollo/client';

import { GetWarehouseDropdownQuery } from '@/graphql/defs/components/__generated__/warehouse-dropdown.generated';

gql`
  fragment WarehouseDropdown on Warehouse {
    id
    name
  }
`;

gql`
  query GetWarehouseDropdown($orgId: ID!) {
    warehouses(
      filter: { company: { organizationId: { eq: $orgId } } }
      sorting: [{ field: name, direction: ASC }]
    ) {
      nodes {
        ...WarehouseDropdown
      }
    }
  }
`;

export const GetWarehouseDropdown_defaultData: GetWarehouseDropdownQuery = {
  __typename: 'Query',
  warehouses: {
    __typename: 'WarehouseOffsetConnection',
    nodes: [],
  },
};
