import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useDeleteOneFulfillmentBlockMutation,
  useUpdateOneFulfillmentBlockMutation,
} from '@/graphql/defs/components/modals/__generated__/update-fulfillment-block-modal.generated';
import { useListFulfillmentBlocksQuery } from '@/graphql/defs/list/__generated__/fulfillment-blocks.generated';
import { FulfillmentBlockUpdateInput } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import FormValues from '@models/FormValues';
import { IFulfillmentBlockUpdateModal } from '@models/modal';

const UpdateFulfillmentBlockModal = () => {
  const { t } = useTranslation('components');
  const { selectedWarehouseId } = useEntityUtils();
  const { showMessage } = useSnackbar();
  const { modal, closeModal, isPreparing, setPreparing, setLoading } =
    useModalContent<IFulfillmentBlockUpdateModal>();

  const [deleteFulfillmentBlock] = useDeleteOneFulfillmentBlockMutation({
    onCompleted: async ({ deleteOneFulfillmentBlock: { label } }) => {
      showMessage({
        type: 'success',
        message: t('modal.fulfillmentBlock.update.successDelete', { label }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
  });

  const formMethods = useForm<FormValues<FulfillmentBlockUpdateInput>>({
    defaultValues: {
      label: modal.fulfillmentBlock.label || '',
      description: modal.fulfillmentBlock.description || '',
    },
  });
  const { control, handleSubmit } = formMethods;
  const { notWhiteSpaceOnly } = useFormValidation();

  const [updateFulfillmentBlock] = useUpdateOneFulfillmentBlockMutation({
    onCompleted: async ({ updateOneFulfillmentBlock: { label } }) => {
      showMessage({
        type: 'success',
        message: t('modal.fulfillmentBlock.update.successUpdate', { label }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const { data: listFulfillmentBlocksData } = useListFulfillmentBlocksQuery({
    skip: !selectedWarehouseId,
    onCompleted: () => {
      setPreparing(false);
    },
    onError: (error) => {
      setPreparing(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = async (fields: FulfillmentBlockUpdateInput) => {
    updateFulfillmentBlock({
      variables: {
        input: {
          id: modal.fulfillmentBlock.id,
          update: {
            label: fields.label,
            description: fields.description,
          },
        },
      },
    });
  };

  const onDelete = () => {
    setLoading(true);
    deleteFulfillmentBlock({
      variables: {
        input: {
          id: modal.fulfillmentBlock.id,
        },
      },
    });
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Controller
                name="label"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.code') }),
                  validate: {
                    notWhiteSpaceOnly,
                    noDuplicats: (value) => {
                      return (
                        !listFulfillmentBlocksData.listFulfillmentBlocks.fulfillmentBlocks.some(
                          ({ label, id }) => label === value && id !== modal.fulfillmentBlock.id,
                        ) || t('fulfillmentBlocks.noDuplicateLabels')
                      );
                    },
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    autoFocus
                    required
                    id="fulfillmentBlock-label"
                    label={t('common.label')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={(e) => field.onChange(e)}
                    dataTestId="fulfillmentBlock-label"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    id="fulfillmentBlock-description"
                    label={t('common.description')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={(e) => field.onChange(e)}
                    dataTestId="fulfillmentBlock-description"
                  />
                )}
              />
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions>
          <ModalButton
            sx={{ marginRight: 'auto' }}
            onClick={onDelete}
            variant="outlined"
            color="error"
            actionType="cancel"
          >
            {t('common.delete')}
          </ModalButton>
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton variant="contained" color="primary" actionType="submit">
            {t('common.submit')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default UpdateFulfillmentBlockModal;
