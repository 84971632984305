import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  BinStorageTypeDetailsFragment,
  useBinStorageTypeDetailsQuery,
} from '@/graphql/defs/pages/__generated__/bin-storage-type.generated';
import Collapse from '@components/collapse';
import DataTable from '@components/data-table';
import useBinStorageTypeBinsDataTable from '@components/data-table/hooks/table-props/useBinStorageTypeBinsDataTable';
import useBinStorageTypeChangeHistoryDataTable from '@components/data-table/hooks/table-props/useBinStorageTypeChangeHistoryDataTable';
import { DataTableVariants } from '@components/data-table/model/data-table';
import { IColumn, JustifiedColumns } from '@components/justified-columns';
import CollapseLoading from '@components/loading-indicator/CollapseLoading';
import PageInfo from '@components/page-info';
import Typography from '@components/styled/Typography';
import { BIN_STORAGE_TYPES } from '@constants/routes';

const BinStorageTypeDetails = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'bin-storage-type-details' });
  const { t: tC } = useTranslation('components');
  const { id: binStorageTypeId } = useParams();

  const [hasInitializedData, setHasInitializedData] = useState(false);
  const [binStorageTypeDetails, setBinStorageTypeDetails] = useState(
    {} as BinStorageTypeDetailsFragment,
  );

  useBinStorageTypeDetailsQuery({
    variables: {
      id: binStorageTypeId,
    },
    onCompleted: ({ viewBinStorageTypes: { nodes } }) => {
      const _details = nodes[0];
      if (_details) {
        setBinStorageTypeDetails(_details);
      }
      setHasInitializedData(true);
    },
    onError: (error) => {
      console.error(error.message);
      setHasInitializedData(true);
    },
  });

  const detailColumns = useMemo<IColumn[]>(
    () => [
      {
        labelWidth: '150px',
        lineItems: [
          {
            label: tC('common.codeSuffix', { prefix: tC('common.warehouse') }),
            value: binStorageTypeDetails.warehouseCode,
          },
          {
            label: tC('common.weightCapacity'),
            value: `${binStorageTypeDetails.weightCapacity}${binStorageTypeDetails.weightCapacityUomCode}`,
          },
        ],
      },
      {
        labelWidth: '65px',
        lineItems: [
          {
            label: tC('common.depth'),
            value: `${binStorageTypeDetails.depth}${binStorageTypeDetails.distanceUomCode}`,
          },
          {
            label: tC('common.width'),
            value: `${binStorageTypeDetails.width}${binStorageTypeDetails.distanceUomCode}`,
          },
          {
            label: tC('common.depth'),
            value: `${binStorageTypeDetails.height}${binStorageTypeDetails.distanceUomCode}`,
          },
        ],
      },
    ],
    [binStorageTypeDetails, tC],
  );

  const { dataTableProps: binsDataTableProps } = useBinStorageTypeBinsDataTable(binStorageTypeId);
  const { dataTableProps: changeHistoryDataTableProps } =
    useBinStorageTypeChangeHistoryDataTable(binStorageTypeId);

  const titlePrefix = useMemo(() => {
    if (binStorageTypeDetails.label) {
      return t('titleWithLabel', { binStorageTypeLabel: binStorageTypeDetails.label });
    } else {
      return t('titleGeneric');
    }
  }, [binStorageTypeDetails.label]);
  const helmetTitle = tC('htmlTitleSuffix', { prefix: titlePrefix });

  return (
    <>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>

      <PageInfo
        title={
          <Typography variant="h2" align="left">
            {hasInitializedData
              ? `${binStorageTypeDetails.code} - ${binStorageTypeDetails.label}`
              : ''}
          </Typography>
        }
        subtitle={
          hasInitializedData && binStorageTypeDetails.description
            ? binStorageTypeDetails.description
            : ''
        }
        backButton
        backButtonRoute={BIN_STORAGE_TYPES}
      />

      <Collapse
        title={<Typography styledVariant="tableHeader">{t('titleGeneric')}</Typography>}
        isLoading={!hasInitializedData}
      >
        <JustifiedColumns columns={detailColumns} />
      </Collapse>

      {!hasInitializedData ? (
        <CollapseLoading />
      ) : (
        <DataTable {...binsDataTableProps} variant={DataTableVariants.Collapsible} />
      )}

      {!hasInitializedData ? (
        <CollapseLoading />
      ) : (
        <DataTable
          {...changeHistoryDataTableProps}
          variant={DataTableVariants.Collapsible}
          defaultCollapsed={true}
        />
      )}
    </>
  );
};

export default BinStorageTypeDetails;
