/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateFulfillmentHeaderMutationVariables = Types.Exact<{
  input: Types.UpdateOneDeliveryInput;
}>;


export type UpdateFulfillmentHeaderMutation = { __typename?: 'Mutation', updateFulfillmentHeader: { __typename?: 'Delivery', id: string } };


export const UpdateFulfillmentHeaderDocument = gql`
    mutation UpdateFulfillmentHeader($input: UpdateOneDeliveryInput!) {
  updateFulfillmentHeader: updateOneDelivery(input: $input) {
    id
  }
}
    `;
export type UpdateFulfillmentHeaderMutationFn = Apollo.MutationFunction<UpdateFulfillmentHeaderMutation, UpdateFulfillmentHeaderMutationVariables>;

/**
 * __useUpdateFulfillmentHeaderMutation__
 *
 * To run a mutation, you first call `useUpdateFulfillmentHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFulfillmentHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFulfillmentHeaderMutation, { data, loading, error }] = useUpdateFulfillmentHeaderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFulfillmentHeaderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFulfillmentHeaderMutation, UpdateFulfillmentHeaderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFulfillmentHeaderMutation, UpdateFulfillmentHeaderMutationVariables>(UpdateFulfillmentHeaderDocument, options);
      }
export type UpdateFulfillmentHeaderMutationHookResult = ReturnType<typeof useUpdateFulfillmentHeaderMutation>;
export type UpdateFulfillmentHeaderMutationResult = Apollo.MutationResult<UpdateFulfillmentHeaderMutation>;
export type UpdateFulfillmentHeaderMutationOptions = Apollo.BaseMutationOptions<UpdateFulfillmentHeaderMutation, UpdateFulfillmentHeaderMutationVariables>;