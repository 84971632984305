/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SlottingRunRowFragment = { __typename?: 'SlottingRun', id?: string | null, name: string, status: string, overall?: number | null, density?: number | null, efficiency?: number | null, created_at?: string | null, deployed_at?: string | null, deployment_ended_at?: string | null, warehouse_id: string, variant: string, ruleset_id: string, ruleset_name: string, dataset_id: string, dataset_name: string, created_by_user_id: string, created_by_user_name: string };

export type SlottingRunsDataTableQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.SlottingRunFilter>;
  sorting?: Types.InputMaybe<Array<Types.SlottingRunSort> | Types.SlottingRunSort>;
  includePageInfo?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeNodes?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeTotalCount?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type SlottingRunsDataTableQuery = { __typename?: 'Query', query: { __typename?: 'SlottingRunOffsetConnection', totalCount?: number, pageInfo?: { __typename?: 'OffsetPageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null }, nodes?: Array<{ __typename?: 'SlottingRun', id?: string | null, name: string, status: string, overall?: number | null, density?: number | null, efficiency?: number | null, created_at?: string | null, deployed_at?: string | null, deployment_ended_at?: string | null, warehouse_id: string, variant: string, ruleset_id: string, ruleset_name: string, dataset_id: string, dataset_name: string, created_by_user_id: string, created_by_user_name: string }> } };

export const SlottingRunRowFragmentDoc = gql`
    fragment SlottingRunRow on SlottingRun {
  id
  name
  status
  overall
  density
  efficiency
  created_at
  deployed_at
  deployment_ended_at
  warehouse_id
  variant
  ruleset_id
  ruleset_name
  dataset_id
  dataset_name
  created_by_user_id
  created_by_user_name
}
    `;
export const SlottingRunsDataTableDocument = gql`
    query SlottingRunsDataTable($paging: OffsetPaging, $filter: SlottingRunFilter, $sorting: [SlottingRunSort!], $includePageInfo: Boolean = true, $includeNodes: Boolean = true, $includeTotalCount: Boolean = false) {
  query: slottingRuns(paging: $paging, filter: $filter, sorting: $sorting) {
    pageInfo @include(if: $includePageInfo) {
      hasNextPage
      hasPreviousPage
    }
    nodes @include(if: $includeNodes) {
      ...SlottingRunRow
    }
    totalCount @include(if: $includeTotalCount)
  }
}
    ${SlottingRunRowFragmentDoc}`;

/**
 * __useSlottingRunsDataTableQuery__
 *
 * To run a query within a React component, call `useSlottingRunsDataTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlottingRunsDataTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlottingRunsDataTableQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      includePageInfo: // value for 'includePageInfo'
 *      includeNodes: // value for 'includeNodes'
 *      includeTotalCount: // value for 'includeTotalCount'
 *   },
 * });
 */
export function useSlottingRunsDataTableQuery(baseOptions?: Apollo.QueryHookOptions<SlottingRunsDataTableQuery, SlottingRunsDataTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SlottingRunsDataTableQuery, SlottingRunsDataTableQueryVariables>(SlottingRunsDataTableDocument, options);
      }
export function useSlottingRunsDataTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SlottingRunsDataTableQuery, SlottingRunsDataTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SlottingRunsDataTableQuery, SlottingRunsDataTableQueryVariables>(SlottingRunsDataTableDocument, options);
        }
export type SlottingRunsDataTableQueryHookResult = ReturnType<typeof useSlottingRunsDataTableQuery>;
export type SlottingRunsDataTableLazyQueryHookResult = ReturnType<typeof useSlottingRunsDataTableLazyQuery>;
export type SlottingRunsDataTableQueryResult = Apollo.QueryResult<SlottingRunsDataTableQuery, SlottingRunsDataTableQueryVariables>;