import { Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useUpdateBusinessPartnerMutation } from '@/graphql/defs/components/modals/__generated__/update-business-partner-modal.generated';
import { BusinessPartnerUpdateInput } from '@/graphql/types.generated';
import DateTimePicker from '@components/date-time-picker';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { LANGUAGES } from '@constants/languages';
import { TIME_ZONES } from '@constants/time-zones';
import { WORLD_COUNTRIES } from '@constants/world-countries';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import decodeHtmlEntities from '@lib/decode-html-entities';
import FormValues from '@models/FormValues';
import { IBusinessPartnerUpdateModal } from '@models/modal';

const UpdateBusinessPartnerModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, isPreparing, setLoading } =
    useModalContent<IBusinessPartnerUpdateModal>();
  const { showMessage } = useSnackbar();
  const { businessPartner } = modal;
  const formMethods = useForm<FormValues<BusinessPartnerUpdateInput>>({
    defaultValues: {
      addressTimezone: businessPartner.addressTimezone || '',
      addressUUID: businessPartner.addressUUID || '',
      cityName: businessPartner.cityName || '',
      code: businessPartner.code || '',
      country: businessPartner.country || '',
      customerCode: businessPartner.customerCode || '',
      defaultPhoneNumber: businessPartner.defaultPhoneNumber || '',
      district: businessPartner.district || '',
      email: businessPartner.email || '',
      language: businessPartner.language || '',
      name: businessPartner.name || '',
      phoneNumber: businessPartner.phoneNumber || '',
      postalCode: businessPartner.postalCode || '',
      region: businessPartner.region || '',
      searchTerm1: businessPartner.searchTerm1 || '',
      searchTerm2: businessPartner.searchTerm2 || '',
      streetAddress: businessPartner.streetAddress || '',
      supplierCode: businessPartner.supplierCode || '',
      type: businessPartner.type || '',
      validityEnd: businessPartner.validityEnd || '',
      validityStart: businessPartner.validityStart || '',
    },
  });
  const { control, handleSubmit } = formMethods;
  const { notWhiteSpaceOnly, validDateTime, validEmail } = useFormValidation();

  const [updateBusinessPartner] = useUpdateBusinessPartnerMutation({
    onCompleted: ({ updateOneBusinessPartner: { name } }) => {
      showMessage({
        type: 'success',
        message: t('modal.businessPartner.createSuccess', { name }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onSubmit = (businessPartnerFields) => {
    const input = {
      id: businessPartner.id,
      update: Object.entries(businessPartnerFields).reduce((acc, [key, value]) => {
        if (businessPartner[key] !== value) {
          acc[key] = value;
        }
        return acc;
      }, {}),
    };
    void updateBusinessPartner({
      variables: {
        input,
      },
    });
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <Controller
                name="code"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.code') }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    autoFocus
                    required
                    fullWidth
                    dataTestId="businessPartner-code"
                    label={t('common.code')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.name') }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    dataTestId="businessPartner-name"
                    label={t('common.name')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="searchTerm1"
                control={control}
                rules={{
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    dataTestId="businessPartner-search-term-1"
                    label={t('modal.businessPartner.searchTerm1')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="searchTerm2"
                control={control}
                rules={{
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    dataTestId="businessPartner-search-term-2"
                    label={t('modal.businessPartner.searchTerm2')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="validityStart"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('modal.businessPartner.validityStart'),
                  }),
                  validate: {
                    validDateTime,
                  },
                }}
                render={({ field, fieldState }) => (
                  <DateTimePicker
                    required
                    label={t('modal.businessPartner.validityStart')}
                    field={field}
                    fieldState={fieldState}
                    setDate={field.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="validityEnd"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('modal.businessPartner.validityEnd'),
                  }),
                  validate: {
                    validDateTime,
                  },
                }}
                render={({ field, fieldState }) => (
                  <DateTimePicker
                    required
                    label={t('modal.businessPartner.validityEnd')}
                    field={field}
                    fieldState={fieldState}
                    setDate={field.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="streetAddress"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.street') }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    dataTestId="businessPartner-street"
                    label={t('common.street')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="cityName"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.city') }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    dataTestId="businessPartner-city"
                    label={t('common.city')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="district"
                control={control}
                rules={{
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    dataTestId="businessPartner-district"
                    label={t('common.district')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="region"
                control={control}
                rules={{
                  required: decodeHtmlEntities(
                    t('form.validation.requiredField', { field: t('common.region-state') }),
                  ),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  // TODO - Replace with <StatesDropdown />
                  <TextField
                    {...field}
                    required
                    fullWidth
                    dataTestId="businessPartner-region-state"
                    label={t('common.region-state')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="addressTimezone"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.timezone'),
                  }),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    id="businessPartner-timezone"
                    label={t('common.timezone')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    select
                    SelectProps={{ native: true }}
                    dataTestId="businessPartner-timezone"
                  >
                    <option disabled />
                    {TIME_ZONES.map((zone, i) => (
                      <option key={zone} value={zone}>
                        {zone}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="country"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.country'),
                  }),
                }}
                render={({ field, fieldState }) => (
                  // TODO - Replace with <CountriesDropdown />
                  <TextField
                    {...field}
                    fullWidth
                    required
                    id="businessPartner-country"
                    label={t('common.country')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    select
                    SelectProps={{ native: true }}
                    dataTestId="businessPartner-country"
                  >
                    <option disabled />
                    {WORLD_COUNTRIES.map(({ name, code }, i) => (
                      <option key={code} value={code}>
                        {name}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="postalCode"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('common.postalCode') }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    dataTestId="businessPartner-postal-code"
                    label={t('common.postalCode')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="language"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.language'),
                  }),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    id="businessPartner-language"
                    label={t('common.language')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    select
                    SelectProps={{ native: true }}
                    dataTestId="businessPartner-language"
                  >
                    <option disabled />
                    {LANGUAGES.map(({ name, code }, i) => (
                      <option key={code} value={code}>
                        {name}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    dataTestId="businessPartner-phone-number"
                    label={t('common.phoneNumber')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="email"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('common.email'),
                  }),
                  validate: {
                    notWhiteSpaceOnly,
                    validEmail,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    dataTestId="businessPartner-email"
                    label={t('common.email')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions>
          <ModalButton
            onClick={() => closeModal()}
            variant="outlined"
            color="primary"
            actionType="cancel"
          >
            {t('common.cancel')}
          </ModalButton>
          <ModalButton
            data-testid="createBusinessPartnerModal_submit"
            variant="contained"
            color="primary"
            actionType="submit"
          >
            {t('common.submit')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default UpdateBusinessPartnerModal;
