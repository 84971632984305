const PutawayIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2182 24L12.2182 16.35L24 16.35L24 7.65L12.2182 7.65L12.2182 -5.34074e-07L-5.24537e-07 12L12.2182 24Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PutawayIcon;
