import { useEffect, useState } from 'react';

import {
  DeliveryCompletionStatus,
  ProductAvailability,
  SlottingAnalysisStatus,
  SlottingLoadStatus,
  TaskStatus,
  WarehouseOpFileRowStatus,
} from '@/graphql/types.generated';
import { RunStatus } from '@components/data-table/hooks/shared-columns/useCreateSlottingRunsColumns';
import StatusValues from '@models/StatusValues';

export type TStatusColor = '#162922' | '#B52B2B' | '#219653' | '#5EB1E3';

const useStatusColor = (
  status:
    | TaskStatus
    | DeliveryCompletionStatus
    | ProductAvailability
    | StatusValues
    | WarehouseOpFileRowStatus
    | SlottingLoadStatus
    | SlottingAnalysisStatus
    | RunStatus,
) => {
  const [currentStatusColor, setCurrentStatusColor] = useState<TStatusColor>('#162922');

  useEffect(() => {
    switch (status) {
      case DeliveryCompletionStatus.NotStarted:
      case ProductAvailability.Blocked:
      case StatusValues.Blocked:
      case StatusValues.False:
      case StatusValues.NotStarted:
      case TaskStatus.Cancelled:
      case TaskStatus.NotStarted:
      case WarehouseOpFileRowStatus.Failed:
      case SlottingLoadStatus.NotStarted:
      case SlottingAnalysisStatus.NotStarted:
      case SlottingAnalysisStatus.Failed:
      case SlottingAnalysisStatus.Cancelled:
      case RunStatus['run aborted: invalid warehouse']:
      case StatusValues.Locked:
        //case SlottingLoadStatus.Error: // TODO: add eror
        setCurrentStatusColor('#B52B2B');
        break;
      case DeliveryCompletionStatus.InProgress:
      case RunStatus.initialized:
      case StatusValues.InProgress:
      case SlottingLoadStatus.InProgress:
      case StatusValues.OutBound:
      case StatusValues.Remaining:
      case TaskStatus.InProgress:
      case WarehouseOpFileRowStatus.Queued:
      case WarehouseOpFileRowStatus.Received:
      case SlottingAnalysisStatus.InProgress:
      case RunStatus['run started']:
      case RunStatus['calculating pre metrics']:
      case RunStatus['calculating post metrics']:
        setCurrentStatusColor('#5EB1E3');
        break;
      case DeliveryCompletionStatus.Complete:
      case ProductAvailability.Available:
      case StatusValues.Available:
      case StatusValues.Complete:
      case RunStatus['run complete']:
      case StatusValues.Inbound:
      case StatusValues.True:
      case TaskStatus.Complete:
      case SlottingLoadStatus.Complete:
      case SlottingAnalysisStatus.Complete:
      case WarehouseOpFileRowStatus.Processed:
      case StatusValues.Unlocked:
        setCurrentStatusColor('#219653');
        break;
      default:
        setCurrentStatusColor('#162922');
    }
  }, [status]);

  return currentStatusColor;
};

export default useStatusColor;
