import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useZoneBinsSettingsTableLazyQuery } from '@/graphql/defs/hooks/table-props/__generated__/useZoneBinsSettingsDataTable.generated';
import { ViewZoneBinMappedSortFields } from '@/graphql/types.generated';
import {
  ColumnFilter,
  ColumnTypes,
  FilterOperators,
} from '@components/data-table/controls/filter/filter-definitions';
import useCreateDataTableColumns from '@components/data-table/hooks/useCreateDataTableColumns';
import useDataTableSelection, {
  SelectionType,
} from '@components/data-table/hooks/useDataTableSelection';
import useTriggerDataTableRefetch from '@components/data-table/hooks/useTriggerDataTableRefetch';
import { validateDataTableProps } from '@components/data-table/lib/validateDataTableProps';
import { DataTableVariants, IDataTableSort } from '@components/data-table/model/data-table';
import {
  TExtractLazyHookDataType,
  TExtractLazyHookFetchFilterType,
  TExtractLazyHookFieldNames,
} from '@components/data-table/model/extract-query-hook-types';
import TableIds from '@components/data-table/model/TableIds';
import LinkCell from '@components/data-table/table/cells/LinkCell';
import { BIN } from '@constants/routes';
import { useEntityUtils } from '@context/entity-utils';

const LAZY_QUERY_HOOK = useZoneBinsSettingsTableLazyQuery;
type _TDataType = TExtractLazyHookDataType<typeof LAZY_QUERY_HOOK>;
type _TFilterType = TExtractLazyHookFetchFilterType<typeof LAZY_QUERY_HOOK>;
type _TFieldNames = TExtractLazyHookFieldNames<typeof LAZY_QUERY_HOOK>;

export enum ZoneEntityTableVersion {
  Display,
  Add,
  Remove,
}

const useZoneBinsSettingsDataTable = ({
  zoneId,
  version = ZoneEntityTableVersion.Display,
}: {
  zoneId: string;
  version?: ZoneEntityTableVersion;
}) => {
  const { t } = useTranslation('components');
  const { selectedWarehouseId } = useEntityUtils();

  const { selection, clearSelection, rowSelection } = useDataTableSelection<_TDataType>(
    version === ZoneEntityTableVersion.Display ? SelectionType.none : SelectionType.multi,
    version === ZoneEntityTableVersion.Display ? undefined : 'binId',
  );
  const [refetchTrigger, triggerDataTableRefetch] = useTriggerDataTableRefetch({
    triggerCallback: clearSelection,
  });

  const columns = useCreateDataTableColumns<_TDataType>(
    (columnHelper) => [
      columnHelper.accessor('binCode', {
        header: t('common.code'),
        cell: ({ row, getValue }) => (
          <LinkCell
            href={`${BIN}/${row.original.binId}`}
            text={getValue()}
            dataTestId="zone-bins-settings-bin-link"
          />
        ),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
      columnHelper.accessor('binType', {
        header: t('common.type'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
      columnHelper.accessor('inactive', {
        header: t('common.inactive'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
      columnHelper.accessor('sourceBinBlock', {
        header: t('common.sourceBinBlock'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
      columnHelper.accessor('destinationBinBlock', {
        header: t('common.destinationBinBlock'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
      columnHelper.accessor('level', {
        header: t('common.level'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
      columnHelper.accessor('x', {
        header: t('common.x'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
      columnHelper.accessor('y', {
        header: t('common.y'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
      columnHelper.accessor('z', {
        header: t('common.z'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
        },
      }),
    ],
    [],
  );

  const baseFilter = useMemo<ColumnFilter<_TFieldNames>[]>(
    () => [
      {
        columnId: ViewZoneBinMappedSortFields.BinType,
        operator: FilterOperators.notIn,
        value: ['disposition', 'lostAndFound', 'planned'],
      },
      {
        columnId: ViewZoneBinMappedSortFields.WarehouseId,
        operator: FilterOperators.eq,
        value: selectedWarehouseId,
      },
      {
        columnId: ViewZoneBinMappedSortFields.ZoneId,
        operator: FilterOperators.eq,
        value: zoneId,
      },
    ],
    [selectedWarehouseId, version],
  );

  const defaultSorting: IDataTableSort<_TFieldNames>[] = useMemo(
    () => [
      {
        id: ViewZoneBinMappedSortFields.BinCode,
        desc: false,
      },
    ],
    [],
  );

  return {
    triggerDataTableRefetch,
    selectedBins: version === ZoneEntityTableVersion.Display ? undefined : selection,
    zoneBinsSettingsDataTableProps: validateDataTableProps<_TDataType, _TFilterType, _TFieldNames>({
      variant:
        version === ZoneEntityTableVersion.Display
          ? DataTableVariants.Collapsible
          : DataTableVariants.Basic,
      type: 'query',
      tableId: setTableId(version),
      tableHeader: t('zone.zoneBins.title'),
      columns,
      queryHook: LAZY_QUERY_HOOK,
      baseFilter,
      defaultSorting,
      refetchTrigger,
      defaultCollapsed: version === ZoneEntityTableVersion.Display,
      rowSelection,
    }),
  };
};

const setTableId = (version) => {
  switch (version) {
    case ZoneEntityTableVersion.Display:
      return TableIds.ZoneBinsSettingsDisplay;
    case ZoneEntityTableVersion.Remove:
      return TableIds.ZoneBinsSettingsRemove;
  }
};

export default useZoneBinsSettingsDataTable;
