const UnloadIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.7879 8.47369V6.73128V3.33301H11.8182C10.8182 3.33301 10 4.15119 10 5.15119V14.9131H11.8182C11.8182 16.4222 13.0364 17.6404 14.5455 17.6404C16.0545 17.6404 17.2727 16.4222 17.2727 14.9131H17.7273H18.3333H18.7879V10.1404V8.47369ZM14.5455 15.8222C14.0455 15.8222 13.6364 15.4131 13.6364 14.9131C13.6364 14.4131 14.0455 14.004 14.5455 14.004C15.0455 14.004 15.4545 14.4131 15.4545 14.9131C15.4545 15.4131 15.0455 15.8222 14.5455 15.8222Z"
        fill="currentColor"
      />
      <path
        d="M3.74994 16.249C3.74994 16.249 4.45195 17.1053 4.99984 17.0824C5.54773 17.0595 12.9181 14.9584 12.9181 14.9584L12.9163 13.7527C12.9163 13.7527 3.28181 16.2776 3.74994 16.249Z"
        fill="currentColor"
      />
      <path
        d="M5.32381 11.6331L5.32381 14.3467L0.833336 10.0901L5.32381 5.83349L5.32381 8.54707L8.69167 8.54707L8.69167 11.6331L5.32381 11.6331Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default UnloadIcon;
