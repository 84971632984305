import { Theme, Tooltip as MUITooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import React from 'react';

type TVariants = 'primaryBg';

interface IStyledTooltip {
  styledVariant?: TVariants;
}

const styledVariants = (theme: Theme): Record<TVariants, Record<string, any>> => ({
  primaryBg: {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#0a3a57',
      color: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#0a3a57',
    },
  },
});

const Tooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <MUITooltip {...props} classes={{ popper: className }} />
  ),
  {
    shouldForwardProp: (prop) => prop !== 'styledVariant',
  },
)<IStyledTooltip>(({ styledVariant, theme }) => styledVariants(theme)[styledVariant]);

export default Tooltip;
