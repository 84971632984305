import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateOnePhysicalInventoryTaskMutation } from '@/graphql/defs/components/modals/__generated__/inventory-adjustment-modal.generated';
import { AdjustmentType, TaskCreatePiInput } from '@/graphql/types.generated';
import { ModalActions } from '@components/modal/modal-actions';
import { ModalButton } from '@components/modal/modal-button';
import { ModalContent } from '@components/modal/modal-content';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useModalToggle } from '@context/modal/ModalToggleProvider';
import { useSnackbar } from '@context/snackbar';
import { IInventoryAdjustmentModal, ModalTypes } from '@models/modal';

export const InventoryAdjustmentModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();

  const [createInventoryTask] = useCreateOnePhysicalInventoryTaskMutation();

  const [shouldAutocomplete, setShouldAutocomplete] = useState(false);
  const formMethods = useForm();
  const { control, handleSubmit } = formMethods;

  const { openModal } = useModalToggle();
  const { modal, closeModal, setLoading } = useModalContent<IInventoryAdjustmentModal>();
  const { inventoryItem: ii } = modal;

  const onSubmit = async ({ newCount, adjustmentType }) => {
    try {
      const cycleCountPI: TaskCreatePiInput = {
        binId: ii.binId,
        warehouseId: ii.warehouseId,
        createdInUnitOfMeasureId: ii.unitOfMeasureId,
        productId: ii.productId,
        lotId: ii.lotId,
        stockStatusId: ii.stockStatusTypeId,
        autocomplete: shouldAutocomplete,
        licensePlateId: ii.licensePlateId,
      };
      if (shouldAutocomplete) {
        cycleCountPI.adjustmentType = adjustmentType;
        cycleCountPI.newCount = newCount ? newCount : null;
        if (adjustmentType === AdjustmentType.DamagedProduct)
          cycleCountPI.damagedCount = newCount ? newCount : null;
      }

      const {
        data: { createOnePhysicalInventoryTask: approvalTask },
      } = await createInventoryTask({
        variables: {
          input: {
            physicalInventory: cycleCountPI,
          },
        },
        onError: (error) => {
          showMessage({
            type: 'error',
            message: error.message,
          });
        },
      });

      // If autocomplete, then go to approve step - otherwise close modal and show success message
      if (shouldAutocomplete) {
        openModal({
          type: ModalTypes.approveQuantityAdjustment,
          approvalTask,
        });
      } else {
        showMessage({
          type: 'success',
          message: t('modal.task.cycleCountCreated', { taskCode: approvalTask.code }),
        });

        closeModal({ bypassLoading: true, success: true });
      }
    } catch (error) {
      // TODO: handle errors
    }

    setLoading(false);
  };

  const onCancel = () => {
    closeModal();
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <Box sx={{ width: 600, marginBottom: '15px' }}>
          <Grid container>
            <Grid xs={3} item>
              <Typography variant="body1" align="left">
                {t('common.product')}:
              </Typography>
            </Grid>
            <Grid xs={9} item>
              <Typography variant="body1" align="left">
                <strong>{ii.productCode}</strong>
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={3} item>
              <Typography variant="body1" align="left">
                {t('common.description')}:
              </Typography>
            </Grid>
            <Grid xs={9} item>
              <Typography variant="body1" align="left">
                {ii.description}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={3} item>
              <Typography variant="body1" align="left">
                {t('common.unitOfMeasure')}:
              </Typography>
            </Grid>
            <Grid xs={9} item>
              <Typography variant="body1" align="left">
                {ii.unitOfMeasure}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={3} item>
              <Typography variant="body1" align="left">
                {t('common.area')}:
              </Typography>
            </Grid>
            <Grid xs={9} item>
              <Typography variant="body1" align="left">
                {ii.areaCode}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={3} item>
              <Typography variant="body1" align="left">
                {t('common.bin')}:
              </Typography>
            </Grid>
            <Grid xs={9} item>
              <Typography variant="body1" align="left">
                {ii.binCode}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={3} item>
              <Typography variant="body1" align="left">
                {t('common.licensePlate')}:
              </Typography>
            </Grid>
            <Grid xs={9} item>
              <Typography variant="body1" align="left">
                {ii.licensePlateCode || t('common.n/a')}
              </Typography>
            </Grid>
          </Grid>
          {/* ===================== */}
          <Grid container>
            <Grid xs={3} item>
              <Typography variant="body1" align="left">
                {t('common.lot')}:
              </Typography>
            </Grid>
            <Grid xs={9} item>
              <Typography variant="body1" align="left">
                {ii.lotCode || t('common.n/a')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={3} item>
              <Typography variant="body1" align="left">
                {t('common.stockStatus')}:
              </Typography>
            </Grid>
            <Grid xs={9} item>
              <Typography variant="body1" align="left">
                {ii.stockStatus}
              </Typography>
            </Grid>
          </Grid>
          {/* ===================== */}
        </Box>
        <Box sx={{ marginBottom: '15px' }}>
          <Grid container>
            <Grid xs={3} item>
              <Typography variant="body1" align="left">
                {t('modal.task.currentCount')}:
              </Typography>
            </Grid>
            <Grid xs={9} item>
              <Typography variant="body1" align="left">
                <strong>{ii.quantity}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ marginBottom: '15px' }}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setShouldAutocomplete(e?.target?.checked || false);
                }}
              />
            }
            label={t('modal.task.markAsComplete')}
          />
        </Box>
        {shouldAutocomplete && (
          <Box sx={{ marginBottom: '25px' }}>
            <Typography variant="body3" align="left" sx={{ marginBottom: '5px' }}>
              {t('modal.task.reasonForAdjustmentQuestion')}
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Controller
                  name="adjustmentType"
                  control={control}
                  rules={{
                    required: t('form.validation.requiredField', {
                      field: t('modal.task.reasonForAdjustment'),
                    }),
                  }}
                  defaultValue=""
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        {...field}
                        fullWidth
                        autoFocus
                        required
                        id="reason-for-adjustment"
                        label={t('modal.task.reasonForAdjustment')}
                        error={!!fieldState?.error}
                        helperText={fieldState?.error?.message}
                        select
                        SelectProps={{ native: true }}
                        dataTestId="reason-for-adjustment"
                      >
                        <option disabled />
                        <option value={AdjustmentType.PhysicalInventory}>
                          {t('modal.task.physicalInventory')}
                        </option>
                        <option value={AdjustmentType.DamagedProduct}>
                          {
                            t(
                              'modal.task.damagedProduct',
                            ) /* TODO: add pi adjustment for damaged product */
                          }
                        </option>
                      </TextField>
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="newCount"
                  control={control}
                  rules={{
                    required: t('form.validation.requiredField', {
                      field: t('modal.task.newCount'),
                    }),
                    pattern: /[0-9]/,
                  }}
                  defaultValue=""
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        {...field}
                        fullWidth
                        required
                        id="newCount"
                        label={t('modal.task.newCount')}
                        error={!!fieldState?.error}
                        helperText={fieldState?.error?.message}
                        dataTestId="newCount"
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </ModalContent>
      <ModalActions>
        <ModalButton variant="outlined" color="primary" onClick={onCancel} actionType="cancel">
          {t('common.cancel')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" actionType="submit">
          {t('common.submit')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};
