/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserGroupMappingsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.UserGroupMappingFilter>;
}>;


export type UserGroupMappingsQuery = { __typename?: 'Query', userGroupMappings: { __typename?: 'UserGroupMappingOffsetConnection', nodes: Array<{ __typename?: 'UserGroupMapping', createdAt: string, deletedAt?: string | null, id: string, updatedAt?: string | null, userGroupId: string, userId: string }> } };

export type UpdateUsersForUserGroupMutationVariables = Types.Exact<{
  input: Types.UpdateUsersForUserGroupMappingInput;
}>;


export type UpdateUsersForUserGroupMutation = { __typename?: 'Mutation', updateUsersForUserGroup: string };


export const UserGroupMappingsDocument = gql`
    query UserGroupMappings($filter: UserGroupMappingFilter) {
  userGroupMappings(filter: $filter) {
    nodes {
      createdAt
      deletedAt
      id
      updatedAt
      userGroupId
      userId
    }
  }
}
    `;

/**
 * __useUserGroupMappingsQuery__
 *
 * To run a query within a React component, call `useUserGroupMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupMappingsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUserGroupMappingsQuery(baseOptions?: Apollo.QueryHookOptions<UserGroupMappingsQuery, UserGroupMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupMappingsQuery, UserGroupMappingsQueryVariables>(UserGroupMappingsDocument, options);
      }
export function useUserGroupMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupMappingsQuery, UserGroupMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupMappingsQuery, UserGroupMappingsQueryVariables>(UserGroupMappingsDocument, options);
        }
export type UserGroupMappingsQueryHookResult = ReturnType<typeof useUserGroupMappingsQuery>;
export type UserGroupMappingsLazyQueryHookResult = ReturnType<typeof useUserGroupMappingsLazyQuery>;
export type UserGroupMappingsQueryResult = Apollo.QueryResult<UserGroupMappingsQuery, UserGroupMappingsQueryVariables>;
export const UpdateUsersForUserGroupDocument = gql`
    mutation UpdateUsersForUserGroup($input: UpdateUsersForUserGroupMappingInput!) {
  updateUsersForUserGroup(input: $input)
}
    `;
export type UpdateUsersForUserGroupMutationFn = Apollo.MutationFunction<UpdateUsersForUserGroupMutation, UpdateUsersForUserGroupMutationVariables>;

/**
 * __useUpdateUsersForUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUsersForUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUsersForUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUsersForUserGroupMutation, { data, loading, error }] = useUpdateUsersForUserGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUsersForUserGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUsersForUserGroupMutation, UpdateUsersForUserGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUsersForUserGroupMutation, UpdateUsersForUserGroupMutationVariables>(UpdateUsersForUserGroupDocument, options);
      }
export type UpdateUsersForUserGroupMutationHookResult = ReturnType<typeof useUpdateUsersForUserGroupMutation>;
export type UpdateUsersForUserGroupMutationResult = Apollo.MutationResult<UpdateUsersForUserGroupMutation>;
export type UpdateUsersForUserGroupMutationOptions = Apollo.BaseMutationOptions<UpdateUsersForUserGroupMutation, UpdateUsersForUserGroupMutationVariables>;