import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

import { useAuth } from './auth';
import { useEntityUtils } from './entity-utils';

import { useGetPermissionsLazyQuery } from '@/graphql/defs/context/__generated__/permissions.generated';
import { useSnackbar } from '@context/snackbar';

interface IPermissionsContext {
  permissions: {
    [key: string]: boolean;
  };
  loading: boolean;
}

const PermissionsContext = createContext<IPermissionsContext>({
  permissions: {},
  loading: true,
});

const PermissionsProvider = ({ children }) => {
  const { user } = useAuth();
  const { selectedWarehouseId } = useEntityUtils();
  const { showMessage } = useSnackbar();

  const hasInitializedPermissions = useRef(false);
  const [loading, setLoading] = useState(false);

  const [permissions, setPermissions] = useState({});
  const [getPermissions] = useGetPermissionsLazyQuery();
  const loadPermissions = async (warehouseId) => {
    setLoading(true);
    try {
      const {
        data: { permissions },
      } = await getPermissions({
        variables: {
          warehouseId,
        },
      });
      await setPermissions(
        permissions.reduce((acc, { code }) => {
          acc[code] = true;
          return acc;
        }, {}),
      );
    } catch (e) {
      showMessage({ type: 'error', message: e.message });
    }
    setLoading(false);
    if (hasInitializedPermissions) hasInitializedPermissions.current = true;
  };

  useEffect(() => {
    if (user?.email) {
      loadPermissions(selectedWarehouseId);
    }
  }, [selectedWarehouseId, user?.email]);

  return (
    <PermissionsContext.Provider value={{ permissions, loading }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export default PermissionsProvider;

export const usePermissions = () => {
  const ctx = useContext(PermissionsContext);
  if (ctx === null) {
    throw new Error('usePermissions must be used within PermissionsProvider');
  }

  return ctx;
};
