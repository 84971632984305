/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { BaseWarehouseFragmentDoc } from './warehouse.generated';
export type BaseDeliveryFragment = { __typename?: 'Delivery', id: string, type: Types.DeliveryType, billOfLading?: string | null, erpCreated?: string | null, erpBlockingReason?: string | null, dueDate?: string | null, erpCode: string, totalGrossWeight?: number | null, totalNetWeight?: number | null, totalWeightUOMId?: string | null, erpLastChanged?: string | null, erpSalesOrder?: string | null, goodsReceiptOrIssueStatus?: Types.DeliveryCompletionStatus | null, hasTasks: boolean, pickOrPutawayStatus?: Types.DeliveryCompletionStatus | null, deliveryStatus?: Types.DeliveryCompletionStatus | null, supplier?: string | null, supplierBusinessPartnerId?: string | null, blockStatus?: string | null, supplierName?: string | null, promiseDate?: string | null, pointOfContact?: string | null, shipReadyDate?: string | null, totalVolume?: number | null, totalVolumeUOMId?: string | null, export?: string | null, shipTo?: string | null, shipToBusinessPartnerId?: string | null, shipToName?: string | null, soldTo?: string | null, soldToBusinessPartnerId?: string | null, soldToName?: string | null, progress?: number | null, progressString?: Types.DeliveryCompletionStatus | null, createdAt: string, updatedAt?: string | null, deletedAt?: string | null, warehouse?: { __typename?: 'Warehouse', id: string, code: string, name?: string | null } | null, fulfillmentBlock?: { __typename?: 'FulfillmentBlock', label: string, description?: string | null, id: string } | null };

export const BaseDeliveryFragmentDoc = gql`
    fragment BaseDelivery on Delivery {
  id
  type
  billOfLading
  erpCreated
  erpBlockingReason
  dueDate
  erpCode
  totalGrossWeight
  totalNetWeight
  totalWeightUOMId
  erpLastChanged
  erpSalesOrder
  goodsReceiptOrIssueStatus
  hasTasks
  pickOrPutawayStatus
  deliveryStatus
  warehouse {
    ...BaseWarehouse
  }
  fulfillmentBlock {
    label
    description
    id
  }
  supplier
  supplierBusinessPartnerId
  blockStatus
  supplierName
  promiseDate
  pointOfContact
  shipReadyDate
  totalVolume
  totalVolumeUOMId
  export
  shipTo
  shipToBusinessPartnerId
  shipToName
  soldTo
  soldToBusinessPartnerId
  soldToName
  progress
  progressString
  createdAt
  updatedAt
  deletedAt
}
    ${BaseWarehouseFragmentDoc}`;