import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDateTime } from '@context/date-time';

const DateRangePicker = ({
  dateRange,
  setDateRange,
  direction = 'row',
  onClear = undefined,
  startLabel = undefined,
  endLabel = undefined,
  isDisabled = false,
  dataTestId = 'date-range-picker',
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'dateRangePicker' });
  const { dateTimeToWHTimezone } = useDateTime();

  const [internalDateRange, setInternalDateRange] = useState<[DateTime, DateTime]>(dateRange);

  useEffect(() => {
    if (dateRange[0] !== internalDateRange[0] || dateRange[1] !== internalDateRange[1]) {
      setInternalDateRange(dateRange);
    }
  }, [dateRange[0], dateRange[1]]);

  return (
    <MobileDateRangePicker
      disabled={isDisabled}
      componentsProps={{
        actionBar: {
          actions: ['clear', 'cancel', 'accept'],
        },
      }}
      onAccept={(accepted: DateTime[]) => {
        if (dateRange[0] !== accepted[0] || dateRange[1] !== accepted[1]) {
          setDateRange([
            dateTimeToWHTimezone(accepted[0])?.startOf('day') || null,
            dateTimeToWHTimezone(accepted[1])?.endOf('day') || null,
          ]);
          if (onClear && !accepted[0] && !accepted[1]) {
            onClear();
          }
        }
      }}
      value={internalDateRange}
      onChange={(dateRange: [DateTime, DateTime]) => {
        // If the From is less than Start, or Start is not defined/valid, then set From to Start
        // This way the user can't create a range where Start > From.
        if (
          dateRange[1]?.isValid &&
          (!dateRange[0]?.isValid || dateRange[1]?.toMillis() < dateRange[0]?.toMillis())
        ) {
          setInternalDateRange([dateTimeToWHTimezone(dateRange[1])?.startOf('day'), null]);
        } else {
          setInternalDateRange([
            dateRange[0]?.isValid ? dateTimeToWHTimezone(dateRange[0])?.startOf('day') : null,
            dateRange[1]?.isValid ? dateTimeToWHTimezone(dateRange[1])?.endOf('day') : null,
          ]);
        }
      }}
      renderInput={(startProps, endProps) => {
        startProps.label = startLabel ? startLabel : t('from');
        startProps.inputProps['data-testid'] = 'date-range-from';
        endProps.label = endLabel ? endLabel : t('to');
        endProps.inputProps['data-testid'] = 'date-range-to';
        return (
          <Box sx={{ display: 'flex', flexDirection: direction }}>
            <Box sx={{ marginRight: '10px', width: '155px' }}>
              <StyledTextField
                {...startProps}
                variant="standard"
                autoComplete="off"
                data-testid={`${dataTestId}-start`}
              />
            </Box>
            <Box sx={{ marginRight: '10px', width: '155px' }}>
              <StyledTextField
                {...endProps}
                variant="standard"
                autoComplete="off"
                data-testid={`${dataTestId}-end`}
              />
            </Box>
          </Box>
        );
      }}
    />
  );
};

export default DateRangePicker;

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderBottom: '0px solid #5EB1E3',
  '& .MuiInput-underline:after': {
    height: '2px',
    width: '100%',
    backgroundColor: '#5EB1E3',
    borderRadius: '1px',
    borderBottom: '0px solid #5EB1E3',
  },
  '& .MuiInput-underline:before': {
    height: '2px',
    width: '100%',
    backgroundColor: '#5EB1E3',
    borderRadius: '1px',
    borderBottom: '0px solid #5EB1E3',
  },
  '&:hover .MuiInput-underline:before': {
    borderBottom: '3px solid #5EB1E3',
  },
  '& .MuiInputBase-input': {
    '&:focus': {
      borderRadius: '2px',
      backgroundColor: theme.palette.offwhite.main,
    },
    paddingLeft: '3px',
  },
  '&:hover': {
    borderBottom: '0px solid #5EB1E3',
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      border: '0px #ffffff00 solid',
    },
  },
}));
