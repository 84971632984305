/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import { BaseProductFragmentDoc } from '../../../shared-fragments/__generated__/product.generated';
import { UoMFragmentDoc } from '../../../shared-fragments/__generated__/uom.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PiApprovalProductFragment = { __typename?: 'Product', lotManaged?: boolean | null, netWeight?: number | null, grossWeight?: number | null, totalWeightUOMId?: string | null, volumeUOMId?: string | null, volume?: number | null, erpUpdatedAt?: string | null, erpCreatedAt?: string | null, createdAt: string, updatedAt?: string | null, id: string, companyId: string, code: string, description?: string | null, type?: string | null, status?: Types.ProductAvailability | null, unitOfMeasures?: { __typename?: 'UnitOfMeasureProductConversionNodes', nodes: Array<{ __typename?: 'UnitOfMeasureProductConversion', id: string, code: string, isBaseUom: boolean }> } | null };

export type GetPiApprovalProductQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetPiApprovalProductQuery = { __typename?: 'Query', product: { __typename?: 'Product', lotManaged?: boolean | null, netWeight?: number | null, grossWeight?: number | null, totalWeightUOMId?: string | null, volumeUOMId?: string | null, volume?: number | null, erpUpdatedAt?: string | null, erpCreatedAt?: string | null, createdAt: string, updatedAt?: string | null, id: string, companyId: string, code: string, description?: string | null, type?: string | null, status?: Types.ProductAvailability | null, unitOfMeasures?: { __typename?: 'UnitOfMeasureProductConversionNodes', nodes: Array<{ __typename?: 'UnitOfMeasureProductConversion', id: string, code: string, isBaseUom: boolean }> } | null } };

export type PiApprovalTaskFragment = { __typename?: 'Task', code: string, completionDate?: string | null, deletedAt?: string | null, createdAt: string, deliveryId?: string | null, deliveryItemId?: string | null, destinationBinId?: string | null, destinationLotId?: string | null, destinationStockStatusId?: string | null, dueDate?: string | null, fulfillmentItemId?: string | null, id: string, metaData?: any | null, productId?: string | null, quantity?: string | null, referringDoc?: string | null, sourceBinId?: string | null, sourceLotId?: string | null, sourceStockStatusId?: string | null, status?: Types.TaskStatus | null, taskTypeId: string, teamId?: string | null, updatedAt?: string | null, warehouseId: string, taskType?: { __typename?: 'TaskType', code: string } | null };

export type CompleteOnePhysicalInventoryApprovalTaskMutationVariables = Types.Exact<{
  input: Types.ApprovePiTaskInputDto;
}>;


export type CompleteOnePhysicalInventoryApprovalTaskMutation = { __typename?: 'Mutation', completeOnePhysicalInventoryApprovalTask: { __typename?: 'Task', code: string, completionDate?: string | null, deletedAt?: string | null, createdAt: string, deliveryId?: string | null, deliveryItemId?: string | null, destinationBinId?: string | null, destinationLotId?: string | null, destinationStockStatusId?: string | null, dueDate?: string | null, fulfillmentItemId?: string | null, id: string, metaData?: any | null, productId?: string | null, quantity?: string | null, referringDoc?: string | null, sourceBinId?: string | null, sourceLotId?: string | null, sourceStockStatusId?: string | null, status?: Types.TaskStatus | null, taskTypeId: string, teamId?: string | null, updatedAt?: string | null, warehouseId: string, taskType?: { __typename?: 'TaskType', code: string } | null } };

export const PiApprovalProductFragmentDoc = gql`
    fragment PIApprovalProduct on Product {
  ...BaseProduct
  lotManaged
  unitOfMeasures {
    nodes {
      ...UoM
    }
  }
  netWeight
  grossWeight
  totalWeightUOMId
  volumeUOMId
  volume
  erpUpdatedAt
  erpCreatedAt
  createdAt
  updatedAt
}
    ${BaseProductFragmentDoc}
${UoMFragmentDoc}`;
export const PiApprovalTaskFragmentDoc = gql`
    fragment PIApprovalTask on Task {
  code
  completionDate
  deletedAt
  createdAt
  deliveryId
  deliveryItemId
  destinationBinId
  destinationLotId
  destinationStockStatusId
  dueDate
  fulfillmentItemId
  id
  metaData
  productId
  quantity
  referringDoc
  sourceBinId
  sourceLotId
  sourceStockStatusId
  status
  taskTypeId
  taskType {
    code
  }
  teamId
  updatedAt
  warehouseId
}
    `;
export const GetPiApprovalProductDocument = gql`
    query GetPIApprovalProduct($id: ID!) {
  product(id: $id) {
    ...PIApprovalProduct
  }
}
    ${PiApprovalProductFragmentDoc}`;

/**
 * __useGetPiApprovalProductQuery__
 *
 * To run a query within a React component, call `useGetPiApprovalProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPiApprovalProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPiApprovalProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPiApprovalProductQuery(baseOptions: Apollo.QueryHookOptions<GetPiApprovalProductQuery, GetPiApprovalProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPiApprovalProductQuery, GetPiApprovalProductQueryVariables>(GetPiApprovalProductDocument, options);
      }
export function useGetPiApprovalProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPiApprovalProductQuery, GetPiApprovalProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPiApprovalProductQuery, GetPiApprovalProductQueryVariables>(GetPiApprovalProductDocument, options);
        }
export type GetPiApprovalProductQueryHookResult = ReturnType<typeof useGetPiApprovalProductQuery>;
export type GetPiApprovalProductLazyQueryHookResult = ReturnType<typeof useGetPiApprovalProductLazyQuery>;
export type GetPiApprovalProductQueryResult = Apollo.QueryResult<GetPiApprovalProductQuery, GetPiApprovalProductQueryVariables>;
export const CompleteOnePhysicalInventoryApprovalTaskDocument = gql`
    mutation CompleteOnePhysicalInventoryApprovalTask($input: ApprovePITaskInputDto!) {
  completeOnePhysicalInventoryApprovalTask(input: $input) {
    ...PIApprovalTask
  }
}
    ${PiApprovalTaskFragmentDoc}`;
export type CompleteOnePhysicalInventoryApprovalTaskMutationFn = Apollo.MutationFunction<CompleteOnePhysicalInventoryApprovalTaskMutation, CompleteOnePhysicalInventoryApprovalTaskMutationVariables>;

/**
 * __useCompleteOnePhysicalInventoryApprovalTaskMutation__
 *
 * To run a mutation, you first call `useCompleteOnePhysicalInventoryApprovalTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteOnePhysicalInventoryApprovalTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeOnePhysicalInventoryApprovalTaskMutation, { data, loading, error }] = useCompleteOnePhysicalInventoryApprovalTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteOnePhysicalInventoryApprovalTaskMutation(baseOptions?: Apollo.MutationHookOptions<CompleteOnePhysicalInventoryApprovalTaskMutation, CompleteOnePhysicalInventoryApprovalTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteOnePhysicalInventoryApprovalTaskMutation, CompleteOnePhysicalInventoryApprovalTaskMutationVariables>(CompleteOnePhysicalInventoryApprovalTaskDocument, options);
      }
export type CompleteOnePhysicalInventoryApprovalTaskMutationHookResult = ReturnType<typeof useCompleteOnePhysicalInventoryApprovalTaskMutation>;
export type CompleteOnePhysicalInventoryApprovalTaskMutationResult = Apollo.MutationResult<CompleteOnePhysicalInventoryApprovalTaskMutation>;
export type CompleteOnePhysicalInventoryApprovalTaskMutationOptions = Apollo.BaseMutationOptions<CompleteOnePhysicalInventoryApprovalTaskMutation, CompleteOnePhysicalInventoryApprovalTaskMutationVariables>;