import { InputBase as MUIInputBase } from '@mui/material';
import { styled } from '@mui/material/styles';

type TVariants = 'inline';

interface IStyledInputBase {
  styledVariant?: TVariants;
}

const styledVariants: Record<TVariants, Record<string, any>> = {
  inline: {
    '& input': {
      padding: 0,
      height: 'auto',
      color: '#256999',
      '&:focus': {
        color: '#0a3a57',
      },
    },
    '&.Mui-error input': {
      color: '#eb5757',
    },
  },
};

const InputBase = styled(MUIInputBase, {
  shouldForwardProp: (prop) => prop !== 'styledVariant',
})<IStyledInputBase>(({ styledVariant }) => styledVariants[styledVariant] || {});

export default InputBase;
