import { Paper } from '@mui/material';
import { Trans } from 'react-i18next';

import AuthBackground from '@components/auth-background';
import { useFeatureFlags } from '@context/feature-flags';
import { getTransComponents } from '@lib/translation';
import Typography from '@styled/Typography';

const MaintenanceMode = () => {
  const {
    applicationFeatureFlags: { maintenanceMode: maintenanceModeFlags },
  } = useFeatureFlags();

  return (
    <AuthBackground page="maintenanceMode">
      <Paper elevation={6} sx={{ paddingY: 8, paddingX: 12, width: '580px' }}>
        <Typography variant="h2" mb={3} align="center">
          {maintenanceModeFlags.title}
        </Typography>
        <Typography variant="body1" align="center">
          <Trans components={getTransComponents(['bold', 'break'])}>
            {maintenanceModeFlags.message}
          </Trans>
        </Typography>
      </Paper>
    </AuthBackground>
  );
};

export default MaintenanceMode;
