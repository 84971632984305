import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IBarcodeCard {
  count: number;
  text: string;
  color: string;
  icon: React.ReactElement;
}

const BarcodeCard: React.FC<IBarcodeCard> = ({ count, text, color, icon }) => {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography fontWeight={600} fontSize={65}>
        {count}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {icon}
        <Typography fontSize="24px" fontWeight={700} color={color}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export const ToEnrollCard = ({ count }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'barcodes' });
  return (
    <BarcodeCard
      count={count}
      text={t('needToBeEnrolled')}
      color={'#256999'}
      icon={<ClockIcon color="secondary" />}
    />
  );
};

export const CustomBarcodeCard = ({ count }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'barcodes' });
  return (
    <BarcodeCard
      count={count}
      text={t('customBarcodes')}
      color={'#219653'}
      icon={<CheckedIcon color="secondary" />}
    />
  );
};
export const PreEnrolledBarcodeCard = ({ count }) => {
  const { t } = useTranslation('pages', { keyPrefix: 'barcodes' });
  return (
    <BarcodeCard
      count={count}
      text={t('preEnrolledBarcodes')}
      color={'#4A4A4A'}
      icon={<CheckedIcon color="secondary" />}
    />
  );
};

const ClockIcon = styled(AccessAlarmIcon)(({ theme }) => ({
  fontSize: '24px',
  marginRight: '10px',
}));
const CheckedIcon = styled(CheckCircleOutlineIcon)(({ theme }) => ({
  fontSize: '24px',
  marginRight: '10px',
}));
