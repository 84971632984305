/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateBusinessPartnerMutationVariables = Types.Exact<{
  newBusinessPartner: Types.BusinessPartnerCreateInput;
}>;


export type CreateBusinessPartnerMutation = { __typename?: 'Mutation', createOneBusinessPartner: { __typename?: 'BusinessPartner', id: string, code: string, name?: string | null } };


export const CreateBusinessPartnerDocument = gql`
    mutation CreateBusinessPartner($newBusinessPartner: BusinessPartnerCreateInput!) {
  createOneBusinessPartner(input: {businessPartner: $newBusinessPartner}) {
    id
    code
    name
  }
}
    `;
export type CreateBusinessPartnerMutationFn = Apollo.MutationFunction<CreateBusinessPartnerMutation, CreateBusinessPartnerMutationVariables>;

/**
 * __useCreateBusinessPartnerMutation__
 *
 * To run a mutation, you first call `useCreateBusinessPartnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessPartnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessPartnerMutation, { data, loading, error }] = useCreateBusinessPartnerMutation({
 *   variables: {
 *      newBusinessPartner: // value for 'newBusinessPartner'
 *   },
 * });
 */
export function useCreateBusinessPartnerMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusinessPartnerMutation, CreateBusinessPartnerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusinessPartnerMutation, CreateBusinessPartnerMutationVariables>(CreateBusinessPartnerDocument, options);
      }
export type CreateBusinessPartnerMutationHookResult = ReturnType<typeof useCreateBusinessPartnerMutation>;
export type CreateBusinessPartnerMutationResult = Apollo.MutationResult<CreateBusinessPartnerMutation>;
export type CreateBusinessPartnerMutationOptions = Apollo.BaseMutationOptions<CreateBusinessPartnerMutation, CreateBusinessPartnerMutationVariables>;