import { Section, StepWrapper } from '@components/modal/ruleset/steps';
import WeightsInput from '@components/modal/ruleset/steps/weights-input';

const Step5 = ({ active, control, review }) => {
  return (
    <StepWrapper active={active} review={review}>
      <Section section="warehouseWeights">
        <WeightsInput control={control} prefix={`weights`} />
      </Section>
    </StepWrapper>
  );
};

export default Step5;
