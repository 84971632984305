import { Box } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  DeliveryItemsToUnloadFragment,
  useCreateUnloadTasksMutation,
  useGetDeliveryItemsForUnloadQuery,
} from '@/graphql/defs/components/modals/__generated__/unload-task-create-modal.generated';
import EditableTable, {
  EditableTableCell,
  EditableTableColumn,
  EditableTableRow,
} from '@components/editable-table/EditableTable';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { getTransComponents } from '@lib/translation';
import { IUnloadTaskCreateModal } from '@models/modal';

const UnloadTaskCreateModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();

  const { modal, closeModal, setLoading, isPreparing, setPreparing } =
    useModalContent<IUnloadTaskCreateModal>();

  const [createTasks] = useCreateUnloadTasksMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t('modal.delivery.unloadTaskCreate.success', {
          deliveryCode: modal.deliveryDetails?.erpCode,
        }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({ type: 'error', message: error.message });
    },
  });

  const [deliveryItems, setDeliveryItems] = useState<DeliveryItemsToUnloadFragment[]>([]);
  useGetDeliveryItemsForUnloadQuery({
    variables: {
      deliveryId: modal?.deliveryDetails?.id,
    },
    onCompleted: ({ deliveryItemsToUnload }) => {
      setDeliveryItems(deliveryItemsToUnload);
      setPreparing(false);
    },
    onError: (error) => {
      setPreparing(false);
      showMessage({ type: 'error', message: error.message });
    },
  });

  const lpDeliveryItemColumns: EditableTableColumn[] = [
    {
      label: t('common.item'),
      width: '15%',
    },
    {
      label: t('common.licensePlate'),
      width: '15%',
    },
    {
      label: t('common.description'),
      width: '55%',
    },
    {
      label: t('common.destinationBin'),
      width: '15%',
    },
  ];

  const productDeliveryItemColumns: EditableTableColumn[] = [
    {
      label: t('common.item'),
      width: '16.66%',
    },
    {
      label: t('common.productCodeAbbr'),
      width: '16.66%',
    },
    {
      label: t('common.description'),
      width: '16.66%',
    },
    {
      label: t('common.lot'),
      width: '16.66%',
    },
    {
      label: t('common.qty'),
      width: '16.66%',
    },
  ];

  const [lpUnloadTasks, looseProductUnloadTasks] = useMemo<
    [EditableTableRow[], EditableTableRow[]]
  >(() => {
    const rows = [...deliveryItems];
    const looseProductRows = rows.filter((row) => !row.licensePlateId);
    const lpRows = rows.filter((row) => row.licensePlateId);

    return [
      lpRows.map(createLpDeliveryItemRow),
      looseProductRows.map(createProductDeliveryItemRow),
    ];
  }, [deliveryItems]);

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = () => {
    void createTasks({ variables: { deliveryId: modal.deliveryDetails?.id } });
  };

  return (
    !isPreparing && (
      <>
        <ModalContent>
          <Box mb={8}>
            <Trans
              ns="components"
              i18nKey="modal.delivery.unloadTaskCreate.text"
              components={getTransComponents(['bold'])}
            />
          </Box>
          <Box mb={4}>
            {lpUnloadTasks && lpUnloadTasks.length > 0 && (
              <EditableTable columns={lpDeliveryItemColumns} data={lpUnloadTasks} />
            )}
          </Box>
          <Box>
            {looseProductUnloadTasks && looseProductUnloadTasks.length > 0 && (
              <EditableTable columns={productDeliveryItemColumns} data={looseProductUnloadTasks} />
            )}
          </Box>
        </ModalContent>
        <ModalActions>
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton onClick={onSubmit} variant="contained" color="primary" actionType="submit">
            {t('common.create')}
          </ModalButton>
        </ModalActions>
      </>
    )
  );
};

export default UnloadTaskCreateModal;

const createLpDeliveryItemRow = (deliveryItem, index): EditableTableRow => {
  const cells: EditableTableCell[] = [
    {
      dataTestId: `deliveryItem-${index}-deliveryItem`,
      value: deliveryItem.deliveryItem,
    },
    {
      dataTestId: `deliveryItem-${index}-lpCode`,
      value: deliveryItem.licensePlateCode,
    },
    {
      dataTestId: `deliveryItem-${index}-lpDesc`,
      value: deliveryItem.licensePlateDescription,
    },
    {
      dataTestId: `deliveryItem-${index}-destBin`,
      value: deliveryItem.destinationBinCode,
    },
  ];
  return {
    dataTestId: `deliveryItem-${index}`,
    item: deliveryItem,
    cells: cells,
  };
};

const createProductDeliveryItemRow = (deliveryItem, index): EditableTableRow => {
  const cells: EditableTableCell[] = [
    {
      dataTestId: `deliveryItem-${index}-deliveryItem`,
      value: deliveryItem.deliveryItem,
    },
    {
      dataTestId: `deliveryItem-${index}-productCode`,
      value: deliveryItem.productCode,
    },
    {
      dataTestId: `deliveryItem-${index}-productDesc`,
      value: deliveryItem.productDescription,
    },
    {
      dataTestId: `deliveryItem-${index}-lotCode`,
      value: deliveryItem.lotCode,
    },
    {
      dataTestId: `deliveryItem-${index}-quantity`,
      value: deliveryItem.quantity,
    },
  ];
  return {
    dataTestId: `deliveryItem-${index}`,
    item: deliveryItem,
    cells: cells,
  };
};
