import { Box, Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useBulkEditBinMutation,
  useUpdateManyBinStatusMappingsMutation,
} from '@/graphql/defs/components/modals/__generated__/bulk-edit-bin-modal.generated';
import {
  BinStorageTypeItemFragment,
  useListBinStorageTypesQuery,
} from '@/graphql/defs/list/__generated__/list-bin-storage-types.generated';
import { useBinStatusesQuery } from '@/graphql/defs/shared-queries/__generated__/bin-statuses.generated';
import { BinActiveState, BinBlockState, BinStatusCode } from '@/graphql/types.generated';
import DateTimePicker from '@components/date-time-picker';
import { ModalContent } from '@components/modal';
import { ModalActions } from '@components/modal/modal-actions';
import { ModalButton } from '@components/modal/modal-button';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import VirtualAutocomplete from '@components/virtual-autocomplete';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import { IBulkEditBinsModal } from '@models/modal';

const BulkEditBinsModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { selectedWarehouseId } = useEntityUtils();
  const { modal, closeModal, isPreparing, setPreparing } = useModalContent<IBulkEditBinsModal>();

  const onCancel = () => {
    closeModal();
  };

  const [updateMappings] = useUpdateManyBinStatusMappingsMutation({
    onError: (error) => {
      showMessage({ type: 'error', message: error.message });
    },
  });

  const [updated, setUpdated] = useState<{ checkbox: boolean; code: BinStatusCode; on: boolean }[]>(
    [],
  );
  const { data: { binStatuses: { nodes: statuses } } = { binStatuses: { nodes: [] } } } =
    useBinStatusesQuery({
      onCompleted: ({ binStatuses: { nodes: _statuses } }) => {
        const newUpdate = _statuses.reduce((acc, status, index, _) => {
          acc[index] = {
            checkbox: false,
            code: status.code,
            on: modal.bins.every((bin) => {
              switch (status.code) {
                case BinStatusCode.BinStatusDestinationBlock:
                  return bin?.destinationBinBlock === BinBlockState.Blocked;
                case BinStatusCode.BinStatusInactive:
                  return bin?.inactive === BinActiveState.Inactive;
                case BinStatusCode.BinStatusSourceBlock:
                  return bin?.sourceBinBlock === BinBlockState.Blocked;
                case BinStatusCode.BinStatusTestCode:
                default:
                  return false;
              }
            }),
          };
          return acc;
        }, []);
        setUpdated(newUpdate);
        setPreparing(false);
      },
      onError: (error) => {
        showMessage({ type: 'error', message: error.message });
        setPreparing(false);
      },
    });

  const formMethods = useForm({
    mode: 'all',
  });
  const { control, handleSubmit, formState } = formMethods;
  const { validDateTime, notWhiteSpaceOnly } = useFormValidation();

  const [shouldUpdateLastCount, setShouldUpdateLastCount] = useState(false);
  const [lastCountDate, setLastCountDate] = useState<DateTime>(null);

  const [shouldUpdateBinStorageType, setShouldUpdateBinStorageType] = useState(false);
  const [binStorageTypeId, setBinStorageTypeId] = useState<string>(null);

  const [binStorageTypes, setBinStorageTypes] = useState<
    Record<string, BinStorageTypeItemFragment>
  >({});
  useListBinStorageTypesQuery({
    variables: {
      warehouseId: selectedWarehouseId,
    },
    onCompleted: ({ viewBinStorageTypes: { nodes: _binStorageTypes } }) => {
      const _binStorageTypesObject = _binStorageTypes.reduce<
        Record<string, BinStorageTypeItemFragment>
      >((acc, binStorageType) => {
        acc[binStorageType.id] = binStorageType;
        return acc;
      }, {});
      setBinStorageTypes(_binStorageTypesObject);
    },
  });

  const [bulkUpdateBin] = useBulkEditBinMutation({
    onError: (error) => {
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const runNextBinUpdateOrExit = async (index: number) => {
    if (index >= modal.bins.length) {
      const message =
        shouldUpdateLastCount && shouldUpdateBinStorageType
          ? t('modal.bin.bulkEditLastCountBinStorageTypeSuccess', { count: modal.bins.length })
          : shouldUpdateLastCount
          ? t('modal.bin.bulkEditLastCountSuccess', { count: modal.bins.length })
          : t('modal.bin.bulkEditBinStorageTypeSuccess', { count: modal.bins.length });

      showMessage({
        type: 'success',
        message,
      });
      return;
    }

    await bulkUpdateBin({
      variables: {
        binId: modal.bins[index]?.id,
        lastCountDate: shouldUpdateLastCount ? lastCountDate : undefined,
        binStorageTypeId: shouldUpdateBinStorageType ? binStorageTypeId : undefined,
        updateBoth: shouldUpdateLastCount && shouldUpdateBinStorageType,
        updateLastCountDate: shouldUpdateLastCount && !shouldUpdateBinStorageType,
        updateBinStorageType: !shouldUpdateLastCount && shouldUpdateBinStorageType,
      },
    });
    await runNextBinUpdateOrExit(++index);
  };

  const onSubmit = async () => {
    const updatedStatues = updated.filter((x) => !!x.checkbox);

    if (updatedStatues.length > 0) {
      await updateMappings({
        variables: {
          input: {
            binStatusMappings: {
              binIds: modal.bins.map(({ id }) => id),
              binStatus: updatedStatues.map(({ code, on }) => ({ binStatusCode: code, on })),
            },
          },
        },
      });
    }

    if (shouldUpdateLastCount || shouldUpdateBinStorageType) {
      await runNextBinUpdateOrExit(0);
    }

    closeModal({ bypassLoading: true, success: true });
  };

  const toggle = ({ index, on }) => {
    const newUpdate = [...updated];
    newUpdate[index] = { ...newUpdate[index], on };
    setUpdated(newUpdate);
  };

  const calculateUpdateCount = ({ code, on }) => {
    const key = {
      [BinStatusCode.BinStatusDestinationBlock]: 'destinationBinBlock',
      [BinStatusCode.BinStatusSourceBlock]: 'sourceBinBlock',
      [BinStatusCode.BinStatusInactive]: 'inactive',
    }[code];
    return modal.bins.reduce((acc, bin) => {
      if (on && ['available', 'active'].includes(bin[key])) {
        return acc + 1;
      } else if (!on && ['blocked', 'inactive'].includes(bin[key])) {
        return acc + 1;
      }
      return acc;
    }, 0);
  };

  const containsProducts = useMemo(
    () => modal.bins.some(({ containsProducts }) => containsProducts),
    [],
  );
  const hasOpenTasks = useMemo(() => modal.bins.some(({ hasOpenTasks }) => hasOpenTasks), []);

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent sx={{ width: '750px' }}>
          <Box
            sx={{
              marginTop: '15px',
              color: (theme) => theme.palette.text.primary,
              fontSize: '18px',
              fontWeight: 600,
            }}
          >
            {t('modal.bin.bulkEditSubtitle', { count: modal.bins.length })}
          </Box>
          {statuses
            .filter(({ code }) => !code.includes('TEST'))
            .map((status, i) => {
              return (
                <Box
                  key={i}
                  sx={{
                    width: '90%',
                    margin: '0px auto',
                    display: 'flex',
                    alignItems: 'center',
                    padding: '32px 8px',
                    borderTop: `1px solid #D8E0E5${i === 0 ? '00' : ''}`,
                    flexWrap: 'wrap',
                  }}
                >
                  <Checkbox
                    checked={!!updated[i].checkbox}
                    onChange={() => {
                      const newUpdate = [...updated];
                      newUpdate[i] = { ...newUpdate[i], checkbox: !newUpdate[i]?.checkbox };
                      setUpdated(newUpdate);
                    }}
                    data-testid={`bulk-bin-edit-${status.code}-checkbox`}
                  />
                  <Box sx={{ width: 225, marginRight: '25px' }}>
                    {t(`modal.bin.statusCheck.${status.code}`)}
                  </Box>
                  <Controller
                    name={status.label}
                    control={control}
                    rules={{
                      validate: (on) => {
                        if (on) {
                          if (status.code === BinStatusCode.BinStatusInactive) {
                            if (hasOpenTasks) {
                              return t('modal.bin.binsWithOpenTasksCantBeInactive');
                            } else if (containsProducts) {
                              return t('modal.bin.binsContainingProductsCantBeInactive');
                            }
                          } else if (hasOpenTasks) {
                            return t('modal.bin.binsWithOpenTasksCantBeBlocked');
                          }
                        }
                        return true;
                      },
                    }}
                    defaultValue=""
                    render={({ field }) => {
                      return (
                        <RadioGroup
                          row
                          name={t(`modal.bin.statusCheck.${status.code}`)}
                          value={!!updated[i]?.on || false}
                          onChange={(e, value) => {
                            toggle({ index: i, on: value === 'true' });
                            field.onChange(!updated[i]?.on);
                          }}
                        >
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label={t(`modal.bin.statusToggle.${status.code}_FALSE`)}
                            disabled={!updated[i].checkbox}
                          />
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label={t(`modal.bin.statusToggle.${status.code}_TRUE`)}
                            disabled={!updated[i].checkbox}
                          />
                        </RadioGroup>
                      );
                    }}
                  />
                  {!!updated[i].checkbox && (
                    <Box sx={{ marginLeft: '25px', fontWeight: 600 }}>
                      {t('modal.bin.countBinsWillBeUpdated', {
                        count: calculateUpdateCount({ code: status.code, on: updated[i]?.on }),
                      })}
                    </Box>
                  )}
                  {formState?.errors[status.label]?.message && (
                    <Box sx={{ height: '24px', width: '100%', textAlign: 'center', color: 'red' }}>
                      {formState?.errors[status.label]?.message}
                    </Box>
                  )}
                </Box>
              );
            })}
          <Box
            sx={{
              width: '90%',
              margin: '0px auto',
              display: 'flex',
              alignItems: 'center',
              padding: '32px 8px',
              borderTop: `1px solid #D8E0E5`,
              flexWrap: 'nowrap',
            }}
          >
            <Checkbox
              checked={shouldUpdateLastCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setShouldUpdateLastCount(event.target.checked);
              }}
              data-testid="bulk-bin-edit-last-count-checkbox"
            />
            <Box sx={{ width: 225, marginRight: '25px' }}>{t('modal.bin.lastCountCheck')}</Box>
            <Box maxWidth="240px">
              <Controller
                name="lastCounted"
                control={control}
                rules={{
                  validate: {
                    validDateTime,
                  },
                }}
                defaultValue={null}
                render={({ field, fieldState }) => (
                  <DateTimePicker
                    isDisabled={!shouldUpdateLastCount}
                    label={t('common.lastCounted')}
                    field={field}
                    fieldState={fieldState}
                    setDate={(dateTime) => {
                      setLastCountDate(dateTime);
                      field.onChange(dateTime);
                    }}
                    dataTestId="bulk-bin-edit-last-count"
                  />
                )}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: '90%',
              margin: '0px auto',
              display: 'flex',
              alignItems: 'center',
              padding: '32px 8px',
              borderTop: `1px solid #D8E0E5`,
              flexWrap: 'nowrap',
            }}
          >
            <Checkbox
              checked={shouldUpdateBinStorageType}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setShouldUpdateBinStorageType(event.target.checked);
              }}
              data-testid="bulk-bin-edit-bin-storage-type-checkbox"
            />
            <Box sx={{ width: 225, marginRight: '25px' }}>{t('modal.bin.binStorageTypeCheck')}</Box>
            <Box width="240px">
              <Controller
                name="binStorageTypeId"
                control={control}
                rules={{
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                defaultValue={null}
                render={({ field, fieldState }) => (
                  <VirtualAutocomplete
                    {...field}
                    fullWidth
                    disabled={!shouldUpdateBinStorageType}
                    freeSolo={false}
                    multiple={false}
                    disableClearable={true}
                    autoHighlight={true}
                    value={binStorageTypeId}
                    options={Object.keys(binStorageTypes)}
                    getOptionLabel={(option) => binStorageTypes[option].label}
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(event, item, reason) => {
                      setBinStorageTypeId(item);
                      field.onChange(item);
                    }}
                    renderOption={(props, option) => {
                      return (
                        <Box
                          {...props}
                          component="li"
                          display="flex"
                          flexDirection="column"
                          sx={{ alignItems: 'flex-start!important' }}
                        >
                          <Typography variant="body4">{binStorageTypes[option].code}</Typography>
                          <Typography variant="body1">{binStorageTypes[option].label}</Typography>
                        </Box>
                      );
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          required={shouldUpdateBinStorageType}
                          label={t('common.binStorageType')}
                          error={!!fieldState?.error}
                          helperText={fieldState?.error?.message}
                          dataTestId="bulk-bin-edit-bin-storage-type-input"
                        />
                      );
                    }}
                  />
                )}
              />
            </Box>
          </Box>
        </ModalContent>
        <ModalActions>
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton
            data-testid="bulkEditBins_submit"
            variant="contained"
            color="primary"
            isDisabled={
              updated.filter((value) => !!value).length === 0 &&
              !shouldUpdateLastCount &&
              !shouldUpdateBinStorageType
            }
            actionType="submit"
          >
            {t('common.save')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default BulkEditBinsModal;
