import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateBinMutation } from '@/graphql/defs/components/modals/__generated__/create-bin-modal.generated';
import { BinCreateInput } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import BinFields from '@components/modal/bin/bin-fields';
import ModalForm from '@components/modal/modal-form';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import FormValues from '@models/FormValues';
import { IBinCreateModal } from '@models/modal';

const CreateBinModal = () => {
  const { t } = useTranslation('components');
  const { selectedWarehouseId } = useEntityUtils();
  const { showMessage } = useSnackbar();
  const { modal, closeModal, setLoading, depBucket, updateDepBucket } =
    useModalContent<IBinCreateModal>();

  const formMethods = useForm<FormValues<BinCreateInput>>({
    defaultValues: {
      code: '',
      areaId: !!modal?.forceArea ? modal.forceArea : '',
      aisleId: '',
      aisleColumnId: '',
      binStorageTypeId: '',
      level: '',
      x: null,
      y: null,
      lastCount: null,
    },
  });
  const { handleSubmit } = formMethods;

  const [createBin] = useCreateBinMutation({
    onCompleted: ({ createOneBin: { id, code } }) => {
      showMessage({
        type: 'success',
        message: t('modal.bin.createBin.success', { code }),
      });

      if (depBucket['pendingDepBucketKey']) {
        updateDepBucket(depBucket['pendingDepBucketKey'], id);
      }

      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = (fields: BinCreateInput) => {
    // Purposefully leaving aisleId and aisleColumnId as null, as we don't currently have the ability to select these entity ids.
    void createBin({
      variables: {
        newBin: {
          binStorageTypeId: fields.binStorageTypeId,
          code: fields.code,
          areaId: fields.areaId,
          aisleId: null,
          aisleColumnId: null,
          level: fields.level,
          x: fields.x,
          y: fields.y,
          lastCount: fields.lastCount,
          warehouseId: selectedWarehouseId,
        },
      },
    });
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <BinFields forceArea={!!modal?.forceArea} />
      </ModalContent>
      <ModalActions>
        <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          variant="contained"
          color="primary"
          data-testid="create-bin-submit"
          actionType="submit"
        >
          {t('common.submit')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default CreateBinModal;
