import { DialogTitle } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ModalTitle = styled(DialogTitle)({
  '&': {
    paddingBottom: '6px',
    textTransform: 'uppercase',
    '& + .MuiDialogContent-root': {
      paddingTop: '10px!important', // !important seemingly needed as there is a style that sets padding-top to 0, in relation to the title... and it appears to be system set.
    },
  },
});
