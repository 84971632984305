/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddUserToTeamMutationVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
  userId: Types.Scalars['ID']['input'];
}>;


export type AddUserToTeamMutation = { __typename?: 'Mutation', createOneUserTeamMapping: { __typename?: 'UserTeamMapping', id: string } };

export type AddUserListedUserFragment = { __typename?: 'ViewUser', firstName?: string | null, lastName?: string | null, email?: string | null, id?: string | null };

export type AddUserListOfUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AddUserListOfUsersQuery = { __typename?: 'Query', getUsers: { __typename?: 'ViewUserOffsetConnection', users: Array<{ __typename?: 'ViewUser', firstName?: string | null, lastName?: string | null, email?: string | null, id?: string | null }> } };

export const AddUserListedUserFragmentDoc = gql`
    fragment AddUserListedUser on ViewUser {
  id: userId
  firstName
  lastName
  email
}
    `;
export const AddUserToTeamDocument = gql`
    mutation AddUserToTeam($teamId: ID!, $userId: ID!) {
  createOneUserTeamMapping(
    input: {userTeamMapping: {teamId: $teamId, userId: $userId}}
  ) {
    id
  }
}
    `;
export type AddUserToTeamMutationFn = Apollo.MutationFunction<AddUserToTeamMutation, AddUserToTeamMutationVariables>;

/**
 * __useAddUserToTeamMutation__
 *
 * To run a mutation, you first call `useAddUserToTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToTeamMutation, { data, loading, error }] = useAddUserToTeamMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddUserToTeamMutation(baseOptions?: Apollo.MutationHookOptions<AddUserToTeamMutation, AddUserToTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserToTeamMutation, AddUserToTeamMutationVariables>(AddUserToTeamDocument, options);
      }
export type AddUserToTeamMutationHookResult = ReturnType<typeof useAddUserToTeamMutation>;
export type AddUserToTeamMutationResult = Apollo.MutationResult<AddUserToTeamMutation>;
export type AddUserToTeamMutationOptions = Apollo.BaseMutationOptions<AddUserToTeamMutation, AddUserToTeamMutationVariables>;
export const AddUserListOfUsersDocument = gql`
    query AddUserListOfUsers {
  getUsers: viewUsers(
    sorting: [{field: lastName, direction: ASC}, {field: firstName, direction: ASC}]
  ) {
    users: nodes {
      ...AddUserListedUser
    }
  }
}
    ${AddUserListedUserFragmentDoc}`;

/**
 * __useAddUserListOfUsersQuery__
 *
 * To run a query within a React component, call `useAddUserListOfUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddUserListOfUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddUserListOfUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddUserListOfUsersQuery(baseOptions?: Apollo.QueryHookOptions<AddUserListOfUsersQuery, AddUserListOfUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddUserListOfUsersQuery, AddUserListOfUsersQueryVariables>(AddUserListOfUsersDocument, options);
      }
export function useAddUserListOfUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddUserListOfUsersQuery, AddUserListOfUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddUserListOfUsersQuery, AddUserListOfUsersQueryVariables>(AddUserListOfUsersDocument, options);
        }
export type AddUserListOfUsersQueryHookResult = ReturnType<typeof useAddUserListOfUsersQuery>;
export type AddUserListOfUsersLazyQueryHookResult = ReturnType<typeof useAddUserListOfUsersLazyQuery>;
export type AddUserListOfUsersQueryResult = Apollo.QueryResult<AddUserListOfUsersQuery, AddUserListOfUsersQueryVariables>;