/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEquipmentTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  updatedType: Types.EquipmentTypeUpdateInput;
}>;


export type UpdateEquipmentTypeMutation = { __typename?: 'Mutation', updateOneEquipmentType: { __typename?: 'EquipmentType', code: string, label: string } };

export type DeleteEquipmentTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeleteEquipmentTypeMutation = { __typename?: 'Mutation', deleteOneEquipmentType: { __typename?: 'EquipmentType', id: string, label: string } };


export const UpdateEquipmentTypeDocument = gql`
    mutation UpdateEquipmentType($id: ID!, $updatedType: EquipmentTypeUpdateInput!) {
  updateOneEquipmentType(input: {id: $id, update: $updatedType}) {
    code
    label
  }
}
    `;
export type UpdateEquipmentTypeMutationFn = Apollo.MutationFunction<UpdateEquipmentTypeMutation, UpdateEquipmentTypeMutationVariables>;

/**
 * __useUpdateEquipmentTypeMutation__
 *
 * To run a mutation, you first call `useUpdateEquipmentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEquipmentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEquipmentTypeMutation, { data, loading, error }] = useUpdateEquipmentTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updatedType: // value for 'updatedType'
 *   },
 * });
 */
export function useUpdateEquipmentTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEquipmentTypeMutation, UpdateEquipmentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEquipmentTypeMutation, UpdateEquipmentTypeMutationVariables>(UpdateEquipmentTypeDocument, options);
      }
export type UpdateEquipmentTypeMutationHookResult = ReturnType<typeof useUpdateEquipmentTypeMutation>;
export type UpdateEquipmentTypeMutationResult = Apollo.MutationResult<UpdateEquipmentTypeMutation>;
export type UpdateEquipmentTypeMutationOptions = Apollo.BaseMutationOptions<UpdateEquipmentTypeMutation, UpdateEquipmentTypeMutationVariables>;
export const DeleteEquipmentTypeDocument = gql`
    mutation DeleteEquipmentType($id: ID!) {
  deleteOneEquipmentType(input: {id: $id}) {
    id
    label
  }
}
    `;
export type DeleteEquipmentTypeMutationFn = Apollo.MutationFunction<DeleteEquipmentTypeMutation, DeleteEquipmentTypeMutationVariables>;

/**
 * __useDeleteEquipmentTypeMutation__
 *
 * To run a mutation, you first call `useDeleteEquipmentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEquipmentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEquipmentTypeMutation, { data, loading, error }] = useDeleteEquipmentTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEquipmentTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEquipmentTypeMutation, DeleteEquipmentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEquipmentTypeMutation, DeleteEquipmentTypeMutationVariables>(DeleteEquipmentTypeDocument, options);
      }
export type DeleteEquipmentTypeMutationHookResult = ReturnType<typeof useDeleteEquipmentTypeMutation>;
export type DeleteEquipmentTypeMutationResult = Apollo.MutationResult<DeleteEquipmentTypeMutation>;
export type DeleteEquipmentTypeMutationOptions = Apollo.BaseMutationOptions<DeleteEquipmentTypeMutation, DeleteEquipmentTypeMutationVariables>;