import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useSlottingRunsDataTableLazyQuery } from '@/graphql/defs/hooks/shared-columns/__generated__/useCreateSlottingRunsColumns.generated';
import { ColumnTypes } from '@components/data-table/controls/filter/filter-definitions';
import { TColumnFactory } from '@components/data-table/hooks/useCreateDataTableColumns';
import filterDataTableColumnDefs from '@components/data-table/lib/filterDataTableColumnDefs';
import {
  TExtractLazyHookDataType,
  TExtractLazyHookFetchFilterType,
  TExtractLazyHookFieldNames,
} from '@components/data-table/model/extract-query-hook-types';
import LinkCell from '@components/data-table/table/cells/LinkCell';
import StatusTypography from '@components/status-typography';
import { SLOTTING_RULESET, SLOTTING_RUN } from '@constants/routes';
import { useDateTime } from '@context/date-time';

export const SLOTTING_RUNS_HOOKS = useSlottingRunsDataTableLazyQuery;
export type TSlottingRunsDataType = TExtractLazyHookDataType<typeof SLOTTING_RUNS_HOOKS>;
export type TSlottingRunsFilterType = TExtractLazyHookFetchFilterType<typeof SLOTTING_RUNS_HOOKS>;
export type TSlottingRunsFieldNames = TExtractLazyHookFieldNames<typeof SLOTTING_RUNS_HOOKS>;

export enum RunStatus {
  'initialized' = 'initialized',
  'run started' = 'started',
  'run aborted: invalid warehouse' = 'aborted',
  'calculating pre metrics' = 'inProgress',
  'calculating post metrics' = 'inProgress',
  'run complete' = 'complete',
}

interface IUseCreateSlottingRunsColumns {
  dataTestId: string;
  removeColumns?: (keyof TSlottingRunsDataType)[];
}
const useCreateSlottingRunsColumns = ({
  dataTestId,
  removeColumns = [],
}: IUseCreateSlottingRunsColumns) => {
  const { t } = useTranslation('components');
  const { displayDateTime } = useDateTime();

  const createSlottingRunsColumns = useCallback<TColumnFactory<TSlottingRunsDataType>>(
    (columnHelper) => {
      const columns = [
        columnHelper.accessor('name', {
          header: t('slotting.run'),
          cell: ({ row, getValue }) => (
            <LinkCell
              href={`${SLOTTING_RUN}/${row.original.id}`}
              text={getValue()}
              dataTestId={`${dataTestId}-run-name`}
            />
          ),
          meta: {
            columnType: ColumnTypes.string,
            enableExport: false,
          },
        }),
        columnHelper.accessor('created_at', {
          header: t('dates.createdAt'),
          cell: ({ getValue }) =>
            displayDateTime({
              date: getValue(),
            }),
          meta: {
            columnType: ColumnTypes.number,
            exportFormatter: (value) =>
              displayDateTime({
                date: value,
              }),
          },
        }),
        columnHelper.accessor('deployed_at', {
          header: t('dates.deployedAt'),
          cell: ({ getValue }) =>
            displayDateTime({
              date: getValue(),
            }),
          meta: {
            columnType: ColumnTypes.number,
            exportFormatter: (value) =>
              displayDateTime({
                date: value,
              }),
          },
        }),
        columnHelper.accessor('deployment_ended_at', {
          header: t('dates.deploymentEndedAt'),
          cell: ({ getValue }) =>
            displayDateTime({
              date: getValue(),
            }),
          meta: {
            columnType: ColumnTypes.number,
            exportFormatter: (value) =>
              displayDateTime({
                date: value,
              }),
          },
        }),
        columnHelper.accessor('status', {
          header: t('slotting.status'),
          cell: ({ getValue }) => {
            const status: RunStatus = RunStatus[getValue()] || 'inProgress';
            return (
              <StatusTypography
                status={status}
                display={t(`common.${status}`)}
                bold={true}
                uppercase={true}
              />
            );
          },
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('created_by_user_name', {
          header: t('common.createdBy'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('overall', {
          header: t('slotting.runScore'),
          cell: ({ getValue }) => getValue()?.toFixed(2) || 0,
          meta: {
            columnType: ColumnTypes.number,
            exportFormatter: (value) => value?.toFixed(2) || 0,
          },
        }),
        columnHelper.accessor('ruleset_name', {
          header: t('slotting.ruleset'),
          cell: ({ row, getValue }) => (
            <LinkCell
              href={`${SLOTTING_RULESET}/${row.original.ruleset_id}`}
              text={getValue()}
              dataTestId={`${dataTestId}-ruleset-name`}
            />
          ),
          meta: {
            columnType: ColumnTypes.string,
            enableExport: false,
          },
        }),
        columnHelper.accessor('dataset_name', {
          header: t('slotting.dataset'),
          cell: ({ row, getValue }) => (
            <LinkCell
              href={`${SLOTTING_RULESET}/${row.original.dataset_id}`}
              text={getValue()}
              dataTestId={`${dataTestId}-dataset-name`}
            />
          ),
          meta: {
            columnType: ColumnTypes.string,
            enableExport: false,
          },
        }),
        columnHelper.accessor('variant', {
          header: t('slotting.variant'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
      ];
      return columns.filter(filterDataTableColumnDefs<TSlottingRunsDataType>(removeColumns));
    },
    [t, displayDateTime, ...removeColumns],
  );

  return createSlottingRunsColumns;
};

export default useCreateSlottingRunsColumns;
