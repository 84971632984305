import { useState } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';

import { useVolumeUnitOfMeasuresQuery } from '@/graphql/defs/components/form-util/field-blocks/__generated__/volume-uom-dropdown-db.generated';
import TextField from '@components/text-field';
import VirtualAutocomplete from '@components/virtual-autocomplete';
import FormValues from '@models/FormValues';

const VolumeUomDropdownDb = ({
  field,
  fieldState,
  warehouseId,
  label,
  dataTestId,
  required = true,
}: {
  field: ControllerRenderProps<FormValues<any>, any>;
  fieldState: ControllerFieldState;
  warehouseId: string;
  label: string;
  dataTestId: string;
  required?: boolean;
}) => {
  const [volumeUoms, setVolumeUoms] = useState<Record<string, string>>({});

  const { loading, called } = useVolumeUnitOfMeasuresQuery({
    variables: {
      warehouseId,
    },
    onCompleted: ({ unitOfMeasureWarehousePreference: _volumeUoms }) => {
      const _uomObject = _volumeUoms.reduce((acc, uom) => {
        acc[uom.id] = uom.code;
        return acc;
      }, {});
      setVolumeUoms(_uomObject);
    },
  });

  return (
    <VirtualAutocomplete
      {...field}
      fullWidth
      loading={loading || !called}
      freeSolo={false}
      multiple={false}
      disableClearable={true}
      autoHighlight={true}
      value={field.value}
      options={Object.keys(volumeUoms)}
      getOptionLabel={(option) => volumeUoms[option] || ''}
      isOptionEqualToValue={(option, value) => option === value}
      onChange={(event, item, reason) => {
        field.onChange(item);
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            required={required}
            label={label}
            error={!!fieldState?.error}
            helperText={fieldState?.error?.message}
            dataTestId={`${dataTestId}-volumeUomDropdown`}
          />
        );
      }}
    />
  );
};

export default VolumeUomDropdownDb;
