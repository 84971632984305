import { AxiosError } from 'axios';
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { getWebConfig } from '@/apis/axios';
import { ModelWarehouseType } from '@/graphql/types.generated';
import { useAppLoadingToggle } from '@context/app-loading/AppLoadingToggleProvider';
import { useSnackbar } from '@context/snackbar';
import _stubbedFeatureFlags from '@models/_stubbed-feature-flags';
import { InventoryLevels } from '@models/inventory';
import MaintenanceMode from '@pages/maintenance-mode';

export interface IApplicationFeatureFlagToggles {
  companies: {
    allowCreate: boolean;
    allowEditDetails: boolean;
    allowEditContactInfo: boolean;
    allowEditDisplayPref: boolean;
  };
  dataTables: {
    totalCountRefreshMs: number;
    savedPaginationTTL: number;
  };
  guide: {
    enabled: boolean;
  };
  maintenanceMode: {
    enabled: boolean;
    title?: string;
    message?: string;
  };
  organization: {
    allowEditDetails: boolean;
    allowEditContactInfo: boolean;
    allowEditDisplayPref: boolean;
  };
  pagesEnabled: {
    map: boolean;
    settings: {
      apiLogs: boolean;
      agentConfig: boolean;
      erpSystemAuth: boolean;
      hardware: boolean;
      licensePlates: boolean;
      numberRanges: boolean;
      organization: boolean;
      processes: boolean;
      queueLogs: boolean;
      serialNumbers: boolean;
      stockStatus: boolean;
      sapSettings: {
        company: boolean;
        costCenters: boolean;
        documentTypes: boolean;
        movementTypes: boolean;
        syncSettings: boolean;
        warehouseMapping: boolean;
      };
    };
    support: boolean;
    developerTools: boolean;
  };
  stockStatuses: {
    allowCreate: boolean;
    allowEdit: boolean;
    allowDelete: boolean;
  };
  warehouses: {
    allowCreate: boolean;
    allowEditDetails: boolean;
    allowEditContactInfo: boolean;
    allowEditDisplayPref: boolean;
  };
}

export interface IWarehouseFeatureFlagToggles {
  barcodes: {
    showNonCompliantTable: boolean;
  };
  deliveries: {
    assignDoor: boolean;
    unassignDoor: boolean;
    showAsnOcInfo: boolean;
    showCategory: boolean;
    showERPDates: boolean;
    showERPCode: boolean;
    showGoodsIssueReceipt: boolean;
    showLoadUnload: boolean;
    showERPBlock: boolean;
    showAvailability: boolean;
  };
  doors: {
    allowCreate: boolean;
    allowEdit: boolean;
    allowDelete: boolean;
  };
  inboundDeliveries: {
    showPGR: boolean;
    manageInboundLPs: boolean;
  };
  inventory: {
    deliveryAssociated: boolean;
    levelsEnabled: Record<InventoryLevels, boolean>;
    levels: {
      [InventoryLevels.Bin]: {
        showAdjustInventory: boolean;
      };
    };
    binLevelActions: {
      adjustInventory: boolean;
      updateStockStatus: boolean;
      createCount: boolean;
      createBinToBin: boolean;
      createIssueStock: boolean;
    };
    licensePlateActions: {
      add: boolean;
      block: boolean;
      create: boolean;
      merge: boolean;
      move: boolean;
      unpack: boolean;
    };
    lostAndFoundActions: {
      issueStock: boolean;
      editStock: boolean;
      binToBin: boolean;
    };
  };
  licensePlates: {
    details: {
      showNestedTable: boolean;
    };
  };
  outboundDeliveries: {
    allowShortShip: boolean;
    showPGI: boolean;
    showPromiseDate: boolean;
    loadCreate: {
      columnType: 'product' | 'lp';
    };
  };
  tasks: {
    actions: {
      editUoM: boolean;
      cancelTasks: boolean;
    };
  };
}
export type TWarehouseTypeFeatureFlags = Record<ModelWarehouseType, IWarehouseFeatureFlagToggles>;

export interface IFeatureFlagToggles extends IApplicationFeatureFlagToggles {
  warehouseTypes: TWarehouseTypeFeatureFlags;
}

const DEFAULT_APPLICATION_FEATURE_FLAG_TOGGLES: IApplicationFeatureFlagToggles = {
  companies: {
    allowCreate: false,
    allowEditDetails: false,
    allowEditContactInfo: false,
    allowEditDisplayPref: false,
  },
  dataTables: {
    totalCountRefreshMs: 60000,
    savedPaginationTTL: 300000,
  },
  guide: {
    enabled: false,
  },
  maintenanceMode: {
    enabled: false,
  },
  organization: {
    allowEditDetails: false,
    allowEditContactInfo: false,
    allowEditDisplayPref: false,
  },
  pagesEnabled: {
    map: false,
    settings: {
      agentConfig: false,
      apiLogs: false,
      erpSystemAuth: false,
      hardware: false,
      licensePlates: false,
      numberRanges: false,
      organization: false,
      processes: false,
      queueLogs: false,
      serialNumbers: false,
      stockStatus: false,
      sapSettings: {
        company: false,
        costCenters: false,
        documentTypes: false,
        movementTypes: false,
        syncSettings: false,
        warehouseMapping: false,
      },
    },
    support: false,
    developerTools: false,
  },
  stockStatuses: {
    allowCreate: false,
    allowEdit: false,
    allowDelete: false,
  },
  warehouses: {
    allowCreate: false,
    allowEditDetails: false,
    allowEditContactInfo: false,
    allowEditDisplayPref: false,
  },
};

export const DEFAULT_WAREHOUSE_FEATURE_FLAG_TOGGLES: IWarehouseFeatureFlagToggles = {
  barcodes: {
    showNonCompliantTable: false,
  },
  deliveries: {
    assignDoor: false,
    unassignDoor: false,
    showAsnOcInfo: false,
    showCategory: false,
    showERPDates: false,
    showERPCode: false,
    showGoodsIssueReceipt: false,
    showLoadUnload: false,
    showERPBlock: false,
    showAvailability: false,
  },
  doors: {
    allowCreate: false,
    allowEdit: false,
    allowDelete: false,
  },
  inboundDeliveries: {
    showPGR: false,
    manageInboundLPs: false,
  },
  inventory: {
    deliveryAssociated: false,
    levelsEnabled: {
      [InventoryLevels.Bin]: false,
      [InventoryLevels.Counting]: false,
      [InventoryLevels.Lot]: false,
      [InventoryLevels.LostAndFound]: false,
      [InventoryLevels.LP]: false,
      [InventoryLevels.Product]: false,
    },
    levels: {
      [InventoryLevels.Bin]: {
        showAdjustInventory: false,
      },
    },
    binLevelActions: {
      adjustInventory: false,
      updateStockStatus: false,
      createCount: false,
      createBinToBin: false,
      createIssueStock: false,
    },
    licensePlateActions: {
      add: false,
      block: false,
      create: false,
      merge: false,
      move: false,
      unpack: false,
    },
    lostAndFoundActions: {
      issueStock: false,
      editStock: false,
      binToBin: false,
    },
  },
  licensePlates: {
    details: {
      showNestedTable: false,
    },
  },
  outboundDeliveries: {
    allowShortShip: false,
    showPGI: false,
    showPromiseDate: false,
    loadCreate: {
      columnType: 'product',
    },
  },
  tasks: {
    actions: {
      editUoM: false,
      cancelTasks: false,
    },
  },
};

const DEFAULT_FEATURE_FLAG_TOGGLES: IFeatureFlagToggles = {
  ...DEFAULT_APPLICATION_FEATURE_FLAG_TOGGLES,
  warehouseTypes: {
    default: DEFAULT_WAREHOUSE_FEATURE_FLAG_TOGGLES,
    aoe: DEFAULT_WAREHOUSE_FEATURE_FLAG_TOGGLES,
    pto: DEFAULT_WAREHOUSE_FEATURE_FLAG_TOGGLES,
  },
};

interface IFeatureFlagsContext {
  applicationFeatureFlags: IApplicationFeatureFlagToggles;
  warehouseTypeFeatureFlags: TWarehouseTypeFeatureFlags;
}
const FeatureFlagsContext = createContext<IFeatureFlagsContext>(null);

const FeatureFlagsProvider = ({ children }) => {
  const { setAppLoading } = useAppLoadingToggle();
  const { showMessage } = useSnackbar();

  const hasInitializedFlags = useRef(false);
  const [isLoadingFeatureFlags, setIsLoadingFeatureFlags] = useState(true);
  const [_featureFlags, setFeatureFlags] = useState<IFeatureFlagToggles>(null);
  useEffect(() => {
    setAppLoading(true);
    setIsLoadingFeatureFlags(true);

    if (process.env.REACT_APP_STUB_FEATURE_FLAGS !== 'true') {
      getWebConfig()
        .then(({ data: featureFlags }) => {
          setFeatureFlags(Object.assign({}, DEFAULT_FEATURE_FLAG_TOGGLES, featureFlags));
        })
        .catch((error: AxiosError) => {
          console.error(`Feature Flags failed to load: ${error?.message}`);
          showMessage({ type: 'error', message: 'Error retrieving feature flags.' });
          setFeatureFlags(DEFAULT_FEATURE_FLAG_TOGGLES);
        });
    } else {
      setFeatureFlags(Object.assign({}, DEFAULT_FEATURE_FLAG_TOGGLES, _stubbedFeatureFlags));
    }
  }, [_stubbedFeatureFlags]);

  const [applicationFeatureFlags, warehouseTypeFeatureFlags] = useMemo<
    [IApplicationFeatureFlagToggles, TWarehouseTypeFeatureFlags]
  >(() => {
    let _applicationFlags = _featureFlags
      ? Object.assign({}, _featureFlags)
      : DEFAULT_FEATURE_FLAG_TOGGLES;
    let _warehouseTypeFlags = _featureFlags
      ? Object.assign({}, _featureFlags.warehouseTypes)
      : DEFAULT_FEATURE_FLAG_TOGGLES.warehouseTypes;

    delete _applicationFlags.warehouseTypes;

    if (_featureFlags !== null) {
      setIsLoadingFeatureFlags(false);
    }

    return [_applicationFlags, _warehouseTypeFlags];
  }, [_featureFlags]);

  useEffect(() => {
    if (!isLoadingFeatureFlags) {
      if (!hasInitializedFlags.current) {
        hasInitializedFlags.current = true;
      } else {
        setAppLoading(false);
      }

      if (applicationFeatureFlags.maintenanceMode.enabled) {
        setAppLoading(false);
      }
    }
  }, [isLoadingFeatureFlags, applicationFeatureFlags]);

  return (
    <FeatureFlagsContext.Provider value={{ applicationFeatureFlags, warehouseTypeFeatureFlags }}>
      {isLoadingFeatureFlags ? null : applicationFeatureFlags.maintenanceMode.enabled ? (
        <MaintenanceMode />
      ) : (
        children
      )}
    </FeatureFlagsContext.Provider>
  );
};

export default FeatureFlagsProvider;

export const useFeatureFlags = () => {
  const ctx = useContext(FeatureFlagsContext);
  if (ctx === null) {
    throw new Error('useFeatureFlags must be used within FeatureFlagsProvider');
  }
  return ctx;
};
