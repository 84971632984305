/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
export type BaseInventoryFragment = { __typename?: 'InventoryBaseQueryShape', areaCode?: string | null, areaId?: string | null, availableQuantity?: string | null, binCode?: string | null, binId?: string | null, erpSalesOrder?: string | null, deliveryItemId?: string | null, stockDeliveryId?: string | null, stockDeliveryCode?: string | null, fulfillmentItemId?: string | null, stockFulfillmentId?: string | null, stockFulfillmentCode?: string | null, soldToBusinessPartnerId?: string | null, soldToBusinessPartnerName?: string | null, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, licensePlateStatus?: Types.LicensePlateStatusState | null, lotCode?: string | null, lotId?: string | null, lotManaged?: boolean | null, openTaskCount?: number | null, productCode?: string | null, productDescription?: string | null, productId?: string | null, quantity: string, stockStatus?: string | null, stockStatusTypeId?: string | null, unitOfMeasure?: string | null, unitOfMeasureId?: string | null, warehouseCode?: string | null, warehouseId?: string | null, deliveryItem?: string | null, fulfillmentItem?: string | null };

export const BaseInventoryFragmentDoc = gql`
    fragment BaseInventory on InventoryBaseQueryShape {
  areaCode
  areaId
  availableQuantity
  binCode
  binId
  erpSalesOrder
  deliveryItemId
  deliveryItem: deliveryItemItem
  stockDeliveryId
  stockDeliveryCode
  fulfillmentItemId
  fulfillmentItem: fulfillmentItemItem
  stockFulfillmentId
  stockFulfillmentCode
  soldToBusinessPartnerId
  soldToBusinessPartnerName
  licensePlateId
  licensePlateCode
  licensePlateDescription
  licensePlateStatus
  lotCode
  lotId
  lotManaged
  openTaskCount
  productCode
  productDescription
  productId
  quantity
  stockStatus
  stockStatusTypeId
  unitOfMeasure
  unitOfMeasureId
  warehouseCode
  warehouseId
}
    `;