import { ColumnDef } from '@tanstack/react-table';

import {
  ANY_COLUMN_ID,
  ColumnFilter,
  ColumnFilterValues,
  ColumnTypes,
  FilterOperators,
} from '@components/data-table/controls/filter/filter-definitions';
import { DefaultDataType } from '@components/data-table/model/data-table';
import { toCamelCase } from '@lib/string';

export const getAnyColumnTypeOperator = (columnType: ColumnTypes) => {
  switch (columnType) {
    case ColumnTypes.any:
    case ColumnTypes.string:
      return FilterOperators.iLike;
    case ColumnTypes.date:
    case ColumnTypes.dateTime:
      return FilterOperators.between;
    case ColumnTypes.boolean:
      return FilterOperators.is;
    case ColumnTypes.enum:
    case ColumnTypes.number:
    case ColumnTypes.stringRange:
    default:
      return FilterOperators.eq;
  }
};

const formatOperator = ({ columnType, operator }) => {
  if (
    columnType === ColumnTypes.date &&
    (operator === FilterOperators.eq || operator === FilterOperators.neq)
  ) {
    return FilterOperators.between;
  }
  if (operator === FilterOperators.isEmpty) {
    return FilterOperators.is;
  }
  if (operator === FilterOperators.isNotEmpty) {
    return FilterOperators.isNot;
  }
  return operator;
};

const formatValue = (
  columnType: ColumnTypes,
  operator: FilterOperators,
  value: ColumnFilterValues,
) => {
  if (columnType === ColumnTypes.number && typeof value === 'string') {
    value = parseFloat(value);
  }
  if (operator === FilterOperators.isEmpty || operator === FilterOperators.isNotEmpty) {
    return null;
  }
  if ([FilterOperators.iLike, FilterOperators.notILike].includes(operator)) {
    return `%${typeof value === 'string' ? value.trim() : value}%`;
  }
  return typeof value === 'string' ? value.trim() : value;
};

function formatFilter(filter: ColumnFilter[], columns: ColumnDef<DefaultDataType>[]) {
  return filter.reduce((formatted, next) => {
    const nextColumn = next.columnId;

    if (nextColumn !== ANY_COLUMN_ID) {
      // Handle Formatting for Columns as Normal
      // TODO: Need to figure out how the tan-stack react table typing wants the ColumnDef DataType to be typed in able to acknowledge accessorKey as a valid property...
      const columnDef = columns.find(
        // @ts-ignore
        (c) => typeof c.accessorKey !== 'undefined' && c.accessorKey === nextColumn,
      );

      const processOrColumnFilter = (hasColumnDef: boolean) => {
        const orFilter = next.or.map((_filter) => {
          const _filterOperator = hasColumnDef
            ? formatOperator({ columnType: columnDef.meta.columnType, operator: _filter.operator })
            : _filter.operator;
          const _filterValue = hasColumnDef
            ? formatValue(columnDef.meta.columnType, _filter.operator, _filter.value)
            : _filter.value;
          return {
            [_filter.columnId]: {
              [_filterOperator]: _filterValue,
            },
          };
        });

        if (formatted.or) {
          Object.assign(formatted, { or: [...formatted.or, ...orFilter] });
        } else {
          Object.assign(formatted, { or: orFilter });
        }
        return formatted;
      };

      const processColumnFilter = (hasColumnDef: boolean) => {
        const _filterOperator = hasColumnDef
          ? formatOperator({ columnType: columnDef.meta.columnType, operator: next.operator })
          : next.operator;
        const _filterValue = hasColumnDef
          ? formatValue(columnDef.meta.columnType, next.operator, next.value)
          : next.value;

        const nextFilter = {
          [nextColumn]: {
            [_filterOperator]: _filterValue,
          },
        };

        if (formatted[nextColumn]) {
          if (formatted.and) {
            formatted.and.push(nextFilter);
          } else {
            formatted.and = [nextFilter];
          }
        } else {
          Object.assign(formatted, nextFilter);
        }
      };

      if (next.or) {
        processOrColumnFilter(!!columnDef);
      } else {
        processColumnFilter(!!columnDef);
      }
    } else {
      // Apply "Any" Filter Across all Columns
      const anyOrFilter = columns
        .map((column) => {
          if (column.enableColumnFilter) {
            // TODO: Need to figure out how the tan-stack react table typing wants the ColumnDef DataType to be typed in able to acknowledge accessorKey as a valid property...
            // @ts-ignore
            const columnAccessor = column.accessorKey;
            const columnType = column.meta.columnType;
            const columnOperator = getAnyColumnTypeOperator(columnType);

            if (
              ![
                ColumnTypes.boolean,
                ColumnTypes.date,
                ColumnTypes.dateTime,
                ColumnTypes.stringRange,
              ].includes(columnType)
            ) {
              let isValidNumberValue =
                columnType !== ColumnTypes.number ||
                (columnType === ColumnTypes.number && !isNaN(Number(next.value)));

              if (isValidNumberValue) {
                if (columnType === ColumnTypes.enum) {
                  const unformattedValueMatch = column.meta.options.some(
                    (option) => option.value === next.value,
                  );
                  const formattedValueMatch = column.meta.options.some(
                    (option) =>
                      typeof next.value === 'string' && option.value === toCamelCase(next.value),
                  );
                  if (!!column.meta.options && (unformattedValueMatch || formattedValueMatch)) {
                    return {
                      [columnAccessor]: {
                        [formatOperator({ columnType, operator: columnOperator })]: formatValue(
                          columnType,
                          columnOperator,
                          unformattedValueMatch ? next.value : toCamelCase(next.value as string),
                        ),
                      },
                    };
                  }
                } else {
                  return {
                    [columnAccessor]: {
                      [formatOperator({ columnType, operator: columnOperator })]: formatValue(
                        columnType,
                        columnOperator,
                        next.value,
                      ),
                    },
                  };
                }
              }
            }
          }
          return null;
        })
        .filter((v) => !!v);

      if (formatted.or) {
        Object.assign(formatted, { or: [...formatted.or, ...anyOrFilter] });
      } else {
        Object.assign(formatted, { or: anyOrFilter });
      }
    }

    return formatted;
  }, {} as Record<string, any>);
}

export default formatFilter;
