/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateOneFulfillmentBlockMutationVariables = Types.Exact<{
  input: Types.UpdateOneFulfillmentBlockInput;
}>;


export type UpdateOneFulfillmentBlockMutation = { __typename?: 'Mutation', updateOneFulfillmentBlock: { __typename?: 'FulfillmentBlock', label: string } };

export type DeleteOneFulfillmentBlockMutationVariables = Types.Exact<{
  input: Types.DeleteOneFulfillmentBlockInput;
}>;


export type DeleteOneFulfillmentBlockMutation = { __typename?: 'Mutation', deleteOneFulfillmentBlock: { __typename?: 'FulfillmentBlock', label: string } };


export const UpdateOneFulfillmentBlockDocument = gql`
    mutation UpdateOneFulfillmentBlock($input: UpdateOneFulfillmentBlockInput!) {
  updateOneFulfillmentBlock(input: $input) {
    label
  }
}
    `;
export type UpdateOneFulfillmentBlockMutationFn = Apollo.MutationFunction<UpdateOneFulfillmentBlockMutation, UpdateOneFulfillmentBlockMutationVariables>;

/**
 * __useUpdateOneFulfillmentBlockMutation__
 *
 * To run a mutation, you first call `useUpdateOneFulfillmentBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneFulfillmentBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneFulfillmentBlockMutation, { data, loading, error }] = useUpdateOneFulfillmentBlockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOneFulfillmentBlockMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneFulfillmentBlockMutation, UpdateOneFulfillmentBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneFulfillmentBlockMutation, UpdateOneFulfillmentBlockMutationVariables>(UpdateOneFulfillmentBlockDocument, options);
      }
export type UpdateOneFulfillmentBlockMutationHookResult = ReturnType<typeof useUpdateOneFulfillmentBlockMutation>;
export type UpdateOneFulfillmentBlockMutationResult = Apollo.MutationResult<UpdateOneFulfillmentBlockMutation>;
export type UpdateOneFulfillmentBlockMutationOptions = Apollo.BaseMutationOptions<UpdateOneFulfillmentBlockMutation, UpdateOneFulfillmentBlockMutationVariables>;
export const DeleteOneFulfillmentBlockDocument = gql`
    mutation DeleteOneFulfillmentBlock($input: DeleteOneFulfillmentBlockInput!) {
  deleteOneFulfillmentBlock(input: $input) {
    label
  }
}
    `;
export type DeleteOneFulfillmentBlockMutationFn = Apollo.MutationFunction<DeleteOneFulfillmentBlockMutation, DeleteOneFulfillmentBlockMutationVariables>;

/**
 * __useDeleteOneFulfillmentBlockMutation__
 *
 * To run a mutation, you first call `useDeleteOneFulfillmentBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneFulfillmentBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneFulfillmentBlockMutation, { data, loading, error }] = useDeleteOneFulfillmentBlockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOneFulfillmentBlockMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneFulfillmentBlockMutation, DeleteOneFulfillmentBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneFulfillmentBlockMutation, DeleteOneFulfillmentBlockMutationVariables>(DeleteOneFulfillmentBlockDocument, options);
      }
export type DeleteOneFulfillmentBlockMutationHookResult = ReturnType<typeof useDeleteOneFulfillmentBlockMutation>;
export type DeleteOneFulfillmentBlockMutationResult = Apollo.MutationResult<DeleteOneFulfillmentBlockMutation>;
export type DeleteOneFulfillmentBlockMutationOptions = Apollo.BaseMutationOptions<DeleteOneFulfillmentBlockMutation, DeleteOneFulfillmentBlockMutationVariables>;