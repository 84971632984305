import { Popover } from '@mui/material';

import { Barcode } from './';

export const BarcodePopover = ({ onClose, anchorEl, code }) => {
  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      onClose={onClose}
    >
      {anchorEl ? <Barcode code={code} /> : null}
    </Popover>
  );
};
