import { InventoryBaseQueryShape, InventoryLotQueryShape, InventoryProductQueryShape } from '@/graphql/types.generated';

export enum InventoryLevels {
  Bin = 'bin',
  Counting = 'counting',
  Lot = 'lot',
  LostAndFound = 'lostAndFound',
  LP = 'lp',
  Product = 'product',
}

export type InventoryItem = Omit<InventoryBaseQueryShape, '__typename'> &
  Omit<InventoryLotQueryShape, '__typename'> &
  Omit<InventoryProductQueryShape, '__typename'>;
