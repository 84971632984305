import { gql } from '@apollo/client';

import { PopulateCompletePickTaskModalQuery } from '@/graphql/defs/components/modals/__generated__/pick-task-complete-modal.generated';

gql`
  mutation CompletePickTask($input: CompletePickTaskInputDto!) {
    completePickTask: completeOnePickTask(input: $input) {
      code
    }
  }
`;

gql`
  query PopulateCompletePickTaskModal($productId: ID!, $taskId: ID!) {
    product(id: $productId) {
      id
      description
    }
    binSources: binsForPickTask(taskId: $taskId) {
      binCode
      binId
      binLevel
      lotCode
      lotExpiration
      lotId
      lotProductionDate
      quantity
    }
    licensePlateSources: licensePlatesForPickTask(taskId: $taskId) {
      binCode
      binId
      licensePlateCode
      licensePlateId
      lotCode
      lotExpirationDate
      lotId
      lotProductionDate
      quantity
    }
  }
`;

export const PopulateCompletePickTaskModal_defaultData: PopulateCompletePickTaskModalQuery = {
  __typename: 'Query',
  product: null,
  binSources: [],
  licensePlateSources: [],
};
