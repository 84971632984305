import { Grid } from '@mui/material';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateAreaMutation } from '@/graphql/defs/components/modals/__generated__/create-area-modal.generated';
import { useListSapStorageLocationsQuery } from '@/graphql/defs/list/__generated__/list-sap-storage-location.generated';
import { ListSapStorageLocations_defaultData } from '@/graphql/defs/list/list-sap-storage-location';
import {
  AreaCreateInput,
  AreaStatus,
  AreaType,
  SapStorageLocationPlantSortFields,
  SortDirection,
} from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import { uppercase } from '@lib/form';
import FormValues from '@models/FormValues';
import { IAreaCreateModal } from '@models/modal';

const CreateAreaModal = () => {
  const { t } = useTranslation('components');
  const { closeModal, isPreparing, setLoading, setPreparing } = useModalContent<IAreaCreateModal>();
  const { selectedWarehouseId } = useEntityUtils();
  const { showMessage } = useSnackbar();

  const formMethods = useForm<FormValues<AreaCreateInput>>({
    defaultValues: {
      name: '',
      code: '',
      description: '',
      status: '',
      sapStorageLocationPlantId: '',
    },
  });
  const { control, handleSubmit } = formMethods;
  const { notWhiteSpaceOnly } = useFormValidation();

  const [createArea] = useCreateAreaMutation({
    onCompleted: ({ createOneArea: { name } }) => {
      showMessage({
        type: 'success',
        message: t('modal.area.createSuccess', { name }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const { data: storageLocationData } = useListSapStorageLocationsQuery({
    variables: {
      sorting: [
        { field: SapStorageLocationPlantSortFields.Plant, direction: SortDirection.Asc },
        {
          field: SapStorageLocationPlantSortFields.StorageLocation,
          direction: SortDirection.Asc,
        },
      ],
    },
    onCompleted: () => {
      setPreparing(false);
    },
    onError: () => {
      setPreparing(false);
      showMessage({
        type: 'error',
        message: '',
      });
    },
  });
  const {
    sapStorageLocationPlants: { storageLocations },
  } = useMemo(
    () => storageLocationData || ListSapStorageLocations_defaultData,
    [storageLocationData],
  );

  const onSubmit = (areaFields: AreaCreateInput) => {
    void createArea({
      variables: {
        newArea: { ...areaFields, type: AreaType.Perm, warehouseId: selectedWarehouseId },
      },
    });
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Grid container spacing={6}>
            {/* TODO: Need to add type to ViewArea before we can have it as a user manageable field. */}
            {/*<Grid  item xs={6}>*/}
            {/*  <Controller*/}
            {/*    name="type"*/}
            {/*    control={control}*/}
            {/*    rules={{*/}
            {/*      required: t('form.validation.requiredField', { field: t('common.type') }),*/}
            {/*      validate: {*/}
            {/*        notWhiteSpaceOnly,*/}
            {/*      },*/}
            {/*    }}*/}
            {/*    render={({ field, fieldState }) => (*/}
            {/*      <TextField*/}
            {/*        fullWidth*/}
            {/*        select*/}
            {/*        SelectProps={{*/}
            {/*          native: true,*/}
            {/*        }}*/}
            {/*        id="type"*/}
            {/*        label={t('common.type')}*/}
            {/*        {...field}*/}
            {/*        error={!!fieldState?.error}*/}
            {/*        helperText={fieldState?.error?.message}*/}
            {/*      >*/}
            {/*        <option disabled />*/}
            {/*        {enumKeys(AreaType).map((type) => {*/}
            {/*          return (*/}
            {/*            <option key={`type-${type}`} value={AreaType[type]}>*/}
            {/*              {t(`common.${AreaType[type]}`)}*/}
            {/*            </option>*/}
            {/*          );*/}
            {/*        })}*/}
            {/*      </TextField>*/}
            {/*    )}*/}
            {/*  />*/}
            {/*</Grid>*/}
            {/*<Grid item xs={6} />*/}
            <Grid item xs={6}>
              <Controller
                name="code"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('areas.columns.code') }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    autoFocus
                    required
                    fullWidth
                    dataTestId="area-code"
                    label={t('areas.columns.code')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onChange={(e) => field.onChange(uppercase(e))}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', { field: t('areas.columns.name') }),
                  validate: {
                    notWhiteSpaceOnly,
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    dataTestId="area-name"
                    label={t('areas.columns.name')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="sapStorageLocationPlantId"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('areas.columns.storageLocation'),
                  }),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    fullWidth
                    required
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="storageLocation"
                    dataTestId="area-storage-location"
                    label={t('common.storageLocation')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                  >
                    <option disabled />
                    {storageLocations.map((storageLocation) => (
                      <option
                        key={`${storageLocation.plant}//${storageLocation.storageLocation}`}
                        value={storageLocation.id}
                      >
                        {`${storageLocation.plant}: ${storageLocation.storageLocation}`}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="status"
                control={control}
                rules={{
                  required: t('form.validation.requiredField', {
                    field: t('areas.columns.status'),
                  }),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    required
                    id="area-status"
                    label={t('areas.columns.status')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    select
                    SelectProps={{ native: true }}
                    dataTestId="area-status"
                  >
                    <option disabled />
                    {Object.values(AreaStatus).map((status) => (
                      <option key={status} value={status}>
                        {t(`common.${status}`)}
                      </option>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    dataTestId="area-description"
                    label={t('areas.columns.description')}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    maxRows={3}
                    multiline
                  />
                )}
              />
            </Grid>
          </Grid>
        </ModalContent>
        <ModalActions>
          <ModalButton
            onClick={() => closeModal()}
            variant="outlined"
            color="primary"
            actionType="cancel"
          >
            {t('common.cancel')}
          </ModalButton>
          <ModalButton
            data-testid="createAreaModal_submit"
            variant="contained"
            color="primary"
            actionType="submit"
          >
            {t('common.submit')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default CreateAreaModal;
