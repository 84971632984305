/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeliveryLpInventoryFragment = { __typename?: 'InventoryBaseQueryShape', areaCode?: string | null, areaId?: string | null, binCode?: string | null, binId?: string | null, stockStatus?: string | null, stockStatusTypeId?: string | null, unitOfMeasure?: string | null, unitOfMeasureId?: string | null, warehouseCode?: string | null, warehouseId?: string | null, quantity: string, availableQuantity?: string | null, productId?: string | null, productCode?: string | null, productDescription?: string | null, lotId?: string | null, lotCode?: string | null, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateStatus?: Types.LicensePlateStatusState | null, licensePlateDescription?: string | null, openTaskQuantity?: string | null };

export type GetDeliveryLpInventoryQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.InventoryBaseQueryShapeFilter>;
  sorting?: Types.InputMaybe<Array<Types.InventoryBaseQueryShapeSort> | Types.InventoryBaseQueryShapeSort>;
}>;


export type GetDeliveryLpInventoryQuery = { __typename?: 'Query', deliveryItems: { __typename?: 'InventoryBaseQueryShapeOffsetConnection', totalCount: number, nodes: Array<{ __typename?: 'InventoryBaseQueryShape', areaCode?: string | null, areaId?: string | null, binCode?: string | null, binId?: string | null, stockStatus?: string | null, stockStatusTypeId?: string | null, unitOfMeasure?: string | null, unitOfMeasureId?: string | null, warehouseCode?: string | null, warehouseId?: string | null, quantity: string, availableQuantity?: string | null, productId?: string | null, productCode?: string | null, productDescription?: string | null, lotId?: string | null, lotCode?: string | null, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateStatus?: Types.LicensePlateStatusState | null, licensePlateDescription?: string | null, openTaskQuantity?: string | null }> } };

export const DeliveryLpInventoryFragmentDoc = gql`
    fragment DeliveryLPInventory on InventoryBaseQueryShape {
  areaCode
  areaId
  binCode
  binId
  stockStatus
  stockStatusTypeId
  unitOfMeasure
  unitOfMeasureId
  warehouseCode
  warehouseId
  quantity
  availableQuantity
  productId
  productCode
  productDescription
  lotId
  lotCode
  licensePlateId
  licensePlateCode
  licensePlateStatus
  licensePlateDescription
  openTaskQuantity
}
    `;
export const GetDeliveryLpInventoryDocument = gql`
    query GetDeliveryLPInventory($paging: OffsetPaging, $filter: InventoryBaseQueryShapeFilter, $sorting: [InventoryBaseQueryShapeSort!]) {
  deliveryItems: baseInventoryAll(
    paging: $paging
    filter: $filter
    sorting: $sorting
  ) {
    nodes {
      ...DeliveryLPInventory
    }
    totalCount
  }
}
    ${DeliveryLpInventoryFragmentDoc}`;

/**
 * __useGetDeliveryLpInventoryQuery__
 *
 * To run a query within a React component, call `useGetDeliveryLpInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryLpInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryLpInventoryQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useGetDeliveryLpInventoryQuery(baseOptions?: Apollo.QueryHookOptions<GetDeliveryLpInventoryQuery, GetDeliveryLpInventoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryLpInventoryQuery, GetDeliveryLpInventoryQueryVariables>(GetDeliveryLpInventoryDocument, options);
      }
export function useGetDeliveryLpInventoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryLpInventoryQuery, GetDeliveryLpInventoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryLpInventoryQuery, GetDeliveryLpInventoryQueryVariables>(GetDeliveryLpInventoryDocument, options);
        }
export type GetDeliveryLpInventoryQueryHookResult = ReturnType<typeof useGetDeliveryLpInventoryQuery>;
export type GetDeliveryLpInventoryLazyQueryHookResult = ReturnType<typeof useGetDeliveryLpInventoryLazyQuery>;
export type GetDeliveryLpInventoryQueryResult = Apollo.QueryResult<GetDeliveryLpInventoryQuery, GetDeliveryLpInventoryQueryVariables>;