import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DeliveryCompletionStatus, ViewDeliveryItemSortFields } from '@/graphql/types.generated';
import {
  ColumnFilter,
  FilterOperators,
} from '@components/data-table/controls/filter/filter-definitions';
import useCreateInboundDeliveryItemColumns, {
  DELIVERY_ITEMS_HOOK,
  TDeliveryItemsDataType,
  TDeliveryItemsFieldNames,
  TDeliveryItemsFilterType,
} from '@components/data-table/hooks/shared-columns/useCreateInboundDeliveryItemColumns';
import useCreateDataTableColumns from '@components/data-table/hooks/useCreateDataTableColumns';
import useDataTableSelection, {
  SelectionType,
} from '@components/data-table/hooks/useDataTableSelection';
import useTriggerDataTableRefetch from '@components/data-table/hooks/useTriggerDataTableRefetch';
import { validateDataTableProps } from '@components/data-table/lib/validateDataTableProps';
import { IDataTableSort, RowSelectionEnabledFilter } from '@components/data-table/model/data-table';
import TableIds from '@components/data-table/model/TableIds';
import { IDropdownMenuItem } from '@components/dropdown-menu';
import { InboundDeliveryItemsView } from '@pages/deliveries/inbound-deliveries/tables/InboundDeliveryItemsInvDataTables';

const useInboundDeliveryItemsDataTable = (
  deliveryId: string,
  deliveryItemViews: IDropdownMenuItem<InboundDeliveryItemsView>[] | undefined,
  disableSelection?: boolean,
) => {
  const { t } = useTranslation('pages');

  const { selection, clearSelection, rowSelection } = useDataTableSelection<TDeliveryItemsDataType>(
    SelectionType.multi,
    'id',
  );
  const [refetchTrigger, triggerDataTableRefetch] = useTriggerDataTableRefetch({
    triggerCallback: clearSelection,
  });

  const createInboundDeliveryItemColumns = useCreateInboundDeliveryItemColumns({
    dataTestId: 'delivery-items',
    removeColumns: ['deliveryCode', 'deliveryDueDate'],
  });
  const columns = useCreateDataTableColumns<TDeliveryItemsDataType>(
    createInboundDeliveryItemColumns,
  );

  const baseFilter = useMemo<ColumnFilter<TDeliveryItemsFieldNames>[]>(
    () => [
      {
        columnId: ViewDeliveryItemSortFields.DeliveryId,
        operator: FilterOperators.eq,
        value: deliveryId,
      },
    ],
    [deliveryId],
  );

  const defaultSorting: IDataTableSort<TDeliveryItemsFieldNames>[] = useMemo(
    () => [
      {
        id: ViewDeliveryItemSortFields.Item,
        desc: false,
      },
      {
        id: ViewDeliveryItemSortFields.ProductCode,
        desc: false,
      },
    ],
    [],
  );

  const rowSelectionEnabledFilter = useCallback<RowSelectionEnabledFilter<TDeliveryItemsDataType>>(
    (row) =>
      row.original.unloadStatus === DeliveryCompletionStatus.NotStarted &&
      row.original.putawayStatus === DeliveryCompletionStatus.NotStarted,
    [],
  );

  return {
    selectedDeliveryItems: selection,
    triggerIbdDeliveryItemDataTableRefetch: triggerDataTableRefetch,
    ibdDeliveryItemDataTableProps: validateDataTableProps<
      TDeliveryItemsDataType,
      TDeliveryItemsFilterType,
      TDeliveryItemsFieldNames
    >({
      type: 'query',
      tableId: TableIds.InboundDeliveryItems,
      tableHeader: t(`deliveries.deliveryItems.${InboundDeliveryItemsView.DeliveryItem}`),
      layoutDropdownAddtOptions: deliveryItemViews || undefined,
      layoutDropdownAddtOptionLabel: !!deliveryItemViews
        ? t('deliveries.deliveryItemViews')
        : undefined,
      queryHook: DELIVERY_ITEMS_HOOK,
      columns,
      baseFilter,
      defaultSorting,
      refetchTrigger,
      rowSelection: !disableSelection
        ? {
            ...rowSelection,
            rowSelectionEnabledFilter,
          }
        : { enableRowSelection: SelectionType.none },
    }),
  };
};

export default useInboundDeliveryItemsDataTable;
