import { Grid } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useDeleteTeamMutation,
  useUpdateTeamMutation,
} from '@/graphql/defs/components/modals/__generated__/team-update-modal.generated';
import { PopulateTeamsSettingsPageDocument } from '@/graphql/defs/pages/__generated__/teams.generated';
import { TeamUpdateInput } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import { stripUnchangedFields } from '@lib/form';
import FormValues from '@models/FormValues';
import { ITeamUpdateModal } from '@models/modal';

const TeamUpdateModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, setLoading } = useModalContent<ITeamUpdateModal>();
  const { showMessage } = useSnackbar();
  const { selectedWarehouseId } = useEntityUtils();

  const defaultValues: FormValues<TeamUpdateInput> = {
    name: modal.team?.name || '',
    description: modal.team?.description || '',
  };
  const formMethods = useForm<FormValues<TeamUpdateInput>>({
    defaultValues,
  });
  const { control, handleSubmit } = formMethods;
  const { notWhiteSpaceOnly } = useFormValidation();

  const [updateTeam] = useUpdateTeamMutation({
    refetchQueries: [
      { query: PopulateTeamsSettingsPageDocument, variables: { warehouseId: selectedWarehouseId } },
    ],
    onCompleted: ({ updateOneTeam: { name } }) => {
      showMessage({
        type: 'success',
        message: t('modal.team.update.success', { name }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const [deleteTeam] = useDeleteTeamMutation({
    refetchQueries: [
      { query: PopulateTeamsSettingsPageDocument, variables: { warehouseId: selectedWarehouseId } },
    ],
    onCompleted: ({ deleteOneTeam: { name } }) => {
      showMessage({
        type: 'success',
        message: t('modal.team.update.deleteSuccess', { name }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onSubmit = (fields: TeamUpdateInput) => {
    void updateTeam({
      variables: {
        id: modal.team?.id,
        updatedTeam: stripUnchangedFields(defaultValues, fields),
      },
    });
  };

  const onDelete = () => {
    void deleteTeam({ variables: { id: modal.team?.id } });
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: t('form.validation.requiredField', { field: t('teams.name') }),
                validate: {
                  notWhiteSpaceOnly,
                },
              }}
              render={({ field, fieldState }) => (
                <TextField
                  autoFocus
                  fullWidth
                  required
                  id="team-name"
                  dataTestId="team-name"
                  label={t('teams.name')}
                  {...field}
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  fullWidth
                  id="team-description"
                  dataTestId="team-description"
                  label={t('teams.descriptionOfTeam')}
                  {...field}
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  minRows={3}
                  maxRows={3}
                  multiline
                />
              )}
            />
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        <ModalButton
          data-testid={`team-delete-modal-button`}
          onClick={() => onDelete()}
          variant="outlined"
          color="error"
          actionType="delete"
          sx={{ marginRight: 'auto' }}
        >
          {t('common.delete')}
        </ModalButton>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          data-testid={`team-submit-modal-button`}
          variant="contained"
          color="primary"
          actionType="submit"
        >
          {t('common.submit')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default TeamUpdateModal;
