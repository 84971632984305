import {
  ColumnOrderState,
  ColumnSizingState,
  createColumnHelper,
  getCoreRowModel,
  RowSelectionState,
  useReactTable,
} from '@tanstack/react-table';
import { createContext, useContext, useMemo, useState } from 'react';

import createRowSelectionColumnDef from '@components/data-table/columnDefs/rowSelection';
import { SelectionType } from '@components/data-table/hooks/useDataTableSelection';
import useHandleQueryBasedSelection from '@components/data-table/hooks/useHandleQueryBasedSelection';
import {
  IDataTableDataBasedContext,
  IDataTableDataBasedProviderProps,
} from '@components/data-table/model/data-table';

const DataTableDataBasedContext = createContext<IDataTableDataBasedContext>(null);

const DataTableDataBasedProvider = ({
  dataTableProps: props,
  children,
}: IDataTableDataBasedProviderProps) => {
  const tableId = props.tableId;
  const data = props.data;

  const memoizedColumns = useMemo(
    () =>
      props.rowSelection?.enableRowSelection !== SelectionType.none
        ? [createRowSelectionColumnDef(createColumnHelper()), ...props.columns]
        : props.columns,
    [props.columns, props.rowSelection?.enableRowSelection],
  );

  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [columnSizing, setColumnSizing] = useState<ColumnSizingState>({});
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});

  const tableInstance = useReactTable({
    data,
    columns: memoizedColumns,
    columnResizeMode: 'onChange',
    defaultColumn: {
      size: 150,
      minSize: 20,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
    state: {
      columnVisibility,
      columnOrder,
      columnSizing,
      rowSelection: selectedRows,
    },
    enableRowSelection:
      props.rowSelection?.enableRowSelection !== SelectionType.none &&
      (typeof props.rowSelection?.rowSelectionEnabledFilter === 'function'
        ? props.rowSelection?.rowSelectionEnabledFilter
        : true),
    enableColumnResizing: true,
    enableColumnFilters: false,
    enableFilters: false,
    enableGlobalFilter: false,
    enableHiding: false,
    enableMultiSort: false,
    enableSorting: false,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    onRowSelectionChange: setSelectedRows,
    onColumnSizingChange: setColumnSizing,
    getCoreRowModel: getCoreRowModel(),
    meta: {
      rowSelectionType: props.rowSelection?.enableRowSelection,
    },
  });

  const _memoizedHeaderColumns = useMemo(
    () => tableInstance.getHeaderGroups()[0].headers,
    [memoizedColumns, columnOrder, columnSizing, columnVisibility],
  );

  useHandleQueryBasedSelection({
    data,
    selectionType: props.rowSelection.enableRowSelection,
    selectionDataKey: props.rowSelection.selectionDataKey,
    selectionOverride: props.rowSelection.selectionOverride,
    selectedVisibleRows: selectedRows,
    setSelectedVisibleRows: setSelectedRows,
    setSelectedRowsData: props.rowSelection.setSelectedRowsData,
    hasCompletedFirstFetch: true,
    clearSelectionTrigger: props.rowSelection.clearSelectionTrigger,
  });

  return (
    <DataTableDataBasedContext.Provider
      value={{
        tableId,
        tableSize: tableInstance.getTotalSize(),
        allColumns: tableInstance.getAllLeafColumns(),
        headerColumns: _memoizedHeaderColumns,
        isLoadingData: props.isDataLoading,
        data: data,
        dataRows: tableInstance.getRowModel().rows,
        selectedRows,
      }}
    >
      {children}
    </DataTableDataBasedContext.Provider>
  );
};

export default DataTableDataBasedProvider;

export const useDataBasedDataTable = () => {
  const ctx = useContext(DataTableDataBasedContext);
  if (ctx === null) {
    throw new Error('useDataBasedDataTable must be used within DataTableDataBasedProvider');
  }

  return ctx;
};
