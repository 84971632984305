/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MergeLicensePlatesMutationVariables = Types.Exact<{
  binId: Types.Scalars['ID']['input'];
  licensePlateId: Types.Scalars['ID']['input'];
  products: Array<Types.LicensePlateMovementProductInput> | Types.LicensePlateMovementProductInput;
  autocomplete: Types.Scalars['Boolean']['input'];
}>;


export type MergeLicensePlatesMutation = { __typename?: 'Mutation', mergeLicensePlates: { __typename?: 'LicensePlate', id: string, code: string } };


export const MergeLicensePlatesDocument = gql`
    mutation MergeLicensePlates($binId: ID!, $licensePlateId: ID!, $products: [LicensePlateMovementProductInput!]!, $autocomplete: Boolean!) {
  mergeLicensePlates: moveStockToLicensePlate(
    input: {licensePlateMovement: {autocomplete: $autocomplete, destinationBinId: $binId, licensePlateId: $licensePlateId, products: $products}}
  ) {
    id
    code
  }
}
    `;
export type MergeLicensePlatesMutationFn = Apollo.MutationFunction<MergeLicensePlatesMutation, MergeLicensePlatesMutationVariables>;

/**
 * __useMergeLicensePlatesMutation__
 *
 * To run a mutation, you first call `useMergeLicensePlatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeLicensePlatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeLicensePlatesMutation, { data, loading, error }] = useMergeLicensePlatesMutation({
 *   variables: {
 *      binId: // value for 'binId'
 *      licensePlateId: // value for 'licensePlateId'
 *      products: // value for 'products'
 *      autocomplete: // value for 'autocomplete'
 *   },
 * });
 */
export function useMergeLicensePlatesMutation(baseOptions?: Apollo.MutationHookOptions<MergeLicensePlatesMutation, MergeLicensePlatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MergeLicensePlatesMutation, MergeLicensePlatesMutationVariables>(MergeLicensePlatesDocument, options);
      }
export type MergeLicensePlatesMutationHookResult = ReturnType<typeof useMergeLicensePlatesMutation>;
export type MergeLicensePlatesMutationResult = Apollo.MutationResult<MergeLicensePlatesMutation>;
export type MergeLicensePlatesMutationOptions = Apollo.BaseMutationOptions<MergeLicensePlatesMutation, MergeLicensePlatesMutationVariables>;