/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PiCreateTaskFragment = { __typename?: 'Task', id: string, code: string, status?: Types.TaskStatus | null, productId?: string | null, destinationBinId?: string | null, metaData?: any | null, taskType?: { __typename?: 'TaskType', id: string, code: string } | null };

export type CreateOnePhysicalInventoryTaskMutationVariables = Types.Exact<{
  input: Types.CreatePiTaskInputDto;
}>;


export type CreateOnePhysicalInventoryTaskMutation = { __typename?: 'Mutation', createOnePhysicalInventoryTask: { __typename?: 'Task', id: string, code: string, status?: Types.TaskStatus | null, productId?: string | null, destinationBinId?: string | null, metaData?: any | null, taskType?: { __typename?: 'TaskType', id: string, code: string } | null } };

export const PiCreateTaskFragmentDoc = gql`
    fragment PICreateTask on Task {
  id
  code
  status
  productId
  destinationBinId
  metaData
  taskType {
    id
    code
  }
}
    `;
export const CreateOnePhysicalInventoryTaskDocument = gql`
    mutation CreateOnePhysicalInventoryTask($input: CreatePITaskInputDto!) {
  createOnePhysicalInventoryTask(input: $input) {
    ...PICreateTask
  }
}
    ${PiCreateTaskFragmentDoc}`;
export type CreateOnePhysicalInventoryTaskMutationFn = Apollo.MutationFunction<CreateOnePhysicalInventoryTaskMutation, CreateOnePhysicalInventoryTaskMutationVariables>;

/**
 * __useCreateOnePhysicalInventoryTaskMutation__
 *
 * To run a mutation, you first call `useCreateOnePhysicalInventoryTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOnePhysicalInventoryTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOnePhysicalInventoryTaskMutation, { data, loading, error }] = useCreateOnePhysicalInventoryTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOnePhysicalInventoryTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateOnePhysicalInventoryTaskMutation, CreateOnePhysicalInventoryTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOnePhysicalInventoryTaskMutation, CreateOnePhysicalInventoryTaskMutationVariables>(CreateOnePhysicalInventoryTaskDocument, options);
      }
export type CreateOnePhysicalInventoryTaskMutationHookResult = ReturnType<typeof useCreateOnePhysicalInventoryTaskMutation>;
export type CreateOnePhysicalInventoryTaskMutationResult = Apollo.MutationResult<CreateOnePhysicalInventoryTaskMutation>;
export type CreateOnePhysicalInventoryTaskMutationOptions = Apollo.BaseMutationOptions<CreateOnePhysicalInventoryTaskMutation, CreateOnePhysicalInventoryTaskMutationVariables>;