import { gql } from '@apollo/client';

import { GetMapsQuery } from '@/graphql/defs/shared-queries/__generated__/get-maps.generated';

gql`
  query GetMaps($filter: MapFilter) {
    maps(filter: $filter) {
      nodes {
        warehouseId
        name
        mapJson
      }
    }
  }
`;

export const GetMaps_defaultData: GetMapsQuery = {
  __typename: 'Query',
  maps: {
    __typename: 'MapOffsetConnection',
    nodes: [],
  },
};
