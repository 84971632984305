import { DateTime } from 'luxon';

import i18n from '@/i18n';

export enum ColumnTypes {
  any = 'any',
  string = 'string',
  stringRange = 'stringRange', // strings that need filtering with ranges
  boolean = 'boolean',
  number = 'number',
  enum = 'enum',
  date = 'date',
  dateTime = 'dateTime',
}

export enum FilterOperators {
  eq = 'eq',
  neq = 'neq',
  iLike = 'iLike',
  notILike = 'notILike',
  is = 'is',
  isNot = 'isNot',
  gt = 'gt',
  gte = 'gte',
  lt = 'lt',
  lte = 'lte',
  between = 'between',
  notBetween = 'notBetween',
  in = 'in',
  notIn = 'notIn',
  isEmpty = 'isEmpty',
  isNotEmpty = 'isNotEmpty',
}

export type ColumnFilterEnumOption = { value: string; display: string };

export type ColumnFilterBetweenValue<BoundType = any> = { lower: BoundType; upper: BoundType };
export type ColumnFilterValues =
  | string
  | number
  | boolean
  | null
  | ColumnFilterBetweenValue
  | DateTime
  | string[];

interface ColumnFilterDynamicProperties<FilterColumn = string> {
  columnId: FilterColumn;
  operator: FilterOperators;
}
export interface ColumnFilterWithoutOr<FilterColumn = string>
  extends Required<ColumnFilterDynamicProperties<FilterColumn>> {
  value?: ColumnFilterValues;
  or?: never;
}
export interface ColumnFilterWithOr<FilterColumn = string>
  extends Partial<ColumnFilterDynamicProperties<FilterColumn>> {
  value?: ColumnFilterValues;
  or?: ColumnFilterWithoutOr<FilterColumn>[];
}
export interface LinkedColumnFilter<FilterColumn = string>
  extends ColumnFilterWithOr<FilterColumn> {
  linked: true;
  linkedTooltip: string;
}
export type ColumnFilter<FilterColumn = string> =
  | ColumnFilterWithoutOr<FilterColumn>
  | ColumnFilterWithOr<FilterColumn>
  | LinkedColumnFilter<FilterColumn>;

export interface ColumnFilterDefinition {
  id: string;
  label: string;
  columnType: ColumnTypes;
  options?: ColumnFilterEnumOption[];
  booleanLabels?: string[];
}

export const DEFAULT_FILTER: ColumnFilter = {
  columnId: '',
  operator: FilterOperators.eq,
  value: '',
};

export const ANY_COLUMN_ID = 'anyColumnQuickFilter';
export const ANY_COLUMN: ColumnFilterDefinition = {
  id: ANY_COLUMN_ID,
  label: i18n.t('common.any', { ns: 'components' }),
  columnType: ColumnTypes.any,
};

export const ANY_COLUMN_FILTER: ColumnFilter<string> = {
  ...DEFAULT_FILTER,
  columnId: ANY_COLUMN_ID,
  operator: FilterOperators.iLike,
};

export interface IFilterDef {
  operator: FilterOperators;
  defaultValue: ColumnFilterValues;
}
type TFilterDefs = Record<ColumnTypes, IFilterDef[]>;

export const FILTER_DEFINITIONS: TFilterDefs = {
  any: [{ operator: FilterOperators.iLike, defaultValue: '' }],
  string: [
    { operator: FilterOperators.eq, defaultValue: '' },
    { operator: FilterOperators.neq, defaultValue: '' },
    { operator: FilterOperators.iLike, defaultValue: '' },
    { operator: FilterOperators.notILike, defaultValue: '' },
    { operator: FilterOperators.isEmpty, defaultValue: '' },
    { operator: FilterOperators.isNotEmpty, defaultValue: '' },
  ],
  stringRange: [
    { operator: FilterOperators.eq, defaultValue: '' },
    { operator: FilterOperators.neq, defaultValue: '' },
    { operator: FilterOperators.iLike, defaultValue: '' },
    { operator: FilterOperators.notILike, defaultValue: '' },
    { operator: FilterOperators.gt, defaultValue: '' },
    { operator: FilterOperators.gte, defaultValue: '' },
    { operator: FilterOperators.lt, defaultValue: '' },
    { operator: FilterOperators.lte, defaultValue: '' },
    { operator: FilterOperators.between, defaultValue: { lower: '', upper: '' } },
    { operator: FilterOperators.notBetween, defaultValue: { lower: '', upper: '' } },
  ],
  boolean: [
    { operator: FilterOperators.is, defaultValue: true },
    { operator: FilterOperators.isNot, defaultValue: true },
    { operator: FilterOperators.isEmpty, defaultValue: '' },
    { operator: FilterOperators.isNotEmpty, defaultValue: '' },
  ],
  number: [
    { operator: FilterOperators.eq, defaultValue: '' },
    { operator: FilterOperators.neq, defaultValue: '' },
    { operator: FilterOperators.gt, defaultValue: '' },
    { operator: FilterOperators.gte, defaultValue: '' },
    { operator: FilterOperators.lt, defaultValue: '' },
    { operator: FilterOperators.lte, defaultValue: '' },
    { operator: FilterOperators.between, defaultValue: { lower: '', upper: '' } },
    { operator: FilterOperators.notBetween, defaultValue: { lower: '', upper: '' } },
    { operator: FilterOperators.isEmpty, defaultValue: '' },
    { operator: FilterOperators.isNotEmpty, defaultValue: '' },
  ],
  enum: [
    { operator: FilterOperators.eq, defaultValue: null },
    { operator: FilterOperators.neq, defaultValue: null },
    { operator: FilterOperators.in, defaultValue: [] },
    { operator: FilterOperators.notIn, defaultValue: [] },
  ],
  date: [
    { operator: FilterOperators.eq, defaultValue: { lower: null, upper: null } },
    { operator: FilterOperators.gt, defaultValue: null },
    { operator: FilterOperators.gte, defaultValue: null },
    { operator: FilterOperators.lt, defaultValue: null },
    { operator: FilterOperators.lte, defaultValue: null },
    { operator: FilterOperators.between, defaultValue: { lower: null, upper: null } },
    { operator: FilterOperators.notBetween, defaultValue: { lower: null, upper: null } },
  ],
  dateTime: [
    { operator: FilterOperators.eq, defaultValue: null },
    { operator: FilterOperators.gt, defaultValue: null },
    { operator: FilterOperators.gte, defaultValue: null },
    { operator: FilterOperators.lt, defaultValue: null },
    { operator: FilterOperators.lte, defaultValue: null },
    { operator: FilterOperators.between, defaultValue: { lower: null, upper: null } },
    { operator: FilterOperators.notBetween, defaultValue: { lower: null, upper: null } },
  ],
};

export const getDefaultValue = (
  columnType: keyof typeof FILTER_DEFINITIONS,
  operator: FilterOperators,
) => {
  return (
    [...FILTER_DEFINITIONS[columnType]]?.find((filterDef) => filterDef.operator === operator)
      ?.defaultValue || null
  );
};
