/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BinStorageTypeDetailsFragment = { __typename?: 'ViewBinStorageType', id?: string | null, code?: string | null, label?: string | null, description?: string | null, weightCapacityUomId?: string | null, weightCapacityUomCode?: string | null, weightCapacity?: number | null, distanceUomId?: string | null, distanceUomCode?: string | null, depth?: number | null, width?: number | null, height?: number | null, warehouseCode?: string | null };

export type BinStorageTypeDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type BinStorageTypeDetailsQuery = { __typename?: 'Query', viewBinStorageTypes: { __typename?: 'ViewBinStorageTypeOffsetConnection', nodes: Array<{ __typename?: 'ViewBinStorageType', id?: string | null, code?: string | null, label?: string | null, description?: string | null, weightCapacityUomId?: string | null, weightCapacityUomCode?: string | null, weightCapacity?: number | null, distanceUomId?: string | null, distanceUomCode?: string | null, depth?: number | null, width?: number | null, height?: number | null, warehouseCode?: string | null }> } };

export const BinStorageTypeDetailsFragmentDoc = gql`
    fragment BinStorageTypeDetails on ViewBinStorageType {
  id
  code
  label
  description
  weightCapacityUomId
  weightCapacityUomCode
  weightCapacity
  distanceUomId
  distanceUomCode
  depth
  width
  height
  warehouseCode
}
    `;
export const BinStorageTypeDetailsDocument = gql`
    query BinStorageTypeDetails($id: ID!) {
  viewBinStorageTypes(filter: {id: {eq: $id}}) {
    nodes {
      ...BinStorageTypeDetails
    }
  }
}
    ${BinStorageTypeDetailsFragmentDoc}`;

/**
 * __useBinStorageTypeDetailsQuery__
 *
 * To run a query within a React component, call `useBinStorageTypeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBinStorageTypeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBinStorageTypeDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBinStorageTypeDetailsQuery(baseOptions: Apollo.QueryHookOptions<BinStorageTypeDetailsQuery, BinStorageTypeDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BinStorageTypeDetailsQuery, BinStorageTypeDetailsQueryVariables>(BinStorageTypeDetailsDocument, options);
      }
export function useBinStorageTypeDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BinStorageTypeDetailsQuery, BinStorageTypeDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BinStorageTypeDetailsQuery, BinStorageTypeDetailsQueryVariables>(BinStorageTypeDetailsDocument, options);
        }
export type BinStorageTypeDetailsQueryHookResult = ReturnType<typeof useBinStorageTypeDetailsQuery>;
export type BinStorageTypeDetailsLazyQueryHookResult = ReturnType<typeof useBinStorageTypeDetailsLazyQuery>;
export type BinStorageTypeDetailsQueryResult = Apollo.QueryResult<BinStorageTypeDetailsQuery, BinStorageTypeDetailsQueryVariables>;