import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useSlottingAbcAnalysisDataTableLazyQuery } from '@/graphql/defs/hooks/table-props/__generated__/useSlottingAbcAnalysisDataTable.generated';
import { ColumnTypes } from '@components/data-table/controls/filter/filter-definitions';
import { TColumnFactory } from '@components/data-table/hooks/useCreateDataTableColumns';
import filterDataTableColumnDefs from '@components/data-table/lib/filterDataTableColumnDefs';
import {
  TExtractLazyHookDataType,
  TExtractLazyHookFetchFilterType,
  TExtractLazyHookFieldNames,
} from '@components/data-table/model/extract-query-hook-types';
import { useDateTime } from '@context/date-time';

export const ABC_ANALYSIS_HOOK = useSlottingAbcAnalysisDataTableLazyQuery;
export type TABCAnalysisDataType = TExtractLazyHookDataType<typeof ABC_ANALYSIS_HOOK>;
export type TABCAnalysisFilterType = TExtractLazyHookFetchFilterType<typeof ABC_ANALYSIS_HOOK>;
export type TABCAnalysisFieldNames = TExtractLazyHookFieldNames<typeof ABC_ANALYSIS_HOOK>;

interface IUseCreateSlottingAbcAnalysisColumns {
  addColumnsToStart?: TColumnFactory<TABCAnalysisDataType>;
  removeColumns?: (keyof TABCAnalysisDataType)[];
}
const useCreateSlottingAbcAnalysisColumns = ({
  addColumnsToStart,
  removeColumns = [],
}: IUseCreateSlottingAbcAnalysisColumns = {}) => {
  const { t } = useTranslation('components');
  const { displayDateTime } = useDateTime();

  const createSlottingAbcAnalysisColumns = useCallback<TColumnFactory<TABCAnalysisDataType>>(
    (columnHelper) => {
      const columns = [
        ...(addColumnsToStart ? addColumnsToStart(columnHelper) : []),
        columnHelper.accessor('material', {
          header: t('slotting.material'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('material_description', {
          header: t('slotting.materialDescription'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('current_abc_indicator', {
          header: t('slotting.currentAbcIndicator'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('proposed_abc_indicator', {
          header: t('slotting.proposedAbcIndicator'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
      ];
      return columns.filter(filterDataTableColumnDefs<TABCAnalysisDataType>(removeColumns));
    },
    [t, displayDateTime, addColumnsToStart, ...removeColumns],
  );

  return createSlottingAbcAnalysisColumns;
};

export default useCreateSlottingAbcAnalysisColumns;
