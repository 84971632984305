import { gql } from '@apollo/client';

import { ListAllStockStatusTypesQuery } from '@/graphql/defs/list/__generated__/list-stock-status-types.generated';

gql`
  fragment ListedStockStatusType on ViewStockStatus {
    id
    code
    label
  }

  query ListAllStockStatusTypes {
    viewStockStatuses(
      paging: { offset: 0, limit: 1000000 }
      sorting: [{ field: label, direction: ASC }]
    ) {
      stockStatusTypes: nodes {
        ...ListedStockStatusType
      }
    }
  }
`;

export const ListAllStockStatusTypes_defaultData: ListAllStockStatusTypesQuery = {
  __typename: 'Query',
  viewStockStatuses: {
    __typename: 'ViewStockStatusOffsetConnection',
    stockStatusTypes: [],
  },
};
