import { Button, Link } from '@mui/material';
import React from 'react';

export const ErrorActionButton = ({ href, text }) => (
  <Link href={href} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
    <Button
      variant="contained"
      size="small"
      sx={{
        fontWeight: 600,
        color: 'red',
        backgroundColor: 'white',
        textDecoration: 'none',
        '&:hover': {
          backgroundColor: (theme) => theme.palette.offwhite.main,
        },
        '&:visited': {
          textDecoration: 'none',
        },
      }}
    >
      {text}
    </Button>
  </Link>
);
