import { Box, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useEditTaskUoMUpdateMutation } from '@/graphql/defs/components/modals/__generated__/edit-task-uom-modal.generated';
import {
  UoMConversionsFragment,
  useGetProductUoMConversionsQuery,
} from '@/graphql/defs/shared-queries/__generated__/get-product-uom-conversions.generated';
import { useViewUoMsQuery } from '@/graphql/defs/shared-queries/__generated__/view-uoms.generated';
import { ViewUoMs_defaultData } from '@/graphql/defs/shared-queries/view-uoms';
import {
  SortDirection,
  ViewUnitOfMeasureProductConversionSortFields,
} from '@/graphql/types.generated';
import UoMSelection from '@components/form-util/uom-selection';
import { IColumn, JustifiedColumns } from '@components/justified-columns';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { IEditTaskUnitOfMeasureModal } from '@models/modal';

const EditTaskUoMModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal, isPreparing, setPreparing, setLoading } =
    useModalContent<IEditTaskUnitOfMeasureModal>();

  const formMethods = useForm();
  const { control, handleSubmit } = formMethods;
  const updateUoMFieldName = 'updateUoMId';
  const updateUoMFieldValue = useWatch({
    control,
    name: updateUoMFieldName,
  });

  const { data: uomData } = useViewUoMsQuery({
    variables: {
      filter: {
        productId: { eq: modal.productId },
      },
      sorting: [
        { field: ViewUnitOfMeasureProductConversionSortFields.Code, direction: SortDirection.Asc },
      ],
    },
  });
  const {
    unitOfMeasures: { nodes: productUoMs },
  } = useMemo(() => uomData || ViewUoMs_defaultData, [uomData]);

  const currentTaskUoM = useMemo(() => {
    if (productUoMs) {
      return productUoMs.find((uom) => uom.id === modal.unitOfMeasureId);
    }
    return {};
  }, [productUoMs, modal.unitOfMeasureId]);

  const {
    called: calledUoMs,
    loading: isLoadingUoMs,
    data: { convertQuantityToAllUoM: { unitOfMeasureConversions: uomConversions } } = {
      convertQuantityToAllUoM: { unitOfMeasureConversions: [] as UoMConversionsFragment[] },
    },
  } = useGetProductUoMConversionsQuery({
    variables: {
      quantity: modal.quantity,
      unitOfMeasure: modal.unitOfMeasureId,
    },
  });

  const currentQtyColumns: IColumn[] = useMemo(
    () => [
      {
        labelWidth: '100px',
        lineItems: [
          { label: t('common.currentQty'), value: `${modal.quantity} ${currentTaskUoM?.code}` },
        ],
      },
    ],
    [modal.quantity, currentTaskUoM],
  );

  const updatedUoMConversion = useMemo(() => {
    return uomConversions?.find(
      (conversion) =>
        conversion.unitOfMeasure.id ===
        (updateUoMFieldValue ? updateUoMFieldValue : modal.unitOfMeasureId),
    );
  }, [updateUoMFieldValue, uomConversions]);
  const updatedQtyColumns: IColumn[] = useMemo(
    () => [
      {
        labelWidth: '100px',
        lineItems: [
          {
            label: t('common.updatedQty'),
            value: `${updatedUoMConversion?.quantity} ${updatedUoMConversion?.unitOfMeasure?.code}`,
          },
        ],
      },
    ],
    [updatedUoMConversion],
  );

  const [updateTask] = useEditTaskUoMUpdateMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t('modal.task.editUoM.success', {
          beforeCode: currentTaskUoM?.label ? currentTaskUoM.label : currentTaskUoM.code,
          afterCode: updatedUoMConversion?.unitOfMeasure?.label
            ? updatedUoMConversion.unitOfMeasure.label
            : updatedUoMConversion.unitOfMeasure.code,
        }),
      });

      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const onSubmit = () => {
    void updateTask({
      variables: {
        input: {
          id: modal.taskId,
          update: {
            quantity: updatedUoMConversion?.quantity,
            createdInUnitOfMeasureId: updatedUoMConversion?.unitOfMeasure?.id,
          },
        },
      },
    });
  };

  useEffect(() => {
    setPreparing(!calledUoMs || isLoadingUoMs);
  }, [calledUoMs, isLoadingUoMs]);

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <Typography mb={4}>{t('modal.task.editUoM.subtitle')}</Typography>
          <Box sx={{ width: '575px' }}>
            <JustifiedColumns columns={currentQtyColumns} sx={{ marginBottom: '15px' }} />
            <FormProvider {...formMethods}>
              <UoMSelection
                autoFocus={true}
                fieldName={updateUoMFieldName}
                fieldLabel={t('common.updateUoM')}
                defaultUoMId={modal.unitOfMeasureId}
                productId={modal.productId}
                sx={{
                  width: '200px',
                  display: 'block',
                }}
              />
            </FormProvider>
            {updateUoMFieldValue && updateUoMFieldValue !== modal.unitOfMeasureId && (
              <JustifiedColumns fluid columns={updatedQtyColumns} sx={{ marginTop: '15px' }} />
            )}
          </Box>
        </ModalContent>
        <ModalActions>
          <ModalButton
            onClick={() => closeModal()}
            variant="outlined"
            color="primary"
            actionType="cancel"
          >
            {t('common.cancel')}
          </ModalButton>
          <ModalButton
            data-testid="editTaskUoM_submit"
            variant="contained"
            color="primary"
            actionType="submit"
          >
            {t('common.updateTask')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};

export default EditTaskUoMModal;
