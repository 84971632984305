/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LoadFulfillmentItemFragment = { __typename?: 'ViewFulfillmentItem', id?: string | null, item?: string | null, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, productId?: string | null, productCode?: string | null, productDescription?: string | null, unitOfMeasure?: string | null };

export type GetLoadFulfillmentItemQueryVariables = Types.Exact<{
  fulfillmentItemId: Types.Scalars['ID']['input'];
}>;


export type GetLoadFulfillmentItemQuery = { __typename?: 'Query', viewFulfillmentItems: { __typename?: 'ViewFulfillmentItemOffsetConnection', nodes: Array<{ __typename?: 'ViewFulfillmentItem', id?: string | null, item?: string | null, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, productId?: string | null, productCode?: string | null, productDescription?: string | null, unitOfMeasure?: string | null }> } };

export type CompleteLoadTaskMutationVariables = Types.Exact<{
  warehouseId: Types.Scalars['ID']['input'];
  completeLoadTasks: Array<Types.LoadTaskCompleteInput> | Types.LoadTaskCompleteInput;
}>;


export type CompleteLoadTaskMutation = { __typename?: 'Mutation', completeManyLoadTasks: Array<{ __typename?: 'Task', id: string, code: string }> };

export const LoadFulfillmentItemFragmentDoc = gql`
    fragment LoadFulfillmentItem on ViewFulfillmentItem {
  id
  item
  licensePlateId
  licensePlateCode
  licensePlateDescription
  productId
  productCode
  productDescription
  unitOfMeasure
}
    `;
export const GetLoadFulfillmentItemDocument = gql`
    query GetLoadFulfillmentItem($fulfillmentItemId: ID!) {
  viewFulfillmentItems(filter: {id: {eq: $fulfillmentItemId}}) {
    nodes {
      ...LoadFulfillmentItem
    }
  }
}
    ${LoadFulfillmentItemFragmentDoc}`;

/**
 * __useGetLoadFulfillmentItemQuery__
 *
 * To run a query within a React component, call `useGetLoadFulfillmentItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoadFulfillmentItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoadFulfillmentItemQuery({
 *   variables: {
 *      fulfillmentItemId: // value for 'fulfillmentItemId'
 *   },
 * });
 */
export function useGetLoadFulfillmentItemQuery(baseOptions: Apollo.QueryHookOptions<GetLoadFulfillmentItemQuery, GetLoadFulfillmentItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoadFulfillmentItemQuery, GetLoadFulfillmentItemQueryVariables>(GetLoadFulfillmentItemDocument, options);
      }
export function useGetLoadFulfillmentItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoadFulfillmentItemQuery, GetLoadFulfillmentItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoadFulfillmentItemQuery, GetLoadFulfillmentItemQueryVariables>(GetLoadFulfillmentItemDocument, options);
        }
export type GetLoadFulfillmentItemQueryHookResult = ReturnType<typeof useGetLoadFulfillmentItemQuery>;
export type GetLoadFulfillmentItemLazyQueryHookResult = ReturnType<typeof useGetLoadFulfillmentItemLazyQuery>;
export type GetLoadFulfillmentItemQueryResult = Apollo.QueryResult<GetLoadFulfillmentItemQuery, GetLoadFulfillmentItemQueryVariables>;
export const CompleteLoadTaskDocument = gql`
    mutation CompleteLoadTask($warehouseId: ID!, $completeLoadTasks: [LoadTaskCompleteInput!]!) {
  completeManyLoadTasks(
    warehouseId: $warehouseId
    completeLoadTasks: $completeLoadTasks
  ) {
    id
    code
  }
}
    `;
export type CompleteLoadTaskMutationFn = Apollo.MutationFunction<CompleteLoadTaskMutation, CompleteLoadTaskMutationVariables>;

/**
 * __useCompleteLoadTaskMutation__
 *
 * To run a mutation, you first call `useCompleteLoadTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteLoadTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeLoadTaskMutation, { data, loading, error }] = useCompleteLoadTaskMutation({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      completeLoadTasks: // value for 'completeLoadTasks'
 *   },
 * });
 */
export function useCompleteLoadTaskMutation(baseOptions?: Apollo.MutationHookOptions<CompleteLoadTaskMutation, CompleteLoadTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteLoadTaskMutation, CompleteLoadTaskMutationVariables>(CompleteLoadTaskDocument, options);
      }
export type CompleteLoadTaskMutationHookResult = ReturnType<typeof useCompleteLoadTaskMutation>;
export type CompleteLoadTaskMutationResult = Apollo.MutationResult<CompleteLoadTaskMutation>;
export type CompleteLoadTaskMutationOptions = Apollo.BaseMutationOptions<CompleteLoadTaskMutation, CompleteLoadTaskMutationVariables>;