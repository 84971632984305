/**
 * Retrieve the keys of an ENUM object
 * @param obj
 * @param opts
 */
function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj) as K[];
}

export default enumKeys;
