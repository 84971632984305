import { gql } from '@apollo/client';

import { ListUserRolesQuery } from '@/graphql/defs/list/__generated__/list-user-roles.generated';

gql`
  query ListUserRoles {
    listRoles: roles {
      roles: nodes {
        createdAt
        default
        deletedAt
        id
        name
        updatedAt
      }
    }
  }
`;

export const ListUserRoles_defaultData: ListUserRolesQuery = {
  __typename: 'Query',
  listRoles: {
    __typename: 'RoleOffsetConnection',
    roles: [],
  },
};
