import { Box, FormControlLabel, Radio, RadioGroup, styled } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  Control,
  Controller,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DataTable from '@components/data-table';
import createEditRowColumnDef from '@components/data-table/columnDefs/editRow';
import { ColumnTypes } from '@components/data-table/controls/filter/filter-definitions';
import TableIds from '@components/data-table/model/TableIds';
import { ModalActions, ModalButton } from '@components/modal';
import { Section, StepWrapper } from '@components/modal/ruleset/steps';
import RestrictionsInput from '@components/modal/ruleset/steps/restrictions-input';
import WeightsInput from '@components/modal/ruleset/steps/weights-input';
import { FixedBins, IRuleset, IZone, WarehouseDefault } from '@hooks/form/ruleset/useRulesetForm';
import FormValues from '@models/FormValues';

const Step4 = ({
  control,
  active,
  review,
  getValues,
  setValue,
  clearErrors = () => {},
  activeZone = null,
  setActiveZone = () => {},
}: {
  active: boolean;
  review: boolean;
  control: Control<FormValues<IRuleset>, any>;
  getValues: UseFormGetValues<FormValues<IRuleset>>;
  setValue: UseFormSetValue<FormValues<IRuleset>>;
  clearErrors?: UseFormClearErrors<FormValues<IRuleset>>;
  activeZone?: string;
  setActiveZone?: Dispatch<SetStateAction<string>>;
}) => {
  const zones = useWatch({ control, name: 'zones' });
  return (
    <>
      {activeZone ? null : (
        <ViewZones
          review={review}
          zones={zones !== '' ? zones : []}
          setActiveZone={setActiveZone}
          active={active && !activeZone}
        />
      )}
      {zones !== '' &&
        zones.map(({ zoneId, code }, index) => (
          <ZoneInput
            key={`${index}:${code}`}
            index={index}
            active={active && activeZone === zoneId}
            control={control}
            clearErrors={clearErrors}
            getValues={getValues}
            setValue={setValue}
            setActiveZone={setActiveZone}
          />
        ))}
    </>
  );
};

const ZoneInput = ({ control, active, clearErrors, index, getValues, setActiveZone, setValue }) => {
  const [initialValues, setInitialValues] = useState<IZone>();
  useEffect(() => {
    if (active) {
      const zones = getValues('zones');
      setInitialValues(JSON.parse(JSON.stringify(zones[index])));
    } else if (initialValues) {
      setInitialValues(null);
    }
  }, [active]);
  const { t } = useTranslation('components');
  const useDefault =
    useWatch({ control, name: `zones[${index}].useWarehouseDefaultEnum` }) ===
    WarehouseDefault.warehouse;
  const prefix = `zones[${index}]`;
  const onApply = () => {
    setActiveZone(null);
  };
  const onCancel = () => {
    setValue(`zones.${index}`, initialValues);
    setActiveZone(null);
  };
  return (
    <>
      {active ? (
        <Box sx={{ fontSize: '24px', marginBottom: '10px', fontWeight: 700 }}>
          {t('modal.ruleset.addZoneSpecificRestrictions')} -{' '}
          <DetailHighlight>
            {`${t('common.zone')} ${initialValues?.code} ${initialValues?.description}`}
          </DetailHighlight>
        </Box>
      ) : null}
      <StepWrapper active={active} review={false}>
        <Section section="zoneRulesAndWeights" noSubtitle>
          <Controller
            name={`${prefix}.useWarehouseDefaultEnum`}
            control={control}
            render={({ field }) => {
              return (
                <RadioGroup
                  {...field}
                  name={`${prefix}.useWarehouseDefaultEnum`}
                  onChange={({ target: { value } }) => {
                    if (value === WarehouseDefault.warehouse) {
                      clearErrors([
                        `${prefix}.weights`,
                        `${prefix}.restrictions`,
                        `${prefix}.fixedBins`,
                      ]);
                    }
                    field.onChange(value);
                  }}
                >
                  <FormControlLabel
                    value={WarehouseDefault.warehouse}
                    control={<Radio />}
                    label={t(`modal.ruleset.defaultToWarehouseRestrictionsAndWeights`)}
                  />
                  <FormControlLabel
                    value={WarehouseDefault.custom}
                    control={<Radio />}
                    label={t(`modal.ruleset.customRestrictionsAndWeights`)}
                  />
                </RadioGroup>
              );
            }}
          />
        </Section>
        <Section section="fixedBins" noSubtitle>
          <Controller
            name={`${prefix}.fixedBinsEnum`}
            control={control}
            render={({ field }) => {
              return (
                <RadioGroup {...field} name={`${prefix}.fixedBinsEnum`}>
                  <FormControlLabel
                    value={FixedBins.no}
                    control={<Radio />}
                    label={t(`modal.ruleset.thisZoneDoesNotHaveFixedBins`)}
                  />
                  <FormControlLabel
                    value={FixedBins.yes}
                    control={<Radio />}
                    label={t(`modal.ruleset.thisZoneHasFixedBins`)}
                  />
                </RadioGroup>
              );
            }}
          />
        </Section>
        <Box sx={{ height: 20 }} />
        <Section section="restrictions" noSubtitle>
          <RestrictionsInput
            control={control}
            disabled={useDefault}
            prefix={`zones[${index}].restrictions`}
          />
        </Section>
        <Box sx={{ height: 20 }} />
        <Section section="weights" noSubtitle>
          <WeightsInput
            control={control}
            disabled={useDefault}
            prefix={`zones[${index}].weights`}
          />
        </Section>
        {active ? (
          <ModalActions>
            <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
              {t('common.cancel')}
            </ModalButton>
            <ModalButton
              variant="contained"
              color="primary"
              data-testid="create-ruleset-next"
              onClick={onApply}
            >
              {t('common.apply')}
            </ModalButton>
          </ModalActions>
        ) : null}
      </StepWrapper>
    </>
  );
};

const ViewZones = ({ zones, setActiveZone, active, review }) => {
  const { t } = useTranslation('components');
  const columnHelper = createColumnHelper<IZone>();
  const columns = useMemo(
    () => [
      ...(review
        ? []
        : [
            createEditRowColumnDef(
              columnHelper,
              (zone) => {
                setActiveZone(zone.zoneId);
              },
              t('common.actions'),
              70,
            ),
          ]),
      columnHelper.accessor('code', {
        header: t('common.zone'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
          enableExport: false,
        },
      }),
      columnHelper.accessor('description', {
        header: t('common.description'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
          enableExport: false,
        },
      }),
      columnHelper.accessor('useWarehouseDefaultEnum', {
        header: t('common.restrictions'),
        cell: ({ getValue }) => {
          return t(`common.${getValue()}`);
        },
        meta: {
          columnType: ColumnTypes.enum,
          options: Object.values(WarehouseDefault).map((value) => ({
            value,
            display: t(`common.${value}`),
          })),
          enableExport: false,
        },
      }),
      columnHelper.accessor('fixedBinsEnum', {
        header: t('modal.ruleset.fixedBins'),
        cell: ({ getValue }) => t(`common.${getValue()}`),
        meta: {
          columnType: ColumnTypes.enum,
          options: Object.values(FixedBins).map((value) => ({
            value,
            display: t(`common.${value}`),
          })),
          enableExport: false,
        },
        enableSorting: true,
        size: 90,
      }),
    ],
    [review],
  );

  return (
    <StepWrapper active={active} review={review} sx={active ? { marginLeft: 0 } : {}}>
      <Section section="zoneSpecificRestrictions" sx={active ? { marginLeft: 0 } : {}}>
        <Box sx={{ border: '1px solid #D8E0E5', borderRadius: '4px' }}>
          <DataTable
            type="data"
            tableId={TableIds.RulesetZones}
            columns={columns}
            data={zones}
            isDataLoading={false}
            disableExport={true}
          />
        </Box>
      </Section>
    </StepWrapper>
  );
};

export default Step4;

const DetailHighlight = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  display: 'inline',
}));
