import { Box, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateRoleMutation } from '@/graphql/defs/components/modals/__generated__/create-role-modal.generated';
import { RoleCreateDto } from '@/graphql/types.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import FormValues from '@models/FormValues';
import { IRoleCreateModal } from '@models/modal';

const CreateRoleModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { closeModal, setLoading } = useModalContent<IRoleCreateModal>();

  const formReturn = useForm<FormValues<RoleCreateDto>>({
    defaultValues: {
      name: '',
    },
  });
  const { control, handleSubmit } = formReturn;

  const [createRole] = useCreateRoleMutation({
    onCompleted: async ({ createOneRole: { name } }) => {
      showMessage({
        type: 'success',
        message: t('roleActions.createSuccess', { name }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = async (fields: RoleCreateDto) => {
    void createRole({
      variables: {
        createRoleInput: {
          role: {
            name: fields.name,
          },
        },
      },
    });
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formReturn}>
      <ModalContent>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Box
              sx={{
                color: (theme) => theme.palette.text.primary,
                fontSize: '16px',
                fontWeight: 400,
              }}
            >
              {t('roleActions.provideName')}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: t('form.validation.requiredField', { field: t('common.name') }),
              }}
              defaultValue=""
              render={({ field, fieldState }) => (
                <TextField
                  autoFocus
                  fullWidth
                  required
                  id="role-name"
                  label={t('common.name')}
                  {...field}
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  onChange={(e) => field.onChange(e)}
                  autoComplete="off"
                  dataTestId="role-name"
                />
              )}
            />
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
          {t('common.cancel')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" actionType="submit">
          {t('common.submit')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default CreateRoleModal;
