import {
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
} from '@mui/icons-material';
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ReactNode, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function findIndex<ItemType>(
  transferItem: ITransferItem<ItemType>,
  transferItems: ITransferItem<ItemType>[],
) {
  return transferItems.findIndex((t) => t.id === transferItem.id);
}

function not<ItemType>(a: ITransferItem<ItemType>[], b: ITransferItem<ItemType>[]) {
  return a.filter((item) => findIndex(item, b) === -1);
}

function intersection<ItemType>(a: ITransferItem<ItemType>[], b: ITransferItem<ItemType>[]) {
  return a.filter((item) => findIndex(item, b) !== -1);
}

function union<ItemType>(a: ITransferItem<ItemType>[], b: ITransferItem<ItemType>[]) {
  return [...a, ...not(b, a)];
}

function sortByIndex(a, b) {
  if (a.index < b.index) {
    return -1;
  }
  if (a.index > b.index) {
    return 1;
  }
  return 0;
}

export interface ITransferItem<ItemType> {
  id: string;
  display: string;
  item?: ItemType;
  disableLeftRight?: boolean;
}
export type TTransferListUpdate<ItemType> = (update: {
  active: ITransferItem<ItemType>[];
  inactive: ITransferItem<ItemType>[];
}) => void;

interface ITransferList<ItemType> {
  dataTestId: string;
  activeHeader: string;
  inactiveHeader: string;
  activeList: ITransferItem<ItemType>[];
  inactiveList: ITransferItem<ItemType>[];
  handleTransferListUpdate: TTransferListUpdate<ItemType>;
}
function TransferList<ItemType = unknown>({
  dataTestId,
  activeHeader,
  inactiveHeader,
  activeList,
  inactiveList,
  handleTransferListUpdate,
}: ITransferList<ItemType>) {
  const { t } = useTranslation('components');

  const [mounted, setMounted] = useState(false);

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState(inactiveList);
  const [right, setRight] = useState(activeList);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) handleTransferListUpdate({ active: right, inactive: left });
  }, [left, right]);

  const handleToggle = (item: ITransferItem<ItemType>) => () => {
    const currentIndex = findIndex(item, checked);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const isUpDownDisabled = useMemo(
    () =>
      (leftChecked.length === 0 && rightChecked.length === 0) ||
      (leftChecked.length > 0 && rightChecked.length > 0),
    [leftChecked, rightChecked],
  );

  const isLeftDisabled = useMemo(
    () =>
      rightChecked.length === 0 ||
      (leftChecked.length > 0 && rightChecked.length > 0) ||
      rightChecked.some((item) => item.disableLeftRight),
    [leftChecked, rightChecked],
  );

  const isRightDisabled = useMemo(
    () =>
      leftChecked.length === 0 ||
      (leftChecked.length > 0 && rightChecked.length > 0) ||
      leftChecked.some((item) => item.disableLeftRight),
    [leftChecked, rightChecked],
  );

  const numberOfChecked = (items: ITransferItem<ItemType>[]) => intersection(checked, items).length;

  const handleToggleAll = (items: ITransferItem<ItemType>[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedUp = () => {
    const selection = leftChecked.length > 0 ? leftChecked : rightChecked;
    const selectionSource = leftChecked.length > 0 ? left : right;
    const selectionSortedByIndex = selection
      .map((item) => ({
        index: findIndex(item, selectionSource),
        ...item,
      }))
      .sort(sortByIndex);

    const updatedSource = [...selectionSource];
    for (let i = 0; i < selectionSortedByIndex.length; i++) {
      const item = selectionSortedByIndex[i];
      const prevItem = i > 0 && {
        ...selectionSortedByIndex[i - 1],
        index: findIndex(selectionSortedByIndex[i - 1], updatedSource), // Update the index to account for prevItem moving last loop.
      };

      // If the item is the first in the list, don't do anything.
      // If the item is behind the prevItem, do nothing - as this means the prevItem couldn't move as well.
      if (item.index !== 0 && (!prevItem || prevItem?.index !== item.index - 1)) {
        updatedSource.splice(item.index, 1);
        updatedSource.splice(item.index - 1, 0, {
          id: item.id,
          display: item.display,
          item: item.item,
        });
      }
    }

    leftChecked.length > 0 ? setLeft(updatedSource) : setRight(updatedSource);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleCheckedDown = () => {
    const selection = leftChecked.length > 0 ? leftChecked : rightChecked;
    const selectionSource = leftChecked.length > 0 ? left : right;
    const selectionSortedByIndex = selection
      .map((item) => ({
        index: findIndex(item, selectionSource),
        ...item,
      }))
      .sort(sortByIndex);
    const selectionSortedReversed = [...selectionSortedByIndex].reverse();

    const updatedSource = [...selectionSource];
    for (let i = 0; i < selectionSortedByIndex.length; i++) {
      const lastSourceIndex = updatedSource.length - 1;

      // We use the reversed sort here to tackle the "lowest" items first, so we don't have items jumping lower selected items that haven't moved yet
      const item = selectionSortedReversed[i];
      const prevItem = i > 0 && {
        ...selectionSortedReversed[i - 1],
        index: findIndex(selectionSortedReversed[i - 1], updatedSource), // Update the index to account for prevItem moving last loop.
      };

      // If the item is the last in the list, don't do anything.
      // If the item is behind the prevItem, do nothing - as this means the prevItem couldn't move as well.
      if (item.index !== lastSourceIndex && (!prevItem || prevItem?.index !== item.index + 1)) {
        updatedSource.splice(item.index, 1);
        updatedSource.splice(item.index + 1, 0, {
          id: item.id,
          display: item.display,
          item: item.item,
        });
      }
    }

    leftChecked.length > 0 ? setLeft(updatedSource) : setRight(updatedSource);
  };

  // listCard is a function that returns a ReactNode, rather than a full FC/Functional Component
  // due to re-render issues causing scrolling position to jump to the top when a checkbox is toggled
  const listCard = (title: ReactNode, items: ITransferItem<ItemType>[]) => (
    <Card data-testid={`${dataTestId}-list-card`}>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
            data-testid={`${dataTestId}-list-card-select-all`}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} ${t('common.selected')}`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
        data-testid={`${dataTestId}-list-card-list`}
      >
        {items.map((item: ITransferItem<ItemType>) => {
          const labelId = `transfer-list-all-item-${item.id}-label`;

          return (
            <ListItem
              key={item.id}
              role="listitem"
              button
              onClick={handleToggle(item)}
              data-testid={`${dataTestId}-list-card-item`}
              data-testkey="itemId"
              data-testvalue={item.id}
            >
              <ListItemIcon>
                <Checkbox
                  checked={findIndex(item, checked) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                  data-testid={`${dataTestId}-list-card-select-item`}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={item.display}
                data-testid={`${dataTestId}-list-card-item-label`}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item data-testid={`${dataTestId}-inactiveList`}>
        {listCard(inactiveHeader, left)}
      </Grid>
      <Grid item>
        <Grid
          container
          direction="column"
          alignItems="center"
          data-testid={`${dataTestId}-transfer-list-controls`}
        >
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedUp}
            disabled={isUpDownDisabled}
            aria-label="move selected up"
            data-testid={`${dataTestId}-move-selection-up`}
          >
            <KeyboardArrowUp />
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={isRightDisabled}
            aria-label="move selected right"
            data-testid={`${dataTestId}-move-selection-right`}
          >
            <KeyboardArrowRight />
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={isLeftDisabled}
            aria-label="move selected left"
            data-testid={`${dataTestId}-move-selection-left`}
          >
            <KeyboardArrowLeft />
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedDown}
            disabled={isUpDownDisabled}
            aria-label="move selected down"
            data-testid={`${dataTestId}-move-selection-down`}
          >
            <KeyboardArrowDown />
          </Button>
        </Grid>
      </Grid>
      <Grid item data-testid={`${dataTestId}-activeList`}>
        {listCard(activeHeader, right)}
      </Grid>
    </Grid>
  );
}

export default TransferList;
