import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box } from '@mui/system';

export default function PanZoom({ setOffset, zoomMap }) {
  return (
    <Box
      sx={{ position: 'absolute', bottom: 10, right: 10 }}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '20px',
          justifyContent: 'space-between',
          width: 'max-content',
          marginLeft: 'auto',
          backgroundColor: '#fffffe',
          borderRadius: '4px',
          overflow: 'hidden',
          boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        }}
      >
        <AddIcon fontSize="large" onClick={() => zoomMap(-200, true)} />
        <RemoveIcon fontSize="large" onClick={() => zoomMap(200, true)} />
      </Box>
      <Box
        sx={{
          backgroundColor: '#fffffe',
          borderRadius: '4px',
          overflow: 'hidden',
          boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        }}
      >
        <Box sx={{ width: 72, height: 24, display: 'flex', justifyContent: 'center' }}>
          <ArrowDropUpIcon
            onClick={() => setOffset((prevOffset) => ({ x: prevOffset.x, y: prevOffset.y + 50 }))}
          />
        </Box>
        <Box sx={{ width: 72, height: 24, display: 'flex', justifyContent: 'space-between' }}>
          <ArrowLeftIcon
            onClick={() => setOffset((prevOffset) => ({ x: prevOffset.x + 50, y: prevOffset.y }))}
          />
          <ArrowRightIcon
            onClick={() => setOffset((prevOffset) => ({ x: prevOffset.x - 50, y: prevOffset.y }))}
          />
        </Box>
        <Box sx={{ width: 72, height: 24, display: 'flex', justifyContent: 'center' }}>
          <ArrowDropDownIcon
            onClick={() => setOffset((prevOffset) => ({ x: prevOffset.x, y: prevOffset.y - 50 }))}
          />
        </Box>
      </Box>
    </Box>
  );
}
