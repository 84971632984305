const LoadIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.5455 10.1688V8.07792V4H14.1818C12.9818 4 12 4.98182 12 6.18182V17.8961H14.1818C14.1818 19.707 15.6436 21.1688 17.4545 21.1688C19.2655 21.1688 20.7273 19.707 20.7273 17.8961H21.2727H22H22.5455V12.1688V10.1688ZM17.4545 18.987C16.8545 18.987 16.3636 18.4961 16.3636 17.8961C16.3636 17.2961 16.8545 16.8052 17.4545 16.8052C18.0545 16.8052 18.5455 17.2961 18.5455 17.8961C18.5455 18.4961 18.0545 18.987 17.4545 18.987Z"
        fill="currentColor"
      />
      <path
        d="M4.49989 19.4986C4.49989 19.4986 5.34231 20.5261 5.99977 20.4986C6.65724 20.4712 15.5016 17.9499 15.5016 17.9499L15.4995 16.5031C15.4995 16.5031 3.93814 19.5329 4.49989 19.4986Z"
        fill="currentColor"
      />
      <path
        d="M5.04148 13.9595L5.04148 17.2158L10.4301 12.1079L5.04148 6.99999L5.04148 10.2563L1.00005 10.2563L1.00005 13.9595L5.04148 13.9595Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LoadIcon;
