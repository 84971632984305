/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ZoneBinsAddTableFragment = { __typename?: 'ViewZoneBinUnmapped', warehouseId?: string | null, binId?: string | null, binCode?: string | null, binType?: Types.BinType | null, destinationBinBlock?: Types.BinBlockState | null, inactive?: Types.BinActiveState | null, level?: number | null, sourceBinBlock?: Types.BinBlockState | null, x?: number | null, y?: number | null, z?: number | null };

export type ZoneBinsAddTableQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.ViewZoneBinUnmappedFilter>;
  sorting?: Types.InputMaybe<Array<Types.ViewZoneBinUnmappedSort> | Types.ViewZoneBinUnmappedSort>;
  includePageInfo?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeNodes?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeTotalCount?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ZoneBinsAddTableQuery = { __typename?: 'Query', query: { __typename?: 'ViewZoneBinUnmappedOffsetConnection', totalCount?: number, pageInfo?: { __typename?: 'OffsetPageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null }, nodes?: Array<{ __typename?: 'ViewZoneBinUnmapped', warehouseId?: string | null, binId?: string | null, binCode?: string | null, binType?: Types.BinType | null, destinationBinBlock?: Types.BinBlockState | null, inactive?: Types.BinActiveState | null, level?: number | null, sourceBinBlock?: Types.BinBlockState | null, x?: number | null, y?: number | null, z?: number | null }> } };

export const ZoneBinsAddTableFragmentDoc = gql`
    fragment ZoneBinsAddTable on ViewZoneBinUnmapped {
  warehouseId
  binId
  binCode
  binType
  destinationBinBlock
  inactive
  level
  sourceBinBlock
  x
  y
  z
}
    `;
export const ZoneBinsAddTableDocument = gql`
    query ZoneBinsAddTable($paging: OffsetPaging, $filter: ViewZoneBinUnmappedFilter, $sorting: [ViewZoneBinUnmappedSort!], $includePageInfo: Boolean = true, $includeNodes: Boolean = true, $includeTotalCount: Boolean = false) {
  query: viewZoneBinsUnmapped(sorting: $sorting, paging: $paging, filter: $filter) {
    pageInfo @include(if: $includePageInfo) {
      hasNextPage
      hasPreviousPage
    }
    nodes @include(if: $includeNodes) {
      ...ZoneBinsAddTable
    }
    totalCount @include(if: $includeTotalCount)
  }
}
    ${ZoneBinsAddTableFragmentDoc}`;

/**
 * __useZoneBinsAddTableQuery__
 *
 * To run a query within a React component, call `useZoneBinsAddTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoneBinsAddTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoneBinsAddTableQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      includePageInfo: // value for 'includePageInfo'
 *      includeNodes: // value for 'includeNodes'
 *      includeTotalCount: // value for 'includeTotalCount'
 *   },
 * });
 */
export function useZoneBinsAddTableQuery(baseOptions?: Apollo.QueryHookOptions<ZoneBinsAddTableQuery, ZoneBinsAddTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ZoneBinsAddTableQuery, ZoneBinsAddTableQueryVariables>(ZoneBinsAddTableDocument, options);
      }
export function useZoneBinsAddTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZoneBinsAddTableQuery, ZoneBinsAddTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ZoneBinsAddTableQuery, ZoneBinsAddTableQueryVariables>(ZoneBinsAddTableDocument, options);
        }
export type ZoneBinsAddTableQueryHookResult = ReturnType<typeof useZoneBinsAddTableQuery>;
export type ZoneBinsAddTableLazyQueryHookResult = ReturnType<typeof useZoneBinsAddTableLazyQuery>;
export type ZoneBinsAddTableQueryResult = Apollo.QueryResult<ZoneBinsAddTableQuery, ZoneBinsAddTableQueryVariables>;