import { Box } from '@mui/material';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import DataTable from '@components/data-table';
import { ColumnTypes } from '@components/data-table/controls/filter/filter-definitions';
import TableIds from '@components/data-table/model/TableIds';
import { Section, StepWrapper } from '@components/modal/ruleset/steps';
import { QuantityInput } from '@components/modal/ruleset/steps/quantity-input';
import { IWorkers } from '@hooks/form/ruleset/useRulesetForm';

const Step7 = ({ active, control, review }) => {
  const { t } = useTranslation('components');
  const columnHelper = createColumnHelper<IWorkers>();
  const workers = useWatch({ control, name: 'workers' });
  const columns = useMemo(
    () => [
      columnHelper.accessor('quantity', {
        header: t('common.quantity'),
        cell: ({
          row: {
            original: { quantity, index },
          },
        }) => {
          return review ? (
            quantity
          ) : (
            <QuantityInput
              control={control}
              name={`workers[${index}].quantity`}
              quantity={quantity}
            />
          );
        },
        meta: {
          columnType: ColumnTypes.string,
          enableExport: false,
        },
        size: 70,
      }),
      columnHelper.accessor('code', {
        header: t('common.code'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
          enableExport: false,
        },
      }),
      columnHelper.accessor('label', {
        header: t('common.label'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
          enableExport: false,
        },
      }),
      columnHelper.accessor('description', {
        header: t('common.description'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.string,
          enableExport: false,
        },
      }),
      columnHelper.accessor('cost', {
        header: t('common.cost'),
        cell: ({ getValue }) => getValue(),
        meta: {
          columnType: ColumnTypes.number,
          enableExport: false,
        },
        size: 70,
      }),
    ],
    [review],
  );
  return (
    <StepWrapper active={active} review={review} sx={active ? { marginLeft: 0 } : {}}>
      <Section section="workers" sx={active ? { marginLeft: 0 } : {}}>
        <Box sx={{ border: '1px solid #D8E0E5', borderRadius: '4px' }}>
          <DataTable
            type="data"
            tableId={TableIds.RulesetWorkers}
            columns={columns}
            data={workers}
            isDataLoading={false}
            disableExport={true}
          />
        </Box>
      </Section>
    </StepWrapper>
  );
};

export default Step7;
