/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FulfillmentItemToLoadFragment = { __typename?: 'FulfillmentItemDetails', id: string, fulfillmentItem: string, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, productId: string, productCode: string, productDescription?: string | null };

export type GetFulfillmentItemsForLoadQueryVariables = Types.Exact<{
  fulfillmentId: Types.Scalars['ID']['input'];
}>;


export type GetFulfillmentItemsForLoadQuery = { __typename?: 'Query', fulfillmentItemsToLoad: Array<{ __typename?: 'FulfillmentItemDetails', id: string, fulfillmentItem: string, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, productId: string, productCode: string, productDescription?: string | null }> };

export type CreateLoadTasksMutationVariables = Types.Exact<{
  fulfillmentId: Types.Scalars['ID']['input'];
}>;


export type CreateLoadTasksMutation = { __typename?: 'Mutation', createLoadTasksForFulfillment: Array<{ __typename?: 'Task', id: string, code: string }> };

export const FulfillmentItemToLoadFragmentDoc = gql`
    fragment FulfillmentItemToLoad on FulfillmentItemDetails {
  id
  fulfillmentItem
  licensePlateId
  licensePlateCode
  licensePlateDescription
  productId
  productCode
  productDescription
}
    `;
export const GetFulfillmentItemsForLoadDocument = gql`
    query GetFulfillmentItemsForLoad($fulfillmentId: ID!) {
  fulfillmentItemsToLoad(id: $fulfillmentId) {
    ...FulfillmentItemToLoad
  }
}
    ${FulfillmentItemToLoadFragmentDoc}`;

/**
 * __useGetFulfillmentItemsForLoadQuery__
 *
 * To run a query within a React component, call `useGetFulfillmentItemsForLoadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFulfillmentItemsForLoadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFulfillmentItemsForLoadQuery({
 *   variables: {
 *      fulfillmentId: // value for 'fulfillmentId'
 *   },
 * });
 */
export function useGetFulfillmentItemsForLoadQuery(baseOptions: Apollo.QueryHookOptions<GetFulfillmentItemsForLoadQuery, GetFulfillmentItemsForLoadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFulfillmentItemsForLoadQuery, GetFulfillmentItemsForLoadQueryVariables>(GetFulfillmentItemsForLoadDocument, options);
      }
export function useGetFulfillmentItemsForLoadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFulfillmentItemsForLoadQuery, GetFulfillmentItemsForLoadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFulfillmentItemsForLoadQuery, GetFulfillmentItemsForLoadQueryVariables>(GetFulfillmentItemsForLoadDocument, options);
        }
export type GetFulfillmentItemsForLoadQueryHookResult = ReturnType<typeof useGetFulfillmentItemsForLoadQuery>;
export type GetFulfillmentItemsForLoadLazyQueryHookResult = ReturnType<typeof useGetFulfillmentItemsForLoadLazyQuery>;
export type GetFulfillmentItemsForLoadQueryResult = Apollo.QueryResult<GetFulfillmentItemsForLoadQuery, GetFulfillmentItemsForLoadQueryVariables>;
export const CreateLoadTasksDocument = gql`
    mutation CreateLoadTasks($fulfillmentId: ID!) {
  createLoadTasksForFulfillment(fulfillmentId: $fulfillmentId) {
    id
    code
  }
}
    `;
export type CreateLoadTasksMutationFn = Apollo.MutationFunction<CreateLoadTasksMutation, CreateLoadTasksMutationVariables>;

/**
 * __useCreateLoadTasksMutation__
 *
 * To run a mutation, you first call `useCreateLoadTasksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLoadTasksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLoadTasksMutation, { data, loading, error }] = useCreateLoadTasksMutation({
 *   variables: {
 *      fulfillmentId: // value for 'fulfillmentId'
 *   },
 * });
 */
export function useCreateLoadTasksMutation(baseOptions?: Apollo.MutationHookOptions<CreateLoadTasksMutation, CreateLoadTasksMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLoadTasksMutation, CreateLoadTasksMutationVariables>(CreateLoadTasksDocument, options);
      }
export type CreateLoadTasksMutationHookResult = ReturnType<typeof useCreateLoadTasksMutation>;
export type CreateLoadTasksMutationResult = Apollo.MutationResult<CreateLoadTasksMutation>;
export type CreateLoadTasksMutationOptions = Apollo.BaseMutationOptions<CreateLoadTasksMutation, CreateLoadTasksMutationVariables>;