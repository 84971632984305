import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useCancelDeliveryMutation } from '@/graphql/defs/components/modals/__generated__/cancel-delivery-modal.generated';
import { ModalActions, ModalContent } from '@components/modal';
import { ModalButton } from '@components/modal/modal-button';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { ICancelDeliveryModal } from '@models/modal';

export const CancelDeliveryModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, setLoading } = useModalContent<ICancelDeliveryModal>();

  const { showMessage } = useSnackbar();

  const [cancelDelivery] = useCancelDeliveryMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t('modal.delivery.cancelInboundSuccess', { code: modal.code }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      showMessage({ type: 'error', message: error.message });
      setLoading(false);
    },
  });

  const handleSubmit = async () => {
    cancelDelivery({ variables: { deliveryId: modal.id } });
  };

  return (
    <>
      <ModalContent>
        <Box
          sx={{ width: 650, fontSize: 18, paddingBottom: 40, whiteSpace: 'pre-line' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {t('modal.delivery.cancelInboundDeliveryNotice', { code: modal.code })}
        </Box>
      </ModalContent>
      <ModalActions>
        <ModalButton
          variant="outlined"
          color="primary"
          onClick={() => closeModal()}
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" onClick={handleSubmit} actionType="submit">
          {t('common.confirm')}
        </ModalButton>
      </ModalActions>
    </>
  );
};
