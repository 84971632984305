import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import {
  Box,
  Paper,
  Button,
  Typography,
  Collapse as MuiCollapse,
  SxProps,
  Theme,
  styled,
  Fade,
} from '@mui/material';
import { useState, isValidElement, ReactElement } from 'react';

import CollapseLoading from '@components/loading-indicator/CollapseLoading';

// TODO: Update props to have opts and move option props inside of that
export interface ICollapse {
  isLoading?: boolean;
  defaultCollapsed?: boolean;
  icon?: ReactElement;
  hideIconCollapsed?: boolean;
  title?: string | ReactElement;
  hideTitleExpanded?: boolean;
  largeTitle?: boolean;
  action?: ReactElement;
  onOpen?: () => void;
  onClose?: () => void;
  wrapperSx?: SxProps<Theme>;
  headerSx?: SxProps<Theme>;
  titleSx?: SxProps<Theme>;
  bodySx?: SxProps<Theme>;
  children: ReactElement | ReactElement[];
}

const Collapse = ({
  isLoading = false,
  defaultCollapsed = false,
  icon = null,
  hideIconCollapsed = false,
  title = null,
  hideTitleExpanded = false,
  largeTitle = true,
  action = null,
  onOpen,
  onClose,
  wrapperSx = {},
  headerSx = {},
  titleSx = {},
  bodySx = {},
  children,
}: ICollapse) => {
  const [collapsed, setCollapsed] = useState<boolean>(defaultCollapsed);

  return isLoading ? (
    <CollapseLoading />
  ) : (
    <CollapseWrapper sx={wrapperSx}>
      <CollapseHeaderWrapper>
        <CollapseHeader collapsed={collapsed} sx={headerSx}>
          {!hideIconCollapsed || (hideIconCollapsed && !collapsed) ? icon : null}
          <Box flexGrow="1" display="flex" alignItems="center" justifyContent="space-between">
            {isValidElement(title) ? (
              title
            ) : (
              <CollapseTitle
                variant={largeTitle ? 'h3' : 'h4'}
                hideTitleExpanded={hideTitleExpanded}
                collapsed={collapsed}
                sx={titleSx}
              >
                {title}
              </CollapseTitle>
            )}
            <CollapseActions>
              {action}
              <Button
                onClick={() =>
                  setCollapsed((currentlyCollapsed) => {
                    if (currentlyCollapsed && onOpen) onOpen();
                    if (!currentlyCollapsed && onClose) onClose();
                    return !currentlyCollapsed;
                  })
                }
                variant="text"
                sx={{
                  minWidth: '0',
                  '& .ff-MuiSvgIcon-root': {
                    color: (theme) => theme.palette.secondary.main,
                  },
                }}
              >
                {collapsed ? <AddCircleOutlineRoundedIcon /> : <RemoveCircleOutlineRoundedIcon />}
              </Button>
            </CollapseActions>
          </Box>
        </CollapseHeader>
      </CollapseHeaderWrapper>
      <MuiCollapse in={!collapsed} mountOnEnter unmountOnExit>
        <Fade in={!collapsed}>
          <CollapseBody sx={bodySx}>{children}</CollapseBody>
        </Fade>
      </MuiCollapse>
    </CollapseWrapper>
  );
};

export default Collapse;

const CollapseWrapper = styled(Paper)(() => ({
  overflow: 'hidden',
  width: '100%',
}));

const CollapseHeaderWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 4),
}));

const CollapseHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'collapsed',
})<{ collapsed: boolean }>(({ theme, collapsed }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(4, 0),
  borderBottom: collapsed
    ? `0px solid ${theme.palette.gainsboro.main}`
    : `1px solid  ${theme.palette.gainsboro.main}`,
  transition: 'border-width 0.2s linear',
}));

const CollapseTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'hideTitleExpanded' && prop !== 'collapsed',
})<{ hideTitleExpanded: boolean; collapsed: boolean }>(
  ({ theme, hideTitleExpanded, collapsed }) => ({
    color: hideTitleExpanded && !collapsed ? 'transparent' : theme.palette.primary.main,
    userSelect: hideTitleExpanded && !collapsed ? 'none' : 'auto',
    cursor: hideTitleExpanded && !collapsed ? 'default' : 'auto',
  }),
);

const CollapseActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-end',
  gap: theme.spacing(2),
}));

const CollapseBody = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
}));
