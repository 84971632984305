import { Box, Typography, useTheme } from '@mui/material';

import FulfilldLogo from '@components/icons/fulfilld-logo';

interface IEmptySection {
  title: string;
  message: string;
}
const EmptySection = ({ title, message }: IEmptySection) => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" gap={2} sx={{ textAlign: 'center' }}>
      <Box
        sx={{
          display: 'inline-block',
          padding: theme.spacing(2),
          backgroundColor: theme.palette.gainsboro.main,
          borderRadius: '50%',
          margin: '0 auto',
        }}
      >
        <FulfilldLogo fillColor={theme.palette.white.main} />
      </Box>
      <Typography variant="h3">{title}</Typography>
      <Typography variant="body2">{message}</Typography>
    </Box>
  );
};

export default EmptySection;
