/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUomGlossaryMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  unitOfMeasure: Types.UnitOfMeasureGlossaryUpdateInput;
}>;


export type UpdateUomGlossaryMutation = { __typename?: 'Mutation', updateOneUnitOfMeasureGlossary: { __typename?: 'UnitOfMeasureGlossary', id: string, code: string, label?: string | null, description?: string | null } };


export const UpdateUomGlossaryDocument = gql`
    mutation UpdateUomGlossary($id: ID!, $unitOfMeasure: UnitOfMeasureGlossaryUpdateInput!) {
  updateOneUnitOfMeasureGlossary(input: {id: $id, update: $unitOfMeasure}) {
    id
    code
    label
    description
  }
}
    `;
export type UpdateUomGlossaryMutationFn = Apollo.MutationFunction<UpdateUomGlossaryMutation, UpdateUomGlossaryMutationVariables>;

/**
 * __useUpdateUomGlossaryMutation__
 *
 * To run a mutation, you first call `useUpdateUomGlossaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUomGlossaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUomGlossaryMutation, { data, loading, error }] = useUpdateUomGlossaryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      unitOfMeasure: // value for 'unitOfMeasure'
 *   },
 * });
 */
export function useUpdateUomGlossaryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUomGlossaryMutation, UpdateUomGlossaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUomGlossaryMutation, UpdateUomGlossaryMutationVariables>(UpdateUomGlossaryDocument, options);
      }
export type UpdateUomGlossaryMutationHookResult = ReturnType<typeof useUpdateUomGlossaryMutation>;
export type UpdateUomGlossaryMutationResult = Apollo.MutationResult<UpdateUomGlossaryMutation>;
export type UpdateUomGlossaryMutationOptions = Apollo.BaseMutationOptions<UpdateUomGlossaryMutation, UpdateUomGlossaryMutationVariables>;