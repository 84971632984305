/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DestinationBinsForBinToBinQueryVariables = Types.Exact<{
  warehouseId: Types.Scalars['ID']['input'];
}>;


export type DestinationBinsForBinToBinQuery = { __typename?: 'Query', destinationBinsForTaskType: { __typename?: 'TaskTypeBinStatusDestinationBins', destinationBins: Array<{ __typename?: 'ViewBin', id?: string | null, code?: string | null, areaId?: string | null, areaCode?: string | null }> } };

export type CreateOneBinToBinMovementTaskMutationVariables = Types.Exact<{
  input: Types.CreateBinToBinTaskInputDto;
}>;


export type CreateOneBinToBinMovementTaskMutation = { __typename?: 'Mutation', createOneBinToBinMovementTask: { __typename?: 'Task', id: string, code: string, status?: Types.TaskStatus | null, product?: { __typename?: 'Product', id: string, code: string } | null, destinationBin?: { __typename?: 'Bin', id: string, code: string } | null } };


export const DestinationBinsForBinToBinDocument = gql`
    query DestinationBinsForBinToBin($warehouseId: ID!) {
  destinationBinsForTaskType(
    taskTypeCode: "TSK-01"
    filter: {warehouseId: {eq: $warehouseId}}
    sorting: {field: code, direction: ASC}
  ) {
    destinationBins {
      id
      code
      areaId
      areaCode
    }
  }
}
    `;

/**
 * __useDestinationBinsForBinToBinQuery__
 *
 * To run a query within a React component, call `useDestinationBinsForBinToBinQuery` and pass it any options that fit your needs.
 * When your component renders, `useDestinationBinsForBinToBinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDestinationBinsForBinToBinQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useDestinationBinsForBinToBinQuery(baseOptions: Apollo.QueryHookOptions<DestinationBinsForBinToBinQuery, DestinationBinsForBinToBinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DestinationBinsForBinToBinQuery, DestinationBinsForBinToBinQueryVariables>(DestinationBinsForBinToBinDocument, options);
      }
export function useDestinationBinsForBinToBinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DestinationBinsForBinToBinQuery, DestinationBinsForBinToBinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DestinationBinsForBinToBinQuery, DestinationBinsForBinToBinQueryVariables>(DestinationBinsForBinToBinDocument, options);
        }
export type DestinationBinsForBinToBinQueryHookResult = ReturnType<typeof useDestinationBinsForBinToBinQuery>;
export type DestinationBinsForBinToBinLazyQueryHookResult = ReturnType<typeof useDestinationBinsForBinToBinLazyQuery>;
export type DestinationBinsForBinToBinQueryResult = Apollo.QueryResult<DestinationBinsForBinToBinQuery, DestinationBinsForBinToBinQueryVariables>;
export const CreateOneBinToBinMovementTaskDocument = gql`
    mutation CreateOneBinToBinMovementTask($input: CreateBinToBinTaskInputDto!) {
  createOneBinToBinMovementTask(input: $input) {
    id
    code
    status
    product {
      id
      code
    }
    destinationBin {
      id
      code
    }
  }
}
    `;
export type CreateOneBinToBinMovementTaskMutationFn = Apollo.MutationFunction<CreateOneBinToBinMovementTaskMutation, CreateOneBinToBinMovementTaskMutationVariables>;

/**
 * __useCreateOneBinToBinMovementTaskMutation__
 *
 * To run a mutation, you first call `useCreateOneBinToBinMovementTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneBinToBinMovementTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneBinToBinMovementTaskMutation, { data, loading, error }] = useCreateOneBinToBinMovementTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneBinToBinMovementTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneBinToBinMovementTaskMutation, CreateOneBinToBinMovementTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneBinToBinMovementTaskMutation, CreateOneBinToBinMovementTaskMutationVariables>(CreateOneBinToBinMovementTaskDocument, options);
      }
export type CreateOneBinToBinMovementTaskMutationHookResult = ReturnType<typeof useCreateOneBinToBinMovementTaskMutation>;
export type CreateOneBinToBinMovementTaskMutationResult = Apollo.MutationResult<CreateOneBinToBinMovementTaskMutation>;
export type CreateOneBinToBinMovementTaskMutationOptions = Apollo.BaseMutationOptions<CreateOneBinToBinMovementTaskMutation, CreateOneBinToBinMovementTaskMutationVariables>;