import { CheckBoxOutlineBlankOutlined } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { ReactNode, isValidElement, memo } from 'react';

export interface ILineItem {
  label?: string;
  value?: string | number | ReactNode;
  icon?: ReactNode;
  boldLabel?: boolean;
  removeDots?: boolean;
  removeBold?: boolean;
  emptyLine?: boolean;
  emptyIcon?: boolean;
  fullLineValue?: boolean;
}

export interface IColumn {
  labelWidth: string;
  lineItems: ILineItem[];
}

export interface IJustifiedColumn {
  lineItems: ILineItem[];
  labelWidth: string;
  columnIndex: number;
  mb: string;
}

export const JustifiedColumn: React.FC<IJustifiedColumn> = memo(
  ({ lineItems, labelWidth, columnIndex, mb }) => {
    return (
      <>
        {lineItems
          .filter((li) => !!li)
          .map((lineItem, index) => (
            <Box
              key={`columnItem-${index}`}
              display="flex"
              textAlign="left"
              maxWidth="100%"
              mb={index < lineItems.length - 1 ? mb : 'none'}
            >
              {!lineItem.fullLineValue && (
                <Box
                  display="flex"
                  alignItems="center"
                  width={labelWidth}
                  style={{
                    flexShrink: 0,
                    visibility: lineItem.emptyLine ? 'hidden' : 'visible',
                  }}
                  aria-hidden={lineItem.emptyLine ? true : false}
                >
                  {lineItem.icon}
                  {!lineItem.icon && lineItem.emptyIcon && (
                    <CheckBoxOutlineBlankOutlined sx={{ visibility: 'hidden' }} />
                  )}
                  <Typography
                    variant="body1"
                    align="left"
                    sx={{
                      fontWeight: lineItem.boldLabel ? '700' : 'normal',
                      marginLeft: lineItem.icon || lineItem.emptyIcon ? '5px' : '0px',
                    }}
                  >
                    {!lineItem.emptyLine ? lineItem.label : 'EMPTY'}
                    {!lineItem.removeDots && ':'}
                  </Typography>
                </Box>
              )}

              {/* Here we check if the passed in value is a ReactNode or not, if so - just render it */}
              {isValidElement(lineItem.value) ? (
                lineItem.value
              ) : (
                <Typography
                  data-testid={`justified-column-${columnIndex}-${index}-value`}
                  variant="body1"
                  align="left"
                  flexGrow="1"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  fontWeight={!lineItem.removeBold ? '700' : 'normal'}
                >
                  {lineItem.value}
                </Typography>
              )}
            </Box>
          ))}
      </>
    );
  },
);

// IJustifiedColumns can only support up to 4 columns
export interface IJustifiedColumns {
  columns: IColumn[];
  mb?: string;
  fluid?: boolean;
  sx?: SxProps<Theme>;
}

export const JustifiedColumns: React.FC<IJustifiedColumns> = memo(
  ({ columns, mb = '16px', fluid = false, sx }) => {
    let columnSize = 12;
    switch (columns.length) {
      case 0:
      case 1:
        columnSize = 12;
        break;
      case 2:
        columnSize = 6;
        break;
      case 3:
        columnSize = 4;
        break;
      case 4:
        columnSize = 3;
        break;
    }

    const JustifiedWrapper = ({ children }) =>
      fluid ? (
        <Box display="inline-flex" gap="75px" sx={sx}>
          {children}
        </Box>
      ) : (
        <Grid container spacing={6} sx={sx}>
          {children}
        </Grid>
      );

    const JustifiedColumnWrapper = ({ children }) =>
      fluid ? (
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          {children}
        </Box>
      ) : (
        <Grid item xs={columnSize} container direction="column" alignItems="flex-start">
          {children}
        </Grid>
      );

    return (
      <JustifiedWrapper>
        {columns
          .filter((c) => !!c)
          .map((column, index) => (
            <JustifiedColumnWrapper key={`columnWrapper-${index}`}>
              <JustifiedColumn
                lineItems={column.lineItems}
                labelWidth={column.labelWidth}
                columnIndex={index}
                mb={mb}
              />
            </JustifiedColumnWrapper>
          ))}
      </JustifiedWrapper>
    );
  },
);
