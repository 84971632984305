import { Box, Grid } from '@mui/material';
import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useAssignDoorToDeliveryMutation,
  useListDoorsForDeliveryQuery,
} from '@/graphql/defs/components/modals/__generated__/delivery-door-assign.generated';
import { ListDoorsForDelivery_defaultData } from '@/graphql/defs/components/modals/delivery-door-assign';
import { DeliveryType } from '@/graphql/types.generated';
import DeliveryTypeCell from '@components/data-table/table/cells/DeliveryTypeCell';
import EditableTable, {
  EditableTableColumn,
  EditableTableRow,
} from '@components/editable-table/EditableTable';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useDateTime } from '@context/date-time';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { useFormValidation } from '@hooks/form/validators';
import FormValues from '@models/FormValues';
import { IDeliveryAssignDoorModal } from '@models/modal';

const DeliveryDoorAssignModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal } = useModalContent<IDeliveryAssignDoorModal>();
  const { showMessage } = useSnackbar();
  const { displayDate } = useDateTime();

  interface IDeliveryDoorAssignFields {
    doorId: string;
  }
  const formMethods = useForm<FormValues<IDeliveryDoorAssignFields>>({
    defaultValues: { doorId: '' },
  });
  const { control, handleSubmit } = formMethods;
  const { notWhiteSpaceOnly } = useFormValidation();

  const { data: listDoorsData } = useListDoorsForDeliveryQuery({
    variables: {
      direction: modal.doorDirection,
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });
  const {
    listDoors: { doors },
  } = useMemo(() => listDoorsData || ListDoorsForDelivery_defaultData, [listDoorsData]);

  const [updateDelivery] = useAssignDoorToDeliveryMutation({
    onCompleted: ({
      updateOneDelivery: {
        erpCode: deliveryCode,
        door: { code: doorCode },
      },
    }) => {
      showMessage({
        type: 'success',
        message: t('modal.delivery.doorAssign.success', { doorCode, deliveryCode }),
      });
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const processDoorAssignments = async (doorId: string, index: number) => {
    const delivery = modal.deliveries[index];
    const nextIndex = ++index;

    void updateDelivery({
      variables: {
        doorId,
        deliveryId: delivery?.id,
      },
      onCompleted: () => {
        if (nextIndex >= modal.deliveries.length) {
          closeModal({ bypassLoading: true, success: true });
        }
      },
    });

    if (nextIndex < modal.deliveries.length) {
      await processDoorAssignments(doorId, index);
    }
  };

  const onSubmit = (fields: IDeliveryDoorAssignFields) => {
    void processDoorAssignments(fields?.doorId, 0);
  };

  const deliveryColumns: EditableTableColumn[] = [
    {
      label: t('common.code'),
      width: '25%',
    },
    {
      label: t('common.type'),
      width: '25%',
    },
    {
      label: t('common.dueDate'),
      width: '25%',
    },
    {
      label: t('common.businessPartner'),
      width: '25%',
    },
  ];

  const deliveryRows: EditableTableRow[] = useMemo(
    () =>
      modal.deliveries?.map((delivery): EditableTableRow => {
        return {
          item: delivery,
          dataTestId: `${delivery?.id}`,
          cells: [
            {
              value: delivery?.erpCode,
              dataTestId: `${delivery?.id}-code`,
            },
            {
              value: <DeliveryTypeCell deliveryType={delivery?.type} />,
              dataTestId: `${delivery?.id}-type`,
            },
            {
              value: displayDate({ date: delivery?.dueDate }),
              dataTestId: `${delivery?.id}-dueDate`,
            },
            {
              value:
                delivery?.type === DeliveryType.Delivery
                  ? delivery?.supplierName
                  : delivery?.soldToName,
              dataTestId: `${delivery?.id}-dueDate`,
            },
          ],
        };
      }) || [],
    [modal.deliveries],
  );

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <Box sx={{ marginBottom: '15px' }}>
          {t('modal.delivery.doorAssign.text', {
            count: modal.deliveries?.length,
          })}
        </Box>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <Controller
              name="doorId"
              control={control}
              rules={{
                required: t('form.validation.requiredField', {
                  field: t('common.doorCode'),
                }),
                validate: {
                  notWhiteSpaceOnly,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    autoFocus
                    fullWidth
                    required
                    select
                    SelectProps={{
                      native: true,
                    }}
                    id="doorId"
                    label={t('common.doorCode')}
                    {...field}
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    dataTestId="door"
                  >
                    <option disabled />
                    {doors.map((door) => {
                      return (
                        <option key={`door-${door.code}`} value={door.id}>
                          {door.code}
                        </option>
                      );
                    })}
                  </TextField>
                );
              }}
            />
          </Grid>
        </Grid>
        {modal.deliveries.length > 1 && (
          <EditableTable columns={deliveryColumns} data={deliveryRows} sx={{ marginTop: '15px' }} />
        )}
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" actionType="submit">
          {t('common.assign')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default DeliveryDoorAssignModal;
