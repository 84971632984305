/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
export type UoMFragment = { __typename?: 'UnitOfMeasureProductConversion', id: string, code: string, isBaseUom: boolean };

export const UoMFragmentDoc = gql`
    fragment UoM on UnitOfMeasureProductConversion {
  id
  code
  isBaseUom
}
    `;