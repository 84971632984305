enum TableIds {
  AbcAnalysisV1 = 'abc-analysis',
  AgentHost = 'agent-host',
  AgentList = 'agent-list',
  AreaSettings = 'areas-settings',
  BarcodeEnrollmentPartnersList = 'barcode-enrollment-partners-list',
  BarcodeSettingsCustom = 'barcode-settings-custom',
  BarcodeSettingsNonCompliant = 'barcode-settings-non-compliant',
  BarcodeSettingsPreEnrolled = 'barcode-settings-pre-enrolled',
  BinDetailsChangeHistory = 'bin-details-change-history',
  BinDetailsInventory = 'bin-details-inventory',
  BinDetailsTasks = 'bin-details-tasks',
  BinSettings = 'bin-settings',
  BinStorageTypes = 'bin-storage-types',
  BinStorageTypeBins = 'bin-storage-type-bins',
  BinStorageTypeChangeHistory = 'bin-storage-type-change-history',
  BusinessPartnerSettings = 'business-partner-settings',
  CancelDeliveryItems = 'cancel-delivery-items',
  ContractorValidationLog = 'contractor-validation-log',
  CreateRunFromRulesetDatasets = 'create-run-from-ruleset-datasets',
  CreateRunFromDatasetRulesets = 'create-run-from-dataset-rulesets',
  DatasetAdditionalRuns = 'dataset-additional-runs',
  Datasets = 'datasets',
  DatasetsData = 'datasets-data',
  DatasetsUpdate = 'datasets-update',
  DatasetsLockUnlock = 'datasets-lock-unlock',
  DeliveriesFulfillmentsOverview = 'deliveries-fulfillments-overview',
  DeliveriesFulfillmentsOverview_DeliveryItems = 'deliveries-fulfillments-overview-delivery-items',
  DeliveriesFulfillmentsOverview_FulfillmentItems = 'deliveries-fulfillments-overview-fulfillment-items',
  DoorSettings = 'door-settings',
  EntityChangeHistory = 'entity-change-history',
  EquipmentSettings = 'equipment-settings',
  EquipmentModelSettings = 'equipment-model-settings',
  EquipmentTypeSettings = 'equipment-type-settings',
  FulfillmentBlocks = 'fulfillment-blocks',
  InboundDeliveryChangeHistory = 'inbound-delivery-change-history',
  InboundDeliveryItems = 'inbound-delivery-items',
  InboundDeliveryItemsChangeHistory = 'inbound-delivery-items-change-history',
  InboundDeliveryInventory = 'inbound-delivery-inventory',
  InboundDeliveryTasks = 'inbound-delivery-tasks',
  InventoryBinLevel = 'inventory-bin-level',
  InventoryCounting = 'inventory-counting',
  InventoryLicensePlateLevel = 'inventory-license-plate-level',
  InventoryLostAndFound = 'inventory-lost-and-found-level',
  InventoryLotLevel = 'inventory-lot-level',
  InventoryProductLevel = 'inventory-product-level',
  LicensePlateDetailsInventory = 'license-plate-details-inventory',
  LicensePlateDetailsNestedLPs = 'license-plate-details-nested-lps',
  LicensePlateDetailsTasks = 'license-plate-details-tasks',
  LotDetailsInventory = 'lot-details-inventory',
  LotDetailsTasks = 'lot-details-tasks',
  LotSettings = 'lot-settings',
  MapAreaBins = 'map-area-bins',
  OutboundFulfillmentChangeHistory = 'outbound-fulfillment-change-history',
  OutboundFulfillmentItems = 'outbound-fulfillment-items',
  OutboundFulfillmentItemsChangeHistory = 'outbound-fulfillment-items-change-history',
  OutboundFulfillmentInventory = 'outbound-fulfillment-inventory',
  OutboundFulfillmentTasks = 'outbound-fulfillment-tasks',
  ProcessedFileRows = 'processed-file-rows',
  ProductDetailsInventory = 'product-details-inventory',
  ProductDetailsTasks = 'product-details-tasks',
  ProductDetailsUOM = 'product-details-unit-of-measures',
  ProductSettings = 'products',
  RolesAndPermissionsSettings = 'roles-and-permissions-settings',
  Rulesets = 'rulesets',
  RulesetsModal = 'rulesets-modal',
  RulesetWorkers = 'ruleset-workers',
  RulesetZones = 'ruleset-zones',
  RulesetEquipment = 'ruleset-equipment',
  RunDeployMovementsProposedAbcAnalysis = 'run-deploy-movements-proposed-abc-analysis',
  RunDeployMovementsApprovedAbcAnalysis = 'run-deploy-movements-approved-abc-analysis',
  RunDeployMovementsProposedMovements = 'run-deploy-movements-proposed-movements',
  RunDeployMovementsApprovedMovements = 'run-deploy-movements-approved-movements',
  StockStatusSettings = 'stock-status-settings',
  SlottingAbcAnalysis = 'slotting-abc-analysis',
  SlottingDashboardRuns = 'slotting-dashboard-runs',
  SlottingDashboardDataSets = 'slotting-dashboard-data-sets',
  SlottingDashboardRuleSets = 'slotting-dashboard-rule-sets',
  SlottingMovements = 'slotting-movements',
  SlottingPepsiMovements = 'slotting-pepsi-movements',
  SlottingInventory = 'slotting-inventory',
  SlottingRuns = 'slotting-runs',
  TaskTypeBinStatusMappings = 'TaskTypeBinStatusMappings',
  TaskTypesStockStatusMappings = 'task-types-stock-status-mappings',
  TaskDetails = 'task-details',
  TaskList = 'global-task-list',
  TaskGroups = 'task-groups',
  TaskGroupTasks = 'task-group-tasks',
  TaskChangeHistory = 'task-change-history',
  TeamCardUserList = 'team-card-user-list',
  UnitOfMeasureGlossary = 'uom-glossary',
  UserAdminSettings = 'user-admin-settings',
  WarehouseRoleTypeSettings = 'warehouse-role-type-settings',
  ZoneSettings = 'zone-settings',
  ZoneAislesSettingsAdd = 'zone-aisles-settings-add',
  ZoneAislesSettingsRemove = 'zone-aisles-settings-remove',
  ZoneAislesSettingsDisplay = 'zone-aisles-settings-display',
  ZoneAreasSettingsAdd = 'zone-areas-settings-add',
  ZoneAreasSettingsRemove = 'zone-areas-settings-remove',
  ZoneAreasSettingsDisplay = 'zone-areas-settings-display',
  ZoneAisleColumnsSettingsAdd = 'zone-aisle-columns-settings-add',
  ZoneAisleColumnsSettingsRemove = 'zone-aisle-columns-settings-remove',
  ZoneAisleColumnsSettingsDisplay = 'zone-aisle-columns-settings-display',
  ZoneBinsSettingsAdd = 'zone-bins-settings-add',
  ZoneBinsSettingsRemove = 'zone-bins-settings-remove',
  ZoneBinsSettingsDisplay = 'zone-bins-settings-display',
}

export default TableIds;
