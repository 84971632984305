/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateZoneMutationVariables = Types.Exact<{
  input: Types.ZoneCreateOneInput;
}>;


export type CreateZoneMutation = { __typename?: 'Mutation', createOneZone: { __typename?: 'Zone', id: string, createdAt: string, updatedAt?: string | null, updatedByEmail?: string | null, updatedById?: string | null, deletedAt?: string | null, code: string, name?: string | null, description?: string | null, warehouseId: string } };


export const CreateZoneDocument = gql`
    mutation CreateZone($input: ZoneCreateOneInput!) {
  createOneZone(input: $input) {
    id
    createdAt
    updatedAt
    updatedByEmail
    updatedById
    deletedAt
    code
    name
    description
    warehouseId
  }
}
    `;
export type CreateZoneMutationFn = Apollo.MutationFunction<CreateZoneMutation, CreateZoneMutationVariables>;

/**
 * __useCreateZoneMutation__
 *
 * To run a mutation, you first call `useCreateZoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateZoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createZoneMutation, { data, loading, error }] = useCreateZoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateZoneMutation(baseOptions?: Apollo.MutationHookOptions<CreateZoneMutation, CreateZoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateZoneMutation, CreateZoneMutationVariables>(CreateZoneDocument, options);
      }
export type CreateZoneMutationHookResult = ReturnType<typeof useCreateZoneMutation>;
export type CreateZoneMutationResult = Apollo.MutationResult<CreateZoneMutation>;
export type CreateZoneMutationOptions = Apollo.BaseMutationOptions<CreateZoneMutation, CreateZoneMutationVariables>;