import { gql } from '@apollo/client';

import { ListFulfillmentBlocksQuery } from '@/graphql/defs/list/__generated__/fulfillment-blocks.generated';

gql`
  query ListFulfillmentBlocks(
    $paging: OffsetPaging
    $filter: FulfillmentBlockFilter
    $sorting: [FulfillmentBlockSort!]
  ) {
    listFulfillmentBlocks: fulfillmentBlocks(paging: $paging, filter: $filter, sorting: $sorting) {
      fulfillmentBlocks: nodes {
        id
        createdAt
        updatedAt
        deletedAt
        label
        description
      }
      totalCount
    }
  }
`;

export const ListFulfillmentBlocks_defaultData: ListFulfillmentBlocksQuery = {
  __typename: 'Query',
  listFulfillmentBlocks: {
    totalCount: 0,
    __typename: 'FulfillmentBlockOffsetConnection',
    fulfillmentBlocks: [],
  },
};
