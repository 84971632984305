import { ApolloError } from '@apollo/client';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  usePostGoodsIssueMutation,
  usePostGoodsReceiptMutation,
} from '@/graphql/defs/components/modals/__generated__/post-goods-receipt-or-issue.generated';
import { DeliveryType } from '@/graphql/types.generated';
import { ModalActions, ModalContent } from '@components/modal';
import { ModalButton } from '@components/modal/modal-button';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { IPostGoodsReceiptOrIssueModal } from '@models/modal';

export const PostGoodsReceiptOrIssueModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal } = useModalContent<IPostGoodsReceiptOrIssueModal>();
  const { showMessage } = useSnackbar();

  const handleError = (error: ApolloError) => {
    showMessage({
      type: 'error',
      message: error.message,
    });
  };

  const [postGoodsIssue] = usePostGoodsIssueMutation({ onError: handleError });
  const [postGoodsReceipt] = usePostGoodsReceiptMutation({ onError: handleError });

  const handleSubmit = async () => {
    if (modal.deliveryType === DeliveryType.Fulfillment)
      await postGoodsIssue({ variables: { fulfillmentId: modal.id } });
    else await postGoodsReceipt({ variables: { deliveryId: modal.id } });

    closeModal({ bypassLoading: true, success: true });
  };

  return (
    <>
      <ModalContent>
        <Box
          sx={{ height: 300, width: 650, fontSize: 18, paddingBottom: 40 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {t(
            modal.deliveryType === DeliveryType.Delivery
              ? 'modal.delivery.pgrNotice'
              : 'modal.delivery.pgiNotice',
            { code: modal.code },
          )}
        </Box>
      </ModalContent>
      <ModalActions>
        <ModalButton
          variant="outlined"
          color="primary"
          onClick={() => closeModal()}
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" onClick={handleSubmit} actionType="submit">
          {t('common.confirm')}
        </ModalButton>
      </ModalActions>
    </>
  );
};
