/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListZonesQueryVariables = Types.Exact<{
  sorting?: Types.InputMaybe<Array<Types.ViewZoneSort> | Types.ViewZoneSort>;
  filter?: Types.InputMaybe<Types.ViewZoneFilter>;
  paging?: Types.InputMaybe<Types.OffsetPaging>;
}>;


export type ListZonesQuery = { __typename?: 'Query', listZones: { __typename?: 'ViewZoneOffsetConnection', zones: Array<{ __typename?: 'ViewZone', id?: string | null, code?: string | null, name?: string | null, description?: string | null, warehouseId?: string | null, areaCount?: number | null, binCount?: number | null, aisleCount?: number | null, aisleColumnCount?: number | null }> } };


export const ListZonesDocument = gql`
    query ListZones($sorting: [ViewZoneSort!], $filter: ViewZoneFilter, $paging: OffsetPaging) {
  listZones: viewZones(sorting: $sorting, filter: $filter, paging: $paging) {
    zones: nodes {
      id
      code
      name
      description
      warehouseId
      areaCount
      binCount
      aisleCount
      aisleColumnCount
    }
  }
}
    `;

/**
 * __useListZonesQuery__
 *
 * To run a query within a React component, call `useListZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListZonesQuery({
 *   variables: {
 *      sorting: // value for 'sorting'
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useListZonesQuery(baseOptions?: Apollo.QueryHookOptions<ListZonesQuery, ListZonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListZonesQuery, ListZonesQueryVariables>(ListZonesDocument, options);
      }
export function useListZonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListZonesQuery, ListZonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListZonesQuery, ListZonesQueryVariables>(ListZonesDocument, options);
        }
export type ListZonesQueryHookResult = ReturnType<typeof useListZonesQuery>;
export type ListZonesLazyQueryHookResult = ReturnType<typeof useListZonesLazyQuery>;
export type ListZonesQueryResult = Apollo.QueryResult<ListZonesQuery, ListZonesQueryVariables>;