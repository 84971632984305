import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useCreateIssueStockTaskMutation,
  useGetDeliveryInfoForIssueStockModalLazyQuery,
} from '@/graphql/defs/components/modals/__generated__/create-issue-stock-modal.generated';
import { LostAndFoundInventoryFragment } from '@/graphql/defs/hooks/shared-columns/__generated__/useCreateLostAndFoundInventoryColumns.generated';
import { BaseInventoryFragment } from '@/graphql/defs/shared-fragments/__generated__/inventory.generated';
import { CreateOneIssueStockTaskDto, TaskStatus } from '@/graphql/types.generated';
import { IColumn, JustifiedColumns } from '@components/justified-columns';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { ICreateIssueStockModal } from '@models/modal';

interface IIssueStockItem {
  licensePlateId?: string | null;
  licensePlateCode?: string | null;
  licensePlateDescription?: string | null;
  productId?: string;
  productCode?: string;
  productDescription?: string | null;
  productStockStatusId?: string;
  productStockStatus?: string;
  productUoM?: string;
  productLotId?: string | null;
  sourceBin?: string;
  customer?: string | null;
  deliveryCode?: string | null;
  deliveryItemId?: string | null;
  deliveryItem?: string | null;
  warehouse?: string;
}

const CreateIssueStockModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal, setLoading, isPreparing, setPreparing } =
    useModalContent<ICreateIssueStockModal>();

  const [shouldAutocomplete, setShouldAutocomplete] = useState(false);
  const [issueStockItem, setIssueStockItem] = useState<IIssueStockItem>(null);

  const [createTask] = useCreateIssueStockTaskMutation({
    onCompleted: ({ createOneIssueStockTask: { code, status } }) => {
      showMessage({
        type: 'success',
        message:
          status === TaskStatus.Complete
            ? t('modal.issueStock.create.successAutocomplete')
            : t('modal.issueStock.create.success', { taskCode: code }),
      });

      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      showMessage({ type: 'error', message: error.message });
      setLoading(false);
    },
  });

  const [getDeliveryInfo] = useGetDeliveryInfoForIssueStockModalLazyQuery({
    onCompleted: ({
      deliveryInfo: { shipToBusinessPartner, soldToBusinessPartner, supplierBusinessPartner },
    }) => {
      setIssueStockItem({
        ...issueStockItem,
        customer:
          supplierBusinessPartner?.name ||
          shipToBusinessPartner?.name ||
          soldToBusinessPartner?.name,
      });
      setPreparing(false);
    },
    onError: (error) => {
      showMessage({ type: 'error', message: error.message });
      setPreparing(false);
    },
  });

  useEffect(() => {
    if (modal.inventoryItem.__typename === 'InventoryBaseQueryShape') {
      const item = modal.inventoryItem as BaseInventoryFragment;
      setIssueStockItem({
        licensePlateCode: item.licensePlateCode,
        licensePlateDescription: item.licensePlateDescription,
        productCode: item.productCode,
        productDescription: item.productDescription,
        productStockStatus: item.stockStatus,
        productUoM: item.unitOfMeasure,
        productLotId: item.lotId,
        sourceBin: item.binCode,
        deliveryItemId: item.deliveryItemId,
        deliveryItem: item.deliveryItem,
        deliveryCode: item.stockDeliveryCode,
        warehouse: item.warehouseCode,
      });
    } else {
      const item = modal.inventoryItem as LostAndFoundInventoryFragment;
      setIssueStockItem({
        licensePlateCode: item.licensePlateCode,
        licensePlateDescription: item.licensePlateDescription,
        productCode: item.productCode,
        productDescription: item.productDescription,
        productStockStatus: item.stockStatusLabel,
        productUoM: item.unitOfMeasureCode,
        productLotId: item.lotId,
        sourceBin: item.binCode,
        deliveryItemId: item.deliveryItemId,
        deliveryItem: item.deliveryItem,
        deliveryCode: item.stockDeliveryCode,
        warehouse: item.warehouseCode,
      });
    }

    if (modal.inventoryItem.stockDeliveryId)
      void getDeliveryInfo({ variables: { deliveryId: modal.inventoryItem.stockDeliveryId } });
  }, []);

  const issueStockItemDetails = useMemo<IColumn[]>(
    () => [
      {
        labelWidth: '150px',
        lineItems: [
          {
            label: t('common.customer'),
            value: issueStockItem?.customer,
            removeBold: true,
            boldLabel: true,
          },
          {
            label: t('common.product'),
            value: issueStockItem?.productCode,
            removeBold: true,
            boldLabel: true,
          },
          {
            label: t('common.delivery', { count: 1 }),
            value: issueStockItem?.deliveryCode,
            removeBold: true,
            boldLabel: true,
          },
          {
            label: t('common.item'),
            value: issueStockItem?.deliveryItem,
            removeBold: true,
            boldLabel: true,
          },
          {
            label: t('common.sourceBin'),
            value: issueStockItem?.sourceBin,
            boldLabel: true,
          },
        ],
      },
      {
        labelWidth: '150px',
        lineItems: [
          {
            label: t('common.warehouse'),
            value: issueStockItem?.warehouse,
            removeBold: true,
            boldLabel: true,
          },
          {
            label: t('common.description'),
            value: issueStockItem?.productDescription,
            removeBold: true,
            boldLabel: true,
          },
          {
            label: t('common.stockStatus'),
            value: issueStockItem?.productStockStatus,
            removeBold: true,
            boldLabel: true,
          },
          {
            label: t('common.uom'),
            value: issueStockItem?.productUoM,
            removeBold: true,
            boldLabel: true,
          },
          {
            label: t('common.destinationBin'),
            value: '--',
            boldLabel: true,
          },
        ],
      },
    ],
    [issueStockItem],
  );

  const onSubmit = () => {
    let taskInput: CreateOneIssueStockTaskDto;
    if (modal.inventoryItem.__typename === 'InventoryBaseQueryShape') {
      const item = modal.inventoryItem as BaseInventoryFragment;
      taskInput = {
        autocomplete: shouldAutocomplete,
        deliveryItemId: item.deliveryItemId,
        fulfillmentItemId: item.fulfillmentItemId,
        productId: item.productId,
        quantity: item.quantity,
        sourceBinId: item.binId,
        sourceLicensePlateId: item.licensePlateId,
        sourceLotId: item.lotId,
        sourceStockStatusId: item.stockStatusTypeId,
      };
    } else {
      const item = modal.inventoryItem as LostAndFoundInventoryFragment;
      taskInput = {
        autocomplete: shouldAutocomplete,
        deliveryItemId: item.deliveryItemId,
        fulfillmentItemId: item.fulfillmentItemId,
        productId: item.productId,
        quantity: item.quantity,
        sourceBinId: item.binId,
        sourceLicensePlateId: item.licensePlateId,
        sourceLotId: item.lotId,
        sourceStockStatusId: item.stockStatusId,
      };
    }
    void createTask({
      variables: { taskInput },
    });
  };

  return (
    !isPreparing && (
      <>
        <ModalContent>
          <Box display="flex" maxWidth="100%">
            <Typography fontWeight={700} width="150px" flexShrink={0}>
              {t('common.licensePlate')}
              {': '}
            </Typography>
            <Typography
              color="primary"
              fontWeight={700}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {issueStockItem?.licensePlateCode}
            </Typography>
          </Box>
          <Box display="flex" sx={{ marginBottom: '20px' }} maxWidth="100%">
            <Typography fontWeight={700} width="150px" flexShrink={0}>
              {t('common.description')}
              {': '}
            </Typography>
            <Typography whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
              {issueStockItem?.licensePlateDescription}
            </Typography>
          </Box>
          <JustifiedColumns columns={issueStockItemDetails} />
        </ModalContent>
        <ModalActions>
          <Box sx={{ marginRight: 'auto' }}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setShouldAutocomplete(e?.target?.checked || false);
                  }}
                />
              }
              label={t('modal.task.markAsComplete')}
            />
          </Box>
          <ModalButton
            onClick={() => closeModal()}
            variant="outlined"
            color="primary"
            actionType="cancel"
          >
            {t('common.cancel')}
          </ModalButton>
          <ModalButton onClick={onSubmit} variant="contained" color="primary" actionType="submit">
            {t('common.submit')}
          </ModalButton>
        </ModalActions>
      </>
    )
  );
};

export default CreateIssueStockModal;
