import { ApolloError } from '@apollo/client';

import { GetWarehouseDropdownDocument } from '@/graphql/defs/components/__generated__/warehouse-dropdown.generated';
import { useCreateWarehouseMutation } from '@/graphql/defs/components/modals/__generated__/warehouse-create-modal.generated';
import { useUpdateWarehouseDetailsMutation } from '@/graphql/defs/components/modals/__generated__/warehouse-details-modal.generated';
import { WarehouseCreateInput, WarehouseUpdateInput } from '@/graphql/types.generated';
import { IVStepCBArgs } from '@components/form-util';
import { useEntityUtils } from '@context/entity-utils';
import { stripUnchangedFields } from '@lib/form';

/**
 * Handle the submission of the Warehouse Details field block within a stepper form.
 *
 * @param args - The Vertical Stepper Callback Arguments
 * @returns True or false, depending on if any errors occur.
 */
export const useWarehouseDetailsStep = (args: IVStepCBArgs) => {
  const { selectedOrganizationId } = useEntityUtils();

  const { depMap, updateDepMap, setError, setActiveStepState } = args;

  const handleError = (error: ApolloError) => {
    setError(error.message);
    setActiveStepState('error');
  };

  const [createWarehouse] = useCreateWarehouseMutation({
    refetchQueries: [
      { query: GetWarehouseDropdownDocument, variables: { orgId: selectedOrganizationId } },
    ],
    onCompleted: ({ createOneWarehouse: newWarehouse }) => {
      updateDepMap({ parentId: newWarehouse.id, warehouse: newWarehouse });
      setActiveStepState('success');
    },
    onError: handleError,
  });

  const [updateWarehouse] = useUpdateWarehouseDetailsMutation({
    refetchQueries: [
      { query: GetWarehouseDropdownDocument, variables: { orgId: selectedOrganizationId } },
    ],
    onCompleted: ({ updateOneWarehouse: updatedWarehouse }) => {
      updateDepMap({ warehouse: updatedWarehouse });
      setActiveStepState('success');
    },
    onError: handleError,
  });

  return (formWarehouse: WarehouseCreateInput & WarehouseUpdateInput) => {
    const { warehouse } = depMap;

    // Only run the actual update if values changed, or it's a creation, otherwise just return true.
    if (
      warehouse?.id &&
      warehouse.name === formWarehouse.name &&
      warehouse.code === formWarehouse.code &&
      warehouse.companyId === formWarehouse.companyId
    ) {
      setActiveStepState('success');
      return;
    }

    // Check if we have a saved ID - if not, create rather than update.
    if (!warehouse?.id) {
      void createWarehouse({ variables: { newWarehouse: formWarehouse } });
    } else {
      const currentWarehouse = {
        name: warehouse.name,
        code: warehouse.code,
        companyId: warehouse.companyId,
      };

      void updateWarehouse({
        variables: {
          id: warehouse.id,
          updatedWarehouse: stripUnchangedFields(currentWarehouse, formWarehouse),
        },
      });
    }
  };
};
