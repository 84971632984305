import { Box } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useMarkBarcodesAsCompliantMutation } from '@/graphql/defs/components/modals/__generated__/mark-as-compliant-modal.generated';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { getTransComponents } from '@lib/translation';
import { IBarcodeMarkAsCompliantModal } from '@models/modal';

const MarkAsCompliantModal = () => {
  const { modal, closeModal, setLoading } = useModalContent<IBarcodeMarkAsCompliantModal>();
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();

  const [resolveNonCompliantBarcodes] = useMarkBarcodesAsCompliantMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t('modal.barcodes.compliantSuccess', {
          count: modal.nonCompliantBarcodesIds.length,
        }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onSubmit = () => {
    void resolveNonCompliantBarcodes({ variables: { ids: modal.nonCompliantBarcodesIds } });
  };

  return (
    <>
      <ModalContent>
        <Box sx={{ minWidth: '600px' }}>
          <Trans
            ns="components"
            i18nKey="modal.barcodes.markAsCompliantText"
            components={getTransComponents(['bold', 'break'])}
            count={modal.nonCompliantBarcodesIds.length}
          />
        </Box>
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          data-testid="markAsCompliant_submit"
          onClick={onSubmit}
          variant="contained"
          color="primary"
          actionType="submit"
        >
          {t('common.confirm')}
        </ModalButton>
      </ModalActions>
    </>
  );
};

export default MarkAsCompliantModal;
