import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SlottingMovementSortFields } from '@/graphql/types.generated';
import {
  FilterOperators,
  ColumnFilter,
} from '@components/data-table/controls/filter/filter-definitions';
import useCreateSlottingMovementsColumns, {
  SLOTTING_MOVEMENTS_HOOK,
  TSlottingMovementsDataType,
  TSlottingMovementsFieldNames,
  TSlottingMovementsFilterType,
} from '@components/data-table/hooks/shared-columns/useCreateSlottingMovementsColumns';
import useCreateDataTableColumns from '@components/data-table/hooks/useCreateDataTableColumns';
import useDataTableSelection, {
  SelectionType,
} from '@components/data-table/hooks/useDataTableSelection';
import { validateDataTableProps } from '@components/data-table/lib/validateDataTableProps';
import { IDataTableSort } from '@components/data-table/model/data-table';
import TableIds from '@components/data-table/model/TableIds';

interface IUseRunDeployMovementsProposedMovementsDataTableProps {
  runId: string;
}

const useRunDeployMovementsProposedMovementsDataTable = ({
  runId,
}: IUseRunDeployMovementsProposedMovementsDataTableProps) => {
  const { t } = useTranslation('components');

  const slottingMovementsColumns = useCreateSlottingMovementsColumns();
  const columns = useCreateDataTableColumns<TSlottingMovementsDataType>(slottingMovementsColumns);

  const { selection, rowSelection } = useDataTableSelection<TSlottingMovementsDataType>(
    SelectionType.multi,
    'id',
  );

  const [selectionOverride, setSelectionOverride] = useState<TSlottingMovementsDataType[]>([]);
  const handleRemoveApproved = (id: string) => {
    const updatedApprovedMovements = selection.filter((movement) => movement.id !== id);
    setSelectionOverride(updatedApprovedMovements);
  };

  const baseFilter = useMemo<ColumnFilter<TSlottingMovementsFieldNames>[]>(
    () => [
      {
        columnId: SlottingMovementSortFields.RunId,
        operator: FilterOperators.eq,
        value: runId,
      },
    ],
    [runId],
  );

  const defaultSorting = useMemo<IDataTableSort<TSlottingMovementsFieldNames>[]>(
    () => [
      {
        id: SlottingMovementSortFields.SourceBin,
        desc: false,
      },
    ],
    [],
  );

  return {
    approvedMovements: selection,
    setCurrentlyApprovedMovements: setSelectionOverride,
    handleRemoveApproved,
    dataTableProps: validateDataTableProps<
      TSlottingMovementsDataType,
      TSlottingMovementsFilterType,
      TSlottingMovementsFieldNames
    >({
      type: 'query',
      tableId: TableIds.RunDeployMovementsProposedMovements,
      tableHeader: t('slotting.fixedBinDataRecommendations'),
      columns,
      queryHook: SLOTTING_MOVEMENTS_HOOK,
      baseFilter,
      defaultSorting,
      rowSelection: {
        ...rowSelection,
        selectionOverride,
      },
    }),
  };
};

export default useRunDeployMovementsProposedMovementsDataTable;
