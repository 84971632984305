import { Checkbox, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

enum Action {
  pick = 'pick',
  putaway = 'putaway',
}
enum Restriction {
  preventMixedLotsInBins = 'preventMixedLotsInBins',
  preventMixedProductsInBins = 'preventMixedProductsInBins',
  preventMixedExpirationDatesInBins = 'preventMixedExpirationDatesInBins',
  heaviestToLightestPicks = 'heaviestToLightestPicks',
  preventSimilarProductsInAdjacentBins = 'preventSimilarProductsInAdjacentBins',
  fifoPicking = 'fifoPicking',
  fefoFifoPicking = 'fefoFifoPicking',
  enforceSingleOrderPicks = 'enforceSingleOrderPicks',
}

const RESTRICTIONS = [
  {
    type: Action.pick,
    checkboxes: [
      'fifoPicking',
      'fefoFifoPicking',
      'heaviestToLightestPicks',
      'enforceSingleOrderPicks',
    ] as Restriction[],
  },
  {
    type: Action.putaway,
    checkboxes: [
      'preventMixedProductsInBins',
      'preventMixedLotsInBins',
      'preventMixedExpirationDatesInBins',
      // 'preventSimilarProductsInAdjacentBins',
    ] as Restriction[],
  },
];

const RestrictionsInput = ({ control, disabled = false, prefix }) => {
  const { t } = useTranslation('components');
  return (
    <>
      {RESTRICTIONS.map((group, key) => (
        <span key={key}>
          <SubSectionTitle>
            {t(
              group.type === Action.pick
                ? 'modal.ruleset.restrictions.pickRestrictions'
                : 'modal.ruleset.restrictions.putawayRestrictions',
            )}
          </SubSectionTitle>
          {group.checkboxes.map((checkbox, key) => {
            return (
              <Controller
                key={key}
                name={`${prefix}.${checkbox}`}
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ width: '48%' }}
                    control={
                      <Checkbox
                        {...field}
                        checked={!!field.value}
                        disabled={disabled || checkbox === Restriction.enforceSingleOrderPicks}
                      />
                    }
                    label={t(`modal.ruleset.restrictions.${checkbox}`)}
                  />
                )}
              />
            );
          })}
        </span>
      ))}
    </>
  );
};

export default RestrictionsInput;

const SubSectionTitle = styled(Box)({
  margin: '15px 0px 15px 0px',
  fontWeight: 400,
  fontSize: '18px',
});
