import { gql } from '@apollo/client';

import { PopulateLostAndFoundLpModalQuery } from '@/graphql/defs/components/modals/__generated__/update-lost-and-found-lp-modal.generated';

gql`
  query PopulateLostAndFoundLPModal {
    getProductList: products(sorting: [{ field: code, direction: ASC }]) {
      products: nodes {
        id
        code
        description
        unitOfMeasures {
          nodes {
            id
            code
            isBaseUom
          }
        }
      }
    }
    getStockStatusList: stockStatusTypes(sorting: [{ field: label, direction: ASC }]) {
      stockStatuses: nodes {
        id
        label
      }
    }
    getFulfillments: deliveries(
      filter: { type: { eq: fulfillment }, deliveryStatus: { neq: complete } }
    ) {
      fulfillments: nodes {
        id
        erpCode
      }
    }
  }

  mutation UpdateLostAndFoundLPStock($lpStock: CreateAndCompleteOneFoundStockTaskDto!) {
    updateLostAndFoundLPStock: createAndCompleteOneFoundStockTask(
      input: { createAndCompleteOneFoundStockTaskDto: $lpStock }
    ) {
      id
      code
    }
  }
`;

export const PopulateLostAndFoundLPModal_defaultData: PopulateLostAndFoundLpModalQuery = {
  __typename: 'Query',
  getProductList: {
    __typename: 'ProductOffsetConnection',
    products: [],
  },
  getStockStatusList: {
    __typename: 'StockStatusTypeOffsetConnection',
    stockStatuses: [],
  },
  getFulfillments: {
    __typename: 'DeliveryOffsetConnection',
    fulfillments: [],
  },
};
