/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListedStockStatusTypeFragment = { __typename?: 'ViewStockStatus', id?: string | null, code?: string | null, label?: string | null };

export type ListAllStockStatusTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListAllStockStatusTypesQuery = { __typename?: 'Query', viewStockStatuses: { __typename?: 'ViewStockStatusOffsetConnection', stockStatusTypes: Array<{ __typename?: 'ViewStockStatus', id?: string | null, code?: string | null, label?: string | null }> } };

export const ListedStockStatusTypeFragmentDoc = gql`
    fragment ListedStockStatusType on ViewStockStatus {
  id
  code
  label
}
    `;
export const ListAllStockStatusTypesDocument = gql`
    query ListAllStockStatusTypes {
  viewStockStatuses(
    paging: {offset: 0, limit: 1000000}
    sorting: [{field: label, direction: ASC}]
  ) {
    stockStatusTypes: nodes {
      ...ListedStockStatusType
    }
  }
}
    ${ListedStockStatusTypeFragmentDoc}`;

/**
 * __useListAllStockStatusTypesQuery__
 *
 * To run a query within a React component, call `useListAllStockStatusTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllStockStatusTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllStockStatusTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAllStockStatusTypesQuery(baseOptions?: Apollo.QueryHookOptions<ListAllStockStatusTypesQuery, ListAllStockStatusTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllStockStatusTypesQuery, ListAllStockStatusTypesQueryVariables>(ListAllStockStatusTypesDocument, options);
      }
export function useListAllStockStatusTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllStockStatusTypesQuery, ListAllStockStatusTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllStockStatusTypesQuery, ListAllStockStatusTypesQueryVariables>(ListAllStockStatusTypesDocument, options);
        }
export type ListAllStockStatusTypesQueryHookResult = ReturnType<typeof useListAllStockStatusTypesQuery>;
export type ListAllStockStatusTypesLazyQueryHookResult = ReturnType<typeof useListAllStockStatusTypesLazyQuery>;
export type ListAllStockStatusTypesQueryResult = Apollo.QueryResult<ListAllStockStatusTypesQuery, ListAllStockStatusTypesQueryVariables>;