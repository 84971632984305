/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SlottingAbcAnalysisRowFragment = { __typename?: 'SlottingAbcAnalysis', id?: string | null, run_id?: string | null, material: string, material_description?: string | null, proposed_abc_indicator: string, current_abc_indicator: string, process_dt: string };

export type SlottingAbcAnalysisDataTableQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.SlottingAbcAnalysisFilter>;
  sorting?: Types.InputMaybe<Array<Types.SlottingAbcAnalysisSort> | Types.SlottingAbcAnalysisSort>;
  includePageInfo?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeNodes?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeTotalCount?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type SlottingAbcAnalysisDataTableQuery = { __typename?: 'Query', query: { __typename?: 'SlottingAbcAnalysisOffsetConnection', totalCount?: number, pageInfo?: { __typename?: 'OffsetPageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null }, nodes?: Array<{ __typename?: 'SlottingAbcAnalysis', id?: string | null, run_id?: string | null, material: string, material_description?: string | null, proposed_abc_indicator: string, current_abc_indicator: string, process_dt: string }> } };

export const SlottingAbcAnalysisRowFragmentDoc = gql`
    fragment SlottingAbcAnalysisRow on SlottingAbcAnalysis {
  id
  run_id
  material
  material_description
  proposed_abc_indicator
  current_abc_indicator
  process_dt
}
    `;
export const SlottingAbcAnalysisDataTableDocument = gql`
    query SlottingAbcAnalysisDataTable($paging: OffsetPaging, $filter: SlottingAbcAnalysisFilter, $sorting: [SlottingAbcAnalysisSort!], $includePageInfo: Boolean = true, $includeNodes: Boolean = true, $includeTotalCount: Boolean = false) {
  query: slottingAbcAnalysis(paging: $paging, filter: $filter, sorting: $sorting) {
    pageInfo @include(if: $includePageInfo) {
      hasNextPage
      hasPreviousPage
    }
    nodes @include(if: $includeNodes) {
      ...SlottingAbcAnalysisRow
    }
    totalCount @include(if: $includeTotalCount)
  }
}
    ${SlottingAbcAnalysisRowFragmentDoc}`;

/**
 * __useSlottingAbcAnalysisDataTableQuery__
 *
 * To run a query within a React component, call `useSlottingAbcAnalysisDataTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlottingAbcAnalysisDataTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlottingAbcAnalysisDataTableQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      includePageInfo: // value for 'includePageInfo'
 *      includeNodes: // value for 'includeNodes'
 *      includeTotalCount: // value for 'includeTotalCount'
 *   },
 * });
 */
export function useSlottingAbcAnalysisDataTableQuery(baseOptions?: Apollo.QueryHookOptions<SlottingAbcAnalysisDataTableQuery, SlottingAbcAnalysisDataTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SlottingAbcAnalysisDataTableQuery, SlottingAbcAnalysisDataTableQueryVariables>(SlottingAbcAnalysisDataTableDocument, options);
      }
export function useSlottingAbcAnalysisDataTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SlottingAbcAnalysisDataTableQuery, SlottingAbcAnalysisDataTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SlottingAbcAnalysisDataTableQuery, SlottingAbcAnalysisDataTableQueryVariables>(SlottingAbcAnalysisDataTableDocument, options);
        }
export type SlottingAbcAnalysisDataTableQueryHookResult = ReturnType<typeof useSlottingAbcAnalysisDataTableQuery>;
export type SlottingAbcAnalysisDataTableLazyQueryHookResult = ReturnType<typeof useSlottingAbcAnalysisDataTableLazyQuery>;
export type SlottingAbcAnalysisDataTableQueryResult = Apollo.QueryResult<SlottingAbcAnalysisDataTableQuery, SlottingAbcAnalysisDataTableQueryVariables>;