/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ZoneBinsSettingsTableFragment = { __typename?: 'ViewZoneBinMapped', zoneId?: string | null, zoneCode?: string | null, zoneName?: string | null, zoneDescription?: string | null, warehouseId?: string | null, binId?: string | null, binCode?: string | null, binType?: Types.BinType | null, destinationBinBlock?: Types.BinBlockState | null, inactive?: Types.BinActiveState | null, level?: number | null, sourceBinBlock?: Types.BinBlockState | null, x?: number | null, y?: number | null, z?: number | null };

export type ZoneBinsSettingsTableQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.ViewZoneBinMappedFilter>;
  sorting?: Types.InputMaybe<Array<Types.ViewZoneBinMappedSort> | Types.ViewZoneBinMappedSort>;
  includePageInfo?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeNodes?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeTotalCount?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ZoneBinsSettingsTableQuery = { __typename?: 'Query', query: { __typename?: 'ViewZoneBinMappedOffsetConnection', totalCount?: number, pageInfo?: { __typename?: 'OffsetPageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null }, nodes?: Array<{ __typename?: 'ViewZoneBinMapped', zoneId?: string | null, zoneCode?: string | null, zoneName?: string | null, zoneDescription?: string | null, warehouseId?: string | null, binId?: string | null, binCode?: string | null, binType?: Types.BinType | null, destinationBinBlock?: Types.BinBlockState | null, inactive?: Types.BinActiveState | null, level?: number | null, sourceBinBlock?: Types.BinBlockState | null, x?: number | null, y?: number | null, z?: number | null }> } };

export const ZoneBinsSettingsTableFragmentDoc = gql`
    fragment ZoneBinsSettingsTable on ViewZoneBinMapped {
  zoneId
  zoneCode
  zoneName
  zoneDescription
  warehouseId
  binId
  binCode
  binType
  destinationBinBlock
  inactive
  level
  sourceBinBlock
  x
  y
  z
}
    `;
export const ZoneBinsSettingsTableDocument = gql`
    query ZoneBinsSettingsTable($paging: OffsetPaging, $filter: ViewZoneBinMappedFilter, $sorting: [ViewZoneBinMappedSort!], $includePageInfo: Boolean = true, $includeNodes: Boolean = true, $includeTotalCount: Boolean = false) {
  query: viewZoneBinsMapped(sorting: $sorting, paging: $paging, filter: $filter) {
    pageInfo @include(if: $includePageInfo) {
      hasNextPage
      hasPreviousPage
    }
    nodes @include(if: $includeNodes) {
      ...ZoneBinsSettingsTable
    }
    totalCount @include(if: $includeTotalCount)
  }
}
    ${ZoneBinsSettingsTableFragmentDoc}`;

/**
 * __useZoneBinsSettingsTableQuery__
 *
 * To run a query within a React component, call `useZoneBinsSettingsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoneBinsSettingsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoneBinsSettingsTableQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      includePageInfo: // value for 'includePageInfo'
 *      includeNodes: // value for 'includeNodes'
 *      includeTotalCount: // value for 'includeTotalCount'
 *   },
 * });
 */
export function useZoneBinsSettingsTableQuery(baseOptions?: Apollo.QueryHookOptions<ZoneBinsSettingsTableQuery, ZoneBinsSettingsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ZoneBinsSettingsTableQuery, ZoneBinsSettingsTableQueryVariables>(ZoneBinsSettingsTableDocument, options);
      }
export function useZoneBinsSettingsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZoneBinsSettingsTableQuery, ZoneBinsSettingsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ZoneBinsSettingsTableQuery, ZoneBinsSettingsTableQueryVariables>(ZoneBinsSettingsTableDocument, options);
        }
export type ZoneBinsSettingsTableQueryHookResult = ReturnType<typeof useZoneBinsSettingsTableQuery>;
export type ZoneBinsSettingsTableLazyQueryHookResult = ReturnType<typeof useZoneBinsSettingsTableLazyQuery>;
export type ZoneBinsSettingsTableQueryResult = Apollo.QueryResult<ZoneBinsSettingsTableQuery, ZoneBinsSettingsTableQueryVariables>;