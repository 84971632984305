import { Grid } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NumberFormat } from '@/graphql/types.generated';
import TextField from '@components/text-field';
import { CURRENCIES } from '@constants/currencies';
import { DATE_FORMATS, TIME_FORMATS } from '@constants/date-time-formats';
import { LANGUAGES } from '@constants/languages';
import { TIME_ZONES } from '@constants/time-zones';
import { useDateTime } from '@context/date-time';

export interface IFieldBlockDisplayPreferences {
  control: Control;
  focusOnLoad?: boolean;
  isUpdate?: boolean;
}
export const FieldBlockDisplayPreferences: React.FC<IFieldBlockDisplayPreferences> = ({
  control,
  focusOnLoad,
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'field-blocks.displayPreferences' });
  const { t: tC } = useTranslation('components');
  const { displayDate, displayTime } = useDateTime();

  return (
    <>
      <Grid item xs={6}>
        <Controller
          name="numberFormat"
          control={control}
          defaultValue={NumberFormat.WholeCommasFractionalPeriod}
          render={({ field }) => <input {...field} type="hidden" />}
        />
        <Controller
          name="currency"
          control={control}
          rules={{
            required: tC('form.validation.requiredField', { field: t('currency') }),
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              autoFocus={focusOnLoad}
              fullWidth
              required
              id="displayPref-currency"
              label={t('currency')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              select
              SelectProps={{ native: true }}
              dataTestId="displayPref-currency"
            >
              <option disabled />
              {Object.values(CURRENCIES).map((currency) => (
                <option key={currency.cc} value={currency.cc}>
                  {currency.name}
                </option>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="language"
          control={control}
          rules={{
            required: tC('form.validation.requiredField', { field: t('language') }),
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              required
              id="displayPref-language"
              label={t('language')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              select
              SelectProps={{ native: true }}
              dataTestId="displayPref-language"
            >
              <option disabled />
              {Object.values(LANGUAGES).map((language) => (
                <option key={language.code} value={language.code}>
                  {language.name}
                </option>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="dateFormat"
          control={control}
          rules={{
            required: tC('form.validation.requiredField', { field: t('dateFormat') }),
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              required
              select
              SelectProps={{
                native: true,
              }}
              id="displayPref-dateFormat"
              dataTestId="displayPref-dateFormat"
              label={t('dateFormat')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
            >
              <option disabled />
              {DATE_FORMATS.map((dateFormat, i) => (
                <option key={i} value={dateFormat}>
                  {/* Display the Option as a formatted date - February 1, 2022 */}
                  {/* Explicitly using UTC here as WH context doesn't matter, and the provided example date is UTC-0 */}
                  {displayDate({
                    date: '2022-02-01T00:00:00+0000',
                    format: dateFormat,
                    timezone: 'UTC-0',
                  })}
                </option>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="timeFormat"
          control={control}
          rules={{
            required: tC('form.validation.requiredField', { field: t('timeFormat') }),
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              required
              select
              SelectProps={{
                native: true,
              }}
              id="displayPref-timeFormat"
              dataTestId="displayPref-timeFormat"
              label={t('timeFormat')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
            >
              <option disabled />
              {TIME_FORMATS.map((timeFormat, i) => (
                <option key={i} value={timeFormat}>
                  {/* Explicitly using UTC here as WH context doesn't matter, and the provided example date/time is UTC-0 */}
                  {displayTime({
                    date: '2022-02-01T06:09:00+0000',
                    format: timeFormat,
                    timezone: 'UTC-0',
                    showTimezone: false,
                  })}
                  {' | '}
                  {displayTime({
                    date: '2022-02-01T18:09:00+0000',
                    format: timeFormat,
                    timezone: 'UTC-0',
                    showTimezone: false,
                  })}
                </option>
              ))}
            </TextField>
          )}
        />
      </Grid>
      <Grid item xs={4}>
        <Controller
          name="timezone"
          control={control}
          rules={{
            required: tC('form.validation.requiredField', { field: t('timezone') }),
          }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              fullWidth
              required
              id="displayPref-timezone"
              label={t('timezone')}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              select
              SelectProps={{ native: true }}
              dataTestId="displayPref-timezone"
            >
              <option disabled />
              {TIME_ZONES.map((zone) => (
                <option key={zone} value={zone}>
                  {zone}
                </option>
              ))}
            </TextField>
          )}
        />
      </Grid>
    </>
  );
};
