import DataTable from '@components/data-table';
import useInboundDeliveryItemsDataTable from '@components/data-table/hooks/table-props/useInboundDeliveryItemsDataTable';
import { ModalContent } from '@components/modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { IDeliveryItemsModal } from '@models/modal';

const DeliveryItemsModal = () => {
  const { modal } = useModalContent<IDeliveryItemsModal>();

  const { ibdDeliveryItemDataTableProps } = useInboundDeliveryItemsDataTable(
    modal.delivery.id,
    undefined,
    true,
  );

  return (
    <>
      <ModalContent>
        <DataTable {...ibdDeliveryItemDataTableProps} />
      </ModalContent>
    </>
  );
};

export default DeliveryItemsModal;
