import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useCompleteUnloadTaskMutation,
  useGetUnloadTaskDeliveryItemQuery,
} from '@/graphql/defs/components/modals/__generated__/unload-task-complete-modal.generated';
import { GetUnloadTaskDeliveryItem_defaultData } from '@/graphql/defs/components/modals/unload-task-complete-modal';
import { IColumn, JustifiedColumns } from '@components/justified-columns';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { IUnloadTaskCompleteModal } from '@models/modal';

const UnloadTaskCompleteModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();

  const { modal, closeModal, setLoading, setPreparing, isPreparing } =
    useModalContent<IUnloadTaskCompleteModal>();

  const [completeTask] = useCompleteUnloadTaskMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t('modal.delivery.unloadTaskComplete.success', {
          taskCode: modal?.task?.taskCode,
        }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({ type: 'error', message: error.message });
    },
  });

  const { data: deliveryItemData } = useGetUnloadTaskDeliveryItemQuery({
    variables: {
      deliveryItemId: modal?.task?.deliveryItemId,
    },
    onCompleted: () => {
      setPreparing(false);
    },
    onError: (error) => {
      showMessage({ type: 'error', message: error.message });
    },
  });
  const deliveryItem = useMemo(() => {
    const {
      viewDeliveryItems: { nodes },
    } = deliveryItemData || GetUnloadTaskDeliveryItem_defaultData;
    return nodes[0];
  }, [deliveryItemData]);

  // TODO: Will need to look into how Unload Tasks get their destBinId and make sure that matches up with the Unload Door Bin. As it does not currently.
  // const { called: doorStagingBinCalled, loading: isDoorStagingBinLoading } =
  //   useGetUnloadDoorStagingBinQuery({
  //     variables: {
  //       doorId: modal?.deliveryDetails?.door?.id,
  //     },
  //     onCompleted: ({ door }) => {
  //       void getDoorBin({ variables: { binId: door?.binId } });
  //     },
  //     onError: (error) => {
  //       showMessage({ type: 'error', message: error.message });
  //     },
  //   });

  // const [
  //   getDoorBin,
  //   { called: doorBinInfoCalled, loading: isDoorBinInfoLoading, data: doorBinInfoData },
  // ] = useGetUnloadDoorBinInfoLazyQuery({
  //   onError: (error) => {
  //     showMessage({ type: 'error', message: error.message });
  //   },
  // });
  // const { bin: doorBin } = useMemo(
  //   () => doorBinInfoData || GetUnloadDoorBinInfo_defaultData,
  //   [doorBinInfoData],
  // );

  const unloadTaskDetails = useMemo<IColumn[]>(
    () => [
      {
        labelWidth: '150px',
        lineItems: [
          {
            label: t('common.warehouse'),
            value: modal?.task?.warehouseCode,
            removeBold: true,
          },
          {
            label: t('common.delivery', { count: 1 }),
            value: modal?.task?.deliveryErpCode,
            removeBold: true,
          },
          modal?.task?.productId
            ? {
                label: t('common.product'),
                value: modal?.task?.productCode,
                boldLabel: true,
              }
            : null,
          modal?.task?.destinationLicensePlateId
            ? {
                label: t('common.licensePlateNumberSymbolFull'),
                value: modal?.task?.destinationLicensePlateCode,
                removeBold: true,
              }
            : null,

          {
            label: t('common.customer'),
            value: modal?.deliveryDetails?.supplierBusinessPartner?.name,
            removeBold: true,
          },
          {
            label: t('common.quantity'),
            value: modal?.task?.quantity,
            removeBold: true,
          },
        ].filter((value) => !!value),
      },
      {
        labelWidth: '150px',
        lineItems: [
          { emptyLine: true },
          {
            label: t('common.item'),
            value: modal?.task?.deliveryItem,
            removeBold: true,
          },
          modal?.task?.productId
            ? {
                label: t('common.description'),
                value: modal?.task?.productDescription,
                removeBold: true,
              }
            : null,
          modal?.task?.destinationLicensePlateId
            ? {
                label: t('common.licensePlateDescription'),
                value: modal?.task?.destinationLicensePlateDescription,
                removeBold: true,
              }
            : null,

          {
            label: t('common.stockStatus'),
            value: modal?.task?.sourceStatus,
            removeBold: true,
          },
          {
            label: t('common.unitOfMeasureAbbr'),
            value: modal?.task?.unitOfMeasure,
            removeBold: true,
          },
        ].filter((value) => !!value),
      },
    ],
    [deliveryItem],
  );

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = () => {
    void completeTask({
      variables: {
        input: {
          taskId: modal?.task?.id,
          destinationBinId: modal?.task?.destinationBinId,
          licensePlateId: modal?.task?.sourceLicensePlateId || null,
          completedInUnitOfMeasureId: modal?.task?.unitOfMeasureId || null,
          quantity: modal?.task?.quantity || null,
        },
      },
    });
  };

  return (
    !isPreparing && (
      <>
        <ModalContent>
          <JustifiedColumns columns={unloadTaskDetails} sx={{ marginBottom: '20px' }} />
          <Box display="flex">
            <Typography width="150px" fontWeight={700}>
              {t('common.destinationBin')}
              {': '}
            </Typography>
            <Typography>{modal?.task?.destinationBinCode}</Typography>
          </Box>
        </ModalContent>
        <ModalActions>
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton onClick={onSubmit} variant="contained" color="primary" actionType="submit">
            {t('common.completeTask')}
          </ModalButton>
        </ModalActions>
      </>
    )
  );
};

export default UnloadTaskCompleteModal;
