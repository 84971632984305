import { Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCreateSlottingRunMutation } from '@/graphql/defs/components/modals/__generated__/create-run-modal.generated';
import DataTable from '@components/data-table';
import useCreateRunFromRulesetDatasetsDataTable from '@components/data-table/hooks/table-props/useCreateRunFromRulesetDatasetsDataTable';
import { ModalButton } from '@components/modal';
import { ModalActions } from '@components/modal/modal-actions';
import { ModalContent } from '@components/modal/modal-content';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import FormValues from '@models/FormValues';
import { ICreateRunFromRulesetModal } from '@models/modal';

const CreateRunFromRuleset = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const formMethods = useForm<FormValues<{ name: string }>>({
    defaultValues: {
      name: '',
    },
  });

  const { handleSubmit, control } = formMethods;
  const { closeModal, modal, setLoading } = useModalContent<ICreateRunFromRulesetModal>();
  const { dataTableProps, selectedDataset } = useCreateRunFromRulesetDatasetsDataTable();
  const [createRun] = useCreateSlottingRunMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t('modal.run.createFromRuleset.success', {
          name: formMethods.getValues('name'),
        }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onSubmit = async (fields) => {
    createRun({
      variables: {
        input: {
          slottingRun: {
            datasetId: selectedDataset.id,
            name: fields.name,
            rulesetId: modal.ruleset.id,
          },
        },
      },
    });
  };

  return (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent sx={{ width: '98vw' }}>
        <Controller
          name="name"
          control={control}
          rules={{
            required: t('form.validation.requiredField', { field: t('common.name') }),
          }}
          render={({ field, fieldState }) => (
            <TextField
              sx={{ width: 300, marginBottom: '30px' }}
              required
              id="run-name"
              label={t('common.name')}
              {...field}
              ref={null}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              dataTestId="create-run-from-ruleset-name"
            />
          )}
        />
        <Box sx={{ maxWidth: '100%', border: '1px solid #D8E0E5', borderRadius: '4px' }}>
          <DataTable {...dataTableProps} />
        </Box>
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.back')}
        </ModalButton>
        <ModalButton
          isDisabled={!selectedDataset?.id}
          variant="contained"
          color="primary"
          actionType="submit"
        >
          {t('modal.run.performRun')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default CreateRunFromRuleset;
