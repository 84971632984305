/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AreaItemFragment = { __typename?: 'ViewArea', id?: string | null, code?: string | null, name?: string | null };

export type ListAreasQueryVariables = Types.Exact<{
  warehouseId: Types.Scalars['ID']['input'];
}>;


export type ListAreasQuery = { __typename?: 'Query', viewAreas: { __typename?: 'ViewAreaOffsetConnection', areas: Array<{ __typename?: 'ViewArea', id?: string | null, code?: string | null, name?: string | null }> } };

export const AreaItemFragmentDoc = gql`
    fragment AreaItem on ViewArea {
  id
  code
  name
}
    `;
export const ListAreasDocument = gql`
    query ListAreas($warehouseId: ID!) {
  viewAreas(
    paging: {offset: 0, limit: 1000000}
    filter: {warehouseId: {eq: $warehouseId}}
    sorting: [{field: code, direction: ASC}]
  ) {
    areas: nodes {
      ...AreaItem
    }
  }
}
    ${AreaItemFragmentDoc}`;

/**
 * __useListAreasQuery__
 *
 * To run a query within a React component, call `useListAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAreasQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useListAreasQuery(baseOptions: Apollo.QueryHookOptions<ListAreasQuery, ListAreasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAreasQuery, ListAreasQueryVariables>(ListAreasDocument, options);
      }
export function useListAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAreasQuery, ListAreasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAreasQuery, ListAreasQueryVariables>(ListAreasDocument, options);
        }
export type ListAreasQueryHookResult = ReturnType<typeof useListAreasQuery>;
export type ListAreasLazyQueryHookResult = ReturnType<typeof useListAreasLazyQuery>;
export type ListAreasQueryResult = Apollo.QueryResult<ListAreasQuery, ListAreasQueryVariables>;