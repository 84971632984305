import { gql } from '@apollo/client';

import { GetLoadFulfillmentItemQuery } from '@/graphql/defs/components/modals/__generated__/load-task-complete-modal.generated';

gql`
  fragment LoadFulfillmentItem on ViewFulfillmentItem {
    id
    item
    licensePlateId
    licensePlateCode
    licensePlateDescription
    productId
    productCode
    productDescription
    unitOfMeasure
  }

  query GetLoadFulfillmentItem($fulfillmentItemId: ID!) {
    viewFulfillmentItems(filter: { id: { eq: $fulfillmentItemId } }) {
      nodes {
        ...LoadFulfillmentItem
      }
    }
  }

  mutation CompleteLoadTask($warehouseId: ID!, $completeLoadTasks: [LoadTaskCompleteInput!]!) {
    completeManyLoadTasks(warehouseId: $warehouseId, completeLoadTasks: $completeLoadTasks) {
      id
      code
    }
  }
`;

export const GetLoadFulfillmentItem_defaultData: GetLoadFulfillmentItemQuery = {
  __typename: 'Query',
  viewFulfillmentItems: {
    __typename: 'ViewFulfillmentItemOffsetConnection',
    nodes: [],
  },
};
