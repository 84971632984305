import { Box, FormControlLabel, Radio, RadioGroup, Slider, TextField } from '@mui/material';
import { Control, Controller, UseFormClearErrors, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Section, StepWrapper } from '@components/modal/ruleset/steps';
import { IRuleset } from '@hooks/form/ruleset/useRulesetForm';
import FormValues from '@models/FormValues';

export const sliderMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 100,
    label: '100',
  },
];
enum Criteria {
  salesOrderLineItems = 'salesOrderLineItems',
  salesOrderQuantity = 'salesOrderQuantity',
}
const Step2 = ({
  active,
  control,
  clearErrors,
  review,
}: {
  active: boolean;
  review: boolean;
  control: Control<FormValues<IRuleset>, any>;
  clearErrors?: UseFormClearErrors<FormValues<IRuleset>>;
}) => {
  const { t } = useTranslation('components');
  const useAbcAnalysis = useWatch({ control, name: 'useAbcAnalysis' }) === 'use';
  const indicatorPercentages = useWatch({ control, name: 'abcAnalysis.indicatorPercentages' });
  const indicatorsSum = indicatorPercentages.reduce((acc, num) => acc + num);
  return (
    <StepWrapper active={active} review={review}>
      <Section section="abcAnalysis">
        <Controller
          name="useAbcAnalysis"
          control={control}
          render={({ field }) => {
            return (
              <RadioGroup
                {...field}
                name={'abcAnalysis'}
                onChange={({ target: { value } }) => {
                  if (value === 'skip' && !!clearErrors) {
                    clearErrors(['abcAnalysis.criteria', 'abcAnalysis.indicatorPercentages']);
                  }
                  field.onChange(value);
                }}
              >
                <FormControlLabel
                  value="use"
                  control={<Radio />}
                  label={t(`modal.ruleset.useAbcAnalysis`)}
                />
                <FormControlLabel
                  value="skip"
                  control={<Radio />}
                  label={t(`modal.ruleset.skipAbcAnalysis`)}
                />
              </RadioGroup>
            );
          }}
        />
        <Controller
          name="abcAnalysis.criteria"
          control={control}
          rules={{
            required: useAbcAnalysis
              ? t('form.validation.requiredField', { field: t('slotting.abcCriteria') })
              : false,
          }}
          render={({ field, fieldState }) => (
            <TextField
              sx={{ width: 400, margin: '30px 0px' }}
              select
              SelectProps={{
                native: true,
              }}
              id="ruleset-abc-analysis-criteria"
              label={t('slotting.abcCriteria')}
              {...field}
              ref={null}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
              disabled={!useAbcAnalysis}
            >
              <option disabled />
              {Object.keys(Criteria).map((criteria) => (
                <option key={`abc-criteria-${criteria}`} value={criteria}>
                  {t(`modal.ruleset.create.abcAnalysis.${criteria as Criteria}`)}
                </option>
              ))}
            </TextField>
          )}
        />
        <Box
          sx={{
            padding: '0px 0px 10px 0px',
            color: indicatorsSum !== 100 && useAbcAnalysis ? 'red' : '',
            display: 'flex',
          }}
        >
          Must equal 100% between AB&C Criteria{' '}
          <Box sx={{ marginLeft: '10px', fontWeight: 700 }}>
            {indicatorsSum !== 100 && useAbcAnalysis ? `(Currently ${indicatorsSum}%)` : ''}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          {[
            { key: 'A', value: 25 },
            { key: 'B', value: 50 },
            { key: 'C', value: 25 },
          ].map((indicator, index) => (
            <FormControlLabel
              sx={{ margin: '0px 0px 30px 0px' }}
              key={indicator.key}
              control={
                <Controller
                  name={`abcAnalysis.indicatorPercentages.${index}`}
                  control={control}
                  rules={{
                    validate: (z) => {
                      if (index === 0 && indicatorsSum !== 100) {
                        return t('slotting.abcIndicatorWarning');
                      }
                      return true;
                    },
                  }}
                  render={(props) => (
                    <Slider
                      disabled={!useAbcAnalysis}
                      sx={{ width: '170px' }}
                      marks={sliderMarks}
                      {...props.field}
                      valueLabelDisplay="auto"
                      max={100}
                      step={5}
                    />
                  )}
                />
              }
              labelPlacement="top"
              label={<Box sx={{ width: '150px', marginBottom: '15px' }}>{indicator.key}</Box>}
            />
          ))}
        </Box>
      </Section>
    </StepWrapper>
  );
};

export default Step2;
