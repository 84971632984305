/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TeamDetailsFragment = { __typename?: 'Team', id: string, name: string, description: string, warehouseId: string, users?: { __typename?: 'Users', nodes: Array<{ __typename?: 'User', id: string }> } | null };

export type PopulateTeamsSettingsPageQueryVariables = Types.Exact<{
  warehouseId: Types.Scalars['ID']['input'];
}>;


export type PopulateTeamsSettingsPageQuery = { __typename?: 'Query', getTeams: { __typename?: 'TeamOffsetConnection', teams: Array<{ __typename?: 'Team', id: string, name: string, description: string, warehouseId: string, users?: { __typename?: 'Users', nodes: Array<{ __typename?: 'User', id: string }> } | null }> } };

export const TeamDetailsFragmentDoc = gql`
    fragment TeamDetails on Team {
  id
  name
  description
  warehouseId
  users {
    nodes {
      id
    }
  }
}
    `;
export const PopulateTeamsSettingsPageDocument = gql`
    query PopulateTeamsSettingsPage($warehouseId: ID!) {
  getTeams: teams(
    filter: {warehouseId: {eq: $warehouseId}}
    sorting: {field: name, direction: ASC}
  ) {
    teams: nodes {
      ...TeamDetails
    }
  }
}
    ${TeamDetailsFragmentDoc}`;

/**
 * __usePopulateTeamsSettingsPageQuery__
 *
 * To run a query within a React component, call `usePopulateTeamsSettingsPageQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopulateTeamsSettingsPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopulateTeamsSettingsPageQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function usePopulateTeamsSettingsPageQuery(baseOptions: Apollo.QueryHookOptions<PopulateTeamsSettingsPageQuery, PopulateTeamsSettingsPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PopulateTeamsSettingsPageQuery, PopulateTeamsSettingsPageQueryVariables>(PopulateTeamsSettingsPageDocument, options);
      }
export function usePopulateTeamsSettingsPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PopulateTeamsSettingsPageQuery, PopulateTeamsSettingsPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PopulateTeamsSettingsPageQuery, PopulateTeamsSettingsPageQueryVariables>(PopulateTeamsSettingsPageDocument, options);
        }
export type PopulateTeamsSettingsPageQueryHookResult = ReturnType<typeof usePopulateTeamsSettingsPageQuery>;
export type PopulateTeamsSettingsPageLazyQueryHookResult = ReturnType<typeof usePopulateTeamsSettingsPageLazyQuery>;
export type PopulateTeamsSettingsPageQueryResult = Apollo.QueryResult<PopulateTeamsSettingsPageQuery, PopulateTeamsSettingsPageQueryVariables>;