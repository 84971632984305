import { Box, Paper, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { useCancelDeliveryItemsMutation } from '@/graphql/defs/components/modals/__generated__/cancel-delivery-items-modal.generated';
import { CancelManyDeliveryItemsDto } from '@/graphql/types.generated';
import DataTable from '@components/data-table';
import useCreateInboundDeliveryItemColumns from '@components/data-table/hooks/shared-columns/useCreateInboundDeliveryItemColumns';
import useCreateDataTableColumns from '@components/data-table/hooks/useCreateDataTableColumns';
import TableIds from '@components/data-table/model/TableIds';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { getTransComponents } from '@lib/translation';
import { ICancelDeliveryItemsModal } from '@models/modal';

const CancelDeliveryItemsModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal, setLoading } = useModalContent<ICancelDeliveryItemsModal>();

  const createInboundDeliveryItemColumns = useCreateInboundDeliveryItemColumns({
    dataTestId: 'cancel-delivery-items',
    removeColumns: ['putawayStatus', 'unloadStatus'],
    removeDeepLinks: true,
  });
  const columns = useCreateDataTableColumns(createInboundDeliveryItemColumns);

  const [cancelDeliveryItems] = useCancelDeliveryItemsMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t('modal.cancelDeliveryItems.success', {
          count: modal.deliveryItems.length,
        }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: t('modal.cancelDeliveryItems.error', {
          count: modal.deliveryItems.length,
          errorMessage: error.message,
        }),
      });
    },
  });

  const handleSubmit = () => {
    cancelDeliveryItems({
      variables: {
        deliveryItemIds: modal.deliveryItems.map<CancelManyDeliveryItemsDto>((item) => ({
          deliveryItemId: item.id,
        })),
      },
    });
  };

  return (
    <>
      <ModalContent>
        <Box display="flex" flexDirection="column" gap={3}>
          <Typography>
            {t('modal.cancelDeliveryItems.textAdvise', { count: modal.deliveryItems.length })}
          </Typography>
          <Paper elevation={2}>
            <DataTable
              type="data"
              tableId={TableIds.CancelDeliveryItems}
              columns={columns}
              data={modal.deliveryItems}
              isDataLoading={false}
            />
          </Paper>
          <Typography>
            <Trans
              ns="components"
              i18nKey="modal.cancelDeliveryItems.textConfirm"
              components={getTransComponents(['bold', 'break'])}
              count={modal.deliveryItems.length}
            />
          </Typography>
        </Box>
      </ModalContent>
      <ModalActions>
        <ModalButton
          variant="outlined"
          color="primary"
          onClick={() => closeModal()}
          actionType="cancel"
        >
          {t('modal.cancelDeliveryItems.goBack')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" onClick={handleSubmit} actionType="submit">
          {t('modal.cancelDeliveryItems.cancelItem', { count: modal.deliveryItems.length })}
        </ModalButton>
      </ModalActions>
    </>
  );
};

export default CancelDeliveryItemsModal;
