import { decode } from 'html-entities';

const entityRegExp = /(&[a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});/gi;
const handleContent = (match, content) => {
  return decode(content);
};

const decodeHtmlEntities = (string: string) => string.replace(entityRegExp, handleContent);

export default decodeHtmlEntities;
