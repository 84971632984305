import { ThemeProvider } from '@mui/material/styles';
import React from 'react';

import theme from '../theme';

// import AuthProvider from './auth';
import FirebaseProvider from './auth';
import ShortcutProvider from './shortcut';
import SnackbarProvider from './snackbar';

import AppLoadingToggleProvider from '@context/app-loading/AppLoadingToggleProvider';
import FeatureFlagsProvider from '@context/feature-flags';

const AppProviders: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <AppLoadingToggleProvider>
      <ShortcutProvider>
        <SnackbarProvider>
          <FeatureFlagsProvider>
            <FirebaseProvider>
              {children}
              {/* <AuthProvider>{children}</AuthProvider> */}
            </FirebaseProvider>
          </FeatureFlagsProvider>
        </SnackbarProvider>
      </ShortcutProvider>
    </AppLoadingToggleProvider>
  </ThemeProvider>
);

export default AppProviders;
