/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { BaseAreaFragmentDoc } from './area.generated';
import { BaseWarehouseFragmentDoc } from './warehouse.generated';
export type BaseBinFragment = { __typename?: 'Bin', id: string, code: string, warehouseId: string, binStorageTypeId: string, x?: number | null, y?: number | null, areaId?: string | null, aisleId?: string | null, aisleColumnId?: string | null, level?: number | null, putawayBlock?: boolean | null, removalBlock?: boolean | null, verificationCode?: string | null, lastMovement?: string | null, lastCount?: string | null };

export type FullBinFragment = { __typename?: 'Bin', id: string, code: string, warehouseId: string, binStorageTypeId: string, x?: number | null, y?: number | null, areaId?: string | null, aisleId?: string | null, aisleColumnId?: string | null, level?: number | null, putawayBlock?: boolean | null, removalBlock?: boolean | null, verificationCode?: string | null, lastMovement?: string | null, lastCount?: string | null, area?: { __typename?: 'Area', id: string, name?: string | null, code: string, description?: string | null, warehouseId?: string | null, type?: Types.AreaType | null, status?: Types.AreaStatus | null, sapStorageLocationPlant: { __typename?: 'SapStorageLocationPlant', id: string, storageLocation: string } } | null, warehouse?: { __typename?: 'Warehouse', id: string, code: string, name?: string | null } | null };

export const BaseBinFragmentDoc = gql`
    fragment BaseBin on Bin {
  id
  code
  warehouseId
  binStorageTypeId
  x
  y
  areaId
  aisleId
  aisleColumnId
  level
  putawayBlock
  removalBlock
  verificationCode
  lastMovement
  lastCount
}
    `;
export const FullBinFragmentDoc = gql`
    fragment FullBin on Bin {
  ...BaseBin
  area {
    ...BaseArea
  }
  warehouse {
    ...BaseWarehouse
  }
}
    ${BaseBinFragmentDoc}
${BaseAreaFragmentDoc}
${BaseWarehouseFragmentDoc}`;