import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  PutawayBinFragment,
  useCompletePutawayTaskMutation,
  useDestinationBinsForPutawayQuery,
} from '@/graphql/defs/components/modals/__generated__/putaway-task-complete-modal.generated';
import { SortDirection, TaskTypeBinStatusBinsQueryDtoSortFields } from '@/graphql/types.generated';
import UoMSelection from '@components/form-util/uom-selection';
import UoMTiedQuantity from '@components/form-util/uom-tied-quantity';
import { JustifiedColumns } from '@components/justified-columns';
import { ModalActions, ModalContent } from '@components/modal';
import { ModalButton } from '@components/modal/modal-button';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import VirtualAutocomplete from '@components/virtual-autocomplete';
import { TaskTypeCodes } from '@constants/task-type-config';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import FormValues from '@models/FormValues';
import { IPutawayTaskCompleteModal } from '@models/modal';

export const PutawayTaskCompleteModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, setLoading, isPreparing, setPreparing } =
    useModalContent<IPutawayTaskCompleteModal>();

  const [completePutawayTask] = useCompletePutawayTaskMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: `${t('modal.task.completePutawayTaskSuccessMessage')}`,
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: () => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: `${t('modal.task.completePutawayTaskErrorMessage')}`,
      });
    },
  });

  interface IPutawayCompleteForm {
    quantity: string;
    completedUoMId: string;
    destinationBin: PutawayBinFragment;
  }
  const formMethods = useForm<FormValues<IPutawayCompleteForm>>();
  const { control, handleSubmit, setValue } = formMethods;

  const quantityFieldName = 'quantity';
  const completedUoMFieldName = 'completedUoMId';

  const { selectedWarehouseId } = useEntityUtils();
  const { showMessage } = useSnackbar();

  const [binList, setBinList] = useState<PutawayBinFragment[]>([]);
  useDestinationBinsForPutawayQuery({
    variables: {
      taskTypeCode: TaskTypeCodes.deliveryPutawayTask,
      filter: { warehouseId: { eq: selectedWarehouseId } },
      sorting: {
        field: TaskTypeBinStatusBinsQueryDtoSortFields.Code,
        direction: SortDirection.Asc,
      },
    },
    onCompleted: ({ destinationBinsForTaskType: { destinationBins } }) => {
      setBinList(destinationBins);
      setPreparing(false);
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: error.message,
      });
      setPreparing(false);
    },
  });

  const onSubmit = (data: IPutawayCompleteForm) => {
    void completePutawayTask({
      variables: {
        input: {
          id: modal.task?.id,
          update: {
            destinationBinId: data.destinationBin?.id,
            quantity: data.quantity,
            completedInUnitOfMeasureId: data.completedUoMId,
          },
        },
      },
    });
  };

  const detailsColumns = [
    {
      labelWidth: '125px',
      lineItems: [
        !modal.task?.sourceLicensePlateId && {
          label: t('common.product'),
          value: modal.task?.productCode,
          removeBold: true,
        },
        !modal.task?.sourceLicensePlateId && {
          label: t('common.description'),
          value: modal.task?.productDescription,
          removeBold: true,
        },
        {
          label: t('common.warehouse'),
          value: modal.task?.warehouseName,
          removeBold: true,
        },
        {
          label: t('common.delivery', { count: 1 }),
          value: modal.task?.deliveryErpCode,
          removeBold: true,
        },
        {
          label: t('common.licensePlate'),
          value: modal.task?.sourceLicensePlateCode,
          removeBold: true,
        },
        !modal.task?.sourceLicensePlateId && {
          label: t('common.item'),
          value: modal.task?.deliveryItem,
          removeBold: true,
        },
      ],
    },
    !modal.task?.sourceLicensePlateId && {
      labelWidth: '155px',
      lineItems: [
        {
          label: t('common.lot'),
          value: modal.task?.sourceLotCode,
          removeBold: true,
        },
        {
          label: t('common.stockStatus'),
          value: modal.task?.sourceStatus,
          removeBold: true,
        },
        {
          label: t('common.quantity'),
          value: modal.task?.quantity,
          removeBold: true,
        },
        {
          label: t('common.uom'),
          value: modal.task?.unitOfMeasure,
          removeBold: true,
        },
      ],
    },
  ];

  useEffect(() => {
    const _defaultBin =
      modal?.task?.destinationBinId && binList
        ? binList.find((_bin) => _bin.id === modal?.task?.destinationBinId) || null
        : null;
    if (_defaultBin) {
      setValue('destinationBin', _defaultBin);
    }
  }, [modal?.task?.destinationBinId, binList]);

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent>
          <JustifiedColumns columns={detailsColumns} mb="5px" />
          <Box mb={10} />
          <Box>
            <Grid container spacing={6}>
              <Grid item xs={6}>
                <Controller
                  name="destinationBin"
                  control={control}
                  rules={{
                    required: t('form.validation.requiredField', {
                      field: t('common.destinationBin'),
                    }),
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <VirtualAutocomplete
                        freeSolo={false}
                        multiple={false}
                        autoHighlight={true}
                        data-testid="destinationBin"
                        isOptionEqualToValue={(option, value) =>
                          option !== '' && value !== '' && option.id === value.id
                        }
                        getOptionLabel={(option) => (option !== '' ? option.code : '')}
                        options={binList}
                        onChange={(event, item, reason) => {
                          if (reason === 'selectOption' || reason === 'clear') {
                            field.onChange(item);
                          }
                        }}
                        defaultValue={field.value}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              {...field}
                              required
                              label={t('common.destinationBin')}
                              error={!!fieldState?.error}
                              helperText={fieldState?.error?.message}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              dataTestId="destinationBin"
                            />
                          );
                        }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6} />
              {!modal.task?.sourceLicensePlateId && (
                <FormProvider {...formMethods}>
                  <Grid item xs={6}>
                    <UoMTiedQuantity
                      fieldName={quantityFieldName}
                      fieldLabel={t('common.quantity')}
                      uomFieldName={completedUoMFieldName}
                      defaultQuantity={modal.task?.quantity}
                      availableQuantity={modal.task?.quantity}
                      defaultUomId={modal.task?.unitOfMeasureId}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <UoMSelection
                      fieldName={completedUoMFieldName}
                      fieldLabel={t('common.completedUoM')}
                      defaultUoMId={modal.task?.unitOfMeasureId}
                      productId={modal.task?.productId}
                    />
                  </Grid>
                </FormProvider>
              )}
            </Grid>
          </Box>
        </ModalContent>
        <ModalActions>
          <ModalButton
            variant="outlined"
            color="primary"
            onClick={() => closeModal()}
            actionType="cancel"
          >
            {t('common.cancel')}
          </ModalButton>
          <ModalButton variant="contained" color="primary" actionType="submit">
            {t('modal.task.completeTask')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};
