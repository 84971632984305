/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RunDeployMovementsRunDetailsFragment = { __typename?: 'SlottingRun', id?: string | null, name: string, dataset_id: string, ruleset_id: string, created_at?: string | null, deployed_at?: string | null, deployed_by_user_name?: string | null };

export type GetRunDeployMovementsRunDetailsQueryVariables = Types.Exact<{
  runId: Types.Scalars['String']['input'];
}>;


export type GetRunDeployMovementsRunDetailsQuery = { __typename?: 'Query', proposedRunDetails: { __typename?: 'SlottingRun', id?: string | null, name: string, dataset_id: string, ruleset_id: string, created_at?: string | null, deployed_at?: string | null, deployed_by_user_name?: string | null }, deployedRunDetails: { __typename?: 'SlottingRunOffsetConnection', nodes: Array<{ __typename?: 'SlottingRun', id?: string | null, name: string, dataset_id: string, ruleset_id: string, created_at?: string | null, deployed_at?: string | null, deployed_by_user_name?: string | null }> } };

export type RunDeploymentMovementsDatasetFragment = { __typename?: 'SlottingDataset', id: string, code: string, tag?: string | null, pullDate: string, createdByUserName: string, dateRangeStart: string, dateRangeEnd: string };

export type RunDeploymentMovementsRulesetFragment = { __typename?: 'SlottingRuleset', id: string, name: string };

export type GetRunDeploymentMovementsDatasetsAndRulesetsQueryVariables = Types.Exact<{
  datasetIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
  rulesetIds?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>;
}>;


export type GetRunDeploymentMovementsDatasetsAndRulesetsQuery = { __typename?: 'Query', slottingDatasets: { __typename?: 'SlottingDatasetOffsetConnection', nodes: Array<{ __typename?: 'SlottingDataset', id: string, code: string, tag?: string | null, pullDate: string, createdByUserName: string, dateRangeStart: string, dateRangeEnd: string }> }, slottingRulesets: { __typename?: 'SlottingRulesetOffsetConnection', nodes: Array<{ __typename?: 'SlottingRuleset', id: string, name: string }> } };

export type DeployRunMovementsMutationVariables = Types.Exact<{
  runId: Types.Scalars['ID']['input'];
  abcAnalysisIds: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
  movementsIds: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type DeployRunMovementsMutation = { __typename?: 'Mutation', deploySlottingRun: string };

export const RunDeployMovementsRunDetailsFragmentDoc = gql`
    fragment RunDeployMovementsRunDetails on SlottingRun {
  id
  name
  dataset_id
  ruleset_id
  created_at
  deployed_at
  deployed_by_user_name
}
    `;
export const RunDeploymentMovementsDatasetFragmentDoc = gql`
    fragment RunDeploymentMovementsDataset on SlottingDataset {
  id
  code
  tag
  pullDate
  createdByUserName
  dateRangeStart
  dateRangeEnd
}
    `;
export const RunDeploymentMovementsRulesetFragmentDoc = gql`
    fragment RunDeploymentMovementsRuleset on SlottingRuleset {
  id
  name
}
    `;
export const GetRunDeployMovementsRunDetailsDocument = gql`
    query GetRunDeployMovementsRunDetails($runId: String!) {
  proposedRunDetails: slottingRun(runId: $runId) {
    ...RunDeployMovementsRunDetails
  }
  deployedRunDetails: slottingRuns(
    paging: {offset: 0, limit: 1}
    filter: {deployed_at: {isNot: null}, deployment_ended_at: {is: null}}
  ) {
    nodes {
      ...RunDeployMovementsRunDetails
    }
  }
}
    ${RunDeployMovementsRunDetailsFragmentDoc}`;

/**
 * __useGetRunDeployMovementsRunDetailsQuery__
 *
 * To run a query within a React component, call `useGetRunDeployMovementsRunDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunDeployMovementsRunDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunDeployMovementsRunDetailsQuery({
 *   variables: {
 *      runId: // value for 'runId'
 *   },
 * });
 */
export function useGetRunDeployMovementsRunDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetRunDeployMovementsRunDetailsQuery, GetRunDeployMovementsRunDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRunDeployMovementsRunDetailsQuery, GetRunDeployMovementsRunDetailsQueryVariables>(GetRunDeployMovementsRunDetailsDocument, options);
      }
export function useGetRunDeployMovementsRunDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRunDeployMovementsRunDetailsQuery, GetRunDeployMovementsRunDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRunDeployMovementsRunDetailsQuery, GetRunDeployMovementsRunDetailsQueryVariables>(GetRunDeployMovementsRunDetailsDocument, options);
        }
export type GetRunDeployMovementsRunDetailsQueryHookResult = ReturnType<typeof useGetRunDeployMovementsRunDetailsQuery>;
export type GetRunDeployMovementsRunDetailsLazyQueryHookResult = ReturnType<typeof useGetRunDeployMovementsRunDetailsLazyQuery>;
export type GetRunDeployMovementsRunDetailsQueryResult = Apollo.QueryResult<GetRunDeployMovementsRunDetailsQuery, GetRunDeployMovementsRunDetailsQueryVariables>;
export const GetRunDeploymentMovementsDatasetsAndRulesetsDocument = gql`
    query GetRunDeploymentMovementsDatasetsAndRulesets($datasetIds: [ID!], $rulesetIds: [ID!]) {
  slottingDatasets(filter: {id: {in: $datasetIds}}) {
    nodes {
      ...RunDeploymentMovementsDataset
    }
  }
  slottingRulesets(filter: {id: {in: $rulesetIds}}) {
    nodes {
      ...RunDeploymentMovementsRuleset
    }
  }
}
    ${RunDeploymentMovementsDatasetFragmentDoc}
${RunDeploymentMovementsRulesetFragmentDoc}`;

/**
 * __useGetRunDeploymentMovementsDatasetsAndRulesetsQuery__
 *
 * To run a query within a React component, call `useGetRunDeploymentMovementsDatasetsAndRulesetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRunDeploymentMovementsDatasetsAndRulesetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRunDeploymentMovementsDatasetsAndRulesetsQuery({
 *   variables: {
 *      datasetIds: // value for 'datasetIds'
 *      rulesetIds: // value for 'rulesetIds'
 *   },
 * });
 */
export function useGetRunDeploymentMovementsDatasetsAndRulesetsQuery(baseOptions?: Apollo.QueryHookOptions<GetRunDeploymentMovementsDatasetsAndRulesetsQuery, GetRunDeploymentMovementsDatasetsAndRulesetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRunDeploymentMovementsDatasetsAndRulesetsQuery, GetRunDeploymentMovementsDatasetsAndRulesetsQueryVariables>(GetRunDeploymentMovementsDatasetsAndRulesetsDocument, options);
      }
export function useGetRunDeploymentMovementsDatasetsAndRulesetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRunDeploymentMovementsDatasetsAndRulesetsQuery, GetRunDeploymentMovementsDatasetsAndRulesetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRunDeploymentMovementsDatasetsAndRulesetsQuery, GetRunDeploymentMovementsDatasetsAndRulesetsQueryVariables>(GetRunDeploymentMovementsDatasetsAndRulesetsDocument, options);
        }
export type GetRunDeploymentMovementsDatasetsAndRulesetsQueryHookResult = ReturnType<typeof useGetRunDeploymentMovementsDatasetsAndRulesetsQuery>;
export type GetRunDeploymentMovementsDatasetsAndRulesetsLazyQueryHookResult = ReturnType<typeof useGetRunDeploymentMovementsDatasetsAndRulesetsLazyQuery>;
export type GetRunDeploymentMovementsDatasetsAndRulesetsQueryResult = Apollo.QueryResult<GetRunDeploymentMovementsDatasetsAndRulesetsQuery, GetRunDeploymentMovementsDatasetsAndRulesetsQueryVariables>;
export const DeployRunMovementsDocument = gql`
    mutation DeployRunMovements($runId: ID!, $abcAnalysisIds: [String!]!, $movementsIds: [String!]!) {
  deploySlottingRun(
    input: {runId: $runId, abcAnalysisIds: $abcAnalysisIds, movementIds: $movementsIds}
  )
}
    `;
export type DeployRunMovementsMutationFn = Apollo.MutationFunction<DeployRunMovementsMutation, DeployRunMovementsMutationVariables>;

/**
 * __useDeployRunMovementsMutation__
 *
 * To run a mutation, you first call `useDeployRunMovementsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployRunMovementsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployRunMovementsMutation, { data, loading, error }] = useDeployRunMovementsMutation({
 *   variables: {
 *      runId: // value for 'runId'
 *      abcAnalysisIds: // value for 'abcAnalysisIds'
 *      movementsIds: // value for 'movementsIds'
 *   },
 * });
 */
export function useDeployRunMovementsMutation(baseOptions?: Apollo.MutationHookOptions<DeployRunMovementsMutation, DeployRunMovementsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeployRunMovementsMutation, DeployRunMovementsMutationVariables>(DeployRunMovementsDocument, options);
      }
export type DeployRunMovementsMutationHookResult = ReturnType<typeof useDeployRunMovementsMutation>;
export type DeployRunMovementsMutationResult = Apollo.MutationResult<DeployRunMovementsMutation>;
export type DeployRunMovementsMutationOptions = Apollo.BaseMutationOptions<DeployRunMovementsMutation, DeployRunMovementsMutationVariables>;