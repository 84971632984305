/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListedSapStockStatusTypeFragment = { __typename?: 'SapStockStatusType', id: string, code: string, label: string };

export type ListAllSapStockStatusTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListAllSapStockStatusTypesQuery = { __typename?: 'Query', listAllSapStockStatusTypes: { __typename?: 'SapStockStatusTypeOffsetConnection', sapStockStatusTypes: Array<{ __typename?: 'SapStockStatusType', id: string, code: string, label: string }> } };

export const ListedSapStockStatusTypeFragmentDoc = gql`
    fragment ListedSapStockStatusType on SapStockStatusType {
  id
  code
  label
}
    `;
export const ListAllSapStockStatusTypesDocument = gql`
    query ListAllSapStockStatusTypes {
  listAllSapStockStatusTypes: sapStockStatusTypes(
    paging: {offset: 0, limit: 1000000}
    sorting: [{field: label, direction: ASC}]
  ) {
    sapStockStatusTypes: nodes {
      ...ListedSapStockStatusType
    }
  }
}
    ${ListedSapStockStatusTypeFragmentDoc}`;

/**
 * __useListAllSapStockStatusTypesQuery__
 *
 * To run a query within a React component, call `useListAllSapStockStatusTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllSapStockStatusTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllSapStockStatusTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAllSapStockStatusTypesQuery(baseOptions?: Apollo.QueryHookOptions<ListAllSapStockStatusTypesQuery, ListAllSapStockStatusTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllSapStockStatusTypesQuery, ListAllSapStockStatusTypesQueryVariables>(ListAllSapStockStatusTypesDocument, options);
      }
export function useListAllSapStockStatusTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllSapStockStatusTypesQuery, ListAllSapStockStatusTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllSapStockStatusTypesQuery, ListAllSapStockStatusTypesQueryVariables>(ListAllSapStockStatusTypesDocument, options);
        }
export type ListAllSapStockStatusTypesQueryHookResult = ReturnType<typeof useListAllSapStockStatusTypesQuery>;
export type ListAllSapStockStatusTypesLazyQueryHookResult = ReturnType<typeof useListAllSapStockStatusTypesLazyQuery>;
export type ListAllSapStockStatusTypesQueryResult = Apollo.QueryResult<ListAllSapStockStatusTypesQuery, ListAllSapStockStatusTypesQueryVariables>;