/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductUoMFragment = { __typename?: 'ViewUnitOfMeasureProductConversion', id?: string | null, productId?: string | null, code?: string | null, label?: string | null, description?: string | null, conversionFactor?: number | null, height?: number | null, length?: number | null, width?: number | null, dimensionUOMId?: string | null, dimensionUOMCode?: string | null, dimensionUOMLabel?: string | null, grossWeight?: number | null, weightUOMId?: string | null, weightUOMCode?: string | null, weightUOMLabel?: string | null, volume?: number | null, volumeUOMId?: string | null, volumeUOMCode?: string | null, volumeUOMLabel?: string | null, isBaseUom?: boolean | null };

export type ProductUoMTableQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ViewUnitOfMeasureProductConversionFilter>;
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  sorting?: Types.InputMaybe<Array<Types.ViewUnitOfMeasureProductConversionSort> | Types.ViewUnitOfMeasureProductConversionSort>;
  includePageInfo?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeNodes?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeTotalCount?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ProductUoMTableQuery = { __typename?: 'Query', query: { __typename?: 'ViewUnitOfMeasureProductConversionOffsetConnection', totalCount?: number, pageInfo?: { __typename?: 'OffsetPageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null }, nodes?: Array<{ __typename?: 'ViewUnitOfMeasureProductConversion', id?: string | null, productId?: string | null, code?: string | null, label?: string | null, description?: string | null, conversionFactor?: number | null, height?: number | null, length?: number | null, width?: number | null, dimensionUOMId?: string | null, dimensionUOMCode?: string | null, dimensionUOMLabel?: string | null, grossWeight?: number | null, weightUOMId?: string | null, weightUOMCode?: string | null, weightUOMLabel?: string | null, volume?: number | null, volumeUOMId?: string | null, volumeUOMCode?: string | null, volumeUOMLabel?: string | null, isBaseUom?: boolean | null }> } };

export const ProductUoMFragmentDoc = gql`
    fragment ProductUoM on ViewUnitOfMeasureProductConversion {
  id
  productId
  code
  label
  description
  conversionFactor
  height
  length
  width
  dimensionUOMId
  dimensionUOMCode
  dimensionUOMLabel
  grossWeight
  weightUOMId
  weightUOMCode
  weightUOMLabel
  volume
  volumeUOMId
  volumeUOMCode
  volumeUOMLabel
  isBaseUom
}
    `;
export const ProductUoMTableDocument = gql`
    query ProductUoMTable($filter: ViewUnitOfMeasureProductConversionFilter, $paging: OffsetPaging, $sorting: [ViewUnitOfMeasureProductConversionSort!], $includePageInfo: Boolean = true, $includeNodes: Boolean = true, $includeTotalCount: Boolean = false) {
  query: viewUnitOfMeasureProductConversions(
    paging: $paging
    filter: $filter
    sorting: $sorting
  ) {
    pageInfo @include(if: $includePageInfo) {
      hasNextPage
      hasPreviousPage
    }
    nodes @include(if: $includeNodes) {
      ...ProductUoM
    }
    totalCount @include(if: $includeTotalCount)
  }
}
    ${ProductUoMFragmentDoc}`;

/**
 * __useProductUoMTableQuery__
 *
 * To run a query within a React component, call `useProductUoMTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductUoMTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductUoMTableQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      includePageInfo: // value for 'includePageInfo'
 *      includeNodes: // value for 'includeNodes'
 *      includeTotalCount: // value for 'includeTotalCount'
 *   },
 * });
 */
export function useProductUoMTableQuery(baseOptions?: Apollo.QueryHookOptions<ProductUoMTableQuery, ProductUoMTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductUoMTableQuery, ProductUoMTableQueryVariables>(ProductUoMTableDocument, options);
      }
export function useProductUoMTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductUoMTableQuery, ProductUoMTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductUoMTableQuery, ProductUoMTableQueryVariables>(ProductUoMTableDocument, options);
        }
export type ProductUoMTableQueryHookResult = ReturnType<typeof useProductUoMTableQuery>;
export type ProductUoMTableLazyQueryHookResult = ReturnType<typeof useProductUoMTableLazyQuery>;
export type ProductUoMTableQueryResult = Apollo.QueryResult<ProductUoMTableQuery, ProductUoMTableQueryVariables>;