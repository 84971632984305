import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useShortShipFulfillmentMutation } from '@/graphql/defs/components/modals/__generated__/short-ship-modal.generated';
import { ModalActions, ModalContent } from '@components/modal';
import { ModalButton } from '@components/modal/modal-button';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { IShortShipModal } from '@models/modal';

export const ShortShipModal = () => {
  const { t } = useTranslation('components');
  const { modal, setLoading, closeModal } = useModalContent<IShortShipModal>();

  const { showMessage } = useSnackbar();

  const [shortShip] = useShortShipFulfillmentMutation({
    onCompleted: async () => {
      showMessage({
        type: 'success',
        message: t('modal.shortShip.success'),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({ type: 'error', message: error.message });
    },
  });

  const handleSubmit = async () => {
    shortShip({ variables: { fulfillmentId: modal.id } });
  };

  return (
    <>
      <ModalContent>
        <Box
          sx={{ width: 650, fontSize: 18, paddingBottom: 40, whiteSpace: 'pre-line' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {t('modal.delivery.shortShipNotice', { code: modal.code })}
        </Box>
      </ModalContent>
      <ModalActions>
        <ModalButton
          variant="outlined"
          color="primary"
          onClick={() => closeModal()}
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" onClick={handleSubmit} actionType="submit">
          {t('common.confirm')}
        </ModalButton>
      </ModalActions>
    </>
  );
};
