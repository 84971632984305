/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateOneFulfillmentBlockMutationVariables = Types.Exact<{
  input: Types.CreateOneFulfillmentBlockInput;
}>;


export type CreateOneFulfillmentBlockMutation = { __typename?: 'Mutation', createOneFulfillmentBlock: { __typename?: 'FulfillmentBlock', label: string, id: string } };


export const CreateOneFulfillmentBlockDocument = gql`
    mutation CreateOneFulfillmentBlock($input: CreateOneFulfillmentBlockInput!) {
  createOneFulfillmentBlock(input: $input) {
    label
    id
  }
}
    `;
export type CreateOneFulfillmentBlockMutationFn = Apollo.MutationFunction<CreateOneFulfillmentBlockMutation, CreateOneFulfillmentBlockMutationVariables>;

/**
 * __useCreateOneFulfillmentBlockMutation__
 *
 * To run a mutation, you first call `useCreateOneFulfillmentBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneFulfillmentBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneFulfillmentBlockMutation, { data, loading, error }] = useCreateOneFulfillmentBlockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOneFulfillmentBlockMutation(baseOptions?: Apollo.MutationHookOptions<CreateOneFulfillmentBlockMutation, CreateOneFulfillmentBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOneFulfillmentBlockMutation, CreateOneFulfillmentBlockMutationVariables>(CreateOneFulfillmentBlockDocument, options);
      }
export type CreateOneFulfillmentBlockMutationHookResult = ReturnType<typeof useCreateOneFulfillmentBlockMutation>;
export type CreateOneFulfillmentBlockMutationResult = Apollo.MutationResult<CreateOneFulfillmentBlockMutation>;
export type CreateOneFulfillmentBlockMutationOptions = Apollo.BaseMutationOptions<CreateOneFulfillmentBlockMutation, CreateOneFulfillmentBlockMutationVariables>;