import { Tooltip } from '@mui/material';
import { useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  UoMConversionsFragment,
  useGetProductUoMConversionsQuery,
} from '@/graphql/defs/shared-queries/__generated__/get-product-uom-conversions.generated';
import { EditableCell } from '@components/editable-table/EditableTable';
import { useFormValidation } from '@hooks/form/validators';
import { allowOnlyNumeric } from '@lib/form';
import InputBase from '@styled/InputBase';

interface IEditableQty extends EditableCell {
  item: Record<string, any> & {
    productId?: string;
    availableQuantity?: string;
    unitOfMeasureId?: string;
  };
}
const EditableQty = ({ item, createUID }: IEditableQty) => {
  const { t } = useTranslation('components');
  const { control } = useFormContext();
  const { fieldMaxValue, fieldMinValue } = useFormValidation();

  const itemUID = createUID(item);

  // Allows us to update the new quantity max when the UoM changes. If we're using an editable UoM, otherwise we'll default to the item UoM.
  const editableUom = useWatch({
    control,
    name: `${itemUID}-uom`,
  });
  const currentUoMSet = useMemo(() => {
    return editableUom ? editableUom : item.unitOfMeasureId;
  }, [editableUom]);

  const {
    data: { convertQuantityToAllUoM: { unitOfMeasureConversions } } = {
      convertQuantityToAllUoM: { unitOfMeasureConversions: [] as UoMConversionsFragment[] },
    },
  } = useGetProductUoMConversionsQuery({
    variables: { quantity: item.availableQuantity, unitOfMeasure: item.unitOfMeasureId },
  });

  const maxQuantity = useMemo(() => {
    if (currentUoMSet && unitOfMeasureConversions) {
      const currUomConversion = unitOfMeasureConversions.find((conversion) => {
        return conversion?.unitOfMeasure?.id === currentUoMSet;
      });
      return parseFloat(currUomConversion?.quantity) || 0;
    }
    return 0;
  }, [currentUoMSet, unitOfMeasureConversions]);

  return (
    <Controller
      name={`${itemUID}-qty`}
      control={control}
      rules={{
        required: t('form.validation.requiredField', { field: t('common.quantity') }),
        validate: {
          minValue: fieldMinValue(0),
          maxValue: fieldMaxValue(maxQuantity),
        },
      }}
      defaultValue={item.availableQuantity}
      render={({ field, fieldState }) => (
        <Tooltip
          title={fieldState?.error?.message || ''}
          open={!!fieldState?.error}
          arrow
          placement="right"
          disableFocusListener
          disableTouchListener
        >
          <InputBase
            fullWidth
            required
            styledVariant="inline"
            id={`${itemUID}-qty`}
            inputProps={{ 'data-testid': `${itemUID}-qty-input` }}
            type="number"
            error={!!fieldState?.error}
            {...field}
            onChange={(e) =>
              field.onChange(allowOnlyNumeric(e, true, { allowNegatives: false, allowFloat: true }))
            }
          />
        </Tooltip>
      )}
    />
  );
};
export default EditableQty;
