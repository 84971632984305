/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListSapStorageLocationsQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.SapStorageLocationPlantFilter>;
  sorting?: Types.InputMaybe<Array<Types.SapStorageLocationPlantSort> | Types.SapStorageLocationPlantSort>;
}>;


export type ListSapStorageLocationsQuery = { __typename?: 'Query', sapStorageLocationPlants: { __typename?: 'SapStorageLocationPlantOffsetConnection', storageLocations: Array<{ __typename?: 'SapStorageLocationPlant', id: string, plant: string, storageLocation: string }> } };


export const ListSapStorageLocationsDocument = gql`
    query ListSapStorageLocations($paging: OffsetPaging, $filter: SapStorageLocationPlantFilter, $sorting: [SapStorageLocationPlantSort!]) {
  sapStorageLocationPlants(paging: $paging, filter: $filter, sorting: $sorting) {
    storageLocations: nodes {
      id
      plant
      storageLocation
    }
  }
}
    `;

/**
 * __useListSapStorageLocationsQuery__
 *
 * To run a query within a React component, call `useListSapStorageLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSapStorageLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSapStorageLocationsQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useListSapStorageLocationsQuery(baseOptions?: Apollo.QueryHookOptions<ListSapStorageLocationsQuery, ListSapStorageLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListSapStorageLocationsQuery, ListSapStorageLocationsQueryVariables>(ListSapStorageLocationsDocument, options);
      }
export function useListSapStorageLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListSapStorageLocationsQuery, ListSapStorageLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListSapStorageLocationsQuery, ListSapStorageLocationsQueryVariables>(ListSapStorageLocationsDocument, options);
        }
export type ListSapStorageLocationsQueryHookResult = ReturnType<typeof useListSapStorageLocationsQuery>;
export type ListSapStorageLocationsLazyQueryHookResult = ReturnType<typeof useListSapStorageLocationsLazyQuery>;
export type ListSapStorageLocationsQueryResult = Apollo.QueryResult<ListSapStorageLocationsQuery, ListSapStorageLocationsQueryVariables>;