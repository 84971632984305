import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useCancelTasksMutation } from '@/graphql/defs/components/modals/__generated__/cancel-tasks-modal.generated';
import { TaskCancelManyDto } from '@/graphql/types.generated';
import EditableTable, {
  EditableTableCell,
  EditableTableColumn,
  EditableTableRow,
} from '@components/editable-table/EditableTable';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useDateTime } from '@context/date-time';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { getTransComponents } from '@lib/translation';
import { ICancelTasksModal } from '@models/modal';

const CancelTasksModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { displayDateTime } = useDateTime();
  const { modal, closeModal, setLoading } = useModalContent<ICancelTasksModal>();

  const [cancelTasks] = useCancelTasksMutation({
    onCompleted: ({ cancelManyTasks }) => {
      showMessage({
        type: 'success',
        message: t('modal.cancelTasks.success', {
          count: modal.tasks.length,
          taskCodeList: cancelManyTasks.map((task) => task.code).join(', '),
        }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({ type: 'error', message: error.message });
    },
  });

  const taskColumns: EditableTableColumn[] = [
    {
      label: t('common.code'),
      width: '20%',
    },
    {
      label: t('common.type'),
      width: '20%',
    },
    {
      label: t('common.status'),
      width: '20%',
    },
    {
      label: t('common.created'),
      width: '20%',
    },
    {
      label: t('common.team'),
      width: '20%',
    },
  ];

  const taskRows = useMemo<EditableTableRow[]>(
    () =>
      modal.tasks.map((task, index) => {
        const cells: EditableTableCell[] = [
          {
            dataTestId: `task-${index}-taskCode`,
            value: task.taskCode,
          },
          {
            dataTestId: `task-${index}-type`,
            value: task.taskType,
          },
          {
            dataTestId: `task-${index}-status`,
            value: t(`common.${task.taskStatus}`),
          },
          {
            dataTestId: `task-${index}-createdAt`,
            value: displayDateTime({ date: task.createdAt }),
          },
          {
            dataTestId: `task-${index}-team`,
            value: task.teamName,
          },
        ];
        return {
          dataTestId: `task-${index}`,
          item: task,
          cells: cells,
        };
      }),
    [],
  );

  const onSubmit = () => {
    void cancelTasks({
      variables: {
        tasksToCancel: modal.tasks.map<TaskCancelManyDto>((task) => ({ id: task.id })),
      },
    });
  };

  return (
    <>
      <ModalContent>
        <Trans
          ns="components"
          i18nKey="modal.cancelTasks.text"
          components={getTransComponents(['bold', 'break'])}
          count={modal.tasks.length}
          values={{
            taskType: modal.tasks[0].taskType,
            taskCode: modal.tasks[0].taskCode,
          }}
        />
        {modal.tasks.length > 1 && (
          <EditableTable columns={taskColumns} data={taskRows} sx={{ marginTop: '20px' }} />
        )}
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('modal.cancelTasks.cancel', { count: modal.tasks.length })}
        </ModalButton>
        <ModalButton
          variant="contained"
          color="error"
          actionType="submit"
          onClick={() => onSubmit()}
        >
          {t('modal.cancelTasks.submit', { count: modal.tasks.length })}
        </ModalButton>
      </ModalActions>
    </>
  );
};

export default CancelTasksModal;
