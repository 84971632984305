import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useSlottingMovementsDataTableLazyQuery } from '@/graphql/defs/hooks/table-props/__generated__/useSlottingMovementsDataTable.generated';
import { ColumnTypes } from '@components/data-table/controls/filter/filter-definitions';
import { TColumnFactory } from '@components/data-table/hooks/useCreateDataTableColumns';
import filterDataTableColumnDefs from '@components/data-table/lib/filterDataTableColumnDefs';
import {
  TExtractLazyHookDataType,
  TExtractLazyHookFetchFilterType,
  TExtractLazyHookFieldNames,
} from '@components/data-table/model/extract-query-hook-types';
import { useDateTime } from '@context/date-time';

export const SLOTTING_MOVEMENTS_HOOK = useSlottingMovementsDataTableLazyQuery;
export type TSlottingMovementsDataType = TExtractLazyHookDataType<typeof SLOTTING_MOVEMENTS_HOOK>;
export type TSlottingMovementsFilterType = TExtractLazyHookFetchFilterType<
  typeof SLOTTING_MOVEMENTS_HOOK
>;
export type TSlottingMovementsFieldNames = TExtractLazyHookFieldNames<
  typeof SLOTTING_MOVEMENTS_HOOK
>;

interface IUseCreateSlottingMovementsColumns {
  addColumnsToStart?: TColumnFactory<TSlottingMovementsDataType>;
  removeColumns?: (keyof TSlottingMovementsDataType)[];
}
const useCreateSlottingMovementsColumns = ({
  addColumnsToStart,
  removeColumns = [],
}: IUseCreateSlottingMovementsColumns = {}) => {
  const { t } = useTranslation('components');
  const { displayDateTime } = useDateTime();

  const createSlottingMovementsColumns = useCallback<TColumnFactory<TSlottingMovementsDataType>>(
    (columnHelper) => {
      const columns = [
        ...(addColumnsToStart ? addColumnsToStart(columnHelper) : []),
        columnHelper.accessor('material', {
          header: t('common.product'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('material_description', {
          header: t('common.productDescription'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('source_bin', {
          header: t('slotting.currentFixedBin'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('destination_bin', {
          header: t('slotting.proposedFixedBin'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('current_max_quantity', {
          header: t('slotting.currentMaxQuantity'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.number,
          },
        }),
        columnHelper.accessor('proposed_max_quantity', {
          header: t('slotting.proposedMaxQuantity'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.number,
          },
        }),
        columnHelper.accessor('current_min_quantity', {
          header: t('slotting.currentMinQuantity'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.number,
          },
        }),
        columnHelper.accessor('proposed_min_quantity', {
          header: t('slotting.proposedMinQuantity'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.number,
          },
        }),
      ];
      return columns.filter(filterDataTableColumnDefs<TSlottingMovementsDataType>(removeColumns));
    },
    [t, displayDateTime, addColumnsToStart, ...removeColumns],
  );

  return createSlottingMovementsColumns;
};

export default useCreateSlottingMovementsColumns;
