import { useDataBasedDataTable } from '@components/data-table/context/DataBasedProvider';
import DataTableTableStructure from '@components/data-table/table/TableStructure';

const DataTableDataBasedTable = () => {
  const { tableId, tableSize, headerColumns, dataRows, selectedRows, isLoadingData } =
    useDataBasedDataTable();

  return (
    <DataTableTableStructure
      tableId={tableId}
      tableSize={tableSize}
      headerColumns={headerColumns}
      dataRows={dataRows}
      selectedRows={selectedRows}
      isDataTableLoading={isLoadingData}
      hasCompletedFirstFetch={true}
    />
  );
};

export default DataTableDataBasedTable;
