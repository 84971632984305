import enumKeys from '@lib/enum-keys';

/***
 * Takes a String Enum and returns whether or not your value matches one of the enum's values.
 * @param obj - String enum object.
 * @param value - String value to look for inside of the enum.
 *
 * @return boolean
 */
function isStringEnumValue<O extends object>(obj: O, value: string) {
  const keys = enumKeys(obj);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (obj[key] === value) return true;
  }
  return false;
}

export default isStringEnumValue;
