export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
};

export type AbcAnalysis = {
  __typename?: 'AbcAnalysis';
  criteria: Scalars['String']['output'];
  indicatorPercentages: Array<Scalars['Float']['output']>;
};

export type AbcAnalysisInput = {
  /** Slotting ABC criteria */
  criteria: AbcCriteria;
  /** Slotting ABC indicator percentages */
  indicatorPercentages: Array<Scalars['Float']['input']>;
};

export enum AbcCriteria {
  SalesOrderLineItems = 'salesOrderLineItems',
  SalesOrderQuantity = 'salesOrderQuantity'
}

export enum AdjustmentType {
  DamagedProduct = 'damagedProduct',
  PhysicalInventory = 'physicalInventory'
}

/** Admin Task */
export type AdminTask = {
  __typename?: 'AdminTask';
  adminTaskType?: Maybe<AdminTaskType>;
  /** Task type ID (foreign key) */
  adminTaskTypeId: Scalars['ID']['output'];
  barcode?: Maybe<Barcode>;
  /** Barcode entity ID */
  barcodeId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Task status, i.e Not Started */
  status: TaskStatus;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity's user ID (foreign key) */
  userId?: Maybe<Scalars['ID']['output']>;
};

export type AdminTaskType = {
  __typename?: 'AdminTaskType';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity's label */
  label: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export enum AgentConfigStatus {
  Error = 'error',
  Ok = 'ok'
}

/** Agent configuration model */
export type AgentConfiguration = {
  __typename?: 'AgentConfiguration';
  /** Agent Id */
  agentId: Scalars['ID']['output'];
  /** Agent Name */
  agentName?: Maybe<Scalars['String']['output']>;
  /** Agent Hosts */
  hosts: Array<AgentConfigurationHost>;
  /** Agent status */
  status?: Maybe<AgentConfigStatus>;
  /** Agent version */
  version?: Maybe<Scalars['String']['output']>;
};

export enum AgentConfigurationAccessPolicy {
  AllPaths = 'ALL_PATHS',
  PathOnly = 'PATH_ONLY'
}

/** Agent configuration host */
export type AgentConfigurationHost = {
  __typename?: 'AgentConfigurationHost';
  /** Agent Access Policy */
  accessPolicy: AgentConfigurationAccessPolicy;
  /** Agent Hostname */
  hostname?: Maybe<Scalars['String']['output']>;
  /** Agent Path */
  path: Scalars['String']['output'];
  /** Agent Port */
  port?: Maybe<Scalars['String']['output']>;
};

export type AgentConfigurationHostInput = {
  /** Agent Access Policy */
  accessPolicy: AgentConfigurationAccessPolicy;
  /** Agent Hostname */
  hostname: Scalars['String']['input'];
  /** Agent Path */
  path?: InputMaybe<Scalars['String']['input']>;
  /** Agent Port */
  port: Scalars['String']['input'];
};

export type AgentConfigurationUpdate = {
  /** Agent Name */
  agentName: Scalars['String']['input'];
  /** Agent Hosts */
  hosts: Array<AgentConfigurationHostInput>;
};

/** Aggregate index counts */
export type AggsResults = {
  __typename?: 'AggsResults';
  area: Scalars['Int']['output'];
  bin: Scalars['Int']['output'];
  delivery: Scalars['Int']['output'];
  fulfillment: Scalars['Int']['output'];
  license_plate: Scalars['Int']['output'];
  product: Scalars['Int']['output'];
  task: Scalars['Int']['output'];
};

export type Aisle = {
  __typename?: 'Aisle';
  /** Entity's area ID (foreign key) */
  areaId: Scalars['ID']['output'];
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** ApiDocs */
  name?: Maybe<Scalars['String']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
};

export type AisleColumn = {
  __typename?: 'AisleColumn';
  /** Aisle within the warehouse. */
  aisleId: Scalars['ID']['output'];
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** ApiDocs */
  name?: Maybe<Scalars['String']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
};

export type AisleColumnCreateInput = {
  /** Aisle within the warehouse. */
  aisleId: Scalars['ID']['input'];
  /** Entity code */
  code: Scalars['String']['input'];
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ApiDocs */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['input'];
};

export type AisleColumnCreateOneInput = {
  /** The record to create */
  aisleColumn: AisleColumnCreateInput;
};

export type AisleColumnFilter = {
  aisleId?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<AisleColumnFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AisleColumnFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type AisleColumnOffsetConnection = {
  __typename?: 'AisleColumnOffsetConnection';
  /** Array of nodes. */
  nodes: Array<AisleColumn>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type AisleColumnSort = {
  direction: SortDirection;
  field: AisleColumnSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AisleColumnSortFields {
  AisleId = 'aisleId',
  Code = 'code',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WarehouseId = 'warehouseId'
}

export type AisleColumnUpdateInput = {
  /** Aisle within the warehouse. */
  aisleId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ApiDocs */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type AisleColumnUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: AisleColumnUpdateInput;
};

export type AisleCreateInput = {
  /** Entity's area ID (foreign key) */
  areaId: Scalars['ID']['input'];
  /** Entity code */
  code: Scalars['String']['input'];
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ApiDocs */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['input'];
};

export type AisleCreateOneInput = {
  /** The record to create */
  aisle: AisleCreateInput;
};

export type AisleFilter = {
  and?: InputMaybe<Array<AisleFilter>>;
  areaId?: InputMaybe<IdFilterComparison>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AisleFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type AisleOffsetConnection = {
  __typename?: 'AisleOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Aisle>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type AisleSort = {
  direction: SortDirection;
  field: AisleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AisleSortFields {
  AreaId = 'areaId',
  Code = 'code',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WarehouseId = 'warehouseId'
}

export type AisleUpdateInput = {
  /** Entity's area ID (foreign key) */
  areaId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ApiDocs */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type AisleUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: AisleUpdateInput;
};

export type AllBarcodeEntities = {
  __typename?: 'AllBarcodeEntities';
  entity: Scalars['String']['output'];
  fields: Array<Scalars['String']['output']>;
};

export type ApprovePiTaskInputDto = {
  /** Dto wrapper */
  physicalInventoryApproval: TaskApprovePiInput;
};

/** Area model */
export type Area = {
  __typename?: 'Area';
  aisles?: Maybe<Array<Aisle>>;
  bins?: Maybe<Array<Bin>>;
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  doors?: Maybe<Array<Door>>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** ApiDocs */
  name?: Maybe<Scalars['String']['output']>;
  sapStorageLocationPlant: SapStorageLocationPlant;
  /** Area's status, i.e. ACTIVE, INACTIVE */
  status?: Maybe<AreaStatus>;
  /** Area's type, i.e. TEMP, PERM */
  type?: Maybe<AreaType>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  warehouse: Warehouse;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
};

export type AreaCreateInput = {
  /** Entity code */
  code: Scalars['String']['input'];
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ApiDocs */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Entity's SAP storage location plant ID (foreign key) */
  sapStorageLocationPlantId?: InputMaybe<Scalars['ID']['input']>;
  /** Area's status, i.e. ACTIVE, INACTIVE */
  status?: InputMaybe<AreaStatus>;
  /** Area's type, i.e. TEMP, PERM */
  type?: InputMaybe<AreaType>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type AreaCreateOneInput = {
  /** The record to create */
  area: AreaCreateInput;
};

export type AreaFilter = {
  and?: InputMaybe<Array<AreaFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<AreaFilter>>;
  status?: InputMaybe<AreaStatusFilterComparison>;
  type?: InputMaybe<AreaTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type AreaOffsetConnection = {
  __typename?: 'AreaOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Area>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

/** Area Search results */
export type AreaSearchResults = {
  __typename?: 'AreaSearchResults';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  index?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  storageLocation?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  warehouseCode?: Maybe<Scalars['String']['output']>;
};

export type AreaSort = {
  direction: SortDirection;
  field: AreaSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum AreaSortFields {
  Code = 'code',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WarehouseId = 'warehouseId'
}

export enum AreaStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type AreaStatusFilterComparison = {
  eq?: InputMaybe<AreaStatus>;
  gt?: InputMaybe<AreaStatus>;
  gte?: InputMaybe<AreaStatus>;
  iLike?: InputMaybe<AreaStatus>;
  in?: InputMaybe<Array<AreaStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<AreaStatus>;
  lt?: InputMaybe<AreaStatus>;
  lte?: InputMaybe<AreaStatus>;
  neq?: InputMaybe<AreaStatus>;
  notILike?: InputMaybe<AreaStatus>;
  notIn?: InputMaybe<Array<AreaStatus>>;
  notLike?: InputMaybe<AreaStatus>;
};

export enum AreaType {
  Perm = 'perm',
  Temp = 'temp'
}

export type AreaTypeFilterComparison = {
  eq?: InputMaybe<AreaType>;
  gt?: InputMaybe<AreaType>;
  gte?: InputMaybe<AreaType>;
  iLike?: InputMaybe<AreaType>;
  in?: InputMaybe<Array<AreaType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<AreaType>;
  lt?: InputMaybe<AreaType>;
  lte?: InputMaybe<AreaType>;
  neq?: InputMaybe<AreaType>;
  notILike?: InputMaybe<AreaType>;
  notIn?: InputMaybe<Array<AreaType>>;
  notLike?: InputMaybe<AreaType>;
};

export type AreaUpdateInput = {
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ApiDocs */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Entity's SAP storage location plant ID (foreign key) */
  sapStorageLocationPlantId?: InputMaybe<Scalars['ID']['input']>;
  /** Area's status, i.e. ACTIVE, INACTIVE */
  status?: InputMaybe<AreaStatus>;
  /** Area's type, i.e. TEMP, PERM */
  type?: InputMaybe<AreaType>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type AreaUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** update dto */
  update: AreaUpdateInput;
};

export type Barcode = {
  __typename?: 'Barcode';
  adminTask?: Maybe<AdminTask>;
  barcodeMappings?: Maybe<BarcodeMappings>;
  businessPartner?: Maybe<BusinessPartner>;
  /** Entity ID */
  businessPartnerId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  code: Scalars['String']['output'];
  company?: Maybe<Company>;
  /** Entity's company ID (foreign key) */
  companyId: Scalars['ID']['output'];
  /** configuration type */
  configuration: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** scanned data */
  scanData?: Maybe<Scalars['String']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type BarcodeCreateInput = {
  businessPartnerId?: InputMaybe<Scalars['ID']['input']>;
  companyId: Scalars['ID']['input'];
  configuration: Scalars['String']['input'];
  scanData: Scalars['String']['input'];
};

export type BarcodeCreateOneInput = {
  /** The record to create */
  barcode: BarcodeCreateInput;
};

export type BarcodeField = {
  /** Barcode application identifier */
  ai: Scalars['String']['input'];
  /** Barcode mapping entity ID */
  barcodeMappingId?: InputMaybe<Scalars['ID']['input']>;
  /** The entity relation for the application identifier */
  entity: Scalars['String']['input'];
  /** The entity field relation for the application identifier */
  entityField: Scalars['String']['input'];
};

export type BarcodeFilter = {
  and?: InputMaybe<Array<BarcodeFilter>>;
  businessPartnerId?: InputMaybe<IdFilterComparison>;
  code?: InputMaybe<StringFieldComparison>;
  companyId?: InputMaybe<IdFilterComparison>;
  configuration?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<BarcodeFilter>>;
  scanData?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type BarcodeMapping = {
  __typename?: 'BarcodeMapping';
  /** barcode field name */
  ai: Scalars['String']['output'];
  /** barcode id */
  barcodeId: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** data content of barcode application identifier */
  dataContent?: Maybe<Scalars['String']['output']>;
  /** data title of barcode application identifier */
  dataTitle?: Maybe<Scalars['String']['output']>;
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** barcode field entity relationship */
  entity?: Maybe<Scalars['String']['output']>;
  /** entity field for barcode mapping */
  entityField?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** required identifier in the barcode spec */
  required: Scalars['Boolean']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type BarcodeMappingFilter = {
  ai?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<BarcodeMappingFilter>>;
  barcodeId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  dataContent?: InputMaybe<StringFieldComparison>;
  dataTitle?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  entity?: InputMaybe<StringFieldComparison>;
  entityField?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<BarcodeMappingFilter>>;
  required?: InputMaybe<BooleanFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type BarcodeMappingOffsetConnection = {
  __typename?: 'BarcodeMappingOffsetConnection';
  /** Array of nodes. */
  nodes: Array<BarcodeMapping>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type BarcodeMappingSort = {
  direction: SortDirection;
  field: BarcodeMappingSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BarcodeMappingSortFields {
  Ai = 'ai',
  BarcodeId = 'barcodeId',
  CreatedAt = 'createdAt',
  DataContent = 'dataContent',
  DataTitle = 'dataTitle',
  DeletedAt = 'deletedAt',
  Entity = 'entity',
  EntityField = 'entityField',
  Id = 'id',
  Required = 'required',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type BarcodeMappings = {
  __typename?: 'BarcodeMappings';
  nodes: Array<BarcodeMapping>;
};

export type BarcodeOffsetConnection = {
  __typename?: 'BarcodeOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Barcode>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export enum BarcodeScanner {
  Zebra = 'zebra'
}

export type BarcodeSort = {
  direction: SortDirection;
  field: BarcodeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BarcodeSortFields {
  BusinessPartnerId = 'businessPartnerId',
  Code = 'code',
  CompanyId = 'companyId',
  Configuration = 'configuration',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  ScanData = 'scanData',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export enum BarcodeSymbologyType {
  Gs1 = 'gs1',
  Pdf417 = 'pdf417'
}

export type BarcodeSymbologyTypeFilterComparison = {
  eq?: InputMaybe<BarcodeSymbologyType>;
  gt?: InputMaybe<BarcodeSymbologyType>;
  gte?: InputMaybe<BarcodeSymbologyType>;
  iLike?: InputMaybe<BarcodeSymbologyType>;
  in?: InputMaybe<Array<BarcodeSymbologyType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<BarcodeSymbologyType>;
  lt?: InputMaybe<BarcodeSymbologyType>;
  lte?: InputMaybe<BarcodeSymbologyType>;
  neq?: InputMaybe<BarcodeSymbologyType>;
  notILike?: InputMaybe<BarcodeSymbologyType>;
  notIn?: InputMaybe<Array<BarcodeSymbologyType>>;
  notLike?: InputMaybe<BarcodeSymbologyType>;
};

export type BarcodeTemplate = {
  __typename?: 'BarcodeTemplate';
  configurationName: BarcodeSymbologyType;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type BarcodeTemplateFilter = {
  and?: InputMaybe<Array<BarcodeTemplateFilter>>;
  configurationName?: InputMaybe<BarcodeSymbologyTypeFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<BarcodeTemplateFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type BarcodeTemplateMapping = {
  __typename?: 'BarcodeTemplateMapping';
  /** barcode application identifier */
  ai: Scalars['String']['output'];
  /** barcode id */
  barcodeTemplateId: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** data content of barcode application identifier */
  dataContent: Scalars['String']['output'];
  /** data title of barcode application identifier */
  dataTitle: Scalars['String']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** barcode field entity relationship */
  entity: Scalars['String']['output'];
  /** entity field for barcode mapping */
  entityField: Scalars['String']['output'];
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type BarcodeTemplateMappingCreateInput = {
  /** barcode application identifier */
  ai: Scalars['String']['input'];
  /** barcode id */
  barcodeId: Scalars['ID']['input'];
  /** data content of barcode application identifier */
  dataContent: Scalars['String']['input'];
  /** data title of barcode application identifier */
  dataTitle: Scalars['String']['input'];
  /** barcode field entity relationship */
  entity: Scalars['String']['input'];
  /** entity field for barcode mapping */
  entityField: Scalars['String']['input'];
};

export type BarcodeTemplateMappingCreateOneInput = {
  /** The record to create */
  barcodeTemplateMapping: BarcodeTemplateMappingCreateInput;
};

export type BarcodeTemplateMappingFilter = {
  ai?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<BarcodeTemplateMappingFilter>>;
  barcodeTemplateId?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  dataContent?: InputMaybe<StringFieldComparison>;
  dataTitle?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  entity?: InputMaybe<StringFieldComparison>;
  entityField?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<BarcodeTemplateMappingFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type BarcodeTemplateMappingOffsetConnection = {
  __typename?: 'BarcodeTemplateMappingOffsetConnection';
  /** Array of nodes. */
  nodes: Array<BarcodeTemplateMapping>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type BarcodeTemplateMappingSort = {
  direction: SortDirection;
  field: BarcodeTemplateMappingSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BarcodeTemplateMappingSortFields {
  Ai = 'ai',
  BarcodeTemplateId = 'barcodeTemplateId',
  CreatedAt = 'createdAt',
  DataContent = 'dataContent',
  DataTitle = 'dataTitle',
  DeletedAt = 'deletedAt',
  Entity = 'entity',
  EntityField = 'entityField',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type BarcodeTemplateMappingUpdateInput = {
  /** barcode application identifier */
  ai?: InputMaybe<Scalars['String']['input']>;
  /** barcode id */
  barcodeId?: InputMaybe<Scalars['ID']['input']>;
  /** data content of barcode application identifier */
  dataContent?: InputMaybe<Scalars['String']['input']>;
  /** data title of barcode application identifier */
  dataTitle?: InputMaybe<Scalars['String']['input']>;
  /** barcode field entity relationship */
  entity?: InputMaybe<Scalars['String']['input']>;
  /** entity field for barcode mapping */
  entityField?: InputMaybe<Scalars['String']['input']>;
};

export type BarcodeTemplateMappingUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: BarcodeTemplateMappingUpdateInput;
};

export type BarcodeTemplateOffsetConnection = {
  __typename?: 'BarcodeTemplateOffsetConnection';
  /** Array of nodes. */
  nodes: Array<BarcodeTemplate>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type BarcodeTemplateSort = {
  direction: SortDirection;
  field: BarcodeTemplateSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BarcodeTemplateSortFields {
  ConfigurationName = 'configurationName',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type BarcodeUpdateInput = {
  businessPartnerId?: InputMaybe<Scalars['ID']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  configuration?: InputMaybe<Scalars['String']['input']>;
  scanData?: InputMaybe<Scalars['String']['input']>;
};

export type BarcodeUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: BarcodeUpdateInput;
};

export type BaseGraphqlErrorReturnType = {
  __typename?: 'BaseGraphqlErrorReturnType';
  action?: Maybe<Scalars['String']['output']>;
  code?: Maybe<ErrorCodes>;
  duration?: Maybe<Scalars['Float']['output']>;
  log?: Maybe<Scalars['String']['output']>;
  logType?: Maybe<LogType>;
  message: Scalars['String']['output'];
  type?: Maybe<ErrorType>;
};

/** Bin entity model */
export type Bin = {
  __typename?: 'Bin';
  /** Column where the bin is located. */
  aisleColumnId?: Maybe<Scalars['ID']['output']>;
  /** Aisle where the bin is located */
  aisleId?: Maybe<Scalars['ID']['output']>;
  area?: Maybe<Area>;
  /** Entity's area ID (foreign key) */
  areaId?: Maybe<Scalars['ID']['output']>;
  /** Storage type for a bin */
  binStorageTypeId: Scalars['ID']['output'];
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Delivery ID (foreign key) */
  deliveryId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Timestamp of latest inventory count approval */
  lastCount?: Maybe<Scalars['DateTime']['output']>;
  /** Timestamp of latest movement */
  lastMovement?: Maybe<Scalars['DateTime']['output']>;
  /** Level where the bin is located. */
  level?: Maybe<Scalars['Int']['output']>;
  /** If Bin is blocked from product being putaway */
  putawayBlock?: Maybe<Scalars['Boolean']['output']>;
  /** If Bin is blocked from product being removed */
  removalBlock?: Maybe<Scalars['Boolean']['output']>;
  /** Bin type */
  type?: Maybe<BinType>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Verification code from a barcode scan */
  verificationCode?: Maybe<Scalars['String']['output']>;
  warehouse?: Maybe<Warehouse>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
  /** x coordinate location */
  x?: Maybe<Scalars['Float']['output']>;
  /** Y coordinate location */
  y?: Maybe<Scalars['Float']['output']>;
  /** Z coordinate location */
  z?: Maybe<Scalars['Float']['output']>;
};

export enum BinActiveState {
  Active = 'active',
  Inactive = 'inactive'
}

export type BinActiveStateFilterComparison = {
  eq?: InputMaybe<BinActiveState>;
  gt?: InputMaybe<BinActiveState>;
  gte?: InputMaybe<BinActiveState>;
  iLike?: InputMaybe<BinActiveState>;
  in?: InputMaybe<Array<BinActiveState>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<BinActiveState>;
  lt?: InputMaybe<BinActiveState>;
  lte?: InputMaybe<BinActiveState>;
  neq?: InputMaybe<BinActiveState>;
  notILike?: InputMaybe<BinActiveState>;
  notIn?: InputMaybe<Array<BinActiveState>>;
  notLike?: InputMaybe<BinActiveState>;
};

export enum BinBlockState {
  Available = 'available',
  Blocked = 'blocked'
}

export type BinBlockStateFilterComparison = {
  eq?: InputMaybe<BinBlockState>;
  gt?: InputMaybe<BinBlockState>;
  gte?: InputMaybe<BinBlockState>;
  iLike?: InputMaybe<BinBlockState>;
  in?: InputMaybe<Array<BinBlockState>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<BinBlockState>;
  lt?: InputMaybe<BinBlockState>;
  lte?: InputMaybe<BinBlockState>;
  neq?: InputMaybe<BinBlockState>;
  notILike?: InputMaybe<BinBlockState>;
  notIn?: InputMaybe<Array<BinBlockState>>;
  notLike?: InputMaybe<BinBlockState>;
};

export type BinCreateInput = {
  /** Column where the bin is located. */
  aisleColumnId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's area ID (foreign key) */
  aisleId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's area ID (foreign key) */
  areaId?: InputMaybe<Scalars['ID']['input']>;
  /** Storage type for a bin */
  binStorageTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity code */
  code: Scalars['String']['input'];
  /** Timestamp of latest inventory count approval */
  lastCount?: InputMaybe<Scalars['DateTime']['input']>;
  /** Level where the bin is located. */
  level?: InputMaybe<Scalars['Int']['input']>;
  /** If Bin is blocked from product being putaway */
  putawayBlock?: InputMaybe<Scalars['Boolean']['input']>;
  /** If Bin is blocked from product being removed */
  removalBlock?: InputMaybe<Scalars['Boolean']['input']>;
  /** Verification code from a barcode scan */
  verificationCode?: InputMaybe<Scalars['String']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
  /** x coordinate location */
  x?: InputMaybe<Scalars['Float']['input']>;
  /** Y coordinate location */
  y?: InputMaybe<Scalars['Float']['input']>;
  /** Z coordinate location */
  z?: InputMaybe<Scalars['Float']['input']>;
};

export type BinCreateOneInput = {
  /** The record to create */
  bin: BinCreateInput;
};

export type BinEnrollment = {
  /** x coordinate location */
  x: Scalars['Float']['input'];
  /** Y coordinate location */
  y: Scalars['Float']['input'];
  /** Z coordinate location */
  z?: InputMaybe<Scalars['Float']['input']>;
};

export type BinFilter = {
  aisleColumnId?: InputMaybe<IdFilterComparison>;
  aisleId?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<BinFilter>>;
  areaId?: InputMaybe<IdFilterComparison>;
  binStorageTypeId?: InputMaybe<IdFilterComparison>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  deliveryId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lastCount?: InputMaybe<DateFieldComparison>;
  lastMovement?: InputMaybe<DateFieldComparison>;
  level?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<BinFilter>>;
  putawayBlock?: InputMaybe<BooleanFieldComparison>;
  removalBlock?: InputMaybe<BooleanFieldComparison>;
  type?: InputMaybe<BinTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  verificationCode?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  x?: InputMaybe<FloatFieldComparison>;
  y?: InputMaybe<FloatFieldComparison>;
  z?: InputMaybe<FloatFieldComparison>;
};

export type BinForPickTask = {
  __typename?: 'BinForPickTask';
  availableQuantity?: Maybe<Scalars['Float']['output']>;
  binCode?: Maybe<Scalars['String']['output']>;
  binId?: Maybe<Scalars['ID']['output']>;
  binLevel?: Maybe<Scalars['String']['output']>;
  lotCode?: Maybe<Scalars['String']['output']>;
  lotExpiration?: Maybe<Scalars['DateTime']['output']>;
  lotId?: Maybe<Scalars['ID']['output']>;
  lotProductionDate?: Maybe<Scalars['DateTime']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

export type BinOffsetConnection = {
  __typename?: 'BinOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Bin>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

/** Bin Search results */
export type BinSearchResults = {
  __typename?: 'BinSearchResults';
  aisle?: Maybe<Scalars['String']['output']>;
  areaCode?: Maybe<Scalars['String']['output']>;
  areaDescription?: Maybe<Scalars['String']['output']>;
  areaName?: Maybe<Scalars['String']['output']>;
  areaType?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  column?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  index?: Maybe<Scalars['String']['output']>;
  lastMoved?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  warehouseCode?: Maybe<Scalars['String']['output']>;
};

export type BinSort = {
  direction: SortDirection;
  field: BinSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BinSortFields {
  AisleColumnId = 'aisleColumnId',
  AisleId = 'aisleId',
  AreaId = 'areaId',
  BinStorageTypeId = 'binStorageTypeId',
  Code = 'code',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  DeliveryId = 'deliveryId',
  Id = 'id',
  LastCount = 'lastCount',
  LastMovement = 'lastMovement',
  Level = 'level',
  PutawayBlock = 'putawayBlock',
  RemovalBlock = 'removalBlock',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  VerificationCode = 'verificationCode',
  WarehouseId = 'warehouseId',
  X = 'x',
  Y = 'y',
  Z = 'z'
}

export type BinStatus = {
  __typename?: 'BinStatus';
  /** Entity code */
  code: BinStatusCode;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity's label */
  label: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export enum BinStatusCode {
  BinStatusDestinationBlock = 'BIN_STATUS_DESTINATION_BLOCK',
  BinStatusInactive = 'BIN_STATUS_INACTIVE',
  BinStatusSourceBlock = 'BIN_STATUS_SOURCE_BLOCK',
  BinStatusTestCode = 'BIN_STATUS_TEST_CODE'
}

export type BinStatusCodeFilterComparison = {
  eq?: InputMaybe<BinStatusCode>;
  gt?: InputMaybe<BinStatusCode>;
  gte?: InputMaybe<BinStatusCode>;
  iLike?: InputMaybe<BinStatusCode>;
  in?: InputMaybe<Array<BinStatusCode>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<BinStatusCode>;
  lt?: InputMaybe<BinStatusCode>;
  lte?: InputMaybe<BinStatusCode>;
  neq?: InputMaybe<BinStatusCode>;
  notILike?: InputMaybe<BinStatusCode>;
  notIn?: InputMaybe<Array<BinStatusCode>>;
  notLike?: InputMaybe<BinStatusCode>;
};

export type BinStatusFilter = {
  and?: InputMaybe<Array<BinStatusFilter>>;
  code?: InputMaybe<BinStatusCodeFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BinStatusFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type BinStatusInput = {
  /** Entity code */
  binStatusCode: BinStatusCode;
  /** Bin status detail's active flag */
  on: Scalars['Boolean']['input'];
};

export type BinStatusMapping = {
  __typename?: 'BinStatusMapping';
  /** Entity ID */
  binId: Scalars['ID']['output'];
  /** Entity ID */
  binStatusId: Scalars['ID']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type BinStatusMappingFilter = {
  and?: InputMaybe<Array<BinStatusMappingFilter>>;
  binId?: InputMaybe<IdFilterComparison>;
  binStatusId?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<BinStatusMappingFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type BinStatusMappingInput = {
  /** Bin Ids for mapping assignment */
  binIds: Array<Scalars['ID']['input']>;
  /** Bin Status Input */
  binStatus: Array<BinStatusInput>;
};

export type BinStatusMappingInputDto = {
  /** Bin Status mappings */
  binStatusMappings: BinStatusMappingInput;
};

export type BinStatusMappingJson = {
  __typename?: 'BinStatusMappingJson';
  binStatusCode?: Maybe<Scalars['String']['output']>;
  binStatusId?: Maybe<Scalars['ID']['output']>;
  binStatusLabel?: Maybe<Scalars['String']['output']>;
  editable?: Maybe<Scalars['Boolean']['output']>;
  mapped?: Maybe<Scalars['Boolean']['output']>;
};

export type BinStatusMappingOffsetConnection = {
  __typename?: 'BinStatusMappingOffsetConnection';
  /** Array of nodes. */
  nodes: Array<BinStatusMapping>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type BinStatusMappingSort = {
  direction: SortDirection;
  field: BinStatusMappingSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BinStatusMappingSortFields {
  BinId = 'binId',
  BinStatusId = 'binStatusId',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type BinStatusOffsetConnection = {
  __typename?: 'BinStatusOffsetConnection';
  /** Array of nodes. */
  nodes: Array<BinStatus>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type BinStatusSort = {
  direction: SortDirection;
  field: BinStatusSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BinStatusSortFields {
  Code = 'code',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Label = 'label',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type BinStockDetailDto = {
  /** Entity ID */
  deliveryItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity ID */
  fulfillmentItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity ID */
  licensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity ID */
  lotId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity ID */
  productId?: InputMaybe<Scalars['ID']['input']>;
  quantity?: InputMaybe<Scalars['String']['input']>;
  /** Entity ID */
  stockStatusId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity ID */
  unitOfMeasureId?: InputMaybe<Scalars['ID']['input']>;
};

export type BinStorageType = {
  __typename?: 'BinStorageType';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Created by user */
  createdByUserId?: Maybe<Scalars['String']['output']>;
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Depth(X) value of storage capacity */
  depth: Scalars['Float']['output'];
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Unit of measure glossary ID */
  distanceUomId: Scalars['ID']['output'];
  /** Height(Z) value of storage capacity */
  height: Scalars['Float']['output'];
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity's label */
  label: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
  /** Weight capacity of storage */
  weightCapacity: Scalars['Float']['output'];
  /** Unit of measure glossary ID */
  weightCapacityUomId: Scalars['ID']['output'];
  /** Width(Y) value of storage capacity */
  width: Scalars['Float']['output'];
};

export type BinStorageTypeCopyDto = {
  /** Entity ID */
  binStorageTypeIdToCopy: Scalars['ID']['input'];
  /** Entity code */
  code: Scalars['String']['input'];
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Entity's label */
  label: Scalars['String']['input'];
  /** Entity ID */
  warehouseId: Scalars['ID']['input'];
};

export type BinStorageTypeCreateInput = {
  /** Entity code */
  code: Scalars['String']['input'];
  /** Depth(X) value of storage capacity */
  depth: Scalars['Float']['input'];
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Unit of measure for volume capacity of storage */
  distanceUomId: Scalars['String']['input'];
  /** Height(Z) value of storage capacity */
  height: Scalars['Float']['input'];
  /** Entity's label */
  label: Scalars['String']['input'];
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['input'];
  /** Weight capacity of storage */
  weightCapacity: Scalars['Float']['input'];
  /** Entity ID */
  weightCapacityUomId: Scalars['String']['input'];
  /** Width(Y) value of storage capacity */
  width: Scalars['Float']['input'];
};

export type BinStorageTypeCreateOneInput = {
  /** The record to create */
  binStorageType: BinStorageTypeCreateInput;
};

export type BinStorageTypeFilter = {
  and?: InputMaybe<Array<BinStorageTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  createdByUserId?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  depth?: InputMaybe<FloatFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  distanceUomId?: InputMaybe<IdFilterComparison>;
  height?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BinStorageTypeFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  weightCapacity?: InputMaybe<FloatFieldComparison>;
  weightCapacityUomId?: InputMaybe<IdFilterComparison>;
  width?: InputMaybe<FloatFieldComparison>;
};

export type BinStorageTypeOffsetConnection = {
  __typename?: 'BinStorageTypeOffsetConnection';
  /** Array of nodes. */
  nodes: Array<BinStorageType>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type BinStorageTypeSort = {
  direction: SortDirection;
  field: BinStorageTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BinStorageTypeSortFields {
  Code = 'code',
  CreatedAt = 'createdAt',
  CreatedByUserId = 'createdByUserId',
  DeletedAt = 'deletedAt',
  Depth = 'depth',
  Description = 'description',
  DistanceUomId = 'distanceUomId',
  Height = 'height',
  Id = 'id',
  Label = 'label',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WarehouseId = 'warehouseId',
  WeightCapacity = 'weightCapacity',
  WeightCapacityUomId = 'weightCapacityUomId',
  Width = 'width'
}

export type BinStorageTypeUpdateInput = {
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Depth(X) value of storage capacity */
  depth?: InputMaybe<Scalars['Float']['input']>;
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Unit of measure for volume capacity of storage */
  distanceUomId?: InputMaybe<Scalars['String']['input']>;
  /** Height(Z) value of storage capacity */
  height?: InputMaybe<Scalars['Float']['input']>;
  /** Entity's label */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
  /** Weight capacity of storage */
  weightCapacity?: InputMaybe<Scalars['Float']['input']>;
  /** Entity ID */
  weightCapacityUomId?: InputMaybe<Scalars['String']['input']>;
  /** Width(Y) value of storage capacity */
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type BinStorageTypeUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: BinStorageTypeUpdateInput;
};

export enum BinType {
  Default = 'default',
  Delivery = 'delivery',
  Disposition = 'disposition',
  Fulfillment = 'fulfillment',
  LostAndFound = 'lostAndFound',
  Planned = 'planned',
  Staging = 'staging'
}

export type BinTypeFilterComparison = {
  eq?: InputMaybe<BinType>;
  gt?: InputMaybe<BinType>;
  gte?: InputMaybe<BinType>;
  iLike?: InputMaybe<BinType>;
  in?: InputMaybe<Array<BinType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<BinType>;
  lt?: InputMaybe<BinType>;
  lte?: InputMaybe<BinType>;
  neq?: InputMaybe<BinType>;
  notILike?: InputMaybe<BinType>;
  notIn?: InputMaybe<Array<BinType>>;
  notLike?: InputMaybe<BinType>;
};

export type BinUpdateInput = {
  /** Column where the bin is located. */
  aisleColumnId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's area ID (foreign key) */
  aisleId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's area ID (foreign key) */
  areaId?: InputMaybe<Scalars['ID']['input']>;
  /** Storage type for a bin */
  binStorageTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Timestamp of latest inventory count approval */
  lastCount?: InputMaybe<Scalars['DateTime']['input']>;
  /** Level where the bin is located. */
  level?: InputMaybe<Scalars['Int']['input']>;
  /** If Bin is blocked from product being putaway */
  putawayBlock?: InputMaybe<Scalars['Boolean']['input']>;
  /** If Bin is blocked from product being removed */
  removalBlock?: InputMaybe<Scalars['Boolean']['input']>;
  /** Verification code from a barcode scan */
  verificationCode?: InputMaybe<Scalars['String']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
  /** x coordinate location */
  x?: InputMaybe<Scalars['Float']['input']>;
  /** Y coordinate location */
  y?: InputMaybe<Scalars['Float']['input']>;
  /** Z coordinate location */
  z?: InputMaybe<Scalars['Float']['input']>;
};

export type BinUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: BinUpdateInput;
};

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Business Partner model */
export type BusinessPartner = {
  __typename?: 'BusinessPartner';
  /** Timezone */
  addressTimezone?: Maybe<Scalars['String']['output']>;
  /** SAP address unique identifier */
  addressUUID?: Maybe<Scalars['String']['output']>;
  /** Name of city */
  cityName?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  code: Scalars['String']['output'];
  /** Country */
  country?: Maybe<Scalars['String']['output']>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** SAP Customer Code */
  customerCode?: Maybe<Scalars['String']['output']>;
  /** Phone number */
  defaultPhoneNumber?: Maybe<Scalars['String']['output']>;
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Often used for county, prefecture, etc. */
  district?: Maybe<Scalars['String']['output']>;
  /** Email address */
  email?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Language */
  language?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  name?: Maybe<Scalars['String']['output']>;
  /** Phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  /** Postal code */
  postalCode?: Maybe<Scalars['String']['output']>;
  /** Region / State */
  region?: Maybe<Scalars['String']['output']>;
  /** Open text search term */
  searchTerm1?: Maybe<Scalars['String']['output']>;
  /** Open text search term */
  searchTerm2?: Maybe<Scalars['String']['output']>;
  /** house number and street name */
  streetAddress?: Maybe<Scalars['String']['output']>;
  /** SAP Supplier code */
  supplierCode?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Validity end date */
  validityEnd?: Maybe<Scalars['DateTime']['output']>;
  /** Validity start date */
  validityStart?: Maybe<Scalars['DateTime']['output']>;
};

export type BusinessPartnerCreateInput = {
  /** Timezone */
  addressTimezone?: InputMaybe<Scalars['String']['input']>;
  /** SAP address unique identifier */
  addressUUID?: InputMaybe<Scalars['String']['input']>;
  /** Name of city */
  cityName?: InputMaybe<Scalars['String']['input']>;
  /** Entity code */
  code: Scalars['String']['input'];
  /** Country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** SAP Customer Code */
  customerCode?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  defaultPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Often used for county, prefecture, etc. */
  district?: InputMaybe<Scalars['String']['input']>;
  /** Email address */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Language */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Entity's human readable name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Postal code */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Region / State */
  region?: InputMaybe<Scalars['String']['input']>;
  /** Open text search term */
  searchTerm1?: InputMaybe<Scalars['String']['input']>;
  /** Open text search term */
  searchTerm2?: InputMaybe<Scalars['String']['input']>;
  /** house number and street name */
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  /** SAP Supplier code */
  supplierCode?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  /** Validity end date */
  validityEnd?: InputMaybe<Scalars['DateTime']['input']>;
  /** Validity start date */
  validityStart?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BusinessPartnerCreateOneInput = {
  /** The record to create */
  businessPartner: BusinessPartnerCreateInput;
};

export type BusinessPartnerFilter = {
  addressTimezone?: InputMaybe<StringFieldComparison>;
  addressUUID?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<BusinessPartnerFilter>>;
  cityName?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  country?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  customerCode?: InputMaybe<StringFieldComparison>;
  defaultPhoneNumber?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  district?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  language?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BusinessPartnerFilter>>;
  phoneNumber?: InputMaybe<StringFieldComparison>;
  postalCode?: InputMaybe<StringFieldComparison>;
  region?: InputMaybe<StringFieldComparison>;
  searchTerm1?: InputMaybe<StringFieldComparison>;
  searchTerm2?: InputMaybe<StringFieldComparison>;
  streetAddress?: InputMaybe<StringFieldComparison>;
  supplierCode?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  validityEnd?: InputMaybe<DateFieldComparison>;
  validityStart?: InputMaybe<DateFieldComparison>;
};

export type BusinessPartnerOffsetConnection = {
  __typename?: 'BusinessPartnerOffsetConnection';
  /** Array of nodes. */
  nodes: Array<BusinessPartner>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type BusinessPartnerSort = {
  direction: SortDirection;
  field: BusinessPartnerSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BusinessPartnerSortFields {
  AddressTimezone = 'addressTimezone',
  AddressUuid = 'addressUUID',
  CityName = 'cityName',
  Code = 'code',
  Country = 'country',
  CreatedAt = 'createdAt',
  CustomerCode = 'customerCode',
  DefaultPhoneNumber = 'defaultPhoneNumber',
  DeletedAt = 'deletedAt',
  District = 'district',
  Email = 'email',
  Id = 'id',
  Language = 'language',
  Name = 'name',
  PhoneNumber = 'phoneNumber',
  PostalCode = 'postalCode',
  Region = 'region',
  SearchTerm1 = 'searchTerm1',
  SearchTerm2 = 'searchTerm2',
  StreetAddress = 'streetAddress',
  SupplierCode = 'supplierCode',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  ValidityEnd = 'validityEnd',
  ValidityStart = 'validityStart'
}

export type BusinessPartnerUpdateInput = {
  /** Timezone */
  addressTimezone?: InputMaybe<Scalars['String']['input']>;
  /** SAP address unique identifier */
  addressUUID?: InputMaybe<Scalars['String']['input']>;
  /** Name of city */
  cityName?: InputMaybe<Scalars['String']['input']>;
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** SAP Customer Code */
  customerCode?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  defaultPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Often used for county, prefecture, etc. */
  district?: InputMaybe<Scalars['String']['input']>;
  /** Email address */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Language */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Entity's human readable name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Postal code */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Region / State */
  region?: InputMaybe<Scalars['String']['input']>;
  /** Open text search term */
  searchTerm1?: InputMaybe<Scalars['String']['input']>;
  /** Open text search term */
  searchTerm2?: InputMaybe<Scalars['String']['input']>;
  /** house number and street name */
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  /** SAP Supplier code */
  supplierCode?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  /** Validity end date */
  validityEnd?: InputMaybe<Scalars['DateTime']['input']>;
  /** Validity start date */
  validityStart?: InputMaybe<Scalars['DateTime']['input']>;
};

export type BusinessPartnerUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: BusinessPartnerUpdateInput;
};

export type CancelManyDeliveryItemsDto = {
  /** Entity ID */
  deliveryItemId: Scalars['ID']['input'];
};

export type CancelPutawayTaskInputDto = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: TaskCancelPutawayInput;
};

/** Company model */
export type Company = {
  __typename?: 'Company';
  /** Entity code */
  code: Scalars['String']['output'];
  contactInfo?: Maybe<ContactInfo>;
  /** Entity's contact info ID (foreign key) */
  contactInfoId?: Maybe<Scalars['ID']['output']>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  displayPreference?: Maybe<DisplayPreference>;
  displayPreferenceId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** ApiDocs */
  name: Scalars['String']['output'];
  organization?: Maybe<Organization>;
  /** Organization id */
  organizationId?: Maybe<Scalars['ID']['output']>;
  products?: Maybe<ProductNodes>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  warehouses?: Maybe<WarehouseNodes>;
};

export type CompanyCreateInput = {
  /** Entity code */
  code: Scalars['String']['input'];
  /** Enity's contact info */
  contactInfoId?: InputMaybe<Scalars['ID']['input']>;
  /** Enity's display preferences */
  displayPreferenceId?: InputMaybe<Scalars['ID']['input']>;
  /** ApiDocs */
  name: Scalars['String']['input'];
  /** Entity's organization ID (foreign key) */
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type CompanyCreateOneInput = {
  /** The record to create */
  company: CompanyCreateInput;
};

export type CompanyFilter = {
  and?: InputMaybe<Array<CompanyFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  contactInfoId?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<CompanyFilter>>;
  organizationId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type CompanyNodes = {
  __typename?: 'CompanyNodes';
  nodes: Array<Company>;
};

export type CompanyOffsetConnection = {
  __typename?: 'CompanyOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Company>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CompanySort = {
  direction: SortDirection;
  field: CompanySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CompanySortFields {
  Code = 'code',
  ContactInfoId = 'contactInfoId',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Name = 'name',
  OrganizationId = 'organizationId',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type CompanyUpdateInput = {
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Enity's contact info */
  contactInfoId?: InputMaybe<Scalars['ID']['input']>;
  /** Enity's display preferences */
  displayPreferenceId?: InputMaybe<Scalars['ID']['input']>;
  /** ApiDocs */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Entity's organization ID (foreign key) */
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type CompanyUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: CompanyUpdateInput;
};

export type CompleteBarcodeEnrollmentDto = {
  /** Barcode entity ID */
  barcodeId: Scalars['ID']['input'];
  /** Entity ID */
  businessPartnerIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  /** Field assignment to application identifiers */
  fields: Array<BarcodeField>;
};

export type CompleteBarcodeEnrollmentInputDto = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: CompleteBarcodeEnrollmentDto;
};

export type CompleteBinPiTaskInputDto = {
  /** Bin Stock */
  inventory: Array<BinStockDetailDto>;
  /** Entity ID */
  taskId: Scalars['ID']['input'];
  /** Unknown Bin Stock */
  unknownInventory?: InputMaybe<Array<UnknownBinStockDetailDto>>;
};

export type CompleteBinPiTaskResponseItem = {
  __typename?: 'CompleteBinPITaskResponseItem';
  /** Entity ID */
  deliveryItemId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  fulfillmentItemId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  licensePlateId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  lotId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  productId?: Maybe<Scalars['ID']['output']>;
  quantity?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  stockStatusId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  unitOfMeasureId?: Maybe<Scalars['ID']['output']>;
};

export type CompleteBinPiTaskResponseObject = {
  __typename?: 'CompleteBinPITaskResponseObject';
  /** Added Stock */
  additions: Array<CompleteBinPiTaskResponseItem>;
  /** Expected Stock */
  expected: Array<CompleteBinPiTaskResponseItem>;
  /** Missing Stock */
  missing: Array<CompleteBinPiTaskResponseItem>;
};

export type CompleteBinToBinTaskInputDto = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: TaskCompleteBinToBinInput;
};

export type CompletePiTaskInputDto = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: TaskCompletePiInput;
};

export type CompletePickTaskInputDto = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: TaskCompletePickInput;
};

export type CompletePickToStagingTaskInput = {
  /** Unit of measure ID */
  completedInUnitOfMeasureId?: InputMaybe<Scalars['ID']['input']>;
  /** Source License Plate Id */
  licensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** Quantity of product */
  quantity?: InputMaybe<Scalars['String']['input']>;
  /** Source bin ID (foreign key) */
  sourceBinId: Scalars['ID']['input'];
  /** Source lot ID (foreign key) */
  sourceLotId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's task ID (foreign key) */
  taskId: Scalars['ID']['input'];
};

export type CompletePutawayTaskInputDto = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: TaskCompletePutawayInput;
};

/** Contact Info model */
export type ContactInfo = {
  __typename?: 'ContactInfo';
  /** Town or City */
  city?: Maybe<Scalars['String']['output']>;
  /** Country */
  country?: Maybe<Scalars['String']['output']>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Contact email address */
  email?: Maybe<Scalars['String']['output']>;
  /** Fax Number */
  fax?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** State */
  state?: Maybe<Scalars['String']['output']>;
  /** Street 1 */
  street1?: Maybe<Scalars['String']['output']>;
  /** Street 2 */
  street2?: Maybe<Scalars['String']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Zip code */
  zip?: Maybe<Scalars['String']['output']>;
};

export type ContactInfoCreateOneInput = {
  /** The record to create */
  contactInfo: CreateContactInfoDto;
};

export type ContactInfoFilter = {
  and?: InputMaybe<Array<ContactInfoFilter>>;
  city?: InputMaybe<StringFieldComparison>;
  country?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  fax?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ContactInfoFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  state?: InputMaybe<StringFieldComparison>;
  street1?: InputMaybe<StringFieldComparison>;
  street2?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  zip?: InputMaybe<StringFieldComparison>;
};

export type ContactInfoOffsetConnection = {
  __typename?: 'ContactInfoOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ContactInfo>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ContactInfoSort = {
  direction: SortDirection;
  field: ContactInfoSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ContactInfoSortFields {
  City = 'city',
  Country = 'country',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Email = 'email',
  Fax = 'fax',
  Id = 'id',
  Phone = 'phone',
  State = 'state',
  Street1 = 'street1',
  Street2 = 'street2',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  Zip = 'zip'
}

export type ContactInfoUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: UpdateContactInfoDto;
};

export type CopyStockStatusTypeInputDto = {
  /** The record to create */
  copyStockStatus: StockStatusTypeCopy;
};

export type CountQuery = {
  body: SearchBodyQuery;
  index?: InputMaybe<Array<EsIndices>>;
};

export type CreateAndCompleteOneFoundStockTaskDto = {
  /** Entity's team ID (foreign key) */
  assignedTeamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  /** Delivery or fulfillment erp code */
  fulfillmentCode: Scalars['String']['input'];
  /** Destination license plate description */
  licensePlateDescription?: InputMaybe<Scalars['String']['input']>;
  /** Entity's license plate ID (foreign key) */
  licensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** ERP delivery block */
  licensePlateStatus?: InputMaybe<LicensePlateStatusState>;
  /** Source lot ID (foreign key) */
  lotId?: InputMaybe<Scalars['ID']['input']>;
  /** Product code */
  productCode: Scalars['String']['input'];
  /** Quantity of product */
  quantity: Scalars['String']['input'];
  /** Entity's stock status type ID (foreign key) */
  stockStatusId: Scalars['ID']['input'];
};

export type CreateBarcodeEnrollmentInput = {
  /** Entity ID */
  businessPartnerId?: InputMaybe<Scalars['ID']['input']>;
  /** Data payload from a barcode scan */
  payload: Scalars['JSONObject']['input'];
  /** Barcode scanner type */
  scanner: BarcodeScanner;
};

export type CreateBarcodeEnrollmentInputDto = {
  /** barcode enrollment dto */
  enrollment: CreateBarcodeEnrollmentInput;
};

export type CreateBinPiTaskInputDto = {
  /** The record to create */
  physicalInventoryByBin: TaskCreateBinPiInput;
};

export type CreateBinToBinTaskInputDto = {
  /** The record to create */
  binToBinMovement: TaskCreateBinToBinInput;
};

export type CreateContactInfoDto = {
  /** Town or City */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Contact email address */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Fax Number */
  fax?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** State */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Street 1 */
  street1?: InputMaybe<Scalars['String']['input']>;
  /** Street 2 */
  street2?: InputMaybe<Scalars['String']['input']>;
  /** Zip code */
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type CreateOneFulfillmentBlockInput = {
  /** The record to create */
  fulfillmentBlock: FulfillmentBlockCreateInput;
};

export type CreateOneIssueStockTaskDto = {
  /** Entity's team ID (foreign key) */
  assignedTeamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  /** Autocomplete the task on creation */
  autocomplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Delivery item ID (foreign key) */
  deliveryItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Fulfillment item id (foreign key) */
  fulfillmentItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's product ID (foreign key) */
  productId: Scalars['ID']['input'];
  /** Quantity of product */
  quantity: Scalars['String']['input'];
  /** Entity's bin ID (foreign key) */
  sourceBinId: Scalars['ID']['input'];
  /** Entity's license plate ID (foreign key) */
  sourceLicensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** Source lot ID (foreign key) */
  sourceLotId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's stock status type ID (foreign key) */
  sourceStockStatusId: Scalars['ID']['input'];
};

export type CreateOneTaskDispositionInput = {
  /** The record to create */
  taskDisposition: CreateTaskDisposition;
};

export type CreateOneTaskLicensePlateStockStatusInput = {
  /** The record to create */
  taskLicensePlateStockStatus: CreateTaskLicensePlateStockStatus;
};

export type CreatePiTaskInputDto = {
  /** The record to create */
  physicalInventory: TaskCreatePiInput;
};

export type CreatePickTaskInputDto = {
  /** The record to create */
  fulfillment: TaskCreatePickInput;
};

export type CreatePutawayTaskInputDto = {
  /** The record to create */
  delivery: TaskCreatePutawayInput;
};

export type CreateStockFromProductionTaskInputDto = {
  /** Dto Wrapper */
  createStockFromProduction: TaskCreateStockFromProductionDto;
};

export type CreateTaskDisposition = {
  /** Entity's team ID (foreign key) */
  assignedTeamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  /** ID of stored image */
  images?: InputMaybe<Array<TaskDispositionImages>>;
  /** Entity's license plate ID (foreign key) */
  licensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** Notes for a submitted disposition */
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTaskLicensePlateStockStatus = {
  /** Entity's team ID (foreign key) */
  assignedTeamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  /** Autocomplete the task on creation */
  autocomplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID of stored image */
  images?: InputMaybe<Array<TaskLicensePlateStockStatusImages>>;
  /** Entity's license plate ID (foreign key) */
  licensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** Notes for a submitted disposition */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Entity's stock status type ID (foreign key) */
  stockStatusId?: InputMaybe<Scalars['ID']['input']>;
};

export enum DataGenerationType {
  Demo = 'demo',
  Testing = 'testing'
}

export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DeleteOneBarcodeInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
};

export type DeleteOneEntityIdInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
};

export type DeleteOneFulfillmentBlockInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
};

/** Delivery model */
export type Delivery = {
  __typename?: 'Delivery';
  availability?: Maybe<Scalars['Int']['output']>;
  /** Bill of Lading */
  billOfLading?: Maybe<Scalars['String']['output']>;
  /** ERP delivery block */
  blockStatus?: Maybe<Scalars['String']['output']>;
  /** Delivery category */
  category?: Maybe<DeliveryCategory>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deliveryItems?: Maybe<DeliveryItemNodes>;
  /** Overall status for the delivery or fulfillment */
  deliveryStatus?: Maybe<DeliveryCompletionStatus>;
  door?: Maybe<Door>;
  /** Entity's door ID (foreign key) */
  doorId?: Maybe<Scalars['ID']['output']>;
  /** Expected delivery date */
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  /** Reason blocked in ERP */
  erpBlockingReason?: Maybe<Scalars['String']['output']>;
  /** Erp Delivery Code */
  erpCode: Scalars['String']['output'];
  /** Date created in ERP */
  erpCreated?: Maybe<Scalars['DateTime']['output']>;
  /** When data was last updated in ERP */
  erpLastChanged?: Maybe<Scalars['DateTime']['output']>;
  erpPurchaseOrder?: Maybe<Scalars['String']['output']>;
  /** Reference document */
  erpSalesOrder?: Maybe<Scalars['String']['output']>;
  /** Export */
  export?: Maybe<Scalars['String']['output']>;
  fulfillmentBlock?: Maybe<FulfillmentBlock>;
  /** Fulfillment Block */
  fulfillmentBlockId?: Maybe<Scalars['ID']['output']>;
  fulfillmentItems?: Maybe<FulfillmentItemNodes>;
  /** Goods receipt or goods issue status */
  goodsReceiptOrIssueStatus?: Maybe<DeliveryCompletionStatus>;
  hasTasks: Scalars['Boolean']['output'];
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Load/unload status */
  loadOrUnloadStatus?: Maybe<DeliveryCompletionStatus>;
  orderConfirmationFile?: Maybe<WarehouseOpFile>;
  /** ID for a warehouse op file */
  orderConfirmationFileId?: Maybe<Scalars['ID']['output']>;
  /** Picking or putaway status */
  pickOrPutawayStatus?: Maybe<DeliveryCompletionStatus>;
  /** Point of contact */
  pointOfContact?: Maybe<Scalars['String']['output']>;
  progress?: Maybe<Scalars['Float']['output']>;
  progressString?: Maybe<DeliveryCompletionStatus>;
  /** Delivery promise date */
  promiseDate?: Maybe<Scalars['DateTime']['output']>;
  /** Date when all line items are availalbe 100% */
  shipReadyDate?: Maybe<Scalars['DateTime']['output']>;
  /**
   * Ship to party
   * @deprecated Please access this value through business partner relationship shipToBusinessPartnerId or via delivery view.
   */
  shipTo?: Maybe<Scalars['String']['output']>;
  shipToBusinessPartner?: Maybe<BusinessPartner>;
  /** Ship to Business Partner unique Id */
  shipToBusinessPartnerId?: Maybe<Scalars['ID']['output']>;
  /**
   * Ship to party name
   * @deprecated Please access this value through business partner relationship soldToBusinessPartnerId or via delivery view.
   */
  shipToName?: Maybe<Scalars['String']['output']>;
  /**
   * Sold to party
   * @deprecated Please access this value through business partner relationship soldToBusinessPartnerId or via delivery view.
   */
  soldTo?: Maybe<Scalars['String']['output']>;
  soldToBusinessPartner?: Maybe<BusinessPartner>;
  /** Sold to Business Partner unique id */
  soldToBusinessPartnerId?: Maybe<Scalars['ID']['output']>;
  /**
   * Sold to party name
   * @deprecated Please access this value through business partner relationship soldToBusinessPartnerId or via delivery view.
   */
  soldToName?: Maybe<Scalars['String']['output']>;
  /**
   * Supplier
   * @deprecated Please access this value through business partner relationship supplierBusinessPartnerId or via delivery view.
   */
  supplier?: Maybe<Scalars['String']['output']>;
  supplierBusinessPartner?: Maybe<BusinessPartner>;
  /** Supplier Business Partner unique Id */
  supplierBusinessPartnerId?: Maybe<Scalars['ID']['output']>;
  /**
   * Supplier name
   * @deprecated Please access this value through business partner relationship supplierBusinessPartnerId or via delivery view.
   */
  supplierName?: Maybe<Scalars['String']['output']>;
  /** Total gross weight */
  totalGrossWeight?: Maybe<Scalars['Float']['output']>;
  /** Total net weight */
  totalNetWeight?: Maybe<Scalars['Float']['output']>;
  /** Volume */
  totalVolume?: Maybe<Scalars['Float']['output']>;
  totalVolumeUOM?: Maybe<UnitOfMeasureGlossary>;
  /** Volume unit of measure */
  totalVolumeUOMId?: Maybe<Scalars['ID']['output']>;
  totalWeightUOM?: Maybe<UnitOfMeasureGlossary>;
  /** Total gross weight unit of measure */
  totalWeightUOMId?: Maybe<Scalars['ID']['output']>;
  /** Type of delivery (i.e. delivery vs fulfillment) */
  type: DeliveryType;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  warehouse?: Maybe<Warehouse>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
};

export enum DeliveryCategory {
  Return = 'return',
  Vendor = 'vendor'
}

export type DeliveryCategoryFilterComparison = {
  eq?: InputMaybe<DeliveryCategory>;
  gt?: InputMaybe<DeliveryCategory>;
  gte?: InputMaybe<DeliveryCategory>;
  iLike?: InputMaybe<DeliveryCategory>;
  in?: InputMaybe<Array<DeliveryCategory>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<DeliveryCategory>;
  lt?: InputMaybe<DeliveryCategory>;
  lte?: InputMaybe<DeliveryCategory>;
  neq?: InputMaybe<DeliveryCategory>;
  notILike?: InputMaybe<DeliveryCategory>;
  notIn?: InputMaybe<Array<DeliveryCategory>>;
  notLike?: InputMaybe<DeliveryCategory>;
};

export enum DeliveryCompletionStatus {
  Cancelled = 'cancelled',
  Complete = 'complete',
  InProgress = 'inProgress',
  NotStarted = 'notStarted'
}

export type DeliveryCompletionStatusFilterComparison = {
  eq?: InputMaybe<DeliveryCompletionStatus>;
  gt?: InputMaybe<DeliveryCompletionStatus>;
  gte?: InputMaybe<DeliveryCompletionStatus>;
  iLike?: InputMaybe<DeliveryCompletionStatus>;
  in?: InputMaybe<Array<DeliveryCompletionStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<DeliveryCompletionStatus>;
  lt?: InputMaybe<DeliveryCompletionStatus>;
  lte?: InputMaybe<DeliveryCompletionStatus>;
  neq?: InputMaybe<DeliveryCompletionStatus>;
  notILike?: InputMaybe<DeliveryCompletionStatus>;
  notIn?: InputMaybe<Array<DeliveryCompletionStatus>>;
  notLike?: InputMaybe<DeliveryCompletionStatus>;
};

export type DeliveryCreateInput = {
  /** Bill of Lading */
  billOfLading?: InputMaybe<Scalars['String']['input']>;
  /** ERP delivery block */
  blockStatus?: InputMaybe<Scalars['String']['input']>;
  /** Overall status for the delivery or fulfillment */
  deliveryStatus?: InputMaybe<DeliveryCompletionStatus>;
  /** Entity's door ID (foreign key) */
  doorId?: InputMaybe<Scalars['ID']['input']>;
  /** Expected delivery date */
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Reason blocked in ERP */
  erpBlockingReason?: InputMaybe<Scalars['String']['input']>;
  /** Erp Delivery Code */
  erpCode?: InputMaybe<Scalars['String']['input']>;
  /** Date created in ERP */
  erpCreated?: InputMaybe<Scalars['DateTime']['input']>;
  /** When data was last updated in ERP */
  erpLastChanged?: InputMaybe<Scalars['DateTime']['input']>;
  /** Reference document */
  erpSalesOrder?: InputMaybe<Scalars['String']['input']>;
  /** Export */
  export?: InputMaybe<Scalars['String']['input']>;
  /** Fulfilment Block ID */
  fulfillmentBlockId?: InputMaybe<Scalars['ID']['input']>;
  /** Goods receipt or goods issue status */
  goodsReceiptOrIssueStatus?: InputMaybe<DeliveryCompletionStatus>;
  /** Load/unload status */
  loadOrUnloadStatus?: InputMaybe<DeliveryCompletionStatus>;
  /** Picking or putaway status */
  pickOrPutawayStatus?: InputMaybe<DeliveryCompletionStatus>;
  /** Person to contact to learn more about delivery */
  pointOfContact?: InputMaybe<Scalars['String']['input']>;
  /** Date delivery has been promised */
  promiseDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Date when all items became 100% available */
  shipReadyDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Ship to party */
  shipTo?: InputMaybe<Scalars['String']['input']>;
  /** Ship to Business Partner unique Id */
  shipToBusinessPartnerId?: InputMaybe<Scalars['ID']['input']>;
  /** Ship to party name */
  shipToName?: InputMaybe<Scalars['String']['input']>;
  /** Sold to party */
  soldTo?: InputMaybe<Scalars['String']['input']>;
  /** Sold to Business Partner unique id */
  soldToBusinessPartnerId?: InputMaybe<Scalars['ID']['input']>;
  /** Sold to party name */
  soldToName?: InputMaybe<Scalars['String']['input']>;
  /** Supplier */
  supplier?: InputMaybe<Scalars['String']['input']>;
  /** Supplier Business Partner unique Id */
  supplierBusinessPartnerId?: InputMaybe<Scalars['ID']['input']>;
  /** Supplier name */
  supplierName?: InputMaybe<Scalars['String']['input']>;
  /** Total gross weight */
  totalGrossWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Total net weight */
  totalNetWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Volume */
  totalVolume?: InputMaybe<Scalars['Float']['input']>;
  /** Volume unit of measure */
  totalVolumeUOMId?: InputMaybe<Scalars['ID']['input']>;
  /** Total gross weight unit of measure */
  totalWeightUOMId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type DeliveryCreateOneInput = {
  /** The record to create */
  delivery: DeliveryCreateInput;
};

export type DeliveryFilter = {
  and?: InputMaybe<Array<DeliveryFilter>>;
  billOfLading?: InputMaybe<StringFieldComparison>;
  blockStatus?: InputMaybe<StringFieldComparison>;
  category?: InputMaybe<DeliveryCategoryFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  deliveryStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  doorId?: InputMaybe<IdFilterComparison>;
  dueDate?: InputMaybe<DateFieldComparison>;
  erpBlockingReason?: InputMaybe<StringFieldComparison>;
  erpCode?: InputMaybe<StringFieldComparison>;
  erpCreated?: InputMaybe<DateFieldComparison>;
  erpLastChanged?: InputMaybe<DateFieldComparison>;
  erpPurchaseOrder?: InputMaybe<StringFieldComparison>;
  erpSalesOrder?: InputMaybe<StringFieldComparison>;
  export?: InputMaybe<StringFieldComparison>;
  fulfillmentBlockId?: InputMaybe<IdFilterComparison>;
  goodsReceiptOrIssueStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  loadOrUnloadStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  or?: InputMaybe<Array<DeliveryFilter>>;
  orderConfirmationFileId?: InputMaybe<IdFilterComparison>;
  pickOrPutawayStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  pointOfContact?: InputMaybe<StringFieldComparison>;
  promiseDate?: InputMaybe<DateFieldComparison>;
  shipReadyDate?: InputMaybe<DateFieldComparison>;
  shipTo?: InputMaybe<StringFieldComparison>;
  shipToBusinessPartnerId?: InputMaybe<IdFilterComparison>;
  shipToName?: InputMaybe<StringFieldComparison>;
  soldTo?: InputMaybe<StringFieldComparison>;
  soldToBusinessPartnerId?: InputMaybe<IdFilterComparison>;
  soldToName?: InputMaybe<StringFieldComparison>;
  supplier?: InputMaybe<StringFieldComparison>;
  supplierBusinessPartnerId?: InputMaybe<IdFilterComparison>;
  supplierName?: InputMaybe<StringFieldComparison>;
  totalGrossWeight?: InputMaybe<FloatFieldComparison>;
  totalNetWeight?: InputMaybe<FloatFieldComparison>;
  totalVolume?: InputMaybe<FloatFieldComparison>;
  totalVolumeUOMId?: InputMaybe<IdFilterComparison>;
  totalWeightUOMId?: InputMaybe<IdFilterComparison>;
  type?: InputMaybe<DeliveryTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

/** DeliveryItem model */
export type DeliveryItem = {
  __typename?: 'DeliveryItem';
  /** Actual remaining quantity */
  actualRecievedQuantity?: Maybe<Scalars['String']['output']>;
  /** ID for a warehouse op file */
  advancedShipmentNotificationFileId?: Maybe<Scalars['ID']['output']>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  delivery?: Maybe<Delivery>;
  /** Delivery Code */
  deliveryCode: Scalars['String']['output'];
  /** Delivery ID (foreign key) */
  deliveryId: Scalars['ID']['output'];
  /** Delivery Item */
  deliveryItem: Scalars['String']['output'];
  /** Delivery Item Description */
  description?: Maybe<Scalars['String']['output']>;
  /** ERP Created Date */
  erpCreated?: Maybe<Scalars['DateTime']['output']>;
  /** Erp last changed date */
  erpLastChanged?: Maybe<Scalars['DateTime']['output']>;
  /**
   * Purchase Order
   * @deprecated Update to reference parent delivery purchase order.
   */
  erpPurchaseOrder?: Maybe<Scalars['String']['output']>;
  /** Purchase Order item */
  erpPurchaseOrderItem?: Maybe<Scalars['String']['output']>;
  /** Gross Weight */
  grossWeight?: Maybe<Scalars['Float']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity's lot ID (foreign key) */
  licensePlateId?: Maybe<Scalars['ID']['output']>;
  /** Entity's lot ID (foreign key) */
  licensePlateLevel?: Maybe<Scalars['ID']['output']>;
  lot?: Maybe<Lot>;
  /** Entity's lot ID (foreign key) */
  lotId?: Maybe<Scalars['ID']['output']>;
  /** Net Weight */
  netWeight?: Maybe<Scalars['Float']['output']>;
  product?: Maybe<Product>;
  /** Entity's product ID (foreign key) */
  productId?: Maybe<Scalars['ID']['output']>;
  /** Putaway Status */
  putawayStatus?: Maybe<DeliveryCompletionStatus>;
  /** Phone */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** Receipt Status */
  receiptStatus?: Maybe<DeliveryCompletionStatus>;
  stockStatusType?: Maybe<StockStatusType>;
  /** Stock Status Type id */
  stockStatusTypeId?: Maybe<Scalars['String']['output']>;
  /** Supplier lot */
  supplierLot?: Maybe<Scalars['String']['output']>;
  /** Unit of measure ID */
  unitOfMeasureId: Scalars['ID']['output'];
  /** Unload status */
  unloadStatus?: Maybe<DeliveryCompletionStatus>;
  /** Unit of measure */
  uom?: Maybe<Scalars['String']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Volume */
  volume?: Maybe<Scalars['Float']['output']>;
  /** Volume unit of measure */
  volumeUOMId?: Maybe<Scalars['ID']['output']>;
  /** Weight unit of measure */
  weightUOMId?: Maybe<Scalars['ID']['output']>;
};

export type DeliveryItemCreateInput = {
  /** Actual recieved quantity */
  actualRecievedQuantity?: InputMaybe<Scalars['String']['input']>;
  /** Delivery code */
  deliveryCode: Scalars['String']['input'];
  /** Delivery ID (foreign key) */
  deliveryId: Scalars['ID']['input'];
  /** Delivery item */
  deliveryItem: Scalars['String']['input'];
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ERP Created */
  erpCreated?: InputMaybe<Scalars['DateTime']['input']>;
  /** When data was last updated in ERP */
  erpLastChanged?: InputMaybe<Scalars['String']['input']>;
  /** Purchase order */
  erpPurchaseOrder?: InputMaybe<Scalars['String']['input']>;
  /** Purchase order item */
  erpPurchaseOrderItem?: InputMaybe<Scalars['String']['input']>;
  /** Gross weight of entity */
  grossWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Entity's lot ID (foreign key) */
  lotId?: InputMaybe<Scalars['ID']['input']>;
  /** Net weight of entity */
  netWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Entity's product ID (foreign key) */
  productId: Scalars['ID']['input'];
  /** Putaway status */
  putawayStatus?: InputMaybe<DeliveryCompletionStatus>;
  /** Quantity */
  quantity?: InputMaybe<Scalars['String']['input']>;
  /** Receipt status */
  receiptStatus?: InputMaybe<DeliveryCompletionStatus>;
  /** Supplier Lot */
  supplierLot?: InputMaybe<Scalars['String']['input']>;
  /** Unit of measure */
  uom?: InputMaybe<Scalars['String']['input']>;
  /** Volume of entity */
  volume?: InputMaybe<Scalars['Float']['input']>;
  /** Volume in unit of measure for entity */
  volumeUomId?: InputMaybe<Scalars['String']['input']>;
  /** Weight UOM, i.e KG */
  weightUomId?: InputMaybe<Scalars['String']['input']>;
};

export type DeliveryItemCreateOneInput = {
  /** The record to create */
  deliveryItem: DeliveryItemCreateInput;
};

export type DeliveryItemDetails = {
  __typename?: 'DeliveryItemDetails';
  /** Delivery item associated with entity */
  deliveryItem: Scalars['String']['output'];
  /** Entity code */
  destinationBinCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  destinationBinId?: Maybe<Scalars['ID']['output']>;
  /** Delivery item ID (foreign key) */
  id: Scalars['ID']['output'];
  /** Entity code */
  licensePlateCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  licensePlateDescription?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  licensePlateId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  lotCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  lotId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  productCode: Scalars['String']['output'];
  /** Description of entity */
  productDescription?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  productId: Scalars['ID']['output'];
  /** Quantity of product */
  quantity: Scalars['String']['output'];
  /** Entity code */
  sourceBinCode?: Maybe<Scalars['String']['output']>;
  /** Entity's bin ID (foreign key) */
  sourceBinId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  stockStatusCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  stockStatusId?: Maybe<Scalars['ID']['output']>;
};

export type DeliveryItemFilter = {
  actualRecievedQuantity?: InputMaybe<StringFieldComparison>;
  advancedShipmentNotificationFileId?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<DeliveryItemFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  deliveryCode?: InputMaybe<StringFieldComparison>;
  deliveryId?: InputMaybe<IdFilterComparison>;
  deliveryItem?: InputMaybe<StringFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  erpCreated?: InputMaybe<DateFieldComparison>;
  erpLastChanged?: InputMaybe<DateFieldComparison>;
  erpPurchaseOrder?: InputMaybe<StringFieldComparison>;
  erpPurchaseOrderItem?: InputMaybe<StringFieldComparison>;
  grossWeight?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  licensePlateId?: InputMaybe<IdFilterComparison>;
  licensePlateLevel?: InputMaybe<IdFilterComparison>;
  lotId?: InputMaybe<IdFilterComparison>;
  netWeight?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<DeliveryItemFilter>>;
  productId?: InputMaybe<IdFilterComparison>;
  putawayStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  quantity?: InputMaybe<IntFieldComparison>;
  receiptStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  stockStatusTypeId?: InputMaybe<StringFieldComparison>;
  supplierLot?: InputMaybe<StringFieldComparison>;
  unitOfMeasureId?: InputMaybe<IdFilterComparison>;
  unloadStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  uom?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  volume?: InputMaybe<FloatFieldComparison>;
  volumeUOMId?: InputMaybe<IdFilterComparison>;
  weightUOMId?: InputMaybe<IdFilterComparison>;
};

export type DeliveryItemNodes = {
  __typename?: 'DeliveryItemNodes';
  nodes: Array<DeliveryItem>;
  totalCount: Scalars['Int']['output'];
};

export type DeliveryItemOffsetConnection = {
  __typename?: 'DeliveryItemOffsetConnection';
  /** Array of nodes. */
  nodes: Array<DeliveryItem>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type DeliveryItemSearchResults = {
  __typename?: 'DeliveryItemSearchResults';
  deliveryItem?: Maybe<Scalars['String']['output']>;
  erpPurchaseOrder?: Maybe<Scalars['String']['output']>;
  erpPurchaseOrderItem?: Maybe<Scalars['String']['output']>;
  lot?: Maybe<Scalars['String']['output']>;
  productCode?: Maybe<Scalars['String']['output']>;
  productDescription?: Maybe<Scalars['String']['output']>;
  putawayStatus?: Maybe<Scalars['String']['output']>;
  receiptStatus?: Maybe<Scalars['String']['output']>;
  supplierLot?: Maybe<Scalars['String']['output']>;
};

export type DeliveryItemSort = {
  direction: SortDirection;
  field: DeliveryItemSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DeliveryItemSortFields {
  ActualRecievedQuantity = 'actualRecievedQuantity',
  AdvancedShipmentNotificationFileId = 'advancedShipmentNotificationFileId',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  DeliveryCode = 'deliveryCode',
  DeliveryId = 'deliveryId',
  DeliveryItem = 'deliveryItem',
  Description = 'description',
  ErpCreated = 'erpCreated',
  ErpLastChanged = 'erpLastChanged',
  ErpPurchaseOrder = 'erpPurchaseOrder',
  ErpPurchaseOrderItem = 'erpPurchaseOrderItem',
  GrossWeight = 'grossWeight',
  Id = 'id',
  LicensePlateId = 'licensePlateId',
  LicensePlateLevel = 'licensePlateLevel',
  LotId = 'lotId',
  NetWeight = 'netWeight',
  ProductId = 'productId',
  PutawayStatus = 'putawayStatus',
  Quantity = 'quantity',
  ReceiptStatus = 'receiptStatus',
  StockStatusTypeId = 'stockStatusTypeId',
  SupplierLot = 'supplierLot',
  UnitOfMeasureId = 'unitOfMeasureId',
  UnloadStatus = 'unloadStatus',
  Uom = 'uom',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  Volume = 'volume',
  VolumeUomId = 'volumeUOMId',
  WeightUomId = 'weightUOMId'
}

export type DeliveryItemUpdateInput = {
  /** Actual recieved quantity */
  actualRecievedQuantity?: InputMaybe<Scalars['String']['input']>;
  /** Delivery code */
  deliveryCode?: InputMaybe<Scalars['String']['input']>;
  /** Delivery ID (foreign key) */
  deliveryId?: InputMaybe<Scalars['ID']['input']>;
  /** Delivery item */
  deliveryItem?: InputMaybe<Scalars['String']['input']>;
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ERP Created */
  erpCreated?: InputMaybe<Scalars['DateTime']['input']>;
  /** When data was last updated in ERP */
  erpLastChanged?: InputMaybe<Scalars['String']['input']>;
  /** Purchase order */
  erpPurchaseOrder?: InputMaybe<Scalars['String']['input']>;
  /** Purchase order item */
  erpPurchaseOrderItem?: InputMaybe<Scalars['String']['input']>;
  /** Gross weight of entity */
  grossWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Entity's lot ID (foreign key) */
  lotId?: InputMaybe<Scalars['ID']['input']>;
  /** Net weight of entity */
  netWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Entity's product ID (foreign key) */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Putaway status */
  putawayStatus?: InputMaybe<DeliveryCompletionStatus>;
  /** Quantity */
  quantity?: InputMaybe<Scalars['String']['input']>;
  /** Receipt status */
  receiptStatus?: InputMaybe<DeliveryCompletionStatus>;
  /** Supplier Lot */
  supplierLot?: InputMaybe<Scalars['String']['input']>;
  /** Unit of measure */
  uom?: InputMaybe<Scalars['String']['input']>;
  /** Volume of entity */
  volume?: InputMaybe<Scalars['Float']['input']>;
  /** Volume in unit of measure for entity */
  volumeUomId?: InputMaybe<Scalars['String']['input']>;
  /** Weight UOM, i.e KG */
  weightUomId?: InputMaybe<Scalars['String']['input']>;
};

export type DeliveryItemUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: DeliveryItemUpdateInput;
};

export type DeliveryOffsetConnection = {
  __typename?: 'DeliveryOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Delivery>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type DeliveryProcessReturnDto = {
  /** Return item */
  returnItems: Array<DeliveryReturnItemDto>;
  /** Unknown return item */
  unknownReturnItems?: InputMaybe<Array<UnknownDeliveryReturnItemDto>>;
  /** Entity ID */
  warehouseId: Scalars['ID']['input'];
};

export type DeliveryReturnItemDto = {
  /** Entity ID */
  licensePlateId?: InputMaybe<Scalars['ID']['input']>;
};

/** Delivery Search results */
export type DeliverySearchResults = {
  __typename?: 'DeliverySearchResults';
  billOfLading?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  deliveryItems?: Maybe<Array<DeliveryItemSearchResults>>;
  deliveryStatus?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  erpCode?: Maybe<Scalars['String']['output']>;
  erpPurchaseOrder?: Maybe<Scalars['String']['output']>;
  erpSalesOrder?: Maybe<Scalars['String']['output']>;
  goodsReceiptStatus?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  index?: Maybe<Scalars['String']['output']>;
  putawayStatus?: Maybe<Scalars['String']['output']>;
  shipTo?: Maybe<Scalars['String']['output']>;
  shipToBusinessPartnerId?: Maybe<Scalars['ID']['output']>;
  shipToName?: Maybe<Scalars['String']['output']>;
  soldTo?: Maybe<Scalars['String']['output']>;
  soldToBusinessPartnerId?: Maybe<Scalars['ID']['output']>;
  soldToName?: Maybe<Scalars['String']['output']>;
  supplier?: Maybe<Scalars['String']['output']>;
  supplierBusinessPartnerId?: Maybe<Scalars['ID']['output']>;
  supplierName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  warehouseCode?: Maybe<Scalars['String']['output']>;
  warehouseName?: Maybe<Scalars['String']['output']>;
};

export type DeliverySort = {
  direction: SortDirection;
  field: DeliverySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DeliverySortFields {
  BillOfLading = 'billOfLading',
  BlockStatus = 'blockStatus',
  Category = 'category',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  DeliveryStatus = 'deliveryStatus',
  DoorId = 'doorId',
  DueDate = 'dueDate',
  ErpBlockingReason = 'erpBlockingReason',
  ErpCode = 'erpCode',
  ErpCreated = 'erpCreated',
  ErpLastChanged = 'erpLastChanged',
  ErpPurchaseOrder = 'erpPurchaseOrder',
  ErpSalesOrder = 'erpSalesOrder',
  Export = 'export',
  FulfillmentBlockId = 'fulfillmentBlockId',
  GoodsReceiptOrIssueStatus = 'goodsReceiptOrIssueStatus',
  Id = 'id',
  LoadOrUnloadStatus = 'loadOrUnloadStatus',
  OrderConfirmationFileId = 'orderConfirmationFileId',
  PickOrPutawayStatus = 'pickOrPutawayStatus',
  PointOfContact = 'pointOfContact',
  PromiseDate = 'promiseDate',
  ShipReadyDate = 'shipReadyDate',
  ShipTo = 'shipTo',
  ShipToBusinessPartnerId = 'shipToBusinessPartnerId',
  ShipToName = 'shipToName',
  SoldTo = 'soldTo',
  SoldToBusinessPartnerId = 'soldToBusinessPartnerId',
  SoldToName = 'soldToName',
  Supplier = 'supplier',
  SupplierBusinessPartnerId = 'supplierBusinessPartnerId',
  SupplierName = 'supplierName',
  TotalGrossWeight = 'totalGrossWeight',
  TotalNetWeight = 'totalNetWeight',
  TotalVolume = 'totalVolume',
  TotalVolumeUomId = 'totalVolumeUOMId',
  TotalWeightUomId = 'totalWeightUOMId',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WarehouseId = 'warehouseId'
}

export enum DeliveryTaskSteps {
  OneStep = 'oneStep',
  TwoStep = 'twoStep'
}

export enum DeliveryType {
  Delivery = 'delivery',
  Fulfillment = 'fulfillment'
}

export type DeliveryTypeFilterComparison = {
  eq?: InputMaybe<DeliveryType>;
  gt?: InputMaybe<DeliveryType>;
  gte?: InputMaybe<DeliveryType>;
  iLike?: InputMaybe<DeliveryType>;
  in?: InputMaybe<Array<DeliveryType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<DeliveryType>;
  lt?: InputMaybe<DeliveryType>;
  lte?: InputMaybe<DeliveryType>;
  neq?: InputMaybe<DeliveryType>;
  notILike?: InputMaybe<DeliveryType>;
  notIn?: InputMaybe<Array<DeliveryType>>;
  notLike?: InputMaybe<DeliveryType>;
};

export type DeliveryUpdateInput = {
  /** Bill of Lading */
  billOfLading?: InputMaybe<Scalars['String']['input']>;
  /** ERP delivery block */
  blockStatus?: InputMaybe<Scalars['String']['input']>;
  /** Overall status for the delivery or fulfillment */
  deliveryStatus?: InputMaybe<DeliveryCompletionStatus>;
  /** Entity's door ID (foreign key) */
  doorId?: InputMaybe<Scalars['ID']['input']>;
  /** Expected delivery date */
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Reason blocked in ERP */
  erpBlockingReason?: InputMaybe<Scalars['String']['input']>;
  /** Erp Delivery Code */
  erpCode?: InputMaybe<Scalars['String']['input']>;
  /** Date created in ERP */
  erpCreated?: InputMaybe<Scalars['DateTime']['input']>;
  /** When data was last updated in ERP */
  erpLastChanged?: InputMaybe<Scalars['DateTime']['input']>;
  /** Reference document */
  erpSalesOrder?: InputMaybe<Scalars['String']['input']>;
  /** Export */
  export?: InputMaybe<Scalars['String']['input']>;
  /** Fulfilment Block ID */
  fulfillmentBlockId?: InputMaybe<Scalars['ID']['input']>;
  /** Goods receipt or goods issue status */
  goodsReceiptOrIssueStatus?: InputMaybe<DeliveryCompletionStatus>;
  /** Load/unload status */
  loadOrUnloadStatus?: InputMaybe<DeliveryCompletionStatus>;
  /** Picking or putaway status */
  pickOrPutawayStatus?: InputMaybe<DeliveryCompletionStatus>;
  /** Person to contact to learn more about delivery */
  pointOfContact?: InputMaybe<Scalars['String']['input']>;
  /** Date delivery has been promised */
  promiseDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Date when all items became 100% available */
  shipReadyDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Ship to party */
  shipTo?: InputMaybe<Scalars['String']['input']>;
  /** Ship to Business Partner unique Id */
  shipToBusinessPartnerId?: InputMaybe<Scalars['ID']['input']>;
  /** Ship to party name */
  shipToName?: InputMaybe<Scalars['String']['input']>;
  /** Sold to party */
  soldTo?: InputMaybe<Scalars['String']['input']>;
  /** Sold to Business Partner unique id */
  soldToBusinessPartnerId?: InputMaybe<Scalars['ID']['input']>;
  /** Sold to party name */
  soldToName?: InputMaybe<Scalars['String']['input']>;
  /** Supplier */
  supplier?: InputMaybe<Scalars['String']['input']>;
  /** Supplier Business Partner unique Id */
  supplierBusinessPartnerId?: InputMaybe<Scalars['ID']['input']>;
  /** Supplier name */
  supplierName?: InputMaybe<Scalars['String']['input']>;
  /** Total gross weight */
  totalGrossWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Total net weight */
  totalNetWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Volume */
  totalVolume?: InputMaybe<Scalars['Float']['input']>;
  /** Volume unit of measure */
  totalVolumeUOMId?: InputMaybe<Scalars['ID']['input']>;
  /** Total gross weight unit of measure */
  totalWeightUOMId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type DeployRunInput = {
  /** abc analysis */
  abcAnalysisIds: Array<Scalars['String']['input']>;
  /** movements */
  movementIds: Array<Scalars['String']['input']>;
  /** Entity ID */
  runId: Scalars['ID']['input'];
};

/** Display preference model */
export type DisplayPreference = {
  __typename?: 'DisplayPreference';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Preferred currency, i.e. USD */
  currency: Scalars['String']['output'];
  /** Preferred date and time format, i.e. MM/dd/yyyy HH:mm */
  dateTimeFormat: Scalars['String']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Preferred language, i.e. en */
  language: Scalars['String']['output'];
  /** Unit of measure system */
  measurementSystem: StandardUomSystem;
  /** Preferred number format, i.e. wcfp */
  numberFormat?: Maybe<NumberFormat>;
  /** Preferred timezone, i.e. America/Denver */
  timezone: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type DisplayPreferenceCreateInput = {
  /** Preferred currency, i.e. USD */
  currency?: InputMaybe<Scalars['String']['input']>;
  /** Preferred date and time format, i.e. MM/dd/yyyy HH:mm */
  dateTimeFormat?: InputMaybe<Scalars['String']['input']>;
  /** Preferred language, i.e. en */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Preferred number format, i.e. wcfp */
  numberFormat?: InputMaybe<Scalars['String']['input']>;
  /** Preferred timezone, i.e. America/Denver */
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type DisplayPreferenceCreateOneInput = {
  /** The record to create */
  displayPreference: DisplayPreferenceCreateInput;
};

export type DisplayPreferenceFilter = {
  and?: InputMaybe<Array<DisplayPreferenceFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  currency?: InputMaybe<StringFieldComparison>;
  dateTimeFormat?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  language?: InputMaybe<StringFieldComparison>;
  measurementSystem?: InputMaybe<StandardUomSystemFilterComparison>;
  numberFormat?: InputMaybe<NumberFormatFilterComparison>;
  or?: InputMaybe<Array<DisplayPreferenceFilter>>;
  timezone?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type DisplayPreferenceOffsetConnection = {
  __typename?: 'DisplayPreferenceOffsetConnection';
  /** Array of nodes. */
  nodes: Array<DisplayPreference>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type DisplayPreferenceSort = {
  direction: SortDirection;
  field: DisplayPreferenceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DisplayPreferenceSortFields {
  CreatedAt = 'createdAt',
  Currency = 'currency',
  DateTimeFormat = 'dateTimeFormat',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Language = 'language',
  MeasurementSystem = 'measurementSystem',
  NumberFormat = 'numberFormat',
  Timezone = 'timezone',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type DisplayPreferenceUpdateDto = {
  /** Preferred currency, i.e. USD */
  currency?: InputMaybe<Scalars['String']['input']>;
  /** Preferred date and time format, i.e. MM/dd/yyyy HH:mm */
  dateTimeFormat?: InputMaybe<Scalars['String']['input']>;
  /** Preferred language, i.e. en */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Preferred number format, i.e. wcfp */
  numberFormat?: InputMaybe<Scalars['String']['input']>;
  /** Preferred timezone, i.e. America/Denver */
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type DisplayPreferenceUpdateInputDto = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: DisplayPreferenceUpdateDto;
};

export type DisplayPreferenceUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: DisplayPreferenceUpdateDto;
};

/** Disposition model */
export type Disposition = {
  __typename?: 'Disposition';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  images?: Maybe<Array<StoredImage>>;
  /** Notes for a submitted disposition */
  notes: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type DispositionApproveOneDto = {
  /** Disposition approval */
  approval: Scalars['Boolean']['input'];
  /** Disposition ID */
  dispositionId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's license plate ID (foreign key) */
  licensePlateId?: InputMaybe<Scalars['ID']['input']>;
};

export type DispositionFilter = {
  and?: InputMaybe<Array<DispositionFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  notes?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DispositionFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type DispositionImages = {
  /** ID of stored image */
  imageId: Scalars['ID']['input'];
};

export type DispositionOffsetConnection = {
  __typename?: 'DispositionOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Disposition>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type DispositionSort = {
  direction: SortDirection;
  field: DispositionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DispositionSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Notes = 'notes',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type DispositionUpdateOneDto = {
  /** ID of stored image */
  images?: InputMaybe<Array<DispositionImages>>;
  /** Entity's license plate ID (foreign key) */
  licensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** Notes for a submitted disposition */
  notes?: InputMaybe<Scalars['String']['input']>;
};

/** Door model */
export type Door = {
  __typename?: 'Door';
  /** Entity's area ID (foreign key) */
  areaId?: Maybe<Scalars['ID']['output']>;
  /** Entity's bin ID (foreign key) */
  binId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  code?: Maybe<Scalars['String']['output']>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Door direction.  inbound outbound or both. */
  direction: DoorDirection;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** x coordinate location */
  x?: Maybe<Scalars['Float']['output']>;
  /** Y coordinate location */
  y?: Maybe<Scalars['Float']['output']>;
};

export type DoorCreateInput = {
  /** Entity's area ID (foreign key) */
  areaId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's bin ID (foreign key) */
  binId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Door direction.  inbound outbound or both. */
  direction: DoorDirection;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
  /** x coordinate location */
  x?: InputMaybe<Scalars['Float']['input']>;
  /** Y coordinate location */
  y?: InputMaybe<Scalars['Float']['input']>;
};

export type DoorCreateOneInput = {
  /** The record to create */
  door: DoorCreateInput;
};

export type DoorDirectionFilterComparison = {
  eq?: InputMaybe<DoorDirection>;
  gt?: InputMaybe<DoorDirection>;
  gte?: InputMaybe<DoorDirection>;
  iLike?: InputMaybe<DoorDirection>;
  in?: InputMaybe<Array<DoorDirection>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<DoorDirection>;
  lt?: InputMaybe<DoorDirection>;
  lte?: InputMaybe<DoorDirection>;
  neq?: InputMaybe<DoorDirection>;
  notILike?: InputMaybe<DoorDirection>;
  notIn?: InputMaybe<Array<DoorDirection>>;
  notLike?: InputMaybe<DoorDirection>;
};

export type DoorFilter = {
  and?: InputMaybe<Array<DoorFilter>>;
  areaId?: InputMaybe<IdFilterComparison>;
  binId?: InputMaybe<IdFilterComparison>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  direction?: InputMaybe<DoorDirectionFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<DoorFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  x?: InputMaybe<FloatFieldComparison>;
  y?: InputMaybe<FloatFieldComparison>;
};

export type DoorOffsetConnection = {
  __typename?: 'DoorOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Door>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type DoorSort = {
  direction: SortDirection;
  field: DoorSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DoorSortFields {
  AreaId = 'areaId',
  BinId = 'binId',
  Code = 'code',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Direction = 'direction',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WarehouseId = 'warehouseId',
  X = 'x',
  Y = 'y'
}

export type DoorUpdateInput = {
  /** Entity's area ID (foreign key) */
  areaId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's bin ID (foreign key) */
  binId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Door direction.  inbound outbound or both. */
  direction?: InputMaybe<DoorDirection>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
  /** x coordinate location */
  x?: InputMaybe<Scalars['Float']['input']>;
  /** Y coordinate location */
  y?: InputMaybe<Scalars['Float']['input']>;
};

export type DoorUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: DoorUpdateInput;
};

export enum ErpOptions {
  Aoe = 'aoe',
  Default = 'default',
  Pto = 'pto',
  Sap = 'sap'
}

export enum EsIndices {
  Area = 'area',
  Bin = 'bin',
  Delivery = 'delivery',
  Fulfillment = 'fulfillment',
  LicensePlate = 'license_plate',
  Product = 'product',
  Task = 'task'
}

/** Empty Search results */
export type EmptySearchResults = {
  __typename?: 'EmptySearchResults';
  x?: Maybe<Scalars['String']['output']>;
};

export enum EntityChangeType {
  Create = 'create',
  Delete = 'delete',
  Insert = 'insert',
  Update = 'update'
}

export type EntityChangeTypeFilterComparison = {
  eq?: InputMaybe<EntityChangeType>;
  gt?: InputMaybe<EntityChangeType>;
  gte?: InputMaybe<EntityChangeType>;
  iLike?: InputMaybe<EntityChangeType>;
  in?: InputMaybe<Array<EntityChangeType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<EntityChangeType>;
  lt?: InputMaybe<EntityChangeType>;
  lte?: InputMaybe<EntityChangeType>;
  neq?: InputMaybe<EntityChangeType>;
  notILike?: InputMaybe<EntityChangeType>;
  notIn?: InputMaybe<Array<EntityChangeType>>;
  notLike?: InputMaybe<EntityChangeType>;
};

export type EquipmentCreateOneInput = {
  /** The record to create */
  equipmentItem: EquipmentCreateType;
};

export type EquipmentCreateType = {
  /** Entity code */
  code: Scalars['String']['input'];
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Entity's label */
  equipmentModelId: Scalars['ID']['input'];
  /** Entity's label */
  label: Scalars['String']['input'];
  /** Entity's label */
  status?: InputMaybe<EquipmentStatus>;
  /** Identifier for Redpoint Tracking Tag. */
  tagId?: InputMaybe<Scalars['String']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

/** Equipment model */
export type EquipmentItem = {
  __typename?: 'EquipmentItem';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Model of equipment */
  equipmentModelId: Scalars['ID']['output'];
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity's label */
  label: Scalars['String']['output'];
  /** Status of equipment */
  status?: Maybe<EquipmentStatus>;
  /** Identifier for Redpoint Tracking Tag. */
  tagId?: Maybe<Scalars['ID']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
};

export type EquipmentItemFilter = {
  and?: InputMaybe<Array<EquipmentItemFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  equipmentModelId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<EquipmentItemFilter>>;
  status?: InputMaybe<EquipmentStatusFilterComparison>;
  tagId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type EquipmentItemOffsetConnection = {
  __typename?: 'EquipmentItemOffsetConnection';
  /** Array of nodes. */
  nodes: Array<EquipmentItem>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type EquipmentItemSort = {
  direction: SortDirection;
  field: EquipmentItemSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum EquipmentItemSortFields {
  Code = 'code',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  EquipmentModelId = 'equipmentModelId',
  Id = 'id',
  Label = 'label',
  Status = 'status',
  TagId = 'tagId',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WarehouseId = 'warehouseId'
}

export enum EquipmentMobility {
  Dynamic = 'dynamic',
  Static = 'static'
}

export type EquipmentMobilityFilterComparison = {
  eq?: InputMaybe<EquipmentMobility>;
  gt?: InputMaybe<EquipmentMobility>;
  gte?: InputMaybe<EquipmentMobility>;
  iLike?: InputMaybe<EquipmentMobility>;
  in?: InputMaybe<Array<EquipmentMobility>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<EquipmentMobility>;
  lt?: InputMaybe<EquipmentMobility>;
  lte?: InputMaybe<EquipmentMobility>;
  neq?: InputMaybe<EquipmentMobility>;
  notILike?: InputMaybe<EquipmentMobility>;
  notIn?: InputMaybe<Array<EquipmentMobility>>;
  notLike?: InputMaybe<EquipmentMobility>;
};

/** EquipmentModel model */
export type EquipmentModel = {
  __typename?: 'EquipmentModel';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Type of equipment, i.e. forklift */
  equipmentTypeId: Scalars['ID']['output'];
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Type of equipment, i.e. forklift */
  label: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type EquipmentModelCreateOneInput = {
  /** The record to create */
  equipmentModel: EquipmentModelCreateType;
};

export type EquipmentModelCreateType = {
  /** Entity code */
  code: Scalars['String']['input'];
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Type of equipment, i.e. forklift */
  equipmentTypeId: Scalars['ID']['input'];
  /** Entity's label */
  label: Scalars['String']['input'];
};

export type EquipmentModelFilter = {
  and?: InputMaybe<Array<EquipmentModelFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  equipmentTypeId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<EquipmentModelFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type EquipmentModelOffsetConnection = {
  __typename?: 'EquipmentModelOffsetConnection';
  /** Array of nodes. */
  nodes: Array<EquipmentModel>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type EquipmentModelSort = {
  direction: SortDirection;
  field: EquipmentModelSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum EquipmentModelSortFields {
  Code = 'code',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  EquipmentTypeId = 'equipmentTypeId',
  Id = 'id',
  Label = 'label',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type EquipmentModelUpdateInput = {
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Type of equipment, i.e. forklift */
  equipmentTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's label */
  label?: InputMaybe<Scalars['String']['input']>;
};

export type EquipmentModelUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: EquipmentModelUpdateInput;
};

export enum EquipmentStatus {
  Active = 'active',
  Inactive = 'inactive',
  Planned = 'planned'
}

export type EquipmentStatusFilterComparison = {
  eq?: InputMaybe<EquipmentStatus>;
  gt?: InputMaybe<EquipmentStatus>;
  gte?: InputMaybe<EquipmentStatus>;
  iLike?: InputMaybe<EquipmentStatus>;
  in?: InputMaybe<Array<EquipmentStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<EquipmentStatus>;
  lt?: InputMaybe<EquipmentStatus>;
  lte?: InputMaybe<EquipmentStatus>;
  neq?: InputMaybe<EquipmentStatus>;
  notILike?: InputMaybe<EquipmentStatus>;
  notIn?: InputMaybe<Array<EquipmentStatus>>;
  notLike?: InputMaybe<EquipmentStatus>;
};

/** EquipmentType model */
export type EquipmentType = {
  __typename?: 'EquipmentType';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Cost */
  cost?: Maybe<Scalars['Float']['output']>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Currency */
  currency: Scalars['String']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  equipmentModels?: Maybe<Array<EquipmentModel>>;
  /** Number representing maximum height allowance of equipment model. */
  heightMax?: Maybe<Scalars['Float']['output']>;
  /** Number representing minimum height allowance of equipment model. */
  heightMin?: Maybe<Scalars['Float']['output']>;
  /** Imperial or metric unit of measure for height limit. */
  heightUOMId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Type of equipment, i.e. forklift */
  label: Scalars['String']['output'];
  /** Type of location data for an equipment model (Dynamic/Static) */
  mobility?: Maybe<EquipmentMobility>;
  tasks?: Maybe<Array<Task>>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Velocity */
  velocity?: Maybe<Scalars['Float']['output']>;
  /** Velocity unit of measure */
  velocityUOMId?: Maybe<Scalars['ID']['output']>;
  /** Velocity */
  verticalVelocity?: Maybe<Scalars['Float']['output']>;
  /** Cost */
  volumeMax?: Maybe<Scalars['Float']['output']>;
  /** Volume in unit of measure for entity */
  volumeUOMId?: Maybe<Scalars['ID']['output']>;
  /** Number representing maximum weight limit for an equipment model. */
  weightMax?: Maybe<Scalars['Float']['output']>;
  /** Weight UOM, i.e KG */
  weightUOMId?: Maybe<Scalars['ID']['output']>;
};

export type EquipmentTypeCreateOneInput = {
  /** The record to create */
  equipmentType: EquipmentTypeCreateType;
};

export type EquipmentTypeCreateType = {
  /** Entity code */
  code: Scalars['String']['input'];
  /** Cost */
  cost: Scalars['Float']['input'];
  /** Currency */
  currency: Scalars['String']['input'];
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Number representing maximum height allowance of equipment model. */
  heightMax: Scalars['Float']['input'];
  /** Number representing minimum height allowance of equipment model. */
  heightMin: Scalars['Float']['input'];
  /** Imperial or metric unit of measure for height limit. */
  heightUOMId: Scalars['ID']['input'];
  /** Type of equipment, i.e. forklift */
  label: Scalars['String']['input'];
  /** Volume of entity */
  mobility: EquipmentMobility;
  /** Velocity */
  velocity: Scalars['Float']['input'];
  /** Velocity unit of measure */
  velocityUOMId: Scalars['ID']['input'];
  /** Velocity of raising and lowering a piece of equipment */
  verticalVelocity?: InputMaybe<Scalars['Float']['input']>;
  /** Volume of entity */
  volumeMax: Scalars['Float']['input'];
  /** Volume in unit of measure for entity */
  volumeUOMId: Scalars['ID']['input'];
  /** Velocity */
  weightMax: Scalars['Float']['input'];
  /** Weight UOM, i.e KG */
  weightUOMId: Scalars['ID']['input'];
};

export type EquipmentTypeFilter = {
  and?: InputMaybe<Array<EquipmentTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  cost?: InputMaybe<FloatFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  currency?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  heightMax?: InputMaybe<FloatFieldComparison>;
  heightMin?: InputMaybe<FloatFieldComparison>;
  heightUOMId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  mobility?: InputMaybe<EquipmentMobilityFilterComparison>;
  or?: InputMaybe<Array<EquipmentTypeFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  velocity?: InputMaybe<FloatFieldComparison>;
  velocityUOMId?: InputMaybe<IdFilterComparison>;
  verticalVelocity?: InputMaybe<FloatFieldComparison>;
  volumeMax?: InputMaybe<FloatFieldComparison>;
  volumeUOMId?: InputMaybe<IdFilterComparison>;
  weightMax?: InputMaybe<FloatFieldComparison>;
  weightUOMId?: InputMaybe<IdFilterComparison>;
};

export type EquipmentTypeOffsetConnection = {
  __typename?: 'EquipmentTypeOffsetConnection';
  /** Array of nodes. */
  nodes: Array<EquipmentType>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type EquipmentTypeQueryShape = {
  __typename?: 'EquipmentTypeQueryShape';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Cost */
  cost?: Maybe<Scalars['Float']['output']>;
  /** Currency */
  currency?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Number representing maximum height allowance of equipment model. */
  heightMax?: Maybe<Scalars['Float']['output']>;
  /** Number representing minimum height allowance of equipment model. */
  heightMin?: Maybe<Scalars['Float']['output']>;
  /** Imperial or metric unit of measure for height limit. */
  heightUOMCode?: Maybe<Scalars['String']['output']>;
  /** Imperial or metric unit of measure for height limit. */
  heightUOMId?: Maybe<Scalars['ID']['output']>;
  /** Imperial or metric unit of measure for height limit. */
  heightUOMLabel?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Type of equipment, i.e. forklift */
  label: Scalars['String']['output'];
  /** Type of location data for an equipment model (Dynamic/Static) */
  mobility?: Maybe<EquipmentMobility>;
  /** Velocity */
  velocity?: Maybe<Scalars['Float']['output']>;
  /** Velocity unit of measure */
  velocityUOMCode?: Maybe<Scalars['String']['output']>;
  /** Velocity unit of measure */
  velocityUOMId?: Maybe<Scalars['ID']['output']>;
  /** Velocity unit of measure */
  velocityUOMLabel?: Maybe<Scalars['String']['output']>;
  /** Velocity of raising and lowering a piece of equipment */
  verticalVelocity?: Maybe<Scalars['Float']['output']>;
  /** Max Volume of entity */
  volumeMax?: Maybe<Scalars['Float']['output']>;
  /** Volume in unit of measure for entity */
  volumeUOMCode?: Maybe<Scalars['String']['output']>;
  /** Volume in unit of measure for entity */
  volumeUOMId?: Maybe<Scalars['ID']['output']>;
  /** Volume in unit of measure for entity */
  volumeUOMLabel?: Maybe<Scalars['String']['output']>;
  /** Number representing maximum weight limit for an equipment model. */
  weightMax?: Maybe<Scalars['Float']['output']>;
  /** Weight UOM, i.e KG */
  weightUOMCode?: Maybe<Scalars['String']['output']>;
  /** Weight UOM, i.e KG */
  weightUOMId?: Maybe<Scalars['ID']['output']>;
  /** Weight UOM, i.e KG */
  weightUOMLabel?: Maybe<Scalars['String']['output']>;
};

export type EquipmentTypeQueryShapeFilter = {
  and?: InputMaybe<Array<EquipmentTypeQueryShapeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  cost?: InputMaybe<FloatFieldComparison>;
  currency?: InputMaybe<StringFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  heightMax?: InputMaybe<FloatFieldComparison>;
  heightMin?: InputMaybe<FloatFieldComparison>;
  heightUOMCode?: InputMaybe<StringFieldComparison>;
  heightUOMId?: InputMaybe<IdFilterComparison>;
  heightUOMLabel?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  mobility?: InputMaybe<EquipmentMobilityFilterComparison>;
  or?: InputMaybe<Array<EquipmentTypeQueryShapeFilter>>;
  velocity?: InputMaybe<FloatFieldComparison>;
  velocityUOMCode?: InputMaybe<StringFieldComparison>;
  velocityUOMId?: InputMaybe<IdFilterComparison>;
  velocityUOMLabel?: InputMaybe<StringFieldComparison>;
  verticalVelocity?: InputMaybe<FloatFieldComparison>;
  volumeMax?: InputMaybe<FloatFieldComparison>;
  volumeUOMCode?: InputMaybe<StringFieldComparison>;
  volumeUOMId?: InputMaybe<IdFilterComparison>;
  volumeUOMLabel?: InputMaybe<StringFieldComparison>;
  weightMax?: InputMaybe<FloatFieldComparison>;
  weightUOMCode?: InputMaybe<StringFieldComparison>;
  weightUOMId?: InputMaybe<IdFilterComparison>;
  weightUOMLabel?: InputMaybe<StringFieldComparison>;
};

export type EquipmentTypeQueryShapeOffsetConnection = {
  __typename?: 'EquipmentTypeQueryShapeOffsetConnection';
  /** Array of nodes. */
  nodes: Array<EquipmentTypeQueryShape>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type EquipmentTypeQueryShapeSort = {
  direction: SortDirection;
  field: EquipmentTypeQueryShapeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum EquipmentTypeQueryShapeSortFields {
  Code = 'code',
  Cost = 'cost',
  Currency = 'currency',
  Description = 'description',
  HeightMax = 'heightMax',
  HeightMin = 'heightMin',
  HeightUomCode = 'heightUOMCode',
  HeightUomId = 'heightUOMId',
  HeightUomLabel = 'heightUOMLabel',
  Id = 'id',
  Label = 'label',
  Mobility = 'mobility',
  Velocity = 'velocity',
  VelocityUomCode = 'velocityUOMCode',
  VelocityUomId = 'velocityUOMId',
  VelocityUomLabel = 'velocityUOMLabel',
  VerticalVelocity = 'verticalVelocity',
  VolumeMax = 'volumeMax',
  VolumeUomCode = 'volumeUOMCode',
  VolumeUomId = 'volumeUOMId',
  VolumeUomLabel = 'volumeUOMLabel',
  WeightMax = 'weightMax',
  WeightUomCode = 'weightUOMCode',
  WeightUomId = 'weightUOMId',
  WeightUomLabel = 'weightUOMLabel'
}

export type EquipmentTypeSort = {
  direction: SortDirection;
  field: EquipmentTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum EquipmentTypeSortFields {
  Code = 'code',
  Cost = 'cost',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  DeletedAt = 'deletedAt',
  Description = 'description',
  HeightMax = 'heightMax',
  HeightMin = 'heightMin',
  HeightUomId = 'heightUOMId',
  Id = 'id',
  Label = 'label',
  Mobility = 'mobility',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  Velocity = 'velocity',
  VelocityUomId = 'velocityUOMId',
  VerticalVelocity = 'verticalVelocity',
  VolumeMax = 'volumeMax',
  VolumeUomId = 'volumeUOMId',
  WeightMax = 'weightMax',
  WeightUomId = 'weightUOMId'
}

export type EquipmentTypeUpdateInput = {
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Cost */
  cost?: InputMaybe<Scalars['Float']['input']>;
  /** Currency */
  currency?: InputMaybe<Scalars['String']['input']>;
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Number representing maximum height allowance of equipment model. */
  heightMax?: InputMaybe<Scalars['Float']['input']>;
  /** Number representing minimum height allowance of equipment model. */
  heightMin?: InputMaybe<Scalars['Float']['input']>;
  /** Imperial or metric unit of measure for height limit. */
  heightUOMId?: InputMaybe<Scalars['ID']['input']>;
  /** Type of equipment, i.e. forklift */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Volume of entity */
  mobility?: InputMaybe<EquipmentMobility>;
  /** Velocity */
  velocity?: InputMaybe<Scalars['Float']['input']>;
  /** Velocity unit of measure */
  velocityUOMId?: InputMaybe<Scalars['ID']['input']>;
  /** Velocity of raising and lowering a piece of equipment */
  verticalVelocity?: InputMaybe<Scalars['Float']['input']>;
  /** Volume of entity */
  volumeMax?: InputMaybe<Scalars['Float']['input']>;
  /** Volume in unit of measure for entity */
  volumeUOMId?: InputMaybe<Scalars['ID']['input']>;
  /** Velocity */
  weightMax?: InputMaybe<Scalars['Float']['input']>;
  /** Weight UOM, i.e KG */
  weightUOMId?: InputMaybe<Scalars['ID']['input']>;
};

export type EquipmentTypeUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: EquipmentTypeUpdateInput;
};

export type EquipmentUpdateInput = {
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Entity's label */
  equipmentModelId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's label */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Entity's label */
  status?: InputMaybe<EquipmentStatus>;
  /** Identifier for Redpoint Tracking Tag. */
  tagId?: InputMaybe<Scalars['String']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type EquipmentUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: EquipmentUpdateInput;
};

export enum ErrorCodes {
  AnalyticsAdminTokenNotFound = 'ANALYTICS_ADMIN_TOKEN_NOT_FOUND',
  AnalyticsCardsNotFound = 'ANALYTICS_CARDS_NOT_FOUND',
  AnalyticsDashboardNotFound = 'ANALYTICS_DASHBOARD_NOT_FOUND',
  AnalyticsEnvConfigError = 'ANALYTICS_ENV_CONFIG_ERROR',
  AnalyticsErrorCreatingUser = 'ANALYTICS_ERROR_CREATING_USER',
  AnalyticsErrorFetchingCards = 'ANALYTICS_ERROR_FETCHING_CARDS',
  AnalyticsErrorFetchingDashboards = 'ANALYTICS_ERROR_FETCHING_DASHBOARDS',
  AnalyticsLiveboardNamingError = 'ANALYTICS_LIVEBOARD_NAMING_ERROR',
  AnalyticsTenantConfigError = 'ANALYTICS_TENANT_CONFIG_ERROR',
  AnalyticsUserNotFound = 'ANALYTICS_USER_NOT_FOUND',
  AnalyticsUserTokenNotFound = 'ANALYTICS_USER_TOKEN_NOT_FOUND',
  AreaIsSystemDefault = 'AREA_IS_SYSTEM_DEFAULT',
  AreaIsSystemDefaultCannotDelete = 'AREA_IS_SYSTEM_DEFAULT_CANNOT_DELETE',
  AuthenticationBrokerUserDisabled = 'AUTHENTICATION_BROKER_USER_DISABLED',
  AuthenticationExpiredToken = 'AUTHENTICATION_EXPIRED_TOKEN',
  AuthenticationFfLoginError = 'AUTHENTICATION_FF_LOGIN_ERROR',
  AuthenticationFfM2MMismatch = 'AUTHENTICATION_FF_M2M_MISMATCH',
  AuthenticationFfUserNotFound = 'AUTHENTICATION_FF_USER_NOT_FOUND',
  AuthenticationIdpUserNotFound = 'AUTHENTICATION_IDP_USER_NOT_FOUND',
  AuthenticationInvalidAccessToken = 'AUTHENTICATION_INVALID_ACCESS_TOKEN',
  AuthenticationInvalidAuthType = 'AUTHENTICATION_INVALID_AUTH_TYPE',
  AuthenticationInvalidIdToken = 'AUTHENTICATION_INVALID_ID_TOKEN',
  AuthenticationMissingAccessToken = 'AUTHENTICATION_MISSING_ACCESS_TOKEN',
  AuthenticationMissingAuthType = 'AUTHENTICATION_MISSING_AUTH_TYPE',
  AuthenticationMissingIdToken = 'AUTHENTICATION_MISSING_ID_TOKEN',
  AuthenticationRevokedToken = 'AUTHENTICATION_REVOKED_TOKEN',
  AuthenticationSapRefreshTokenExpired = 'AUTHENTICATION_SAP_REFRESH_TOKEN_EXPIRED',
  AuthenticationTokenMappingError = 'AUTHENTICATION_TOKEN_MAPPING_ERROR',
  AuthenticationTokenVerificationError = 'AUTHENTICATION_TOKEN_VERIFICATION_ERROR',
  AuthenticationUnknownError = 'AUTHENTICATION_UNKNOWN_ERROR',
  AuthenticationUserDisabled = 'AUTHENTICATION_USER_DISABLED',
  BadRequest = 'BAD_REQUEST',
  BarcodeEmpty = 'BARCODE_EMPTY',
  BarcodeNonCompliant = 'BARCODE_NON_COMPLIANT',
  BarcodeParseError = 'BARCODE_PARSE_ERROR',
  BinIsSystemDefault = 'BIN_IS_SYSTEM_DEFAULT',
  BinIsSystemDefaultCannotDelete = 'BIN_IS_SYSTEM_DEFAULT_CANNOT_DELETE',
  BinNotEmpty = 'BIN_NOT_EMPTY',
  BinStatusMappingValidation = 'BIN_STATUS_MAPPING_VALIDATION',
  BinStorageTypeBadInputUpdate = 'BIN_STORAGE_TYPE_BAD_INPUT_UPDATE',
  BinStorageTypeInUseDelete = 'BIN_STORAGE_TYPE_IN_USE_DELETE',
  BinStorageTypeIsSystemDefault = 'BIN_STORAGE_TYPE_IS_SYSTEM_DEFAULT',
  BinStorageTypeIsSystemDefaultCannotDelete = 'BIN_STORAGE_TYPE_IS_SYSTEM_DEFAULT_CANNOT_DELETE',
  BusinessPartnerComplianceCheckError = 'BUSINESS_PARTNER_COMPLIANCE_CHECK_ERROR',
  CountBinTaskOpen = 'COUNT_BIN_TASK_OPEN',
  CycleCountValidation = 'CYCLE_COUNT_VALIDATION',
  DeleteLayoutVariantError = 'DELETE_LAYOUT_VARIANT_ERROR',
  DeliveryCategoryError = 'DELIVERY_CATEGORY_ERROR',
  DeliveryDeliveryItemsNotFound = 'DELIVERY_DELIVERY_ITEMS_NOT_FOUND',
  DeliveryItemStarted = 'DELIVERY_ITEM_STARTED',
  DeliveryStarted = 'DELIVERY_STARTED',
  DeliveryStatusError = 'DELIVERY_STATUS_ERROR',
  DeliveryTasksAlreadyExist = 'DELIVERY_TASKS_ALREADY_EXIST',
  DeliveryTypeError = 'DELIVERY_TYPE_ERROR',
  DispositionAlreadyOpen = 'DISPOSITION_ALREADY_OPEN',
  DispositionLicensePlateDoesntExist = 'DISPOSITION_LICENSE_PLATE_DOESNT_EXIST',
  DispositionLicensePlateInventoryNotFound = 'DISPOSITION_LICENSE_PLATE_INVENTORY_NOT_FOUND',
  DispositionLicensePlateOrDisposoDoesntExist = 'DISPOSITION_LICENSE_PLATE_OR_DISPOSO_DOESNT_EXIST',
  DispositionOpenTasksExist = 'DISPOSITION_OPEN_TASKS_EXIST',
  DoorIsSystemDefault = 'DOOR_IS_SYSTEM_DEFAULT',
  DoorIsSystemDefaultCannotDelete = 'DOOR_IS_SYSTEM_DEFAULT_CANNOT_DELETE',
  DoorNotAssigned = 'DOOR_NOT_ASSIGNED',
  DoorSelectionRequiresWarehouseSelection = 'DOOR_SELECTION_REQUIRES_WAREHOUSE_SELECTION',
  DoorWarehouseDoesntMatchSelectedWarehouse = 'DOOR_WAREHOUSE_DOESNT_MATCH_SELECTED_WAREHOUSE',
  EquipmentModelDeleteValidation = 'EQUIPMENT_MODEL_DELETE_VALIDATION',
  EquipmentTypeDeleteValidation = 'EQUIPMENT_TYPE_DELETE_VALIDATION',
  EquipmentTypeNotInWarehouse = 'EQUIPMENT_TYPE_NOT_IN_WAREHOUSE',
  EventsWarheouseOpsBusinessPartnerNotFound = 'EVENTS_WARHEOUSE_OPS_BUSINESS_PARTNER_NOT_FOUND',
  EventsWarheouseOpsOpNotFound = 'EVENTS_WARHEOUSE_OPS_OP_NOT_FOUND',
  EwmDeliveryNotFound = 'EWM_DELIVERY_NOT_FOUND',
  EwmDlvTaskInvalidBin = 'EWM_DLV_TASK_INVALID_BIN',
  EwmDlvTaskNotFound = 'EWM_DLV_TASK_NOT_FOUND',
  EwmFieldMappingNotFound = 'EWM_FIELD_MAPPING_NOT_FOUND',
  EwmHuDeconStatus = 'EWM_HU_DECON_STATUS',
  EwmHuNotFound = 'EWM_HU_NOT_FOUND',
  EwmInvalidTaskType = 'EWM_INVALID_TASK_TYPE',
  EwmMalformedTask = 'EWM_MALFORMED_TASK',
  EwmTaskCompleteError = 'EWM_TASK_COMPLETE_ERROR',
  EwmTaskCreateError = 'EWM_TASK_CREATE_ERROR',
  EwmTaskInvalidBin = 'EWM_TASK_INVALID_BIN',
  EwmTaskNotFound = 'EWM_TASK_NOT_FOUND',
  EwmTaskPgrError = 'EWM_TASK_PGR_ERROR',
  FoundStockLicensePlateNotFound = 'FOUND_STOCK_LICENSE_PLATE_NOT_FOUND',
  FulfillmentBlocked = 'FULFILLMENT_BLOCKED',
  FulfillmentBlockValidation = 'FULFILLMENT_BLOCK_VALIDATION',
  FulfillmentItemNotAvailable = 'FULFILLMENT_ITEM_NOT_AVAILABLE',
  FulfillmentItemNotFound = 'FULFILLMENT_ITEM_NOT_FOUND',
  GcpRawError = 'GCP_RAW_ERROR',
  GcpStorageFileNotFound = 'GCP_STORAGE_FILE_NOT_FOUND',
  GcpStorageFolderNotFound = 'GCP_STORAGE_FOLDER_NOT_FOUND',
  GcpStorageNoBucketSelected = 'GCP_STORAGE_NO_BUCKET_SELECTED',
  HistoryIdMistmatch = 'HISTORY_ID_MISTMATCH',
  InvalidDateError = 'INVALID_DATE_ERROR',
  InvalidInput = 'INVALID_INPUT',
  InventoryNotFound = 'INVENTORY_NOT_FOUND',
  ItemsNotComplete = 'ITEMS_NOT_COMPLETE',
  LicensePlateDoesNotMatchSelectedProduct = 'LICENSE_PLATE_DOES_NOT_MATCH_SELECTED_PRODUCT',
  LicensePlateStatusInvalid = 'LICENSE_PLATE_STATUS_INVALID',
  LoadDeliveryIdNotOnTask = 'LOAD_DELIVERY_ID_NOT_ON_TASK',
  LoadFulfillmentItemNotFound = 'LOAD_FULFILLMENT_ITEM_NOT_FOUND',
  LoadFulfillmentNotFound = 'LOAD_FULFILLMENT_NOT_FOUND',
  LoadNoFulfillmentItems = 'LOAD_NO_FULFILLMENT_ITEMS',
  LoadNoStockInStaging = 'LOAD_NO_STOCK_IN_STAGING',
  LoadTaskCompleteBadRequest = 'LOAD_TASK_COMPLETE_BAD_REQUEST',
  LoadTaskCompleteLpBadRequest = 'LOAD_TASK_COMPLETE_LP_BAD_REQUEST',
  LoadTaskNotCreated = 'LOAD_TASK_NOT_CREATED',
  LoadWarehouseNotOnFulfillment = 'LOAD_WAREHOUSE_NOT_ON_FULFILLMENT',
  LotProductMismatch = 'LOT_PRODUCT_MISMATCH',
  MinMaxReplenishmentquantityUndefined = 'MIN_MAX_REPLENISHMENTQUANTITY_UNDEFINED',
  NotFound = 'NOT_FOUND',
  NotUnique = 'NOT_UNIQUE',
  NoSlottingRunDeployed = 'NO_SLOTTING_RUN_DEPLOYED',
  NoStockFound = 'NO_STOCK_FOUND',
  PickToStagingDeliveryDoorBinNotAssigned = 'PICK_TO_STAGING_DELIVERY_DOOR_BIN_NOT_ASSIGNED',
  PickToStagingDeliveryDoorNotAssigned = 'PICK_TO_STAGING_DELIVERY_DOOR_NOT_ASSIGNED',
  PickToStagingNoAvailableQuantity = 'PICK_TO_STAGING_NO_AVAILABLE_QUANTITY',
  PickToStagingTaskCompleteBadRequest = 'PICK_TO_STAGING_TASK_COMPLETE_BAD_REQUEST',
  PickToStagingTaskCompleteLpBadRequest = 'PICK_TO_STAGING_TASK_COMPLETE_LP_BAD_REQUEST',
  ProductIsLotManagedNoLotSelected = 'PRODUCT_IS_LOT_MANAGED_NO_LOT_SELECTED',
  ProductLotSelectionMismatch = 'PRODUCT_LOT_SELECTION_MISMATCH',
  PutawayTaskDeliveryItemsNotFound = 'PUTAWAY_TASK_DELIVERY_ITEMS_NOT_FOUND',
  PutawayTaskValidation = 'PUTAWAY_TASK_VALIDATION',
  SapAuthError = 'SAP_AUTH_ERROR',
  SapGeneral = 'SAP_GENERAL',
  SapInboundDeliveryImport = 'SAP_INBOUND_DELIVERY_IMPORT',
  SapInvalidRequest = 'SAP_INVALID_REQUEST',
  SapMaterialMovementCreate = 'SAP_MATERIAL_MOVEMENT_CREATE',
  SapNotFound = 'SAP_NOT_FOUND',
  SapPostGoodsError = 'SAP_POST_GOODS_ERROR',
  SapUnknown = 'SAP_UNKNOWN',
  ServerError = 'SERVER_ERROR',
  SlottingConfigurationZoneRulesError = 'SLOTTING_CONFIGURATION_ZONE_RULES_ERROR',
  SlottingDatasetSlocPlantWhError = 'SLOTTING_DATASET_SLOC_PLANT_WH_ERROR',
  SlottingDatasetVariantListError = 'SLOTTING_DATASET_VARIANT_LIST_ERROR',
  SlottingRunAlreadyDeployed = 'SLOTTING_RUN_ALREADY_DEPLOYED',
  SlottingRunNotFound = 'SLOTTING_RUN_NOT_FOUND',
  SmartScanContextNotFound = 'SMART_SCAN_CONTEXT_NOT_FOUND',
  SmartScanLicensePlateNotFound = 'SMART_SCAN_LICENSE_PLATE_NOT_FOUND',
  TaskBlockInvalid = 'TASK_BLOCK_INVALID',
  TaskBlockStatus = 'TASK_BLOCK_STATUS',
  TaskBlockTaskType = 'TASK_BLOCK_TASK_TYPE',
  TaskCompleteError = 'TASK_COMPLETE_ERROR',
  TaskGroupAiEndpointRequest = 'TASK_GROUP_AI_ENDPOINT_REQUEST',
  TaskGroupAssignMissingUserTeam = 'TASK_GROUP_ASSIGN_MISSING_USER_TEAM',
  TaskGroupEmpty = 'TASK_GROUP_EMPTY',
  TaskGroupNotFound = 'TASK_GROUP_NOT_FOUND',
  TaskGroupOrderingXYCoordinatesUnknown = 'TASK_GROUP_ORDERING_X_Y_COORDINATES_UNKNOWN',
  TaskGroupStatusCancelled = 'TASK_GROUP_STATUS_CANCELLED',
  TaskGroupStatusComplete = 'TASK_GROUP_STATUS_COMPLETE',
  TaskInsufficientQuantity = 'TASK_INSUFFICIENT_QUANTITY',
  TaskInvalidDestBin = 'TASK_INVALID_DEST_BIN',
  TaskLicensePlateStockStatus = 'TASK_LICENSE_PLATE_STOCK_STATUS',
  TaskLicensePlateStockStatusLpOrTaskNotFound = 'TASK_LICENSE_PLATE_STOCK_STATUS_LP_OR_TASK_NOT_FOUND',
  TaskOpenTasks = 'TASK_OPEN_TASKS',
  TaskQuantityInvalid = 'TASK_QUANTITY_INVALID',
  TaskQuantityNotFound = 'TASK_QUANTITY_NOT_FOUND',
  TaskReceiveFromProductionBadRequest = 'TASK_RECEIVE_FROM_PRODUCTION_BAD_REQUEST',
  TaskStatusCancelled = 'TASK_STATUS_CANCELLED',
  TaskStatusComplete = 'TASK_STATUS_COMPLETE',
  TaskStatusPlanned = 'TASK_STATUS_PLANNED',
  TaskTypeBinStatusRestriction = 'TASK_TYPE_BIN_STATUS_RESTRICTION',
  TaskTypeBinStatusUpdate = 'TASK_TYPE_BIN_STATUS_UPDATE',
  TaskTypeBinStatusValidation = 'TASK_TYPE_BIN_STATUS_VALIDATION',
  TaskTypeInvalid = 'TASK_TYPE_INVALID',
  TaskTypeRead = 'TASK_TYPE_READ',
  TaskTypeStockStatusRestriction = 'TASK_TYPE_STOCK_STATUS_RESTRICTION',
  TaskUpdateTask = 'TASK_UPDATE_TASK',
  UnauthorizedError = 'UNAUTHORIZED_ERROR',
  Unhandled = 'UNHANDLED',
  UnitOfMeasureBaseQuantityValidationError = 'UNIT_OF_MEASURE_BASE_QUANTITY_VALIDATION_ERROR',
  UnitOfMeasureCompletionQuantityExceedsCreationQuantity = 'UNIT_OF_MEASURE_COMPLETION_QUANTITY_EXCEEDS_CREATION_QUANTITY',
  UnitOfMeasureInvalidForProduct = 'UNIT_OF_MEASURE_INVALID_FOR_PRODUCT',
  UnitOfMeasureNotFound = 'UNIT_OF_MEASURE_NOT_FOUND',
  UnitOfMeasureProductDoesNotMatchValidationError = 'UNIT_OF_MEASURE_PRODUCT_DOES_NOT_MATCH_VALIDATION_ERROR',
  UnitOfMeasureRequiredForProduct = 'UNIT_OF_MEASURE_REQUIRED_FOR_PRODUCT',
  UnknownBarcodeScanner = 'UNKNOWN_BARCODE_SCANNER',
  UnknownBarcodeSymbology = 'UNKNOWN_BARCODE_SYMBOLOGY',
  UploadEventError = 'UPLOAD_EVENT_ERROR',
  UserCreateError = 'USER_CREATE_ERROR',
  UserDoesNotHaveAnActiveTeam = 'USER_DOES_NOT_HAVE_AN_ACTIVE_TEAM',
  UserNotFoundError = 'USER_NOT_FOUND_ERROR',
  WarehouseConfigurationNotFound = 'WAREHOUSE_CONFIGURATION_NOT_FOUND',
  WarehouseNotFound = 'WAREHOUSE_NOT_FOUND',
  WarehouseNotInCompany = 'WAREHOUSE_NOT_IN_COMPANY',
  WarehouseOpBpNotFound = 'WAREHOUSE_OP_BP_NOT_FOUND',
  WarehouseOpBpUnsupported = 'WAREHOUSE_OP_BP_UNSUPPORTED',
  WarehouseOpCodeNotCode = 'WAREHOUSE_OP_CODE_NOT_CODE',
  WarehouseOpCodeUnknown = 'WAREHOUSE_OP_CODE_UNKNOWN',
  WarehouseOpDuplicateUploadError = 'WAREHOUSE_OP_DUPLICATE_UPLOAD_ERROR',
  WarehouseOpNoBarcodeProvided = 'WAREHOUSE_OP_NO_BARCODE_PROVIDED',
  WarehouseOpUploadError = 'WAREHOUSE_OP_UPLOAD_ERROR',
  WarehousePreferredUnitOfMeasureValidation = 'WAREHOUSE_PREFERRED_UNIT_OF_MEASURE_VALIDATION',
  ZoneUpdateInvalidBinType = 'ZONE_UPDATE_INVALID_BIN_TYPE',
  ZoneUpdateInvalidWarehouse = 'ZONE_UPDATE_INVALID_WAREHOUSE'
}

export enum ErrorType {
  AnalyticsError = 'ANALYTICS_ERROR',
  Authorization = 'AUTHORIZATION',
  Create = 'CREATE',
  Database = 'DATABASE',
  Delete = 'DELETE',
  Destroy = 'DESTROY',
  NotFound = 'NOT_FOUND',
  Read = 'READ',
  SapError = 'SAP_ERROR',
  ServerError = 'SERVER_ERROR',
  Update = 'UPDATE',
  Validation = 'VALIDATION'
}

export type EwmBinToBinResponse = {
  __typename?: 'EwmBinToBinResponse';
  /** Warehouse task */
  warehouseTask: Scalars['String']['output'];
};

export type EwmDelivery = {
  __typename?: 'EwmDelivery';
  /** Advanced Shipping Notification */
  advancedShippingNotification?: Maybe<Scalars['String']['output']>;
  /** Delivery category */
  deliveryCategory?: Maybe<Scalars['String']['output']>;
  /** EWM delivery type */
  deliveryType?: Maybe<Scalars['String']['output']>;
  /** Due date */
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  /** Handling Units */
  handlingUnits?: Maybe<Array<EwmHandlingUnit>>;
  /** Handling Units */
  handlingUnitsCount: Scalars['Int']['output'];
  /** Delivery associated with the entity */
  inboundDeliveryCode?: Maybe<Scalars['String']['output']>;
  /** Lookup method details */
  lookUpMethod?: Maybe<EwmDeliveryLookUpMethod>;
  /** Business partner code */
  partnerCode?: Maybe<Scalars['String']['output']>;
  /** Business partner name */
  partnerDescription?: Maybe<Scalars['String']['output']>;
  /** Warehouse code */
  warehouseCode?: Maybe<Scalars['String']['output']>;
};

export enum EwmDeliveryDetailOption {
  Asn = 'asn',
  Delivery = 'delivery',
  HandlingUnit = 'handlingUnit',
  PurchaseDocument = 'purchaseDocument',
  Unknown = 'unknown'
}

export type EwmDeliveryLookUpMethod = {
  __typename?: 'EwmDeliveryLookUpMethod';
  /** Lookup method */
  method?: Maybe<EwmDeliveryDetailOption>;
  /** Lookup value */
  value?: Maybe<Scalars['String']['output']>;
};

export type EwmHuDeconResponse = {
  __typename?: 'EwmHUDeconResponse';
  /** Warehouse entity belongs to */
  ewmWarehouse: Scalars['String']['output'];
  /** Handling unit */
  handlingUnit: Scalars['String']['output'];
  workCenter: Scalars['String']['output'];
};

export type EwmHandlingUnit = {
  __typename?: 'EwmHandlingUnit';
  /** Entity ID */
  code: Scalars['String']['output'];
  /** Delivery item associated with entity */
  deliveryItem?: Maybe<Scalars['String']['output']>;
  /** Gross weight of entity */
  grossWeight: Scalars['Float']['output'];
  /** Handling unit status */
  handlingUnitStatus: Scalars['String']['output'];
  /** Packaging material type */
  handlingUnitType: Scalars['String']['output'];
  /** Nested handling units */
  handlingUnits?: Maybe<Array<EwmHandlingUnit>>;
  /** Net weight of entity */
  netWeight: Scalars['Float']['output'];
  /** Packaging material */
  packagingMaterial: Scalars['String']['output'];
  pgrStatus?: Maybe<Scalars['String']['output']>;
  /** Product code */
  productCode?: Maybe<Scalars['String']['output']>;
  /** Product description */
  productDescription?: Maybe<Scalars['String']['output']>;
  putawayStatus?: Maybe<Scalars['String']['output']>;
  /** Quantity in base unit of measure */
  quantity?: Maybe<Scalars['Float']['output']>;
  /** Unit of measure for entity */
  quantityUnitOfMeasure?: Maybe<Scalars['String']['output']>;
  /** Stock status type entity belongs to */
  stockType?: Maybe<Scalars['String']['output']>;
  /** Handling Unit type */
  type?: Maybe<HandlingUnitType>;
  unloadingStatus?: Maybe<Scalars['String']['output']>;
  warehouseTaskConfirmationStatus?: Maybe<Scalars['String']['output']>;
  warehouseTaskCreateStatus?: Maybe<Scalars['String']['output']>;
  /** Weight UOM, i.e KG */
  weightUnitOfMeasure: Scalars['String']['output'];
};

export type EwmWarehouseTask = {
  __typename?: 'EwmWarehouseTask';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Bin entity belongs to */
  destinationBin?: Maybe<Scalars['String']['output']>;
  /** Handling unit */
  destinationHandlingUnit?: Maybe<Scalars['String']['output']>;
  destinationStorageType?: Maybe<Scalars['String']['output']>;
  /** Net weight of entity */
  netWeight?: Maybe<Scalars['String']['output']>;
  /** Product code */
  productCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  productDescription?: Maybe<Scalars['String']['output']>;
  /** Quantity in base unit of measure */
  quantity?: Maybe<Scalars['String']['output']>;
  /** Unit of measure for entity */
  quantityUnitOfMeasure?: Maybe<Scalars['String']['output']>;
  /** Bin entity belongs to */
  sourceBin?: Maybe<Scalars['String']['output']>;
  /** Handling unit */
  sourceHandlingUnit?: Maybe<Scalars['String']['output']>;
  sourceStorageType?: Maybe<Scalars['String']['output']>;
  /** Task status, i.e Not Started */
  status?: Maybe<EwmWarehouseTaskStatus>;
  /** Task type */
  taskType?: Maybe<Scalars['String']['output']>;
  /** Unit of measure for entity */
  weightUnitOfMeasure?: Maybe<Scalars['String']['output']>;
};

export type EwmWarehouseTaskCodeFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
};

export type EwmWarehouseTaskCreatedAtFilterComparison = {
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type EwmWarehouseTaskFilter = {
  and?: InputMaybe<Array<EwmWarehouseTaskFilter>>;
  code?: InputMaybe<EwmWarehouseTaskCodeFilterComparison>;
  createdAt?: InputMaybe<EwmWarehouseTaskCreatedAtFilterComparison>;
  or?: InputMaybe<Array<EwmWarehouseTaskFilter>>;
  productCode?: InputMaybe<EwmWarehouseTaskProductCodeFilterComparison>;
  quantity?: InputMaybe<EwmWarehouseTaskQuantityFilterComparison>;
  sourceHandlingUnit?: InputMaybe<EwmWarehouseTaskSourceHandlingUnitFilterComparison>;
  status?: InputMaybe<EwmWarehouseTaskStatusFilterComparison>;
};

export type EwmWarehouseTaskOffsetConnection = {
  __typename?: 'EwmWarehouseTaskOffsetConnection';
  /** Array of nodes. */
  nodes: Array<EwmWarehouseTask>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type EwmWarehouseTaskProductCodeFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
};

export type EwmWarehouseTaskQuantityFilterComparison = {
  gte?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
};

export type EwmWarehouseTaskResponse = {
  __typename?: 'EwmWarehouseTaskResponse';
  /** Handling unit */
  handlingUnit: Scalars['String']['output'];
  pgrStatus?: Maybe<Scalars['String']['output']>;
  putawayStatus?: Maybe<Scalars['String']['output']>;
  unloadingStatus?: Maybe<Scalars['String']['output']>;
  /** Warehouse task */
  warehouseTask: Scalars['String']['output'];
  warehouseTaskConfirmationStatus?: Maybe<Scalars['String']['output']>;
  warehouseTaskCreateStatus?: Maybe<Scalars['String']['output']>;
};

export type EwmWarehouseTaskSort = {
  direction: SortDirection;
  field: EwmWarehouseTaskSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum EwmWarehouseTaskSortFields {
  Code = 'code',
  CreatedAt = 'createdAt',
  ProductCode = 'productCode',
  Quantity = 'quantity',
  SourceHandlingUnit = 'sourceHandlingUnit',
  Status = 'status'
}

export type EwmWarehouseTaskSourceHandlingUnitFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
};

export enum EwmWarehouseTaskStatus {
  Confirmed = 'confirmed',
  Open = 'open'
}

export type EwmWarehouseTaskStatusFilterComparison = {
  eq?: InputMaybe<EwmWarehouseTaskStatus>;
};

export enum FeatureContext {
  App = 'app'
}

export type FloatFieldComparison = {
  between?: InputMaybe<FloatFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  notBetween?: InputMaybe<FloatFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type FloatFieldComparisonBetween = {
  lower: Scalars['Float']['input'];
  upper: Scalars['Float']['input'];
};

export enum Forecasting {
  Future = 'future',
  Historical = 'historical'
}

export type ForecastingFilterComparison = {
  eq?: InputMaybe<Forecasting>;
  gt?: InputMaybe<Forecasting>;
  gte?: InputMaybe<Forecasting>;
  iLike?: InputMaybe<Forecasting>;
  in?: InputMaybe<Array<Forecasting>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Forecasting>;
  lt?: InputMaybe<Forecasting>;
  lte?: InputMaybe<Forecasting>;
  neq?: InputMaybe<Forecasting>;
  notILike?: InputMaybe<Forecasting>;
  notIn?: InputMaybe<Array<Forecasting>>;
  notLike?: InputMaybe<Forecasting>;
};

export type FoundStockTaskCreateAndCompleteOneInput = {
  /** The record to create */
  createAndCompleteOneFoundStockTaskDto: CreateAndCompleteOneFoundStockTaskDto;
};

/** Fulfillment Block model */
export type FulfillmentBlock = {
  __typename?: 'FulfillmentBlock';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity's label */
  label: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type FulfillmentBlockCreateInput = {
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Entity's label */
  label: Scalars['String']['input'];
};

export type FulfillmentBlockFilter = {
  and?: InputMaybe<Array<FulfillmentBlockFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<FulfillmentBlockFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type FulfillmentBlockInput = {
  /** Created on date */
  fulfillmentBlockId: Scalars['String']['input'];
  /** Bill of lading */
  id: Scalars['String']['input'];
};

export type FulfillmentBlockOffsetConnection = {
  __typename?: 'FulfillmentBlockOffsetConnection';
  /** Array of nodes. */
  nodes: Array<FulfillmentBlock>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type FulfillmentBlockSort = {
  direction: SortDirection;
  field: FulfillmentBlockSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FulfillmentBlockSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  Id = 'id',
  Label = 'label',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type FulfillmentBlockUpdateInput = {
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Entity's label */
  label?: InputMaybe<Scalars['String']['input']>;
};

/** FulfillmentItem model */
export type FulfillmentItem = {
  __typename?: 'FulfillmentItem';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Customer material */
  customerProductCode?: Maybe<Scalars['String']['output']>;
  /** Date the material is available */
  dateMaterialAvailable?: Maybe<Scalars['DateTime']['output']>;
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  delivery?: Maybe<Delivery>;
  /** Delivery ID (foreign key) */
  deliveryId: Scalars['ID']['output'];
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** When data was last updated in ERP */
  erpLastChanged?: Maybe<Scalars['DateTime']['output']>;
  /**
   * Reference document
   * @deprecated Update to reference sales order on the parent delivery entity
   */
  erpSalesOrder?: Maybe<Scalars['String']['output']>;
  /** Reference item */
  erpSalesOrderItem?: Maybe<Scalars['String']['output']>;
  /** Delivery Document */
  fulfillmentCode?: Maybe<Scalars['String']['output']>;
  /** Fulfillment item associated with the entity */
  fulfillmentItem: Scalars['String']['output'];
  /** Quantity of product */
  fulfillmentQuantity: Scalars['String']['output'];
  /** Unit of measure for entity */
  fulfillmentQuantityUom?: Maybe<Scalars['String']['output']>;
  /** Gross weight of entity */
  grossWeight?: Maybe<Scalars['Float']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  issueStatus?: Maybe<DeliveryCompletionStatus>;
  /** Entity's license plate ID (foreign key) */
  licensePlateId?: Maybe<Scalars['ID']['output']>;
  /** Level the license plate is in the heiarchy */
  licensePlateLevel?: Maybe<Scalars['String']['output']>;
  /** Load status */
  loadStatus?: Maybe<DeliveryCompletionStatus>;
  lot?: Maybe<Lot>;
  /** Entity's lot ID (foreign key) */
  lotId?: Maybe<Scalars['ID']['output']>;
  /** Net weight of entity */
  netWeight?: Maybe<Scalars['Float']['output']>;
  /** Pick status */
  pickStatus?: Maybe<DeliveryCompletionStatus>;
  product?: Maybe<Product>;
  /** Entity's product ID (foreign key) */
  productId?: Maybe<Scalars['ID']['output']>;
  stockStatusType?: Maybe<StockStatusType>;
  /** Entity's stock status type ID (foreign key) */
  stockStatusTypeId?: Maybe<Scalars['ID']['output']>;
  /** Unit of measure ID */
  unitOfMeasureId: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Volume of entity */
  volume?: Maybe<Scalars['Float']['output']>;
  /** Volume in unit of measure for entity */
  volumeUOMId?: Maybe<Scalars['ID']['output']>;
  /** Weight UOM, i.e KG */
  weightUOMId?: Maybe<Scalars['ID']['output']>;
};

export type FulfillmentItemCreateInput = {
  /** Customer material */
  customerProductCode?: InputMaybe<Scalars['String']['input']>;
  /** Date the material is available */
  dateMaterialAvailable?: InputMaybe<Scalars['DateTime']['input']>;
  /** Delivery ID (foreign key) */
  deliveryId?: InputMaybe<Scalars['ID']['input']>;
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** When data was last updated in ERP */
  erpLastChanged?: InputMaybe<Scalars['DateTime']['input']>;
  /** Reference document */
  erpSalesOrder?: InputMaybe<Scalars['String']['input']>;
  /** Reference item */
  erpSalesOrderItem?: InputMaybe<Scalars['String']['input']>;
  /** Delivery Document */
  fulfillmentCode?: InputMaybe<Scalars['String']['input']>;
  /** Fulfillment item associated with the entity */
  fulfillmentItem?: InputMaybe<Scalars['String']['input']>;
  /** Quantity of product */
  fulfillmentQuantity?: InputMaybe<Scalars['String']['input']>;
  /** Unit of measure for entity */
  fulfillmentQuantityUom?: InputMaybe<Scalars['String']['input']>;
  /** Gross weight of entity */
  grossWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Entity's lot ID (foreign key) */
  lotId?: InputMaybe<Scalars['ID']['input']>;
  /** Net weight of entity */
  netWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Pick status */
  pickStatus?: InputMaybe<DeliveryCompletionStatus>;
  /** Entity's product ID (foreign key) */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's stock status type ID (foreign key) */
  stockStatusTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** Volume of entity */
  volume?: InputMaybe<Scalars['Float']['input']>;
  /** Volume in unit of measure for entity */
  volumeUOMId?: InputMaybe<Scalars['String']['input']>;
  /** Weight UOM, i.e KG */
  weightUOMId?: InputMaybe<Scalars['String']['input']>;
};

export type FulfillmentItemCreateOneInput = {
  /** The record to create */
  fulfillmentItem: FulfillmentItemCreateInput;
};

export type FulfillmentItemDetails = {
  __typename?: 'FulfillmentItemDetails';
  /** Entity code */
  destinationBinCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  destinationBinId?: Maybe<Scalars['ID']['output']>;
  /** Fulfillment item associated with the entity */
  fulfillmentItem: Scalars['String']['output'];
  /** Fulfillment item id (foreign key) */
  id: Scalars['ID']['output'];
  /** Entity code */
  licensePlateCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  licensePlateDescription?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  licensePlateId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  lotCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  lotId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  productCode: Scalars['String']['output'];
  /** Description of entity */
  productDescription?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  productId: Scalars['ID']['output'];
  /** Quantity of product */
  quantity: Scalars['String']['output'];
  /** Entity code */
  sourceBinCode?: Maybe<Scalars['String']['output']>;
  /** Entity's bin ID (foreign key) */
  sourceBinId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  stockStatusCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  stockStatusId?: Maybe<Scalars['ID']['output']>;
};

export type FulfillmentItemFilter = {
  and?: InputMaybe<Array<FulfillmentItemFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  customerProductCode?: InputMaybe<StringFieldComparison>;
  dateMaterialAvailable?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  deliveryId?: InputMaybe<IdFilterComparison>;
  description?: InputMaybe<StringFieldComparison>;
  erpLastChanged?: InputMaybe<DateFieldComparison>;
  erpSalesOrder?: InputMaybe<StringFieldComparison>;
  erpSalesOrderItem?: InputMaybe<StringFieldComparison>;
  fulfillmentCode?: InputMaybe<StringFieldComparison>;
  fulfillmentItem?: InputMaybe<StringFieldComparison>;
  fulfillmentQuantity?: InputMaybe<StringFieldComparison>;
  fulfillmentQuantityUom?: InputMaybe<StringFieldComparison>;
  grossWeight?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  issueStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  licensePlateId?: InputMaybe<IdFilterComparison>;
  licensePlateLevel?: InputMaybe<StringFieldComparison>;
  loadStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  lotId?: InputMaybe<IdFilterComparison>;
  netWeight?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<FulfillmentItemFilter>>;
  pickStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  productId?: InputMaybe<IdFilterComparison>;
  stockStatusTypeId?: InputMaybe<IdFilterComparison>;
  unitOfMeasureId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  volume?: InputMaybe<FloatFieldComparison>;
  volumeUOMId?: InputMaybe<IdFilterComparison>;
  weightUOMId?: InputMaybe<IdFilterComparison>;
};

export type FulfillmentItemForFulfillmentCreateInput = {
  /** Customer material */
  customerProductCode?: InputMaybe<Scalars['String']['input']>;
  /** Description of entity */
  dateMaterialAvailable?: InputMaybe<Scalars['DateTime']['input']>;
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Description of entity */
  erpLastChanged?: InputMaybe<Scalars['DateTime']['input']>;
  /** Reference document */
  erpSalesOrder?: InputMaybe<Scalars['String']['input']>;
  /** Reference document */
  erpSalesOrderItem?: InputMaybe<Scalars['String']['input']>;
  /** Delivery Document */
  fulfillmentCode?: InputMaybe<Scalars['String']['input']>;
  /** Fulfillment item associated with the entity */
  fulfillmentItem: Scalars['String']['input'];
  /** Quantity of product */
  fulfillmentQuantity: Scalars['String']['input'];
  /** Unit of measure for entity */
  fulfillmentQuantityUom?: InputMaybe<Scalars['String']['input']>;
  /** Gross weight of entity */
  grossWeight?: InputMaybe<Scalars['Float']['input']>;
  issueStatus?: InputMaybe<DeliveryCompletionStatus>;
  /** Entity's license plate ID (foreign key) */
  licensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** Load status */
  licensePlateLevel?: InputMaybe<DeliveryCompletionStatus>;
  /** Load status */
  loadStatus?: InputMaybe<DeliveryCompletionStatus>;
  /** Entity's lot ID (foreign key) */
  lotId?: InputMaybe<Scalars['ID']['input']>;
  /** Pick status */
  pickStatus?: InputMaybe<DeliveryCompletionStatus>;
  /** Entity's product ID (foreign key) */
  productId: Scalars['ID']['input'];
  /** Unit of measure ID */
  unitOfMeasureId: Scalars['ID']['input'];
};

export type FulfillmentItemNodes = {
  __typename?: 'FulfillmentItemNodes';
  nodes: Array<FulfillmentItem>;
  totalCount: Scalars['Int']['output'];
};

export type FulfillmentItemOffsetConnection = {
  __typename?: 'FulfillmentItemOffsetConnection';
  /** Array of nodes. */
  nodes: Array<FulfillmentItem>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type FulfillmentItemSearchResults = {
  __typename?: 'FulfillmentItemSearchResults';
  erpSalesOrder?: Maybe<Scalars['String']['output']>;
  erpSalesOrderItem?: Maybe<Scalars['String']['output']>;
  fulfillmentItem?: Maybe<Scalars['String']['output']>;
  issueStatus?: Maybe<Scalars['String']['output']>;
  lotCode?: Maybe<Scalars['String']['output']>;
  pickStatus?: Maybe<Scalars['String']['output']>;
  productCode?: Maybe<Scalars['String']['output']>;
  productDescription?: Maybe<Scalars['String']['output']>;
};

export type FulfillmentItemSort = {
  direction: SortDirection;
  field: FulfillmentItemSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FulfillmentItemSortFields {
  CreatedAt = 'createdAt',
  CustomerProductCode = 'customerProductCode',
  DateMaterialAvailable = 'dateMaterialAvailable',
  DeletedAt = 'deletedAt',
  DeliveryId = 'deliveryId',
  Description = 'description',
  ErpLastChanged = 'erpLastChanged',
  ErpSalesOrder = 'erpSalesOrder',
  ErpSalesOrderItem = 'erpSalesOrderItem',
  FulfillmentCode = 'fulfillmentCode',
  FulfillmentItem = 'fulfillmentItem',
  FulfillmentQuantity = 'fulfillmentQuantity',
  FulfillmentQuantityUom = 'fulfillmentQuantityUom',
  GrossWeight = 'grossWeight',
  Id = 'id',
  IssueStatus = 'issueStatus',
  LicensePlateId = 'licensePlateId',
  LicensePlateLevel = 'licensePlateLevel',
  LoadStatus = 'loadStatus',
  LotId = 'lotId',
  NetWeight = 'netWeight',
  PickStatus = 'pickStatus',
  ProductId = 'productId',
  StockStatusTypeId = 'stockStatusTypeId',
  UnitOfMeasureId = 'unitOfMeasureId',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  Volume = 'volume',
  VolumeUomId = 'volumeUOMId',
  WeightUomId = 'weightUOMId'
}

export type FulfillmentItemUpdateInput = {
  /** Customer material */
  customerProductCode?: InputMaybe<Scalars['String']['input']>;
  /** Date the material is available */
  dateMaterialAvailable?: InputMaybe<Scalars['DateTime']['input']>;
  /** Delivery ID (foreign key) */
  deliveryId?: InputMaybe<Scalars['ID']['input']>;
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** When data was last updated in ERP */
  erpLastChanged?: InputMaybe<Scalars['DateTime']['input']>;
  /** Reference document */
  erpSalesOrder?: InputMaybe<Scalars['String']['input']>;
  /** Reference item */
  erpSalesOrderItem?: InputMaybe<Scalars['String']['input']>;
  /** Delivery Document */
  fulfillmentCode?: InputMaybe<Scalars['String']['input']>;
  /** Fulfillment item associated with the entity */
  fulfillmentItem?: InputMaybe<Scalars['String']['input']>;
  /** Quantity of product */
  fulfillmentQuantity?: InputMaybe<Scalars['String']['input']>;
  /** Unit of measure for entity */
  fulfillmentQuantityUom?: InputMaybe<Scalars['String']['input']>;
  /** Gross weight of entity */
  grossWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Entity's lot ID (foreign key) */
  lotId?: InputMaybe<Scalars['ID']['input']>;
  /** Net weight of entity */
  netWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Pick status */
  pickStatus?: InputMaybe<DeliveryCompletionStatus>;
  /** Entity's product ID (foreign key) */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's stock status type ID (foreign key) */
  stockStatusTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** Volume of entity */
  volume?: InputMaybe<Scalars['Float']['input']>;
  /** Volume in unit of measure for entity */
  volumeUOMId?: InputMaybe<Scalars['String']['input']>;
  /** Weight UOM, i.e KG */
  weightUOMId?: InputMaybe<Scalars['String']['input']>;
};

export type FulfillmentItemUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: FulfillmentItemUpdateInput;
};

/** Fulfillment Search results */
export type FulfillmentSearchResults = {
  __typename?: 'FulfillmentSearchResults';
  billOfLading?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  erpCode?: Maybe<Scalars['String']['output']>;
  erpPurchaseOrder?: Maybe<Scalars['String']['output']>;
  erpSalesOrder?: Maybe<Scalars['String']['output']>;
  fulfillmentItems?: Maybe<Array<FulfillmentItemSearchResults>>;
  fulfillmentStatus?: Maybe<Scalars['String']['output']>;
  goodsIssueStatus?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  index?: Maybe<Scalars['String']['output']>;
  pickStatus?: Maybe<Scalars['String']['output']>;
  shipTo?: Maybe<Scalars['String']['output']>;
  shipToBusinessPartnerId?: Maybe<Scalars['ID']['output']>;
  shipToName?: Maybe<Scalars['String']['output']>;
  soldTo?: Maybe<Scalars['String']['output']>;
  soldToBusinessPartnerId?: Maybe<Scalars['ID']['output']>;
  soldToName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  warehouseCode?: Maybe<Scalars['String']['output']>;
  warehouseName?: Maybe<Scalars['String']['output']>;
};

export type FulfillmentUnblockInput = {
  /** Bill of lading */
  id: Scalars['String']['input'];
};

export type FulfillmentWithItemsCreateOneDtoInput = {
  billOfLading?: InputMaybe<Scalars['String']['input']>;
  blockStatus?: InputMaybe<Scalars['String']['input']>;
  /** Delivery category */
  category?: InputMaybe<DeliveryCategory>;
  deliveryStatus?: InputMaybe<DeliveryCompletionStatus>;
  doorId: Scalars['ID']['input'];
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  erpBlockingReason?: InputMaybe<Scalars['String']['input']>;
  erpCode?: InputMaybe<Scalars['String']['input']>;
  erpCreated?: InputMaybe<Scalars['DateTime']['input']>;
  /** When data was last updated in ERP */
  erpLastChanged?: InputMaybe<Scalars['DateTime']['input']>;
  erpPurchaseOrder?: InputMaybe<Scalars['String']['input']>;
  erpSalesOrder?: InputMaybe<Scalars['String']['input']>;
  export?: InputMaybe<Scalars['String']['input']>;
  fulfillmentBlockId?: InputMaybe<Scalars['ID']['input']>;
  /** Fulfillment items to create */
  fulfillmentItems?: InputMaybe<Array<FulfillmentItemForFulfillmentCreateInput>>;
  goodsReceiptOrIssueStatus?: InputMaybe<DeliveryCompletionStatus>;
  loadOrUnloadStatus?: InputMaybe<DeliveryCompletionStatus>;
  pickOrPutawayStatus?: InputMaybe<DeliveryCompletionStatus>;
  pointOfContact?: InputMaybe<Scalars['String']['input']>;
  /** Delivery promise date */
  promiseDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** When data was last updated in ERP */
  shipReadyDate?: InputMaybe<Scalars['DateTime']['input']>;
  shipTo?: InputMaybe<Scalars['String']['input']>;
  shipToBusinessPartnerId?: InputMaybe<Scalars['ID']['input']>;
  shipToName?: InputMaybe<Scalars['String']['input']>;
  soldTo?: InputMaybe<Scalars['String']['input']>;
  soldToBusinessPartnerId?: InputMaybe<Scalars['ID']['input']>;
  soldToName?: InputMaybe<Scalars['String']['input']>;
  supplier?: InputMaybe<Scalars['String']['input']>;
  supplierBusinessPartnerId?: InputMaybe<Scalars['ID']['input']>;
  supplierName?: InputMaybe<Scalars['String']['input']>;
  totalGrossWeight?: InputMaybe<Scalars['Float']['input']>;
  totalNetWeight?: InputMaybe<Scalars['Float']['input']>;
  totalVolume?: InputMaybe<Scalars['Float']['input']>;
  totalVolumeUom?: InputMaybe<Scalars['String']['input']>;
  totalWeightUom?: InputMaybe<Scalars['String']['input']>;
};

export type FulfillmentWithItemsCreateOneInput = {
  /** The record to create */
  fulfillmentWithItems: FulfillmentWithItemsCreateOneDtoInput;
};

export type GenerateDataOutputDto = {
  __typename?: 'GenerateDataOutputDto';
  deliveries: Array<Delivery>;
  fulfillments: Array<Delivery>;
};

export type GlobalSearchResultWithAggs = {
  __typename?: 'GlobalSearchResultWithAggs';
  aggs?: Maybe<AggsResults>;
  hits: Array<GlobalSearchResults>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GlobalSearchResults = AreaSearchResults | BinSearchResults | DeliverySearchResults | EmptySearchResults | FulfillmentSearchResults | LicensePlateSearchResults | ProductSearchResults | TaskSearchResults;

export enum HandlingUnitType {
  Carton = 'carton',
  Mixed = 'mixed',
  Uniform = 'uniform'
}

export type IdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  iLike?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  neq?: InputMaybe<Scalars['ID']['input']>;
  notILike?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  notLike?: InputMaybe<Scalars['ID']['input']>;
};

export type IntFieldComparison = {
  between?: InputMaybe<IntFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  neq?: InputMaybe<Scalars['Int']['input']>;
  notBetween?: InputMaybe<IntFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntFieldComparisonBetween = {
  lower: Scalars['Int']['input'];
  upper: Scalars['Int']['input'];
};

export type InventoryBaseQueryShape = {
  __typename?: 'InventoryBaseQueryShape';
  /** Entity code */
  areaCode?: Maybe<Scalars['String']['output']>;
  /** Entity's area ID (foreign key) */
  areaId?: Maybe<Scalars['ID']['output']>;
  /** Available quantity */
  availableQuantity?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  binCode?: Maybe<Scalars['String']['output']>;
  /** Entity's bin ID (foreign key) */
  binId?: Maybe<Scalars['ID']['output']>;
  /** Bin type */
  binType?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  deliveryCode?: Maybe<Scalars['String']['output']>;
  /** Delivery ID (foreign key) */
  deliveryId?: Maybe<Scalars['ID']['output']>;
  /** Delivery item ID (foreign key) */
  deliveryItemId?: Maybe<Scalars['ID']['output']>;
  /** Delivery item associated with entity */
  deliveryItemItem?: Maybe<Scalars['String']['output']>;
  /** Unit of measure for dimensions of a license plate */
  dimensionUOM?: Maybe<Scalars['String']['output']>;
  /** Delivery or fulfillment erp code */
  erpSalesOrder?: Maybe<Scalars['String']['output']>;
  /** Fulfillment item id (foreign key) */
  fulfillmentItemId?: Maybe<Scalars['ID']['output']>;
  /** Fulfillment item associated with the entity */
  fulfillmentItemItem?: Maybe<Scalars['String']['output']>;
  /** Gross volume of entity */
  grossVolume?: Maybe<Scalars['Float']['output']>;
  /** Gross weight of entity */
  grossWeight?: Maybe<Scalars['Float']['output']>;
  /** Height of license plate */
  height?: Maybe<Scalars['Float']['output']>;
  /** Length of license plate */
  length?: Maybe<Scalars['Float']['output']>;
  /** Entity code */
  licensePlateCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  licensePlateDescription?: Maybe<Scalars['String']['output']>;
  /** Entity's license plate ID (foreign key) */
  licensePlateId?: Maybe<Scalars['ID']['output']>;
  /** Active/Inactive bin status flag */
  licensePlateStatus?: Maybe<LicensePlateStatusState>;
  /** Entity code */
  lotCode?: Maybe<Scalars['String']['output']>;
  /** Entity's lot ID (foreign key) */
  lotId?: Maybe<Scalars['ID']['output']>;
  /** Whether the product is lot managed or not */
  lotManaged?: Maybe<Scalars['Boolean']['output']>;
  /** Net weight of entity */
  netWeight?: Maybe<Scalars['Float']['output']>;
  /** Number of open tasks */
  openTaskCount?: Maybe<Scalars['Int']['output']>;
  /** Open task quantity */
  openTaskQuantity?: Maybe<Scalars['String']['output']>;
  /** License Plate Parent ID */
  parentId?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  productCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  productDescription?: Maybe<Scalars['String']['output']>;
  /** Product ID */
  productId?: Maybe<Scalars['ID']['output']>;
  /** Quantity of product */
  quantity: Scalars['String']['output'];
  /** Business partner code */
  soldToBusinessPartnerCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  soldToBusinessPartnerId?: Maybe<Scalars['ID']['output']>;
  /** Business partner name */
  soldToBusinessPartnerName?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  stockDeliveryCode?: Maybe<Scalars['String']['output']>;
  /** Delivery ID (foreign key) */
  stockDeliveryId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  stockFulfillmentCode?: Maybe<Scalars['String']['output']>;
  /** Fulfillment Id */
  stockFulfillmentId?: Maybe<Scalars['ID']['output']>;
  /** I.e. Returns, Available, Lot Restricted etc. */
  stockStatus?: Maybe<Scalars['String']['output']>;
  /** Entity's stock status type ID (foreign key) */
  stockStatusTypeId?: Maybe<Scalars['ID']['output']>;
  /** Unit of measure for entity */
  unitOfMeasure?: Maybe<Scalars['String']['output']>;
  /** Unit of measure ID */
  unitOfMeasureId?: Maybe<Scalars['ID']['output']>;
  /** Volume in unit of measure for entity */
  volumeUOM?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  warehouseCode?: Maybe<Scalars['String']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** Weight UOM, i.e KG */
  weightUOM?: Maybe<Scalars['String']['output']>;
  /** Width of license plate */
  width?: Maybe<Scalars['Float']['output']>;
};

export type InventoryBaseQueryShapeFilter = {
  and?: InputMaybe<Array<InventoryBaseQueryShapeFilter>>;
  areaCode?: InputMaybe<StringFieldComparison>;
  areaId?: InputMaybe<IdFilterComparison>;
  availableQuantity?: InputMaybe<StringFieldComparison>;
  binCode?: InputMaybe<StringFieldComparison>;
  binId?: InputMaybe<IdFilterComparison>;
  binType?: InputMaybe<StringFieldComparison>;
  deliveryCode?: InputMaybe<StringFieldComparison>;
  deliveryId?: InputMaybe<IdFilterComparison>;
  deliveryItemId?: InputMaybe<IdFilterComparison>;
  deliveryItemItem?: InputMaybe<StringFieldComparison>;
  dimensionUOM?: InputMaybe<StringFieldComparison>;
  erpSalesOrder?: InputMaybe<StringFieldComparison>;
  fulfillmentItemId?: InputMaybe<IdFilterComparison>;
  fulfillmentItemItem?: InputMaybe<StringFieldComparison>;
  grossVolume?: InputMaybe<FloatFieldComparison>;
  grossWeight?: InputMaybe<FloatFieldComparison>;
  height?: InputMaybe<FloatFieldComparison>;
  length?: InputMaybe<FloatFieldComparison>;
  licensePlateCode?: InputMaybe<StringFieldComparison>;
  licensePlateDescription?: InputMaybe<StringFieldComparison>;
  licensePlateId?: InputMaybe<IdFilterComparison>;
  licensePlateStatus?: InputMaybe<LicensePlateStatusStateFilterComparison>;
  lotCode?: InputMaybe<StringFieldComparison>;
  lotId?: InputMaybe<IdFilterComparison>;
  lotManaged?: InputMaybe<BooleanFieldComparison>;
  netWeight?: InputMaybe<FloatFieldComparison>;
  openTaskCount?: InputMaybe<IntFieldComparison>;
  openTaskQuantity?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InventoryBaseQueryShapeFilter>>;
  parentId?: InputMaybe<StringFieldComparison>;
  productCode?: InputMaybe<StringFieldComparison>;
  productDescription?: InputMaybe<StringFieldComparison>;
  productId?: InputMaybe<IdFilterComparison>;
  quantity?: InputMaybe<StringFieldComparison>;
  soldToBusinessPartnerCode?: InputMaybe<StringFieldComparison>;
  soldToBusinessPartnerId?: InputMaybe<IdFilterComparison>;
  soldToBusinessPartnerName?: InputMaybe<StringFieldComparison>;
  stockDeliveryCode?: InputMaybe<StringFieldComparison>;
  stockDeliveryId?: InputMaybe<IdFilterComparison>;
  stockFulfillmentCode?: InputMaybe<StringFieldComparison>;
  stockFulfillmentId?: InputMaybe<IdFilterComparison>;
  stockStatus?: InputMaybe<StringFieldComparison>;
  stockStatusTypeId?: InputMaybe<IdFilterComparison>;
  unitOfMeasure?: InputMaybe<StringFieldComparison>;
  unitOfMeasureId?: InputMaybe<IdFilterComparison>;
  volumeUOM?: InputMaybe<StringFieldComparison>;
  warehouseCode?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  weightUOM?: InputMaybe<StringFieldComparison>;
  width?: InputMaybe<FloatFieldComparison>;
};

export type InventoryBaseQueryShapeOffsetConnection = {
  __typename?: 'InventoryBaseQueryShapeOffsetConnection';
  /** Array of nodes. */
  nodes: Array<InventoryBaseQueryShape>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type InventoryBaseQueryShapeSort = {
  direction: SortDirection;
  field: InventoryBaseQueryShapeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum InventoryBaseQueryShapeSortFields {
  AreaCode = 'areaCode',
  AreaId = 'areaId',
  AvailableQuantity = 'availableQuantity',
  BinCode = 'binCode',
  BinId = 'binId',
  BinType = 'binType',
  DeliveryCode = 'deliveryCode',
  DeliveryId = 'deliveryId',
  DeliveryItemId = 'deliveryItemId',
  DeliveryItemItem = 'deliveryItemItem',
  DimensionUom = 'dimensionUOM',
  ErpSalesOrder = 'erpSalesOrder',
  FulfillmentItemId = 'fulfillmentItemId',
  FulfillmentItemItem = 'fulfillmentItemItem',
  GrossVolume = 'grossVolume',
  GrossWeight = 'grossWeight',
  Height = 'height',
  Length = 'length',
  LicensePlateCode = 'licensePlateCode',
  LicensePlateDescription = 'licensePlateDescription',
  LicensePlateId = 'licensePlateId',
  LicensePlateStatus = 'licensePlateStatus',
  LotCode = 'lotCode',
  LotId = 'lotId',
  LotManaged = 'lotManaged',
  NetWeight = 'netWeight',
  OpenTaskCount = 'openTaskCount',
  OpenTaskQuantity = 'openTaskQuantity',
  ParentId = 'parentId',
  ProductCode = 'productCode',
  ProductDescription = 'productDescription',
  ProductId = 'productId',
  Quantity = 'quantity',
  SoldToBusinessPartnerCode = 'soldToBusinessPartnerCode',
  SoldToBusinessPartnerId = 'soldToBusinessPartnerId',
  SoldToBusinessPartnerName = 'soldToBusinessPartnerName',
  StockDeliveryCode = 'stockDeliveryCode',
  StockDeliveryId = 'stockDeliveryId',
  StockFulfillmentCode = 'stockFulfillmentCode',
  StockFulfillmentId = 'stockFulfillmentId',
  StockStatus = 'stockStatus',
  StockStatusTypeId = 'stockStatusTypeId',
  UnitOfMeasure = 'unitOfMeasure',
  UnitOfMeasureId = 'unitOfMeasureId',
  VolumeUom = 'volumeUOM',
  WarehouseCode = 'warehouseCode',
  WarehouseId = 'warehouseId',
  WeightUom = 'weightUOM',
  Width = 'width'
}

export enum InventoryCategory {
  Executable = 'EXECUTABLE',
  Planned = 'PLANNED'
}

export type InventoryLotQueryShape = {
  __typename?: 'InventoryLotQueryShape';
  /** Available quantity */
  availableQuantity?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Lot expiration date */
  expirationDate?: Maybe<Scalars['ID']['output']>;
  /** Gross weight of entity */
  grossWeight?: Maybe<Scalars['Float']['output']>;
  /** Entity code */
  lotCode?: Maybe<Scalars['ID']['output']>;
  /** Entity's lot ID (foreign key) */
  lotId?: Maybe<Scalars['ID']['output']>;
  /** Number of open tasks */
  openTaskCount?: Maybe<Scalars['Int']['output']>;
  /** Open task quantity */
  openTaskQuantity?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  productCode?: Maybe<Scalars['String']['output']>;
  /** Product ID */
  productId?: Maybe<Scalars['ID']['output']>;
  /** Lot production date */
  productionDate?: Maybe<Scalars['ID']['output']>;
  /** Quantity of product */
  quantity?: Maybe<Scalars['String']['output']>;
  /** I.e. Returns, Available, Lot Restricted etc. */
  stockStatus?: Maybe<Scalars['String']['output']>;
  /** Entity's stock status type ID (foreign key) */
  stockStatusTypeId?: Maybe<Scalars['ID']['output']>;
  /** Weight UOM, i.e KG */
  totalWeightUOM?: Maybe<Scalars['String']['output']>;
  /** Unit of measure for entity */
  unitOfMeasure?: Maybe<Scalars['String']['output']>;
  /** Unit of measure ID */
  unitOfMeasureId?: Maybe<Scalars['ID']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
};

export type InventoryLotQueryShapeFilter = {
  and?: InputMaybe<Array<InventoryLotQueryShapeFilter>>;
  availableQuantity?: InputMaybe<StringFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  expirationDate?: InputMaybe<IdFilterComparison>;
  grossWeight?: InputMaybe<FloatFieldComparison>;
  lotCode?: InputMaybe<IdFilterComparison>;
  lotId?: InputMaybe<IdFilterComparison>;
  openTaskCount?: InputMaybe<IntFieldComparison>;
  openTaskQuantity?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InventoryLotQueryShapeFilter>>;
  productCode?: InputMaybe<StringFieldComparison>;
  productId?: InputMaybe<IdFilterComparison>;
  productionDate?: InputMaybe<IdFilterComparison>;
  quantity?: InputMaybe<StringFieldComparison>;
  stockStatus?: InputMaybe<StringFieldComparison>;
  stockStatusTypeId?: InputMaybe<IdFilterComparison>;
  totalWeightUOM?: InputMaybe<StringFieldComparison>;
  unitOfMeasure?: InputMaybe<StringFieldComparison>;
  unitOfMeasureId?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type InventoryLotQueryShapeOffsetConnection = {
  __typename?: 'InventoryLotQueryShapeOffsetConnection';
  /** Array of nodes. */
  nodes: Array<InventoryLotQueryShape>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type InventoryLotQueryShapeSort = {
  direction: SortDirection;
  field: InventoryLotQueryShapeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum InventoryLotQueryShapeSortFields {
  AvailableQuantity = 'availableQuantity',
  Description = 'description',
  ExpirationDate = 'expirationDate',
  GrossWeight = 'grossWeight',
  LotCode = 'lotCode',
  LotId = 'lotId',
  OpenTaskCount = 'openTaskCount',
  OpenTaskQuantity = 'openTaskQuantity',
  ProductCode = 'productCode',
  ProductId = 'productId',
  ProductionDate = 'productionDate',
  Quantity = 'quantity',
  StockStatus = 'stockStatus',
  StockStatusTypeId = 'stockStatusTypeId',
  TotalWeightUom = 'totalWeightUOM',
  UnitOfMeasure = 'unitOfMeasure',
  UnitOfMeasureId = 'unitOfMeasureId',
  WarehouseId = 'warehouseId'
}

export type InventoryProductQueryShape = {
  __typename?: 'InventoryProductQueryShape';
  /** Available quantity */
  availableQuantity?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Gross weight of entity */
  grossWeight?: Maybe<Scalars['Float']['output']>;
  /** Number of open tasks */
  openTaskCount?: Maybe<Scalars['Int']['output']>;
  /** Open task quantity */
  openTaskQuantity?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  productCode?: Maybe<Scalars['String']['output']>;
  /** Product ID */
  productId?: Maybe<Scalars['ID']['output']>;
  /** Quantity of product */
  quantity?: Maybe<Scalars['String']['output']>;
  /** I.e. Returns, Available, Lot Restricted etc. */
  stockStatus?: Maybe<Scalars['String']['output']>;
  /** Entity's stock status type ID (foreign key) */
  stockStatusTypeId?: Maybe<Scalars['ID']['output']>;
  /** Weight UOM, i.e KG */
  totalWeightUOM?: Maybe<Scalars['String']['output']>;
  /** Unit of measure for entity */
  unitOfMeasure?: Maybe<Scalars['String']['output']>;
  /** Unit of measure ID */
  unitOfMeasureId?: Maybe<Scalars['ID']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
};

export type InventoryProductQueryShapeFilter = {
  and?: InputMaybe<Array<InventoryProductQueryShapeFilter>>;
  availableQuantity?: InputMaybe<StringFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  grossWeight?: InputMaybe<FloatFieldComparison>;
  openTaskCount?: InputMaybe<IntFieldComparison>;
  openTaskQuantity?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<InventoryProductQueryShapeFilter>>;
  productCode?: InputMaybe<StringFieldComparison>;
  productId?: InputMaybe<IdFilterComparison>;
  quantity?: InputMaybe<StringFieldComparison>;
  stockStatus?: InputMaybe<StringFieldComparison>;
  stockStatusTypeId?: InputMaybe<IdFilterComparison>;
  totalWeightUOM?: InputMaybe<StringFieldComparison>;
  unitOfMeasure?: InputMaybe<StringFieldComparison>;
  unitOfMeasureId?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type InventoryProductQueryShapeOffsetConnection = {
  __typename?: 'InventoryProductQueryShapeOffsetConnection';
  /** Array of nodes. */
  nodes: Array<InventoryProductQueryShape>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type InventoryProductQueryShapeSort = {
  direction: SortDirection;
  field: InventoryProductQueryShapeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum InventoryProductQueryShapeSortFields {
  AvailableQuantity = 'availableQuantity',
  Description = 'description',
  GrossWeight = 'grossWeight',
  OpenTaskCount = 'openTaskCount',
  OpenTaskQuantity = 'openTaskQuantity',
  ProductCode = 'productCode',
  ProductId = 'productId',
  Quantity = 'quantity',
  StockStatus = 'stockStatus',
  StockStatusTypeId = 'stockStatusTypeId',
  TotalWeightUom = 'totalWeightUOM',
  UnitOfMeasure = 'unitOfMeasure',
  UnitOfMeasureId = 'unitOfMeasureId',
  WarehouseId = 'warehouseId'
}

export type IssueStockTaskCreateOneInput = {
  /** The record to create */
  createOneIssueStockTaskDto: CreateOneIssueStockTaskDto;
};

export type JsonFilterComparison = {
  eq?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  iLike?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<Scalars['JSON']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  neq?: InputMaybe<Scalars['JSON']['input']>;
  notILike?: InputMaybe<Scalars['JSON']['input']>;
  notIn?: InputMaybe<Array<Scalars['JSON']['input']>>;
  notLike?: InputMaybe<Scalars['JSON']['input']>;
};

export type JsonObjectFilterComparison = {
  eq?: InputMaybe<Scalars['JSONObject']['input']>;
  gt?: InputMaybe<Scalars['JSONObject']['input']>;
  gte?: InputMaybe<Scalars['JSONObject']['input']>;
  iLike?: InputMaybe<Scalars['JSONObject']['input']>;
  in?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['JSONObject']['input']>;
  lt?: InputMaybe<Scalars['JSONObject']['input']>;
  lte?: InputMaybe<Scalars['JSONObject']['input']>;
  neq?: InputMaybe<Scalars['JSONObject']['input']>;
  notILike?: InputMaybe<Scalars['JSONObject']['input']>;
  notIn?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
  notLike?: InputMaybe<Scalars['JSONObject']['input']>;
};

export enum JobState {
  Disabled = 'disabled',
  Enabled = 'enabled',
  Paused = 'paused',
  StateUnspecified = 'stateUnspecified',
  UpdateFailed = 'updateFailed'
}

export type JobStateFilterComparison = {
  eq?: InputMaybe<JobState>;
  gt?: InputMaybe<JobState>;
  gte?: InputMaybe<JobState>;
  iLike?: InputMaybe<JobState>;
  in?: InputMaybe<Array<JobState>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<JobState>;
  lt?: InputMaybe<JobState>;
  lte?: InputMaybe<JobState>;
  neq?: InputMaybe<JobState>;
  notILike?: InputMaybe<JobState>;
  notIn?: InputMaybe<Array<JobState>>;
  notLike?: InputMaybe<JobState>;
};

export type Layout = {
  __typename?: 'Layout';
  /** Column widths in FE table */
  columnSizes?: Maybe<Scalars['JSON']['output']>;
  /** Columns visible in FE table */
  columns?: Maybe<Scalars['JSON']['output']>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Filter for FE table */
  filter?: Maybe<Scalars['JSON']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity's label */
  label: Scalars['String']['output'];
  /** Page size for FE tables */
  pageSize: Scalars['Float']['output'];
  /** Default shared layout for FE tables */
  sharedDefault: Scalars['Boolean']['output'];
  /** Sorting for FE tables */
  sorting?: Maybe<Scalars['JSON']['output']>;
  /** Identifier for front end tables. */
  tableId: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** User default for FE tables */
  userDefault: Scalars['Boolean']['output'];
  /** Entity's user ID (foreign key) */
  userId: Scalars['ID']['output'];
  /** User or shared layout for FE table layouts */
  variant: LayoutVariant;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
};

export type LayoutDeleteInputType = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type LayoutFilter = {
  and?: InputMaybe<Array<LayoutFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<LayoutFilter>>;
  tableId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  userId?: InputMaybe<IdFilterComparison>;
  variant?: InputMaybe<LayoutVariantFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type LayoutOffsetConnection = {
  __typename?: 'LayoutOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Layout>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type LayoutSharedCreateDto = {
  /** ApiDocs */
  columnSizes: Scalars['JSON']['input'];
  /** ApiDocs */
  columns: Scalars['JSON']['input'];
  /** ApiDocs */
  filter: Scalars['JSON']['input'];
  /** ApiDocs */
  label: Scalars['String']['input'];
  /** Identifier for front end tables. */
  pageSize: Scalars['Float']['input'];
  /** ApiDocs */
  sharedDefault: Scalars['Boolean']['input'];
  /** ApiDocs */
  sorting: Scalars['JSON']['input'];
  /** Identifier for front end tables. */
  tableId: Scalars['String']['input'];
  /** ApiDocs */
  userDefault: Scalars['Boolean']['input'];
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['String']['input'];
};

export type LayoutSharedCreateInput = {
  /** ApiDocs */
  input: LayoutSharedCreateDto;
};

export type LayoutSharedUpdateDto = {
  /** ApiDocs */
  columnSizes?: InputMaybe<Scalars['JSON']['input']>;
  /** ApiDocs */
  columns?: InputMaybe<Scalars['JSON']['input']>;
  /** ApiDocs */
  filter?: InputMaybe<Scalars['JSON']['input']>;
  /** ApiDocs */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Identifier for front end tables. */
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  /** ApiDocs */
  sharedDefault?: InputMaybe<Scalars['Boolean']['input']>;
  /** ApiDocs */
  sorting?: InputMaybe<Scalars['JSON']['input']>;
};

export type LayoutSharedUpdateInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** ApiDocs */
  update: LayoutSharedUpdateDto;
};

export type LayoutSort = {
  direction: SortDirection;
  field: LayoutSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LayoutSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  TableId = 'tableId',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  UserId = 'userId',
  Variant = 'variant',
  WarehouseId = 'warehouseId'
}

export type LayoutUserCreateDto = {
  /** ApiDocs */
  columnSizes: Scalars['JSON']['input'];
  /** ApiDocs */
  columns: Scalars['JSON']['input'];
  /** ApiDocs */
  filter: Scalars['JSON']['input'];
  /** ApiDocs */
  label: Scalars['String']['input'];
  /** Identifier for front end tables. */
  pageSize: Scalars['Float']['input'];
  /** ApiDocs */
  sorting: Scalars['JSON']['input'];
  /** Identifier for front end tables. */
  tableId: Scalars['String']['input'];
  /** ApiDocs */
  userDefault: Scalars['Boolean']['input'];
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['String']['input'];
};

export type LayoutUserCreateInput = {
  /** ApiDocs */
  input: LayoutUserCreateDto;
};

export type LayoutUserSetDefaultDto = {
  /** ApiDocs */
  userDefault: Scalars['Boolean']['input'];
};

export type LayoutUserSetDefaultInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** ApiDocs */
  update: LayoutUserSetDefaultDto;
};

export type LayoutUserUpdateDto = {
  /** ApiDocs */
  columnSizes?: InputMaybe<Scalars['JSON']['input']>;
  /** ApiDocs */
  columns?: InputMaybe<Scalars['JSON']['input']>;
  /** ApiDocs */
  filter?: InputMaybe<Scalars['JSON']['input']>;
  /** ApiDocs */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Identifier for front end tables. */
  pageSize?: InputMaybe<Scalars['Float']['input']>;
  /** ApiDocs */
  sorting?: InputMaybe<Scalars['JSON']['input']>;
};

export type LayoutUserUpdateInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** ApiDocs */
  update: LayoutUserUpdateDto;
};

export enum LayoutVariant {
  Shared = 'Shared',
  User = 'User'
}

export type LayoutVariantFilterComparison = {
  eq?: InputMaybe<LayoutVariant>;
  gt?: InputMaybe<LayoutVariant>;
  gte?: InputMaybe<LayoutVariant>;
  iLike?: InputMaybe<LayoutVariant>;
  in?: InputMaybe<Array<LayoutVariant>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<LayoutVariant>;
  lt?: InputMaybe<LayoutVariant>;
  lte?: InputMaybe<LayoutVariant>;
  neq?: InputMaybe<LayoutVariant>;
  notILike?: InputMaybe<LayoutVariant>;
  notIn?: InputMaybe<Array<LayoutVariant>>;
  notLike?: InputMaybe<LayoutVariant>;
};

export type LicensePlate = {
  __typename?: 'LicensePlate';
  /** Gross weight of entity */
  baseGrossWeight?: Maybe<Scalars['Float']['output']>;
  /** Height of license plate */
  baseHeight?: Maybe<Scalars['Float']['output']>;
  /** Height of license plate */
  baseLength?: Maybe<Scalars['Float']['output']>;
  baseNetWeight?: Maybe<Scalars['Float']['output']>;
  /** Height of license plate */
  baseWidth?: Maybe<Scalars['Float']['output']>;
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Unit of measure for dimensions of a license plate */
  dimensionUOMId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  dispositionId?: Maybe<Scalars['String']['output']>;
  /** License Plate erp code */
  erpCode?: Maybe<Scalars['String']['output']>;
  /** Gross weight of entity */
  grossWeight?: Maybe<Scalars['Float']['output']>;
  /** Height of license plate */
  height?: Maybe<Scalars['Float']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Length of license plate */
  length?: Maybe<Scalars['Float']['output']>;
  /** Material that the license plate is made of */
  licensePlateMaterial?: Maybe<Scalars['String']['output']>;
  /** Material type that the license plate is made of */
  licensePlateMaterialType?: Maybe<Scalars['String']['output']>;
  /** Net weight of entity */
  netWeight?: Maybe<Scalars['Float']['output']>;
  /** License Plate Parent ID */
  parentId?: Maybe<Scalars['ID']['output']>;
  /** Status of license plate */
  processStatus?: Maybe<Scalars['String']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Weight UOM, i.e KG */
  weightUOMId?: Maybe<Scalars['String']['output']>;
  /** Width of license plate */
  width?: Maybe<Scalars['Float']['output']>;
  /** X coordinate location for the license plate */
  x?: Maybe<Scalars['Float']['output']>;
  /** Y coordinate location for the license plate */
  y?: Maybe<Scalars['Float']['output']>;
};

export type LicensePlateBinToBinTaskMovementCreateDto = {
  /** Entity's team ID (foreign key) */
  assignedTeamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  /** Autocomplete the task on creation */
  autocomplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Entity ID */
  deliveryItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Destination bin ID */
  destinationBinId: Scalars['ID']['input'];
  /** Entity ID */
  fulfillmentItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's license plate ID (foreign key) */
  licensePlateId: Scalars['ID']['input'];
};

export type LicensePlateBinToBinTaskMovementCreateInputDto = {
  /** The record to create */
  licensePlateBinToBinMovement: LicensePlateBinToBinTaskMovementCreateDto;
};

export type LicensePlateCreateInput = {
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Unit of measure for dimensions of a license plate */
  dimensionUOMId?: InputMaybe<Scalars['String']['input']>;
  /** Gross weight of entity */
  grossWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Height of license plate */
  height?: InputMaybe<Scalars['Float']['input']>;
  /** Length of license plate */
  length?: InputMaybe<Scalars['Float']['input']>;
  /** Net weight of entity */
  netWeight?: InputMaybe<Scalars['Float']['input']>;
  /** License Plate Parent ID */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['input'];
  /** Weight UOM, i.e KG */
  weightUOMId?: InputMaybe<Scalars['String']['input']>;
  /** Width of license plate */
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type LicensePlateCreateOneInput = {
  /** The record to create */
  licensePlate: LicensePlateCreateInput;
};

export type LicensePlateCreateStockFromProductionDto = {
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Unit of measure for dimensions of a license plate */
  dimensionUOMId?: InputMaybe<Scalars['ID']['input']>;
  /** License Plate erp code */
  erpCode: Scalars['String']['input'];
  /** Gross weight of entity */
  grossWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Height of license plate */
  height?: InputMaybe<Scalars['Float']['input']>;
  /** Length of license plate */
  length?: InputMaybe<Scalars['Float']['input']>;
  /** Net weight of entity */
  netWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Weight UOM, i.e KG */
  weightUOMId?: InputMaybe<Scalars['ID']['input']>;
  /** Width of license plate */
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type LicensePlateDetail = {
  __typename?: 'LicensePlateDetail';
  /**
   * Aisle where the bin is located
   * @deprecated Deprecated field name use aisleCode instead.
   */
  aisle?: Maybe<Scalars['String']['output']>;
  /** Aisle where the bin is located */
  aisleCode?: Maybe<Scalars['String']['output']>;
  /** Column where the bin is located. */
  aisleColumnCode?: Maybe<Scalars['String']['output']>;
  /** Column where the bin is located. */
  aisleColumnId?: Maybe<Scalars['ID']['output']>;
  /** Aisle where the bin is located */
  aisleId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  areaCode?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  binCode?: Maybe<Scalars['String']['output']>;
  /** Entity's bin ID (foreign key) */
  binId?: Maybe<Scalars['ID']['output']>;
  /** Bin type */
  binType?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  code: Scalars['String']['output'];
  /**
   * Column where the bin is located.
   * @deprecated Deprecated field name use aisleColumnCode instead.
   */
  column?: Maybe<Scalars['String']['output']>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  deliveryId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  deliveryItemId?: Maybe<Scalars['ID']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Unit of measure for dimensions of a license plate */
  dimensionUOMId?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  dispositionId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  erpSalesOrder?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  fulfillmentId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  fulfillmentItemId?: Maybe<Scalars['ID']['output']>;
  /** Indicator of open tasks */
  hasOpenTasks?: Maybe<Scalars['Boolean']['output']>;
  /** Height of license plate */
  height?: Maybe<Scalars['Float']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Active/Inactive bin status flag */
  inactive?: Maybe<LicensePlateStatusState>;
  /** Length of license plate */
  length?: Maybe<Scalars['Float']['output']>;
  /** Level where the bin is located. */
  level?: Maybe<Scalars['Int']['output']>;
  /** Material that the license plate is made of */
  licensePlateMaterial?: Maybe<Scalars['String']['output']>;
  /** Material type that the license plate is made of */
  licensePlateMaterialType?: Maybe<Scalars['String']['output']>;
  /** Array of license plate ids that are nested inside of the license plate */
  lpHierarchy?: Maybe<Scalars['JSON']['output']>;
  /** Number of open tasks */
  openTaskCount?: Maybe<Scalars['Int']['output']>;
  /** License Plate Parent ID */
  parentId?: Maybe<Scalars['ID']['output']>;
  /** Status of license plate */
  processStatus?: Maybe<Scalars['String']['output']>;
  /** Business partner code */
  soldToBusinessPartnerCode?: Maybe<Scalars['String']['output']>;
  /** Business partner name */
  soldToBusinessPartnerId?: Maybe<Scalars['ID']['output']>;
  /** Business partner name */
  soldToBusinessPartnerName?: Maybe<Scalars['String']['output']>;
  /** Gross volume of entity */
  totalVolume?: Maybe<Scalars['Float']['output']>;
  /** Gross weight of entity */
  totalWeight?: Maybe<Scalars['Float']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** Entity's warehouse name */
  warehouseName?: Maybe<Scalars['String']['output']>;
  /** Width of license plate */
  width?: Maybe<Scalars['Float']['output']>;
  /** X coordinate location for the license plate */
  x?: Maybe<Scalars['Float']['output']>;
  /** Y coordinate location for the license plate */
  y?: Maybe<Scalars['Float']['output']>;
};

export type LicensePlateDetailFilter = {
  aisle?: InputMaybe<StringFieldComparison>;
  aisleCode?: InputMaybe<StringFieldComparison>;
  aisleColumnCode?: InputMaybe<StringFieldComparison>;
  aisleColumnId?: InputMaybe<IdFilterComparison>;
  aisleId?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<LicensePlateDetailFilter>>;
  areaCode?: InputMaybe<StringFieldComparison>;
  binCode?: InputMaybe<StringFieldComparison>;
  binId?: InputMaybe<IdFilterComparison>;
  binType?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  column?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  deliveryId?: InputMaybe<IdFilterComparison>;
  deliveryItemId?: InputMaybe<IdFilterComparison>;
  description?: InputMaybe<StringFieldComparison>;
  dimensionUOMId?: InputMaybe<StringFieldComparison>;
  dispositionId?: InputMaybe<IdFilterComparison>;
  erpSalesOrder?: InputMaybe<StringFieldComparison>;
  fulfillmentId?: InputMaybe<IdFilterComparison>;
  fulfillmentItemId?: InputMaybe<IdFilterComparison>;
  hasOpenTasks?: InputMaybe<BooleanFieldComparison>;
  height?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  inactive?: InputMaybe<LicensePlateStatusStateFilterComparison>;
  length?: InputMaybe<FloatFieldComparison>;
  level?: InputMaybe<IntFieldComparison>;
  licensePlateMaterial?: InputMaybe<StringFieldComparison>;
  licensePlateMaterialType?: InputMaybe<StringFieldComparison>;
  lpHierarchy?: InputMaybe<JsonFilterComparison>;
  openTaskCount?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<LicensePlateDetailFilter>>;
  parentId?: InputMaybe<IdFilterComparison>;
  processStatus?: InputMaybe<StringFieldComparison>;
  soldToBusinessPartnerCode?: InputMaybe<StringFieldComparison>;
  soldToBusinessPartnerId?: InputMaybe<IdFilterComparison>;
  soldToBusinessPartnerName?: InputMaybe<StringFieldComparison>;
  totalVolume?: InputMaybe<FloatFieldComparison>;
  totalWeight?: InputMaybe<FloatFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  warehouseName?: InputMaybe<StringFieldComparison>;
  width?: InputMaybe<FloatFieldComparison>;
  x?: InputMaybe<FloatFieldComparison>;
  y?: InputMaybe<FloatFieldComparison>;
};

export type LicensePlateDetailOffsetConnection = {
  __typename?: 'LicensePlateDetailOffsetConnection';
  /** Array of nodes. */
  nodes: Array<LicensePlateDetail>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type LicensePlateDetailSort = {
  direction: SortDirection;
  field: LicensePlateDetailSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LicensePlateDetailSortFields {
  Aisle = 'aisle',
  AisleCode = 'aisleCode',
  AisleColumnCode = 'aisleColumnCode',
  AisleColumnId = 'aisleColumnId',
  AisleId = 'aisleId',
  AreaCode = 'areaCode',
  BinCode = 'binCode',
  BinId = 'binId',
  BinType = 'binType',
  Code = 'code',
  Column = 'column',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  DeliveryId = 'deliveryId',
  DeliveryItemId = 'deliveryItemId',
  Description = 'description',
  DimensionUomId = 'dimensionUOMId',
  DispositionId = 'dispositionId',
  ErpSalesOrder = 'erpSalesOrder',
  FulfillmentId = 'fulfillmentId',
  FulfillmentItemId = 'fulfillmentItemId',
  HasOpenTasks = 'hasOpenTasks',
  Height = 'height',
  Id = 'id',
  Inactive = 'inactive',
  Length = 'length',
  Level = 'level',
  LicensePlateMaterial = 'licensePlateMaterial',
  LicensePlateMaterialType = 'licensePlateMaterialType',
  LpHierarchy = 'lpHierarchy',
  OpenTaskCount = 'openTaskCount',
  ParentId = 'parentId',
  ProcessStatus = 'processStatus',
  SoldToBusinessPartnerCode = 'soldToBusinessPartnerCode',
  SoldToBusinessPartnerId = 'soldToBusinessPartnerId',
  SoldToBusinessPartnerName = 'soldToBusinessPartnerName',
  TotalVolume = 'totalVolume',
  TotalWeight = 'totalWeight',
  UpdatedAt = 'updatedAt',
  WarehouseId = 'warehouseId',
  WarehouseName = 'warehouseName',
  Width = 'width',
  X = 'x',
  Y = 'y'
}

export type LicensePlateFilter = {
  and?: InputMaybe<Array<LicensePlateFilter>>;
  baseGrossWeight?: InputMaybe<FloatFieldComparison>;
  baseHeight?: InputMaybe<FloatFieldComparison>;
  baseLength?: InputMaybe<FloatFieldComparison>;
  baseNetWeight?: InputMaybe<FloatFieldComparison>;
  baseWidth?: InputMaybe<FloatFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  dimensionUOMId?: InputMaybe<IdFilterComparison>;
  dispositionId?: InputMaybe<StringFieldComparison>;
  erpCode?: InputMaybe<StringFieldComparison>;
  grossWeight?: InputMaybe<FloatFieldComparison>;
  height?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  length?: InputMaybe<FloatFieldComparison>;
  licensePlateMaterial?: InputMaybe<StringFieldComparison>;
  licensePlateMaterialType?: InputMaybe<StringFieldComparison>;
  netWeight?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<LicensePlateFilter>>;
  parentId?: InputMaybe<IdFilterComparison>;
  processStatus?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  weightUOMId?: InputMaybe<StringFieldComparison>;
  width?: InputMaybe<FloatFieldComparison>;
  x?: InputMaybe<FloatFieldComparison>;
  y?: InputMaybe<FloatFieldComparison>;
};

export type LicensePlateForPickTask = {
  __typename?: 'LicensePlateForPickTask';
  availableQuantity?: Maybe<Scalars['Float']['output']>;
  binCode?: Maybe<Scalars['String']['output']>;
  binId?: Maybe<Scalars['ID']['output']>;
  licensePlateCode?: Maybe<Scalars['String']['output']>;
  licensePlateId?: Maybe<Scalars['ID']['output']>;
  lotCode?: Maybe<Scalars['String']['output']>;
  lotExpirationDate?: Maybe<Scalars['DateTime']['output']>;
  lotId?: Maybe<Scalars['ID']['output']>;
  lotProductionDate?: Maybe<Scalars['DateTime']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
};

export type LicensePlateMovementCreateInputDto = {
  /** The record to create */
  licensePlateMovement: LicensePlateTaskMovementCreateDto;
};

export type LicensePlateMovementProductInput = {
  /** Entity's bin ID (foreign key) */
  binId: Scalars['ID']['input'];
  /** Unit of measure ID */
  createdInUnitOfMeasureId: Scalars['ID']['input'];
  /** Delivery item ID (foreign key) */
  deliveryItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity ID */
  licensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's lot ID (foreign key) */
  lotId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's product ID (foreign key) */
  productId: Scalars['ID']['input'];
  /** Quantity of product */
  quantity: Scalars['String']['input'];
  /** Entity's stock status type ID (foreign key) */
  stockStatusId: Scalars['ID']['input'];
};

export type LicensePlateOffsetConnection = {
  __typename?: 'LicensePlateOffsetConnection';
  /** Array of nodes. */
  nodes: Array<LicensePlate>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

/** License Plate Search results */
export type LicensePlateSearchResults = {
  __typename?: 'LicensePlateSearchResults';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  index?: Maybe<Scalars['String']['output']>;
  status?: Maybe<LicensePlateStatusState>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type LicensePlateSort = {
  direction: SortDirection;
  field: LicensePlateSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LicensePlateSortFields {
  BaseGrossWeight = 'baseGrossWeight',
  BaseHeight = 'baseHeight',
  BaseLength = 'baseLength',
  BaseNetWeight = 'baseNetWeight',
  BaseWidth = 'baseWidth',
  Code = 'code',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  DimensionUomId = 'dimensionUOMId',
  DispositionId = 'dispositionId',
  ErpCode = 'erpCode',
  GrossWeight = 'grossWeight',
  Height = 'height',
  Id = 'id',
  Length = 'length',
  LicensePlateMaterial = 'licensePlateMaterial',
  LicensePlateMaterialType = 'licensePlateMaterialType',
  NetWeight = 'netWeight',
  ParentId = 'parentId',
  ProcessStatus = 'processStatus',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WeightUomId = 'weightUOMId',
  Width = 'width',
  X = 'x',
  Y = 'y'
}

export type LicensePlateStatus = {
  __typename?: 'LicensePlateStatus';
  /** Entity code */
  code: LicensePlateStatusCode;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity's label */
  label: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export enum LicensePlateStatusCode {
  LpStatusInactive = 'LP_STATUS_INACTIVE'
}

export type LicensePlateStatusCodeFilterComparison = {
  eq?: InputMaybe<LicensePlateStatusCode>;
  gt?: InputMaybe<LicensePlateStatusCode>;
  gte?: InputMaybe<LicensePlateStatusCode>;
  iLike?: InputMaybe<LicensePlateStatusCode>;
  in?: InputMaybe<Array<LicensePlateStatusCode>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<LicensePlateStatusCode>;
  lt?: InputMaybe<LicensePlateStatusCode>;
  lte?: InputMaybe<LicensePlateStatusCode>;
  neq?: InputMaybe<LicensePlateStatusCode>;
  notILike?: InputMaybe<LicensePlateStatusCode>;
  notIn?: InputMaybe<Array<LicensePlateStatusCode>>;
  notLike?: InputMaybe<LicensePlateStatusCode>;
};

export type LicensePlateStatusFilter = {
  and?: InputMaybe<Array<LicensePlateStatusFilter>>;
  code?: InputMaybe<LicensePlateStatusCodeFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<LicensePlateStatusFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type LicensePlateStatusInput = {
  /** Entity code */
  licensePlateStatusCode: LicensePlateStatusCode;
  /** License Plate status detail's active flag */
  on: Scalars['Boolean']['input'];
};

export type LicensePlateStatusMapping = {
  __typename?: 'LicensePlateStatusMapping';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity ID */
  licensePlateId: Scalars['ID']['output'];
  /** Entity ID */
  licensePlateStatusId: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type LicensePlateStatusMappingFilter = {
  and?: InputMaybe<Array<LicensePlateStatusMappingFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  licensePlateId?: InputMaybe<IdFilterComparison>;
  licensePlateStatusId?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<LicensePlateStatusMappingFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type LicensePlateStatusMappingInput = {
  /** License Plate Ids for mapping assignment */
  licensePlateIds: Array<Scalars['ID']['input']>;
  /** License Plate Status Input */
  licensePlateStatus: Array<LicensePlateStatusInput>;
};

export type LicensePlateStatusMappingInputDto = {
  /** License Plate Status mappings */
  licensePlateStatusMappings: LicensePlateStatusMappingInput;
};

export type LicensePlateStatusMappingOffsetConnection = {
  __typename?: 'LicensePlateStatusMappingOffsetConnection';
  /** Array of nodes. */
  nodes: Array<LicensePlateStatusMapping>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type LicensePlateStatusMappingSort = {
  direction: SortDirection;
  field: LicensePlateStatusMappingSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LicensePlateStatusMappingSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  LicensePlateId = 'licensePlateId',
  LicensePlateStatusId = 'licensePlateStatusId',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type LicensePlateStatusOffsetConnection = {
  __typename?: 'LicensePlateStatusOffsetConnection';
  /** Array of nodes. */
  nodes: Array<LicensePlateStatus>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type LicensePlateStatusSort = {
  direction: SortDirection;
  field: LicensePlateStatusSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LicensePlateStatusSortFields {
  Code = 'code',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Label = 'label',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export enum LicensePlateStatusState {
  Active = 'active',
  Inactive = 'inactive'
}

export type LicensePlateStatusStateFilterComparison = {
  eq?: InputMaybe<LicensePlateStatusState>;
  gt?: InputMaybe<LicensePlateStatusState>;
  gte?: InputMaybe<LicensePlateStatusState>;
  iLike?: InputMaybe<LicensePlateStatusState>;
  in?: InputMaybe<Array<LicensePlateStatusState>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<LicensePlateStatusState>;
  lt?: InputMaybe<LicensePlateStatusState>;
  lte?: InputMaybe<LicensePlateStatusState>;
  neq?: InputMaybe<LicensePlateStatusState>;
  notILike?: InputMaybe<LicensePlateStatusState>;
  notIn?: InputMaybe<Array<LicensePlateStatusState>>;
  notLike?: InputMaybe<LicensePlateStatusState>;
};

export type LicensePlateTaskMovementCreateDto = {
  /** Entity's team ID (foreign key) */
  assignedTeamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  /** Autocomplete the task on creation */
  autocomplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Destination bin ID */
  destinationBinId: Scalars['ID']['input'];
  /** Entity's license plate ID (foreign key) */
  licensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** License Plate Parent ID */
  licensePlateParentId?: InputMaybe<Scalars['ID']['input']>;
  /** ApiDocs */
  products: Array<LicensePlateMovementProductInput>;
};

export type LicensePlateTaskValidationDto = {
  licensePlateId: Scalars['ID']['input'];
};

export type LicensePlateUnpackLicensePlateCompleteDto = {
  /** Entity's task ID (foreign key) */
  taskId: Scalars['ID']['input'];
};

export type LicensePlateUnpackLicensePlateDto = {
  /** Entity's team ID (foreign key) */
  assignedTeamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  /** Autocomplete the task on creation */
  autocomplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Entity's license plate ID (foreign key) */
  licensePlateId: Scalars['ID']['input'];
};

export type LicensePlateUpdateInput = {
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Unit of measure for dimensions of a license plate */
  dimensionUOMId?: InputMaybe<Scalars['String']['input']>;
  /** Gross weight of entity */
  grossWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Height of license plate */
  height?: InputMaybe<Scalars['Float']['input']>;
  /** Length of license plate */
  length?: InputMaybe<Scalars['Float']['input']>;
  /** Net weight of entity */
  netWeight?: InputMaybe<Scalars['Float']['input']>;
  /** License Plate Parent ID */
  parentId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
  /** Weight UOM, i.e KG */
  weightUOMId?: InputMaybe<Scalars['String']['input']>;
  /** Width of license plate */
  width?: InputMaybe<Scalars['Float']['input']>;
};

export type LicensePlateUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: LicensePlateUpdateInput;
};

export type LoadTaskCompleteInput = {
  /** Unit of measure ID */
  completedInUnitOfMeasureId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's task ID (foreign key) */
  licensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** Quantity of product */
  quantity?: InputMaybe<Scalars['String']['input']>;
  /** Source bin ID (foreign key) */
  sourceBinId: Scalars['ID']['input'];
  /** Source lot ID (foreign key) */
  sourceLotId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's task ID (foreign key) */
  taskId: Scalars['ID']['input'];
};

export enum LogType {
  AdminTask = 'ADMIN_TASK',
  AdminTaskType = 'ADMIN_TASK_TYPE',
  AgentConfiguration = 'AGENT_CONFIGURATION',
  Aisle = 'AISLE',
  AisleColumn = 'AISLE_COLUMN',
  Analytics = 'ANALYTICS',
  Anchor = 'ANCHOR',
  Area = 'AREA',
  Asn = 'ASN',
  Auth = 'AUTH',
  Authorization = 'AUTHORIZATION',
  Barcode = 'BARCODE',
  BarcodeMapping = 'BARCODE_MAPPING',
  BarcodeParse = 'BARCODE_PARSE',
  BarcodeTemplate = 'BARCODE_TEMPLATE',
  BarcodeTemplateMapping = 'BARCODE_TEMPLATE_MAPPING',
  Bin = 'BIN',
  BinStatus = 'BIN_STATUS',
  BinStatusMapping = 'BIN_STATUS_MAPPING',
  BinStorageType = 'BIN_STORAGE_TYPE',
  BusinessPartner = 'BUSINESS_PARTNER',
  BusinessPartnerCompiance = 'BUSINESS_PARTNER_COMPIANCE',
  Company = 'COMPANY',
  ContactInfo = 'CONTACT_INFO',
  DeadLetter = 'DEAD_LETTER',
  DeadLetterEvent = 'DEAD_LETTER_EVENT',
  Delivery = 'DELIVERY',
  DeliveryItem = 'DELIVERY_ITEM',
  DeliveryJob = 'DELIVERY_JOB',
  DisplayPreferences = 'DISPLAY_PREFERENCES',
  Disposition = 'DISPOSITION',
  Door = 'DOOR',
  Equipment = 'EQUIPMENT',
  EventService = 'EVENT_SERVICE',
  EwmDelivery = 'EWM_DELIVERY',
  EwmDeliveryHuMapping = 'EWM_DELIVERY_HU_MAPPING',
  EwmFieldMapping = 'EWM_FIELD_MAPPING',
  EwmHu = 'EWM_HU',
  EwmWhsTask = 'EWM_WHS_TASK',
  Floorplan = 'FLOORPLAN',
  Fulfillment = 'FULFILLMENT',
  FulfillmentBlock = 'FULFILLMENT_BLOCK',
  FulfillmentItem = 'FULFILLMENT_ITEM',
  GcpStorage = 'GCP_STORAGE',
  General = 'GENERAL',
  HistoryRecord = 'HISTORY_RECORD',
  InboundDeliveryEvent = 'INBOUND_DELIVERY_EVENT',
  InventoryBase = 'INVENTORY_BASE',
  Layout = 'LAYOUT',
  LicensePlate = 'LICENSE_PLATE',
  LicensePlateStatus = 'LICENSE_PLATE_STATUS',
  LicensePlateStatusMapping = 'LICENSE_PLATE_STATUS_MAPPING',
  Lot = 'LOT',
  Map = 'MAP',
  Node = 'NODE',
  NonCompliantBarcode = 'NON_COMPLIANT_BARCODE',
  Organization = 'ORGANIZATION',
  Permission = 'PERMISSION',
  PermissionDimension = 'PERMISSION_DIMENSION',
  Postgres = 'POSTGRES',
  Product = 'PRODUCT',
  ProductMovement = 'PRODUCT_MOVEMENT',
  PtoStockStatusType = 'PTO_STOCK_STATUS_TYPE',
  Redis = 'REDIS',
  Redpoint = 'REDPOINT',
  Replenishment = 'REPLENISHMENT',
  Role = 'ROLE',
  RolePermissionMapping = 'ROLE_PERMISSION_MAPPING',
  SapAuth = 'SAP_AUTH',
  SapBatch = 'SAP_BATCH',
  SapBusinessPartner = 'SAP_BUSINESS_PARTNER',
  SapConnector = 'SAP_CONNECTOR',
  SapDelivery = 'SAP_DELIVERY',
  SapDeliveryItem = 'SAP_DELIVERY_ITEM',
  SapMaterialMovement = 'SAP_MATERIAL_MOVEMENT',
  SapOdataClient = 'SAP_ODATA_CLIENT',
  SapOutboundDelivery = 'SAP_OUTBOUND_DELIVERY',
  SapOutboundDeliveryItem = 'SAP_OUTBOUND_DELIVERY_ITEM',
  SapPhysicalInventory = 'SAP_PHYSICAL_INVENTORY',
  SapPostgres = 'SAP_POSTGRES',
  SapSalesOrder = 'SAP_SALES_ORDER',
  SapSalesOrderItem = 'SAP_SALES_ORDER_ITEM',
  SapStockStatusType = 'SAP_STOCK_STATUS_TYPE',
  SapStorageLocationPlant = 'SAP_STORAGE_LOCATION_PLANT',
  ScheduledJob = 'SCHEDULED_JOB',
  ScheduledJobType = 'SCHEDULED_JOB_TYPE',
  Scheduler = 'SCHEDULER',
  SearchQueue = 'SEARCH_QUEUE',
  SlottingConfiguration = 'SLOTTING_CONFIGURATION',
  SlottingDataset = 'SLOTTING_DATASET',
  SlottingRuleset = 'SLOTTING_RULESET',
  SlottingSetting = 'SLOTTING_SETTING',
  SlottingSettingItem = 'SLOTTING_SETTING_ITEM',
  StandardUnitOfMeasure = 'STANDARD_UNIT_OF_MEASURE',
  StockStatusType = 'STOCK_STATUS_TYPE',
  StoredImage = 'STORED_IMAGE',
  Task = 'TASK',
  TaskEvent = 'TASK_EVENT',
  TaskFromFulfillment = 'TASK_FROM_FULFILLMENT',
  TaskGroup = 'TASK_GROUP',
  TaskGroupingJob = 'TASK_GROUPING_JOB',
  TaskLoad = 'TASK_LOAD',
  TaskPickToStaging = 'TASK_PICK_TO_STAGING',
  TaskType = 'TASK_TYPE',
  TaskTypeBinStatusMapping = 'TASK_TYPE_BIN_STATUS_MAPPING',
  TaskTypeStockStatusMapping = 'TASK_TYPE_STOCK_STATUS_MAPPING',
  Team = 'TEAM',
  TestLog = 'TEST_LOG',
  Unhandled = 'UNHANDLED',
  UnitOfMeasure = 'UNIT_OF_MEASURE',
  UnitOfMeasureGlossary = 'UNIT_OF_MEASURE_GLOSSARY',
  UploadError = 'UPLOAD_ERROR',
  UserAccount = 'USER_ACCOUNT',
  UserGroup = 'USER_GROUP',
  UserGroupMapping = 'USER_GROUP_MAPPING',
  UserGroupRoleMapping = 'USER_GROUP_ROLE_MAPPING',
  UserTeamMapping = 'USER_TEAM_MAPPING',
  UserWarehouseDefault = 'USER_WAREHOUSE_DEFAULT',
  Warehouse = 'WAREHOUSE',
  WarehouseConfiguration = 'WAREHOUSE_CONFIGURATION',
  WarehouseOp = 'WAREHOUSE_OP',
  WarehouseOpFieldMapping = 'WAREHOUSE_OP_FIELD_MAPPING',
  WarehouseOpJob = 'WAREHOUSE_OP_JOB',
  WarehousePreferredUnitOfMeasure = 'WAREHOUSE_PREFERRED_UNIT_OF_MEASURE',
  WarehouseUserGroupMapping = 'WAREHOUSE_USER_GROUP_MAPPING',
  Zone = 'ZONE',
  ZoneBehaviorMapping = 'ZONE_BEHAVIOR_MAPPING',
  ZoneRule = 'ZONE_RULE',
  ZoneRuleMapping = 'ZONE_RULE_MAPPING'
}

/** Lot model */
export type Lot = {
  __typename?: 'Lot';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** When data was created in ERP */
  erpCreatedOn?: Maybe<Scalars['DateTime']['output']>;
  /** When data was last updated in ERP */
  erpUpdatedOn?: Maybe<Scalars['DateTime']['output']>;
  /** Lot expiration date */
  expiration?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  product?: Maybe<Product>;
  /** Entity's product ID (foreign key) */
  productId: Scalars['ID']['output'];
  /** Product number in erp */
  productNumber?: Maybe<Scalars['String']['output']>;
  /** Lot production date */
  productionDate?: Maybe<Scalars['DateTime']['output']>;
  /** Lot supplier number */
  supplierLotNumber?: Maybe<Scalars['String']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Warehouse entity belongs to */
  warehouseStatus?: Maybe<Scalars['Boolean']['output']>;
};

export type LotCreateInput = {
  /** Entity code */
  code: Scalars['String']['input'];
  /** When data was created in ERP */
  erpCreatedOn?: InputMaybe<Scalars['DateTime']['input']>;
  /** When data was last updated in ERP */
  erpUpdatedOn?: InputMaybe<Scalars['DateTime']['input']>;
  /** Lot expiration date */
  expiration?: InputMaybe<Scalars['DateTime']['input']>;
  /** Entity's product ID (foreign key) */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Product number in erp */
  productNumber?: InputMaybe<Scalars['String']['input']>;
  /** Lot production date */
  productionDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Lot supplier number */
  supplierLotNumber?: InputMaybe<Scalars['String']['input']>;
  /** Boolean indicating if lot product is in restricted stock status */
  warehouseStatus?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LotCreateOneInput = {
  /** The record to create */
  lot: LotCreateInput;
};

export type LotFilter = {
  and?: InputMaybe<Array<LotFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  erpCreatedOn?: InputMaybe<DateFieldComparison>;
  erpUpdatedOn?: InputMaybe<DateFieldComparison>;
  expiration?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<LotFilter>>;
  productId?: InputMaybe<IdFilterComparison>;
  productNumber?: InputMaybe<StringFieldComparison>;
  productionDate?: InputMaybe<DateFieldComparison>;
  supplierLotNumber?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  warehouseStatus?: InputMaybe<BooleanFieldComparison>;
};

export type LotNodes = {
  __typename?: 'LotNodes';
  nodes: Array<Lot>;
};

export type LotOffsetConnection = {
  __typename?: 'LotOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Lot>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type LotSort = {
  direction: SortDirection;
  field: LotSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LotSortFields {
  Code = 'code',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  ErpCreatedOn = 'erpCreatedOn',
  ErpUpdatedOn = 'erpUpdatedOn',
  Expiration = 'expiration',
  Id = 'id',
  ProductId = 'productId',
  ProductNumber = 'productNumber',
  ProductionDate = 'productionDate',
  SupplierLotNumber = 'supplierLotNumber',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WarehouseStatus = 'warehouseStatus'
}

export type LotUpdateInput = {
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** When data was created in ERP */
  erpCreatedOn?: InputMaybe<Scalars['DateTime']['input']>;
  /** When data was last updated in ERP */
  erpUpdatedOn?: InputMaybe<Scalars['DateTime']['input']>;
  /** Lot expiration date */
  expiration?: InputMaybe<Scalars['DateTime']['input']>;
  /** Entity's product ID (foreign key) */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Product number in erp */
  productNumber?: InputMaybe<Scalars['String']['input']>;
  /** Lot production date */
  productionDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Lot supplier number */
  supplierLotNumber?: InputMaybe<Scalars['String']['input']>;
  /** Boolean indicating if lot product is in restricted stock status */
  warehouseStatus?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LotUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: LotUpdateInput;
};

/** Map model */
export type Map = {
  __typename?: 'Map';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  mapJson: Scalars['JSONObject']['output'];
  /** Entity's human readable name */
  name: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
};

export type MapCreateOneInput = {
  /** The record to create */
  map: MapCreateType;
};

export type MapCreateType = {
  /** Json data to generate a warehouse map */
  mapJson: Scalars['JSON']['input'];
  /** Entity's human readable name */
  name: Scalars['String']['input'];
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['String']['input'];
};

export type MapFilter = {
  and?: InputMaybe<Array<MapFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<MapFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type MapOffsetConnection = {
  __typename?: 'MapOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Map>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type MapQuery = {
  bucket: Scalars['String']['input'];
  endX?: InputMaybe<Scalars['Float']['input']>;
  endY?: InputMaybe<Scalars['Float']['input']>;
  startX: Scalars['Float']['input'];
  startY: Scalars['Float']['input'];
  stops: Array<Stop>;
  warehouse: Scalars['Float']['input'];
};

export type MapSort = {
  direction: SortDirection;
  field: MapSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum MapSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WarehouseId = 'warehouseId'
}

export type MaterialDocumentItemDocumentInput = {
  Batch?: InputMaybe<Scalars['String']['input']>;
  GoodsMovementType: SapMaterialMovementType;
  IssuingOrReceivingPlant?: InputMaybe<Scalars['String']['input']>;
  IssuingOrReceivingStorageLoc?: InputMaybe<Scalars['String']['input']>;
  Material: Scalars['String']['input'];
  Plant: Scalars['String']['input'];
  QuantityInEntryUnit: Scalars['String']['input'];
  StorageLocation: Scalars['String']['input'];
};

export type MaterialMovmentDtoCreateOneInput = {
  GoodsMovementCode: SapMaterialMovementCode;
  PostingDate: Scalars['String']['input'];
  to_MaterialDocumentItem: Array<MaterialDocumentItemDocumentInput>;
};

/** Mobile configuration model */
export type MobileConfigurationModel = {
  __typename?: 'MobileConfigurationModel';
  /** Data payload from a barcode scan */
  payload: Scalars['JSONObject']['output'];
};

/** Mobile motd model */
export type MobileMotdModel = {
  __typename?: 'MobileMotdModel';
  /** Message of the day payload */
  payload: Scalars['JSONObject']['output'];
};

export type MobileViewTask = {
  __typename?: 'MobileViewTask';
  /** Deleted at date */
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  assignedByUserId?: Maybe<Scalars['ID']['output']>;
  assignedTeamId?: Maybe<Scalars['ID']['output']>;
  assignedUserId?: Maybe<Scalars['ID']['output']>;
  /** Date and time a task is completed */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** x coordinate location */
  completedAtLocationX?: Maybe<Scalars['Float']['output']>;
  /** Y coordinate location */
  completedAtLocationY?: Maybe<Scalars['Float']['output']>;
  completedByUserId?: Maybe<Scalars['ID']['output']>;
  /** Date the task was completed */
  completionDate?: Maybe<Scalars['DateTime']['output']>;
  /** Created at date */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Delivery or fulfillment erp code */
  deliveryErpCode?: Maybe<Scalars['String']['output']>;
  /** Delivery ID (foreign key) */
  deliveryId?: Maybe<Scalars['ID']['output']>;
  /** Delivery item associated with entity */
  deliveryItem?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  destinationAreaCode?: Maybe<Scalars['String']['output']>;
  /** Entity's area ID (foreign key) */
  destinationAreaId?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  destinationBinCode?: Maybe<Scalars['String']['output']>;
  /** Entity's bin ID (foreign key) */
  destinationBinId?: Maybe<Scalars['String']['output']>;
  /** Destination license plate code */
  destinationLicensePlateCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  destinationLicensePlateDescription?: Maybe<Scalars['String']['output']>;
  /** Destination license plate ID */
  destinationLicensePlateId?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  destinationLotCode?: Maybe<Scalars['String']['output']>;
  /** Source lot ID (foreign key) */
  destinationLotId?: Maybe<Scalars['String']['output']>;
  /** Stock status type entity belongs to */
  destinationStatus?: Maybe<Scalars['String']['output']>;
  /** Due date */
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  /** Delivery or fulfillment erp code */
  erpSalesOrder?: Maybe<Scalars['String']['output']>;
  /** Delivery or fulfillment erp code */
  fulfillmentErpCode?: Maybe<Scalars['String']['output']>;
  /** Fulfillment item associated with the entity */
  fulfillmentItem?: Maybe<Scalars['String']['output']>;
  /** Gross weight of entity */
  grossWeight?: Maybe<Scalars['Float']['output']>;
  /** Boolean for if a record is a task group */
  isTaskGroup?: Maybe<Scalars['Boolean']['output']>;
  /** Net weight of entity */
  netWeight?: Maybe<Scalars['Float']['output']>;
  /** Entity's parent task ID */
  parentTaskId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  productCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  productDescription?: Maybe<Scalars['String']['output']>;
  /** Entity's product ID (foreign key) */
  productId?: Maybe<Scalars['String']['output']>;
  /** Whether the product is lot managed or not */
  productLotManaged?: Maybe<Scalars['Boolean']['output']>;
  /** Quantity of product */
  quantity?: Maybe<Scalars['String']['output']>;
  /** Task that is display only */
  readonly?: Maybe<Scalars['Boolean']['output']>;
  /** Reference document */
  referringDoc?: Maybe<Scalars['String']['output']>;
  /** Reference item */
  referringDocItem?: Maybe<Scalars['String']['output']>;
  /** Business partner name */
  shipToName?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  sourceAreaCode?: Maybe<Scalars['String']['output']>;
  /** Entity's area ID (foreign key) */
  sourceAreaId?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  sourceBinCode?: Maybe<Scalars['String']['output']>;
  /** Entity's bin ID (foreign key) */
  sourceBinId?: Maybe<Scalars['String']['output']>;
  /** Source License Plate code */
  sourceLicensePlateCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  sourceLicensePlateDescription?: Maybe<Scalars['String']['output']>;
  /** Source License Plate Id */
  sourceLicensePlateId?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  sourceLotCode?: Maybe<Scalars['String']['output']>;
  /** Source lot ID (foreign key) */
  sourceLotId?: Maybe<Scalars['String']['output']>;
  /** Stock status type entity belongs to */
  sourceStatus?: Maybe<Scalars['String']['output']>;
  /** Date and time a task is started */
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  /** x coordinate location */
  startedAtLocationX?: Maybe<Scalars['Float']['output']>;
  /** Y coordinate location */
  startedAtLocationY?: Maybe<Scalars['Float']['output']>;
  startedByUserId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  taskCode?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  taskGroupCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  taskGroupId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  taskGroupPosition?: Maybe<Scalars['Float']['output']>;
  /** Task status, i.e Not Started */
  taskGroupStatus?: Maybe<TaskStatus>;
  taskGroupTaskData: TaskGroupTaskResource;
  /** Entity's task ID (foreign key) */
  taskId?: Maybe<Scalars['String']['output']>;
  /** Task status, i.e Not Started */
  taskStatus?: Maybe<TaskStatus>;
  /** Task type */
  taskType?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  taskTypeCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  teamDescription?: Maybe<Scalars['String']['output']>;
  /**
   * Entity's team ID (foreign key)
   * @deprecated no longer in use
   */
  teamId?: Maybe<Scalars['ID']['output']>;
  /** Entity's team name */
  teamName?: Maybe<Scalars['String']['output']>;
  /** Unit of measure for entity */
  unitOfMeasure?: Maybe<Scalars['String']['output']>;
  /** Unit of measure ID */
  unitOfMeasureId?: Maybe<Scalars['ID']['output']>;
  /**
   * Entity's user ID (foreign key)
   * @deprecated no longer in use
   */
  userId?: Maybe<Scalars['ID']['output']>;
  /** Volume of entity */
  volume?: Maybe<Scalars['Float']['output']>;
  /** Entity code */
  warehouseCode?: Maybe<Scalars['String']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** Warehouse entity belongs to */
  warehouseName?: Maybe<Scalars['String']['output']>;
  /** Weight UOM, i.e KG */
  weightUOMId?: Maybe<Scalars['String']['output']>;
};

export type MobileViewTaskFilter = {
  and?: InputMaybe<Array<MobileViewTaskFilter>>;
  assignedAt?: InputMaybe<DateFieldComparison>;
  assignedByUserId?: InputMaybe<IdFilterComparison>;
  assignedTeamId?: InputMaybe<IdFilterComparison>;
  assignedUserId?: InputMaybe<IdFilterComparison>;
  completedAt?: InputMaybe<DateFieldComparison>;
  completedAtLocationX?: InputMaybe<FloatFieldComparison>;
  completedAtLocationY?: InputMaybe<FloatFieldComparison>;
  completedByUserId?: InputMaybe<IdFilterComparison>;
  completionDate?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deliveryErpCode?: InputMaybe<StringFieldComparison>;
  deliveryId?: InputMaybe<IdFilterComparison>;
  deliveryItem?: InputMaybe<StringFieldComparison>;
  destinationAreaCode?: InputMaybe<StringFieldComparison>;
  destinationAreaId?: InputMaybe<StringFieldComparison>;
  destinationBinCode?: InputMaybe<StringFieldComparison>;
  destinationBinId?: InputMaybe<StringFieldComparison>;
  destinationLicensePlateCode?: InputMaybe<StringFieldComparison>;
  destinationLicensePlateDescription?: InputMaybe<StringFieldComparison>;
  destinationLicensePlateId?: InputMaybe<StringFieldComparison>;
  destinationLotCode?: InputMaybe<StringFieldComparison>;
  destinationLotId?: InputMaybe<StringFieldComparison>;
  destinationStatus?: InputMaybe<StringFieldComparison>;
  dueDate?: InputMaybe<DateFieldComparison>;
  erpSalesOrder?: InputMaybe<StringFieldComparison>;
  fulfillmentErpCode?: InputMaybe<StringFieldComparison>;
  fulfillmentItem?: InputMaybe<StringFieldComparison>;
  grossWeight?: InputMaybe<FloatFieldComparison>;
  isTaskGroup?: InputMaybe<BooleanFieldComparison>;
  netWeight?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<MobileViewTaskFilter>>;
  parentTaskId?: InputMaybe<IdFilterComparison>;
  productCode?: InputMaybe<StringFieldComparison>;
  productDescription?: InputMaybe<StringFieldComparison>;
  productId?: InputMaybe<StringFieldComparison>;
  productLotManaged?: InputMaybe<BooleanFieldComparison>;
  quantity?: InputMaybe<StringFieldComparison>;
  readonly?: InputMaybe<BooleanFieldComparison>;
  referringDoc?: InputMaybe<StringFieldComparison>;
  referringDocItem?: InputMaybe<StringFieldComparison>;
  shipToName?: InputMaybe<StringFieldComparison>;
  sourceAreaCode?: InputMaybe<StringFieldComparison>;
  sourceAreaId?: InputMaybe<StringFieldComparison>;
  sourceBinCode?: InputMaybe<StringFieldComparison>;
  sourceBinId?: InputMaybe<StringFieldComparison>;
  sourceLicensePlateCode?: InputMaybe<StringFieldComparison>;
  sourceLicensePlateDescription?: InputMaybe<StringFieldComparison>;
  sourceLicensePlateId?: InputMaybe<StringFieldComparison>;
  sourceLotCode?: InputMaybe<StringFieldComparison>;
  sourceLotId?: InputMaybe<StringFieldComparison>;
  sourceStatus?: InputMaybe<StringFieldComparison>;
  startedAt?: InputMaybe<DateFieldComparison>;
  startedAtLocationX?: InputMaybe<FloatFieldComparison>;
  startedAtLocationY?: InputMaybe<FloatFieldComparison>;
  startedByUserId?: InputMaybe<IdFilterComparison>;
  taskCode?: InputMaybe<StringFieldComparison>;
  taskGroupCode?: InputMaybe<StringFieldComparison>;
  taskGroupId?: InputMaybe<IdFilterComparison>;
  taskGroupPosition?: InputMaybe<FloatFieldComparison>;
  taskGroupStatus?: InputMaybe<TaskStatusFilterComparison>;
  taskId?: InputMaybe<StringFieldComparison>;
  taskStatus?: InputMaybe<TaskStatusFilterComparison>;
  taskType?: InputMaybe<StringFieldComparison>;
  taskTypeCode?: InputMaybe<StringFieldComparison>;
  teamDescription?: InputMaybe<StringFieldComparison>;
  teamId?: InputMaybe<IdFilterComparison>;
  teamName?: InputMaybe<StringFieldComparison>;
  unitOfMeasure?: InputMaybe<StringFieldComparison>;
  unitOfMeasureId?: InputMaybe<IdFilterComparison>;
  userId?: InputMaybe<IdFilterComparison>;
  volume?: InputMaybe<FloatFieldComparison>;
  warehouseCode?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  warehouseName?: InputMaybe<StringFieldComparison>;
  weightUOMId?: InputMaybe<StringFieldComparison>;
};

export type MobileViewTaskOffsetConnection = {
  __typename?: 'MobileViewTaskOffsetConnection';
  /** Array of nodes. */
  nodes: Array<MobileViewTask>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type MobileViewTaskSort = {
  direction: SortDirection;
  field: MobileViewTaskSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum MobileViewTaskSortFields {
  AssignedAt = 'assignedAt',
  AssignedByUserId = 'assignedByUserId',
  AssignedTeamId = 'assignedTeamId',
  AssignedUserId = 'assignedUserId',
  CompletedAt = 'completedAt',
  CompletedAtLocationX = 'completedAtLocationX',
  CompletedAtLocationY = 'completedAtLocationY',
  CompletedByUserId = 'completedByUserId',
  CompletionDate = 'completionDate',
  CreatedAt = 'createdAt',
  DeliveryErpCode = 'deliveryErpCode',
  DeliveryId = 'deliveryId',
  DeliveryItem = 'deliveryItem',
  DestinationAreaCode = 'destinationAreaCode',
  DestinationAreaId = 'destinationAreaId',
  DestinationBinCode = 'destinationBinCode',
  DestinationBinId = 'destinationBinId',
  DestinationLicensePlateCode = 'destinationLicensePlateCode',
  DestinationLicensePlateDescription = 'destinationLicensePlateDescription',
  DestinationLicensePlateId = 'destinationLicensePlateId',
  DestinationLotCode = 'destinationLotCode',
  DestinationLotId = 'destinationLotId',
  DestinationStatus = 'destinationStatus',
  DueDate = 'dueDate',
  ErpSalesOrder = 'erpSalesOrder',
  FulfillmentErpCode = 'fulfillmentErpCode',
  FulfillmentItem = 'fulfillmentItem',
  GrossWeight = 'grossWeight',
  IsTaskGroup = 'isTaskGroup',
  NetWeight = 'netWeight',
  ParentTaskId = 'parentTaskId',
  ProductCode = 'productCode',
  ProductDescription = 'productDescription',
  ProductId = 'productId',
  ProductLotManaged = 'productLotManaged',
  Quantity = 'quantity',
  Readonly = 'readonly',
  ReferringDoc = 'referringDoc',
  ReferringDocItem = 'referringDocItem',
  ShipToName = 'shipToName',
  SourceAreaCode = 'sourceAreaCode',
  SourceAreaId = 'sourceAreaId',
  SourceBinCode = 'sourceBinCode',
  SourceBinId = 'sourceBinId',
  SourceLicensePlateCode = 'sourceLicensePlateCode',
  SourceLicensePlateDescription = 'sourceLicensePlateDescription',
  SourceLicensePlateId = 'sourceLicensePlateId',
  SourceLotCode = 'sourceLotCode',
  SourceLotId = 'sourceLotId',
  SourceStatus = 'sourceStatus',
  StartedAt = 'startedAt',
  StartedAtLocationX = 'startedAtLocationX',
  StartedAtLocationY = 'startedAtLocationY',
  StartedByUserId = 'startedByUserId',
  TaskCode = 'taskCode',
  TaskGroupCode = 'taskGroupCode',
  TaskGroupId = 'taskGroupId',
  TaskGroupPosition = 'taskGroupPosition',
  TaskGroupStatus = 'taskGroupStatus',
  TaskId = 'taskId',
  TaskStatus = 'taskStatus',
  TaskType = 'taskType',
  TaskTypeCode = 'taskTypeCode',
  TeamDescription = 'teamDescription',
  TeamId = 'teamId',
  TeamName = 'teamName',
  UnitOfMeasure = 'unitOfMeasure',
  UnitOfMeasureId = 'unitOfMeasureId',
  UserId = 'userId',
  Volume = 'volume',
  WarehouseCode = 'warehouseCode',
  WarehouseId = 'warehouseId',
  WarehouseName = 'warehouseName',
  WeightUomId = 'weightUOMId'
}

export enum ModelWarehouseType {
  Aoe = 'aoe',
  Default = 'default',
  Pto = 'pto'
}

export type ModelWarehouseTypeFilterComparison = {
  eq?: InputMaybe<ModelWarehouseType>;
  gt?: InputMaybe<ModelWarehouseType>;
  gte?: InputMaybe<ModelWarehouseType>;
  iLike?: InputMaybe<ModelWarehouseType>;
  in?: InputMaybe<Array<ModelWarehouseType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<ModelWarehouseType>;
  lt?: InputMaybe<ModelWarehouseType>;
  lte?: InputMaybe<ModelWarehouseType>;
  neq?: InputMaybe<ModelWarehouseType>;
  notILike?: InputMaybe<ModelWarehouseType>;
  notIn?: InputMaybe<Array<ModelWarehouseType>>;
  notLike?: InputMaybe<ModelWarehouseType>;
};

export type Mutation = {
  __typename?: 'Mutation';
  approveOneDisposition: Scalars['String']['output'];
  assignTask: Task;
  assignTaskGroup: TaskGroup;
  blockBinToBinTask: Task;
  /** Blocks a fulfillment prevent progress or load events */
  blockFulfillment: Delivery;
  blockPickTask: Task;
  cancelDelivery: Delivery;
  cancelDeliveryTasks: Array<Scalars['String']['output']>;
  cancelManyDeliveryItems: Array<DeliveryItem>;
  cancelManyTasks: Array<Task>;
  closeOneSupportTicket: Scalars['String']['output'];
  completeBarcodeEnrollment: AdminTask;
  completeFulfillment: Delivery;
  completeLicensePlateBinToBinSingleTask: Task;
  completeLicensePlateBinToBinTask: Task;
  completeLicensePlateMovementTask: Task;
  completeLicensePlateMovementTaskTaskGroup: Task;
  completeManyFFUnloadMovementTask: Array<Task>;
  completeManyLicensePlatePutawayTask: TaskCompleteManyLicensePlatePutawayResponse;
  /** @deprecated Deprecated. Please use completeManyLoadTasks mutation. */
  completeManyLoadMovementTask: Array<Task>;
  completeManyLoadTasks: Array<Task>;
  /** @deprecated Deprecated. Please use completeManyLoadTasks mutation. */
  completeManyOneStepLoad: Array<Task>;
  /** @deprecated Deprecated. Please use completeOneFFPutawayTask mutation. */
  completeManyOneStepUnload: TaskCompleteManyLicensePlatePutawayResponse;
  /** @deprecated Deprecated.  Please use completeManyPickToStagingTasks mutation. */
  completeManyPickToStagingMovementTasks: Array<Task>;
  completeManyPickToStagingTasks: Array<Task>;
  /** @deprecated Deprecated. Please use completeManyFFUnloadMovementTask mutation. */
  completeManyUnloadMovementTask: Array<Task>;
  completeOneBinToBinMovementTask: Task;
  completeOneFFPutawayTask: Task;
  completeOneFFUnloadMovementTask: Task;
  completeOneIssueStockTask: Task;
  /** @deprecated Deprecated. Please use completeManyLoadTasks mutation. */
  completeOneLoadMovementTask: Task;
  completeOnePhysicalInventoryApprovalTask: Task;
  completeOnePhysicalInventoryTask: Task;
  completeOnePhysicalInventoryTaskByBin: CompleteBinPiTaskResponseObject;
  completeOnePickTask: Task;
  /** @deprecated Deprecated.  Please use completeManyPickToStagingTasks mutation. */
  completeOnePickToStagingMovementTask: Task;
  completeOnePutawayTask: Task;
  completeOneTaskLicensePlateStockStatus: Task;
  /** @deprecated Deprecated. Please use completeOneFFUnloadMovementTask mutation. */
  completeOneUnloadMovementTask: Task;
  completeOneUnpackLicensePlateTask: Task;
  completeRandomPutaways: RandomPutawayOutputDto;
  completeRandomUnloads: RandomUnloadOutputDto;
  copyOneBinStorageType: BinStorageType;
  copyOneStockStatusType: StockStatusType;
  createAndCompleteOneDispositionTask: Task;
  createAndCompleteOneFoundStockTask: Task;
  createBarcodeEnrollment: AdminTask;
  /** Adds deliveries to fulfilld system by shipping point from a provided Warehouse id. */
  createDeliveriesByWarehouseId: Scalars['String']['output'];
  /** Adds fulfillments to fulfilld system by shipping point from a provided Warehouse id. */
  createFulfillmentsByWarehouseId: Scalars['String']['output'];
  createLicensePlateBinToBinSingleTask: Task;
  createLicensePlateBinToBinTask: Task;
  /** @deprecated Deprecated.  Awaiting refactor. */
  createLoadTasksForFulfillment: Array<Task>;
  /** creates a new agent configuration */
  createOneAgentConfiguration: ProxyConfig;
  createOneAisle: Aisle;
  createOneAisleColumn: AisleColumn;
  createOneArea: Area;
  createOneBarcode: Barcode;
  createOneBarcodeTemplateMapping: BarcodeTemplateMapping;
  createOneBin: Bin;
  createOneBinStorageType: BinStorageType;
  createOneBinToBinMovementTask: Task;
  createOneBusinessPartner: BusinessPartner;
  createOneCompany: Company;
  createOneContactInfo: ContactInfo;
  createOneDelivery: Delivery;
  createOneDeliveryItem: DeliveryItem;
  createOneDisplayPreference: DisplayPreference;
  createOneDoor: Door;
  createOneEquipmentItem: EquipmentItem;
  createOneEquipmentModel: EquipmentModel;
  createOneEquipmentType: EquipmentType;
  createOneFulfillment: Delivery;
  createOneFulfillmentBlock: FulfillmentBlock;
  createOneFulfillmentItem: FulfillmentItem;
  createOneIssueStockTask: Task;
  createOneLicensePlate: LicensePlate;
  createOneLot: Lot;
  createOneMap: Map;
  /** Create an SAP material movement. */
  createOneMaterialMovement: SapMaterialDocumentHeader;
  createOneOrganization: Organization;
  createOnePhysicalInventoryTask: Task;
  createOnePhysicalInventoryTaskByBin: Task;
  createOneProduct: Product;
  createOneReplenishment: Replenishment;
  createOneRole: Role;
  createOneSalesOrder: SapSalesOrder;
  createOneScheduledJob: ScheduledJob;
  createOneSharedLayout: Layout;
  createOneSlottingConfiguration: SlottingConfiguration;
  createOneSlottingConfigurationV1: SlottingConfigurationV1;
  createOneSlottingDataset: SlottingDataset;
  createOneSlottingRuleset: SlottingRuleset;
  createOneSlottingRun: Scalars['Boolean']['output'];
  createOneSlottingSetting: SlottingSetting;
  createOneStockStatusType: StockStatusType;
  createOneSupportTicket: Scalars['String']['output'];
  createOneTask: Task;
  createOneTaskGroup: TaskGroup;
  createOneTaskLicensePlateStockStatus: Task;
  createOneTaskType: TaskType;
  createOneTeam: Team;
  createOneUnpackLicensePlateTask: Task;
  createOneUser: User;
  createOneUserGroup: UserGroup;
  createOneUserLayout: Layout;
  createOneUserTeamMapping: UserTeamMapping;
  createOneUserWarehouseDefault: UserWarehouseDefault;
  createOneWarehouse: Warehouse;
  createOneWarehouseConfiguration: WarehouseConfiguration;
  createOneWarehousePreferredUnitOfMeasure: WarehousePreferredUnitOfMeasure;
  createOneWarehouseRoleType: WarehouseRoleType;
  createOneZone: Zone;
  createPickTasksForFulfillment: TaskCreatePickToStagingResourceDto;
  /** @deprecated Deprecated.  Please use createPickTasksForFulfillment mutation. */
  createPickToStagingMovementTasks: Array<Task>;
  createStockFromProduction: Scalars['String']['output'];
  createTasksForDelivery: Array<Task>;
  createTasksForFulfillment: Array<Task>;
  /** @deprecated Deprecated. Please use createUnloadTasksForFFDelivery mutation. */
  createUnloadTasksForDelivery: Array<Task>;
  createUnloadTasksForFFDelivery: Array<Task>;
  declineOneSupportTicket: Scalars['String']['output'];
  /** deletes a single agent configuration by id */
  deleteOneAgentConfiguration: ProxyConfig;
  deleteOneAisle: Aisle;
  deleteOneAisleColumn: AisleColumn;
  deleteOneArea: Area;
  deleteOneBarcode: Barcode;
  deleteOneBarcodeTemplateMapping: BarcodeTemplateMapping;
  deleteOneBin: Bin;
  deleteOneBinStorageType: BinStorageType;
  deleteOneBusinessPartner: BusinessPartner;
  deleteOneCompany: Company;
  deleteOneContactInfo: ContactInfo;
  deleteOneDelivery: Delivery;
  deleteOneDeliveryItem: DeliveryItem;
  deleteOneDisplayPreference: DisplayPreference;
  deleteOneDoor: Door;
  deleteOneEquipmentItem: EquipmentItem;
  deleteOneEquipmentModel: EquipmentModel;
  deleteOneEquipmentType: EquipmentType;
  deleteOneFulfillmentBlock: FulfillmentBlock;
  deleteOneFulfillmentItem: FulfillmentItem;
  deleteOneLicensePlate: LicensePlate;
  deleteOneLot: Lot;
  deleteOneOrganization: Organization;
  deleteOneProduct: Product;
  deleteOneReplenishment: Replenishment;
  deleteOneRole: Role;
  deleteOneScheduledJob: ScheduledJob;
  deleteOneSharedLayout: Scalars['String']['output'];
  deleteOneSlottingConfiguration: SlottingConfiguration;
  deleteOneSlottingConfigurationV1: SlottingConfigurationV1;
  deleteOneSlottingDataset: SlottingDataset;
  deleteOneSlottingRuleset: SlottingRuleset;
  deleteOneSlottingSetting: SlottingSetting;
  deleteOneStockStatusType: StockStatusType;
  deleteOneTask: Task;
  deleteOneTaskGroup: TaskGroup;
  deleteOneTaskType: TaskType;
  deleteOneTeam: Team;
  deleteOneUnitOfMeasureGlossary: UnitOfMeasureGlossary;
  deleteOneUnitOfMeasureProductConversion: UnitOfMeasureProductConversion;
  deleteOneUser: User;
  deleteOneUserGroup: UserGroup;
  deleteOneUserLayout: Scalars['String']['output'];
  deleteOneUserTeamMapping: UserTeamMapping;
  deleteOneUserWarehouseDefault: UserWarehouseDefault;
  deleteOneWarehouse: Warehouse;
  deleteOneWarehouseConfiguration: WarehouseConfiguration;
  deleteOneWarehousePreferredUnitOfMeasure: WarehousePreferredUnitOfMeasure;
  deleteOneWarehouseRoleType: WarehouseRoleType;
  deleteOneZone: Zone;
  deploySlottingRun: Scalars['String']['output'];
  destroyOneAdminTask: AdminTask;
  destroyOneAdminTaskType: AdminTaskType;
  destroyOneAisle: Aisle;
  destroyOneAisleColumn: AisleColumn;
  destroyOneArea: Area;
  destroyOneBarcode: Barcode;
  destroyOneBarcodeMapping: BarcodeMapping;
  destroyOneBin: Bin;
  destroyOneBinStorageType: BinStorageType;
  destroyOneBusinessPartner: BusinessPartner;
  destroyOneCompany: Company;
  destroyOneContactInfo: ContactInfo;
  destroyOneDelivery: Delivery;
  destroyOneDeliveryItem: DeliveryItem;
  destroyOneDisplayPreference: DisplayPreference;
  destroyOneDoor: Door;
  destroyOneEquipmentItem: EquipmentItem;
  destroyOneEquipmentModel: EquipmentModel;
  destroyOneEquipmentType: EquipmentType;
  destroyOneFulfillment: Delivery;
  destroyOneFulfillmentBlock: FulfillmentBlock;
  destroyOneFulfillmentItem: FulfillmentItem;
  destroyOneLayout: Layout;
  destroyOneLicensePlate: LicensePlate;
  destroyOneLot: Lot;
  destroyOneOrganization: Organization;
  destroyOneProduct: Product;
  destroyOneProductMovement: ProductMovement;
  destroyOneReplenishment: Replenishment;
  destroyOneSapStockStatusType: SapStockStatusType;
  destroyOneSapStorageLocationPlant: SapStorageLocationPlant;
  destroyOneScheduledJob: ScheduledJob;
  destroyOneSlottingConfiguration: SlottingConfiguration;
  destroyOneSlottingConfigurationV1: SlottingConfigurationV1;
  destroyOneSlottingDataset: SlottingDataset;
  destroyOneSlottingRuleset: SlottingRuleset;
  destroyOneSlottingSetting: SlottingSetting;
  destroyOneSlottingSettingItem: SlottingSettingItem;
  destroyOneStockStatusType: StockStatusType;
  destroyOneTask: Task;
  destroyOneTaskGroup: TaskGroup;
  destroyOneTaskType: TaskType;
  destroyOneTeam: Team;
  destroyOneUnitOfMeasure: WarehousePreferredUnitOfMeasure;
  destroyOneUnitOfMeasureGlossary: UnitOfMeasureGlossary;
  destroyOneUnitOfMeasureProductConversion: UnitOfMeasureProductConversion;
  destroyOneUser: User;
  destroyOneUserTeamMapping: UserTeamMapping;
  destroyOneUserWarehouseDefault: UserWarehouseDefault;
  destroyOneWarehouse?: Maybe<Warehouse>;
  destroyOneWarehouseConfiguration: WarehouseConfiguration;
  destroyOneWarehouseRoleType: WarehouseRoleType;
  destroyOneZone: Zone;
  destroyOneZoneBehaviorMapping: ZoneBehaviorMapping;
  destroyOneZoneRule: ZoneRule;
  destroyOneZoneRuleMapping: ZoneRuleMapping;
  destroyOnebarcodeTemplate: BarcodeTemplate;
  destroyOnebarcodeTemplateMapping: BarcodeTemplateMapping;
  destroyOnelicensePlateStatus: LicensePlateStatus;
  destroyOnelicensePlateStatusMapping: LicensePlateStatusMapping;
  destroyOnenonCompliantBarcode: NonCompliantBarcode;
  destroyOnepermission: Permission;
  destroyOnepermissionDimension: PermissionDimension;
  destroyOnerole: Role;
  destroyOnerolePermissionMapping: RolePermissionMapping;
  destroyOneuserGroup: UserGroup;
  destroyOneuserGroupMapping: UserGroupMapping;
  destroyOneuserGroupRoleMapping: UserGroupRoleMapping;
  /** Enrolls bin location data in the system. */
  enroll_Bin: Bin;
  ewmCreateWarehouseTask: EwmWarehouseTaskResponse;
  ewmDeconHandlingUnit: EwmHuDeconResponse;
  ewmOneStepBinToBin: EwmBinToBinResponse;
  ewmPostGoodsReceipt: EwmWarehouseTaskResponse;
  ewmTwoStepBinToBinComplete: EwmBinToBinResponse;
  ewmTwoStepBinToBinCreate: EwmBinToBinResponse;
  generateData: GenerateDataOutputDto;
  moveStockToLicensePlate: LicensePlate;
  moveStockToLicensePlateTaskGroup: LicensePlate;
  pauseOneScheduledJob: ScheduledJob;
  /** Submits an SAP Post Goods Issue for a fulfillment. */
  postGoodsIssue: Delivery;
  /** Submits an SAP Post Goods Receipt for a delivery. */
  postGoodsReceipt: Delivery;
  /** @deprecated Deprecated. Awaiting refactor. */
  processReturnDelivery: Scalars['String']['output'];
  /** Removes a blocks from a fulfillment. */
  removeFulfillmentBlock: Delivery;
  resolveNonCompliantBarcodes: Scalars['String']['output'];
  resumeOneScheduledJob: ScheduledJob;
  runSlottingConfiguration: Scalars['String']['output'];
  runSlottingConfigurationV1: Scalars['String']['output'];
  setUserDefaultLayout: Layout;
  shortShipFulfillment: Delivery;
  startTask: Task;
  startTaskGroup: TaskGroup;
  sysAdminCreateSapOutboundDeliveries: Scalars['String']['output'];
  updateManyBinStatusMappings: Scalars['String']['output'];
  updateManyLicensePlateStatusMappings: Scalars['String']['output'];
  /** updates a given agent configuration by id */
  updateOneAgentConfiguration: ProxyConfig;
  updateOneAisle: Aisle;
  updateOneAisleColumn: AisleColumn;
  updateOneArea: Area;
  updateOneBarcode: Barcode;
  updateOneBarcodeTemplateMapping: BarcodeTemplateMapping;
  updateOneBin: Bin;
  updateOneBinStorageType: BinStorageType;
  updateOneBusinessPartner: BusinessPartner;
  updateOneCompany: Company;
  updateOneContactInfo: ContactInfo;
  /** Update Contact info data on Company by provided Company id and updateData argument. */
  updateOneContactInfoByCompanyId: ContactInfo;
  /** Update Contact info data on Organization by provided Organization id and updateData argument. */
  updateOneContactInfoByOrganizationId: ContactInfo;
  /** Update Contact info data on Warehouse by provided Warehouse id and updateData argument. */
  updateOneContactInfoByWarehouseId: ContactInfo;
  updateOneDelivery: Delivery;
  updateOneDeliveryItem: DeliveryItem;
  updateOneDisplayPreference: DisplayPreference;
  /** Updates display preferences by company id */
  updateOneDisplayPreferenceByCompanyId: DisplayPreference;
  /** Updates display preferences by organization id */
  updateOneDisplayPreferenceByOrganizationId: DisplayPreference;
  /** Updates display preferences by user id */
  updateOneDisplayPreferenceByUserId: DisplayPreference;
  /** Updates display preferences by warehouse id */
  updateOneDisplayPreferenceByWarehouseId: DisplayPreference;
  updateOneDisposition: Disposition;
  updateOneDoor: Door;
  updateOneEquipmentItem: EquipmentItem;
  updateOneEquipmentModel: EquipmentModel;
  updateOneEquipmentType: EquipmentType;
  updateOneFulfillmentBlock: FulfillmentBlock;
  updateOneFulfillmentItem: FulfillmentItem;
  updateOneLicensePlate: LicensePlate;
  updateOneLot: Lot;
  updateOneOrganization: Organization;
  updateOneProduct: Product;
  updateOneReplenishment: Replenishment;
  updateOneScheduledJob: ScheduledJob;
  updateOneSharedLayout: Layout;
  updateOneSlottingConfiguration: SlottingConfiguration;
  updateOneSlottingConfigurationV1: SlottingConfigurationV1;
  updateOneSlottingDataset: SlottingDataset;
  updateOneSlottingRuleset: SlottingRuleset;
  updateOneSlottingSetting: SlottingSetting;
  updateOneStockStatusType: StockStatusType;
  updateOneTask: Task;
  updateOneTaskGroup: TaskGroup;
  updateOneTaskType: TaskType;
  /** update one bin status mappings for a single task type */
  updateOneTaskTypeBinStatusMapping: ViewTaskTypeBinStatusMapping;
  /** update one stock status mappings for a single task type */
  updateOneTaskTypeStockStatusMapping: ViewTaskTypeStockStatusMapping;
  updateOneTeam: Team;
  updateOneUnitOfMeasureGlossary: UnitOfMeasureGlossary;
  updateOneUser: User;
  updateOneUserGroup: UserGroup;
  updateOneUserLayout: Layout;
  updateOneUserTeamMapping: UserTeamMapping;
  updateOneUserWarehouseDefault: UserWarehouseDefault;
  updateOneWarehouse: Warehouse;
  updateOneWarehouseConfiguration: WarehouseConfiguration;
  updateOneWarehousePreferredUnitOfMeasure: WarehousePreferredUnitOfMeasure;
  updateOneWarehouseRoleType: WarehouseRoleType;
  updateOneZone: Zone;
  updatePermissionsForRole: Scalars['String']['output'];
  /** updates the proxy url */
  updateProxyUrl: ProxyConfig;
  updateRolesForUserGroup: Scalars['String']['output'];
  updateUsersForUserGroup: Scalars['String']['output'];
};


export type MutationApproveOneDispositionArgs = {
  input: DispositionApproveOneDto;
};


export type MutationAssignTaskArgs = {
  input: TaskAssignDto;
};


export type MutationAssignTaskGroupArgs = {
  assignTeamId?: InputMaybe<Scalars['ID']['input']>;
  assignUserId?: InputMaybe<Scalars['ID']['input']>;
  taskGroupId: Scalars['ID']['input'];
};


export type MutationBlockBinToBinTaskArgs = {
  input: TaskBlockInputDto;
};


export type MutationBlockFulfillmentArgs = {
  input: FulfillmentBlockInput;
};


export type MutationBlockPickTaskArgs = {
  input: TaskBlockInputDto;
};


export type MutationCancelDeliveryArgs = {
  deliveryId: Scalars['String']['input'];
};


export type MutationCancelDeliveryTasksArgs = {
  input: CancelPutawayTaskInputDto;
};


export type MutationCancelManyDeliveryItemsArgs = {
  cancelManyDeliveryItems: Array<CancelManyDeliveryItemsDto>;
};


export type MutationCancelManyTasksArgs = {
  input: Array<TaskCancelManyDto>;
};


export type MutationCloseOneSupportTicketArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCompleteBarcodeEnrollmentArgs = {
  input: CompleteBarcodeEnrollmentInputDto;
};


export type MutationCompleteFulfillmentArgs = {
  fulfillmentId: Scalars['ID']['input'];
};


export type MutationCompleteLicensePlateBinToBinSingleTaskArgs = {
  input: TaskCompleteLicensePlateBinToBinMovementInputDto;
};


export type MutationCompleteLicensePlateBinToBinTaskArgs = {
  input: TaskCompleteLicensePlateBinToBinMovementInputDto;
};


export type MutationCompleteLicensePlateMovementTaskArgs = {
  input: TaskCompleteLicensePlateMovementInputDto;
};


export type MutationCompleteLicensePlateMovementTaskTaskGroupArgs = {
  input: TaskCompleteLicensePlateMovementInputDto;
};


export type MutationCompleteManyFfUnloadMovementTaskArgs = {
  completeManyUnloadTasks?: InputMaybe<Array<TaskCompleteOneOfManyUnloadInput>>;
  destinationBinId: Scalars['ID']['input'];
};


export type MutationCompleteManyLicensePlatePutawayTaskArgs = {
  completeLicensePlatePutaways: Array<TaskCompleteManyLicensePlatePutawayInput>;
  destinationBinId: Scalars['ID']['input'];
};


export type MutationCompleteManyLoadMovementTaskArgs = {
  completeLoadTasks?: InputMaybe<Array<TaskCompleteLoadInput>>;
};


export type MutationCompleteManyLoadTasksArgs = {
  completeLoadTasks: Array<LoadTaskCompleteInput>;
  warehouseId: Scalars['ID']['input'];
};


export type MutationCompleteManyOneStepLoadArgs = {
  completePickToStagingTasks?: InputMaybe<Array<TaskCompleteManyPickToStagingInput>>;
};


export type MutationCompleteManyOneStepUnloadArgs = {
  completeUnloadTasks: Array<TaskCompleteUnloadInput>;
  destinationBinId: Scalars['String']['input'];
};


export type MutationCompleteManyPickToStagingMovementTasksArgs = {
  completePickToStagingTasks?: InputMaybe<Array<TaskCompleteManyPickToStagingInput>>;
};


export type MutationCompleteManyPickToStagingTasksArgs = {
  completePickToStagingTasks: Array<CompletePickToStagingTaskInput>;
  warehouseId: Scalars['String']['input'];
};


export type MutationCompleteManyUnloadMovementTaskArgs = {
  completeUnloadTasks?: InputMaybe<Array<TaskCompleteUnloadInput>>;
};


export type MutationCompleteOneBinToBinMovementTaskArgs = {
  input: CompleteBinToBinTaskInputDto;
};


export type MutationCompleteOneFfPutawayTaskArgs = {
  input: CompletePutawayTaskInputDto;
};


export type MutationCompleteOneFfUnloadMovementTaskArgs = {
  completeUnloadTask: TaskCompleteOneUnloadInput;
};


export type MutationCompleteOneIssueStockTaskArgs = {
  completeOneIssueStockTask: TaskCompleteOneIssueStockInput;
};


export type MutationCompleteOneLoadMovementTaskArgs = {
  completeLoadTask: TaskCompleteOneLoadInput;
};


export type MutationCompleteOnePhysicalInventoryApprovalTaskArgs = {
  input: ApprovePiTaskInputDto;
};


export type MutationCompleteOnePhysicalInventoryTaskArgs = {
  input: CompletePiTaskInputDto;
};


export type MutationCompleteOnePhysicalInventoryTaskByBinArgs = {
  input: CompleteBinPiTaskInputDto;
};


export type MutationCompleteOnePickTaskArgs = {
  input: CompletePickTaskInputDto;
};


export type MutationCompleteOnePickToStagingMovementTaskArgs = {
  completePickToStagingTask: TaskCompleteOnePickToStagingInput;
};


export type MutationCompleteOnePutawayTaskArgs = {
  input: CompletePutawayTaskInputDto;
};


export type MutationCompleteOneTaskLicensePlateStockStatusArgs = {
  input: TaskLicensePlateStockStatusCompleteOneDto;
};


export type MutationCompleteOneUnloadMovementTaskArgs = {
  completeUnloadTask: TaskCompleteOneUnloadInput;
};


export type MutationCompleteOneUnpackLicensePlateTaskArgs = {
  input: TaskLicensePlateUnpackInputCompleteDto;
};


export type MutationCompleteRandomPutawaysArgs = {
  maxToComplete?: InputMaybe<Scalars['Float']['input']>;
  throttleLimit?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationCompleteRandomUnloadsArgs = {
  maxToComplete?: InputMaybe<Scalars['Float']['input']>;
  throttleLimit?: InputMaybe<Scalars['Float']['input']>;
};


export type MutationCopyOneBinStorageTypeArgs = {
  copyBinStorageType: BinStorageTypeCopyDto;
};


export type MutationCopyOneStockStatusTypeArgs = {
  input: CopyStockStatusTypeInputDto;
};


export type MutationCreateAndCompleteOneDispositionTaskArgs = {
  input: CreateOneTaskDispositionInput;
};


export type MutationCreateAndCompleteOneFoundStockTaskArgs = {
  input: FoundStockTaskCreateAndCompleteOneInput;
};


export type MutationCreateBarcodeEnrollmentArgs = {
  input: CreateBarcodeEnrollmentInputDto;
};


export type MutationCreateDeliveriesByWarehouseIdArgs = {
  warehouseId: Scalars['ID']['input'];
};


export type MutationCreateFulfillmentsByWarehouseIdArgs = {
  warehouseId: Scalars['ID']['input'];
};


export type MutationCreateLicensePlateBinToBinSingleTaskArgs = {
  input: LicensePlateBinToBinTaskMovementCreateInputDto;
};


export type MutationCreateLicensePlateBinToBinTaskArgs = {
  input: LicensePlateBinToBinTaskMovementCreateInputDto;
};


export type MutationCreateLoadTasksForFulfillmentArgs = {
  fulfillmentId: Scalars['ID']['input'];
};


export type MutationCreateOneAgentConfigurationArgs = {
  agentName: Scalars['String']['input'];
  hosts: Array<AgentConfigurationHostInput>;
};


export type MutationCreateOneAisleArgs = {
  input: AisleCreateOneInput;
};


export type MutationCreateOneAisleColumnArgs = {
  input: AisleColumnCreateOneInput;
};


export type MutationCreateOneAreaArgs = {
  input: AreaCreateOneInput;
};


export type MutationCreateOneBarcodeArgs = {
  input: BarcodeCreateOneInput;
};


export type MutationCreateOneBarcodeTemplateMappingArgs = {
  input: BarcodeTemplateMappingCreateOneInput;
};


export type MutationCreateOneBinArgs = {
  input: BinCreateOneInput;
};


export type MutationCreateOneBinStorageTypeArgs = {
  input: BinStorageTypeCreateOneInput;
};


export type MutationCreateOneBinToBinMovementTaskArgs = {
  input: CreateBinToBinTaskInputDto;
};


export type MutationCreateOneBusinessPartnerArgs = {
  input: BusinessPartnerCreateOneInput;
};


export type MutationCreateOneCompanyArgs = {
  input: CompanyCreateOneInput;
};


export type MutationCreateOneContactInfoArgs = {
  input: ContactInfoCreateOneInput;
};


export type MutationCreateOneDeliveryArgs = {
  input: DeliveryCreateOneInput;
};


export type MutationCreateOneDeliveryItemArgs = {
  input: DeliveryItemCreateOneInput;
};


export type MutationCreateOneDisplayPreferenceArgs = {
  input: DisplayPreferenceCreateOneInput;
};


export type MutationCreateOneDoorArgs = {
  input: DoorCreateOneInput;
};


export type MutationCreateOneEquipmentItemArgs = {
  input: EquipmentCreateOneInput;
};


export type MutationCreateOneEquipmentModelArgs = {
  input: EquipmentModelCreateOneInput;
};


export type MutationCreateOneEquipmentTypeArgs = {
  input: EquipmentTypeCreateOneInput;
};


export type MutationCreateOneFulfillmentArgs = {
  input: FulfillmentWithItemsCreateOneInput;
};


export type MutationCreateOneFulfillmentBlockArgs = {
  input: CreateOneFulfillmentBlockInput;
};


export type MutationCreateOneFulfillmentItemArgs = {
  input: FulfillmentItemCreateOneInput;
};


export type MutationCreateOneIssueStockTaskArgs = {
  input: IssueStockTaskCreateOneInput;
};


export type MutationCreateOneLicensePlateArgs = {
  input: LicensePlateCreateOneInput;
};


export type MutationCreateOneLotArgs = {
  input: LotCreateOneInput;
};


export type MutationCreateOneMapArgs = {
  input: MapCreateOneInput;
};


export type MutationCreateOneMaterialMovementArgs = {
  materialMovementDto: MaterialMovmentDtoCreateOneInput;
};


export type MutationCreateOneOrganizationArgs = {
  input: OrganizationCreateOneInput;
};


export type MutationCreateOnePhysicalInventoryTaskArgs = {
  input: CreatePiTaskInputDto;
};


export type MutationCreateOnePhysicalInventoryTaskByBinArgs = {
  input: CreateBinPiTaskInputDto;
};


export type MutationCreateOneProductArgs = {
  input: ProductCreateOneInput;
};


export type MutationCreateOneReplenishmentArgs = {
  input: ReplenishmentCreateOneInput;
};


export type MutationCreateOneRoleArgs = {
  input: RoleCreateInput;
};


export type MutationCreateOneSalesOrderArgs = {
  salesOrderData: SapSalesOrderCreateOneInput;
};


export type MutationCreateOneScheduledJobArgs = {
  input: ScheduledJobCreateInputDto;
};


export type MutationCreateOneSharedLayoutArgs = {
  input: LayoutSharedCreateInput;
};


export type MutationCreateOneSlottingConfigurationArgs = {
  input: SlottingConfigurationCreateOneInput;
};


export type MutationCreateOneSlottingConfigurationV1Args = {
  input: SlottingConfigurationCreateOneInputV1;
};


export type MutationCreateOneSlottingDatasetArgs = {
  input: SlottingDatasetCreateOneInput;
};


export type MutationCreateOneSlottingRulesetArgs = {
  input: SlottingRulesetCreateOneInput;
};


export type MutationCreateOneSlottingRunArgs = {
  input: SlottingRunCreateOneInput;
};


export type MutationCreateOneSlottingSettingArgs = {
  input: SlottingSettingCreateOneInput;
};


export type MutationCreateOneStockStatusTypeArgs = {
  input: StockStatusTypeCreateOneInput;
};


export type MutationCreateOneSupportTicketArgs = {
  description: Scalars['String']['input'];
  senderEmail: Scalars['String']['input'];
  senderName: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationCreateOneTaskArgs = {
  input: TaskCreateOneInput;
};


export type MutationCreateOneTaskGroupArgs = {
  input: TaskGroupCreateOneInput;
};


export type MutationCreateOneTaskLicensePlateStockStatusArgs = {
  input: CreateOneTaskLicensePlateStockStatusInput;
};


export type MutationCreateOneTaskTypeArgs = {
  input: TaskTypeCreateOneInput;
};


export type MutationCreateOneTeamArgs = {
  input: TeamCreateOneInput;
};


export type MutationCreateOneUnpackLicensePlateTaskArgs = {
  input: TaskLicensePlateUnpackInputCreateDto;
};


export type MutationCreateOneUserArgs = {
  input: UserCreateOneInput;
};


export type MutationCreateOneUserGroupArgs = {
  input: UserGroupCreateInputDto;
};


export type MutationCreateOneUserLayoutArgs = {
  input: LayoutUserCreateInput;
};


export type MutationCreateOneUserTeamMappingArgs = {
  input: UserTeamMappingCreateOneInput;
};


export type MutationCreateOneUserWarehouseDefaultArgs = {
  input: UserWarehouseDefaultCreateOneInput;
};


export type MutationCreateOneWarehouseArgs = {
  input: WarehouseCreateOneInput;
};


export type MutationCreateOneWarehouseConfigurationArgs = {
  input: WarehouseConfigurationCreateOneInput;
};


export type MutationCreateOneWarehousePreferredUnitOfMeasureArgs = {
  input: WarehousePreferredUnitOfMeasureCreateOneInput;
};


export type MutationCreateOneWarehouseRoleTypeArgs = {
  input: WarehouseRoleTypeCreateOneInput;
};


export type MutationCreateOneZoneArgs = {
  input: ZoneCreateOneInput;
};


export type MutationCreatePickTasksForFulfillmentArgs = {
  fulfillmentId: Scalars['ID']['input'];
};


export type MutationCreatePickToStagingMovementTasksArgs = {
  createManyPickToStagingTasks: Array<TaskCreateOnePickToStagingInput>;
};


export type MutationCreateStockFromProductionArgs = {
  input: CreateStockFromProductionTaskInputDto;
};


export type MutationCreateTasksForDeliveryArgs = {
  input: CreatePutawayTaskInputDto;
};


export type MutationCreateTasksForFulfillmentArgs = {
  input: CreatePickTaskInputDto;
};


export type MutationCreateUnloadTasksForDeliveryArgs = {
  deliveryId: Scalars['ID']['input'];
};


export type MutationCreateUnloadTasksForFfDeliveryArgs = {
  deliveryId: Scalars['ID']['input'];
};


export type MutationDeclineOneSupportTicketArgs = {
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
};


export type MutationDeleteOneAgentConfigurationArgs = {
  agentId: Scalars['ID']['input'];
};


export type MutationDeleteOneAisleArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneAisleColumnArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneAreaArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneBarcodeArgs = {
  input: DeleteOneBarcodeInput;
};


export type MutationDeleteOneBarcodeTemplateMappingArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneBinArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneBinStorageTypeArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneBusinessPartnerArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneCompanyArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneContactInfoArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneDeliveryArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneDeliveryItemArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneDisplayPreferenceArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneDoorArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneEquipmentItemArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneEquipmentModelArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneEquipmentTypeArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneFulfillmentBlockArgs = {
  input: DeleteOneFulfillmentBlockInput;
};


export type MutationDeleteOneFulfillmentItemArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneLicensePlateArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneLotArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneOrganizationArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneProductArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneReplenishmentArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneRoleArgs = {
  input: RoleDeleteInputType;
};


export type MutationDeleteOneScheduledJobArgs = {
  input: UpdateScheduledJobByIdInputDto;
};


export type MutationDeleteOneSharedLayoutArgs = {
  input: LayoutDeleteInputType;
};


export type MutationDeleteOneSlottingConfigurationArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneSlottingConfigurationV1Args = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneSlottingDatasetArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneSlottingRulesetArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneSlottingSettingArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneStockStatusTypeArgs = {
  input: StockStatusTypeDeleteOneInput;
};


export type MutationDeleteOneTaskArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneTaskGroupArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneTaskTypeArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneTeamArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneUnitOfMeasureGlossaryArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneUnitOfMeasureProductConversionArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneUserArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneUserGroupArgs = {
  input: UserGroupDeleteOneDto;
};


export type MutationDeleteOneUserLayoutArgs = {
  input: LayoutDeleteInputType;
};


export type MutationDeleteOneUserTeamMappingArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneUserWarehouseDefaultArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneWarehouseArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneWarehouseConfigurationArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneWarehousePreferredUnitOfMeasureArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneWarehouseRoleTypeArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeleteOneZoneArgs = {
  input: DeleteOneEntityIdInput;
};


export type MutationDeploySlottingRunArgs = {
  input: DeployRunInput;
};


export type MutationDestroyOneAdminTaskArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneAdminTaskTypeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneAisleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneAisleColumnArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneAreaArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneBarcodeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneBarcodeMappingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneBinArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneBinStorageTypeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneBusinessPartnerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneContactInfoArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneDeliveryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneDeliveryItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneDisplayPreferenceArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneDoorArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneEquipmentItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneEquipmentModelArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneEquipmentTypeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneFulfillmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneFulfillmentBlockArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneFulfillmentItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneLayoutArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneLicensePlateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneLotArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneProductArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneProductMovementArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneReplenishmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneSapStockStatusTypeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneSapStorageLocationPlantArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneScheduledJobArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneSlottingConfigurationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneSlottingConfigurationV1Args = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneSlottingDatasetArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneSlottingRulesetArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneSlottingSettingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneSlottingSettingItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneStockStatusTypeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneTaskArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneTaskGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneTaskTypeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneTeamArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneUnitOfMeasureArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneUnitOfMeasureGlossaryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneUnitOfMeasureProductConversionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneUserTeamMappingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneUserWarehouseDefaultArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneWarehouseArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneWarehouseConfigurationArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneWarehouseRoleTypeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneZoneArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneZoneBehaviorMappingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneZoneRuleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneZoneRuleMappingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOnebarcodeTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOnebarcodeTemplateMappingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOnelicensePlateStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOnelicensePlateStatusMappingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOnenonCompliantBarcodeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOnepermissionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOnepermissionDimensionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneroleArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOnerolePermissionMappingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneuserGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneuserGroupMappingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyOneuserGroupRoleMappingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationEnroll_BinArgs = {
  coordinates: BinEnrollment;
  id: Scalars['ID']['input'];
};


export type MutationEwmCreateWarehouseTaskArgs = {
  delivery: Scalars['String']['input'];
  handlingUnit: Scalars['String']['input'];
};


export type MutationEwmDeconHandlingUnitArgs = {
  handlingUnit: Scalars['String']['input'];
};


export type MutationEwmOneStepBinToBinArgs = {
  bin: Scalars['String']['input'];
  handlingUnit: Scalars['String']['input'];
};


export type MutationEwmPostGoodsReceiptArgs = {
  bin: Scalars['String']['input'];
  handlingUnit: Scalars['String']['input'];
};


export type MutationEwmTwoStepBinToBinCompleteArgs = {
  destinationBin: Scalars['String']['input'];
  warehouseTask: Scalars['String']['input'];
};


export type MutationEwmTwoStepBinToBinCreateArgs = {
  destinationBin: Scalars['String']['input'];
  handlingUnit: Scalars['String']['input'];
};


export type MutationGenerateDataArgs = {
  deliveryDateSpread?: InputMaybe<Scalars['Float']['input']>;
  generateTasks?: InputMaybe<Scalars['Boolean']['input']>;
  maxLineItems?: InputMaybe<Scalars['Float']['input']>;
  maxQuantity?: InputMaybe<Scalars['Float']['input']>;
  minLineItems?: InputMaybe<Scalars['Float']['input']>;
  minQuantity?: InputMaybe<Scalars['Float']['input']>;
  numberOfDeliveries?: InputMaybe<Scalars['Float']['input']>;
  scopedData?: InputMaybe<Array<ScopedData>>;
  throttleLimit?: InputMaybe<Scalars['Float']['input']>;
  type: DataGenerationType;
};


export type MutationMoveStockToLicensePlateArgs = {
  input: LicensePlateMovementCreateInputDto;
};


export type MutationMoveStockToLicensePlateTaskGroupArgs = {
  input: LicensePlateMovementCreateInputDto;
};


export type MutationPauseOneScheduledJobArgs = {
  input: UpdateScheduledJobByIdInputDto;
};


export type MutationPostGoodsIssueArgs = {
  fulfillmentId: Scalars['ID']['input'];
};


export type MutationPostGoodsReceiptArgs = {
  deliveryId: Scalars['ID']['input'];
};


export type MutationProcessReturnDeliveryArgs = {
  input: DeliveryProcessReturnDto;
};


export type MutationRemoveFulfillmentBlockArgs = {
  input: FulfillmentUnblockInput;
};


export type MutationResolveNonCompliantBarcodesArgs = {
  input: NonCompliantBarcodeResolveInputType;
};


export type MutationResumeOneScheduledJobArgs = {
  input: UpdateScheduledJobByIdInputDto;
};


export type MutationRunSlottingConfigurationArgs = {
  id: Scalars['String']['input'];
};


export type MutationRunSlottingConfigurationV1Args = {
  id: Scalars['String']['input'];
};


export type MutationSetUserDefaultLayoutArgs = {
  input: LayoutUserSetDefaultInput;
};


export type MutationShortShipFulfillmentArgs = {
  fulfillmentId: Scalars['ID']['input'];
};


export type MutationStartTaskArgs = {
  input: TaskStartDto;
};


export type MutationStartTaskGroupArgs = {
  input: TaskGroupStartDto;
};


export type MutationSysAdminCreateSapOutboundDeliveriesArgs = {
  outboundDeliveryData: SysAdminOutboundDeliveryCreateDtoInput;
};


export type MutationUpdateManyBinStatusMappingsArgs = {
  input: BinStatusMappingInputDto;
};


export type MutationUpdateManyLicensePlateStatusMappingsArgs = {
  input: LicensePlateStatusMappingInputDto;
};


export type MutationUpdateOneAgentConfigurationArgs = {
  agentId: Scalars['ID']['input'];
  update: AgentConfigurationUpdate;
};


export type MutationUpdateOneAisleArgs = {
  input: AisleUpdateOneInput;
};


export type MutationUpdateOneAisleColumnArgs = {
  input: AisleColumnUpdateOneInput;
};


export type MutationUpdateOneAreaArgs = {
  input: AreaUpdateOneInput;
};


export type MutationUpdateOneBarcodeArgs = {
  input: BarcodeUpdateOneInput;
};


export type MutationUpdateOneBarcodeTemplateMappingArgs = {
  input: BarcodeTemplateMappingUpdateOneInput;
};


export type MutationUpdateOneBinArgs = {
  input: BinUpdateOneInput;
};


export type MutationUpdateOneBinStorageTypeArgs = {
  input: BinStorageTypeUpdateOneInput;
};


export type MutationUpdateOneBusinessPartnerArgs = {
  input: BusinessPartnerUpdateOneInput;
};


export type MutationUpdateOneCompanyArgs = {
  input: CompanyUpdateOneInput;
};


export type MutationUpdateOneContactInfoArgs = {
  input: ContactInfoUpdateOneInput;
};


export type MutationUpdateOneContactInfoByCompanyIdArgs = {
  input: UpdateContactInfoInputDto;
};


export type MutationUpdateOneContactInfoByOrganizationIdArgs = {
  input: UpdateContactInfoInputDto;
};


export type MutationUpdateOneContactInfoByWarehouseIdArgs = {
  input: UpdateContactInfoInputDto;
};


export type MutationUpdateOneDeliveryArgs = {
  input: UpdateOneDeliveryInput;
};


export type MutationUpdateOneDeliveryItemArgs = {
  input: DeliveryItemUpdateOneInput;
};


export type MutationUpdateOneDisplayPreferenceArgs = {
  input: DisplayPreferenceUpdateOneInput;
};


export type MutationUpdateOneDisplayPreferenceByCompanyIdArgs = {
  input: DisplayPreferenceUpdateInputDto;
};


export type MutationUpdateOneDisplayPreferenceByOrganizationIdArgs = {
  input: DisplayPreferenceUpdateInputDto;
};


export type MutationUpdateOneDisplayPreferenceByUserIdArgs = {
  input: DisplayPreferenceUpdateInputDto;
};


export type MutationUpdateOneDisplayPreferenceByWarehouseIdArgs = {
  input: DisplayPreferenceUpdateInputDto;
};


export type MutationUpdateOneDispositionArgs = {
  input: UpdateOneDispositionInput;
};


export type MutationUpdateOneDoorArgs = {
  input: DoorUpdateOneInput;
};


export type MutationUpdateOneEquipmentItemArgs = {
  input: EquipmentUpdateOneInput;
};


export type MutationUpdateOneEquipmentModelArgs = {
  input: EquipmentModelUpdateOneInput;
};


export type MutationUpdateOneEquipmentTypeArgs = {
  input: EquipmentTypeUpdateOneInput;
};


export type MutationUpdateOneFulfillmentBlockArgs = {
  input: UpdateOneFulfillmentBlockInput;
};


export type MutationUpdateOneFulfillmentItemArgs = {
  input: FulfillmentItemUpdateOneInput;
};


export type MutationUpdateOneLicensePlateArgs = {
  input: LicensePlateUpdateOneInput;
};


export type MutationUpdateOneLotArgs = {
  input: LotUpdateOneInput;
};


export type MutationUpdateOneOrganizationArgs = {
  input: OrganizationUpdateOneInput;
};


export type MutationUpdateOneProductArgs = {
  input: ProductUpdateOneInput;
};


export type MutationUpdateOneReplenishmentArgs = {
  input: ReplenishmentUpdateOneInput;
};


export type MutationUpdateOneScheduledJobArgs = {
  input: UpdateScheduledJobInputDto;
};


export type MutationUpdateOneSharedLayoutArgs = {
  input: LayoutSharedUpdateInput;
};


export type MutationUpdateOneSlottingConfigurationArgs = {
  input: SlottingConfigurationUpdateOneInput;
};


export type MutationUpdateOneSlottingConfigurationV1Args = {
  input: SlottingConfigurationUpdateOneInputV1;
};


export type MutationUpdateOneSlottingDatasetArgs = {
  input: SlottingDatasetUpdateOneInput;
};


export type MutationUpdateOneSlottingRulesetArgs = {
  input: SlottingRulesetUpdateOneInput;
};


export type MutationUpdateOneSlottingSettingArgs = {
  input: SlottingSettingUpdateOneInput;
};


export type MutationUpdateOneStockStatusTypeArgs = {
  input: UpdateStockStatusTypeInputDto;
};


export type MutationUpdateOneTaskArgs = {
  input: UpdateOneTaskInput;
};


export type MutationUpdateOneTaskGroupArgs = {
  input: TaskGroupUpdateOneInput;
};


export type MutationUpdateOneTaskTypeArgs = {
  input: TaskTypeUpdateOneInput;
};


export type MutationUpdateOneTaskTypeBinStatusMappingArgs = {
  input: TaskTypeBinStatusReplaceInputDto;
};


export type MutationUpdateOneTaskTypeStockStatusMappingArgs = {
  input: TaskTypeStockStatusMappingReplaceInputDto;
};


export type MutationUpdateOneTeamArgs = {
  input: TeamUpdateOneInput;
};


export type MutationUpdateOneUnitOfMeasureGlossaryArgs = {
  input: UnitOfMeasureGlossaryUpdateOneInput;
};


export type MutationUpdateOneUserArgs = {
  input: UserUpdateOneInput;
};


export type MutationUpdateOneUserGroupArgs = {
  input: UserGroupUpdateInput;
};


export type MutationUpdateOneUserLayoutArgs = {
  input: LayoutUserUpdateInput;
};


export type MutationUpdateOneUserTeamMappingArgs = {
  input: UserTeamMappingUpdateOneInput;
};


export type MutationUpdateOneUserWarehouseDefaultArgs = {
  input: UserWarehouseDefaultUpdateOneInput;
};


export type MutationUpdateOneWarehouseArgs = {
  input: WarehouseUpdateOneInput;
};


export type MutationUpdateOneWarehouseConfigurationArgs = {
  input: WarehouseConfigurationUpdateOneInput;
};


export type MutationUpdateOneWarehousePreferredUnitOfMeasureArgs = {
  input: WarehousePreferredUnitOfMeasureUpdateOneInput;
};


export type MutationUpdateOneWarehouseRoleTypeArgs = {
  input: WarehouseRoleTypeUpdateOneInput;
};


export type MutationUpdateOneZoneArgs = {
  input: ZoneUpdateOneInput;
};


export type MutationUpdatePermissionsForRoleArgs = {
  input: UpdatePermissionsForRoleDto;
};


export type MutationUpdateProxyUrlArgs = {
  proxyUrl: Scalars['String']['input'];
};


export type MutationUpdateRolesForUserGroupArgs = {
  input: UpdateRolesForUserGroupDto;
};


export type MutationUpdateUsersForUserGroupArgs = {
  input: UpdateUsersForUserGroupMappingInput;
};

export type NonCompliantBarcode = {
  __typename?: 'NonCompliantBarcode';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Delivery associated with the entity */
  deliveryCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Non compliant barcode resolution status */
  resolved: Scalars['Boolean']['output'];
  /** Raw barcode scan data */
  scanData: Scalars['String']['output'];
  /** Barcode symbology */
  symbology: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Business partner name */
  vendor?: Maybe<Scalars['String']['output']>;
  /** Business partner code */
  vendorCode?: Maybe<Scalars['String']['output']>;
};

export type NonCompliantBarcodeFilter = {
  and?: InputMaybe<Array<NonCompliantBarcodeFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  deliveryCode?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<NonCompliantBarcodeFilter>>;
  resolved?: InputMaybe<BooleanFieldComparison>;
  scanData?: InputMaybe<StringFieldComparison>;
  symbology?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  vendor?: InputMaybe<StringFieldComparison>;
  vendorCode?: InputMaybe<StringFieldComparison>;
};

export type NonCompliantBarcodeOffsetConnection = {
  __typename?: 'NonCompliantBarcodeOffsetConnection';
  /** Array of nodes. */
  nodes: Array<NonCompliantBarcode>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type NonCompliantBarcodeResolveInput = {
  /** list of ids to update */
  ids: Array<Scalars['ID']['input']>;
};

export type NonCompliantBarcodeResolveInputType = {
  resolveNonCompliantBarcodes: NonCompliantBarcodeResolveInput;
};

export type NonCompliantBarcodeSort = {
  direction: SortDirection;
  field: NonCompliantBarcodeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum NonCompliantBarcodeSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  DeliveryCode = 'deliveryCode',
  Id = 'id',
  Resolved = 'resolved',
  ScanData = 'scanData',
  Symbology = 'symbology',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  Vendor = 'vendor',
  VendorCode = 'vendorCode'
}

export type NumberFieldComparison = {
  between?: InputMaybe<NumberFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  notBetween?: InputMaybe<NumberFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float']['input'];
  upper: Scalars['Float']['input'];
};

export enum NumberFormat {
  WholeCommasFractionalPeriod = 'WHOLE_COMMAS_FRACTIONAL_PERIOD',
  WholePeriodFractionalComma = 'WHOLE_PERIOD_FRACTIONAL_COMMA',
  WholeSpaceFractionalComma = 'WHOLE_SPACE_FRACTIONAL_COMMA'
}

export type NumberFormatFilterComparison = {
  eq?: InputMaybe<NumberFormat>;
  gt?: InputMaybe<NumberFormat>;
  gte?: InputMaybe<NumberFormat>;
  iLike?: InputMaybe<NumberFormat>;
  in?: InputMaybe<Array<NumberFormat>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<NumberFormat>;
  lt?: InputMaybe<NumberFormat>;
  lte?: InputMaybe<NumberFormat>;
  neq?: InputMaybe<NumberFormat>;
  notILike?: InputMaybe<NumberFormat>;
  notIn?: InputMaybe<Array<NumberFormat>>;
  notLike?: InputMaybe<NumberFormat>;
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
};

export type OffsetPaging = {
  /** Limit the number of records returned */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Offset to start returning records from */
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderedTaskGroupResponse = {
  __typename?: 'OrderedTaskGroupResponse';
  /** Task group with steps in recommended completion order */
  orderedTaskGroupSteps: Array<OrderedTaskGroupStep>;
};

export type OrderedTaskGroupStep = {
  __typename?: 'OrderedTaskGroupStep';
  /** Identifies if a task is being started or completed */
  startOrFinish: StartOrFinish;
  /** Position in order within a task group */
  stepNumber: Scalars['Int']['output'];
  /** Entity's task ID (foreign key) */
  taskId: Scalars['ID']['output'];
  /** Task type code */
  taskTypeCode: Scalars['String']['output'];
  /** Total number of steps within a task group */
  totalSteps: Scalars['Int']['output'];
};

/** Organization model */
export type Organization = {
  __typename?: 'Organization';
  /** Entity code */
  code?: Maybe<Scalars['String']['output']>;
  companies?: Maybe<CompanyNodes>;
  contactInfo?: Maybe<ContactInfo>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  displayPreference?: Maybe<DisplayPreference>;
  displayPreferenceId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** ApiDocs */
  name: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Number of users allowed in an organization */
  userLimit: Scalars['Int']['output'];
};

export type OrganizationCreateInput = {
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ApiDocs */
  name: Scalars['String']['input'];
  /** Number of users allowed in an organization */
  userLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationCreateOneInput = {
  /** The record to create */
  organization: OrganizationCreateInput;
};

export type OrganizationFilter = {
  and?: InputMaybe<Array<OrganizationFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<OrganizationFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  userLimit?: InputMaybe<IntFieldComparison>;
};

export type OrganizationOffsetConnection = {
  __typename?: 'OrganizationOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Organization>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type OrganizationSort = {
  direction: SortDirection;
  field: OrganizationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum OrganizationSortFields {
  Code = 'code',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  UserLimit = 'userLimit'
}

export type OrganizationUpdateInput = {
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ApiDocs */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Number of users allowed in an organization */
  userLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type OrganizationUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: OrganizationUpdateInput;
};

export enum PmInventoryCategory {
  Executable = 'EXECUTABLE',
  Planned = 'PLANNED'
}

export type PmInventoryCategoryFilterComparison = {
  eq?: InputMaybe<PmInventoryCategory>;
  gt?: InputMaybe<PmInventoryCategory>;
  gte?: InputMaybe<PmInventoryCategory>;
  iLike?: InputMaybe<PmInventoryCategory>;
  in?: InputMaybe<Array<PmInventoryCategory>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<PmInventoryCategory>;
  lt?: InputMaybe<PmInventoryCategory>;
  lte?: InputMaybe<PmInventoryCategory>;
  neq?: InputMaybe<PmInventoryCategory>;
  notILike?: InputMaybe<PmInventoryCategory>;
  notIn?: InputMaybe<Array<PmInventoryCategory>>;
  notLike?: InputMaybe<PmInventoryCategory>;
};

export type Permission = {
  __typename?: 'Permission';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description: Scalars['String']['output'];
  grouping: PermissionGrouping;
  /** Entity ID */
  id: Scalars['ID']['output'];
  subject: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type PermissionDimension = {
  __typename?: 'PermissionDimension';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  field: Scalars['String']['output'];
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity ID */
  permissionId: Scalars['ID']['output'];
  /** Entity ID */
  roleId: Scalars['ID']['output'];
  subject: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  value: Scalars['String']['output'];
};

export type PermissionFilter = {
  and?: InputMaybe<Array<PermissionFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  grouping?: InputMaybe<PermissionGroupingFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<PermissionFilter>>;
  subject?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export enum PermissionGrouping {
  AgentConfiguration = 'AGENT_CONFIGURATION',
  Aisle = 'AISLE',
  AisleColumn = 'AISLE_COLUMN',
  Area = 'AREA',
  Barcode = 'BARCODE',
  Bin = 'BIN',
  BinStatus = 'BIN_STATUS',
  BinStatusMapping = 'BIN_STATUS_MAPPING',
  BinStorageType = 'BIN_STORAGE_TYPE',
  BusinessPartner = 'BUSINESS_PARTNER',
  Company = 'COMPANY',
  ContactInfo = 'CONTACT_INFO',
  Delivery = 'DELIVERY',
  DeliveryItem = 'DELIVERY_ITEM',
  DisplayPreference = 'DISPLAY_PREFERENCE',
  Disposition = 'DISPOSITION',
  Door = 'DOOR',
  Equipment = 'EQUIPMENT',
  EquipmentModel = 'EQUIPMENT_MODEL',
  EquipmentType = 'EQUIPMENT_TYPE',
  Fulfillment = 'FULFILLMENT',
  FulfillmentBlock = 'FULFILLMENT_BLOCK',
  FulfillmentItem = 'FULFILLMENT_ITEM',
  History = 'HISTORY',
  Inventory = 'INVENTORY',
  Layout = 'LAYOUT',
  LicensePlate = 'LICENSE_PLATE',
  LicensePlateStatus = 'LICENSE_PLATE_STATUS',
  LicensePlateStatusMapping = 'LICENSE_PLATE_STATUS_MAPPING',
  Lot = 'LOT',
  Map = 'MAP',
  NonCompliantBarcode = 'NON_COMPLIANT_BARCODE',
  Organization = 'ORGANIZATION',
  Page = 'PAGE',
  Permission = 'PERMISSION',
  Product = 'PRODUCT',
  Role = 'ROLE',
  RolePermissionMapping = 'ROLE_PERMISSION_MAPPING',
  SapStockStatus = 'SAP_STOCK_STATUS',
  SapStorageLocationPlant = 'SAP_STORAGE_LOCATION_PLANT',
  SlottingConfig = 'SLOTTING_CONFIG',
  SlottingDataset = 'SLOTTING_DATASET',
  SlottingRuleset = 'SLOTTING_RULESET',
  SlottingRun = 'SLOTTING_RUN',
  StockStatusType = 'STOCK_STATUS_TYPE',
  Task = 'TASK',
  TaskType = 'TASK_TYPE',
  TaskTypeBinStatusMapping = 'TASK_TYPE_BIN_STATUS_MAPPING',
  TaskTypeStockStatusMapping = 'TASK_TYPE_STOCK_STATUS_MAPPING',
  Team = 'TEAM',
  UnitOfMeasure = 'UNIT_OF_MEASURE',
  UnitOfMeasureGlossary = 'UNIT_OF_MEASURE_GLOSSARY',
  User = 'USER',
  UserGroup = 'USER_GROUP',
  UserTeamMapping = 'USER_TEAM_MAPPING',
  Warehouse = 'WAREHOUSE',
  WarehouseConfiguration = 'WAREHOUSE_CONFIGURATION',
  WarehouseOpFileRow = 'WAREHOUSE_OP_FILE_ROW',
  WarehousePreferredUom = 'WAREHOUSE_PREFERRED_UOM',
  WarehouseRoleType = 'WAREHOUSE_ROLE_TYPE',
  Zone = 'ZONE'
}

export type PermissionGroupingFilterComparison = {
  eq?: InputMaybe<PermissionGrouping>;
  gt?: InputMaybe<PermissionGrouping>;
  gte?: InputMaybe<PermissionGrouping>;
  iLike?: InputMaybe<PermissionGrouping>;
  in?: InputMaybe<Array<PermissionGrouping>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<PermissionGrouping>;
  lt?: InputMaybe<PermissionGrouping>;
  lte?: InputMaybe<PermissionGrouping>;
  neq?: InputMaybe<PermissionGrouping>;
  notILike?: InputMaybe<PermissionGrouping>;
  notIn?: InputMaybe<Array<PermissionGrouping>>;
  notLike?: InputMaybe<PermissionGrouping>;
};

export type PermissionOffsetConnection = {
  __typename?: 'PermissionOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Permission>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type PermissionSort = {
  direction: SortDirection;
  field: PermissionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum PermissionSortFields {
  Code = 'code',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  Grouping = 'grouping',
  Id = 'id',
  Subject = 'subject',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

/** Product model */
export type Product = {
  __typename?: 'Product';
  /** Entity code */
  code: Scalars['String']['output'];
  company?: Maybe<Company>;
  /** Entity's company ID (foreign key) */
  companyId: Scalars['ID']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** When data was created in ERP */
  erpCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** When data was last updated in ERP */
  erpUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Gross weight of entity */
  grossWeight?: Maybe<Scalars['Float']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Whether the product is lot managed or not */
  lotManaged?: Maybe<Scalars['Boolean']['output']>;
  lots?: Maybe<LotNodes>;
  /** Name of product */
  name?: Maybe<Scalars['String']['output']>;
  /** Net weight of entity */
  netWeight?: Maybe<Scalars['Float']['output']>;
  /** Status of product */
  status?: Maybe<ProductAvailability>;
  /** Entity's total weight in the unit of measure */
  totalWeightUOMId?: Maybe<Scalars['String']['output']>;
  /** Type of product */
  type?: Maybe<Scalars['String']['output']>;
  unitOfMeasures?: Maybe<UnitOfMeasureProductConversionNodes>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Volume of entity */
  volume?: Maybe<Scalars['Float']['output']>;
  /** Volume in unit of measure for entity */
  volumeUOMId?: Maybe<Scalars['String']['output']>;
};

export enum ProductAvailability {
  Available = 'available',
  Blocked = 'blocked'
}

export type ProductAvailabilityFilterComparison = {
  eq?: InputMaybe<ProductAvailability>;
  gt?: InputMaybe<ProductAvailability>;
  gte?: InputMaybe<ProductAvailability>;
  iLike?: InputMaybe<ProductAvailability>;
  in?: InputMaybe<Array<ProductAvailability>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<ProductAvailability>;
  lt?: InputMaybe<ProductAvailability>;
  lte?: InputMaybe<ProductAvailability>;
  neq?: InputMaybe<ProductAvailability>;
  notILike?: InputMaybe<ProductAvailability>;
  notIn?: InputMaybe<Array<ProductAvailability>>;
  notLike?: InputMaybe<ProductAvailability>;
};

export type ProductCreateInput = {
  /** Entity code */
  code: Scalars['String']['input'];
  /** Entity's company ID (foreign key) */
  companyId: Scalars['ID']['input'];
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** When data was created in ERP */
  erpCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** When data was last updated in ERP */
  erpUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Gross weight of entity */
  grossWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Whether the product is lot managed or not */
  lotManaged?: InputMaybe<Scalars['Boolean']['input']>;
  /** Name of product */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Net weight of entity */
  netWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Status of product */
  status?: InputMaybe<ProductAvailability>;
  /** Entity's total weight in the unit of measure */
  totalWeightUOMId?: InputMaybe<Scalars['String']['input']>;
  /** Type of product */
  type?: InputMaybe<Scalars['String']['input']>;
  /** Volume of entity */
  volume?: InputMaybe<Scalars['Float']['input']>;
  /** Volume in unit of measure for entity */
  volumeUOMId?: InputMaybe<Scalars['String']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['input'];
};

export type ProductCreateOneInput = {
  /** The record to create */
  product: ProductCreateInput;
};

export type ProductFilter = {
  and?: InputMaybe<Array<ProductFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  companyId?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  erpCreatedAt?: InputMaybe<DateFieldComparison>;
  erpUpdatedAt?: InputMaybe<DateFieldComparison>;
  grossWeight?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lotManaged?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  netWeight?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<ProductFilter>>;
  status?: InputMaybe<ProductAvailabilityFilterComparison>;
  totalWeightUOMId?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  volume?: InputMaybe<FloatFieldComparison>;
  volumeUOMId?: InputMaybe<StringFieldComparison>;
};

/** Product Movement model */
export type ProductMovement = {
  __typename?: 'ProductMovement';
  /** Entity's bin ID (foreign key) */
  binId: Scalars['ID']['output'];
  category: InventoryCategory;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  deliveryItemId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  fulfillmentItemId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity's user ID (foreign key) */
  licensePlateId?: Maybe<Scalars['ID']['output']>;
  /** Entity's lot ID (foreign key) */
  lotId?: Maybe<Scalars['ID']['output']>;
  /** Entity's product ID (foreign key) */
  productId: Scalars['ID']['output'];
  /** Quantity of product moved */
  quantity: Scalars['String']['output'];
  /** Entity's stock status type ID (foreign key) */
  stockStatusTypeId?: Maybe<Scalars['ID']['output']>;
  /** Entity's task ID (foreign key) */
  taskId: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity's user ID (foreign key) */
  userId: Scalars['ID']['output'];
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
};

export type ProductNodes = {
  __typename?: 'ProductNodes';
  nodes: Array<Product>;
};

export type ProductOffsetConnection = {
  __typename?: 'ProductOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Product>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ProductQueryShape = {
  __typename?: 'ProductQueryShape';
  /** Entity code */
  baseUOMCode?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  baseUOMId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  code: Scalars['String']['output'];
  /** Entity's company ID (foreign key) */
  companyId: Scalars['ID']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** When data was created in ERP */
  erpCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** When data was last updated in ERP */
  erpUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Gross weight of entity */
  grossWeight?: Maybe<Scalars['Float']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Whether the product is lot managed or not */
  lotManaged?: Maybe<Scalars['Boolean']['output']>;
  /** Name of product */
  name?: Maybe<Scalars['String']['output']>;
  /** Net weight of entity */
  netWeight?: Maybe<Scalars['Float']['output']>;
  /** Status of product */
  status?: Maybe<ProductAvailability>;
  /** Volume in unit of measure for entity */
  totalWeightUOMCode?: Maybe<Scalars['String']['output']>;
  /** Entity's total weight in the unit of measure */
  totalWeightUOMId?: Maybe<Scalars['String']['output']>;
  /** Volume in unit of measure for entity */
  totalWeightUOMLabel?: Maybe<Scalars['String']['output']>;
  /** Type of product */
  type?: Maybe<Scalars['String']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Volume of entity */
  volume?: Maybe<Scalars['Float']['output']>;
  /** Volume in unit of measure for entity */
  volumeUOMCode?: Maybe<Scalars['String']['output']>;
  /** Volume in unit of measure for entity */
  volumeUOMId?: Maybe<Scalars['String']['output']>;
  /** Volume in unit of measure for entity */
  volumeUOMLabel?: Maybe<Scalars['String']['output']>;
};

export type ProductQueryShapeFilter = {
  and?: InputMaybe<Array<ProductQueryShapeFilter>>;
  baseUOMCode?: InputMaybe<StringFieldComparison>;
  baseUOMId?: InputMaybe<IdFilterComparison>;
  code?: InputMaybe<StringFieldComparison>;
  companyId?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  erpCreatedAt?: InputMaybe<DateFieldComparison>;
  erpUpdatedAt?: InputMaybe<DateFieldComparison>;
  grossWeight?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lotManaged?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  netWeight?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<ProductQueryShapeFilter>>;
  status?: InputMaybe<ProductAvailabilityFilterComparison>;
  totalWeightUOMCode?: InputMaybe<StringFieldComparison>;
  totalWeightUOMId?: InputMaybe<StringFieldComparison>;
  totalWeightUOMLabel?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  volume?: InputMaybe<FloatFieldComparison>;
  volumeUOMCode?: InputMaybe<StringFieldComparison>;
  volumeUOMId?: InputMaybe<StringFieldComparison>;
  volumeUOMLabel?: InputMaybe<StringFieldComparison>;
};

export type ProductQueryShapeOffsetConnection = {
  __typename?: 'ProductQueryShapeOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ProductQueryShape>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ProductQueryShapeSort = {
  direction: SortDirection;
  field: ProductQueryShapeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ProductQueryShapeSortFields {
  BaseUomCode = 'baseUOMCode',
  BaseUomId = 'baseUOMId',
  Code = 'code',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Description = 'description',
  ErpCreatedAt = 'erpCreatedAt',
  ErpUpdatedAt = 'erpUpdatedAt',
  GrossWeight = 'grossWeight',
  Id = 'id',
  LotManaged = 'lotManaged',
  Name = 'name',
  NetWeight = 'netWeight',
  Status = 'status',
  TotalWeightUomCode = 'totalWeightUOMCode',
  TotalWeightUomId = 'totalWeightUOMId',
  TotalWeightUomLabel = 'totalWeightUOMLabel',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Volume = 'volume',
  VolumeUomCode = 'volumeUOMCode',
  VolumeUomId = 'volumeUOMId',
  VolumeUomLabel = 'volumeUOMLabel'
}

/** Product Search results */
export type ProductSearchResults = {
  __typename?: 'ProductSearchResults';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  erpUpdatedAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  index?: Maybe<Scalars['String']['output']>;
  lotManaged?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ProductSort = {
  direction: SortDirection;
  field: ProductSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ProductSortFields {
  Code = 'code',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  ErpCreatedAt = 'erpCreatedAt',
  ErpUpdatedAt = 'erpUpdatedAt',
  GrossWeight = 'grossWeight',
  Id = 'id',
  LotManaged = 'lotManaged',
  Name = 'name',
  NetWeight = 'netWeight',
  Status = 'status',
  TotalWeightUomId = 'totalWeightUOMId',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  Volume = 'volume',
  VolumeUomId = 'volumeUOMId'
}

export type ProductUpdateInput = {
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Entity's company ID (foreign key) */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** When data was created in ERP */
  erpCreatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** When data was last updated in ERP */
  erpUpdatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Gross weight of entity */
  grossWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Whether the product is lot managed or not */
  lotManaged?: InputMaybe<Scalars['Boolean']['input']>;
  /** Name of product */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Net weight of entity */
  netWeight?: InputMaybe<Scalars['Float']['input']>;
  /** Status of product */
  status?: InputMaybe<ProductAvailability>;
  /** Entity's total weight in the unit of measure */
  totalWeightUOMId?: InputMaybe<Scalars['String']['input']>;
  /** Type of product */
  type?: InputMaybe<Scalars['String']['input']>;
  /** Volume of entity */
  volume?: InputMaybe<Scalars['Float']['input']>;
  /** Volume in unit of measure for entity */
  volumeUOMId?: InputMaybe<Scalars['String']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProductUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: ProductUpdateInput;
};

/** Proxy configuration model */
export type ProxyConfig = {
  __typename?: 'ProxyConfig';
  /** Agent configurations */
  agents: Array<AgentConfiguration>;
  /** Proxy URL */
  proxyUrl?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  adminTaskType: AdminTaskType;
  /** Get all agent configurations */
  agentConfigurations: ProxyConfig;
  aisle: Aisle;
  aisleColumn: AisleColumn;
  aisleColumns: AisleColumnOffsetConnection;
  aisles: AisleOffsetConnection;
  allBarcodeEntities: Array<AllBarcodeEntities>;
  area: Area;
  areas: AreaOffsetConnection;
  barcode: Barcode;
  barcodeMapping: BarcodeMapping;
  barcodeMappings: BarcodeMappingOffsetConnection;
  barcodeTemplate: BarcodeTemplate;
  barcodeTemplateMapping: BarcodeTemplateMapping;
  barcodeTemplateMappings: BarcodeTemplateMappingOffsetConnection;
  barcodeTemplates: BarcodeTemplateOffsetConnection;
  barcodes: BarcodeOffsetConnection;
  baseInventory: InventoryBaseQueryShapeOffsetConnection;
  baseInventoryAll: InventoryBaseQueryShapeOffsetConnection;
  bin: Bin;
  binStatus: BinStatus;
  binStatusMapping: BinStatusMapping;
  binStatusMappings: BinStatusMappingOffsetConnection;
  binStatuses: BinStatusOffsetConnection;
  binStorageType: BinStorageType;
  binStorageTypes: BinStorageTypeOffsetConnection;
  bins: BinOffsetConnection;
  binsForPickTask: Array<BinForPickTask>;
  businessPartner: BusinessPartner;
  businessPartners: BusinessPartnerOffsetConnection;
  companies: CompanyOffsetConnection;
  company: Company;
  contactInfo: ContactInfo;
  contactInfos: ContactInfoOffsetConnection;
  convertQuantityToAllUoM: UnitOfMeasureConversionResource;
  deliveries: DeliveryOffsetConnection;
  delivery: Delivery;
  deliveryItem: DeliveryItem;
  deliveryItems: DeliveryItemOffsetConnection;
  deliveryItemsToPutaway: Array<DeliveryItemDetails>;
  deliveryItemsToUnload: Array<DeliveryItemDetails>;
  deployedRun?: Maybe<SlottingRun>;
  /** returns a list of valid destination bins by task type */
  destinationBinsForTaskType: TaskTypeBinStatusDestinationBins;
  displayPreference: DisplayPreference;
  displayPreferences: DisplayPreferenceOffsetConnection;
  disposition: Disposition;
  dispositions: DispositionOffsetConnection;
  door: Door;
  doors: DoorOffsetConnection;
  equipmentItem: EquipmentItem;
  equipmentItems: EquipmentItemOffsetConnection;
  equipmentModel: EquipmentModel;
  equipmentModels: EquipmentModelOffsetConnection;
  equipmentType: EquipmentType;
  equipmentTypes: EquipmentTypeOffsetConnection;
  /** count products by partial string. */
  esCountQuery: Scalars['Int']['output'];
  /** Search products by partial string. */
  esSearchQuery: GlobalSearchResultWithAggs;
  ewmDeliveryFromDetails: EwmDelivery;
  ewmDeliveryFromScan: EwmDelivery;
  ewmWarehouseTasks: EwmWarehouseTaskOffsetConnection;
  fulfillmentBlock: FulfillmentBlock;
  fulfillmentBlocks: FulfillmentBlockOffsetConnection;
  fulfillmentItem: FulfillmentItem;
  fulfillmentItems: FulfillmentItemOffsetConnection;
  fulfillmentItemsToLoad: Array<FulfillmentItemDetails>;
  fulfillmentItemsToPick: Array<FulfillmentItemDetails>;
  /** Get directions for shortest path for several picks */
  getDirections: Array<Array<Array<Scalars['Float']['output']>>>;
  /** Get the warehouse map */
  getMap: Scalars['JSON']['output'];
  /** Get warehouse mapping data by shipping point */
  getSapWarehouseMapping: Array<SapWarehouseMapping>;
  /** Get slotting suggestions */
  getSlotting: Scalars['JSON']['output'];
  getTaskTypeAnalytics: TaskTypeAnalyticsResponse;
  layout: Layout;
  layouts: LayoutOffsetConnection;
  licensePlate: LicensePlate;
  licensePlateDetails: LicensePlateDetailOffsetConnection;
  licensePlateDetailsAllBins: LicensePlateDetailOffsetConnection;
  licensePlateStatus: LicensePlateStatus;
  licensePlateStatusMapping: LicensePlateStatusMapping;
  licensePlateStatusMappings: LicensePlateStatusMappingOffsetConnection;
  licensePlateStatuses: LicensePlateStatusOffsetConnection;
  licensePlates: LicensePlateOffsetConnection;
  licensePlatesForPickTask: Array<LicensePlateForPickTask>;
  lot: Lot;
  lotInventory: InventoryLotQueryShapeOffsetConnection;
  lots: LotOffsetConnection;
  map: Map;
  maps: MapOffsetConnection;
  mobileConfiguration: MobileConfigurationModel;
  mobileMotd: MobileMotdModel;
  mobileViewTasks: MobileViewTaskOffsetConnection;
  nonCompliantBarcodes: NonCompliantBarcodeOffsetConnection;
  orderStepsForTaskGroup: OrderedTaskGroupResponse;
  organization: Organization;
  organizations: OrganizationOffsetConnection;
  permissions: PermissionOffsetConnection;
  permittedWarehouses: Array<UserPermittedWarehousesResponseDto>;
  product: Product;
  productInventory: InventoryProductQueryShapeOffsetConnection;
  products: ProductOffsetConnection;
  readByCode: StockStatusType;
  /** Reads a fulfillment by provided delivery code.  If the fulfillment is not within the fulfilld system it is retrieved from SAP */
  readByDeliveryCode: Scalars['String']['output'];
  /** Reads a delivery by provided delivery code.  If the delivery is not within the fulfilld system it is retrieved from SAP. */
  readDeliveryByDeliveryCode: Delivery;
  replenishment: Replenishment;
  replenishments: ReplenishmentOffsetConnection;
  role: Role;
  rolePermissionMappings: RolePermissionMappingOffsetConnection;
  roles: RoleOffsetConnection;
  salesOrder: SapSalesOrder;
  salesOrderItems: Array<SapSalesOrderItem>;
  sapDatasetVariants: SlottingDatasetVariants;
  sapStockStatusByCode: SapStockStatusType;
  sapStockStatusType: SapStockStatusType;
  sapStockStatusTypes: SapStockStatusTypeOffsetConnection;
  sapStorageLocationPlant: SapStorageLocationPlant;
  sapStorageLocationPlants: SapStorageLocationPlantOffsetConnection;
  sapVariantPlantSlocWarehouse: SlottingDatasetVariantPlantSlocWh;
  scheduledJob: ScheduledJob;
  scheduledJobType: ScheduledJobType;
  scheduledJobTypes: ScheduledJobTypeOffsetConnection;
  scheduledJobs: ScheduledJobOffsetConnection;
  slottingAbcAnalysis: SlottingAbcAnalysisOffsetConnection;
  slottingBinResultsV1: SlottingBinResultV1OffsetConnection;
  slottingConfiguration: SlottingConfiguration;
  slottingConfigurationAbcAnalysis: SlottingConfigurationAbcAnalysisOffsetConnection;
  slottingConfigurationV1: SlottingConfigurationV1;
  slottingConfigurations: SlottingConfigurationOffsetConnection;
  slottingConfigurationsV1: SlottingConfigurationV1OffsetConnection;
  slottingConfigurationsView: SlottingConfigurationViewOffsetConnection;
  slottingConfigurationsViewV1: SlottingConfigurationViewV1OffsetConnection;
  slottingDataset: SlottingDataset;
  slottingDatasets: SlottingDatasetOffsetConnection;
  slottingInventory: SlottingInventoryOffsetConnection;
  slottingMovements: SlottingMovementOffsetConnection;
  slottingMovementsV1: SlottingMovementV1OffsetConnection;
  slottingPepsiMovements: SlottingMovementPepsiOffsetConnection;
  slottingRuleset: SlottingRuleset;
  slottingRulesets: SlottingRulesetOffsetConnection;
  slottingRun: SlottingRun;
  slottingRuns: SlottingRunOffsetConnection;
  slottingRunsView: SlottingConfigurationRunViewOffsetConnection;
  slottingRunsViewV1: SlottingConfigurationRunViewV1OffsetConnection;
  slottingSetting: SlottingSetting;
  slottingSettingItem: SlottingSettingItem;
  slottingSettingItems: SlottingSettingItemOffsetConnection;
  slottingSettings: SlottingSettingOffsetConnection;
  slottingSuggestedMaterialPlacement: SlottingSuggestedMaterialPlacementOffsetConnection;
  smartScan: SmartScanResource;
  /** returns a list of valid bins by task type */
  sourceBinsForTaskType: TaskTypeBinStatusSourceBins;
  stockStatusType: StockStatusType;
  stockStatusTypes: StockStatusTypeOffsetConnection;
  supportTicket: Scalars['String']['output'];
  supportTickets: Scalars['String']['output'];
  task: Task;
  taskGroup: TaskGroup;
  taskGroups: TaskGroupOffsetConnection;
  taskType: TaskType;
  taskTypes: TaskTypeOffsetConnection;
  tasks: TaskOffsetConnection;
  team: Team;
  teams: TeamOffsetConnection;
  unitOfMeasureGlossaries: UnitOfMeasureGlossaryOffsetConnection;
  unitOfMeasureGlossary: UnitOfMeasureGlossary;
  unitOfMeasureProductConversion: UnitOfMeasureProductConversion;
  unitOfMeasureProductConversions: UnitOfMeasureProductConversionOffsetConnection;
  unitOfMeasureWarehousePreference: Array<UnitOfMeasureGlossary>;
  user: User;
  userGroupMappings: UserGroupMappingOffsetConnection;
  userGroups: UserGroupOffsetConnection;
  userPermissions: Array<UserPermissionsResponseDto>;
  userTeamMapping: UserTeamMapping;
  userTeamMappings: UserTeamMappingOffsetConnection;
  userWarehouseDefault: UserWarehouseDefault;
  userWarehouseDefaults: UserWarehouseDefaultOffsetConnection;
  users: UserOffsetConnection;
  validateTask: TaskValidationErrorModel;
  /** @deprecated obsolete - move to validateTask */
  validateTaskType: TaskValidationErrorModel;
  viewAreas: ViewAreaOffsetConnection;
  viewBarcodes: ViewBarcodeOffsetConnection;
  viewBinStorageTypes: ViewBinStorageTypeOffsetConnection;
  viewBins: ViewBinOffsetConnection;
  viewDeliveries: ViewDeliveryOffsetConnection;
  viewDelivery: ViewDelivery;
  viewDeliveryItem: ViewDeliveryItem;
  viewDeliveryItems: ViewDeliveryItemOffsetConnection;
  viewDoors: ViewDoorOffsetConnection;
  viewEquipmentModels: ViewEquipmentModelOffsetConnection;
  viewEquipmentTypes: EquipmentTypeQueryShapeOffsetConnection;
  viewEquipments: ViewEquipmentOffsetConnection;
  viewFulfillmentItem: ViewFulfillmentItem;
  viewFulfillmentItems: ViewFulfillmentItemOffsetConnection;
  viewHistoryFieldDiffs: ViewHistoryFieldDiffOffsetConnection;
  viewLostAndFoundInventory: ViewLostAndFoundOffsetConnection;
  viewLots: ViewLotOffsetConnection;
  viewMapAisles: ViewMapAisleOffsetConnection;
  viewMapBins: ViewMapBinOffsetConnection;
  viewProducts: ProductQueryShapeOffsetConnection;
  viewSlottingRulesets: ViewSlottingRulesetOffsetConnection;
  viewSlottingSettings: ViewSlottingSettingOffsetConnection;
  viewStockStatuses: ViewStockStatusOffsetConnection;
  viewTaskGroups: ViewTaskGroupOffsetConnection;
  viewTaskTypeBinStatusMappings: ViewTaskTypeBinStatusMappingOffsetConnection;
  viewTaskTypeStockStatusMappings: ViewTaskTypeStockStatusMappingOffsetConnection;
  viewTasks: ViewTaskOffsetConnection;
  viewUnitOfMeasureProductConversions: ViewUnitOfMeasureProductConversionOffsetConnection;
  viewUserGroups: ViewUserGroupOffsetConnection;
  viewUsers: ViewUserOffsetConnection;
  viewWarehouseRoleTypes: WarehouseRoleTypeQueryShapeOffsetConnection;
  viewZoneAisleColumns: ViewZoneAisleColumnOffsetConnection;
  viewZoneAisles: ViewZoneAisleOffsetConnection;
  viewZoneAreas: ViewZoneAreaOffsetConnection;
  viewZoneBinsMapped: ViewZoneBinMappedOffsetConnection;
  viewZoneBinsUnmapped: ViewZoneBinUnmappedOffsetConnection;
  viewZones: ViewZoneOffsetConnection;
  warehouse: Warehouse;
  warehouseConfiguration: WarehouseConfiguration;
  warehouseOpFile: WarehouseOpFile;
  warehouseOpFileRow: WarehouseOpFileRow;
  warehouseOpFileRows: WarehouseOpFileRowOffsetConnection;
  warehouseOpFiles: WarehouseOpFileOffsetConnection;
  warehousePreferredUnitOfMeasure: WarehousePreferredUnitOfMeasure;
  warehousePreferredUnitOfMeasures: WarehousePreferredUnitOfMeasureOffsetConnection;
  warehouseRoleType: WarehouseRoleType;
  warehouseRoleTypes: WarehouseRoleTypeOffsetConnection;
  warehouses: WarehouseOffsetConnection;
  zone: Zone;
  zoneRule: ZoneRule;
  zoneRuleMapping: ZoneRuleMapping;
  zoneRuleMappings: ZoneRuleMappingOffsetConnection;
  zoneRules: ZoneRuleOffsetConnection;
  zones: ZoneOffsetConnection;
};


export type QueryAdminTaskTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAisleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAisleColumnArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAisleColumnsArgs = {
  filter?: InputMaybe<AisleColumnFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<AisleColumnSort>>;
};


export type QueryAislesArgs = {
  filter?: InputMaybe<AisleFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<AisleSort>>;
};


export type QueryAreaArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAreasArgs = {
  filter?: InputMaybe<AreaFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<AreaSort>>;
};


export type QueryBarcodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBarcodeMappingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBarcodeMappingsArgs = {
  filter?: InputMaybe<BarcodeMappingFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<BarcodeMappingSort>>;
};


export type QueryBarcodeTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBarcodeTemplateMappingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBarcodeTemplateMappingsArgs = {
  filter?: InputMaybe<BarcodeTemplateMappingFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<BarcodeTemplateMappingSort>>;
};


export type QueryBarcodeTemplatesArgs = {
  filter?: InputMaybe<BarcodeTemplateFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<BarcodeTemplateSort>>;
};


export type QueryBarcodesArgs = {
  filter?: InputMaybe<BarcodeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<BarcodeSort>>;
};


export type QueryBaseInventoryArgs = {
  filter?: InputMaybe<InventoryBaseQueryShapeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<InventoryBaseQueryShapeSort>>;
};


export type QueryBaseInventoryAllArgs = {
  filter?: InputMaybe<InventoryBaseQueryShapeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<InventoryBaseQueryShapeSort>>;
};


export type QueryBinArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBinStatusArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBinStatusMappingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBinStatusMappingsArgs = {
  filter?: InputMaybe<BinStatusMappingFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<BinStatusMappingSort>>;
};


export type QueryBinStatusesArgs = {
  filter?: InputMaybe<BinStatusFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<BinStatusSort>>;
};


export type QueryBinStorageTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBinStorageTypesArgs = {
  filter?: InputMaybe<BinStorageTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<BinStorageTypeSort>>;
};


export type QueryBinsArgs = {
  filter?: InputMaybe<BinFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<BinSort>>;
};


export type QueryBinsForPickTaskArgs = {
  taskId: Scalars['ID']['input'];
};


export type QueryBusinessPartnerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBusinessPartnersArgs = {
  filter?: InputMaybe<BusinessPartnerFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<BusinessPartnerSort>>;
};


export type QueryCompaniesArgs = {
  filter?: InputMaybe<CompanyFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<CompanySort>>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContactInfoArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContactInfosArgs = {
  filter?: InputMaybe<ContactInfoFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ContactInfoSort>>;
};


export type QueryConvertQuantityToAllUoMArgs = {
  quantity: Scalars['String']['input'];
  unitOfMeasureId: Scalars['ID']['input'];
};


export type QueryDeliveriesArgs = {
  filter?: InputMaybe<DeliveryFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<DeliverySort>>;
};


export type QueryDeliveryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDeliveryItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDeliveryItemsArgs = {
  filter?: InputMaybe<DeliveryItemFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<DeliveryItemSort>>;
};


export type QueryDeliveryItemsToPutawayArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDeliveryItemsToUnloadArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDestinationBinsForTaskTypeArgs = {
  filter?: InputMaybe<TaskTypeBinStatusBinsQueryDtoFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<TaskTypeBinStatusBinsQueryDtoSort>>;
  taskTypeCode?: InputMaybe<Scalars['String']['input']>;
  taskTypeId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDisplayPreferenceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDisplayPreferencesArgs = {
  filter?: InputMaybe<DisplayPreferenceFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<DisplayPreferenceSort>>;
};


export type QueryDispositionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDispositionsArgs = {
  filter?: InputMaybe<DispositionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<DispositionSort>>;
};


export type QueryDoorArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDoorsArgs = {
  filter?: InputMaybe<DoorFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<DoorSort>>;
};


export type QueryEquipmentItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEquipmentItemsArgs = {
  filter?: InputMaybe<EquipmentItemFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<EquipmentItemSort>>;
};


export type QueryEquipmentModelArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEquipmentModelsArgs = {
  filter?: InputMaybe<EquipmentModelFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<EquipmentModelSort>>;
};


export type QueryEquipmentTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEquipmentTypesArgs = {
  filter?: InputMaybe<EquipmentTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<EquipmentTypeSort>>;
};


export type QueryEsCountQueryArgs = {
  count: CountQuery;
};


export type QueryEsSearchQueryArgs = {
  search: SearchQuery;
};


export type QueryEwmDeliveryFromDetailsArgs = {
  input: Scalars['String']['input'];
  type: EwmDeliveryDetailOption;
};


export type QueryEwmDeliveryFromScanArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>;
  payload: Scalars['JSONObject']['input'];
  scanner: BarcodeScanner;
};


export type QueryEwmWarehouseTasksArgs = {
  filter?: InputMaybe<EwmWarehouseTaskFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<EwmWarehouseTaskSort>>;
};


export type QueryFulfillmentBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFulfillmentBlocksArgs = {
  filter?: InputMaybe<FulfillmentBlockFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<FulfillmentBlockSort>>;
};


export type QueryFulfillmentItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFulfillmentItemsArgs = {
  filter?: InputMaybe<FulfillmentItemFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<FulfillmentItemSort>>;
};


export type QueryFulfillmentItemsToLoadArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFulfillmentItemsToPickArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetDirectionsArgs = {
  mapping: MapQuery;
};


export type QueryGetSlottingArgs = {
  createSlottingConfig: SlottingQuery;
};


export type QueryGetTaskTypeAnalyticsArgs = {
  filter: TaskTypeAnalyticsFilter;
};


export type QueryLayoutArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLayoutsArgs = {
  filter?: InputMaybe<LayoutFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<LayoutSort>>;
};


export type QueryLicensePlateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLicensePlateDetailsArgs = {
  filter?: InputMaybe<LicensePlateDetailFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<LicensePlateDetailSort>>;
};


export type QueryLicensePlateDetailsAllBinsArgs = {
  filter?: InputMaybe<LicensePlateDetailFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<LicensePlateDetailSort>>;
};


export type QueryLicensePlateStatusArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLicensePlateStatusMappingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLicensePlateStatusMappingsArgs = {
  filter?: InputMaybe<LicensePlateStatusMappingFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<LicensePlateStatusMappingSort>>;
};


export type QueryLicensePlateStatusesArgs = {
  filter?: InputMaybe<LicensePlateStatusFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<LicensePlateStatusSort>>;
};


export type QueryLicensePlatesArgs = {
  filter?: InputMaybe<LicensePlateFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<LicensePlateSort>>;
};


export type QueryLicensePlatesForPickTaskArgs = {
  taskId: Scalars['ID']['input'];
};


export type QueryLotArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLotInventoryArgs = {
  filter?: InputMaybe<InventoryLotQueryShapeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<InventoryLotQueryShapeSort>>;
};


export type QueryLotsArgs = {
  filter?: InputMaybe<LotFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<LotSort>>;
};


export type QueryMapArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMapsArgs = {
  filter?: InputMaybe<MapFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<MapSort>>;
};


export type QueryMobileConfigurationArgs = {
  context?: InputMaybe<FeatureContext>;
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryMobileViewTasksArgs = {
  filter?: InputMaybe<MobileViewTaskFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<MobileViewTaskSort>>;
};


export type QueryNonCompliantBarcodesArgs = {
  filter?: InputMaybe<NonCompliantBarcodeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<NonCompliantBarcodeSort>>;
};


export type QueryOrderStepsForTaskGroupArgs = {
  binCode: Scalars['String']['input'];
  taskGroupId: Scalars['ID']['input'];
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrganizationsArgs = {
  filter?: InputMaybe<OrganizationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<OrganizationSort>>;
};


export type QueryPermissionsArgs = {
  filter?: InputMaybe<PermissionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<PermissionSort>>;
};


export type QueryProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductInventoryArgs = {
  filter?: InputMaybe<InventoryProductQueryShapeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<InventoryProductQueryShapeSort>>;
};


export type QueryProductsArgs = {
  filter?: InputMaybe<ProductFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ProductSort>>;
};


export type QueryReadByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryReadByDeliveryCodeArgs = {
  deliveryCode: Scalars['String']['input'];
};


export type QueryReadDeliveryByDeliveryCodeArgs = {
  deliveryCode: Scalars['String']['input'];
};


export type QueryReplenishmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReplenishmentsArgs = {
  filter?: InputMaybe<ReplenishmentFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ReplenishmentSort>>;
};


export type QueryRoleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRolePermissionMappingsArgs = {
  filter?: InputMaybe<RolePermissionMappingFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<RolePermissionMappingSort>>;
};


export type QueryRolesArgs = {
  filter?: InputMaybe<RoleFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<RoleSort>>;
};


export type QuerySalesOrderArgs = {
  salesOrderNumber: Scalars['String']['input'];
};


export type QuerySalesOrderItemsArgs = {
  salesOrderNumber: Scalars['String']['input'];
};


export type QuerySapStockStatusByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QuerySapStockStatusTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySapStockStatusTypesArgs = {
  filter?: InputMaybe<SapStockStatusTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SapStockStatusTypeSort>>;
};


export type QuerySapStorageLocationPlantArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySapStorageLocationPlantsArgs = {
  filter?: InputMaybe<SapStorageLocationPlantFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SapStorageLocationPlantSort>>;
};


export type QuerySapVariantPlantSlocWarehouseArgs = {
  variantName: Scalars['String']['input'];
};


export type QueryScheduledJobArgs = {
  id: Scalars['ID']['input'];
};


export type QueryScheduledJobTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryScheduledJobTypesArgs = {
  filter?: InputMaybe<ScheduledJobTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ScheduledJobTypeSort>>;
};


export type QueryScheduledJobsArgs = {
  filter?: InputMaybe<ScheduledJobFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ScheduledJobSort>>;
};


export type QuerySlottingAbcAnalysisArgs = {
  filter?: InputMaybe<SlottingAbcAnalysisFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingAbcAnalysisSort>>;
};


export type QuerySlottingBinResultsV1Args = {
  filter?: InputMaybe<SlottingBinResultV1Filter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingBinResultV1Sort>>;
};


export type QuerySlottingConfigurationArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySlottingConfigurationAbcAnalysisArgs = {
  filter?: InputMaybe<SlottingConfigurationAbcAnalysisFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingConfigurationAbcAnalysisSort>>;
};


export type QuerySlottingConfigurationV1Args = {
  id: Scalars['ID']['input'];
};


export type QuerySlottingConfigurationsArgs = {
  filter?: InputMaybe<SlottingConfigurationFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingConfigurationSort>>;
};


export type QuerySlottingConfigurationsV1Args = {
  filter?: InputMaybe<SlottingConfigurationV1Filter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingConfigurationV1Sort>>;
};


export type QuerySlottingConfigurationsViewArgs = {
  filter?: InputMaybe<SlottingConfigurationViewFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingConfigurationViewSort>>;
};


export type QuerySlottingConfigurationsViewV1Args = {
  filter?: InputMaybe<SlottingConfigurationViewV1Filter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingConfigurationViewV1Sort>>;
};


export type QuerySlottingDatasetArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySlottingDatasetsArgs = {
  filter?: InputMaybe<SlottingDatasetFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingDatasetSort>>;
};


export type QuerySlottingInventoryArgs = {
  filter?: InputMaybe<SlottingInventoryFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingInventorySort>>;
};


export type QuerySlottingMovementsArgs = {
  filter?: InputMaybe<SlottingMovementFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingMovementSort>>;
};


export type QuerySlottingMovementsV1Args = {
  filter?: InputMaybe<SlottingMovementV1Filter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingMovementV1Sort>>;
};


export type QuerySlottingPepsiMovementsArgs = {
  filter?: InputMaybe<SlottingMovementPepsiFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingMovementPepsiSort>>;
};


export type QuerySlottingRulesetArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySlottingRulesetsArgs = {
  filter?: InputMaybe<SlottingRulesetFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingRulesetSort>>;
};


export type QuerySlottingRunArgs = {
  runId: Scalars['String']['input'];
};


export type QuerySlottingRunsArgs = {
  filter?: InputMaybe<SlottingRunFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingRunSort>>;
};


export type QuerySlottingRunsViewArgs = {
  filter?: InputMaybe<SlottingConfigurationRunViewFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingConfigurationRunViewSort>>;
};


export type QuerySlottingRunsViewV1Args = {
  filter?: InputMaybe<SlottingConfigurationRunViewV1Filter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingConfigurationRunViewV1Sort>>;
};


export type QuerySlottingSettingArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySlottingSettingItemArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySlottingSettingItemsArgs = {
  filter?: InputMaybe<SlottingSettingItemFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingSettingItemSort>>;
};


export type QuerySlottingSettingsArgs = {
  filter?: InputMaybe<SlottingSettingFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingSettingSort>>;
};


export type QuerySlottingSuggestedMaterialPlacementArgs = {
  filter?: InputMaybe<SlottingSuggestedMaterialPlacementFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<SlottingSuggestedMaterialPlacementSort>>;
};


export type QuerySmartScanArgs = {
  payload: Scalars['String']['input'];
  smartScanContext: SmartScanContext;
};


export type QuerySourceBinsForTaskTypeArgs = {
  filter?: InputMaybe<TaskTypeBinStatusBinsQueryDtoFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<TaskTypeBinStatusBinsQueryDtoSort>>;
  taskTypeCode?: InputMaybe<Scalars['String']['input']>;
  taskTypeId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryStockStatusTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryStockStatusTypesArgs = {
  filter?: InputMaybe<StockStatusTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<StockStatusTypeSort>>;
};


export type QuerySupportTicketArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTaskArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTaskGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTaskGroupsArgs = {
  filter?: InputMaybe<TaskGroupFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<TaskGroupSort>>;
};


export type QueryTaskTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTaskTypesArgs = {
  filter?: InputMaybe<TaskTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<TaskTypeSort>>;
};


export type QueryTasksArgs = {
  filter?: InputMaybe<TaskFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<TaskSort>>;
};


export type QueryTeamArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTeamsArgs = {
  filter?: InputMaybe<TeamFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<TeamSort>>;
};


export type QueryUnitOfMeasureGlossariesArgs = {
  filter?: InputMaybe<UnitOfMeasureGlossaryFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<UnitOfMeasureGlossarySort>>;
};


export type QueryUnitOfMeasureGlossaryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUnitOfMeasureProductConversionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUnitOfMeasureProductConversionsArgs = {
  filter?: InputMaybe<UnitOfMeasureProductConversionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<UnitOfMeasureProductConversionSort>>;
};


export type QueryUnitOfMeasureWarehousePreferenceArgs = {
  category: StandardUomCategory;
  warehouseId: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserGroupMappingsArgs = {
  filter?: InputMaybe<UserGroupMappingFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<UserGroupMappingSort>>;
};


export type QueryUserGroupsArgs = {
  filter?: InputMaybe<UserGroupFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<UserGroupSort>>;
};


export type QueryUserPermissionsArgs = {
  warehouseId: Scalars['ID']['input'];
};


export type QueryUserTeamMappingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserTeamMappingsArgs = {
  filter?: InputMaybe<UserTeamMappingFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<UserTeamMappingSort>>;
};


export type QueryUserWarehouseDefaultArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserWarehouseDefaultsArgs = {
  filter?: InputMaybe<UserWarehouseDefaultFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<UserWarehouseDefaultSort>>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<UserSort>>;
};


export type QueryValidateTaskArgs = {
  openTasksForLicensePlate?: InputMaybe<LicensePlateTaskValidationDto>;
  taskTypeBinStatus?: InputMaybe<TaskTypeBinStatusValidationInput>;
  taskTypeBinStatusForLicensePlate?: InputMaybe<TaskTypeBinStatusForLicensePlateValidationInput>;
  taskTypeCode?: InputMaybe<Scalars['String']['input']>;
  taskTypeId?: InputMaybe<Scalars['ID']['input']>;
  taskTypeStockStatus?: InputMaybe<TaskTypeStockStatusValidationInput>;
  taskTypeStockStatusForLicensePlate?: InputMaybe<TaskTypeStockStatusForLicensePlateValidationInput>;
  warehouseId: Scalars['ID']['input'];
};


export type QueryValidateTaskTypeArgs = {
  openTasksForLicensePlate?: InputMaybe<LicensePlateTaskValidationDto>;
  taskTypeBinStatus?: InputMaybe<TaskTypeBinStatusValidationInput>;
  taskTypeBinStatusForLicensePlate?: InputMaybe<TaskTypeBinStatusForLicensePlateValidationInput>;
  taskTypeCode?: InputMaybe<Scalars['String']['input']>;
  taskTypeId?: InputMaybe<Scalars['ID']['input']>;
  taskTypeStockStatus?: InputMaybe<TaskTypeStockStatusValidationInput>;
  taskTypeStockStatusForLicensePlate?: InputMaybe<TaskTypeStockStatusForLicensePlateValidationInput>;
  warehouseId: Scalars['ID']['input'];
};


export type QueryViewAreasArgs = {
  filter?: InputMaybe<ViewAreaFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewAreaSort>>;
};


export type QueryViewBarcodesArgs = {
  filter?: InputMaybe<ViewBarcodeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewBarcodeSort>>;
};


export type QueryViewBinStorageTypesArgs = {
  filter?: InputMaybe<ViewBinStorageTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewBinStorageTypeSort>>;
};


export type QueryViewBinsArgs = {
  filter?: InputMaybe<ViewBinFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewBinSort>>;
};


export type QueryViewDeliveriesArgs = {
  filter?: InputMaybe<ViewDeliveryFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewDeliverySort>>;
};


export type QueryViewDeliveryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryViewDeliveryItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryViewDeliveryItemsArgs = {
  filter?: InputMaybe<ViewDeliveryItemFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewDeliveryItemSort>>;
};


export type QueryViewDoorsArgs = {
  filter?: InputMaybe<ViewDoorFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewDoorSort>>;
};


export type QueryViewEquipmentModelsArgs = {
  filter?: InputMaybe<ViewEquipmentModelFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewEquipmentModelSort>>;
};


export type QueryViewEquipmentTypesArgs = {
  filter?: InputMaybe<EquipmentTypeQueryShapeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<EquipmentTypeQueryShapeSort>>;
};


export type QueryViewEquipmentsArgs = {
  filter?: InputMaybe<ViewEquipmentFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewEquipmentSort>>;
};


export type QueryViewFulfillmentItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryViewFulfillmentItemsArgs = {
  filter?: InputMaybe<ViewFulfillmentItemFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewFulfillmentItemSort>>;
};


export type QueryViewHistoryFieldDiffsArgs = {
  filter?: InputMaybe<ViewHistoryFieldDiffFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewHistoryFieldDiffSort>>;
};


export type QueryViewLostAndFoundInventoryArgs = {
  filter?: InputMaybe<ViewLostAndFoundFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewLostAndFoundSort>>;
};


export type QueryViewLotsArgs = {
  filter?: InputMaybe<ViewLotFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewLotSort>>;
};


export type QueryViewMapAislesArgs = {
  filter?: InputMaybe<ViewMapAisleFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewMapAisleSort>>;
};


export type QueryViewMapBinsArgs = {
  filter?: InputMaybe<ViewMapBinFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewMapBinSort>>;
};


export type QueryViewProductsArgs = {
  filter?: InputMaybe<ProductQueryShapeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ProductQueryShapeSort>>;
};


export type QueryViewSlottingRulesetsArgs = {
  filter?: InputMaybe<ViewSlottingRulesetFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewSlottingRulesetSort>>;
};


export type QueryViewSlottingSettingsArgs = {
  filter?: InputMaybe<ViewSlottingSettingFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewSlottingSettingSort>>;
};


export type QueryViewStockStatusesArgs = {
  filter?: InputMaybe<ViewStockStatusFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewStockStatusSort>>;
};


export type QueryViewTaskGroupsArgs = {
  filter?: InputMaybe<ViewTaskGroupFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewTaskGroupSort>>;
};


export type QueryViewTaskTypeBinStatusMappingsArgs = {
  filter?: InputMaybe<ViewTaskTypeBinStatusMappingFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewTaskTypeBinStatusMappingSort>>;
};


export type QueryViewTaskTypeStockStatusMappingsArgs = {
  filter?: InputMaybe<ViewTaskTypeStockStatusMappingFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewTaskTypeStockStatusMappingSort>>;
};


export type QueryViewTasksArgs = {
  filter?: InputMaybe<ViewTaskFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewTaskSort>>;
};


export type QueryViewUnitOfMeasureProductConversionsArgs = {
  filter?: InputMaybe<ViewUnitOfMeasureProductConversionFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewUnitOfMeasureProductConversionSort>>;
};


export type QueryViewUserGroupsArgs = {
  filter?: InputMaybe<ViewUserGroupFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewUserGroupSort>>;
};


export type QueryViewUsersArgs = {
  filter?: InputMaybe<ViewUserFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewUserSort>>;
};


export type QueryViewWarehouseRoleTypesArgs = {
  filter?: InputMaybe<WarehouseRoleTypeQueryShapeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<WarehouseRoleTypeQueryShapeSort>>;
};


export type QueryViewZoneAisleColumnsArgs = {
  filter?: InputMaybe<ViewZoneAisleColumnFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewZoneAisleColumnSort>>;
};


export type QueryViewZoneAislesArgs = {
  filter?: InputMaybe<ViewZoneAisleFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewZoneAisleSort>>;
};


export type QueryViewZoneAreasArgs = {
  filter?: InputMaybe<ViewZoneAreaFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewZoneAreaSort>>;
};


export type QueryViewZoneBinsMappedArgs = {
  filter?: InputMaybe<ViewZoneBinMappedFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewZoneBinMappedSort>>;
};


export type QueryViewZoneBinsUnmappedArgs = {
  filter?: InputMaybe<ViewZoneBinUnmappedFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewZoneBinUnmappedSort>>;
};


export type QueryViewZonesArgs = {
  filter?: InputMaybe<ViewZoneFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ViewZoneSort>>;
};


export type QueryWarehouseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWarehouseConfigurationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWarehouseOpFileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWarehouseOpFileRowArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWarehouseOpFileRowsArgs = {
  filter?: InputMaybe<WarehouseOpFileRowFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<WarehouseOpFileRowSort>>;
};


export type QueryWarehouseOpFilesArgs = {
  filter?: InputMaybe<WarehouseOpFileFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<WarehouseOpFileSort>>;
};


export type QueryWarehousePreferredUnitOfMeasureArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWarehousePreferredUnitOfMeasuresArgs = {
  filter?: InputMaybe<WarehousePreferredUnitOfMeasureFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<WarehousePreferredUnitOfMeasureSort>>;
};


export type QueryWarehouseRoleTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryWarehouseRoleTypesArgs = {
  filter?: InputMaybe<WarehouseRoleTypeFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<WarehouseRoleTypeSort>>;
};


export type QueryWarehousesArgs = {
  filter?: InputMaybe<WarehouseFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<WarehouseSort>>;
};


export type QueryZoneArgs = {
  id: Scalars['ID']['input'];
};


export type QueryZoneRuleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryZoneRuleMappingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryZoneRuleMappingsArgs = {
  filter?: InputMaybe<ZoneRuleMappingFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ZoneRuleMappingSort>>;
};


export type QueryZoneRulesArgs = {
  filter?: InputMaybe<ZoneRuleFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ZoneRuleSort>>;
};


export type QueryZonesArgs = {
  filter?: InputMaybe<ZoneFilter>;
  paging?: InputMaybe<OffsetPaging>;
  sorting?: InputMaybe<Array<ZoneSort>>;
};

export type RandomPutawayOutputDto = {
  __typename?: 'RandomPutawayOutputDto';
  completedTaskIds: Array<Scalars['String']['output']>;
};

export type RandomUnloadOutputDto = {
  __typename?: 'RandomUnloadOutputDto';
  completedTaskIds: Array<Scalars['String']['output']>;
};

/** Replenishment entity model */
export type Replenishment = {
  __typename?: 'Replenishment';
  /** Maximum quantity threshold for a replenishment setting */
  autoMax?: Maybe<Scalars['Float']['output']>;
  /** Minimum quantity threshold for a replenishment setting */
  autoMin?: Maybe<Scalars['Float']['output']>;
  /** Quantity to replenish when a replenishment setting threshold is triggered */
  autoReplenishmentQuantity?: Maybe<Scalars['Float']['output']>;
  /** Entity's bin ID (foreign key) */
  binId: Scalars['ID']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Maximum quantity threshold for a manual replenishment setting */
  manualMax?: Maybe<Scalars['Float']['output']>;
  /** Minimum quantity threshold for a manual replenishment setting */
  manualMin?: Maybe<Scalars['Float']['output']>;
  /** Quantity to replenish when a manual replenishment setting threshold is triggered */
  manualReplenishmentQuantity?: Maybe<Scalars['Float']['output']>;
  /** Entity's product ID (foreign key) */
  productId: Scalars['ID']['output'];
  /** // TODO-REPLENISHMENT - figure out what this means */
  replenishmentType: ReplenishmentType;
  /** Unit of measure ID */
  unitOfMeasureId: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
};

export type ReplenishmentCreateInput = {
  /** Maximum quantity threshold for a replenishment setting */
  autoMax?: InputMaybe<Scalars['Float']['input']>;
  /** Minimum quantity threshold for a replenishment setting */
  autoMin?: InputMaybe<Scalars['Float']['input']>;
  /** Quantity to replenish when a replenishment setting threshold is triggered */
  autoReplenishmentQuantity?: InputMaybe<Scalars['Float']['input']>;
  /** Entity's bin ID (foreign key) */
  binId: Scalars['ID']['input'];
  /** Maximum quantity threshold for a manual replenishment setting */
  manualMax?: InputMaybe<Scalars['Float']['input']>;
  /** Minimum quantity threshold for a manual replenishment setting */
  manualMin?: InputMaybe<Scalars['Float']['input']>;
  /** Quantity to replenish when a manual replenishment setting threshold is triggered */
  manualReplenishmentQuantity?: InputMaybe<Scalars['Float']['input']>;
  /** Entity's product ID (foreign key) */
  productId: Scalars['ID']['input'];
  /** Entity's warehouse (foreign key) */
  replenishmentType: ReplenishmentType;
  /** Unit of measure ID */
  unitOfMeasureId: Scalars['ID']['input'];
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['input'];
};

export type ReplenishmentCreateOneInput = {
  /** The record to create */
  replenishment: ReplenishmentCreateInput;
};

export type ReplenishmentFilter = {
  and?: InputMaybe<Array<ReplenishmentFilter>>;
  autoMax?: InputMaybe<FloatFieldComparison>;
  autoMin?: InputMaybe<FloatFieldComparison>;
  autoReplenishmentQuantity?: InputMaybe<FloatFieldComparison>;
  binId?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  manualMax?: InputMaybe<FloatFieldComparison>;
  manualMin?: InputMaybe<FloatFieldComparison>;
  manualReplenishmentQuantity?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<ReplenishmentFilter>>;
  productId?: InputMaybe<IdFilterComparison>;
  replenishmentType?: InputMaybe<ReplenishmentTypeFilterComparison>;
  unitOfMeasureId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type ReplenishmentOffsetConnection = {
  __typename?: 'ReplenishmentOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Replenishment>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ReplenishmentSort = {
  direction: SortDirection;
  field: ReplenishmentSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ReplenishmentSortFields {
  AutoMax = 'autoMax',
  AutoMin = 'autoMin',
  AutoReplenishmentQuantity = 'autoReplenishmentQuantity',
  BinId = 'binId',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  ManualMax = 'manualMax',
  ManualMin = 'manualMin',
  ManualReplenishmentQuantity = 'manualReplenishmentQuantity',
  ProductId = 'productId',
  ReplenishmentType = 'replenishmentType',
  UnitOfMeasureId = 'unitOfMeasureId',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WarehouseId = 'warehouseId'
}

export enum ReplenishmentType {
  Auto = 'auto',
  Manual = 'manual',
  Off = 'off'
}

export type ReplenishmentTypeFilterComparison = {
  eq?: InputMaybe<ReplenishmentType>;
  gt?: InputMaybe<ReplenishmentType>;
  gte?: InputMaybe<ReplenishmentType>;
  iLike?: InputMaybe<ReplenishmentType>;
  in?: InputMaybe<Array<ReplenishmentType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<ReplenishmentType>;
  lt?: InputMaybe<ReplenishmentType>;
  lte?: InputMaybe<ReplenishmentType>;
  neq?: InputMaybe<ReplenishmentType>;
  notILike?: InputMaybe<ReplenishmentType>;
  notIn?: InputMaybe<Array<ReplenishmentType>>;
  notLike?: InputMaybe<ReplenishmentType>;
};

export type ReplenishmentUpdateInput = {
  /** Maximum quantity threshold for a replenishment setting */
  autoMax?: InputMaybe<Scalars['Float']['input']>;
  /** Minimum quantity threshold for a replenishment setting */
  autoMin?: InputMaybe<Scalars['Float']['input']>;
  /** Quantity to replenish when a replenishment setting threshold is triggered */
  autoReplenishmentQuantity?: InputMaybe<Scalars['Float']['input']>;
  /** Entity's bin ID (foreign key) */
  binId?: InputMaybe<Scalars['ID']['input']>;
  /** Maximum quantity threshold for a manual replenishment setting */
  manualMax?: InputMaybe<Scalars['Float']['input']>;
  /** Minimum quantity threshold for a manual replenishment setting */
  manualMin?: InputMaybe<Scalars['Float']['input']>;
  /** Quantity to replenish when a manual replenishment setting threshold is triggered */
  manualReplenishmentQuantity?: InputMaybe<Scalars['Float']['input']>;
  /** Entity's product ID (foreign key) */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's warehouse (foreign key) */
  replenishmentType?: InputMaybe<ReplenishmentType>;
  /** Unit of measure ID */
  unitOfMeasureId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type ReplenishmentUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: ReplenishmentUpdateInput;
};

export type Role = {
  __typename?: 'Role';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Determines if a roles is default */
  default: Scalars['Boolean']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** ApiDocs */
  name: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type RoleCreateDto = {
  /** ApiDocs */
  name: Scalars['String']['input'];
};

export type RoleCreateInput = {
  /** ApiDocs */
  role: RoleCreateDto;
};

export type RoleDeleteInputType = {
  /** The id of the record to delete. */
  id: Scalars['ID']['input'];
};

export type RoleFilter = {
  and?: InputMaybe<Array<RoleFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  default?: InputMaybe<BooleanFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<RoleFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type RoleOffsetConnection = {
  __typename?: 'RoleOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Role>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type RolePermissionMapping = {
  __typename?: 'RolePermissionMapping';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity ID */
  permissionId: Scalars['ID']['output'];
  /** Entity ID */
  roleId: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type RolePermissionMappingFilter = {
  and?: InputMaybe<Array<RolePermissionMappingFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<RolePermissionMappingFilter>>;
  permissionId?: InputMaybe<IdFilterComparison>;
  roleId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type RolePermissionMappingOffsetConnection = {
  __typename?: 'RolePermissionMappingOffsetConnection';
  /** Array of nodes. */
  nodes: Array<RolePermissionMapping>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type RolePermissionMappingSort = {
  direction: SortDirection;
  field: RolePermissionMappingSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum RolePermissionMappingSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  PermissionId = 'permissionId',
  RoleId = 'roleId',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type RoleSort = {
  direction: SortDirection;
  field: RoleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum RoleSortFields {
  CreatedAt = 'createdAt',
  Default = 'default',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

/** SAP sales order */
export type SapSalesOrder = {
  __typename?: 'SAPSalesOrder';
  DistributionChannel: Scalars['String']['output'];
  OrganizationDivision: Scalars['String']['output'];
  SalesOrder: Scalars['String']['output'];
  SalesOrderType: Scalars['String']['output'];
  SalesOrganization: Scalars['String']['output'];
  SoldToParty: Scalars['String']['output'];
};

export type SapSalesOrderCreateOneInput = {
  /** Date string for requested delivery date */
  requestedDeliveryDate: Scalars['String']['input'];
  /** Erp sales order */
  salesOrderNumber: Scalars['String']['input'];
};

/** SAP sales order item */
export type SapSalesOrderItem = {
  __typename?: 'SAPSalesOrderItem';
  Material: Scalars['String']['output'];
  ReferenceSDDocument: Scalars['String']['output'];
  ReferenceSDDocumentItem: Scalars['String']['output'];
  RequestedQuantity: Scalars['String']['output'];
};

/** SAP material document header */
export type SapMaterialDocumentHeader = {
  __typename?: 'SapMaterialDocumentHeader';
  MaterialDocument: Scalars['String']['output'];
};

export enum SapMaterialMovementCode {
  GoodsIssue = 'GOODS_ISSUE',
  GoodsRecieptProductionOrder = 'GOODS_RECIEPT_PRODUCTION_ORDER',
  GoodsRecieptPurchaseOrder = 'GOODS_RECIEPT_PURCHASE_ORDER',
  OtherGoodsReceipts = 'OTHER_GOODS_RECEIPTS',
  ReversalGoodsMovement = 'REVERSAL_GOODS_MOVEMENT',
  SubsequentAdjustmentSubcontractOrder = 'SUBSEQUENT_ADJUSTMENT_SUBCONTRACT_ORDER',
  TransferPosting = 'TRANSFER_POSTING'
}

export enum SapMaterialMovementType {
  GoodsRecieptWithoutPurchaseOrder = 'GOODS_RECIEPT_WITHOUT_PURCHASE_ORDER'
}

export type SapStockStatusType = {
  __typename?: 'SapStockStatusType';
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type SapStockStatusTypeFilter = {
  and?: InputMaybe<Array<SapStockStatusTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SapStockStatusTypeFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type SapStockStatusTypeOffsetConnection = {
  __typename?: 'SapStockStatusTypeOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SapStockStatusType>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SapStockStatusTypeSort = {
  direction: SortDirection;
  field: SapStockStatusTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SapStockStatusTypeSortFields {
  Code = 'code',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Label = 'label',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type SapStorageLocationPlant = {
  __typename?: 'SapStorageLocationPlant';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** plant */
  plant: Scalars['String']['output'];
  /** Storage Location */
  storageLocation: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type SapStorageLocationPlantFilter = {
  and?: InputMaybe<Array<SapStorageLocationPlantFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SapStorageLocationPlantFilter>>;
  plant?: InputMaybe<StringFieldComparison>;
  storageLocation?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type SapStorageLocationPlantOffsetConnection = {
  __typename?: 'SapStorageLocationPlantOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SapStorageLocationPlant>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SapStorageLocationPlantSort = {
  direction: SortDirection;
  field: SapStorageLocationPlantSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SapStorageLocationPlantSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Plant = 'plant',
  StorageLocation = 'storageLocation',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type SapWarehouseMapping = {
  __typename?: 'SapWarehouseMapping';
  plant: Scalars['String']['output'];
  shippingPoints: Array<Scalars['String']['output']>;
  storageLocations: Array<Scalars['String']['output']>;
  warehouseId: Scalars['ID']['output'];
};

/** Scheduled Job model */
export type ScheduledJob = {
  __typename?: 'ScheduledJob';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  jobJson: Scalars['JSON']['output'];
  lastRun?: Maybe<Scalars['DateTime']['output']>;
  /** ApiDocs */
  name: Scalars['String']['output'];
  /** Entity ID */
  scheduledJobTypeId: Scalars['ID']['output'];
  /** Job state */
  state: JobState;
  /** Interval in min. should should execute */
  updateInterval: Scalars['Int']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type ScheduledJobCreateInput = {
  /** ApiDocs */
  name: Scalars['String']['input'];
  scheduledJobTypeId: Scalars['ID']['input'];
  state: JobState;
  /** Interval in min. should should execute */
  updateInterval: Scalars['Int']['input'];
};

export type ScheduledJobCreateInputDto = {
  /** The record to create */
  scheduledJob: ScheduledJobCreateInput;
};

export type ScheduledJobFilter = {
  and?: InputMaybe<Array<ScheduledJobFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lastRun?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ScheduledJobFilter>>;
  scheduledJobTypeId?: InputMaybe<IdFilterComparison>;
  state?: InputMaybe<JobStateFilterComparison>;
  updateInterval?: InputMaybe<IntFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type ScheduledJobOffsetConnection = {
  __typename?: 'ScheduledJobOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ScheduledJob>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export enum ScheduledJobSchedulerType {
  New = 'NEW',
  Update = 'UPDATE'
}

export type ScheduledJobSchedulerTypeFilterComparison = {
  eq?: InputMaybe<ScheduledJobSchedulerType>;
  gt?: InputMaybe<ScheduledJobSchedulerType>;
  gte?: InputMaybe<ScheduledJobSchedulerType>;
  iLike?: InputMaybe<ScheduledJobSchedulerType>;
  in?: InputMaybe<Array<ScheduledJobSchedulerType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<ScheduledJobSchedulerType>;
  lt?: InputMaybe<ScheduledJobSchedulerType>;
  lte?: InputMaybe<ScheduledJobSchedulerType>;
  neq?: InputMaybe<ScheduledJobSchedulerType>;
  notILike?: InputMaybe<ScheduledJobSchedulerType>;
  notIn?: InputMaybe<Array<ScheduledJobSchedulerType>>;
  notLike?: InputMaybe<ScheduledJobSchedulerType>;
};

export type ScheduledJobSort = {
  direction: SortDirection;
  field: ScheduledJobSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ScheduledJobSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  LastRun = 'lastRun',
  Name = 'name',
  ScheduledJobTypeId = 'scheduledJobTypeId',
  State = 'state',
  UpdateInterval = 'updateInterval',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

/** Scheduled Job Type Model */
export type ScheduledJobType = {
  __typename?: 'ScheduledJobType';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Fulfilld controller, i.e. "delivery" */
  controller: ScheduledJobTypeControllerName;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of job being executed */
  description: Scalars['String']['output'];
  /** Meta data for http call executed by GCP job */
  httpTargetJson: Scalars['JSON']['output'];
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Type of job being executed, i.e. "new" */
  type: ScheduledJobSchedulerType;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export enum ScheduledJobTypeControllerName {
  Delivery = 'DELIVERY',
  Fulfillment = 'FULFILLMENT',
  Inventory = 'INVENTORY',
  Lot = 'LOT',
  Product = 'PRODUCT',
  Search = 'SEARCH',
  TaskFromFulfillment = 'TASK_FROM_FULFILLMENT',
  TaskGrouping = 'TASK_GROUPING',
  Test = 'TEST',
  WarehouseOp = 'WAREHOUSE_OP'
}

export type ScheduledJobTypeControllerNameFilterComparison = {
  eq?: InputMaybe<ScheduledJobTypeControllerName>;
  gt?: InputMaybe<ScheduledJobTypeControllerName>;
  gte?: InputMaybe<ScheduledJobTypeControllerName>;
  iLike?: InputMaybe<ScheduledJobTypeControllerName>;
  in?: InputMaybe<Array<ScheduledJobTypeControllerName>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<ScheduledJobTypeControllerName>;
  lt?: InputMaybe<ScheduledJobTypeControllerName>;
  lte?: InputMaybe<ScheduledJobTypeControllerName>;
  neq?: InputMaybe<ScheduledJobTypeControllerName>;
  notILike?: InputMaybe<ScheduledJobTypeControllerName>;
  notIn?: InputMaybe<Array<ScheduledJobTypeControllerName>>;
  notLike?: InputMaybe<ScheduledJobTypeControllerName>;
};

export type ScheduledJobTypeFilter = {
  and?: InputMaybe<Array<ScheduledJobTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  controller?: InputMaybe<ScheduledJobTypeControllerNameFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  httpTargetJson?: InputMaybe<JsonFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ScheduledJobTypeFilter>>;
  type?: InputMaybe<ScheduledJobSchedulerTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type ScheduledJobTypeOffsetConnection = {
  __typename?: 'ScheduledJobTypeOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ScheduledJobType>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ScheduledJobTypeSort = {
  direction: SortDirection;
  field: ScheduledJobTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ScheduledJobTypeSortFields {
  Code = 'code',
  Controller = 'controller',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  HttpTargetJson = 'httpTargetJson',
  Id = 'id',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type ScheduledJobUpdateInput = {
  /** ApiDocs */
  name?: InputMaybe<Scalars['String']['input']>;
  scheduledJobTypeId?: InputMaybe<Scalars['ID']['input']>;
  state?: InputMaybe<JobState>;
  /** Interval in min. should should execute */
  updateInterval?: InputMaybe<Scalars['Int']['input']>;
};

export type ScheduledJobUpdateStatusInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type ScopedData = {
  binCode: Scalars['String']['input'];
  productCode: Scalars['String']['input'];
};

export type SearchAggTerms = {
  terms: SearchAggTermsField;
};

export type SearchAggTermsAgg = {
  termsAggregation: SearchAggTerms;
};

export type SearchAggTermsField = {
  field: Scalars['String']['input'];
};

export type SearchBodyBool = {
  bool: SearchBodyBoolBody;
};

export type SearchBodyBoolBody = {
  filter?: InputMaybe<Array<Scalars['JSONObject']['input']>>;
  minimum_should_match?: InputMaybe<Scalars['Int']['input']>;
  must?: InputMaybe<Array<SearchBodyQueryString>>;
  should?: InputMaybe<Array<SearchBodyQueryString>>;
};

export type SearchBodyNested = {
  ignore_unmapped: Scalars['Boolean']['input'];
  path: Scalars['String']['input'];
  query: SearchBodyBool;
};

export type SearchBodyQuery = {
  aggs?: InputMaybe<SearchAggTermsAgg>;
  query: SearchBodyBool;
};

export type SearchBodyQueryString = {
  bool?: InputMaybe<SearchBodyBoolBody>;
  nested?: InputMaybe<SearchBodyNested>;
  query_string?: InputMaybe<SearchBodyQueryStringBody>;
};

export type SearchBodyQueryStringBody = {
  analyze_wildcard?: InputMaybe<Scalars['Boolean']['input']>;
  default_operator?: InputMaybe<Scalars['String']['input']>;
  escape?: InputMaybe<Scalars['Boolean']['input']>;
  fields?: InputMaybe<Array<Scalars['String']['input']>>;
  query: Scalars['String']['input'];
};

export type SearchQuery = {
  body: SearchBodyQuery;
  from?: InputMaybe<Scalars['Int']['input']>;
  index?: InputMaybe<Array<EsIndices>>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

export type SlottingAbcAnalysis = {
  __typename?: 'SlottingAbcAnalysis';
  /** Current ABC indicator */
  current_abc_indicator: Scalars['String']['output'];
  /** Deployed state */
  deployed: Scalars['Boolean']['output'];
  /** Entity ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Material */
  material: Scalars['String']['output'];
  /** Material Description */
  material_description?: Maybe<Scalars['String']['output']>;
  /** Slotting Plant (ERP) */
  plant?: Maybe<Scalars['String']['output']>;
  /** Date processed */
  process_dt: Scalars['DateTime']['output'];
  /** Proposed ABC indicator */
  proposed_abc_indicator: Scalars['String']['output'];
  /** Slotting run ID */
  run_id?: Maybe<Scalars['ID']['output']>;
};

export type SlottingAbcAnalysisFilter = {
  and?: InputMaybe<Array<SlottingAbcAnalysisFilter>>;
  current_abc_indicator?: InputMaybe<StringFieldComparison>;
  deployed?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  material?: InputMaybe<StringFieldComparison>;
  material_description?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SlottingAbcAnalysisFilter>>;
  plant?: InputMaybe<StringFieldComparison>;
  process_dt?: InputMaybe<DateFieldComparison>;
  proposed_abc_indicator?: InputMaybe<StringFieldComparison>;
  run_id?: InputMaybe<IdFilterComparison>;
};

export type SlottingAbcAnalysisOffsetConnection = {
  __typename?: 'SlottingAbcAnalysisOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingAbcAnalysis>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingAbcAnalysisSort = {
  direction: SortDirection;
  field: SlottingAbcAnalysisSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingAbcAnalysisSortFields {
  CurrentAbcIndicator = 'current_abc_indicator',
  Deployed = 'deployed',
  Id = 'id',
  Material = 'material',
  MaterialDescription = 'material_description',
  Plant = 'plant',
  ProcessDt = 'process_dt',
  ProposedAbcIndicator = 'proposed_abc_indicator',
  RunId = 'run_id'
}

export enum SlottingAnalysisStatus {
  Cancelled = 'cancelled',
  Complete = 'complete',
  Failed = 'failed',
  InProgress = 'inProgress',
  NotStarted = 'notStarted'
}

export type SlottingAnalysisStatusFilterComparison = {
  eq?: InputMaybe<SlottingAnalysisStatus>;
  gt?: InputMaybe<SlottingAnalysisStatus>;
  gte?: InputMaybe<SlottingAnalysisStatus>;
  iLike?: InputMaybe<SlottingAnalysisStatus>;
  in?: InputMaybe<Array<SlottingAnalysisStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<SlottingAnalysisStatus>;
  lt?: InputMaybe<SlottingAnalysisStatus>;
  lte?: InputMaybe<SlottingAnalysisStatus>;
  neq?: InputMaybe<SlottingAnalysisStatus>;
  notILike?: InputMaybe<SlottingAnalysisStatus>;
  notIn?: InputMaybe<Array<SlottingAnalysisStatus>>;
  notLike?: InputMaybe<SlottingAnalysisStatus>;
};

export type SlottingBinResultV1 = {
  __typename?: 'SlottingBinResultV1';
  abc_criteria?: Maybe<Scalars['String']['output']>;
  bin_code: Scalars['String']['output'];
  company: Scalars['String']['output'];
  material_description?: Maybe<Scalars['String']['output']>;
  material_lines?: Maybe<Scalars['Float']['output']>;
  material_name: Scalars['String']['output'];
  material_price?: Maybe<Scalars['Float']['output']>;
  material_quantity?: Maybe<Scalars['Float']['output']>;
  material_weight?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  plant?: Maybe<Scalars['String']['output']>;
  process_dt?: Maybe<Scalars['DateTime']['output']>;
  run_id: Scalars['String']['output'];
  version: Scalars['String']['output'];
  warehouse: Scalars['String']['output'];
};

export type SlottingBinResultV1Filter = {
  abc_criteria?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<SlottingBinResultV1Filter>>;
  bin_code?: InputMaybe<StringFieldComparison>;
  company?: InputMaybe<StringFieldComparison>;
  material_description?: InputMaybe<StringFieldComparison>;
  material_lines?: InputMaybe<NumberFieldComparison>;
  material_name?: InputMaybe<StringFieldComparison>;
  material_price?: InputMaybe<FloatFieldComparison>;
  material_quantity?: InputMaybe<FloatFieldComparison>;
  material_weight?: InputMaybe<FloatFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SlottingBinResultV1Filter>>;
  plant?: InputMaybe<StringFieldComparison>;
  process_dt?: InputMaybe<DateFieldComparison>;
  run_id?: InputMaybe<StringFieldComparison>;
  version?: InputMaybe<StringFieldComparison>;
  warehouse?: InputMaybe<StringFieldComparison>;
};

export type SlottingBinResultV1OffsetConnection = {
  __typename?: 'SlottingBinResultV1OffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingBinResultV1>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingBinResultV1Sort = {
  direction: SortDirection;
  field: SlottingBinResultV1SortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingBinResultV1SortFields {
  AbcCriteria = 'abc_criteria',
  BinCode = 'bin_code',
  Company = 'company',
  MaterialDescription = 'material_description',
  MaterialLines = 'material_lines',
  MaterialName = 'material_name',
  MaterialPrice = 'material_price',
  MaterialQuantity = 'material_quantity',
  MaterialWeight = 'material_weight',
  Name = 'name',
  Plant = 'plant',
  ProcessDt = 'process_dt',
  RunId = 'run_id',
  Version = 'version',
  Warehouse = 'warehouse'
}

export type SlottingConfiguration = {
  __typename?: 'SlottingConfiguration';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Entity ID */
  createdByUserId: Scalars['ID']['output'];
  criteria: Scalars['String']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  forecasting: Forecasting;
  /** Entity ID */
  id: Scalars['ID']['output'];
  indicatorPercentages: Array<Scalars['Float']['output']>;
  /** Slotting config last run */
  lastRun?: Maybe<Scalars['DateTime']['output']>;
  maxMovements?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  /** Slotting config private indicator */
  privateConfiguration: Scalars['Boolean']['output'];
  runCount: Scalars['Int']['output'];
  slottingRules: SlottingConfigurationRules;
  slottingWeights: SlottingConfigurationWeights;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  warehouseId: Scalars['ID']['output'];
  zones: Array<SlottingConfigurationZone>;
};

export type SlottingConfigurationAbcAnalysis = {
  __typename?: 'SlottingConfigurationAbcAnalysis';
  abc_criteria?: Maybe<Scalars['String']['output']>;
  company: Scalars['String']['output'];
  current_abc_indicator?: Maybe<Scalars['String']['output']>;
  material: Scalars['String']['output'];
  material_description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  plant?: Maybe<Scalars['String']['output']>;
  process_dt?: Maybe<Scalars['DateTime']['output']>;
  proposed_abc_indicator: Scalars['String']['output'];
  run_id: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
  warehouse?: Maybe<Scalars['String']['output']>;
};

export type SlottingConfigurationAbcAnalysisFilter = {
  abc_criteria?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<SlottingConfigurationAbcAnalysisFilter>>;
  company?: InputMaybe<StringFieldComparison>;
  current_abc_indicator?: InputMaybe<StringFieldComparison>;
  material?: InputMaybe<StringFieldComparison>;
  material_description?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SlottingConfigurationAbcAnalysisFilter>>;
  plant?: InputMaybe<StringFieldComparison>;
  process_dt?: InputMaybe<DateFieldComparison>;
  proposed_abc_indicator?: InputMaybe<StringFieldComparison>;
  run_id?: InputMaybe<StringFieldComparison>;
  version?: InputMaybe<StringFieldComparison>;
  warehouse?: InputMaybe<StringFieldComparison>;
};

export type SlottingConfigurationAbcAnalysisOffsetConnection = {
  __typename?: 'SlottingConfigurationAbcAnalysisOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingConfigurationAbcAnalysis>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingConfigurationAbcAnalysisSort = {
  direction: SortDirection;
  field: SlottingConfigurationAbcAnalysisSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingConfigurationAbcAnalysisSortFields {
  AbcCriteria = 'abc_criteria',
  Company = 'company',
  CurrentAbcIndicator = 'current_abc_indicator',
  Material = 'material',
  MaterialDescription = 'material_description',
  Name = 'name',
  Plant = 'plant',
  ProcessDt = 'process_dt',
  ProposedAbcIndicator = 'proposed_abc_indicator',
  RunId = 'run_id',
  Version = 'version',
  Warehouse = 'warehouse'
}

export type SlottingConfigurationCreateInput = {
  /** Entity ID */
  createdByUserId: Scalars['ID']['input'];
  criteria: Scalars['String']['input'];
  forecasting: Forecasting;
  id?: InputMaybe<Scalars['String']['input']>;
  indicatorPercentages: Array<Scalars['Float']['input']>;
  /** Slotting config last run */
  lastRun?: InputMaybe<Scalars['DateTime']['input']>;
  maxMovements?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  /** Slotting config private indicator */
  privateConfiguration: Scalars['Boolean']['input'];
  slottingRules: SlottingRulesInput;
  slottingWeights: SlottingConfigurationWeightsInput;
  warehouseId: Scalars['String']['input'];
  zones: Array<SlottingConfigurationZoneInput>;
};

export type SlottingConfigurationCreateInputV1 = {
  areaToOptimize: Scalars['String']['input'];
  avoidCongestion: Scalars['Float']['input'];
  company: Scalars['String']['input'];
  /** Zone rules for slotting configuration */
  configurationZoneRules: Array<SlottingConfigurationZoneDtoV1>;
  criteria: Scalars['String']['input'];
  /** Currency */
  currency: Scalars['String']['input'];
  enforceSingleOrderPicking: Scalars['Boolean']['input'];
  enforceZones: Scalars['Boolean']['input'];
  enforcedZoneType?: InputMaybe<Scalars['String']['input']>;
  favorGroundLevel: Scalars['Float']['input'];
  fefoFifoPicking: Scalars['Boolean']['input'];
  fifoPicking: Scalars['Boolean']['input'];
  forecastUsingFutureDemand: Scalars['Boolean']['input'];
  forecastUsingHistoricalOrders: Scalars['Boolean']['input'];
  generationsAcrossRegions: Scalars['Float']['input'];
  generationsWithinRegion: Scalars['Float']['input'];
  heaviestToLightestPicks: Scalars['Boolean']['input'];
  inboundArea: Scalars['String']['input'];
  indicatorPercentages: Array<Scalars['Float']['input']>;
  maxMovements?: InputMaybe<Scalars['Int']['input']>;
  maxMovementsPerGeneration: Scalars['Float']['input'];
  minBinsForOptimization: Scalars['Float']['input'];
  minBinsForSubdivision: Scalars['Float']['input'];
  mixedBinStorage: Scalars['Boolean']['input'];
  mixedLotsInBins: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  numberOfColumns: Scalars['Float']['input'];
  numberOfRows: Scalars['Float']['input'];
  outboundArea: Scalars['String']['input'];
  pickDensity: Scalars['Float']['input'];
  pickEfficiency: Scalars['Float']['input'];
  /** Slotting config last run */
  private?: InputMaybe<Scalars['Boolean']['input']>;
  putawayDensity: Scalars['Float']['input'];
  putawayEfficiency: Scalars['Float']['input'];
  similarProductInAdjacentBin: Scalars['Boolean']['input'];
  /** Entity ID */
  slottingSettingId: Scalars['ID']['input'];
  /** Velocity unit of measure */
  velocityUnit: Scalars['String']['input'];
  warehouse: Scalars['String']['input'];
  warehouseId: Scalars['String']['input'];
};

export type SlottingConfigurationCreateOneInput = {
  /** The record to create */
  slottingConfiguration: SlottingConfigurationCreateInput;
};

export type SlottingConfigurationCreateOneInputV1 = {
  /** The record to create */
  slottingConfigurationV1: SlottingConfigurationCreateInputV1;
};

export type SlottingConfigurationFilter = {
  and?: InputMaybe<Array<SlottingConfigurationFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  createdByUserId?: InputMaybe<IdFilterComparison>;
  criteria?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  forecasting?: InputMaybe<ForecastingFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lastRun?: InputMaybe<DateFieldComparison>;
  maxMovements?: InputMaybe<IntFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SlottingConfigurationFilter>>;
  privateConfiguration?: InputMaybe<BooleanFieldComparison>;
  runCount?: InputMaybe<IntFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type SlottingConfigurationMovement = {
  __typename?: 'SlottingConfigurationMovement';
  company: Scalars['String']['output'];
  cost_saved_per_quarter: Scalars['Float']['output'];
  destination_bin: Scalars['String']['output'];
  destination_column: Scalars['String']['output'];
  distance_saved: Scalars['Float']['output'];
  importance: Scalars['Float']['output'];
  material: Scalars['String']['output'];
  movement_reason?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  process_dt?: Maybe<Scalars['DateTime']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  run_id: Scalars['String']['output'];
  source_bin: Scalars['String']['output'];
  source_column: Scalars['String']['output'];
  task_type?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  warehouse_id: Scalars['String']['output'];
};

export type SlottingConfigurationMovementOffsetConnection = {
  __typename?: 'SlottingConfigurationMovementOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingConfigurationMovement>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingConfigurationOffsetConnection = {
  __typename?: 'SlottingConfigurationOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingConfiguration>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingConfigurationRules = {
  __typename?: 'SlottingConfigurationRules';
  allowMixedExpirationDates: Scalars['Boolean']['output'];
  allowMixedLots: Scalars['Boolean']['output'];
  allowMixedProducts: Scalars['Boolean']['output'];
  enforceSingleOrderPicking: Scalars['Boolean']['output'];
  fefoFifoPicking: Scalars['Boolean']['output'];
  fifoPicking: Scalars['Boolean']['output'];
  heaviestToLightestPicks: Scalars['Boolean']['output'];
  similarProductInAdjacentBin: Scalars['Boolean']['output'];
};

export type SlottingConfigurationRunView = {
  __typename?: 'SlottingConfigurationRunView';
  abc_criteria?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  configuration?: Maybe<Scalars['JSON']['output']>;
  density?: Maybe<Scalars['Float']['output']>;
  efficiency?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  overall?: Maybe<Scalars['Float']['output']>;
  run_id?: Maybe<Scalars['String']['output']>;
  run_id_version?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_dt?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  warehouseId?: Maybe<Scalars['String']['output']>;
};

export type SlottingConfigurationRunViewFilter = {
  abc_criteria?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<SlottingConfigurationRunViewFilter>>;
  company?: InputMaybe<StringFieldComparison>;
  configuration?: InputMaybe<JsonFilterComparison>;
  density?: InputMaybe<FloatFieldComparison>;
  efficiency?: InputMaybe<FloatFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SlottingConfigurationRunViewFilter>>;
  overall?: InputMaybe<FloatFieldComparison>;
  run_id?: InputMaybe<StringFieldComparison>;
  run_id_version?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<StringFieldComparison>;
  updated_dt?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<StringFieldComparison>;
};

export type SlottingConfigurationRunViewOffsetConnection = {
  __typename?: 'SlottingConfigurationRunViewOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingConfigurationRunView>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingConfigurationRunViewSort = {
  direction: SortDirection;
  field: SlottingConfigurationRunViewSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingConfigurationRunViewSortFields {
  AbcCriteria = 'abc_criteria',
  Company = 'company',
  Configuration = 'configuration',
  Density = 'density',
  Efficiency = 'efficiency',
  Name = 'name',
  Overall = 'overall',
  RunId = 'run_id',
  RunIdVersion = 'run_id_version',
  Status = 'status',
  UpdatedDt = 'updated_dt',
  Version = 'version',
  WarehouseId = 'warehouseId'
}

export type SlottingConfigurationRunViewV1 = {
  __typename?: 'SlottingConfigurationRunViewV1';
  abc_criteria?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  configuration: Scalars['JSON']['output'];
  density?: Maybe<Scalars['Float']['output']>;
  efficiency?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  overall?: Maybe<Scalars['Float']['output']>;
  run_id: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  updated_dt?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  warehouseId?: Maybe<Scalars['String']['output']>;
};

export type SlottingConfigurationRunViewV1Filter = {
  abc_criteria?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<SlottingConfigurationRunViewV1Filter>>;
  company?: InputMaybe<StringFieldComparison>;
  configuration?: InputMaybe<JsonFilterComparison>;
  density?: InputMaybe<FloatFieldComparison>;
  efficiency?: InputMaybe<FloatFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SlottingConfigurationRunViewV1Filter>>;
  overall?: InputMaybe<FloatFieldComparison>;
  run_id?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<StringFieldComparison>;
  updated_dt?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<StringFieldComparison>;
};

export type SlottingConfigurationRunViewV1OffsetConnection = {
  __typename?: 'SlottingConfigurationRunViewV1OffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingConfigurationRunViewV1>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingConfigurationRunViewV1Sort = {
  direction: SortDirection;
  field: SlottingConfigurationRunViewV1SortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingConfigurationRunViewV1SortFields {
  AbcCriteria = 'abc_criteria',
  Company = 'company',
  Configuration = 'configuration',
  Density = 'density',
  Efficiency = 'efficiency',
  Name = 'name',
  Overall = 'overall',
  RunId = 'run_id',
  Status = 'status',
  UpdatedDt = 'updated_dt',
  Version = 'version',
  WarehouseId = 'warehouseId'
}

export type SlottingConfigurationSort = {
  direction: SortDirection;
  field: SlottingConfigurationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingConfigurationSortFields {
  CreatedAt = 'createdAt',
  CreatedByUserId = 'createdByUserId',
  Criteria = 'criteria',
  DeletedAt = 'deletedAt',
  Forecasting = 'forecasting',
  Id = 'id',
  LastRun = 'lastRun',
  MaxMovements = 'maxMovements',
  Name = 'name',
  PrivateConfiguration = 'privateConfiguration',
  RunCount = 'runCount',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WarehouseId = 'warehouseId'
}

export type SlottingConfigurationUpdateInput = {
  /** Entity ID */
  createdByUserId?: InputMaybe<Scalars['ID']['input']>;
  criteria?: InputMaybe<Scalars['String']['input']>;
  forecasting?: InputMaybe<Forecasting>;
  id?: InputMaybe<Scalars['String']['input']>;
  indicatorPercentages?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** Slotting config last run */
  lastRun?: InputMaybe<Scalars['DateTime']['input']>;
  maxMovements?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Slotting config private indicator */
  privateConfiguration?: InputMaybe<Scalars['Boolean']['input']>;
  slottingRules?: InputMaybe<SlottingRulesInput>;
  slottingWeights?: InputMaybe<SlottingConfigurationWeightsInput>;
  warehouseId?: InputMaybe<Scalars['String']['input']>;
  zones?: InputMaybe<Array<SlottingConfigurationZoneInput>>;
};

export type SlottingConfigurationUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: SlottingConfigurationUpdateInput;
};

export type SlottingConfigurationUpdateOneInputV1 = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: SlottingConfigurationV1UpdateInput;
};

export type SlottingConfigurationV1 = {
  __typename?: 'SlottingConfigurationV1';
  areaToOptimize: Scalars['String']['output'];
  avoidCongestion: Scalars['Float']['output'];
  company: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Entity ID */
  createdByUserId: Scalars['ID']['output'];
  criteria: Scalars['String']['output'];
  /** Currency */
  currency: Scalars['String']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  enforceSingleOrderPicking: Scalars['Boolean']['output'];
  enforceZones: Scalars['Boolean']['output'];
  enforcedZoneType?: Maybe<Scalars['String']['output']>;
  favorGroundLevel: Scalars['Float']['output'];
  fefoFifoPicking: Scalars['Boolean']['output'];
  fifoPicking: Scalars['Boolean']['output'];
  forecastUsingFutureDemand: Scalars['Boolean']['output'];
  forecastUsingHistoricalOrders: Scalars['Boolean']['output'];
  generationsAcrossRegions: Scalars['Float']['output'];
  generationsWithinRegion: Scalars['Float']['output'];
  heaviestToLightestPicks: Scalars['Boolean']['output'];
  /** Entity ID */
  id: Scalars['ID']['output'];
  inboundArea: Scalars['String']['output'];
  indicatorPercentages: Array<Scalars['Float']['output']>;
  /** Slotting config last run */
  lastRun?: Maybe<Scalars['DateTime']['output']>;
  maxMovements?: Maybe<Scalars['Int']['output']>;
  maxMovementsPerGeneration: Scalars['Float']['output'];
  minBinsForOptimization: Scalars['Float']['output'];
  minBinsForSubdivision: Scalars['Float']['output'];
  mixedBinStorage: Scalars['Boolean']['output'];
  mixedLotsInBins: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  numberOfColumns: Scalars['Float']['output'];
  numberOfRows: Scalars['Float']['output'];
  outboundArea: Scalars['String']['output'];
  pickDensity: Scalars['Float']['output'];
  pickEfficiency: Scalars['Float']['output'];
  /** Slotting config private indicator */
  private: Scalars['Boolean']['output'];
  putawayDensity: Scalars['Float']['output'];
  putawayEfficiency: Scalars['Float']['output'];
  runCount: Scalars['Int']['output'];
  similarProductInAdjacentBin: Scalars['Boolean']['output'];
  /** Slotting settings applied to the slotting configuration */
  slottingSettings: Array<SlottingSettingItemsForConfigObjectDtoV1>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Velocity unit of measure */
  velocityUnit: Scalars['String']['output'];
  version: Scalars['Int']['output'];
  warehouse: Scalars['String']['output'];
  warehouseId: Scalars['String']['output'];
};

export type SlottingConfigurationV1Filter = {
  and?: InputMaybe<Array<SlottingConfigurationV1Filter>>;
  areaToOptimize?: InputMaybe<StringFieldComparison>;
  avoidCongestion?: InputMaybe<FloatFieldComparison>;
  company?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  createdByUserId?: InputMaybe<IdFilterComparison>;
  criteria?: InputMaybe<StringFieldComparison>;
  currency?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  enforceSingleOrderPicking?: InputMaybe<BooleanFieldComparison>;
  enforceZones?: InputMaybe<BooleanFieldComparison>;
  enforcedZoneType?: InputMaybe<StringFieldComparison>;
  favorGroundLevel?: InputMaybe<FloatFieldComparison>;
  fefoFifoPicking?: InputMaybe<BooleanFieldComparison>;
  fifoPicking?: InputMaybe<BooleanFieldComparison>;
  forecastUsingFutureDemand?: InputMaybe<BooleanFieldComparison>;
  forecastUsingHistoricalOrders?: InputMaybe<BooleanFieldComparison>;
  generationsAcrossRegions?: InputMaybe<FloatFieldComparison>;
  generationsWithinRegion?: InputMaybe<FloatFieldComparison>;
  heaviestToLightestPicks?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  inboundArea?: InputMaybe<StringFieldComparison>;
  lastRun?: InputMaybe<DateFieldComparison>;
  maxMovements?: InputMaybe<IntFieldComparison>;
  maxMovementsPerGeneration?: InputMaybe<FloatFieldComparison>;
  minBinsForOptimization?: InputMaybe<FloatFieldComparison>;
  minBinsForSubdivision?: InputMaybe<FloatFieldComparison>;
  mixedBinStorage?: InputMaybe<BooleanFieldComparison>;
  mixedLotsInBins?: InputMaybe<BooleanFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  numberOfColumns?: InputMaybe<FloatFieldComparison>;
  numberOfRows?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<SlottingConfigurationV1Filter>>;
  outboundArea?: InputMaybe<StringFieldComparison>;
  pickDensity?: InputMaybe<FloatFieldComparison>;
  pickEfficiency?: InputMaybe<FloatFieldComparison>;
  private?: InputMaybe<BooleanFieldComparison>;
  putawayDensity?: InputMaybe<FloatFieldComparison>;
  putawayEfficiency?: InputMaybe<FloatFieldComparison>;
  runCount?: InputMaybe<IntFieldComparison>;
  similarProductInAdjacentBin?: InputMaybe<BooleanFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  velocityUnit?: InputMaybe<StringFieldComparison>;
  version?: InputMaybe<IntFieldComparison>;
  warehouse?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<StringFieldComparison>;
};

export type SlottingConfigurationV1OffsetConnection = {
  __typename?: 'SlottingConfigurationV1OffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingConfigurationV1>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingConfigurationV1Sort = {
  direction: SortDirection;
  field: SlottingConfigurationV1SortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingConfigurationV1SortFields {
  AreaToOptimize = 'areaToOptimize',
  AvoidCongestion = 'avoidCongestion',
  Company = 'company',
  CreatedAt = 'createdAt',
  CreatedByUserId = 'createdByUserId',
  Criteria = 'criteria',
  Currency = 'currency',
  DeletedAt = 'deletedAt',
  EnforceSingleOrderPicking = 'enforceSingleOrderPicking',
  EnforceZones = 'enforceZones',
  EnforcedZoneType = 'enforcedZoneType',
  FavorGroundLevel = 'favorGroundLevel',
  FefoFifoPicking = 'fefoFifoPicking',
  FifoPicking = 'fifoPicking',
  ForecastUsingFutureDemand = 'forecastUsingFutureDemand',
  ForecastUsingHistoricalOrders = 'forecastUsingHistoricalOrders',
  GenerationsAcrossRegions = 'generationsAcrossRegions',
  GenerationsWithinRegion = 'generationsWithinRegion',
  HeaviestToLightestPicks = 'heaviestToLightestPicks',
  Id = 'id',
  InboundArea = 'inboundArea',
  LastRun = 'lastRun',
  MaxMovements = 'maxMovements',
  MaxMovementsPerGeneration = 'maxMovementsPerGeneration',
  MinBinsForOptimization = 'minBinsForOptimization',
  MinBinsForSubdivision = 'minBinsForSubdivision',
  MixedBinStorage = 'mixedBinStorage',
  MixedLotsInBins = 'mixedLotsInBins',
  Name = 'name',
  NumberOfColumns = 'numberOfColumns',
  NumberOfRows = 'numberOfRows',
  OutboundArea = 'outboundArea',
  PickDensity = 'pickDensity',
  PickEfficiency = 'pickEfficiency',
  Private = 'private',
  PutawayDensity = 'putawayDensity',
  PutawayEfficiency = 'putawayEfficiency',
  RunCount = 'runCount',
  SimilarProductInAdjacentBin = 'similarProductInAdjacentBin',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  VelocityUnit = 'velocityUnit',
  Version = 'version',
  Warehouse = 'warehouse',
  WarehouseId = 'warehouseId'
}

export type SlottingConfigurationV1UpdateInput = {
  areaToOptimize?: InputMaybe<Scalars['String']['input']>;
  avoidCongestion?: InputMaybe<Scalars['Float']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  /** Zone rules for slotting configuration */
  configurationZoneRules?: InputMaybe<Array<SlottingConfigurationZoneDtoV1>>;
  criteria?: InputMaybe<Scalars['String']['input']>;
  /** Currency */
  currency?: InputMaybe<Scalars['String']['input']>;
  enforceSingleOrderPicking?: InputMaybe<Scalars['Boolean']['input']>;
  enforceZones?: InputMaybe<Scalars['Boolean']['input']>;
  enforcedZoneType?: InputMaybe<Scalars['String']['input']>;
  favorGroundLevel?: InputMaybe<Scalars['Float']['input']>;
  fefoFifoPicking?: InputMaybe<Scalars['Boolean']['input']>;
  fifoPicking?: InputMaybe<Scalars['Boolean']['input']>;
  forecastUsingFutureDemand?: InputMaybe<Scalars['Boolean']['input']>;
  forecastUsingHistoricalOrders?: InputMaybe<Scalars['Boolean']['input']>;
  generationsAcrossRegions?: InputMaybe<Scalars['Float']['input']>;
  generationsWithinRegion?: InputMaybe<Scalars['Float']['input']>;
  heaviestToLightestPicks?: InputMaybe<Scalars['Boolean']['input']>;
  inboundArea?: InputMaybe<Scalars['String']['input']>;
  indicatorPercentages?: InputMaybe<Array<Scalars['Float']['input']>>;
  maxMovements?: InputMaybe<Scalars['Int']['input']>;
  maxMovementsPerGeneration?: InputMaybe<Scalars['Float']['input']>;
  minBinsForOptimization?: InputMaybe<Scalars['Float']['input']>;
  minBinsForSubdivision?: InputMaybe<Scalars['Float']['input']>;
  mixedBinStorage?: InputMaybe<Scalars['Boolean']['input']>;
  mixedLotsInBins?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  numberOfColumns?: InputMaybe<Scalars['Float']['input']>;
  numberOfRows?: InputMaybe<Scalars['Float']['input']>;
  outboundArea?: InputMaybe<Scalars['String']['input']>;
  pickDensity?: InputMaybe<Scalars['Float']['input']>;
  pickEfficiency?: InputMaybe<Scalars['Float']['input']>;
  /** Slotting config last run */
  private?: InputMaybe<Scalars['Boolean']['input']>;
  putawayDensity?: InputMaybe<Scalars['Float']['input']>;
  putawayEfficiency?: InputMaybe<Scalars['Float']['input']>;
  similarProductInAdjacentBin?: InputMaybe<Scalars['Boolean']['input']>;
  /** Entity ID */
  slottingSettingId?: InputMaybe<Scalars['ID']['input']>;
  /** Velocity unit of measure */
  velocityUnit?: InputMaybe<Scalars['String']['input']>;
  warehouse?: InputMaybe<Scalars['String']['input']>;
  warehouseId?: InputMaybe<Scalars['String']['input']>;
};

export type SlottingConfigurationView = {
  __typename?: 'SlottingConfigurationView';
  abc_criteria?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  configuration?: Maybe<Scalars['JSON']['output']>;
  density?: Maybe<Scalars['Float']['output']>;
  efficiency?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  overall?: Maybe<Scalars['Float']['output']>;
  run_id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_dt?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  warehouseId?: Maybe<Scalars['String']['output']>;
};

export type SlottingConfigurationViewFilter = {
  abc_criteria?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<SlottingConfigurationViewFilter>>;
  company?: InputMaybe<StringFieldComparison>;
  configuration?: InputMaybe<JsonFilterComparison>;
  density?: InputMaybe<FloatFieldComparison>;
  efficiency?: InputMaybe<FloatFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SlottingConfigurationViewFilter>>;
  overall?: InputMaybe<FloatFieldComparison>;
  run_id?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<StringFieldComparison>;
  updated_dt?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<StringFieldComparison>;
};

export type SlottingConfigurationViewOffsetConnection = {
  __typename?: 'SlottingConfigurationViewOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingConfigurationView>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingConfigurationViewSort = {
  direction: SortDirection;
  field: SlottingConfigurationViewSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingConfigurationViewSortFields {
  AbcCriteria = 'abc_criteria',
  Company = 'company',
  Configuration = 'configuration',
  Density = 'density',
  Efficiency = 'efficiency',
  Name = 'name',
  Overall = 'overall',
  RunId = 'run_id',
  Status = 'status',
  UpdatedDt = 'updated_dt',
  Version = 'version',
  WarehouseId = 'warehouseId'
}

export type SlottingConfigurationViewV1 = {
  __typename?: 'SlottingConfigurationViewV1';
  company?: Maybe<Scalars['String']['output']>;
  configuration: Scalars['JSON']['output'];
  name?: Maybe<Scalars['String']['output']>;
  run_id: Scalars['String']['output'];
  updated_dt?: Maybe<Scalars['DateTime']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export type SlottingConfigurationViewV1Filter = {
  and?: InputMaybe<Array<SlottingConfigurationViewV1Filter>>;
  company?: InputMaybe<StringFieldComparison>;
  configuration?: InputMaybe<JsonFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SlottingConfigurationViewV1Filter>>;
  run_id?: InputMaybe<StringFieldComparison>;
  updated_dt?: InputMaybe<DateFieldComparison>;
  version?: InputMaybe<StringFieldComparison>;
};

export type SlottingConfigurationViewV1OffsetConnection = {
  __typename?: 'SlottingConfigurationViewV1OffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingConfigurationViewV1>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingConfigurationViewV1Sort = {
  direction: SortDirection;
  field: SlottingConfigurationViewV1SortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingConfigurationViewV1SortFields {
  Company = 'company',
  Configuration = 'configuration',
  Name = 'name',
  RunId = 'run_id',
  UpdatedDt = 'updated_dt',
  Version = 'version'
}

export type SlottingConfigurationWeights = {
  __typename?: 'SlottingConfigurationWeights';
  avoidCongestion: Scalars['Float']['output'];
  favorGroundLevel: Scalars['Float']['output'];
  pickDensity: Scalars['Float']['output'];
  pickEfficiency: Scalars['Float']['output'];
  putawayDensity: Scalars['Float']['output'];
  putawayEfficiency: Scalars['Float']['output'];
  replenishment: Scalars['Float']['output'];
};

export type SlottingConfigurationWeightsInput = {
  avoidCongestion: Scalars['Float']['input'];
  favorGroundLevel: Scalars['Float']['input'];
  pickDensity: Scalars['Float']['input'];
  pickEfficiency: Scalars['Float']['input'];
  putawayDensity: Scalars['Float']['input'];
  putawayEfficiency: Scalars['Float']['input'];
  replenishment: Scalars['Float']['input'];
};

export type SlottingConfigurationZone = {
  __typename?: 'SlottingConfigurationZone';
  slottingRules: SlottingConfigurationRules;
  slottingWeights: SlottingConfigurationWeights;
  zoneId: Scalars['String']['output'];
};

export type SlottingConfigurationZoneDtoV1 = {
  /** Zone behavior list */
  zoneBehaviors?: InputMaybe<Array<ZoneBehavior>>;
  /** Entity ID */
  zoneIds: Array<Scalars['String']['input']>;
  /** Zone rules to apply */
  zoneRules: Array<SlottingConfigurationZoneRuleDtoV1>;
};

export type SlottingConfigurationZoneInput = {
  slottingRules: SlottingRulesInput;
  slottingWeights: SlottingConfigurationWeightsInput;
  zoneId: Scalars['String']['input'];
};

export type SlottingConfigurationZoneRuleDtoV1 = {
  /** Entity ID */
  zoneRuleId: Scalars['ID']['input'];
  /** Zone rule value */
  zoneRuleValue?: InputMaybe<Scalars['JSONObject']['input']>;
};

/** SlottingDataset entity model */
export type SlottingDataset = {
  __typename?: 'SlottingDataset';
  /** Current status when processing a slotting dataset */
  analysisStatus: SlottingAnalysisStatus;
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Entity's user ID (foreign key) */
  createdByUserId: Scalars['ID']['output'];
  /** Person's full name */
  createdByUserName: Scalars['String']['output'];
  /** Date range for data pulled from ERP system */
  dateRangeEnd: Scalars['DateTime']['output'];
  /** Date range for data pulled from ERP system */
  dateRangeStart: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Current status when loading a slotting dataset from ERP system */
  loadStatus: SlottingLoadStatus;
  /** Lock setting for a slotting dataset */
  locked: Scalars['Boolean']['output'];
  /** Slotting dataset process type */
  processType: SlottingDatasetProcessType;
  /** Date the slotting data was pulled from ERP system */
  pullDate: Scalars['DateTime']['output'];
  /** Tag Identifier */
  tag?: Maybe<Scalars['String']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Description for SAP variant */
  variantDescription: Scalars['String']['output'];
  /** Name for SAP variant */
  variantName: Scalars['String']['output'];
  warehouse?: Maybe<Warehouse>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
};

export type SlottingDatasetCreateInput = {
  /** Date range for data pulled from ERP system */
  dateRangeEnd: Scalars['DateTime']['input'];
  /** Date range for data pulled from ERP system */
  dateRangeStart: Scalars['DateTime']['input'];
  /** Lock setting for a slotting dataset */
  locked: Scalars['Boolean']['input'];
  /** Tag Identifier */
  tag?: InputMaybe<Scalars['String']['input']>;
  /** Description for SAP variant */
  variantDescription: Scalars['String']['input'];
  /** Name for SAP variant */
  variantName: Scalars['String']['input'];
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['input'];
};

export type SlottingDatasetCreateOneInput = {
  /** The record to create */
  SlottingDataset: SlottingDatasetCreateInput;
};

export type SlottingDatasetFilter = {
  analysisStatus?: InputMaybe<SlottingAnalysisStatusFilterComparison>;
  and?: InputMaybe<Array<SlottingDatasetFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  createdByUserId?: InputMaybe<IdFilterComparison>;
  createdByUserName?: InputMaybe<StringFieldComparison>;
  dateRangeEnd?: InputMaybe<DateFieldComparison>;
  dateRangeStart?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  loadStatus?: InputMaybe<SlottingLoadStatusFilterComparison>;
  locked?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<SlottingDatasetFilter>>;
  processType?: InputMaybe<SlottingDatasetProcessTypeFilterComparison>;
  pullDate?: InputMaybe<DateFieldComparison>;
  tag?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  variantDescription?: InputMaybe<StringFieldComparison>;
  variantName?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type SlottingDatasetOffsetConnection = {
  __typename?: 'SlottingDatasetOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingDataset>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export enum SlottingDatasetProcessType {
  Manual = 'manual'
}

export type SlottingDatasetProcessTypeFilterComparison = {
  eq?: InputMaybe<SlottingDatasetProcessType>;
  gt?: InputMaybe<SlottingDatasetProcessType>;
  gte?: InputMaybe<SlottingDatasetProcessType>;
  iLike?: InputMaybe<SlottingDatasetProcessType>;
  in?: InputMaybe<Array<SlottingDatasetProcessType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<SlottingDatasetProcessType>;
  lt?: InputMaybe<SlottingDatasetProcessType>;
  lte?: InputMaybe<SlottingDatasetProcessType>;
  neq?: InputMaybe<SlottingDatasetProcessType>;
  notILike?: InputMaybe<SlottingDatasetProcessType>;
  notIn?: InputMaybe<Array<SlottingDatasetProcessType>>;
  notLike?: InputMaybe<SlottingDatasetProcessType>;
};

export type SlottingDatasetSort = {
  direction: SortDirection;
  field: SlottingDatasetSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingDatasetSortFields {
  AnalysisStatus = 'analysisStatus',
  Code = 'code',
  CreatedAt = 'createdAt',
  CreatedByUserId = 'createdByUserId',
  CreatedByUserName = 'createdByUserName',
  DateRangeEnd = 'dateRangeEnd',
  DateRangeStart = 'dateRangeStart',
  DeletedAt = 'deletedAt',
  Id = 'id',
  LoadStatus = 'loadStatus',
  Locked = 'locked',
  ProcessType = 'processType',
  PullDate = 'pullDate',
  Tag = 'tag',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  VariantDescription = 'variantDescription',
  VariantName = 'variantName',
  WarehouseId = 'warehouseId'
}

export type SlottingDatasetUpdateInput = {
  /** Current status when loading a slotting dataset from ERP system */
  loadStatus?: InputMaybe<SlottingLoadStatus>;
  /** Lock setting for a slotting dataset */
  locked?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description of entity */
  tag?: InputMaybe<Scalars['String']['input']>;
  /** Description of entity */
  variantDescription?: InputMaybe<Scalars['String']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type SlottingDatasetUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: SlottingDatasetUpdateInput;
};

export type SlottingDatasetVariantPlantSlocWh = {
  __typename?: 'SlottingDatasetVariantPlantSlocWh';
  /** SAP plants for an SAP variant */
  plants: Array<Scalars['String']['output']>;
  /** SAP storage locations for an SAP variant */
  storageLocations: Array<Scalars['String']['output']>;
  /** SAP Warehouses for an SAP variant */
  warehouses: Array<Scalars['String']['output']>;
};

export type SlottingDatasetVariants = {
  __typename?: 'SlottingDatasetVariants';
  /** Name for SAP variant */
  variants: Array<Scalars['String']['output']>;
};

export type SlottingEquipment = {
  __typename?: 'SlottingEquipment';
  id: Scalars['ID']['output'];
  quantity: Scalars['Float']['output'];
};

export type SlottingEquipmentInput = {
  id: Scalars['ID']['input'];
  quantity: Scalars['Float']['input'];
};

export type SlottingInventory = {
  __typename?: 'SlottingInventory';
  binCode?: Maybe<Scalars['String']['output']>;
  eaQtyPerCase?: Maybe<Scalars['String']['output']>;
  productGroup?: Maybe<Scalars['String']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  productNumber?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['String']['output']>;
  rank?: Maybe<Scalars['String']['output']>;
  uom?: Maybe<Scalars['String']['output']>;
  zone?: Maybe<Scalars['String']['output']>;
};

export type SlottingInventoryFilter = {
  and?: InputMaybe<Array<SlottingInventoryFilter>>;
  binCode?: InputMaybe<StringFieldComparison>;
  eaQtyPerCase?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SlottingInventoryFilter>>;
  productGroup?: InputMaybe<StringFieldComparison>;
  productName?: InputMaybe<StringFieldComparison>;
  productNumber?: InputMaybe<StringFieldComparison>;
  quantity?: InputMaybe<StringFieldComparison>;
  rank?: InputMaybe<StringFieldComparison>;
  uom?: InputMaybe<StringFieldComparison>;
  zone?: InputMaybe<StringFieldComparison>;
};

export type SlottingInventoryOffsetConnection = {
  __typename?: 'SlottingInventoryOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingInventory>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingInventorySort = {
  direction: SortDirection;
  field: SlottingInventorySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingInventorySortFields {
  BinCode = 'binCode',
  EaQtyPerCase = 'eaQtyPerCase',
  ProductGroup = 'productGroup',
  ProductName = 'productName',
  ProductNumber = 'productNumber',
  Quantity = 'quantity',
  Rank = 'rank',
  Uom = 'uom',
  Zone = 'zone'
}

export enum SlottingLoadStatus {
  Complete = 'complete',
  Deleted = 'deleted',
  Failed = 'failed',
  InProgress = 'inProgress',
  NotStarted = 'notStarted'
}

export type SlottingLoadStatusFilterComparison = {
  eq?: InputMaybe<SlottingLoadStatus>;
  gt?: InputMaybe<SlottingLoadStatus>;
  gte?: InputMaybe<SlottingLoadStatus>;
  iLike?: InputMaybe<SlottingLoadStatus>;
  in?: InputMaybe<Array<SlottingLoadStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<SlottingLoadStatus>;
  lt?: InputMaybe<SlottingLoadStatus>;
  lte?: InputMaybe<SlottingLoadStatus>;
  neq?: InputMaybe<SlottingLoadStatus>;
  notILike?: InputMaybe<SlottingLoadStatus>;
  notIn?: InputMaybe<Array<SlottingLoadStatus>>;
  notLike?: InputMaybe<SlottingLoadStatus>;
};

export type SlottingMovement = {
  __typename?: 'SlottingMovement';
  /** Current Max Quantity */
  current_max_quantity?: Maybe<Scalars['Float']['output']>;
  /** Current Min Quantity */
  current_min_quantity?: Maybe<Scalars['Float']['output']>;
  /** Deployed state */
  deployed: Scalars['Boolean']['output'];
  /** Source bin code */
  destination_bin?: Maybe<Scalars['String']['output']>;
  /** Source Column */
  destination_column?: Maybe<Scalars['String']['output']>;
  /** Source Zone */
  destination_zone?: Maybe<Scalars['String']['output']>;
  /** distanceSaved */
  distance_saved?: Maybe<Scalars['Float']['output']>;
  /** Entity's warehouse (foreign key) */
  id?: Maybe<Scalars['ID']['output']>;
  /** Importance */
  importance?: Maybe<Scalars['Float']['output']>;
  /** Material */
  material: Scalars['String']['output'];
  /** Material Description */
  material_description?: Maybe<Scalars['String']['output']>;
  /** moneySaved */
  money_saved?: Maybe<Scalars['Float']['output']>;
  /** Movement Reason */
  movement_reason?: Maybe<Scalars['String']['output']>;
  /** Date processed */
  process_dt: Scalars['DateTime']['output'];
  /** Proposed Max Quantity */
  proposed_max_quantity?: Maybe<Scalars['Float']['output']>;
  /** Proposed Min Quantity */
  proposed_min_quantity?: Maybe<Scalars['Float']['output']>;
  /** Quantity of product */
  quantity?: Maybe<Scalars['Float']['output']>;
  /** Slotting run ID */
  run_id?: Maybe<Scalars['ID']['output']>;
  /** Slotting run ID */
  run_name?: Maybe<Scalars['String']['output']>;
  /** Destination bin code */
  source_bin?: Maybe<Scalars['String']['output']>;
  /** Destination Column */
  source_column?: Maybe<Scalars['String']['output']>;
  /** Destination Zone */
  source_zone?: Maybe<Scalars['String']['output']>;
  /** Task type */
  task_type?: Maybe<Scalars['String']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouse_id?: Maybe<Scalars['ID']['output']>;
};

export type SlottingMovementFilter = {
  and?: InputMaybe<Array<SlottingMovementFilter>>;
  current_max_quantity?: InputMaybe<FloatFieldComparison>;
  current_min_quantity?: InputMaybe<FloatFieldComparison>;
  deployed?: InputMaybe<BooleanFieldComparison>;
  destination_bin?: InputMaybe<StringFieldComparison>;
  destination_column?: InputMaybe<StringFieldComparison>;
  destination_zone?: InputMaybe<StringFieldComparison>;
  distance_saved?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  importance?: InputMaybe<FloatFieldComparison>;
  material?: InputMaybe<StringFieldComparison>;
  material_description?: InputMaybe<StringFieldComparison>;
  money_saved?: InputMaybe<FloatFieldComparison>;
  movement_reason?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SlottingMovementFilter>>;
  process_dt?: InputMaybe<DateFieldComparison>;
  proposed_max_quantity?: InputMaybe<FloatFieldComparison>;
  proposed_min_quantity?: InputMaybe<FloatFieldComparison>;
  quantity?: InputMaybe<FloatFieldComparison>;
  run_id?: InputMaybe<IdFilterComparison>;
  run_name?: InputMaybe<StringFieldComparison>;
  source_bin?: InputMaybe<StringFieldComparison>;
  source_column?: InputMaybe<StringFieldComparison>;
  source_zone?: InputMaybe<StringFieldComparison>;
  task_type?: InputMaybe<StringFieldComparison>;
  warehouse_id?: InputMaybe<IdFilterComparison>;
};

export type SlottingMovementOffsetConnection = {
  __typename?: 'SlottingMovementOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingMovement>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingMovementPepsi = {
  __typename?: 'SlottingMovementPepsi';
  company: Scalars['String']['output'];
  cost_saved_per_day_for_picks: Scalars['Float']['output'];
  cost_saved_per_day_for_replenishment: Scalars['Float']['output'];
  destination_bin: Scalars['String']['output'];
  destination_column: Scalars['String']['output'];
  importance: Scalars['Int']['output'];
  improved_density_pct: Scalars['Float']['output'];
  improved_efficiency_pct: Scalars['Float']['output'];
  improved_replenishment_pct: Scalars['Float']['output'];
  material: Scalars['String']['output'];
  movement_reason?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pick_date: Scalars['DateTime']['output'];
  process_dt?: Maybe<Scalars['DateTime']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rearrangement_cost: Scalars['Float']['output'];
  replenishment_cost: Scalars['Float']['output'];
  run_id: Scalars['String']['output'];
  source_bin: Scalars['String']['output'];
  source_column: Scalars['String']['output'];
  storage_type?: Maybe<Scalars['String']['output']>;
  task_type?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
  warehouse_id: Scalars['String']['output'];
  zone: Scalars['String']['output'];
};

export type SlottingMovementPepsiFilter = {
  and?: InputMaybe<Array<SlottingMovementPepsiFilter>>;
  company?: InputMaybe<StringFieldComparison>;
  cost_saved_per_day_for_picks?: InputMaybe<FloatFieldComparison>;
  cost_saved_per_day_for_replenishment?: InputMaybe<FloatFieldComparison>;
  destination_bin?: InputMaybe<StringFieldComparison>;
  destination_column?: InputMaybe<StringFieldComparison>;
  importance?: InputMaybe<IntFieldComparison>;
  improved_density_pct?: InputMaybe<FloatFieldComparison>;
  improved_efficiency_pct?: InputMaybe<FloatFieldComparison>;
  improved_replenishment_pct?: InputMaybe<FloatFieldComparison>;
  material?: InputMaybe<StringFieldComparison>;
  movement_reason?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SlottingMovementPepsiFilter>>;
  pick_date?: InputMaybe<DateFieldComparison>;
  process_dt?: InputMaybe<DateFieldComparison>;
  quantity?: InputMaybe<FloatFieldComparison>;
  rearrangement_cost?: InputMaybe<FloatFieldComparison>;
  replenishment_cost?: InputMaybe<FloatFieldComparison>;
  run_id?: InputMaybe<StringFieldComparison>;
  source_bin?: InputMaybe<StringFieldComparison>;
  source_column?: InputMaybe<StringFieldComparison>;
  storage_type?: InputMaybe<StringFieldComparison>;
  task_type?: InputMaybe<StringFieldComparison>;
  version?: InputMaybe<StringFieldComparison>;
  warehouse_id?: InputMaybe<StringFieldComparison>;
  zone?: InputMaybe<StringFieldComparison>;
};

export type SlottingMovementPepsiOffsetConnection = {
  __typename?: 'SlottingMovementPepsiOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingMovementPepsi>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingMovementPepsiSort = {
  direction: SortDirection;
  field: SlottingMovementPepsiSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingMovementPepsiSortFields {
  Company = 'company',
  CostSavedPerDayForPicks = 'cost_saved_per_day_for_picks',
  CostSavedPerDayForReplenishment = 'cost_saved_per_day_for_replenishment',
  DestinationBin = 'destination_bin',
  DestinationColumn = 'destination_column',
  Importance = 'importance',
  ImprovedDensityPct = 'improved_density_pct',
  ImprovedEfficiencyPct = 'improved_efficiency_pct',
  ImprovedReplenishmentPct = 'improved_replenishment_pct',
  Material = 'material',
  MovementReason = 'movement_reason',
  Name = 'name',
  PickDate = 'pick_date',
  ProcessDt = 'process_dt',
  Quantity = 'quantity',
  RearrangementCost = 'rearrangement_cost',
  ReplenishmentCost = 'replenishment_cost',
  RunId = 'run_id',
  SourceBin = 'source_bin',
  SourceColumn = 'source_column',
  StorageType = 'storage_type',
  TaskType = 'task_type',
  Version = 'version',
  WarehouseId = 'warehouse_id',
  Zone = 'zone'
}

export type SlottingMovementSort = {
  direction: SortDirection;
  field: SlottingMovementSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingMovementSortFields {
  CurrentMaxQuantity = 'current_max_quantity',
  CurrentMinQuantity = 'current_min_quantity',
  Deployed = 'deployed',
  DestinationBin = 'destination_bin',
  DestinationColumn = 'destination_column',
  DestinationZone = 'destination_zone',
  DistanceSaved = 'distance_saved',
  Id = 'id',
  Importance = 'importance',
  Material = 'material',
  MaterialDescription = 'material_description',
  MoneySaved = 'money_saved',
  MovementReason = 'movement_reason',
  ProcessDt = 'process_dt',
  ProposedMaxQuantity = 'proposed_max_quantity',
  ProposedMinQuantity = 'proposed_min_quantity',
  Quantity = 'quantity',
  RunId = 'run_id',
  RunName = 'run_name',
  SourceBin = 'source_bin',
  SourceColumn = 'source_column',
  SourceZone = 'source_zone',
  TaskType = 'task_type',
  WarehouseId = 'warehouse_id'
}

export type SlottingMovementV1 = {
  __typename?: 'SlottingMovementV1';
  abc_criteria?: Maybe<Scalars['String']['output']>;
  company: Scalars['String']['output'];
  cost_saved_per_quarter?: Maybe<Scalars['Float']['output']>;
  destination_bin?: Maybe<Scalars['String']['output']>;
  destination_column?: Maybe<Scalars['String']['output']>;
  distance_saved?: Maybe<Scalars['Float']['output']>;
  importance?: Maybe<Scalars['Float']['output']>;
  material: Scalars['String']['output'];
  movement_reason?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  process_dt?: Maybe<Scalars['DateTime']['output']>;
  quantity?: Maybe<Scalars['String']['output']>;
  run_id: Scalars['String']['output'];
  source_bin?: Maybe<Scalars['String']['output']>;
  source_column?: Maybe<Scalars['String']['output']>;
  task_type: Scalars['String']['output'];
  version?: Maybe<Scalars['String']['output']>;
};

export type SlottingMovementV1Filter = {
  abc_criteria?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<SlottingMovementV1Filter>>;
  company?: InputMaybe<StringFieldComparison>;
  cost_saved_per_quarter?: InputMaybe<FloatFieldComparison>;
  destination_bin?: InputMaybe<StringFieldComparison>;
  destination_column?: InputMaybe<StringFieldComparison>;
  distance_saved?: InputMaybe<FloatFieldComparison>;
  importance?: InputMaybe<FloatFieldComparison>;
  material?: InputMaybe<StringFieldComparison>;
  movement_reason?: InputMaybe<StringFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SlottingMovementV1Filter>>;
  process_dt?: InputMaybe<DateFieldComparison>;
  quantity?: InputMaybe<StringFieldComparison>;
  run_id?: InputMaybe<StringFieldComparison>;
  source_bin?: InputMaybe<StringFieldComparison>;
  source_column?: InputMaybe<StringFieldComparison>;
  task_type?: InputMaybe<StringFieldComparison>;
  version?: InputMaybe<StringFieldComparison>;
};

export type SlottingMovementV1OffsetConnection = {
  __typename?: 'SlottingMovementV1OffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingMovementV1>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingMovementV1Sort = {
  direction: SortDirection;
  field: SlottingMovementV1SortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingMovementV1SortFields {
  AbcCriteria = 'abc_criteria',
  Company = 'company',
  CostSavedPerQuarter = 'cost_saved_per_quarter',
  DestinationBin = 'destination_bin',
  DestinationColumn = 'destination_column',
  DistanceSaved = 'distance_saved',
  Importance = 'importance',
  Material = 'material',
  MovementReason = 'movement_reason',
  Name = 'name',
  ProcessDt = 'process_dt',
  Quantity = 'quantity',
  RunId = 'run_id',
  SourceBin = 'source_bin',
  SourceColumn = 'source_column',
  TaskType = 'task_type',
  Version = 'version'
}

export type SlottingQuery = {
  avoidCongestion: Scalars['Float']['input'];
  enforceZones: Scalars['Boolean']['input'];
  favorGroundLevel: Scalars['Float']['input'];
  forecasting: Scalars['String']['input'];
  heaviestToLightestPicks: Scalars['Boolean']['input'];
  mixedBinStorage: Scalars['Boolean']['input'];
  pickDensity: Scalars['Float']['input'];
  pickEfficiency: Scalars['Float']['input'];
  putawayDensity: Scalars['Float']['input'];
  putawayEfficiency: Scalars['Float']['input'];
  warehouseId: Scalars['Boolean']['input'];
};

export type SlottingRestrictions = {
  __typename?: 'SlottingRestrictions';
  enforceSingleOrderPicks: Scalars['Boolean']['output'];
  fefoFifoPicking: Scalars['Boolean']['output'];
  fifoPicking: Scalars['Boolean']['output'];
  heaviestToLightestPicks: Scalars['Boolean']['output'];
  preventMixedExpirationDatesInBins: Scalars['Boolean']['output'];
  preventMixedLotsInBins: Scalars['Boolean']['output'];
  preventMixedProductsInBins: Scalars['Boolean']['output'];
  preventSimilarProductsInAdjacentBins: Scalars['Boolean']['output'];
};

export type SlottingRestrictionsInput = {
  /** Slotting restriction field */
  enforceSingleOrderPicks: Scalars['Boolean']['input'];
  /** Slotting restriction field */
  fefoFifoPicking: Scalars['Boolean']['input'];
  /** Slotting restriction field */
  fifoPicking: Scalars['Boolean']['input'];
  /** Slotting restriction field */
  heaviestToLightestPicks: Scalars['Boolean']['input'];
  /** Slotting restriction field */
  preventMixedExpirationDatesInBins: Scalars['Boolean']['input'];
  /** Slotting restriction field */
  preventMixedLotsInBins: Scalars['Boolean']['input'];
  /** Slotting restriction field */
  preventMixedProductsInBins: Scalars['Boolean']['input'];
  /** Slotting restriction field */
  preventSimilarProductsInAdjacentBins: Scalars['Boolean']['input'];
};

export type SlottingRulesInput = {
  allowMixedExpirationDates: Scalars['Boolean']['input'];
  allowMixedLots: Scalars['Boolean']['input'];
  allowMixedProducts: Scalars['Boolean']['input'];
  enforceSingleOrderPicking: Scalars['Boolean']['input'];
  fefoFifoPicking: Scalars['Boolean']['input'];
  fifoPicking: Scalars['Boolean']['input'];
  heaviestToLightestPicks: Scalars['Boolean']['input'];
  similarProductInAdjacentBin: Scalars['Boolean']['input'];
};

export type SlottingRuleset = {
  __typename?: 'SlottingRuleset';
  abcAnalysis?: Maybe<AbcAnalysis>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Entity ID */
  createdByUserId: Scalars['ID']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  equipment: Array<SlottingEquipment>;
  forecasting: Forecasting;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Slotting config last run */
  lastRun?: Maybe<Scalars['DateTime']['output']>;
  maxMovements?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  /** Slotting config private indicator */
  privateRuleset: Scalars['Boolean']['output'];
  restrictions: SlottingRestrictions;
  runCount: Scalars['Int']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  warehouseId: Scalars['String']['output'];
  weights: SlottingWeights;
  workers: Array<SlottingWorker>;
  zones: Array<SlottingZone>;
};

export type SlottingRulesetCreateInput = {
  abcAnalysis?: InputMaybe<AbcAnalysisInput>;
  /** Created by user */
  createdByUserId: Scalars['ID']['input'];
  equipment: Array<SlottingEquipmentInput>;
  forecasting: Forecasting;
  id?: InputMaybe<Scalars['String']['input']>;
  maxMovements?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  /** Slotting config private indicator */
  privateRuleset: Scalars['Boolean']['input'];
  restrictions: SlottingRestrictionsInput;
  warehouseId: Scalars['String']['input'];
  weights: SlottingWeightsInput;
  workers: Array<SlottingWorkerInput>;
  zones: Array<SlottingZoneInput>;
};

export type SlottingRulesetCreateOneInput = {
  /** The record to create */
  slottingRuleset: SlottingRulesetCreateInput;
};

export type SlottingRulesetFilter = {
  and?: InputMaybe<Array<SlottingRulesetFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  createdByUserId?: InputMaybe<IdFilterComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  forecasting?: InputMaybe<ForecastingFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lastRun?: InputMaybe<DateFieldComparison>;
  maxMovements?: InputMaybe<IntFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SlottingRulesetFilter>>;
  privateRuleset?: InputMaybe<BooleanFieldComparison>;
  runCount?: InputMaybe<IntFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<StringFieldComparison>;
};

export type SlottingRulesetOffsetConnection = {
  __typename?: 'SlottingRulesetOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingRuleset>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingRulesetSort = {
  direction: SortDirection;
  field: SlottingRulesetSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingRulesetSortFields {
  CreatedAt = 'createdAt',
  CreatedByUserId = 'createdByUserId',
  DeletedAt = 'deletedAt',
  Forecasting = 'forecasting',
  Id = 'id',
  LastRun = 'lastRun',
  MaxMovements = 'maxMovements',
  Name = 'name',
  PrivateRuleset = 'privateRuleset',
  RunCount = 'runCount',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WarehouseId = 'warehouseId'
}

export type SlottingRulesetUpdateInput = {
  abcAnalysis?: InputMaybe<AbcAnalysisInput>;
  /** Created by user */
  createdByUserId?: InputMaybe<Scalars['ID']['input']>;
  equipment?: InputMaybe<Array<SlottingEquipmentInput>>;
  forecasting?: InputMaybe<Forecasting>;
  id?: InputMaybe<Scalars['String']['input']>;
  maxMovements?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Slotting config private indicator */
  privateRuleset?: InputMaybe<Scalars['Boolean']['input']>;
  restrictions?: InputMaybe<SlottingRestrictionsInput>;
  warehouseId?: InputMaybe<Scalars['String']['input']>;
  weights?: InputMaybe<SlottingWeightsInput>;
  workers?: InputMaybe<Array<SlottingWorkerInput>>;
  zones?: InputMaybe<Array<SlottingZoneInput>>;
};

export type SlottingRulesetUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: SlottingRulesetUpdateInput;
};

export type SlottingRun = {
  __typename?: 'SlottingRun';
  /** Date slotting run was deployed */
  created_at?: Maybe<Scalars['DateTime']['output']>;
  created_by_user_id: Scalars['ID']['output'];
  created_by_user_name: Scalars['String']['output'];
  dataset_id: Scalars['ID']['output'];
  dataset_name: Scalars['String']['output'];
  density?: Maybe<Scalars['Float']['output']>;
  /** Date slotting run was deployed */
  deployed_at?: Maybe<Scalars['DateTime']['output']>;
  deployed_by_user_id?: Maybe<Scalars['ID']['output']>;
  deployed_by_user_name?: Maybe<Scalars['String']['output']>;
  /** Date slotting run was deployed */
  deployment_ended_at?: Maybe<Scalars['DateTime']['output']>;
  distance_saved?: Maybe<Scalars['Float']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  efficiency?: Maybe<Scalars['Float']['output']>;
  /** Entity ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** slotting run */
  input: Scalars['JSONObject']['output'];
  money_saved?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
  overall?: Maybe<Scalars['Float']['output']>;
  ruleset_id: Scalars['ID']['output'];
  ruleset_name: Scalars['String']['output'];
  status: Scalars['String']['output'];
  variant: Scalars['String']['output'];
  warehouse_id: Scalars['ID']['output'];
};

export type SlottingRunCreateInput = {
  datasetId: Scalars['ID']['input'];
  name: Scalars['ID']['input'];
  rulesetId?: InputMaybe<Scalars['ID']['input']>;
};

export type SlottingRunCreateOneInput = {
  /** The record to create */
  slottingRun: SlottingRunCreateInput;
};

export type SlottingRunFilter = {
  and?: InputMaybe<Array<SlottingRunFilter>>;
  created_at?: InputMaybe<DateFieldComparison>;
  created_by_user_id?: InputMaybe<IdFilterComparison>;
  created_by_user_name?: InputMaybe<StringFieldComparison>;
  dataset_id?: InputMaybe<IdFilterComparison>;
  dataset_name?: InputMaybe<StringFieldComparison>;
  density?: InputMaybe<NumberFieldComparison>;
  deployed_at?: InputMaybe<DateFieldComparison>;
  deployed_by_user_id?: InputMaybe<IdFilterComparison>;
  deployed_by_user_name?: InputMaybe<StringFieldComparison>;
  deployment_ended_at?: InputMaybe<DateFieldComparison>;
  distance_saved?: InputMaybe<NumberFieldComparison>;
  duration?: InputMaybe<NumberFieldComparison>;
  efficiency?: InputMaybe<NumberFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  input?: InputMaybe<JsonObjectFilterComparison>;
  money_saved?: InputMaybe<NumberFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SlottingRunFilter>>;
  overall?: InputMaybe<NumberFieldComparison>;
  ruleset_id?: InputMaybe<IdFilterComparison>;
  ruleset_name?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<StringFieldComparison>;
  variant?: InputMaybe<StringFieldComparison>;
  warehouse_id?: InputMaybe<IdFilterComparison>;
};

export type SlottingRunOffsetConnection = {
  __typename?: 'SlottingRunOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingRun>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingRunSort = {
  direction: SortDirection;
  field: SlottingRunSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingRunSortFields {
  CreatedAt = 'created_at',
  CreatedByUserId = 'created_by_user_id',
  CreatedByUserName = 'created_by_user_name',
  DatasetId = 'dataset_id',
  DatasetName = 'dataset_name',
  Density = 'density',
  DeployedAt = 'deployed_at',
  DeployedByUserId = 'deployed_by_user_id',
  DeployedByUserName = 'deployed_by_user_name',
  DeploymentEndedAt = 'deployment_ended_at',
  DistanceSaved = 'distance_saved',
  Duration = 'duration',
  Efficiency = 'efficiency',
  Id = 'id',
  Input = 'input',
  MoneySaved = 'money_saved',
  Name = 'name',
  Overall = 'overall',
  RulesetId = 'ruleset_id',
  RulesetName = 'ruleset_name',
  Status = 'status',
  Variant = 'variant',
  WarehouseId = 'warehouse_id'
}

export type SlottingSetting = {
  __typename?: 'SlottingSetting';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Preferred currency, i.e. USD */
  currency: Scalars['String']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Velocity */
  velocityUOMId: Scalars['ID']['output'];
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
};

export type SlottingSettingCreateInput = {
  /** Preferred currency, i.e. USD */
  currency?: InputMaybe<Scalars['String']['input']>;
  /** Slotting setting items */
  slottingSettingItems: Array<SlottingSettingItemCreateInput>;
  /** Velocity */
  velocityUOMId: Scalars['ID']['input'];
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['input'];
};

export type SlottingSettingCreateOneInput = {
  /** The record to create */
  slottingSetting: SlottingSettingCreateInput;
};

export type SlottingSettingFilter = {
  and?: InputMaybe<Array<SlottingSettingFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  currency?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SlottingSettingFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  velocityUOMId?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type SlottingSettingItem = {
  __typename?: 'SlottingSettingItem';
  /** Cost */
  cost: Scalars['Float']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  equipmentTypeId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity ID */
  slottingSettingId: Scalars['ID']['output'];
  /** Slotting setting type */
  type: SlottingSettingOption;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Velocity */
  velocity: Scalars['Float']['output'];
};

export type SlottingSettingItemCreateInput = {
  /** Cost */
  cost: Scalars['Float']['input'];
  /** Entity ID */
  equipmentTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** Slotting setting type */
  type?: InputMaybe<SlottingSettingOption>;
  /** Velocity */
  velocity: Scalars['Float']['input'];
};

export type SlottingSettingItemFilter = {
  and?: InputMaybe<Array<SlottingSettingItemFilter>>;
  cost?: InputMaybe<FloatFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  equipmentTypeId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<SlottingSettingItemFilter>>;
  slottingSettingId?: InputMaybe<IdFilterComparison>;
  type?: InputMaybe<SlottingSettingOptionFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  velocity?: InputMaybe<FloatFieldComparison>;
};

export type SlottingSettingItemOffsetConnection = {
  __typename?: 'SlottingSettingItemOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingSettingItem>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingSettingItemSort = {
  direction: SortDirection;
  field: SlottingSettingItemSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingSettingItemSortFields {
  Cost = 'cost',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  EquipmentTypeId = 'equipmentTypeId',
  Id = 'id',
  SlottingSettingId = 'slottingSettingId',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  Velocity = 'velocity'
}

export type SlottingSettingItemsForConfigObjectDtoV1 = {
  __typename?: 'SlottingSettingItemsForConfigObjectDtoV1';
  /** Cost */
  cost: Scalars['Float']['output'];
  /** Entity ID */
  equipmentTypeId?: Maybe<Scalars['ID']['output']>;
  /** Slotting setting type */
  type: SlottingSettingOption;
  /** Velocity */
  velocity: Scalars['Float']['output'];
};

export type SlottingSettingItemsForSlottingSettingUpdateDto = {
  /** Slotting setting items to add */
  add?: InputMaybe<Array<SlottingSettingItemCreateInput>>;
  /** IDs to remove */
  remove?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type SlottingSettingOffsetConnection = {
  __typename?: 'SlottingSettingOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingSetting>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export enum SlottingSettingOption {
  Equipment = 'equipment',
  Worker = 'worker'
}

export type SlottingSettingOptionFilterComparison = {
  eq?: InputMaybe<SlottingSettingOption>;
  gt?: InputMaybe<SlottingSettingOption>;
  gte?: InputMaybe<SlottingSettingOption>;
  iLike?: InputMaybe<SlottingSettingOption>;
  in?: InputMaybe<Array<SlottingSettingOption>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<SlottingSettingOption>;
  lt?: InputMaybe<SlottingSettingOption>;
  lte?: InputMaybe<SlottingSettingOption>;
  neq?: InputMaybe<SlottingSettingOption>;
  notILike?: InputMaybe<SlottingSettingOption>;
  notIn?: InputMaybe<Array<SlottingSettingOption>>;
  notLike?: InputMaybe<SlottingSettingOption>;
};

export type SlottingSettingSort = {
  direction: SortDirection;
  field: SlottingSettingSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingSettingSortFields {
  CreatedAt = 'createdAt',
  Currency = 'currency',
  DeletedAt = 'deletedAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  VelocityUomId = 'velocityUOMId',
  WarehouseId = 'warehouseId'
}

export type SlottingSettingUpdateInput = {
  /** Preferred currency, i.e. USD */
  currency?: InputMaybe<Scalars['String']['input']>;
  /** Slotting setting items */
  slottingSettingItems?: InputMaybe<SlottingSettingItemsForSlottingSettingUpdateDto>;
  /** Velocity */
  velocityUOMId?: InputMaybe<Scalars['ID']['input']>;
};

export type SlottingSettingUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: SlottingSettingUpdateInput;
};

export type SlottingSuggestedMaterialPlacement = {
  __typename?: 'SlottingSuggestedMaterialPlacement';
  bin_code: Scalars['String']['output'];
  expiration_dt?: Maybe<Scalars['DateTime']['output']>;
  goods_received_dt?: Maybe<Scalars['DateTime']['output']>;
  inventory_owner?: Maybe<Scalars['String']['output']>;
  license_plate?: Maybe<Scalars['String']['output']>;
  lot?: Maybe<Scalars['String']['output']>;
  material: Scalars['String']['output'];
  material_description?: Maybe<Scalars['String']['output']>;
  material_name?: Maybe<Scalars['String']['output']>;
  plant?: Maybe<Scalars['String']['output']>;
  process_dt: Scalars['DateTime']['output'];
  quantity: Scalars['Float']['output'];
  run_id: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  storage_type?: Maybe<Scalars['String']['output']>;
  warehouse: Scalars['String']['output'];
};

export type SlottingSuggestedMaterialPlacementFilter = {
  and?: InputMaybe<Array<SlottingSuggestedMaterialPlacementFilter>>;
  bin_code?: InputMaybe<StringFieldComparison>;
  expiration_dt?: InputMaybe<DateFieldComparison>;
  goods_received_dt?: InputMaybe<DateFieldComparison>;
  inventory_owner?: InputMaybe<StringFieldComparison>;
  license_plate?: InputMaybe<StringFieldComparison>;
  lot?: InputMaybe<StringFieldComparison>;
  material?: InputMaybe<StringFieldComparison>;
  material_description?: InputMaybe<StringFieldComparison>;
  material_name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<SlottingSuggestedMaterialPlacementFilter>>;
  plant?: InputMaybe<StringFieldComparison>;
  process_dt?: InputMaybe<DateFieldComparison>;
  quantity?: InputMaybe<FloatFieldComparison>;
  run_id?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<StringFieldComparison>;
  storage_type?: InputMaybe<StringFieldComparison>;
  warehouse?: InputMaybe<StringFieldComparison>;
};

export type SlottingSuggestedMaterialPlacementOffsetConnection = {
  __typename?: 'SlottingSuggestedMaterialPlacementOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SlottingSuggestedMaterialPlacement>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type SlottingSuggestedMaterialPlacementSort = {
  direction: SortDirection;
  field: SlottingSuggestedMaterialPlacementSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SlottingSuggestedMaterialPlacementSortFields {
  BinCode = 'bin_code',
  ExpirationDt = 'expiration_dt',
  GoodsReceivedDt = 'goods_received_dt',
  InventoryOwner = 'inventory_owner',
  LicensePlate = 'license_plate',
  Lot = 'lot',
  Material = 'material',
  MaterialDescription = 'material_description',
  MaterialName = 'material_name',
  Plant = 'plant',
  ProcessDt = 'process_dt',
  Quantity = 'quantity',
  RunId = 'run_id',
  Status = 'status',
  StorageType = 'storage_type',
  Warehouse = 'warehouse'
}

export type SlottingWeights = {
  __typename?: 'SlottingWeights';
  avoidCongestion: Scalars['Float']['output'];
  favorGroundLevel: Scalars['Float']['output'];
  pickDensity: Scalars['Float']['output'];
  pickEfficiency: Scalars['Float']['output'];
  putawayDensity: Scalars['Float']['output'];
  putawayEfficiency: Scalars['Float']['output'];
};

export type SlottingWeightsInput = {
  avoidCongestion: Scalars['Float']['input'];
  favorGroundLevel: Scalars['Float']['input'];
  pickDensity: Scalars['Float']['input'];
  pickEfficiency: Scalars['Float']['input'];
  putawayDensity: Scalars['Float']['input'];
  putawayEfficiency: Scalars['Float']['input'];
};

export type SlottingWorker = {
  __typename?: 'SlottingWorker';
  id: Scalars['ID']['output'];
  quantity: Scalars['Float']['output'];
};

export type SlottingWorkerInput = {
  id: Scalars['ID']['input'];
  quantity: Scalars['Float']['input'];
};

export type SlottingZone = {
  __typename?: 'SlottingZone';
  fixedBins: Scalars['Boolean']['output'];
  maxFixedBinsPerProduct: Scalars['Float']['output'];
  restrictions: SlottingRestrictions;
  useWarehouseDefault: Scalars['Boolean']['output'];
  weights: SlottingWeights;
  zoneId: Scalars['String']['output'];
};

export type SlottingZoneInput = {
  fixedBins: Scalars['Boolean']['input'];
  maxFixedBinsPerProduct: Scalars['Float']['input'];
  restrictions: SlottingRestrictionsInput;
  useWarehouseDefault: Scalars['Boolean']['input'];
  weights: SlottingWeightsInput;
  zoneId?: InputMaybe<Scalars['String']['input']>;
};

export enum SmartScanContext {
  TaskAgenda = 'taskAgenda'
}

export type SmartScanMetaData = {
  __typename?: 'SmartScanMetaData';
  /** License Plate Detail view record */
  licensePlate?: Maybe<LicensePlateDetail>;
  /** Mobile view task record */
  task?: Maybe<MobileViewTask>;
  /** Task type */
  taskType?: Maybe<TaskType>;
};

export type SmartScanResource = {
  __typename?: 'SmartScanResource';
  /** Suggestion of action to be carried out in a smart scan response */
  action: Scalars['String']['output'];
  /** Metadata json object providing relevant data to carry out the provided action */
  metaData: Array<SmartScanMetaData>;
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type SourceDestinationArea = {
  __typename?: 'SourceDestinationArea';
  destinationCode?: Maybe<Scalars['String']['output']>;
  sourceCode?: Maybe<Scalars['String']['output']>;
};

export enum StandardUomCategory {
  Distance = 'distance',
  Quantity = 'quantity',
  Velocity = 'velocity',
  Volume = 'volume',
  Weight = 'weight'
}

export type StandardUomCategoryFilterComparison = {
  eq?: InputMaybe<StandardUomCategory>;
  gt?: InputMaybe<StandardUomCategory>;
  gte?: InputMaybe<StandardUomCategory>;
  iLike?: InputMaybe<StandardUomCategory>;
  in?: InputMaybe<Array<StandardUomCategory>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<StandardUomCategory>;
  lt?: InputMaybe<StandardUomCategory>;
  lte?: InputMaybe<StandardUomCategory>;
  neq?: InputMaybe<StandardUomCategory>;
  notILike?: InputMaybe<StandardUomCategory>;
  notIn?: InputMaybe<Array<StandardUomCategory>>;
  notLike?: InputMaybe<StandardUomCategory>;
};

export enum StandardUomSystem {
  Imperial = 'imperial',
  Metric = 'metric'
}

export type StandardUomSystemFilterComparison = {
  eq?: InputMaybe<StandardUomSystem>;
  gt?: InputMaybe<StandardUomSystem>;
  gte?: InputMaybe<StandardUomSystem>;
  iLike?: InputMaybe<StandardUomSystem>;
  in?: InputMaybe<Array<StandardUomSystem>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<StandardUomSystem>;
  lt?: InputMaybe<StandardUomSystem>;
  lte?: InputMaybe<StandardUomSystem>;
  neq?: InputMaybe<StandardUomSystem>;
  notILike?: InputMaybe<StandardUomSystem>;
  notIn?: InputMaybe<Array<StandardUomSystem>>;
  notLike?: InputMaybe<StandardUomSystem>;
};

export enum StartOrFinish {
  Finish = 'finish',
  Start = 'start'
}

export type StockCreateStockFromProductionDto = {
  /** Entity code */
  lotCode?: InputMaybe<Scalars['String']['input']>;
  /** Entity code */
  productCode: Scalars['String']['input'];
  /** Quantity of product */
  quantity: Scalars['String']['input'];
  /** Entity code */
  stockStatusCode?: InputMaybe<Scalars['String']['input']>;
  /** Entity code */
  unitOfMeasureCode: Scalars['String']['input'];
};

export type StockStatusMapping = {
  __typename?: 'StockStatusMapping';
  editable?: Maybe<Scalars['Boolean']['output']>;
  mapped?: Maybe<Scalars['Boolean']['output']>;
  stockStatusCode?: Maybe<Scalars['String']['output']>;
  stockStatusId?: Maybe<Scalars['ID']['output']>;
  stockStatusLabel?: Maybe<Scalars['String']['output']>;
};

/** Stock Status Type model */
export type StockStatusType = {
  __typename?: 'StockStatusType';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Stock status used when converting ERP stock status to Fulfilld stock status */
  default?: Maybe<Scalars['Boolean']['output']>;
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description: Scalars['String']['output'];
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity's label */
  label: Scalars['String']['output'];
  /** Entity's SAP stock status type ID (foreign key) */
  ptoStockStatusTypeId?: Maybe<Scalars['ID']['output']>;
  sapStockStatusType?: Maybe<SapStockStatusType>;
  /** Entity's SAP stock status type ID (foreign key) */
  sapStockStatusTypeId?: Maybe<Scalars['ID']['output']>;
  /** Determines if stock status type is in use */
  stockStatusTypeStatus?: Maybe<StockStatusTypeStatus>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type StockStatusTypeCopy = {
  /** Entity code */
  code: Scalars['String']['input'];
  /** Description of entity */
  description: Scalars['String']['input'];
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Entity's label */
  label: Scalars['String']['input'];
  /** Entity's SAP stock status type ID (foreign key) */
  sapStockStatusTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** Determines if stock status type is in use */
  stockStatusTypeStatus?: InputMaybe<StockStatusTypeStatus>;
};

export type StockStatusTypeCreate = {
  /** Entity code */
  code: Scalars['String']['input'];
  /** Description of entity */
  description: Scalars['String']['input'];
  /** Entity's label */
  label: Scalars['String']['input'];
  /** Entity's SAP stock status type ID (foreign key) */
  sapStockStatusTypeId: Scalars['ID']['input'];
  /** Determines if stock status type is in use */
  stockStatusTypeStatus?: InputMaybe<StockStatusTypeStatus>;
};

export type StockStatusTypeCreateOneInput = {
  /** The record to create */
  stockStatusType: StockStatusTypeCreate;
};

export type StockStatusTypeDeleteOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
};

export type StockStatusTypeFilter = {
  and?: InputMaybe<Array<StockStatusTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  default?: InputMaybe<BooleanFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<StockStatusTypeFilter>>;
  ptoStockStatusTypeId?: InputMaybe<IdFilterComparison>;
  sapStockStatusTypeId?: InputMaybe<IdFilterComparison>;
  stockStatusTypeStatus?: InputMaybe<StockStatusTypeStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type StockStatusTypeOffsetConnection = {
  __typename?: 'StockStatusTypeOffsetConnection';
  /** Array of nodes. */
  nodes: Array<StockStatusType>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type StockStatusTypeSort = {
  direction: SortDirection;
  field: StockStatusTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum StockStatusTypeSortFields {
  Code = 'code',
  CreatedAt = 'createdAt',
  Default = 'default',
  DeletedAt = 'deletedAt',
  Description = 'description',
  Id = 'id',
  Label = 'label',
  PtoStockStatusTypeId = 'ptoStockStatusTypeId',
  SapStockStatusTypeId = 'sapStockStatusTypeId',
  StockStatusTypeStatus = 'stockStatusTypeStatus',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export enum StockStatusTypeStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type StockStatusTypeStatusFilterComparison = {
  eq?: InputMaybe<StockStatusTypeStatus>;
  gt?: InputMaybe<StockStatusTypeStatus>;
  gte?: InputMaybe<StockStatusTypeStatus>;
  iLike?: InputMaybe<StockStatusTypeStatus>;
  in?: InputMaybe<Array<StockStatusTypeStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<StockStatusTypeStatus>;
  lt?: InputMaybe<StockStatusTypeStatus>;
  lte?: InputMaybe<StockStatusTypeStatus>;
  neq?: InputMaybe<StockStatusTypeStatus>;
  notILike?: InputMaybe<StockStatusTypeStatus>;
  notIn?: InputMaybe<Array<StockStatusTypeStatus>>;
  notLike?: InputMaybe<StockStatusTypeStatus>;
};

export type StockStatusTypeUpdate = {
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Stock status used when converting ERP stock status to Fulfilld stock status */
  default?: InputMaybe<Scalars['Boolean']['input']>;
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Entity's label */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Entity's SAP stock status type ID (foreign key) */
  sapStockStatusTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** Determines if stock status type is in use */
  stockStatusTypeStatus?: InputMaybe<StockStatusTypeStatus>;
};

export type Stop = {
  aisle: Scalars['String']['input'];
  column: Scalars['String']['input'];
};

/** Stored image model */
export type StoredImage = {
  __typename?: 'StoredImage';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Original image file uploaded */
  original: Scalars['String']['output'];
  /** Thumbnail version of image */
  thumbnail?: Maybe<Scalars['String']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Web optimized version of image */
  web: Scalars['String']['output'];
};

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  iLike?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notILike?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type SysAdminOutboundDeliveryCreateDtoInput = {
  plant: Scalars['String']['input'];
  requestedDeliveryDate: Scalars['String']['input'];
  storageLocation: Scalars['String']['input'];
};

/** Task model */
export type Task = {
  __typename?: 'Task';
  /** Deleted at date */
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  assignedByUserId?: Maybe<Scalars['ID']['output']>;
  assignedTeam?: Maybe<Team>;
  assignedTeamId?: Maybe<Scalars['ID']['output']>;
  assignedUserId?: Maybe<Scalars['ID']['output']>;
  /** Quantity of product */
  baseQuantity?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  code: Scalars['String']['output'];
  /** Date and time a task is completed */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** x coordinate location */
  completedAtLocationX?: Maybe<Scalars['Float']['output']>;
  /** Y coordinate location */
  completedAtLocationY?: Maybe<Scalars['Float']['output']>;
  completedByUserId?: Maybe<Scalars['ID']['output']>;
  /** Unit of measure ID */
  completedInUnitOfMeasureId?: Maybe<Scalars['ID']['output']>;
  /**
   * Date the task was completed
   * @deprecated no longer in use
   */
  completionDate?: Maybe<Scalars['DateTime']['output']>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Unit of measure ID */
  createdInUnitOfMeasureId?: Maybe<Scalars['ID']['output']>;
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  delivery?: Maybe<Delivery>;
  /** Delivery ID (foreign key) */
  deliveryId?: Maybe<Scalars['ID']['output']>;
  deliveryItem?: Maybe<DeliveryItem>;
  /** Delivery item ID (foreign key) */
  deliveryItemId?: Maybe<Scalars['ID']['output']>;
  destinationBin?: Maybe<Bin>;
  /** Destination bin ID (foreign key) */
  destinationBinId?: Maybe<Scalars['ID']['output']>;
  destinationLicensePlate?: Maybe<LicensePlate>;
  /** Destination license plate ID */
  destinationLicensePlateId?: Maybe<Scalars['ID']['output']>;
  destinationLot?: Maybe<Lot>;
  /** Destination lot ID (foreign key) */
  destinationLotId?: Maybe<Scalars['ID']['output']>;
  destinationStockStatus?: Maybe<StockStatusType>;
  /** Destination stock status type ID (foreign key) */
  destinationStockStatusId?: Maybe<Scalars['ID']['output']>;
  /** Date the task is or was due */
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  /** Type of equipment, i.e. forklift */
  equipmentTypeId?: Maybe<Scalars['ID']['output']>;
  fulfillmentItem?: Maybe<FulfillmentItem>;
  /** Fulfillment item id (foreign key) */
  fulfillmentItemId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  inventoryCategory: PmInventoryCategory;
  linkedTaskId?: Maybe<Scalars['ID']['output']>;
  /** Meta data stored on the entity in json format */
  metaData?: Maybe<Scalars['JSON']['output']>;
  /** Entity's parent task ID */
  parentTaskId?: Maybe<Scalars['ID']['output']>;
  product?: Maybe<Product>;
  /** Entity's product ID (foreign key) */
  productId?: Maybe<Scalars['ID']['output']>;
  /** Quantity of product */
  quantity?: Maybe<Scalars['String']['output']>;
  /** Task that is display only */
  readonly?: Maybe<Scalars['Boolean']['output']>;
  /** Referring doc */
  referringDoc?: Maybe<Scalars['String']['output']>;
  /** Reference item */
  referringDocItem?: Maybe<Scalars['String']['output']>;
  sourceBin?: Maybe<Bin>;
  /** Source bin ID (foreign key) */
  sourceBinId?: Maybe<Scalars['ID']['output']>;
  sourceLicensePlate?: Maybe<LicensePlate>;
  /** Source License Plate Id */
  sourceLicensePlateId?: Maybe<Scalars['ID']['output']>;
  sourceLot?: Maybe<Lot>;
  /** Source lot ID (foreign key) */
  sourceLotId?: Maybe<Scalars['ID']['output']>;
  sourceStockStatus?: Maybe<StockStatusType>;
  /** Source stock status type ID (foreign key) */
  sourceStockStatusId?: Maybe<Scalars['ID']['output']>;
  /** Date and time a task is started */
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  /** x coordinate location */
  startedAtLocationX?: Maybe<Scalars['Float']['output']>;
  /** Y coordinate location */
  startedAtLocationY?: Maybe<Scalars['Float']['output']>;
  startedByUserId?: Maybe<Scalars['ID']['output']>;
  /** Task status, i.e Not Started */
  status?: Maybe<TaskStatus>;
  /** Entity ID */
  taskGroupId?: Maybe<Scalars['ID']['output']>;
  /** Position in order within a task group */
  taskGroupPosition?: Maybe<Scalars['Float']['output']>;
  taskType?: Maybe<TaskType>;
  /** Task type ID (foreign key) */
  taskTypeId: Scalars['ID']['output'];
  /** @deprecated deprecated - use assignedTeam */
  team?: Maybe<Team>;
  /**
   * Entity's team ID (foreign key)
   * @deprecated no longer in use
   */
  teamId?: Maybe<Scalars['ID']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /**
   * Entity's user ID (foreign key)
   * @deprecated no longer in use
   */
  userId?: Maybe<Scalars['ID']['output']>;
  warehouse?: Maybe<Warehouse>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
};

export type TaskApprovePiInput = {
  /** Entity's team ID (foreign key) */
  assignedTeamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  taskId: Scalars['ID']['input'];
};

export type TaskAssignDto = {
  /** Entity's team ID (foreign key) */
  assignTeamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  assignUserId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's task ID (foreign key) */
  taskId: Scalars['ID']['input'];
};

export type TaskBlockInput = {
  /** Entity's bin ID (foreign key) */
  binId: Scalars['String']['input'];
  /** Entity's task ID (foreign key) */
  taskId: Scalars['String']['input'];
};

export type TaskBlockInputDto = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: TaskBlockInput;
};

export type TaskCancelManyDto = {
  /** Entity ID */
  id: Scalars['String']['input'];
};

export type TaskCancelPutawayInput = {
  /** Delivery ID (foreign key) */
  id: Scalars['ID']['input'];
};

export type TaskCompleteBinToBinInput = {
  /** Unit of measure ID */
  completedInUnitOfMeasureId: Scalars['ID']['input'];
  /** Quantity of product */
  quantity: Scalars['String']['input'];
  /** Entity's task ID (foreign key) */
  taskId?: InputMaybe<Scalars['ID']['input']>;
};

export type TaskCompleteLicensePlateBinToBinMovement = {
  /** Entity's user ID (foreign key) */
  taskId?: InputMaybe<Scalars['ID']['input']>;
};

export type TaskCompleteLicensePlateBinToBinMovementInputDto = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: TaskCompleteLicensePlateBinToBinMovement;
};

export type TaskCompleteLicensePlateMovement = {
  /** Unit of measure ID */
  completedInUnitOfMeasureId?: InputMaybe<Scalars['ID']['input']>;
  /** Quantity of product */
  quantity: Scalars['String']['input'];
  /** Entity's user ID (foreign key) */
  taskId?: InputMaybe<Scalars['ID']['input']>;
};

export type TaskCompleteLicensePlateMovementInputDto = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: TaskCompleteLicensePlateMovement;
};

export type TaskCompleteLoadInput = {
  /** Entity's task ID (foreign key) */
  licensePlateId: Scalars['ID']['input'];
};

export type TaskCompleteManyLicensePlatePutawayInput = {
  /** Entity ID */
  licensePlateId: Scalars['ID']['input'];
};

export type TaskCompleteManyLicensePlatePutawayResponse = {
  __typename?: 'TaskCompleteManyLicensePlatePutawayResponse';
  /** Successful license plates */
  successful: Array<LicensePlate>;
  /** Unsuccessful license plates */
  unsuccessful: Array<LicensePlate>;
};

export type TaskCompleteManyPickToStagingInput = {
  /** Entity's task ID (foreign key) */
  taskId: Scalars['ID']['input'];
};

export type TaskCompleteOneIssueStockInput = {
  /** Entity's task ID (foreign key) */
  taskId?: InputMaybe<Scalars['ID']['input']>;
};

export type TaskCompleteOneLoadInput = {
  /** Entity's task ID (foreign key) */
  licensePlateId: Scalars['ID']['input'];
  /** Entity's task ID (foreign key) */
  taskId?: InputMaybe<Scalars['ID']['input']>;
};

export type TaskCompleteOneOfManyUnloadInput = {
  /** Unit of measure ID */
  completedInUnitOfMeasureId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's license plate ID (foreign key) */
  licensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** Quantity of product */
  quantity?: InputMaybe<Scalars['String']['input']>;
  /** Entity's task ID (foreign key) */
  taskId: Scalars['ID']['input'];
};

export type TaskCompleteOnePickToStagingInput = {
  /** Entity's task ID (foreign key) */
  taskId: Scalars['ID']['input'];
};

export type TaskCompleteOneUnloadInput = {
  /** Unit of measure ID */
  completedInUnitOfMeasureId?: InputMaybe<Scalars['ID']['input']>;
  /** Destination bin ID */
  destinationBinId: Scalars['ID']['input'];
  /** Entity's license plate ID (foreign key) */
  licensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** Quantity of product */
  quantity?: InputMaybe<Scalars['String']['input']>;
  /** Entity's task ID (foreign key) */
  taskId: Scalars['ID']['input'];
};

export type TaskCompletePiInput = {
  /** Type of physical inventory adjustment */
  adjustmentType: AdjustmentType;
  /** Unit of measure ID */
  completedInUnitOfMeasureId?: InputMaybe<Scalars['ID']['input']>;
  /** Damaged quantity during cycle count */
  damagedCount?: InputMaybe<Scalars['String']['input']>;
  /** New quantity during cycle count */
  newCount?: InputMaybe<Scalars['String']['input']>;
};

export type TaskCompletePickInput = {
  /** Unit of measure ID */
  completedInUnitOfMeasureId: Scalars['ID']['input'];
  /** Source License Plate Id */
  licensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** Quantity of product */
  quantity: Scalars['String']['input'];
  /** Source bin ID (foreign key) */
  sourceBinId: Scalars['ID']['input'];
  /** Source lot ID (foreign key) */
  sourceLotId?: InputMaybe<Scalars['ID']['input']>;
};

export type TaskCompletePutawayInput = {
  /** Unit of measure ID */
  completedInUnitOfMeasureId?: InputMaybe<Scalars['ID']['input']>;
  /** Destination bin ID (foreign key) */
  destinationBinId: Scalars['ID']['input'];
  /** Quantity of product */
  quantity?: InputMaybe<Scalars['String']['input']>;
};

export type TaskCompleteUnloadInput = {
  /** Entity's task ID (foreign key) */
  licensePlateId: Scalars['ID']['input'];
  /** Entity's task ID (foreign key) */
  taskId?: InputMaybe<Scalars['ID']['input']>;
};

export type TaskCreateBinPiInput = {
  /** Entity's team ID (foreign key) */
  assignedTeamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity ID */
  binId: Scalars['ID']['input'];
  /** Date the task is or was due */
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskCreateBinToBinInput = {
  /** Entity's team ID (foreign key) */
  assignedTeamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  /** Autocomplete the task on creation */
  autocomplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Unit of measure ID */
  createdInUnitOfMeasureId: Scalars['ID']['input'];
  /** Entity ID */
  deliveryItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Destination bin ID */
  destinationBinId: Scalars['ID']['input'];
  /** Destination lot ID (foreign key) */
  destinationLotId?: InputMaybe<Scalars['ID']['input']>;
  /** Destination stock status type ID (foreign key) */
  destinationStockStatusId?: InputMaybe<Scalars['ID']['input']>;
  /** Date the task is or was due */
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Entity ID */
  fulfillmentItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Meta data stored on the entity in json format */
  metaData?: InputMaybe<Scalars['JSON']['input']>;
  /** Entity's product ID (foreign key) */
  productId: Scalars['ID']['input'];
  /** Quantity of product */
  quantity: Scalars['String']['input'];
  /** Source bin ID */
  sourceBinId: Scalars['ID']['input'];
  /** Source lot ID (foreign key) */
  sourceLotId?: InputMaybe<Scalars['ID']['input']>;
  /** Source stock status type ID (foreign key) */
  sourceStockStatusId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['input'];
};

export type TaskCreateInput = {
  /** Entity's team ID (foreign key) */
  assignedTeamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
};

export type TaskCreateOneInput = {
  /** The record to create */
  task: TaskCreateInput;
};

export type TaskCreateOnePickToStagingInput = {
  /** Entity's team ID (foreign key) */
  assignedTeamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  /** Fulfillment item id (foreign key) */
  fulfillmentItemId: Scalars['ID']['input'];
  /** Entity's product ID (foreign key) */
  productId: Scalars['ID']['input'];
  /** Quantity of product */
  quantity: Scalars['String']['input'];
  /** Entity's bin ID (foreign key) */
  sourceBinId: Scalars['ID']['input'];
  /** Entity's license plate ID (foreign key) */
  sourceLicensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** Source lot ID (foreign key) */
  sourceLotId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's stock status type ID (foreign key) */
  sourceStockStatusId: Scalars['ID']['input'];
};

export type TaskCreatePiInput = {
  /** Type of physical inventory adjustment */
  adjustmentType?: InputMaybe<AdjustmentType>;
  /** Entity's team ID (foreign key) */
  assignedTeamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  /** Autocomplete the task on creation */
  autocomplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Entity's bin ID (foreign key) */
  binId: Scalars['ID']['input'];
  /** Unit of measure ID */
  createdInUnitOfMeasureId?: InputMaybe<Scalars['ID']['input']>;
  /** Damaged quantity during cycle count */
  damagedCount?: InputMaybe<Scalars['String']['input']>;
  /** Entity's license plate ID (foreign key) */
  licensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's lot ID (foreign key) */
  lotId?: InputMaybe<Scalars['ID']['input']>;
  /** New quantity during cycle count */
  newCount?: InputMaybe<Scalars['String']['input']>;
  /** Entity's product ID (foreign key) */
  productId: Scalars['ID']['input'];
  /** Entity's stock status type ID (foreign key) */
  stockStatusId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['input'];
};

export type TaskCreatePickInput = {
  /** Entity's team ID (foreign key) */
  assignedTeamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  /** Fulfillment Id */
  fulfillmentItemsToPick: Array<TaskPickFulfillmentItem>;
  /** Fulfillment Id */
  id: Scalars['ID']['input'];
};

export type TaskCreatePickToStagingResourceDto = {
  __typename?: 'TaskCreatePickToStagingResourceDto';
  /** Fulfillment items with a pick to staging task being created */
  created: Array<FulfillmentItem>;
  /** Fulfillment items with a pick to staging that has already been created */
  hasTask: Array<FulfillmentItem>;
  /** Fulfillment items with no stock available to create pick to staging tasks */
  noStockFound: Array<FulfillmentItem>;
};

export type TaskCreatePutawayInput = {
  /** Entity's team ID (foreign key) */
  assignedTeamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  /** Delivery ID (foreign key) */
  id: Scalars['ID']['input'];
  looseProducts?: InputMaybe<Array<TaskLooseProductsInput>>;
};

export type TaskCreateStockFromProductionDto = {
  /** Entity code */
  binCode: Scalars['String']['input'];
  /** License Plate To Create */
  licensePlate?: InputMaybe<LicensePlateCreateStockFromProductionDto>;
  /** Stock to create */
  stock: Array<StockCreateStockFromProductionDto>;
  /** Entity code */
  warehouseCode: Scalars['String']['input'];
};

export type TaskDispositionImages = {
  /** ID of stored image */
  imageId: Scalars['ID']['input'];
};

export type TaskFilter = {
  and?: InputMaybe<Array<TaskFilter>>;
  assignedAt?: InputMaybe<DateFieldComparison>;
  assignedByUserId?: InputMaybe<IdFilterComparison>;
  assignedTeamId?: InputMaybe<IdFilterComparison>;
  assignedUserId?: InputMaybe<IdFilterComparison>;
  baseQuantity?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  completedAt?: InputMaybe<DateFieldComparison>;
  completedAtLocationX?: InputMaybe<FloatFieldComparison>;
  completedAtLocationY?: InputMaybe<FloatFieldComparison>;
  completedByUserId?: InputMaybe<IdFilterComparison>;
  completionDate?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  deliveryId?: InputMaybe<IdFilterComparison>;
  deliveryItemId?: InputMaybe<IdFilterComparison>;
  destinationBinId?: InputMaybe<IdFilterComparison>;
  destinationLicensePlateId?: InputMaybe<IdFilterComparison>;
  destinationLotId?: InputMaybe<IdFilterComparison>;
  destinationStockStatusId?: InputMaybe<IdFilterComparison>;
  dueDate?: InputMaybe<DateFieldComparison>;
  equipmentTypeId?: InputMaybe<IdFilterComparison>;
  fulfillmentItemId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  inventoryCategory?: InputMaybe<PmInventoryCategoryFilterComparison>;
  linkedTaskId?: InputMaybe<IdFilterComparison>;
  metaData?: InputMaybe<JsonFilterComparison>;
  or?: InputMaybe<Array<TaskFilter>>;
  parentTaskId?: InputMaybe<IdFilterComparison>;
  productId?: InputMaybe<IdFilterComparison>;
  quantity?: InputMaybe<StringFieldComparison>;
  readonly?: InputMaybe<BooleanFieldComparison>;
  referringDoc?: InputMaybe<StringFieldComparison>;
  referringDocItem?: InputMaybe<StringFieldComparison>;
  sourceBinId?: InputMaybe<IdFilterComparison>;
  sourceLicensePlateId?: InputMaybe<IdFilterComparison>;
  sourceLotId?: InputMaybe<IdFilterComparison>;
  sourceStockStatusId?: InputMaybe<IdFilterComparison>;
  startedAt?: InputMaybe<DateFieldComparison>;
  startedAtLocationX?: InputMaybe<FloatFieldComparison>;
  startedAtLocationY?: InputMaybe<FloatFieldComparison>;
  startedByUserId?: InputMaybe<IdFilterComparison>;
  status?: InputMaybe<TaskStatusFilterComparison>;
  taskGroupId?: InputMaybe<IdFilterComparison>;
  taskGroupPosition?: InputMaybe<FloatFieldComparison>;
  taskType?: InputMaybe<TaskFilterTaskTypeFilter>;
  taskTypeId?: InputMaybe<IdFilterComparison>;
  teamId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  userId?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type TaskFilterTaskTypeFilter = {
  and?: InputMaybe<Array<TaskFilterTaskTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<TaskFilterTaskTypeFilter>>;
  referenceCategory?: InputMaybe<TaskTypeReferenceCategoryFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

/** Task group model */
export type TaskGroup = {
  __typename?: 'TaskGroup';
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  assignedByUserId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  assignedTeamId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  assignedUserId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  code: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** x coordinate location */
  completedAtLocationX?: Maybe<Scalars['Float']['output']>;
  /** Y coordinate location */
  completedAtLocationY?: Maybe<Scalars['Float']['output']>;
  /** Entity ID */
  completedByUserId?: Maybe<Scalars['ID']['output']>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  plannedCompletionAt?: Maybe<Scalars['DateTime']['output']>;
  plannedStartAt?: Maybe<Scalars['DateTime']['output']>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  /** x coordinate location */
  startedAtLocationX?: Maybe<Scalars['Float']['output']>;
  /** Y coordinate location */
  startedAtLocationY?: Maybe<Scalars['Float']['output']>;
  /** Entity ID */
  startedByUserId?: Maybe<Scalars['ID']['output']>;
  /** Task status, i.e Not Started */
  status: TaskGroupTaskStatus;
  /** Entity ID */
  taskTypeId?: Maybe<Scalars['ID']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  warehouse: Warehouse;
  /** Entity ID */
  warehouseId: Scalars['ID']['output'];
};

export type TaskGroupCreateInput = {
  /** Task status, i.e Not Started */
  status: TaskStatus;
  /** Entity ID */
  taskTypeId: Scalars['ID']['input'];
  /** Entity ID */
  teamId: Scalars['ID']['input'];
  /** Entity ID */
  userId: Scalars['ID']['input'];
  /** Entity ID */
  warehouseId: Scalars['ID']['input'];
};

export type TaskGroupCreateOneInput = {
  /** The record to create */
  taskGroup: TaskGroupCreateInput;
};

export type TaskGroupFilter = {
  and?: InputMaybe<Array<TaskGroupFilter>>;
  assignedAt?: InputMaybe<DateFieldComparison>;
  assignedByUserId?: InputMaybe<IdFilterComparison>;
  assignedTeamId?: InputMaybe<IdFilterComparison>;
  assignedUserId?: InputMaybe<IdFilterComparison>;
  code?: InputMaybe<StringFieldComparison>;
  completedAt?: InputMaybe<DateFieldComparison>;
  completedAtLocationX?: InputMaybe<FloatFieldComparison>;
  completedAtLocationY?: InputMaybe<FloatFieldComparison>;
  completedByUserId?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<TaskGroupFilter>>;
  plannedCompletionAt?: InputMaybe<DateFieldComparison>;
  plannedStartAt?: InputMaybe<DateFieldComparison>;
  startedAt?: InputMaybe<DateFieldComparison>;
  startedAtLocationX?: InputMaybe<FloatFieldComparison>;
  startedAtLocationY?: InputMaybe<FloatFieldComparison>;
  startedByUserId?: InputMaybe<IdFilterComparison>;
  status?: InputMaybe<TaskGroupTaskStatusFilterComparison>;
  taskTypeId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type TaskGroupOffsetConnection = {
  __typename?: 'TaskGroupOffsetConnection';
  /** Array of nodes. */
  nodes: Array<TaskGroup>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type TaskGroupSort = {
  direction: SortDirection;
  field: TaskGroupSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum TaskGroupSortFields {
  AssignedAt = 'assignedAt',
  AssignedByUserId = 'assignedByUserId',
  AssignedTeamId = 'assignedTeamId',
  AssignedUserId = 'assignedUserId',
  Code = 'code',
  CompletedAt = 'completedAt',
  CompletedAtLocationX = 'completedAtLocationX',
  CompletedAtLocationY = 'completedAtLocationY',
  CompletedByUserId = 'completedByUserId',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  PlannedCompletionAt = 'plannedCompletionAt',
  PlannedStartAt = 'plannedStartAt',
  StartedAt = 'startedAt',
  StartedAtLocationX = 'startedAtLocationX',
  StartedAtLocationY = 'startedAtLocationY',
  StartedByUserId = 'startedByUserId',
  Status = 'status',
  TaskTypeId = 'taskTypeId',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WarehouseId = 'warehouseId'
}

export type TaskGroupStartDto = {
  /** x coordinate location */
  startedAtLocationX?: InputMaybe<Scalars['Float']['input']>;
  /** Y coordinate location */
  startedAtLocationY?: InputMaybe<Scalars['Float']['input']>;
  /** Task Group Id */
  taskGroupId: Scalars['ID']['input'];
};

export type TaskGroupTaskResource = {
  __typename?: 'TaskGroupTaskResource';
  estimatedTimeToComplete: Scalars['Float']['output'];
  taskCount: Scalars['Int']['output'];
};

export enum TaskGroupTaskStatus {
  Cancelled = 'cancelled',
  Complete = 'complete',
  InProgress = 'inProgress',
  NotStarted = 'notStarted',
  Planned = 'planned'
}

export type TaskGroupTaskStatusFilterComparison = {
  eq?: InputMaybe<TaskGroupTaskStatus>;
  gt?: InputMaybe<TaskGroupTaskStatus>;
  gte?: InputMaybe<TaskGroupTaskStatus>;
  iLike?: InputMaybe<TaskGroupTaskStatus>;
  in?: InputMaybe<Array<TaskGroupTaskStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<TaskGroupTaskStatus>;
  lt?: InputMaybe<TaskGroupTaskStatus>;
  lte?: InputMaybe<TaskGroupTaskStatus>;
  neq?: InputMaybe<TaskGroupTaskStatus>;
  notILike?: InputMaybe<TaskGroupTaskStatus>;
  notIn?: InputMaybe<Array<TaskGroupTaskStatus>>;
  notLike?: InputMaybe<TaskGroupTaskStatus>;
};

export type TaskGroupUpdateInput = {
  /** Task status, i.e Not Started */
  status?: InputMaybe<TaskStatus>;
  /** Entity ID */
  taskTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity ID */
  teamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity ID */
  userId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity ID */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type TaskGroupUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: TaskGroupUpdateInput;
};

export type TaskLicensePlateStockStatusCompleteOneDto = {
  licensePlateId?: InputMaybe<Scalars['ID']['input']>;
  taskId?: InputMaybe<Scalars['ID']['input']>;
};

export type TaskLicensePlateStockStatusImages = {
  /** ID of stored image */
  imageId: Scalars['ID']['input'];
};

export type TaskLicensePlateUnpackInputCompleteDto = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: LicensePlateUnpackLicensePlateCompleteDto;
};

export type TaskLicensePlateUnpackInputCreateDto = {
  /** The record to create */
  licensePlateUnpack: LicensePlateUnpackLicensePlateDto;
};

export type TaskLooseProductsInput = {
  /** Entity's bin ID (foreign key) */
  binId: Scalars['ID']['input'];
  /** Unit of measure ID */
  createdInUnitOfMeasureId: Scalars['ID']['input'];
  /** Entity's lot ID (foreign key) */
  lotId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's product ID (foreign key) */
  productId: Scalars['ID']['input'];
  /** Quantity of product */
  quantity: Scalars['String']['input'];
  /** Entity's stock status type ID (foreign key) */
  stockStatusTypeId: Scalars['ID']['input'];
};

export type TaskOffsetConnection = {
  __typename?: 'TaskOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Task>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type TaskPickFulfillmentItem = {
  /** Unit of measure ID */
  createdInUnitOfMeasureId: Scalars['ID']['input'];
  /** Fulfillment item id (foreign key) */
  fulfillmentItemId: Scalars['ID']['input'];
  /** Entity's lot ID (foreign key) */
  lotId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's product ID (foreign key) */
  productId: Scalars['ID']['input'];
  /** Quantity of product */
  quantity: Scalars['String']['input'];
};

/** Task Search results */
export type TaskSearchResults = {
  __typename?: 'TaskSearchResults';
  childrenTasks?: Maybe<Array<TaskSearchResults>>;
  /** Entity code */
  code?: Maybe<Scalars['String']['output']>;
  /** Created at date */
  createdAt?: Maybe<Scalars['String']['output']>;
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['String']['output']>;
  /** Destination bin code */
  destinationBinCode?: Maybe<Scalars['String']['output']>;
  /** Destination license plate code */
  destinationLicensePlateCode?: Maybe<Scalars['String']['output']>;
  /** Destination lot associated with the entity */
  destinationLotCode?: Maybe<Scalars['String']['output']>;
  /** Due date */
  dueDate?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Elasticsearch index name */
  index?: Maybe<Scalars['String']['output']>;
  /** Product code */
  productCode?: Maybe<Scalars['String']['output']>;
  /** Product entity belongs to */
  productDescription?: Maybe<Scalars['String']['output']>;
  shipTo?: Maybe<Scalars['String']['output']>;
  shipToBusinessPartnerId?: Maybe<Scalars['ID']['output']>;
  shipToName?: Maybe<Scalars['String']['output']>;
  soldTo?: Maybe<Scalars['String']['output']>;
  soldToBusinessPartnerId?: Maybe<Scalars['ID']['output']>;
  soldToName?: Maybe<Scalars['String']['output']>;
  /** Source lot code associated with the entity */
  sourcLotCode?: Maybe<Scalars['String']['output']>;
  /** Source bin code */
  sourceBinCode?: Maybe<Scalars['String']['output']>;
  /** Source License Plate code */
  sourceLicensePlateCode?: Maybe<Scalars['String']['output']>;
  supplier?: Maybe<Scalars['String']['output']>;
  supplierBusinessPartnerId?: Maybe<Scalars['ID']['output']>;
  supplierName?: Maybe<Scalars['String']['output']>;
  /** Task type code */
  taskType?: Maybe<Scalars['String']['output']>;
  /** Task type label */
  taskTypeLabel?: Maybe<Scalars['String']['output']>;
  /** Entity's team description */
  teamDescription?: Maybe<Scalars['String']['output']>;
  /** Entity's team name */
  teamName?: Maybe<Scalars['String']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['String']['output']>;
  /** Warehouse code */
  warehouseCode?: Maybe<Scalars['String']['output']>;
  /** Entity's warehouse name */
  warehouseName?: Maybe<Scalars['String']['output']>;
};

export type TaskSort = {
  direction: SortDirection;
  field: TaskSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum TaskSortFields {
  AssignedAt = 'assignedAt',
  AssignedByUserId = 'assignedByUserId',
  AssignedTeamId = 'assignedTeamId',
  AssignedUserId = 'assignedUserId',
  BaseQuantity = 'baseQuantity',
  Code = 'code',
  CompletedAt = 'completedAt',
  CompletedAtLocationX = 'completedAtLocationX',
  CompletedAtLocationY = 'completedAtLocationY',
  CompletedByUserId = 'completedByUserId',
  CompletionDate = 'completionDate',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  DeliveryId = 'deliveryId',
  DeliveryItemId = 'deliveryItemId',
  DestinationBinId = 'destinationBinId',
  DestinationLicensePlateId = 'destinationLicensePlateId',
  DestinationLotId = 'destinationLotId',
  DestinationStockStatusId = 'destinationStockStatusId',
  DueDate = 'dueDate',
  EquipmentTypeId = 'equipmentTypeId',
  FulfillmentItemId = 'fulfillmentItemId',
  Id = 'id',
  InventoryCategory = 'inventoryCategory',
  LinkedTaskId = 'linkedTaskId',
  MetaData = 'metaData',
  ParentTaskId = 'parentTaskId',
  ProductId = 'productId',
  Quantity = 'quantity',
  Readonly = 'readonly',
  ReferringDoc = 'referringDoc',
  ReferringDocItem = 'referringDocItem',
  SourceBinId = 'sourceBinId',
  SourceLicensePlateId = 'sourceLicensePlateId',
  SourceLotId = 'sourceLotId',
  SourceStockStatusId = 'sourceStockStatusId',
  StartedAt = 'startedAt',
  StartedAtLocationX = 'startedAtLocationX',
  StartedAtLocationY = 'startedAtLocationY',
  StartedByUserId = 'startedByUserId',
  Status = 'status',
  TaskGroupId = 'taskGroupId',
  TaskGroupPosition = 'taskGroupPosition',
  TaskTypeId = 'taskTypeId',
  TeamId = 'teamId',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  UserId = 'userId',
  WarehouseId = 'warehouseId'
}

export type TaskStartDto = {
  /** x coordinate location */
  startedAtLocationX?: InputMaybe<Scalars['Float']['input']>;
  /** Y coordinate location */
  startedAtLocationY?: InputMaybe<Scalars['Float']['input']>;
  /** Entity's task ID (foreign key) */
  taskId: Scalars['ID']['input'];
};

export enum TaskStatus {
  Cancelled = 'cancelled',
  Complete = 'complete',
  InProgress = 'inProgress',
  NotStarted = 'notStarted',
  Planned = 'planned'
}

export type TaskStatusFilterComparison = {
  eq?: InputMaybe<TaskStatus>;
  gt?: InputMaybe<TaskStatus>;
  gte?: InputMaybe<TaskStatus>;
  iLike?: InputMaybe<TaskStatus>;
  in?: InputMaybe<Array<TaskStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<TaskStatus>;
  lt?: InputMaybe<TaskStatus>;
  lte?: InputMaybe<TaskStatus>;
  neq?: InputMaybe<TaskStatus>;
  notILike?: InputMaybe<TaskStatus>;
  notIn?: InputMaybe<Array<TaskStatus>>;
  notLike?: InputMaybe<TaskStatus>;
};

/** Task Type model */
export type TaskType = {
  __typename?: 'TaskType';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity's label */
  label: Scalars['String']['output'];
  /** Task type reference category */
  referenceCategory?: Maybe<TaskTypeReferenceCategory>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type TaskTypeAnalyticsFilter = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  warehouseId: Scalars['ID']['input'];
};

export type TaskTypeAnalyticsResponse = {
  __typename?: 'TaskTypeAnalyticsResponse';
  taskTypeAndFrequency: Array<TaskTypeWithFrequency>;
  total: Scalars['Float']['output'];
};

export type TaskTypeBinStatusBinsQueryDto = {
  __typename?: 'TaskTypeBinStatusBinsQueryDto';
  /** Aisle where the bin is located */
  aisle?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  areaCode?: Maybe<Scalars['String']['output']>;
  /** Entity's area ID (foreign key) */
  areaId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  code?: Maybe<Scalars['String']['output']>;
  /** Column where the bin is located. */
  column?: Maybe<Scalars['String']['output']>;
  containsProducts?: Maybe<Scalars['Boolean']['output']>;
  /** Destination bin block flag */
  destinationBinBlock?: Maybe<BinBlockState>;
  hasOpenTasks?: Maybe<Scalars['Boolean']['output']>;
  /** Entity ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Active/Inactive bin status flag */
  inactive?: Maybe<BinActiveState>;
  /** Timestamp of latest inventory count approval */
  lastCount?: Maybe<Scalars['DateTime']['output']>;
  /** Timestamp of latest movement */
  lastMovement?: Maybe<Scalars['DateTime']['output']>;
  /** Level where the bin is located. */
  level?: Maybe<Scalars['Float']['output']>;
  /** Source bin block flag */
  sourceBinBlock?: Maybe<BinBlockState>;
  /** Verification code from a barcode scan */
  verificationCode?: Maybe<Scalars['String']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** x coordinate location */
  x?: Maybe<Scalars['Float']['output']>;
  /** Y coordinate location */
  y?: Maybe<Scalars['Float']['output']>;
};

export type TaskTypeBinStatusBinsQueryDtoFilter = {
  aisle?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<TaskTypeBinStatusBinsQueryDtoFilter>>;
  areaCode?: InputMaybe<StringFieldComparison>;
  areaId?: InputMaybe<IdFilterComparison>;
  code?: InputMaybe<StringFieldComparison>;
  column?: InputMaybe<StringFieldComparison>;
  containsProducts?: InputMaybe<BooleanFieldComparison>;
  hasOpenTasks?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lastCount?: InputMaybe<DateFieldComparison>;
  lastMovement?: InputMaybe<DateFieldComparison>;
  level?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<TaskTypeBinStatusBinsQueryDtoFilter>>;
  verificationCode?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  x?: InputMaybe<FloatFieldComparison>;
  y?: InputMaybe<FloatFieldComparison>;
};

export type TaskTypeBinStatusBinsQueryDtoSort = {
  direction: SortDirection;
  field: TaskTypeBinStatusBinsQueryDtoSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum TaskTypeBinStatusBinsQueryDtoSortFields {
  Aisle = 'aisle',
  AreaCode = 'areaCode',
  AreaId = 'areaId',
  Code = 'code',
  Column = 'column',
  ContainsProducts = 'containsProducts',
  HasOpenTasks = 'hasOpenTasks',
  Id = 'id',
  LastCount = 'lastCount',
  LastMovement = 'lastMovement',
  Level = 'level',
  VerificationCode = 'verificationCode',
  WarehouseId = 'warehouseId',
  X = 'x',
  Y = 'y'
}

export type TaskTypeBinStatusDestinationBins = {
  __typename?: 'TaskTypeBinStatusDestinationBins';
  /** Array of valid destination bins */
  destinationBins: Array<ViewBin>;
};

export type TaskTypeBinStatusForLicensePlateValidationInput = {
  destinationLicensePlateId?: InputMaybe<Scalars['ID']['input']>;
  sourceLicensePlateId?: InputMaybe<Scalars['ID']['input']>;
};

export type TaskTypeBinStatusMappingReplaceInput = {
  /** Bin status mappings for the task type */
  binStatusMappings?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['input'];
};

export type TaskTypeBinStatusReplaceInputDto = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: TaskTypeBinStatusMappingReplaceInput;
};

export type TaskTypeBinStatusSourceBins = {
  __typename?: 'TaskTypeBinStatusSourceBins';
  /** Array of valid source bins */
  sourceBins: Array<ViewBin>;
};

export type TaskTypeBinStatusValidationInput = {
  destinationBinId?: InputMaybe<Scalars['ID']['input']>;
  sourceBinId?: InputMaybe<Scalars['ID']['input']>;
};

export type TaskTypeCreateInput = {
  /** Entity code */
  code: Scalars['String']['input'];
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Entity's label */
  label: Scalars['String']['input'];
};

export type TaskTypeCreateOneInput = {
  /** The record to create */
  taskType: TaskTypeCreateInput;
};

export type TaskTypeFilter = {
  and?: InputMaybe<Array<TaskTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<TaskTypeFilter>>;
  referenceCategory?: InputMaybe<TaskTypeReferenceCategoryFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type TaskTypeOffsetConnection = {
  __typename?: 'TaskTypeOffsetConnection';
  /** Array of nodes. */
  nodes: Array<TaskType>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export enum TaskTypeReferenceCategory {
  Count = 'count',
  InboundDelivery = 'inboundDelivery',
  OutboundFulfillment = 'outboundFulfillment'
}

export type TaskTypeReferenceCategoryFilterComparison = {
  eq?: InputMaybe<TaskTypeReferenceCategory>;
  gt?: InputMaybe<TaskTypeReferenceCategory>;
  gte?: InputMaybe<TaskTypeReferenceCategory>;
  iLike?: InputMaybe<TaskTypeReferenceCategory>;
  in?: InputMaybe<Array<TaskTypeReferenceCategory>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<TaskTypeReferenceCategory>;
  lt?: InputMaybe<TaskTypeReferenceCategory>;
  lte?: InputMaybe<TaskTypeReferenceCategory>;
  neq?: InputMaybe<TaskTypeReferenceCategory>;
  notILike?: InputMaybe<TaskTypeReferenceCategory>;
  notIn?: InputMaybe<Array<TaskTypeReferenceCategory>>;
  notLike?: InputMaybe<TaskTypeReferenceCategory>;
};

export type TaskTypeSort = {
  direction: SortDirection;
  field: TaskTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum TaskTypeSortFields {
  Code = 'code',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  Id = 'id',
  Label = 'label',
  ReferenceCategory = 'referenceCategory',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type TaskTypeStatusCount = {
  __typename?: 'TaskTypeStatusCount';
  complete: Scalars['Float']['output'];
  inProgress: Scalars['Float']['output'];
  notStarted: Scalars['Float']['output'];
};

export type TaskTypeStockStatusForLicensePlateValidationInput = {
  licensePlateId: Scalars['ID']['input'];
};

export type TaskTypeStockStatusMappingReplaceInput = {
  /** Stock status mappings for the task type */
  stockStatusMappings?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['input'];
};

export type TaskTypeStockStatusMappingReplaceInputDto = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: TaskTypeStockStatusMappingReplaceInput;
};

export type TaskTypeStockStatusValidationInput = {
  stockStatusId: Scalars['ID']['input'];
};

export type TaskTypeUpdateInput = {
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Entity's label */
  label?: InputMaybe<Scalars['String']['input']>;
};

export type TaskTypeUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: TaskTypeUpdateInput;
};

export type TaskTypeWithFrequency = {
  __typename?: 'TaskTypeWithFrequency';
  count: Scalars['Int']['output'];
  statusCount: TaskTypeStatusCount;
  taskType: TaskType;
};

export type TaskUpdateInput = {
  /** Deleted at date */
  assignedAt?: InputMaybe<Scalars['DateTime']['input']>;
  assignedByUserId?: InputMaybe<Scalars['ID']['input']>;
  assignedTeamId?: InputMaybe<Scalars['ID']['input']>;
  assignedUserId?: InputMaybe<Scalars['ID']['input']>;
  /** Autocomplete the task on creation */
  autocomplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Date and time a task is completed */
  completedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** x coordinate location */
  completedAtLocationX?: InputMaybe<Scalars['Float']['input']>;
  /** Y coordinate location */
  completedAtLocationY?: InputMaybe<Scalars['Float']['input']>;
  completedByUserId?: InputMaybe<Scalars['ID']['input']>;
  /** Date the task was completed */
  completionDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Unit of measure ID */
  createdInUnitOfMeasureId?: InputMaybe<Scalars['ID']['input']>;
  /** Delivery ID (foreign key) */
  deliveryId?: InputMaybe<Scalars['ID']['input']>;
  /** Delivery item ID (foreign key) */
  deliveryItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Destination bin ID (foreign key) */
  destinationBinId?: InputMaybe<Scalars['ID']['input']>;
  /** Destination license plate ID */
  destinationLicensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** Destination lot ID (foreign key) */
  destinationLotId?: InputMaybe<Scalars['ID']['input']>;
  /** Destination stock status type ID (foreign key) */
  destinationStockStatusId?: InputMaybe<Scalars['ID']['input']>;
  /** Date the task is or was due */
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Fulfillment item id (foreign key) */
  fulfillmentItemId?: InputMaybe<Scalars['ID']['input']>;
  /** License Plate Parent ID */
  licensePlateParentId?: InputMaybe<Scalars['ID']['input']>;
  /** Meta data stored on the entity in json format */
  metaData?: InputMaybe<Scalars['JSON']['input']>;
  /** Entity's parent task ID */
  parentTaskId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's product ID (foreign key) */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Quantity of product */
  quantity?: InputMaybe<Scalars['String']['input']>;
  /** Referring doc */
  referringDoc?: InputMaybe<Scalars['String']['input']>;
  /** Reference item */
  referringDocItem?: InputMaybe<Scalars['String']['input']>;
  /** Source bin ID (foreign key) */
  sourceBinId?: InputMaybe<Scalars['ID']['input']>;
  /** Source License Plate Id */
  sourceLicensePlateId?: InputMaybe<Scalars['ID']['input']>;
  /** Source lot ID (foreign key) */
  sourceLotId?: InputMaybe<Scalars['ID']['input']>;
  /** Source stock status type ID (foreign key) */
  sourceStockStatusId?: InputMaybe<Scalars['ID']['input']>;
  /** Date and time a task is started */
  startedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** x coordinate location */
  startedAtLocationX?: InputMaybe<Scalars['Float']['input']>;
  /** Y coordinate location */
  startedAtLocationY?: InputMaybe<Scalars['Float']['input']>;
  startedByUserId?: InputMaybe<Scalars['ID']['input']>;
  /** Task status, i.e Not Started */
  status?: InputMaybe<TaskStatus>;
  /** Task type ID (foreign key) */
  taskTypeId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's team ID (foreign key) */
  teamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type TaskValidationErrorModel = {
  __typename?: 'TaskValidationErrorModel';
  errors?: Maybe<Array<BaseGraphqlErrorReturnType>>;
  valid: Scalars['Boolean']['output'];
};

/** Team model */
export type Team = {
  __typename?: 'Team';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Is the entity a default entity that cant be deleted */
  default: Scalars['Boolean']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description: Scalars['String']['output'];
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** ApiDocs */
  name: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  users?: Maybe<Users>;
  warehouse?: Maybe<Warehouse>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
};

export type TeamCreateInput = {
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ApiDocs */
  name: Scalars['String']['input'];
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type TeamCreateOneInput = {
  /** The record to create */
  team: TeamCreateInput;
};

export type TeamFilter = {
  and?: InputMaybe<Array<TeamFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  default?: InputMaybe<BooleanFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<TeamFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type TeamOffsetConnection = {
  __typename?: 'TeamOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Team>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type TeamSort = {
  direction: SortDirection;
  field: TeamSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum TeamSortFields {
  CreatedAt = 'createdAt',
  Default = 'default',
  DeletedAt = 'deletedAt',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WarehouseId = 'warehouseId'
}

export type TeamUpdateInput = {
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ApiDocs */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type TeamUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: TeamUpdateInput;
};

/** Unit Of Measure Conversion Resource */
export type UnitOfMeasureConversion = {
  __typename?: 'UnitOfMeasureConversion';
  /** Quantity of product */
  quantity: Scalars['String']['output'];
  /** Unit of measure for entity */
  unitOfMeasure: UnitOfMeasureConversionItemResource;
};

export type UnitOfMeasureConversionItemResource = {
  __typename?: 'UnitOfMeasureConversionItemResource';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Conversion factor to convert units of measure -> Multiply against base UOM */
  conversionFactor: Scalars['Float']['output'];
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Boolean for base unit of measure if true UOM is base */
  isBaseUom: Scalars['Boolean']['output'];
  /** Entity's label */
  label?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  productId: Scalars['ID']['output'];
};

/** Unit Of Measure Conversion Resource */
export type UnitOfMeasureConversionResource = {
  __typename?: 'UnitOfMeasureConversionResource';
  /** Unit of measure for entity */
  unitOfMeasureConversions: Array<UnitOfMeasureConversion>;
};

/** Unit Of Measure Glossary model */
export type UnitOfMeasureGlossary = {
  __typename?: 'UnitOfMeasureGlossary';
  category: StandardUomCategory;
  /** Entity code */
  code: Scalars['String']['output'];
  /** Entity's company ID (foreign key) */
  companyId?: Maybe<Scalars['ID']['output']>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity code */
  description?: Maybe<Scalars['String']['output']>;
  /** Reference code in ERP */
  erpCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  isBaseUom?: Maybe<Scalars['Boolean']['output']>;
  /** Entity's label */
  label?: Maybe<Scalars['String']['output']>;
  /** Unit of measure system */
  measurementSystem?: Maybe<StandardUomSystem>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type UnitOfMeasureGlossaryFilter = {
  and?: InputMaybe<Array<UnitOfMeasureGlossaryFilter>>;
  category?: InputMaybe<StandardUomCategoryFilterComparison>;
  code?: InputMaybe<StringFieldComparison>;
  companyId?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  erpCode?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isBaseUom?: InputMaybe<BooleanFieldComparison>;
  label?: InputMaybe<StringFieldComparison>;
  measurementSystem?: InputMaybe<StandardUomSystemFilterComparison>;
  or?: InputMaybe<Array<UnitOfMeasureGlossaryFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type UnitOfMeasureGlossaryOffsetConnection = {
  __typename?: 'UnitOfMeasureGlossaryOffsetConnection';
  /** Array of nodes. */
  nodes: Array<UnitOfMeasureGlossary>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type UnitOfMeasureGlossarySort = {
  direction: SortDirection;
  field: UnitOfMeasureGlossarySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UnitOfMeasureGlossarySortFields {
  Category = 'category',
  Code = 'code',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  ErpCode = 'erpCode',
  Id = 'id',
  IsBaseUom = 'isBaseUom',
  Label = 'label',
  MeasurementSystem = 'measurementSystem',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type UnitOfMeasureGlossaryUpdateInput = {
  /** Entity's company ID (foreign key) */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Entity's label */
  label?: InputMaybe<Scalars['String']['input']>;
};

export type UnitOfMeasureGlossaryUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: UnitOfMeasureGlossaryUpdateInput;
};

/** Unit Of Measure Product Conversion model */
export type UnitOfMeasureProductConversion = {
  __typename?: 'UnitOfMeasureProductConversion';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Conversion factor to convert units of measure -> Multiply against base UOM */
  conversionFactor: Scalars['Float']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Unit of measure for product dimensions */
  dimensionUOMId?: Maybe<Scalars['String']['output']>;
  /** Gross weight of entity */
  grossWeight?: Maybe<Scalars['Float']['output']>;
  /** Product's Height */
  height?: Maybe<Scalars['Float']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Boolean for base unit of measure if true UOM is base */
  isBaseUom: Scalars['Boolean']['output'];
  /** Product's length */
  length?: Maybe<Scalars['Float']['output']>;
  /** Entity's product ID (foreign key) */
  productId: Scalars['ID']['output'];
  /** Unit of measure glossary ID */
  unitOfMeasureGlossaryId: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Volume of entity */
  volume?: Maybe<Scalars['Float']['output']>;
  /** Volume in unit of measure for entity */
  volumeUOMId?: Maybe<Scalars['String']['output']>;
  /** Boolean for base unit of measure if true UOM is base */
  warehouseUnitOfMeasure?: Maybe<Scalars['JSON']['output']>;
  /** Weight UOM, i.e KG */
  weightUOMId?: Maybe<Scalars['String']['output']>;
  /** Product's width */
  width?: Maybe<Scalars['Float']['output']>;
};

export type UnitOfMeasureProductConversionFilter = {
  and?: InputMaybe<Array<UnitOfMeasureProductConversionFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  conversionFactor?: InputMaybe<FloatFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  dimensionUOMId?: InputMaybe<StringFieldComparison>;
  grossWeight?: InputMaybe<FloatFieldComparison>;
  height?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isBaseUom?: InputMaybe<BooleanFieldComparison>;
  length?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<UnitOfMeasureProductConversionFilter>>;
  productId?: InputMaybe<IdFilterComparison>;
  unitOfMeasureGlossaryId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  volume?: InputMaybe<FloatFieldComparison>;
  volumeUOMId?: InputMaybe<StringFieldComparison>;
  warehouseUnitOfMeasure?: InputMaybe<JsonFilterComparison>;
  weightUOMId?: InputMaybe<StringFieldComparison>;
  width?: InputMaybe<FloatFieldComparison>;
};

export type UnitOfMeasureProductConversionNodes = {
  __typename?: 'UnitOfMeasureProductConversionNodes';
  nodes: Array<UnitOfMeasureProductConversion>;
};

export type UnitOfMeasureProductConversionOffsetConnection = {
  __typename?: 'UnitOfMeasureProductConversionOffsetConnection';
  /** Array of nodes. */
  nodes: Array<UnitOfMeasureProductConversion>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type UnitOfMeasureProductConversionSort = {
  direction: SortDirection;
  field: UnitOfMeasureProductConversionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UnitOfMeasureProductConversionSortFields {
  Code = 'code',
  ConversionFactor = 'conversionFactor',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  DimensionUomId = 'dimensionUOMId',
  GrossWeight = 'grossWeight',
  Height = 'height',
  Id = 'id',
  IsBaseUom = 'isBaseUom',
  Length = 'length',
  ProductId = 'productId',
  UnitOfMeasureGlossaryId = 'unitOfMeasureGlossaryId',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  Volume = 'volume',
  VolumeUomId = 'volumeUOMId',
  WarehouseUnitOfMeasure = 'warehouseUnitOfMeasure',
  WeightUomId = 'weightUOMId',
  Width = 'width'
}

export type UnknownBinStockDetailDto = {
  /** Entity ID */
  licensePlateCode?: InputMaybe<Scalars['String']['input']>;
};

export type UnknownDeliveryReturnItemDto = {
  /** Entity ID */
  licensePlateCode: Scalars['String']['input'];
};

export type UpdateContactInfoDto = {
  /** Town or City */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Country */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Contact email address */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Fax Number */
  fax?: InputMaybe<Scalars['String']['input']>;
  /** Phone number */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** State */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Street 1 */
  street1?: InputMaybe<Scalars['String']['input']>;
  /** Street 2 */
  street2?: InputMaybe<Scalars['String']['input']>;
  /** Zip code */
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactInfoInputDto = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: UpdateContactInfoDto;
};

export type UpdateOneDeliveryInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: DeliveryUpdateInput;
};

export type UpdateOneDispositionInput = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: DispositionUpdateOneDto;
};

export type UpdateOneFulfillmentBlockInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: FulfillmentBlockUpdateInput;
};

export type UpdateOneTaskInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: TaskUpdateInput;
};

export type UpdatePermissionsForRoleDto = {
  permissionIds: Array<Scalars['String']['input']>;
  /** Entity ID */
  roleId: Scalars['String']['input'];
};

export type UpdateRolesForUserGroupDto = {
  /** List of role ids to add to user group */
  roleIds: Array<Scalars['String']['input']>;
  /** Entity ID */
  userGroupId: Scalars['ID']['input'];
  /** Entity ID */
  warehouseIds: Array<Scalars['String']['input']>;
};

export type UpdateScheduledJobByIdInputDto = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: ScheduledJobUpdateStatusInput;
};

export type UpdateScheduledJobInputDto = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: ScheduledJobUpdateInput;
};

export type UpdateStockStatusTypeInputDto = {
  /** The id of the record to update */
  id: Scalars['ID']['input'];
  /** The update to apply. */
  update: StockStatusTypeUpdate;
};

export type UpdateUsersForUserGroupDto = {
  /** On or off */
  status: UserGroupMappingStatus;
  /** Entity ID */
  userGroupId: Scalars['ID']['input'];
  /** Entity ID */
  userId: Scalars['ID']['input'];
};

export type UpdateUsersForUserGroupMappingInput = {
  userGroupMappings: Array<UpdateUsersForUserGroupDto>;
};

/** User model */
export type User = {
  __typename?: 'User';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  defaultWarehouse?: Maybe<Warehouse>;
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  displayPreference?: Maybe<DisplayPreference>;
  displayPreferenceId?: Maybe<Scalars['ID']['output']>;
  /** Email address */
  email: Scalars['String']['output'];
  /** User's firebase auth Id */
  firebaseAuthUid?: Maybe<Scalars['String']['output']>;
  /** Person's first name */
  firstName: Scalars['String']['output'];
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Person's last name */
  lastName: Scalars['String']['output'];
  mobileDetails?: Maybe<Scalars['JSON']['output']>;
  /** Phone number */
  phone?: Maybe<Scalars['String']['output']>;
  /** If true entire mobileDetails object will be replaced on update otherwise update fields will merge with the object */
  refreshMobileDetails?: Maybe<Scalars['Boolean']['output']>;
  /** User's account status */
  status: UserStatus;
  team?: Maybe<Team>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type UserCreateInput = {
  /** Email address */
  email: Scalars['String']['input'];
  /** User's firebase auth Id */
  firebaseAuthUid?: InputMaybe<Scalars['String']['input']>;
  /** Person's first name */
  firstName: Scalars['String']['input'];
  /** Person's last name */
  lastName: Scalars['String']['input'];
  mobileDetails?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Phone number */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** If true entire mobileDetails object will be replaced on update otherwise update fields will merge with the object */
  refreshMobileDetails?: InputMaybe<Scalars['Boolean']['input']>;
  /** User's account status */
  status: UserStatus;
};

export type UserCreateOneInput = {
  /** The record to create */
  user: UserCreateInput;
};

export type UserFilter = {
  and?: InputMaybe<Array<UserFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firebaseAuthUid?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  mobileDetails?: InputMaybe<JsonFilterComparison>;
  or?: InputMaybe<Array<UserFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
  status?: InputMaybe<UserStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Default entity */
  default?: Maybe<Scalars['Boolean']['output']>;
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** ApiDocs */
  name: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

export type UserGroupCreateInput = {
  /** ApiDocs */
  name: Scalars['String']['input'];
};

export type UserGroupCreateInputDto = {
  /** dto wrapper */
  userGroup: UserGroupCreateInput;
};

export type UserGroupDeleteOneDto = {
  /** Entity ID */
  id: Scalars['ID']['input'];
};

export type UserGroupFilter = {
  and?: InputMaybe<Array<UserGroupFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  default?: InputMaybe<BooleanFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<UserGroupFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type UserGroupMapping = {
  __typename?: 'UserGroupMapping';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  userGroupId: Scalars['ID']['output'];
  /** Entity ID */
  userId: Scalars['ID']['output'];
};

export type UserGroupMappingFilter = {
  and?: InputMaybe<Array<UserGroupMappingFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<UserGroupMappingFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  userGroupId?: InputMaybe<IdFilterComparison>;
  userId?: InputMaybe<IdFilterComparison>;
};

export type UserGroupMappingOffsetConnection = {
  __typename?: 'UserGroupMappingOffsetConnection';
  /** Array of nodes. */
  nodes: Array<UserGroupMapping>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type UserGroupMappingSort = {
  direction: SortDirection;
  field: UserGroupMappingSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserGroupMappingSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  UserGroupId = 'userGroupId',
  UserId = 'userId'
}

export enum UserGroupMappingStatus {
  Off = 'off',
  On = 'on'
}

export type UserGroupOffsetConnection = {
  __typename?: 'UserGroupOffsetConnection';
  /** Array of nodes. */
  nodes: Array<UserGroup>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type UserGroupRoleMapping = {
  __typename?: 'UserGroupRoleMapping';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity ID */
  roleId?: Maybe<Scalars['ID']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  userGroupId: Scalars['ID']['output'];
  /** Entity ID */
  warehouseId: Scalars['ID']['output'];
};

export type UserGroupSort = {
  direction: SortDirection;
  field: UserGroupSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserGroupSortFields {
  CreatedAt = 'createdAt',
  Default = 'default',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type UserGroupUpdateDto = {
  /** ApiDocs */
  name: Scalars['String']['input'];
};

export type UserGroupUpdateInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Fields to update */
  update: UserGroupUpdateDto;
};

export type UserOffsetConnection = {
  __typename?: 'UserOffsetConnection';
  /** Array of nodes. */
  nodes: Array<User>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type UserPermissionsResponseDto = {
  __typename?: 'UserPermissionsResponseDto';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Description of entity */
  description: Scalars['String']['output'];
  grouping: PermissionGrouping;
  /** Entity ID */
  id: Scalars['ID']['output'];
};

export type UserPermittedWarehousesResponseDto = {
  __typename?: 'UserPermittedWarehousesResponseDto';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** ApiDocs */
  name?: Maybe<Scalars['String']['output']>;
  /** Configuration Model for the Warehouse */
  type?: Maybe<ModelWarehouseType>;
};

export type UserSort = {
  direction: SortDirection;
  field: UserSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Email = 'email',
  FirebaseAuthUid = 'firebaseAuthUid',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  MobileDetails = 'mobileDetails',
  Phone = 'phone',
  Status = 'status',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export enum UserStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type UserStatusFilterComparison = {
  eq?: InputMaybe<UserStatus>;
  gt?: InputMaybe<UserStatus>;
  gte?: InputMaybe<UserStatus>;
  iLike?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<UserStatus>;
  lt?: InputMaybe<UserStatus>;
  lte?: InputMaybe<UserStatus>;
  neq?: InputMaybe<UserStatus>;
  notILike?: InputMaybe<UserStatus>;
  notIn?: InputMaybe<Array<UserStatus>>;
  notLike?: InputMaybe<UserStatus>;
};

export type UserTeamMapping = {
  __typename?: 'UserTeamMapping';
  /** Entity's team ID (foreign key) */
  active: Scalars['Boolean']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity's team ID (foreign key) */
  teamId: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity's team ID (foreign key) */
  userId: Scalars['ID']['output'];
};

export type UserTeamMappingCreateInput = {
  /** Entity's team ID (foreign key) */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Entity's team ID (foreign key) */
  teamId: Scalars['ID']['input'];
  /** Entity's user ID (foreign key) */
  userId: Scalars['ID']['input'];
};

export type UserTeamMappingCreateOneInput = {
  /** The record to create */
  userTeamMapping: UserTeamMappingCreateInput;
};

export type UserTeamMappingFilter = {
  active?: InputMaybe<BooleanFieldComparison>;
  and?: InputMaybe<Array<UserTeamMappingFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<UserTeamMappingFilter>>;
  teamId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  userId?: InputMaybe<IdFilterComparison>;
};

export type UserTeamMappingOffsetConnection = {
  __typename?: 'UserTeamMappingOffsetConnection';
  /** Array of nodes. */
  nodes: Array<UserTeamMapping>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type UserTeamMappingSort = {
  direction: SortDirection;
  field: UserTeamMappingSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserTeamMappingSortFields {
  Active = 'active',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  TeamId = 'teamId',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  UserId = 'userId'
}

export type UserTeamMappingUpdateInput = {
  /** Entity's team ID (foreign key) */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Entity's team ID (foreign key) */
  teamId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's user ID (foreign key) */
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserTeamMappingUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: UserTeamMappingUpdateInput;
};

export type UserUpdateInput = {
  /** User's firebase auth Id */
  firebaseAuthUid?: InputMaybe<Scalars['String']['input']>;
  /** Person's first name */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Person's last name */
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobileDetails?: InputMaybe<Scalars['JSONObject']['input']>;
  /** Phone number */
  phone?: InputMaybe<Scalars['String']['input']>;
  /** If true entire mobileDetails object will be replaced on update otherwise update fields will merge with the object */
  refreshMobileDetails?: InputMaybe<Scalars['Boolean']['input']>;
  /** User's account status */
  status?: InputMaybe<UserStatus>;
};

export type UserUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: UserUpdateInput;
};

export type UserWarehouseDefault = {
  __typename?: 'UserWarehouseDefault';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Entity's team ID (foreign key) */
  default: Scalars['Boolean']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity's user ID (foreign key) */
  userId: Scalars['String']['output'];
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['String']['output'];
};

export type UserWarehouseDefaultCreateInput = {
  /** Default entity */
  default?: InputMaybe<Scalars['Boolean']['input']>;
  /** Entity's user ID (foreign key) */
  userId: Scalars['ID']['input'];
  /** Entity's team ID (foreign key) */
  warehouseId: Scalars['ID']['input'];
};

export type UserWarehouseDefaultCreateOneInput = {
  /** The record to create */
  userWarehouseDefault: UserWarehouseDefaultCreateInput;
};

export type UserWarehouseDefaultFilter = {
  and?: InputMaybe<Array<UserWarehouseDefaultFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  default?: InputMaybe<BooleanFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<UserWarehouseDefaultFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  userId?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<StringFieldComparison>;
};

export type UserWarehouseDefaultOffsetConnection = {
  __typename?: 'UserWarehouseDefaultOffsetConnection';
  /** Array of nodes. */
  nodes: Array<UserWarehouseDefault>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type UserWarehouseDefaultSort = {
  direction: SortDirection;
  field: UserWarehouseDefaultSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserWarehouseDefaultSortFields {
  CreatedAt = 'createdAt',
  Default = 'default',
  DeletedAt = 'deletedAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  UserId = 'userId',
  WarehouseId = 'warehouseId'
}

export type UserWarehouseDefaultUpdateInput = {
  /** Default entity */
  default?: InputMaybe<Scalars['Boolean']['input']>;
  /** Entity's user ID (foreign key) */
  userId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's team ID (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type UserWarehouseDefaultUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update DTO */
  update: UserWarehouseDefaultUpdateInput;
};

export type Users = {
  __typename?: 'Users';
  nodes: Array<User>;
};

export type ViewArea = {
  __typename?: 'ViewArea';
  /** Entity code */
  code?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** ApiDocs */
  name?: Maybe<Scalars['String']['output']>;
  /** Area's status, i.e. ACTIVE, INACTIVE */
  status?: Maybe<AreaStatus>;
  /** Entity's SAP storage location */
  storageLocation?: Maybe<Scalars['String']['output']>;
  /** Entity's SAP storage location ID (foreign key) */
  storageLocationId?: Maybe<Scalars['ID']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
};

export type ViewAreaFilter = {
  and?: InputMaybe<Array<ViewAreaFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ViewAreaFilter>>;
  status?: InputMaybe<AreaStatusFilterComparison>;
  storageLocation?: InputMaybe<StringFieldComparison>;
  storageLocationId?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type ViewAreaOffsetConnection = {
  __typename?: 'ViewAreaOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewArea>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewAreaSort = {
  direction: SortDirection;
  field: ViewAreaSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewAreaSortFields {
  Code = 'code',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  Status = 'status',
  StorageLocation = 'storageLocation',
  StorageLocationId = 'storageLocationId',
  WarehouseId = 'warehouseId'
}

export type ViewBarcode = {
  __typename?: 'ViewBarcode';
  /** Entity ID */
  adminTaskId?: Maybe<Scalars['ID']['output']>;
  /** Task status, i.e Not Started */
  adminTaskStatus?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  barcodeCode?: Maybe<Scalars['String']['output']>;
  /** Created at date */
  barcodeCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Barcode entity ID */
  barcodeId?: Maybe<Scalars['ID']['output']>;
  /** Business partner code */
  businessPartnerCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  businessPartnerId?: Maybe<Scalars['ID']['output']>;
  /** Business partner name */
  businessPartnerName?: Maybe<Scalars['String']['output']>;
  /** Person's first name */
  userFirstName?: Maybe<Scalars['String']['output']>;
  /** Person's last name */
  userLastName?: Maybe<Scalars['String']['output']>;
};

export type ViewBarcodeFilter = {
  adminTaskId?: InputMaybe<IdFilterComparison>;
  adminTaskStatus?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ViewBarcodeFilter>>;
  barcodeCode?: InputMaybe<StringFieldComparison>;
  barcodeCreatedAt?: InputMaybe<DateFieldComparison>;
  barcodeId?: InputMaybe<IdFilterComparison>;
  businessPartnerCode?: InputMaybe<StringFieldComparison>;
  businessPartnerId?: InputMaybe<IdFilterComparison>;
  businessPartnerName?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ViewBarcodeFilter>>;
  userFirstName?: InputMaybe<StringFieldComparison>;
  userLastName?: InputMaybe<StringFieldComparison>;
};

export type ViewBarcodeOffsetConnection = {
  __typename?: 'ViewBarcodeOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewBarcode>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewBarcodeSort = {
  direction: SortDirection;
  field: ViewBarcodeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewBarcodeSortFields {
  AdminTaskId = 'adminTaskId',
  AdminTaskStatus = 'adminTaskStatus',
  BarcodeCode = 'barcodeCode',
  BarcodeCreatedAt = 'barcodeCreatedAt',
  BarcodeId = 'barcodeId',
  BusinessPartnerCode = 'businessPartnerCode',
  BusinessPartnerId = 'businessPartnerId',
  BusinessPartnerName = 'businessPartnerName',
  UserFirstName = 'userFirstName',
  UserLastName = 'userLastName'
}

export type ViewBin = {
  __typename?: 'ViewBin';
  /**
   * Aisle where the bin is located
   * @deprecated Deprecated field name use aisleCode instead.
   */
  aisle?: Maybe<Scalars['String']['output']>;
  /** Aisle where the bin is located */
  aisleCode?: Maybe<Scalars['String']['output']>;
  /** Column where the bin is located. */
  aisleColumnCode?: Maybe<Scalars['String']['output']>;
  /** Column where the bin is located. */
  aisleColumnId?: Maybe<Scalars['ID']['output']>;
  /** Aisle where the bin is located */
  aisleId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  areaCode?: Maybe<Scalars['String']['output']>;
  /** Entity's area ID (foreign key) */
  areaId?: Maybe<Scalars['ID']['output']>;
  /** Area's name */
  areaName?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  binStorageTypeCode?: Maybe<Scalars['String']['output']>;
  /** Depth(X) value of storage capacity */
  binStorageTypeDepth?: Maybe<Scalars['Float']['output']>;
  /** Height(Z) value of storage capacity */
  binStorageTypeHeight?: Maybe<Scalars['Float']['output']>;
  /** Entity ID */
  binStorageTypeId?: Maybe<Scalars['ID']['output']>;
  /** Weight capacity of storage */
  binStorageTypeWeightCapacity?: Maybe<Scalars['Float']['output']>;
  /** Width(Y) value of storage capacity */
  binStorageTypeWidth?: Maybe<Scalars['Float']['output']>;
  /** Entity code */
  code?: Maybe<Scalars['String']['output']>;
  /**
   * Column where the bin is located.
   * @deprecated Deprecated field name use aisleColumnCode instead.
   */
  column?: Maybe<Scalars['String']['output']>;
  containsProducts?: Maybe<Scalars['Boolean']['output']>;
  /** Destination bin block flag */
  destinationBinBlock?: Maybe<BinBlockState>;
  /** Unit of measure for entity */
  distanceUomCode?: Maybe<Scalars['String']['output']>;
  /** Indicator of open tasks */
  hasOpenTasks?: Maybe<Scalars['Boolean']['output']>;
  /** Entity ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Active/Inactive bin status flag */
  inactive?: Maybe<BinActiveState>;
  /** Bin has been assigned to a door */
  isAssignedToDoor?: Maybe<Scalars['Boolean']['output']>;
  /** Timestamp of latest inventory count approval */
  lastCount?: Maybe<Scalars['DateTime']['output']>;
  /** Timestamp of latest movement */
  lastMovement?: Maybe<Scalars['DateTime']['output']>;
  /** Level where the bin is located. */
  level?: Maybe<Scalars['Float']['output']>;
  /** Source bin block flag */
  sourceBinBlock?: Maybe<BinBlockState>;
  /** Verification code from a barcode scan */
  verificationCode?: Maybe<Scalars['String']['output']>;
  /** Warehouse code */
  warehouseCode?: Maybe<Scalars['String']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** Unit of measure for entity */
  weightCapacityUomCode?: Maybe<Scalars['String']['output']>;
  /** x coordinate location */
  x?: Maybe<Scalars['Float']['output']>;
  /** Y coordinate location */
  y?: Maybe<Scalars['Float']['output']>;
};

export type ViewBinFilter = {
  aisle?: InputMaybe<StringFieldComparison>;
  aisleCode?: InputMaybe<StringFieldComparison>;
  aisleColumnCode?: InputMaybe<StringFieldComparison>;
  aisleColumnId?: InputMaybe<IdFilterComparison>;
  aisleId?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<ViewBinFilter>>;
  areaCode?: InputMaybe<StringFieldComparison>;
  areaId?: InputMaybe<IdFilterComparison>;
  areaName?: InputMaybe<StringFieldComparison>;
  binStorageTypeCode?: InputMaybe<StringFieldComparison>;
  binStorageTypeDepth?: InputMaybe<FloatFieldComparison>;
  binStorageTypeHeight?: InputMaybe<FloatFieldComparison>;
  binStorageTypeId?: InputMaybe<IdFilterComparison>;
  binStorageTypeWeightCapacity?: InputMaybe<FloatFieldComparison>;
  binStorageTypeWidth?: InputMaybe<FloatFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  column?: InputMaybe<StringFieldComparison>;
  containsProducts?: InputMaybe<BooleanFieldComparison>;
  destinationBinBlock?: InputMaybe<BinBlockStateFilterComparison>;
  distanceUomCode?: InputMaybe<StringFieldComparison>;
  hasOpenTasks?: InputMaybe<BooleanFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  inactive?: InputMaybe<BinActiveStateFilterComparison>;
  isAssignedToDoor?: InputMaybe<BooleanFieldComparison>;
  lastCount?: InputMaybe<DateFieldComparison>;
  lastMovement?: InputMaybe<DateFieldComparison>;
  level?: InputMaybe<NumberFieldComparison>;
  or?: InputMaybe<Array<ViewBinFilter>>;
  sourceBinBlock?: InputMaybe<BinBlockStateFilterComparison>;
  verificationCode?: InputMaybe<StringFieldComparison>;
  warehouseCode?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  weightCapacityUomCode?: InputMaybe<StringFieldComparison>;
  x?: InputMaybe<FloatFieldComparison>;
  y?: InputMaybe<FloatFieldComparison>;
};

export type ViewBinOffsetConnection = {
  __typename?: 'ViewBinOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewBin>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewBinSort = {
  direction: SortDirection;
  field: ViewBinSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewBinSortFields {
  Aisle = 'aisle',
  AisleCode = 'aisleCode',
  AisleColumnCode = 'aisleColumnCode',
  AisleColumnId = 'aisleColumnId',
  AisleId = 'aisleId',
  AreaCode = 'areaCode',
  AreaId = 'areaId',
  AreaName = 'areaName',
  BinStorageTypeCode = 'binStorageTypeCode',
  BinStorageTypeDepth = 'binStorageTypeDepth',
  BinStorageTypeHeight = 'binStorageTypeHeight',
  BinStorageTypeId = 'binStorageTypeId',
  BinStorageTypeWeightCapacity = 'binStorageTypeWeightCapacity',
  BinStorageTypeWidth = 'binStorageTypeWidth',
  Code = 'code',
  Column = 'column',
  ContainsProducts = 'containsProducts',
  DestinationBinBlock = 'destinationBinBlock',
  DistanceUomCode = 'distanceUomCode',
  HasOpenTasks = 'hasOpenTasks',
  Id = 'id',
  Inactive = 'inactive',
  IsAssignedToDoor = 'isAssignedToDoor',
  LastCount = 'lastCount',
  LastMovement = 'lastMovement',
  Level = 'level',
  SourceBinBlock = 'sourceBinBlock',
  VerificationCode = 'verificationCode',
  WarehouseCode = 'warehouseCode',
  WarehouseId = 'warehouseId',
  WeightCapacityUomCode = 'weightCapacityUomCode',
  X = 'x',
  Y = 'y'
}

export type ViewBinStorageType = {
  __typename?: 'ViewBinStorageType';
  /** The number of bins assigned to a bin storage type */
  binCount?: Maybe<Scalars['Float']['output']>;
  /** Entity code */
  code?: Maybe<Scalars['String']['output']>;
  /** Created by user */
  createdByUserFirstName?: Maybe<Scalars['ID']['output']>;
  /** Created by user */
  createdByUserLastName?: Maybe<Scalars['ID']['output']>;
  /** Depth(X) value of storage capacity */
  depth?: Maybe<Scalars['Float']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Unit of measure for entity */
  distanceUomCode?: Maybe<Scalars['String']['output']>;
  /** Unit of measure ID */
  distanceUomId?: Maybe<Scalars['ID']['output']>;
  /** Height(Z) value of storage capacity */
  height?: Maybe<Scalars['Float']['output']>;
  /** Entity ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Entity's label */
  label?: Maybe<Scalars['String']['output']>;
  /** Warehouse code */
  warehouseCode?: Maybe<Scalars['String']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** Weight capacity of storage */
  weightCapacity?: Maybe<Scalars['Float']['output']>;
  /** Unit of measure for entity */
  weightCapacityUomCode?: Maybe<Scalars['String']['output']>;
  /** Unit of measure ID */
  weightCapacityUomId?: Maybe<Scalars['ID']['output']>;
  /** Width(Y) value of storage capacity */
  width?: Maybe<Scalars['Float']['output']>;
};

export type ViewBinStorageTypeFilter = {
  and?: InputMaybe<Array<ViewBinStorageTypeFilter>>;
  binCount?: InputMaybe<FloatFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  createdByUserFirstName?: InputMaybe<IdFilterComparison>;
  createdByUserLastName?: InputMaybe<IdFilterComparison>;
  depth?: InputMaybe<FloatFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  distanceUomCode?: InputMaybe<StringFieldComparison>;
  distanceUomId?: InputMaybe<IdFilterComparison>;
  height?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ViewBinStorageTypeFilter>>;
  warehouseCode?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  weightCapacity?: InputMaybe<FloatFieldComparison>;
  weightCapacityUomCode?: InputMaybe<StringFieldComparison>;
  weightCapacityUomId?: InputMaybe<IdFilterComparison>;
  width?: InputMaybe<FloatFieldComparison>;
};

export type ViewBinStorageTypeOffsetConnection = {
  __typename?: 'ViewBinStorageTypeOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewBinStorageType>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewBinStorageTypeSort = {
  direction: SortDirection;
  field: ViewBinStorageTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewBinStorageTypeSortFields {
  BinCount = 'binCount',
  Code = 'code',
  CreatedByUserFirstName = 'createdByUserFirstName',
  CreatedByUserLastName = 'createdByUserLastName',
  Depth = 'depth',
  Description = 'description',
  DistanceUomCode = 'distanceUomCode',
  DistanceUomId = 'distanceUomId',
  Height = 'height',
  Id = 'id',
  Label = 'label',
  WarehouseCode = 'warehouseCode',
  WarehouseId = 'warehouseId',
  WeightCapacity = 'weightCapacity',
  WeightCapacityUomCode = 'weightCapacityUomCode',
  WeightCapacityUomId = 'weightCapacityUomId',
  Width = 'width'
}

export type ViewDelivery = {
  __typename?: 'ViewDelivery';
  /** A deliveries overall availability */
  availability?: Maybe<Scalars['Int']['output']>;
  /** Bill of lading */
  billOfLading?: Maybe<Scalars['String']['output']>;
  /** ERP delivery block */
  blockStatus?: Maybe<Scalars['String']['output']>;
  /** Delivery category */
  category?: Maybe<DeliveryCategory>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Overall status for the delivery or fulfillment */
  deliveryStatus?: Maybe<DeliveryCompletionStatus>;
  /** Entity code */
  doorCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  doorId?: Maybe<Scalars['ID']['output']>;
  /** Due date */
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  /** Reason blocked in ERP */
  erpBlockingReason?: Maybe<Scalars['String']['output']>;
  /** Delivery or fulfillment erp code */
  erpCode?: Maybe<Scalars['String']['output']>;
  /** When data was created in ERP */
  erpCreated?: Maybe<Scalars['DateTime']['output']>;
  /** When data was last updated in ERP */
  erpLastChanged?: Maybe<Scalars['DateTime']['output']>;
  /** Erp purchase order */
  erpPurchaseOrder?: Maybe<Scalars['String']['output']>;
  /** Erp sales order */
  erpSalesOrder?: Maybe<Scalars['String']['output']>;
  /** Export */
  export?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  fulfillmentBlockId?: Maybe<Scalars['ID']['output']>;
  /** Entity's label */
  fulfillmentBlockLabel?: Maybe<Scalars['String']['output']>;
  /** Goods receipt or goods issue status */
  goodsReceiptOrIssueStatus?: Maybe<DeliveryCompletionStatus>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Delivery Item count */
  itemCount?: Maybe<Scalars['Int']['output']>;
  /** Delivery load or unload status */
  loadOrUnloadStatus?: Maybe<DeliveryCompletionStatus>;
  /** ID for a warehouse op file */
  orderConfirmationFileId?: Maybe<Scalars['ID']['output']>;
  /** Picking or putaway status */
  pickOrPutawayStatus?: Maybe<DeliveryCompletionStatus>;
  /** Point of contact */
  pointOfContact?: Maybe<Scalars['String']['output']>;
  /** Progress of delivery */
  progress: Scalars['Int']['output'];
  /** Progress string */
  progressString: DeliveryCompletionStatus;
  /** Delivery promise date */
  promiseDate?: Maybe<Scalars['DateTime']['output']>;
  /** Date when all line items are availalbe 100% */
  shipReadyDate?: Maybe<Scalars['DateTime']['output']>;
  /** Delivery or fulfillment ship to code */
  shipToBusinessPartnerCode?: Maybe<Scalars['String']['output']>;
  /** Delivery or fulfillment ship to code */
  shipToBusinessPartnerName?: Maybe<Scalars['String']['output']>;
  /** Delivery or fulfillment ship to code */
  shipToCode?: Maybe<Scalars['String']['output']>;
  /** Delivery or fulfillment ship to code */
  shipToId?: Maybe<Scalars['ID']['output']>;
  /** Ship to party name */
  shipToName?: Maybe<Scalars['String']['output']>;
  /** Delivery or fulfillment sold to code */
  soldToBusinessPartnerCode?: Maybe<Scalars['String']['output']>;
  /** Delivery or fulfillment sold to code */
  soldToBusinessPartnerName?: Maybe<Scalars['String']['output']>;
  /** Delivery or fulfillment sold to code */
  soldToCode?: Maybe<Scalars['String']['output']>;
  /** Delivery or Fulfillment sold to Business Partner Entity ID (foreign key) */
  soldToId?: Maybe<Scalars['ID']['output']>;
  /** Sold to party name */
  soldToName?: Maybe<Scalars['String']['output']>;
  /** Delivery or fulfillment supplier */
  supplierBusinessPartnerCode?: Maybe<Scalars['String']['output']>;
  /** Delivery or fulfillment supplier */
  supplierBusinessPartnerName?: Maybe<Scalars['String']['output']>;
  /** Delivery or fulfillment supplier */
  supplierCode?: Maybe<Scalars['String']['output']>;
  /** Delivery or fulfillment supplier */
  supplierId?: Maybe<Scalars['ID']['output']>;
  /** Delivery or fulfillment supplier Name */
  supplierName?: Maybe<Scalars['String']['output']>;
  /** Gross weight of entity */
  totalGrossWeight?: Maybe<Scalars['Float']['output']>;
  /** Net weight of entity */
  totalNetWeight?: Maybe<Scalars['Float']['output']>;
  /** Volume of entity */
  totalVolume?: Maybe<Scalars['Float']['output']>;
  /** Volume in unit of measure for entity */
  totalVolumeUOMCode?: Maybe<Scalars['String']['output']>;
  /** Volume in unit of measure for entity */
  totalVolumeUOMId?: Maybe<Scalars['ID']['output']>;
  /** Volume in unit of measure for entity */
  totalVolumeUOMLabel?: Maybe<Scalars['String']['output']>;
  /** Unit of measure for entity */
  totalWeightUOMCode?: Maybe<Scalars['String']['output']>;
  /** Unit of measure for entity */
  totalWeightUOMId?: Maybe<Scalars['ID']['output']>;
  /** Unit of measure for entity */
  totalWeightUOMLabel?: Maybe<Scalars['String']['output']>;
  /** fulfillment or delivery */
  type?: Maybe<DeliveryType>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity code */
  warehouseCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  warehouseId?: Maybe<Scalars['ID']['output']>;
};

export type ViewDeliveryFilter = {
  and?: InputMaybe<Array<ViewDeliveryFilter>>;
  availability?: InputMaybe<IntFieldComparison>;
  billOfLading?: InputMaybe<StringFieldComparison>;
  blockStatus?: InputMaybe<StringFieldComparison>;
  category?: InputMaybe<DeliveryCategoryFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deliveryStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  doorCode?: InputMaybe<StringFieldComparison>;
  doorId?: InputMaybe<IdFilterComparison>;
  dueDate?: InputMaybe<DateFieldComparison>;
  erpBlockingReason?: InputMaybe<StringFieldComparison>;
  erpCode?: InputMaybe<StringFieldComparison>;
  erpCreated?: InputMaybe<DateFieldComparison>;
  erpLastChanged?: InputMaybe<DateFieldComparison>;
  erpPurchaseOrder?: InputMaybe<StringFieldComparison>;
  erpSalesOrder?: InputMaybe<StringFieldComparison>;
  export?: InputMaybe<StringFieldComparison>;
  fulfillmentBlockId?: InputMaybe<IdFilterComparison>;
  fulfillmentBlockLabel?: InputMaybe<StringFieldComparison>;
  goodsReceiptOrIssueStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  itemCount?: InputMaybe<IntFieldComparison>;
  loadOrUnloadStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  or?: InputMaybe<Array<ViewDeliveryFilter>>;
  orderConfirmationFileId?: InputMaybe<IdFilterComparison>;
  pickOrPutawayStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  pointOfContact?: InputMaybe<StringFieldComparison>;
  progress?: InputMaybe<IntFieldComparison>;
  progressString?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  promiseDate?: InputMaybe<DateFieldComparison>;
  shipReadyDate?: InputMaybe<DateFieldComparison>;
  shipToBusinessPartnerCode?: InputMaybe<StringFieldComparison>;
  shipToBusinessPartnerName?: InputMaybe<StringFieldComparison>;
  shipToCode?: InputMaybe<StringFieldComparison>;
  shipToId?: InputMaybe<IdFilterComparison>;
  shipToName?: InputMaybe<StringFieldComparison>;
  soldToBusinessPartnerCode?: InputMaybe<StringFieldComparison>;
  soldToBusinessPartnerName?: InputMaybe<StringFieldComparison>;
  soldToCode?: InputMaybe<StringFieldComparison>;
  soldToId?: InputMaybe<IdFilterComparison>;
  soldToName?: InputMaybe<StringFieldComparison>;
  supplierBusinessPartnerCode?: InputMaybe<StringFieldComparison>;
  supplierBusinessPartnerName?: InputMaybe<StringFieldComparison>;
  supplierCode?: InputMaybe<StringFieldComparison>;
  supplierId?: InputMaybe<IdFilterComparison>;
  supplierName?: InputMaybe<StringFieldComparison>;
  totalGrossWeight?: InputMaybe<FloatFieldComparison>;
  totalNetWeight?: InputMaybe<FloatFieldComparison>;
  totalVolume?: InputMaybe<FloatFieldComparison>;
  totalVolumeUOMCode?: InputMaybe<StringFieldComparison>;
  totalVolumeUOMId?: InputMaybe<IdFilterComparison>;
  totalVolumeUOMLabel?: InputMaybe<StringFieldComparison>;
  totalWeightUOMCode?: InputMaybe<StringFieldComparison>;
  totalWeightUOMId?: InputMaybe<IdFilterComparison>;
  totalWeightUOMLabel?: InputMaybe<StringFieldComparison>;
  type?: InputMaybe<DeliveryTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  warehouseCode?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type ViewDeliveryItem = {
  __typename?: 'ViewDeliveryItem';
  /** Entity code */
  advancedShipmentNotificationFileCode?: Maybe<Scalars['String']['output']>;
  /** ID for a warehouse op file */
  advancedShipmentNotificationFileId?: Maybe<Scalars['ID']['output']>;
  /** Delivery or fulfillment erp code */
  deliveryCode?: Maybe<Scalars['String']['output']>;
  /** Due date */
  deliveryDueDate?: Maybe<Scalars['DateTime']['output']>;
  /** Delivery ID (foreign key) */
  deliveryId: Scalars['ID']['output'];
  /** Erp last changed date */
  erpLastChanged?: Maybe<Scalars['DateTime']['output']>;
  /** Purchase Order */
  erpPurchaseOrder?: Maybe<Scalars['String']['output']>;
  /** Purchase Order item */
  erpPurchaseOrderItem?: Maybe<Scalars['String']['output']>;
  /** Gross Weight */
  grossWeight?: Maybe<Scalars['Float']['output']>;
  /** Delivery item ID (foreign key) */
  id?: Maybe<Scalars['ID']['output']>;
  /** Delivery item associated with entity */
  item?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  licensePlateCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  licensePlateDescription?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  licensePlateId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  lotCode?: Maybe<Scalars['String']['output']>;
  /** Lot id */
  lotId?: Maybe<Scalars['ID']['output']>;
  /** Net Weight */
  netWeight?: Maybe<Scalars['Float']['output']>;
  /** Entity code */
  productCode?: Maybe<Scalars['String']['output']>;
  /** Product description */
  productDescription?: Maybe<Scalars['String']['output']>;
  /** Product id */
  productId?: Maybe<Scalars['ID']['output']>;
  /** Putaway Status */
  putawayStatus?: Maybe<DeliveryCompletionStatus>;
  /** Quantity of product */
  quantity?: Maybe<Scalars['String']['output']>;
  /** Receipt Status */
  receiptStatus?: Maybe<DeliveryCompletionStatus>;
  /** Actual received quantity */
  recievedQuantity?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  stockStatusCode?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  stockStatusId?: Maybe<Scalars['String']['output']>;
  /** Stock status type entity belongs to */
  stockStatusLabel?: Maybe<Scalars['String']['output']>;
  /** Supplier lot code */
  supplierLotCode?: Maybe<Scalars['String']['output']>;
  /** Unit of measure */
  unitOfMeasure?: Maybe<Scalars['String']['output']>;
  /** Unit of measure ID */
  unitOfMeasureId?: Maybe<Scalars['ID']['output']>;
  /** Unload status */
  unloadStatus?: Maybe<DeliveryCompletionStatus>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Volume */
  volume?: Maybe<Scalars['Float']['output']>;
  /** Volume unit of measure */
  volumeUOMCode?: Maybe<Scalars['String']['output']>;
  /** Volume unit of measure */
  volumeUOMId?: Maybe<Scalars['ID']['output']>;
  /** Volume unit of measure */
  volumeUOMLabel?: Maybe<Scalars['String']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
  /** Weight unit of measure */
  weightUOMCode?: Maybe<Scalars['String']['output']>;
  /** Weight unit of measure */
  weightUOMId?: Maybe<Scalars['ID']['output']>;
  /** Weight unit of measure */
  weightUOMLabel?: Maybe<Scalars['String']['output']>;
};

export type ViewDeliveryItemFilter = {
  advancedShipmentNotificationFileCode?: InputMaybe<StringFieldComparison>;
  advancedShipmentNotificationFileId?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<ViewDeliveryItemFilter>>;
  deliveryCode?: InputMaybe<StringFieldComparison>;
  deliveryDueDate?: InputMaybe<DateFieldComparison>;
  deliveryId?: InputMaybe<IdFilterComparison>;
  erpLastChanged?: InputMaybe<DateFieldComparison>;
  erpPurchaseOrder?: InputMaybe<StringFieldComparison>;
  erpPurchaseOrderItem?: InputMaybe<StringFieldComparison>;
  grossWeight?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  item?: InputMaybe<StringFieldComparison>;
  licensePlateCode?: InputMaybe<StringFieldComparison>;
  licensePlateDescription?: InputMaybe<StringFieldComparison>;
  licensePlateId?: InputMaybe<IdFilterComparison>;
  lotCode?: InputMaybe<StringFieldComparison>;
  lotId?: InputMaybe<IdFilterComparison>;
  netWeight?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<ViewDeliveryItemFilter>>;
  productCode?: InputMaybe<StringFieldComparison>;
  productDescription?: InputMaybe<StringFieldComparison>;
  productId?: InputMaybe<IdFilterComparison>;
  putawayStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  quantity?: InputMaybe<StringFieldComparison>;
  receiptStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  recievedQuantity?: InputMaybe<StringFieldComparison>;
  stockStatusCode?: InputMaybe<StringFieldComparison>;
  stockStatusId?: InputMaybe<StringFieldComparison>;
  stockStatusLabel?: InputMaybe<StringFieldComparison>;
  supplierLotCode?: InputMaybe<StringFieldComparison>;
  unitOfMeasure?: InputMaybe<StringFieldComparison>;
  unitOfMeasureId?: InputMaybe<IdFilterComparison>;
  unloadStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  volume?: InputMaybe<FloatFieldComparison>;
  volumeUOMCode?: InputMaybe<StringFieldComparison>;
  volumeUOMId?: InputMaybe<IdFilterComparison>;
  volumeUOMLabel?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  weightUOMCode?: InputMaybe<StringFieldComparison>;
  weightUOMId?: InputMaybe<IdFilterComparison>;
  weightUOMLabel?: InputMaybe<StringFieldComparison>;
};

export type ViewDeliveryItemOffsetConnection = {
  __typename?: 'ViewDeliveryItemOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewDeliveryItem>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewDeliveryItemSort = {
  direction: SortDirection;
  field: ViewDeliveryItemSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewDeliveryItemSortFields {
  AdvancedShipmentNotificationFileCode = 'advancedShipmentNotificationFileCode',
  AdvancedShipmentNotificationFileId = 'advancedShipmentNotificationFileId',
  DeliveryCode = 'deliveryCode',
  DeliveryDueDate = 'deliveryDueDate',
  DeliveryId = 'deliveryId',
  ErpLastChanged = 'erpLastChanged',
  ErpPurchaseOrder = 'erpPurchaseOrder',
  ErpPurchaseOrderItem = 'erpPurchaseOrderItem',
  GrossWeight = 'grossWeight',
  Id = 'id',
  Item = 'item',
  LicensePlateCode = 'licensePlateCode',
  LicensePlateDescription = 'licensePlateDescription',
  LicensePlateId = 'licensePlateId',
  LotCode = 'lotCode',
  LotId = 'lotId',
  NetWeight = 'netWeight',
  ProductCode = 'productCode',
  ProductDescription = 'productDescription',
  ProductId = 'productId',
  PutawayStatus = 'putawayStatus',
  Quantity = 'quantity',
  ReceiptStatus = 'receiptStatus',
  RecievedQuantity = 'recievedQuantity',
  StockStatusCode = 'stockStatusCode',
  StockStatusId = 'stockStatusId',
  StockStatusLabel = 'stockStatusLabel',
  SupplierLotCode = 'supplierLotCode',
  UnitOfMeasure = 'unitOfMeasure',
  UnitOfMeasureId = 'unitOfMeasureId',
  UnloadStatus = 'unloadStatus',
  UpdatedAt = 'updatedAt',
  Volume = 'volume',
  VolumeUomCode = 'volumeUOMCode',
  VolumeUomId = 'volumeUOMId',
  VolumeUomLabel = 'volumeUOMLabel',
  WarehouseId = 'warehouseId',
  WeightUomCode = 'weightUOMCode',
  WeightUomId = 'weightUOMId',
  WeightUomLabel = 'weightUOMLabel'
}

export type ViewDeliveryOffsetConnection = {
  __typename?: 'ViewDeliveryOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewDelivery>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewDeliverySort = {
  direction: SortDirection;
  field: ViewDeliverySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewDeliverySortFields {
  Availability = 'availability',
  BillOfLading = 'billOfLading',
  BlockStatus = 'blockStatus',
  Category = 'category',
  CreatedAt = 'createdAt',
  DeliveryStatus = 'deliveryStatus',
  DoorCode = 'doorCode',
  DoorId = 'doorId',
  DueDate = 'dueDate',
  ErpBlockingReason = 'erpBlockingReason',
  ErpCode = 'erpCode',
  ErpCreated = 'erpCreated',
  ErpLastChanged = 'erpLastChanged',
  ErpPurchaseOrder = 'erpPurchaseOrder',
  ErpSalesOrder = 'erpSalesOrder',
  Export = 'export',
  FulfillmentBlockId = 'fulfillmentBlockId',
  FulfillmentBlockLabel = 'fulfillmentBlockLabel',
  GoodsReceiptOrIssueStatus = 'goodsReceiptOrIssueStatus',
  Id = 'id',
  ItemCount = 'itemCount',
  LoadOrUnloadStatus = 'loadOrUnloadStatus',
  OrderConfirmationFileId = 'orderConfirmationFileId',
  PickOrPutawayStatus = 'pickOrPutawayStatus',
  PointOfContact = 'pointOfContact',
  Progress = 'progress',
  ProgressString = 'progressString',
  PromiseDate = 'promiseDate',
  ShipReadyDate = 'shipReadyDate',
  ShipToBusinessPartnerCode = 'shipToBusinessPartnerCode',
  ShipToBusinessPartnerName = 'shipToBusinessPartnerName',
  ShipToCode = 'shipToCode',
  ShipToId = 'shipToId',
  ShipToName = 'shipToName',
  SoldToBusinessPartnerCode = 'soldToBusinessPartnerCode',
  SoldToBusinessPartnerName = 'soldToBusinessPartnerName',
  SoldToCode = 'soldToCode',
  SoldToId = 'soldToId',
  SoldToName = 'soldToName',
  SupplierBusinessPartnerCode = 'supplierBusinessPartnerCode',
  SupplierBusinessPartnerName = 'supplierBusinessPartnerName',
  SupplierCode = 'supplierCode',
  SupplierId = 'supplierId',
  SupplierName = 'supplierName',
  TotalGrossWeight = 'totalGrossWeight',
  TotalNetWeight = 'totalNetWeight',
  TotalVolume = 'totalVolume',
  TotalVolumeUomCode = 'totalVolumeUOMCode',
  TotalVolumeUomId = 'totalVolumeUOMId',
  TotalVolumeUomLabel = 'totalVolumeUOMLabel',
  TotalWeightUomCode = 'totalWeightUOMCode',
  TotalWeightUomId = 'totalWeightUOMId',
  TotalWeightUomLabel = 'totalWeightUOMLabel',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  WarehouseCode = 'warehouseCode',
  WarehouseId = 'warehouseId'
}

export type ViewDoor = {
  __typename?: 'ViewDoor';
  /** Entity code */
  areaCode?: Maybe<Scalars['String']['output']>;
  /** Entity's area ID (foreign key) */
  areaId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  binCode?: Maybe<Scalars['String']['output']>;
  /** Entity's bin ID (foreign key) */
  binId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  code?: Maybe<Scalars['String']['output']>;
  /** Door direction.  inbound outbound or both. */
  direction?: Maybe<DoorDirection>;
  /** Entity ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** x coordinate location */
  x?: Maybe<Scalars['Float']['output']>;
  /** Y coordinate location */
  y?: Maybe<Scalars['Float']['output']>;
};

export type ViewDoorFilter = {
  and?: InputMaybe<Array<ViewDoorFilter>>;
  areaCode?: InputMaybe<StringFieldComparison>;
  areaId?: InputMaybe<IdFilterComparison>;
  binCode?: InputMaybe<StringFieldComparison>;
  binId?: InputMaybe<IdFilterComparison>;
  code?: InputMaybe<StringFieldComparison>;
  direction?: InputMaybe<DoorDirectionFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ViewDoorFilter>>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  x?: InputMaybe<FloatFieldComparison>;
  y?: InputMaybe<FloatFieldComparison>;
};

export type ViewDoorOffsetConnection = {
  __typename?: 'ViewDoorOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewDoor>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewDoorSort = {
  direction: SortDirection;
  field: ViewDoorSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewDoorSortFields {
  AreaCode = 'areaCode',
  AreaId = 'areaId',
  BinCode = 'binCode',
  BinId = 'binId',
  Code = 'code',
  Direction = 'direction',
  Id = 'id',
  WarehouseId = 'warehouseId',
  X = 'x',
  Y = 'y'
}

export type ViewEquipment = {
  __typename?: 'ViewEquipment';
  /** Entity code */
  code?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Model of equipment */
  equipmentModelId?: Maybe<Scalars['String']['output']>;
  /** Type of equipment, i.e. forklift */
  equipmentTypeId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Entity's label */
  label?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  modelCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  modelDescription?: Maybe<Scalars['String']['output']>;
  /** Entity's warehouse (foreign key) */
  modelLabel?: Maybe<Scalars['String']['output']>;
  /** Status of equipment */
  status?: Maybe<EquipmentStatus>;
  /** Identifier for Redpoint Tracking Tag. */
  tagId?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  typeCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  typeDescription?: Maybe<Scalars['String']['output']>;
  /** Number representing maximum height allowance of equipment model. */
  typeHeightMax?: Maybe<Scalars['Float']['output']>;
  /** Number representing minimum height allowance of equipment model. */
  typeHeightMin?: Maybe<Scalars['Float']['output']>;
  /** Imperial or metric unit of measure for height limit. */
  typeHeightUOMCode?: Maybe<Scalars['String']['output']>;
  /** Imperial or metric unit of measure for height limit. */
  typeHeightUOMId?: Maybe<Scalars['ID']['output']>;
  /** Imperial or metric unit of measure for height limit. */
  typeHeightUOMLabel?: Maybe<Scalars['ID']['output']>;
  /** Entity's label */
  typeLabel?: Maybe<Scalars['String']['output']>;
  /** Type of location data for an equipment model (Dynamic/Static) */
  typeMobility?: Maybe<EquipmentMobility>;
  /** Velocity */
  typeVelocity?: Maybe<Scalars['Float']['output']>;
  /** Velocity unit of measure */
  typeVelocityUOMCode?: Maybe<Scalars['String']['output']>;
  /** Velocity unit of measure */
  typeVelocityUOMId?: Maybe<Scalars['String']['output']>;
  /** Velocity unit of measure */
  typeVelocityUOMLabel?: Maybe<Scalars['String']['output']>;
  /** Velocity */
  typeVerticalVelocity?: Maybe<Scalars['Float']['output']>;
  /** Max Volume of entity */
  typeVolumeMax?: Maybe<Scalars['Float']['output']>;
  /** Volume in unit of measure for entity */
  typeVolumeUOMCode?: Maybe<Scalars['String']['output']>;
  /** Volume in unit of measure for entity */
  typeVolumeUOMId?: Maybe<Scalars['ID']['output']>;
  /** Volume in unit of measure for entity */
  typeVolumeUOMLabel?: Maybe<Scalars['String']['output']>;
  /** Number representing maximum weight limit for an equipment model. */
  typeWeightMax?: Maybe<Scalars['Float']['output']>;
  /** Weight UOM, i.e KG */
  typeWeightUOMCode?: Maybe<Scalars['String']['output']>;
  /** Weight UOM, i.e KG */
  typeWeightUOMId?: Maybe<Scalars['ID']['output']>;
  /** Weight UOM, i.e KG */
  typeWeightUOMLabel?: Maybe<Scalars['String']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
};

export type ViewEquipmentFilter = {
  and?: InputMaybe<Array<ViewEquipmentFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  equipmentModelId?: InputMaybe<StringFieldComparison>;
  equipmentTypeId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  modelCode?: InputMaybe<StringFieldComparison>;
  modelDescription?: InputMaybe<StringFieldComparison>;
  modelLabel?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ViewEquipmentFilter>>;
  status?: InputMaybe<EquipmentStatusFilterComparison>;
  tagId?: InputMaybe<StringFieldComparison>;
  typeCode?: InputMaybe<StringFieldComparison>;
  typeDescription?: InputMaybe<StringFieldComparison>;
  typeHeightMax?: InputMaybe<FloatFieldComparison>;
  typeHeightMin?: InputMaybe<FloatFieldComparison>;
  typeHeightUOMCode?: InputMaybe<StringFieldComparison>;
  typeHeightUOMId?: InputMaybe<IdFilterComparison>;
  typeHeightUOMLabel?: InputMaybe<IdFilterComparison>;
  typeLabel?: InputMaybe<StringFieldComparison>;
  typeMobility?: InputMaybe<EquipmentMobilityFilterComparison>;
  typeVelocity?: InputMaybe<FloatFieldComparison>;
  typeVelocityUOMCode?: InputMaybe<StringFieldComparison>;
  typeVelocityUOMId?: InputMaybe<StringFieldComparison>;
  typeVelocityUOMLabel?: InputMaybe<StringFieldComparison>;
  typeVerticalVelocity?: InputMaybe<FloatFieldComparison>;
  typeVolumeMax?: InputMaybe<FloatFieldComparison>;
  typeVolumeUOMCode?: InputMaybe<StringFieldComparison>;
  typeVolumeUOMId?: InputMaybe<IdFilterComparison>;
  typeVolumeUOMLabel?: InputMaybe<StringFieldComparison>;
  typeWeightMax?: InputMaybe<FloatFieldComparison>;
  typeWeightUOMCode?: InputMaybe<StringFieldComparison>;
  typeWeightUOMId?: InputMaybe<IdFilterComparison>;
  typeWeightUOMLabel?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type ViewEquipmentModel = {
  __typename?: 'ViewEquipmentModel';
  /** Entity code */
  code?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Type of equipment, i.e. forklift */
  equipmentTypeId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Entity's label */
  label?: Maybe<Scalars['String']['output']>;
  /** Entity's label */
  typeLabel?: Maybe<Scalars['String']['output']>;
};

export type ViewEquipmentModelFilter = {
  and?: InputMaybe<Array<ViewEquipmentModelFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  equipmentTypeId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ViewEquipmentModelFilter>>;
  typeLabel?: InputMaybe<StringFieldComparison>;
};

export type ViewEquipmentModelOffsetConnection = {
  __typename?: 'ViewEquipmentModelOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewEquipmentModel>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewEquipmentModelSort = {
  direction: SortDirection;
  field: ViewEquipmentModelSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewEquipmentModelSortFields {
  Code = 'code',
  Description = 'description',
  EquipmentTypeId = 'equipmentTypeId',
  Id = 'id',
  Label = 'label',
  TypeLabel = 'typeLabel'
}

export type ViewEquipmentOffsetConnection = {
  __typename?: 'ViewEquipmentOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewEquipment>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewEquipmentSort = {
  direction: SortDirection;
  field: ViewEquipmentSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewEquipmentSortFields {
  Code = 'code',
  Description = 'description',
  EquipmentModelId = 'equipmentModelId',
  EquipmentTypeId = 'equipmentTypeId',
  Id = 'id',
  Label = 'label',
  ModelCode = 'modelCode',
  ModelDescription = 'modelDescription',
  ModelLabel = 'modelLabel',
  Status = 'status',
  TagId = 'tagId',
  TypeCode = 'typeCode',
  TypeDescription = 'typeDescription',
  TypeHeightMax = 'typeHeightMax',
  TypeHeightMin = 'typeHeightMin',
  TypeHeightUomCode = 'typeHeightUOMCode',
  TypeHeightUomId = 'typeHeightUOMId',
  TypeHeightUomLabel = 'typeHeightUOMLabel',
  TypeLabel = 'typeLabel',
  TypeMobility = 'typeMobility',
  TypeVelocity = 'typeVelocity',
  TypeVelocityUomCode = 'typeVelocityUOMCode',
  TypeVelocityUomId = 'typeVelocityUOMId',
  TypeVelocityUomLabel = 'typeVelocityUOMLabel',
  TypeVerticalVelocity = 'typeVerticalVelocity',
  TypeVolumeMax = 'typeVolumeMax',
  TypeVolumeUomCode = 'typeVolumeUOMCode',
  TypeVolumeUomId = 'typeVolumeUOMId',
  TypeVolumeUomLabel = 'typeVolumeUOMLabel',
  TypeWeightMax = 'typeWeightMax',
  TypeWeightUomCode = 'typeWeightUOMCode',
  TypeWeightUomId = 'typeWeightUOMId',
  TypeWeightUomLabel = 'typeWeightUOMLabel',
  WarehouseId = 'warehouseId'
}

export type ViewFulfillmentItem = {
  __typename?: 'ViewFulfillmentItem';
  /** A delivery or fulfillment items availability */
  availability?: Maybe<Scalars['Int']['output']>;
  /** Availability date */
  dateAvailable?: Maybe<Scalars['DateTime']['output']>;
  /** Deliery id */
  deliveryId?: Maybe<Scalars['ID']['output']>;
  /** When data was last updated in ERP */
  erpLastChanged?: Maybe<Scalars['DateTime']['output']>;
  /** Delivery associated with the entity */
  fulfillmentCode?: Maybe<Scalars['String']['output']>;
  /** Due date */
  fulfillmentDueDate?: Maybe<Scalars['DateTime']['output']>;
  /** Gross Weight */
  grossWeight?: Maybe<Scalars['Float']['output']>;
  /** Fulfillment item id (foreign key) */
  id?: Maybe<Scalars['ID']['output']>;
  /** Fulfillment item associated with the entity */
  item?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  licensePlateCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  licensePlateDescription?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  licensePlateId?: Maybe<Scalars['ID']['output']>;
  /** Load status */
  loadStatus?: Maybe<DeliveryCompletionStatus>;
  /** Entity code */
  lotCode?: Maybe<Scalars['String']['output']>;
  /** Lot id */
  lotId?: Maybe<Scalars['ID']['output']>;
  /** Net Weight */
  netWeight?: Maybe<Scalars['Float']['output']>;
  /** Pick status */
  pickStatus?: Maybe<DeliveryCompletionStatus>;
  /** Entity code */
  productCode?: Maybe<Scalars['String']['output']>;
  /** Product description */
  productDescription?: Maybe<Scalars['String']['output']>;
  /** Product id */
  productId?: Maybe<Scalars['ID']['output']>;
  /** Quantity of product */
  quantity?: Maybe<Scalars['String']['output']>;
  /** Reference document */
  salesOrderCode?: Maybe<Scalars['String']['output']>;
  /** Reference item */
  salesOrderItem?: Maybe<Scalars['String']['output']>;
  /** Stock status type entity belongs to */
  stockStatus?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  stockStatusCode?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  stockStatusId?: Maybe<Scalars['String']['output']>;
  /** Unit of measure */
  unitOfMeasure?: Maybe<Scalars['String']['output']>;
  /** Unit of measure ID */
  unitOfMeasureId?: Maybe<Scalars['ID']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Volume */
  volume?: Maybe<Scalars['Float']['output']>;
  /** Volume unit of measure */
  volumeUOMCode?: Maybe<Scalars['String']['output']>;
  /** Volume unit of measure */
  volumeUOMId?: Maybe<Scalars['ID']['output']>;
  /** Volume unit of measure */
  volumeUOMLabel?: Maybe<Scalars['String']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
  /** Weight unit of measure */
  weightUOMCode?: Maybe<Scalars['String']['output']>;
  /** Weight unit of measure */
  weightUOMId?: Maybe<Scalars['ID']['output']>;
  /** Weight unit of measure */
  weightUOMLabel?: Maybe<Scalars['String']['output']>;
};

export type ViewFulfillmentItemFilter = {
  and?: InputMaybe<Array<ViewFulfillmentItemFilter>>;
  availability?: InputMaybe<IntFieldComparison>;
  dateAvailable?: InputMaybe<DateFieldComparison>;
  deliveryId?: InputMaybe<IdFilterComparison>;
  erpLastChanged?: InputMaybe<DateFieldComparison>;
  fulfillmentCode?: InputMaybe<StringFieldComparison>;
  fulfillmentDueDate?: InputMaybe<DateFieldComparison>;
  grossWeight?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  item?: InputMaybe<StringFieldComparison>;
  licensePlateCode?: InputMaybe<StringFieldComparison>;
  licensePlateDescription?: InputMaybe<StringFieldComparison>;
  licensePlateId?: InputMaybe<IdFilterComparison>;
  loadStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  lotCode?: InputMaybe<StringFieldComparison>;
  lotId?: InputMaybe<IdFilterComparison>;
  netWeight?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<ViewFulfillmentItemFilter>>;
  pickStatus?: InputMaybe<DeliveryCompletionStatusFilterComparison>;
  productCode?: InputMaybe<StringFieldComparison>;
  productDescription?: InputMaybe<StringFieldComparison>;
  productId?: InputMaybe<IdFilterComparison>;
  quantity?: InputMaybe<StringFieldComparison>;
  salesOrderCode?: InputMaybe<StringFieldComparison>;
  salesOrderItem?: InputMaybe<StringFieldComparison>;
  stockStatus?: InputMaybe<StringFieldComparison>;
  stockStatusCode?: InputMaybe<StringFieldComparison>;
  stockStatusId?: InputMaybe<StringFieldComparison>;
  unitOfMeasure?: InputMaybe<StringFieldComparison>;
  unitOfMeasureId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  volume?: InputMaybe<FloatFieldComparison>;
  volumeUOMCode?: InputMaybe<StringFieldComparison>;
  volumeUOMId?: InputMaybe<IdFilterComparison>;
  volumeUOMLabel?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  weightUOMCode?: InputMaybe<StringFieldComparison>;
  weightUOMId?: InputMaybe<IdFilterComparison>;
  weightUOMLabel?: InputMaybe<StringFieldComparison>;
};

export type ViewFulfillmentItemOffsetConnection = {
  __typename?: 'ViewFulfillmentItemOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewFulfillmentItem>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewFulfillmentItemSort = {
  direction: SortDirection;
  field: ViewFulfillmentItemSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewFulfillmentItemSortFields {
  Availability = 'availability',
  DateAvailable = 'dateAvailable',
  DeliveryId = 'deliveryId',
  ErpLastChanged = 'erpLastChanged',
  FulfillmentCode = 'fulfillmentCode',
  FulfillmentDueDate = 'fulfillmentDueDate',
  GrossWeight = 'grossWeight',
  Id = 'id',
  Item = 'item',
  LicensePlateCode = 'licensePlateCode',
  LicensePlateDescription = 'licensePlateDescription',
  LicensePlateId = 'licensePlateId',
  LoadStatus = 'loadStatus',
  LotCode = 'lotCode',
  LotId = 'lotId',
  NetWeight = 'netWeight',
  PickStatus = 'pickStatus',
  ProductCode = 'productCode',
  ProductDescription = 'productDescription',
  ProductId = 'productId',
  Quantity = 'quantity',
  SalesOrderCode = 'salesOrderCode',
  SalesOrderItem = 'salesOrderItem',
  StockStatus = 'stockStatus',
  StockStatusCode = 'stockStatusCode',
  StockStatusId = 'stockStatusId',
  UnitOfMeasure = 'unitOfMeasure',
  UnitOfMeasureId = 'unitOfMeasureId',
  UpdatedAt = 'updatedAt',
  Volume = 'volume',
  VolumeUomCode = 'volumeUOMCode',
  VolumeUomId = 'volumeUOMId',
  VolumeUomLabel = 'volumeUOMLabel',
  WarehouseId = 'warehouseId',
  WeightUomCode = 'weightUOMCode',
  WeightUomId = 'weightUOMId',
  WeightUomLabel = 'weightUOMLabel'
}

export type ViewHistoryFieldDiff = {
  __typename?: 'ViewHistoryFieldDiff';
  /** Which change occured, i.e. UPDATE, CREATE */
  changeType?: Maybe<EntityChangeType>;
  /** Entity code */
  code?: Maybe<Scalars['String']['output']>;
  /** Update at date */
  date?: Maybe<Scalars['DateTime']['output']>;
  /** Entity's human readable name */
  entity?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  entityId?: Maybe<Scalars['ID']['output']>;
  /** Name of the column changing values */
  field?: Maybe<Scalars['String']['output']>;
  /** Diff field type */
  fieldType?: Maybe<Scalars['String']['output']>;
  /** The history field diff ID */
  historyId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Person's first name */
  name?: Maybe<Scalars['String']['output']>;
  /** New value of the history field diff */
  new?: Maybe<Scalars['String']['output']>;
  /** Entity version */
  newVersion?: Maybe<Scalars['Int']['output']>;
  /** Old value of the history field diff */
  old?: Maybe<Scalars['String']['output']>;
  /** Entity version */
  oldVersion?: Maybe<Scalars['Int']['output']>;
  /** Entity's user ID (foreign key) */
  userId?: Maybe<Scalars['String']['output']>;
};

export type ViewHistoryFieldDiffFilter = {
  and?: InputMaybe<Array<ViewHistoryFieldDiffFilter>>;
  changeType?: InputMaybe<EntityChangeTypeFilterComparison>;
  code?: InputMaybe<StringFieldComparison>;
  date?: InputMaybe<DateFieldComparison>;
  entity?: InputMaybe<StringFieldComparison>;
  entityId?: InputMaybe<IdFilterComparison>;
  field?: InputMaybe<StringFieldComparison>;
  fieldType?: InputMaybe<StringFieldComparison>;
  historyId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  new?: InputMaybe<StringFieldComparison>;
  newVersion?: InputMaybe<IntFieldComparison>;
  old?: InputMaybe<StringFieldComparison>;
  oldVersion?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<ViewHistoryFieldDiffFilter>>;
  userId?: InputMaybe<StringFieldComparison>;
};

export type ViewHistoryFieldDiffOffsetConnection = {
  __typename?: 'ViewHistoryFieldDiffOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewHistoryFieldDiff>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewHistoryFieldDiffSort = {
  direction: SortDirection;
  field: ViewHistoryFieldDiffSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewHistoryFieldDiffSortFields {
  ChangeType = 'changeType',
  Code = 'code',
  Date = 'date',
  Entity = 'entity',
  EntityId = 'entityId',
  Field = 'field',
  FieldType = 'fieldType',
  HistoryId = 'historyId',
  Id = 'id',
  Name = 'name',
  New = 'new',
  NewVersion = 'newVersion',
  Old = 'old',
  OldVersion = 'oldVersion',
  UserId = 'userId'
}

export type ViewLostAndFound = {
  __typename?: 'ViewLostAndFound';
  /** Entity code */
  areaCode?: Maybe<Scalars['String']['output']>;
  /** Entity's area ID (foreign key) */
  areaId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  binCode?: Maybe<Scalars['String']['output']>;
  /** Entity's bin ID (foreign key) */
  binId?: Maybe<Scalars['ID']['output']>;
  /** Bin type */
  binType?: Maybe<Scalars['String']['output']>;
  /** Delivery item associated with entity */
  deliveryItem?: Maybe<Scalars['String']['output']>;
  /** Delivery item ID (foreign key) */
  deliveryItemId?: Maybe<Scalars['ID']['output']>;
  /** Fulfillment item associated with the entity */
  fulfillmentItem?: Maybe<Scalars['String']['output']>;
  /** Fulfillment item id (foreign key) */
  fulfillmentItemId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  licensePlateCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  licensePlateDescription?: Maybe<Scalars['String']['output']>;
  /** Entity's license plate ID (foreign key) */
  licensePlateId?: Maybe<Scalars['ID']['output']>;
  /** Active/Inactive bin status flag */
  licensePlateStatus?: Maybe<LicensePlateStatusState>;
  /** Entity code */
  lotCode?: Maybe<Scalars['String']['output']>;
  /** Entity's lot ID (foreign key) */
  lotId?: Maybe<Scalars['ID']['output']>;
  /** Whether the product is lot managed or not */
  lotManaged?: Maybe<Scalars['Boolean']['output']>;
  /** Entity code */
  productCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  productDescription?: Maybe<Scalars['String']['output']>;
  /** Product ID */
  productId?: Maybe<Scalars['ID']['output']>;
  /** Quantity of product */
  quantity: Scalars['String']['output'];
  /** Entity code */
  stockDeliveryCode?: Maybe<Scalars['String']['output']>;
  /** Delivery ID (foreign key) */
  stockDeliveryId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  stockFulfillmentCode?: Maybe<Scalars['String']['output']>;
  /** Fulfillment Id */
  stockFulfillmentId?: Maybe<Scalars['ID']['output']>;
  /** Entity's stock status type ID (foreign key) */
  stockStatusId?: Maybe<Scalars['ID']['output']>;
  /** I.e. Returns, Available, Lot Restricted etc. */
  stockStatusLabel?: Maybe<Scalars['String']['output']>;
  /** Unit of measure for entity */
  unitOfMeasureCode?: Maybe<Scalars['String']['output']>;
  /** Unit of measure ID */
  unitOfMeasureId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  warehouseCode?: Maybe<Scalars['String']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
};

export type ViewLostAndFoundFilter = {
  and?: InputMaybe<Array<ViewLostAndFoundFilter>>;
  areaCode?: InputMaybe<StringFieldComparison>;
  areaId?: InputMaybe<IdFilterComparison>;
  binCode?: InputMaybe<StringFieldComparison>;
  binId?: InputMaybe<IdFilterComparison>;
  binType?: InputMaybe<StringFieldComparison>;
  deliveryItem?: InputMaybe<StringFieldComparison>;
  deliveryItemId?: InputMaybe<IdFilterComparison>;
  fulfillmentItem?: InputMaybe<StringFieldComparison>;
  fulfillmentItemId?: InputMaybe<IdFilterComparison>;
  licensePlateCode?: InputMaybe<StringFieldComparison>;
  licensePlateDescription?: InputMaybe<StringFieldComparison>;
  licensePlateId?: InputMaybe<IdFilterComparison>;
  licensePlateStatus?: InputMaybe<LicensePlateStatusStateFilterComparison>;
  lotCode?: InputMaybe<StringFieldComparison>;
  lotId?: InputMaybe<IdFilterComparison>;
  lotManaged?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<ViewLostAndFoundFilter>>;
  productCode?: InputMaybe<StringFieldComparison>;
  productDescription?: InputMaybe<StringFieldComparison>;
  productId?: InputMaybe<IdFilterComparison>;
  quantity?: InputMaybe<StringFieldComparison>;
  stockDeliveryCode?: InputMaybe<StringFieldComparison>;
  stockDeliveryId?: InputMaybe<IdFilterComparison>;
  stockFulfillmentCode?: InputMaybe<StringFieldComparison>;
  stockFulfillmentId?: InputMaybe<IdFilterComparison>;
  stockStatusId?: InputMaybe<IdFilterComparison>;
  stockStatusLabel?: InputMaybe<StringFieldComparison>;
  unitOfMeasureCode?: InputMaybe<StringFieldComparison>;
  unitOfMeasureId?: InputMaybe<IdFilterComparison>;
  warehouseCode?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type ViewLostAndFoundOffsetConnection = {
  __typename?: 'ViewLostAndFoundOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewLostAndFound>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewLostAndFoundSort = {
  direction: SortDirection;
  field: ViewLostAndFoundSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewLostAndFoundSortFields {
  AreaCode = 'areaCode',
  AreaId = 'areaId',
  BinCode = 'binCode',
  BinId = 'binId',
  BinType = 'binType',
  DeliveryItem = 'deliveryItem',
  DeliveryItemId = 'deliveryItemId',
  FulfillmentItem = 'fulfillmentItem',
  FulfillmentItemId = 'fulfillmentItemId',
  LicensePlateCode = 'licensePlateCode',
  LicensePlateDescription = 'licensePlateDescription',
  LicensePlateId = 'licensePlateId',
  LicensePlateStatus = 'licensePlateStatus',
  LotCode = 'lotCode',
  LotId = 'lotId',
  LotManaged = 'lotManaged',
  ProductCode = 'productCode',
  ProductDescription = 'productDescription',
  ProductId = 'productId',
  Quantity = 'quantity',
  StockDeliveryCode = 'stockDeliveryCode',
  StockDeliveryId = 'stockDeliveryId',
  StockFulfillmentCode = 'stockFulfillmentCode',
  StockFulfillmentId = 'stockFulfillmentId',
  StockStatusId = 'stockStatusId',
  StockStatusLabel = 'stockStatusLabel',
  UnitOfMeasureCode = 'unitOfMeasureCode',
  UnitOfMeasureId = 'unitOfMeasureId',
  WarehouseCode = 'warehouseCode',
  WarehouseId = 'warehouseId'
}

export type ViewLot = {
  __typename?: 'ViewLot';
  /** Entity code */
  code: Scalars['String']['output'];
  /** When data was created in ERP */
  erpCreatedOn?: Maybe<Scalars['DateTime']['output']>;
  /** When data was last updated in ERP */
  erpUpdatedOn?: Maybe<Scalars['DateTime']['output']>;
  /** Lot expiration date */
  expiration?: Maybe<Scalars['DateTime']['output']>;
  /** Lot ID */
  id: Scalars['ID']['output'];
  /** Product entity belongs to */
  productCode?: Maybe<Scalars['String']['output']>;
  /** Entity's product ID (foreign key) */
  productId?: Maybe<Scalars['ID']['output']>;
  /** Lot production date */
  productionDate?: Maybe<Scalars['DateTime']['output']>;
  /** Lot supplier number */
  supplierLotNumber?: Maybe<Scalars['String']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ViewLotFilter = {
  and?: InputMaybe<Array<ViewLotFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  erpCreatedOn?: InputMaybe<DateFieldComparison>;
  erpUpdatedOn?: InputMaybe<DateFieldComparison>;
  expiration?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ViewLotFilter>>;
  productCode?: InputMaybe<StringFieldComparison>;
  productId?: InputMaybe<IdFilterComparison>;
  productionDate?: InputMaybe<DateFieldComparison>;
  supplierLotNumber?: InputMaybe<StringFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
};

export type ViewLotOffsetConnection = {
  __typename?: 'ViewLotOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewLot>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewLotSort = {
  direction: SortDirection;
  field: ViewLotSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewLotSortFields {
  Code = 'code',
  ErpCreatedOn = 'erpCreatedOn',
  ErpUpdatedOn = 'erpUpdatedOn',
  Expiration = 'expiration',
  Id = 'id',
  ProductCode = 'productCode',
  ProductId = 'productId',
  ProductionDate = 'productionDate',
  SupplierLotNumber = 'supplierLotNumber',
  UpdatedAt = 'updatedAt'
}

export type ViewMapAisle = {
  __typename?: 'ViewMapAisle';
  /** Entity code */
  areaCode?: Maybe<Scalars['String']['output']>;
  /** Entity's area ID (foreign key) */
  areaId?: Maybe<Scalars['ID']['output']>;
  /** Area's name */
  areaName?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  code?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Volume capacity of storage */
  dimensionCapacity?: Maybe<Scalars['Int']['output']>;
  /** Entity ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** ApiDocs */
  name?: Maybe<Scalars['String']['output']>;
  /** Area's status, i.e. ACTIVE, INACTIVE */
  status?: Maybe<AreaStatus>;
  /** Entity's SAP storage location */
  storageLocation?: Maybe<Scalars['String']['output']>;
  /** Entity's SAP storage location ID (foreign key) */
  storageLocationId?: Maybe<Scalars['ID']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** Weight capacity of storage */
  weightCapacity?: Maybe<Scalars['Int']['output']>;
};

export type ViewMapAisleFilter = {
  and?: InputMaybe<Array<ViewMapAisleFilter>>;
  areaCode?: InputMaybe<StringFieldComparison>;
  areaId?: InputMaybe<IdFilterComparison>;
  areaName?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  dimensionCapacity?: InputMaybe<IntFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ViewMapAisleFilter>>;
  status?: InputMaybe<AreaStatusFilterComparison>;
  storageLocation?: InputMaybe<StringFieldComparison>;
  storageLocationId?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  weightCapacity?: InputMaybe<IntFieldComparison>;
};

export type ViewMapAisleOffsetConnection = {
  __typename?: 'ViewMapAisleOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewMapAisle>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewMapAisleSort = {
  direction: SortDirection;
  field: ViewMapAisleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewMapAisleSortFields {
  AreaCode = 'areaCode',
  AreaId = 'areaId',
  AreaName = 'areaName',
  Code = 'code',
  Description = 'description',
  DimensionCapacity = 'dimensionCapacity',
  Id = 'id',
  Name = 'name',
  Status = 'status',
  StorageLocation = 'storageLocation',
  StorageLocationId = 'storageLocationId',
  WarehouseId = 'warehouseId',
  WeightCapacity = 'weightCapacity'
}

export type ViewMapBin = {
  __typename?: 'ViewMapBin';
  /** Aisle within the warehouse. */
  aisleCode?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  aisleColumnCode?: Maybe<Scalars['String']['output']>;
  /** Column where the bin is located. */
  aisleColumnId?: Maybe<Scalars['ID']['output']>;
  /** Aisle within the warehouse. */
  aisleId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  areaCode?: Maybe<Scalars['String']['output']>;
  /** Entity's area ID (foreign key) */
  areaId?: Maybe<Scalars['ID']['output']>;
  /** Area's name */
  areaName?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  binCode?: Maybe<Scalars['String']['output']>;
  /** Entity's bin ID (foreign key) */
  binId?: Maybe<Scalars['ID']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Destination bin block flag */
  destinationBinBlock?: Maybe<BinBlockState>;
  /** Volume capacity of storage */
  dimensionCapacity?: Maybe<Scalars['Float']['output']>;
  /** Timestamp of latest inventory count approval */
  lastCount?: Maybe<Scalars['DateTime']['output']>;
  /** Timestamp of latest movement */
  lastMovement?: Maybe<Scalars['DateTime']['output']>;
  /** Level where the bin is located. */
  level?: Maybe<Scalars['String']['output']>;
  /** Weight capacity of storage */
  maxVolumeCapacity?: Maybe<Scalars['Float']['output']>;
  /** Weight capacity of storage */
  maxWeightCapacity?: Maybe<Scalars['Float']['output']>;
  /** Weight capacity of storage */
  productVolume?: Maybe<Scalars['Float']['output']>;
  /** Weight capacity of storage */
  productWeight?: Maybe<Scalars['Float']['output']>;
  /** Source bin block flag */
  sourceBinBlock?: Maybe<BinBlockState>;
  /** Area's status, i.e. ACTIVE, INACTIVE */
  status?: Maybe<AreaStatus>;
  /** Entity's SAP storage location */
  storageLocation?: Maybe<Scalars['String']['output']>;
  /** Entity's SAP storage location ID (foreign key) */
  storageLocationId?: Maybe<Scalars['ID']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** Weight capacity of storage */
  weightCapacity?: Maybe<Scalars['Float']['output']>;
};

export type ViewMapBinFilter = {
  aisleCode?: InputMaybe<StringFieldComparison>;
  aisleColumnCode?: InputMaybe<StringFieldComparison>;
  aisleColumnId?: InputMaybe<IdFilterComparison>;
  aisleId?: InputMaybe<IdFilterComparison>;
  and?: InputMaybe<Array<ViewMapBinFilter>>;
  areaCode?: InputMaybe<StringFieldComparison>;
  areaId?: InputMaybe<IdFilterComparison>;
  areaName?: InputMaybe<StringFieldComparison>;
  binCode?: InputMaybe<StringFieldComparison>;
  binId?: InputMaybe<IdFilterComparison>;
  description?: InputMaybe<StringFieldComparison>;
  destinationBinBlock?: InputMaybe<BinBlockStateFilterComparison>;
  dimensionCapacity?: InputMaybe<FloatFieldComparison>;
  lastCount?: InputMaybe<DateFieldComparison>;
  lastMovement?: InputMaybe<DateFieldComparison>;
  level?: InputMaybe<StringFieldComparison>;
  maxVolumeCapacity?: InputMaybe<FloatFieldComparison>;
  maxWeightCapacity?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<ViewMapBinFilter>>;
  productVolume?: InputMaybe<FloatFieldComparison>;
  productWeight?: InputMaybe<FloatFieldComparison>;
  sourceBinBlock?: InputMaybe<BinBlockStateFilterComparison>;
  status?: InputMaybe<AreaStatusFilterComparison>;
  storageLocation?: InputMaybe<StringFieldComparison>;
  storageLocationId?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  weightCapacity?: InputMaybe<FloatFieldComparison>;
};

export type ViewMapBinOffsetConnection = {
  __typename?: 'ViewMapBinOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewMapBin>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewMapBinSort = {
  direction: SortDirection;
  field: ViewMapBinSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewMapBinSortFields {
  AisleCode = 'aisleCode',
  AisleColumnCode = 'aisleColumnCode',
  AisleColumnId = 'aisleColumnId',
  AisleId = 'aisleId',
  AreaCode = 'areaCode',
  AreaId = 'areaId',
  AreaName = 'areaName',
  BinCode = 'binCode',
  BinId = 'binId',
  Description = 'description',
  DestinationBinBlock = 'destinationBinBlock',
  DimensionCapacity = 'dimensionCapacity',
  LastCount = 'lastCount',
  LastMovement = 'lastMovement',
  Level = 'level',
  MaxVolumeCapacity = 'maxVolumeCapacity',
  MaxWeightCapacity = 'maxWeightCapacity',
  ProductVolume = 'productVolume',
  ProductWeight = 'productWeight',
  SourceBinBlock = 'sourceBinBlock',
  Status = 'status',
  StorageLocation = 'storageLocation',
  StorageLocationId = 'storageLocationId',
  WarehouseId = 'warehouseId',
  WeightCapacity = 'weightCapacity'
}

export type ViewSlottingRuleset = {
  __typename?: 'ViewSlottingRuleset';
  /** Created by user */
  createdBy?: Maybe<Scalars['String']['output']>;
  /** Created by user */
  createdByUserId?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Timestamp of latest movement */
  lastUpdated?: Maybe<Scalars['DateTime']['output']>;
  /** ApiDocs */
  name?: Maybe<Scalars['String']['output']>;
  /** Person's last name */
  runCount?: Maybe<Scalars['Float']['output']>;
  /** Skip ABC Anlysis */
  skipAbc?: Maybe<Scalars['Boolean']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
};

export type ViewSlottingRulesetFilter = {
  and?: InputMaybe<Array<ViewSlottingRulesetFilter>>;
  createdBy?: InputMaybe<StringFieldComparison>;
  createdByUserId?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lastUpdated?: InputMaybe<DateFieldComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ViewSlottingRulesetFilter>>;
  runCount?: InputMaybe<NumberFieldComparison>;
  skipAbc?: InputMaybe<BooleanFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type ViewSlottingRulesetOffsetConnection = {
  __typename?: 'ViewSlottingRulesetOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewSlottingRuleset>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewSlottingRulesetSort = {
  direction: SortDirection;
  field: ViewSlottingRulesetSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewSlottingRulesetSortFields {
  CreatedBy = 'createdBy',
  CreatedByUserId = 'createdByUserId',
  Id = 'id',
  LastUpdated = 'lastUpdated',
  Name = 'name',
  RunCount = 'runCount',
  SkipAbc = 'skipAbc',
  WarehouseId = 'warehouseId'
}

export type ViewSlottingSetting = {
  __typename?: 'ViewSlottingSetting';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Preferred currency, i.e. USD */
  currency?: Maybe<Scalars['String']['output']>;
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Velocity */
  velocityUOMCode?: Maybe<Scalars['String']['output']>;
  /** Velocity */
  velocityUOMId?: Maybe<Scalars['String']['output']>;
  /** Velocity */
  velocityUOMLabel?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  warehouseCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** ApiDocs */
  warehouseName?: Maybe<Scalars['String']['output']>;
};

export type ViewSlottingSettingFilter = {
  and?: InputMaybe<Array<ViewSlottingSettingFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  currency?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ViewSlottingSettingFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  velocityUOMCode?: InputMaybe<StringFieldComparison>;
  velocityUOMId?: InputMaybe<StringFieldComparison>;
  velocityUOMLabel?: InputMaybe<StringFieldComparison>;
  warehouseCode?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  warehouseName?: InputMaybe<StringFieldComparison>;
};

export type ViewSlottingSettingOffsetConnection = {
  __typename?: 'ViewSlottingSettingOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewSlottingSetting>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewSlottingSettingSort = {
  direction: SortDirection;
  field: ViewSlottingSettingSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewSlottingSettingSortFields {
  CreatedAt = 'createdAt',
  Currency = 'currency',
  DeletedAt = 'deletedAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  VelocityUomCode = 'velocityUOMCode',
  VelocityUomId = 'velocityUOMId',
  VelocityUomLabel = 'velocityUOMLabel',
  WarehouseCode = 'warehouseCode',
  WarehouseId = 'warehouseId',
  WarehouseName = 'warehouseName'
}

export type ViewStockStatus = {
  __typename?: 'ViewStockStatus';
  /** Entity code */
  code?: Maybe<Scalars['String']['output']>;
  /** Stock status used when converting ERP stock status to Fulfilld stock status */
  default?: Maybe<Scalars['Boolean']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Entity's label */
  label?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  sapStockStatusCode?: Maybe<Scalars['String']['output']>;
  /** Entity's SAP stock status type ID (foreign key) */
  sapStockStatusId?: Maybe<Scalars['ID']['output']>;
  /** I.e. Returns, Available, Lot Restricted etc. */
  sapStockStatusLabel?: Maybe<Scalars['String']['output']>;
  /** Determines if stock status type is in use */
  stockStatusTypeStatus?: Maybe<StockStatusTypeStatus>;
};

export type ViewStockStatusFilter = {
  and?: InputMaybe<Array<ViewStockStatusFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  default?: InputMaybe<BooleanFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ViewStockStatusFilter>>;
  sapStockStatusCode?: InputMaybe<StringFieldComparison>;
  sapStockStatusId?: InputMaybe<IdFilterComparison>;
  sapStockStatusLabel?: InputMaybe<StringFieldComparison>;
  stockStatusTypeStatus?: InputMaybe<StockStatusTypeStatusFilterComparison>;
};

export type ViewStockStatusOffsetConnection = {
  __typename?: 'ViewStockStatusOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewStockStatus>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewStockStatusSort = {
  direction: SortDirection;
  field: ViewStockStatusSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewStockStatusSortFields {
  Code = 'code',
  Default = 'default',
  Description = 'description',
  Id = 'id',
  Label = 'label',
  SapStockStatusCode = 'sapStockStatusCode',
  SapStockStatusId = 'sapStockStatusId',
  SapStockStatusLabel = 'sapStockStatusLabel',
  StockStatusTypeStatus = 'stockStatusTypeStatus'
}

export type ViewTask = {
  __typename?: 'ViewTask';
  /** Deleted at date */
  assignedAt?: Maybe<Scalars['DateTime']['output']>;
  assignedByUserId?: Maybe<Scalars['ID']['output']>;
  assignedTeamId?: Maybe<Scalars['ID']['output']>;
  assignedUserId?: Maybe<Scalars['ID']['output']>;
  /** Date and time a task is completed */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** x coordinate location */
  completedAtLocationX?: Maybe<Scalars['Float']['output']>;
  /** Y coordinate location */
  completedAtLocationY?: Maybe<Scalars['Float']['output']>;
  completedByUserId?: Maybe<Scalars['ID']['output']>;
  /** Date the task was completed */
  completionDate?: Maybe<Scalars['DateTime']['output']>;
  /** Created at date */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Delivery or fulfillment erp code */
  deliveryErpCode?: Maybe<Scalars['String']['output']>;
  /** Delivery ID (foreign key) */
  deliveryId?: Maybe<Scalars['ID']['output']>;
  /** Delivery item associated with entity */
  deliveryItem?: Maybe<Scalars['String']['output']>;
  /** Delivery item ID (foreign key) */
  deliveryItemId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  destinationAreaCode?: Maybe<Scalars['String']['output']>;
  /** Entity's area ID (foreign key) */
  destinationAreaId?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  destinationBinCode?: Maybe<Scalars['String']['output']>;
  /** Entity's bin ID (foreign key) */
  destinationBinId?: Maybe<Scalars['String']['output']>;
  /** Destination license plate code */
  destinationLicensePlateCode?: Maybe<Scalars['String']['output']>;
  /** Destination license plate description */
  destinationLicensePlateDescription?: Maybe<Scalars['String']['output']>;
  /** Destination license plate ID */
  destinationLicensePlateId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  destinationLotCode?: Maybe<Scalars['String']['output']>;
  /** Source lot ID (foreign key) */
  destinationLotId?: Maybe<Scalars['String']['output']>;
  /** Stock status type entity belongs to */
  destinationStatus?: Maybe<Scalars['String']['output']>;
  /** Destination stock status type ID (foreign key) */
  destinationStockStatusId?: Maybe<Scalars['ID']['output']>;
  /** Date the task is or was due */
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  /** Delivery or fulfillment erp code */
  fulfillmentErpCode?: Maybe<Scalars['String']['output']>;
  /** Fulfillment item associated with the entity */
  fulfillmentItem?: Maybe<Scalars['String']['output']>;
  /** Fulfillment item id (foreign key) */
  fulfillmentItemId?: Maybe<Scalars['ID']['output']>;
  /** Gross weight of entity */
  grossWeight?: Maybe<Scalars['Float']['output']>;
  /** Boolean for if a record is a task group */
  isTaskGroup?: Maybe<Scalars['Boolean']['output']>;
  /** Net weight of entity */
  netWeight?: Maybe<Scalars['Float']['output']>;
  /** Entity's parent task ID */
  parentTaskId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  productCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  productDescription?: Maybe<Scalars['String']['output']>;
  /** Entity's product ID (foreign key) */
  productId?: Maybe<Scalars['String']['output']>;
  /** Whether the product is lot managed or not */
  productLotManaged?: Maybe<Scalars['Boolean']['output']>;
  /** Quantity of product */
  quantity?: Maybe<Scalars['String']['output']>;
  /** Task that is display only */
  readonly?: Maybe<Scalars['Boolean']['output']>;
  /** Reference document */
  referringDoc?: Maybe<Scalars['String']['output']>;
  /** Reference item */
  referringDocItem?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  sourceAreaCode?: Maybe<Scalars['String']['output']>;
  /** Entity's area ID (foreign key) */
  sourceAreaId?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  sourceBinCode?: Maybe<Scalars['String']['output']>;
  /** Entity's bin ID (foreign key) */
  sourceBinId?: Maybe<Scalars['String']['output']>;
  /** Source License Plate code */
  sourceLicensePlateCode?: Maybe<Scalars['String']['output']>;
  /** Source License Plate description */
  sourceLicensePlateDescription?: Maybe<Scalars['String']['output']>;
  /** Source License Plate Id */
  sourceLicensePlateId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  sourceLotCode?: Maybe<Scalars['String']['output']>;
  /** Source lot ID (foreign key) */
  sourceLotId?: Maybe<Scalars['String']['output']>;
  /** Stock status type entity belongs to */
  sourceStatus?: Maybe<Scalars['String']['output']>;
  /** Source stock status type ID (foreign key) */
  sourceStockStatusId?: Maybe<Scalars['String']['output']>;
  /** Date and time a task is started */
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  /** x coordinate location */
  startedAtLocationX?: Maybe<Scalars['Float']['output']>;
  /** Y coordinate location */
  startedAtLocationY?: Maybe<Scalars['Float']['output']>;
  startedByUserId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  taskCode?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  taskGroupCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  taskGroupId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  taskGroupPosition?: Maybe<Scalars['Float']['output']>;
  /** Task status, i.e Not Started */
  taskGroupStatus?: Maybe<TaskStatus>;
  /** Entity's task ID (foreign key) */
  taskId?: Maybe<Scalars['String']['output']>;
  /** Task status, i.e Not Started */
  taskStatus?: Maybe<TaskStatus>;
  /** Task type */
  taskType?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  taskTypeCode?: Maybe<Scalars['String']['output']>;
  /** Task type reference category */
  taskTypeReferenceCategory?: Maybe<TaskTypeReferenceCategory>;
  /** Description of entity */
  teamDescription?: Maybe<Scalars['String']['output']>;
  /**
   * Entity's team ID (foreign key)
   * @deprecated no longer in use
   */
  teamId?: Maybe<Scalars['ID']['output']>;
  /** Entity's team name */
  teamName?: Maybe<Scalars['String']['output']>;
  /** Unit of measure for entity */
  unitOfMeasure?: Maybe<Scalars['String']['output']>;
  /** Unit of measure ID */
  unitOfMeasureId?: Maybe<Scalars['ID']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Volume of entity */
  volume?: Maybe<Scalars['Float']['output']>;
  /** Entity code */
  warehouseCode?: Maybe<Scalars['String']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** Warehouse entity belongs to */
  warehouseName?: Maybe<Scalars['String']['output']>;
  /** Weight UOM, i.e KG */
  weightUOMId?: Maybe<Scalars['String']['output']>;
};

export type ViewTaskFilter = {
  and?: InputMaybe<Array<ViewTaskFilter>>;
  assignedAt?: InputMaybe<DateFieldComparison>;
  assignedByUserId?: InputMaybe<IdFilterComparison>;
  assignedTeamId?: InputMaybe<IdFilterComparison>;
  assignedUserId?: InputMaybe<IdFilterComparison>;
  completedAt?: InputMaybe<DateFieldComparison>;
  completedAtLocationX?: InputMaybe<FloatFieldComparison>;
  completedAtLocationY?: InputMaybe<FloatFieldComparison>;
  completedByUserId?: InputMaybe<IdFilterComparison>;
  completionDate?: InputMaybe<DateFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deliveryErpCode?: InputMaybe<StringFieldComparison>;
  deliveryId?: InputMaybe<IdFilterComparison>;
  deliveryItem?: InputMaybe<StringFieldComparison>;
  deliveryItemId?: InputMaybe<IdFilterComparison>;
  destinationAreaCode?: InputMaybe<StringFieldComparison>;
  destinationAreaId?: InputMaybe<StringFieldComparison>;
  destinationBinCode?: InputMaybe<StringFieldComparison>;
  destinationBinId?: InputMaybe<StringFieldComparison>;
  destinationLicensePlateCode?: InputMaybe<StringFieldComparison>;
  destinationLicensePlateDescription?: InputMaybe<StringFieldComparison>;
  destinationLicensePlateId?: InputMaybe<IdFilterComparison>;
  destinationLotCode?: InputMaybe<StringFieldComparison>;
  destinationLotId?: InputMaybe<StringFieldComparison>;
  destinationStatus?: InputMaybe<StringFieldComparison>;
  destinationStockStatusId?: InputMaybe<IdFilterComparison>;
  dueDate?: InputMaybe<DateFieldComparison>;
  fulfillmentErpCode?: InputMaybe<StringFieldComparison>;
  fulfillmentItem?: InputMaybe<StringFieldComparison>;
  fulfillmentItemId?: InputMaybe<IdFilterComparison>;
  grossWeight?: InputMaybe<FloatFieldComparison>;
  isTaskGroup?: InputMaybe<BooleanFieldComparison>;
  netWeight?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<ViewTaskFilter>>;
  parentTaskId?: InputMaybe<IdFilterComparison>;
  productCode?: InputMaybe<StringFieldComparison>;
  productDescription?: InputMaybe<StringFieldComparison>;
  productId?: InputMaybe<StringFieldComparison>;
  productLotManaged?: InputMaybe<BooleanFieldComparison>;
  quantity?: InputMaybe<StringFieldComparison>;
  readonly?: InputMaybe<BooleanFieldComparison>;
  referringDoc?: InputMaybe<StringFieldComparison>;
  referringDocItem?: InputMaybe<StringFieldComparison>;
  sourceAreaCode?: InputMaybe<StringFieldComparison>;
  sourceAreaId?: InputMaybe<StringFieldComparison>;
  sourceBinCode?: InputMaybe<StringFieldComparison>;
  sourceBinId?: InputMaybe<StringFieldComparison>;
  sourceLicensePlateCode?: InputMaybe<StringFieldComparison>;
  sourceLicensePlateDescription?: InputMaybe<StringFieldComparison>;
  sourceLicensePlateId?: InputMaybe<IdFilterComparison>;
  sourceLotCode?: InputMaybe<StringFieldComparison>;
  sourceLotId?: InputMaybe<StringFieldComparison>;
  sourceStatus?: InputMaybe<StringFieldComparison>;
  sourceStockStatusId?: InputMaybe<StringFieldComparison>;
  startedAt?: InputMaybe<DateFieldComparison>;
  startedAtLocationX?: InputMaybe<FloatFieldComparison>;
  startedAtLocationY?: InputMaybe<FloatFieldComparison>;
  startedByUserId?: InputMaybe<IdFilterComparison>;
  taskCode?: InputMaybe<StringFieldComparison>;
  taskGroupCode?: InputMaybe<StringFieldComparison>;
  taskGroupId?: InputMaybe<IdFilterComparison>;
  taskGroupPosition?: InputMaybe<FloatFieldComparison>;
  taskGroupStatus?: InputMaybe<TaskStatusFilterComparison>;
  taskId?: InputMaybe<StringFieldComparison>;
  taskStatus?: InputMaybe<TaskStatusFilterComparison>;
  taskType?: InputMaybe<StringFieldComparison>;
  taskTypeCode?: InputMaybe<StringFieldComparison>;
  taskTypeReferenceCategory?: InputMaybe<TaskTypeReferenceCategoryFilterComparison>;
  teamDescription?: InputMaybe<StringFieldComparison>;
  teamId?: InputMaybe<IdFilterComparison>;
  teamName?: InputMaybe<StringFieldComparison>;
  unitOfMeasure?: InputMaybe<StringFieldComparison>;
  unitOfMeasureId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  volume?: InputMaybe<FloatFieldComparison>;
  warehouseCode?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  warehouseName?: InputMaybe<StringFieldComparison>;
  weightUOMId?: InputMaybe<StringFieldComparison>;
};

export type ViewTaskGroup = {
  __typename?: 'ViewTaskGroup';
  areaCodes?: Maybe<Array<SourceDestinationArea>>;
  /** Entity ID */
  assignedTeamId?: Maybe<Scalars['ID']['output']>;
  /** Entity's team name */
  assignedTeamName?: Maybe<Scalars['String']['output']>;
  /** Person's first name */
  assignedUserFirstName?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  assignedUserId?: Maybe<Scalars['ID']['output']>;
  /** Person's last name */
  assignedUserLastName?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  code?: Maybe<Scalars['String']['output']>;
  /** Date and time a task is completed */
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  completedUserId?: Maybe<Scalars['ID']['output']>;
  /** Person's last name */
  completedUserLastName?: Maybe<Scalars['String']['output']>;
  /** Person's first name */
  completedUserserFirstName?: Maybe<Scalars['String']['output']>;
  /** Date and time a task is completed */
  plannedCompletionAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date and time a task is completed */
  plannedStartAt?: Maybe<Scalars['DateTime']['output']>;
  progress: Scalars['Int']['output'];
  /** Date and time a task is completed */
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  taskGroupId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** ApiDocs */
  warehouseName?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  workingTeamId?: Maybe<Scalars['ID']['output']>;
  /** Entity's team name */
  workingTeamName?: Maybe<Scalars['String']['output']>;
};

export type ViewTaskGroupFilter = {
  and?: InputMaybe<Array<ViewTaskGroupFilter>>;
  assignedTeamId?: InputMaybe<IdFilterComparison>;
  assignedTeamName?: InputMaybe<StringFieldComparison>;
  assignedUserFirstName?: InputMaybe<StringFieldComparison>;
  assignedUserId?: InputMaybe<IdFilterComparison>;
  assignedUserLastName?: InputMaybe<StringFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  completedAt?: InputMaybe<DateFieldComparison>;
  completedUserId?: InputMaybe<IdFilterComparison>;
  completedUserLastName?: InputMaybe<StringFieldComparison>;
  completedUserserFirstName?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ViewTaskGroupFilter>>;
  plannedCompletionAt?: InputMaybe<DateFieldComparison>;
  plannedStartAt?: InputMaybe<DateFieldComparison>;
  startedAt?: InputMaybe<DateFieldComparison>;
  taskGroupId?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  warehouseName?: InputMaybe<StringFieldComparison>;
  workingTeamId?: InputMaybe<IdFilterComparison>;
  workingTeamName?: InputMaybe<StringFieldComparison>;
};

export type ViewTaskGroupOffsetConnection = {
  __typename?: 'ViewTaskGroupOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewTaskGroup>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewTaskGroupSort = {
  direction: SortDirection;
  field: ViewTaskGroupSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewTaskGroupSortFields {
  AssignedTeamId = 'assignedTeamId',
  AssignedTeamName = 'assignedTeamName',
  AssignedUserFirstName = 'assignedUserFirstName',
  AssignedUserId = 'assignedUserId',
  AssignedUserLastName = 'assignedUserLastName',
  Code = 'code',
  CompletedAt = 'completedAt',
  CompletedUserId = 'completedUserId',
  CompletedUserLastName = 'completedUserLastName',
  CompletedUserserFirstName = 'completedUserserFirstName',
  PlannedCompletionAt = 'plannedCompletionAt',
  PlannedStartAt = 'plannedStartAt',
  StartedAt = 'startedAt',
  TaskGroupId = 'taskGroupId',
  WarehouseId = 'warehouseId',
  WarehouseName = 'warehouseName',
  WorkingTeamId = 'workingTeamId',
  WorkingTeamName = 'workingTeamName'
}

export type ViewTaskOffsetConnection = {
  __typename?: 'ViewTaskOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewTask>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewTaskSort = {
  direction: SortDirection;
  field: ViewTaskSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewTaskSortFields {
  AssignedAt = 'assignedAt',
  AssignedByUserId = 'assignedByUserId',
  AssignedTeamId = 'assignedTeamId',
  AssignedUserId = 'assignedUserId',
  CompletedAt = 'completedAt',
  CompletedAtLocationX = 'completedAtLocationX',
  CompletedAtLocationY = 'completedAtLocationY',
  CompletedByUserId = 'completedByUserId',
  CompletionDate = 'completionDate',
  CreatedAt = 'createdAt',
  DeliveryErpCode = 'deliveryErpCode',
  DeliveryId = 'deliveryId',
  DeliveryItem = 'deliveryItem',
  DeliveryItemId = 'deliveryItemId',
  DestinationAreaCode = 'destinationAreaCode',
  DestinationAreaId = 'destinationAreaId',
  DestinationBinCode = 'destinationBinCode',
  DestinationBinId = 'destinationBinId',
  DestinationLicensePlateCode = 'destinationLicensePlateCode',
  DestinationLicensePlateDescription = 'destinationLicensePlateDescription',
  DestinationLicensePlateId = 'destinationLicensePlateId',
  DestinationLotCode = 'destinationLotCode',
  DestinationLotId = 'destinationLotId',
  DestinationStatus = 'destinationStatus',
  DestinationStockStatusId = 'destinationStockStatusId',
  DueDate = 'dueDate',
  FulfillmentErpCode = 'fulfillmentErpCode',
  FulfillmentItem = 'fulfillmentItem',
  FulfillmentItemId = 'fulfillmentItemId',
  GrossWeight = 'grossWeight',
  IsTaskGroup = 'isTaskGroup',
  NetWeight = 'netWeight',
  ParentTaskId = 'parentTaskId',
  ProductCode = 'productCode',
  ProductDescription = 'productDescription',
  ProductId = 'productId',
  ProductLotManaged = 'productLotManaged',
  Quantity = 'quantity',
  Readonly = 'readonly',
  ReferringDoc = 'referringDoc',
  ReferringDocItem = 'referringDocItem',
  SourceAreaCode = 'sourceAreaCode',
  SourceAreaId = 'sourceAreaId',
  SourceBinCode = 'sourceBinCode',
  SourceBinId = 'sourceBinId',
  SourceLicensePlateCode = 'sourceLicensePlateCode',
  SourceLicensePlateDescription = 'sourceLicensePlateDescription',
  SourceLicensePlateId = 'sourceLicensePlateId',
  SourceLotCode = 'sourceLotCode',
  SourceLotId = 'sourceLotId',
  SourceStatus = 'sourceStatus',
  SourceStockStatusId = 'sourceStockStatusId',
  StartedAt = 'startedAt',
  StartedAtLocationX = 'startedAtLocationX',
  StartedAtLocationY = 'startedAtLocationY',
  StartedByUserId = 'startedByUserId',
  TaskCode = 'taskCode',
  TaskGroupCode = 'taskGroupCode',
  TaskGroupId = 'taskGroupId',
  TaskGroupPosition = 'taskGroupPosition',
  TaskGroupStatus = 'taskGroupStatus',
  TaskId = 'taskId',
  TaskStatus = 'taskStatus',
  TaskType = 'taskType',
  TaskTypeCode = 'taskTypeCode',
  TaskTypeReferenceCategory = 'taskTypeReferenceCategory',
  TeamDescription = 'teamDescription',
  TeamId = 'teamId',
  TeamName = 'teamName',
  UnitOfMeasure = 'unitOfMeasure',
  UnitOfMeasureId = 'unitOfMeasureId',
  UpdatedAt = 'updatedAt',
  Volume = 'volume',
  WarehouseCode = 'warehouseCode',
  WarehouseId = 'warehouseId',
  WarehouseName = 'warehouseName',
  WeightUomId = 'weightUOMId'
}

export type ViewTaskTypeBinStatusMapping = {
  __typename?: 'ViewTaskTypeBinStatusMapping';
  /** Bin status mappings for the task type */
  binStatusMappings?: Maybe<Array<BinStatusMappingJson>>;
  /** Task type code */
  taskTypeCode?: Maybe<Scalars['String']['output']>;
  /** Task type ID (foreign key) */
  taskTypeId?: Maybe<Scalars['ID']['output']>;
  /** Task type label */
  taskTypeLabel?: Maybe<Scalars['String']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
};

export type ViewTaskTypeBinStatusMappingFilter = {
  and?: InputMaybe<Array<ViewTaskTypeBinStatusMappingFilter>>;
  or?: InputMaybe<Array<ViewTaskTypeBinStatusMappingFilter>>;
  taskTypeCode?: InputMaybe<StringFieldComparison>;
  taskTypeId?: InputMaybe<IdFilterComparison>;
  taskTypeLabel?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type ViewTaskTypeBinStatusMappingOffsetConnection = {
  __typename?: 'ViewTaskTypeBinStatusMappingOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewTaskTypeBinStatusMapping>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewTaskTypeBinStatusMappingSort = {
  direction: SortDirection;
  field: ViewTaskTypeBinStatusMappingSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewTaskTypeBinStatusMappingSortFields {
  TaskTypeCode = 'taskTypeCode',
  TaskTypeId = 'taskTypeId',
  TaskTypeLabel = 'taskTypeLabel',
  WarehouseId = 'warehouseId'
}

export type ViewTaskTypeStockStatusMapping = {
  __typename?: 'ViewTaskTypeStockStatusMapping';
  /** Stock status mappings for the task type */
  stockStatusMappings?: Maybe<Array<StockStatusMapping>>;
  /** Task type code */
  taskTypeCode?: Maybe<Scalars['String']['output']>;
  /** Task type ID (foreign key) */
  taskTypeId?: Maybe<Scalars['ID']['output']>;
  /** Task type label */
  taskTypeLabel?: Maybe<Scalars['String']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
};

export type ViewTaskTypeStockStatusMappingFilter = {
  and?: InputMaybe<Array<ViewTaskTypeStockStatusMappingFilter>>;
  or?: InputMaybe<Array<ViewTaskTypeStockStatusMappingFilter>>;
  taskTypeCode?: InputMaybe<StringFieldComparison>;
  taskTypeId?: InputMaybe<IdFilterComparison>;
  taskTypeLabel?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type ViewTaskTypeStockStatusMappingOffsetConnection = {
  __typename?: 'ViewTaskTypeStockStatusMappingOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewTaskTypeStockStatusMapping>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewTaskTypeStockStatusMappingSort = {
  direction: SortDirection;
  field: ViewTaskTypeStockStatusMappingSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewTaskTypeStockStatusMappingSortFields {
  TaskTypeCode = 'taskTypeCode',
  TaskTypeId = 'taskTypeId',
  TaskTypeLabel = 'taskTypeLabel',
  WarehouseId = 'warehouseId'
}

export type ViewUnitOfMeasureProductConversion = {
  __typename?: 'ViewUnitOfMeasureProductConversion';
  /** Entity code */
  code?: Maybe<Scalars['String']['output']>;
  /** Conversion factor to convert units of measure -> Multiply against base UOM */
  conversionFactor?: Maybe<Scalars['Float']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Unit of measure for product dimensions */
  dimensionUOMCode?: Maybe<Scalars['String']['output']>;
  /** Unit of measure for product dimensions */
  dimensionUOMId?: Maybe<Scalars['ID']['output']>;
  /** Unit of measure for product dimensions */
  dimensionUOMLabel?: Maybe<Scalars['String']['output']>;
  /** Gross weight of entity */
  grossWeight?: Maybe<Scalars['Float']['output']>;
  /** Product's Height */
  height?: Maybe<Scalars['Float']['output']>;
  /** Entity ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** Boolean for base unit of measure if true UOM is base */
  isBaseUom?: Maybe<Scalars['Boolean']['output']>;
  /** Entity's label */
  label?: Maybe<Scalars['String']['output']>;
  /** Product's length */
  length?: Maybe<Scalars['Float']['output']>;
  /** Entity's warehouse (foreign key) */
  productId?: Maybe<Scalars['ID']['output']>;
  /** Volume of entity */
  volume?: Maybe<Scalars['Float']['output']>;
  /** Volume in unit of measure for entity */
  volumeUOMCode?: Maybe<Scalars['String']['output']>;
  /** Volume in unit of measure for entity */
  volumeUOMId?: Maybe<Scalars['ID']['output']>;
  /** Volume in unit of measure for entity */
  volumeUOMLabel?: Maybe<Scalars['String']['output']>;
  /** Weight UOM, i.e KG */
  weightUOMCode?: Maybe<Scalars['String']['output']>;
  /** Weight UOM, i.e KG */
  weightUOMId?: Maybe<Scalars['ID']['output']>;
  /** Weight UOM, i.e KG */
  weightUOMLabel?: Maybe<Scalars['String']['output']>;
  /** Product's width */
  width?: Maybe<Scalars['Float']['output']>;
};

export type ViewUnitOfMeasureProductConversionFilter = {
  and?: InputMaybe<Array<ViewUnitOfMeasureProductConversionFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  conversionFactor?: InputMaybe<FloatFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  dimensionUOMCode?: InputMaybe<StringFieldComparison>;
  dimensionUOMId?: InputMaybe<IdFilterComparison>;
  dimensionUOMLabel?: InputMaybe<StringFieldComparison>;
  grossWeight?: InputMaybe<FloatFieldComparison>;
  height?: InputMaybe<FloatFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  isBaseUom?: InputMaybe<BooleanFieldComparison>;
  label?: InputMaybe<StringFieldComparison>;
  length?: InputMaybe<FloatFieldComparison>;
  or?: InputMaybe<Array<ViewUnitOfMeasureProductConversionFilter>>;
  productId?: InputMaybe<IdFilterComparison>;
  volume?: InputMaybe<FloatFieldComparison>;
  volumeUOMCode?: InputMaybe<StringFieldComparison>;
  volumeUOMId?: InputMaybe<IdFilterComparison>;
  volumeUOMLabel?: InputMaybe<StringFieldComparison>;
  weightUOMCode?: InputMaybe<StringFieldComparison>;
  weightUOMId?: InputMaybe<IdFilterComparison>;
  weightUOMLabel?: InputMaybe<StringFieldComparison>;
  width?: InputMaybe<FloatFieldComparison>;
};

export type ViewUnitOfMeasureProductConversionOffsetConnection = {
  __typename?: 'ViewUnitOfMeasureProductConversionOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewUnitOfMeasureProductConversion>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewUnitOfMeasureProductConversionSort = {
  direction: SortDirection;
  field: ViewUnitOfMeasureProductConversionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewUnitOfMeasureProductConversionSortFields {
  Code = 'code',
  ConversionFactor = 'conversionFactor',
  Description = 'description',
  DimensionUomCode = 'dimensionUOMCode',
  DimensionUomId = 'dimensionUOMId',
  DimensionUomLabel = 'dimensionUOMLabel',
  GrossWeight = 'grossWeight',
  Height = 'height',
  Id = 'id',
  IsBaseUom = 'isBaseUom',
  Label = 'label',
  Length = 'length',
  ProductId = 'productId',
  Volume = 'volume',
  VolumeUomCode = 'volumeUOMCode',
  VolumeUomId = 'volumeUOMId',
  VolumeUomLabel = 'volumeUOMLabel',
  WeightUomCode = 'weightUOMCode',
  WeightUomId = 'weightUOMId',
  WeightUomLabel = 'weightUOMLabel',
  Width = 'width'
}

export type ViewUser = {
  __typename?: 'ViewUser';
  /** Email address */
  email?: Maybe<Scalars['String']['output']>;
  /** Person's first name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Person's last name */
  lastName?: Maybe<Scalars['ID']['output']>;
  /** Phone number */
  phone?: Maybe<Scalars['ID']['output']>;
  /** User's account status */
  status?: Maybe<Scalars['String']['output']>;
  userGroups?: Maybe<Array<ViewUserUserGroups>>;
  /** Entity ID */
  userId?: Maybe<Scalars['ID']['output']>;
};

export type ViewUserFilter = {
  and?: InputMaybe<Array<ViewUserFilter>>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  lastName?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ViewUserFilter>>;
  phone?: InputMaybe<IdFilterComparison>;
  status?: InputMaybe<StringFieldComparison>;
  userId?: InputMaybe<IdFilterComparison>;
};

export type ViewUserGroup = {
  __typename?: 'ViewUserGroup';
  /** Determines if a roles is default */
  roleDefault?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  roleId?: Maybe<Scalars['ID']['output']>;
  /** ApiDocs */
  roleName?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  userGroupId?: Maybe<Scalars['ID']['output']>;
  /** ApiDocs */
  userGroupName?: Maybe<Scalars['String']['output']>;
  /** Entity code */
  warehouseCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** ApiDocs */
  warehouseName?: Maybe<Scalars['String']['output']>;
};

export type ViewUserGroupFilter = {
  and?: InputMaybe<Array<ViewUserGroupFilter>>;
  or?: InputMaybe<Array<ViewUserGroupFilter>>;
  roleDefault?: InputMaybe<StringFieldComparison>;
  roleId?: InputMaybe<IdFilterComparison>;
  roleName?: InputMaybe<StringFieldComparison>;
  userGroupId?: InputMaybe<IdFilterComparison>;
  userGroupName?: InputMaybe<StringFieldComparison>;
  warehouseCode?: InputMaybe<StringFieldComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  warehouseName?: InputMaybe<StringFieldComparison>;
};

export type ViewUserGroupOffsetConnection = {
  __typename?: 'ViewUserGroupOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewUserGroup>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewUserGroupSort = {
  direction: SortDirection;
  field: ViewUserGroupSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewUserGroupSortFields {
  RoleDefault = 'roleDefault',
  RoleId = 'roleId',
  RoleName = 'roleName',
  UserGroupId = 'userGroupId',
  UserGroupName = 'userGroupName',
  WarehouseCode = 'warehouseCode',
  WarehouseId = 'warehouseId',
  WarehouseName = 'warehouseName'
}

export type ViewUserOffsetConnection = {
  __typename?: 'ViewUserOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewUser>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewUserSort = {
  direction: SortDirection;
  field: ViewUserSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewUserSortFields {
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  Phone = 'phone',
  Status = 'status',
  UserId = 'userId'
}

export type ViewUserUserGroups = {
  __typename?: 'ViewUserUserGroups';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ViewZone = {
  __typename?: 'ViewZone';
  /** Number of aisle columns a zone contains */
  aisleColumnCount?: Maybe<Scalars['Int']['output']>;
  /** Number of aisles a zone contains */
  aisleCount?: Maybe<Scalars['Int']['output']>;
  /** Number of areas a zone contains */
  areaCount?: Maybe<Scalars['Int']['output']>;
  /** Number of bins a zone contains */
  binCount?: Maybe<Scalars['Int']['output']>;
  /** Entity code */
  code?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id?: Maybe<Scalars['ID']['output']>;
  /** ApiDocs */
  name?: Maybe<Scalars['String']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
};

export type ViewZoneAisle = {
  __typename?: 'ViewZoneAisle';
  /** Entity code */
  aisleCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  aisleDescription?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  aisleId?: Maybe<Scalars['ID']['output']>;
  /** ApiDocs */
  aisleName?: Maybe<Scalars['String']['output']>;
  /** The zone and entity mapping exists */
  mapped?: Maybe<Scalars['Boolean']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  zoneCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  zoneDescription?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  zoneId?: Maybe<Scalars['ID']['output']>;
  /** ApiDocs */
  zoneName?: Maybe<Scalars['String']['output']>;
};

export type ViewZoneAisleColumn = {
  __typename?: 'ViewZoneAisleColumn';
  /** Entity code */
  aisleColumnCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  aisleColumnDescription?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  aisleColumnId?: Maybe<Scalars['ID']['output']>;
  /** ApiDocs */
  aisleColumnName?: Maybe<Scalars['String']['output']>;
  /** The zone and entity mapping exists */
  mapped?: Maybe<Scalars['Boolean']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  zoneCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  zoneDescription?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  zoneId?: Maybe<Scalars['ID']['output']>;
  /** ApiDocs */
  zoneName?: Maybe<Scalars['String']['output']>;
};

export type ViewZoneAisleColumnFilter = {
  aisleColumnCode?: InputMaybe<StringFieldComparison>;
  aisleColumnDescription?: InputMaybe<StringFieldComparison>;
  aisleColumnId?: InputMaybe<IdFilterComparison>;
  aisleColumnName?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ViewZoneAisleColumnFilter>>;
  mapped?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<ViewZoneAisleColumnFilter>>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  zoneCode?: InputMaybe<StringFieldComparison>;
  zoneDescription?: InputMaybe<StringFieldComparison>;
  zoneId?: InputMaybe<IdFilterComparison>;
  zoneName?: InputMaybe<StringFieldComparison>;
};

export type ViewZoneAisleColumnOffsetConnection = {
  __typename?: 'ViewZoneAisleColumnOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewZoneAisleColumn>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewZoneAisleColumnSort = {
  direction: SortDirection;
  field: ViewZoneAisleColumnSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewZoneAisleColumnSortFields {
  AisleColumnCode = 'aisleColumnCode',
  AisleColumnDescription = 'aisleColumnDescription',
  AisleColumnId = 'aisleColumnId',
  AisleColumnName = 'aisleColumnName',
  Mapped = 'mapped',
  WarehouseId = 'warehouseId',
  ZoneCode = 'zoneCode',
  ZoneDescription = 'zoneDescription',
  ZoneId = 'zoneId',
  ZoneName = 'zoneName'
}

export type ViewZoneAisleFilter = {
  aisleCode?: InputMaybe<StringFieldComparison>;
  aisleDescription?: InputMaybe<StringFieldComparison>;
  aisleId?: InputMaybe<IdFilterComparison>;
  aisleName?: InputMaybe<StringFieldComparison>;
  and?: InputMaybe<Array<ViewZoneAisleFilter>>;
  mapped?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<ViewZoneAisleFilter>>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  zoneCode?: InputMaybe<StringFieldComparison>;
  zoneDescription?: InputMaybe<StringFieldComparison>;
  zoneId?: InputMaybe<IdFilterComparison>;
  zoneName?: InputMaybe<StringFieldComparison>;
};

export type ViewZoneAisleOffsetConnection = {
  __typename?: 'ViewZoneAisleOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewZoneAisle>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewZoneAisleSort = {
  direction: SortDirection;
  field: ViewZoneAisleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewZoneAisleSortFields {
  AisleCode = 'aisleCode',
  AisleDescription = 'aisleDescription',
  AisleId = 'aisleId',
  AisleName = 'aisleName',
  Mapped = 'mapped',
  WarehouseId = 'warehouseId',
  ZoneCode = 'zoneCode',
  ZoneDescription = 'zoneDescription',
  ZoneId = 'zoneId',
  ZoneName = 'zoneName'
}

export type ViewZoneArea = {
  __typename?: 'ViewZoneArea';
  /** Entity code */
  areaCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  areaId?: Maybe<Scalars['ID']['output']>;
  /** Area's name */
  areaName?: Maybe<Scalars['String']['output']>;
  /** The zone and entity mapping exists */
  mapped?: Maybe<Scalars['Boolean']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** Entity code */
  zoneCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  zoneDescription?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  zoneId?: Maybe<Scalars['ID']['output']>;
  /** ApiDocs */
  zoneName?: Maybe<Scalars['String']['output']>;
};

export type ViewZoneAreaFilter = {
  and?: InputMaybe<Array<ViewZoneAreaFilter>>;
  areaCode?: InputMaybe<StringFieldComparison>;
  areaId?: InputMaybe<IdFilterComparison>;
  areaName?: InputMaybe<StringFieldComparison>;
  mapped?: InputMaybe<BooleanFieldComparison>;
  or?: InputMaybe<Array<ViewZoneAreaFilter>>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  zoneCode?: InputMaybe<StringFieldComparison>;
  zoneDescription?: InputMaybe<StringFieldComparison>;
  zoneId?: InputMaybe<IdFilterComparison>;
  zoneName?: InputMaybe<StringFieldComparison>;
};

export type ViewZoneAreaOffsetConnection = {
  __typename?: 'ViewZoneAreaOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewZoneArea>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewZoneAreaSort = {
  direction: SortDirection;
  field: ViewZoneAreaSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewZoneAreaSortFields {
  AreaCode = 'areaCode',
  AreaId = 'areaId',
  AreaName = 'areaName',
  Mapped = 'mapped',
  WarehouseId = 'warehouseId',
  ZoneCode = 'zoneCode',
  ZoneDescription = 'zoneDescription',
  ZoneId = 'zoneId',
  ZoneName = 'zoneName'
}

export type ViewZoneBinMapped = {
  __typename?: 'ViewZoneBinMapped';
  /** Entity code */
  binCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  binId?: Maybe<Scalars['ID']['output']>;
  /** Bin type */
  binType?: Maybe<BinType>;
  /** Destination bin block flag */
  destinationBinBlock?: Maybe<BinBlockState>;
  /** Active/Inactive bin status flag */
  inactive?: Maybe<BinActiveState>;
  /** Level where the bin is located. */
  level?: Maybe<Scalars['Int']['output']>;
  /** Source bin block flag */
  sourceBinBlock?: Maybe<BinBlockState>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** x coordinate location */
  x?: Maybe<Scalars['Float']['output']>;
  /** Y coordinate location */
  y?: Maybe<Scalars['Float']['output']>;
  /** Z coordinate location */
  z?: Maybe<Scalars['Float']['output']>;
  /** Entity code */
  zoneCode?: Maybe<Scalars['String']['output']>;
  /** Description of entity */
  zoneDescription?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  zoneId?: Maybe<Scalars['ID']['output']>;
  /** ApiDocs */
  zoneName?: Maybe<Scalars['String']['output']>;
};

export type ViewZoneBinMappedFilter = {
  and?: InputMaybe<Array<ViewZoneBinMappedFilter>>;
  binCode?: InputMaybe<StringFieldComparison>;
  binId?: InputMaybe<IdFilterComparison>;
  binType?: InputMaybe<BinTypeFilterComparison>;
  destinationBinBlock?: InputMaybe<BinBlockStateFilterComparison>;
  inactive?: InputMaybe<BinActiveStateFilterComparison>;
  level?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<ViewZoneBinMappedFilter>>;
  sourceBinBlock?: InputMaybe<BinBlockStateFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  x?: InputMaybe<FloatFieldComparison>;
  y?: InputMaybe<FloatFieldComparison>;
  z?: InputMaybe<FloatFieldComparison>;
  zoneCode?: InputMaybe<StringFieldComparison>;
  zoneDescription?: InputMaybe<StringFieldComparison>;
  zoneId?: InputMaybe<IdFilterComparison>;
  zoneName?: InputMaybe<StringFieldComparison>;
};

export type ViewZoneBinMappedOffsetConnection = {
  __typename?: 'ViewZoneBinMappedOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewZoneBinMapped>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewZoneBinMappedSort = {
  direction: SortDirection;
  field: ViewZoneBinMappedSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewZoneBinMappedSortFields {
  BinCode = 'binCode',
  BinId = 'binId',
  BinType = 'binType',
  DestinationBinBlock = 'destinationBinBlock',
  Inactive = 'inactive',
  Level = 'level',
  SourceBinBlock = 'sourceBinBlock',
  WarehouseId = 'warehouseId',
  X = 'x',
  Y = 'y',
  Z = 'z',
  ZoneCode = 'zoneCode',
  ZoneDescription = 'zoneDescription',
  ZoneId = 'zoneId',
  ZoneName = 'zoneName'
}

export type ViewZoneBinUnmapped = {
  __typename?: 'ViewZoneBinUnmapped';
  /** Entity code */
  binCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  binId?: Maybe<Scalars['ID']['output']>;
  /** Bin type */
  binType?: Maybe<BinType>;
  /** Destination bin block flag */
  destinationBinBlock?: Maybe<BinBlockState>;
  /** Active/Inactive bin status flag */
  inactive?: Maybe<BinActiveState>;
  /** Level where the bin is located. */
  level?: Maybe<Scalars['Int']['output']>;
  /** Source bin block flag */
  sourceBinBlock?: Maybe<BinBlockState>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
  /** x coordinate location */
  x?: Maybe<Scalars['Float']['output']>;
  /** Y coordinate location */
  y?: Maybe<Scalars['Float']['output']>;
  /** Z coordinate location */
  z?: Maybe<Scalars['Float']['output']>;
};

export type ViewZoneBinUnmappedFilter = {
  and?: InputMaybe<Array<ViewZoneBinUnmappedFilter>>;
  binCode?: InputMaybe<StringFieldComparison>;
  binId?: InputMaybe<IdFilterComparison>;
  binType?: InputMaybe<BinTypeFilterComparison>;
  destinationBinBlock?: InputMaybe<BinBlockStateFilterComparison>;
  inactive?: InputMaybe<BinActiveStateFilterComparison>;
  level?: InputMaybe<IntFieldComparison>;
  or?: InputMaybe<Array<ViewZoneBinUnmappedFilter>>;
  sourceBinBlock?: InputMaybe<BinBlockStateFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
  x?: InputMaybe<FloatFieldComparison>;
  y?: InputMaybe<FloatFieldComparison>;
  z?: InputMaybe<FloatFieldComparison>;
};

export type ViewZoneBinUnmappedOffsetConnection = {
  __typename?: 'ViewZoneBinUnmappedOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewZoneBinUnmapped>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewZoneBinUnmappedSort = {
  direction: SortDirection;
  field: ViewZoneBinUnmappedSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewZoneBinUnmappedSortFields {
  BinCode = 'binCode',
  BinId = 'binId',
  BinType = 'binType',
  DestinationBinBlock = 'destinationBinBlock',
  Inactive = 'inactive',
  Level = 'level',
  SourceBinBlock = 'sourceBinBlock',
  WarehouseId = 'warehouseId',
  X = 'x',
  Y = 'y',
  Z = 'z'
}

export type ViewZoneFilter = {
  aisleColumnCount?: InputMaybe<IntFieldComparison>;
  aisleCount?: InputMaybe<IntFieldComparison>;
  and?: InputMaybe<Array<ViewZoneFilter>>;
  areaCount?: InputMaybe<IntFieldComparison>;
  binCount?: InputMaybe<IntFieldComparison>;
  code?: InputMaybe<StringFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ViewZoneFilter>>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type ViewZoneOffsetConnection = {
  __typename?: 'ViewZoneOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ViewZone>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ViewZoneSort = {
  direction: SortDirection;
  field: ViewZoneSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ViewZoneSortFields {
  AisleColumnCount = 'aisleColumnCount',
  AisleCount = 'aisleCount',
  AreaCount = 'areaCount',
  BinCount = 'binCount',
  Code = 'code',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  WarehouseId = 'warehouseId'
}

export type WhAsnDeliveryConfiguration = {
  __typename?: 'WHAsnDeliveryConfiguration';
  createPutawayTasks: Scalars['Boolean']['output'];
  createUnloadTasks: Scalars['Boolean']['output'];
  enabled: Scalars['Boolean']['output'];
  fields: WhAsnDeliveryFieldsConfiguration;
};

export type WhAsnDeliveryConfigurationInput = {
  createPutawayTasks: Scalars['Boolean']['input'];
  createUnloadTasks: Scalars['Boolean']['input'];
  enabled: Scalars['Boolean']['input'];
  fields: WhAsnDeliveryFieldsConfigurationInput;
};

export type WhAsnDeliveryFieldConfiguration = {
  __typename?: 'WHAsnDeliveryFieldConfiguration';
  mappingName?: Maybe<Scalars['String']['output']>;
  required: Scalars['Boolean']['output'];
};

export type WhAsnDeliveryFieldConfigurationInput = {
  mappingName?: InputMaybe<Scalars['String']['input']>;
  required: Scalars['Boolean']['input'];
};

export type WhAsnDeliveryFieldsConfiguration = {
  __typename?: 'WHAsnDeliveryFieldsConfiguration';
  billOfLading: WhAsnDeliveryFieldConfiguration;
  blockStatus: WhAsnDeliveryFieldConfiguration;
  category: WhAsnDeliveryFieldConfiguration;
  deliveryStatus: WhAsnDeliveryFieldConfiguration;
  doorCode: WhAsnDeliveryFieldConfiguration;
  dueDate: WhAsnDeliveryFieldConfiguration;
  erpBlockingReason: WhAsnDeliveryFieldConfiguration;
  erpCreated: WhAsnDeliveryFieldConfiguration;
  erpLastChanged: WhAsnDeliveryFieldConfiguration;
  erpPurchaseOrder: WhAsnDeliveryFieldConfiguration;
  erpSalesOrder: WhAsnDeliveryFieldConfiguration;
  export: WhAsnDeliveryFieldConfiguration;
  fulfillmentBlockCode: WhAsnDeliveryFieldConfiguration;
  goodsReceiptOrIssueStatus: WhAsnDeliveryFieldConfiguration;
  loadOrUnloadStatus: WhAsnDeliveryFieldConfiguration;
  pickOrPutawayStatus: WhAsnDeliveryFieldConfiguration;
  pointOfContact: WhAsnDeliveryFieldConfiguration;
  promiseDate: WhAsnDeliveryFieldConfiguration;
  shipReadyDate: WhAsnDeliveryFieldConfiguration;
  shipTo: WhAsnDeliveryFieldConfiguration;
  shipToName: WhAsnDeliveryFieldConfiguration;
  soldTo: WhAsnDeliveryFieldConfiguration;
  soldToName: WhAsnDeliveryFieldConfiguration;
  supplier: WhAsnDeliveryFieldConfiguration;
  supplierBusinessPartnerCode: WhAsnDeliveryFieldConfiguration;
  supplierName: WhAsnDeliveryFieldConfiguration;
  totalGrossWeight: WhAsnDeliveryFieldConfiguration;
  totalNetWeight: WhAsnDeliveryFieldConfiguration;
  totalVolume: WhAsnDeliveryFieldConfiguration;
  totalVolumeUom: WhAsnDeliveryFieldConfiguration;
  totalWeightUom: WhAsnDeliveryFieldConfiguration;
};

export type WhAsnDeliveryFieldsConfigurationInput = {
  billOfLading: WhAsnDeliveryFieldConfigurationInput;
  blockStatus: WhAsnDeliveryFieldConfigurationInput;
  category: WhAsnDeliveryFieldConfigurationInput;
  deliveryStatus: WhAsnDeliveryFieldConfigurationInput;
  doorCode: WhAsnDeliveryFieldConfigurationInput;
  dueDate: WhAsnDeliveryFieldConfigurationInput;
  erpBlockingReason: WhAsnDeliveryFieldConfigurationInput;
  erpCreated: WhAsnDeliveryFieldConfigurationInput;
  erpLastChanged: WhAsnDeliveryFieldConfigurationInput;
  erpPurchaseOrder: WhAsnDeliveryFieldConfigurationInput;
  erpSalesOrder: WhAsnDeliveryFieldConfigurationInput;
  export: WhAsnDeliveryFieldConfigurationInput;
  fulfillmentBlockCode: WhAsnDeliveryFieldConfigurationInput;
  goodsReceiptOrIssueStatus: WhAsnDeliveryFieldConfigurationInput;
  loadOrUnloadStatus: WhAsnDeliveryFieldConfigurationInput;
  pickOrPutawayStatus: WhAsnDeliveryFieldConfigurationInput;
  pointOfContact: WhAsnDeliveryFieldConfigurationInput;
  promiseDate: WhAsnDeliveryFieldConfigurationInput;
  shipReadyDate: WhAsnDeliveryFieldConfigurationInput;
  shipTo: WhAsnDeliveryFieldConfigurationInput;
  shipToName: WhAsnDeliveryFieldConfigurationInput;
  soldTo: WhAsnDeliveryFieldConfigurationInput;
  soldToName: WhAsnDeliveryFieldConfigurationInput;
  supplier: WhAsnDeliveryFieldConfigurationInput;
  supplierBusinessPartnerCode: WhAsnDeliveryFieldConfigurationInput;
  supplierName: WhAsnDeliveryFieldConfigurationInput;
  totalGrossWeight: WhAsnDeliveryFieldConfigurationInput;
  totalNetWeight: WhAsnDeliveryFieldConfigurationInput;
  totalVolume: WhAsnDeliveryFieldConfigurationInput;
  totalVolumeUom: WhAsnDeliveryFieldConfigurationInput;
  totalWeightUom: WhAsnDeliveryFieldConfigurationInput;
};

export type WhAsnDeliveryItemConfiguration = {
  __typename?: 'WHAsnDeliveryItemConfiguration';
  enabled: Scalars['Boolean']['output'];
  fields: WhAsnDeliveryItemFieldsConfiguration;
  plannedStockBinCode: Scalars['String']['output'];
};

export type WhAsnDeliveryItemConfigurationInput = {
  enabled: Scalars['Boolean']['input'];
  fields: WhAsnDeliveryItemFieldsConfigurationInput;
  plannedStockBinCode: Scalars['String']['input'];
};

export type WhAsnDeliveryItemFieldConfiguration = {
  __typename?: 'WHAsnDeliveryItemFieldConfiguration';
  create: Scalars['Boolean']['output'];
  mappingName?: Maybe<Scalars['String']['output']>;
  required: Scalars['Boolean']['output'];
};

export type WhAsnDeliveryItemFieldConfigurationInput = {
  create: Scalars['Boolean']['input'];
  mappingName?: InputMaybe<Scalars['String']['input']>;
  required: Scalars['Boolean']['input'];
};

export type WhAsnDeliveryItemFieldsConfiguration = {
  __typename?: 'WHAsnDeliveryItemFieldsConfiguration';
  actualRecievedQuantity: WhAsnDeliveryItemFieldConfiguration;
  deliveryItem: WhAsnDeliveryItemFieldConfiguration;
  description: WhAsnDeliveryItemFieldConfiguration;
  erpCreated: WhAsnDeliveryItemFieldConfiguration;
  erpLastChanged: WhAsnDeliveryItemFieldConfiguration;
  erpPurchaseOrder: WhAsnDeliveryItemFieldConfiguration;
  erpPurchaseOrderItem: WhAsnDeliveryItemFieldConfiguration;
  grossWeight: WhAsnDeliveryItemFieldConfiguration;
  isBaseUoM: WhAsnDeliveryItemFieldConfiguration;
  licensePlateCode: WhAsnDeliveryItemFieldConfiguration;
  lotCode: WhAsnDeliveryItemFieldConfiguration;
  netWeight: WhAsnDeliveryItemFieldConfiguration;
  productCode: WhAsnDeliveryItemFieldConfiguration;
  putawayStatus: WhAsnDeliveryItemFieldConfiguration;
  receiptStatus: WhAsnDeliveryItemFieldConfiguration;
  stockStatusTypeCode: WhAsnDeliveryItemFieldConfiguration;
  supplierLot: WhAsnDeliveryItemFieldConfiguration;
  unloadStatus: WhAsnDeliveryItemFieldConfiguration;
  uom: WhAsnDeliveryItemFieldConfiguration;
  uomConversionFactor: WhAsnDeliveryItemFieldConfiguration;
  volume: WhAsnDeliveryItemFieldConfiguration;
  volumeUom: WhAsnDeliveryItemFieldConfiguration;
  weightUom: WhAsnDeliveryItemFieldConfiguration;
};

export type WhAsnDeliveryItemFieldsConfigurationInput = {
  actualRecievedQuantity: WhAsnDeliveryItemFieldConfigurationInput;
  deliveryItem: WhAsnDeliveryItemFieldConfigurationInput;
  description: WhAsnDeliveryItemFieldConfigurationInput;
  erpCreated: WhAsnDeliveryItemFieldConfigurationInput;
  erpLastChanged: WhAsnDeliveryItemFieldConfigurationInput;
  erpPurchaseOrder: WhAsnDeliveryItemFieldConfigurationInput;
  erpPurchaseOrderItem: WhAsnDeliveryItemFieldConfigurationInput;
  grossWeight: WhAsnDeliveryItemFieldConfigurationInput;
  isBaseUoM: WhAsnDeliveryItemFieldConfigurationInput;
  licensePlateCode: WhAsnDeliveryItemFieldConfigurationInput;
  lotCode: WhAsnDeliveryItemFieldConfigurationInput;
  netWeight: WhAsnDeliveryItemFieldConfigurationInput;
  productCode: WhAsnDeliveryItemFieldConfigurationInput;
  putawayStatus: WhAsnDeliveryItemFieldConfigurationInput;
  receiptStatus: WhAsnDeliveryItemFieldConfigurationInput;
  stockStatusTypeCode: WhAsnDeliveryItemFieldConfigurationInput;
  supplierLot: WhAsnDeliveryItemFieldConfigurationInput;
  unloadStatus: WhAsnDeliveryItemFieldConfigurationInput;
  uom: WhAsnDeliveryItemFieldConfigurationInput;
  uomConversionFactor: WhAsnDeliveryItemFieldConfigurationInput;
  volume: WhAsnDeliveryItemFieldConfigurationInput;
  volumeUom: WhAsnDeliveryItemFieldConfigurationInput;
  weightUom: WhAsnDeliveryItemFieldConfigurationInput;
};

export type WhBusinessPartnerConfiguration = {
  __typename?: 'WHBusinessPartnerConfiguration';
  master: WhMasterBusinessPartnerConfiguration;
};

export type WhBusinessPartnerConfigurationInput = {
  master: WhMasterBusinessPartnerConfigurationInput;
};

export type WhConfiguration = {
  __typename?: 'WHConfiguration';
  businessPartner?: Maybe<WhBusinessPartnerConfiguration>;
  delivery?: Maybe<WhDeliveryConfiguration>;
  deliveryItem?: Maybe<WhDeliveryItemConfiguration>;
  erp?: Maybe<ErpOptions>;
  fulfillment?: Maybe<WhFulfillmentConfiguration>;
  fulfillmentItem?: Maybe<WhFulfillmentItemConfiguration>;
  hooks?: Maybe<WhDeliveryHookConfig>;
  product?: Maybe<WhProductConfiguration>;
  task?: Maybe<WhTaskConfiguration>;
  unitOfMeasure?: Maybe<WhUnitOfMeasureConfiguration>;
};

export type WhConfigurationInput = {
  businessPartner?: InputMaybe<WhBusinessPartnerConfigurationInput>;
  delivery?: InputMaybe<WhDeliveryConfigurationInput>;
  deliveryItem?: InputMaybe<WhDeliveryItemConfigurationInput>;
  erp?: InputMaybe<ErpOptions>;
  fulfillment?: InputMaybe<WhFulfillmentConfigurationInput>;
  fulfillmentItem?: InputMaybe<WhFulfillmentItemConfigurationInput>;
  hooks?: InputMaybe<WhDeliveryHookConfigInput>;
  product?: InputMaybe<WhProductConfigurationInput>;
  task?: InputMaybe<WhTaskConfigurationInput>;
  unitOfMeasure?: InputMaybe<WhUnitOfMeasureConfigurationInput>;
};

export type WhDeliveryConfiguration = {
  __typename?: 'WHDeliveryConfiguration';
  asn: WhAsnDeliveryConfiguration;
  etaDateOffset?: Maybe<Scalars['Int']['output']>;
};

export type WhDeliveryConfigurationInput = {
  asn: WhAsnDeliveryConfigurationInput;
  etaDateOffset?: InputMaybe<Scalars['Int']['input']>;
};

export type WhDeliveryHookConfig = {
  __typename?: 'WHDeliveryHookConfig';
  delivery_pgi?: Maybe<WhHookConfigOptions>;
  task_binToBin?: Maybe<WhHookConfigOptions>;
  task_pick?: Maybe<WhHookConfigOptions>;
  task_putaway?: Maybe<WhHookConfigOptions>;
};

export type WhDeliveryHookConfigInput = {
  delivery_pgi?: InputMaybe<WhHookConfigOptionsInput>;
  task_binToBin?: InputMaybe<WhHookConfigOptionsInput>;
  task_pick?: InputMaybe<WhHookConfigOptionsInput>;
  task_putaway?: InputMaybe<WhHookConfigOptionsInput>;
};

export type WhDeliveryItemConfiguration = {
  __typename?: 'WHDeliveryItemConfiguration';
  asn: WhAsnDeliveryItemConfiguration;
};

export type WhDeliveryItemConfigurationInput = {
  asn: WhAsnDeliveryItemConfigurationInput;
};

export type WhFulfillmentConfiguration = {
  __typename?: 'WHFulfillmentConfiguration';
  so: WhSoFulfillmentConfiguration;
};

export type WhFulfillmentConfigurationInput = {
  so: WhSoFulfillmentConfigurationInput;
};

export type WhFulfillmentItemConfiguration = {
  __typename?: 'WHFulfillmentItemConfiguration';
  so: WhSoFulfillmentItemConfiguration;
};

export type WhFulfillmentItemConfigurationInput = {
  so: WhSoFulfillmentItemConfigurationInput;
};

export type WhHookConfigOptions = {
  __typename?: 'WHHookConfigOptions';
  async?: Maybe<Scalars['Boolean']['output']>;
  on?: Maybe<Scalars['Boolean']['output']>;
};

export type WhHookConfigOptionsInput = {
  async?: InputMaybe<Scalars['Boolean']['input']>;
  on?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WhMasterBusinessPartnerConfiguration = {
  __typename?: 'WHMasterBusinessPartnerConfiguration';
  enabled: Scalars['Boolean']['output'];
  fields: WhMasterBusinessPartnerFieldsConfiguration;
};

export type WhMasterBusinessPartnerConfigurationInput = {
  enabled: Scalars['Boolean']['input'];
  fields: WhMasterBusinessPartnerFieldsConfigurationInput;
};

export type WhMasterBusinessPartnerFieldConfiguration = {
  __typename?: 'WHMasterBusinessPartnerFieldConfiguration';
  create: Scalars['Boolean']['output'];
  mappingName?: Maybe<Scalars['String']['output']>;
  required: Scalars['Boolean']['output'];
};

export type WhMasterBusinessPartnerFieldConfigurationInput = {
  create: Scalars['Boolean']['input'];
  mappingName?: InputMaybe<Scalars['String']['input']>;
  required: Scalars['Boolean']['input'];
};

export type WhMasterBusinessPartnerFieldsConfiguration = {
  __typename?: 'WHMasterBusinessPartnerFieldsConfiguration';
  addressTimezone: WhMasterBusinessPartnerFieldConfiguration;
  addressUUID: WhMasterBusinessPartnerFieldConfiguration;
  cityName: WhMasterBusinessPartnerFieldConfiguration;
  country: WhMasterBusinessPartnerFieldConfiguration;
  customerCode: WhMasterBusinessPartnerFieldConfiguration;
  defaultPhoneNumber: WhMasterBusinessPartnerFieldConfiguration;
  district: WhMasterBusinessPartnerFieldConfiguration;
  email: WhMasterBusinessPartnerFieldConfiguration;
  language: WhMasterBusinessPartnerFieldConfiguration;
  name: WhMasterBusinessPartnerFieldConfiguration;
  phoneNumber: WhMasterBusinessPartnerFieldConfiguration;
  postalCode: WhMasterBusinessPartnerFieldConfiguration;
  region: WhMasterBusinessPartnerFieldConfiguration;
  searchTerm1: WhMasterBusinessPartnerFieldConfiguration;
  searchTerm2: WhMasterBusinessPartnerFieldConfiguration;
  streetAddress: WhMasterBusinessPartnerFieldConfiguration;
  supplierCode: WhMasterBusinessPartnerFieldConfiguration;
  type: WhMasterBusinessPartnerFieldConfiguration;
  validityEnd: WhMasterBusinessPartnerFieldConfiguration;
  validityStart: WhMasterBusinessPartnerFieldConfiguration;
};

export type WhMasterBusinessPartnerFieldsConfigurationInput = {
  addressTimezone: WhMasterBusinessPartnerFieldConfigurationInput;
  addressUUID: WhMasterBusinessPartnerFieldConfigurationInput;
  cityName: WhMasterBusinessPartnerFieldConfigurationInput;
  country: WhMasterBusinessPartnerFieldConfigurationInput;
  customerCode: WhMasterBusinessPartnerFieldConfigurationInput;
  defaultPhoneNumber: WhMasterBusinessPartnerFieldConfigurationInput;
  district: WhMasterBusinessPartnerFieldConfigurationInput;
  email: WhMasterBusinessPartnerFieldConfigurationInput;
  language: WhMasterBusinessPartnerFieldConfigurationInput;
  name: WhMasterBusinessPartnerFieldConfigurationInput;
  phoneNumber: WhMasterBusinessPartnerFieldConfigurationInput;
  postalCode: WhMasterBusinessPartnerFieldConfigurationInput;
  region: WhMasterBusinessPartnerFieldConfigurationInput;
  searchTerm1: WhMasterBusinessPartnerFieldConfigurationInput;
  searchTerm2: WhMasterBusinessPartnerFieldConfigurationInput;
  streetAddress: WhMasterBusinessPartnerFieldConfigurationInput;
  supplierCode: WhMasterBusinessPartnerFieldConfigurationInput;
  type: WhMasterBusinessPartnerFieldConfigurationInput;
  validityEnd: WhMasterBusinessPartnerFieldConfigurationInput;
  validityStart: WhMasterBusinessPartnerFieldConfigurationInput;
};

export type WhMasterProductConfiguration = {
  __typename?: 'WHMasterProductConfiguration';
  enabled: Scalars['Boolean']['output'];
  fields: WhMasterProductFieldsConfiguration;
};

export type WhMasterProductConfigurationInput = {
  enabled: Scalars['Boolean']['input'];
  fields: WhMasterProductFieldsConfigurationInput;
};

export type WhMasterProductFieldConfiguration = {
  __typename?: 'WHMasterProductFieldConfiguration';
  create: Scalars['Boolean']['output'];
  mappingName?: Maybe<Scalars['String']['output']>;
  required: Scalars['Boolean']['output'];
};

export type WhMasterProductFieldConfigurationInput = {
  create: Scalars['Boolean']['input'];
  mappingName?: InputMaybe<Scalars['String']['input']>;
  required: Scalars['Boolean']['input'];
};

export type WhMasterProductFieldsConfiguration = {
  __typename?: 'WHMasterProductFieldsConfiguration';
  description: WhMasterProductFieldConfiguration;
  dimensionUnitOfMeasure: WhMasterProductFieldConfiguration;
  height: WhMasterProductFieldConfiguration;
  length: WhMasterProductFieldConfiguration;
  lotManaged: WhMasterProductFieldConfiguration;
  name: WhMasterProductFieldConfiguration;
  weight: WhMasterProductFieldConfiguration;
  weightUnitOfMeasure: WhMasterProductFieldConfiguration;
  width: WhMasterProductFieldConfiguration;
};

export type WhMasterProductFieldsConfigurationInput = {
  description: WhMasterProductFieldConfigurationInput;
  dimensionUnitOfMeasure: WhMasterProductFieldConfigurationInput;
  height: WhMasterProductFieldConfigurationInput;
  length: WhMasterProductFieldConfigurationInput;
  lotManaged: WhMasterProductFieldConfigurationInput;
  name: WhMasterProductFieldConfigurationInput;
  weight: WhMasterProductFieldConfigurationInput;
  weightUnitOfMeasure: WhMasterProductFieldConfigurationInput;
  width: WhMasterProductFieldConfigurationInput;
};

export type WhMasterUnitOfMeasureConfiguration = {
  __typename?: 'WHMasterUnitOfMeasureConfiguration';
  enabled: Scalars['Boolean']['output'];
};

export type WhMasterUnitOfMeasureConfigurationInput = {
  enabled: Scalars['Boolean']['input'];
};

export type WhProductConfiguration = {
  __typename?: 'WHProductConfiguration';
  master: WhMasterProductConfiguration;
};

export type WhProductConfigurationInput = {
  master: WhMasterProductConfigurationInput;
};

export type WhSoFulfillmentConfiguration = {
  __typename?: 'WHSoFulfillmentConfiguration';
  enabled: Scalars['Boolean']['output'];
  fields: WhSoFulfillmentFieldsConfiguration;
};

export type WhSoFulfillmentConfigurationInput = {
  enabled: Scalars['Boolean']['input'];
  fields: WhSoFulfillmentFieldsConfigurationInput;
};

export type WhSoFulfillmentFieldConfiguration = {
  __typename?: 'WHSoFulfillmentFieldConfiguration';
  mappingName?: Maybe<Scalars['String']['output']>;
  required: Scalars['Boolean']['output'];
};

export type WhSoFulfillmentFieldConfigurationInput = {
  mappingName?: InputMaybe<Scalars['String']['input']>;
  required: Scalars['Boolean']['input'];
};

export type WhSoFulfillmentFieldsConfiguration = {
  __typename?: 'WHSoFulfillmentFieldsConfiguration';
  blockStatus: WhSoFulfillmentFieldConfiguration;
  category: WhSoFulfillmentFieldConfiguration;
  deliveryStatus: WhSoFulfillmentFieldConfiguration;
  doorCode: WhSoFulfillmentFieldConfiguration;
  dueDate: WhSoFulfillmentFieldConfiguration;
  erpBlockingReason: WhSoFulfillmentFieldConfiguration;
  erpCreated: WhSoFulfillmentFieldConfiguration;
  erpLastChanged: WhSoFulfillmentFieldConfiguration;
  erpPurchaseOrder: WhSoFulfillmentFieldConfiguration;
  erpSalesOrder: WhSoFulfillmentFieldConfiguration;
  export: WhSoFulfillmentFieldConfiguration;
  fulfillmentBlockCode: WhSoFulfillmentFieldConfiguration;
  goodsReceiptOrIssueStatus: WhSoFulfillmentFieldConfiguration;
  loadOrUnloadStatus: WhSoFulfillmentFieldConfiguration;
  pickOrPutawayStatus: WhSoFulfillmentFieldConfiguration;
  pointOfContact: WhSoFulfillmentFieldConfiguration;
  promiseDate: WhSoFulfillmentFieldConfiguration;
  shipReadyDate: WhSoFulfillmentFieldConfiguration;
  shipTo: WhSoFulfillmentFieldConfiguration;
  shipToBusinessPartnerCode: WhSoFulfillmentFieldConfiguration;
  shipToName: WhSoFulfillmentFieldConfiguration;
  soldTo: WhSoFulfillmentFieldConfiguration;
  soldToBusinessPartnerCode: WhSoFulfillmentFieldConfiguration;
  soldToName: WhSoFulfillmentFieldConfiguration;
  supplier: WhSoFulfillmentFieldConfiguration;
  supplierName: WhSoFulfillmentFieldConfiguration;
  totalGrossWeight: WhSoFulfillmentFieldConfiguration;
  totalNetWeight: WhSoFulfillmentFieldConfiguration;
  totalVolume: WhSoFulfillmentFieldConfiguration;
  totalVolumeUom: WhSoFulfillmentFieldConfiguration;
  totalWeightUom: WhSoFulfillmentFieldConfiguration;
};

export type WhSoFulfillmentFieldsConfigurationInput = {
  blockStatus: WhSoFulfillmentFieldConfigurationInput;
  category: WhSoFulfillmentFieldConfigurationInput;
  deliveryStatus: WhSoFulfillmentFieldConfigurationInput;
  doorCode: WhSoFulfillmentFieldConfigurationInput;
  dueDate: WhSoFulfillmentFieldConfigurationInput;
  erpBlockingReason: WhSoFulfillmentFieldConfigurationInput;
  erpCreated: WhSoFulfillmentFieldConfigurationInput;
  erpLastChanged: WhSoFulfillmentFieldConfigurationInput;
  erpPurchaseOrder: WhSoFulfillmentFieldConfigurationInput;
  erpSalesOrder: WhSoFulfillmentFieldConfigurationInput;
  export: WhSoFulfillmentFieldConfigurationInput;
  fulfillmentBlockCode: WhSoFulfillmentFieldConfigurationInput;
  goodsReceiptOrIssueStatus: WhSoFulfillmentFieldConfigurationInput;
  loadOrUnloadStatus: WhSoFulfillmentFieldConfigurationInput;
  pickOrPutawayStatus: WhSoFulfillmentFieldConfigurationInput;
  pointOfContact: WhSoFulfillmentFieldConfigurationInput;
  promiseDate: WhSoFulfillmentFieldConfigurationInput;
  shipReadyDate: WhSoFulfillmentFieldConfigurationInput;
  shipTo: WhSoFulfillmentFieldConfigurationInput;
  shipToBusinessPartnerCode: WhSoFulfillmentFieldConfigurationInput;
  shipToName: WhSoFulfillmentFieldConfigurationInput;
  soldTo: WhSoFulfillmentFieldConfigurationInput;
  soldToBusinessPartnerCode: WhSoFulfillmentFieldConfigurationInput;
  soldToName: WhSoFulfillmentFieldConfigurationInput;
  supplier: WhSoFulfillmentFieldConfigurationInput;
  supplierName: WhSoFulfillmentFieldConfigurationInput;
  totalGrossWeight: WhSoFulfillmentFieldConfigurationInput;
  totalNetWeight: WhSoFulfillmentFieldConfigurationInput;
  totalVolume: WhSoFulfillmentFieldConfigurationInput;
  totalVolumeUom: WhSoFulfillmentFieldConfigurationInput;
  totalWeightUom: WhSoFulfillmentFieldConfigurationInput;
};

export type WhSoFulfillmentItemConfiguration = {
  __typename?: 'WHSoFulfillmentItemConfiguration';
  enabled: Scalars['Boolean']['output'];
  fields: WhSoFulfillmentItemFieldsConfiguration;
};

export type WhSoFulfillmentItemConfigurationInput = {
  enabled: Scalars['Boolean']['input'];
  fields: WhSoFulfillmentItemFieldsConfigurationInput;
};

export type WhSoFulfillmentItemFieldConfiguration = {
  __typename?: 'WHSoFulfillmentItemFieldConfiguration';
  create: Scalars['Boolean']['output'];
  mappingName?: Maybe<Scalars['String']['output']>;
  required: Scalars['Boolean']['output'];
};

export type WhSoFulfillmentItemFieldConfigurationInput = {
  create: Scalars['Boolean']['input'];
  mappingName?: InputMaybe<Scalars['String']['input']>;
  required: Scalars['Boolean']['input'];
};

export type WhSoFulfillmentItemFieldsConfiguration = {
  __typename?: 'WHSoFulfillmentItemFieldsConfiguration';
  customerProductCode: WhSoFulfillmentItemFieldConfiguration;
  dateMaterialAvailable: WhSoFulfillmentItemFieldConfiguration;
  description: WhSoFulfillmentItemFieldConfiguration;
  erpLastChanged: WhSoFulfillmentItemFieldConfiguration;
  erpSalesOrder: WhSoFulfillmentItemFieldConfiguration;
  erpSalesOrderItem: WhSoFulfillmentItemFieldConfiguration;
  fulfillmentItem: WhSoFulfillmentItemFieldConfiguration;
  grossWeight: WhSoFulfillmentItemFieldConfiguration;
  issueStatus: WhSoFulfillmentItemFieldConfiguration;
  loadStatus: WhSoFulfillmentItemFieldConfiguration;
  lotCode: WhSoFulfillmentItemFieldConfiguration;
  netWeight: WhSoFulfillmentItemFieldConfiguration;
  pickStatus: WhSoFulfillmentItemFieldConfiguration;
  productCode: WhSoFulfillmentItemFieldConfiguration;
  stockStatusTypeCode: WhSoFulfillmentItemFieldConfiguration;
  uom: WhSoFulfillmentItemFieldConfiguration;
  volume: WhSoFulfillmentItemFieldConfiguration;
  volumeUom: WhSoFulfillmentItemFieldConfiguration;
  weightUom: WhSoFulfillmentItemFieldConfiguration;
};

export type WhSoFulfillmentItemFieldsConfigurationInput = {
  customerProductCode: WhSoFulfillmentItemFieldConfigurationInput;
  dateMaterialAvailable: WhSoFulfillmentItemFieldConfigurationInput;
  description: WhSoFulfillmentItemFieldConfigurationInput;
  erpLastChanged: WhSoFulfillmentItemFieldConfigurationInput;
  erpSalesOrder: WhSoFulfillmentItemFieldConfigurationInput;
  erpSalesOrderItem: WhSoFulfillmentItemFieldConfigurationInput;
  fulfillmentItem: WhSoFulfillmentItemFieldConfigurationInput;
  grossWeight: WhSoFulfillmentItemFieldConfigurationInput;
  issueStatus: WhSoFulfillmentItemFieldConfigurationInput;
  loadStatus: WhSoFulfillmentItemFieldConfigurationInput;
  lotCode: WhSoFulfillmentItemFieldConfigurationInput;
  netWeight: WhSoFulfillmentItemFieldConfigurationInput;
  pickStatus: WhSoFulfillmentItemFieldConfigurationInput;
  productCode: WhSoFulfillmentItemFieldConfigurationInput;
  stockStatusTypeCode: WhSoFulfillmentItemFieldConfigurationInput;
  uom: WhSoFulfillmentItemFieldConfigurationInput;
  volume: WhSoFulfillmentItemFieldConfigurationInput;
  volumeUom: WhSoFulfillmentItemFieldConfigurationInput;
  weightUom: WhSoFulfillmentItemFieldConfigurationInput;
};

export type WhTaskConfiguration = {
  __typename?: 'WHTaskConfiguration';
  delivery?: Maybe<WhTaskDeliveryConfiguration>;
  fulfillment?: Maybe<WhTaskFulfillmentConfiguration>;
  grouping?: Maybe<WhTaskGroupingConfiguration>;
};

export type WhTaskConfigurationInput = {
  delivery?: InputMaybe<WhTaskDeliveryConfigurationInput>;
  fulfillment?: InputMaybe<WhTaskFulfillmentConfigurationInput>;
  grouping?: InputMaybe<WhTaskGroupingConfigurationInput>;
};

export type WhTaskDeliveryConfiguration = {
  __typename?: 'WHTaskDeliveryConfiguration';
  putawaySteps?: Maybe<DeliveryTaskSteps>;
};

export type WhTaskDeliveryConfigurationInput = {
  putawaySteps?: InputMaybe<DeliveryTaskSteps>;
};

export type WhTaskFulfillmentConfiguration = {
  __typename?: 'WHTaskFulfillmentConfiguration';
  pickSteps?: Maybe<DeliveryTaskSteps>;
};

export type WhTaskFulfillmentConfigurationInput = {
  pickSteps?: InputMaybe<DeliveryTaskSteps>;
};

export type WhTaskGroupingConfiguration = {
  __typename?: 'WHTaskGroupingConfiguration';
  maxTaskGroupSize?: Maybe<Scalars['Int']['output']>;
};

export type WhTaskGroupingConfigurationInput = {
  maxTaskGroupSize?: InputMaybe<Scalars['Int']['input']>;
};

export type WhUnitOfMeasureConfiguration = {
  __typename?: 'WHUnitOfMeasureConfiguration';
  master: WhMasterUnitOfMeasureConfiguration;
};

export type WhUnitOfMeasureConfigurationInput = {
  master: WhMasterUnitOfMeasureConfigurationInput;
};

/** Warehouse model */
export type Warehouse = {
  __typename?: 'Warehouse';
  /** Entity code */
  code: Scalars['String']['output'];
  company?: Maybe<Company>;
  /** Entity's company ID (foreign key) */
  companyId: Scalars['ID']['output'];
  contactInfo?: Maybe<ContactInfo>;
  contactInfoId?: Maybe<Scalars['ID']['output']>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  displayPreference?: Maybe<DisplayPreference>;
  displayPreferenceId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** ApiDocs */
  name?: Maybe<Scalars['String']['output']>;
  /** Configuration Model for the Warehouse */
  type?: Maybe<ModelWarehouseType>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
};

/** WarehouseConfiguration entity model */
export type WarehouseConfiguration = {
  __typename?: 'WarehouseConfiguration';
  configuration: WhConfiguration;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
};

export type WarehouseConfigurationCreateInput = {
  configuration: WhConfigurationInput;
  warehouseId: Scalars['ID']['input'];
};

export type WarehouseConfigurationCreateOneInput = {
  /** The record to create */
  warehouseConfiguration: WarehouseConfigurationCreateInput;
};

export type WarehouseConfigurationUpdateInput = {
  configuration?: InputMaybe<WhConfigurationInput>;
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type WarehouseConfigurationUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: WarehouseConfigurationUpdateInput;
};

export type WarehouseCreateInput = {
  /** Entity code */
  code: Scalars['String']['input'];
  /** Entity's company ID (foreign key) */
  companyId: Scalars['ID']['input'];
  /** Entity's human readable name */
  name: Scalars['String']['input'];
  /** Configuration Model for the Warehouse */
  type?: InputMaybe<ModelWarehouseType>;
};

export type WarehouseCreateOneInput = {
  /** The record to create */
  warehouse: WarehouseCreateInput;
};

export type WarehouseFilter = {
  and?: InputMaybe<Array<WarehouseFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  company?: InputMaybe<WarehouseFilterCompanyFilter>;
  companyId?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WarehouseFilter>>;
  type?: InputMaybe<ModelWarehouseTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type WarehouseFilterCompanyFilter = {
  and?: InputMaybe<Array<WarehouseFilterCompanyFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  contactInfoId?: InputMaybe<IdFilterComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WarehouseFilterCompanyFilter>>;
  organizationId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
};

export type WarehouseNodes = {
  __typename?: 'WarehouseNodes';
  nodes: Array<Warehouse>;
};

export type WarehouseOffsetConnection = {
  __typename?: 'WarehouseOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Warehouse>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export enum WarehouseOpCode {
  Asn = 'asn',
  AsnDelivery = 'asnDelivery',
  AsnDeliveryItem = 'asnDeliveryItem',
  MasterBusinessPartner = 'masterBusinessPartner',
  MasterProduct = 'masterProduct',
  MasterUnitOfMeasure = 'masterUnitOfMeasure',
  Oc = 'oc',
  SoFulfillment = 'soFulfillment',
  SoFulfillmentItem = 'soFulfillmentItem'
}

export type WarehouseOpCodeFilterComparison = {
  eq?: InputMaybe<WarehouseOpCode>;
  gt?: InputMaybe<WarehouseOpCode>;
  gte?: InputMaybe<WarehouseOpCode>;
  iLike?: InputMaybe<WarehouseOpCode>;
  in?: InputMaybe<Array<WarehouseOpCode>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<WarehouseOpCode>;
  lt?: InputMaybe<WarehouseOpCode>;
  lte?: InputMaybe<WarehouseOpCode>;
  neq?: InputMaybe<WarehouseOpCode>;
  notILike?: InputMaybe<WarehouseOpCode>;
  notIn?: InputMaybe<Array<WarehouseOpCode>>;
  notLike?: InputMaybe<WarehouseOpCode>;
};

/** Warehouse op file */
export type WarehouseOpFile = {
  __typename?: 'WarehouseOpFile';
  /** Business partner ID */
  businessPartnerId: Scalars['ID']['output'];
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Date the file finished processing */
  processedDate?: Maybe<Scalars['DateTime']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Date file was uploaded */
  uploadedDate?: Maybe<Scalars['DateTime']['output']>;
  /** Which type operation that triggers flow, i.e. oc, asn */
  warehouseOpCode: WarehouseOpCode;
  /** Enum indicating file processing status, i.e. queued, processed */
  warehouseOpFileStatus: WarehouseOpFileStatus;
};

export type WarehouseOpFileFilter = {
  and?: InputMaybe<Array<WarehouseOpFileFilter>>;
  businessPartnerId?: InputMaybe<IdFilterComparison>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WarehouseOpFileFilter>>;
  processedDate?: InputMaybe<DateFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  uploadedDate?: InputMaybe<DateFieldComparison>;
  warehouseOpCode?: InputMaybe<WarehouseOpCodeFilterComparison>;
  warehouseOpFileStatus?: InputMaybe<WarehouseOpFileStatusFilterComparison>;
};

export type WarehouseOpFileOffsetConnection = {
  __typename?: 'WarehouseOpFileOffsetConnection';
  /** Array of nodes. */
  nodes: Array<WarehouseOpFile>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

/** Warehouse op file row */
export type WarehouseOpFileRow = {
  __typename?: 'WarehouseOpFileRow';
  /** Warehouse op file row barcode */
  barcode?: Maybe<Scalars['String']['output']>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Delivery item ID (foreign key) */
  deliveryItemId?: Maybe<Scalars['ID']['output']>;
  /** Error message from a failed row processing */
  errors?: Maybe<Scalars['JSONObject']['output']>;
  /** Fulfillment item id (foreign key) */
  fulfillmentItemId?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Raw row data from the uploaded file */
  rowData: Scalars['JSONObject']['output'];
  /** Which row in the processed file the row is connected with */
  rowNumber: Scalars['Int']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** ID for a warehouse op file */
  warehouseOpFileId: Scalars['String']['output'];
  /** Enum indicating if the row was processed successfully */
  warehouseOpFileRowStatus: WarehouseOpFileRowStatus;
  /** Type of warehouse op file (OC or ASN) */
  warehouseOpFileType?: Maybe<WarehouseOpCode>;
};

export type WarehouseOpFileRowFilter = {
  and?: InputMaybe<Array<WarehouseOpFileRowFilter>>;
  barcode?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  deliveryItemId?: InputMaybe<IdFilterComparison>;
  errors?: InputMaybe<JsonObjectFilterComparison>;
  fulfillmentItemId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WarehouseOpFileRowFilter>>;
  rowData?: InputMaybe<JsonObjectFilterComparison>;
  rowNumber?: InputMaybe<IntFieldComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  warehouseOpFileId?: InputMaybe<StringFieldComparison>;
  warehouseOpFileRowStatus?: InputMaybe<WarehouseOpFileRowStatusFilterComparison>;
  warehouseOpFileType?: InputMaybe<WarehouseOpCodeFilterComparison>;
};

export type WarehouseOpFileRowOffsetConnection = {
  __typename?: 'WarehouseOpFileRowOffsetConnection';
  /** Array of nodes. */
  nodes: Array<WarehouseOpFileRow>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type WarehouseOpFileRowSort = {
  direction: SortDirection;
  field: WarehouseOpFileRowSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WarehouseOpFileRowSortFields {
  Barcode = 'barcode',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  DeliveryItemId = 'deliveryItemId',
  Errors = 'errors',
  FulfillmentItemId = 'fulfillmentItemId',
  Id = 'id',
  RowData = 'rowData',
  RowNumber = 'rowNumber',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WarehouseOpFileId = 'warehouseOpFileId',
  WarehouseOpFileRowStatus = 'warehouseOpFileRowStatus',
  WarehouseOpFileType = 'warehouseOpFileType'
}

export enum WarehouseOpFileRowStatus {
  Failed = 'failed',
  Processed = 'processed',
  Queued = 'queued',
  Received = 'received'
}

export type WarehouseOpFileRowStatusFilterComparison = {
  eq?: InputMaybe<WarehouseOpFileRowStatus>;
  gt?: InputMaybe<WarehouseOpFileRowStatus>;
  gte?: InputMaybe<WarehouseOpFileRowStatus>;
  iLike?: InputMaybe<WarehouseOpFileRowStatus>;
  in?: InputMaybe<Array<WarehouseOpFileRowStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<WarehouseOpFileRowStatus>;
  lt?: InputMaybe<WarehouseOpFileRowStatus>;
  lte?: InputMaybe<WarehouseOpFileRowStatus>;
  neq?: InputMaybe<WarehouseOpFileRowStatus>;
  notILike?: InputMaybe<WarehouseOpFileRowStatus>;
  notIn?: InputMaybe<Array<WarehouseOpFileRowStatus>>;
  notLike?: InputMaybe<WarehouseOpFileRowStatus>;
};

export type WarehouseOpFileSort = {
  direction: SortDirection;
  field: WarehouseOpFileSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WarehouseOpFileSortFields {
  BusinessPartnerId = 'businessPartnerId',
  Code = 'code',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  ProcessedDate = 'processedDate',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  UploadedDate = 'uploadedDate',
  WarehouseOpCode = 'warehouseOpCode',
  WarehouseOpFileStatus = 'warehouseOpFileStatus'
}

export enum WarehouseOpFileStatus {
  Failed = 'failed',
  PartialFailure = 'partial_failure',
  Processed = 'processed',
  Processing = 'processing',
  Queued = 'queued',
  Recieved = 'recieved'
}

export type WarehouseOpFileStatusFilterComparison = {
  eq?: InputMaybe<WarehouseOpFileStatus>;
  gt?: InputMaybe<WarehouseOpFileStatus>;
  gte?: InputMaybe<WarehouseOpFileStatus>;
  iLike?: InputMaybe<WarehouseOpFileStatus>;
  in?: InputMaybe<Array<WarehouseOpFileStatus>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<WarehouseOpFileStatus>;
  lt?: InputMaybe<WarehouseOpFileStatus>;
  lte?: InputMaybe<WarehouseOpFileStatus>;
  neq?: InputMaybe<WarehouseOpFileStatus>;
  notILike?: InputMaybe<WarehouseOpFileStatus>;
  notIn?: InputMaybe<Array<WarehouseOpFileStatus>>;
  notLike?: InputMaybe<WarehouseOpFileStatus>;
};

/** Warehouse Preferred Unit Of Measure model */
export type WarehousePreferredUnitOfMeasure = {
  __typename?: 'WarehousePreferredUnitOfMeasure';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  product?: Maybe<Product>;
  /** Entity's product ID (foreign key) */
  productId?: Maybe<Scalars['ID']['output']>;
  unitOfMeasure?: Maybe<UnitOfMeasureProductConversion>;
  /** Unit of measure ID */
  unitOfMeasureId: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: Maybe<Scalars['ID']['output']>;
};

export type WarehousePreferredUnitOfMeasureCreateInput = {
  /** Entity's product ID (foreign key) */
  productId: Scalars['ID']['input'];
  /** Unit of measure ID */
  unitOfMeasureId: Scalars['ID']['input'];
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['input'];
};

export type WarehousePreferredUnitOfMeasureCreateOneInput = {
  /** The record to create */
  warehousePreferredUnitOfMeasure: WarehousePreferredUnitOfMeasureCreateInput;
};

export type WarehousePreferredUnitOfMeasureFilter = {
  and?: InputMaybe<Array<WarehousePreferredUnitOfMeasureFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<WarehousePreferredUnitOfMeasureFilter>>;
  productId?: InputMaybe<IdFilterComparison>;
  unitOfMeasureId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type WarehousePreferredUnitOfMeasureOffsetConnection = {
  __typename?: 'WarehousePreferredUnitOfMeasureOffsetConnection';
  /** Array of nodes. */
  nodes: Array<WarehousePreferredUnitOfMeasure>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type WarehousePreferredUnitOfMeasureSort = {
  direction: SortDirection;
  field: WarehousePreferredUnitOfMeasureSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WarehousePreferredUnitOfMeasureSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  ProductId = 'productId',
  UnitOfMeasureId = 'unitOfMeasureId',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WarehouseId = 'warehouseId'
}

export type WarehousePreferredUnitOfMeasureUpdateInput = {
  /** Entity's product ID (foreign key) */
  productId?: InputMaybe<Scalars['ID']['input']>;
  /** Unit of measure ID */
  unitOfMeasureId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId?: InputMaybe<Scalars['ID']['input']>;
};

export type WarehousePreferredUnitOfMeasureUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: WarehousePreferredUnitOfMeasureUpdateInput;
};

/** WarehouseRoleType entity model */
export type WarehouseRoleType = {
  __typename?: 'WarehouseRoleType';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Cost */
  cost?: Maybe<Scalars['Float']['output']>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Currency */
  currency: Scalars['String']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity's label */
  label: Scalars['String']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Velocity */
  velocity?: Maybe<Scalars['Float']['output']>;
  /** Velocity unit of measure */
  velocityUOMId?: Maybe<Scalars['String']['output']>;
  /** Number representing maximum weight limit for an equipment model. */
  weightMax?: Maybe<Scalars['Float']['output']>;
  /** Weight UOM, i.e KG */
  weightUOMId: Scalars['String']['output'];
};

export type WarehouseRoleTypeCreateOneInput = {
  /** The record to create */
  warehouseRoleType: WarehouseRoleTypeCreateType;
};

export type WarehouseRoleTypeCreateType = {
  /** Entity code */
  code: Scalars['String']['input'];
  /** Cost */
  cost: Scalars['Float']['input'];
  /** Currency */
  currency: Scalars['String']['input'];
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Entity's label */
  label: Scalars['String']['input'];
  /** Velocity */
  velocity: Scalars['Float']['input'];
  /** Velocity unit of measure */
  velocityUOMId: Scalars['ID']['input'];
  /** Number representing maximum weight limit for an equipment model. */
  weightMax: Scalars['Float']['input'];
  /** Weight UOM, i.e KG */
  weightUOMId: Scalars['ID']['input'];
};

export type WarehouseRoleTypeFilter = {
  and?: InputMaybe<Array<WarehouseRoleTypeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  cost?: InputMaybe<FloatFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  currency?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WarehouseRoleTypeFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  velocity?: InputMaybe<FloatFieldComparison>;
  velocityUOMId?: InputMaybe<StringFieldComparison>;
  weightMax?: InputMaybe<FloatFieldComparison>;
  weightUOMId?: InputMaybe<StringFieldComparison>;
};

export type WarehouseRoleTypeOffsetConnection = {
  __typename?: 'WarehouseRoleTypeOffsetConnection';
  /** Array of nodes. */
  nodes: Array<WarehouseRoleType>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type WarehouseRoleTypeQueryShape = {
  __typename?: 'WarehouseRoleTypeQueryShape';
  /** Entity code */
  code?: Maybe<Scalars['String']['output']>;
  /** Currency */
  cost?: Maybe<Scalars['Float']['output']>;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Currency */
  currency?: Maybe<Scalars['String']['output']>;
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity's label */
  label?: Maybe<Scalars['String']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Velocity */
  velocity?: Maybe<Scalars['Float']['output']>;
  /** Velocity unit of measure */
  velocityUOMCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  velocityUOMId?: Maybe<Scalars['String']['output']>;
  /** Velocity unit of measure */
  velocityUOMLabel?: Maybe<Scalars['String']['output']>;
  /** Number representing maximum weight limit for an equipment model. */
  weightMax?: Maybe<Scalars['Float']['output']>;
  /** Weight UOM, i.e KG */
  weightUOMCode?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  weightUOMId?: Maybe<Scalars['ID']['output']>;
  /** Weight UOM, i.e KG */
  weightUOMLabel?: Maybe<Scalars['String']['output']>;
};

export type WarehouseRoleTypeQueryShapeFilter = {
  and?: InputMaybe<Array<WarehouseRoleTypeQueryShapeFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  cost?: InputMaybe<FloatFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  currency?: InputMaybe<StringFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  label?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<WarehouseRoleTypeQueryShapeFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  velocity?: InputMaybe<FloatFieldComparison>;
  velocityUOMCode?: InputMaybe<StringFieldComparison>;
  velocityUOMId?: InputMaybe<StringFieldComparison>;
  velocityUOMLabel?: InputMaybe<StringFieldComparison>;
  weightMax?: InputMaybe<FloatFieldComparison>;
  weightUOMCode?: InputMaybe<StringFieldComparison>;
  weightUOMId?: InputMaybe<IdFilterComparison>;
  weightUOMLabel?: InputMaybe<StringFieldComparison>;
};

export type WarehouseRoleTypeQueryShapeOffsetConnection = {
  __typename?: 'WarehouseRoleTypeQueryShapeOffsetConnection';
  /** Array of nodes. */
  nodes: Array<WarehouseRoleTypeQueryShape>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type WarehouseRoleTypeQueryShapeSort = {
  direction: SortDirection;
  field: WarehouseRoleTypeQueryShapeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WarehouseRoleTypeQueryShapeSortFields {
  Code = 'code',
  Cost = 'cost',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  DeletedAt = 'deletedAt',
  Description = 'description',
  Id = 'id',
  Label = 'label',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  Velocity = 'velocity',
  VelocityUomCode = 'velocityUOMCode',
  VelocityUomId = 'velocityUOMId',
  VelocityUomLabel = 'velocityUOMLabel',
  WeightMax = 'weightMax',
  WeightUomCode = 'weightUOMCode',
  WeightUomId = 'weightUOMId',
  WeightUomLabel = 'weightUOMLabel'
}

export type WarehouseRoleTypeSort = {
  direction: SortDirection;
  field: WarehouseRoleTypeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WarehouseRoleTypeSortFields {
  Code = 'code',
  Cost = 'cost',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  DeletedAt = 'deletedAt',
  Description = 'description',
  Id = 'id',
  Label = 'label',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  Velocity = 'velocity',
  VelocityUomId = 'velocityUOMId',
  WeightMax = 'weightMax',
  WeightUomId = 'weightUOMId'
}

export type WarehouseRoleTypeUpdateInput = {
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Cost */
  cost?: InputMaybe<Scalars['Float']['input']>;
  /** Currency */
  currency?: InputMaybe<Scalars['String']['input']>;
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Entity's label */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Velocity */
  velocity?: InputMaybe<Scalars['Float']['input']>;
  /** Velocity unit of measure */
  velocityUOMId?: InputMaybe<Scalars['ID']['input']>;
  /** Number representing maximum weight limit for an equipment model. */
  weightMax?: InputMaybe<Scalars['Float']['input']>;
  /** Weight UOM, i.e KG */
  weightUOMId?: InputMaybe<Scalars['ID']['input']>;
};

export type WarehouseRoleTypeUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: WarehouseRoleTypeUpdateInput;
};

export type WarehouseSort = {
  direction: SortDirection;
  field: WarehouseSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WarehouseSortFields {
  Code = 'code',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Name = 'name',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById'
}

export type WarehouseUpdateInput = {
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Entity's company ID (foreign key) */
  companyId?: InputMaybe<Scalars['ID']['input']>;
  /** Entity's human readable name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Configuration Model for the Warehouse */
  type?: InputMaybe<ModelWarehouseType>;
};

export type WarehouseUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: WarehouseUpdateInput;
};

export type Zone = {
  __typename?: 'Zone';
  /** Entity code */
  code: Scalars['String']['output'];
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of entity */
  description?: Maybe<Scalars['String']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** ApiDocs */
  name?: Maybe<Scalars['String']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['output'];
};

export type ZoneAddRemoveBlock = {
  /** Relation IDs to add */
  add: Array<Scalars['ID']['input']>;
  /** Relation IDs to remove */
  remove: Array<Scalars['ID']['input']>;
};

export enum ZoneBehavior {
  Inbound = 'inbound',
  Outbound = 'outbound',
  PhysicalInventory = 'physicalInventory',
  QualityControl = 'qualityControl',
  Replenishment = 'replenishment'
}

export type ZoneBehaviorMapping = {
  __typename?: 'ZoneBehaviorMapping';
  /** Zone behavior */
  behavior: ZoneBehavior;
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  zoneRuleMappingId: Scalars['ID']['output'];
};

export type ZoneCreateInput = {
  /** Entity code */
  code: Scalars['String']['input'];
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ApiDocs */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Entity's warehouse (foreign key) */
  warehouseId: Scalars['ID']['input'];
};

export type ZoneCreateOneInput = {
  /** The record to create */
  zone: ZoneCreateInput;
};

export type ZoneFilter = {
  and?: InputMaybe<Array<ZoneFilter>>;
  code?: InputMaybe<StringFieldComparison>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  description?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<ZoneFilter>>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  warehouseId?: InputMaybe<IdFilterComparison>;
};

export type ZoneOffsetConnection = {
  __typename?: 'ZoneOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Zone>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export enum ZoneRelatedEntity {
  EquipmentType = 'equipmentType',
  Product = 'product'
}

export type ZoneRelatedEntityFilterComparison = {
  eq?: InputMaybe<ZoneRelatedEntity>;
  gt?: InputMaybe<ZoneRelatedEntity>;
  gte?: InputMaybe<ZoneRelatedEntity>;
  iLike?: InputMaybe<ZoneRelatedEntity>;
  in?: InputMaybe<Array<ZoneRelatedEntity>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<ZoneRelatedEntity>;
  lt?: InputMaybe<ZoneRelatedEntity>;
  lte?: InputMaybe<ZoneRelatedEntity>;
  neq?: InputMaybe<ZoneRelatedEntity>;
  notILike?: InputMaybe<ZoneRelatedEntity>;
  notIn?: InputMaybe<Array<ZoneRelatedEntity>>;
  notLike?: InputMaybe<ZoneRelatedEntity>;
};

export type ZoneRule = {
  __typename?: 'ZoneRule';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Zone rule concerned entity */
  entity?: Maybe<ZoneRelatedEntity>;
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Zone rule */
  rule: ZoneRuleName;
  /** Zone rule type */
  type: ZoneRuleType;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Zone rule value options */
  valueOptions?: Maybe<Array<Scalars['String']['output']>>;
  /** Zone rule value type for static rules */
  valueType?: Maybe<ZoneRuleValueType>;
};

export type ZoneRuleFilter = {
  and?: InputMaybe<Array<ZoneRuleFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  entity?: InputMaybe<ZoneRelatedEntityFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ZoneRuleFilter>>;
  rule?: InputMaybe<ZoneRuleNameFilterComparison>;
  type?: InputMaybe<ZoneRuleTypeFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  valueType?: InputMaybe<ZoneRuleValueTypeFilterComparison>;
};

export type ZoneRuleMapping = {
  __typename?: 'ZoneRuleMapping';
  /** Created at date */
  createdAt: Scalars['DateTime']['output'];
  /** Deleted at date */
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Zone rule mapping group */
  group: Scalars['String']['output'];
  /** Entity ID */
  id: Scalars['ID']['output'];
  /** Entity ID */
  slottingConfigurationId?: Maybe<Scalars['ID']['output']>;
  /** Update at date */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Update by email */
  updatedByEmail?: Maybe<Scalars['String']['output']>;
  /** Update by id */
  updatedById?: Maybe<Scalars['ID']['output']>;
  /** Entity ID */
  zoneRuleId: Scalars['ID']['output'];
  /** Zone rule value */
  zoneRuleValue?: Maybe<Scalars['JSONObject']['output']>;
};

export type ZoneRuleMappingFilter = {
  and?: InputMaybe<Array<ZoneRuleMappingFilter>>;
  createdAt?: InputMaybe<DateFieldComparison>;
  deletedAt?: InputMaybe<DateFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<ZoneRuleMappingFilter>>;
  slottingConfigurationId?: InputMaybe<IdFilterComparison>;
  updatedAt?: InputMaybe<DateFieldComparison>;
  updatedByEmail?: InputMaybe<StringFieldComparison>;
  updatedById?: InputMaybe<IdFilterComparison>;
  zoneRuleId?: InputMaybe<IdFilterComparison>;
  zoneRuleValue?: InputMaybe<JsonObjectFilterComparison>;
};

export type ZoneRuleMappingOffsetConnection = {
  __typename?: 'ZoneRuleMappingOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ZoneRuleMapping>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ZoneRuleMappingSort = {
  direction: SortDirection;
  field: ZoneRuleMappingSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ZoneRuleMappingSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Id = 'id',
  SlottingConfigurationId = 'slottingConfigurationId',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  ZoneRuleId = 'zoneRuleId',
  ZoneRuleValue = 'zoneRuleValue'
}

export enum ZoneRuleName {
  EnforceSingleOrderPicking = 'enforceSingleOrderPicking',
  FefoFifoPicking = 'fefoFifoPicking',
  FifoPicking = 'fifoPicking',
  HeaviestToLightestPicks = 'heaviestToLightestPicks',
  MixedBinStorage = 'mixedBinStorage',
  MixedLotsInBins = 'mixedLotsInBins',
  SimilarProductInAdjacentBin = 'similarProductInAdjacentBin'
}

export type ZoneRuleNameFilterComparison = {
  eq?: InputMaybe<ZoneRuleName>;
  gt?: InputMaybe<ZoneRuleName>;
  gte?: InputMaybe<ZoneRuleName>;
  iLike?: InputMaybe<ZoneRuleName>;
  in?: InputMaybe<Array<ZoneRuleName>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<ZoneRuleName>;
  lt?: InputMaybe<ZoneRuleName>;
  lte?: InputMaybe<ZoneRuleName>;
  neq?: InputMaybe<ZoneRuleName>;
  notILike?: InputMaybe<ZoneRuleName>;
  notIn?: InputMaybe<Array<ZoneRuleName>>;
  notLike?: InputMaybe<ZoneRuleName>;
};

export type ZoneRuleOffsetConnection = {
  __typename?: 'ZoneRuleOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ZoneRule>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ZoneRuleSort = {
  direction: SortDirection;
  field: ZoneRuleSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ZoneRuleSortFields {
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Entity = 'entity',
  Id = 'id',
  Rule = 'rule',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  ValueType = 'valueType'
}

export enum ZoneRuleType {
  Binary = 'binary',
  Value = 'value'
}

export type ZoneRuleTypeFilterComparison = {
  eq?: InputMaybe<ZoneRuleType>;
  gt?: InputMaybe<ZoneRuleType>;
  gte?: InputMaybe<ZoneRuleType>;
  iLike?: InputMaybe<ZoneRuleType>;
  in?: InputMaybe<Array<ZoneRuleType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<ZoneRuleType>;
  lt?: InputMaybe<ZoneRuleType>;
  lte?: InputMaybe<ZoneRuleType>;
  neq?: InputMaybe<ZoneRuleType>;
  notILike?: InputMaybe<ZoneRuleType>;
  notIn?: InputMaybe<Array<ZoneRuleType>>;
  notLike?: InputMaybe<ZoneRuleType>;
};

export enum ZoneRuleValueType {
  Bool = 'bool',
  Enum = 'enum',
  EnumArray = 'enumArray',
  Float = 'float',
  FloatArray = 'floatArray',
  String = 'string',
  StringArray = 'stringArray'
}

export type ZoneRuleValueTypeFilterComparison = {
  eq?: InputMaybe<ZoneRuleValueType>;
  gt?: InputMaybe<ZoneRuleValueType>;
  gte?: InputMaybe<ZoneRuleValueType>;
  iLike?: InputMaybe<ZoneRuleValueType>;
  in?: InputMaybe<Array<ZoneRuleValueType>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<ZoneRuleValueType>;
  lt?: InputMaybe<ZoneRuleValueType>;
  lte?: InputMaybe<ZoneRuleValueType>;
  neq?: InputMaybe<ZoneRuleValueType>;
  notILike?: InputMaybe<ZoneRuleValueType>;
  notIn?: InputMaybe<Array<ZoneRuleValueType>>;
  notLike?: InputMaybe<ZoneRuleValueType>;
};

export type ZoneSort = {
  direction: SortDirection;
  field: ZoneSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ZoneSortFields {
  Code = 'code',
  CreatedAt = 'createdAt',
  DeletedAt = 'deletedAt',
  Description = 'description',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  UpdatedByEmail = 'updatedByEmail',
  UpdatedById = 'updatedById',
  WarehouseId = 'warehouseId'
}

export type ZoneUpdateInput = {
  /** Entity ID */
  aisleColumnIds?: InputMaybe<ZoneAddRemoveBlock>;
  /** Entity ID */
  aisleIds?: InputMaybe<ZoneAddRemoveBlock>;
  /** Entity ID */
  areaIds?: InputMaybe<ZoneAddRemoveBlock>;
  /** Entity ID */
  binIds?: InputMaybe<ZoneAddRemoveBlock>;
  /** Entity code */
  code?: InputMaybe<Scalars['String']['input']>;
  /** Description of entity */
  description?: InputMaybe<Scalars['String']['input']>;
  /** ApiDocs */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ZoneUpdateOneInput = {
  /** Entity ID */
  id: Scalars['ID']['input'];
  /** Update Dto */
  update: ZoneUpdateInput;
};

export enum DoorDirection {
  Both = 'both',
  Inbound = 'inbound',
  Outbound = 'outbound'
}
