import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useDeleteDatasetMutation } from '@/graphql/defs/components/modals/__generated__/delete-dataset-modal.generated';
import DataTable from '@components/data-table';
import useCreateDatasetColumns from '@components/data-table/hooks/shared-columns/useCreateDatasetColumns';
import useCreateDataTableColumns from '@components/data-table/hooks/useCreateDataTableColumns';
import TableIds from '@components/data-table/model/TableIds';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { IDatasetDeleteModal } from '@models/modal';

const DeleteDatasetModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { closeModal, setLoading, modal } = useModalContent<IDatasetDeleteModal>();

  const [deleteDataset] = useDeleteDatasetMutation();

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = async () => {
    try {
      await Promise.all(
        modal.datasets.map(({ id }) =>
          deleteDataset({
            variables: {
              input: {
                id,
              },
            },
          }),
        ),
      );
      showMessage({
        type: 'success',
        message: t('modal.dataset.delete.success'),
      });

      closeModal({ bypassLoading: true, success: true });
    } catch (error) {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    }
  };

  const createDatasetColumns = useCreateDatasetColumns({
    removeColumns: ['processType', 'analysisStatus', 'locked', 'updatedByEmail', 'updatedAt'],
  });
  const columns = useCreateDataTableColumns(createDatasetColumns);

  return (
    <>
      <ModalContent>
        <Box marginBottom="25px">{t(`modal.dataset.delete.subtitle`)}</Box>
        <DataTable
          type="data"
          tableId={TableIds.DatasetsUpdate}
          columns={columns}
          data={modal.datasets}
          isDataLoading={false}
          disableExport={true}
        />
      </ModalContent>
      <ModalActions>
        <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          variant="contained"
          color="error"
          data-testid="create-dataset-submit"
          onClick={() => onSubmit()}
        >
          {t('modal.dataset.delete.delete')}
        </ModalButton>
      </ModalActions>
    </>
  );
};

export default DeleteDatasetModal;
