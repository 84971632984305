/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
export type BaseAreaFragment = { __typename?: 'Area', id: string, name?: string | null, code: string, description?: string | null, warehouseId?: string | null, type?: Types.AreaType | null, status?: Types.AreaStatus | null, sapStorageLocationPlant: { __typename?: 'SapStorageLocationPlant', id: string, storageLocation: string } };

export const BaseAreaFragmentDoc = gql`
    fragment BaseArea on Area {
  id
  name
  code
  description
  warehouseId
  type
  status
  sapStorageLocationPlant {
    id
    storageLocation
  }
}
    `;