import {
  useState,
  useContext,
  createContext,
  useEffect,
  Dispatch,
  SetStateAction,
  ReactElement,
  MutableRefObject,
  useRef,
} from 'react';

import { Modal } from '@components/modal';
import IModalDepBucket from '@models/DepBucket';
import { IModal } from '@models/modal';

export type TCloseModal = (opts?: {
  bypassLoading?: boolean;
  success?: boolean;
  bypassGuarded?: boolean;
  handleCloseOpts?: Record<string, any>;
}) => void;
export type THandleBeforeClose = (success: boolean, opts: Record<string, any>) => void;
export type THandleAfterClose = (success: boolean, opts: Record<string, any>) => void;
export type TOpenModalOpts = {
  beforeClose?: THandleBeforeClose;
  afterClose?: THandleAfterClose;
};
export type TOpenModal = (modal: IModal, opts?: TOpenModalOpts) => void;
export type TLoadingType = 'submit' | 'delete' | 'cancel' | 'back' | 'misc' | null;
type TUpdateDepBucket = <K extends keyof IModalDepBucket>(
  depKey: K,
  depValue: IModalDepBucket[K],
) => void;
export interface IModalContentContext<MT = IModal> {
  isOpen: boolean;
  modal: MT | null;
  closeModal: TCloseModal;
  setBeforeClose: Dispatch<SetStateAction<THandleBeforeClose>>;
  setAfterClose: Dispatch<SetStateAction<THandleAfterClose>>;
  isLoading: boolean;
  setLoading: (isLoading: boolean) => void;
  loadingType: TLoadingType;
  setLoadingType: Dispatch<SetStateAction<TLoadingType>>;
  isPreparing: boolean;
  setPreparing: Dispatch<SetStateAction<boolean>>;
  depBucket: IModalDepBucket;
  updateDepBucket: TUpdateDepBucket;
  guardedWarning: boolean;
  setGuardedWarning: Dispatch<SetStateAction<boolean>>;
}
export const ModalContentContext = createContext<IModalContentContext>(null);

interface IModalContentProviderProps {
  setOpenModalRef: MutableRefObject<TOpenModal>;
  children: ReactElement | ReactElement[];
}
const ModalContentProvider = ({ setOpenModalRef, children }: IModalContentProviderProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState<IModal>(null);
  const [handleBeforeClose, setBeforeClose] = useState<THandleBeforeClose>();
  const [handleAfterClose, setAfterClose] = useState<THandleAfterClose>();
  const [isLoading, setLoading] = useState(false);
  const [loadingType, setLoadingType] = useState<TLoadingType>(null);
  const [isPreparing, setPreparing] = useState(false);
  const [depBucket, setDepBucket] = useState<IModalDepBucket>({});
  const [guardedWarning, setGuardedWarning] = useState(false);

  // Used to close the modal but allow onClose to cancel
  let closeTimer = useRef(null);

  const openModal: TOpenModal = (modalVariant: IModal, opts: TOpenModalOpts = {}) => {
    clearTimeout(closeTimer.current);
    setPreparing(false);
    setLoading(false);
    setIsOpen(true);
    setModal(modalVariant);
    if (!!opts.beforeClose) setBeforeClose(() => opts.beforeClose);
    if (!!opts.afterClose) setAfterClose(() => opts.afterClose);
  };

  const closeModal: TCloseModal = (
    { bypassLoading = false, success = false, bypassGuarded = false, handleCloseOpts = {} } = {
      bypassLoading: false,
      success: false,
      bypassGuarded: false,
      handleCloseOpts: {},
    },
  ) => {
    if (bypassLoading || !isLoading) {
      // Allows us to cancel the close with an beforeClose handler that uses `openModal`
      closeTimer.current = setTimeout(() => {
        setIsOpen(false);
        setTimeout(() => {
          if (handleAfterClose) {
            handleAfterClose(success, handleCloseOpts);
            setAfterClose(null);
          }
          setModal(null);
        }, 100);
      }, 50);

      if (!!handleBeforeClose) {
        handleBeforeClose(success, handleCloseOpts);
        setBeforeClose(null);
      } else {
        // No handleOnClose, modals are all closed, empty depBucket
        setDepBucket({});
      }
    }
  };

  const updateLoading = (_isLoading: boolean) => {
    setLoading(_isLoading);
    if (!_isLoading) setLoadingType(null);
  };

  const updateDepBucket = function <K extends keyof IModalDepBucket>(
    depKey: K,
    depValue: IModalDepBucket[K],
  ) {
    const addition: IModalDepBucket = {};
    addition[depKey] = depValue;

    setDepBucket(Object.assign({ ...depBucket }, { ...addition }));
  };

  useEffect(() => {
    setOpenModalRef.current = openModal;
  }, [openModal]);

  useEffect(() => {
    return () => clearTimeout(closeTimer.current);
  }, []);

  return (
    <ModalContentContext.Provider
      value={{
        isOpen,
        modal,
        closeModal,
        setBeforeClose,
        setAfterClose,
        isLoading,
        setLoading: updateLoading,
        loadingType,
        setLoadingType,
        isPreparing,
        setPreparing,
        depBucket,
        updateDepBucket,
        guardedWarning,
        setGuardedWarning,
      }}
    >
      {children}
      <Modal />
    </ModalContentContext.Provider>
  );
};

export default ModalContentProvider;

export function useModalContent<MT = IModal>() {
  const ctx = useContext(ModalContentContext) as IModalContentContext<MT>;
  if (ctx === null) {
    throw new Error('useModalContent must be used within ModalContentProvider');
  }
  return ctx;
}
