import { gql } from '@apollo/client';

import { DestinationBinsForBinToBinQuery } from '@/graphql/defs/components/modals/__generated__/bin-to-bin-movement-modal.generated';

gql`
  query DestinationBinsForBinToBin($warehouseId: ID!) {
    destinationBinsForTaskType(
      taskTypeCode: "TSK-01"
      filter: { warehouseId: { eq: $warehouseId } }
      sorting: { field: code, direction: ASC }
    ) {
      destinationBins {
        id
        code
        areaId
        areaCode
      }
    }
  }

  mutation CreateOneBinToBinMovementTask($input: CreateBinToBinTaskInputDto!) {
    createOneBinToBinMovementTask(input: $input) {
      id
      code
      status
      product {
        id
        code
      }
      destinationBin {
        id
        code
      }
    }
  }
`;

export const DestinationBinsForBinToBin_defaultData: DestinationBinsForBinToBinQuery = {
  __typename: 'Query',
  destinationBinsForTaskType: {
    __typename: 'TaskTypeBinStatusDestinationBins',
    destinationBins: [],
  },
};
