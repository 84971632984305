import { SortDirection, SortNulls } from '@/graphql/types.generated';
import {
  DefaultFieldNames,
  IAPISort,
  IDataTableSort,
} from '@components/data-table/model/data-table';

function formatDataTableSorting<FieldNames = DefaultFieldNames>(
  sorting: IDataTableSort<FieldNames>[],
): IAPISort<FieldNames>[] {
  return sorting.map<IAPISort<FieldNames>>((s) => ({
    field: s.id,
    direction: s.desc ? SortDirection.Desc : SortDirection.Asc,
    nulls: s.desc ? SortNulls.NullsFirst : SortNulls.NullsLast,
  }));
}

export default formatDataTableSorting;
