import Collapse, { ICollapse } from '@components/collapse';
import { useQueryBasedDataTable } from '@components/data-table/context/QueryBasedProvider';

interface IDataTableQueryBasedCollapse extends ICollapse {}
const DataTableQueryBasedCollapse = ({
  children,
  ...collapseProps
}: IDataTableQueryBasedCollapse) => {
  const { setSuppressDataFetch } = useQueryBasedDataTable();

  const onOpen = () => {
    setSuppressDataFetch(false);
  };
  const onClose = () => {
    setSuppressDataFetch(true);
  };

  return (
    <Collapse {...collapseProps} onOpen={onOpen} onClose={onClose}>
      {children}
    </Collapse>
  );
};

export default DataTableQueryBasedCollapse;
