import { Grid } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ModalActions } from '../modal-actions';
import { ModalButton } from '../modal-button';
import { ModalContent } from '../modal-content';
import ModalForm from '../modal-form';

import { ControlledAutocomplete } from './user-group-modal-base';

import {
  useUpdateUsersForUserGroupMutation,
  useUserGroupMappingsQuery,
} from '@/graphql/defs/components/modals/__generated__/user-group-users-modal.generated';
import { useListUsersLazyQuery } from '@/graphql/defs/list/__generated__/list-users.generated';
import { UserGroupMappingStatus } from '@/graphql/types.generated';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import FormValues from '@models/FormValues';
import { IUserGroupUsersEditModal } from '@models/modal';

interface IUser {
  name: string;
  id: string;
}

const EditUserGroupUsersModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const modalContext = useModalContent<IUserGroupUsersEditModal>();
  const { modal, closeModal, setLoading } = modalContext;
  const [defaultUsers, setDefaultUsers] = useState([]);
  const [users, setUsers] = useState([]);

  const formReturn = useForm<FormValues<{ users: IUser[] }>>({
    defaultValues: {
      users: [],
    },
  });

  const { setPreparing, isPreparing } = modalContext;
  const [getUsers] = useListUsersLazyQuery();

  useUserGroupMappingsQuery({
    variables: {
      filter: {
        userGroupId: {
          eq: modal.userGroupId,
        },
      },
    },
    onCompleted: async ({ userGroupMappings: { nodes } }) => {
      const [
        {
          data: {
            listUsers: { users: mappedUsers },
          },
        },
        {
          data: {
            listUsers: { users: allUsers },
          },
        },
      ] = await Promise.all([
        nodes.length
          ? getUsers({
              variables: {
                filter: {
                  id: {
                    in: nodes.map(({ userId }) => userId),
                  },
                },
              },
            })
          : Promise.resolve({ data: { listUsers: { users: [] } } }),
        getUsers(),
      ]);
      const mapNames = ({ id, firstName, lastName }) => ({ id, name: `${firstName} ${lastName}` });
      setDefaultUsers(mappedUsers.map(mapNames));
      setUsers(allUsers.map(mapNames));
      setPreparing(false);
    },
  });
  const { control, handleSubmit } = formReturn;

  const [updateUserGroupUsers] = useUpdateUsersForUserGroupMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t('userGroupUserActions.updateUsersSuccess', { name: modal.userGroupName }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onSubmit = async (fields: { users: IUser[] }) => {
    // collect all mappings that need to be turned on
    const userGroupMappingsOn = fields.users.reduce((acc, { id }) => {
      if (!defaultUsers.find((user) => user.id === id)) {
        acc.push({
          status: UserGroupMappingStatus.On,
          userGroupId: modal.userGroupId,
          userId: id,
        });
      }
      return acc;
    }, []);

    // add mappings that need to be turned off
    const userGroupMappings = defaultUsers.reduce((acc, { id }) => {
      if (!fields.users.find((user) => user.id === id)) {
        acc.push({
          status: UserGroupMappingStatus.Off,
          userGroupId: modal.userGroupId,
          userId: id,
        });
      }
      return acc;
    }, userGroupMappingsOn);

    await updateUserGroupUsers({
      variables: {
        input: {
          userGroupMappings,
        },
      },
    });
  };

  const onCancel = () => {
    closeModal();
  };

  return isPreparing ? null : (
    <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formReturn}>
      <ModalContent sx={{ width: '800px' }}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <ControlledAutocomplete
              control={control}
              objOptions={users}
              defaultValue={defaultUsers}
              name={t('common.users')}
              rules={{
                required: t('form.validation.requiredField', { field: t('common.users') }),
                minLength: 1,
              }}
            />
          </Grid>
        </Grid>
      </ModalContent>
      <ModalActions>
        <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
          {t('common.cancel')}
        </ModalButton>
        <ModalButton variant="contained" color="primary" actionType="submit">
          {t('common.save')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default EditUserGroupUsersModal;
