import { gql } from '@apollo/client';

import { ListAllTeamsQuery } from '@/graphql/defs/list/__generated__/list-teams.generated';

gql`
  fragment ListedTeam on Team {
    id
    name
  }

  query ListAllTeams($warehouseId: ID!) {
    getTeams: teams(
      filter: { warehouseId: { eq: $warehouseId } }
      sorting: { field: name, direction: ASC }
    ) {
      teams: nodes {
        ...ListedTeam
      }
    }
  }
`;

export const ListAllTeams_defaultData: ListAllTeamsQuery = {
  __typename: 'Query',
  getTeams: {
    __typename: 'TeamOffsetConnection',
    teams: [],
  },
};
