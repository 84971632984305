import { Box, Step, StepLabel, Stepper } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateRulesetMutation } from '@/graphql/defs/components/modals/__generated__/create-or-copy-ruleset-modal.generated';
import { SlottingEquipmentInput, SlottingWorkerInput } from '@/graphql/types.generated';
import { CenteredLoadingIndicator } from '@components/loading-indicator';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import Step1 from '@components/modal/ruleset/steps/step-1';
import Step2 from '@components/modal/ruleset/steps/step-2';
import Step3 from '@components/modal/ruleset/steps/step-3';
import Step4 from '@components/modal/ruleset/steps/step-4';
import Step5 from '@components/modal/ruleset/steps/step-5';
import Step6 from '@components/modal/ruleset/steps/step-6';
import Step7 from '@components/modal/ruleset/steps/step-7';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import useRulesetForm, {
  FixedBins,
  IRuleset,
  RULESET_STEPS,
  UseAbcAnalysis,
  WarehouseDefault,
} from '@hooks/form/ruleset/useRulesetForm';
import { IRulesetCreateOrCopyModal } from '@models/modal';

const CreateOrCopyRulesetModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, isPreparing, setPreparing, closeModal, setLoading } =
    useModalContent<IRulesetCreateOrCopyModal>();

  const [activeStep, setActiveStep] = useState(1);
  const [activeZone, setActiveZone] = useState();
  const [saving, setSaving] = useState(false);

  const formMethods = useRulesetForm({
    rulesetId: modal?.rulesetId || null,
    setPreparing,
  });
  const { control, handleSubmit, clearErrors, getValues, setValue } = formMethods;

  const [createRuleset] = useCreateRulesetMutation({
    onCompleted: ({ ruleset: { id, name } }) => {
      showMessage({
        type: 'success',
        message: t('modal.ruleset.create.success', { name }),
      });

      closeModal({
        bypassLoading: true,
        success: true,
        bypassGuarded: true,
        handleCloseOpts: { rulesetId: id },
      });
    },
    onError: (error) => {
      setSaving(false);
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const validateStep = async () => {
    formMethods.clearErrors();
    switch (activeStep) {
      case 1:
        return formMethods.trigger(['name', 'forecasting', 'maxMovements']);
      case 2:
        return formMethods.trigger(['abcAnalysis.criteria', 'abcAnalysis.indicatorPercentages']);

      case 4:
        return formMethods.trigger(['abcAnalysis.criteria', 'abcAnalysis.indicatorPercentages']);
      case 3: // no validation needed
      case 5:
      case 6:
      case 7:
        return true;
    }
  };
  const onNext = async () => {
    const ready = await validateStep();
    if (ready) setActiveStep(activeStep + 1);
  };
  const onBack = () => {
    setActiveStep(activeStep - 1);
  };

  const onSubmit = async (fields: IRuleset) => {
    setSaving(true);
    const slottingRuleset = {
      ...fields,
      maxMovements: Number(fields.maxMovements),
      abcAnalysis: fields.useAbcAnalysis === UseAbcAnalysis.skip ? null : fields.abcAnalysis,
      equipment: fields.equipment.map(
        ({ id, quantity }) =>
          ({
            id,
            quantity,
          } as SlottingEquipmentInput),
      ),
      workers: fields.workers.map(
        ({ id, quantity }) =>
          ({
            id,
            quantity,
          } as SlottingWorkerInput),
      ),
      zones: fields.zones.map(
        ({ zoneId, useWarehouseDefaultEnum, fixedBinsEnum, weights, restrictions }) => {
          const useDefault = useWarehouseDefaultEnum === WarehouseDefault.warehouse;
          return {
            useWarehouseDefault: useDefault,
            fixedBins: useDefault ? false : fixedBinsEnum === FixedBins.yes,
            zoneId,
            weights: useDefault ? fields.weights : weights,
            restrictions: useDefault ? fields.restrictions : restrictions,
            maxFixedBinsPerProduct: 1,
          };
        },
      ),
      createdByUserId: '',
    };
    delete slottingRuleset.useAbcAnalysis;
    void createRuleset({
      variables: {
        input: {
          slottingRuleset,
        },
      },
    });
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent sx={{ maxWidth: '97vw', width: '900px', overflowX: 'hidden' }}>
          <Box sx={{ flex: 1, height: 100, margin: '0px -10px' }}>
            <Stepper activeStep={activeStep - 1} alternativeLabel>
              {RULESET_STEPS.map((label, key) => (
                <Step key={key}>
                  <StepLabel>{t(`modal.ruleset.create.steps.${label}`)}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box sx={{ padding: '0 30px', maxWidth: 1000 }}>
            {activeZone ? null : (
              <>
                <Box sx={{ fontSize: '24px', fontWeight: 700 }}>
                  {t(activeStep < 8 ? 'modal.ruleset.create.title' : 'modal.ruleset.review.title')}
                </Box>
                {activeStep === 1 && (
                  <Box sx={{ fontSize: '16px', fontWeight: 400, margin: '10px 0px 40px 15px' }}>
                    {t('modal.ruleset.create.subtitle')}
                  </Box>
                )}
              </>
            )}
            {saving && (
              <Box
                sx={{
                  height: 500,
                  fontSize: 18,
                  fontWeight: 500,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <Box sx={{ marginBottom: '50px' }}>
                  {t('modal.ruleset.create.checkingRulesetsInBackground')}
                </Box>
                <CenteredLoadingIndicator />
              </Box>
            )}
            <Step1
              control={control}
              review={!saving && activeStep === 8}
              active={activeStep === 1}
            />
            <Step2
              control={control}
              review={!saving && activeStep === 8}
              active={activeStep === 2}
              clearErrors={clearErrors}
            />
            <Step3
              control={control}
              review={!saving && activeStep === 8}
              active={activeStep === 3}
            />
            <Step4
              control={control}
              review={!saving && activeStep === 8}
              active={activeStep === 4}
              clearErrors={clearErrors}
              getValues={getValues}
              activeZone={activeZone}
              setActiveZone={setActiveZone}
              setValue={setValue}
            />
            <Step5
              control={control}
              review={!saving && activeStep === 8}
              active={activeStep === 5}
            />
            <Step6
              control={control}
              review={!saving && activeStep === 8}
              active={activeStep === 6}
            />
            <Step7
              control={control}
              review={!saving && activeStep === 8}
              active={activeStep === 7}
            />
          </Box>
        </ModalContent>
        {activeZone ? null : (
          <ModalActions>
            <ModalButton
              sx={{ visibility: activeStep === 1 ? 'hidden' : 'visible' }}
              onClick={onBack}
              variant="outlined"
              color="primary"
              actionType="cancel"
            >
              {t('common.back')}
            </ModalButton>
            <ModalButton
              sx={activeStep !== 8 ? { visibility: 'hidden', position: 'fixed' } : {}}
              id="submit"
              variant="contained"
              color="primary"
              data-testid="create-ruleset-submit"
              actionType="submit"
            >
              {t('modal.ruleset.create.saveNewRuleset')}
            </ModalButton>
            <ModalButton
              sx={activeStep === 8 ? { visibility: 'hidden', position: 'fixed' } : {}}
              id="next"
              variant="contained"
              color="primary"
              data-testid="create-ruleset-next"
              onClick={onNext}
            >
              {t('common.next')}
            </ModalButton>
          </ModalActions>
        )}
      </ModalForm>
    )
  );
};

export default CreateOrCopyRulesetModal;
