import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CompanyFragment } from '@/graphql/defs/pages/__generated__/companies.generated';
import {
  contactInfoDefault,
  FieldBlockContactInfo,
  FieldBlockDisplayPreferences,
  IVStep,
  IVStepCBArgs,
  useContactInformationStep,
  useDisplayPreferencesStep,
  VerticalStepper,
} from '@components/form-util';
import { ModalContent } from '@components/modal';
import { FieldBlockCompanyDetails, useCompanyDetailsStep } from '@components/modal/company/form';
import { IDisplayPreferenceFields } from '@components/modal/display-preferences/display-preferences-modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import FormValues from '@models/FormValues';
import { ICompanyCreateModal } from '@models/modal';

const CompanyCreateModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal, setAfterClose } = useModalContent<ICompanyCreateModal>();

  const [company, setCompany] = useState<CompanyFragment>();

  useEffect(() => {
    if (company) {
      setAfterClose(() => {
        modal.setSelectedCompany(company);
      });
    } else {
      setAfterClose(null);
    }
  }, [company]);

  const onDepMapUpdate = (args: IVStepCBArgs) => {
    const { company: depCompany } = args.depMap;
    if (depCompany?.id) setCompany(depCompany);
  };

  const onComplete = () => {
    closeModal({ bypassLoading: true, success: true });
  };

  const setParentType = (args: IVStepCBArgs) => {
    const { updateDepMap } = args;
    updateDepMap({ parentType: 'company' });
  };

  const formSteps: IVStep[] = [
    {
      title: t('field-blocks.companyDetails.title'),
      onSubmit: useCompanyDetailsStep,
      stepContent: FieldBlockCompanyDetails,
      formHooks: useForm(),
      focusOnLoad: true,
    },
    {
      title: t('companies.contactInformation.title'),
      onBack: true,
      onSubmit: useContactInformationStep,
      stepContent: FieldBlockContactInfo,
      formHooks: useForm({ defaultValues: contactInfoDefault }),
      focusOnLoad: true,
    },
    {
      title: t('companies.displayPreferences.title'),
      onBack: true,
      onSubmit: useDisplayPreferencesStep,
      stepContent: FieldBlockDisplayPreferences,
      formHooks: useForm<FormValues<IDisplayPreferenceFields>>({
        defaultValues: {
          dateFormat: '',
          timeFormat: '',
          language: '',
          currency: '',
          timezone: '',
        },
      }),
      focusOnLoad: true,
    },
  ];

  return (
    <ModalContent>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <VerticalStepper
            steps={formSteps}
            onDepMapUpdate={onDepMapUpdate}
            onComplete={onComplete}
            beforeStart={setParentType}
          />
        </Grid>
      </Grid>
    </ModalContent>
  );
};

export default CompanyCreateModal;
