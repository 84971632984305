import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { EditableCell } from '@components/editable-table/EditableTable';

interface INoneditableQty extends EditableCell {
  item: Record<string, any> & {
    availableQuantity?: string;
    quantity?: string;
  };
}
const NoneditableQty: React.FC<INoneditableQty> = ({ item, createUID }) => {
  const { control } = useFormContext();

  const itemUID = createUID(item);

  const {
    field: { value: quantity },
  } = useController({
    name: `${itemUID}-qty`,
    control,
    defaultValue: item.availableQuantity || item.quantity,
  });

  return quantity;
};
export default NoneditableQty;
