/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SlottingMovementsQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.SlottingMovementFilter>;
  sorting?: Types.InputMaybe<Array<Types.SlottingMovementSort> | Types.SlottingMovementSort>;
}>;


export type SlottingMovementsQuery = { __typename?: 'Query', listSlottingMovements: { __typename?: 'SlottingMovementOffsetConnection', slottingMovements: Array<{ __typename?: 'SlottingMovement', id?: string | null, run_id?: string | null, destination_bin?: string | null, destination_column?: string | null, material: string, material_description?: string | null, source_bin?: string | null, source_column?: string | null, current_max_quantity?: number | null, proposed_max_quantity?: number | null, current_min_quantity?: number | null, proposed_min_quantity?: number | null, distance_saved?: number | null, money_saved?: number | null, importance?: number | null, process_dt: string }> } };


export const SlottingMovementsDocument = gql`
    query SlottingMovements($paging: OffsetPaging, $filter: SlottingMovementFilter, $sorting: [SlottingMovementSort!]) {
  listSlottingMovements: slottingMovements(
    paging: $paging
    filter: $filter
    sorting: $sorting
  ) {
    slottingMovements: nodes {
      id
      run_id
      destination_bin
      destination_column
      material
      material_description
      source_bin
      source_column
      current_max_quantity
      proposed_max_quantity
      current_min_quantity
      proposed_min_quantity
      distance_saved
      money_saved
      importance
      process_dt
    }
  }
}
    `;

/**
 * __useSlottingMovementsQuery__
 *
 * To run a query within a React component, call `useSlottingMovementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlottingMovementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlottingMovementsQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useSlottingMovementsQuery(baseOptions?: Apollo.QueryHookOptions<SlottingMovementsQuery, SlottingMovementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SlottingMovementsQuery, SlottingMovementsQueryVariables>(SlottingMovementsDocument, options);
      }
export function useSlottingMovementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SlottingMovementsQuery, SlottingMovementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SlottingMovementsQuery, SlottingMovementsQueryVariables>(SlottingMovementsDocument, options);
        }
export type SlottingMovementsQueryHookResult = ReturnType<typeof useSlottingMovementsQuery>;
export type SlottingMovementsLazyQueryHookResult = ReturnType<typeof useSlottingMovementsLazyQuery>;
export type SlottingMovementsQueryResult = Apollo.QueryResult<SlottingMovementsQuery, SlottingMovementsQueryVariables>;