import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const Section = ({
  children,
  section,
  noSubtitle = false,
  sx = {},
}: {
  children: React.ReactNode;
  section:
    | 'maxMovements'
    | 'naming'
    | 'forecasting'
    | 'abcAnalysis'
    | 'warehouseRestrictions'
    | 'zoneSpecificRestrictions'
    | 'zoneRulesAndWeights'
    | 'restrictions'
    | 'weights'
    | 'fixedBins'
    | 'equipment'
    | 'workers'
    | 'warehouseWeights';
  noSubtitle?: boolean;
  sx?: Record<any, any>;
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'modal.ruleset.create' });
  return (
    <Box sx={{ marginLeft: '25px', ...sx }}>
      <Box sx={{ marginBottom: '30px' }}>
        <Box sx={{ fontSize: '18px', fontWeight: 600 }}>{t(`${section}.title`)}</Box>
        {noSubtitle ? null : (
          <Box sx={{ fontSize: '16px', fontWeight: 400 }}>{t(`${section}.subtitle`)}</Box>
        )}
      </Box>
      <Box sx={{ margin: '0px 24px' }}>{children}</Box>
    </Box>
  );
};

export const StepWrapper = ({ children, active, sx = {}, review }) => (
  <Box
    sx={{
      position: 'relative',
      display: 'flex',
      margin: '15px 20px 0px 10px',
      flexDirection: 'column',
      ...(active || review ? {} : { visibility: 'hidden', position: 'fixed' }),
      ...sx,
    }}
  >
    {children}
    {review ? (
      <Box
        onClick={(e) => {
          e.stopPropagation();
        }}
        sx={{
          zIndex: 5,
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      />
    ) : null}
  </Box>
);

export const Separator = () => (
  <Box sx={{ margin: '15px 0', height: '1px', width: '100%', backgroundColor: '#D8E0E5' }} />
);
