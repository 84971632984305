/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BlockUnblockLicensePlatesMutationVariables = Types.Exact<{
  input: Types.LicensePlateStatusMappingInputDto;
}>;


export type BlockUnblockLicensePlatesMutation = { __typename?: 'Mutation', updateManyLicensePlateStatusMappings: string };


export const BlockUnblockLicensePlatesDocument = gql`
    mutation BlockUnblockLicensePlates($input: LicensePlateStatusMappingInputDto!) {
  updateManyLicensePlateStatusMappings(input: $input)
}
    `;
export type BlockUnblockLicensePlatesMutationFn = Apollo.MutationFunction<BlockUnblockLicensePlatesMutation, BlockUnblockLicensePlatesMutationVariables>;

/**
 * __useBlockUnblockLicensePlatesMutation__
 *
 * To run a mutation, you first call `useBlockUnblockLicensePlatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUnblockLicensePlatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUnblockLicensePlatesMutation, { data, loading, error }] = useBlockUnblockLicensePlatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockUnblockLicensePlatesMutation(baseOptions?: Apollo.MutationHookOptions<BlockUnblockLicensePlatesMutation, BlockUnblockLicensePlatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlockUnblockLicensePlatesMutation, BlockUnblockLicensePlatesMutationVariables>(BlockUnblockLicensePlatesDocument, options);
      }
export type BlockUnblockLicensePlatesMutationHookResult = ReturnType<typeof useBlockUnblockLicensePlatesMutation>;
export type BlockUnblockLicensePlatesMutationResult = Apollo.MutationResult<BlockUnblockLicensePlatesMutation>;
export type BlockUnblockLicensePlatesMutationOptions = Apollo.BaseMutationOptions<BlockUnblockLicensePlatesMutation, BlockUnblockLicensePlatesMutationVariables>;