import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useModalToggle } from '@context/modal/ModalToggleProvider';
import { getTransComponents } from '@lib/translation';
import { ITableLayoutOverwriteModal, ModalTypes } from '@models/modal';

const OverwriteTableLayoutModal = () => {
  const { t } = useTranslation('components');
  const { openModal } = useModalToggle();
  const { modal, closeModal } = useModalContent<ITableLayoutOverwriteModal>();

  const openSaveNewLayoutModal = () => {
    openModal({
      type: ModalTypes.tableLayoutCreate,
      tableId: modal.tableId,
      handleCreate: modal.handleCreate,
    });
  };

  const onSubmit = () => {
    modal.handleOverwrite(() => {
      closeModal({ bypassLoading: true, success: true });
    });
  };

  return (
    <>
      <ModalContent>
        <Box>
          <Trans
            ns="components"
            i18nKey="modal.tableLayout.overwrite.text"
            components={getTransComponents(['bold'])}
            values={{
              layoutName: modal.layout?.label || '',
            }}
          />
        </Box>
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
          sx={{ marginRight: 'auto' }}
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          onClick={() => openSaveNewLayoutModal()}
          variant="outlined"
          color="primary"
          actionType="misc"
        >
          {t('common.newLayout')}
        </ModalButton>
        <ModalButton
          onClick={() => onSubmit()}
          variant="contained"
          color="primary"
          actionType="submit"
        >
          {t('common.overwrite')}
        </ModalButton>
      </ModalActions>
    </>
  );
};

export default OverwriteTableLayoutModal;
