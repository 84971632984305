import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useCompletePickToStagingTaskMutation,
  useGetPickToStagingFulfillmentItemQuery,
} from '@/graphql/defs/components/modals/__generated__/pick-to-staging-complete-modal.generated';
import { GetPickToStagingFulfillmentItem_defaultData } from '@/graphql/defs/components/modals/pick-to-staging-complete-modal';
import { IColumn, JustifiedColumns } from '@components/justified-columns';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { IPickToStagingCompleteModal } from '@models/modal';

const PickToStagingCompleteModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal, setLoading, isPreparing, setPreparing } =
    useModalContent<IPickToStagingCompleteModal>();

  const [completeTask] = useCompletePickToStagingTaskMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t('modal.fulfillment.pickToStagingComplete.success', {
          taskCode: modal.task?.taskCode,
        }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({ type: 'error', message: error.message });
    },
  });

  const { data: fulfillmentItemData } = useGetPickToStagingFulfillmentItemQuery({
    variables: {
      fulfillmentItemId: modal?.task?.fulfillmentItemId,
    },
    onCompleted: () => {
      setPreparing(false);
    },
    onError: (error) => {
      setPreparing(false);
      showMessage({ type: 'error', message: error.message });
    },
  });
  const fulfillmentItem = useMemo(() => {
    const {
      viewFulfillmentItems: { nodes },
    } = fulfillmentItemData || GetPickToStagingFulfillmentItem_defaultData;
    return nodes[0] || null;
  }, [fulfillmentItemData]);

  const fulfillmentItemDetails = useMemo<IColumn[]>(
    () => [
      {
        labelWidth: '150px',
        lineItems: [
          {
            label: t('common.customer'),
            value: modal.customerName,
            removeBold: true,
            boldLabel: true,
          },
          {
            label: t('common.product'),
            value: fulfillmentItem?.productCode,
            removeBold: true,
            boldLabel: true,
          },
          {
            label: t('common.delivery', { count: 1 }),
            value: modal.fulfillmentCode,
            removeBold: true,
            boldLabel: true,
          },
          {
            label: t('common.item'),
            value: fulfillmentItem?.item,
            removeBold: true,
            boldLabel: true,
          },
          {
            label: t('common.sourceBin'),
            value: modal.task?.sourceBinCode,
            boldLabel: true,
          },
        ],
      },
      {
        labelWidth: '150px',
        lineItems: [
          {
            label: t('common.warehouse'),
            value: modal.task?.warehouseCode,
            removeBold: true,
            boldLabel: true,
          },
          {
            label: t('common.description'),
            value: fulfillmentItem?.productDescription,
            removeBold: true,
            boldLabel: true,
          },
          {
            label: t('common.stockStatus'),
            value: modal?.task?.sourceStatus,
            removeBold: true,
            boldLabel: true,
          },
          {
            label: t('common.uom'),
            value: fulfillmentItem?.unitOfMeasure,
            removeBold: true,
            boldLabel: true,
          },
          {
            label: t('common.destinationBin'),
            value: modal.task?.destinationBinCode,
            boldLabel: true,
          },
        ],
      },
    ],
    [fulfillmentItem],
  );

  const onCancel = () => {
    closeModal();
  };

  const onSubmit = () => {
    void completeTask({
      variables: {
        warehouseId: modal?.task?.warehouseId,
        completePickToStagingInput: [
          {
            taskId: modal?.task?.id,
            sourceBinId: modal?.task?.sourceBinId,
            licensePlateId: modal?.task?.sourceLicensePlateId,
            sourceLotId: modal?.task?.sourceLotId,
            quantity: modal?.task?.quantity,
            completedInUnitOfMeasureId: modal?.task?.unitOfMeasureId,
          },
        ],
      },
    });
  };

  return (
    !isPreparing && (
      <>
        <ModalContent>
          {fulfillmentItem?.licensePlateId && (
            <>
              <Box display="flex" maxWidth="100%">
                <Typography fontWeight={700} width="150px" flexShrink={0}>
                  {t('common.licensePlate')}
                  {': '}
                </Typography>
                <Typography
                  color="primary"
                  fontWeight={700}
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {fulfillmentItem?.licensePlateCode}
                </Typography>
              </Box>
              <Box display="flex" sx={{ marginBottom: '20px' }} maxWidth="100%">
                <Typography fontWeight={700} width="150px" flexShrink={0}>
                  {t('common.description')}
                  {': '}
                </Typography>
                <Typography whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                  {fulfillmentItem?.licensePlateDescription}
                </Typography>
              </Box>
            </>
          )}
          <JustifiedColumns columns={fulfillmentItemDetails} />
        </ModalContent>
        <ModalActions>
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton onClick={onSubmit} variant="contained" color="primary" actionType="submit">
            {t('common.completeTask')}
          </ModalButton>
        </ModalActions>
      </>
    )
  );
};

export default PickToStagingCompleteModal;
