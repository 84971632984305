/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UoMConversionsFragment = { __typename?: 'UnitOfMeasureConversion', quantity: string, unitOfMeasure: { __typename?: 'UnitOfMeasureConversionItemResource', id: string, code: string, label?: string | null } };

export type GetProductUoMConversionsQueryVariables = Types.Exact<{
  quantity: Types.Scalars['String']['input'];
  unitOfMeasure: Types.Scalars['ID']['input'];
}>;


export type GetProductUoMConversionsQuery = { __typename?: 'Query', convertQuantityToAllUoM: { __typename?: 'UnitOfMeasureConversionResource', unitOfMeasureConversions: Array<{ __typename?: 'UnitOfMeasureConversion', quantity: string, unitOfMeasure: { __typename?: 'UnitOfMeasureConversionItemResource', id: string, code: string, label?: string | null } }> } };

export const UoMConversionsFragmentDoc = gql`
    fragment UoMConversions on UnitOfMeasureConversion {
  quantity
  unitOfMeasure {
    id
    code
    label
  }
}
    `;
export const GetProductUoMConversionsDocument = gql`
    query GetProductUoMConversions($quantity: String!, $unitOfMeasure: ID!) {
  convertQuantityToAllUoM(quantity: $quantity, unitOfMeasureId: $unitOfMeasure) {
    unitOfMeasureConversions {
      ...UoMConversions
    }
  }
}
    ${UoMConversionsFragmentDoc}`;

/**
 * __useGetProductUoMConversionsQuery__
 *
 * To run a query within a React component, call `useGetProductUoMConversionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductUoMConversionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductUoMConversionsQuery({
 *   variables: {
 *      quantity: // value for 'quantity'
 *      unitOfMeasure: // value for 'unitOfMeasure'
 *   },
 * });
 */
export function useGetProductUoMConversionsQuery(baseOptions: Apollo.QueryHookOptions<GetProductUoMConversionsQuery, GetProductUoMConversionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductUoMConversionsQuery, GetProductUoMConversionsQueryVariables>(GetProductUoMConversionsDocument, options);
      }
export function useGetProductUoMConversionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductUoMConversionsQuery, GetProductUoMConversionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductUoMConversionsQuery, GetProductUoMConversionsQueryVariables>(GetProductUoMConversionsDocument, options);
        }
export type GetProductUoMConversionsQueryHookResult = ReturnType<typeof useGetProductUoMConversionsQuery>;
export type GetProductUoMConversionsLazyQueryHookResult = ReturnType<typeof useGetProductUoMConversionsLazyQuery>;
export type GetProductUoMConversionsQueryResult = Apollo.QueryResult<GetProductUoMConversionsQuery, GetProductUoMConversionsQueryVariables>;