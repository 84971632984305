/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateLicensePlateStockStatusMutationVariables = Types.Exact<{
  licensePlateId: Types.Scalars['ID']['input'];
  stockStatusId: Types.Scalars['ID']['input'];
}>;


export type UpdateLicensePlateStockStatusMutation = { __typename?: 'Mutation', createOneTaskLicensePlateStockStatus: { __typename?: 'Task', id: string, code: string } };


export const UpdateLicensePlateStockStatusDocument = gql`
    mutation UpdateLicensePlateStockStatus($licensePlateId: ID!, $stockStatusId: ID!) {
  createOneTaskLicensePlateStockStatus(
    input: {taskLicensePlateStockStatus: {licensePlateId: $licensePlateId, stockStatusId: $stockStatusId, autocomplete: true}}
  ) {
    id
    code
  }
}
    `;
export type UpdateLicensePlateStockStatusMutationFn = Apollo.MutationFunction<UpdateLicensePlateStockStatusMutation, UpdateLicensePlateStockStatusMutationVariables>;

/**
 * __useUpdateLicensePlateStockStatusMutation__
 *
 * To run a mutation, you first call `useUpdateLicensePlateStockStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLicensePlateStockStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLicensePlateStockStatusMutation, { data, loading, error }] = useUpdateLicensePlateStockStatusMutation({
 *   variables: {
 *      licensePlateId: // value for 'licensePlateId'
 *      stockStatusId: // value for 'stockStatusId'
 *   },
 * });
 */
export function useUpdateLicensePlateStockStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLicensePlateStockStatusMutation, UpdateLicensePlateStockStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLicensePlateStockStatusMutation, UpdateLicensePlateStockStatusMutationVariables>(UpdateLicensePlateStockStatusDocument, options);
      }
export type UpdateLicensePlateStockStatusMutationHookResult = ReturnType<typeof useUpdateLicensePlateStockStatusMutation>;
export type UpdateLicensePlateStockStatusMutationResult = Apollo.MutationResult<UpdateLicensePlateStockStatusMutation>;
export type UpdateLicensePlateStockStatusMutationOptions = Apollo.BaseMutationOptions<UpdateLicensePlateStockStatusMutation, UpdateLicensePlateStockStatusMutationVariables>;