// General Links
export const BASE_URL = '/';
export const DASHBOARD = BASE_URL;
export const INVENTORY = '/inventory';
export const PROFILE = '/profile';

// Analytics Links
export const ANALYTICS = '/analytics';
export const ANALYTICS_DASHBOARD = '/analytics/dashboard';
export const ANALYTICS_OPS_OVERVIEW = '/analytics/ops-overview';
export const ANALYTICS_RESOURCE_TASK = '/analytics/resource-task';
export const ANALYTICS_SEARCH = '/analytics/search';

// Auth Links
export const AUTH_CALLBACK = '/auth/callback';
export const LOGIN = '/auth/login';
export const SUPPORT_LOGIN = '/auth/support/login';
export const LOGOUT = '/auth/logout';
export const MAP_SAP_TOKEN = '/auth/sap/map-token';

// Slotting Links
export const SLOTTING_DASHBOARD = '/slotting';
export const SLOTTING_DATASET = '/slotting/dataset';
export const SLOTTING_DATASET_RUN_SUMMARIES = '/slotting/dataset/:id/runs';
export const SLOTTING_DATASETS = '/slotting/datasets';
export const SLOTTING_RULESET = '/slotting/ruleset';
export const SLOTTING_RULESETS = '/slotting/rulesets';
export const SLOTTING_RUNS = '/slotting/runs';
export const SLOTTING_RUN = '/slotting/run';
export const SLOTTING_DEPLOYED_RUN = 'slotting/run/deployed';

// Entity Detail Links
export const BIN = '/bin';
export const BIN_STORAGE_TYPE = '/bin-storage-type';
export const BUSINESS_PARTNER = '/business-partner';
export const LICENSE_PLATE = '/license-plate';
export const LOT = '/lot';
export const PRODUCT = '/product';

// Delivery Links
export const DELIVERIES = '/deliveries';
export const INBOUND_DELIVERY = '/deliveries/inbound';
export const OUTBOUND_DELIVERY = '/deliveries/outbound';

// Task Links
export const TASK = '/task';
export const TASKS = '/tasks';
export const TASK_GROUP = '/task-group';

// Map Links
export const MAP = 'map';
export const MAP_AISLE = '/map/aisle';

// Settings Links
export const SETTINGS = '/settings';
export const AGENT_CONFIG = '/settings/agent-config';
export const AREAS = '/settings/areas';
export const BARCODES = '/settings/barcodes';
export const BARCODE_ENROLLMENT = '/settings/barcode-enrollment';
export const BINS = '/settings/bins';
export const BIN_STORAGE_TYPES = '/settings/bin-storage-types';
export const BUSINESS_PARTNERS = '/settings/business-partners';
export const COMPANIES = '/settings/companies';
export const CONTRACTOR_VALIDATION_LOG = '/settings/contractor-validation-log';
export const DEVELOPER_TOOLS = '/settings/developer-tools';
export const DOORS = '/settings/doors';
export const ENTITY_HISTORY = '/settings/entity-history';
export const EQUIPMENT = '/settings/equipment';
export const EQUIPMENT_MODELS = '/settings/equipment/models';
export const EQUIPMENT_TYPES = '/settings/equipment/types';
export const FULFILLMENT_BLOCKS = '/settings/fulfillment-blocks';
export const INBOUND_FILE_PROCESSING = '/settings/inbound-file-processing';
export const LOTS = '/settings/lots';
export const ORGANIZATION = '/settings/organization';
export const PRODUCTS_SETTINGS = '/settings/products';
export const ROLES_AND_PERMISSIONS = '/settings/roles-and-permissions';
export const SLOTTING_SETTINGS = '/settings/slotting-configurations';
export const STOCK_STATUS = '/settings/stock-status';
export const TASK_TYPE_BIN_STATUS = '/settings/task-type-bin-status';
export const TASK_TYPE_STOCK_STATUS = '/settings/task-type-stock-status';
export const TEAMS = '/settings/teams';
export const UOM_GLOSSARY = '/settings/uom-glossary';
export const USER_ADMIN = '/settings/user-admin';
export const USER_ASSIGNMENT_GROUPS = '/settings/user-assignment-groups';
export const WAREHOUSE_ROLE_TYPES = '/settings/warehouse-role-types';
export const WAREHOUSES = '/settings/warehouses';
export const WAREHOUSES_MAPPING = '/settings/warehouse-mapping';
export const ZONE = '/settings/zone';
export const ZONES = '/settings/zones';

// Support Links
export const SUPPORT = '/support';
