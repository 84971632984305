import { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useGetUseRulesetFormDetailsLazyQuery } from '@/graphql/defs/components/modals/__generated__/create-or-copy-ruleset-modal.generated';
import { useListEquipmentTypesLazyQuery } from '@/graphql/defs/list/__generated__/list-equipment-types.generated';
import { useListWarehouseRoleTypesLazyQuery } from '@/graphql/defs/list/__generated__/list-warehouse-role-types.generated';
import { useListZonesLazyQuery } from '@/graphql/defs/list/__generated__/list-zones.generated';
import {
  AbcAnalysisInput,
  AbcCriteria,
  Forecasting,
  SlottingEquipmentInput,
  SlottingRulesetCreateInput,
  SlottingWorkerInput,
  SlottingZoneInput,
} from '@/graphql/types.generated';
import { useEntityUtils } from '@context/entity-utils';
import { useSnackbar } from '@context/snackbar';
import FormValues from '@models/FormValues';

export const RULESET_STEPS = [
  'naming',
  'abcAnalysis',
  'restrictions',
  'zones',
  'weights',
  'equipment',
  'workers',
  'review',
] as const;

export enum WarehouseDefault {
  warehouse = 'warehouse',
  custom = 'custom',
}
export enum FixedBins {
  yes = 'yes',
  no = 'no',
}

export enum UseAbcAnalysis {
  use = 'use',
  skip = 'skip',
}

export interface IZone extends SlottingZoneInput {
  code: string;
  description: string;
  useWarehouseDefaultEnum: WarehouseDefault;
  fixedBinsEnum: FixedBins;
}

export interface IEquipment extends SlottingEquipmentInput {
  code: string;
  label: string;
  description: string;
  cost: number;
  index: number;
  quantity: number;
}
export interface IWorkers extends SlottingWorkerInput {
  code: string;
  label: string;
  description: string;
  cost: number;
  index: number;
  quantity: number;
}

export interface IRuleset extends SlottingRulesetCreateInput {
  useAbcAnalysis: UseAbcAnalysis;
  zones: IZone[];
}

const DEFAULT_ABC_ANALYSIS: AbcAnalysisInput = {
  criteria: AbcCriteria.SalesOrderQuantity,
  indicatorPercentages: [25, 50, 25],
};

const useRulesetForm = ({
  rulesetId,
  setPreparing,
  readonly = false,
}: {
  rulesetId: string | null;
  setPreparing: Dispatch<SetStateAction<boolean>>;
  readonly?: boolean;
}) => {
  const { t } = useTranslation('components');
  const { selectedWarehouseId } = useEntityUtils();
  const { showMessage } = useSnackbar();

  const [getWarehouseRoleTypes] = useListWarehouseRoleTypesLazyQuery();
  const [getEquipmentTypes] = useListEquipmentTypesLazyQuery();
  const [getZones] = useListZonesLazyQuery();
  const [geRulesetDetails] = useGetUseRulesetFormDetailsLazyQuery();

  const defaultWeights = {
    pickDensity: 0,
    pickEfficiency: 0,
    putawayEfficiency: 0,
    putawayDensity: 0,
    avoidCongestion: 0,
    favorGroundLevel: 0,
  };
  const defaultRestrictions = {
    fefoFifoPicking: false,
    fifoPicking: false,
    heaviestToLightestPicks: false,
    preventMixedProductsInBins: false,
    preventMixedLotsInBins: false,
    preventMixedExpirationDatesInBins: false,
    enforceSingleOrderPicks: true,
    preventSimilarProductsInAdjacentBins: false,
  };

  const formMethods = useForm<FormValues<IRuleset>>({
    defaultValues: {
      name: '',
      privateRuleset: false,
      maxMovements: 20,
      warehouseId: selectedWarehouseId,
      useAbcAnalysis: UseAbcAnalysis.use,
      abcAnalysis: DEFAULT_ABC_ANALYSIS,
      forecasting: Forecasting.Historical,
      weights: defaultWeights,
      restrictions: defaultRestrictions,
      workers: [],
      equipment: [],
      zones: [],
    },
  });

  const prepare = async () => {
    const [
      {
        data: {
          listWarehouseRoleTypes: { warehouseRoleTypes },
        },
      },
      {
        data: {
          listEquipmentTypes: { equipmentTypes },
        },
      },
      {
        data: {
          listZones: { zones },
        },
      },
    ] = await Promise.all([
      getWarehouseRoleTypes(),
      getEquipmentTypes(),
      getZones({
        variables: {
          filter: { warehouseId: { eq: selectedWarehouseId } },
        },
      }),
    ]);

    const workers = warehouseRoleTypes.map(({ id, code, label, description }, index) => ({
      id,
      code,
      label,
      description,
      quantity: 0,
      cost: 0,
      index,
    }));
    const equipment = equipmentTypes.map(({ id, code, label, description }, index) => ({
      id,
      code,
      label,
      description,
      quantity: 0,
      cost: 5,
      index,
    }));
    const slottingZones = zones.map(({ id, code, description }) => ({
      zoneId: id,
      code,
      description,
      useWarehouseDefaultEnum: WarehouseDefault.warehouse,
      fixedBinsEnum: FixedBins.no,
      maxFixedBinsPerProduct: 1,
      weights: defaultWeights,
      restrictions: defaultRestrictions,
    }));

    if (!!rulesetId) {
      await geRulesetDetails({
        variables: { id: rulesetId },
        onCompleted: ({ slottingRuleset }) => {
          const { name, privateRuleset, maxMovements, forecasting } = slottingRuleset;

          let abcAnalysis: AbcAnalysisInput = !!slottingRuleset.abcAnalysis
            ? {
                criteria: slottingRuleset.abcAnalysis.criteria as AbcCriteria,
                indicatorPercentages: slottingRuleset.abcAnalysis.indicatorPercentages,
              }
            : DEFAULT_ABC_ANALYSIS;
          let weights = { ...slottingRuleset.weights, __typename: undefined };
          let restrictions = { ...slottingRuleset.restrictions, __typename: undefined };
          let copiedRulesetZones = [...slottingRuleset.zones];
          let copiedRulesetEquipment = [...slottingRuleset.equipment];
          let copiedRulesetWorkers = [...slottingRuleset.workers];

          formMethods.setValue('name', readonly ? name : `Copy of ${name}`);
          formMethods.setValue('privateRuleset', privateRuleset);
          formMethods.setValue('maxMovements', maxMovements);
          formMethods.setValue(
            'useAbcAnalysis',
            !!slottingRuleset.abcAnalysis ? UseAbcAnalysis.use : UseAbcAnalysis.skip,
          );
          formMethods.setValue('abcAnalysis', abcAnalysis);
          formMethods.setValue('forecasting', forecasting);
          formMethods.setValue('weights', weights);
          formMethods.setValue('restrictions', restrictions);
          formMethods.setValue(
            'zones',
            copiedRulesetZones
              .map((_zone) => {
                const zone = zones.find(({ id }) => id === _zone.zoneId);

                _zone = {
                  ..._zone,
                  __typename: undefined,
                  weights: { ..._zone.weights, __typename: undefined },
                  restrictions: { ..._zone.restrictions, __typename: undefined },
                };
                const formattedZone: IZone = {
                  ..._zone,
                  code: zone?.code,
                  description: zone?.description,
                  useWarehouseDefaultEnum: _zone.useWarehouseDefault
                    ? WarehouseDefault.warehouse
                    : WarehouseDefault.custom,
                  fixedBinsEnum: _zone.fixedBins ? FixedBins.yes : FixedBins.no,
                };
                return zone?.id ? formattedZone : null;
              })
              .filter((v) => !!v),
          );
          formMethods.setValue(
            'equipment',
            copiedRulesetEquipment
              .map((_copiedEquipment) => {
                const _equipment = equipment.find(({ id }) => id === _copiedEquipment.id);

                _copiedEquipment = { ..._copiedEquipment, __typename: undefined };
                const formattedEquipment: IEquipment = {
                  ..._equipment,
                  quantity: _copiedEquipment.quantity,
                };
                return _equipment ? formattedEquipment : null;
              })
              .filter((v) => !!v),
          );
          formMethods.setValue(
            'workers',
            copiedRulesetWorkers
              .map((_copiedWorker) => {
                const _worker = workers.find(({ id }) => id === _copiedWorker.id);

                _copiedWorker = { ..._copiedWorker, __typename: undefined };
                const formattedWorker: IWorkers = {
                  ..._worker,
                  quantity: _copiedWorker.quantity,
                };
                return _worker?.id ? formattedWorker : null;
              })
              .filter((v) => !!v),
          );

          setPreparing(false);
        },
        onError: (error) => {
          showMessage({
            type: 'error',
            message: t('modal.ruleset.copy.errorGettingDetails'),
          });

          formMethods.setValue('zones', slottingZones as unknown as '' | IZone[]);
          formMethods.setValue('workers', workers);
          formMethods.setValue('equipment', equipment);

          setPreparing(false);
        },
      });
    } else {
      formMethods.setValue('zones', slottingZones as unknown as '' | IZone[]);
      formMethods.setValue('workers', workers);
      formMethods.setValue('equipment', equipment);
      setPreparing(false);
    }
  };

  useEffect(() => {
    prepare();
  }, [rulesetId]);

  return formMethods;
};

export default useRulesetForm;
