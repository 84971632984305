import { DependencyList, EffectCallback, useEffect } from 'react';

interface IDebounceEffectOpts {
  shouldRun?: boolean;
  setDebouncePending?: (isDebouncePending: boolean) => void;
}

// https://dev.to/rajeshroyal/how-to-make-a-custom-debounce-hook-in-react-js-4gcc
const useDebouncedEffect = (
  effect: EffectCallback,
  delay: number,
  deps: DependencyList,
  opts: IDebounceEffectOpts = {},
) => {
  let handler = null;

  useEffect(() => {
    clearTimeout(handler);

    if (typeof opts.shouldRun === 'undefined' || opts.shouldRun) {
      if (opts.setDebouncePending) opts.setDebouncePending(true);
      handler = setTimeout(() => {
        effect();
        if (opts.setDebouncePending) opts.setDebouncePending(false);
      }, delay);
    }

    return () => clearTimeout(handler);

    // using || operator because
    // if its optional then it can be undefined.
  }, [...deps, delay]);
};

export default useDebouncedEffect;
