import { FormControlLabel, Slider } from '@mui/material';
import { Box } from '@mui/system';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

enum Weights {
  pickDensity = 'pickDensity',
  pickEfficiency = 'pickEfficiency',
  putawayDensity = 'putawayDensity',
  putawayEfficiency = 'putawayEfficiency',
  avoidCongestion = 'avoidCongestion',
  favorGroundLevel = 'favorGroundLevel',
}

const WEIGHTINGS = Object.values(Weights);

const sliderMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 100,
    label: '100',
  },
];

const WeightsInput = ({ control, disabled = false, prefix }) => {
  const { t } = useTranslation('components');
  return (
    <Box sx={{ marginBottom: '35px' }}>
      {WEIGHTINGS.map((weight) => {
        return (
          <FormControlLabel
            key={weight}
            sx={{
              padding: '15px 5% 15px 0px',
              width: '28%',
              minWidth: '150px',
            }}
            control={
              <Controller
                name={`${prefix}.${weight}`}
                control={control}
                render={(props) => (
                  <Slider
                    marks={sliderMarks}
                    {...props.field}
                    disabled={disabled}
                    valueLabelDisplay="auto"
                    max={100}
                    step={20}
                  />
                )}
              />
            }
            labelPlacement="top"
            label={<Box sx={{ width: '180px' }}>{t(`modal.ruleset.weights.${weight}`)}</Box>}
          />
        );
      })}
    </Box>
  );
};

export default WeightsInput;
