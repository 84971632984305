import { Box, Popover } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useController, useFormContext, useWatch } from 'react-hook-form';

import {
  UoMConversionsFragment,
  useGetProductUoMConversionsLazyQuery,
} from '@/graphql/defs/shared-queries/__generated__/get-product-uom-conversions.generated';
import { useViewUoMsQuery } from '@/graphql/defs/shared-queries/__generated__/view-uoms.generated';
import { ViewUoMs_defaultData } from '@/graphql/defs/shared-queries/view-uoms';
import { EditableCell } from '@components/editable-table/EditableTable';
import Typography from '@styled/Typography';

interface IEditableUoM extends EditableCell {
  item: Record<string, any> & { productId: string; unitOfMeasureId: string };
}
const EditableUoM: React.FC<IEditableUoM> = ({ item, createUID }) => {
  const { control, setValue } = useFormContext();

  const itemUID = createUID(item);

  // This allows us to get the updates the user makes to the editable quantity field, so we can automatically update the conversions for our dropdown.
  const currentQuantitySet = useWatch({
    control,
    name: `${itemUID}-qty`,
  });

  const {
    field: { onChange, value: currentSetUoMId },
  } = useController({
    name: `${itemUID}-uom`,
    control,
    defaultValue: item.unitOfMeasureId,
  });

  const { data: uomData } = useViewUoMsQuery({
    variables: { filter: { productId: { eq: item.productId } } },
  });
  const {
    unitOfMeasures: { nodes: productUoMs },
  } = useMemo(() => uomData || ViewUoMs_defaultData, [uomData]);

  const [
    getConversions,
    {
      data: { convertQuantityToAllUoM: { unitOfMeasureConversions } } = {
        convertQuantityToAllUoM: { unitOfMeasureConversions: [] as UoMConversionsFragment[] },
      },
    },
  ] = useGetProductUoMConversionsLazyQuery();

  // We use userChangeUoM to prevent the component from requesting conversions when the user only changed the UoM selected.
  // This is to help keep the Conversions from creating dust/difference in quantity, when all the user did was change the UoM.
  const [userChangedUoM, setUserChangedUoM] = useState(false);
  useEffect(() => {
    if (!userChangedUoM) {
      void getConversions({
        variables: { quantity: currentQuantitySet || '0', unitOfMeasure: currentSetUoMId },
      });
    } else {
      setUserChangedUoM(false);
    }
  }, [currentQuantitySet]);

  const sortedConversions = useMemo(() => {
    const sorted = [...unitOfMeasureConversions];
    return sorted.sort((a, b) => parseFloat(b.quantity) - parseFloat(a.quantity));
  }, [unitOfMeasureConversions]);

  const selectedUoM = useMemo(() => {
    return productUoMs.find((uom) => uom.id === currentSetUoMId);
  }, [productUoMs, currentSetUoMId]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? 'uomSelection-popover' : undefined;

  return (
    <>
      <Typography
        aria-describedby={popoverId}
        data-testid={`${itemUID}-uom-anchor`}
        styledVariant="editable"
        onClick={handleClick}
      >
        {selectedUoM?.code}
      </Typography>
      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gridAutoRows: 'auto',
            maxHeight: '135px',
            overflowY: 'auto',
          }}
        >
          {sortedConversions.map((conversion) => (
            <Box
              key={`${conversion.unitOfMeasure.code}-conversion`}
              display="contents"
              sx={{
                cursor: 'pointer',
                '&:hover p': { backgroundColor: 'rgba(0,0,0,0.1)' },
              }}
              onClick={(e) => {
                setUserChangedUoM(true);
                onChange(conversion.unitOfMeasure.id);
                setValue(`${itemUID}-qty`, conversion.quantity);
                handleClose();
              }}
            >
              <Typography
                sx={{
                  paddingLeft: '10px',
                  paddingRight: '5px',
                  paddingY: '10px',
                  textAlign: 'right',
                  fontVariantNumeric: 'tabular-nums',
                }}
              >
                {conversion.quantity}
              </Typography>
              <Typography
                sx={{
                  paddingRight: '10px',
                  paddingLeft: '5px',
                  paddingY: '10px',
                  textAlign: 'left',
                }}
              >
                {conversion.unitOfMeasure.code}
              </Typography>
            </Box>
          ))}
        </Box>
      </Popover>
    </>
  );
};
export default EditableUoM;
