import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAddToLicensePlateMutation } from '@/graphql/defs/components/modals/__generated__/add-to-license-plate.generated';
import EditableTable from '@components/editable-table/EditableTable';
import {
  createItemUID,
  useEditableLicensePlateTable,
} from '@components/editable-table/license-plate/hooks';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import ModalForm from '@components/modal/modal-form';
import TextField from '@components/text-field';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { InventoryItem } from '@models/inventory';
import { ILicensePlateAddToModal } from '@models/modal';

const AddToLicensePlateModal = () => {
  const { t } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal, setLoading } = useModalContent<ILicensePlateAddToModal>();

  const [shouldAutocomplete, setShouldAutocomplete] = useState(true);

  const filteredItems = useMemo(() => {
    const items = modal.inventoryItems as InventoryItem[];
    return items.filter((item: InventoryItem) => item.licensePlateId === null);
  }, [modal.inventoryItems]);

  const { createProductInput, licensePlateColumns, productItems, productQuantitiesUoM } =
    useEditableLicensePlateTable(filteredItems);

  const formMethods = useForm();
  useEffect(() => {
    formMethods.reset(productQuantitiesUoM);
  }, [productQuantitiesUoM]);

  const [addToLicensePlate] = useAddToLicensePlateMutation({
    onCompleted: ({ addToLicensePlate: { code } }) => {
      showMessage({
        type: 'success',
        message: !shouldAutocomplete
          ? t('licensePlateActions.addToSuccess', { code })
          : t('licensePlateActions.addToSuccessAutocomplete', { code }),
      });

      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onSubmit = (quantities) => {
    const binId = modal.inventoryItems[0].binId;
    const licensePlateId = modal.licensePlateId;
    void addToLicensePlate({
      variables: {
        binId,
        licensePlateId,
        products: createProductInput(quantities),
        autocomplete: shouldAutocomplete,
      },
    });
  };

  return (
    <ModalForm onSubmit={formMethods.handleSubmit(onSubmit)} formReturn={formMethods}>
      <ModalContent>
        <Typography mb={4}>{t('licensePlateActions.addToText')}</Typography>
        <Box sx={{ margin: '0 0 15px' }}>
          <TextField
            label={t('common.destinationLicensePlate')}
            value={modal.licensePlateCode}
            inputProps={{
              'data-testid': 'destinationLicensePlate',
            }}
            disabled={true}
            dataTestId="destinationLicensePlate"
          />
        </Box>
        <EditableTable
          label={modal.inventoryItems[0].binCode}
          columns={licensePlateColumns}
          createUID={createItemUID}
          data={productItems}
        />
      </ModalContent>
      <ModalActions>
        {modal.forceAutocomplete ? null : (
          <Box sx={{ marginRight: 'auto' }}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  onChange={(e) => {
                    setShouldAutocomplete(e?.target?.checked || false);
                  }}
                />
              }
              label={t('modal.task.markAsComplete')}
            />
          </Box>
        )}
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          data-testid="addToLicensePlate_submit"
          variant="contained"
          color="primary"
          actionType="submit"
        >
          {t('common.add')}
        </ModalButton>
      </ModalActions>
    </ModalForm>
  );
};

export default AddToLicensePlateModal;
