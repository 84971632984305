/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ZoneAreasSettingsTableFragment = { __typename?: 'ViewZoneArea', zoneId?: string | null, zoneCode?: string | null, zoneName?: string | null, zoneDescription?: string | null, warehouseId?: string | null, areaId?: string | null, areaCode?: string | null, areaName?: string | null, mapped?: boolean | null };

export type ZoneAreasSettingsTableQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.ViewZoneAreaFilter>;
  sorting?: Types.InputMaybe<Array<Types.ViewZoneAreaSort> | Types.ViewZoneAreaSort>;
  includePageInfo?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeNodes?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeTotalCount?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type ZoneAreasSettingsTableQuery = { __typename?: 'Query', query: { __typename?: 'ViewZoneAreaOffsetConnection', totalCount?: number, pageInfo?: { __typename?: 'OffsetPageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null }, nodes?: Array<{ __typename?: 'ViewZoneArea', zoneId?: string | null, zoneCode?: string | null, zoneName?: string | null, zoneDescription?: string | null, warehouseId?: string | null, areaId?: string | null, areaCode?: string | null, areaName?: string | null, mapped?: boolean | null }> } };

export const ZoneAreasSettingsTableFragmentDoc = gql`
    fragment ZoneAreasSettingsTable on ViewZoneArea {
  zoneId
  zoneCode
  zoneName
  zoneDescription
  warehouseId
  areaId
  areaCode
  areaName
  mapped
}
    `;
export const ZoneAreasSettingsTableDocument = gql`
    query ZoneAreasSettingsTable($paging: OffsetPaging, $filter: ViewZoneAreaFilter, $sorting: [ViewZoneAreaSort!], $includePageInfo: Boolean = true, $includeNodes: Boolean = true, $includeTotalCount: Boolean = false) {
  query: viewZoneAreas(sorting: $sorting, paging: $paging, filter: $filter) {
    pageInfo @include(if: $includePageInfo) {
      hasNextPage
      hasPreviousPage
    }
    nodes @include(if: $includeNodes) {
      ...ZoneAreasSettingsTable
    }
    totalCount @include(if: $includeTotalCount)
  }
}
    ${ZoneAreasSettingsTableFragmentDoc}`;

/**
 * __useZoneAreasSettingsTableQuery__
 *
 * To run a query within a React component, call `useZoneAreasSettingsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoneAreasSettingsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoneAreasSettingsTableQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      includePageInfo: // value for 'includePageInfo'
 *      includeNodes: // value for 'includeNodes'
 *      includeTotalCount: // value for 'includeTotalCount'
 *   },
 * });
 */
export function useZoneAreasSettingsTableQuery(baseOptions?: Apollo.QueryHookOptions<ZoneAreasSettingsTableQuery, ZoneAreasSettingsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ZoneAreasSettingsTableQuery, ZoneAreasSettingsTableQueryVariables>(ZoneAreasSettingsTableDocument, options);
      }
export function useZoneAreasSettingsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ZoneAreasSettingsTableQuery, ZoneAreasSettingsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ZoneAreasSettingsTableQuery, ZoneAreasSettingsTableQueryVariables>(ZoneAreasSettingsTableDocument, options);
        }
export type ZoneAreasSettingsTableQueryHookResult = ReturnType<typeof useZoneAreasSettingsTableQuery>;
export type ZoneAreasSettingsTableLazyQueryHookResult = ReturnType<typeof useZoneAreasSettingsTableLazyQuery>;
export type ZoneAreasSettingsTableQueryResult = Apollo.QueryResult<ZoneAreasSettingsTableQuery, ZoneAreasSettingsTableQueryVariables>;