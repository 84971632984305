import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateTime, Settings } from 'luxon';
import { createContext, useCallback, useContext, useMemo } from 'react';

import { useGetDateTimeDisplayPrefQuery } from '@/graphql/defs/context/__generated__/date-time.generated';
import { GetDateTimeDisplayPref_defaultData } from '@/graphql/defs/context/date-time';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_DATE_TIME_OBJECT,
  DEFAULT_TIME_FORMAT,
} from '@constants/date-time-formats';
import { useAuth } from '@context/auth';
import { useEntityUtils } from '@context/entity-utils';
import { createDateTimeFormatObject, display, IDateTimePref, IDisplayFunc } from '@lib/date';

interface IDateTimeContext {
  dateTimePref: IDateTimePref;
  displayDate: IDisplayFunc;
  displayTime: IDisplayFunc;
  displayDateTime: IDisplayFunc;
  dateTimeToWHTimezone: (dateTime: DateTime) => DateTime;
  todaysDateTimeRange: [DateTime, DateTime];
  weeksDateTimeRange: [DateTime, DateTime];
}

const getStartAndEndOfWeek = (): [DateTime, DateTime] => {
  const now = DateTime.now();
  const daysAfterSunday = now.weekday % 7;
  const start = now.minus({ days: daysAfterSunday }).startOf('day');
  const end = start.plus({ days: 6 }).endOf('day');
  return [start, end];
};

const DateTimeContext = createContext<IDateTimeContext>({
  dateTimePref: DEFAULT_DATE_TIME_OBJECT,
  displayDate: () => '',
  displayTime: () => '',
  displayDateTime: () => '',
  dateTimeToWHTimezone: (dateTime) => dateTime,
  todaysDateTimeRange: [DateTime.now().startOf('day'), DateTime.now().endOf('day')],
  weeksDateTimeRange: getStartAndEndOfWeek(),
});

const DateTimeProvider = ({ children }) => {
  const { user } = useAuth();
  const { data: getDateTimeData } = useGetDateTimeDisplayPrefQuery({
    skip: !user?.id,
    fetchPolicy: 'cache-first',
    variables: { userId: user?.id },
  });
  const { selectedWarehouse } = useEntityUtils();

  const {
    user: { displayPreference },
  } = useMemo(() => getDateTimeData || GetDateTimeDisplayPref_defaultData, [getDateTimeData]);

  const dateTimePref: IDateTimePref = useMemo(() => {
    // This setting should make it so all MUI Date/DateTime pickers are set to the WH Timezone.
    Settings.defaultZone = displayPreference?.timezone || 'system';
    return createDateTimeFormatObject(displayPreference);
  }, [displayPreference]);

  // Used for the LocalizationProvider format prop
  const formats = useMemo(() => {
    return {
      fullDate: dateTimePref?.date,
      fullDateTime: dateTimePref?.dateTime,
      fullDateTime12h: dateTimePref?.dateTime,
      fullDateTime24h: dateTimePref?.dateTime,
      fullTime: dateTimePref?.time,
      fullTime12h: dateTimePref?.time,
      fullTime24h: dateTimePref?.time,
      keyboardDate: dateTimePref?.date,
      keyboardDateTime: dateTimePref?.dateTime,
      keyboardDateTime12h: dateTimePref?.dateTime,
      keyboardDateTime24h: dateTimePref?.dateTime,
      normalDate: dateTimePref?.date,
    };
  }, [dateTimePref]);

  // Note: Dates should almost always be rendered in the Warehouse's Timezone so that no date shifting occurs.
  // We allow the timezone to be overrode by individual use cases' but this should only be for non-warehouse related dates.
  const displayDate = useCallback(
    ({
      date,
      format = dateTimePref.date,
      timezone = selectedWarehouse?.displayPreference?.timezone || DateTime.now().zoneName,
    }) =>
      display({ date, format, defaultFormat: DEFAULT_DATE_FORMAT, timezone, showTimezone: false }),
    [dateTimePref, selectedWarehouse],
  );
  const displayTime = useCallback(
    ({
      date,
      format = dateTimePref.time,
      timezone = selectedWarehouse?.displayPreference?.timezone || DateTime.now().zoneName,
      showTimezone = true,
    }) =>
      display({
        date,
        format,
        defaultFormat: DEFAULT_TIME_FORMAT,
        timezone,
        showTimezone,
      }),
    [dateTimePref, selectedWarehouse],
  );
  const displayDateTime = useCallback(
    ({
      date,
      format = dateTimePref.dateTime,
      timezone = selectedWarehouse?.displayPreference?.timezone || DateTime.now().zoneName,
      showTimezone = true,
    }) =>
      display({
        date,
        format,
        defaultFormat: DEFAULT_DATE_TIME_FORMAT,
        timezone,
        showTimezone,
      }),
    [dateTimePref, selectedWarehouse],
  );

  const dateTimeToWHTimezone = useCallback(
    (dateTime: DateTime, keepLocalTime: boolean = true) =>
      dateTime?.setZone(selectedWarehouse?.displayPreference?.timezone || DateTime.now().zoneName, {
        keepLocalTime,
      }),
    [selectedWarehouse],
  );

  const todaysDateTimeRange = useMemo<[DateTime, DateTime]>(() => {
    const today = DateTime.now().setZone(
      selectedWarehouse?.displayPreference?.timezone || DateTime.now().zoneName,
      { keepLocalTime: true },
    );
    return [today.startOf('day'), today.endOf('day')];
  }, [selectedWarehouse]);

  const weeksDateTimeRange = useMemo<[DateTime, DateTime]>(() => {
    return getStartAndEndOfWeek();
  }, [selectedWarehouse]);

  return (
    <DateTimeContext.Provider
      value={{
        dateTimePref,
        displayDate,
        displayTime,
        displayDateTime,
        dateTimeToWHTimezone,
        todaysDateTimeRange,
        weeksDateTimeRange,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterLuxon} dateFormats={formats}>
        {children}
      </LocalizationProvider>
    </DateTimeContext.Provider>
  );
};

export default DateTimeProvider;

export const useDateTime = () => {
  const ctx = useContext(DateTimeContext);
  if (ctx === null) {
    throw new Error('useDateTime must be used within DateTimeProvider');
  }

  return ctx;
};
