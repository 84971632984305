/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OutboundFulfillmentItemFragment = { __typename?: 'ViewFulfillmentItem', id?: string | null, availability?: number | null, dateAvailable?: string | null, deliveryId?: string | null, fulfillmentCode?: string | null, fulfillmentDueDate?: string | null, erpLastChanged?: string | null, pickStatus?: Types.DeliveryCompletionStatus | null, grossWeight?: number | null, weightUOMId?: string | null, weightUOMCode?: string | null, weightUOMLabel?: string | null, item?: string | null, loadStatus?: Types.DeliveryCompletionStatus | null, lotCode?: string | null, lotId?: string | null, netWeight?: number | null, productId?: string | null, productCode?: string | null, productDescription?: string | null, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, quantity?: string | null, salesOrderCode?: string | null, salesOrderItem?: string | null, stockStatus?: string | null, unitOfMeasure?: string | null, unitOfMeasureId?: string | null, volume?: number | null, volumeUOMId?: string | null, volumeUOMCode?: string | null, volumeUOMLabel?: string | null, updatedAt?: string | null, warehouseId: string };

export type OutboundFulfillmentItemsTableQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ViewFulfillmentItemFilter>;
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  sorting?: Types.InputMaybe<Array<Types.ViewFulfillmentItemSort> | Types.ViewFulfillmentItemSort>;
  includePageInfo?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeNodes?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  includeTotalCount?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type OutboundFulfillmentItemsTableQuery = { __typename?: 'Query', query: { __typename?: 'ViewFulfillmentItemOffsetConnection', totalCount?: number, pageInfo?: { __typename?: 'OffsetPageInfo', hasNextPage?: boolean | null, hasPreviousPage?: boolean | null }, nodes?: Array<{ __typename?: 'ViewFulfillmentItem', id?: string | null, availability?: number | null, dateAvailable?: string | null, deliveryId?: string | null, fulfillmentCode?: string | null, fulfillmentDueDate?: string | null, erpLastChanged?: string | null, pickStatus?: Types.DeliveryCompletionStatus | null, grossWeight?: number | null, weightUOMId?: string | null, weightUOMCode?: string | null, weightUOMLabel?: string | null, item?: string | null, loadStatus?: Types.DeliveryCompletionStatus | null, lotCode?: string | null, lotId?: string | null, netWeight?: number | null, productId?: string | null, productCode?: string | null, productDescription?: string | null, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, quantity?: string | null, salesOrderCode?: string | null, salesOrderItem?: string | null, stockStatus?: string | null, unitOfMeasure?: string | null, unitOfMeasureId?: string | null, volume?: number | null, volumeUOMId?: string | null, volumeUOMCode?: string | null, volumeUOMLabel?: string | null, updatedAt?: string | null, warehouseId: string }> } };

export const OutboundFulfillmentItemFragmentDoc = gql`
    fragment OutboundFulfillmentItem on ViewFulfillmentItem {
  id
  availability
  dateAvailable
  deliveryId
  fulfillmentCode
  fulfillmentDueDate
  erpLastChanged
  pickStatus
  grossWeight
  weightUOMId
  weightUOMCode
  weightUOMLabel
  item
  loadStatus
  lotCode
  lotId
  netWeight
  productId
  productCode
  productDescription
  licensePlateId
  licensePlateCode
  licensePlateDescription
  quantity
  salesOrderCode
  salesOrderItem
  stockStatus
  unitOfMeasure
  unitOfMeasureId
  volume
  volumeUOMId
  volumeUOMCode
  volumeUOMLabel
  updatedAt
  warehouseId
}
    `;
export const OutboundFulfillmentItemsTableDocument = gql`
    query OutboundFulfillmentItemsTable($filter: ViewFulfillmentItemFilter, $paging: OffsetPaging, $sorting: [ViewFulfillmentItemSort!], $includePageInfo: Boolean = true, $includeNodes: Boolean = true, $includeTotalCount: Boolean = false) {
  query: viewFulfillmentItems(paging: $paging, filter: $filter, sorting: $sorting) {
    pageInfo @include(if: $includePageInfo) {
      hasNextPage
      hasPreviousPage
    }
    nodes @include(if: $includeNodes) {
      ...OutboundFulfillmentItem
    }
    totalCount @include(if: $includeTotalCount)
  }
}
    ${OutboundFulfillmentItemFragmentDoc}`;

/**
 * __useOutboundFulfillmentItemsTableQuery__
 *
 * To run a query within a React component, call `useOutboundFulfillmentItemsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutboundFulfillmentItemsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutboundFulfillmentItemsTableQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *      includePageInfo: // value for 'includePageInfo'
 *      includeNodes: // value for 'includeNodes'
 *      includeTotalCount: // value for 'includeTotalCount'
 *   },
 * });
 */
export function useOutboundFulfillmentItemsTableQuery(baseOptions?: Apollo.QueryHookOptions<OutboundFulfillmentItemsTableQuery, OutboundFulfillmentItemsTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutboundFulfillmentItemsTableQuery, OutboundFulfillmentItemsTableQueryVariables>(OutboundFulfillmentItemsTableDocument, options);
      }
export function useOutboundFulfillmentItemsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutboundFulfillmentItemsTableQuery, OutboundFulfillmentItemsTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutboundFulfillmentItemsTableQuery, OutboundFulfillmentItemsTableQueryVariables>(OutboundFulfillmentItemsTableDocument, options);
        }
export type OutboundFulfillmentItemsTableQueryHookResult = ReturnType<typeof useOutboundFulfillmentItemsTableQuery>;
export type OutboundFulfillmentItemsTableLazyQueryHookResult = ReturnType<typeof useOutboundFulfillmentItemsTableLazyQuery>;
export type OutboundFulfillmentItemsTableQueryResult = Apollo.QueryResult<OutboundFulfillmentItemsTableQuery, OutboundFulfillmentItemsTableQueryVariables>;