import { CancelOutlined, KeyboardArrowDown } from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DeliveryInventoryFragment,
  InboundDeliveryDetailsFragment,
} from '@/graphql/defs/pages/__generated__/inbound-deliveries.generated';
import { TDeliveryItemsDataType } from '@components/data-table/hooks/shared-columns/useCreateInboundDeliveryItemColumns';
import PutawayIcon from '@components/icons/putaway';
import UnloadIcon from '@components/icons/unload';
import Button from '@components/styled/Button';
import { useModalToggle } from '@context/modal/ModalToggleProvider';
import { useWarehouseFeatureFlags } from '@context/warehouse-feature-flags';
import { ModalTypes, TRefetchDataTable } from '@models/modal';
import MenuItem from '@styled/MenuItem';

export interface IInboundLooseProduct extends DeliveryInventoryFragment {
  deliveryItem: string;
}
interface IDeliveryItemsActionMenu {
  deliveryDetails: InboundDeliveryDetailsFragment;
  looseProduct: IInboundLooseProduct[];
  selectedDeliveryItems: TDeliveryItemsDataType[];
  refetchData: TRefetchDataTable;
  sx?: SxProps<Theme>;
}
const DeliveryItemsActionMenu: FC<IDeliveryItemsActionMenu> = ({
  deliveryDetails,
  looseProduct,
  selectedDeliveryItems,
  refetchData,
  sx,
}) => {
  const { t } = useTranslation('components');
  const { openModal } = useModalToggle();
  const {
    warehouseFeatureFlags: { deliveries: deliveryFlags },
  } = useWarehouseFeatureFlags();

  const menuAnchor = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const openCancelDeliveryItemsModal = () => {
    openModal(
      {
        type: ModalTypes.cancelDeliveryItems,
        deliveryItems: selectedDeliveryItems,
      },
      {
        afterClose: (success) => {
          if (success) {
            refetchData();
          }
        },
      },
    );
  };

  const openCreatePutawayTasksModal = () => {
    openModal(
      {
        type: ModalTypes.putawayTaskCreate,
        deliveryId: deliveryDetails?.id,
        erpCode: deliveryDetails?.erpCode,
        looseProduct,
      },
      {
        afterClose: (success) => {
          if (success) {
            refetchData();
          }
        },
      },
    );
  };

  const openCreateUnloadTasksModal = () => {
    openModal(
      {
        type: ModalTypes.unloadTaskCreate,
        deliveryDetails,
      },
      {
        afterClose: (success) => {
          if (success) {
            refetchData();
          }
        },
      },
    );
  };

  return (
    <>
      <Button
        data-testid="deliveryItemsActions_button"
        ref={menuAnchor}
        variant="contained"
        styledVariant="actionMenu"
        color="primary"
        endIcon={<KeyboardArrowDown fontSize="inherit" />}
        onClick={() => setIsOpen(true)}
        sx={{
          fontWeight: 500,
          padding: '5px 25px',
          '& svg:nth-of-type(1)': { fontSize: 28 },
          ...sx,
        }}
      >
        {t('common.actions')}
      </Button>
      <Menu
        id="delivery-items-action-menu"
        data-testid="deliveryItemsAction_menu"
        anchorEl={menuAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onClick={() => setIsOpen(false)}
      >
        {selectedDeliveryItems.length > 0 && (
          <MenuItem
            styledVariant="actionMenu"
            iconColor="#b52b2b"
            data-testid="actionItem_cancelDeliveryItems"
            onClick={openCancelDeliveryItemsModal}
          >
            <ListItemIcon>
              <CancelOutlined />
            </ListItemIcon>
            <ListItemText>
              {t('deliveries.itemActionMenus.cancelDeliveryItem', {
                count: selectedDeliveryItems.length,
              })}
            </ListItemText>
          </MenuItem>
        )}
        <MenuItem
          styledVariant="actionMenu"
          data-testid="actionItem_createPutawayTasks"
          iconColor={(theme) => theme.palette.success.main}
          onClick={openCreatePutawayTasksModal}
        >
          <ListItemIcon>
            <PutawayIcon />
          </ListItemIcon>
          <ListItemText>{t('deliveries.itemActionMenus.createPutawayTasks')}</ListItemText>
        </MenuItem>
        {deliveryFlags.showLoadUnload && (
          <MenuItem
            styledVariant="actionMenu"
            data-testid="actionItem_createUnloadTasks"
            onClick={openCreateUnloadTasksModal}
          >
            <ListItemIcon>
              <UnloadIcon />
            </ListItemIcon>
            <ListItemText>{t('deliveries.itemActionMenus.createUnloadTasks')}</ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default DeliveryItemsActionMenu;
