import { createContext, ReactElement, useContext, useRef } from 'react';

import ModalContentProvider, {
  TOpenModal,
  TOpenModalOpts,
} from '@context/modal/ModalContentProvider';
import { IModal } from '@models/modal';

export interface IModalToggleContext {
  openModal: TOpenModal;
}
export const ModalToggleContext = createContext<IModalToggleContext>(null);

interface IModalToggleProvider {
  children: ReactElement | ReactElement[];
}
const ModalToggleProvider = ({ children }: IModalToggleProvider) => {
  const setOpenModalRef = useRef<TOpenModal>(() => {});

  const openModal: TOpenModal = (modal: IModal, opts: TOpenModalOpts = {}) => {
    setOpenModalRef.current(modal, opts);
  };

  return (
    <ModalToggleContext.Provider
      value={{
        openModal,
      }}
    >
      <ModalContentProvider setOpenModalRef={setOpenModalRef}>{children}</ModalContentProvider>
    </ModalToggleContext.Provider>
  );
};

export default ModalToggleProvider;

export function useModalToggle() {
  const ctx = useContext(ModalToggleContext);
  if (ctx === null) {
    throw new Error('useModalToggle must be used within ModalToggleProvider');
  }
  return ctx;
}
