import { DatePicker as MuiDatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from 'luxon';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';

import TextField from '@components/text-field';
import { useDateTime } from '@context/date-time';

interface IDatePicker
  extends Omit<DatePickerProps<DateTime, DateTime>, 'onChange' | 'renderInput' | 'value'> {
  onChange?: never;
  renderInput?: never;
  filterStyle?: boolean;
  required?: boolean;
  isDisabled?: boolean;
  fullWidth?: boolean;
  setDate: (dateTime: DateTime | null) => void;
  dataTestId?: string;
}
interface IDatePickerControllerBased extends IDatePicker {
  value?: never;
  field?: ControllerRenderProps<any, any>;
  fieldState?: ControllerFieldState;
}
interface IDatePickerNonControllerBased extends IDatePicker {
  value: DateTime | null;
  field?: never;
  fieldState?: never;
}
export const DatePicker: FC<IDatePickerControllerBased | IDatePickerNonControllerBased> = ({
  setDate,
  value,
  field = {
    onChange: () => {
      return;
    },
    value: null,
  },
  fieldState,
  filterStyle = false,
  required = false,
  isDisabled = false,
  autoFocus = false,
  fullWidth = true,
  label = undefined,
  dataTestId = 'date-picker',
  ...props
}) => {
  const { dateTimeToWHTimezone } = useDateTime();

  const [open, setOpen] = useState(false);
  const close = () => {
    setOpen(false);
  };

  const formattedValue = useMemo(() => {
    if (typeof value !== 'undefined') return value;
    if (typeof field.value === 'string') {
      const formatted = DateTime.fromISO(field.value);
      field.onChange(formatted);
      return formatted;
    }
    return field.value;
  }, [value, field.value]);

  // This use effect block is used to close the calendar when the modal is clicked on
  // due to z-index settings this doesn't happen automatically
  useEffect(() => {
    const searchModal = document.getElementById('search-modal');
    if (searchModal) {
      searchModal.addEventListener('click', close);
      return () => {
        searchModal.removeEventListener('click', close);
      };
    }
  }, []);

  return (
    <MuiDatePicker
      {...props}
      {...field}
      disabled={isDisabled}
      value={formattedValue}
      onChange={(date: DateTime) => {
        setDate(dateTimeToWHTimezone(date).startOf('day'));
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          autoFocus={autoFocus}
          required={required}
          fullWidth={fullWidth}
          error={!!fieldState?.error}
          helperText={fieldState?.error?.message}
          dataTestId={dataTestId}
        />
      )}
    />
  );
};
