enum StatusValues {
  NotStarted = 'notStarted',
  InProgress = 'inProgress',
  Complete = 'complete',
  True = 'true',
  Available = 'available',
  Blocked = 'blocked',
  False = 'false',
  Inbound = 'inbound',
  OutBound = 'outbound',
  Remaining = 'remaining',
  Successful = 'successful',
  Error = 'error',
  Processed = 'processed',
  Unlocked = 'unlocked',
  Locked = 'locked',
}

export default StatusValues;
