/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PickToStagingFulfillmentItemFragment = { __typename?: 'ViewFulfillmentItem', id?: string | null, item?: string | null, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, productId?: string | null, productCode?: string | null, productDescription?: string | null, unitOfMeasure?: string | null };

export type GetPickToStagingFulfillmentItemQueryVariables = Types.Exact<{
  fulfillmentItemId: Types.Scalars['ID']['input'];
}>;


export type GetPickToStagingFulfillmentItemQuery = { __typename?: 'Query', viewFulfillmentItems: { __typename?: 'ViewFulfillmentItemOffsetConnection', nodes: Array<{ __typename?: 'ViewFulfillmentItem', id?: string | null, item?: string | null, licensePlateId?: string | null, licensePlateCode?: string | null, licensePlateDescription?: string | null, productId?: string | null, productCode?: string | null, productDescription?: string | null, unitOfMeasure?: string | null }> } };

export type CompletePickToStagingTaskMutationVariables = Types.Exact<{
  warehouseId: Types.Scalars['String']['input'];
  completePickToStagingInput: Array<Types.CompletePickToStagingTaskInput> | Types.CompletePickToStagingTaskInput;
}>;


export type CompletePickToStagingTaskMutation = { __typename?: 'Mutation', completeManyPickToStagingTasks: Array<{ __typename?: 'Task', id: string, code: string }> };

export const PickToStagingFulfillmentItemFragmentDoc = gql`
    fragment PickToStagingFulfillmentItem on ViewFulfillmentItem {
  id
  item
  licensePlateId
  licensePlateCode
  licensePlateDescription
  productId
  productCode
  productDescription
  unitOfMeasure
}
    `;
export const GetPickToStagingFulfillmentItemDocument = gql`
    query GetPickToStagingFulfillmentItem($fulfillmentItemId: ID!) {
  viewFulfillmentItems(filter: {id: {eq: $fulfillmentItemId}}) {
    nodes {
      ...PickToStagingFulfillmentItem
    }
  }
}
    ${PickToStagingFulfillmentItemFragmentDoc}`;

/**
 * __useGetPickToStagingFulfillmentItemQuery__
 *
 * To run a query within a React component, call `useGetPickToStagingFulfillmentItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPickToStagingFulfillmentItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPickToStagingFulfillmentItemQuery({
 *   variables: {
 *      fulfillmentItemId: // value for 'fulfillmentItemId'
 *   },
 * });
 */
export function useGetPickToStagingFulfillmentItemQuery(baseOptions: Apollo.QueryHookOptions<GetPickToStagingFulfillmentItemQuery, GetPickToStagingFulfillmentItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPickToStagingFulfillmentItemQuery, GetPickToStagingFulfillmentItemQueryVariables>(GetPickToStagingFulfillmentItemDocument, options);
      }
export function useGetPickToStagingFulfillmentItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPickToStagingFulfillmentItemQuery, GetPickToStagingFulfillmentItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPickToStagingFulfillmentItemQuery, GetPickToStagingFulfillmentItemQueryVariables>(GetPickToStagingFulfillmentItemDocument, options);
        }
export type GetPickToStagingFulfillmentItemQueryHookResult = ReturnType<typeof useGetPickToStagingFulfillmentItemQuery>;
export type GetPickToStagingFulfillmentItemLazyQueryHookResult = ReturnType<typeof useGetPickToStagingFulfillmentItemLazyQuery>;
export type GetPickToStagingFulfillmentItemQueryResult = Apollo.QueryResult<GetPickToStagingFulfillmentItemQuery, GetPickToStagingFulfillmentItemQueryVariables>;
export const CompletePickToStagingTaskDocument = gql`
    mutation CompletePickToStagingTask($warehouseId: String!, $completePickToStagingInput: [CompletePickToStagingTaskInput!]!) {
  completeManyPickToStagingTasks(
    warehouseId: $warehouseId
    completePickToStagingTasks: $completePickToStagingInput
  ) {
    id
    code
  }
}
    `;
export type CompletePickToStagingTaskMutationFn = Apollo.MutationFunction<CompletePickToStagingTaskMutation, CompletePickToStagingTaskMutationVariables>;

/**
 * __useCompletePickToStagingTaskMutation__
 *
 * To run a mutation, you first call `useCompletePickToStagingTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePickToStagingTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePickToStagingTaskMutation, { data, loading, error }] = useCompletePickToStagingTaskMutation({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      completePickToStagingInput: // value for 'completePickToStagingInput'
 *   },
 * });
 */
export function useCompletePickToStagingTaskMutation(baseOptions?: Apollo.MutationHookOptions<CompletePickToStagingTaskMutation, CompletePickToStagingTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompletePickToStagingTaskMutation, CompletePickToStagingTaskMutationVariables>(CompletePickToStagingTaskDocument, options);
      }
export type CompletePickToStagingTaskMutationHookResult = ReturnType<typeof useCompletePickToStagingTaskMutation>;
export type CompletePickToStagingTaskMutationResult = Apollo.MutationResult<CompletePickToStagingTaskMutation>;
export type CompletePickToStagingTaskMutationOptions = Apollo.BaseMutationOptions<CompletePickToStagingTaskMutation, CompletePickToStagingTaskMutationVariables>;