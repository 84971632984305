/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ValidateTaskQueryVariables = Types.Exact<{
  warehouseId: Types.Scalars['ID']['input'];
  taskTypeBinStatusForLicensePlate?: Types.InputMaybe<Types.TaskTypeBinStatusForLicensePlateValidationInput>;
  taskTypeCode?: Types.InputMaybe<Types.Scalars['String']['input']>;
  taskTypeId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  taskTypeStockStatus?: Types.InputMaybe<Types.TaskTypeStockStatusValidationInput>;
  taskTypeBinStatus?: Types.InputMaybe<Types.TaskTypeBinStatusValidationInput>;
  taskTypeStockStatusForLicensePlate?: Types.InputMaybe<Types.TaskTypeStockStatusForLicensePlateValidationInput>;
}>;


export type ValidateTaskQuery = { __typename?: 'Query', validateTask: { __typename?: 'TaskValidationErrorModel', valid: boolean, errors?: Array<{ __typename?: 'BaseGraphqlErrorReturnType', action?: string | null, code?: Types.ErrorCodes | null, duration?: number | null, log?: string | null, logType?: Types.LogType | null, message: string, type?: Types.ErrorType | null }> | null } };


export const ValidateTaskDocument = gql`
    query ValidateTask($warehouseId: ID!, $taskTypeBinStatusForLicensePlate: TaskTypeBinStatusForLicensePlateValidationInput, $taskTypeCode: String, $taskTypeId: ID, $taskTypeStockStatus: TaskTypeStockStatusValidationInput, $taskTypeBinStatus: TaskTypeBinStatusValidationInput, $taskTypeStockStatusForLicensePlate: TaskTypeStockStatusForLicensePlateValidationInput) {
  validateTask: validateTaskType(
    warehouseId: $warehouseId
    taskTypeBinStatusForLicensePlate: $taskTypeBinStatusForLicensePlate
    taskTypeCode: $taskTypeCode
    taskTypeId: $taskTypeId
    taskTypeStockStatus: $taskTypeStockStatus
    taskTypeBinStatus: $taskTypeBinStatus
    taskTypeStockStatusForLicensePlate: $taskTypeStockStatusForLicensePlate
  ) {
    errors {
      action
      code
      duration
      log
      logType
      message
      type
    }
    valid
  }
}
    `;

/**
 * __useValidateTaskQuery__
 *
 * To run a query within a React component, call `useValidateTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateTaskQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      taskTypeBinStatusForLicensePlate: // value for 'taskTypeBinStatusForLicensePlate'
 *      taskTypeCode: // value for 'taskTypeCode'
 *      taskTypeId: // value for 'taskTypeId'
 *      taskTypeStockStatus: // value for 'taskTypeStockStatus'
 *      taskTypeBinStatus: // value for 'taskTypeBinStatus'
 *      taskTypeStockStatusForLicensePlate: // value for 'taskTypeStockStatusForLicensePlate'
 *   },
 * });
 */
export function useValidateTaskQuery(baseOptions: Apollo.QueryHookOptions<ValidateTaskQuery, ValidateTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateTaskQuery, ValidateTaskQueryVariables>(ValidateTaskDocument, options);
      }
export function useValidateTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateTaskQuery, ValidateTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateTaskQuery, ValidateTaskQueryVariables>(ValidateTaskDocument, options);
        }
export type ValidateTaskQueryHookResult = ReturnType<typeof useValidateTaskQuery>;
export type ValidateTaskLazyQueryHookResult = ReturnType<typeof useValidateTaskLazyQuery>;
export type ValidateTaskQueryResult = Apollo.QueryResult<ValidateTaskQuery, ValidateTaskQueryVariables>;