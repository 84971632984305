/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddEquipmentTypeMutationVariables = Types.Exact<{
  newType: Types.EquipmentTypeCreateType;
}>;


export type AddEquipmentTypeMutation = { __typename?: 'Mutation', equipmentType: { __typename?: 'EquipmentType', id: string, code: string, label: string, description?: string | null } };


export const AddEquipmentTypeDocument = gql`
    mutation AddEquipmentType($newType: EquipmentTypeCreateType!) {
  equipmentType: createOneEquipmentType(input: {equipmentType: $newType}) {
    id
    code
    label
    description
  }
}
    `;
export type AddEquipmentTypeMutationFn = Apollo.MutationFunction<AddEquipmentTypeMutation, AddEquipmentTypeMutationVariables>;

/**
 * __useAddEquipmentTypeMutation__
 *
 * To run a mutation, you first call `useAddEquipmentTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEquipmentTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEquipmentTypeMutation, { data, loading, error }] = useAddEquipmentTypeMutation({
 *   variables: {
 *      newType: // value for 'newType'
 *   },
 * });
 */
export function useAddEquipmentTypeMutation(baseOptions?: Apollo.MutationHookOptions<AddEquipmentTypeMutation, AddEquipmentTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEquipmentTypeMutation, AddEquipmentTypeMutationVariables>(AddEquipmentTypeDocument, options);
      }
export type AddEquipmentTypeMutationHookResult = ReturnType<typeof useAddEquipmentTypeMutation>;
export type AddEquipmentTypeMutationResult = Apollo.MutationResult<AddEquipmentTypeMutation>;
export type AddEquipmentTypeMutationOptions = Apollo.BaseMutationOptions<AddEquipmentTypeMutation, AddEquipmentTypeMutationVariables>;