/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LpDetailsFragment = { __typename?: 'LicensePlateDetail', id: string, dispositionId?: string | null, code: string, description?: string | null, aisleCode?: string | null, aisleColumnCode?: string | null, areaCode?: string | null, binId?: string | null, binCode?: string | null, hasOpenTasks?: boolean | null, inactive?: Types.LicensePlateStatusState | null, level?: number | null, lpHierarchy?: any | null, totalWeight?: number | null, totalVolume?: number | null, warehouseName?: string | null, x?: number | null, y?: number | null, createdAt: string, updatedAt?: string | null };

export type GetLpDetailsQueryVariables = Types.Exact<{
  lpId: Types.Scalars['ID']['input'];
}>;


export type GetLpDetailsQuery = { __typename?: 'Query', licensePlateDetailsAllBins: { __typename?: 'LicensePlateDetailOffsetConnection', nodes: Array<{ __typename?: 'LicensePlateDetail', id: string, dispositionId?: string | null, code: string, description?: string | null, aisleCode?: string | null, aisleColumnCode?: string | null, areaCode?: string | null, binId?: string | null, binCode?: string | null, hasOpenTasks?: boolean | null, inactive?: Types.LicensePlateStatusState | null, level?: number | null, lpHierarchy?: any | null, totalWeight?: number | null, totalVolume?: number | null, warehouseName?: string | null, x?: number | null, y?: number | null, createdAt: string, updatedAt?: string | null }> } };

export type GetDispositionQueryVariables = Types.Exact<{
  dispositionId: Types.Scalars['ID']['input'];
}>;


export type GetDispositionQuery = { __typename?: 'Query', disposition: { __typename?: 'Disposition', id: string, createdAt: string, updatedAt?: string | null, deletedAt?: string | null, notes: string, images?: Array<{ __typename?: 'StoredImage', id: string, createdAt: string, updatedAt?: string | null, deletedAt?: string | null, original: string, web: string, thumbnail?: string | null }> | null } };

export type ApproveDenyDispositionMutationVariables = Types.Exact<{
  input: Types.DispositionApproveOneDto;
}>;


export type ApproveDenyDispositionMutation = { __typename?: 'Mutation', approveOneDisposition: string };

export type UpdateDispositionMutationVariables = Types.Exact<{
  input: Types.UpdateOneDispositionInput;
}>;


export type UpdateDispositionMutation = { __typename?: 'Mutation', updateOneDisposition: { __typename?: 'Disposition', id: string } };

export type GetDispositionStatusQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.InventoryBaseQueryShapeFilter>;
}>;


export type GetDispositionStatusQuery = { __typename?: 'Query', dispositionStatus: { __typename?: 'InventoryBaseQueryShapeOffsetConnection', nodes: Array<{ __typename?: 'InventoryBaseQueryShape', stockStatus?: string | null, stockStatusTypeId?: string | null }> } };

export const LpDetailsFragmentDoc = gql`
    fragment LPDetails on LicensePlateDetail {
  id
  dispositionId
  code
  description
  aisleCode
  aisleColumnCode
  areaCode
  binId
  binCode
  hasOpenTasks
  inactive
  level
  lpHierarchy
  totalWeight
  totalVolume
  warehouseName
  x
  y
  createdAt
  updatedAt
}
    `;
export const GetLpDetailsDocument = gql`
    query GetLPDetails($lpId: ID!) {
  licensePlateDetailsAllBins(
    paging: {offset: 0, limit: 1}
    filter: {id: {eq: $lpId}}
  ) {
    nodes {
      ...LPDetails
    }
  }
}
    ${LpDetailsFragmentDoc}`;

/**
 * __useGetLpDetailsQuery__
 *
 * To run a query within a React component, call `useGetLpDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLpDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLpDetailsQuery({
 *   variables: {
 *      lpId: // value for 'lpId'
 *   },
 * });
 */
export function useGetLpDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetLpDetailsQuery, GetLpDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLpDetailsQuery, GetLpDetailsQueryVariables>(GetLpDetailsDocument, options);
      }
export function useGetLpDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLpDetailsQuery, GetLpDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLpDetailsQuery, GetLpDetailsQueryVariables>(GetLpDetailsDocument, options);
        }
export type GetLpDetailsQueryHookResult = ReturnType<typeof useGetLpDetailsQuery>;
export type GetLpDetailsLazyQueryHookResult = ReturnType<typeof useGetLpDetailsLazyQuery>;
export type GetLpDetailsQueryResult = Apollo.QueryResult<GetLpDetailsQuery, GetLpDetailsQueryVariables>;
export const GetDispositionDocument = gql`
    query GetDisposition($dispositionId: ID!) {
  disposition(id: $dispositionId) {
    id
    createdAt
    updatedAt
    deletedAt
    notes
    images {
      id
      createdAt
      updatedAt
      deletedAt
      original
      web
      thumbnail
    }
  }
}
    `;

/**
 * __useGetDispositionQuery__
 *
 * To run a query within a React component, call `useGetDispositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDispositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDispositionQuery({
 *   variables: {
 *      dispositionId: // value for 'dispositionId'
 *   },
 * });
 */
export function useGetDispositionQuery(baseOptions: Apollo.QueryHookOptions<GetDispositionQuery, GetDispositionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDispositionQuery, GetDispositionQueryVariables>(GetDispositionDocument, options);
      }
export function useGetDispositionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDispositionQuery, GetDispositionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDispositionQuery, GetDispositionQueryVariables>(GetDispositionDocument, options);
        }
export type GetDispositionQueryHookResult = ReturnType<typeof useGetDispositionQuery>;
export type GetDispositionLazyQueryHookResult = ReturnType<typeof useGetDispositionLazyQuery>;
export type GetDispositionQueryResult = Apollo.QueryResult<GetDispositionQuery, GetDispositionQueryVariables>;
export const ApproveDenyDispositionDocument = gql`
    mutation ApproveDenyDisposition($input: DispositionApproveOneDto!) {
  approveOneDisposition(input: $input)
}
    `;
export type ApproveDenyDispositionMutationFn = Apollo.MutationFunction<ApproveDenyDispositionMutation, ApproveDenyDispositionMutationVariables>;

/**
 * __useApproveDenyDispositionMutation__
 *
 * To run a mutation, you first call `useApproveDenyDispositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveDenyDispositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveDenyDispositionMutation, { data, loading, error }] = useApproveDenyDispositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveDenyDispositionMutation(baseOptions?: Apollo.MutationHookOptions<ApproveDenyDispositionMutation, ApproveDenyDispositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveDenyDispositionMutation, ApproveDenyDispositionMutationVariables>(ApproveDenyDispositionDocument, options);
      }
export type ApproveDenyDispositionMutationHookResult = ReturnType<typeof useApproveDenyDispositionMutation>;
export type ApproveDenyDispositionMutationResult = Apollo.MutationResult<ApproveDenyDispositionMutation>;
export type ApproveDenyDispositionMutationOptions = Apollo.BaseMutationOptions<ApproveDenyDispositionMutation, ApproveDenyDispositionMutationVariables>;
export const UpdateDispositionDocument = gql`
    mutation UpdateDisposition($input: UpdateOneDispositionInput!) {
  updateOneDisposition(input: $input) {
    id
  }
}
    `;
export type UpdateDispositionMutationFn = Apollo.MutationFunction<UpdateDispositionMutation, UpdateDispositionMutationVariables>;

/**
 * __useUpdateDispositionMutation__
 *
 * To run a mutation, you first call `useUpdateDispositionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDispositionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDispositionMutation, { data, loading, error }] = useUpdateDispositionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDispositionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDispositionMutation, UpdateDispositionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDispositionMutation, UpdateDispositionMutationVariables>(UpdateDispositionDocument, options);
      }
export type UpdateDispositionMutationHookResult = ReturnType<typeof useUpdateDispositionMutation>;
export type UpdateDispositionMutationResult = Apollo.MutationResult<UpdateDispositionMutation>;
export type UpdateDispositionMutationOptions = Apollo.BaseMutationOptions<UpdateDispositionMutation, UpdateDispositionMutationVariables>;
export const GetDispositionStatusDocument = gql`
    query GetDispositionStatus($filter: InventoryBaseQueryShapeFilter) {
  dispositionStatus: baseInventory(filter: $filter) {
    nodes {
      stockStatus
      stockStatusTypeId
    }
  }
}
    `;

/**
 * __useGetDispositionStatusQuery__
 *
 * To run a query within a React component, call `useGetDispositionStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDispositionStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDispositionStatusQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDispositionStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetDispositionStatusQuery, GetDispositionStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDispositionStatusQuery, GetDispositionStatusQueryVariables>(GetDispositionStatusDocument, options);
      }
export function useGetDispositionStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDispositionStatusQuery, GetDispositionStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDispositionStatusQuery, GetDispositionStatusQueryVariables>(GetDispositionStatusDocument, options);
        }
export type GetDispositionStatusQueryHookResult = ReturnType<typeof useGetDispositionStatusQuery>;
export type GetDispositionStatusLazyQueryHookResult = ReturnType<typeof useGetDispositionStatusLazyQuery>;
export type GetDispositionStatusQueryResult = Apollo.QueryResult<GetDispositionStatusQuery, GetDispositionStatusQueryVariables>;