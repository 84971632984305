/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import { FullBinFragmentDoc } from '../../shared-fragments/__generated__/bin.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBinQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type GetBinQuery = { __typename?: 'Query', bin: { __typename?: 'Bin', id: string, code: string, warehouseId: string, binStorageTypeId: string, x?: number | null, y?: number | null, areaId?: string | null, aisleId?: string | null, aisleColumnId?: string | null, level?: number | null, putawayBlock?: boolean | null, removalBlock?: boolean | null, verificationCode?: string | null, lastMovement?: string | null, lastCount?: string | null, area?: { __typename?: 'Area', id: string, name?: string | null, code: string, description?: string | null, warehouseId?: string | null, type?: Types.AreaType | null, status?: Types.AreaStatus | null, sapStorageLocationPlant: { __typename?: 'SapStorageLocationPlant', id: string, storageLocation: string } } | null, warehouse?: { __typename?: 'Warehouse', id: string, code: string, name?: string | null } | null } };


export const GetBinDocument = gql`
    query GetBin($id: ID!) {
  bin(id: $id) {
    ...FullBin
  }
}
    ${FullBinFragmentDoc}`;

/**
 * __useGetBinQuery__
 *
 * To run a query within a React component, call `useGetBinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBinQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBinQuery(baseOptions: Apollo.QueryHookOptions<GetBinQuery, GetBinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBinQuery, GetBinQueryVariables>(GetBinDocument, options);
      }
export function useGetBinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBinQuery, GetBinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBinQuery, GetBinQueryVariables>(GetBinDocument, options);
        }
export type GetBinQueryHookResult = ReturnType<typeof useGetBinQuery>;
export type GetBinLazyQueryHookResult = ReturnType<typeof useGetBinLazyQuery>;
export type GetBinQueryResult = Apollo.QueryResult<GetBinQuery, GetBinQueryVariables>;