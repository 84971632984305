import { Autocomplete, AutocompleteProps, Box } from '@mui/material';
import {
  ForwardedRef,
  HTMLAttributes,
  ReactNode,
  forwardRef,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { VariableSizeList } from 'react-window';

import GetVirtualListbox, { VirtualListboxContext } from '@components/virtualized-listbox';

const TrackedOption = ({
  optionIndex,
  renderType,
  renderProps,
  children,
}: {
  optionIndex: number;
  renderType: 'renderOption' | 'getOptionLabel';
  renderProps?: HTMLAttributes<HTMLLIElement>;
  children: ReactNode | ReactNode[];
}) => {
  const optionRef = useRef<HTMLElement>(null);
  const { setItemSize } = useContext(VirtualListboxContext);

  useEffect(() => {
    setItemSize(optionIndex, optionRef.current.getBoundingClientRect().height);
  }, []);

  return renderType === 'renderOption' ? (
    <Box ref={optionRef}>{children}</Box>
  ) : (
    <Box ref={optionRef} component="li" {...(!!renderProps && renderProps)}>
      {children}
    </Box>
  );
};

function VirtualAutocomplete<
  T,
  Multiple extends boolean = undefined,
  DisableClearable extends boolean = undefined,
  FreeSolo extends boolean = undefined,
>(
  autocompleteProps: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  ref: ForwardedRef<unknown>,
): JSX.Element {
  const virtualListboxRef = useRef<VariableSizeList>(null);
  const itemSizes = useRef<Record<string, number>>({});

  const getItemSize = (index) => {
    // We default to 48 just because it seems like a sensible default.
    // That said, we should hopefully never need a default for the final render thanks to resetAfterIndex() in setItemSize.
    return itemSizes.current[index] || 48;
  };

  const setItemSize = (index, size) => {
    itemSizes.current = { ...itemSizes.current, [index]: size };
    virtualListboxRef.current.resetAfterIndex(0, true);
  };

  return (
    <VirtualListboxContext.Provider value={{ getItemSize, setItemSize, virtualListboxRef }}>
      <Autocomplete
        {...autocompleteProps}
        ref={ref}
        renderOption={(props, option, state, ownerState) => {
          const index = props['data-option-index'] || 0;
          return typeof autocompleteProps.renderOption === 'function' ? (
            <TrackedOption
              key={`trackedOption-${index}`}
              optionIndex={index}
              renderType="renderOption"
            >
              {autocompleteProps.renderOption(props, option, state, ownerState)}
            </TrackedOption>
          ) : (
            <TrackedOption
              key={`trackedOption-${index}`}
              optionIndex={index}
              renderType="getOptionLabel"
              renderProps={props}
            >
              {autocompleteProps.getOptionLabel(option)}
            </TrackedOption>
          );
        }}
        ListboxComponent={GetVirtualListbox()}
      />
    </VirtualListboxContext.Provider>
  );
}

export default forwardRef(VirtualAutocomplete) as <
  T,
  Multiple extends boolean = undefined,
  DisableClearable extends boolean = undefined,
  FreeSolo extends boolean = undefined,
>(
  autocompleteProps: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  ref: ForwardedRef<unknown>,
) => JSX.Element;
