import { Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { getTransComponents } from '@lib/translation';
import { ITableLayoutDeleteModal } from '@models/modal';

const DeleteTableLayoutModal = () => {
  const { t } = useTranslation('components');
  const { modal, closeModal } = useModalContent<ITableLayoutDeleteModal>();

  const onSubmit = () => {
    modal.handleDelete(modal.layout.id, modal.layout.variant, () => {
      closeModal({ bypassLoading: true, success: true });
    });
  };

  return (
    <>
      <ModalContent>
        <Box>
          <Trans
            ns="components"
            i18nKey="modal.tableLayout.delete.text"
            components={getTransComponents(['bold'])}
            values={{
              layoutName: modal.layout?.label || '',
            }}
          />
        </Box>
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {t('common.cancel')}
        </ModalButton>
        <ModalButton
          onClick={() => onSubmit()}
          variant="contained"
          color="error"
          actionType="delete"
        >
          {t('common.delete')}
        </ModalButton>
      </ModalActions>
    </>
  );
};

export default DeleteTableLayoutModal;
