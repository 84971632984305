import { Box, Paper } from '@mui/material';
import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import CollapseLoading from '@components/loading-indicator/CollapseLoading';
import { Separator } from '@components/modal/ruleset/steps';
import Step1 from '@components/modal/ruleset/steps/step-1';
import Step2 from '@components/modal/ruleset/steps/step-2';
import Step3 from '@components/modal/ruleset/steps/step-3';
import Step4 from '@components/modal/ruleset/steps/step-4';
import Step5 from '@components/modal/ruleset/steps/step-5';
import Step6 from '@components/modal/ruleset/steps/step-6';
import Step7 from '@components/modal/ruleset/steps/step-7';
import PageInfo from '@components/page-info';
import Button from '@components/styled/Button';
import { SLOTTING_RULESET, SLOTTING_RULESETS } from '@constants/routes';
import { useModalToggle } from '@context/modal/ModalToggleProvider';
import useRulesetForm from '@hooks/form/ruleset/useRulesetForm';
import { ModalTypes } from '@models/modal';

const RulesetDetails = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'ruleset-details' });
  const { t: tC } = useTranslation('components');
  const { id: rulesetId } = useParams();
  const { openModal } = useModalToggle();
  const navigate = useNavigate();

  const [preparing, setPreparing] = useState(true);
  useMemo(() => {
    if (!preparing) setPreparing(true);
  }, [rulesetId]);

  const { control, clearErrors, getValues, setValue, watch } = useRulesetForm({
    readonly: true,
    rulesetId: rulesetId || null,
    setPreparing,
  });

  const copyRuleset = () => {
    openModal(
      {
        type: ModalTypes.rulesetCreateOrCopy,
        rulesetId,
      },
      {
        beforeClose: (success, opts) => {
          if (success && !!opts?.rulesetId) navigate(`${SLOTTING_RULESET}/${opts.rulesetId}`);
        },
      },
    );
  };

  const rulesetName = watch('name');
  const titlePrefix = useMemo(() => {
    return preparing
      ? t('title_withoutDetails')
      : t('title_withDetails', { rulesetName, interpolation: { escapeValue: false } });
  }, [preparing, rulesetName]);
  const helmetTitle = tC('htmlTitleSuffix', { prefix: titlePrefix });

  return (
    <>
      <Helmet encodeSpecialCharacters={false}>
        <title>{helmetTitle}</title>
      </Helmet>

      <PageInfo
        title={titlePrefix}
        subtitle={t('subtitle')}
        backButton
        backButtonRoute={SLOTTING_RULESETS}
      >
        <Button onClick={copyRuleset} variant="contained" styledVariant="pageInfoCta">
          {t('copyRuleset')}
        </Button>
      </PageInfo>

      {preparing ? (
        <CollapseLoading />
      ) : (
        <Paper>
          <Box paddingX={8} paddingY={4}>
            <Step1 control={control} review={true} active={false} />
            <Separator />
            <Step2 control={control} review={true} active={false} />
            <Separator />
            <Step3 control={control} review={true} active={false} />
            <Separator />
            <Step4
              control={control}
              review={true}
              active={false}
              clearErrors={clearErrors}
              getValues={getValues}
              setValue={setValue}
            />
            <Separator />
            <Step5 control={control} review={true} active={false} />
            <Separator />
            <Step6 control={control} review={true} active={false} />
            <Separator />
            <Step7 control={control} review={true} active={false} />
          </Box>
        </Paper>
      )}
    </>
  );
};

export default RulesetDetails;
