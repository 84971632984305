import { Button } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import { styled } from '@mui/material/styles';
import { FC, MouseEvent } from 'react';

import { ModalProgressIcon } from '@components/modal';
import { TLoadingType, useModalContent } from '@context/modal/ModalContentProvider';

const StyledModalButton = styled(Button, {
  shouldForwardProp: (prop) => !['actionType', 'isDisabled'].includes(prop as string),
})({
  textTransform: 'capitalize',
  padding: '8px 50px',
  position: 'relative',
  fontWeight: 500,
  minHeight: '45px',
  minWidth: '130px',
  borderWidth: '3px',
  '&:hover': {
    borderWidth: '3px',
    padding: '8px 50px',
  },
});

export const DisconnectedModalButton = StyledModalButton;

interface IModalButton extends ButtonProps {
  actionType?: TLoadingType;
  isDisabled?: boolean;
  disabled?: never;
}
export const ModalButton: FC<IModalButton> = ({ children, isDisabled, ...props }) => {
  const { isLoading, setLoading, loadingType, setLoadingType } = useModalContent();

  // NOTE: If we pass onClick to a ModalButton that is inside a ModalForm, then the ModalForm.onSubmit will NOT run.
  // Only pass onClick for ModalButtons not inside a ModalForm. The onSubmit of ModalForm will trigger with a ModalButton with actionType="submit" is clicked.
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (['submit', 'delete'].includes(props.actionType)) {
      setLoadingType(props.actionType);
      setLoading(true);
    }
    props.onClick(e);
  };

  // {...props} follows the type attribute so that we can override the type if desired.
  return (
    <StyledModalButton
      type={props.actionType === 'submit' ? 'submit' : 'button'}
      {...props}
      disabled={isDisabled || isLoading}
      onClick={typeof props.onClick === 'function' ? handleClick : undefined}
      data-testid={`modal-${props.actionType}-button`}
    >
      {children}
      {isLoading && props.actionType === loadingType && <ModalProgressIcon size={16} />}
    </StyledModalButton>
  );
};
