/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserGroupListItemFragment = { __typename?: 'UserGroup', id: string, createdAt: string, updatedAt?: string | null, deletedAt?: string | null, name: string };

export type ListUserGroupsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListUserGroupsQuery = { __typename?: 'Query', listUserGroups: { __typename?: 'UserGroupOffsetConnection', userGroups: Array<{ __typename?: 'UserGroup', id: string, createdAt: string, updatedAt?: string | null, deletedAt?: string | null, name: string }> } };

export const UserGroupListItemFragmentDoc = gql`
    fragment UserGroupListItem on UserGroup {
  id
  createdAt
  updatedAt
  deletedAt
  name
}
    `;
export const ListUserGroupsDocument = gql`
    query ListUserGroups {
  listUserGroups: userGroups {
    userGroups: nodes {
      ...UserGroupListItem
    }
  }
}
    ${UserGroupListItemFragmentDoc}`;

/**
 * __useListUserGroupsQuery__
 *
 * To run a query within a React component, call `useListUserGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListUserGroupsQuery(baseOptions?: Apollo.QueryHookOptions<ListUserGroupsQuery, ListUserGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserGroupsQuery, ListUserGroupsQueryVariables>(ListUserGroupsDocument, options);
      }
export function useListUserGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserGroupsQuery, ListUserGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserGroupsQuery, ListUserGroupsQueryVariables>(ListUserGroupsDocument, options);
        }
export type ListUserGroupsQueryHookResult = ReturnType<typeof useListUserGroupsQuery>;
export type ListUserGroupsLazyQueryHookResult = ReturnType<typeof useListUserGroupsLazyQuery>;
export type ListUserGroupsQueryResult = Apollo.QueryResult<ListUserGroupsQuery, ListUserGroupsQueryVariables>;