/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateBinStorageTypeMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  binStorageType: Types.BinStorageTypeUpdateInput;
}>;


export type UpdateBinStorageTypeMutation = { __typename?: 'Mutation', updateOneBinStorageType: { __typename?: 'BinStorageType', id: string, code: string, label: string } };


export const UpdateBinStorageTypeDocument = gql`
    mutation UpdateBinStorageType($id: ID!, $binStorageType: BinStorageTypeUpdateInput!) {
  updateOneBinStorageType(input: {id: $id, update: $binStorageType}) {
    id
    code
    label
  }
}
    `;
export type UpdateBinStorageTypeMutationFn = Apollo.MutationFunction<UpdateBinStorageTypeMutation, UpdateBinStorageTypeMutationVariables>;

/**
 * __useUpdateBinStorageTypeMutation__
 *
 * To run a mutation, you first call `useUpdateBinStorageTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBinStorageTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBinStorageTypeMutation, { data, loading, error }] = useUpdateBinStorageTypeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      binStorageType: // value for 'binStorageType'
 *   },
 * });
 */
export function useUpdateBinStorageTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBinStorageTypeMutation, UpdateBinStorageTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBinStorageTypeMutation, UpdateBinStorageTypeMutationVariables>(UpdateBinStorageTypeDocument, options);
      }
export type UpdateBinStorageTypeMutationHookResult = ReturnType<typeof useUpdateBinStorageTypeMutation>;
export type UpdateBinStorageTypeMutationResult = Apollo.MutationResult<UpdateBinStorageTypeMutation>;
export type UpdateBinStorageTypeMutationOptions = Apollo.BaseMutationOptions<UpdateBinStorageTypeMutation, UpdateBinStorageTypeMutationVariables>;