import { HighlightOff } from '@mui/icons-material';
import { Box, Autocomplete, SxProps, Theme, Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ANY_COLUMN,
  ANY_COLUMN_FILTER,
  ANY_COLUMN_ID,
  ColumnFilter,
  ColumnFilterEnumOption,
  ColumnFilterValues,
  ColumnFilterDefinition,
  ColumnTypes,
  FilterOperators,
  FILTER_DEFINITIONS,
  IFilterDef,
} from '@components/data-table/controls/filter/filter-definitions';
import { FilterInput } from '@components/data-table/controls/filter/filter-input';
import TextField from '@components/text-field';

const FilterControl = ({
  input,
  setInput,
  isDisabled = false,
  removeFilter,
  hideRemoveFilterIcon,
  forceColumnOperator,
  removeOperatorInput,
  filterableColumns,
  filterableColumnEnumOptions,
  includeAnyFilter,
  dataTestId,
  sx,
}: {
  input: ColumnFilter;
  setInput: (input: ColumnFilter) => void;
  isDisabled?: boolean;
  removeFilter: () => void;
  hideRemoveFilterIcon?: boolean;
  forceColumnOperator?: (columnType: ColumnTypes) => FilterOperators;
  removeOperatorInput?: boolean;
  filterableColumns: ColumnFilterDefinition[];
  filterableColumnEnumOptions: ColumnFilterEnumOption[];
  includeAnyFilter?: boolean;
  dataTestId: string;
  sx?: SxProps<Theme>;
}) => {
  const { t } = useTranslation('components', { keyPrefix: 'filter' });

  const [isInputControlDisabled, setInputControlDisabled] = useState(
    'linked' in input || isDisabled,
  );
  useEffect(() => {
    setInputControlDisabled('linked' in input || isDisabled);
  }, [input, isDisabled]);

  const columnOptions = [...filterableColumns];
  if (includeAnyFilter) columnOptions.splice(0, 0, ANY_COLUMN);

  const selectedColumn = useMemo(() => {
    const _selectedMatch = columnOptions.find((c) => input.columnId === c.id) || null;
    if (_selectedMatch?.id === ANY_COLUMN_ID) {
      _selectedMatch.options = filterableColumnEnumOptions;
    }
    return _selectedMatch;
  }, [columnOptions, input]);

  const selectedOperator = useMemo(() => {
    return (
      FILTER_DEFINITIONS[selectedColumn?.columnType]?.find(
        (filterDef) => filterDef.operator === input.operator,
      ) ||
      FILTER_DEFINITIONS[selectedColumn?.columnType]?.[0] ||
      null
    );
  }, [input, selectedColumn]);

  const handleColumnChange = (_pendingColumn: ColumnFilterDefinition) => {
    if (_pendingColumn.id === ANY_COLUMN_ID) {
      // Any Column Selection
      setInput(ANY_COLUMN_FILTER);
    } else {
      // Normal Column Selection
      const columnFilterDefs = FILTER_DEFINITIONS[_pendingColumn.columnType];

      // Try to find the operator they are currently on
      let operatorDef =
        (input.operator && columnFilterDefs.find((def) => def.operator === input.operator)) ||
        columnFilterDefs[0];

      if (forceColumnOperator) {
        const _forcedOperator = forceColumnOperator(_pendingColumn.columnType);
        const _forcedDef = columnFilterDefs.find((d) => d.operator === _forcedOperator);
        if (_forcedDef) {
          operatorDef = _forcedDef;
        } else {
          throw new Error(
            `Unable to force ${_pendingColumn.label} to use ${_forcedOperator} operator.`,
          );
        }
      }

      setInput({
        columnId: _pendingColumn.id,
        operator: operatorDef.operator,
        value:
          _pendingColumn.columnType === selectedColumn?.columnType
            ? input.value
            : operatorDef.defaultValue,
      });
    }
  };

  const handleOperatorChange = (filterDef: IFilterDef) => {
    setInput({
      ...input,
      operator: filterDef.operator,
    });
  };

  const setValue = (value: ColumnFilterValues) => {
    setInput({
      ...input,
      value,
    });
  };

  const filterLine = (
    <Box
      sx={{
        marginBottom: (theme) => theme.spacing(8),
        ...(sx || {}),
      }}
    >
      <Box
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          gap: (theme) => theme.spacing(4),
        }}
      >
        <Autocomplete
          data-testid={`${dataTestId}-filter-column`}
          disabled={isInputControlDisabled}
          freeSolo={false}
          multiple={false}
          autoHighlight={true}
          disableClearable={true}
          getOptionLabel={(option) => option.label}
          options={columnOptions}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={selectedColumn}
          onChange={(event, item, reason) => {
            if (reason === 'selectOption') {
              handleColumnChange(item);
            }
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                dataTestId={`${dataTestId}-filter-column-input`}
                label={t('quickFilter.column')}
              />
            );
          }}
          sx={{
            width: '250px',
          }}
        />
        {!removeOperatorInput && selectedColumn?.columnType !== ColumnTypes.any && (
          <Autocomplete
            data-testid={`${dataTestId}-filter-operator`}
            disabled={isInputControlDisabled || !selectedColumn}
            freeSolo={false}
            multiple={false}
            autoHighlight={true}
            disableClearable={true}
            getOptionLabel={(option) => t(option.operator)}
            options={FILTER_DEFINITIONS[selectedColumn?.columnType] || []}
            isOptionEqualToValue={(option, value) => option.operator === value.operator}
            value={selectedOperator}
            onChange={(event, item, reason) => {
              if (reason === 'selectOption') {
                handleOperatorChange(item);
              }
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  dataTestId={`${dataTestId}-filter-operator-input`}
                  label={t('operator')}
                />
              );
            }}
            sx={{
              width: '250px',
            }}
          />
        )}
        <FilterInput
          input={input}
          columnDef={selectedColumn}
          setValue={setValue}
          inputProps={{ disabled: isInputControlDisabled || !selectedColumn || !selectedOperator }}
          dataTestId={dataTestId}
        />
        {!hideRemoveFilterIcon && !isInputControlDisabled && (
          <HighlightOff
            sx={{ marginLeft: 'auto', cursor: 'pointer' }}
            onClick={removeFilter}
            data-testid={`${dataTestId}-remove-filter`}
          />
        )}
      </Box>
    </Box>
  );

  return 'linked' in input ? (
    <Tooltip title={input?.linkedTooltip} placement="top" arrow>
      {filterLine}
    </Tooltip>
  ) : (
    filterLine
  );
};

export default FilterControl;
