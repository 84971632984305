import { useTranslation } from 'react-i18next';

import { useSetWarehouseUomMutation } from '@/graphql/defs/components/modals/__generated__/select-warehouse-uom-modal.generated';
import DataTable from '@components/data-table';
import useSelectWarehouseUoMModalDataTable from '@components/data-table/hooks/table-props/useSelectWarehouseUoMModalDataTable';
import { ModalActions, ModalButton, ModalContent } from '@components/modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { IWarehouseUnitOfMeasureModal } from '@models/modal';
import Typography from '@styled/Typography';

const SelectWarehouseUnitOfMeasureModal = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'product-details' });
  const { t: tC } = useTranslation('components');
  const { showMessage } = useSnackbar();
  const { modal, closeModal, setLoading } = useModalContent<IWarehouseUnitOfMeasureModal>();

  const { selectedUoM, selectWarehouseUomMDataTableProps } = useSelectWarehouseUoMModalDataTable(
    modal.productId,
    modal.whUom,
  );

  const [setWarehouseUom] = useSetWarehouseUomMutation({
    onCompleted: () => {
      showMessage({
        type: 'success',
        message: t('whUomUpdateSuccess', {
          productCode: modal.productCode,
          uomCode: selectedUoM?.code,
        }),
      });
      closeModal({ bypassLoading: true, success: true });
    },
    onError: (error) => {
      setLoading(false);
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  const onSubmit = () => {
    void setWarehouseUom({
      variables: {
        productWHUomId: modal.whUom.id,
        uomId: selectedUoM?.id,
      },
    });
  };

  return (
    <>
      <ModalContent>
        <Typography sx={{ marginBottom: (theme) => theme.spacing(4) }}>
          {tC('modal.warehouseUom.subtitle')}
        </Typography>
        <DataTable {...selectWarehouseUomMDataTableProps} />
      </ModalContent>
      <ModalActions>
        <ModalButton
          onClick={() => closeModal()}
          variant="outlined"
          color="primary"
          actionType="cancel"
        >
          {tC('common.cancel')}
        </ModalButton>
        <ModalButton
          data-testid="setWHUoM_submit"
          variant="contained"
          color="primary"
          onClick={onSubmit}
          isDisabled={!selectedUoM?.id}
          actionType="submit"
        >
          {tC('common.save')}
        </ModalButton>
      </ModalActions>
    </>
  );
};

export default SelectWarehouseUnitOfMeasureModal;
