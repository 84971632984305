import { Box, Typography } from '@mui/material';

import Collapse from '@components/collapse';
import DataTableDataBasedProvider from '@components/data-table/context/DataBasedProvider';
import DataTableDataBasedControls from '@components/data-table/controls/DataBasedControls';
import DataTableTableContainer from '@components/data-table/DataTableTableContainer';
import {
  IDataTableDataBasedProps,
  DataTableVariants,
} from '@components/data-table/model/data-table';
import DataTableDataBasedPagination from '@components/data-table/pagination/DataBasedPagination';
import DataTableDataBasedTable from '@components/data-table/table/DataBasedTable';

const DataTableDataBased = ({ dataBasedProps }: { dataBasedProps: IDataTableDataBasedProps }) => {
  return (
    <DataTableDataBasedProvider dataTableProps={dataBasedProps}>
      {dataBasedProps.variant === DataTableVariants.Basic && (
        <DataTableTableContainer>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{ padding: (theme) => theme.spacing(3, 3, 0) }}
          >
            <Typography
              variant="h3"
              color={(theme) => theme.palette.primary.main}
              data-testid={`data-table-${dataBasedProps.tableId}-header`}
            >
              {dataBasedProps.tableHeader}
            </Typography>
            {!!dataBasedProps.tableActions && dataBasedProps.tableActions}
          </Box>
          <DataTableDataBasedControls disableExport={dataBasedProps.disableExport} />
          <DataTableDataBasedTable />
          <DataTableDataBasedPagination />
        </DataTableTableContainer>
      )}
      {dataBasedProps.variant === DataTableVariants.Collapsible && (
        <Collapse
          title={
            <Typography
              variant="h3"
              color={(theme) => theme.palette.primary.main}
              data-testid={`data-table-${dataBasedProps.tableId}-header`}
            >
              {dataBasedProps.tableHeader}
            </Typography>
          }
          action={!!dataBasedProps.tableActions ? dataBasedProps.tableActions : undefined}
          bodySx={{ padding: 0 }}
          defaultCollapsed={dataBasedProps.defaultCollapsed}
        >
          <DataTableDataBasedControls disableExport={dataBasedProps.disableExport} />
          <DataTableDataBasedTable />
          <DataTableDataBasedPagination />
        </Collapse>
      )}
    </DataTableDataBasedProvider>
  );
};

export default DataTableDataBased;
