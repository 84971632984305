import { gql } from '@apollo/client';

import { PermittedWarehousesQuery } from '@/graphql/defs/list/__generated__/list-permitted-warehouses.generated';

gql`
  query PermittedWarehouses {
    permittedWarehouses {
      code
      id
      name
    }
  }
`;

export const PermittedWarehouses_defaultData: PermittedWarehousesQuery = {
  __typename: 'Query',
  permittedWarehouses: [],
};
