/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveRolePermissionsMutationVariables = Types.Exact<{
  updateRolePermissionsInput: Types.UpdatePermissionsForRoleDto;
}>;


export type SaveRolePermissionsMutation = { __typename?: 'Mutation', updatePermissionsForRole: string };


export const SaveRolePermissionsDocument = gql`
    mutation SaveRolePermissions($updateRolePermissionsInput: UpdatePermissionsForRoleDto!) {
  updatePermissionsForRole(input: $updateRolePermissionsInput)
}
    `;
export type SaveRolePermissionsMutationFn = Apollo.MutationFunction<SaveRolePermissionsMutation, SaveRolePermissionsMutationVariables>;

/**
 * __useSaveRolePermissionsMutation__
 *
 * To run a mutation, you first call `useSaveRolePermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveRolePermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveRolePermissionsMutation, { data, loading, error }] = useSaveRolePermissionsMutation({
 *   variables: {
 *      updateRolePermissionsInput: // value for 'updateRolePermissionsInput'
 *   },
 * });
 */
export function useSaveRolePermissionsMutation(baseOptions?: Apollo.MutationHookOptions<SaveRolePermissionsMutation, SaveRolePermissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveRolePermissionsMutation, SaveRolePermissionsMutationVariables>(SaveRolePermissionsDocument, options);
      }
export type SaveRolePermissionsMutationHookResult = ReturnType<typeof useSaveRolePermissionsMutation>;
export type SaveRolePermissionsMutationResult = Apollo.MutationResult<SaveRolePermissionsMutation>;
export type SaveRolePermissionsMutationOptions = Apollo.BaseMutationOptions<SaveRolePermissionsMutation, SaveRolePermissionsMutationVariables>;