import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetPiResultProductQuery } from '@/graphql/defs/components/modals/__generated__/inventory-adjustment-result-modal.generated';
import { FullBinFragment } from '@/graphql/defs/shared-fragments/__generated__/bin.generated';
import { FullProductFragment } from '@/graphql/defs/shared-fragments/__generated__/product.generated';
import { useGetBinQuery } from '@/graphql/defs/shared-queries/__generated__/get-bin.generated';
import { ModalActions, ModalContent } from '@components/modal';
import { ModalButton } from '@components/modal/modal-button';
import { CurrenciesCC } from '@constants/currencies';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { IInventoryAdjustmentResultModal } from '@models/modal';
import StatusValues from '@models/StatusValues';

export const InventoryAdjustmentResultModal = () => {
  const { t } = useTranslation('components');

  const { modal, closeModal, isPreparing, setPreparing } =
    useModalContent<IInventoryAdjustmentResultModal>();

  const {
    called: calledProduct,
    loading: isLoadingProduct,
    data: { product } = { product: {} as FullProductFragment },
  } = useGetPiResultProductQuery({ variables: { id: modal.approvalTask.productId } });

  const {
    called: calledBin,
    loading: isLoadingBin,
    data: { bin } = { bin: {} as FullBinFragment },
  } = useGetBinQuery({ variables: { id: modal.approvalTask.destinationBinId } });

  const baseUom = useMemo(() => {
    if (product) {
      return product.unitOfMeasures?.nodes?.find((uom) => uom.isBaseUom);
    }
    return;
  }, [product]);

  const onDone = () => {
    closeModal();
  };

  useEffect(() => {
    setPreparing(!calledBin || !calledProduct || isLoadingProduct || isLoadingBin);
  }, [calledBin, calledProduct, isLoadingProduct, isLoadingBin]);

  return (
    !isPreparing && (
      <>
        <ModalContent>
          <Box sx={{ height: 150, width: 650 }}>
            <Grid container>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('common.product')}:
                </Typography>
              </Grid>
              <Grid xs={9} item>
                <Typography variant="body1" align="left">
                  <strong>{product?.code}</strong>
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('common.description')}:
                </Typography>
              </Grid>
              <Grid xs={9} item>
                <Typography variant="body1" align="left">
                  {product?.description}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('common.area')}:
                </Typography>
              </Grid>
              <Grid xs={9} item>
                <Typography variant="body1" align="left">
                  {bin?.area?.code}
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('common.bin')}:
                </Typography>
              </Grid>
              <Grid xs={9} item>
                <Typography variant="body1" align="left">
                  {bin?.code}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ height: 240 }}>
            <Grid container>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('modal.task.currentCount')}:
                </Typography>
              </Grid>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  <strong>
                    {modal.approvalTask.metaData.startingBinInventory} {baseUom?.code}
                  </strong>
                </Typography>
              </Grid>
              {/* ======================================================================== */}
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('modal.task.newCount')}:
                </Typography>
              </Grid>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  <strong>
                    {Number(modal.approvalTask.metaData.startingBinInventory) +
                      Number(modal.approvalTask.metaData.binQuantityChange)}{' '}
                    {baseUom?.code}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
            {/* ======================================================================== */}
            <Box sx={{ height: 15 }} />
            <Grid container>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('modal.task.qtyDifference')}:
                </Typography>
              </Grid>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  <strong>
                    {modal.approvalTask.metaData.binQuantityChange} {baseUom?.code}
                  </strong>
                </Typography>
              </Grid>
              {/* ======================================================================== */}
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('modal.task.valueDifference')}:
                </Typography>
              </Grid>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  <strong>
                    {t(`currency.${modal.approvalTask.metaData.currency as CurrenciesCC}.symbol`)}
                    {modal.approvalTask.metaData.valueDifference}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
            {/* ======================================================================== */}
            <Box sx={{ height: 15 }} />
            <Grid container>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('tasks.taskCode')}:
                </Typography>
              </Grid>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  <strong>{modal.approvalTask.code}</strong>
                </Typography>
              </Grid>
              {/* ======================================================================== */}
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('tasks.taskStatus')}:
                </Typography>
              </Grid>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  <strong>{modal.approvalTask.status}</strong>
                </Typography>
              </Grid>
            </Grid>
            {/* ======================================================================== */}
            <Box sx={{ height: 15 }} />
            <Grid container>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('tasks.taskType')}:
                </Typography>
              </Grid>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  <strong>{modal.approvalTask?.taskType?.code}</strong>
                </Typography>
              </Grid>
              {/* ======================================================================== */}
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('common.synchronized')}:
                </Typography>
              </Grid>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  <strong>
                    {modal.approvalTask.status?.toLowerCase() ===
                    StatusValues.Complete.toLowerCase()
                      ? t('common.true')
                      : t('common.false')}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
            <Box sx={{ height: 15 }} />
            <Grid container>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('common.documentNumber')}:
                </Typography>
              </Grid>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  <strong>{modal.approvalTask?.metaData?.PhysicalInventoryDocument}</strong>
                </Typography>
              </Grid>
              {/* ======================================================================== */}
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  {t('common.documentStatus')}:
                </Typography>
              </Grid>
              <Grid xs={3} item>
                <Typography variant="body1" align="left">
                  <strong>{modal.approvalTask.metaData.documentStatus}</strong>
                </Typography>
              </Grid>
            </Grid>
            {/* ======================================================================== */}
          </Box>
        </ModalContent>
        <ModalActions>
          <ModalButton variant="contained" color="primary" onClick={onDone} actionType="submit">
            {t('common.done')}
          </ModalButton>
        </ModalActions>
      </>
    )
  );
};
