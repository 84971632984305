import { SxProps, Theme, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import { OverridableStringUnion } from '@mui/types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DeliveryCompletionStatus,
  ProductAvailability,
  SlottingAnalysisStatus,
  SlottingLoadStatus,
  TaskStatus,
  WarehouseOpFileRowStatus,
} from '@/graphql/types.generated';
import { RunStatus } from '@components/data-table/hooks/shared-columns/useCreateSlottingRunsColumns';
import useStatusColor from '@hooks/useStatusColor';
import StatusValues from '@models/StatusValues';

interface IStatusTypography {
  status:
    | StatusValues
    | DeliveryCompletionStatus
    | ProductAvailability
    | TaskStatus
    | WarehouseOpFileRowStatus
    | SlottingLoadStatus
    | SlottingAnalysisStatus
    | RunStatus;
  display?: string;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  bold?: boolean;
  variant?: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>;
  uppercase?: boolean;
  fontSize?: string;
  dataTestId?: string;
  sx?: SxProps<Theme>;
}
const StatusTypography: FC<IStatusTypography> = ({
  status,
  display,
  align,
  bold,
  variant,
  uppercase = false,
  dataTestId,
  sx = {},
}) => {
  const { t } = useTranslation('components');
  const statusColor = useStatusColor(status);

  if (!status) return <></>;
  return (
    <Typography
      data-testid={`${dataTestId}`}
      variant={variant || 'body1'}
      align={align || 'left'}
      sx={{
        ...sx,
        color: statusColor,
        fontWeight: bold ? 'bold' : 'normal',
        textTransform: uppercase ? 'uppercase' : 'none',
      }}
    >
      {display ? display : t(`common.${status}`)}
    </Typography>
  );
};

export default StatusTypography;
