/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListEquipmentTypesQueryVariables = Types.Exact<{
  sorting?: Types.InputMaybe<Array<Types.EquipmentTypeSort> | Types.EquipmentTypeSort>;
  filter?: Types.InputMaybe<Types.EquipmentTypeFilter>;
  paging?: Types.InputMaybe<Types.OffsetPaging>;
}>;


export type ListEquipmentTypesQuery = { __typename?: 'Query', listEquipmentTypes: { __typename?: 'EquipmentTypeOffsetConnection', equipmentTypes: Array<{ __typename?: 'EquipmentType', id: string, code: string, label: string, description?: string | null }> } };


export const ListEquipmentTypesDocument = gql`
    query ListEquipmentTypes($sorting: [EquipmentTypeSort!], $filter: EquipmentTypeFilter, $paging: OffsetPaging) {
  listEquipmentTypes: equipmentTypes(
    sorting: $sorting
    filter: $filter
    paging: $paging
  ) {
    equipmentTypes: nodes {
      id
      code
      label
      description
    }
  }
}
    `;

/**
 * __useListEquipmentTypesQuery__
 *
 * To run a query within a React component, call `useListEquipmentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEquipmentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEquipmentTypesQuery({
 *   variables: {
 *      sorting: // value for 'sorting'
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *   },
 * });
 */
export function useListEquipmentTypesQuery(baseOptions?: Apollo.QueryHookOptions<ListEquipmentTypesQuery, ListEquipmentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListEquipmentTypesQuery, ListEquipmentTypesQueryVariables>(ListEquipmentTypesDocument, options);
      }
export function useListEquipmentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListEquipmentTypesQuery, ListEquipmentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListEquipmentTypesQuery, ListEquipmentTypesQueryVariables>(ListEquipmentTypesDocument, options);
        }
export type ListEquipmentTypesQueryHookResult = ReturnType<typeof useListEquipmentTypesQuery>;
export type ListEquipmentTypesLazyQueryHookResult = ReturnType<typeof useListEquipmentTypesLazyQuery>;
export type ListEquipmentTypesQueryResult = Apollo.QueryResult<ListEquipmentTypesQuery, ListEquipmentTypesQueryVariables>;