import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Section, Separator, StepWrapper } from '@components/modal/ruleset/steps';

const Step1 = ({ active, control, review }) => {
  const { t } = useTranslation('components');
  return (
    <StepWrapper active={active} review={review}>
      <Section section="naming">
        <Controller
          name="name"
          control={control}
          rules={{
            required: t('form.validation.requiredField', { field: t('common.name') }),
          }}
          render={({ field, fieldState }) => (
            <TextField
              sx={{ width: 300 }}
              required
              id="ruleset-name"
              label={t('common.name')}
              {...field}
              ref={null}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
            />
          )}
        />
      </Section>
      <Separator />
      <Section section="maxMovements">
        <Controller
          name="maxMovements"
          control={control}
          rules={{
            required: t('form.validation.requiredField', {
              field: t('modal.ruleset.maxMovements'),
            }),
          }}
          render={({ field, fieldState }) => (
            <TextField
              sx={{ width: 300 }}
              required
              type="number"
              id="ruleset-maxMovements"
              label={t('modal.ruleset.maxMovements')}
              {...field}
              ref={null}
              error={!!fieldState?.error}
              helperText={fieldState?.error?.message}
            />
          )}
        />
      </Section>
      <Separator />
      <Section section="forecasting">
        <Controller
          name="forecasting"
          control={control}
          render={({ field }) => {
            return (
              <RadioGroup {...field} name={t(`modal.ruleset.forecasting`)}>
                <FormControlLabel
                  value="historical"
                  control={<Radio />}
                  label={t(`modal.ruleset.historicalOrders`)}
                />
                <FormControlLabel
                  value="future"
                  control={<Radio />}
                  label={t(`modal.ruleset.futureDemand`)}
                />
              </RadioGroup>
            );
          }}
        />
      </Section>
    </StepWrapper>
  );
};

export default Step1;
