/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ViewUoMFragment = { __typename?: 'ViewUnitOfMeasureProductConversion', id?: string | null, productId?: string | null, code?: string | null, label?: string | null, description?: string | null, conversionFactor?: number | null, height?: number | null, length?: number | null, width?: number | null, dimensionUOMId?: string | null, dimensionUOMCode?: string | null, dimensionUOMLabel?: string | null, grossWeight?: number | null, weightUOMId?: string | null, weightUOMCode?: string | null, weightUOMLabel?: string | null, volume?: number | null, volumeUOMId?: string | null, volumeUOMCode?: string | null, volumeUOMLabel?: string | null, isBaseUom?: boolean | null };

export type ViewUoMsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ViewUnitOfMeasureProductConversionFilter>;
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  sorting?: Types.InputMaybe<Array<Types.ViewUnitOfMeasureProductConversionSort> | Types.ViewUnitOfMeasureProductConversionSort>;
}>;


export type ViewUoMsQuery = { __typename?: 'Query', unitOfMeasures: { __typename?: 'ViewUnitOfMeasureProductConversionOffsetConnection', nodes: Array<{ __typename?: 'ViewUnitOfMeasureProductConversion', id?: string | null, productId?: string | null, code?: string | null, label?: string | null, description?: string | null, conversionFactor?: number | null, height?: number | null, length?: number | null, width?: number | null, dimensionUOMId?: string | null, dimensionUOMCode?: string | null, dimensionUOMLabel?: string | null, grossWeight?: number | null, weightUOMId?: string | null, weightUOMCode?: string | null, weightUOMLabel?: string | null, volume?: number | null, volumeUOMId?: string | null, volumeUOMCode?: string | null, volumeUOMLabel?: string | null, isBaseUom?: boolean | null }> } };

export const ViewUoMFragmentDoc = gql`
    fragment ViewUoM on ViewUnitOfMeasureProductConversion {
  id
  productId
  code
  label
  description
  conversionFactor
  height
  length
  width
  dimensionUOMId
  dimensionUOMCode
  dimensionUOMLabel
  grossWeight
  weightUOMId
  weightUOMCode
  weightUOMLabel
  volume
  volumeUOMId
  volumeUOMCode
  volumeUOMLabel
  isBaseUom
}
    `;
export const ViewUoMsDocument = gql`
    query ViewUoMs($filter: ViewUnitOfMeasureProductConversionFilter, $paging: OffsetPaging, $sorting: [ViewUnitOfMeasureProductConversionSort!]) {
  unitOfMeasures: viewUnitOfMeasureProductConversions(
    filter: $filter
    paging: $paging
    sorting: $sorting
  ) {
    nodes {
      ...ViewUoM
    }
  }
}
    ${ViewUoMFragmentDoc}`;

/**
 * __useViewUoMsQuery__
 *
 * To run a query within a React component, call `useViewUoMsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewUoMsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewUoMsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      paging: // value for 'paging'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useViewUoMsQuery(baseOptions?: Apollo.QueryHookOptions<ViewUoMsQuery, ViewUoMsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewUoMsQuery, ViewUoMsQueryVariables>(ViewUoMsDocument, options);
      }
export function useViewUoMsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewUoMsQuery, ViewUoMsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewUoMsQuery, ViewUoMsQueryVariables>(ViewUoMsDocument, options);
        }
export type ViewUoMsQueryHookResult = ReturnType<typeof useViewUoMsQuery>;
export type ViewUoMsLazyQueryHookResult = ReturnType<typeof useViewUoMsLazyQuery>;
export type ViewUoMsQueryResult = Apollo.QueryResult<ViewUoMsQuery, ViewUoMsQueryVariables>;