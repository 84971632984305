import { DateTime } from 'luxon';
import { useEffect } from 'react';

import {
  ColumnFilterBetweenValue,
  ColumnFilterValues,
  FilterOperators,
} from '@components/data-table/controls/filter/filter-definitions';
import { useDateTime } from '@context/date-time';

const useDateFilterAdjustment = (
  inputValue: ColumnFilterValues,
  operator: FilterOperators,
  setValue: (value: ColumnFilterValues) => void,
  isDateTime: boolean = false,
) => {
  const { dateTimeToWHTimezone, todaysDateTimeRange } = useDateTime();

  useEffect(() => {
    const today = todaysDateTimeRange[0];
    const typedInputValue = inputValue as ColumnFilterBetweenValue;

    const isBetweenOperatorWithBadInput =
      [FilterOperators.eq, FilterOperators.between, FilterOperators.notBetween].includes(
        operator,
      ) &&
      (!inputValue ||
        inputValue instanceof DateTime ||
        !typedInputValue.lower ||
        !typedInputValue.upper);
    const isNotBetweenOperatorWithBadInput =
      ![FilterOperators.eq, FilterOperators.between, FilterOperators.notBetween].includes(
        operator,
      ) &&
      (!inputValue || !(inputValue instanceof DateTime));

    if (isBetweenOperatorWithBadInput) {
      if (typedInputValue?.lower === null || typedInputValue?.upper === null) {
        setAdjustedValue(today);
      } else {
        setAdjustedValue(inputValue || today);
      }
    } else if (isNotBetweenOperatorWithBadInput) {
      setAdjustedValue(typedInputValue?.lower || today);
    }
  }, [operator]);

  const setAdjustedValue = (date) => {
    let newDate: DateTime = dateTimeToWHTimezone(date);
    if ([FilterOperators.gt, FilterOperators.lte].includes(operator)) {
      setValue(isDateTime ? newDate : newDate.endOf('day'));
    } else if ([FilterOperators.lt, FilterOperators.gte].includes(operator)) {
      setValue(isDateTime ? newDate : newDate.startOf('day'));
    } else if (
      [
        FilterOperators.eq,
        FilterOperators.neq,
        FilterOperators.between,
        FilterOperators.notBetween,
      ].includes(operator)
    ) {
      setValue({
        lower: isDateTime ? newDate : newDate.startOf('day'),
        upper: newDate.endOf('day'),
      });
    }
  };

  return setAdjustedValue;
};

export default useDateFilterAdjustment;
