/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateWarehouseRoleTypeMutationVariables = Types.Exact<{
  input: Types.WarehouseRoleTypeCreateOneInput;
}>;


export type CreateWarehouseRoleTypeMutation = { __typename?: 'Mutation', warehouseRoleType: { __typename?: 'WarehouseRoleType', code: string } };

export type DeleteWarehouseRoleTypeMutationVariables = Types.Exact<{
  input: Types.DeleteOneEntityIdInput;
}>;


export type DeleteWarehouseRoleTypeMutation = { __typename?: 'Mutation', warehouseRoleType: { __typename?: 'WarehouseRoleType', code: string } };

export type UpdateWarehouseRoleTypeMutationVariables = Types.Exact<{
  input: Types.WarehouseRoleTypeUpdateOneInput;
}>;


export type UpdateWarehouseRoleTypeMutation = { __typename?: 'Mutation', warehouseRoleType: { __typename?: 'WarehouseRoleType', code: string } };


export const CreateWarehouseRoleTypeDocument = gql`
    mutation CreateWarehouseRoleType($input: WarehouseRoleTypeCreateOneInput!) {
  warehouseRoleType: createOneWarehouseRoleType(input: $input) {
    code
  }
}
    `;
export type CreateWarehouseRoleTypeMutationFn = Apollo.MutationFunction<CreateWarehouseRoleTypeMutation, CreateWarehouseRoleTypeMutationVariables>;

/**
 * __useCreateWarehouseRoleTypeMutation__
 *
 * To run a mutation, you first call `useCreateWarehouseRoleTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWarehouseRoleTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWarehouseRoleTypeMutation, { data, loading, error }] = useCreateWarehouseRoleTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWarehouseRoleTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateWarehouseRoleTypeMutation, CreateWarehouseRoleTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWarehouseRoleTypeMutation, CreateWarehouseRoleTypeMutationVariables>(CreateWarehouseRoleTypeDocument, options);
      }
export type CreateWarehouseRoleTypeMutationHookResult = ReturnType<typeof useCreateWarehouseRoleTypeMutation>;
export type CreateWarehouseRoleTypeMutationResult = Apollo.MutationResult<CreateWarehouseRoleTypeMutation>;
export type CreateWarehouseRoleTypeMutationOptions = Apollo.BaseMutationOptions<CreateWarehouseRoleTypeMutation, CreateWarehouseRoleTypeMutationVariables>;
export const DeleteWarehouseRoleTypeDocument = gql`
    mutation DeleteWarehouseRoleType($input: DeleteOneEntityIDInput!) {
  warehouseRoleType: deleteOneWarehouseRoleType(input: $input) {
    code
  }
}
    `;
export type DeleteWarehouseRoleTypeMutationFn = Apollo.MutationFunction<DeleteWarehouseRoleTypeMutation, DeleteWarehouseRoleTypeMutationVariables>;

/**
 * __useDeleteWarehouseRoleTypeMutation__
 *
 * To run a mutation, you first call `useDeleteWarehouseRoleTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWarehouseRoleTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWarehouseRoleTypeMutation, { data, loading, error }] = useDeleteWarehouseRoleTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWarehouseRoleTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWarehouseRoleTypeMutation, DeleteWarehouseRoleTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWarehouseRoleTypeMutation, DeleteWarehouseRoleTypeMutationVariables>(DeleteWarehouseRoleTypeDocument, options);
      }
export type DeleteWarehouseRoleTypeMutationHookResult = ReturnType<typeof useDeleteWarehouseRoleTypeMutation>;
export type DeleteWarehouseRoleTypeMutationResult = Apollo.MutationResult<DeleteWarehouseRoleTypeMutation>;
export type DeleteWarehouseRoleTypeMutationOptions = Apollo.BaseMutationOptions<DeleteWarehouseRoleTypeMutation, DeleteWarehouseRoleTypeMutationVariables>;
export const UpdateWarehouseRoleTypeDocument = gql`
    mutation UpdateWarehouseRoleType($input: WarehouseRoleTypeUpdateOneInput!) {
  warehouseRoleType: updateOneWarehouseRoleType(input: $input) {
    code
  }
}
    `;
export type UpdateWarehouseRoleTypeMutationFn = Apollo.MutationFunction<UpdateWarehouseRoleTypeMutation, UpdateWarehouseRoleTypeMutationVariables>;

/**
 * __useUpdateWarehouseRoleTypeMutation__
 *
 * To run a mutation, you first call `useUpdateWarehouseRoleTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWarehouseRoleTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWarehouseRoleTypeMutation, { data, loading, error }] = useUpdateWarehouseRoleTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWarehouseRoleTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWarehouseRoleTypeMutation, UpdateWarehouseRoleTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWarehouseRoleTypeMutation, UpdateWarehouseRoleTypeMutationVariables>(UpdateWarehouseRoleTypeDocument, options);
      }
export type UpdateWarehouseRoleTypeMutationHookResult = ReturnType<typeof useUpdateWarehouseRoleTypeMutation>;
export type UpdateWarehouseRoleTypeMutationResult = Apollo.MutationResult<UpdateWarehouseRoleTypeMutation>;
export type UpdateWarehouseRoleTypeMutationOptions = Apollo.BaseMutationOptions<UpdateWarehouseRoleTypeMutation, UpdateWarehouseRoleTypeMutationVariables>;