/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CountBinModalFragment = { __typename?: 'ViewBin', id?: string | null, code?: string | null, lastCount?: string | null, lastMovement?: string | null, hasOpenTasks?: boolean | null, containsProducts?: boolean | null };

export type RetrieveBinDetailsForCountBinModalQueryVariables = Types.Exact<{
  binIds: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input'];
}>;


export type RetrieveBinDetailsForCountBinModalQuery = { __typename?: 'Query', viewBins: { __typename?: 'ViewBinOffsetConnection', bins: Array<{ __typename?: 'ViewBin', id?: string | null, code?: string | null, lastCount?: string | null, lastMovement?: string | null, hasOpenTasks?: boolean | null, containsProducts?: boolean | null }> } };

export type CreateCountBinTaskMutationVariables = Types.Exact<{
  binId: Types.Scalars['ID']['input'];
  dueDate?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
}>;


export type CreateCountBinTaskMutation = { __typename?: 'Mutation', createCountBinTask: { __typename?: 'Task', id: string, code: string } };

export const CountBinModalFragmentDoc = gql`
    fragment CountBinModal on ViewBin {
  id
  code
  lastCount
  lastMovement
  hasOpenTasks
  containsProducts
}
    `;
export const RetrieveBinDetailsForCountBinModalDocument = gql`
    query RetrieveBinDetailsForCountBinModal($binIds: [ID!]!) {
  viewBins(filter: {id: {in: $binIds}}, sorting: [{field: code, direction: ASC}]) {
    bins: nodes {
      ...CountBinModal
    }
  }
}
    ${CountBinModalFragmentDoc}`;

/**
 * __useRetrieveBinDetailsForCountBinModalQuery__
 *
 * To run a query within a React component, call `useRetrieveBinDetailsForCountBinModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveBinDetailsForCountBinModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveBinDetailsForCountBinModalQuery({
 *   variables: {
 *      binIds: // value for 'binIds'
 *   },
 * });
 */
export function useRetrieveBinDetailsForCountBinModalQuery(baseOptions: Apollo.QueryHookOptions<RetrieveBinDetailsForCountBinModalQuery, RetrieveBinDetailsForCountBinModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetrieveBinDetailsForCountBinModalQuery, RetrieveBinDetailsForCountBinModalQueryVariables>(RetrieveBinDetailsForCountBinModalDocument, options);
      }
export function useRetrieveBinDetailsForCountBinModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetrieveBinDetailsForCountBinModalQuery, RetrieveBinDetailsForCountBinModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetrieveBinDetailsForCountBinModalQuery, RetrieveBinDetailsForCountBinModalQueryVariables>(RetrieveBinDetailsForCountBinModalDocument, options);
        }
export type RetrieveBinDetailsForCountBinModalQueryHookResult = ReturnType<typeof useRetrieveBinDetailsForCountBinModalQuery>;
export type RetrieveBinDetailsForCountBinModalLazyQueryHookResult = ReturnType<typeof useRetrieveBinDetailsForCountBinModalLazyQuery>;
export type RetrieveBinDetailsForCountBinModalQueryResult = Apollo.QueryResult<RetrieveBinDetailsForCountBinModalQuery, RetrieveBinDetailsForCountBinModalQueryVariables>;
export const CreateCountBinTaskDocument = gql`
    mutation CreateCountBinTask($binId: ID!, $dueDate: DateTime) {
  createCountBinTask: createOnePhysicalInventoryTaskByBin(
    input: {physicalInventoryByBin: {binId: $binId, dueDate: $dueDate}}
  ) {
    id
    code
  }
}
    `;
export type CreateCountBinTaskMutationFn = Apollo.MutationFunction<CreateCountBinTaskMutation, CreateCountBinTaskMutationVariables>;

/**
 * __useCreateCountBinTaskMutation__
 *
 * To run a mutation, you first call `useCreateCountBinTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCountBinTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCountBinTaskMutation, { data, loading, error }] = useCreateCountBinTaskMutation({
 *   variables: {
 *      binId: // value for 'binId'
 *      dueDate: // value for 'dueDate'
 *   },
 * });
 */
export function useCreateCountBinTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateCountBinTaskMutation, CreateCountBinTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCountBinTaskMutation, CreateCountBinTaskMutationVariables>(CreateCountBinTaskDocument, options);
      }
export type CreateCountBinTaskMutationHookResult = ReturnType<typeof useCreateCountBinTaskMutation>;
export type CreateCountBinTaskMutationResult = Apollo.MutationResult<CreateCountBinTaskMutation>;
export type CreateCountBinTaskMutationOptions = Apollo.BaseMutationOptions<CreateCountBinTaskMutation, CreateCountBinTaskMutationVariables>;