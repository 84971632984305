/* eslint-disable */
import * as Types from '../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListFulfillmentBlocksQueryVariables = Types.Exact<{
  paging?: Types.InputMaybe<Types.OffsetPaging>;
  filter?: Types.InputMaybe<Types.FulfillmentBlockFilter>;
  sorting?: Types.InputMaybe<Array<Types.FulfillmentBlockSort> | Types.FulfillmentBlockSort>;
}>;


export type ListFulfillmentBlocksQuery = { __typename?: 'Query', listFulfillmentBlocks: { __typename?: 'FulfillmentBlockOffsetConnection', totalCount: number, fulfillmentBlocks: Array<{ __typename?: 'FulfillmentBlock', id: string, createdAt: string, updatedAt?: string | null, deletedAt?: string | null, label: string, description?: string | null }> } };


export const ListFulfillmentBlocksDocument = gql`
    query ListFulfillmentBlocks($paging: OffsetPaging, $filter: FulfillmentBlockFilter, $sorting: [FulfillmentBlockSort!]) {
  listFulfillmentBlocks: fulfillmentBlocks(
    paging: $paging
    filter: $filter
    sorting: $sorting
  ) {
    fulfillmentBlocks: nodes {
      id
      createdAt
      updatedAt
      deletedAt
      label
      description
    }
    totalCount
  }
}
    `;

/**
 * __useListFulfillmentBlocksQuery__
 *
 * To run a query within a React component, call `useListFulfillmentBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFulfillmentBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFulfillmentBlocksQuery({
 *   variables: {
 *      paging: // value for 'paging'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useListFulfillmentBlocksQuery(baseOptions?: Apollo.QueryHookOptions<ListFulfillmentBlocksQuery, ListFulfillmentBlocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListFulfillmentBlocksQuery, ListFulfillmentBlocksQueryVariables>(ListFulfillmentBlocksDocument, options);
      }
export function useListFulfillmentBlocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListFulfillmentBlocksQuery, ListFulfillmentBlocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListFulfillmentBlocksQuery, ListFulfillmentBlocksQueryVariables>(ListFulfillmentBlocksDocument, options);
        }
export type ListFulfillmentBlocksQueryHookResult = ReturnType<typeof useListFulfillmentBlocksQuery>;
export type ListFulfillmentBlocksLazyQueryHookResult = ReturnType<typeof useListFulfillmentBlocksLazyQuery>;
export type ListFulfillmentBlocksQueryResult = Apollo.QueryResult<ListFulfillmentBlocksQuery, ListFulfillmentBlocksQueryVariables>;