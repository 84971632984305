// anything associated with putaway = 219653
// anything associated with pick = 5EB1E3
// anything associated with PI = F2C94C
// anything associated with damage = EB5757
// anything associated with cycle count = F2994A

// binToBinMovement = 'TSK-01',
// inventoryCount = 'TSK-02', ***
// inventoryCountApproval = 'TSK-03', ***
// deliveryItemToBinMovement = 'TSK-04',
// initialImport = 'TSK-05',
// deliveryPutawayTask = 'TSK-06', ***
// fulfillmentPickTask = 'TSK-07' ***

export const taskTypeColors = {
  'TSK-01': '#27187E',
  'TSK-02': '#F2994A',
  'TSK-03': '#F2994A',
  'TSK-04': '#18713E',
  'TSK-05': '#18713E',
  'TSK-06': '#5EB1E3',
  'TSK-07': '#256999',
  'TSK-08': '#27187E',
  'TSK-09': '#18713E',
  'TSK-10': '#18713E',
  'TSK-11': '#FF7C7C',
  'TSK-12': '#F4CB55',
  'TSK-13': '#5EB1E3',
  'TSK-14': '#18713E',
  'TSK-15': '#5EB1E3',
  'TSK-16': '#5EB1E3',
  'TSK-17': '#256999',
  'TSK-18': '#256999',
  'TSK-19': '#256999',
  'TSK-20': '#256999',
  'TSK-21': '#256999',
  'TSK-22': '#256999',
  'TSK-23': '#256999',
  'TSK-24': '#256999',
  'TSK-25': '#256999',
};

export enum TaskTypeCodes {
  binToBinMovement = 'TSK-01',
  inventoryCount = 'TSK-02',
  inventoryCountApproval = 'TSK-03',
  deliveryPutawayTask = 'TSK-04',
  initialImport = 'TSK-05',
  fulfillmentPickTask = 'TSK-06',
  licensePlateMovement = 'TSK-07',
  licensePlateBinToBinMovement = 'TSK-08',
  deliveryBinImport = 'TSK-09',
  licensePlatePutaway = 'TSK-10',
  licensePlateUnpackTask = 'TSK-11',
  licensePlateMerge = 'TSK-12',
  licensePlatePick = 'TSK-13',
  licensePlateLoad = 'TSK-21',
  unload = 'TSK-14',
  pickToStaging = 'TSK-15',
  load = 'TSK-16',
}
