import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useBaseInventoryBinLevelTableLazyQuery,
  useBaseInventoryTableLazyQuery,
} from '@/graphql/defs/hooks/shared-columns/__generated__/useBaseInventoryColumns.generated';
import { LicensePlateStatusState } from '@/graphql/types.generated';
import { ColumnTypes } from '@components/data-table/controls/filter/filter-definitions';
import { TColumnFactory } from '@components/data-table/hooks/useCreateDataTableColumns';
import useDataTableEnumList from '@components/data-table/hooks/useDataTableEnumLists';
import filterDataTableColumnDefs from '@components/data-table/lib/filterDataTableColumnDefs';
import {
  TExtractLazyHookDataType,
  TExtractLazyHookFetchFilterType,
  TExtractLazyHookFieldNames,
} from '@components/data-table/model/extract-query-hook-types';
import ErpSalesOrderLinkCell from '@components/data-table/table/cells/ErpSalesOrderLinkCell';
import LicensePlateLinkCell from '@components/data-table/table/cells/LicensePlateLinkCell';
import LinkCell from '@components/data-table/table/cells/LinkCell';
import QuantityConversionsTooltip from '@components/tooltips/quantity-conversions';
import {
  BIN,
  BUSINESS_PARTNER,
  INBOUND_DELIVERY,
  LOT,
  OUTBOUND_DELIVERY,
  PRODUCT,
} from '@constants/routes';
import { useWarehouseFeatureFlags } from '@context/warehouse-feature-flags';
import enumKeys from '@lib/enum-keys';
import translateLpStatus from '@lib/translateLpStatus';

export const BASE_INV_HOOK = useBaseInventoryBinLevelTableLazyQuery;
export const BASE_INV_ALL_HOOK = useBaseInventoryTableLazyQuery;
export type TBaseInvDataType = TExtractLazyHookDataType<typeof BASE_INV_HOOK>;
export type TBaseInvFilterType = TExtractLazyHookFetchFilterType<typeof BASE_INV_HOOK>;
export type TBaseInvFieldNames = TExtractLazyHookFieldNames<typeof BASE_INV_HOOK>;

interface ICreatedBaseInventoryColumns {
  dataTestId: string;
  removeColumns?: (keyof TBaseInvDataType)[];
}
const useCreateBaseInventoryColumns = ({
  dataTestId,
  removeColumns = [],
}: ICreatedBaseInventoryColumns) => {
  const { t } = useTranslation('pages', { keyPrefix: 'inventory' });
  const { t: tC } = useTranslation('components');
  const {
    warehouseFeatureFlags: { inventory: inventoryFlags },
  } = useWarehouseFeatureFlags();

  const { stockStatusTypeEnumList, unitOfMeasureEnumList } = useDataTableEnumList({
    fetchStockStatusTypeList: true,
    fetchUoMList: true,
  });

  const createBaseInventoryColumns = useCallback<TColumnFactory<TBaseInvDataType>>(
    (columnHelper) => {
      const columns = [
        columnHelper.accessor('binCode', {
          header: t('columns.binCode'),
          cell: ({ row, getValue }) => (
            <LinkCell
              href={`${BIN}/${row.original.binId}`}
              text={getValue()}
              dataTestId={`${dataTestId}-bin-link`}
            />
          ),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('areaCode', {
          header: t('columns.areaCode'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('productCode', {
          header: t('columns.productCode'),
          cell: ({ row, getValue }) => (
            <LinkCell
              href={`${PRODUCT}/${row.original.productId}`}
              text={getValue()}
              dataTestId={`${dataTestId}-product-link`}
            />
          ),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('productDescription', {
          header: t('columns.productDescription'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('stockStatus', {
          header: t('columns.stockStatus'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.enum,
            options: stockStatusTypeEnumList,
          },
        }),
        columnHelper.accessor('quantity', {
          header: t('columns.qtyOnHand'),
          cell: ({ row, getValue }) => (
            <QuantityConversionsTooltip
              quantity={getValue()}
              unitOfMeasureId={row.original.unitOfMeasureId}
              anchor={getValue()}
            />
          ),
          meta: {
            columnType: ColumnTypes.stringRange,
          },
        }),
        columnHelper.accessor('unitOfMeasure', {
          header: t('columns.uom'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.enum,
            options: unitOfMeasureEnumList,
          },
        }),
        columnHelper.accessor('openTaskCount', {
          header: t('columns.openTaskCount'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.number,
          },
        }),
        columnHelper.accessor('availableQuantity', {
          header: t('columns.availableQty'),
          cell: ({ row, getValue }) => (
            <QuantityConversionsTooltip
              quantity={getValue()}
              unitOfMeasureId={row.original.unitOfMeasureId}
              anchor={getValue()}
            />
          ),
          meta: {
            columnType: ColumnTypes.stringRange,
          },
        }),
        columnHelper.accessor('lotCode', {
          header: t('columns.lotCode'),
          cell: ({ row, getValue }) => (
            <LinkCell
              href={`${LOT}/${row.original.lotId}`}
              text={getValue()}
              dataTestId={`${dataTestId}-lot-link`}
            />
          ),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('licensePlateCode', {
          header: t('columns.licensePlateCode'),
          cell: ({ row, getValue }) => (
            <LicensePlateLinkCell
              lpId={row.original.licensePlateId}
              lpStatus={row.original.licensePlateStatus}
              value={getValue()}
              dataTestId={dataTestId}
            />
          ),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('licensePlateDescription', {
          header: tC('common.licensePlateDescription'),
          cell: ({ getValue }) => getValue(),
          meta: {
            columnType: ColumnTypes.string,
          },
        }),
        columnHelper.accessor('licensePlateStatus', {
          header: tC('common.licensePlateStatus'),
          cell: ({ getValue, row }) =>
            row.original.licensePlateId ? translateLpStatus(getValue()) : '',
          meta: {
            columnType: ColumnTypes.enum,
            options: enumKeys(LicensePlateStatusState).map((status) => ({
              value: LicensePlateStatusState[status],
              display: translateLpStatus(LicensePlateStatusState[status]),
            })),
            exportFormatter: (value, row) => (row.licensePlateId ? translateLpStatus(value) : ''),
          },
        }),
        inventoryFlags.deliveryAssociated
          ? columnHelper.accessor('erpSalesOrder', {
              header: tC('common.salesOrder'),
              cell: ({ getValue }) => (
                <ErpSalesOrderLinkCell erpSalesOrder={getValue()} dataTestId={dataTestId} />
              ),
              meta: {
                columnType: ColumnTypes.string,
              },
            })
          : null,
        inventoryFlags.deliveryAssociated
          ? columnHelper.accessor('stockDeliveryCode', {
              header: tC('common.deliveryCode'),
              cell: ({ row, getValue }) => (
                <LinkCell
                  href={`${INBOUND_DELIVERY}/${row.original.stockDeliveryId}`}
                  text={getValue()}
                  dataTestId={`${dataTestId}-delivery-link`}
                />
              ),
              meta: {
                columnType: ColumnTypes.string,
              },
            })
          : null,
        inventoryFlags.deliveryAssociated
          ? columnHelper.accessor('deliveryItem', {
              header: tC('common.deliveryItem'),
              cell: ({ getValue }) => getValue(),
              meta: {
                columnType: ColumnTypes.string,
              },
            })
          : null,
        inventoryFlags.deliveryAssociated
          ? columnHelper.accessor('stockFulfillmentCode', {
              header: tC('common.fulfillmentCode'),
              cell: ({ row, getValue }) => (
                <LinkCell
                  href={`${OUTBOUND_DELIVERY}/${row.original.stockFulfillmentId}`}
                  text={getValue()}
                  dataTestId={`${dataTestId}-fulfillment-link`}
                />
              ),
              meta: {
                columnType: ColumnTypes.string,
              },
            })
          : null,
        inventoryFlags.deliveryAssociated
          ? columnHelper.accessor('fulfillmentItem', {
              header: tC('common.fulfillmentItem'),
              cell: ({ getValue }) => getValue(),
              meta: {
                columnType: ColumnTypes.string,
              },
            })
          : null,
        inventoryFlags.deliveryAssociated
          ? columnHelper.accessor('soldToBusinessPartnerName', {
              header: tC('common.soldTo'),
              cell: ({ row, getValue }) => (
                <LinkCell
                  href={`${BUSINESS_PARTNER}/${row.original.soldToBusinessPartnerId}`}
                  text={getValue()}
                  dataTestId={`${dataTestId}-partner-link`}
                />
              ),
              meta: {
                columnType: ColumnTypes.string,
              },
            })
          : null,
      ];

      return columns.filter(filterDataTableColumnDefs<TBaseInvDataType>(removeColumns));
    },
    [stockStatusTypeEnumList, unitOfMeasureEnumList, inventoryFlags, ...removeColumns],
  );

  return createBaseInventoryColumns;
};

export default useCreateBaseInventoryColumns;
