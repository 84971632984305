import { Box, Paper } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  SlottingRunDatasetDetailsFragment,
  SlottingRunDetailsFragment,
  SlottingRunRulesetDetailsFragment,
  useGetSlottingRunDatasetAndRulesetLazyQuery,
  useGetSlottingRunDetailsPageLazyQuery,
} from '@/graphql/defs/pages/__generated__/slotting-run.generated';
import Collapse from '@components/collapse';
import DataTable from '@components/data-table';
import useSlottingAbcAnalysisDataTable from '@components/data-table/hooks/table-props/useSlottingAbcAnalysisDataTable';
import useSlottingMovementsDataTable from '@components/data-table/hooks/table-props/useSlottingMovementsDataTable';
import LinkCell from '@components/data-table/table/cells/LinkCell';
import EmptySection from '@components/empty-section';
import { IColumn, JustifiedColumns } from '@components/justified-columns';
import CollapseLoading from '@components/loading-indicator/CollapseLoading';
import PageInfo from '@components/page-info';
import ProgressBar from '@components/progress-bar';
import { SLOTTING_DATASET_RUN_SUMMARIES, SLOTTING_RULESET, SLOTTING_RUNS } from '@constants/routes';
import { useDateTime } from '@context/date-time';
import { useModalToggle } from '@context/modal/ModalToggleProvider';
import { useSnackbar } from '@context/snackbar';
import { getTransComponents } from '@lib/translation';
import { ModalTypes } from '@models/modal';
import SlottingRunMap from '@pages/map/slotting-run-map';
import Button from '@styled/Button';
import Typography from '@styled/Typography';

const SlottingRun = () => {
  const { t } = useTranslation('pages', { keyPrefix: 'run' });
  const { t: tC } = useTranslation('components');
  const { id: runId } = useParams();
  const { showMessage } = useSnackbar();
  const { displayDateTime } = useDateTime();
  const { openModal } = useModalToggle();

  const isDeployedDetails = useMemo(() => runId === 'deployed', [runId]);
  const [runDetails, setRunDetails] = useState<SlottingRunDetailsFragment | null>(null);
  const [datasetDetails, setDatasetDetails] = useState<SlottingRunDatasetDetailsFragment>(null);
  const [rulesetDetails, setRulesetDetails] = useState<SlottingRunRulesetDetailsFragment>(null);

  const [preparing, setPreparing] = useState(true);

  const [getDatasetAndRulesetDetails] = useGetSlottingRunDatasetAndRulesetLazyQuery({
    onError: (error) => {
      showMessage({
        type: 'error',
        message: t('errorLoadingRunDatasetAndRulesetDetails', { errorMessage: error.message }),
      });
      setPreparing(false);
    },
  });

  const [getSlottingRunDetails] = useGetSlottingRunDetailsPageLazyQuery({
    onError: (error) => {
      showMessage({
        type: 'error',
        message: t('errorLoadingRunDetails', { errorMessage: error.message }),
      });
      setPreparing(false);
    },
  });

  const prepare = async () => {
    try {
      const {
        data: { slottingRun, deployedRun },
      } = await getSlottingRunDetails({ variables: { id: runId, isDeployedDetails } });
      const runDetails = slottingRun || deployedRun;
      setRunDetails(runDetails);
      if (runDetails) {
        const {
          data: {
            runDataset,
            runRuleset: { nodes: _runRuleset },
          },
        } = await getDatasetAndRulesetDetails({
          variables: {
            datasetId: runDetails?.dataset_id,
            rulesetId: runDetails?.ruleset_id,
          },
        });

        setDatasetDetails(runDataset);
        setRulesetDetails(_runRuleset[0] || null);
      }
    } catch (e) {
      console.log({ e });
    }
    setPreparing(false);
  };

  useEffect(() => {
    prepare();
  }, []);

  const runDetailColumns = useMemo<IColumn[]>(
    () =>
      [
        {
          labelWidth: '120px',
          lineItems: [
            {
              label: tC('slotting.run'),
              value: runDetails?.name,
            },
            {
              label: tC('common.createdDate'),
              value: displayDateTime({ date: runDetails?.created_at }),
            },
          ],
        },
        !isDeployedDetails
          ? {
              labelWidth: '120px',
              lineItems: [
                {
                  label: tC('slotting.deployedDate'),
                  value: displayDateTime({ date: runDetails?.deployed_at }),
                },
                {
                  label: tC('dates.duration'),
                  value: runDetails?.duration ? convertDuration(runDetails?.duration) : '',
                },
              ],
            }
          : null,
        {
          labelWidth: '140px',
          lineItems: [
            {
              label: tC('slotting.distanceSaved'),
              value: runDetails?.distance_saved,
            },
            {
              label: tC('slotting.costSaved'),
              value: runDetails?.money_saved,
            },
          ],
        },
        !isDeployedDetails
          ? {
              labelWidth: '100px',
              lineItems: [],
            }
          : null,
      ].filter((v) => !!v),
    [runDetails, isDeployedDetails],
  );
  const datasetDetailsColumns = useMemo<IColumn[]>(
    () => [
      {
        labelWidth: '90px',
        lineItems: [
          {
            label: tC('slotting.dataset'),
            value: (
              <LinkCell
                href={SLOTTING_DATASET_RUN_SUMMARIES.replace(':id', datasetDetails?.id)}
                text={datasetDetails?.code}
                dataTestId="slotting-run-details-dataset-code"
              />
            ),
          },
          {
            label: tC('common.tag'),
            value: datasetDetails?.tag,
          },
        ],
      },
      {
        labelWidth: '125px',
        lineItems: [
          {
            label: tC('common.uploadDate'),
            value: displayDateTime({ date: datasetDetails?.pullDate }),
          },
          {
            label: tC('common.lastChanged'),
            value: displayDateTime({ date: datasetDetails?.updatedAt }),
          },
        ],
      },
      {
        labelWidth: '105px',
        lineItems: [
          {
            label: tC('common.createdBy'),
            value: datasetDetails?.createdByUserName,
          },
        ],
      },
    ],
    [datasetDetails],
  );
  const rulesetDetailsColumns = useMemo<IColumn[]>(
    () => [
      {
        labelWidth: '105px',
        lineItems: [
          {
            label: tC('common.ruleSet', { count: 1 }),
            value: (
              <LinkCell
                href={`${SLOTTING_RULESET}/${rulesetDetails?.id}`}
                text={rulesetDetails?.name}
                dataTestId="slotting-run-details-ruleset-code"
              />
            ),
          },
          {
            label: tC('common.createdBy'),
            value: rulesetDetails?.createdBy,
          },
        ],
      },
      {
        labelWidth: '125px',
        lineItems: [
          {
            label: tC('common.lastUpdated'),
            value: displayDateTime({ date: rulesetDetails?.lastUpdated }),
          },
        ],
      },
      {
        labelWidth: '100px',
        lineItems: [],
      },
    ],
    [rulesetDetails],
  );

  const { dataTableProps: abcAnalysisTableProps } = useSlottingAbcAnalysisDataTable({
    runId: runDetails?.id,
    isDeployedDetails,
  });
  const { dataTableProps: movementsTableProps } = useSlottingMovementsDataTable({
    runId: runDetails?.id,
    isDeployedDetails,
  });

  const startDeployment = () => {
    openModal(
      {
        type: ModalTypes.runDeployMovements,
        runName: runDetails?.name,
        runId,
        datasetId: runDetails?.dataset_id,
        rulesetId: runDetails?.ruleset_id,
        skipAbc: rulesetDetails?.skipAbc,
      },
      {
        afterClose: (success) => {
          if (success) prepare();
        },
      },
    );
  };

  const title = useMemo(() => {
    if (isDeployedDetails) {
      return t('slotting-deployed-run.title');
    } else {
      return preparing ? (
        t('title_withoutDetails')
      ) : (
        <Typography variant="h2" align="left">
          <Trans
            ns="pages"
            i18nKey="run.title_withDetails"
            components={getTransComponents(['detailHeaderLabel'])}
            values={{ runName: runDetails?.name || '' }}
          />
        </Typography>
      );
    }
  }, [preparing, isDeployedDetails, runDetails?.name]);

  const helmetTitle = useMemo(() => {
    if (isDeployedDetails) {
      return t('slotting-deployed-run.title');
    } else {
      return preparing
        ? tC('htmlTitleSuffix', { prefix: t('title_withoutDetails') })
        : tC('htmlTitleSuffix', {
            prefix: t('htmlTitle_withDetails', {
              runName: runDetails?.name || '',
              interpolation: { escapeValue: false },
            }),
          });
    }
  }, [preparing, isDeployedDetails, runDetails?.name]);

  return (
    <>
      <Helmet>
        <title>{helmetTitle}</title>
      </Helmet>

      <PageInfo
        title={title}
        subtitle={isDeployedDetails ? t('slotting-deployed-run.subtitle') : t('subtitle')}
        backButton
        backButtonRoute={SLOTTING_RUNS}
      >
        <Button
          onClick={startDeployment}
          disabled={
            !runDetails?.id ||
            !runDetails?.dataset_id ||
            !runDetails?.ruleset_id ||
            !!runDetails?.deployed_at
          }
          variant="contained"
          styledVariant="actionMenu"
        >
          {t('continueToDeployment')}
        </Button>
      </PageInfo>

      {!preparing && !runDetails ? (
        <Paper sx={{ padding: (theme) => theme.spacing(4) }}>
          <EmptySection
            title={
              isDeployedDetails
                ? t('slotting-deployed-run.noDeployedRunTitle')
                : t('runNotFoundTitle')
            }
            message={
              isDeployedDetails
                ? t('slotting-deployed-run.noDeployedRunMessage')
                : t('runNotFoundMessage')
            }
          />
        </Paper>
      ) : (
        <>
          <Collapse
            title={
              <Box display="flex" alignItems="center" gap={5}>
                <Typography styledVariant="tableHeader">{t('runDetails')}</Typography>
                <Box sx={{ width: 150 }}>
                  <Typography variant="body4">{tC('slotting.score')}</Typography>
                  <ProgressBar percent={runDetails?.overall || 0} />
                </Box>
              </Box>
            }
            isLoading={preparing}
          >
            <JustifiedColumns columns={runDetailColumns} />
          </Collapse>

          <Collapse
            title={<Typography styledVariant="tableHeader">{t('datasetDetails')}</Typography>}
            isLoading={preparing}
          >
            <JustifiedColumns columns={datasetDetailsColumns} />
          </Collapse>

          <Collapse
            title={<Typography styledVariant="tableHeader">{t('rulesetDetails')}</Typography>}
            isLoading={preparing}
          >
            <JustifiedColumns columns={rulesetDetailsColumns} />
          </Collapse>

          {!isDeployedDetails && <SlottingRunMap runId={runDetails?.id} />}

          {preparing ? <CollapseLoading /> : <DataTable {...abcAnalysisTableProps} />}

          {preparing ? <CollapseLoading /> : <DataTable {...movementsTableProps} />}
        </>
      )}
    </>
  );
};

export default SlottingRun;

const convertDuration = (duration) => {
  const month = 60 * 24 * 30;
  const day = 60 * 24;
  const hour = 60;

  let remaining = duration;

  const months = Math.floor(remaining / month);
  remaining = remaining % month;
  const days = Math.floor(remaining / day);
  remaining = remaining % day;
  const hours = Math.floor(remaining / hour);
  const minutes = remaining % hour;
  if (months) {
    return `${months} Months${days > 5 ? ` ${days} Days` : ''}`;
  }
  if (days) {
    return `${days} Days${days < 3 && hours > 1 ? ` ${hours} Hours` : ''}`;
  }
  if (hours) {
    return `${hours} Hours${hours < 3 && minutes > 1 ? ` ${minutes} Minuters` : ''}`;
  }
};
