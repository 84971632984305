import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CloseDeliveryModal, PostGoodsReceiptOrIssueModal } from './delivery';
import { ShortShipModal } from './delivery/short-ship-modal';
import DispositionApproveModal from './disposition/disposition-approve';
import DispositionDenyModal from './disposition/disposition-deny';
import DispositionImageModal from './disposition/disposition-image';
import BlockFulfillmentModal from './fulfillment-block/block-fulfillment-modal';
import CreateFulfillmentBlockModal from './fulfillment-block/create-fulfillment-block-modal';
import UnblockFulfillmentModal from './fulfillment-block/unblock-fulfillment-modal';
import UpdateFulfillmentBlockModal from './fulfillment-block/update-fulfillment-block-modal';
import BlockLicensePlateModal from './license-plate/BlockLicensePlate';
import CreateRoleModal from './role-actions/create-role';
import DeleteRoleModal from './role-actions/delete-role';
import SaveRolePermissionsModal from './role-actions/save-role-permissions';
import {
  BinToBinMovementModal,
  InventoryAdjustmentApprovalModal,
  InventoryAdjustmentModal,
  InventoryAdjustmentResultModal,
  PickTaskCompleteModal,
  PickTaskCreateModal,
  PutawayTaskCompleteModal,
  PutawayTaskCreateModal,
  TaskEditDetailsModal,
  TaskTypeBinStatusModal,
  TaskTypeStockStatusModal,
} from './task';
import CopyUserGroupModal from './user-group-actions/copy-user-group';
import CreateUserGroupModal from './user-group-actions/create-user-group';
import EditUserGroupModal from './user-group-actions/edit-user-group';
import EditUserGroupUsersModal from './user-group-actions/edit-user-group-users';
import UserGroupUsersModal from './user-group-actions/user-group-users';

import { DeliveryType } from '@/graphql/types.generated';
import AgentConfigModal from '@components/modal/agent-config/AgentConfig';
import AgentProxyModal from '@components/modal/agent-proxy/AgentProxy';
import CreateAreaModal from '@components/modal/area/create-area-modal';
import UpdateAreaModal from '@components/modal/area/update-area-modal';
import MarkAsCompliantModal from '@components/modal/barcodes/mark-as-compliant-modal';
import BulkEditBinsModal from '@components/modal/bin/bulk-edit-bin-modal';
import CreateBinModal from '@components/modal/bin/create-bin-modal';
import UpdateBinModal from '@components/modal/bin/update-bin-modal';
import BinStorageTypeCreateModal from '@components/modal/bin-storage-type/bin-storage-type-create-modal';
import BinStorageTypeUpdateModal from '@components/modal/bin-storage-type/bin-storage-type-update-modal';
import CreateBusinessPartnerModal from '@components/modal/business-partner/create-business-partner-modal';
import UpdateBusinessPartnerModal from '@components/modal/business-partner/update-business-partner-modal';
import CompanyCreateModal from '@components/modal/company/company-create-modal';
import CompanyDetailsModal from '@components/modal/company/company-details-modal';
import ConfirmationModal from '@components/modal/confirmation';
import ContactInfoModal from '@components/modal/contact-info/contact-info-modal';
import CreateDatasetModal from '@components/modal/dataset/create-dataset-modal';
import DeleteDatasetModal from '@components/modal/dataset/delete-dataset-modal';
import UpdateDatasetModal from '@components/modal/dataset/update-dataset-modal';
import CancelDeliveryItemsModal from '@components/modal/delivery/cancel-delivery-items-modal';
import { CancelDeliveryModal } from '@components/modal/delivery/cancel-delivery-modal';
import DeliveryDoorAssignModal from '@components/modal/delivery/delivery-door-assign';
import DeliveryDoorUnassignModal from '@components/modal/delivery/delivery-door-unassign';
import DeliveryItemsModal from '@components/modal/delivery/delivery-items-modal';
import FulfillmentItemsModal from '@components/modal/delivery/fulfillment-items-modal';
import SalesOrderListModal from '@components/modal/delivery/sales-order-list-modal';
import DisplayPreferencesModal from '@components/modal/display-preferences/display-preferences-modal';
import CreateDoorModal from '@components/modal/door/create-door-modal';
import UpdateDoorModal from '@components/modal/door/update-door-modal';
import AddEquipmentModal from '@components/modal/equipment/add-equipment-modal';
import AddEquipmentModelModal from '@components/modal/equipment/add-equipment-model-modal';
import EquipmentTypeModal from '@components/modal/equipment/equipment-type-modal';
import UpdateEquipmentModal from '@components/modal/equipment/update-equipment-modal';
import UpdateEquipmentModelModal from '@components/modal/equipment/update-equipment-model-modal';
import UpdateFulfillmentHeaderModal from '@components/modal/fulfillment/update-fulfillment-header-modal';
import AddToLicensePlateModal from '@components/modal/license-plate/AddToLicensePlate';
import CreateLicensePlateModal from '@components/modal/license-plate/CreateLicensePlate';
import MergeLicensePlatesModal from '@components/modal/license-plate/MergeLicensePlates';
import MoveLicensePlateModal from '@components/modal/license-plate/MoveLicensePlate';
import UnpackLicensePlateModal from '@components/modal/license-plate/UnpackLicensePlate';
import UpdateLostAndFoundLpModal from '@components/modal/lost-and-found/update-lost-and-found-lp-modal';
import ModalContainer from '@components/modal/modal-container';
import OrganizationDetailsModal from '@components/modal/organization/organization-details-modal';
import CreateProductModal from '@components/modal/product/create-product-modal';
import SelectWarehouseUnitOfMeasureModal from '@components/modal/product/SelectWarehouseUoMModal';
import CreateOrCopyRulesetModal from '@components/modal/ruleset/create-or-copy-ruleset-modal';
import CreateRunFromDataset from '@components/modal/run/create-run-from-dataset';
import CreateRunFromRuleset from '@components/modal/run/create-run-from-ruleset';
import RunDeployMovementsModal from '@components/modal/run/deploy-run-movements';
import StockStatusCrudModal from '@components/modal/stock-status-types/StockStatusCrudModal';
import CreateTableLayoutModal from '@components/modal/table-layouts/create-table-layout-modal';
import DeleteTableLayoutModal from '@components/modal/table-layouts/delete-table-layout-modal';
import OverwriteTableLayoutModal from '@components/modal/table-layouts/overwrite-table-layout-modal';
import CancelTasksModal from '@components/modal/task/cancel-tasks-modal';
import CreateCountBinModal from '@components/modal/task/create-count-bin-modal';
import CreateIssueStockModal from '@components/modal/task/create-issue-stock-modal';
import EditTaskUoMModal from '@components/modal/task/edit-task-uom-modal';
import LoadTaskCompleteModal from '@components/modal/task/load-task-complete-modal';
import LoadTaskCreateModal from '@components/modal/task/load-task-create-modal';
import PickToStagingCompleteModal from '@components/modal/task/pick-to-staging-complete-modal';
import PickToStagingCreateModal from '@components/modal/task/pick-to-staging-create-modal';
import UnloadTaskCompleteModal from '@components/modal/task/unload-task-complete-modal';
import UnloadTaskCreateModal from '@components/modal/task/unload-task-create-modal';
import UpdateLicensePlateStockStatusModal from '@components/modal/task/update-license-plate-stock-status-modal';
import AddUserToTeamModal from '@components/modal/teams/add-user-to-team-modal';
import TeamCreateModal from '@components/modal/teams/team-create-modal';
import TeamUpdateModal from '@components/modal/teams/team-update-modal';
import UpdateUomGlossaryModal from '@components/modal/uom-glossary/UpdateUomGlossaryModal';
import UserManageModal from '@components/modal/users/user-manage-modal';
import WarehouseCreateModal from '@components/modal/warehouse/warehouse-create-modal';
import WarehouseDetailsModal from '@components/modal/warehouse/warehouse-details-modal';
import WarehouseRoleTypeModal from '@components/modal/warehouse-role-type/warehouse-role-type-modal';
import CreateZoneModal from '@components/modal/zone/create-zone-modal';
import UpdateZoneEntityMappingModal from '@components/modal/zone/update-zone-entity-mapping-modal';
import UpdateZoneModal from '@components/modal/zone/update-zone-modal';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { IModal, ModalTypes, ModalMaxWidth } from '@models/modal';

export * from './modal-actions';
export * from './modal-button';
export * from './modal-close';
export * from './modal-content';
export * from './modal-title';
export * from './modal-progress-icon';

export const Modal = () => {
  const { t } = useTranslation('components');
  const { t: tP } = useTranslation('pages');
  const {
    isOpen,
    modal,
    closeModal,
    isPreparing,
    setPreparing,
    guardedWarning,
    setGuardedWarning,
  } = useModalContent();

  const prepareOnOpen = () => {
    setPreparing(true);
  };

  interface ISelectedModal {
    title: string | ReactNode;
    content: string | ReactNode;
    onOpen?: () => void;
    maxWidth?: ModalMaxWidth;
    guarded?: boolean;
  }
  const selectModal = (m: IModal): ISelectedModal => {
    const defaultModal = {
      title: '',
      content: '',
    };
    if (!m?.type) return defaultModal;

    switch (m.type) {
      case ModalTypes.adjustQuantity:
        return {
          title: t('modal.task.adjustInventoryQuantity'),
          content: <InventoryAdjustmentModal />,
        };
      case ModalTypes.updateZoneEntityMapping:
        return {
          title: m.title,
          content: <UpdateZoneEntityMappingModal />,
          maxWidth: false,
        };
      case ModalTypes.adjustQuantityResult:
        return {
          title: t('modal.task.newPhysicalInventoryCountResult'),
          content: <InventoryAdjustmentResultModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.agentConfig:
        return {
          title: m.title,
          content: <AgentConfigModal />,
        };
      case ModalTypes.agentProxy:
        return {
          title: m.title,
          content: <AgentProxyModal />,
        };
      case ModalTypes.approveQuantityAdjustment:
        return {
          title: t('modal.task.approveNewPhysicalInventoryCount'),
          content: <InventoryAdjustmentApprovalModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.areaCreate:
        return {
          title: t('modal.area.createAreaTitle'),
          content: <CreateAreaModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.areaUpdate:
        return {
          title: t('modal.area.updateAreaTitle'),
          content: <UpdateAreaModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.barcodeCompliant:
        return {
          title: t('modal.barcodes.markAsCompliantTitle', {
            count: m.nonCompliantBarcodesIds.length,
          }),
          content: <MarkAsCompliantModal />,
        };
      case ModalTypes.binCreate:
        return {
          title: t('modal.bin.createBin.title'),
          content: <CreateBinModal />,
        };
      case ModalTypes.binStorageTypeCreate:
        return {
          title: t('modal.binStorageTypes.create.title'),
          content: <BinStorageTypeCreateModal />,
        };
      case ModalTypes.binStorageTypeUpdate:
        return {
          title: t('modal.binStorageTypes.update.title', {
            binStorageTypeLabel: m.binStorageType.label,
          }),
          content: <BinStorageTypeUpdateModal />,
        };
      case ModalTypes.binToBinMovement:
        return {
          title: t('modal.bin.binToBin.title'),
          content: <BinToBinMovementModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.binBulkEdit:
        return {
          title: t('modal.bin.bulkEditTitle', { count: m.bins.length }),
          content: <BulkEditBinsModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.binUpdate:
        return {
          title: t('modal.bin.updateBin.title'),
          content: <UpdateBinModal />,
        };
      case ModalTypes.businessPartnerCreate:
        return {
          title: t('modal.businessPartner.create.title'),
          content: <CreateBusinessPartnerModal />,
        };
      case ModalTypes.businessPartnerUpdate:
        return {
          title: t('modal.businessPartner.update.title'),
          content: <UpdateBusinessPartnerModal />,
        };
      case ModalTypes.cancelDeliveryItems:
        return {
          title: t('modal.cancelDeliveryItems.title', { count: m.deliveryItems.length }),
          content: <CancelDeliveryItemsModal />,
        };
      case ModalTypes.confirmation:
        return {
          title: m.title,
          content: <ConfirmationModal />,
        };
      case ModalTypes.dispositionApprove:
        return {
          title: t('modal.disposition.approve.title'),
          content: <DispositionApproveModal />,
        };
      case ModalTypes.dispositionDeny:
        return {
          title: t('modal.disposition.deny.title'),
          content: <DispositionDenyModal />,
        };
      case ModalTypes.dispositionImage:
        return {
          title: '',
          content: <DispositionImageModal />,
        };
      case ModalTypes.blockFulfillment:
        return {
          title: t('modal.fulfillmentBlock.blockFulfillment.title'),
          content: <BlockFulfillmentModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.unblockFulfillment:
        return {
          title: t('modal.fulfillmentBlock.unblockFulfillment.title'),
          content: <UnblockFulfillmentModal />,
        };
      case ModalTypes.cancelTasks:
        return {
          title: t('modal.cancelTasks.title', { count: m.tasks.length }),
          content: <CancelTasksModal />,
        };
      case ModalTypes.closeDelivery:
        return {
          title: m.title,
          content: <CloseDeliveryModal />,
        };
      case ModalTypes.cancelDelivery:
        return {
          title: m.title,
          content: <CancelDeliveryModal />,
        };
      case ModalTypes.companyCreate:
        return {
          title: t('modal.company.create.title'),
          content: <CompanyCreateModal />,
        };
      case ModalTypes.companyDetails:
        return {
          title: t('modal.company.updateDetails.title'),
          content: <CompanyDetailsModal />,
        };
      case ModalTypes.contactInformation:
        return {
          title: (() => {
            switch (m.parentType) {
              case 'organization':
                return t('modal.contactInfo.titleOrg');
              case 'company':
                return t('modal.contactInfo.titleCompany');
              case 'warehouse':
                return t('modal.contactInfo.titleWarehouse');
            }
          })(),
          content: <ContactInfoModal />,
        };
      case ModalTypes.createCountBin:
        return {
          title: t('modal.countBin.create.title'),
          content: <CreateCountBinModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.createIssueStock:
        return {
          title: t('modal.issueStock.create.title'),
          content: <CreateIssueStockModal />,
        };
      case ModalTypes.createProduct:
        return {
          title: t('modal.product.create.title'),
          content: <CreateProductModal />,
        };
      case ModalTypes.createRunFromDataset:
        let _title = t('modal.run.createFromDataset.title');
        if (m.runName && !m.rulesetId && m.rulesetType === 'existing')
          _title = t('modal.run.createFromDataset.title_existingRuleset');
        if (m.runName && m.rulesetId)
          _title = t('modal.run.createFromDataset.title_previewRuleset');
        return {
          title: _title,
          content: <CreateRunFromDataset />,
        };
      case ModalTypes.createRunFromRuleset:
        return {
          title: t('modal.run.createFromRuleset.title'),
          content: <CreateRunFromRuleset />,
          maxWidth: false,
        };
      case ModalTypes.datasetCreate:
        return {
          title: t('modal.dataset.create.title'),
          content: <CreateDatasetModal />,
        };
      case ModalTypes.datasetDelete:
        return {
          title: t('modal.dataset.delete.title'),
          content: <DeleteDatasetModal />,
        };
      case ModalTypes.datasetUpdate:
        return {
          title: t(`modal.dataset.update.${m.lock ? 'lock' : 'unlock'}.title`),
          content: <UpdateDatasetModal />,
        };
      case ModalTypes.deliveryAssignDoor:
        return {
          title: t('modal.delivery.doorAssign.title', {
            count: m.deliveries?.length,
            deliveryType:
              m.deliveries[0]?.type === DeliveryType.Delivery
                ? t('common.inboundDelivery')
                : t('common.outboundFulfillment'),
            code: m.deliveries[0]?.erpCode,
          }),
          content: <DeliveryDoorAssignModal />,
        };
      case ModalTypes.deliveryItems:
        return {
          title: t('modal.deliveryItems.title', { deliveryCode: m.delivery.erpCode }),
          content: <DeliveryItemsModal />,
          maxWidth: 'lg',
        };
      case ModalTypes.deliveryUnassignDoor:
        return {
          title: t('modal.delivery.doorUnassign.title', {
            count: m.deliveries?.length,
            deliveryType:
              m.deliveries[0]?.type === DeliveryType.Delivery
                ? t('common.inboundDelivery')
                : t('common.outboundFulfillment'),
            code: m.deliveries[0]?.erpCode,
          }),
          content: <DeliveryDoorUnassignModal />,
        };
      case ModalTypes.displayPreferences:
        return {
          title: (() => {
            switch (m.parentType) {
              case 'organization':
                return t('modal.displayPreferences.titleOrg');
              case 'company':
                return t('modal.displayPreferences.titleCompany');
              case 'warehouse':
                return t('modal.displayPreferences.titleWarehouse');
            }
          })(),
          content: <DisplayPreferencesModal />,
        };
      case ModalTypes.doorCreate:
        return {
          title: t('modal.door.create.title'),
          content: <CreateDoorModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.doorUpdate:
        return {
          title: t('modal.door.update.title'),
          content: <UpdateDoorModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.editTaskUnitOfMeasure:
        return {
          title: t('modal.task.editUoM.title'),
          content: <EditTaskUoMModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.equipmentAdd:
        return {
          title: t('modal.equipment.item.addTitle'),
          content: <AddEquipmentModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.equipmentUpdate:
        return {
          title: t('modal.equipment.item.editTitle'),
          content: <UpdateEquipmentModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.equipmentModelAdd:
        return {
          title: t('modal.equipment.model.addTitle'),
          content: <AddEquipmentModelModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.equipmentModelUpdate:
        return {
          title: t('modal.equipment.model.editTitle'),
          content: <UpdateEquipmentModelModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.equipmentType:
        return {
          title: m.equipmentType
            ? t('modal.equipment.type.update.title')
            : t('modal.equipment.type.add.title'),
          content: <EquipmentTypeModal />,
        };

      case ModalTypes.fulfillmentBlockCreate:
        return {
          title: t('modal.fulfillmentBlock.create.title'),
          content: <CreateFulfillmentBlockModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.fulfillmentBlockUpdate:
        return {
          title: t('modal.fulfillmentBlock.update.title'),
          content: <UpdateFulfillmentBlockModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.fulfillmentItems:
        return {
          title: t('modal.fulfillmentItems.title', { fulfillmentCode: m.fulfillment.erpCode }),
          content: <FulfillmentItemsModal />,
          maxWidth: 'lg',
        };
      case ModalTypes.licensePlateAddTo:
        return {
          title: m.title,
          content: <AddToLicensePlateModal />,
        };
      case ModalTypes.licensePlateBlock:
        return {
          title: m.title,
          content: <BlockLicensePlateModal />,
        };
      case ModalTypes.licensePlateCreate:
        return {
          title: m.title,
          content: <CreateLicensePlateModal />,
        };
      case ModalTypes.licensePlateMerge:
        return {
          title: m.title,
          content: <MergeLicensePlatesModal />,
        };
      case ModalTypes.licensePlateMove:
        return {
          title: t('licensePlateActions.moveHeader', { count: m.licensePlates.length }),
          content: <MoveLicensePlateModal />,
        };
      case ModalTypes.licensePlateUnpack:
        return {
          title: m.title,
          content: <UnpackLicensePlateModal />,
        };
      case ModalTypes.loadTaskCreate:
        return {
          title: t('modal.fulfillment.loadTaskCreate.title', { fulfillmentCode: '123456' }),
          content: <LoadTaskCreateModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.loadTaskComplete:
        return {
          title: t('modal.fulfillment.loadTaskComplete.title', { taskCode: '123456' }),
          content: <LoadTaskCompleteModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.organizationDetails:
        return {
          title: t('modal.organization.details.title'),
          content: <OrganizationDetailsModal />,
        };
      case ModalTypes.pickTaskComplete:
        return {
          title: t('modal.task.completePickTask'),
          content: <PickTaskCompleteModal />,
        };
      case ModalTypes.pickTaskCreate:
        return {
          title: (
            <span style={{ fontWeight: 700, fontSize: '22px' }}>
              {tP('deliveries.createPickTaskTitle')}
              {' - '}
              <span style={{ color: '#5EB1E3' }}>{m.erpCode}</span>
            </span>
          ),
          content: <PickTaskCreateModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.pickToStagingComplete:
        return {
          title: t('modal.fulfillment.pickToStagingComplete.title', {
            taskCode: m.task?.taskCode,
            interpolation: { escapeValue: false },
          }),
          content: <PickToStagingCompleteModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.pickToStagingCreate:
        return {
          title: (
            <span style={{ fontWeight: 700, fontSize: '22px' }}>
              {t('modal.fulfillment.pickToStagingCreate.title')}
              {' - '}
              <span style={{ color: '#5EB1E3' }}>{m.erpCode}</span>
            </span>
          ),
          content: <PickToStagingCreateModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.postGoodsReceiptOrIssue:
        return {
          title: m.title,
          content: <PostGoodsReceiptOrIssueModal />,
        };
      case ModalTypes.putawayTaskComplete:
        return {
          title: t('modal.task.completePutawayTask'),
          content: <PutawayTaskCompleteModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.putawayTaskCreate:
        return {
          title: (
            <span style={{ fontWeight: 700, fontSize: '22px' }}>
              {tP('deliveries.createPutawayTaskTitle')} -{' '}
              <span style={{ color: '#219653' }}>{m.erpCode}</span>
            </span>
          ),
          content: <PutawayTaskCreateModal />,
        };
      case ModalTypes.roleCreate:
        return {
          title: t('roleActions.createNewRole'),
          content: <CreateRoleModal />,
        };
      case ModalTypes.roleDelete:
        return {
          title: t('roleActions.deleteRole'),
          content: <DeleteRoleModal />,
        };
      case ModalTypes.rolePermissionsSave:
        return {
          title: t('roleActions.updateRolePermissions'),
          content: <SaveRolePermissionsModal />,
        };
      case ModalTypes.rulesetCreateOrCopy:
        return {
          title: undefined,
          content: <CreateOrCopyRulesetModal />,
          maxWidth: false,
          onOpen: prepareOnOpen,
          guarded: true,
        };
      case ModalTypes.runDeployMovements:
        return {
          title: undefined,
          maxWidth: false,
          content: <RunDeployMovementsModal />,
          onOpen: prepareOnOpen,
          guarded: true,
        };
      case ModalTypes.salesOrderList:
        return {
          title: t('modal.salesOrderList.title'),
          content: <SalesOrderListModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.shortShip:
        return {
          title: m.title,
          content: <ShortShipModal />,
        };
      case ModalTypes.stockStatusCreate:
      case ModalTypes.stockStatusCopy:
      case ModalTypes.stockStatusEdit:
        return {
          title: m.title,
          content: <StockStatusCrudModal />,
        };
      case ModalTypes.tableLayoutCreate:
        return {
          title: t('modal.tableLayout.create.title'),
          content: <CreateTableLayoutModal />,
        };
      case ModalTypes.tableLayoutDelete:
        return {
          title: t('modal.tableLayout.delete.title'),
          content: <DeleteTableLayoutModal />,
        };
      case ModalTypes.tableLayoutOverwrite:
        return {
          title: t('modal.tableLayout.overwrite.title'),
          content: <OverwriteTableLayoutModal />,
        };
      case ModalTypes.taskEditDetails:
        return {
          title: m.title,
          content: <TaskEditDetailsModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.taskTypeBinStatusMapping:
        return {
          title: m.title,
          content: <TaskTypeBinStatusModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.taskTypeStockStatusMapping:
        return {
          title: m.title,
          content: <TaskTypeStockStatusModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.teamCreate:
        return {
          title: t('modal.team.create.title'),
          content: <TeamCreateModal />,
        };
      case ModalTypes.teamUpdate:
        return {
          title: t('modal.team.update.title'),
          content: <TeamUpdateModal />,
        };
      case ModalTypes.unitOfMeasureGlossary:
        return {
          title: t('modal.uomGlossary.title'),
          content: <UpdateUomGlossaryModal />,
        };
      case ModalTypes.unloadTaskComplete:
        return {
          title: t('modal.delivery.unloadTaskComplete.title', {
            taskCode: m.task?.taskCode,
            interpolation: { escapeValue: false },
          }),
          content: <UnloadTaskCompleteModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.unloadTaskCreate:
        return {
          title: t('modal.delivery.unloadTaskCreate.title', {
            deliveryCode: m.deliveryDetails?.erpCode,
          }),
          content: <UnloadTaskCreateModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.updateFulfillmentHeader:
        return {
          title: t('modal.fulfillment.fulfillmentHeaderUpdate.title'),
          content: <UpdateFulfillmentHeaderModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.updateLicensePlateStockStatus:
        return {
          title: t('modal.updateLicensePlateStockStatus.title'),
          content: <UpdateLicensePlateStockStatusModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.updateLostAndFoundLP:
        return {
          title: t('modal.lostAndFound.updateLP.title'),
          content: <UpdateLostAndFoundLpModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.userAddToTeam:
        return {
          title: t('modal.team.userAddToTeam.title'),
          content: <AddUserToTeamModal />,
        };
      case ModalTypes.userCreate:
      case ModalTypes.userUpdate:
        return {
          title: m.title,
          content: <UserManageModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.userGroupCreate:
        return {
          title: t('modal.userGroups.create.title'),
          content: <CreateUserGroupModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.userGroupCopy:
        return {
          title: t('modal.userGroups.copy.title'),
          content: <CopyUserGroupModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.userGroupEdit:
        return {
          title: t('modal.userGroups.edit.title'),
          content: <EditUserGroupModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.userGroupUsers:
        return {
          title: t('modal.userGroups.usersEdit.title', {
            name: m.userGroupName,
            interpolation: { escapeValue: false },
          }),
          content: <UserGroupUsersModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.userGroupUsersEdit:
        return {
          title: t('modal.userGroups.usersEdit.title', {
            name: m.userGroupName,
            interpolation: { escapeValue: false },
          }),
          content: <EditUserGroupUsersModal />,
          onOpen: prepareOnOpen,
        };
      case ModalTypes.warehouseCreate:
        return {
          title: t('modal.warehouse.create.title'),
          content: <WarehouseCreateModal />,
        };
      case ModalTypes.warehouseDetails:
        return {
          title: t('modal.warehouse.details.title'),
          content: <WarehouseDetailsModal />,
        };
      case ModalTypes.warehouseRoleType:
        return {
          title: m?.warehouseRoleType
            ? t('modal.warehouseRoleType.update.title')
            : t('modal.warehouseRoleType.create.title'),
          content: <WarehouseRoleTypeModal />,
        };
      case ModalTypes.warehouseUnitOfMeasure:
        return {
          title: t('modal.warehouseUom.title'),
          content: <SelectWarehouseUnitOfMeasureModal />,
        };
      case ModalTypes.zoneCreate:
        return {
          title: t('modal.zone.createZoneTitle'),
          content: <CreateZoneModal />,
        };
      case ModalTypes.zoneUpdate:
        return {
          title: t('modal.zone.updateZoneTitle'),
          content: <UpdateZoneModal />,
        };
      default:
        return defaultModal;
    }
  };

  const [hasOnOpenRan, setHasOpenRan] = useState(false);
  const {
    title,
    content,
    onOpen,
    maxWidth = 'md',
    guarded,
  } = useMemo(() => {
    const newModal = selectModal(modal);

    // If Modal has an onOpen method, setHasOpenRan to false, otherwise, true.
    setHasOpenRan(!(modal !== null && newModal.onOpen));

    return newModal;
  }, [modal]);

  useEffect(() => {
    if (onOpen && typeof onOpen === 'function') {
      onOpen();
      setHasOpenRan(true);
    }
  }, [onOpen]);

  return (
    <ModalContainer
      maxWidth={maxWidth}
      open={isOpen}
      title={title}
      modalType={modal?.type}
      closeModal={(options) => {
        if (guarded && !options?.bypassGuarded) {
          return setGuardedWarning(true);
        }
        closeModal(options);
      }}
      isLoading={isPreparing || !hasOnOpenRan}
      guardedWarning={guardedWarning}
      setGuardedWarning={setGuardedWarning}
      guarded={guarded}
    >
      {content}
    </ModalContainer>
  );
};
