import { Box, Checkbox, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useGetTaskTypeBinStatusModalMappingsQuery,
  useTaskTypeBinStatusModalMappingUpdateMutation,
} from '@/graphql/defs/components/modals/__generated__/task-type-bin-status-modal.generated';
import { useBinStatusesQuery } from '@/graphql/defs/shared-queries/__generated__/bin-statuses.generated';
import { BinStatusCode } from '@/graphql/types.generated';
import { ModalContent } from '@components/modal';
import { ModalActions } from '@components/modal/modal-actions';
import { ModalButton } from '@components/modal/modal-button';
import ModalForm from '@components/modal/modal-form';
import { useEntityUtils } from '@context/entity-utils';
import { useModalContent } from '@context/modal/ModalContentProvider';
import { useSnackbar } from '@context/snackbar';
import { ITaskTypeBinStatusMappingModal } from '@models/modal';

const BinStatus = {
  [BinStatusCode.BinStatusInactive]: 1,
  [BinStatusCode.BinStatusDestinationBlock]: 2,
  [BinStatusCode.BinStatusSourceBlock]: 3,
};

export const TaskTypeBinStatusModal = () => {
  const { t } = useTranslation('components');
  const { t: tP } = useTranslation('pages', { keyPrefix: 'settings.bin-settings' });
  const [updateMapping] = useTaskTypeBinStatusModalMappingUpdateMutation();
  const { showMessage } = useSnackbar();
  const { selectedWarehouseId } = useEntityUtils();
  const { modal, closeModal, isPreparing, setPreparing } =
    useModalContent<ITaskTypeBinStatusMappingModal>();

  const { data: { binStatuses: { nodes: binStatuses } } = { binStatuses: { nodes: [] } } } =
    useBinStatusesQuery({
      onError: (error) => {
        showMessage({
          type: 'error',
          message: error.message,
        });
      },
    });

  const {
    data: {
      view: {
        nodes: [currentMapping],
      },
    } = { view: { nodes: [] } },
  } = useGetTaskTypeBinStatusModalMappingsQuery({
    variables: {
      filter: {
        warehouseId: {
          eq: selectedWarehouseId,
        },
        taskTypeId: {
          eq: modal.mapping.taskTypeId,
        },
      },
    },
    onError: (error) => {
      showMessage({
        type: 'error',
        message: error.message,
      });
    },
  });

  useEffect(() => {
    if (currentMapping && binStatuses.length) {
      const m = currentMapping?.binStatusMappings || [];
      setMapping(
        [...m].sort((a, b) => {
          return BinStatus[a.binStatusCode] > BinStatus[b.binStatusCode] ? 1 : -1;
        }),
      );
      setPreparing(false);
    }
  }, [currentMapping, binStatuses]);

  const formMethods = useForm();
  const { control, handleSubmit } = formMethods;

  const onCancel = () => {
    closeModal();
  };

  const [mapping, setMapping] = useState([]);

  const onSubmit = async (_, __) => {
    await updateMapping({
      variables: {
        input: {
          update: {
            warehouseId: selectedWarehouseId,
            binStatusMappings: mapping
              .filter(({ mapped }) => mapped)
              .map(({ binStatusId }) => binStatusId),
          },
          id: modal.mapping.taskTypeId,
        },
      },
    });
    closeModal({ bypassLoading: true, success: true });
  };

  return (
    !isPreparing && (
      <ModalForm onSubmit={handleSubmit(onSubmit)} formReturn={formMethods}>
        <ModalContent sx={{ height: '370px', width: '850px' }}>
          <Box>{t('taskTypeBinStatusMapping.modal.subtitle')}</Box>
          <Box sx={{ fontSize: '18px', display: 'flex', margin: '20px 0px' }}>
            <Box sx={{ fontWeight: 600, paddingRight: '10px' }}>{modal.mapping.taskTypeCode}</Box>
            {modal.mapping.taskTypeLabel}
          </Box>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {mapping
              .sort((a, b) => {
                return BinStatus[a.binStatusCode] > BinStatus[b.binStatusCode] ? 1 : -1;
              })
              .map((binStatus, i) => {
                return (
                  <Box key={i} sx={{ width: '33%', marginBottom: '10px' }}>
                    <Controller
                      name={binStatus?.binStatusLabel}
                      control={control}
                      defaultValue={binStatus?.mapped || false}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={binStatus?.mapped || false}
                              color="primary"
                              disabled={!binStatus?.editable || false}
                              onChangeCapture={() => {
                                const updatedMapping = [...mapping];
                                updatedMapping[i] = {
                                  ...updatedMapping[i],
                                  mapped: !updatedMapping[i]?.mapped,
                                };
                                setMapping(updatedMapping);
                              }}
                            />
                          }
                          label={tP(binStatus.binStatusCode)}
                        />
                      )}
                    />
                  </Box>
                );
              })}
          </Box>
        </ModalContent>
        <ModalActions>
          <ModalButton onClick={onCancel} variant="outlined" color="primary" actionType="cancel">
            {t('common.cancel')}
          </ModalButton>
          <ModalButton
            data-testid="taskTypeBinStatusMapping_submit"
            variant="contained"
            color="primary"
            actionType="submit"
          >
            {t('common.save')}
          </ModalButton>
        </ModalActions>
      </ModalForm>
    )
  );
};
