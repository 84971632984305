import { gql } from '@apollo/client';

import { BaseInventoryFragment } from '@/graphql/defs/shared-fragments/__generated__/inventory.generated';
import { GetBaseInventoryQuery } from '@/graphql/defs/shared-queries/__generated__/get-base-inventory.generated';

// Get Inventory By Bins
gql`
  query GetBaseInventory(
    $filter: InventoryBaseQueryShapeFilter
    $paging: OffsetPaging
    $sorting: [InventoryBaseQueryShapeSort!]
  ) {
    inventory: baseInventory(filter: $filter, paging: $paging, sorting: $sorting) {
      nodes {
        ...BaseInventory
      }
      totalCount
    }
  }
`;

export function GetBaseInventory_defaultData(
  nodes: BaseInventoryFragment[] = [],
  totalCount = 0,
): GetBaseInventoryQuery {
  return {
    __typename: 'Query',
    inventory: {
      __typename: 'InventoryBaseQueryShapeOffsetConnection',
      nodes,
      totalCount,
    },
  };
}
