import DataTableColumnControls from '@components/data-table/controls/column';
import DataTableControlsWrapper from '@components/data-table/controls/ControlsWrapper';
import DataTableQueryBasedExportControls from '@components/data-table/controls/export/QueryBasedExport';
import DataTableFilterControls from '@components/data-table/controls/filter';
import DataTableSaveLayoutControls from '@components/data-table/controls/layout/save-layout';

const DataTableQueryBasedControls = () => {
  return (
    <DataTableControlsWrapper data-testid="data-table-controls-container">
      <DataTableColumnControls />
      <DataTableFilterControls />
      <DataTableQueryBasedExportControls />
      <DataTableSaveLayoutControls />
    </DataTableControlsWrapper>
  );
};

export default DataTableQueryBasedControls;
