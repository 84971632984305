/* eslint-disable */
import * as Types from '../../../../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserGroupMappingsByUserIdQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type UserGroupMappingsByUserIdQuery = { __typename?: 'Query', userGroupMappings: { __typename?: 'UserGroupMappingOffsetConnection', nodes: Array<{ __typename?: 'UserGroupMapping', userGroupId: string }> } };

export type CreateUserMutationVariables = Types.Exact<{
  newUser: Types.UserCreateInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createOneUser: { __typename?: 'User', id: string, firstName: string, lastName: string } };

export type UpdateUserMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  updatedUser: Types.UserUpdateInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateOneUser: { __typename?: 'User', id: string, firstName: string, lastName: string } };


export const UserGroupMappingsByUserIdDocument = gql`
    query UserGroupMappingsByUserId($userId: ID!) {
  userGroupMappings(filter: {userId: {eq: $userId}}) {
    nodes {
      userGroupId
    }
  }
}
    `;

/**
 * __useUserGroupMappingsByUserIdQuery__
 *
 * To run a query within a React component, call `useUserGroupMappingsByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupMappingsByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupMappingsByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserGroupMappingsByUserIdQuery(baseOptions: Apollo.QueryHookOptions<UserGroupMappingsByUserIdQuery, UserGroupMappingsByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupMappingsByUserIdQuery, UserGroupMappingsByUserIdQueryVariables>(UserGroupMappingsByUserIdDocument, options);
      }
export function useUserGroupMappingsByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupMappingsByUserIdQuery, UserGroupMappingsByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupMappingsByUserIdQuery, UserGroupMappingsByUserIdQueryVariables>(UserGroupMappingsByUserIdDocument, options);
        }
export type UserGroupMappingsByUserIdQueryHookResult = ReturnType<typeof useUserGroupMappingsByUserIdQuery>;
export type UserGroupMappingsByUserIdLazyQueryHookResult = ReturnType<typeof useUserGroupMappingsByUserIdLazyQuery>;
export type UserGroupMappingsByUserIdQueryResult = Apollo.QueryResult<UserGroupMappingsByUserIdQuery, UserGroupMappingsByUserIdQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($newUser: UserCreateInput!) {
  createOneUser(input: {user: $newUser}) {
    id
    firstName
    lastName
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      newUser: // value for 'newUser'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $updatedUser: UserUpdateInput!) {
  updateOneUser(input: {id: $id, update: $updatedUser}) {
    id
    firstName
    lastName
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      updatedUser: // value for 'updatedUser'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;